import { Button } from '@progress/kendo-react-buttons';
import { Avatar, Card, CardActions, CardBody, CardHeader, CardTitle } from '@progress/kendo-react-layout';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import { ExtractEmails } from '../Marketing/ExtractEmails';
import { HeaderHome } from '../layout/header';
export const HomeDefault = ({name,flagLider,setEmailAsesor}) => {
    const cardsData = [
		{
			title: 'Reportes',
			icon: <FaIcons.FaChartPie className="iconCardDS2" />,
			bodytext:
				'Acá podrás encontrar todos los reportes operativos y de resultados de las diferentes areas de Siigo, en un solo lugar.',
			link: `/Reports/`,
		},
		{
			title: 'Mesa de Ayuda',
			icon: <FaIcons.FaTasks className="iconCardDS2" />,
			bodytext:
				'Acá podrás crear requerimientos para solicitudes, reportar incidencias y creacion de analisis y proyectos relacionados con data.',
			link: '/HelpDesk',
		},
		{
			title: 'Data Governance',
			icon: <FaIcons.FaLandmark className="iconCardDS2" />,
			bodytext:
				'Acá podrás encontrar toda la información relacionada con documentación, procesos, queries y gobierno de datos.',
			link: '/DataGovernance',
		},
		{
			title: 'Entrenamiento y Valoraciones',
			icon: <FaIcons.FaGraduationCap className="iconCardDS2" />,
			bodytext:
				'Acá podrás encontrar toda la información relacionada con Entrenamiento y Valoraciones.',
			link: '/Training',
		},
	];
		return (
			<>
				<div className="home-DSR">
					<br />
					{flagLider ? (
						<HeaderHome name={name} />
					):(<div>
						<h2 className="titulo-home">Hola, {name}!</h2>
					<p
						style={{
							maxWidth: '30%',
							textAlign: 'justify',
							fontFamily: 'Nunito Sans',
						}}>
						Bienvenido a DataSmart! Esta es la plataforma de analitica de Siigo.
					</p>
					<br />
					<p>
						Esta es la version inicial y estaremos en cambio constante agregando
						diferentes componentes y datos que vas a poder consumir y analizar.
					</p>
					</div>)}
					<br />
					 {flagLider? <ExtractEmails setEmailAsesor={setEmailAsesor}/> : null}

					<div className="example-container k-d-flex-col">
						<div className="header k-d-flex">
							<span
								style={{
									marginBottom: '10px',
									fontWeight: 700,
									fontSize: '18px',
								}}>
								Quick Links
							</span>
						</div>
					</div>
					<div className="k-card-deck">
						{cardsData.map((card, index) => {
							return (
								<div key={index}>
									<Card
										style={{
											minHeight: 250,
											maxWidth: 400,
										}}>
										<CardHeader
											className="k-hbox"
											style={{
												background: 'transparent',
												minHeight: '75px',
											}}>
											<Avatar
												type="icon"
												size="medium"
												rounded={'small'}
												themeColor={'base'}>
												{card.icon}
											</Avatar>
											<div>
												<CardTitle
													style={{
														marginTop: '12px',
														fontSize: '17px',
														fontWeight: 700,
													}}>
													{card.title}
												</CardTitle>
											</div>
										</CardHeader>
										<CardBody>
											<p>{card.bodytext}</p>
										</CardBody>
										<CardActions>
											<Link to={card.link}>
												<Button themeColor={'base'}>Ir a {card.title}</Button>
											</Link>
										</CardActions>
									</Card>
								</div>
							);
						})}
					</div>
				</div>
			</>
		);
	
}
