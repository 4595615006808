import {Editor, EditorTools, EditorUtils} from '@progress/kendo-react-editor';
import {createRef, useState} from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { thunkChargeInfoTicket } from '../../../redux/helpDeskTicketSlice';
const {
	Bold,
	Italic,
	Underline,
	AlignLeft,
	AlignRight,
	AlignCenter,
	Indent,
	Outdent,
	OrderedList,
	UnorderedList,
	Undo,
	Redo,
	Link,
	Unlink,
} = EditorTools;
const content = "<p><strong>Procedimiento: </strong></p><p style=\"margin-left: 30px;\"><em>&lt;Escribe Aquí&gt;</em></p><p><strong>Objectos Afectados:</strong></p><p style=\"margin-left: 30px;\"><em>&lt;Escribe Aquí&gt;</em></p><p><strong>Esquema:</strong></p><p style=\"margin-left: 30px;\"><em>&lt;Escribe Aquí&gt;</em></p>";
export const NewCommentEditor = ({
	setToast,
	timeElapsedStatus,
	handleSelect,
	ticketId,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const editor = createRef();
	const dispatch = useDispatch();
	const setHtml = (view) => {
		if (view) {
			EditorUtils.setHtml(view, '<p>Acá irá el contenido de un comentario<p/>');
		}
	};
	const getHtml = () => {
		let r = '';
		let view = '';
		if (editor.current) {
			view = editor.current.view;
			if (view) {
				r = EditorUtils.getHtml(view.state);
			}
		}
		const datatoSend = {
			date: new Date(),
			comment: r,
			ticketId,
			timeElapsedStatus,
			commentOwner: {
				name: sessionStorage.getItem('name'),
				mail: sessionStorage.getItem('usr'),
			},
		};

		setIsLoading(true);

		axios
			.post('/api/addCommentsTickets/v2', datatoSend, {
				headers: {
					'Content-Type': 'application/json; charset=UTF-8',
					'Access-Control-Allow-Origin': '*',
				},
			})
			.then((response) => {
				setToast({snackbarOpen: true, message: 'Comentario agregado con Exito', type: 'success'});
				let e={selected:0}
				setTimeout(() => {
					handleSelect(e)
				}, 2500);
			})
			.catch((err) => {
				setToast({snackbarOpen: true, message: 'No se pudo guardar tu comentario', type: 'error'});
				setHtml(view);
				let e={selected:0}
				setTimeout(() => {
					handleSelect(e)
				}, 2500);
				
			})
			.finally(() => {
				dispatch(thunkChargeInfoTicket({numTicket: ticketId}));
				setIsLoading(false);
			});
	};

	return (
		<div style={{position: 'absolute', left: '0', right: '0', top: '0'}}>
			{isLoading ? (
				<div className="k-loading-mask">
					<span className="k-loading-text">Loading</span>
					<div className="k-loading-image" />
					<div className="k-loading-color" />
				</div>
			) : (
				<></>
			)}

			<Editor
				tools={[
					[
						Bold,
						Italic,
						Underline,
						AlignLeft,
						AlignRight,
						AlignCenter,
						Indent,
						Outdent,
						OrderedList,
						UnorderedList,
						Undo,
						Redo,
						Link,
						Unlink,
					],
				]}
				contentStyle={{
					height: 260,
				}}
				defaultContent={content}
				ref={editor}
			/>
			<br />
			<button
				className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-button k-button-md k-rounded-md k-button-solid k-button-solid-base-icontext"
				onClick={getHtml}>
				<span className="k-icon k-i-plus" />
				Guarda Comentario
			</button>
		</div>
	);
};
