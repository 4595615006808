import React from 'react';
import {TileLayout} from '@progress/kendo-react-layout';
import {LineCharts} from '../utils/LineCharts';

export const ViewDefault = () => {
	return (
		<div style={{position: 'relative'}}>
			<TileLayout columns={3} items={tiles} rowHeight={150} />
			<div style={customStyle}>
				<span style={{fontWeight:'bold'}}>Próximamente habilitaremos el dashboard para ti</span>
			</div>
		</div>
	);
};
const customStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    inset: '0px',
    background: 'rgba(243, 243, 243, 0.5)',
    backdropFilter: 'blur(3px)',
    borderRadius: '10px',
    margin: '15px',
};

const CustomItem = ({order}) => {
	const content = [
		{'Cantidad de Requerimientos': 4},
		{'Cantidad de Incidentes': 3},
		{'Promedio de atencion ultimos 10 tickets': '2 horas'},
		{'Promedio de atencio ticket en el mes': '16 horas'},
		{'Cantidad de tickets por dia': 2},
		{'Cantidad de tickets por dia': 2},
		{'Gestion Time Reporting': '2 horas'},
		{'Gestion Tickets Mesa de ayuda': '37'},
	];
	return (
		<div className="card-dash">
			<span className="title-card-dash">{Object.keys(content[order])}</span>
			<span className="number-card-dash">{Object.values(content[order])}</span>
		</div>
	);
};
const tiles = [
	{
		defaultPosition: {
			col: 1,
			colSpan: 2,
			rowSpan: 2,
		},
		item: <LineCharts />,
	},
	{
		defaultPosition: {
			col: 3,
			colSpan: 1,
			rowSpan: 1,
		},
		item: <CustomItem order={0} />,
	},
	{
		defaultPosition: {
			col: 3,
			colSpan: 1,
			rowSpan: 1,
		},
		item: <CustomItem order={1} />,
	},
	{
		defaultPosition: {
			col: 1,
			colSpan: 1,
			rowSpan: 1,
		},
		item: <CustomItem order={2} />,
	},
	{
		defaultPosition: {
			col: 2,
			colSpan: 1,
			rowSpan: 1,
		},
		item: <CustomItem order={3} />,
	},
	{
		defaultPosition: {
			col: 3,
			colSpan: 1,
			rowSpan: 1,
		},
		item: <CustomItem order={4} />,
	},
	{
		defaultPosition: {
			col: 1,
			colSpan: 1,
			rowSpan: 1,
		},
		item: <CustomItem order={5} />,
	},
	{
		defaultPosition: {
			col: 2,
			colSpan: 1,
			rowSpan: 1,
		},
		item: <CustomItem order={6} />,
	},
	{
		defaultPosition: {
			col: 3,
			colSpan: 1,
			rowSpan: 1,
		},
		item: <CustomItem order={7} />,
	},
];
