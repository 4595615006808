import {
	MultiSelectTree,
	getMultiSelectTreeValue,
} from '@progress/kendo-react-dropdowns';
import {expandedState, processMultiSelectTreeData} from './multiselectTree';
import {FieldWrapper} from '@progress/kendo-react-form';
import {Loader} from '@progress/kendo-react-indicators';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {  Hint,Error } from "@progress/kendo-react-labels";
import useAxios from '../../../hooks/UseAxios';
const dataItemKey = 'baseDatos';
const checkField = 'checkField';
const checkIndeterminateField = 'checkIndeterminateField';
const subItemsField = 'tabla';
const expandField = 'expanded';
const textField = 'baseDatos';
const fields = {
	dataItemKey,
	checkField,
	checkIndeterminateField,
	expandField,
	subItemsField,
};
const dataw = [
	{
        "baseDatos": "db_monitoring",
        "tabla": [
            {
                "baseDatos": "vw_slv_timereporting_avg"
            },
            {
                "baseDatos": "tbl_slv_monitoreo"
            },
            {
                "baseDatos": "vw_slv_timereporting"
            }
        ]
    },
    {
        "baseDatos": "db_moodle_mx",
        "tabla": null
    },
    {
        "baseDatos": "db_netsuite",
        "tabla": [
            {
                "baseDatos": "tbl_slv_appliedtotransaction"
            }
        ]
    },
    {
        "baseDatos": "db_nubemultitenant",
        "tabla": [
            {
                "baseDatos": "vw_slv_movimientosne"
            },
            {
                "baseDatos": "vw_slv_contactoscuentasne"
            }
        ]
    },
    {
        "baseDatos": "db_nubemultitenantacademico",
        "tabla": null
    },
    {
        "baseDatos": "db_nubemultitenantchile",
        "tabla": null
    },
    {
        "baseDatos": "db_nubemultitenantmexico",
        "tabla": null
    },
    {
        "baseDatos": "db_nubemultitenantv5",
        "tabla": null
    }
];
const placeholder = 'Please select ...';
const DropDownTreeCustom = (fieldRenderProps) => {

    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        type,
        value,
        data,
        optional,
        ...others
      } = fieldRenderProps;
    const [loading, setLoading] =useState(false)

	const [currentSelected, setCurrentSelected] = useState([]);
	const [expanded, setExpanded] = useState([data[0][dataItemKey]]);
	const [filter, setFilter] = useState(null);

    const showValidationMessage = touched && validationMessage;
      const showHint = !showValidationMessage && hint;
      const hintId = showHint ? `${id}_hint` : '';
      const errorId = showValidationMessage ? `${id}_error` : '';

      
	const handleOpen = () => {
		setCurrentSelected(value);
	};
	const onChange = (event) => {
        fieldRenderProps.onChange({
            value: getMultiSelectTreeValue(data, {
				...fields,
				...event,
				value,
			}),
        })
			
	};
	const onExpandChange = useCallback(
		(event) => setExpanded(expandedState(event.item, dataItemKey, expanded)),
		[expanded]
	);
	const handleClose = () => {
		setCurrentSelected([]);
	};
	const handleCancel = () => {
		//setValue(currentSelected);
		setFilter(null);
	};
	const treeData = useMemo(
		() =>
			processMultiSelectTreeData(data, {
				expanded,
				value,
				filter,
				...fields,
			}),
		[expanded, value, filter]
	);
	const onFilterChange = (event) => setFilter(event.filter);
	return (
		<FieldWrapper>
			<div className="app-wrapper">
				<div>{label}</div>
				<MultiSelectTree
					style={{
						
					}}
                    {...others}
					data={treeData}
					value={value}
					onChange={onChange}
					placeholder={placeholder}
					textField={textField}
                    disabled={disabled}
					dataItemKey={dataItemKey}
					checkField={checkField}
					checkIndeterminateField={checkIndeterminateField}
					expandField={expandField}
					subItemsField={subItemsField}
					onExpandChange={onExpandChange}
					onOpen={handleOpen}
					onClose={handleClose}
					onCancel={handleCancel}
					filterable={true}
					onFilterChange={onFilterChange}
					adaptive={true}
					adaptiveTitle={placeholder}
				/>
			</div>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
    {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
export default DropDownTreeCustom;