export const baseData = [
    {
        "ActivityID": 1,
        "Área": "2",
        "title": "Hugo Fernando Castiblanco Acosta",
        Start: "2024-01-15T13:00:00.000Z",
        End: "2024-01-15T15:00:00.000Z"
    },
    {
        "ActivityID": 2,
        "Área": "2",
        "title": "Silvia Castiblanco Acosta",
        Start: "2024-01-16T13:00:00.000Z",
        End: "2024-01-16T15:00:00.000Z"
    },
    {
        "ActivityID": 3,
        "Área": "1",
        "title": "Hugo Fernando Castiblanco Acosta",
        Start: "2024-01-16T13:00:00.000Z",
        End: "2024-01-16T15:00:00.000Z"
    },{
        "ActivityID": 4,
        "Start": "2024-01-17T05:00:00.000Z",
        "End": "2024-01-18T05:00:00.000Z",
        "Área": "1",
        "title": "Jose"
    },{
        "Start": "2024-01-17T05:00:00.000Z",
        "End": "2024-01-18T05:00:00.000Z",
        "Área": "1",
        "title": "Jose2",
        "ActivityID": 5
    },{
        "Start": "2024-01-18T05:00:00.000Z",
        "End": "2024-01-19T05:00:00.000Z",
        "Área": "2",
        "title": "Fernando",
        "ActivityID": 6
    }];
    export const customModelFields = {
        id: "ActivityID",
        Área: "Área",
        start: "Start",
        end: "End",
        title: "title"
    };
    
      const currentYear = new Date().getFullYear();
    export const parseAdjust = eventDate => {
        const date = new Date(eventDate);
        date.setFullYear(currentYear);
        return date;
      };
      const date = Date.now();
      export const displayDate = new Date(date);
