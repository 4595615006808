import * as React from 'react';
import { SchedulerForm } from '@progress/kendo-react-scheduler';
import { CustomDialog } from '../TimeReporting/custom-dialog';
import { CustomFormSkeletonOperation } from './CustomFormSkeletonOperation';
export const CustomFormOperation = props => {
  const isEditing = props.dataItem.ActivityID !== undefined;
  
  let initialValues= {
    Start:props.dataItem.Start,
    End:props.dataItem.End,
    Área:props.dataItem.Área,
    title:props.dataItem.title,
  }
  if(isEditing){
    initialValues= {...initialValues,
      ActivityID:props.dataItem.ActivityID,
    }

    
  }
  const requiredValidator = React.useCallback(value => value === undefined || value === null || value === '' ? 'Campo Obligatorio' : undefined, []);

  const formValidator = (_dataItem, formValueGetter) => {
    let result = {};
    result.title = [requiredValidator(formValueGetter('title'))].filter(Boolean).reduce((current, acc) => current || acc, '');
    result.Área = [requiredValidator(formValueGetter('Área'))].filter(Boolean).reduce((current, acc) => current || acc, '');
    return result;
  };
  return <SchedulerForm initialValues={initialValues} {...props} editor={CustomFormSkeletonOperation} dialog={CustomDialog} validator={formValidator} />;
};