import React from 'react'
import { Document, Page } from "react-pdf";

const url="https://adlssynapsesiigodwh.blob.core.windows.net/datasmartdocs/SLAs/SLAs%20DA%20-%20Strategy.pdf?sp=r&st=2022-10-25T20:05:53Z&se=2024-10-26T04:05:53Z&spr=https&sv=2021-06-08&sr=b&sig=arNryHcQFGKM4XhJBmk6dLMxkGPf8Rm375WxvCvXrZw%3D"

export const DGDictionary=()=> {
  
  

  return (
    <><h2 className='page-header'>Diccionario de Datos</h2>
    <object data="https://adlssynapsesiigodwh.blob.core.windows.net/datasmartdocs/SLAs/SLA%20-%20Data%20Science.pdf?sp=r&st=2022-10-26T17:46:11Z&se=2024-10-26T01:46:11Z&spr=https&sv=2021-06-08&sr=b&sig=qIko%2FiNkDdrHRTqpRYWqCZde12X4idlhasMKrf98%2F8k%3D" type="application/pdf" width="100%"></object>
</>
  )
}