import { React, Component } from 'react'
import './SiignalTable.css'
import { BottomNavigationAction, BottomNavigation, Grid, Paper, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Card, CardActions, CardContent, Typography, CircularProgress, Collapse } from '@mui/material';
import HexagonIcon from '@mui/icons-material/Hexagon';

class SiignalTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableHeaders: ["Intervalo", "Pais", "Celula", "TMO", "ACW", "SLA", "%Abandoned", "Vol. Forecast", "Vol. Real", "%Volumen", "Staff Programados", "Staff Real", "%Staff"]
        }
    }

    formatTime(seconds) {
        if (seconds == undefined) {
            return "00:00";
        }
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(remainingSeconds).padStart(2, "0");
        return `${formattedMinutes}:${parseInt(formattedSeconds)}`;
    }


    summaryRow(key) {
        return (
            <TableRow key={key}>
                <TableCell><b>Resumen</b></TableCell>
                <TableCell><b>{this.props.cellToDraw.split("#")[0]}</b></TableCell>
                <TableCell><b>{this.props.cellToDraw.split("#")[1]}</b></TableCell>
                <TableCell><b>{this.props.tableData.Resumen[this.props.cellToDraw].TMO}</b></TableCell>
                <TableCell><b>{this.props.tableData.Resumen[this.props.cellToDraw].ACW}</b></TableCell>
                <TableCell><b>{this.props.tableData.Resumen[this.props.cellToDraw].SLA}</b></TableCell>
                <TableCell><b>{this.props.tableData.Resumen[this.props.cellToDraw]["% Abandoned"]}</b></TableCell>
                <TableCell><b>{this.props.tableData.Resumen[this.props.cellToDraw].VolForecast}</b></TableCell>
                <TableCell><b>{this.props.tableData.Resumen[this.props.cellToDraw].VolReal}</b></TableCell>
                <TableCell><b>{this.props.tableData.Resumen[this.props.cellToDraw]["% Forecast"]}</b></TableCell>
                <TableCell><b>{this.props.tableData.Resumen[this.props.cellToDraw].StaffForecast}</b></TableCell>
                <TableCell><b>{this.props.tableData.Resumen[this.props.cellToDraw].StaffReal}</b></TableCell>
                <TableCell><b>{this.props.tableData.Resumen[this.props.cellToDraw]["% Staffing"]}</b></TableCell>
            </TableRow>
        )
    }

    render() {
        return (

            <Grid className="paddingTop">
                <Box >
                    <Card variant="outlined" className='boxShadow'>
                        <Typography component="div" sx={{ fontWeight: 800, fontSize: 19, color: '#00244c', fontFamily: 'inherit', paddingLeft: '5px' }}>
                            Tabla de datos
                        </Typography>
                        <br />
                        <TableContainer component={Paper} sx={{ height: 500 }}>
                            <Table stickyHeader sx={{ minWidth: 700, height: 400 }} size="small" >
                                <TableHead>
                                    <TableRow>
                                        {
                                            this.state.tableHeaders.map((header) => {
                                                return (
                                                    <TableCell key={header}>
                                                        <b>{header}</b>
                                                    </TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        Object.keys(this.props.tableData).sort().map((interval) => {
                                            const STAFF_FORECAST = this.props.tableData[interval].STAFF_FORECAST;
                                            const STAFF_REAL = this.props.tableData[interval].STAFF_REAL;
                                            const STAFFING = (STAFF_FORECAST == undefined || STAFF_REAL == undefined) ? "-" : (STAFF_REAL / STAFF_FORECAST);

                                            const VOLUME_FORECAST = this.props.tableData[interval].VOLUME_FORECAST;
                                            const VOLUME_REAL = this.props.tableData[interval].VOLUME_REAL;
                                            const VOLUME = (VOLUME_FORECAST == undefined || VOLUME_REAL == undefined) ? "-" : (VOLUME_REAL / VOLUME_FORECAST);

                                            const SLA = this.props.tableData[interval]["SLA"] / this.props.tableData[interval]["VOLUME_REAL"]

                                            const ABANDON = this.props.tableData[interval].ABANDONED_AVG

                                            const ACW = this.formatTime(this.props.tableData[interval]["ACW"] / this.props.tableData[interval]["VOLUME_REAL"])

                                            const TMO = this.formatTime(this.props.tableData[interval]["HT"] / (this.props.tableData[interval]["VOLUME_REAL"] - this.props.tableData[interval]["ABANDONED"]))
                                            return (
                                                <TableRow key={interval} >
                                                    <TableCell>{interval}</TableCell>
                                                    <TableCell>{this.props.selectedView.country}</TableCell>
                                                    <TableCell>{this.props.selectedView.cell}</TableCell>
                                                    <TableCell>{(TMO.toString() == 'NaN:NaN') ? "-" : TMO}</TableCell>
                                                    <TableCell>{(ACW == 'NaN:NaN') ? "-" : ACW}</TableCell>
                                                    <TableCell>{(SLA.toString() == 'NaN') ? "-" : `${(SLA * 100).toFixed(2)}%`}</TableCell>
                                                    <TableCell>{(ABANDON == undefined) ? "-" : `${(ABANDON * 100).toFixed(2)}%`}</TableCell>
                                                    <TableCell>{(VOLUME_FORECAST == undefined) ? "-" : parseFloat(VOLUME_FORECAST).toFixed(2)}</TableCell>
                                                    <TableCell>{(VOLUME_REAL == undefined) ? "-" : parseFloat(VOLUME_REAL).toFixed(2)}</TableCell>
                                                    <TableCell>{(VOLUME == '-') ? "-" : (VOLUME * 100.0).toFixed(2) + "%"} </TableCell>
                                                    <TableCell>{(STAFF_FORECAST == undefined) ? "-" : parseFloat(STAFF_FORECAST).toFixed(2)}</TableCell>
                                                    <TableCell>{(STAFF_REAL == undefined) ? "-" : parseFloat(STAFF_REAL).toFixed(2)}</TableCell>
                                                    <TableCell>{(STAFFING == '-') ? "-" : (STAFFING * 100.0).toFixed(2) + "%"}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                    {
                                        // this.summaryRow("ResumenFinal")
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </Box>
            </Grid>
        )
    }
}

export { SiignalTable };