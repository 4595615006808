import React from 'react';
import ReactMarkdown from 'react-markdown';
import './Components.css';
import { RiRobot2Fill } from "react-icons/ri";

const Message = ({ text, sender, references, toggleReferences, isExpanded,name='U' }) => (
    <div className={`message-cb ${sender}-cb`}>
        {sender === 'bot' && <div className="circle-left-cb"><RiRobot2Fill /></div>}
        <div className="message-content-cb">
            <ReactMarkdown>{text}</ReactMarkdown>
            {sender === 'bot' && references && references.length > 0 && (
                <button className="reference-button-cb" onClick={toggleReferences}>
                    Referencias ({references.length})
                </button>
            )}
            {isExpanded && references && references.length > 0 && (
                <div className="reference-list-cb">
                    {references.map((ref, index) => (
                        <div key={index} className="reference-item-cb">
                            <strong>{ref.title}</strong>
                            <p>{ref.content}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
        {sender === 'user' && <div className="circle-right-cb">{name}</div>}
    </div>
);

export default Message;