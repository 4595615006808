// import css
import './style.css';
import axios from 'axios'
import Chip from '@mui/material/Chip';
import Input from '@mui/material/Input';
import { Loading } from '../Loading/Loading';
import SendIcon from '@mui/icons-material/Send';
import { Fade } from "@progress/kendo-react-animation";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { CardHeader, Grid, CardContent } from '@mui/material';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import Card from '@mui/material/Card';

// import react
import React, { useEffect } from 'react';
import { CardBody } from '@progress/kendo-react-layout';

function SkillToCellEditor() {
    const [loading, setLoading] = React.useState(true);
    const [skillToCell, setSkillToCell] = React.useState([]);
    const [cells, setCells] = React.useState({ unnasigned: {} });
    const [newSkill, setNewSkill] = React.useState(null);
    const [notification, setNotification] = React.useState({
        notification: false,
        notificationType: 'success',
        notificationMessage: '',
    });

    useEffect(() => {
        Promise.all([
            axios.get(`/api/siignalGetSkillToCell`, {}),
        ]).then(([skillToCell]) => {
            setSkillToCell(skillToCell.data)
            processSkillToCell(skillToCell.data)
        }).then(() => {
            setLoading(false)
        }).catch(error => {
            console.error(error);
            setLoading(false)
        });
    }, [])

    const processSkillToCell = (skillData) => {
        let cells = { "unnasigned": {} }
        let control = 0
        skillData.map(el => {
            if (el.Pais != "") {
                if (cells[el.Pais] == undefined) {
                    cells[el.Pais] = {}
                }
                if (cells[el.Pais][el.Celula] == undefined) {
                    cells[el.Pais][el.Celula] = {}
                }
                if (cells[el.Pais][el.Celula][el.Skill] == undefined) {
                    cells[el.Pais][el.Celula][el.Skill] = el.Skill
                }
            } else {
                if (control < 5) {
                    cells.unnasigned[el.Skill] = el.Skill
                    control++
                }
            }
        })
        setCells(cells)
    }

    const addSkillToCell = (skillToAdd) => {
        const apiCall = async () => {
            try {
                if (newSkill != null) {
                    const response = await axios.patch(`/api/siignalUpdateSkillToCell`, skillToAdd)
                    processSkillToCell(response.data)
                    showNotification('success', 'Skill eliminado correctamente')
                }
            } catch (error) {
                showNotification('error', 'Error al eliminar el skill')
            }
        }
        apiCall()
    }

    const deleteSkillFromCell = (skillToDelete) => {
        const apiCall = async () => {
            try {
                const response = await axios.patch(`/api/siignalUpdateSkillToCell`, skillToDelete)
                processSkillToCell(response.data)
                showNotification('success', 'Skill eliminado correctamente')
            } catch (error) {
                showNotification('error', 'Error al eliminar el skill')
            }
        }
        apiCall()
    }

    const showNotification = (type, message) => {
        setNotification({
            notification: true,
            notificationType: type,
            notificationMessage: message,
        });

        setTimeout(() => {
            setNotification({
                notification: false,
            });
        }, 5000);
    }

    return (
        loading ? <Loading /> : <>
            <GridLayout
                className="siignal-grid-layout"
                align={{ horizontal: 'center', }}
                cols={[{ width: "32%" }, { width: "32%" }, { width: "32%" }]}
                // rows={[{ height: "auto" }, { height: "auto" }, { height: "auto" }]}
                gap={{ rows: 3, cols: 3, }}>

                <GridLayoutItem className={`skillToCell-box-shadow`} colSpan={3}>
                    <div className="k-hbox">
                        <p className="skillToCell-modulePresentation" xs={{ paddingTop: 3, margin: 3 }} align="center">
                            Asignacion de skills a celulas
                        </p>
                    </div>
                </GridLayoutItem>
                {
                    Object.keys(cells).map((pais, index) => {
                        if (pais != "unnasigned") {
                            return (
                                Object.keys(cells[pais]).map((celula, index) => {
                                    return (


                                        <GridLayoutItem className={`skillToCell-box-shadow`}>
                                            <CardHeader
                                                title={celula}
                                                action={
                                                    <Chip label={pais} color='default' variant='outlined' className={`siignal-${pais.toLowerCase()}-flag`} />
                                                }
                                            />
                                            {/* <input
                                                                type="text"
                                                                list='skillsList'
                                                                className='iputSkillsDataList'
                                                                placeholder='Añadir nuevo skill'
                                                                onChange={(ev) => {
                                                                    if (cells.unnasigned[ev.target.value] != undefined) {
                                                                        setNewSkill({
                                                                            pais: pais,
                                                                            celula: celula,
                                                                            skill: ev.target.value,
                                                                            _id: ev.target.value
                                                                        })
                                                                    } else {
                                                                        setNewSkill(null)
                                                                    }
                                                                }} />
                                                            <label className='saveIconContainer'>
                                                                <SendIcon className='saveIcon' onClick={() => {
                                                                    addSkillToCell(newSkill)
                                                                }} />
                                                            </label> */}
                                            <datalist id='skillsList'>
                                                {
                                                    Object.keys(cells.unnasigned).map((skill, index) => {
                                                        return (
                                                            <option value={skill} />
                                                        )
                                                    })
                                                }
                                            </datalist>
                                            <ul>
                                                {
                                                    Object.keys(cells[pais][celula]).map((skill, index) => {
                                                        return (
                                                            <li item>
                                                                {/* <DeleteForeverIcon
                                                                                    className='iconContainer'
                                                                                    onClick={() => {
                                                                                        console.log(`is deleting ${skill}`)
                                                                                        console.log({
                                                                                            Pais: "",
                                                                                            Celula: "",
                                                                                            Skill: skill,
                                                                                            _id: skill
                                                                                        })
                                                                                        deleteSkillFromCell({
                                                                                            Pais: "",
                                                                                            Celula: "",
                                                                                            Skill: skill,
                                                                                            _id: skill
                                                                                        })
                                                                                    }} /> */}
                                                                {skill}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </GridLayoutItem>
                                    )
                                })

                            )
                        }
                    })
                }
            </GridLayout >
            <NotificationGroup style={{
                right: 0,
                bottom: 0,
                alignItems: 'flex-start',
                flexWrap: 'wrap-reverse'
            }}>
                <Fade>
                    {notification.notification && <Notification type={{
                        style: notification.notificationType,
                        icon: true
                    }} closable={false}>
                        <span>{notification.notificationMessage}</span>
                    </Notification>}
                </Fade>
            </NotificationGroup>
        </>
    );
}
export default SkillToCellEditor;

