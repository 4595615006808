import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

export const TNBSlice = createSlice({
  name: 'TNB',
  initialState: {
    detalleCapacitacion: null,
    loadingDetalleCap:true,
    allStatusCapacitacion:null,
    listaDeCapacitaciones:[],
    isLoadingCapacitaciones:true
  },
  reducers: {
    setDetalleCapacitacion: (state, action) => {
      state.detalleCapacitacion = action.payload;
    },
    setAllStatusCapacitacion: (state, action) => {
      state.allStatusCapacitacion = action.payload;
    },
    setListaDeCapacitaciones: (state, action) => {
      state.listaDeCapacitaciones = action.payload;
    },
    setIsLoadingCapacitacion:(state,action)=>{
      state.isLoadingCapacitaciones=action.payload
    }, 
    setLoadingDetalleCapacitacion:(state,action)=>{
      state.loadingDetalleCap=action.payload
    }, 
  },
})

export const getListaDeCapacitaciones=(idCap=undefined)=>{
    return async (dispatch,getState)=>{
        dispatch(setIsLoadingCapacitacion(true));
        const response = await axios.get(
          `/api/TNB/RegisteredTrainings?id=${idCap}`
        );
        const test = response.data;
        dispatch(setListaDeCapacitaciones(test))
        dispatch(setIsLoadingCapacitacion(false));
    }
}
export const get_DetalledeCapacitaciones=(idCap=undefined)=>{
  return async (dispatch,getState)=>{
      dispatch(setLoadingDetalleCapacitacion(true));
      const response = await axios.get(
        `/api/TNB/RegisteredTrainings?id=${idCap}`
      );
      const test = response.data;
      dispatch(setDetalleCapacitacion(test))
      dispatch(setLoadingDetalleCapacitacion(false));
  }
}

// Action creators are generated for each case reducer function
export const { setDetalleCapacitacion, setAllStatusCapacitacion,setIsLoadingCapacitacion,setListaDeCapacitaciones,setLoadingDetalleCapacitacion } = TNBSlice.actions

export const selectDetalleCapacitacion = state => state.TNB.detalleCapacitacion

export const selectAllStatusCapacitacion = state => state.TNB.allStatusCapacitacion

export const selectListadeCapacitaciones = state => state.TNB.listaDeCapacitaciones

export const selectIsLoadingCapacitaciones = state => state.TNB.isLoadingCapacitaciones

export const selectLoadingDetalle_Cap = state => state.TNB.loadingDetalleCap


export default TNBSlice.reducer