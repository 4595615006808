import '../../components/helpDesk/contentTarjet.css';
import {useSearchParams} from 'react-router-dom';
import DescriptionRequest from '../../components/helpDesk/DescriptionRequest';

import {DialogComponent} from '../../HelpDesk/components/Telerik/Dialog';
import { RequestOpened } from '../../HelpDesk/components/Lobby/MyRequest/RequestOpened';

export const MyRequest = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	searchParams.get('id');

	const search = window.location.search;
	const params = new URLSearchParams(search);
	const id = params.get('id');

	return (
		<>
			{id !== null ? (
				<DescriptionRequest id={id} page={2} />
			) : (
				<>
					<br />
					<div className="contenedorTarjet">
						{/* <MyOpenRequest/> */}
						<RequestOpened />
					</div>
				</>
			)}
		</>
	);
};
