import React from 'react'
import '../../components/status-card/statuscard.css'
import * as FaIcons from 'react-icons/fa'
import * as TbIcons from 'react-icons/tb'
import * as MdIcons from 'react-icons/md'
import { Link } from 'react-router-dom'

const DynamicFaIcon = ({ name }) => {
    const IconComponent = FaIcons[name];
  
    if (!IconComponent) { // Return a default one
      return <FaIcons.FaBeer />;
    }
  
    return <IconComponent />;
  };



export const DGDataProjects=() => {

  return (
    <div>
      <h2 className='page-header'>Proyectos Data</h2>
      <h3 className='page-subheader'>Proyectos por Área</h3>
      <section className='status-cards-container'>
        <div className="col-6-SDR">
            <div className='status-card'>
              <div className="status-card__icon">
                  <TbIcons.TbPresentationAnalytics/>
              </div>
              <div className="status-card__info">
                <Link to='/DataGovernance/Projects/DataAnalytics'>Data Analytics</Link>
              </div>
            </div>
        </div>
        <div className='col-6-SDR'>
              <div className='status-card'>
                <div className="status-card__icon">
                    <MdIcons.MdOutlinePlaylistPlay/>
                </div>
                <div className="status-card__info">
                  <Link to='/DataGovernance/Projects/DataEngineering'>Data Engineering</Link>
                </div>
              </div>
        </div>
        <div className='col-6-SDR'>
              <div className='status-card'>
                <div className="status-card__icon">
                    <FaIcons.FaRegFileCode/>
                </div>
                <div className="status-card__info">
                  <Link to='/DataGovernance/Projects/DataScience'>Data Science</Link>
                </div>
              </div>
        </div>
      </section>
    </div>
      )
  }
