import ChunkBar from './ChunkBar';
import {useSelector} from 'react-redux';
import {UpdatesTable} from '../UpdatesTable/UpdatesTable';
import {selectlastUpdate} from '../../../../redux/ControlCenterReducer.js';
import './SecondaryDisplay.css';
import {KPIs} from '../slices/fifthSlice/KPIs.js';

export const SecondaryDisplay = ({expandChange, datasetId, openKPI}) => {

	let Data = useSelector(selectlastUpdate) || {
		extendedStatus: '',
		startTime: '',
		endTime: '',
		refreshType: '',
		requestId: '',
		statusArray: [],
	};
	const {statusArray} = Data;
	return (
		<div className="containerSecondaryDisplay">
			{openKPI ? (
				<KPIs />
			) : (
				<>
					<ChunkBar statuses={statusArray} data={Data} />
					<UpdatesTable expandChange={expandChange} datasetId={datasetId} />
				</>
			)}
		</div>
	);
};
