import { CampaignOutlined, Check } from "@mui/icons-material";
import { Permissions } from "../PublicacionReportes/RestrictedReport/Permisions";


export const BeforeSendInfo = async(toDo, Type) => {
    toDo['requestType'] = Type;
    
    try {

      let parsed = parseInt(toDo['associatedTicketNumber']);
      if (isNaN(parsed)) {
        toDo['associatedTicketNumber'] = 0;
      } else {
        toDo['associatedTicketNumber'] = parsed;
      }
    } catch (error) {     
      toDo['associatedTicketNumber']=0; 
    }
    if (toDo['requestType'] === 'solicitud de Reporte') {
        
        // let {Area, Roles, Subarea, Todo} = await Permissions(
        //     toDo['requestViewInf']
        // );
        toDo['destinationArea'] = {
            areaName: toDo['areas'],
            subArea: [],
        };
        if (toDo['isRestricted'] === true) {
            toDo['permissions'] = {Area:toDo['areas'], Roles:toDo['requestViewInf']};
        } else {
            toDo['permissions'] = {Area: [], Roles: []};
        }
        toDo['possibleRoute']=toDo['possibleRoute'].join('/');
        if(toDo['whenCheckBIteam'] !== ''){
            let date = new Date(toDo['whenCheckBIteam']);
            date.setHours(date.getHours() - 5);
            const isoDate = date.toISOString();
            toDo['whenCheckBIteam'] = isoDate;
        }
        delete toDo.areas;
    } else if (toDo['requestType'] === 'Incidencia') {
        toDo['destinationArea'] = {
            areaName: [toDo.areas],
            subArea: [toDo.subarea],
        };
        delete toDo.validatedwithBIteam;
        delete toDo.withwhomBIteam;
        delete toDo.whenCheckBIteam;
    } else if (toDo['requestType'] === 'Requerimiento') {
        toDo['destinationArea'] = {
            areaName: [toDo.areas],
            subArea: [toDo.subarea],
        };
        delete toDo.validatedwithBIteam;
        delete toDo.withwhomBIteam;
        delete toDo.whenCheckBIteam;
    }
    
    delete toDo.areas;
    delete toDo.subarea;
    toDo['requestor']={
      name:toDo['requestorName'],
      email:toDo['requestorEmail'],
      area:toDo['requestorArea'],
      jobTitle:toDo['requestorJobTitle'],
      other:toDo['requestorNameOther']
    }
    delete toDo['dateLastModified'];
    delete toDo['requestDate'];
    delete toDo['requestorName'];
    delete toDo['requestorEmail'];
    delete toDo['requestorArea'];
    delete toDo['requestorJobTitle'];
    delete toDo['requestorOther'];
    delete toDo['requestorNameOther'];
    return CheckIfAllfieldAreCorrect(toDo,Type);

}

const CheckIfAllfieldAreCorrect = (toDo,Type) => {
    let Incidentefields = {
        "requestor": {
          "name": "Lisette Garcia",
          "email": "garc28257@siigo.com",
          "area": "Customer Success",
          "jobTitle": "Customer Success Manager",
          "other": false,
          "nameOther": ""
        },
        "associatedTicketNumber": "",
        "priority": "Media",
        "region": "null",
        "title": "Incidente Prueba",
        "requestNeed": "Visualización",
        "requestProduct": "Pruebita3",
        "requestItem": "dataSmart4",
        "requestTool": "Databricksdsv5",
        "descriptionTask": "Prueba 6",
        "requestImpact": "Alto",
        "previousTickets": "No",
        "requestType": "Incidencia",
        "destinationArea": {
          "areaName": [
            "Sales"
          ],
          "subArea": [
            "Aliados"
          ]
        }
      }
    let Requestfields = {
        "requestor": {
          "name": "Lisette Garcia",
          "email": "garc28257@siigo.com",
          "area": "Customer Success",
          "jobTitle": "Customer Success Manager",
          "other": false,
          "nameOther": ""
        },
        "associatedTicketNumber": "",
        "priority": "Media",
        "region": "null",
        "title": "Requerimiento 2",
        "requestNeed": "Modelado de datos",
        "requestProduct": "Pruebita 3",
        "requestTool": "Databricksdsv 4",
        "descriptionTask": "Que pruebe el uso de formulario 5",
        "requestUse": "uso 6",
        "requestViewInf": [
          "MER-N7-28"
        ],
        "requestAutomatization": "No",
        "previousTickets": "No",
        "requestType": "Requerimiento",
        "destinationArea": {
          "areaName": [
            "Sales"
          ],
          "subArea": [
            "Aliados"
          ]
        }
      }
    let SolicitudReportefields = {
        "requestor": {
          "name": "Lisette Garcia",
          "email": "garc28257@siigo.com",
          "area": "Customer Success",
          "jobTitle": "Customer Success Manager",
          "other": false,
          "nameOther": ""
        },
        "schedule":"",
        "associatedTicketNumber": "",
        "previousTickets": "No",
        "isNewReport":false,
        "validatedwithBIteam": false,
        "withwhomBIteam": "",
        "whenCheckBIteam": "",
        "priority": "Media",
        "region": "null",
        "title": "Prueba solicitud de reportes",
        "reportName": "Pruebiña 3",
        "requestViewInf": [
          "Ecna Lisette Garcia Espinosa",
          "Owen Steven Bermudez Arias",
          "Camilo Andres Munoz Guerra"
        ],
        "requestUse": "objetivos 5",
        "reportFile": [
          {
            "extension": ".pbix",
            "name": "projectFinal.pbix",
            "size": 6893152,
            "status": 2,
            "progress": 0,
            "uid": "c17015c9-6bdb-4944-aae4-6683e3679a0a"
          }
        ],
        "isRestricted": "Si",
        "lineage": [
          {
            "baseDatos": "db_cost",
            "tabla": [
              {
                "baseDatos": "tbl_slv_loginsdatabricks"
              },
              {
                "baseDatos": "tbl_slv_consumedatabricks"
              }
            ]
          },
          {
            "baseDatos": "tbl_slv_consumedatabricks"
          },
          {
            "baseDatos": "tbl_slv_loginsdatabricks"
          }
        ],
        "keyword": [
          "6",
          "Customer"
        ],
        "possibleRoute": [
          "Reportes por País ",
          "Colombia"
        ],
        "requestImpact": "Alto",
        "typeOfdashboard": "Dashboard RSL",
        "requestType": "solicitud de Reporte",
        "destinationArea": {
          "areaName": [
            "Customer Success",
            "Marketing"
          ],
          "subArea": [
            null
          ]
        },
        "permissions": {
          "Area": [
            "Customer Success",
            "Marketing"
          ],
          "Roles": [
            "Ops Tech Lead",
            "Gestor de Renovaciones",
            "Commercial Planning Manager Latam"
          ]
        }
      }
      let fields={
        'Incidencia':Incidentefields,
        'Requerimiento':Requestfields,
        'solicitud de Reporte':SolicitudReportefields
      }
    let response={}
    //console.log(Object.keys(fields[Type]))
    Object.keys(fields[Type]).forEach((field) => {
        response[field] = toDo[field];
    })
    //console.log(Object.keys(response))
    return response;
}

