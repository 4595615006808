import {ListView} from '@progress/kendo-react-listview';
import {Avatar} from '@progress/kendo-react-layout';
import { Loader} from '@progress/kendo-react-indicators';
import {useState} from 'react';
import { Badge } from 'react-bootstrap';
import './ListViewMyRequests.css';
import { DialogComponent } from '../../Telerik/Dialog';
import DescriptionRequest from '../../../../components/helpDesk/DescriptionRequest';

const MyItemRender = (props) => {
	const [visibility, setVisibility] = useState(false);
	let item = props.dataItem;

	const BadgePersonalize = ({item}) => {
		let estatusValue;
		
		if (item.status && item.status.currently) {
			estatusValue = item.status.currently;
		} else {
			estatusValue = item.status || 'Indefinido'; // Si item.status es falsy, usa 'Indefinido'
		}
	
		let color;
		switch (estatusValue) {
			case 'Cerrado':
				color = 'secondary';
				break;
			case 'Nuevo':
				color = 'primary';
				break;
			case 'Asignado':
				color = 'warning';
				break;
			case 'Proceso':
				color = 'success';
				break;
			case 'Revisión':
				color = 'danger';
				break;
			case 'Aprobación':
				color = 'info';
				break;
			case 'Espera':
				color = 'info';
				break;
			default:
				color = 'default'; 
		}
	
		return <Badge bg={color}>{estatusValue}</Badge>;
	}
	

	return (
		<div
			className="k-listview-item row p-2 border-bottom align-middle"
			style={{
				margin: 0,
			}}>
			{visibility && (

				<DialogComponent visibility={visibility} setVisibility={setVisibility} title={`Resumen de Ticket No ${item.ticketId}`}>
				<DescriptionRequest id={item.ticketId} page={1} />
			</DialogComponent>
			
			)}
			<div className="col-1">
				<Avatar
					type="text"
					style={{
						padding: 22,backgroundColor: '#f0f0f0',color: '#454545',fontSize: 12,fontWeight: 'bold',
					}}>
					<br />
					<p>{item.ticketId}</p>
				</Avatar>
			</div>
			<div className="col-7">
				<h2
					style={{
						fontSize: 14,
						color: '#454545',
						marginBottom: 0,
					}}
					className="text-uppercase">
					{item.title}
				</h2>
				<div
					style={{
						fontSize: 12,
						color: '#a0a0a0',
					}}>
					{item.descriptionTask}
				</div>
			</div>
			<div className="col-2">
				<BadgePersonalize item={item}/>
			</div>
			<div className="col-2">
				<div className="k-button k-button-md k-button-outline k-button-outline-inverse k-rounded-md">
					<div
						className="k-chip-content"
						onClick={() => {
							setVisibility(true);
						}}>
						Ver más
					</div>
				</div>
			</div>
		</div>
	);
};
export const ListViewMyRequests = ({bandera, jTickets, titulo}) => {
	if (bandera) {
		return (
			<Loader
				style={{
					position: 'absolute',
					left: '50%',
					transform: 'translate(-50%,-50%)',
				}}
				size="large"
				type={'converging-spinner'}
			/>
		);
	}
	// jTickets.unshift({
	// 	ticketId: 'Numero de Ticket',
	// 	title: 'Titulo',
	// 	descriptionTask: 'Descripcion',
	// 	status: 'Estatus',
	// 	actions: 'Acciones',
	// });

	return (
		<div style={{maxHeight:'700px',overflowY:'scroll'}} className='container-My-Request'>
			<p style={{color: '#ABABAB',marginLeft:'1rem'}}>{titulo}</p>
			<ListView
				data={jTickets}
				item={MyItemRender}
				style={{
					width: '100%',
					margin: 'auto',
				}}
			/>
		</div>
	);
};
