import React, { useEffect, useState } from 'react';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import Tablero from '../Tablero';
import TableroMx from '../TableroMx';
import TableroECU from '../TableroECU';
import TableroURU from '../TableroURU';
import { Button } from '@mui/material';
import { OptionSelection } from './OptionSelection';
import { readData } from './../../services/db'
import { useSelector, useDispatch } from 'react-redux';
import { useUserService } from './../../../services/userService'
import { setDynamicInputsData, selectDynamicInputsData } from '../../../../../../redux/renewalsTreeReducer'
import axios from 'axios';
import NotasMx from './NotasMx';

export const GeneralCase = ({ currentStep, formData, handleOptionChange, reset, randomImage, goToCardById, selectionHistory, navigateTo, updateLoading, fixedCards }) => {
    const dispatch = useDispatch();
    const { userProfile } = useUserService();
    const [currentStepData, setCurrentStepData] = React.useState(undefined);
    const [hasNotes, setHasNotes] = React.useState(undefined);
    const [hasTablero, setHasTablero] = React.useState(undefined);
    const [hasTableroMx, setHasTableroMx] = React.useState(undefined);
    const [hasTableroECU, setHasTableroECU] = React.useState(undefined);
    const [hasTableroURU, setHasTableroURU] = React.useState(undefined);
    const [hasInputs, setHasInputs] = React.useState(undefined);
    const [hasOptions, setHasOptions] = React.useState(undefined);
    const dynamicInputsData = useSelector(selectDynamicInputsData);
    const [showResume, setShowResume] = useState(false)
    const [resumeContent, setResumeContent] = useState(null)
    const [savingData, setSavingData] = useState(false)
    const [selectedCountryValue, setSelectedCounrtyValue] = useState("")
    const [mexicoSavedData, setMexicoSavedData] = useState([])
    const [IVA, setIVA] = useState(undefined)

    useEffect(() => {
        setDynamicInputsData({
            ...dynamicInputsData,
            "user": userProfile.email
        })
        let selectedCountry = localStorage.getItem("selectedCountryName")
        setSelectedCounrtyValue(selectedCountry)
    }, [])

    const renderSettings = [
        {
            id: "notasGenerales",
            renderType: "richText",
            className: "renewals-tree-notas",
            label: "Notas Generales:",
            colSpan: 5
        }, {
            id: "actionsSinergy",
            renderType: "richText",
            className: "renewals-tree-notas",
            label: "Acciones en Synergy:",
            colSpan: 5
        }, {
            id: "actionsFive9",
            renderType: "richText",
            className: "renewals-tree-notas",
            label: "Acciones en Five9:",
            colSpan: 5
        },
    ]

    const formatDataMx = (data) => {
        console.log("Generate Notas")
    }

    const formatData = (data) => {
        const tab = '    ';
        // Helper function to format payment details
        const formatPayments = (payments,cuotas,type) => {
            let formatted = [];
            if (cuotas) {
                cuotas.forEach((payment, index) => {
                    if (cuotas[0] != 0) {
                        let subTotal = payments.reduce((acc, curr) => {
                            let value = parseFloat(curr[0].toString().split(".").join(""));
                            return acc + value;
                        }, 0);
    
                        let ivaTotal = payments.reduce((acc, curr) => {
                            let hasIVA = curr[1];
                            let value = parseFloat(curr[0].toString().split(".").join(""));
                            let iva = hasIVA ? value * (IVA / 100) : 0;
                            return acc + iva;
                        }, 0);
                        let paymentAmount = (((parseFloat(subTotal) + parseFloat(ivaTotal)) * parseFloat(payment[0] / 100))
                            .toFixed()).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        formatted.push(`${tab}Cuota ${index + 1}: ${payment[0] || ''}% - $${paymentAmount} (${payment[1] || ''})`);
                    }
                });
            }
            return formatted;
        };
    
        // Helper function to format the common data
        const formatCommonData = (tablero) => {
            let formatted = [
                `Fecha de Gestión: ${tablero.fechaGestion || ''}`,
                'Productos:'
            ];
    
            if (tablero.pagos) {
                tablero.pagos.forEach((pago, index) => {
                    if (pago[0] != 0) {
                        formatted.push(`${tab}Producto ${index + 1}: $${pago[0] || ''}`);
                    }
                });
            }
    
            formatted.push(
                `Tipo de Gestión: ${tablero.tipoGestion2 || ''}`
            );
            if (tablero.tipoGestion2 === "Descuento") {
                formatted.push(`${tab}Porcentaje: ${tablero.descuento || ''}%`);
            }
            if (tablero.tipoGestion2 === "Cuotas" && tablero.cuotas) {
                formatted.push('Cuotas:');
                formatted = formatted.concat(formatPayments(tablero.pagos, tablero.cuotas, tablero.tipoGestion2));
            }
    
            formatted.push(
                `Fecha de Compromiso: ${tablero.fechaCompromiso || ''}`,
                `Fecha de Compromiso Extendido: ${tablero.fechaCompromisoExtendido || ''}`,
                `Aplica Nota de Crédito: ${tablero.aplicaNC || ''}`
            );
    
            if (tablero.aplicaNC === "Si" && tablero.tipoNC) {
                formatted.push(
                    `${tab}${tab}Tipo de Nota de Crédito: ${tablero.tipoNC || ''}`,
                    `${tab}${tab}Motivo de Nota de Crédito: ${tablero.motivoNC || ''}`,
                    `${tab}${tab}Valor de Nota de Crédito: ${tablero.valorNC || ''}`,
                    `${tab}${tab}ID de Nota de Crédito: ${tablero.idNotaCredito || ''}`
                );
            }
    
            formatted.push(
                `${tab}${tab}Notas: ${tablero.notas || ''}`,
                `${tab}${tab}Total de Pago: ${tablero.totalPayment || tablero.totalPaymentURU || tablero.totalPaymentECU || ''}`
            );
    
            return formatted;
        };
    
        // Build the formatted data
        let formattedData = [
            'Información del Usuario:',
            `${tab}Usuario: ${data["Nombre del cliente"] || data.user ||''}`,
            `${tab}ID Entidad: ${data.NIT || data.RUC || data.RUT || ''}`,
            `${tab}Contacto: ${data.Contacto || data['Nombre de Contacto'] || ''}`,
            `${tab}Cargo: ${data.Cargo || ''}`,
            `${tab}Teléfono: ${data.Telefono || ''}`
        ];

        switch (selectedCountryValue) {
            case "ecuador":
                if (data.tableroECU) {
                    formattedData = formattedData.concat(formatCommonData(data.tableroECU));
                }
                break;
            case "uruguay":
                if (data.tableroURU) {
                    formattedData = formattedData.concat(formatCommonData(data.tableroURU));
                }
                break;
            default:
                if (data.tablero) {
                    formattedData = formattedData.concat(formatCommonData(data.tablero));
                }
                break;
        }
    
        return formattedData.join('\n');
    };

    const closeResumeView = () => {
        setResumeContent('')
        setShowResume(false)
        reset();
    }

    const checkIfHtmlIsEmpty = (html) => {
        const withoutEmptyTags = html.replace(/<[^\/>]*>\s*<\/[^>]+>|<[^>]+>\s*<\/[^>]+>/g, '');
        const content = withoutEmptyTags.replace(/\s/g, '');
        return content.length === 0;
    }

    useEffect(() => {
        const tempCurrentStepData = formData[currentStep].data;

        let tempHasNotes = renderSettings.find((setting) => {
            return !checkIfHtmlIsEmpty(tempCurrentStepData[setting.id])
        });

        setCurrentStepData(tempCurrentStepData);
        setHasNotes(tempHasNotes);
        setHasTablero(tempCurrentStepData.Tablero == true)
        setHasTableroMx(tempCurrentStepData.TableroMX == true)
        setHasTableroECU(tempCurrentStepData.TableroECU == true)
        setHasTableroURU(tempCurrentStepData.TableroURU == true)
        setHasInputs(tempCurrentStepData.inputs.length != 0);
        setHasOptions(tempCurrentStepData.options != undefined && tempCurrentStepData.options.length != 0);

        if (hasInputs) {
            let tempInputs = {};
            tempCurrentStepData.inputs.map((input) => {
                tempInputs[input.name] = "";
            })
            dispatch(setDynamicInputsData({ ...dynamicInputsData, ...tempInputs }));
        }
    }, [currentStep])

    const validateInput = (inputToValidate) => {
        if (inputToValidate === null || inputToValidate === undefined || inputToValidate === '') {
            return false; // Invalid input
        } else {
            return true; // Valid input
        }

    }
    const validateInputs = () => {
        let issues = [];
        let shouldDisable = false;
        const inputs = formData[currentStep].data.inputs;
        const { tablero, tableroMx, tableroECU, tableroURU } = dynamicInputsData;
        // Helper function to add issues
        const addIssue = (message) => {
            issues.push(message);
            shouldDisable = true;
        };
    
        // Validate each input
        inputs.forEach(el => {
            let inputValue = dynamicInputsData[el.name];    
            if (!validateInput(inputValue)) {
                addIssue(`Input value wrong: ${el.name}`);
            }
            if (el.name.toLowerCase() === "telefono") {
                if (inputValue === undefined || inputValue === null || inputValue.trim() === "") {
                    addIssue("Telefono is required");
                } else if (inputValue.length < 7 || inputValue.length > 15) {
                    addIssue("Telefono must be between 7 and 15 characters");
                }
            }
        });
        // Validate tablero-related fields
        if (hasTablero) {
            if (!tablero) {
                addIssue("Tablero vacío");
            } else if (tablero.tipoGestion === "") {
                addIssue("Tipo de gestión vacío");
            }
        }
        // Validate tableroMx for Mexico
        if (selectedCountryValue === "mexico") {
            if (!tableroMx) {
                addIssue("TableroMx vacío");
            } else {
                if (!tableroMx.formcontrolBySerial) {
                    addIssue("FormcontrolBySerial vacío");
                } else if (Object.keys(tableroMx.formcontrolBySerial).length === 0) {
                    addIssue("FormcontrolBySerial vacío");
                }
                if (tableroMx.rol === "" && tableroMx["tipo de gestion"] === "") {
                    addIssue("Rol y tipo de gestión vacío");
                }
            }
        }
        // Validate tableroECU for Ecuador
        if (selectedCountryValue === "ecuador") {
            if (!tableroECU) {
                addIssue("TableroECU vacío");
            } else if (tableroECU.tipoGestion === "") {
                addIssue("Tipo de gestión Ecuador vacío");
            }
        } else if (selectedCountryValue === "uruguay") {
            // Validate TableroURU for Uruguay
            if (!tableroURU) {
                addIssue("TableroURU vacío");
            } else if (tableroURU.tipoGestion === "") {
                addIssue("Tipo de gestión vacío");
            }
        }
        console.log(issues)
        return shouldDisable;
    };
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    

    const searchNit = () => {
        axios.get(`/api/siignalGetconfig`).then((res)=>{
            setIVA(parseInt(res.data[0][0][capitalizeFirstLetter(selectedCountryValue)].iva))
        })
        if (currentStepData.key == fixedCards.colombia) {
            let nitToSearch = dynamicInputsData.NIT.trim()
            axios.get(`/api/siignalGetPaymentPromise/${nitToSearch}`).then((res) => {
                let paymentPromise = res.data;
                if (paymentPromise.length == 0) {
                    readData('SiignalRenewals', "renewalsNitInfo").then((localData) => {
                        let nitsList = localData.data[0].baseRenovaciones;
                        let searchResult = nitsList.find((nit) => {
                            return nit.NIT == nitToSearch;
                        })
                        let userMail = userProfile.email
                        if (userMail != null) {
                            if (searchResult == undefined) {
                                searchResult = {
                                    NIT: nitToSearch,
                                    Instancia: "Sin Factura"
                                }
                                dispatch(setDynamicInputsData({ ...dynamicInputsData, listSearchResutl: searchResult, "user": userMail }));
                                goToCardById('ro07kc5jywf05b', "Cliente Sin Factura");
                            } else {
                                dispatch(setDynamicInputsData({ ...dynamicInputsData, listSearchResutl: searchResult, "user": userMail }));
                                if ((searchResult.AREA).toLowerCase().trim() == "ventas nuevas") {
                                    goToCardById('b4hsw0jeu55tuu', "Ventas Nuevas");
                                } else {
                                    goToCardById('c0fkgzeyefico5', "Renovaciones");
                                }
                            }
                        } else {
                            updateLoading("add", "Se ha presentado un error, ingresa de nuevo (COD:USREMAIL)");
                        }
                    }).catch((error) => {
                        alert("Error al consultar NIT, reportalo con tu lider: " + error);
                    })
                } else {
                    let promiseOwner = paymentPromise[0].dynamicInputsData.user
                    alert(`El NIT ya tiene una promesa de pago registrada por ${promiseOwner}`)
                }
            }).catch((error) => {
                console.log(error)
            })
        }

        if (currentStepData.key == fixedCards.ecuador) {
            let nitToSearch = dynamicInputsData.RUC.trim()
            axios.get(`/api/siignalGetBaseRenovacionesEcu/${nitToSearch}`).then((res) => {
                let paymentPromise = res.data.promesas;
                if (paymentPromise.length == 0) {
                    let searchResult = res.data.base;
                    let userMail = userProfile.email
                    if (userMail != null) {
                        if (searchResult.length == 0) {
                            searchResult = {
                                RUC: nitToSearch,
                                Instancia: "No está en base"
                            }

                            dispatch(setDynamicInputsData({ ...dynamicInputsData, listSearchResutl: searchResult, "user": userMail }));
                            goToCardById('rac5vq0g8amolq', "Cliente Sin Factura");
                        } else {
                            dispatch(setDynamicInputsData({ ...dynamicInputsData, listSearchResutl: searchResult, "user": userMail }));
                            goToCardById('r85adj1qkfr1wa', "Renovaciones ECU");
                        }
                    } else {
                        updateLoading("add", "Se ha presentado un error, ingresa de nuevo (COD:USREMAIL)");
                    }
                } else {
                    let promiseOwner = paymentPromise[0].Data.user
                    alert(`El RUC ya tiene una promesa de pago registrada por ${promiseOwner}`)
                }
            }).catch((error) => {
                console.log(error)
            })
        }
        if (currentStepData.key == fixedCards.uruguay) {
            let nitToSearch = dynamicInputsData.RUT.trim()
            axios.get(`/api/siignalGetBaseRenovacionesUru/${nitToSearch}`).then((res) => {
                let paymentPromise = res.data.promesas;
                if (paymentPromise.length == 0) {
                    let searchResult = res.data.base;
                    let userMail = userProfile.email
                    if (userMail != null) {
                        if (searchResult.length == 0) {
                            searchResult = {
                                RUT: nitToSearch,
                                Instancia: "No está en base"
                            }
                            dispatch(setDynamicInputsData({ ...dynamicInputsData, listSearchResutl: searchResult, "user": userMail }))
                            goToCardById('rac5vq0g8amolq', "No registrado en base");
                        } else {
                            dispatch(setDynamicInputsData({ ...dynamicInputsData, listSearchResutl: searchResult, "user": userMail }));
                            goToCardById('bsulgst86xatx4', "Renovaciones URU");
                        }
                    } else {
                        updateLoading("add", "Se ha presentado un error, ingresa de nuevo (COD:USREMAIL)");
                    }
                } else {
                    let promiseOwner = paymentPromise[0].Data.user
                    alert(`El RUT ya tiene una promesa de pago registrada por ${promiseOwner}`)
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    }
    const parseValue = (value) => parseFloat(value.toString().replace(/\./g, ""));
    
    const calculatePagoTotal = () => {
        let tempData = { ...dynamicInputsData };
        const formData = tempData.tablero || tempData.tableroURU || tempData.tableroECU;
        if (!formData) {
            return dynamicInputsData; // Return original data if formData is undefined
        }
        // Calculate subtotal
        const subTotal = formData.pagos.reduce((acc, [amount]) => acc + parseValue(amount), 0);
        // Calculate IVA total
        const ivaTotal = formData.pagos.reduce((acc, [amount, hasIVA]) => {
            return acc + (hasIVA ? parseValue(amount) * (IVA/100) : 0);
        }, 0);
        // Calculate total discounted
        const totalDescontado = (subTotal * (formData.descuento / 100)).toFixed(2);
        // Calculate IVA on discounted total
        const iva = (parseFloat(totalDescontado) * (IVA/100)).toFixed(2);
        // Calculate total with IVA
        const totalConIva = (parseFloat(totalDescontado) + parseFloat(iva)).toFixed(2);
        // Determine payment based on tipoGestion2
        let payment;
        switch (formData.tipoGestion2) {
            case 'Cuotas':
                payment = (subTotal + ivaTotal) * (parseFloat(formData.cuotas[0][0]) / 100);
                break;
            case 'Descuento':
                if (selectedCountryValue === "colombia") {
                    payment = (subTotal * (1 - formData.descuento / 100)) + ivaTotal;
                } else if (selectedCountryValue === "ecuador" || selectedCountryValue === "uruguay") {
                    payment = (subTotal + ivaTotal) - totalConIva;
                }
                break;
            default:
                payment = subTotal + ivaTotal;
                break;
        }
        return {
            ...tempData,
            tablero: {
                ...formData,
                totalPayment: payment
            },
            tableroECU: {
                ...formData,
                totalPaymentECU: payment,
            },
            tableroURU: {
                ...formData,
                totalPaymentURU: payment
            }
        };
    };
    

    return (
        (currentStepData != undefined) ? <>
            <GridLayout
                className='renewals-tree-card-container generalCase'
                style={{ margin: '10px' }}
                align={{ horizontal: 'center', }}
                cols={[{ width: "10%" }, { width: "10%" }, { width: "10%" }, { width: "10%" }, { width: "10%" }, { width: "10%" }, { width: "10%" }, { width: "10%" }, { width: "10%" }, { width: "7%" }]}
                gap={{ rows: 5, cols: 5, }}>

                {/* TITULO */}
                <GridLayoutItem className={`siignal-w100`} colSpan={10}>
                    <GridLayout
                        style={{ margin: '10px' }}
                        cols={[{ width: "100px" }, { width: "auto" }, { width: "100px" }]}
                        rows={[{ height: "100px" }]}
                        gap={{ rows: 5, cols: 0, }}>

                        <GridLayoutItem>
                            <img className={`renewals-tree-title-img`} src={randomImage.image} />
                        </GridLayoutItem>
                        <GridLayoutItem>
                            <div className={`renewals-tree-title renewals-font-${randomImage.color}`}>
                                {currentStepData.title}
                            </div>
                            <div className={`renewals-tree-instance renewals-font-${randomImage.color}`}>
                                {
                                    (dynamicInputsData.listSearchResutl != undefined &&
                                        dynamicInputsData.listSearchResutl.NIT != undefined &&
                                        dynamicInputsData.listSearchResutl.AREA != undefined) ? <>
                                        El NIT {
                                            dynamicInputsData.listSearchResutl.NIT
                                        } corresponde al area {
                                            dynamicInputsData.listSearchResutl.AREA
                                        }
                                    </> : null
                                }
                            </div>
                        </GridLayoutItem>
                        <GridLayoutItem>
                            <img className={`renewals-tree-title-img`} src={randomImage.image} />
                        </GridLayoutItem>
                    </GridLayout>
                </GridLayoutItem>

                {/* NOTAS */}
                {
                    (hasNotes != undefined) ? <>
                        <GridLayoutItem className={`siignal-w100`} colSpan={(hasInputs || hasTablero) ? 3 : 10}>
                            <GridLayout
                                align={{ horizontal: 'center', }}
                                gap={{ rows: 5, cols: 5 }}>
                                {
                                    renderSettings.map((setting) => {
                                        if (setting.renderType == "richText" && !checkIfHtmlIsEmpty(currentStepData[setting.id])) {
                                            return (
                                                <GridLayoutItem
                                                    className={`siignal-box-shadow renewals-border-${randomImage.color}`}
                                                    colSpan={setting.colSpan}
                                                    key={`renewals-notes${setting.id}`}>
                                                    <span className={`renewals-tree-card-title renewals-font-${randomImage.color}`}>{setting.label}</span>
                                                    <hr className={`renewals-border-${randomImage.color}`} />
                                                    <div className='renewals-tree-html-container' dangerouslySetInnerHTML={{ __html: currentStepData[setting.id] }}></div>
                                                </GridLayoutItem>
                                            )
                                        }
                                    })
                                }
                            </GridLayout>
                        </GridLayoutItem>
                    </> : null
                }
                {/* INPUTS | TABLERO */}
                {
                    (hasInputs || hasTablero || hasTableroMx || hasTableroECU || hasTableroURU) ?
                        <GridLayoutItem className={`siignal-w100`} colSpan={(hasNotes) ? 7 : 10}>
                            {
                                (hasInputs) ? <>
                                    <GridLayoutItem
                                        key={`renewals-inputs${currentStep}`}
                                        className={`siignal-box-shadow renewals-border-${randomImage.color}`}
                                        align={{ horizontal: 'center', }}
                                        cols={[{ width: "auto" }, { width: "auto" }]}
                                        gap={{ rows: 5, cols: 5, }}>
                                        {
                                            currentStepData.inputs.map((input, index) => {
                                                return (
                                                    <React.Fragment key={`dynamicInputs${index}`}>
                                                        <label className='siignal-w100'>{input.name}</label>
                                                        {
                                                            input.name.toLowerCase() == "cargo" ? <>
                                                                <select
                                                                    className='siignal-w100'
                                                                    placeholder={input.name}
                                                                    onChange={(e) => {
                                                                        const newValue = e.target.value;
                                                                        dispatch(setDynamicInputsData({ ...dynamicInputsData, [input.name]: newValue }));
                                                                        let customFunction = new Function(input.onChangeFunction);
                                                                        customFunction();
                                                                    }}
                                                                    style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '5px' }}
                                                                    name={input.name}
                                                                    value={dynamicInputsData[input.name] || ''}>
                                                                    <option value="">Seleccione una opcion</option>
                                                                    <option value="Auxiliar">Auxiliar</option>
                                                                    <option value="Contador">Contador</option>
                                                                    <option value="Representante">Representante Legal</option>
                                                                    <option value="Gerente">Gerente</option>
                                                                    <option value="Otro">Otro</option>
                                                                </select>
                                                            </> : <>
                                                                <input
                                                                    className='siignal-w100'
                                                                    placeholder={input.name}
                                                                    onChange={(e) => {
                                                                        const newValue = (input.name == "NIT") ? e.target.value.replace(/\s/g, '') : e.target.value;
                                                                        dispatch(setDynamicInputsData({ ...dynamicInputsData, [input.name]: newValue }));
                                                                        let customFunction = new Function(input.onChangeFunction);
                                                                        customFunction();
                                                                    }}
                                                                    style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '5px' }}
                                                                    name={input.name}
                                                                    value={dynamicInputsData[input.name] || ''}
                                                                />
                                                            </>
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </GridLayoutItem>
                                </> : null
                            }
                            {
                                (hasTablero) ? <>
                                    <GridLayoutItem
                                        className={`siignal-box-shadow renewals-border-${randomImage.color}`}
                                        style={{ marginTop: '5px' }}>
                                        <Tablero
                                            currentStep={currentStep}
                                            formData={formData}
                                            handleOptionChange={handleOptionChange}
                                            reset={reset}
                                            randomImage={randomImage}
                                            goToCardById={goToCardById}
                                            selectionHistory={selectionHistory}
                                            navigateTo={navigateTo} />
                                    </GridLayoutItem>
                                </> : null
                            }
                            {
                                (hasTableroMx) ? <>
                                    <GridLayoutItem
                                        className={`siignal-box-shadow renewals-border-${randomImage.color}`}
                                        style={{ marginTop: '5px' }}>
                                        <TableroMx
                                            currentStep={currentStep}
                                            formData={formData}
                                            handleOptionChange={handleOptionChange}
                                            reset={reset}
                                            randomImage={randomImage}
                                            goToCardById={goToCardById}
                                            selectionHistory={selectionHistory}
                                            navigateTo={navigateTo} />
                                    </GridLayoutItem>
                                </> : null
                            }
                            {
                                (hasTableroECU) ? <>
                                    <GridLayoutItem
                                        className={`siignal-box-shadow renewals-border-${randomImage.color}`}
                                        style={{ marginTop: '5px' }}>
                                        <TableroECU
                                            currentStep={currentStep}
                                            formData={formData}
                                            handleOptionChange={handleOptionChange}
                                            reset={reset}
                                            randomImage={randomImage}
                                            goToCardById={goToCardById}
                                            selectionHistory={selectionHistory}
                                            navigateTo={navigateTo} />
                                    </GridLayoutItem>
                                </> : null
                            }
                            {
                                (hasTableroURU) ? <>
                                    <GridLayoutItem
                                        className={`siignal-box-shadow renewals-border-${randomImage.color}`}
                                        style={{ marginTop: '5px' }}>
                                        <TableroURU
                                            currentStep={currentStep}
                                            formData={formData}
                                            handleOptionChange={handleOptionChange}
                                            reset={reset}
                                            randomImage={randomImage}
                                            goToCardById={goToCardById}
                                            selectionHistory={selectionHistory}
                                            navigateTo={navigateTo} />
                                    </GridLayoutItem>
                                </> : null
                            }
                        </GridLayoutItem> : null
                }
                {
                    hasOptions != 0 ?
                        <GridLayoutItem className={`siignal-w100`} colSpan={10}>
                            <OptionSelection
                                currentStep={currentStep}
                                formData={formData}
                                handleOptionChange={handleOptionChange}
                                reset={reset}
                                randomImage={randomImage} />
                        </GridLayoutItem>
                        : null
                }
                <GridLayoutItem className={`siignal-w100 siignal-buttons-area`} colSpan={10}>
                    {
                        currentStepData.Finalizar == true && !savingData ? <>
                            <Button
                                variant="contained"
                                color="success"
                                disabled={validateInputs()}
                                sx={{ mt: 2, mb: 2 }}
                                onClick={() => {
                                    setSavingData(true)
                                    let dynamicInputsData = calculatePagoTotal();
                                    let selectedCountry = localStorage.getItem("selectedCountryName")
                                    setSelectedCounrtyValue(selectedCountry)
                                    if (selectedCountry == 'mexico') {
                                        if (dynamicInputsData.tableroMx == undefined) {
                                            return;
                                        }
                                        let seriales = dynamicInputsData.tableroMx.formcontrolBySerial
                                        let recordsToSave = []
                                        for (let serial of Object.keys(seriales)) {
                                            recordsToSave.push({
                                                user: dynamicInputsData.user,
                                                nombreCliente: dynamicInputsData["Nombre del cliente"],
                                                cargo: dynamicInputsData.Cargo,
                                                contacto: dynamicInputsData.Contacto,
                                                valor: dynamicInputsData.Valor,
                                                status: "Pendiente",
                                                ...seriales[serial]
                                            })
                                        }
                                        setMexicoSavedData(recordsToSave)
                                        axios.post("/api/processTreeRenewalsSaveFormRegistryMx", {
                                            recordsToSave
                                        }).then((response) => {
                                            setResumeContent(formatDataMx(dynamicInputsData))
                                            setShowResume(true);
                                            setDynamicInputsData({ ...dynamicInputsData, listSearchResutl: [], rfcSearch: null })
                                            setSavingData(false)
                                        }).catch((error) => {
                                            setSavingData(false)
                                            alert("Error al guardar registro, reportalo con tu lider");
                                            console.info(error);
                                        })

                                    } else if(selectedCountry == 'colombia'){
                                        axios.post("/api/processTreeRenewalsSaveFormRegistry", {
                                            dynamicInputsData
                                        }).then((response) => {
                                            setResumeContent(formatData(dynamicInputsData))
                                            setShowResume(true);
                                            setDynamicInputsData({ ...dynamicInputsData, listSearchResutl: [] })
                                            setSavingData(false)
                                        }).catch((error) => {
                                            setSavingData(false)
                                            alert("Error al guardar registro, reportalo con tu lider");
                                            console.info(error);
                                        })
                                    } else if(selectedCountry == 'ecuador'){
                                        if (dynamicInputsData.tableroECU == undefined) {
                                            return;
                                        }
                                        let recordsToSave = []
                                        recordsToSave.push({
                                            RUC: dynamicInputsData.RUC,
                                            user: dynamicInputsData.user,
                                            nombreCliente: dynamicInputsData["Nombre de Contacto"],
                                            cargo: dynamicInputsData.Cargo,
                                            contacto: dynamicInputsData.Telefono,
                                            fechaGestion: dynamicInputsData.tableroECU.fechaGestion,
                                            tipoGestion: dynamicInputsData.tableroECU.tipoGestion,
                                            tipoGestion2: dynamicInputsData.tableroECU.tipoGestion2,
                                            valor: dynamicInputsData.tableroECU.valor,
                                            fechaCompromiso: dynamicInputsData.tableroECU.fechaCompromiso,
                                            fechaCompromisoExtendido: dynamicInputsData.tableroECU.fechaCompromisoExtendido,
                                            descuento: dynamicInputsData.tableroECU.descuento,
                                            cuotas: dynamicInputsData.tableroECU.cuotas,
                                            terceraCuotaPorLider: dynamicInputsData.tableroECU.terceraCuotaPorLider,
                                            aplicaNC: dynamicInputsData.tableroECU.aplicaNC,
                                            tipoNC: dynamicInputsData.tableroECU.tipoNC,
                                            motivoNC: dynamicInputsData.tableroECU.motivoNC,
                                            valorNC: dynamicInputsData.tableroECU.valorNC,
                                            notas: dynamicInputsData.tableroECU.notas,
                                            idNotaCredito: dynamicInputsData.tableroECU.idNotaCredito,
                                            estado: dynamicInputsData.tableroECU.estado,
                                            totalPayment: dynamicInputsData.tableroECU.totalPaymentECU,
                                            Area: dynamicInputsData.listSearchResutl.AREA,
                                            Descuento: dynamicInputsData.listSearchResutl.DESC,
                                            Instancia: dynamicInputsData.listSearchResutl.INSTANCIA,
                                            Periodicidad: dynamicInputsData.listSearchResutl.PERIODICIDAD,
                                            Churn: dynamicInputsData.listSearchResutl[0].CHURN
                                        })
                                        axios.post("/api/processTreeRenewalsSaveFormRegistryECU", {
                                            recordsToSave
                                        }).then(() => {
                                            setResumeContent(formatData(dynamicInputsData))
                                            setShowResume(true);
                                            setDynamicInputsData({ ...dynamicInputsData, listSearchResutl: [] })
                                            setSavingData(false)
                                        }).catch((error) => {
                                            setSavingData(false)
                                            alert("Error al guardar registro, reportalo con tu lider");
                                            console.info(error);
                                        })
                                    } else if(selectedCountry == 'uruguay'){
                                        if (dynamicInputsData.tableroURU == undefined) {
                                            return;
                                        }
                                        console.log()
                                        let recordsToSave = []
                                        recordsToSave.push({
                                            RUT: dynamicInputsData.RUT,
                                            user: dynamicInputsData.user,
                                            nombreCliente: dynamicInputsData["Nombre de Contacto"],
                                            cargo: dynamicInputsData.Cargo,
                                            contacto: dynamicInputsData.Telefono,
                                            fechaGestion: dynamicInputsData.tableroURU.fechaGestion,
                                            tipoGestion: dynamicInputsData.tableroURU.tipoGestion,
                                            tipoGestion2: dynamicInputsData.tableroURU.tipoGestion2,
                                            valor: dynamicInputsData.tableroURU.valor,
                                            fechaCompromiso: dynamicInputsData.tableroURU.fechaCompromiso,
                                            fechaCompromisoExtendido: dynamicInputsData.tableroURU.fechaCompromisoExtendido,
                                            descuento: dynamicInputsData.tableroURU.descuento,
                                            cuotas: dynamicInputsData.tableroURU.cuotas,
                                            terceraCuotaPorLider: dynamicInputsData.tableroURU.terceraCuotaPorLider,
                                            aplicaNC: dynamicInputsData.tableroURU.aplicaNC,
                                            tipoNC: dynamicInputsData.tableroURU.tipoNC,
                                            motivoNC: dynamicInputsData.tableroURU.motivoNC,
                                            valorNC: dynamicInputsData.tableroURU.valorNC,
                                            notas: dynamicInputsData.tableroURU.notas,
                                            idNotaCredito: dynamicInputsData.tableroURU.idNotaCredito,
                                            estado: dynamicInputsData.tableroURU.estado,
                                            totalPayment: dynamicInputsData.tableroURU.totalPaymentURU,
                                            Area: dynamicInputsData.listSearchResutl[0].AREA,
                                            Descuento: dynamicInputsData.listSearchResutl[0].DESC,
                                            Instancia: dynamicInputsData.listSearchResutl[0].INSTANCIA,
                                            Periodicidad: dynamicInputsData.listSearchResutl[0].PERIODICIDAD,
                                            Churn: dynamicInputsData.listSearchResutl[0].CHURN
                                        })
                                        axios.post("/api/processTreeRenewalsSaveFormRegistryURU", {
                                            recordsToSave
                                        }).then((response) => {
                                            setResumeContent(formatData(dynamicInputsData))
                                            setShowResume(true);
                                            setDynamicInputsData({ ...dynamicInputsData, listSearchResutl: [] })
                                            setSavingData(false)
                                        }).catch((error) => {
                                            setSavingData(false)
                                            alert("Error al guardar registro, reportalo con tu lider");
                                            console.info(error);
                                        })
                                    }
                                }}
                            >
                                Guardar
                            </Button>
                        </> : null
                    }
                    {/* COUNTRY SELECTION */}
                    {
                        (currentStepData.key == fixedCards.countrySelection) ? <>
                            <GridLayout
                                style={{ margin: '10px' }}
                                cols={[
                                    { width: "100px" },
                                    ...Object.keys(fixedCards).filter(key => {
                                        return key != "countrySelection"
                                    }).map(() => {
                                        return { width: "auto" }
                                    }),
                                    { width: "100px" },
                                ]}
                                rows={[{ height: "100px" }]}
                                gap={{ rows: 5, cols: 5, }}>

                                <GridLayoutItem></GridLayoutItem>
                                {
                                    Object.keys(fixedCards).filter(key => {
                                        return key != "countrySelection"
                                    }).map((key, index) => {
                                        return (
                                            <div
                                                key={index}
                                                onClick={() => {
                                                    localStorage.setItem("selectedCountryKey", fixedCards[key])
                                                    localStorage.setItem("selectedCountryName", key)
                                                    goToCardById(fixedCards[key], key.toLocaleUpperCase())
                                                }}>
                                                <GridLayoutItem key={`countrySelection${index}`} className={`siignal-box-shadow renewals-border-${randomImage.color}`}>
                                                    {key.toLocaleUpperCase()}
                                                </GridLayoutItem>
                                            </div>
                                        )
                                    })
                                }
                                <GridLayoutItem></GridLayoutItem>
                            </GridLayout>
                        </> : null
                    }

                    {
                        (currentStepData.key != fixedCards.colombia &&
                            currentStepData.key != fixedCards.mexico &&
                            currentStepData.key != fixedCards.ecuador &&
                            currentStepData.key != fixedCards.uruguay &&
                            currentStepData.key != fixedCards.countrySelection) ? <>

                            {/* GO BACK BUTTON */}
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2, mb: 2, marginRight: 1 }}
                                onClick={() => {
                                    navigateTo(selectionHistory.length - 2)
                                }}
                            >
                                Regresar
                            </Button>

                            {/* GO HOME BUTTON */}
                            <Button
                                variant="contained"
                                color="warning"
                                sx={{ mt: 2, mb: 2, marginRight: 1 }}
                                onClick={() => {
                                    navigateTo(0)
                                }}
                            >
                                Inicio
                            </Button>
                        </> : null
                    }
                    {
                        (currentStepData.key === fixedCards.colombia || 
                        currentStepData.key === fixedCards.ecuador || 
                        currentStepData.key === fixedCards.uruguay) &&
                        (validateInput(dynamicInputsData.NIT) || 
                        validateInput(dynamicInputsData.RUC) || 
                        validateInput(dynamicInputsData.RUT)) && (
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2, mb: 2 }}
                                onClick={searchNit}
                            >
                                Consultar {currentStepData.key === fixedCards.colombia ? 'NIT' : 
                                            currentStepData.key === fixedCards.ecuador ? 'RUC' : 
                                            currentStepData.key === fixedCards.uruguay ? 'RUT': ''}
                            </Button>
                        )
                    }
                </GridLayoutItem >
            </GridLayout >
            {
                showResume ? <>
                    < div className='renewalsDataPopUp' >
                        <h4>Resumen de la gestion:</h4>
                        <hr />
                        {
                            (selectedCountryValue != 'mexico') && <>
                                <textarea value={resumeContent} onChange={() => { }} />
                            </>
                        }
                        {
                            (selectedCountryValue == 'mexico') && <>
                                <NotasMx data={mexicoSavedData} />
                            </>
                        }        
                        <hr />
                        <div className='buttons'>
                            {
                                (selectedCountryValue != 'mexico') && <>
                                    <button onClick={() => {
                                        const textarea = document.createElement('textarea');
                                        textarea.value = resumeContent;
                                        // Make the textarea non-editable to be able to copy its content
                                        textarea.setAttribute('readonly', '');
                                        // Hide the textarea from view
                                        textarea.style.position = 'absolute';
                                        textarea.style.left = '-9999px';
                                        document.body.appendChild(textarea);
                                        // Select and copy the content to the clipboard
                                        textarea.select();
                                        document.execCommand('copy');
                                        // Remove the textarea from the DOM
                                        document.body.removeChild(textarea);
                                        // Optionally, you can provide feedback to the user that the content has been copied
                                        alert('Content copied to clipboard!');
                                    }}>Copiar</button>
                                </>
                            }
                            <button onClick={() => {
                                closeResumeView();
                            }}>Confirmar y cerrar</button>
                        </div>
                    </div >
                </> : null
            }
            {
                savingData ? <>
                    <div className='savingPopUp'>
                        Estamos guardando tu promesa
                    </div>
                </> : null
            }
        </> : null
    )
}