import React from 'react'
import { TranferencesHelperDashboard } from '../../components/Dashboards/Customer/TranferencesHelper/TranferencesHelper'


export const TranferencesHelper = () => {
    return (
        <>
            <TranferencesHelperDashboard />
        </>
    )
}


