import * as React from 'react';


import { Popup } from "@progress/kendo-react-popup";

import { xIcon, sparklesIcon } from "@progress/kendo-svg-icons";
import { FloatingActionButton } from "@progress/kendo-react-buttons";
import ChatBox from '../Chatbot/components/ChatBox';


const Chatmicrosoft = ({isAllowedChatbotAlice}) => {
	const anchorRef = React.useRef(null);
  const [textAreaValue, setTextAreaValue] = React.useState("");
  const [localPrompt, setLocalPrompt] = React.useState();
  const [showAIPrompt, setShowAIPrompt] = React.useState(false);
  const [outputs, setOutputs] = React.useState([]);
  const toggleAIPrompt = () => {
    setShowAIPrompt(!showAIPrompt);
  };
	return (
		<>
			<FloatingActionButton
				style={{
					zIndex: 100,
				}}
				ref={anchorRef}
				svgIcon={showAIPrompt ? xIcon : sparklesIcon}
				align={{
					horizontal: 'end',
					vertical: 'bottom',
				}}
				alignOffset={{
					x: 40,
					y: 40,
				}}
				positionMode="absolute"
				onClick={toggleAIPrompt}
			/>
			<Popup
				anchor={anchorRef.current?.element}
				show={showAIPrompt}
				popupAlign={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				anchorAlign={{
					vertical: 'bottom',
					horizontal: 'left',
				}}>
					{!isAllowedChatbotAlice?<html>
					<body>
						<iframe
							src="https://copilotstudio.microsoft.com/environments/Default-3a7479db-d9ff-4e0b-9d0a-380a5e714f6f/bots/cr114_portalDeClientes/webchat?__version__=2"
							width="500px"
							height="700px"
							frameborder="0"></iframe>
					</body>
				</html>:<ChatBox/>}
				
			</Popup>
		</>
	);
};

export default Chatmicrosoft;
