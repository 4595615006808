// Organigram.js
import React from 'react';
import EmployeeNode from './EmployeeNode';

const Organigram = ({ subordinates }) => {
    return (
        <div className='Organigram'>
            <h3>{subordinates.lenght>0?'Personas a cargo':''}</h3>
            {subordinates.map(subordinate => (
                <EmployeeNode key={subordinate.email} name={subordinate.name} email={subordinate.email} />
            ))}
        </div>
    );
};

export default Organigram;
