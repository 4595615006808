import React from 'react';
import { FaHandshake, FaBriefcase } from 'react-icons/fa';
import './item.css';
import { controller } from '../db/controller';
export const ItemTodo = ({item, todoMade,color}) => {
	const iconSelector = (tipo) => {

			switch (tipo) {
				case 'Aliados':
				  return <FaHandshake color={color} />;
				case 'Comerciales':
				  return <FaBriefcase color={color} />;
				default:
				  return null;
			  }
		
		
	  };
	  const onchangeCheck= async (event,idSeguimientoAliado)=>{
		todoMade(event, idSeguimientoAliado)
	  }	

	  
	return (
		<li className="list-group-item d-flex justify-content-between">
			<span
				className={`align-self-center ${
					item.atencion ? 'text-decoration-line-through' : ''
				}`}>
				<input
					type="checkbox"
					id="todoMade"
					onChange={(e) => onchangeCheck(e.target, item.idSeguimientoAliado)}
					checked={item.atencion}
				/>
				<span className="icon-home">{iconSelector(item.tipo)}</span>
				{item.avanceNegociacion}
			</span>
		</li>
	);
};
