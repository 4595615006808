import React from 'react'
import { TimeTrackingLeader } from '../../components/TimeReporting/TimeTrackingLeaders';





export const HelpDeskReportingLeader = () => {
  let renderView;
  if (sessionStorage.getItem('usrArea') === 'Data Analytics'){
    renderView=<div><TimeTrackingLeader/></div>} 
  else renderView=<p>No tienes acceso a este modulo de reportes. Para solicitar acceso, comunicate con un administrador del sistema</p>
    return (
        <>
        <h1 className='page-header-reports'>Time Reporting Data Analyitcs</h1>
        {renderView}
        </>
      )
    }


    