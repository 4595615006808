import { Editor, EditorUtils } from '@progress/kendo-react-editor';
import { useEffect, useRef, useState } from 'react';
import { ProseMirror } from "@progress/kendo-react-editor";

export const TextAreaComments = ({ content }) => {

  const { EditorState, EditorView, Plugin, PluginKey } = ProseMirror;
  const [editable, setEditable] = useState(true);
  const editableRef = useRef(true);
  const view = useRef(null);

  const onMount = (event) => {
    const state = event.viewProps.state;
    const plugins = [
      ...state.plugins,
      new Plugin({
        key: new PluginKey("readonly"),
        props: {
          editable: () => editableRef.current,
        },
        filterTransaction: (tr, _st) => editableRef.current || !tr.docChanged,
      }),
    ];
    view.current = new EditorView(
      {
        mount: event.dom,
      },
      {
        ...event.viewProps,
        state: EditorState.create({
          doc: state.doc,
          plugins,
        }),
      }
    );
    return view.current;
  };
  useEffect(() => {
    if (view.current && editable) {
      view.current.updateState(view.current.state);
    }
  }, [editable]);

  const countParagraphs = (htmlContent) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    return tempDiv.getElementsByTagName('p').length;
  };

  const calculateHeight = (content) => {
    const paragraphs = countParagraphs(content);
    // Suponiendo que cada <p> necesita aproximadamente 60px de altura
    return Math.max(60, paragraphs * 60);
  };

  useEffect(() => {
    editableRef.current = false;
    if (view.current && editable) {
      view.current.updateState(view.current.state);
    }
  }, [editable]);

  const editorStyle = {
    borderColor: 'grey',
    background: '#f3f3f3',
  };

  const height = calculateHeight(content);

  return (
    <div style={editorStyle}>
      <Editor
        contentStyle={{ height: `${height}px` }}
        onMount={onMount}
        defaultContent={content}
      />
    </div>
  );
};
