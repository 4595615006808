import {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
	selectGetInfoTicket,
	selectGetLoadingInfoTicket,
	setEmptyInfoTicket,
	thunkChargeInfoTicket,
} from '../../redux/helpDeskTicketSlice.js';
import '../style/infoTicket.css';
import {
	CalculateBusinessHoursPassed,
	RequestDateCell,
	RequestLastUpdateDateCell,
} from '../components/InfoTicket/CalculateDateAndHours.js';
import {TabStrip, TabStripTab} from '@progress/kendo-react-layout';
import {NewCommentEditor} from '../components/InfoTicket/newCommentEditor.jsx';
import {ContactsRequestorAndAssigned} from '../components/InfoTicket/ContactsRequestorAndAssigned.jsx';
import {TimeLineHistory} from '../components/InfoTicket/TimeLineHistory.jsx';
import CustomSnackbar from '../../components/Notification/SnackBar.js';
import {Button} from '@progress/kendo-react-buttons';
import {chevronLeftIcon, pencilIcon} from '@progress/kendo-svg-icons';
import {TableEditing} from '../components/InfoTicket/TableEditing.js';
import {TablaActividades} from '../components/InfoTicket/Activities/Table.jsx';
import {ActivityForm} from '../components/InfoTicket/Activities/ActivityForm.jsx';
import MissingbodyofTicket from '../components/InfoTicket/bodyTicket/MissingbodyTicket.js';
import { SvgIcon } from "@progress/kendo-react-common";
import { selectCredencialesArea, selectCredencialesRole } from '../../redux/credencialesReducer.js';
import { IsAuthorized } from '../../components/auth/IsAuthorized.js';

export const InfoTicket = () => {
	const area = useSelector(selectCredencialesArea);
    const rol=useSelector(selectCredencialesRole);
	const [toast, setToast] = useState({
		snackbarOpen: false,
		message: '',
		type: '',
	});
	const [timeElapsedStatus, setTimeElapsedStatus] = useState(null);
	const [timeElapsedEntireTicket, setTimeElapsedEntireTicket] = useState(null);
	const [isEdition, setIsEdition] = useState({
		values: false,
		Partner: false,
		requestType: false,
	});
	const [rolHelpDesk, setRolHelpDesk] = useState(
		IsAuthorized('HelpDesk-dataPartner',area,rol )?"Data Partner":IsAuthorized('HelpDesk-resolver',area,rol )?"Resolver":"user"
	);
	const [isPartner, setIsPartner] = useState(
		IsAuthorized('HelpDesk-dataPartner',area,rol )
	);
	const [item, setItem] = useState(null);
	//!Uso que le dará usePerson - Personas que veran la información: authorizedPersons

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const id = useParams().id;
	if (id <= 4191) {
		if (rolHelpDesk === 'Data Partner' || rolHelpDesk === 'portalAdmin') {
			navigate(`/HelpDesk/MyJob/EditTicket1/${id}`);
		} else {
			navigate(`/HelpDesk/MyJob/DescriptionTicket1/${id}`);
		}
	}
	useEffect(() => {
		if (id <= 4191) {
			if (rolHelpDesk === 'Data Partner' || rolHelpDesk === 'portalAdmin') {
				navigate(`/HelpDesk/MyJob/EditTicket1/${id}`);
			} else {
				navigate(`/HelpDesk/MyJob/DescriptionTicket1/${id}`);
			}
		}
	}, [id, rolHelpDesk,navigate]);
	const dataToSend = useMemo(() => ({numTicket: parseInt(id)}), [id]);
	useEffect(() => {
		dispatch(thunkChargeInfoTicket(dataToSend));
	}, []);
	const ticketData = useSelector(selectGetInfoTicket);

	useEffect(() => {
	  return () => {
		dispatch(setEmptyInfoTicket());
	  }
	}, [])
	

	const [selected, setSelected] = useState(rolHelpDesk!=='user'?2:0);//esto es para saber que pestaña se deja por default al abrir la vista
	const handleSelect = (e) => {
		setSelected(e.selected);
	};

	const handleCloseSnackbar = () => {
		setToast({...toast, snackbarOpen: false});
	};

	const isLoading = useSelector(selectGetLoadingInfoTicket);

	if (isLoading) {
		return <h2>Cargando...</h2>;
	}
	if (Object.keys(ticketData).length === 0) {
		return <h2>Cargando...</h2>;
	}

	const ButtonEditValues = ({valuesToEdit}) => {
		const handleEditValues = () => {
			setIsEdition({...isEdition, [valuesToEdit]: !isEdition[valuesToEdit]});
		};

		if (isPartner) {
			return (
				<Button
					svgIcon={pencilIcon}
					onClick={() => handleEditValues()}
					className="ButtonEdit-ticket">
					{' '}
				</Button>
			);
		}

		return null;
	};

	const tabData = [
		{
			title: 'All Comments',
			content: (
				<TimeLineHistory
					status={ticketData.status.history}
					ticketId={ticketData.ticketId}
				/>
			),
			permissions: ['portalAdmin', 'Data Partner', 'Resolver','user'],
		},
		{
			title: 'New Comment',
			content: (
				<NewCommentEditor
					setToast={setToast}
					timeElapsedStatus={timeElapsedStatus}
					ticketId={ticketData.ticketId}
					handleSelect={handleSelect}
				/>
			),
			permissions: ['portalAdmin', 'Data Partner', 'Resolver', 'user'],
		},
		{
			title: 'All Activities',
			content: (
				<TablaActividades
					id={ticketData.ticketId}
					setItem={setItem}
					handleSelect={handleSelect}
					timeElapsedStatus={timeElapsedStatus}
					ticketActivities={ticketData.Activity}
					isPartner={isPartner}
					setToast={setToast}
				/>
			),
			permissions: ['portalAdmin', 'Data Partner', 'Resolver', 'user'],
		},
		{
			title: 'New Activity',
			content: (
				<ActivityForm
					setToast={setToast}
					handleSelect={handleSelect}
					setItem={setItem}
					status={ticketData.status.history}
					id={ticketData.ticketId}
					item={item}
					isPartner={isPartner}
					timeElapsedStatus={timeElapsedStatus}
				/>
			),
			permissions: ['portalAdmin', 'Data Partner', 'Resolver'],
		},
		{
			title: 'History',
			content: (
				<TimeLineHistory
					status={ticketData.status.history}
					ticketId={ticketData.ticketId}
					filterr={true}
				/>
			),
			permissions: ['portalAdmin', 'Data Partner'],
		},
	];
	if(ticketData.previousTickets){
		tabData.push({
			title: `Associated Ticket ${ticketData.associatedTicketNumber}`,
			content: (
				<TimeLineHistory
					status={ticketData.status.history}
					ticketId={ticketData.associatedTicketNumber}
				/>
			),
			permissions: ['portalAdmin', 'Data Partner'],
		});
	}

//navigate('/HelpDesk/MyJob')
	return (
		<div className="containerinfoTicket">
			<CustomSnackbar
				open={toast.snackbarOpen}
				handleClose={handleCloseSnackbar}
				message={toast.message}
				severity={toast.type}
			/>
			<div className="div1infoTicket">
			<div style={{display: 'flex'}}>
			<SvgIcon onClick={()=>{if(rolHelpDesk!=='user'){navigate('/HelpDesk/MyJob')}else{navigate('/HelpDesk')}}} icon={chevronLeftIcon} size="xxlarge" className='ButtonBack'/>
				<h4>{ticketData.ticketId}</h4>
			</div>
				<div style={{display: 'flex', justifyContent: 'space-between'}}>
					<h3 style={{flex: '70%'}}>{ticketData.title}</h3>
					<div style={{flex: '30%', height: '40px'}}>
						<div className="div-Edit-ticket">
							{isEdition.requestType ? (
								<TableEditing
									data={ticketData}
									valuesToEdit={'requestType'}
									setToast={setToast}
									timeElapsedStatus={timeElapsedStatus}
								/>
							) : (
								<p>
									<span style={{paddingRight: '30px'}}>Tipo:</span>{' '}
									{ticketData.requestType}
								</p>
							)}
							<ButtonEditValues valuesToEdit={'requestType'} />
						</div>
					</div>
				</div>

				<div className="contentinfoTicket">
					<div>
						<p>Tags: </p>
					</div>
					<div className="tag-container">
						{ticketData.keyword &&
							ticketData.keyword.map((label, index) => (
								<div className="tag" key={index}>
									{label}
								</div>
							))}
					</div>
				</div>
			</div>
			<div className="div2infoTicket">
				<h4>Descripción</h4>
				<MissingbodyofTicket
					type={ticketData.requestType}
					ticket={ticketData}
					isPartner={isPartner}
					setToast={setToast}
					timeElapsedStatus={timeElapsedStatus}
				/>

				{/* <!-- Espacio para componente de adjuntos --> */}
				<TabStrip
					selected={selected}
					onSelect={handleSelect}
					style={{height: '65%'}}>
					{tabData
						.filter((tab) => tab.permissions.includes(rolHelpDesk))
						.map((tab, index) => (
							<TabStripTab key={index} title={tab.title}>
								<div>{tab.content}</div>
							</TabStripTab>
						))}
				</TabStrip>
			</div>
			<div className="div3infoTicket">
				<h4 style={{textAlign: 'center', padding: '17px'}}>SLAs</h4>
				<table style={{marginRight: '10px', marginLeft: '30px'}}>
					<tbody>
					<tr>
						<td>Fecha de Creación</td>
						<RequestDateCell ticketData={ticketData} />
					</tr>
					<tr>
						<td>Tiempo Transcurrido</td>
						<CalculateBusinessHoursPassed
							ticketData={ticketData}
							setTimeElapsedStatus={setTimeElapsedStatus}
							timeElapsedStatus={timeElapsedStatus}
						/>
					</tr>
					<tr>
						<td>Última Modificación</td>
						<RequestLastUpdateDateCell ticketData={ticketData} />
					</tr>
					</tbody>
				</table>
				<div className="div-Edit-ticket">
					{isEdition.values ? (
						<TableEditing
							data={ticketData}
							valuesToEdit={'values'}
							setToast={setToast}
							timeElapsedStatus={timeElapsedStatus}
						/>
					) : (
						<table style={{width: '100%'}} className="table-Ticket">
							<tbody>
							<tr>
								<td style={{}}>Estatus</td>
								<td>{ticketData.status.currently}</td>
							</tr>
							<tr>
								<td>Prioridad</td>
								<td>{ticketData.priority}</td>
							</tr>
							<tr>
								<td>Área</td>
								<td>
									{ticketData &&
										ticketData.destinationArea?.areaName.map((item) => {
											return ` ${item} |`;
										})}
								</td>
							</tr>
							<tr>
								<td>Subárea</td>
								<td>
									{ticketData.destinationArea?.subArea.map((item) => {
										return ` ${item} |`;
									})}
								</td>
							</tr>
							</tbody>
						</table>
					)}

					<ButtonEditValues valuesToEdit={'values'} />
				</div>
				<div className="div-Edit-ticket">
					{isEdition.Partner ? (
						<TableEditing
							data={ticketData}
							valuesToEdit={'Partner'}
							setToast={setToast}
							timeElapsedStatus={timeElapsedStatus}
						/>
					) : (
						<ContactsRequestorAndAssigned />
					)}
					<ButtonEditValues valuesToEdit={'Partner'} />
				</div>
			</div>
		</div>
	);
};
