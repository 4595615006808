import React from 'react';
import {useNavigate} from 'react-router-dom';
import {getDaigramStorage} from '../utils/localStorageDiagram';
import {exportNestedObject} from '../utils/nestedObjectDiagram';
import '../../components/status-card/statuscard.css';
import {SearchIcons} from '../drawable/icons';

export default function ButtonsCards({actualRute, nombreDelBoton,setTitulo}) {
	const navigate = useNavigate();
	const navigate2 = useNavigate();

    
 
  

	const handleClick = async () => {
    setTitulo(`Reportes de ${nombreDelBoton}`)
		let decision = await selectComponent(actualRute);
		let data = encodeURIComponent(actualRute);
		if (decision === true) {
      if(actualRute==="Reportes por Área/Comercial/Proyecto distribuidores/Proyecto distribuidores"||actualRute==="Reportes por Área/Data Analytics/Data Science/Día de Operación"|| actualRute ==="Reportes por Área/Customer Success/Worforce Management/Siignal" || actualRute==="Reportes por Área/Data Analytics/Data Engineering/Time Reporting Leadership" || actualRute==="Reportes por Área/Data Analytics/Data Engineering/Time Reporting"){
      
		chooseRuteReportsWithoutBI(actualRute);
      }else{
			navigate2(`/LobbyReports/${data}`);}
		} else {
      navigate(`/Reports/${data}`);
    
		}
	};

	const selectComponent = async (actualRute) => {
		const diagram = getDaigramStorage();
		let searchRute = actualRute.split('/');
		let decision = await exportNestedObject(diagram, searchRute);
		return decision;
	};
	const chooseRuteReportsWithoutBI=(actualRute)=>{
		switch(actualRute){
			case "Reportes por Área/Customer Success/Worforce Management/Siignal": navigate2(`/Reports/ReportsCustomer/Siignal`);break;
			case "Reportes por Área/Data Analytics/Data Engineering/Time Reporting Leadership": navigate2(`/Reports/ReportsData/HelpDeskLeadership`);break;
			case "Reportes por Área/Data Analytics/Data Engineering/Time Reporting": navigate2(`/Reports/ReportsData/UserHome`);break;
			case "Reportes por Área/Data Analytics/Data Science/Día de Operación" : navigate2(`/Reports/ReportsData/DayOperation`);break;
			case "Reportes por Área/Comercial/Proyecto distribuidores/Proyecto distribuidores": navigate2(`/Template/Grid/Contacto Verificado`);break;
			default: break;
		}
	}

	return (
		<div className="status-card" onClick={handleClick}>
			<div className="status-card__icon">
				{SearchIcons(nombreDelBoton)}
			</div>
			<div className="status-card__info">
        		<p>{nombreDelBoton}</p>
			</div>
			
		</div>
	);
}
