// import css
import './style.css';
import axios from 'axios'
import { Loading } from '../Loading/Loading';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Notification, NotificationGroup} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { Grid, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

// import react
import React, { useEffect } from 'react';

function UploadCenter() {
    const [csvFile, setCsvFile] = React.useState(null);
    const [jsonData, setJsonData] = React.useState(null);
    const [fileStructureMsg, setFileStructureMsg] = React.useState(null);
    const [validFileStructure, setValidFileStructure] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [notification, setNotification] = React.useState({
        notification: false,
        notificationType: 'success',
        notificationMessage: '',
    });

    const fileStructures = {
        "Horarios": {
            "cols": ['Pais', 'Fecha', 'Cedula', 'Nombre', 'Hr Inicio', 'Hr Fin', 'Desc 1', 'Desc 2', 'H Ini Alm', 'H Fin Alm', 'Area', 'Email', 'IN Break 1', 'FIN Break 1', 'IN Break 2', 'FIN Break 2'],
            "endPoint": "/api/siignalUpdateSchedule",
            "handleUpload": async (data) => {
                return data
            }
        },
        "Dimensionamiento": {
            "cols": ['Pais', 'Fecha', 'Intervalo', 'Skill', 'Total de llamadas', 'TMO', 'Agentes programados', 'Agentes utiles', 'Nivel de atencion', 'Nivel de servicio'],
            "endPoint": "/api/siignalUpdateForecast",
            "handleUpload": async (data) => {
                return data
            }
        },
        "Onboarding LatAm": {
            "cols": ['Pais', 'Nombre Asesor', 'IDENTIFICACION', 'CORREO', 'Gestion', 'Lider', 'Estado', 'Novedad', 'Fecha de ingreso', 'Apoyo', 'Origen del apoyo', 'Celuar de implementacion', 'Link de bookings', 'Link de whatsapp', 'Link corto'],
            "endPoint": "/api/siignalUpdateOnboardingLatAm",
            "handleUpload": async (data) => {
                return data
            }
        },
        "HC Dimensionamiento": {
            "cols": ['Cedula', 'Nombre Completo', 'Lider', 'Celula'],
            "endPoint": "/api/siignalUpdateHcDimensionamiento",
            "handleUpload": async (data) => {
                return data
            }
        },
        "Novedades": {
            "cols": ['ID_AUSENCIA', 'WORKER_NUMBER', 'IDENTIFICACION_NACIONAL', 'NOMBRE', 'DEPARTAMENTO', 'TIPO_DE_AUSENCIA', 'MOTIVO_DE_AUSENCIA', 'DURACION', 'UNIDAD_DE_MEDIDA_DURACION', 'FECHA_INICIO_AUSENCIA', 'FECHA_FIN_AUSENCIA', 'ESTADO_DE_LA_AUSENCIA', 'ESTADO_DE_APROBACION_DE_LA_AUSENCIA'],
            "endPoint": "/api/siignalUpdateNovedades",
            "handleUpload": async (dataOrigin) => {
                let dates = {}
                dataOrigin.forEach(novedad => {
                    let dateStart = new Date(novedad.FECHA_INICIO_AUSENCIA)
                    let dateEnd = new Date(novedad.FECHA_FIN_AUSENCIA)
                    while (dateStart <= dateEnd) {
                        const dateString = dateStart.toISOString().split('T')[0]
                        if (dates[dateString] == undefined) {
                            dates[dateString] = []
                        }
                        dates[dateString].push(novedad);
                        dateStart.setDate(dateStart.getDate() + 1);
                    }
                });
                console.log("###########");
                console.log(dates)
                return dates
            }
        },
        "Base de personal": {
            "cols": ["IDENTIFICACION", "FECHA INICIAL", "FECHA OPERACION", "NOMBRES", "APELLIDOS", "NOMBRES COMPLETOS", "PAIS ORIGEN", "PAIS AL QUE LABORA", "CIUDAD OFICINA", "AREA", "SUBAREA", "CARGO", "JEFE INMEDIATO", "CODIGO USUARIO", "USUARIOFIVE9", "USUARIO RED", "CORREOELECTRONICO", "STATION ID", "ANTIGUEDAD", "AGENT GROUP", "LICENCIAS", "NOTICIAS"],
            "endPoint": "/api/siignalUpdateBasePersonal",
            "handleUpload": async (data) => {
                return data
            }
        },
        "Siigo Headcount": {
            "cols": ["id", "email", "name", "country", "city", "area", "cell", "role", "lider", "isLider"],
            "endPoint": "/api/siignalUpdateSiigoHeadcount",
            "handleUpload": async (data) => {
                return data
            }
        },
        "Base Renovaciones": {
            // "cols": ["NIT", "Instancia", "AREA", "DESC", "RISK", "Gestion", "Categoria", "Tipo", "Periodicidad"],
            "cols": ["NIT", "Instancia", "AREA", "DESC", "Categoria"],
            "endPoint": "/api/siignalUpdateBaseRenovaciones",
            "handleUpload": async (data) => {
                return data
                return [
                    {
                        "NIT": "900.000.000-1",
                    }
                ]
            }
        },
        "Base Renovaciones MX": {
            "cols": ['RFC', 'RAZON_SOCIAL', 'NUMSERIE', 'PRODUCTO', 'PROVEEDOR PRODUCTO', 'PERIODICIDAD', 'FECHA_COMPRA', 'FECHA_INI', 'FECHA_RENOVACION', 'Lada_Telefono_1', 'Lada_Telefono_2', 'Lada_Telefono_3', 'Email_1', 'Email_2', 'Email_3', 'Lada_Telefono_Macrobase', 'Email_Macrobase', 'MARCA_DISTRIBUIDOR', 'FORMA_COBRO', 'MontoNeto'],
            "endPoint": "/api/siignalUpdateBaseRenovacionesMX",
            "handleUpload": async (data) => {
                return data
            }
        },
        "Base Renovaciones ECU": {
            "cols": ['RUC', 'INSTANCIA', 'AREA', 'DESC', 'PERIODICIDAD', 'CHURN'],
            "endPoint": "/api/siignalUpdateBaseRenovacionesECU",
            "handleUpload": async (data) => {
                return data
            }
        },
        "Base Renovaciones URU": {
            "cols": ['RUT', 'INSTANCIA', 'AREA', 'DESC', 'PERIODICIDAD', 'CHURN'],
            "endPoint": "/api/siignalUpdateBaseRenovacionesURU",
            "handleUpload": async (data) => {
                return data
            }
        },
        "Base Pagos Renovaciones MX": {
            "cols": ["NUMSERIE", "VALOR", "FECHA"],
            "endPoint": "/api/siignalUpdateBasePagosRenovacionesMX",
            "handleUpload": async (data) => {
                const removeDuplicatesByKey = (array, key) => {
                    const seen = {};
                    return array.filter((item) => {
                        const value = item[key];

                        if (seen[value]) {
                            return false;
                        }

                        seen[value] = true;
                        return true;
                    });
                }
                let result = await axios.get("/api/siignalGetBaseRenovacionesMXAll").then(response => {
                    let serials = response.data
                    let payments = removeDuplicatesByKey(data, "NUMSERIE")

                    let paymentsFiltered = payments.filter(pago => {
                        let encontrado = serials.find(producto => producto.NUMSERIE === pago.NUMSERIE)
                        return encontrado ? true : false
                    }).map(pago => {
                        let serial = serials.find(serial => serial.NUMSERIE == pago.NUMSERIE)
                        if (serial) {
                            pago.RFC = serial.RFC
                        }
                        return pago
                    })
                    return paymentsFiltered
                }).catch(err => {
                    console.log(err)
                })
                return result
            }
        }

    }

    const findKeyByValue = (fileStructures, inputArray) => {
        for (const key in fileStructures) {
            console.log(key)
            if (fileStructures[key].cols.every(value => inputArray.includes(value))) {
                return key;
            }
        }
        return null; // return null if no matching key is found
    }


    const parseCsv = (csvString) => {

        const rows = csvString.split('\n');
        const headers = rows[0].split(',');

        let data = [];
        for (let i = 1; i < rows.length; i++) {
            const row = rows[i];
            if (row.trim() === '') continue; // Skip empty rows

            let rowData = [];
            let insideQuotes = false;
            let currentCell = '';

            for (let j = 0; j < row.length; j++) {
                const char = row[j];

                if (char === '"') {
                    insideQuotes = !insideQuotes;
                } else if (char === ',' && !insideQuotes) {
                    rowData.push(currentCell.trim());
                    currentCell = '';
                } else {
                    currentCell += char;
                }
            }

            rowData.push(currentCell.trim());
            data.push(rowData);
        }

        console.log("###########")
        // Replace \r or \t from each header and handle accent vowels
        headers.forEach((el, i) => {
            headers[i] = el
                .replace(/\r/g, '')
                .replace(/\t/g, '')
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .trim();
        });

        data = data.map((row) => {
            return row.reduce((acc, curr, i) => {
                // Handle accent vowels in row values
                const normalizedValue = curr
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '');
                acc[headers[i]] = normalizedValue;
                return acc;
            }, {});
        });
        return data;
    };

    const handleUpload = async () => {
        // setLoading(true);
        const fileConfig = fileStructures[validFileStructure]
        console.log("%%%%%%%%%%")
        console.log(fileConfig.handleUpload)
        const processedData = await fileConfig.handleUpload(jsonData)
        console.log(processedData)
        console.log("***********")

        const fileUpload = async () => {
            const response = await axios.patch(fileConfig.endPoint, processedData);
            setNotification({
                notification: true,
                notificationType: response.data.status,
                notificationMessage: response.data.message,
            })
            setLoading(false);
        }
        fileUpload();
    };

    useEffect(() => {
        if (csvFile) {
            const reader = new FileReader();
            reader.onload = async (event) => {
                const csv = event.target.result;
                let jsonRawData = parseCsv(csv);
                let jsonFixedData = []
                jsonRawData.map(el => {
                    if (el.Pais != "") {
                        jsonFixedData.push(el)
                    }
                })
                setJsonData(jsonFixedData);
                console.log(Object.keys(jsonFixedData[0]))
                const fileStructure = findKeyByValue(fileStructures, Object.keys(jsonFixedData[0]));
                setValidFileStructure(fileStructure);
                if (fileStructure != null) {
                    setFileStructureMsg(`Cargaste un archivo que corresponde a ${fileStructure}:`);
                } else {
                    setFileStructureMsg(`El archivo cargado no tiene una estructura válida`);
                }
            };
            reader.readAsText(csvFile, 'ISO-8859-1');
        }
    }, [csvFile]);

    const handleFileSelect = (event) => {
        setCsvFile(event.target.files[0]);
    };

    return (

        loading ? <Loading /> : <>
            <Grid container spacing={3}>
                <Grid xs={1}>
                </Grid>
                <Grid xs={10}>
                    <form noValidate autoComplete="off">
                        <label id="csvFileUpload" className='siignalFileUploadCenterLabel'>
                            <UploadFileIcon sx={{ fontSize: 60 }} />
                            <br />
                            Cargar archivo CSV:
                            <input accept='.csv' id='fileUpload' type='file' onChange={handleFileSelect} />
                        </label>
                    </form>
                </Grid>
            </Grid>

            <Grid container spacing={3} alignItems="center">
                <Grid item xs={12}>
                    {
                        (jsonData != null) ? <>
                            <h4>
                                {fileStructureMsg}
                            </h4>

                            <TableContainer>
                                <Table stickyHeader sx={{ minWidth: 700 }} size="small" >
                                    <TableHead>
                                        <TableRow>
                                            {
                                                Object.keys(jsonData[0]).map((el, i) => {
                                                    return <TableCell key={i} className='uploadCenterCell'>{el}</TableCell>
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            jsonData.slice(0, 10).map((el, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        {
                                                            Object.keys(el).map((el2, i2) => {
                                                                return <TableCell key={i2} className='uploadCenterCell'>{el[el2]}</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br />
                            {
                                (validFileStructure != null) ? <h4>
                                    <Button onClick={handleUpload} variant="contained" color="primary">Confirmar y cargar</Button>
                                </h4> : <h4>
                                    <Button disabled variant="contained" color="primary">Confirmar y cargar</Button>
                                </h4>
                            }

                        </> : <>
                            <h4>Carga un archivo para continuar</h4>
                        </>
                    }
                </Grid>
            </Grid>
            <NotificationGroup style={{
                right: 0,
                bottom: 0,
                alignItems: 'flex-start',
                flexWrap: 'wrap-reverse'
            }}>
                <Fade>
                    {notification.notification && <Notification type={{
                        style: notification.notificationType,
                        icon: true
                    }} closable={false}>
                        <span>{notification.notificationMessage}</span>
                    </Notification>}
                </Fade>
            </NotificationGroup>
        </>
    );
}
export default UploadCenter;

