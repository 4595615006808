import React, { useState } from 'react';
import './style.css';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

export const GuestHome = () => {
    const { instance, accounts } = useMsal();
    const name = accounts[0]?.name;
    const email = accounts[0]?.username;
    const navigate = useNavigate();
    const [onhover, setOnhover] = useState(false);

    return (
        <div className="GuestHomeApp">
            <MainSection name={name} setOnhover={setOnhover} />
            <section className="Contact-section" style={{ paddingBottom: `${onhover ? '65px' : '25px'}` }}>
                <div className="contact-box" onMouseEnter={() => setOnhover(true)} onMouseLeave={() => setOnhover(false)}>
                    <h2>Consumo Alianzas</h2>
                    <p></p>
                    <button className="browse-btn" onClick={() => navigate('/Template/Grid/Seguimientos Landing')}>REGISTRAR</button>
                </div>
            </section>
        </div>
    );
}

const MainSection = ({ name, setOnhover }) => (
    <section className="Main-section" >
        <div className="GuestHomeApp-content" onMouseEnter={() => setOnhover(true)} onMouseLeave={() => setOnhover(false)}>
            <h1>Hola, bienvenido/a {name}</h1>
            <h1><span style={{ fontSize: '115px' }}>Alianzas</span></h1>
        </div>
    </section>
);

export default GuestHome;
