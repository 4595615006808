import * as React from 'react';
import { Field } from '@progress/kendo-react-form';
import { FormInput, FormAutoComplete, FormRadioGroup, FormTextArea, FormInputTrainer, FormInputTrainerEmail, FormDropDownListProject } from './form-components';
import { areaValidator, nameValidator, requiredValidator } from './validators';

import{AdditionalTrainercheck} from './editors.tsx'

export const PersonalDetails = <div>
    <Field key={'trainerName'} id={'trainerName'} name={'nombreEntrenador'} label={'Nombre Capacitador'} component={FormInputTrainer} disabled={true}/>
    <Field key={'email'} id={'email'} name={'emailEntrenador'} label={'Email'} type={'email'} component={FormInputTrainerEmail} disabled={true}  />
    <Field name={'entrenadorAdicional'} component={FormAutoComplete} label={'Capacitador Adicional'} optional={true}/>
    <Field key={'comments'} id={'comments'} name={'comentarios'} label={'Comentarios'} optional={true} component={FormTextArea} />
    <Field name={'project'} component={FormDropDownListProject} label={'Proyecto'} validator={areaValidator}/>
  </div>;