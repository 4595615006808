import {Avatar} from '@progress/kendo-react-layout';
import {SvgIcon} from '@progress/kendo-react-common';
import {pencilIcon, userIcon} from '@progress/kendo-svg-icons';
import {useSelector} from 'react-redux';
import {selectGetInfoTicket} from '../../../redux/helpDeskTicketSlice';
import '../../style/infoTicket.css';
import { Button } from '@progress/kendo-react-buttons';


export const ContactsRequestorAndAssigned = () => {
	const ticketInfo = useSelector(selectGetInfoTicket);
    const contacts=[
        {
            type:'Owner of the Request',
            avatar:'JP',
            name:ticketInfo.requestor.name,
            email:ticketInfo.requestor.email,
            area:ticketInfo.requestor.area,
            jobTitle:ticketInfo.requestor.jobTitle
        },{
            type:'Assigned To',
            avatar:'JP',
            name: ticketInfo.assignedTo.name,
            email:ticketInfo.assignedTo.email,
            area: ticketInfo.assignedTo.area,
        }
    ]
	if (ticketInfo.requestor.other!==''){
		contacts.unshift({
			type:'For whom it is Requested',
			avatar:'JP',
			name:ticketInfo.requestor.other,
			email:" ",
			area:" ",
		})
	}

	ticketInfo.Activity&&ticketInfo.Activity
	.map((activity,index)=>
		contacts.push({
			type:`Activity #${index+1}`,
			avatar:'JP',
			name: activity.assignedTo,
			email:activity.resolverEmail,
			area:activity.objectives,
		})
	)
	if(ticketInfo.requestType==='solicitud de Reporte'){
		contacts.push({
			type:'Performance Partner',
			avatar:'JP',
			name:ticketInfo.approval.performancePartner.Name,
			area:`Aprobación: ${String(ticketInfo.approval.performancePartner.state)}`,
			jobTitle:ticketInfo.approval.performancePartner.domain
		})
	}
	contacts.push({
		type:'Data Owner',
			avatar:'JP',
			name:ticketInfo.approval.dataOwner.Name,
			email:ticketInfo.approval.dataOwner.email,
			area:`Aprobación: ${String(ticketInfo.approval.dataOwner.state)}`
	})
	

    function obtenerIniciales(nombreCompleto) {
        if(nombreCompleto){
			return nombreCompleto
        .split(' ') // Divide el nombre completo en un array de palabras
        .slice(0, 2) // Toma solo las dos primeras palabras
        .map(palabra => palabra[0]) // Toma la primera letra de cada palabra
        .join(''); // Une las letras iniciales
		}else{
			return 'NaN'
		}
    }
	if (!ticketInfo) {
		return <></>;
	}
	return (

			<div
				style={{
					padding: '8px 14px',
					marginBottom: '4px',
					boxShadow: '0 0px 2px #ccc',
				}}>
				{contacts.map((contact, index) => {
					return (
						<div key={index}>
							<div
								className="contact-list"
								style={{
									textAlign: 'left',
									padding: 4,
									fontSize: 20,
								}}>
								{contact.type}
							</div>
							<div className="k-hbox">
								<Avatar
									rounded="full"
									type="text"
									style={{
										marginRight: 5,
									}}>
									{obtenerIniciales(contact.name)}
								</Avatar>
								<div>
									<h2
										style={{
											fontSize: '1.3em',
											fontWeight: 'normal',
											margin: 0,
										}}>
										{contact.name}
									</h2>
									<p
										style={{
											margin: 0,
											fontSize: '0.8em',
										}}>
										{contact.email}
									</p>
                                    <p
										style={{
											margin: 0,
											fontSize: '0.8em',
										}}>
										{contact.area}
									</p>
                                    <p
										style={{
											margin: 0,
											fontSize: '0.8em',
										}}>
										{contact.jobTitle}
									</p>
								</div>
							</div>
						</div>
					);
				})}
			</div>
			
	);
};
