// import css
import './style.css';
import { Loading } from '../Loading/Loading';
import { NoData } from '../NoData';
import { Fade } from "@progress/kendo-react-animation";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Grid } from '@mui/material';
import { Button } from "@progress/kendo-react-buttons";
// import react
import React, { useEffect } from 'react';
import axios from 'axios';
import { SiignalTable } from '../SiignalTable/SiignalTable';
import { MenuNavigation } from '../MenuNavigation/MenuNavigation';
import { Tune } from '@mui/icons-material';
import { SiignalChart } from '../SiignalChart/SiignalChart';
import { KPIScorecard } from '../KPIScorecard/KPIScorecard';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';

function ForecastVsReal() {
    const [loading, setLoading] = React.useState(true);
    const [isDataAvailable, setIsDataAvailable] = React.useState(null)
    const [data, setData] = React.useState([]);
    const [lastUpdated, setLastUpdated] = React.useState(null);
    const [selectedView, setSelectedView] = React.useState(null);
    const [selectedTimeZone, setSelectedTimeZone] = React.useState(0);
    const [shouldDisableUpdateBtn, setShouldDisableUpdateBtn] = React.useState(false)
    const [APIResponseData, setAPIResponseData] = React.useState(null); const timeZoneConvertion = {
        "Colombia": 0,
        "Ecuador": 0,
        "Mexico": -1,
        "Uruguay": 2
    }
    const [notification, setNotification] = React.useState({
        notification: false,
        notificationType: 'success',
        notificationMessage: '',
    });

    const isTimeBeforeNow = (timeString) => {
        // Get the current time
        var now = new Date();

        // Parse the time string
        var [hours, minutes] = timeString.split(':');

        // Create a new Date object with the parsed time
        var time = new Date();
        time.setHours(hours);
        time.setMinutes(minutes);

        // Compare the two time values
        return time < now;
    }

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        if (Object.keys(data).length != 0) {
            if (selectedView == null) {
                const country = Object.keys(data)[0]
                setSelectedView({
                    country: country,
                    cell: Object.keys(data[country])[0],
                    view: "Staff"
                })
            }
            setIsDataAvailable(true)
        } else {
            setIsDataAvailable(false)
        }
    }, [data])

    // useEffect(() => {
    //     let processedData = processData(APIResponseData);
    //     setData(processedData);
    // }, [selectedTimeZone])

    const loadData = (() => {
        setShouldDisableUpdateBtn(true)
        setLastUpdated("Actualizando")
        Promise.all([
            axios.get('/api/getSiignalData', {})
        ]).then(([siignalData]) => {
            setAPIResponseData(siignalData.data)
            let processedData = processData(siignalData.data);
            if (processedData[""] != undefined) {
                delete processedData[""];
            }
            console.log(processData)
            setData(processedData);
        }).then(() => {
            setLoading(false);
            setShouldDisableUpdateBtn(false)
        }).catch((err) => {
            console.log(err);
        })
    })

    const processData = (siignalData) => {
        console.log(siignalData)
        setLastUpdated(siignalData.lastCachedTime)
        let processedData = {};

        if (siignalData.staffByInterval.length != 0) {
            siignalData.staffByInterval.forEach(element => {
                if (element.COUNTRY != undefined) {
                    element.INTERVAL = timeConvertion(element.INTERVAL, 2);
                    if (processedData[element.COUNTRY] == undefined) {
                        processedData[element.COUNTRY] = {};
                    }
                    if (processedData[element.COUNTRY][element.CELL] == undefined) {
                        processedData[element.COUNTRY][element.CELL] = {}
                    }
                    if (processedData[element.COUNTRY][element.CELL][element.INTERVAL] == undefined) {
                        processedData[element.COUNTRY][element.CELL][element.INTERVAL] = {
                            STAFF_REAL: element.CONNECTION_TIME / 1800
                        }
                    }
                }
            });
        }
        if (siignalData.volumeByInterval.length != 0) {
            siignalData.volumeByInterval.forEach(element => {
                element.INTERVAL = timeConvertion(element.INTERVAL, 2);
                if (processedData[element.COUNTRY] == undefined) {
                    processedData[element.COUNTRY] = {}
                }
                if (processedData[element.COUNTRY][element.CELL] == undefined) {
                    processedData[element.COUNTRY][element.CELL] = {}
                }
                if (processedData[element.COUNTRY][element.CELL][element.INTERVAL] == undefined) {
                    processedData[element.COUNTRY][element.CELL][element.INTERVAL] = {}
                }

                // Rename INTERACTION_COUNT to VOLUME_REAL
                element.VOLUME_REAL = element.INTERACTION_COUNT;

                // Delete INTERACTION_COUNT
                delete element.INTERACTION_COUNT;

                Object.keys(element).forEach(rowCol => {
                    if (rowCol != "DATE" && rowCol != "INTERVAL" && rowCol != "COUNTRY" && rowCol != "CELL") {
                        let currentValue = processedData[element.COUNTRY][element.CELL][element.INTERVAL][rowCol] || 0
                        processedData[element.COUNTRY][element.CELL][element.INTERVAL][rowCol] = (element[rowCol] == null) ? 0 : element[rowCol] + currentValue
                    }
                })
            });
        }
        if (siignalData.forecast.length != 0) {
            siignalData.forecast.forEach(element => {
                element.INTERVAL = timeConvertion(element.Intervalo, 0);
                if (processedData[element.Pais] == undefined) {
                    processedData[element.Pais] = {}
                }
                if (processedData[element.Pais][element.Skill] == undefined) {
                    processedData[element.Pais][element.Skill] = {}
                }
                if (processedData[element.Pais][element.Skill][element.INTERVAL] == undefined) {
                    processedData[element.Pais][element.Skill][element.INTERVAL] = {}
                }

                // Rename Agentes utiles to STAFF_FORECAST
                element.STAFF_FORECAST = element["Agentes utiles"];
                // Rename Total de llamadas to VOLUME_FORECAST
                element.VOLUME_FORECAST = element["Total de llamadas"];

                Object.keys(element).forEach(rowCol => {
                    processedData[element.Pais][element.Skill][element.INTERVAL][rowCol] = (element[rowCol] == null) ? 0 : element[rowCol]
                })
            });
        }
        return processedData;
    }

    const timeConvertion = (time1, diff) => {
        // diff = parseInt(diff) + parseInt(timeZoneConvertion[selectedTimeZone])
        let time = time1.toString().padStart(5, '0');
        const dateObj = new Date(`1970-01-01T${time}:00`);
        dateObj.setHours(dateObj.getHours() + diff);
        const outputStr = `${dateObj.getHours().toString().padStart(2, '0')}:${dateObj.getMinutes().toString().padStart(2, '0')}`;
        return outputStr;
    }

    return (
        loading ? <Loading /> : !isDataAvailable ? <NoData /> : <>
            <div className='siignal-w100'>
                <GridLayout
                    className='siignal-w100'
                    align={{ horizontal: 'center', }}
                    cols={[{ width: "auto" }]}
                    gap={{ rows: 3, cols: 3, }}>

                    <GridLayoutItem className='siignal-w100' colSpan={5}>
                        <div className="k-hbox">
                            <p className="skillToCell-modulePresentation" xs={{ paddingTop: 3, margin: 3 }} align="center">
                                Ultima actualizacion: {lastUpdated}
                            </p>
                        </div>
                    </GridLayoutItem>

                    {/* ********************************** */}
                    <GridLayoutItem className='siignal-w100'>
                        <Button
                            disabled={shouldDisableUpdateBtn}
                            fillMode={"outline"}
                            size={'large'}
                            className='siignal-update-btn'
                            onClick={() => {
                                loadData()
                            }}>
                            🚀 Actualizar la informacion
                        </Button>
                    </GridLayoutItem>
                    <GridLayoutItem className='siignal-w100'></GridLayoutItem>
                    <GridLayoutItem className='siignal-w100'>
                        <MenuNavigation xs={4} item optionSelect={(ev) => {
                            setSelectedView({
                                country: ev,
                                cell: Object.keys(data[ev])[0],
                                view: selectedView.view
                            })
                        }} options={Object.keys(data)} name="Pais" />
                    </GridLayoutItem>
                    <GridLayoutItem className='siignal-w100'>
                        <MenuNavigation xs={4} item optionSelect={(ev) => {
                            setSelectedView({
                                country: selectedView.country,
                                cell: ev,
                                view: selectedView.view
                            })
                        }} options={Object.keys(data[selectedView.country])} name="Celula" />
                    </GridLayoutItem>
                    <GridLayoutItem className='siignal-w100'>
                        <MenuNavigation xs={4} item optionSelect={(ev) => {
                            setSelectedView({
                                country: selectedView.country,
                                cell: selectedView.cell,
                                view: ev
                            })
                        }} options={["Staff", "Volumen"]} name="Seccion" />
                    </GridLayoutItem>
                    {/* ********************************** */}
                    <GridLayoutItem className={`siignal-w100`} colSpan={5}>
                        <KPIScorecard scorecardsData={data[selectedView.country][selectedView.cell]} selectedView={selectedView} />
                    </GridLayoutItem>

                    <GridLayoutItem className={`siignal-w100`} colSpan={5}>
                        <SiignalChart chartData={data[selectedView.country][selectedView.cell]} selectedView={selectedView} />
                    </GridLayoutItem>

                    <GridLayoutItem className={`siignal-w100`} colSpan={5}>
                        <SiignalTable tableData={data[selectedView.country][selectedView.cell]} selectedView={selectedView} />
                    </GridLayoutItem>
                </GridLayout>

                <NotificationGroup style={{
                    right: 0,
                    bottom: 0,
                    alignItems: 'flex-start',
                    flexWrap: 'wrap-reverse'
                }}>
                    <Fade>
                        {notification.notification && <Notification type={{
                            style: notification.notificationType,
                            icon: true
                        }} closable={false}>
                            <span>{notification.notificationMessage}</span>
                        </Notification>}
                    </Fade>
                </NotificationGroup>
            </div>
        </>

    );
}
export default ForecastVsReal;

