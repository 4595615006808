
export const todoReducer =(initialState= [], action )=>{
                                    
   switch ( action.type){
      case '[TODO] Add Todo' :
         return [...initialState,action.payload]
      case '[TODO] Remove Todo' :
         return initialState.filter(todo=>todo.idSeguimientoAliado!==action.payload)
      case '[TODO] doneCheck Todo' :
         return initialState.map(todo=>{
            if(todo.idSeguimientoAliado===action.payload.idSeguimientoAliado){
               return{
                  ...todo,
                  atencion:action.payload.atencion
               }
            }
            return todo;
            })
      default:
         return initialState;
   }
}