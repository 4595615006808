import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { FieldWrapper } from "@progress/kendo-react-form";
import { useState } from "react";
import {  Hint,Error } from "@progress/kendo-react-labels";
import { Loader } from "@progress/kendo-react-indicators";
import { UseSearchdirectory } from "../../hooks/UseSearchdirectory";
export const DropdownWorkers = (fieldRenderProps) => {
  const [selectedEmail, setSelectedEmail] = useState("");
    const { search,loading,listNames,searchByemail }=UseSearchdirectory();

    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        type,
        value,
        width,
        optional,
        ...others
      } = fieldRenderProps;

    const [timer, setTimer] = useState(null);
  
  const showValidationMessage = touched && validationMessage;
      const showHint = !showValidationMessage && hint;
      const hintId = showHint ? `${id}_hint` : '';
      const errorId = showValidationMessage ? `${id}_error` : '';
  

  const handleSearch = (event) => {
    
    const inputValue = event.target.value;
    const selectedItem = listNames.find(item => item.name === inputValue);

    if (selectedItem) {
        // Si el usuario selecciona un nombre de la lista
        setSelectedEmail(selectedItem.email); // Actualiza el email seleccionado
        fieldRenderProps.onChange({
            value: selectedItem.email, // Actualiza el valor mostrado con el nombre
        });
    } else {
        // Si el usuario está escribiendo o borrando texto, y no es una selección de la lista
        setSelectedEmail(""); // Limpia el email seleccionado
        fieldRenderProps.onChange({
            value: inputValue, // Actualiza el valor mostrado con el texto ingresado
        });
    }
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
        search(event.value)
    }, 300); // 1000 ms = 1 segundo
    setTimer(newTimer);
  };

  return (
    <FieldWrapper>
    <div>
      <div>{label}</div>
      <AutoComplete
        valid={valid} type={type} id={id} disabled={disabled} ariaDescribedBy={`${hintId} ${errorId}`} {...others}
        data={listNames}
        onChange={handleSearch}
        value={value}
        textField="name"
        placeholder="Escribe aquí el nombre"
        style={{ width: width }}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      {loading && <Loader size="medium" />}
    </div>
    </FieldWrapper>
  )
}

