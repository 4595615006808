
import {Field} from '@progress/kendo-react-form';

import { useState } from 'react';
import { yes_no } from '../../components/RequestForm/data.jsx';
import { FormInput, FormRadioGroup } from '../../components/RequestForm/form-components.js';
import { RadioButtonValidator, numTicketsValidator, requiredValidator } from '../../components/RequestForm/Validators.js';
export const Step3IncidenciaYrequerimiento = (props) => {
    const [enableOtherPanel, setEnableOtherPanel] = useState(false);

    const handleChangeChild = (event) => {
		if(event.value){
			setEnableOtherPanel(true)
		}else{
			setEnableOtherPanel(false)
		}
    };

	return(
		<div>
		<Field
			key={'previousTickets'}
			id={'previousTickets'}
			name={'previousTickets'}
			label={'¿Hay un ticket relacionado con este?'}
			layout={'horizontal'}		
			onChange={handleChangeChild}	
			component={FormRadioGroup}
			data={yes_no}
			validator={RadioButtonValidator}
		/>
		{enableOtherPanel&&
			<Field
			key={'associatedTicketNumber'}
			id={'associatedTicketNumber'}
			name={'associatedTicketNumber'}
			label={'Indica el número del ticket previo:'}
			component={FormInput}
			validator={numTicketsValidator}
		/>
		}
	</div>
	);
}
