import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'
import * as FaIcons from 'react-icons/fa'

function UniqueDeskGeneral() {
    const [links, setLinks] = useState([]);
    const elements = [];
    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        axios.get(`/api/UniquedeskData`).then(res => {
            let data_array = res.data[0]
            setLinks(data_array);
        }).catch(err => {
            alert("Se ha presentado un error");
        });
    }
    Object.entries(links).forEach(([key, value], index) => {
        if(index==0){
            console.log(value)
        }else{
            if(index==6){
                elements.push(
                    <div className="col-6-SDR">
                        <div className='status-card'>
                            <div className="status-card__icon">
                                <FaIcons.FaAddressBook/>
                            </div>
                            <div className="status-card__info">
                                <Link to={value} target="_blank">{key}</Link>
                            </div>
                        </div>
                    </div>
                );
            }else{
                    elements.push(
                        <div className="col-6-SDR">
                            <div className='status-card'>
                                <div className="status-card__icon">
                                    <FaIcons.FaSafari/>
                                </div>
                                <div className="status-card__info">
                                    <Link to={value} target="_blank">{key}</Link>
                                </div>
                            </div>
                        </div>
                    );
                }
        }
      });
    return (
        <div>
            <h2 className='page-header'>Unique Desk</h2>
            <ul>
                <section className='status-cards-container'>
                    <>{elements}</>
                </section>
            </ul>
        </div>
    );
}

export {UniqueDeskGeneral};