import React from 'react';


import {
	Chart,
	ChartCategoryAxis,
	ChartCategoryAxisItem,
	ChartSeries,
	ChartSeriesItem,
	ChartTitle,
} from '@progress/kendo-react-charts';
import 'hammerjs';

const [firstSeries] = [
	[
		1, 2, 3, 5, 4, 6, 7, 3, 1, 3, 5, 5, 0, 0, 5, 6, 4, 6, 6, 4, 3, 6, 5, 6, 6,
		4, 3, 2, 2, 5, 1,
	],
];
function getDaysInMonth() {
	let now = new Date();
	return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
}
export const LineCharts = () => {
	let daysInMonth = getDaysInMonth();
	let categories = Array.from({length: daysInMonth}, (_, i) => i + 1);
	return (
		<div>
			<Chart style={{Width:'100%',height:'300px'}}>
				<ChartTitle text="Número de tickets por día" />
				<ChartCategoryAxis>
					<ChartCategoryAxisItem categories={categories} startAngle={45} />
				</ChartCategoryAxis>
				<ChartSeries>
					<ChartSeriesItem type="column" data={firstSeries} color={'#00244C'}/>
				</ChartSeries>
			</Chart>
		</div>
	);
};
