import React, {useState, useEffect } from 'react';
import ButtonCard from '../component/ButtonsCards';
import {useParams} from 'react-router-dom';
import {exportNestedObject} from '../utils/nestedObjectDiagram';
import {getDaigramStorage} from '../utils/localStorageDiagram';
import '../../components/status-card/statuscard.css';
import { CircularProgress } from '@mui/material';

export default function ReportsPage() {

	//*Por aca empieza todo, y también esta será la pgina que reutilizara cada vez que tenga que graficar los botones tantas veces sea */

	const [error, setError] = useState(null);
	const [thisDiagram, setThisDiagram] = useState(null);
	const [isloading, setIsloading] = useState(true);
  const [titulo,setTitulo]=useState("Reportes")
	const [rute, setRute] = useState(null);
	const [data, setData] = useState(null);
	const actualRute = useParams().actualRute; //esta es la ruta que debe traer para ir en profundidad el diagrama de Json que será el que da la gui de la organizacion de los botones
	const diagram = getDaigramStorage();//este es diagrama entero de lso botones


	useEffect(() => {
		setRute(decodeURIComponent(actualRute).split('/'));
		setThisDiagram(diagram);
    	//setTitulo(actualRute?actualRute:"Reportes")
		let title=(actualRute?actualRute.split("/"):actualRute); //titulo de la pagina de los reportes
    	setTitulo(actualRute?`Reportes de ${title[title.length-1]}`:"Reportes")
	}, [actualRute]);

	useEffect(() => {
		const chargeData = async () => {
			if (!thisDiagram) {
				setIsloading(true);
			} else {
				let datish = await exportNestedObject(diagram, rute); //esto es el encaargado de traer clave valor del JSon en la posicion de la ruta
				setData(datish);
			}
			setIsloading(false);
		};
		chargeData();
	}, [rute, thisDiagram]);

  
  

	return (
		<div>
			{isloading ? (
				<h1>Cargando...</h1>
			) : error ? (
				<p>{error}</p>
			) : (
				<div>
					<h2 className="page-header">{titulo}</h2>
					{data ? (
						Object.keys(data).map((label) => (
							<div key={label}>
								<h3 className="page-subheader">{label}</h3>
								<section className="status-cards-container">
									{data[label].map((child) => (
										<div className="col-6-SDR" key={child}>
											<ButtonCard
                        						setTitulo={setTitulo}
												nombreDelBoton={child}
												actualRute={
													actualRute
														? `${actualRute}/${label}/${child}`
														: `${label}/${child}`
												}
											/>
										</div>
									))}
								</section>
							</div>
						))
					) : (
						<div className='desktop-view-SDR'>
                    <div id='reportContainerSDR'>
                    <div className='centerDS'><CircularProgress/></div>
                    </div>
                </div>
					)}
				</div>
			)}
			<div></div>
		</div>
	);
}


