export const config = {
    auth: {
        clientId: 'dbd2fb65-e26f-475f-b666-666874b3430d',
        redirectUri: process.env.REDIRECT_URI,
        authority: 'https://login.microsoftonline.com/3a7479db-d9ff-4e0b-9d0a-380a5e714f6f'
    },
    //https://datasmart.siigolatam.com/home
    cache: {
        cacheLocation: 'SessionStorage',
        storeAuthStateInCookie: true,
    }
};

export const loginRequest = {
    scopes: ['.default'],
}


export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/beta/me/',
    graphPhotoEndpoint: 'https://graph.microsoft.com/v1.0/me/photo/$value',
    graphCalendarEndpoint: 'https://graph.microsoft.com/beta/me/events?$select=subject,body,bodyPreview,organizer,attendees,start,end,location',
    graphGroupEndpoint: 'https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true',
    graphMyProfilepoint: 'https://graph.microsoft.com/v1.0/me',
    graphSearchPeople: 'https://graph.microsoft.com/beta/users?$search='
}




//V_T8Q~2y4pCkfb0WgFMUup9HAY7AcM~tByrSTaC4

