import { CircularProgress } from '@mui/material';

function Loading() {
    return (
        <>
            <div className='desktop-view-SDR'>
                <div id='reportContainerSDR'>
                    <div className='centerDS'><CircularProgress />
                        <br />
                        <p>Cargando</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export { Loading };