import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../Config";
import { ProcessTreeRenewalsDashboard } from '../../components/Dashboards/Customer/ProcessTreeRenewals/ProcessTreeRenewals'
import { CircularProgress } from '@mui/material';


export const ProcessTreeRenewals = () => {
    const { instance, accounts } = useMsal();
    const email = sessionStorage.getItem('usr')
    const mail = 'gome111471@siigo.com' //'beja36011@siigo.com'
    const name = sessionStorage.getItem('name')
    const [isloading, setLoading] = useState(true);
    const [resultsAsesor, setResultsAsesor] = useState(0);

    const request = {
        ...loginRequest,
        account: accounts[0]
    };

    useEffect(() => {
        const loadGet = async () => {

            // Till the data is fetch using API 
            // the Loading page will show.

            // Await make wait until that 
            // promise settles and return its result
            // const response = await axios.get(`/api/OPC?id=${mail}`);
            // After fetching data stored it in posts state.
            // setResultsAsesor(response.data)
            // Closed the loading page
            setLoading(false);
        }

        // Call the function
        loadGet();
    }, []);
    // debugger




    let renderView;
    if (isloading) {
        return <div className='desktop-view-SDR'>
            <div id='reportContainerSDR'>
                <div className='centerDS'><CircularProgress /></div>
            </div>
        </div>
    } else
        if (sessionStorage.getItem('role') === 'portalAdmin' || sessionStorage.getItem('role') === 'cLevel') {
            renderView = <ProcessTreeRenewalsDashboard />
        } else if (sessionStorage.getItem('area') === 'Customer Success' || sessionStorage.getItem('area') === 'Soporte Tecnico') {
            if (sessionStorage.getItem('role') === 'director' || sessionStorage.getItem('role') === 'wfManager' || sessionStorage.getItem('role') === 'Analyst') {
                renderView = <ProcessTreeRenewalsDashboard />
            }
        } else {
            renderView = <div className='desktop-view-SDR'>
                <div id='reportContainerSDR'>
                    <div className='centerDS'>No eres un usuario registrado, lo sentimos</div>
                </div>
            </div>
        }
    return (
        <>
            {/* <div>{renderView}</div> */}
            <ProcessTreeRenewalsDashboard />
        </>
    )
}


