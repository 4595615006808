import {useEffect, useState} from 'react';
import {MiddleList} from '../components/MidleList';
import {
	FaPhone,
	FaEnvelope,
	FaWhatsapp,
	FaHandshake,
	FaBusinessTime,
	FaBriefcase,
} from 'react-icons/fa';
const VentasNube = () => {
	let dataComercial = sessionStorage.getItem('dataComercial');
	let ventasNube;
	if (dataComercial) {
		({ventasNube} = JSON.parse(dataComercial));
	} else {
		ventasNube = [];
	}
	return (
		<div className="dashboard-card-content">
			<div className="columns-home">
				<div>
					<div className='VentasNubeStyle'>
						<p>Ventas mes</p>
						<div className="linea-home"></div>
						<p>
							<span style={{fontWeight: 'bold'}}>20.5</span> Días
						</p>
					</div>
				</div>
				<div className="dashboard-card-content" style={{marginLeft: '10px'}}>
					<p className="dashboard-card-content-number">{ventasNube}</p>
				</div>
			</div>
			<div>
				<p className="footer"></p>
			</div>
		</div>
	);
};
const Contactos = () => {
	let dataComercial = sessionStorage.getItem('dataComercial');
	const [count, setCount] = useState(0);
	let cantidadContactos;
	if (dataComercial) {
		({cantidadContactos} = JSON.parse(dataComercial));
		if (cantidadContactos === undefined) {
			cantidadContactos = {
				"Whatsapp": 0,
				"Llamada": 0,
				"Correo": 0
			};
		}
	} else {
		cantidadContactos = [];
	}
	useEffect(() => {
		let countAux = 0;
		for (let key in cantidadContactos) {
			if (cantidadContactos.hasOwnProperty(key)) {
				try {
					countAux += parseInt(cantidadContactos[key]);
				} catch (error) {
					countAux += 0;
				}
			}
		}
		setCount(countAux);
	}, [cantidadContactos]);
	// "cantidadContactos" : [
	//     {
	//         "Llamada" : NumberInt(14)
	//     },
	//     {
	//         "Whatsapp" : NumberInt(2)
	//     },
	//     {
	//         "Correo" : NumberInt(0)
	//     }
	// ]
	const iconSelector = (key) => {
		switch (key) {
			case 'Llamada':
				return <FaPhone />;
			case 'Whatsapp':
				return <FaWhatsapp />;
			case 'Correo':
				return <FaEnvelope />;
			default:
				return null;
		}
	};
	return (
		<div className="dashboard-card-content">
			<div className="card-content">
				<ul>
					{cantidadContactos &&
						Object.entries(cantidadContactos)?.map(([key, value], index) => (
							<li key={index} className="li-customer">
								<span className="icon-home">{iconSelector(key)}</span> {key}: {value}
							</li>
						))}
				</ul>
				<div>
					<p className="footer-home">
						Cantidad de Contactos{' '}
						<span>
							{count}
						</span>
					</p>
				</div>
			</div>
		</div>
	);
};
const AtrasadosCard = () => {
	let dataComercial = sessionStorage.getItem('dataComercial');
	const [count, setCount] = useState(0);
	let seguimientosAtrasados;
	if (dataComercial) {
		try {
			({seguimientosAtrasados} = JSON.parse(dataComercial));
			if (seguimientosAtrasados === undefined) {
				seguimientosAtrasados ={
					"Aliados": 0,
					"Comerciales": 0
				  }
			}
		} catch (error) {
			seguimientosAtrasados = {
				"Aliados": 0,
				"Comerciales": 0
			  };
		}
	} else {
		seguimientosAtrasados = {
			"Aliados": 0,
			"Comerciales": 0
		  };
	}

	useEffect(() => {
		let countAux = 0;
		for (let key in seguimientosAtrasados) {
			if (seguimientosAtrasados.hasOwnProperty(key)) {
				try {
					countAux += parseInt(seguimientosAtrasados[key]);
				} catch (error) {
					countAux += 0;
				}
			}
		}
		setCount(countAux);
	}, [seguimientosAtrasados]);
	const iconSelector = (key) => {
		switch (key) {
			case 'Aliados':
				return <FaHandshake />;
			case 'Comerciales':
				return <FaBriefcase />;
			default:
				return null;
		}
	};

	return (
		<div className="dashboard-card-content">
			<div className="card-content">
				<ul>
					{seguimientosAtrasados &&
						Object.entries(seguimientosAtrasados)?.map(
							([key, valor], index) => {
								return (
									<li key={index} className="li-customer">
										<span className="icon-home">{iconSelector(key)}</span> {key}: {valor}
									</li>
								);
							}
						)}
				</ul>
				<div>
					<p className="footer-home">
						Seguimientos Atrasados{' '}
						<span>
							{count}
						</span>
					</p>
				</div>
			</div>
		</div>
	);
};
const Cumpllimiento = () => {
	let dataComercial = sessionStorage.getItem('dataComercial');
    let cumplimiento;

    if (dataComercial) {
        ({cumplimiento} = JSON.parse(dataComercial));

        if (typeof cumplimiento === 'number') {
            // Primero limitar a dos decimales, luego convertir a número
            cumplimiento = parseFloat(cumplimiento.toFixed(2));
            // Multiplicar por 100 y redondear para evitar imprecisiones de punto flotante
            cumplimiento = Math.round(cumplimiento * 100);
        } else {
            cumplimiento = 0;
        }
    } else {
        cumplimiento = 0;
    }

	return (
		<div className="dashboard-card-content">
			<p className="dashboard-card-content-number">{cumplimiento }%</p>
			<div>
				<p className="footer"></p>
			</div>
		</div>
	);
};
const Efectividad = () => {
	let dataComercial = sessionStorage.getItem('dataComercial');
	let efectividad;
	if (dataComercial) {
		({efectividad} = JSON.parse(dataComercial));
		efectividad = parseFloat(efectividad.toFixed(2));
	} else {
		efectividad = [];
	}
	return (
		<div className="dashboard-card-content">
			<p className={`dashboard-card-content-number ${efectividad === 0 ? 'gray' : ''}`}>{efectividad}%</p>
			<div>
				<p className="footer"></p>
			</div>
		</div>
	);
};
const PromedioCreacion = () => {
	let dataComercial = sessionStorage.getItem('dataComercial');
	let promediocreacionRecomendados;
	if (dataComercial) {
		({promediocreacionRecomendados} = JSON.parse(dataComercial));
		promediocreacionRecomendados = parseFloat(
			promediocreacionRecomendados.toFixed(2)
		);
	} else {
		promediocreacionRecomendados = 0;
	}
	return (
		<div className="dashboard-card-content">
			<div style={{display:'flex',justifyContent:'space-around'}}>
				<div className='AverageCreacionRecomendados'>
					<p>Creación Recomendados</p>
				</div>
				<div>
					<p className="dashboard-card-content-number">{promediocreacionRecomendados}</p>
				</div>
			</div>
		</div>
	);
};

export const tiles = [
	{
		defaultPosition: {
			col: 1,
			colSpan: 2,
			rowSpan: 1,
		} ,
		header: '% Cumplimiento',
		body: <Cumpllimiento />,
	},
	{
		defaultPosition: {
			col: 3,
			colSpan: 2,
			rowSpan: 1,
		},
		header: 'Ventas Nube',
		body: <VentasNube />,
	},
	{
		defaultPosition: {
			col: 5,
			colSpan: 2,
			rowSpan: 1,
		}		,
		header: 'Efectividad',
		body: <Efectividad />,
		resizable: 'vertical',
	},
	{
		defaultPosition: {
			col: 7,
			colSpan: 2,
			rowSpan: 1,
		},
		header: 'Promedio',
		body: <PromedioCreacion />,
		resizable: 'vertical',
	},
	{
		defaultPosition: {
			col: 7,
			colSpan: 2,
			rowSpan: 4,
		},
		body: (
			<div className="dashboard-card-content">
				<MiddleList
					title={'Avances 0% y al 5%'}
					valueInit={'tareasRealizarMenor5'}
				/>
			</div>
		),
		resizable: 'vertical',
		reorderable: false,
	},
	{
		defaultPosition: {
			col: 5,
			colSpan: 2,
			rowSpan: 4,
		},
		body: (
			<div className="dashboard-card-content">
				<MiddleList
					title={'Seguimientos al 30%'}
					valueInit={'tareasRealizarMenor30'}
				/>
			</div>
		),
		resizable: 'vertical',
		reorderable: false,
	},
	{
		defaultPosition: {
			col: 3,
			colSpan: 2,
			rowSpan: 4,
		},
		body: (
			<div className="dashboard-card-content">
			<MiddleList
				title={'Seguimientos al 80%'}
				valueInit={'tareasRealizarMayor80'}
			/>
			</div>
		),
		resizable: 'vertical',
		reorderable: false,
	},
	{
		defaultPosition: {
			col: 1,
			colSpan: 2,
			rowSpan: 2,
		},
		body: <AtrasadosCard />,
	},
	{
		defaultPosition: {
			col: 1,
			colSpan: 2,
			rowSpan: 2,
		},
		body: <Contactos />,
		resizable: 'horizontal',
	}
	
];
