import axios from 'axios';

export const validateIfExist = async (
	AsistansList,
	nombreCapacitacion,
	nombresTemas,
	statusCapacitaciones
) => {
	const labelsToVerify = [
		'Externo/Interno',
		'Documento',
		'Nombre Completo',
		'Correo',
		'Tema',
		'Nota',
		'Estado',
	];
	const labelsVerified = AsistansList && AsistansList[0];
	let result = '';

	if (JSON.stringify(labelsVerified) === JSON.stringify(labelsToVerify)) {
		let assistansArray = AsistansList?.slice(1);
		const dataCheck = {
			asistans: assistansArray,
			labelsToVerify,
			nombreCapacitacion,
			nombresTemas,
			statusCapacitaciones,
		};

		try {
			const response = await axios.post(
				`/api/TNB/checkMasiveRegister`,
				dataCheck
			);
			let data = response.data;
			result = theListToBuildTheBodyOfTheTable(
				data,
				assistansArray,
				labelsToVerify
			);
		} catch (error) {
			console.error('Error en la petición:', error);
			result = 'Error en la petición';
		}
	} else {
		result = 'No modifique el encabezado de la matriz por favor';
	}

	return result;
};

function theListToBuildTheBodyOfTheTable(
	result,
	assistansArray,
	labelsToVerify
) {
	const ListToBuildTheBodyOfTheTable = [];
	const fieldwrong = [];

	result.forEach((element, index) => {
		if (typeof element !== 'boolean') {
			ListToBuildTheBodyOfTheTable.push(assistansArray[index]);
			fieldwrong.push(element);
		}
	});

	return rellenar(ListToBuildTheBodyOfTheTable, fieldwrong, labelsToVerify);
}

//['Externo/Interno','Documento','Nombre Completo','Correo','Tema','Nota','Estado']
function rellenar(ListToBuildTheBodyOfTheTable, fieldwrong, labelsToVerify) {
	let aux = 0;
	let aux2 = 0;
	let fieldforSearch = '';
	let tablaHTML = ListToBuildTheBodyOfTheTable.map((registro) => {
		fieldforSearch = labelsToVerify.indexOf(fieldwrong[aux]);
		aux2 = -1;
		let fila = registro
			.map((field) => {
				aux2++;
				if (aux2 === fieldforSearch) {
					return `<td><p style="color: red;">${field}</p></td>`;
				} else {
					return `<td><p>${field}</p></td>`;
				}
			})
			.join(''); // Convierte el array de celdas en una única cadena
		aux++;
		return `<tr>${fila}</tr>`;
	}).join(''); // Convierte el array de filas en una única cadena

	return `<table><tbody><tr>
<td><p>Externo/Interno</p></td>
<td><p>Documento</p></td>
<td><p>Nombre Completo</p></td>
<td><p>Correo</p></td>
<td><p>Tema</p></td>
<td><p>Nota</p></td>
<td><p>Estado</p></td>				
</tr>${tablaHTML}</tbody></table>`; // Retorna la tabla completa
}


{/* <tr>
    <td><p style="font-weight: bold;">Estos fueron los registros que no se pudieron Cargar</p></td>
			
</tr> */}