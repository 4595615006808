import React, { useState } from "react";
export default function NotasMx({ data }) {
    const [nota, setNota] = useState('');
    const mxTableResumeConfig = [
        { field: 'rfc', label: 'RFC' },
        { field: 'serial', label: 'Serie' },
        { field: 'producto', label: 'Producto' },
    ];

    const generateMxNote = (data) => {
        const {
            rfc,
            serial,
            producto,
            fechaExtendida,
            fechaCompromiso,
            valor,
            notas,
        } = data;

        let resumeContent = `RFC: ${rfc}\nSerial ${serial} del producto ${producto}\nFue programado el ${fechaCompromiso} para pagar máximo hasta el ${fechaExtendida} por un valor de ${valor}\nSe presentan las siguientes notas:\n\n${notas}`;

        const textarea = document.createElement('textarea');
        textarea.value = resumeContent;

        // Make the textarea non-editable to be able to copy its content
        textarea.setAttribute('readonly', '');

        // Hide the textarea from view
        textarea.style.position = 'absolute';
        textarea.style.left = '-9999px';

        document.body.appendChild(textarea);

        // Select and copy the content to the clipboard
        textarea.select();
        document.execCommand('copy');

        // Remove the textarea from the DOM
        document.body.removeChild(textarea);
    };
    return (
        <div>
            <table className="siignalRenewalsTable">
                <thead>
                    <tr>
                        {mxTableResumeConfig.map((el) => {
                            return <th>{el.label}</th>;
                        })}
                        <th>
                            Accion
                        </th>
                    </tr>

                </thead>
                <tbody>
                    {data.map((record) => {
                        return (
                            <tr>
                                {mxTableResumeConfig.map((serie) => {
                                    return (
                                        <td>
                                            {record[serie.field]}
                                        </td>
                                    );
                                })}
                                <td>
                                    <button
                                        onClick={() => {
                                            setNota(generateMxNote(record));
                                        }}
                                    >
                                        Copiar
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    )
}