import React from 'react'
import ExecutionTimesBar from './ExecutionTimesBar'

export const SecondSlice = ({max=1,min=0,avg=1}) => {

    
  return (
    <div>
        <h6>Tiempos de Ejecución</h6>
        <ExecutionTimesBar min={min} avg={avg} max={max}/>
    
    </div>
  )
}
