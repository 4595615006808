import React, { useRef, useEffect, useState } from 'react';
import { EditorView, basicSetup } from "codemirror"
import { keymap } from '@codemirror/view';
import { EditorState } from "@codemirror/state"
import { javascript } from "@codemirror/lang-javascript"
import { oneDark } from '@codemirror/theme-one-dark';
import { defaultKeymap, indentWithTab } from '@codemirror/commands';

const CodeEditor = ({ }) => {
  const editor = useRef();
  const [code, setCode] = useState('');

  const onUpdate = EditorView.updateListener.of((v) => {
    setCode(v.state.doc.toString());
  });

  useEffect(() => {
    console.log(EditorState.create);
    let startState = EditorState.create({
      doc: `// Use the power of JavaScript
// use ctrl + space for autocomplete`,
      extensions: [
        basicSetup,
        javascript(),
        keymap.of([defaultKeymap, indentWithTab]),
        oneDark,
        onUpdate,
      ],
    })

    let view = new EditorView({
      state: startState,
      // extensions: [basicSetup, javascript()],
      parent: editor.current,
    })

    return () => {
      view.destroy();
    };
  }, []);

  return <>
    <div ref={editor}></div>
    <hr />
    <div>
      {
        code
      }
    </div>
  </>
};

export default CodeEditor;