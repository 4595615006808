import {useState} from 'react';
import '../style/helpDesk_Lobby.css';
import PairButtons from '../components/Lobby/PairButtons';
import { RequestOpened } from '../components/Lobby/MyRequest/RequestOpened';
import { useSelector } from 'react-redux';
import { selectCredencialesArea, selectCredencialesName, selectCredencialesRole } from '../../redux/credencialesReducer';
import { IsAuthorized } from '../../components/auth/IsAuthorized';


export const Lobby = () => {
	const name=useSelector(selectCredencialesName);
    const area = useSelector(selectCredencialesArea);
    const rol=useSelector(selectCredencialesRole);
    const isAllowed={
		"Data Partner": IsAuthorized('HelpDesk-dataPartner',area,rol ),
		"Resolver": IsAuthorized('HelpDesk-resolver',area,rol ),
	}

	const [section, setSection] = useState('Mi Espacio');
//sessionStorage.getItem('subrole')
	let jmodules = [
		{
			name: 'Nueva Solicitud',
			link: `RegisterRequest/Solicitud`,
			visible: true,
			icon: 'plusIcon',
			section: 'Mi Espacio',
		},
		{
			name: 'Administrar Solicitudes',
			link: 'MyJob',
			visible: true,
			icon: 'gridLayoutIcon',
			section: 'Mi Espacio',
		},
		{
			name: 'Mi Trabajo',
			link: 'MyJob',
			visible: true,
			icon: 'homeIcon',
			section: 'Mi Espacio',
		},
	];

	if (isAllowed["Data Partner"]) {
		jmodules = jmodules.map((module) => {
			if (module.name === 'Mi Trabajo') {
				return {...module, visible: false};
			}
			return module;
		});
	} else if( isAllowed["Resolver"]){ 
		jmodules = jmodules.map((module) => {
			if (
				module.name === 'Administrar Solicitudes' ||
				module.name === 'Aprobar Solicitud'
			) {
				return {...module, visible: false};
			}
			return module;
		});
	}
	if (!isAllowed["Data Partner"] && !isAllowed["Resolver"]) {
		jmodules = jmodules.map((module) => {
			if (
				module.name === 'Administrar Solicitudes' ||
				module.name === 'Aprobar Solicitud' ||
				module.name === 'Mi Trabajo'
			) {
				return {...module, visible: false};
			}
			return module;
		});
	}

	return (
		<div className="contenedor-helpDesk">
			<div className="contenedor-helpDesk-child">
				<div className="helpdesk_image">
					<div className="helpdesk_centrar">
						<h3>Help Desk</h3>
					</div>
				</div>
				<div className='rayitaa'></div>
				<div className="HelpDesk_Botones-principales">
					<PairButtons jmodules={jmodules} section={section} />
				</div>
				<br />
			</div>
			<div className="contenedor-helpDesk-child">
				<RequestOpened />
			</div>
		</div>
	);
};
