import React, { useState } from 'react';
import {validateIfExist} from './validateIfExist'

import { EditorText } from './EditorText';
import { useSelector } from 'react-redux';
import { selectAllStatusCapacitacion, selectDetalleCapacitacion } from '../../../redux/TNBSlice';
import { ChargingBar } from './ChargingBar';

export const ChargeMassive = ({status}) => {
 
    const {Temas,nombreCapacitacion} = useSelector(selectDetalleCapacitacion)
    const nombresTemas = Temas.map(actividad => actividad.nombreActividad);
    const statusCapacitaciones=useSelector(selectAllStatusCapacitacion)
    const [flagAfterCharge,setFlagAfterCharge]=useState(false);
    const [numAssistans,setNumAssistants]=useState()
    const [InvalidRegisters, setInvalidRegisters] = useState("")

    const onClickUpload=async(AsistansList) => {
        setNumAssistants(AsistansList.length)
        setInvalidRegisters (await validateIfExist(AsistansList,nombreCapacitacion,nombresTemas,statusCapacitaciones));
        setFlagAfterCharge(false) 
    }
    
    
	
	if(flagAfterCharge){
		return (
			<>
				<ChargingBar mensaje={"Estamos procesando tu solicitud. Este proceso puede llevar un tiempo"} time={numAssistans}/>
			</>
		)
	}
    return (
        <EditorText setFlagAfterCharge={setFlagAfterCharge} onClickUpload={onClickUpload} tablaHTMLResult={InvalidRegisters} status={status}/>
    );
};
