import React, { useEffect, useState } from 'react';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { Loading } from '../../Siignal/Loading/Loading';
import { FormOptions, Wrapper, Breadcrumb } from './../Components';
import { useSelector, useDispatch } from 'react-redux';
import { setFormData, setCurrentStep, setSelectionHistory, setSelectionHistoryIds, setShapesData, setConectionsData } from '../../../../../redux/renewalsTreeReducer';
import { selectFormData, selectCurrentStep, selectSelectionHistory, selectSelectionHistoryIds, selectShapesData, selectConectionsData } from '../../../../../redux/renewalsTreeReducer';
import axios from 'axios';
import './Agent.css';

export default function StepperForm() {
  const images = {
    blue: require("../images/blue.png"),
    green: require("../images/green.png"),
    pink: require("../images/pink.png"),
    purple: require("../images/purple.png"),
    orange: require("../images/orange.png"),
  }

  let imagesArr = Object.keys(images)
  let [randomImage, setRandomImage] = React.useState();
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch();
  const shapesData = useSelector(selectShapesData);

  const formData = useSelector(selectFormData);
  const currentStep = useSelector(selectCurrentStep);
  const selectionHistory = useSelector(selectSelectionHistory);
  const selectionHistoryIds = useSelector(selectSelectionHistoryIds);

  useEffect(() => {
    let JSONData = parseShapesToFlow(shapesData);
    dispatch(setFormData(JSONData));
    dispatch(setCurrentStep(Object.keys(JSONData)[0]));
    dispatch(setSelectionHistory(['Inicio']));
    dispatch(setSelectionHistoryIds([Object.keys(JSONData)[0]]));
  }, [])


  const getRandomImage = () => {
    let random = Math.floor(Math.random() * imagesArr.length);
    setRandomImage({
      image: images[imagesArr[random]],
      color: imagesArr[random]
    });
  }

  useEffect(() => {
    // check if all are != null+
    if (formData && currentStep && selectionHistory && selectionHistoryIds) {
      setLoading(false)
      getRandomImage();
    }
  }, [formData, currentStep, selectionHistory, selectionHistoryIds])

  const parseShapesToFlow = (json) => {
    const result = {};

    for (let i = 0; i < json.length; i++) {
      const node = json[i];

      if (node.options.length > 0) {
        const options = {};
        for (const option of node.options) {
          options[option.target] = option.name;
        }
        result[node.key] = {
          title: node.title,
          options: options,
          documentation:
            node.documentation || 'Aun no hay recursos disponibles',
          data: node
        };
      } else {
        if (node.title == 'nuevo') {
          // 
        }
        result[node.key] = {
          transfer: node.title,
          schedule: node.schedule,
          documentation:
            node.documentation || 'Aun no hay recursos disponibles',
          data: node
        };
      }
    }
    return result;
  };

  const handleOptionChange = (event) => {
    let selecedOPtionForBreadcrumb = formData[currentStep].options[event.target.value];

    dispatch(setCurrentStep(event.target.value));
    dispatch(setSelectionHistory([...selectionHistory, selecedOPtionForBreadcrumb]));
    dispatch(setSelectionHistoryIds([...selectionHistoryIds, event.target.value]));
    getRandomImage();
  };

  const reset = () => {
    dispatch(setCurrentStep(Object.keys(formData)[0]));
    dispatch(setSelectionHistory(['Inicio']));
    dispatch(setSelectionHistoryIds([Object.keys(formData)[0]]));
    getRandomImage();
  }

  const navigateTo = (index) => {
    dispatch(setCurrentStep(selectionHistoryIds[index]));
    dispatch(setSelectionHistory(selectionHistory.slice(0, index + 1)));
    dispatch(setSelectionHistoryIds(selectionHistoryIds.slice(0, index + 1)));
    getRandomImage();
  }

  return (
    <GridLayout
      className='siignal-w100'
      align={{ horizontal: 'center', }}
      cols={[{ width: "auto" }]}
      gap={{ rows: 10, cols: 10, }}>

      <GridLayoutItem className='siignal-w100'>
        <span className='agent-renewals-title'>Asistente de Transferencias</span> -
        <span className='agent-renewals-subtitle'> Simplificando la transferencia, una interacción a la vez.</span>
        <span className='agent-renewals-version'>Versión 2.0</span>
      </GridLayoutItem>

      {
        loading ? <>
          Cargando...
        </> : <>
          <GridLayoutItem className={`siignal-box-shadow renewals-border-${randomImage.color}`}>
            <Breadcrumb
              selectionHistory={selectionHistory}
              navigateTo={(index) => { navigateTo(index) }}
              randomImage={randomImage}
            />
          </GridLayoutItem>

          <GridLayoutItem className={`siignal-box-shadow renewals-border-${randomImage.color}`}>
            <FormOptions
              currentStep={currentStep}
              formData={formData}
              handleOptionChange={handleOptionChange}
              reset={reset}
              randomImage={randomImage}
            />
          </GridLayoutItem>
        </>
      }
    </GridLayout>
  )
}
