import { CircularProgress } from '@mui/material';

function NoData() {
    return (
        <>
            <div className='desktop-view-SDR'>
                <div id='reportContainerSDR'>
                    <div className='centerDS'>
                        <h5>No existen datos disponibles</h5>
                    </div>
                </div>
            </div>
        </>
    );
}

export { NoData };