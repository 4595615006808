import { Alert } from '@mui/material';
import axios from 'axios';

const getWhatsAppConnectTemplates = (callback) => {
    axios.get('/api/whatsappConnect_templates').then((res) => {
        let resultData = res.data;
        callback(resultData);
    }).catch((err) => {
        alert('An error occurred.');
    });
};

const searchWhatsAppConnectTemplates = (key, value, callback) => {
    axios.get(`/api/whatsappConnect_templates/${key}/${value}`).then((res) => {
        let resultData = res.data;
        callback(resultData);
    }).catch((err) => {
        alert('An error occurred.');
    });
};

const getWhatsAppConnectCampaigns = (callback) => {
    axios.get('/api/whatsappConnect_campaigns').then((res) => {
        let resultData = res.data;
        callback(resultData);
    }).catch((err) => {
        alert('An error occurred.');
    });
};

const searchWhatsAppConnectCampaigns = (key, value, callback) => {
    axios.get(`/api/whatsappConnect_campaigns/${key}/${value}`).then((res) => {
        let resultData = res.data;
        callback(resultData);
    }).catch((err) => {
        alert('An error occurred.');
    });
};

const saveNewWhatsAppConnectCampaign = (campaign, callback) => {
    axios.post('/api/whatsappConnect_campaigns', campaign).then((res) => {
        callback(res);
    }).catch((err) => {
        alert('An error occurred.');
    });
};

const saveEditWhatsAppConnectCampaign = (id, campaign, callback) => {
    axios.patch(`/api/whatsappConnect_campaigns/${id}`, campaign).then((res) => {
        callback('Campaign updated');
        alert('Datos Actualizados')
    }).catch((err) => {
        alert('An error occurred.');
    });
};

const saveAdminEditWhatsAppConnectCampaign = (data, callback) => {
    axios.patch(`/api/whatsappConnectAdmin_campaigns`, data).then((res) => {
        callback('Campaign updated');
        alert('Datos actualizados')
    }).catch((err) => {
        alert('An error occurred.');
    });
}

export {
    getWhatsAppConnectTemplates,
    searchWhatsAppConnectTemplates,
    getWhatsAppConnectCampaigns,
    searchWhatsAppConnectCampaigns,
    saveNewWhatsAppConnectCampaign,
    saveEditWhatsAppConnectCampaign,
    saveAdminEditWhatsAppConnectCampaign
};
