import * as React from 'react';
import {Field} from '@progress/kendo-react-form';
import {
	FormAutoCompleteTopic,
	FormAutoCompleteTrainersTopics,
	FormAutoCompleteTopicType,
	FormDatePicker,
	FormNumericTextBox,
	FormAutoComplete,
} from './form-components';
import {requiredValidator, cardValidator, cvcValidator} from './validators';

export const PaymentDetails = (
	<div>
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',       
			}}>
			<div
				className="fieldFormTnb">
				<Field
					name={'topic1'}
					component={FormAutoCompleteTopic}
					label={'Tema'}
				/>
			</div>
			<div
				className="fieldFormTnb">
				<Field
					name={'trainer1'}
					component={FormAutoCompleteTrainersTopics}
					label={'Entrenador'}
				/>
			</div>
			<div
				className="fieldFormTnb">
				<Field
					name={'type1'}
					component={FormAutoCompleteTopicType}
					label={'Tipo'}
				/>
			</div>
			<div
				style={{
					width: '80px',
					marginRight: '10px',
				}}>
				<Field
					name={'duration1'}
					component={FormNumericTextBox}
					label={'Duración'}
				/>
			</div>
			<Field name={'date1'} component={FormDatePicker} label={'Fecha'} style={{
					width: '80px',
					marginRight: '10px',
				}} />
		</div>

		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
			}}>
			<div
				className="fieldFormTnb">
				<Field name={'topic2'} component={FormAutoCompleteTopic} />
			</div>
			<div
				className="fieldFormTnb">
				<Field name={'trainer2'} component={FormAutoCompleteTrainersTopics} />
			</div>
			<div
				className="fieldFormTnb">
				<Field name={'type2'} component={FormAutoCompleteTopicType} />
			</div>
			<div
				style={{
					width: '80px',
					marginRight: '10px',
				}}>
				<Field name={'duration2'} component={FormNumericTextBox} />
			</div >
			<Field  name={'date2'} component={FormDatePicker} style={{
					width: '80px',
					marginRight: '10px',
				}}/>
		</div>

		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
			}}>
			<div
				className="fieldFormTnb">
				<Field name={'topic3'} component={FormAutoCompleteTopic} />
			</div>
			<div
				className="fieldFormTnb">
				<Field name={'trainer3'} component={FormAutoCompleteTrainersTopics} />
			</div>
			<div
				className="fieldFormTnb">
				<Field name={'type3'} component={FormAutoCompleteTopicType} />
			</div>
			<div
				style={{
					width: '80px',
					marginRight: '10px',
				}}>
				<Field name={'duration3'} component={FormNumericTextBox} />
			</div>
			<Field name={'date3'} component={FormDatePicker} style={{
					width: '80px',
					marginRight: '10px',
				}}/>
		</div>

		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
			}}>
			<div
				className="fieldFormTnb">
				<Field name={'topic4'} component={FormAutoCompleteTopic} />
			</div>
			<div
				className="fieldFormTnb">
				<Field name={'trainer4'} component={FormAutoCompleteTrainersTopics} />
			</div>
			<div
				className="fieldFormTnb">
				<Field name={'type4'} component={FormAutoCompleteTopicType} />
			</div>
			<div
				style={{
					width: '80px',
					marginRight: '10px',
				}}>
				<Field name={'duration4'} component={FormNumericTextBox} />
			</div>
			<Field name={'date4'} component={FormDatePicker} style={{
					width: '80px',
					marginRight: '10px',
				}}/>
		</div>

		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
			}}>
			<div
				className="fieldFormTnb">
				<Field name={'topic5'} component={FormAutoCompleteTopic} />
			</div>
			<div
				className="fieldFormTnb">
				<Field name={'trainer5'} component={FormAutoCompleteTrainersTopics} />
			</div>
			<div
				className="fieldFormTnb">
				<Field name={'type5'} component={FormAutoCompleteTopicType} />
			</div>
			<div
				style={{
					width: '80px',
					marginRight: '10px',
				}}>
				<Field name={'duration5'} component={FormNumericTextBox} />
			</div>
			<Field name={'date5'} component={FormDatePicker} style={{
					width: '80px',
					marginRight: '10px',
				}}/>
		</div>

		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
			}}>
			<div
				className="fieldFormTnb">
				<Field name={'topic6'} component={FormAutoCompleteTopic} />
			</div>
			<div
				className="fieldFormTnb">
				<Field name={'trainer6'} component={FormAutoCompleteTrainersTopics} />
			</div>
			<div
				className="fieldFormTnb">
				<Field name={'type6'} component={FormAutoCompleteTopicType} />
			</div>
			<div
				style={{
					width: '80px',
					marginRight: '10px',
				}}>
				<Field name={'duration6'} component={FormNumericTextBox} />
			</div>
			<Field name={'date6'} component={FormDatePicker} style={{
					width: '80px',
					marginRight: '10px',
				}}/>
		</div>

		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
			}}>
			<div
				className="fieldFormTnb">
				<Field name={'topic7'} component={FormAutoCompleteTopic} />
			</div>
			<div
				className="fieldFormTnb">
				<Field name={'trainer7'} component={FormAutoCompleteTrainersTopics} />
			</div>
			<div
				className="fieldFormTnb">
				<Field name={'type7'} component={FormAutoCompleteTopicType} />
			</div>
			<div
				style={{
					width: '80px',
					marginRight: '10px',
				}}>
				<Field name={'duration7'} component={FormNumericTextBox} />
			</div>
			<Field name={'date7'} component={FormDatePicker} style={{
					width: '80px',
					marginRight: '10px',
				}}/>
		</div>

		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
			}}>
			<div
				className="fieldFormTnb">
				<Field name={'topic8'} component={FormAutoCompleteTopic} />
			</div>
			<div
				className="fieldFormTnb">
				<Field name={'trainer8'} component={FormAutoCompleteTrainersTopics} />
			</div>
			<div
				className="fieldFormTnb">
				<Field name={'type8'} component={FormAutoCompleteTopicType} />
			</div>
			<div
				style={{
					width: '80px',
					marginRight: '10px',
				}}>
				<Field name={'duration8'} component={FormNumericTextBox} />
			</div>
			<Field name={'date8'} component={FormDatePicker} style={{
					width: '80px',
					marginRight: '10px',
				}}/>
		</div>

		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
			}}>
			<div
				className="fieldFormTnb">
				<Field name={'topic9'} component={FormAutoCompleteTopic} />
			</div>
			<div
				className="fieldFormTnb">
				<Field name={'trainer9'} component={FormAutoCompleteTrainersTopics} />
			</div>
			<div
				className="fieldFormTnb">
				<Field name={'type9'} component={FormAutoCompleteTopicType} />
			</div>
			<div
				style={{
					width: '80px',
					marginRight: '10px',
				}}>
				<Field name={'duration9'} component={FormNumericTextBox} />
			</div>
			<Field name={'date9'} component={FormDatePicker} style={{
					width: '80px',
					marginRight: '10px',
				}}/>
		</div>

		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
			}}>
			<div
				className="fieldFormTnb">
				<Field name={'topic10'} component={FormAutoCompleteTopic} />
			</div>
			<div
				className="fieldFormTnb">
				<Field name={'trainer10'} component={FormAutoCompleteTrainersTopics} />
			</div>
			<div
				className="fieldFormTnb">
				<Field name={'type10'} component={FormAutoCompleteTopicType} />
			</div>
			<div
				style={{
					width: '80px',
					marginRight: '10px',
				}}>
				<Field name={'duration10'} component={FormNumericTextBox} />
			</div>
			<Field name={'date10'} component={FormDatePicker} style={{
					width: '80px',
					marginRight: '10px',
				}} />
		</div>
	</div>
);
