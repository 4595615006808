import { Label } from '@mui/icons-material';
import { Container, TextField, Box } from '@mui/material';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { formatMessage, loadMessages, locale } from 'devextreme/localization';
import DataGrid, {
    Column,
    Editing,
    Popup,
    Paging,
    Lookup,
    Form,
} from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { Item } from 'devextreme-react/form';
const notesEditorOptions = { height: 100 };
const IActivity = {
    idActivity: "",
    DescriptionActivity: "",
    objectives: "",
    assignedTo: "",        
    statusTask: "",        
    dateStart: "",
    dateEnd: "",                        
}
const ActivityCardJob = ({handlerActivity, Activitys,successMessage,errorMessage}) => {
    // JS
    locale(navigator.language);
    const arrActivity = [];
    const [currentActivity, setCurrentActivity] = useState(IActivity);
    const [loading, setLoading] = useState(false);
    const status = [{
        ID: 'Asignado',
        Name: 'Asignado'
    }, {
        ID: 'Proceso',
        Name: 'Proceso'
    },{
        ID: 'Revisión',
        Name: 'Revisión'
    },{
        ID: 'Cerrado',
        Name: 'Cerrado'
    }];    
    const onRowInserted = React.useCallback((e) => {
        e.cancel = true;
        if(e.component.totalCount() === 10){         
            e.element.childNodes[0].childNodes[3].childNodes[0].childNodes[0].childNodes[2].childNodes[0].hidden = true;
        }        
    }, []);
    const onRowRemoved = React.useCallback((e) => { 
        if(e.component.totalCount() < 10){
            e.element.childNodes[0].childNodes[3].childNodes[0].childNodes[0].childNodes[2].childNodes[0].hidden = false;            
        }
        if(e.component.totalCount() === 0){
            console.log('todos removidos');            
        }
    }, []);
    async function activitystatusChange(newActivity){
        setLoading(true);
        let api_url = '/api/activitystatusChange';
        // console.log('request',newActivity);
        const data = {"idActivity":newActivity['idActivity'],"ticketId":localStorage.getItem('idTicket'),"username":sessionStorage.getItem('name'),"statusTask":newActivity['statusTask'],"userEmail":sessionStorage.getItem('usr')};
        // console.log('lina1',data);
        let response = await axios.post(api_url,data,{
            headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Access-Control-Allow-Origin': '*'
        }});
        setLoading(false);
        return response;       
    }
    const onSaving = React.useCallback( (e) => {                
        let resp;
        let newStatus = e.changes[0].key;
        activitystatusChange({idActivity:newStatus.idActivity, statusTask:e.changes[0].data.statusTask}).then((respuesta)=>{
           resp=respuesta;
           alert(respuesta.data['message'])
           //console.log("respuesta",resp);
        });
    }, []);
    const onSaved = React.useCallback( (e) => {     
        //console.log('saved',e.component.getVisibleRows()); 
        let newActivity = {};
        e.component.getVisibleRows().forEach(element => {
            newActivity = {};
            newActivity = element.data;
            newActivity['idActivity'] = newActivity['__KEY__'];    
        });
          activitystatusChange(newActivity);
    }, []);
    // HTML
    return (
        <Container>
                <DataGrid
                    dataSource={ Activitys ? Activitys : currentActivity}
                    //keyExpr="idActivity"
                    key="idActivity"
                    showBorders={true}
                    //onRowInserted={onRowInserted}
                    onRowRemoved={onRowRemoved}
                    onSaving={onSaving}
                    onSaved={handlerActivity}
                    noDataText = "No hay actividades registradas"
                >
                    <Paging enabled={false} />
                    <Editing
                        mode="popup"                                
                        allowUpdating={true}
                        allowAdding={false}                        
                        allowDeleting={false}>
                        <Popup title="Información de la actividad" showTitle={true} width={700} height={450} />
                        <Form>
                            <Item itemType="group" colCount={2} colSpan={2}>
                                <Item dataField="idActivity" disabled={true} visible={true}/>
                                <Item dataField="assignedTo" disabled={true} colSpan={2} />
                                <Item dataField="objectives"  disabled={true}/>
                                <Item dataField="statusTask" />
                                <Item dataField="dateStart" disabled={true}/>
                                <Item dataField="dateEnd" disabled={true}/>
                                <Item
                                    disabled={true}
                                    dataField="DescriptionActivity"
                                    editorType="dxTextArea"
                                    colSpan={2}
                                    editorOptions={notesEditorOptions} />
                            </Item>
                        </Form>
                    </Editing>
                    <Column dataField="idActivity" caption="Id Actividad" visible={true}/>
                    <Column dataField="assignedTo" caption="Asignado a"/>
                    <Column dataField="objectives" caption="Objetivos"/>
                    <Column dataField="statusTask" caption="Estado" width={125}>
                        <Lookup dataSource={status} valueExpr="ID" displayExpr="Name" />
                    </Column>
                    <Column dataField="dateStart" dataType="date" caption="Fecha inicio" />
                    <Column dataField="dateEnd" dataType="date" caption="Fecha fin"/>
                    <Column dataField="DescriptionActivity" caption="Descripción" />
                </DataGrid>
                <div>
                    { loading ? <><div className='downDS'><CircularProgress/></div></>:null}     
                </div>     
        </Container>
    )
};
export default ActivityCardJob;