import axios from "axios";
import { useSelector } from "react-redux";
import { selectUrl } from "../../redux/viewBISlice";

export const LogsDB= async (searchRute,area,rol,name)=>{
    let setError=""

    try {
      const res = await axios
        .get(`/api/DataSmart/ReportsLogs`, {params:{"rute":searchRute,"rol":rol,"area":area,"user":name}},{
          headers: {
              'Content-Type': 'application/json; charset=UTF-8',
              'Access-Control-Allow-Origin': '*'
      }})
        .catch(function (error) {
          if (error.response) {
            // La solicitud fue hecha y el servidor respondió con un código de estado
            // que cae fuera del rango de 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            setError="Oops, something went wrong.";
          } else if (error.request) {
            // La solicitud fue hecha pero no se recibió ninguna respuesta
            console.log(error.request);
            setError="Oops, something went wrong.";
          } else {
            // Algo sucedió en la configuración de la solicitud que provocó un error
            console.log("Error", error.message);
            setError="Oops, something went wrong.";
          }

          console.log(error.config);
        });

      return (res.data)
    } catch (e) {
      console.error(e);
      return setError
    }
}