import React from 'react';
import { useNavigate} from 'react-router-dom';
import {Button} from '@progress/kendo-react-buttons';
import { homeIcon, plusIcon,gridLayoutIcon } from '@progress/kendo-svg-icons';

const DynamicFaIcon = (name) => {
    switch (name) {
        case 'homeIcon':
            return homeIcon;
        case 'plusIcon':
            return plusIcon;
        case 'gridLayoutIcon':
            return gridLayoutIcon;
        default:
            return homeIcon;
    }
};
export const PairButtons = ({jmodules, section = 'Mi Espacio'}) => {
	const navigate = useNavigate();

	const handleClick = (redirect) => {
		navigate(redirect);
	};
	return (
		<div style={{display: 'flex', gap: '20px', marginTop:'20px',justifyContent:'flex-end', marginRight:'20px'}}>
			{jmodules
				.filter(
					(module) => module.visible === true && module.section === section
				)
				.map((module, index) => (
					<Button
                        key={index}
                        onClick={() => handleClick(module.link)}
                        svgIcon={DynamicFaIcon(module.icon)}
                    >
                        {module.name}
                    </Button>
				))}
		</div>
	);
};

export default PairButtons;
