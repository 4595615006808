import React from "react";

const ColumnValor = ({ item, index, editMode, editingData, setEditingData }) => {
    return (
        <td className="renewalsAgentPanel-td">
            {
                editMode[index] ? <>
                    <input className='renewalsAgentPanelInput' value={editingData[index].dynamicInputsData.tablero.totalPayment} onChange={(event) => {
                        setEditingData((prevState) => ({
                            ...prevState,
                            [index]: {
                                ...prevState[index],
                                dynamicInputsData: {
                                    ...prevState[index].dynamicInputsData,
                                    tablero: {
                                        ...prevState[index].dynamicInputsData.tablero,
                                        totalPayment: event.target.value
                                    }
                                }
                            }
                        }));
                    }} />
                </> : item.dynamicInputsData.tablero.totalPayment
            }
        </td>
    )
}

export { ColumnValor }