import React from 'react';
import './Card.css'; // Asegúrate de crear y estilizar este archivo CSS
import { useSelector } from 'react-redux';
import { selectlastUpdate } from '../../../../../redux/ControlCenterReducer';
import {formatDate} from '@telerik/kendo-intl';


const CardShowRowUpd = () => {
    let Data = useSelector(selectlastUpdate) || {extendedStatus:'',startTime:'',endTime:'',refreshType:'',requestId:''};
    const {extendedStatus,startTime,endTime,refreshType,requestId} = Data;
    const adjustTimeZone = (dateString) => {
      const date = new Date(dateString);
      date.setHours(date.getHours());
      return date;
    };
    
	const CustomCell = ({field}) => {
		field = adjustTimeZone(field);
		return <p>{formatDate(field, 'yyyy-MM-dd HH:mm:ss')}</p>;
	};
  return (
      <div className="third-slice-grid-container">
          <div className="third-slice-grid-item left">
            <p className='third-slice-bold'>Last Updated</p>
            <p className='third-slice-bold'>Start Date</p>
            <p className='third-slice-bold'>End Date</p>
            <p className='third-slice-bold'>RefreshType</p>
            <p className='third-slice-bold'>requestId</p>
          </div>
          <div className="third-slice-grid-item right third-slice-right"> 
            <p>{extendedStatus}</p>
            <CustomCell field={startTime} />
            <CustomCell field={endTime}/>
            <p>{refreshType}</p>
            <p>{requestId}</p>
          </div>
        </div>

  );
};



export default CardShowRowUpd;
