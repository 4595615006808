import React from "react";
import { SvgIcon } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { pencilIcon, copyIcon, cancelIcon, saveIcon, eyeIcon } from "@progress/kendo-svg-icons";

const ColumnActionsMx = ({ item, index, editMode, toggleEditMode, updatePromise, formatData, viewDetails, deletePromise, isAdmin }) => {
    return (
        <td className="renewalsAgentPanel-td renewalsTreeBtn">
            {editMode[index] ? (
                <>
                    <Button svgIcon={saveIcon} fillMode="outline" onClick={() => updatePromise(index)} >
                        Guardar
                    </Button>
                    <Button svgIcon={cancelIcon} fillMode="outline" onClick={() => toggleEditMode(index)} >
                        Cancelar
                    </Button>
                </>
            ) : (
                <>
                    <Button svgIcon={pencilIcon} fillMode="outline" onClick={() => toggleEditMode(index, item)} >
                        Editar
                    </Button>
                    <Button
                        svgIcon={copyIcon}
                        fillMode="outline"
                        onClick={() => {
                            const textarea = document.createElement('textarea');
                            console.log(item)
                            textarea.value = formatData(item);
                            textarea.setAttribute('readonly', '');
                            textarea.style.position = 'absolute';
                            textarea.style.left = '-9999px';
                            document.body.appendChild(textarea);
                            textarea.select();
                            document.execCommand('copy');
                            document.body.removeChild(textarea);
                            alert('Content copied to clipboard!');
                        }}>
                        Copiar
                    </Button>
                    {
                        isAdmin &&
                        <Button svgIcon={eyeIcon} fillMode="outline" onClick={() => deletePromise(index, item)} >
                            Eliminar
                        </Button>
                    }
                </>
            )}

        </td>
    )
}

export { ColumnActionsMx }