import { graphConfig } from "./Config";
/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
export async function callMsGraphPhoto(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(graphConfig.graphPhotoEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
export async function callMsGraphCalendar(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(graphConfig.graphCalendarEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
export async function callGroups(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(graphConfig.graphGroupEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
export async function callMyProfile(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(graphConfig.graphMyProfilepoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
export async function searchPeople(accessToken, name) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("ConsistencyLevel", "eventual");
    const options = {
        method: "GET",
        headers: headers
    };
    //let nombre = "Lina";
    return fetch(graphConfig.graphSearchPeople + '"displayName:' + name + '"', options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function searchByEmail(accessToken, email) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("ConsistencyLevel", "eventual");
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(graphConfig.graphSearchPeople + '"userPrincipalName:' + email + '"', options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
