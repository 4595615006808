import { useEffect } from 'react';
export const RequestDateCell = ({ticketData}) => {
    const requestDateValue = ticketData.requestDate?.$date;

    if (!requestDateValue) {
        return <td></td>;
    }

    // Crear un objeto Date y ajustar la hora
    let date = new Date(requestDateValue);
    date.setHours(date.getHours() + 5);

    // Convertir el objeto Date a una cadena ISO y dividirla en partes
    const dateParts = date.toISOString().split('T')[0].split('-');
    const hourPart = date.toISOString().split('T')[1].split(':');

    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10);
    const day = parseInt(dateParts[2], 10);
    let hour = parseInt(hourPart[0], 10);
    const minute = parseInt(hourPart[1], 10);

    if (hour < 5) {
        hour = 24 - (5 - hour);
    } else {
        hour = hour - 5;
    }

    const formattedDate = `${day}/${month}/${year} | ${hour}:${minute < 10 ? '0' + minute : minute}`;

    return <td>{formattedDate}</td>;
};
export const RequestLastUpdateDateCell = ({ticketData}) => {
    const requestDateValue = ticketData.dateLastModified?.$date;

    if (!requestDateValue) {
        return <td></td>;
    }
    let date = new Date(requestDateValue);
    date.setHours(date.getHours());
    const dateParts = date.toISOString().split('T')[0].split('-');
    const hourPart = date.toISOString().split('T')[1].split(':');
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10);
    const day = parseInt(dateParts[2], 10);
    let hour = parseInt(hourPart[0], 10);
    const minute = parseInt(hourPart[1], 10);
    const formattedDate = `${day}/${month}/${year} | ${hour}:${minute < 10 ? '0' + minute : minute}`;
    return <td>{formattedDate}</td>;
};
// export const CalculateBusinessHoursPassed = ({ticketData,timeElapsedStatus,setTimeElapsedStatus}) => {
//     const requestDateValue = ticketData.requestDate?.$date;

//     if (!requestDateValue) {
//         return <td></td>;
//     }
//     const startHour = 7; // Hora de inicio del trabajo
//     const endHour = 18; // Hora de fin del trabajo (7 PM)

//     // Convertir la fecha del ticket a la hora local de Colombia
//     let ticketDateTime = new Date(requestDateValue);
//     ticketDateTime.setHours(ticketDateTime.getHours() - 5); // Ajustar la zona horaria

//     // Fecha y hora actual en la zona horaria local
//     let currentDateTime = new Date();
//     currentDateTime.setHours(currentDateTime.getHours() - 5); // Ajustar la zona horaria

//     let hoursPassed = 0;

//     while (ticketDateTime < currentDateTime) {
//         // Verificar si es un día laborable (lunes a viernes)
//         if (ticketDateTime.getDay() !== 0 && ticketDateTime.getDay() !== 6) {
//             // Calcular las horas dentro del día laborable
//             let startOfWorkday = new Date(ticketDateTime);
//             startOfWorkday.setHours(startHour, 0, 0, 0); // Inicio del día laborable

//             let endOfWorkday = new Date(ticketDateTime);
//             endOfWorkday.setHours(endHour, 0, 0, 0); // Fin del día laborable

//             if (ticketDateTime < startOfWorkday) {
//                 ticketDateTime = startOfWorkday;
//             }

//             let hoursToAdd = Math.min(
//                 (endOfWorkday - ticketDateTime) / (1000 * 60 * 60),
//                 (currentDateTime - ticketDateTime) / (1000 * 60 * 60)
//             );

//             hoursPassed += hoursToAdd;
//         }

//         // Mover al siguiente día
//         ticketDateTime.setDate(ticketDateTime.getDate() + 1);
//         ticketDateTime.setHours(0, 0, 0, 0);
//     }
//     setTimeElapsedStatus(parseInt(hoursPassed))

//     return <td>{timeElapsedStatus} Hrs</td>;
// };


// export const CalculateBusinessHoursPassed = ({ ticketData, timeElapsedStatus, setTimeElapsedStatus }) => {
//     const requestDateValue = ticketData.requestDate?.$date;

//     useEffect(() => {
//         if (!requestDateValue) {
//             return;
//         }

//         const startHour = 8; // Hora de inicio del trabajo
//         const endHour = 18; // Hora de fin del trabajo (6 PM)
    
//         let inicio = new Date(requestDateValue);
//         inicio.setHours(inicio.getHours() + 5); 
//         let ahora = new Date();

//         let horasLaborales = 0;

//         while (inicio < ahora) {
//             if ((inicio.getHours() >= startHour && inicio.getHours() < 12) || 
//                 (inicio.getHours() >= 14 && inicio.getHours() < endHour)) {
//                 if (inicio.getDay() !== 0 && inicio.getDay() !== 6) { // 0 = Domingo, 6 = Sábado
//                     horasLaborales++;
//                 }
//             }
//             inicio.setHours(inicio.getHours() + 1); // Incrementar la fecha de inicio en una hora
//         }

//         setTimeElapsedStatus(parseInt(horasLaborales));
//     }, [requestDateValue, setTimeElapsedStatus]); // Dependencias

//     if (!requestDateValue) {
//         return <td></td>;
//     }

//     return <td>{timeElapsedStatus} Hrs</td>;
// };

export const CalculateBusinessHoursPassed = ({ ticketData, timeElapsedStatus, setTimeElapsedStatus }) => {
    const requestDateValue = ticketData.requestDate?.$date;
    const finishDateValue = ticketData.status.elapsedTimeOfTheEntireTicket;



    useEffect(() => {
        if (!requestDateValue) {
            return;
        }
        
            
        

        const startHour = 8; // Hora de inicio del trabajo
        const endHour = 18; // Hora de fin del trabajo (6 PM)
        const incrementoMinutos = 10; // Incrementar en tramos de 10 minutos

        let inicio = new Date(requestDateValue);
        inicio.setHours(inicio.getHours() + 5); 
        let ahora = new Date();

        let minutosLaborales = 0;

        while (inicio < ahora) {
            if ((inicio.getHours() >= startHour && inicio.getHours() < 12) || 
                (inicio.getHours() >= 14 && inicio.getHours() < endHour)) {
                if (inicio.getDay() !== 0 && inicio.getDay() !== 6) { // 0 = Domingo, 6 = Sábado
                    minutosLaborales += incrementoMinutos;
                }
            }
            inicio.setMinutes(inicio.getMinutes() + incrementoMinutos); // Incrementar la fecha de inicio en 10 minutos
        }

        const horasLaborales = minutosLaborales / 60; // Convertir minutos a horas

        // Establecer el estado con precisión decimal y asegurarse de que es un número
        setTimeElapsedStatus(parseFloat(horasLaborales.toFixed(1)));
    }, [requestDateValue, setTimeElapsedStatus]);

    if (!requestDateValue) {
        return <td></td>;
    }
    if (finishDateValue){
        let timeDays=Math.floor(finishDateValue/24);
        if(timeDays>=1){
            let hoursElapsedStatus = +((finishDateValue % 24).toFixed(1));
            return <td style={{color:'gray'}}>{timeDays} Days {hoursElapsedStatus} Hrs Finalizado</td>;
            
        }
        return <td style={{color:'gray'}}>{finishDateValue} Hrs Finalizado</td>;
    }
    if(timeElapsedStatus>24){
        let timeDays=Math.floor(timeElapsedStatus/24);
        let hoursElapsedStatus = +((timeElapsedStatus % 24).toFixed(1));
        if(timeElapsedStatus>=30){
            return <td style={{color:'red'}}>{timeDays} Days {hoursElapsedStatus} Hrs</td>;
        }
        if(timeElapsedStatus>40){
            return <td style={{color:'orange'}}>{timeDays} Days {hoursElapsedStatus} Hrs</td>;
        }
        return <td>{timeDays} Days {hoursElapsedStatus} Hrs</td>;
    }
    

    // Mostrar el valor con una precisión de un decimal
    return <td>{timeElapsedStatus} Hrs</td>;
};

