import './WhatsAppConnect.css';
import React, { useState, useEffect } from 'react';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Slide } from '@progress/kendo-react-animation';
import {
    GridLayout, GridLayoutItem,
    Card,
    CardHeader,
    CardTitle,
    CardBody
} from '@progress/kendo-react-layout';
// import { Chat, ChatMessageSendEvent, Message, } from '@progress/kendo-react-conversational-ui';
import { getWhatsAppConnectTemplates, } from './requestService';
import { StepOne, StepTwo, StepThird } from './CampaignCreation'
import { useSelector, useDispatch } from 'react-redux'
import { setFormStep } from './../../../../../redux/whatsAppConnect'
import { selectFormStep, selectWhatsAppConnect } from './../../../../../redux/whatsAppConnect'
import { AdminPanel } from './AdminPanel';
import { Popup } from "@progress/kendo-react-popup";

const WhatsAppConnect = () => {
    const anchor = React.useRef(null);
    const [show, setShow] = React.useState(false);
    React.useEffect(() => {
      setShow(false);
    }, []);
    const onClick = () => {
      setShow(!show);
    };
    const dispatch = useDispatch();
    const [notifications, setNotifications] = useState([]);

    const formStep = useSelector(selectFormStep);
    const generalState = useSelector(selectWhatsAppConnect)

    const [loading, setLoading] = useState(true);
    const [messageTemplates, setMessageTemplates] = useState([]);

    const nextStep = () => {
        if (formStep == 3) {
            dispatch(setFormStep(1))
        } else {
            dispatch(setFormStep(formStep + 1))
        }

    }
    const prevStep = () => {
        dispatch(setFormStep(formStep - 1))
    }
    const processTemplatesLoad = (data) => {
        setMessageTemplates(data)
        setLoading(false);
    }

    const addNotification = (type, message) => {
        const newNotification = {
            type,
            message,
        };
        setNotifications([...notifications, newNotification]);
    };

    // Function to remove a notification from the state
    const removeNotification = (notification) => {
        setNotifications(notifications.filter((n) => n !== notification));
    };

    useEffect(() => {
        getWhatsAppConnectTemplates(processTemplatesLoad);
    }, [])
    const [anchorAlign, setAnchorAlign] = React.useState({
        horizontal: "center",
        vertical: "bottom",
      });
      const [popupAlign, setPopupAlign] = React.useState({
        horizontal: "center",
        vertical: "top",
      });
    return (
        loading ? <>
            <p>Cargando ...</p>
        </> : <>
            <div className="WhatsAppConnect">
                <AdminPanel />
                <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={onClick}
                ref={anchor}
                >
                {show ? "Esconder creación campañas" : "Mostrar creación campañas"}
                </button>
                <Popup anchorAlign={anchorAlign} popupAlign={popupAlign} anchor={anchor.current} show={show} popupClass={"popup-content"}>
                    <Card>
                        <CardHeader>
                            <CardTitle>
                                Configuracion de campaña
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <GridLayout>
                                <GridLayoutItem>
                                    {formStep === 1 ? <StepOne
                                        messageTemplates={messageTemplates}
                                        nextStep={nextStep}
                                        prevStep={prevStep}
                                        addNotification={addNotification}
                                        removeNotification={removeNotification}
                                    /> : null}
                                    {formStep === 2 ? <StepTwo
                                        nextStep={nextStep}
                                        prevStep={prevStep}
                                        addNotification={addNotification}
                                        removeNotification={removeNotification}
                                    /> : null}
                                    {formStep === 3 ? <StepThird
                                        nextStep={nextStep}
                                        prevStep={prevStep}
                                        addNotification={addNotification}
                                        removeNotification={removeNotification}
                                    /> : null}
                                </GridLayoutItem>
                            </GridLayout>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <CardTitle>
                                Carga de campañas
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <GridLayout>
                                <GridLayoutItem>
                                    <Card>
                                        <CardHeader>
                                            <CardTitle>
                                                WhatsAppConnect
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>

                                        </CardBody>
                                    </Card>
                                </GridLayoutItem>
                            </GridLayout>
                        </CardBody>
                    </Card>
                </Popup>
            </div>
            <NotificationGroup style={{
                right: 0,
                bottom: 0,
                alignItems: 'flex-start',
                flexWrap: 'wrap-reverse',
            }}>
                {notifications.map((notification, index) => (
                    <Slide key={index} direction={notification.type === 'success' ? 'up' : 'down'}>
                        <Notification
                            type={{
                                style: notification.type,
                                icon: true,
                            }}
                            closable={true}
                            onClose={() => removeNotification(notification)} // Implement removeNotification function
                        >
                            <span>{notification.message}</span>
                        </Notification>
                    </Slide>
                ))}
            </NotificationGroup>

        </>
    )
}


export { WhatsAppConnect }