import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {setChecking} from '../../../redux/credencialesReducer';
import {controller} from '../../../components/ViewCustomer/db/controller';

export const UseChargeData = (eMail) => {
    const dispatch = useDispatch();
    const [view, setView] = useState('home')
    const [params, setParams] = useState('name');

    useEffect(() => {
        const fetchDataBasedOnEmail= async()=>{
            dispatch(setChecking(true));
            let homeViewData = await controller('/api/HomesView', 'POST', {
                email: eMail,
            });
            if (homeViewData !== null) {           
                setParams(homeViewData['asesor']);

                if(homeViewData['area']==='Sales'){
                    const comercialData = await controller('/api/Viewcomercial', 'POST', {
                        email: eMail,
                    });
                    if (comercialData !== null) {
                        JSON.stringify(comercialData);
                        console.log(comercialData);
                        if (comercialData && comercialData.hasOwnProperty('asesor')) {
                            sessionStorage.setItem('dataComercial', JSON.stringify(comercialData));
                            setParams(comercialData['asesor']);
                            setView('comercial');
                          } else {
                            sessionStorage.setItem('dataComercial', JSON.stringify(comercialData));
                            setView('CrossSell');
                          }
                    }else{

                        setView('home');
                    }
                    sessionStorage.setItem('customReports', JSON.stringify(homeViewData));              
                }else{                    
                    setView(homeViewData['area']);
                    sessionStorage.setItem('customReports', JSON.stringify(homeViewData));     
                }

            }else{
                setView('default');
            }
            dispatch(setChecking(false));
        }
        fetchDataBasedOnEmail(eMail);
    }, [eMail,dispatch]);

   

    return {view,params};
	
};
