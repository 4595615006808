import {Splitter} from '@progress/kendo-react-layout';
import '../../../style/RequestOpened.css'
import {useEffect, useState} from 'react';
import {ListViewMyRequests} from './ListViewMyRequests';
import axios from 'axios';
import {SplitButton} from '@progress/kendo-react-buttons';
import {
	filterIcon,
	filterSortAscSmallIcon,
	filterSortDescSmallIcon,
	folderIcon,
} from '@progress/kendo-svg-icons';

export const RequestOpened = () => {
	const [panes, setPanes] = useState([
		{
			size: '55%',
			scrollable: true,
		},
		{},
	]);
	const onChange = (event) => {
		setPanes(event.newState);
	};
	const [bandera, setBandera] = useState(Boolean);
	const [jTickets, setJTickets] = useState();
	const [closeTicket, setCloseTicket] = useState();
	const [isLoading, setIsLoading] = useState(false);

	async function RequestDataClosed() {
		let api_url = '/api/ticketsClosed';
		const data = {email: sessionStorage.getItem('usr')};
		let response = await axios.post(api_url, data, {
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
				'Access-Control-Allow-Origin': '*',
			},
		});
		return response;
	}

	async function RequestData() {
		let api_url = '/api/tickets';
		const data = {email: sessionStorage.getItem('usr')};

		let response = await axios.post(api_url, data, {
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
				'Access-Control-Allow-Origin': '*',
			},
		});
		return response;
	}

	useEffect(() => {
		setBandera(true);
		RequestData()
			.then((response) => {
				setJTickets(response.data);
			})
			.catch((err) => {
				console.log(err);
			});
		RequestDataClosed()
			.then((response) => {
				setCloseTicket(response.data);
			})
			.catch((err) => {
				console.log(err);
			});
		setBandera(false);
	}, []);

	const items = [
		{
			text: 'Nuevo -> Cerrado',
			svgIcon: folderIcon,
		},
		{
			text: 'Cerrado -> Nuevo',
			svgIcon: folderIcon,
		},
		{
			text: 'Mayor a Menor',
			svgIcon: filterSortAscSmallIcon,
		},
		{
			text: 'Menor a Mayor',
			svgIcon: filterSortDescSmallIcon,
		},
	];
	const handleFilter = (event) => {
		let filterTickets = [];
		setBandera(true);
		switch (event.item.text) {
			case 'Nuevo -> Cerrado':
				const tabNames = ['Nuevo','Asignado','Proceso','Revisión','Aprobación','Cerrado','Espera'];
				tabNames.forEach((tabName) => {
					let filtered = jTickets.filter(
						(element) => element.status.currently === tabName
					);
					filterTickets.push(...filtered);
				});
				setJTickets(filterTickets);
				break;
			case 'Cerrado -> Nuevo':
				const tabNames2 = ['Espera','Cerrado','Aprobación','Revisión','Proceso','Asignado','Nuevo'];
				tabNames2.forEach((tabName) => {
					let filtered = jTickets.filter(
						(element) => element.status.currently === tabName
					);
					filterTickets.push(...filtered);
				});
				setJTickets(filterTickets);
				break;
			case 'Mayor a Menor':
				filterTickets = jTickets.map((element) => {
					element.ticketId = parseInt(element.ticketId);
					return element;
				});
				setJTickets(filterTickets.sort((a, b) => b.ticketId - a.ticketId));
				break;
			case 'Menor a Mayor':
				filterTickets = jTickets.map((element) => {
					element.ticketId = parseInt(element.ticketId);
					return element;
				});
				setJTickets(filterTickets.sort((a, b) => a.ticketId - b.ticketId));
				break;
			default:
				break;
		}
		setBandera(false);
	};

	return (
		<div style={{position: 'relative'}}>
			<SplitButton
				text="Filtrar"
				onItemClick={handleFilter}
				items={items}
				fillMode={'outline'}
				svgIcon={filterIcon}
				style={{position: 'absolute', right: '40px',top:'10px'}}
			/>
			<ListViewMyRequests
				bandera={bandera}
				jTickets={jTickets}
				titulo={'Mis Tickets'}
			/>
		</div>
	);
};
