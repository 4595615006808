import React, { useEffect } from 'react';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import Tablero from '../Tablero';
import { Button } from '@mui/material';
import { OptionSelection } from './OptionSelection';


export const GeneralCase = ({ currentStep, formData, handleOptionChange, reset, randomImage }) => {
    const [currentStepData, setCurrentStepData] = React.useState(undefined);
    const [hasNotes, setHasNotes] = React.useState(undefined);
    const [hasTablero, setHasTablero] = React.useState(undefined);
    const [hasInputs, setHasInputs] = React.useState(undefined);
    const [hasOptions, setHasOptions] = React.useState(undefined);
    const [collectedData, setCollectedData] = React.useState([]);
    // const currentStepData = formData[currentStep].data;
    const renderSettings = [
        {
            id: "notasGenerales",
            renderType: "richText",
            className: "renewals-tree-notas",
            label: "Notas Generales:",
            colSpan: 5
        }
    ]

    const checkIfHtmlIsEmpty = (html) => {
        const withoutEmptyTags = html.replace(/<[^\/>]*>\s*<\/[^>]+>|<[^>]+>\s*<\/[^>]+>/g, '');
        const content = withoutEmptyTags.replace(/\s/g, '');
        return content.length === 0;
    }

    // const hasTablero = (currentStepData.Tablero != null && currentStepData.Tablero != "");

    useEffect(() => {
        const tempCurrentStepData = formData[currentStep].data;

        let tempHasNotes = renderSettings.find((setting) => {
            return tempCurrentStepData[setting.id] != undefined ? !checkIfHtmlIsEmpty(tempCurrentStepData[setting.id]) : false
        });

        setCurrentStepData(tempCurrentStepData);
        setHasNotes(tempHasNotes);
        setHasTablero(tempCurrentStepData.Tablero == true)
        setHasInputs(tempCurrentStepData.inputs != undefined && tempCurrentStepData.inputs.length != 0);
        setHasOptions(tempCurrentStepData.options != undefined && tempCurrentStepData.options.length != 0);
    }, [currentStep])

    return (
        (currentStepData != undefined) ? <>
            <GridLayout
                className='renewals-tree-card-container generalCase'
                style={{ margin: '10px' }}
                align={{ horizontal: 'center', }}
                gap={{ rows: 5, cols: 0, }}>


                {/* TITULO */}
                <GridLayoutItem className={`siignal-w100`} colSpan={10}>
                    <GridLayout
                        style={{ margin: '10px' }}
                        cols={[{ width: "100px" }, { width: "auto" }, { width: "100px" }]}
                        rows={[{ height: "100px" }]}
                        gap={{ rows: 5, cols: 0, }}>

                        <GridLayoutItem>
                            <img className={`renewals-tree-title-img`} src={randomImage.image} />
                        </GridLayoutItem>
                        <GridLayoutItem className={`renewals-tree-title renewals-font-${randomImage.color}`} >
                            {currentStepData.title}
                        </GridLayoutItem>
                        <GridLayoutItem>
                            <img className={`renewals-tree-title-img`} src={randomImage.image} />
                        </GridLayoutItem>

                    </GridLayout>
                </GridLayoutItem>

                {/* NOTAS */}
                {
                    (hasNotes != undefined) ? <>
                        <GridLayoutItem className={`siignal-w100`} colSpan={(hasInputs || hasTablero) ? 3 : 10}>
                            <GridLayout
                                style={{ margin: '10px' }}
                                align={{ horizontal: 'center', }}
                                cols={[{ width: "auto" }]}
                                gap={{ rows: 5, cols: 0, }}>
                                {
                                    renderSettings.map((setting) => {
                                        if (setting.renderType == "richText" && !checkIfHtmlIsEmpty(currentStepData[setting.id])) {
                                            return (
                                                <GridLayoutItem className={`siignal-box-shadow renewals-border-${randomImage.color}`} colSpan={setting.colSpan} key={`renewals-notes${setting.id}`}>
                                                    <span className='renewals-tree-card-title'>{setting.label}</span>
                                                    <hr className={`renewals-border-${randomImage.color}`} />
                                                    <div dangerouslySetInnerHTML={{ __html: currentStepData[setting.id] }}></div>
                                                </GridLayoutItem>
                                            )
                                        }
                                    })
                                }
                            </GridLayout>
                        </GridLayoutItem>
                    </> : null
                }
                {/* INPUTS | TABLERO */}
                {
                    (hasInputs || hasTablero) ? <>
                        <GridLayoutItem className={`siignal-w100`} colSpan={(hasNotes) ? 7 : 10}>
                            {
                                (hasInputs) ? <>
                                    <GridLayout
                                        key={`renewals-inputs${currentStep}`}
                                        style={{ margin: '10px' }}
                                        align={{ horizontal: 'center', }}
                                        cols={[{ width: "100%" }]}
                                        gap={{ rows: 5, cols: 0, }}>
                                        {
                                            currentStepData.inputs.map((input) => {
                                                return (
                                                    <>
                                                        <label className='siignal-w100'>{input.name}</label>
                                                        <input
                                                            className='siignal-w100'
                                                            placeholder={input.name}
                                                            onChange={() => {
                                                                console.log("parsing function")
                                                                let customFunction = new Function(input.onChangeFunction);
                                                                customFunction();
                                                            }}
                                                            style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '5px' }}
                                                            name={input.name} />
                                                    </>
                                                )
                                            })
                                        }
                                    </GridLayout>
                                </> : null
                            }

                            {
                                (hasTablero) ? <>
                                    <GridLayoutItem>
                                        <Tablero />
                                    </GridLayoutItem>
                                </> : null
                            }
                        </GridLayoutItem>
                    </> : null
                }


                {
                    hasOptions != 0 ?
                        <GridLayoutItem className={`siignal-w100`} colSpan={10}>
                            <OptionSelection
                                currentStep={currentStep}
                                formData={formData}
                                handleOptionChange={handleOptionChange}
                                reset={reset}
                                randomImage={randomImage} />
                        </GridLayoutItem>
                        : null
                }
                {
                    currentStepData.Finalizar == true ? <>
                        <GridLayoutItem className={`siignal-w100`} colSpan={10}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2, mb: 2 }}
                                onClick={() => {
                                    reset();
                                }}
                            >
                                Reiniciar
                            </Button>
                        </GridLayoutItem>
                    </> : null
                }
            </GridLayout >
        </> : null
    )
}