import './TableroMx.css';
import React, { useState, useEffect } from 'react';
import {
    GridLayout, GridLayoutItem,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardActions,
    TabStrip,
    TabStripTab
} from '@progress/kendo-react-layout';
import { useSelector, useDispatch } from 'react-redux';
import { getIncidentByRFC } from './requestServiceMx';
import { get_Left_Days } from './requestServiceMx';
import { setDynamicInputsData, selectDynamicInputsData } from '../../../../../redux/renewalsTreeReducer'
import { useUserService } from './../../services/userService'
import { selectConfig } from '../../../../../redux/renewalsTreeReducer';
const itemsPerPage = 12; // Number of items per page

const TableroMx = () => {
    const dispatch = useDispatch();
    const { userProfile } = useUserService();
    const renewalsConfig = useSelector(selectConfig)
    const dynamicInputsData = useSelector(selectDynamicInputsData);
    const [isLoading, setIsLoading] = useState(true);
    const [selected, setSelected] = React.useState(0);
    const handleSelect = (e) => {
        setSelected(e.selected);
    };
    const [formControl, setFormControl] = useState({
        rfcSearch: null,
    })
    const [rfc, setRfc] = useState('');

    const generalFormSettings = [
        {
            label: "Rol",
            type: "select",
            options: [
                "Administrador(a)",
                "Asistente",
                "Asistente o Auxiliar Contable",
                "Contador",
                "Docente",
                "Dueño de la empresa",
                "Dueño del despacho",
                "Empresario",
                "Encargado de Soporte técnico",
                "Encargado del sistema",
                "Encargado(a)",
                "Estudiante",
                "Gerente de Operaciones",
                "No da información",
                "Representante legal",
                "Distribuidor",
                "Director"
            ]
        },
        {
            label: "Tipo de gestion",
            type: "select",
            options: [
                "Renov. Telefonica",
                "Renov. Whatsapp",
                "Renov. Correo"
            ]
        },
    ]

    const bySerialFormSettings = {
        "rfc": "",
        "serial": "",
        "producto": "",
        "fechaRenovacion": "",
        "medioDePago": "",
        "fechaCompromiso": "",
        "fechaExtendida": "",
        "notas": ""
    };
    const [rfcSerials, setRfcSerials] = useState([])
    const [rfcSavedData, setRfcSavedData] = useState([])
    const [rfcToProcess, setRfcToProcess] = useState({})
    const [days_left_month, setdays_left_month] = useState({})
    const [days_left_year, setdays_left_year] = useState({})
    useEffect(() => {
        dispatch(setDynamicInputsData({
            ...dynamicInputsData,
            user: userProfile.email,
            "tableroMx": {
                ...formControl
            }
        }))
    }, [formControl])

    const calculateExtendedDate = (days = null) => {
        let date = new Date();
        if ([4, 5, 6, 0].includes(date.getDay())) {
            date.setDate(date.getDate() + 1);
        }
        date.setDate(date.getDate() + parseInt(days != null ? days : renewalsConfig["Mexico"].daysToExtendedPromise));
        return date.toISOString().split('T')[0];
    }

    useEffect(() => {
        let generalFormSettingsStoreValues = {}
        generalFormSettings.map(el => {
            generalFormSettingsStoreValues[el.label.toLocaleLowerCase().replace(" ", "_")] = ""
        })
        setFormControl({
            ...formControl,
            ...generalFormSettingsStoreValues,
        })
        setIsLoading(false)
    }, [])
    
    const searchByNit = () => {
        if (validarRFC(rfc.trim())) {
            get_Left_Days((data)=>{
                setdays_left_month(data.leftDaysToShow_month)
                setdays_left_year(data.leftDaysToShow_year)
            })
            getIncidentByRFC(rfc, (data) => {
                if (data.base.length != 0) {
                    let periodo = data.base[0].PERIODICIDAD
                    if(periodo === 'Mensual'){
                            setRfcSerials(data.base)
                            let today = new Date()
                            let hoy = new Date()
                            let days_date = 0
                            hoy.setHours(0,0,0,0)
                            let Fecha_renovacion = data.base[0].FECHA_RENOVACION
                            let fecha_renovacion_dos_dias_atras = new Date()
                            const partesFecha = Fecha_renovacion.split('/'); // Dividir la cadena en día, mes y año
                            if (partesFecha.length === 3) {
                                const dia = parseInt(partesFecha[0], 10);
                                const mes = parseInt(partesFecha[1], 10) - 1; // Los meses en JavaScript van de 0 a 11
                                const año = parseInt(partesFecha[2], 10);
                                Fecha_renovacion = new Date(año, mes, dia);
                                days_date = Fecha_renovacion.getDate() - parseInt(days_left_year)
                                fecha_renovacion_dos_dias_atras = new Date(año, mes, days_date)
                            }
                            fecha_renovacion_dos_dias_atras.setHours(0,0,0,0)
                            let checker = (fecha_renovacion_dos_dias_atras.getTime())<= (hoy.getTime())
                            if(checker){
                                let compiled = data.base.map(item => {
                                    return {
                                        ...item,
                                        Estado: data.pagos.find(el => el.NUMSERIE == item.NUMSERIE) ? "Pagado" : data.promesas.find(el => el.serial == item.NUMSERIE) ? "Promesa" : "Pendiente"
                                    }
                                })
                            setRfcSavedData(compiled)
                            setFormControl({ ...formControl, rfcSearch: rfc })
                            }else{
                                alert("El registro indicado RFC tiene una fecha de renovación supererior al día establecido, La fecha en el registro es: "+ data.base[0].FECHA_RENOVACION + " La fecha esperada es: "+days_left_month+ " atras, para registros con periodicidad mensual")
                            }
                    }else{
                        setRfcSerials(data.base)
                        let today = new Date()
                        let hoy = new Date()
                        hoy.setHours(0,0,0,0)
                        let days_date = 0
                        let Fecha_renovacion = data.base[0].FECHA_RENOVACION
                        const partesFecha = Fecha_renovacion.split('/'); // Dividir la cadena en día, mes y año
                        let fecha_renovacion_dos_dias_atras = new Date()
                        if (partesFecha.length === 3) {
                            const dia = parseInt(partesFecha[0], 10);
                            const mes = parseInt(partesFecha[1], 10) - 1; // Los meses en JavaScript van de 0 a 11
                            const año = parseInt(partesFecha[2], 10);
                            Fecha_renovacion = new Date(año, mes, dia);
                            days_date = Fecha_renovacion.getDate() - parseInt(days_left_year)
                            fecha_renovacion_dos_dias_atras = new Date(año, mes, days_date)
                        }
                        fecha_renovacion_dos_dias_atras.setHours(0,0,0,0)
                        let checker = (fecha_renovacion_dos_dias_atras.getTime())<= (hoy.getTime())
                        if(checker){
                            let compiled = data.base.map(item => {
                                return {
                                    ...item,
                                    Estado: data.pagos.find(el => el.NUMSERIE == item.NUMSERIE) ? "Pagado" : data.promesas.find(el => el.serial == item.NUMSERIE) ? "Promesa" : "Pendiente"
                                }
                            })
                        setRfcSavedData(compiled)
                        setFormControl({ ...formControl, rfcSearch: rfc })
                        }else{
                            alert("El registro indicado RFC tiene una fecha de renovación supererior al día establecido, La fecha en el registro es: "+ data.base[0].FECHA_RENOVACION + " La fecha esperada es: "+days_left_year+ " atras, para registros con periodicidad anual y semestral")
                        }
                    }
                } else {
                    alert("No se encontro el RFC")
                }
            })
        } else {
            alert("El registro indicado no es un RFC valido")
        }
    }

    const updateRfcToProcess = (serie, data) => {
        let tempRfcToProcess = { ...rfcToProcess }
        if (tempRfcToProcess[serie] !== undefined) {
            delete tempRfcToProcess[serie]
        } else {
            tempRfcToProcess[serie] = data
            tempRfcToProcess[serie]["fechaCompromiso"] = (new Date()).toISOString().split("T")[0];
            tempRfcToProcess[serie]["fechaExtendida"] = calculateExtendedDate();
        }

        let formcontrolBySerial = {}
        Object.keys(tempRfcToProcess).forEach(el => {
            let elContent = (formControl.formcontrolBySerial == undefined) ? bySerialFormSettings : (formControl.formcontrolBySerial[el] == undefined ? bySerialFormSettings : formControl.formcontrolBySerial[el])

            elContent = {
                ...elContent,
                "rfc": data.RFC,
                "serial": data.NUMSERIE,
                "producto": data.PRODUCTO,
                "fechaRenovacion": data.FECHA_RENOVACION,
                "medioDePago": data.FORMA_COBRO,
                "fechaCompromiso": data.fechaCompromiso,
                "fechaExtendida": data.fechaExtendida,
                "valor": data.MontoNeto
            }

            formcontrolBySerial[el] = {
                ...elContent,
                rfc: formControl.rfcSearch,
                serial: el,
            }
        })
        setFormControl(prevState => ({
            ...prevState,
            formcontrolBySerial: formcontrolBySerial
        }))
        setRfcToProcess(tempRfcToProcess);
    }

    const validarRFC = (valor) => {
        const regexRFC = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
        return regexRFC.test(valor);
    };

    const pendingPayments = rfcSavedData.filter(el => el.Estado == "Pendiente")
    const realizedPayments = rfcSavedData.filter(el => el.Estado == "Pagado")
    const promisedPayments = rfcSavedData.filter(el => el.Estado == 'Promesa')

    return (
        isLoading ? <div className="loading">Loading&#8230;</div> :
            <>
                <GridLayout
                    gap={{ rows: 8, cols: 8, }}
                    className="MxPaymentPromises">
                    <GridLayoutItem>
                        <GridLayout
                            gap={{ rows: 8, cols: 8, }}
                            cols={[{ width: "auto" }, { width: "70%" }]}>
                            <GridLayoutItem className='siignal-box-shadow'>
                                <Card>
                                    <CardHeader
                                        className="k-hbox"
                                    >
                                        <div>
                                            <CardTitle>
                                                <strong>RFC a buscar:</strong>
                                            </CardTitle>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <input
                                            placeholder='XXXX00000X0'
                                            className='.mxPaymentPromisesInput'
                                            value={rfc}
                                            onChange={(e) => {
                                                setRfc(e.target.value)
                                            }} />
                                    </CardBody>
                                    <CardActions
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>

                                            <button
                                                className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                                                onClick={() => {
                                                    searchByNit()
                                                }}
                                            >
                                                Buscar
                                            </button>
                                        </div>
                                    </CardActions>
                                </Card>
                            </GridLayoutItem>
                            <GridLayoutItem className='siignal-box-shadow'>
                                ESTADISTICAS / TABLA / GRAFICOS
                                <hr />
                                AACV7011241D3
                            </GridLayoutItem>
                        </GridLayout>
                    </GridLayoutItem>
                    {
                        (formControl.rfcSearch != null && rfcSavedData.length != 0) ? <>
                            <GridLayoutItem className='siignal-box-shadow'>
                                <TabStrip selected={selected} onSelect={handleSelect}>
                                    <TabStripTab title={`Pendientes de pago (${pendingPayments.length})`}>
                                        <div className='siignalCenteredText'>
                                            {
                                                (promisedPayments.length != 0) ? <>
                                                    <b>Este cliente ya tiene {promisedPayments.length} promesas creadas</b>
                                                </> : null
                                            }
                                            <br />
                                        </div>
                                        {
                                            (pendingPayments.length != 0) ? <>
                                                <div className='siignalCenteredText'>Estos precios son de referencia y debe realizar CENTRAL</div>
                                                <table className="renewalsMX-SerialsList">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Gestionar</th>
                                                            <th>Numero Serie</th>
                                                            <th>Periodicidad</th>
                                                            <th>Producto</th>
                                                            <th>Monto Neto</th>
                                                            <th>Estado</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            pendingPayments.map((el, index) => {
                                                                return (
                                                                    <>
                                                                        <tr
                                                                            key={index}
                                                                            className={rfcToProcess[el.NUMSERIE] != undefined || el.Estado == "Pagado" ? "success" : "danger"}
                                                                            onClick={() => {
                                                                                updateRfcToProcess(el.NUMSERIE, el)
                                                                            }}>
                                                                            <td>{index + 1}</td>
                                                                            <td>
                                                                                {
                                                                                    rfcToProcess[el.NUMSERIE] != undefined ? "SI" : "NO"
                                                                                }
                                                                            </td>
                                                                            <td>{el.NUMSERIE}</td>
                                                                            <td>{el.PERIODICIDAD}</td>
                                                                            <td>{el.PRODUCTO}</td>
                                                                            <td>MXN${el["MontoNeto"]}</td>
                                                                            <td>{el.Estado}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </> : <>
                                                <div className='siignalCenteredText'>
                                                    Este cliente no tiene pagos pendientes
                                                </div>
                                            </>
                                        }
                                    </TabStripTab>
                                    <TabStripTab title={`Pagados (${realizedPayments.length})`}>
                                        {
                                            (realizedPayments.length != 0) ? <>
                                                <table className="renewalsMX-SerialsList">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Gestionar</th>
                                                            <th>Numero Serie</th>
                                                            <th>Periodicidad</th>
                                                            <th>Producto</th>
                                                            <th>Monto Neto</th>
                                                            <th>Estado</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            realizedPayments.map((el, index) => {
                                                                return (
                                                                    <>
                                                                        <tr
                                                                            key={index}
                                                                            className={rfcToProcess[el.NUMSERIE] != undefined || el.Estado == "Pagado" ? "success" : "danger"}>
                                                                            <td>{index + 1}</td>
                                                                            <td>
                                                                                {
                                                                                    rfcToProcess[el.NUMSERIE] != undefined ? "SI" : "NO"
                                                                                }
                                                                            </td>
                                                                            <td>{el.NUMSERIE}</td>
                                                                            <td>{el.PERIODICIDAD}</td>
                                                                            <td>{el.PRODUCTO}</td>
                                                                            <td>MXN${el["MontoNeto"]}</td>
                                                                            <td>{el.Estado}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </> : <>
                                                <div className='siignalCenteredText'>
                                                    Este cliente no tiene pagos registrados.
                                                    <br />
                                                    <b>NOTA:</b> Esta informacion se actualiza a dia vencido, por lo que es posible que algunos pagos aun no los tengamos.
                                                </div>
                                            </>
                                        }
                                    </TabStripTab>
                                </TabStrip>
                            </GridLayoutItem>
                            <GridLayoutItem>
                                <GridLayout
                                    gap={{ rows: 8, cols: 8, }}
                                    cols={[{ width: "auto" }, { width: "auto" }]}>
                                    {
                                        generalFormSettings.map((field, index) => {
                                            return (
                                                <GridLayoutItem className='siignal-box-shadow'>
                                                    <h6>{field.label}</h6>
                                                    {
                                                        field.type == "select" ? <>
                                                            <select onChange={(event) => {
                                                                setFormControl({
                                                                    ...formControl,
                                                                    [field.label.toLocaleLowerCase()]: event.target.value
                                                                })
                                                            }}>
                                                                <option value="">Selecciona una opción</option>
                                                                {
                                                                    field.options.map(option => {
                                                                        return (
                                                                            <option key={option} value={option}>{option}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </> : <>
                                                            <input
                                                                className='renewalsMX-SerialsList-input'
                                                                type={field.type} />

                                                        </>
                                                    }
                                                </GridLayoutItem>
                                            )
                                        })
                                    }
                                </GridLayout>
                            </GridLayoutItem>

                            <GridLayoutItem className='renewalsMX-mt'>
                                <table className="renewalsMX-SerialsList">
                                    <thead>
                                        <tr>
                                            <th>COD</th>
                                            <th>Product</th>
                                            <th>Num Serial</th>
                                            <th>Fecha Renovacion</th>
                                            <th>Medio de Pago</th>
                                            <th>Saldo</th>
                                            <th>Fecha Compromiso</th>
                                            <th>Fecha Extendida</th>
                                            <th>Notas</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.keys(rfcToProcess).map((serial, index) => {
                                                const serialData = rfcToProcess[serial]
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{serialData.PRODUCTO}</td>
                                                            <td>{serial}</td>
                                                            <td>{serialData["FECHA_RENOVACION"]}</td>
                                                            <td>{serialData["FORMA_COBRO"]}</td>
                                                            <td>MXN${serialData["valor"]}</td>
                                                            <td>
                                                                <input
                                                                    type="date"
                                                                    value={serialData["fechaCompromiso"]}
                                                                    onChange={(e) => { }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="date"
                                                                    value={serialData["fechaExtendida"]}
                                                                    onChange={(e) => { }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    value={rfcToProcess[serial].notas}
                                                                    onChange={(e) => {
                                                                        setFormControl(prevState => ({
                                                                            ...prevState,
                                                                            formcontrolBySerial: {
                                                                                ...prevState.formcontrolBySerial,
                                                                                [serial]: {
                                                                                    ...prevState.formcontrolBySerial[serial],
                                                                                    notas: e.target.value,
                                                                                },
                                                                            },
                                                                        }));
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </GridLayoutItem>
                        </> : null
                    }
                    {
                        (formControl.rfcSearch != null && rfcSerials.length == 0) ? <>
                            <div class="siigoRenewalsCenterText">Este RFC no se encuentra en la base de datos.</div>
                        </> : null
                    }

                    {/* 
                    TODO: Add control for all serial taken
                     */}
                </GridLayout >
            </>
    );
};

export default TableroMx;
