import React from "react";
import { SvgIcon } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { editToolsIcon, copyIcon, cancelIcon, saveIcon, eyeIcon } from "@progress/kendo-svg-icons";

const RowDetails = ({ item, customClass }) => {
    return (
        <>
            <tr className={`renewalsAgentPanel-td ${customClass}`}>
                <td className="renewalsAgentPanel-td header" colSpan={4}>
                    Datos de contacto
                </td>
                <td className="renewalsAgentPanel-td header" colSpan={3}>
                    Tipo de gestion
                </td>
                <td className="renewalsAgentPanel-td header" colSpan={3}>
                    Detalles NC
                </td>
            </tr>
            <tr className={`renewalsAgentPanel-tr ${customClass}`}>
                <td className="renewalsAgentPanel-td" colSpan={4}>
                    {item.dynamicInputsData.Contacto}
                    <br />
                    {item.dynamicInputsData.Cargo}
                    <br />
                    {item.dynamicInputsData.Telefono}
                </td>
                <td className="renewalsAgentPanel-td" colSpan={3}>{item.dynamicInputsData.tablero.tipoGestion}</td>
                <td className="renewalsAgentPanel-td" colSpan={3}>
                    {
                        item.dynamicInputsData.tablero.aplicaNC == "No" ? "No Aplica" : <>
                            Tipo: {item.dynamicInputsData.tablero.tipoNC}
                            <br />
                            Motivo: {item.dynamicInputsData.tablero.motivoNC}
                            <br />
                            Valor: {item.dynamicInputsData.tablero.valorNC}
                        </>
                    }
                </td>
            </tr>
            <tr className={`renewalsAgentPanel-tr ${customClass}`}>
                <td className="renewalsAgentPanel-space" colSpan={10}></td>
            </tr>
        </>
    )
}
export { RowDetails }