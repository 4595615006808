import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const DayOperationSlice = createSlice({
    name: 'DayOperation',
    initialState: {
        activitiesDayOperation:null,
        isLoadingDayOperation:false
    },
    reducers: {

        allActividiesDayOperationPerMonth:(state,action)=>{
            state.activitiesDayOperation=action.payload;
        },
        changeIsLoadingDayOperation:(state,action)=>{
            state.isLoadingDayOperation=action.payload
        }
    }
    
});
export const thunkGetMonthOperation=()=>{
    return async (dispatch,getState)=>{
        dispatch(changeIsLoadingDayOperation(true));

            const response = await axios.get(`/Api/DayOperation`);
            let test=response.data          
        dispatch(allActividiesDayOperationPerMonth(test[0].days))
        dispatch(changeIsLoadingDayOperation(false));
    }
}
export const { allActividiesDayOperationPerMonth,changeIsLoadingDayOperation } = DayOperationSlice.actions; 

export const selectactivitiesDayOperation = state => state.DayOperation.activitiesDayOperation;

export const selectisLoadingDayOperation = state => state.DayOperation.isLoadingDayOperation;
export default DayOperationSlice.reducer