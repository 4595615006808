
import { MyCommandCell } from "./topicsCommandCell";
import { Grid, GridColumn as Column, GridColumnMenuFilter, GridColumnMenuSort, GridToolbar } from "@progress/kendo-react-grid";
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { process } from '@progress/kendo-data-query';
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Button } from '@progress/kendo-react-buttons'
import { useCallback, useEffect, useState } from "react";

const editField = "inEdit";

export const ColumnMenu = (props) => {
  return (
      <div>
          <GridColumnMenuSort {...props} />
          <GridColumnMenuFilter {...props} />
      </div>
  );
}


export const ListUsers = () => {
  const [dataDetails, setDataDetails] = useState([]);
  const [isloading,setLoading] = useState(true);
  const [take, setTake] = useState();
  const [skip, setSkip] = useState(0);
  const [sort, setSort] = useState([]);
  const [group, setGroup] = useState([]);
  const [filter, setFilter] = useState(null);


  useEffect(() => {
    const loadGet = async () => {
      const response = await axios.get('/users');
      const test=response.data
      setDataDetails(test)
      console.log(test)
      setLoading(false);
    }
    loadGet();
},[]);

const dataState = {
  take,
  skip,
  sort,
  group,
  filter,
};

const onDataStateChange = useCallback(
  (event) => {
      setTake(event.dataState.take);
      setSkip(event.dataState.skip);
      setSort(event.dataState.sort);
      setGroup(event.dataState.group);
      setFilter(event.dataState.filter);
  },
  [setTake, setSkip, setSort, setGroup]
);

  // modify the data in the store, db etc
  const remove = dataItem => {
    axios.post('/api/TNB/TrainingTopics/DeleteOne', dataItem).then((response)=>{
      setDataDetails(response.data)})
  };
  const add = dataItem => {
    dataItem.inEdit = false;
    axios.post('/api/TNB/TrainingTopics/InsertNew', dataItem).then((response)=>{
      setDataDetails(response.data)
  })
    ;
  };
  const update = dataItem => {
    dataItem.inEdit = false;
    axios.post('/api/TNB/TrainingTopics/UpdateOne', dataItem).then((response)=>{
      setDataDetails(response.data)})
  };
  const refresh = () => {
    axios.get('/users').then((response)=>{
      setDataDetails(response.data)})
  };

  // Local state operations
  const discard = () => {
    const newData = [...dataDetails];
    newData.splice(0, 1);
    setDataDetails(newData);
  };
  const cancel = dataItem => {
    setDataDetails(dataDetails.map(item => item.idUser === dataItem.idUser ? {
        ...item,
        inEdit: false
      } : item));
  };
  const enterEdit = dataItem => {
    setDataDetails(dataDetails.map(item => item.idUser === dataItem.idUser ? {
      ...item,
      inEdit: true
    } : item));
  };
  const itemChange = event => {
    const newData = dataDetails.map(item => item.idUser === event.dataItem.idUser ? {
      ...item,
      [event.field || '']: event.value
    } : item);
    setDataDetails(newData);
  };
  const addNew = () => {
    const newDataItem = {
      inEdit: true,
    };
    setDataDetails([newDataItem, ...dataDetails]);
  };


  const CommandCell = props => <MyCommandCell {...props} edit={enterEdit} remove={remove} add={add} discard={discard} update={update} cancel={cancel} editField={editField} />;

  const processedData = process(dataDetails, dataState);

  if (isloading) {
    return <div className='desktop-view-SDR'>
                <div id='reportContainerSDR'>
                <div className='centerDS'><CircularProgress/></div>
                </div>
            </div>
} else 
  return (    
  <>
  <h2 className='page-header'>DataSmart User Management</h2>
  <Grid
      style={{
        height: "100%",
      }}

      data={processedData} onItemChange={itemChange} onDataStateChange={onDataStateChange} editField={editField}
    >
    <GridToolbar>
            <Button title="Add new" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary" onClick={addNew}>
              Agregar
            </Button>
            <Button title="Refresh" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary" onClick={refresh}>
              Actualizar
            </Button>
      </GridToolbar>
      <Column field={"idUser"} title={"Id"} width="60px" editable={false} />
      <Column field={"name"} title={"Nombre"} editable={false} columnMenu={ColumnMenu}/>
      <Column field={"username"} title={"Email"} />
      <Column field={"area"} title={"Area"} editable={false} columnMenu={ColumnMenu}/>
      <Column field={"role"} title={"Rol"}  columnMenu={ColumnMenu}/>
      <Column cell={CommandCell} title={"Acciones"} />
    </Grid>
    </>
  )
  
};
