import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import "./TranferencesHelper.css"
import axios from 'axios';
import { writeData, readData, deleteData } from './services/db';
import { useSelector, useDispatch } from 'react-redux';
import { setFormData, setCurrentStep, setSelectionHistory, setSelectionHistoryIds, setShapesData, setConectionsData } from '../../../../redux/renewalsTreeReducer';
import { selectFormData, selectCurrentStep, selectSelectionHistory, selectSelectionHistoryIds, selectShapesData, selectConectionsData } from '../../../../redux/renewalsTreeReducer';
import moment from 'moment';
import Admin from './Modules/AdminURU';
import Agent from './Modules/Agent';

function TranferencesHelperDashboardURU() {
    const dispatch = useDispatch();
    const [showAgent, setShowAgent] = useState(true);
    const [lastUpdate, setLastUpdate] = useState(null);
    const [loading, setLoading] = useState({
        state: true,
        messageQueue: []
    });

    useEffect(() => {
        updateLoading("add", "Obteniendo configuración del árbol");
        axios.get(`/api/siignalGetTRansferencesTreeURU`).then(res => {
            console.log(res)
            updateLoading("add", "Configuración obtenida, guardando en local");
            let requestData = res.data[0];
            const storedData = requestData.shapes;
            const storedLines = requestData.lines;
            if (storedData) {
                console.log("Stored Data:")
                console.log(storedData);
                const shapes = storedData;
                dispatch(setShapesData(shapes));

                if (storedLines) {
                    console.log("Stored Lines:")
                    console.log(storedLines);
                    dispatch(setConectionsData(storedLines));
                }
                updateLoading("reset")
            }
        }).catch(err => {
            updateLoading("add", "Error obteniendo configuración del árbol, Contacta tu lider");
            console.warn(err)
        })
    }, [])


    const updateLoading = (action, message) => {
        if (action == "reset") {
            setLoading({
                state: false,
                messageQueue: []
            });
        } else {
            setLoading((prevState) => ({
                ...prevState,
                state: true,
                messageQueue: [...prevState.messageQueue, message]
            }));
        }
    }

    return (
        (loading.state) ? <>
            <div className="loading-center">
                <div className="loading-container">
                    {
                        loading.messageQueue.map((message, index) => {
                            return <p key={index}>{`${message}`}</p>
                        })
                    }
                </div>
            </div>
        </> : <>
            <Button
                variant="contained"
                color="primary"
                className='renewals-btnSwitch'
                onClick={() => setShowAgent(!showAgent)}
            >
                {showAgent ? 'Ver Admin' : 'Ver Agente'}
            </Button>

            {showAgent ? <Agent /> : <Admin />}
        </>
    );
}

export { TranferencesHelperDashboardURU};