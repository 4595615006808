import * as React from 'react';
import { Splitter, TileLayout } from '@progress/kendo-react-layout';

import { TnbFormularioCapacitacion } from '../../components/TNB/TrainingForm/tnbFormularioCapacitacion';
import {TnbGruposTable} from '../../components/TNB/TrainingTable/table'
import './styles.css'


export const AppTNB=(props) => {
  let tiles2
  const [panes, setPanes] = React.useState([
    {
      size: "33%",
      scrollable: false,
    },
    {},
  ]);
  const onChange = (event) => {
    setPanes(event.newState);
  };
  if (props.isAllowed.admin){
     tiles2 = [
      {
        defaultPosition: {
          col: 1,
          colSpan: 4,
          rowSpan: 4,
        },
        header: "Crea una capacitación",
        body: <TnbFormularioCapacitacion />,

        reorderable: false
      },
      {
        defaultPosition: {
          col: 5,
          colSpan: 8,
          rowSpan: 4,
        },
        header: "Detalle Grupos",
        body: <TnbGruposTable />,

        reorderable: false
      },
    ];
  }
  else if (props.isAllowed.entrenador) {
     tiles2 = [
      {
        defaultPosition: {
          col: 1,
          colSpan: 4,
          rowSpan: 4,
        },
        header: "Crea una capacitación",
        body: <TnbFormularioCapacitacion/>,
        resizable: false,
        reorderable: false
      },
      {
        defaultPosition: {
          col: 5,
          colSpan: 8,
          rowSpan: 4,
        },
        header: "Detalle Grupos",
        body: <TnbGruposTable userV={props.userValue}/>,
        resizable: false,
        reorderable: false
      },
    ];
  }

    return (
      <div className='tnb-container'>
      <Splitter
        style={{

        }}
        panes={panes}
        onChange={onChange}
      >
            <div className="panels-tnb formularioTNB">
              <h6>Crear Capacitación</h6>
            <TnbFormularioCapacitacion/>
            </div>
            <div className="panels-tnb">
            <h6>Detalle Grupos</h6>
            {props.isAllowed.admin?<TnbGruposTable />:<TnbGruposTable userV={props.userValue}/>}           
            </div >
            </Splitter>

      </div>
        )
    }
