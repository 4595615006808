// import css
import './KPIScorecard.css';
import { Loading } from '../Loading/Loading';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { Grid, Typography } from '@mui/material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import PeopleIcon from '@mui/icons-material/People';
import PhoneIcon from '@mui/icons-material/Phone';

// import react
import React, { useEffect } from 'react';

const KPIScorecard = ({ scorecardsData, selectedView }) => {
    const [loading, setLoading] = React.useState(true);
    const [scorecardData, setScorecardData] = React.useState(null);
    useEffect(() => {
        processData(scorecardsData)
    }, [])

    useEffect(() => {
        processData(scorecardsData)
    }, [selectedView, scorecardsData])

    useEffect(() => {
        setLoading(false)
    }, [scorecardData])


    const processData = (scorecardsData) => {
        const scorecards = {
            TMO: {
                name: "TMO",
                value: 0,
            }, ACW: {
                name: "ACW",
                value: 0,
            }, SLA: {
                name: "SLA",
                value: 0,
            }, ABANDON: {
                name: "% Abandono",
                value: 0,
            }, VOLUME_FORECAST: {
                name: "Vol Forecast",
                value: 0,
            }, VOLUME_REAL: {
                name: "Vol Real",
                value: 0,
            }, VOLUME: {
                name: "% Volumen",
                value: 0,
            }, STAFF_FORECAST: {
                name: "Staff Forecast",
                value: 0,
            }, STAFF_REAL: {
                name: "Staff Real",
                value: 0,
            }, STAFFING: {
                name: "% Staffing",
                value: 0,
            }
        }

        let totalInteractions = 0;
        let totalHandleTime = 0;
        let totalACW = 0;
        let totalInteractionsInSLA = 0;
        let totalAbandon = 0;

        for (const key in scorecardsData) {
            if (Object.hasOwnProperty.call(scorecardsData, key)) {
                const element = scorecardsData[key];
                let elementDatetime = new Date(element.Fecha + " " + element.Intervalo)
                let nowDatetime = new Date()

                nowDatetime.setMinutes(nowDatetime.getMinutes() - 30)

                if (elementDatetime < nowDatetime) {
                    totalInteractions += (element.VOLUME_REAL == undefined) ? 0 : element.VOLUME_REAL
                    totalHandleTime += (element.HT == undefined) ? 0 : element.HT
                    totalACW += (element.ACW == undefined) ? 0 : element.ACW
                    totalInteractionsInSLA += (element.SLA == undefined) ? 0 : element.SLA
                    totalAbandon += (element.ABANDONED == undefined) ? 0 : element.ABANDONED

                    scorecards.VOLUME_FORECAST.value += (element.VOLUME_FORECAST == undefined) ? 0 : parseInt(element.VOLUME_FORECAST)
                    scorecards.VOLUME_REAL.value += (element.VOLUME_REAL == undefined) ? 0 : parseInt(element.VOLUME_REAL)

                    scorecards.STAFF_FORECAST.value += (element.STAFF_FORECAST == undefined) ? 0 : parseInt(element.STAFF_FORECAST)
                    scorecards.STAFF_REAL.value += (element.STAFF_REAL == undefined) ? 0 : parseInt(element.STAFF_REAL)
                }
            }
        }

        scorecards.TMO.value = (totalHandleTime / (totalInteractions - totalAbandon)).toFixed(2)
        scorecards.ACW.value = (totalACW / totalInteractions).toFixed(2)
        scorecards.SLA.value = ((totalInteractionsInSLA / totalInteractions) * 100).toFixed(2)
        scorecards.ABANDON.value = ((totalAbandon / totalInteractions) * 100).toFixed(2)

        scorecards.VOLUME.value = ((scorecards.VOLUME_REAL.value / scorecards.VOLUME_FORECAST.value) * 100).toFixed(2)

        scorecards.STAFFING.value = ((scorecards.STAFF_REAL.value / scorecards.STAFF_FORECAST.value) * 100).toFixed(2)

        setScorecardData(scorecards)
    }
    const iconsList = (element) => {
        switch (element) {
            case "TMO":
                return <AccessTimeIcon sx={{ fontSize: 65, color: "#00244c" }} />
                break;
            case "ACW":
                return <AccessTimeFilledIcon sx={{ fontSize: 65, color: "#00244c" }} />
                break;
            case "STAFFING":
                return <PeopleIcon sx={{ fontSize: 65, color: "#00244c" }} />
                break;
            case "VOLUME":
                return <PhoneIcon sx={{ fontSize: 65, color: "#00244c" }} />
                break;
            default:
                return <AccountTreeIcon sx={{ fontSize: 65, color: "#00244c" }} />
                break;
        }
    }

    const formatValue = (valor, name) => {
        if (valor != "-") {
            if (name == "TMO" || name == "ACW") {
                valor = new Date(valor * 1000).toISOString().substr(14, 5);
            }
            if (name == "SLA" || name == "% Abandono" || name == "% Volumen" || name == "% Staffing") {
                valor = `${valor}%`
            }
        }
        return valor;
    }

    return (
        loading ? <Loading /> : <>
            <GridLayout
                className="siignal-grid-layout"
                align={{ horizontal: 'center', }}
                cols={[{ width: "auto" }, { width: "auto" }, { width: "auto" }, { width: "auto" }, { width: "auto" }]}
                gap={{ rows: 8, cols: 8, }}>
                {
                    Object.keys(scorecardData).map(el => {
                        let valor = (isNaN(scorecardData[el].value) || scorecardData[el].value == Infinity) ? "-" : scorecardData[el].value;
                        valor = formatValue(valor, scorecardData[el].name)
                        return (

                            <GridLayoutItem key={el} className='siignal-box-shadow'>
                                <Grid container item xs={12}>
                                    <Grid item xs={8}>
                                        <Typography className="label">{scorecardData[el].name}</Typography>
                                        <Typography className="bigNumber">{valor}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {
                                            iconsList(el)
                                        }
                                    </Grid>
                                </Grid>
                            </GridLayoutItem>
                        )
                    })
                }
            </GridLayout>
        </>
    );
}
export { KPIScorecard } 