import { Button } from '@progress/kendo-react-buttons';
import { Avatar, Card, CardActions, CardBody, CardHeader, CardTitle } from '@progress/kendo-react-layout';
import React from 'react'
import { FaChartLine, FaChartPie, FaGraduationCap, FaLandmark, FaTasks } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export const ShortCutLinks = () => {
    const cardsData = [
		{
			title: 'Reportes',
			icon: <FaChartPie className="iconCardDS2" />,
			bodytext:
				'Acá podrás encontrar todos los reportes operativos y de resultados de las diferentes areas de Siigo, en un solo lugar.',
			link: `/Reports/`,
		},
		{
			title: 'Mesa de Ayuda',
			icon: <FaTasks className="iconCardDS2" />,
			bodytext:
				'Acá podrás crear requerimientos para solicitudes, reportar incidencias y creacion de analisis y proyectos relacionados con data.',
			link: '/HelpDesk',
		},
        {
			title: 'Entrenamiento y Valoraciones',
			icon: <FaGraduationCap className="iconCardDS2" />,
			bodytext:
				'Acá podrás encontrar toda la información relacionada con Entrenamiento y Valoraciones.',
			link: '/Training',
		},
		{
			title: 'Data Governance',
			icon: <FaLandmark className="iconCardDS2" />,
			bodytext:
				'Acá podrás encontrar toda la información relacionada con documentación, procesos, queries y gobierno de datos.',
			link: '/DataGovernance',
		}
	];
  return (
    <div className="k-card-deck column-cards" style={{zIndex:0}}>
						{cardsData.map((card, index) => {
							return (
								<Card
									key={index} 
									style={{
										minHeight: 150,
										maxWidth: 400,
										margin: 0,
										zIndex: 1,
									}}>
										<CardHeader
											className="k-hbox"
											style={{
												background: 'transparent',
												minHeight: '45px',
											}}>
											<Avatar
												type="icon"
												size="medium"
												rounded={'small'}
                                                style={{color:'gray'}}
                                                themeColor={'base'}
												>
												{card.icon}
											</Avatar>
											<div>
												<CardTitle
													style={{
														marginTop: '12px',
														fontSize: '17px',
                                                        color:'gray',
														fontWeight: 700,
													}}>
													{card.title}
												</CardTitle>
											</div>
										</CardHeader>
										<CardBody>
											<p>{card.bodytext}</p>
										</CardBody>
										<CardActions>
											<Link to={card.link}>
												<Button themeColor={'base'}>Ir a {card.title}</Button>
											</Link>
										</CardActions>
									</Card>
							);
						})}
					</div>
  )
}
