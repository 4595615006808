import React,{useState} from 'react'
import * as Icons from 'react-icons/fa';
import './adminbotones.css'
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

export default function AdminBotones() {
    const [icono, setIcono] = useState("FaRegUser");

    const handleSubmit = (e) => {
      e.preventDefault();
  };
  const handleIcons=(iconName)=>{
    console.log(Icons[iconName])
    
    setIcono(iconName)
    //sessionStorage.getItem('role')
  }

  const Icon = ({ iconName }) => {
    const IconComponent = Icons[iconName];
    return <IconComponent />;
  };

  const iconComponents = Object.keys(Icons).map((iconName) => {
    // Acceder al componente del ícono usando el nombre
    const IconComponent = Icons[iconName];
    return <IconComponent key={iconName} style={{ margin: '10px' }} onClick={() => handleIcons(iconName)}/>;

  });
  return (
    <div  className="formulario-reporte container">
          <div className='viewIcon'>
            <div className='iconoDiv'>
            <Icon iconName={icono} />
            </div>
          </div>
          <form onSubmit={handleSubmit} className="formulario-reporte">
            <h2>Próximamente será para modificar los botones</h2>

            
            
          <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Accordion 1</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {iconComponents}
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Accordion 2</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Disabled Accordion</Typography>
        </AccordionSummary>
      </Accordion>

          
        </form>
        </div>
        

      
  )
}
