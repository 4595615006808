import React, { useState } from 'react';
import { Field } from '@progress/kendo-react-form';
import {  FormCheckbox, FormInput,FormRadioGroup } from '../../components/RequestForm/form-components';
import {  AreaNameValidator, requiredValidator, titleValidator, userNameValidator } from '../../components/RequestForm/Validators';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert } from '@mui/material';
import { DropdownWorkers } from '../../components/RequestForm/DropdownWorkers';
import { TypeofRequest } from '../../components/RequestForm/data';

export const Step1RequestForm = () => {
	const navigate=useNavigate();
    const [enableOtherPanel, setEnableOtherPanel] = useState(false);
	const [enableAlert, setEnableAlert] = useState(false)
	const TypeRequest = useParams().type;
	const AlertText = {
		"Incidencia": "Una incidencia es cualquier evento que no forma parte de la operación estándar de un servicio y que causa, o puede causar, una interrupción o una reducción en la calidad del mismo.",
		"Requerimiento": "Un requerimiento es una solicitud formal para añadir una nueva característica, una mejora o una modificación a un sistema o servicio existente.",
		"solicitud de Reporte": "Solicitar un reporte implica pedir subir un archivo de un reporte a DataSmart. No es necesario que el reporte esté en PowerBI, pero si debe usted tener el archivo .pbix."
	};

    const handleChangeChild = (event) => {
        setEnableOtherPanel(event.value);
    };
	const handleChangeTypeofRequest = (event) => {
		setEnableAlert(true)
		navigate(`/HelpDesk/RegisterRequest/${event.value}`);

	};

    return (
        <div>
		<Field
			key={'requestorName'}
			id={'requestorName'}
			name={'requestorName'}
			label={'Nombre'}
			disabled={true}
			component={FormInput}
			validator={userNameValidator}
		/>
		<Field
			key={'requestorArea'}
			id={'requestorArea'}
			name={'requestorArea'}
			label={'Área'}
			disabled={true}
			component={FormInput}
			validator={AreaNameValidator}
		/>
		<Field
			key={'requestType'}
			id={'requestType'}
			name={'requestType'}
			label={'Tipo de Solicitud'}
			layout={'horizontal'}	
			onChange={handleChangeTypeofRequest}
			component={FormRadioGroup}
			data={TypeofRequest}
			validator={requiredValidator}
		/>
		{enableAlert && (
					<Alert severity="info">
						{AlertText[TypeRequest]}
					</Alert>
				)}
		<Field
			key={'requestorOther'}
			id={'requestorOther'}
			name={'requestorOther'}
			onChange={handleChangeChild}
        	label={"¿La solictud es para alguien más?"}
			component={FormCheckbox}
		/>
		{enableOtherPanel&&
			<Field
			key={'requestorNameOther'}
			id={'requestorNameOther'}
			name={'requestorNameOther'}
			label={'Indica quien es el dueño de la solicitud:'}
			component={DropdownWorkers}
			validator={userNameValidator}
		/>
		}
		<Field
			key={'title'}
			id={'title'}
			name={'title'}
			label={`Título para ${TypeRequest}`}
			component={FormInput}
			validator={titleValidator}
		/>
		
		
	</div>
    );
};
