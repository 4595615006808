import * as React from 'react';

import { Badge } from '@progress/kendo-react-indicators';

export const FullNameCell = (props) => {
    const customerPhotoStyle = {
        display: 'inline-block',
        width: 32,
        height: 32,
        borderRadius: '50%',
        backgroundSize: '32px 35px',
        backgroundPosition: 'center center',
        verticalAlign: 'middle',
        lineHeight: '32px',
        boxShadow: 'inset 0 0 1px #999, inset 0 0 10px rgba(0,0,0,.2)',
        marginLeft: '5px',
    };

    const customerName = {
        display: 'inline-block',
        verticalAlign: 'middle',
        lineHeight: '32px',
        paddingLeft: '10px'
    };

    if (props.rowType === 'groupHeader') {
        return null;
    }

    return (
        <td>
            <div style={customerPhotoStyle} />
            <div style={customerName}>{props.dataItem.fullName}</div>
        </td>
    );
};
export const OnlineCell = (props) => {
    const badgeStyle = {
        position: 'relative',
        display: 'inline'
    };

    if (props.rowType === 'groupHeader') {
        return null;
    }

    return (
        <td style={{ textAlign: 'center' }}>

            {
                props.dataItem.isOnline === true ?
                    <Badge themeColor={'success'} shape={'rounded'} style={badgeStyle}>Online</Badge> :
                    <Badge themeColor={'error'} shape={'rounded'} style={badgeStyle}>Offline</Badge>
            }
        </td>
    );
};

export const ScheduleCell = (props) => {
    if (props.rowType === 'groupHeader') {
        return null;
    }

    let scheduledDateTime = props.dataItem[props.field];
    let scheduledTime = (scheduledDateTime !== undefined) ? scheduledDateTime : '-';

    return (
        <td className={props.className}>
            <span style={undefined}>{scheduledDateTime}</span>
        </td>
    );
};


export const AgentCell = (props) => {
    if (props.rowType === 'groupHeader') {
        return null;
    }
    let agentName = props.dataItem['AGENT_NAME'];
    let agentEmail = props.dataItem['AGENT_EMAIL'];

    return (
        <td className={props.className}>
            <span style={undefined}>
                <span>{agentName}</span>
                <br />
                <small>{agentEmail}</small>
            </span>
        </td>
    );
};

export const agentState = (props) => {
    if (props.rowType === 'groupHeader') {
        return null;
    }

    let agentState = props.dataItem[props.field];
    let agentReasonCode = props.dataItem['REASON_CODE'];
    return (
        <td className={props.className}>
            <span style={undefined}>
                <span>{agentState}</span>
                <br />
                <small><i>{(agentReasonCode == '-') ? "" : agentReasonCode}</i></small>
            </span>
        </td>
    );
};

export const TimestampCell = (props) => {
    if (props.rowType === 'groupHeader') {
        return null;
    }

    let timestamp = props.dataItem[props.field];

    let timestampDate = new Date(timestamp);

    // add 2hrs and format as hh:mm:ss
    timestampDate.setHours(timestampDate.getHours() + 2);
    // calculate seconds until now and format as hh:mm:ss
    let timeUntilNowSeconds = (new Date() - timestampDate) / 1000//.toLocaleString()//.toLocaleTimeString('en-US', { hour12: false });
    // formtat seconds  as hh:mm:ss
    const hours = Math.floor(timeUntilNowSeconds / 3600);
    const minutes = Math.floor((timeUntilNowSeconds % 3600) / 60);
    const secs = Math.floor(timeUntilNowSeconds % 60);

    let timeFinal = (hours < 10 ? '0' : '') + hours +
        ':' +
        (minutes < 10 ? '0' : '') + minutes +
        ':' +
        (secs < 10 ? '0' : '') + secs


    timestamp = timestampDate.toLocaleTimeString('en-US', { hour12: false });

    return (
        <td className={props.className}>
            <span style={undefined}>{timestamp}</span>
        </td>
    );
};

export const StateTimeCell = (props) => {
    if (props.rowType === 'groupHeader') {
        return null;
    }

    const thisRowData = props.dataItem;
    let badgeColor = 'success';

    if (thisRowData.REASON_CODE === 'Break') {
        badgeColor = checkIfIsInInterval(thisRowData.breakStart1, thisRowData.breakEnd1, thisRowData.TIMESTAMP) | checkIfIsInInterval(thisRowData.breakStart2, thisRowData.breakEnd2, thisRowData.TIMESTAMP) ? 'success' : 'error';
    } else if (thisRowData.REASON_CODE === 'Almuerzo') {
        badgeColor = checkIfIsInInterval(thisRowData.lunchStart, thisRowData.lunchEnd, thisRowData.TIMESTAMP) ? 'success' : 'error';
    } else if (thisRowData.REASON_CODE === 'After Call Work') {
        badgeColor = "warning"
    } else if (thisRowData.STATE === 'After Call Work') {
        badgeColor = "warning"
    }

    const badgeStyle = {
        position: 'relative',
        display: 'inline'
    };

    let timestampDate = new Date(thisRowData[props.field]);
    timestampDate.setHours(timestampDate.getHours() + 2);
    let timeUntilNowSeconds = (new Date() - timestampDate) / 1000

    const hours = Math.floor(timeUntilNowSeconds / 3600);
    const minutes = Math.floor((timeUntilNowSeconds % 3600) / 60);
    const secs = Math.floor(timeUntilNowSeconds % 60);

    let timeFinal = (hours < 10 ? '0' : '') + hours +
        ':' +
        (minutes < 10 ? '0' : '') + minutes +
        ':' +
        (secs < 10 ? '0' : '') + secs

    return (
        <td className={props.className}>
            <span style={undefined}>
                <Badge themeColor={badgeColor} shape={'rounded'} style={badgeStyle}>{timeFinal}</Badge>
            </span>
        </td>
    );
};

function checkIfIsInInterval(intervalStart, intervarEnd, timestamp) {
    const today = new Date();

    let intervalStartTimestamp = new Date(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()} ${intervalStart}`).getTime();
    let intervarEndTimestamp = new Date(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()} ${intervarEnd}`).getTime();


    let timestampToCheck = new Date(timestamp).getTime();
    return (timestampToCheck >= intervalStartTimestamp && timestampToCheck <= intervarEndTimestamp);
}

