import React, {useState} from 'react';
import { ProfileData } from "./ProfileData";
import { callMsGraph, callMsGraphCalendar, callMsGraphPhoto } from "../Graph";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../Config";
import Button from "react-bootstrap/Button";


function ProfileContent() {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [graphDataPhoto, setGraphDataPhoto] = useState(null);
    const [graphCalData, setGraphCalData] = useState(null);
  
    const name = accounts[0] && accounts[0].name;
  
    function RequestProfileData() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
  
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            callMsGraph(response.accessToken).then(response => setGraphData(response));
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                callMsGraph(response.accessToken).then(response => setGraphData(response));
            });
        });
    }

    function RequestProfilePhoto() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
  
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            callMsGraphPhoto(response.accessToken).then(response => setGraphDataPhoto(response));
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                callMsGraphPhoto(response.accessToken).then(response => setGraphDataPhoto(response));
            });
        });
    }


    function RequestCalaendarData() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
  
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            callMsGraphCalendar(response.accessToken).then(response => setGraphCalData(response));
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                callMsGraphCalendar(response.accessToken).then(response => setGraphCalData(response));
            });
        });
    }
  
    return (
        <>
            <h3>Bienvenido: {name}</h3>
            {graphDataPhoto ?
                <img src={graphDataPhoto}/>:  
                <Button onClick={RequestProfilePhoto}>Request Profile Photo</Button>
            }
            {graphData ? 
                <ProfileData graphData={graphData} />
                :
                <Button onClick={RequestProfileData}>Request Profile Information</Button>
            }
        </>
    );
  };

  export default ProfileContent