import { useDispatch } from "react-redux";
import { controller } from "../../../components/ViewCustomer/db/controller";
import { setChecking } from "../../../redux/credencialesReducer";
import { useEffect, useState } from "react";
import useAxiosEmails from "../../../components/ViewCustomer/Hook/UseAxios";
import { Loader } from "@progress/kendo-react-indicators";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TileLayout } from "@progress/kendo-react-layout";
import { tiles } from "../../../components/ViewCustomer/utils/ExtractData";
import { json } from "react-router-dom";

export const MarketingView = () => {
    const dispatch = useDispatch();
    const [params,setParams] = useState(JSON.parse(sessionStorage.getItem('dataComercial'))['asesor']);
    const [rowHeight, setRowHeight] = useState(100);
    useEffect(() => {
        const handleResize = () => {
            setRowHeight(window.innerHeight / 7);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
  return (
    <div style={{}}>
                    <div style={{display:'flex'}}>
                    <h4
                            style={{
                                position: 'absolute',
                                right: '40px',
                                top: '85px',
                                fontWeight: 'bold',
                                color: '#818181',
                                zIndex: 1,
                            }}>
                            {new Date().toLocaleDateString()}
                        </h4>
                    </div>
                    <TileLayout
                        columns={8}
                        columnWidth={'25%'}
                        rowHeight={rowHeight}
                        autoFlow={'column dense'}
                        gap={{
                            rows: '1rem',
                            columns: '1rem',
                        }}
                        items={tiles}
                    />
                </div>
  )
}
