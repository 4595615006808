import React, { useState, useEffect } from 'react';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
    Notification,
    NotificationGroup
} from '@progress/kendo-react-notification';
import { Slide } from '@progress/kendo-react-animation';
import {
    GridLayout,
    GridLayoutItem,
    Card,
    CardHeader,
    CardTitle,
    CardBody
} from '@progress/kendo-react-layout';
import { getWhatsAppConnectCampaigns, saveAdminEditWhatsAppConnectCampaign } from '../requestService';
import { Details } from './details';
import UploadCenter from '../UploadCenter';
import { useSelector, useDispatch } from 'react-redux'
import {selectMessageSelected} from './../../../../../../redux/whatsAppConnect'

const AdminPanel = () => {
    const [adminState, setAdminState] = useState({
        isLoading: true,
        savedCampaigns: [],
        uploadPanelOpen: false,
        selectedFileToUpload: {},
        isAdmin: true,
    });
    const messageSelected = useSelector(selectMessageSelected);
    const [showDetails, setShowDetails] = useState([]);
    const [selectedDate, setSelectedDate] = useState((new Date()).toISOString().split("T")[0])

    const callback = (res) => {
        let data = generateIntraDates(res)

        const { minDate, maxDate } = data.reduce(({ minDate, maxDate }, campaign) => {
            const startDate = new Date(campaign.campaignDetails.fecha_de_inicio);
            const endDate = new Date(campaign.campaignDetails.fecha_de_fin);
            return {
                minDate: startDate < minDate ? startDate : minDate,
                maxDate: endDate > maxDate ? endDate : maxDate,
            };
        }, {
            minDate: new Date(selectedDate),
            maxDate: new Date(selectedDate),
        });

        for (let i = minDate; i <= maxDate; i.setDate(i.getDate() + 1)) {
            let campaignsInDate = data.filter(campaign => {
                const campaignStartDate = new Date(campaign.campaignDetails.fecha_de_inicio);
                const campaignEndDate = new Date(campaign.campaignDetails.fecha_de_fin);
                return campaignStartDate <= i && campaignEndDate >= i;
            })
            campaignsInDate.sort((a, b) => {
                return a.datesInformation[i.toISOString().split("T")[0]].priority - b.datesInformation[i.toISOString().split("T")[0]].priority
            }).map(campaign => {
                if (campaign.datesInformation[i.toISOString().split("T")[0]].priority == 999999) {
                    data.find(el => el._id.$oid == campaign._id.$oid).datesInformation[i.toISOString().split("T")[0]].priority = data.indexOf(campaign) + 1
                }
            })
        }
        setAdminState({
            ...adminState,
            isLoading: false,
            savedCampaigns: res
        });
        setShowDetails(Array(res.length).fill(false));
    };

    const generateIntraDates = (data) => {
        data = data.map((el, index) => {
            if (el.datesInformation == undefined) {
                el.datesInformation = {}
                const start = new Date(el.campaignDetails.fecha_de_inicio)
                const end = new Date(el.campaignDetails.fecha_de_fin)
                for (let i = start; i <= end; i.setDate(i.getDate() + 1)) {
                    el.datesInformation[i.toISOString().split("T")[0]] = {
                        status: "Pendiente",
                        priority: 999999,
                        uploadedData: [],
                        generatedSuccess: [],
                        generatedFail: [],
                        canUpload: {
                            status: true,
                            closedAt: null,
                            closedBy: null,
                        }
                    }
                }
                return el
            } else {
                return el
            }
        })
        return data;
    }

    const changePriority = (currentCampaign, prevCampaign, nextCampaign, currentPriority, priorityDirection) => {
        const currentData = [...adminState.savedCampaigns];
        let selectedDateData = currentData.filter(campaign => {
            return campaign.datesInformation[selectedDate]
        });

        if (currentPriority == 1 && priorityDirection == -1 || currentPriority == selectedDateData.length && priorityDirection == 1) {
            alert("No se puede mover")
        } else {
            let currentCampaignId = currentCampaign._id.$oid
            let otherCampaignId = (priorityDirection == -1) ? prevCampaign._id.$oid : nextCampaign._id.$oid
            currentData.map(campaign => {
                if (campaign._id.$oid == currentCampaignId) {
                    campaign.datesInformation[selectedDate].priority = currentPriority + priorityDirection
                } else if (campaign._id.$oid == otherCampaignId) {
                    campaign.datesInformation[selectedDate].priority = currentPriority
                }
                return campaign
            })

            setAdminState({
                ...adminState,
                savedCampaigns: currentData
            })
        }
    }

    const generateTemplateFile = (campaign) => {
        let fileHeaders = ["fecha"]
        let new_row = ',,'+messageSelected['URL IMAGEN']
        campaign.messageParametersConfig.map((el) => {
            fileHeaders.push(el.parameterName)
        })
        fileHeaders.push("telefono")
        const csvTemplateFile = fileHeaders.join(",") + "\n";
        const blob = new Blob([csvTemplateFile], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `WhatsAppConectTemplate.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    const adminSaveCallback = (res) => {
        console.log(res)
    }

    const saveAdminCampaignsData = () => {
        let campaigns = adminState.savedCampaigns;
        saveAdminEditWhatsAppConnectCampaign(campaigns, adminSaveCallback)
    }

    const handleUpload = async (data, campaign) => {
        getWhatsAppConnectCampaigns((campaignsUpdated) => {
            console.log({
                data: data,
                campaign: campaign,
                campaignsUpdated: campaignsUpdated
            })
            const updatedCampaignId = campaign._id.$oid;
            campaignsUpdated = campaignsUpdated.map(camp => {
                if (camp._id.$oid == updatedCampaignId) {
                    data.map(row => {
                        if (camp.datesInformation[row.fecha] != undefined) {
                            if (camp.datesInformation[row.fecha].canUpload.status) {
                                camp.datesInformation[row.fecha].uploadedData.push(row)
                            } else {
                                console.log(row.fecha, "Carga ya esta cerrada")
                            }
                        } else {
                            console.log(row.fecha, "No dentro del rango programado")
                        }
                    })
                }
                return camp
            })
            saveAdminEditWhatsAppConnectCampaign(campaignsUpdated, adminSaveCallback)
        })
    }

    const openUploader = (campaign) => {
        let fileHeaders = ["fecha"]
        campaign.messageParametersConfig.map((el) => {
            fileHeaders.push(el.parameterName)
        })
        fileHeaders.push("telefono")
        setAdminState({
            ...adminState,
            uploadPanelOpen: true,
            selectedFileToUpload: {
                [campaign.messageSelected["Template Name"]]: {
                    "cols": fileHeaders,
                    "endPoint": "",
                    "handleUpload": handleUpload,
                    "campaign": campaign
                }
            }
        })
    }

    const adminProcessFiles = () => {
        getWhatsAppConnectCampaigns((updatedCampaigns) => {
            updatedCampaigns = updatedCampaigns.map(campaign => {
                campaign.datesInformation[selectedDate] = {
                    ...campaign.datesInformation[selectedDate],
                    canUpload: {
                        status: false,
                        closedAt: new Date(),
                        closedBy: "Admin"
                    }
                }
                return campaign
            })
            saveAdminEditWhatsAppConnectCampaign(updatedCampaigns, adminSaveCallback)
            getWhatsAppConnectCampaigns((updatedCampaigns) => {
                let finalCampaignData = updatedCampaigns.map(campaign => {
                    return {
                        id: campaign._id.$oid,
                        data: campaign.datesInformation[selectedDate]
                    }
                }).filter(campaign => {
                    return campaign.data.status == "Aprobado"
                })

                finalCampaignData.sort((a, b) => {
                    return a.data.priority - b.data.priority;
                })

                let processedData = processData(finalCampaignData);
                function processData(campaignsData) {
                    const processedData = [];
                    const processedNumbers = {};
                    console.log(campaignsData)

                    campaignsData = campaignsData.map((campaign, index) => {
                        campaign.data.uploadedData.forEach(row => {
                            const { telefono } = row;
                            const processedNumber = processedNumbers[telefono];

                            if (processedNumber) {
                                processedNumber.campaignsInvalid.push(campaign.campaignName);
                                campaign.data.generatedFail.push(row);
                            } else {
                                processedNumbers[telefono] = {
                                    number: telefono,
                                    campaignValid: [campaign.campaignName],
                                    campaignsInvalid: []
                                };
                                campaign.data.generatedSuccess.push(row);
                            }
                        })
                        return campaign;
                    });
                    return campaignsData;
                };

                processedData = processedData.map(campaign => {
                    let campaignIndex = updatedCampaigns.findIndex(camp => camp._id.$oid == campaign.id)
                    updatedCampaigns[campaignIndex].datesInformation[selectedDate] = campaign.data
                })
                saveAdminEditWhatsAppConnectCampaign(updatedCampaigns, adminSaveCallback)
            });
            // TODO: Generate files
        });
    }

    const generateCsvOutput = (fileContent, fileHeaders, shouldSplit, date, campaignDetails) => {
        // Create headers, including the new 'Link' column
        const headers = [...fileHeaders.map(header => header.parameterNumber), "Phone"];
        headers.push('header')
        // Create a list of data column names, including 'telefono' for phone numbers
        const dataColName = [...fileHeaders.map(header => header.parameterName), "telefono"];
        let sampleRow = []
        // Add sample phone number and 'Link' to the sample row
        if(campaignDetails['URL IMAGEN']!==null){
            sampleRow = [...dataColName, "header"].map(col => {
                if (col === "header") {
                    return campaignDetails['URL IMAGEN'];
                }
                return fileContent[0][col] || "+573136521518";
            });
        }else{
            sampleRow = dataColName.map(col => {
                return (fileContent[0][col])
            })
            sampleRow.push("+573136521518")
        }
        
        // Helper function to format phone numbers
        const formatPhoneNumber = (phoneNumber, regex, prefix) => {
            const match = phoneNumber.match(regex);
            return match ? prefix + match[1] : null;
        };
    
        // Regex patterns for phone number validation
        const regexPatterns = {
            '+57': /^(?:\+57|57)?(\d{10})$/,
            '+52': /^(?:\+52|52)?(\d{10})$/,
            '+593': /^(?:\+593|593)?(\d{9})$/,
            '+598': /^(?:\+598|598)?(\d{9})$/
        };
        
        // Process each row of the file content
        const content = fileContent.map(row => {
            // Process each column in dataColName
            const result = dataColName.map(col => {
                if (col === "telefono") {
                    const phoneNumber = row[col] || '';
                    if (phoneNumber.startsWith('+57')) {
                        return formatPhoneNumber(phoneNumber, regexPatterns['+57'], '+57');
                    } else if (phoneNumber.startsWith('+52')) {
                        return formatPhoneNumber(phoneNumber, regexPatterns['+52'], '+52');
                    } else if (phoneNumber.startsWith('+593')) {
                        return formatPhoneNumber(phoneNumber, regexPatterns['+593'], '+593');
                    } else if (phoneNumber.startsWith('+598')) {
                        return formatPhoneNumber(phoneNumber, regexPatterns['+598'], '+598');
                    } else {
                        return null;
                    }
                } else {
                    return row[col];
                }
            });    
            // Add 'URL IMAGEN' to every row
            if(campaignDetails['URL IMAGEN']!==null){
                result.push(campaignDetails['URL IMAGEN']);
            }
            // Exclude rows where the phone number is null
            return result[result.length - 2] === null ? undefined : result.join(",");
        }).filter(row => row !== undefined); // Remove undefined rows
        // Split content if needed
        const splitContent = shouldSplit ? splitArray(content, 997) : [content];
    
        // Create and download CSV files
        splitContent.forEach((part, index) => {
            const csvContent = [
                headers.join(","),
                ...part,
                sampleRow.join(",")
            ].join("\r\n");
    
            const blob = new Blob([csvContent], { type: "text/csv" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
    
            a.style.display = "none";
            a.href = url;
            a.download = `${date}_${campaignDetails["Solicitado por:"]}_${campaignDetails["Template Name"]}${!shouldSplit ? "_Duplicados" : ""}.csv`;
    
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        });
    
        // Function to split array into chunks
        function splitArray(array, chunkSize) {
            const result = [];
            for (let i = 0; i < array.length; i += chunkSize) {
                result.push(array.slice(i, i + chunkSize));
            }
            return result;
        }
    };
    

    useEffect(() => {
        getWhatsAppConnectCampaigns(callback);
    }, []);

    return (
        adminState.isLoading ? <>Cargando</> : <>
            <Card>
                <CardHeader>
                    <CardTitle style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}>
                        <div>
                            Admin Panel -{selectedDate}-
                            <select value={adminState.isAdmin} onChange={(ev) => {
                                setAdminState({
                                    ...adminState,
                                    isAdmin: ev.target.value == "false" ? false : true
                                })
                            }}>
                                <option value={true}>Admin</option>
                                <option value={false}>Mortal</option>
                            </select>
                        </div>
                        <div>
                            <input type='date' value={selectedDate} onChange={(ev) => {
                                setSelectedDate(ev.target.value)
                            }} />
                            {
                                adminState.isAdmin ? <>
                                    <button onClick={() => {
                                        saveAdminCampaignsData()
                                    }}>
                                        Guardar
                                    </button>
                                    <button
                                        onClick={() => {
                                            adminProcessFiles()
                                        }}>
                                        Procesar Archivos
                                    </button>
                                </> : null
                            }
                        </div>
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    <GridLayout>
                        <GridLayoutItem>
                            <table className='whatsAppconnect admin'>
                                <thead>
                                    <tr>
                                        <th>Prioridad</th>
                                        <th>Area</th>
                                        <th>Responsable</th>
                                        <th>Creador</th>
                                        <th>Intevalo</th>
                                        <th>Plantilla</th>
                                        <th>Creacion</th>
                                        <th>Estado</th>
                                        <th colSpan={2}>Centro de Carga</th>
                                        <th colSpan={2}>Resultados</th>
                                        <th>Detalles</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {adminState.savedCampaigns.filter(campaign => {
                                        return campaign.datesInformation[selectedDate]
                                    }).sort((a, b) => {
                                        return a.datesInformation[selectedDate].priority - b.datesInformation[selectedDate].priority
                                    }).map((campaign, index, campaignsInDate) => {
                                        const campaignPriority = campaign.datesInformation[selectedDate].priority;
                                        const currentCampaign = campaign;
                                        const prevCampaign = (index == 0) ? null : campaignsInDate[index - 1];
                                        const nextCampaign = (index == campaignsInDate.length - 1) ? null : campaignsInDate[index + 1];
                                        const campaignIndexInCampaigns = adminState.savedCampaigns.findIndex(campaign => campaign._id.$oid == currentCampaign._id.$oid)
                                        return (
                                            <React.Fragment key={index}>
                                                <tr>
                                                    <td>{(campaignPriority == null) ? "-" : campaignPriority}
                                                        <span className="whatsAppconnect pointer" onClick={() => {
                                                            changePriority(currentCampaign, prevCampaign, nextCampaign, campaignPriority, -1)
                                                        }}>🔼</span>
                                                        <span className="whatsAppconnect pointer" onClick={() => {
                                                            changePriority(currentCampaign, prevCampaign, nextCampaign, campaignPriority, 1)
                                                        }}>🔽</span>
                                                    </td>
                                                    <td>{campaign.campaignDetails.area}</td>
                                                    <td>{campaign.campaignDetails.responsable}</td>
                                                    <td>{campaign.campaignMetaData.email}</td>
                                                    <td>
                                                        {campaign.campaignDetails.fecha_de_inicio}
                                                        <br />
                                                        {campaign.campaignDetails.fecha_de_fin}
                                                    </td>
                                                    <td>{campaign.messageSelected["Template Name"]}</td>
                                                    <td>{campaign.campaignMetaData.creationDate}</td>
                                                    <td>
                                                        {
                                                            adminState.isAdmin ? <>
                                                                <select
                                                                    value={campaign.datesInformation[selectedDate].status} onChange={(ev) => {
                                                                        const value = ev.target.value;
                                                                        const newCampaigns = [...adminState.savedCampaigns];
                                                                        newCampaigns[campaignIndexInCampaigns].datesInformation[selectedDate].status = value;
                                                                        setAdminState({
                                                                            ...adminState,
                                                                            savedCampaigns: newCampaigns,
                                                                        })
                                                                    }}>
                                                                    <option value="Pendiente">Pendiente</option>
                                                                    <option value="Rechazado">Rechazado</option>
                                                                    <option value="Aprobado">Aprobado</option>
                                                                </select>
                                                            </> : <>{campaign.datesInformation[selectedDate].status}</>
                                                        }
                                                    </td>
                                                    {
                                                        campaign.datesInformation[selectedDate].status == "Aprobado" ? <>
                                                            <td>
                                                                <button onClick={() => {
                                                                    generateTemplateFile(campaign);
                                                                }}>Plantilla</button>
                                                            </td>
                                                            <td>
                                                                <button onClick={() => {
                                                                    openUploader(campaign)
                                                                }}>Cargar Lista</button>
                                                            </td>
                                                            <td>
                                                                {
                                                                    campaign.datesInformation[selectedDate].generatedSuccess.length > 0 ? <>
                                                                        <button onClick={() => {
                                                                            generateCsvOutput(campaign.datesInformation[selectedDate].generatedSuccess, campaign.messageParametersConfig, true, selectedDate, campaign.messageSelected)
                                                                        }}>Correctos</button>
                                                                    </> : "No Disponible"
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    campaign.datesInformation[selectedDate].generatedFail.length > 0 ? <>
                                                                        <button onClick={() => {
                                                                            generateCsvOutput(campaign.datesInformation[selectedDate].generatedFail, campaign.messageParametersConfig, false, selectedDate, campaign.messageSelected)
                                                                        }}>Duplicados</button>
                                                                    </> : "No Disponible"
                                                                }
                                                            </td>
                                                        </> : null
                                                    }

                                                    {
                                                        campaign.datesInformation[selectedDate].status == "Rechazado" ? <>
                                                            <td colSpan={4}>
                                                                {campaign.datesInformation[selectedDate].status}
                                                            </td>
                                                        </> : null
                                                    }
                                                    {
                                                        campaign.datesInformation[selectedDate].status == "Pendiente" ? <>
                                                            <td>
                                                                <button onClick={() => {
                                                                    generateTemplateFile(campaign);
                                                                }}>Plantilla</button>
                                                            </td>
                                                            <td colSpan={3}>
                                                                {campaign.datesInformation[selectedDate].status}
                                                            </td>
                                                        </> : null
                                                    }
                                                    <td
                                                        className='whatsAppconnect-cursorPointer'
                                                        onClick={() => {
                                                            const newShowDetails = [...showDetails];
                                                            newShowDetails[index] = !newShowDetails[index];
                                                            setShowDetails(newShowDetails);
                                                        }}>
                                                        {showDetails[index] ? 'Ocultar' : 'Ver'} detalles
                                                    </td>
                                                </tr>
                                                {
                                                    showDetails[index] && (
                                                        <tr>
                                                            <td colSpan={13}>
                                                                <Details messageData={campaign} />
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </GridLayoutItem>
                    </GridLayout>
                </CardBody>
            </Card >

            {/* Upload Dialog */}
            {
                adminState.uploadPanelOpen ? <>
                    <Dialog
                        title={"WhatsApp Connect - Centro de Carga"}
                        width={"80%"}
                        onClose={() => {
                            setAdminState({
                                ...adminState,
                                uploadPanelOpen: false
                            })
                        }}>
                        <p
                            style={{
                                margin: "25px",
                                textAlign: "center",
                            }}
                        >
                            <UploadCenter fileStructures={adminState.selectedFileToUpload} />
                        </p>
                    </Dialog>
                </> : null
            }
        </>
    );
};

export { AdminPanel };
