import React from 'react'

export const CorrectMessage = ({data}) => {
    
    let objects = data?.objects;
    let status=data?.status||'Completed';
    let statusMessage={
        Completed:{ClassNameMessage:'success-message',ClassNameH2:'h-successRowRefresh',Message:'Tabla: ',MessageStatus:'Completed',ClassNameDiv:'success-details'},
        Failed:{ClassNameMessage:'message',ClassNameH2:'h-errorRowRefresh',Message:'Tabla: ',MessageStatus:'Failed',ClassNameDiv:'warning-details'},
        Cancelled:{ClassNameMessage:'warning-message',ClassNameH2:'h-warningRowRefresh',Message:'Tabla: ',MessageStatus:'Cancelled',ClassNameDiv:'warning-details'},
    }
    return (
      <div className={statusMessage[status]?.ClassNameDiv ?? "warning-details"}>
          {objects?.map((obj, index) => (
            <div key={index} className={statusMessage[obj.status].ClassNameMessage}>
              <h2 className={statusMessage[obj.status].ClassNameH2}>Tabla: {obj.table}</h2>
              <p className='p-successRowRefresh'>{obj.status}</p>
            </div>
          ))}
        </div>
      );
}

