import { Input } from '@progress/kendo-react-inputs';
import { useState } from 'react'
import {Error} from '@progress/kendo-react-labels';
import axios from 'axios';
import { Button } from '@progress/kendo-react-buttons';
import { Link } from 'react-router-dom';

export const InputSearchTicket = ({setDataF,filterData}) => {
    const [valorForSearchTicket, setValorForSearchTicket] = useState("")
    const emailRegex = new RegExp(/^[0-9]+$/);
	const emailValidator = (value) =>
		emailRegex.test(value) ? '' : 'Ingresa un Número de Ticket';

	const EmailInput = (fieldRenderProps) => {
		const {validationMessage, visited, ...others} = fieldRenderProps;
		return (
			<div>
				<Input
					{...others}
					style={{
						border: '2px solid #ccc',
						boxShadow: 'inset 0px 0px 0.5px 0px rgba(0,0,0,0.0.1)',
						width: '270px',
						height: '30px',
						marginRight: '10px',
					}}
				/>
				{visited && validationMessage && <Error>{validationMessage}</Error>}
			</div>
		);
	};

	const [infoTicket, setInfoTicket] = useState(null);
	const [errorBusqueda, seterrorBusqueda] = useState(null);

	const handleSubmit = async (dataItem = 256) => {
		// Marca la función como asíncrona

		seterrorBusqueda(null);
		if (dataItem !== infoTicket) {
			setInfoTicket(dataItem);
			try {
				const content = await searchTicket(dataItem); // Espera a que la promesa se resuelva

				if (content.data && content.data.length === 0) {
					seterrorBusqueda('No existe el ticket');
				}
				setDataF(content.data); // Asegúrate de usar la respuesta de la manera que necesitas
			} catch (error) {
				console.error('Error fetching ticket: ', error);
				seterrorBusqueda('Error fetching ticket: ', error);
				// Maneja el error como sea apropiado
			}
		}
	};
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
		  handleSubmit(valorForSearchTicket);
		}
	  };

	async function searchTicket(id) {
		let api_url = '/api/fullTicket';
		const data = {numTicket: parseInt(id)};
		let response = await axios.post(api_url, data, {
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
				'Access-Control-Allow-Origin': '*',
			},
		});
		return response;
	}
    function handleSearch(e) {

        setValorForSearchTicket(e.target.value)
        
        
    }

  return (
    <>
        <Input
								name={'numberTicket'}
								type={'number'}
                                value={valorForSearchTicket}
                                onChange={handleSearch}
								component={EmailInput}
								onKeyDown={handleKeyDown}
								placeholder="Search by ticket number..."
								validator={emailValidator}
								style={{
									border: '1px solid #ccc',
									boxShadow: 'inset 0px 0px 0.5px 0px rgba(0,0,0,0.0.1)',
									width: '200px',
									height: '30px',
									marginRight: '10px'
								}}
							/>
							<button 
								className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
								onClick={() => {
									handleSubmit(valorForSearchTicket);
								}}
								disabled={false}>
								Buscar
							</button>
                            <Link onClick={()=>{setValorForSearchTicket("");filterData({target: {value: ""}})}} style={{color:'red'}}>Borrar</Link>
    </>
  )
}
