import {GridTemplate} from './GridTemplate';
import {useNavigate, useParams} from 'react-router-dom';
import useAxios from '../../reports/hooks/useAxios';
import ErrorMessage from '../utils/ErrorMessage';
import CustomSpinner from '../utils/CustomSpinner';
import {TabStrip, TabStripTab} from '@progress/kendo-react-layout';
import {useState} from 'react';
import { useSelector } from 'react-redux';
import { selectCredencialesUsr } from '../../redux/credencialesReducer';

export const ExampleUse = () => {
    const emailOrigen = useSelector(selectCredencialesUsr);
	const {type} = useParams();
	const navigate = useNavigate();
	const [selected, setSelected] = useState(0);

	let dic = {
		0: 'Seguimientos Landing',
		1: 'Campañas Alianzas',
		2: 'Clientes',
	};
	if (type === 'Contacto Verificado') {
		dic = {
			0: 'Contacto Verificado',
		};
	}
	const handleSelect = (e) => {
		setSelected(e.selected);
		navigate('/Template/Grid/' + dic[e.selected]);
	};

	const {
		data: params,
		isLoading,
		error,
	} = useAxios(`/api/gridSchema?type=${type}`);
	if (isLoading) {
		return <CustomSpinner />;
	}

	if (error) {
		return <ErrorMessage message={error.message} />;
	}
    // const handleEmailChange = (e) => {//Prueba
    //     setNewEmail(e.target.value);//Prueba
    // };//Prueba

    // const handleEmailUpdate = () => {//Prueba
    //     setEmail(newEmail);//Prueba
    // };//Prueba
	return (
		<>
			<h1>{type}</h1>    
            {/* <input 
                type="email" 
                value={newEmail} 
                onChange={handleEmailChange} 
                placeholder="Enter new email"
            />
            <button onClick={handleEmailUpdate}>Update Email</button>
             */}
			<TabStrip
				selected={selected}
				onSelect={(e) => {
					handleSelect(e);
				}}>
				{Object.entries(dic).map(([key, value]) => {
					return (
						<TabStripTab title={value} key={key}>
							<GridTemplate
								columns={params?.columns || []}
								belonging={type}
								params={params}
								IsThereSearchBar={(params?.searchFields?.length || 0) > 0}
                                email={emailOrigen}
							/>
						</TabStripTab>
					);
				})}
			</TabStrip>
		</>
	);
};
