import React from 'react'
import { TranferencesHelperDashboardMX } from '../../components/Dashboards/Customer/TranferencesHelper/TranferencesHelperMX'


export const TranferencesHelperMX = () => {
    return (
        <>
            <TranferencesHelperDashboardMX />
        </>
    )
}
