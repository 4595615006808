import React from 'react'
import * as FaIcons from 'react-icons/fa'


export const SLAgreements=()=> {

  return (
    <div>
      <h3 className='page-subheader'>SLA por Área</h3>
      <section className='status-cards-container'>
        <div className="col-6-SDR">
            <div className='status-card'>
              <div className="status-card__icon">
                  <FaIcons.FaRegFilePdf/>
              </div>
              <div className="status-card__info">
                <a href='https://adlssynapsesiigodwh.blob.core.windows.net/datasmartdocs/SLAs/SLA%20-%20Data%20Analytics.pdf?sp=r&st=2022-10-26T17:36:18Z&se=2024-10-26T01:36:18Z&spr=https&sv=2021-06-08&sr=b&sig=BBEenA5uXw0FhVIL2mZKHRQBLMF9tFTzJ1gM91EbMtk%3D'>SLA - Data Analytics</a>
              </div>
            </div>
        </div>
        <div className='col-6-SDR'>
              <div className='status-card'>
                <div className="status-card__icon">
                    <FaIcons.FaRegFilePdf/>
                </div>
                <div className="status-card__info">
                  <a href='https://adlssynapsesiigodwh.blob.core.windows.net/datasmartdocs/SLAs/SLA%20-%20Data%20Science.pdf?sp=r&st=2022-10-26T17:46:11Z&se=2024-10-26T01:46:11Z&spr=https&sv=2021-06-08&sr=b&sig=qIko%2FiNkDdrHRTqpRYWqCZde12X4idlhasMKrf98%2F8k%3D'>SLA - Data Science</a>
                </div>
              </div>
        </div>
        <div className='col-6-SDR'>
              <div className='status-card'>
                <div className="status-card__icon">
                    <FaIcons.FaRegFilePdf/>
                </div>
                <div className="status-card__info">
                  <a href='https://adlssynapsesiigodwh.blob.core.windows.net/datasmartdocs/SLAs/SLAs%20DA%20-%20Strategy.pdf?sp=r&st=2022-11-09T14:24:25Z&se=2024-10-25T22:24:25Z&spr=https&sv=2021-06-08&sr=b&sig=9WmdeWFY0vnZuKnkt8HOmBk6Wf%2BLdSoMZ3fNp53eQM8%3D'>SLA - Ecosistemas</a>
                </div>
              </div>
        </div>
      </section>
    </div>
  )
}