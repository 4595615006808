import {useEffect, useState} from 'react';
import {getDaigramStorage} from '../utils/localStorageDiagram';
import {useNavigate, useParams} from 'react-router-dom';
import './lobbyReports.css';

export const LobbyReports = () => {
	const [rute, setRute] = useState(null);
	const diagram = getDaigramStorage();
	const actualRute = useParams().actualRute;
	const [titulo, setTitulo] = useState('Reportes');
	const [Isloading, setIsloading] = useState(false);
	const [data, setData] = useState(null);


	const navigate = useNavigate();

	useEffect(() => {
		setRute(decodeURIComponent(actualRute).split('/'));
		let title = actualRute.split('/');
		setTitulo(actualRute ? title[title.length - 1] : 'Reportes');
	}, []);

	useEffect(() => {
		const chargeData = async () => {
			if (!diagram) {
				setIsloading(true);
			} else {
				let datish = await setPestañas(diagram, rute);
				setData(datish);
			}
			setIsloading(false);
		};
		chargeData();
	}, [rute]);

	const setPestañas = async (diagram, rute) => {
		if (rute !== null) {
			let nestedObject = rute?.reduce(
				(obj, key) => (obj && obj[key] ? obj[key] : null),
				diagram
			);
			//nestedObject es el arbol desde la ruta que se le pide buscar
			//puede salir nulo si la ruta llega vacia
			return Object.keys(nestedObject);
		}
		return [];
	};
	function NavigateToDashbPage(nameReport) {
		let encodeRute = encodeURIComponent(actualRute);
		let encodeNameReport = encodeURIComponent(nameReport);
		navigate(`/dashboardpage/${encodeRute}?report=${encodeNameReport}`);
	}

	return (
		<div>
			<div className="lista-container-lobbyReports">
				<h1 style={{'marginLeft':'35px','marginTop':'20px','fontWeight':'bold'}}>Reports Hub</h1>
				<h4 style={{'marginLeft':'35px'}}>Selecciona el reporte al cual deseas ingresar</h4>

				{data?.map((report, index) => (
					<div
						key={index}
						className="lista-item-lobbyReports"
						onClick={() => NavigateToDashbPage(report)}>
						{/* <img src={report} alt={report} className="item-imagen-lobbyReports" /> */}
						<div className="item-texto-lobbyReports">
							<h3>{report}</h3>
							{/* <p>
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
								enim ad minim veniam, quis nostrud exercitation ullamco laboris
								nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
								in reprehenderit in voluptate velit esse cillum dolore eu fugiat
								nulla pariatur. Excepteur sint occaecat cupidatat non proident,
								sunt in culpa qui officia deserunt mollit anim id est laborum."{' '}
							</p> */}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
