import {
	Grid,
	GridColumn as Column,
	GridToolbar,
} from '@progress/kendo-react-grid';
import * as ReactDOM from 'react-dom';
import {useEffect, useRef, useState} from 'react';
import useAxiosTicket from '../../hooks/UseAxiosTickets';
import {orderBy} from '@progress/kendo-data-query';
import {Button} from '@progress/kendo-react-buttons';
import {useNavigate} from 'react-router-dom';
import { Input } from '@progress/kendo-react-inputs';
import { InputSearchTicket } from './InputSearchTicket';
import { useSelector } from 'react-redux';
import { selectCredencialesArea, selectCredencialesRole } from '../../../redux/credencialesReducer';
import { IsAuthorized } from '../../../components/auth/IsAuthorized';

const loadingPanelMarkup = (
	<div className="k-loading-mask">
		<span className="k-loading-text">Loading</span>
		<div className="k-loading-image" />
		<div className="k-loading-color" />
	</div>
);
const LoadingPanel = (props) => {
	const {gridRef} = props;
	const gridContent =
		gridRef.current && gridRef.current.querySelector('.k-grid-content');
	return gridContent
		? ReactDOM.createPortal(loadingPanelMarkup, gridContent)
		: loadingPanelMarkup;
};
const initialSort = [
	{
		field: 'ticketId',
		dir: 'desc',
	},
];
const initialDataState = {
	skip: 0,
	take: 10,
};

export const GridTickets = ({toSend}) => {
	const [sort, setSort] = useState(initialSort);
	const navigate = useNavigate();
	const [miValor, setMiValor] = useState(sessionStorage.getItem('name'));
	const area = useSelector(selectCredencialesArea);
    const rol=useSelector(selectCredencialesRole);
	const isAllowed={
		"Data Partner": IsAuthorized('HelpDesk-dataPartner',area,rol ),
		"Resolver": IsAuthorized('HelpDesk-resolver',area,rol ),
	}
	const [dataF, setDataF] = useState([]);
	const gridRef = useRef(null);
	const {
		data: tickets,
		isLoading,
		error,
	} = useAxiosTicket('/api/lisTicketsAdmin', toSend);
	useEffect(() => {
		filterData({target: {value: miValor}});
	}, [tickets]);

	const filterData = (e) => {
		let value =''
		try {
			 value = e.target.value.toLowerCase();
			 setMiValor(e.target.value);
		} catch (error) {
			console.error("Llega sin nombre del Login")
			setMiValor('');
		}

		const filteredData = tickets.filter((ticket) => {
				const assignedToMatch =
					ticket.assignedTo &&
					ticket.assignedTo.name &&
					ticket.assignedTo.name.toLowerCase().includes(value);

			const activityMatch = Array.isArray(ticket.Activity)
				? ticket.Activity.some((activity) =>
						activity.assignedTo.toLowerCase().includes(value)
				  )
				: false;

			return activityMatch||assignedToMatch;
		});

		setDataF(filteredData);
	};

	const [page, setPage] = useState(initialDataState);
	const [pageSizeValue, setPageSizeValue] = useState();
	const pageChange = (event) => {
		const targetEvent = event.targetEvent;
		const take = targetEvent.value === 'All' ? dataF.length : event.page.take;
		if (targetEvent.value) {
			setPageSizeValue(targetEvent.value);
		}
		setPage({
			...event.page,
			take,
		});
	};

	const RequestDateCell = (props) => {
		const requestDateValue = props.dataItem.requestDate?.$date;

		if (!requestDateValue) {
			return <td></td>;
		}

		const dateParts = new Date(requestDateValue)
			.toISOString()
			.split('T')[0]
			.split('-');
		const year = parseInt(dateParts[0], 10);
		const month = parseInt(dateParts[1], 10);
		const day = parseInt(dateParts[2], 10);

		const formattedDate = `${year}/${month}/${day}`;

		return <td>{formattedDate}</td>;
	};
	const calculateRequestorValue = (props) => {
		const RequestorName = props.dataItem.requestor
			? props.dataItem.requestor.name
			: '';
		return <td>{RequestorName}</td>;
	};
	const calculateAssignedToValue = (props) => {
		const AssignedToName = props.dataItem.assignedTo
			? props.dataItem.assignedTo.name
			: '';
		return <td>{AssignedToName}</td>;
	};
	const calculateActivityAssignedTo = (props) => {
		return Array.isArray(props.dataItem.Activity)
			? props.dataItem.Activity.map((activity) => activity.assignedTo).join(
					', '
			  )
			: '';
	};
	const EditHandleAction = (props) => {
		return (
			<td>
				<Button
					onClick={() => {
						navigationRoute(props.dataItem.ticketId);
					}}>
					Editar
				</Button>
			</td>
		);
	};

	const navigationRoute = (id, type) => {
		if (type) {
			navigate(`DescriptionTicket/${id}`);
		} else {
			navigate(`EditTicket/${id}`);
		}
		
	};

	const onSelectionChange = (event) => {
		if (isAllowed["Data Partner"]) {
			navigationRoute(event.dataItem.ticketId);
		} else {
			navigationRoute(event.dataItem.ticketId, 'description');
		}
	};

	useEffect(() => {
		return () => {
			setDataF([]);
		};
	}, []);

	return (
		<div ref={gridRef}>
			{isLoading ? <LoadingPanel gridRef={gridRef} /> : null}
			<Grid
				style={{
					height: '100%',
				}}
				sortable={true}
				pageable={{
					buttonCount: 4,
					pageSizes: [5, 10, 15, 'All'],
					pageSizeValue: pageSizeValue,
				}}
				onPageChange={pageChange}
				data={orderBy(dataF, sort).slice(page.skip, page.take + page.skip)}
				skip={page.skip}
				take={page.take}
				total={dataF.length}
				sort={sort}
				selectable={{enable: true, mode: 'single', cell: true, drag: false}}
				onRowClick={onSelectionChange}
				onSortChange={(e) => {
					setSort(e.sort);
				}}>
				<GridToolbar>
							<p style={{paddingTop:'15px'}}>Filtro: </p>
							<Input
								onChange={filterData}
								value={miValor}
								width={'250px'}
								placeholder="Search in all columns..."
								style={{
									border: '1px solid #ccc',
									boxShadow: 'inset 0px 0px 0.5px 0px rgba(0,0,0,0.0.1)',
									width: '270px',
									height: '30px',
									marginRight: '130px',
								}}
							/>
							<p style={{paddingTop:'15px'}}>  Busca un Ticket:</p>
							<InputSearchTicket setDataF={setDataF} filterData={filterData}/>
						
				</GridToolbar>
				{isAllowed['Data Partner'] ? (
					<Column
						name="Editar"
						title="Editar"
						cell={EditHandleAction}
						width={'80px'}
					/>
				) : null}
				<Column
					field="ticketId"
					title="# Solicitud"
					filter={'numeric'}
					width={'130px'}
				/>
				<Column field="priority" title="Prioridad" width={'80px'} />
				<Column
					field="requestDate"
					title="Fecha Registro"
					filter={'date'}
					width={'140px'}
					cell={RequestDateCell}
				/>
				<Column
					field="requestor"
					title="Solicitante"
					filter={'text'}
					cell={calculateRequestorValue}
				/>
				<Column field="title" title="Título" filter={'text'} />
				<Column
					field="assignedTo"
					title="Asignado A"
					filter={'text'}
					cell={calculateAssignedToValue}
				/>
				<Column
					field="Activity"
					title="Resolutor"
					filter={'text'}
					cell={calculateActivityAssignedTo}
				/>
			</Grid>
		</div>
	);
};
