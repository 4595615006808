import { Loader } from '@progress/kendo-react-indicators';
import React from 'react'

export const CustomSpinner = () => {
  return (
    <div>
        <Loader
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
            }}
            size="large"
            type={'converging-spinner'}
        />
        <span>
            <p>Cargando Información...</p>
        </span>
    </div>
  )
}
export default CustomSpinner;
