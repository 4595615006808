
import React from 'react';
import * as PropTypes from 'prop-types';

import { Grid as KendoGrid, GridColumn, GridColumnMenuSort, GridColumnMenuFilter, GridToolbar } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@mui/material';

export const Column = GridColumn;

export const ColumnMenu = (props) => {
    return (
        <div>
            <GridColumnMenuSort {...props} />
            <GridColumnMenuFilter {...props} />
        </div>
    );
}

export const Grid = (props) => {
    const { data, onDataChange, ...others } = props;

    const [take, setTake] = React.useState(10);
    const [skip, setSkip] = React.useState(0);
    const [sort, setSort] = React.useState([{
        field: 'TIMESTAMP',
        dir: 'asc'
    }]);
    const [group, setGroup] = React.useState([]);
    const [filter, setFilter] = React.useState(null);
    const [allColumnFilter, setAllColumnFilter] = React.useState('');

    const dataState = {
        take,
        skip,
        sort,
        group,
        filter
    };

    const onDataStateChange = React.useCallback(
        (event) => {
            setTake(event.dataState.take);
            setSkip(event.dataState.skip);
            setSort(event.dataState.sort);
            setGroup(event.dataState.group);
            setFilter(event.dataState.filter);
        },
        [setTake, setSkip, setSort, setGroup]
    );

    const onAllColumnFilterChange = React.useCallback(
        (event) => {
            setAllColumnFilter(event.value);
        },
        [setAllColumnFilter]
    );

    const onHeaderSelectionChange = React.useCallback(
        (event) => {
            const checked = event.syntheticEvent.target.checked;
            const updatedData = data.map(item => {
                return {
                    ...item,
                    selected: checked
                };
            });

            onDataChange(updatedData);
        },
        [data, onDataChange]
    );

    const textColumns = props.children.map(col => {
        if (col.props.children) {
            return col.props.children.map(child => {
                if (!child.props.filter || child.props.filter === "text") {
                    return child.props.field;
                }
                return null;
            });
        } else if (col.props.field) {
            if (!col.props.filter || col.props.filter === "text") {
                return col.props.field;
            }
        }
        return null;
    })
        .flat()
        .filter(field => field);

    const allColumnsFilters = textColumns.map(column => ({
        field: column,
        operator: 'contains',
        value: allColumnFilter
    }));

    const allColumnFilteredData = allColumnFilter ?
        process(data, { filter: { logic: "or", filters: allColumnsFilters } }).data :
        data;

    const processedData = process(allColumnFilteredData, dataState);

    const [selectedRow, setSelectedRow] = React.useState(null);

    const handleRowEnter = (event, dataItem) => {
        setSelectedRow(dataItem);
    };

    const handleRowLeave = () => {
        setSelectedRow(null);
    };


    const rowRender = (trElement, props) => {
        const rowProps = {
            ...props,
            onMouseEnter: (event) => handleRowEnter(event, props.dataItem),
            onMouseLeave: handleRowLeave,
            style: {
                backgroundColor: selectedRow === props.dataItem ? '#e6f2ff' : '',
                color: selectedRow === props.dataItem ? '#000' : '',
            },
        };
        return React.cloneElement(trElement, rowProps, trElement.props.children);
    };

    React.useEffect(
        () => {
            if (!processedData.data.length) {
                setSkip(0);
            }
        },
        [processedData]
    );

    const GridElement = (
        <KendoGrid
            {...dataState}
            {...others}
            pageable
            sortable
            data={processedData}
            onDataStateChange={onDataStateChange}
            onHeaderSelectionChange={onHeaderSelectionChange}

            rowRender={rowRender}
        >
            <GridToolbar>
                <Input
                    value={allColumnFilter}
                    onChange={onAllColumnFilterChange}
                    placeholder="Buscar en todas las columnas"
                />
                <Button variant="outlined" color="warning" onClick={() => { setAllColumnFilter("") }}>Limpiar</Button>
                <Button variant="outlined" color="primary" onClick={() => { setAllColumnFilter("Break") }}>Break</Button>
                <Button variant="outlined" color="primary" onClick={() => { setAllColumnFilter("Almuerzo") }}>Almuerzo</Button>
                <Button variant="outlined" color="primary" onClick={() => { setAllColumnFilter("On Call") }}>En Llamada</Button>
                <Button variant="outlined" color="primary" onClick={() => { setAllColumnFilter("After Call Work") }}>ACW</Button>
            </GridToolbar>
            {props.children}
        </KendoGrid>
    );

    return (
        <>
            {GridElement}
        </>
    );
};

Grid.displayName = 'Grid';
Grid.propTypes = {
    data: PropTypes.array,
    onDataChange: PropTypes.func,
    style: PropTypes.object
};