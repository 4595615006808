import { Label } from '@mui/icons-material';
import { Container, TextField, Box } from '@mui/material';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';
 
import { formatMessage, loadMessages, locale } from 'devextreme/localization';

import DataGrid, {
    Column,
    Editing,
    Popup,
    Paging,
    Lookup,
    Form,
} from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { Item } from 'devextreme-react/form';

const notesEditorOptions = { height: 100 };

const IActivity = {
    idActivity: "",
    DescriptionActivity: "",
    objectives: "",
    assignedTo: "",        
    statusTask: "",        
    dateStart: "",
    dateEnd: "",                        
}

const ActivityCardRequest = ({handlerActivity, Activitys}) => {
    // JS
    locale(navigator.language);
    const arrActivity = [];
    const [currentActivity, setCurrentActivity] = useState(IActivity);

    const status = [{
        ID: 'Asignado',
        Name: 'Asignado'
    }, {
        ID: 'Proceso',
        Name: 'Proceso'
    },{
        ID: 'Revisión',
        Name: 'Revisión'
    },{
        ID: 'Cerrado',
        Name: 'Cerrado'
    }];    

    const onRowInserted = React.useCallback((e) => {
        e.cancel = true;

        if(e.component.totalCount() === 10){         
            e.element.childNodes[0].childNodes[3].childNodes[0].childNodes[0].childNodes[2].childNodes[0].hidden = true;
        }        
    }, []);

    
    const onRowRemoved = React.useCallback((e) => { 
        
        if(e.component.totalCount() < 10){
            e.element.childNodes[0].childNodes[3].childNodes[0].childNodes[0].childNodes[2].childNodes[0].hidden = false;            
        }


        if(e.component.totalCount() === 0){
            console.log('todos removidos');            
        }

    }, []);

    const onSaving = React.useCallback( (e) => {                
        //console.log(e.component.getVisibleRows());
        //let newActivity = e.changes[0].data;
        //console.log(e.changes[0].key);
        //newActivity['idActivity'] = newActivity['__KEY__'];
        //delete newActivity['__KEY__'];
        //setCurrentActivity(newActivity);
        //console.log(currentActivity);
        //newActivity['dateStart'] = dayjs(newActivity['dateStart']).format('DD/MM/YYYY');
        //newActivity['dateEnd'] = dayjs(newActivity['dateEnd']).format('DD/MM/YYYY');
        //newActivity.idActivity = 
        //arrActivity.push(newActivity);
        //setCurrentActivity(arrActivity);
        //handlerActivity(newActivity);
       // Activitys = Activity;
        //sessionStorage.setItem('newActivity', JSON.stringify(arrActivity));
    }, []);

    const onSaved = React.useCallback( (e) => {     
        //console.log('saved',e.component.getVisibleRows()); 

        let arreglo = [];
        let newActivity = {};
        e.component.getVisibleRows().forEach(element => {
            newActivity = {};
            newActivity = element.data;
            newActivity['idActivity'] = newActivity['__KEY__'];
            //delete newActivity['__KEY__'];
    
            //newActivity['dateStart'] = dayjs(newActivity['dateStart']).format('DD/MM/YYYY');
            //newActivity['dateEnd'] = dayjs(newActivity['dateEnd']).format('DD/MM/YYYY');

            arreglo.push(newActivity);
            
        });

        console.log('saved', arreglo);



    }, []);
        

    // HTML
    return (
        <Container>
            
                <DataGrid
                    dataSource={ Activitys ? Activitys : currentActivity}
                    //keyExpr="idActivity"
                    key="idActivity"
                    showBorders={true}
                    noDataText = "No hay actividades registradas"
                >
                    <Paging enabled={false} />
                    <Editing
                        mode="popup"                                
                        allowUpdating={true}
                        allowAdding={false}                        
                        allowDeleting={false}>
                        <Popup title="Información de la actividad" showTitle={true} width={700} height={450} />
                        <Form>
                            <Item itemType="group" colCount={2} colSpan={2}>
                                <Item dataField="idActivity" disabled={true} visible={false}/>
                                {/* <Item dataField="assignedTo" disabled={true} colSpan={2} /> */}
                                <Item dataField="objectives"  disabled={true}/>
                                <Item dataField="statusTask" disabled={true}/>
                                {/* <Item dataField="dateStart" disabled={true}/>
                                <Item dataField="dateEnd" disabled={true}/>                                     */}
                                <Item
                                    disabled={true}
                                    dataField="DescriptionActivity"
                                    editorType="dxTextArea"
                                    colSpan={2}
                                    editorOptions={notesEditorOptions} />
                            </Item>                            
                        </Form>
                    </Editing>                     
                    <Column dataField="idActivity" caption="Id Actividad" visible={false}/>
                    <Column dataField="objectives" caption="Objetivos"/>                    
                    <Column dataField="statusTask" caption="Estado" width={125}>
                        <Lookup dataSource={status} valueExpr="ID" displayExpr="Name" />
                    </Column>
                    <Column dataField="dateStart" dataType="date" caption="Fecha inicio" />                    
                    <Column dataField="dateEnd" dataType="date" caption="Fecha fin"/>                    
                    <Column dataField="DescriptionActivity" caption="Descripción" />
                </DataGrid>            
        </Container>
    )
}

export default ActivityCardRequest;