import React from 'react';
import Organigram from './Organigram';
import './OrgTree.css';
import { ShortCutLinks } from '../ShortCutLinks';

export const OrgTree = () => {
	let organigrama = JSON.parse(sessionStorage.getItem('customReports'));
	let subordinates=organigrama?.subordinates || []

	return (
		<>
			{subordinates.lenght > 0 ? (
				<Organigram subordinates={subordinates} />
			) : (
				<ShortCutLinks/>
			)}
		</>
	);
};
