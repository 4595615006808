import {DropDownList} from '@progress/kendo-react-dropdowns';
import axios from 'axios';
import {useEffect, useState} from 'react';
import {Label, Hint, Error} from '@progress/kendo-react-labels';
import {Input} from '@progress/kendo-react-inputs';
export const insertItem = async (item) => {
	try {
		const response = await axios.post('/api/gridTemplates', item);
		return {
			data: response.data,
			status: response.status,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			status: null,
			error: error.message,
		};
	}
};

export const getItems = async (email, belonging) => {
	const response = await axios.get('/api/gridTemplates', {
		params: {email, belonging},
	});
	const convertedData = convertToISODate(response.data);
	return {
		data: convertedData,
		status: response.status,
		error: null,
	};
};

export const updateItem = async (item) => {
	try {
		const response = await axios.put(`/api/gridTemplates/${item.KEY}`, item);
		return {
			data: response.data,
			status: response.status,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			status: null,
			error: error.message,
		};
	}
};

export const deleteItem = async (item) => {
	try {
		const response = await axios.delete(`/api/gridTemplates/${item.KEY}`);
		return {
			data: response.data,
			status: response.status,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			status: null,
			error: error.message,
		};
	}
};

export function convertToISODate(dataArray) {
	const datePattern =
		/^[A-Za-z]{3}, \d{2} [A-Za-z]{3} \d{4} \d{2}:\d{2}:\d{2} GMT$/;
	dataArray.forEach((data) => {
		for (const key in data) {
			if (typeof data[key] === 'string' && datePattern.test(data[key])) {
				data[key] = new Date(data[key]);
			} else if (typeof data[key] === 'object' && data[key] !== null) {
				convertToISODate([data[key]]);
			}
		}
	});
	return dataArray;
}

export const MonthsDrowpdown = (props) => {
	const [months, setMonths] = useState([
		{month: 'Enero', id: 1},
		{month: 'Febrero', id: 2},
		{month: 'Marzo', id: 3},
		{month: 'Abril', id: 4},
		{month: 'Mayo', id: 5},
		{month: 'Junio', id: 6},
		{month: 'Julio', id: 7},
		{month: 'Agosto', id: 8},
		{month: 'Septiembre', id: 9},
		{month: 'Octubre', id: 10},
		{month: 'Noviembre', id: 11},
		{month: 'Diciembre', id: 12},
	]);
	const [isloading, setLoading] = useState(true);

	const handleChange = (e) => {
		if (props.onChange) {
			props.onChange({
				dataIndex: 0,
				dataItem: props.dataItem,
				field: props.field,
				syntheticEvent: e.syntheticEvent,
				value: e.target.value.month,
			});
		}
	};
	const {dataItem} = props;
	const field = props.field || '';
	const dataValue = dataItem[field] === null ? '' : dataItem[field];

	return (
		<td>
			{dataItem.inEdit ? (
				<DropDownList
					style={{
						width: '100%',
					}}
					onChange={handleChange}
					value={months.find((c) => c.month === dataValue)}
					data={months}
					textField="month"
				/>
			) : (
				dataValue.toString()
			)}
		</td>
	);
};
export const TiposdeRegistrosDrowpdown = (props) => {
	const [tiposRegistros, setTiposRegistros] = useState([
		{type: 'NIT', id: 1},
		{type: 'Cedula', id: 2},
	]);

	const handleChange = (e) => {
		if (props.onChange) {
			props.onChange({
				dataIndex: 0,
				dataItem: props.dataItem,
				field: props.field,
				syntheticEvent: e.syntheticEvent,
				value: e.target.value.type,
			});
		}
	};
	const {dataItem} = props;
	const field = props.field || '';
	const dataValue = dataItem[field] === null ? '' : dataItem[field];

	return (
		<td>
			{dataItem.inEdit ? (
				<DropDownList
					style={{
						width: '100%',
					}}
					onChange={handleChange}
					value={tiposRegistros.find((c) => c.type === dataValue)}
					data={tiposRegistros}
					textField="type"
				/>
			) : (
				dataValue.toString()
			)}
		</td>
	);
};

export const ValidatedInputCell = (props) => {
	const {dataItem, field, onChange} = props;
	const [validationMessage, setValidationMessage] = useState('');

	const validate = (value) => {
		if (!value) {
			return 'Este campo es obligatorio';
		}
		if (!/^\d+$/.test(value)) {
			return 'El valor debe ser numérico';
		}
		if (value.length < 7) {
			return 'El valor debe tener al menos 7 caracteres';
		}
		if (value.length > 11) {
			return 'El valor no debe tener más de 11 caracteres';
		}
		return '';
	};

	const handleChange = (e) => {
		const newValue = e.target.value;
		const validationResult = validate(newValue);
		setValidationMessage(validationResult);

		if (onChange) {
			onChange({
				dataItem,
				field,
				syntheticEvent: e.syntheticEvent,
				value: newValue,
			});
		}
	};

	const dataValue = dataItem[field] === null ? '' : dataItem[field];

	return (
		<td>
			{dataItem.inEdit ? (
				<div>
					<Label>
						<Input
							maxLength={11}
							type="number"
							value={dataValue}
							onChange={handleChange}
							style={{width: '100%'}}
						/>
						{validationMessage && <Error>{validationMessage}</Error>}
					</Label>
				</div>
			) : (
				dataValue.toString()
			)}
		</td>
	);
};

export const PhoneNumberInput = ({dataItem, field, onChange}) => {
	const [validationMessage, setValidationMessage] = useState('');

	const validate = (value) => {
		// if (!value) {
		//   return "El número de teléfono es obligatorio";
		// }
		if (!/^\d+$/.test(value)) {
			return 'El número de teléfono debe ser numérico';
		}
		if (value.length < 9) {
			return 'El número de teléfono debe tener al menos 9 dígitos';
		}
		if (value.length > 11) {
			return 'El número de teléfono no debe tener más de 11 dígitos';
		}
		return '';
	};

	const handleChange = (e) => {
		const newValue = e.target.value;
		const validationResult = validate(newValue);
		setValidationMessage(validationResult);

		if (onChange) {
			onChange({
				dataItem,
				field,
				syntheticEvent: e.syntheticEvent,
				value: newValue,
			});
		}
	};
	const formatPhoneNumber = (value = '') => {
		if (value.length === 10) {
			return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`;
		} else if (value.length === 11) {
			return `${value.slice(0, 1)}-${value.slice(1, 4)}-${value.slice(
				4,
				7
			)}-${value.slice(7)}`;
		}
		return value;
	};

	const dataValue = dataItem[field] === null ? '' : dataItem[field];

	return (
		<td>
			{dataItem.inEdit ? (
				<div>
					<Label>
						<Input
							maxLength={11}
							type="tel"
							value={dataValue}
							onChange={handleChange}
							style={{width: '100%'}}
						/>
						{validationMessage && <Error>{validationMessage}</Error>}
					</Label>
				</div>
			) : (
				formatPhoneNumber(dataValue?.toString())
			)}
		</td>
	);
};
