import React from 'react'
import ProfileContent from '../components/ProfileContent'
import {Link} from 'react-router-dom'
import * as FaIcons from 'react-icons/fa'
import * as RiIcons from 'react-icons/ri'
import {CircleFlag} from 'react-circle-flags'



export const PerformanceGovernance=()=> {
  return (
    <div>

      <h2 className='page-header'>Performance Governance</h2>
      <h3 className='page-subheader'> Reportes Generales</h3>
      <section className='status-cards-container'>
      {/* <div className="col-6-SDR">
            <div className='status-card'>
              <div className="status-card__icon">
                  <FaIcons.FaChartBar/>
              </div>
              <div className="status-card__info">
                <Link to='/PerformanceGovernance/BigNumbers'>Siigo Big Numbers</Link>
              </div>
            </div>
        </div> 
        <div className="col-6-SDR">
            <div className='status-card'>
              <div className="status-card__icon">
                  <FaIcons.FaBusinessTime/>
              </div>
              <div className="status-card__info">
                <Link to='/PerformanceGovernance/BAU'>Core</Link>
              </div>
            </div>
        </div>
        <div className="col-6-SDR">
            <div className='status-card'>
              <div className="status-card__icon">
                  <FaIcons.FaRoute/>
              </div>
              <div className="status-card__info">
                <Link to='/PerformanceGovernance/GamePlan'>Game Plan</Link>
              </div>
            </div>
        </div>*/}
        <div className="col-6-SDR">
            <div className='status-card'>
              <div className="status-card__icon">
                  <RiIcons.RiTeamFill/>
              </div>
              <div className="status-card__info">
                <Link to='/PerformanceGovernance/Board'>Board Meeting</Link>
              </div>
            </div>
        </div>
        </section>
      {/* <h3 className='page-subheader'> Reportes por Área</h3>
      <section className='status-cards-container'>
        <div className="col-6-SDR">
              <div className='status-card'>
                <div className="status-card__icon">
                    <FaIcons.FaHeadset/>
                </div>
                <div className="status-card__info">
                  <Link to='/PerformanceGovernance/Customer'>Customer Success</Link>
                </div>
              </div>
          </div>
          <div className="col-6-SDR">
              <div className='status-card'>
                <div className="status-card__icon">
                    <FaIcons.FaMoneyBillWave/>
                </div>
                <div className="status-card__info">
                  <Link to='/PerformanceGovernance/Finance'>Finance</Link>
                </div>
              </div>
          </div>
          <div className="col-6-SDR">
              <div className='status-card'>
                <div className="status-card__icon">
                    <FaIcons.FaPeopleArrows/>
                </div>
                <div className="status-card__info">
                  <Link to='/PerformanceGovernance/People'>People & Culture</Link>
                </div>
              </div>
          </div>
          <div className="col-6-SDR">
            <div className='status-card'>
              <div className="status-card__icon">
                  <FaIcons.FaShoppingBag/>
              </div>
              <div className="status-card__info">
                <Link to='/PerformanceGovernance/Sales'>Sales & Marketing</Link>
              </div>
            </div>
        </div>
          <div className="col-6-SDR">
              <div className='status-card'>
                <div className="status-card__icon">
                    <FaIcons.FaChess/>
                </div>
                <div className="status-card__info">
                  <Link to='/PerformanceGovernance/Strategy'>Strategy</Link>
                </div>
              </div>
          </div>
          <div className="col-6-SDR">
              <div className='status-card'>
                <div className="status-card__icon">
                    <FaIcons.FaLaptopCode/>
                </div>
                <div className="status-card__info">
                  <Link to='/PerformanceGovernance/Tech'>Tech</Link>
                </div>
              </div>
          </div>
        </section> */}
        {/* <h3 className='page-subheader'>Reportes por País</h3>
      <section className='status-cards-container'>
        <div className="col-6-SDR">
            <div className='status-card'>
              <div className="status-card__icon">
                  <CircleFlag countryCode='co' height='40'/>
              </div>
              <div className="status-card__info">
                <Link to='#'>Colombia</Link>
              </div>
            </div>
        </div>
        <div className='col-6-SDR'>
              <div className='status-card'>
                <div className="status-card__icon">
                    <CircleFlag countryCode='ec' height='40'/>
                </div>
                <div className="status-card__info">
                  <Link to='#'>Ecuador</Link>
                </div>
              </div>
        </div>
          <div className="col-6-SDR">
              <div className='status-card'>
                <div className="status-card__icon">
                    <CircleFlag countryCode='mx' height='40'/>
                </div>
                <div className="status-card__info">
                  <Link to='#'>Mexico</Link>
                </div>
              </div>
          </div>
        <div className="col-6-SDR">
            <div className='status-card'>
              <div className="status-card__icon">
                  <CircleFlag countryCode='uy' height='40'/>
              </div>
              <div className="status-card__info">
                <Link to='#'>Uruguay</Link>
              </div>
            </div>
        </div>
        </section> */}
    </div>
  )
}