import React from "react";

const ColumnNotaCredito = ({ item, index, editMode, editingData, setEditingData }) => {
    return (
        <td className="renewalsAgentPanel-td">
            {
                item.dynamicInputsData.tablero.aplicaNC == "No" ? "No Aplica" : <>
                    {
                        editMode[index] ? <>
                            <input className='renewalsAgentPanelInput' value={editingData[index].dynamicInputsData.tablero.idNotaCredito} onChange={(event) => {
                                setEditingData((prevState) => ({
                                    ...prevState,
                                    [index]: {
                                        ...prevState[index],
                                        dynamicInputsData: {
                                            ...prevState[index].dynamicInputsData,
                                            tablero: {
                                                ...prevState[index].dynamicInputsData.tablero,
                                                idNotaCredito: event.target.value
                                            }
                                        }
                                    }
                                }));
                            }} />
                        </> : item.dynamicInputsData.tablero.idNotaCredito
                    }
                </>
            }
        </td>
    )
}

export { ColumnNotaCredito }