import React, { useState } from 'react';
import {useIsAuthenticated} from '@azure/msal-react';
import {useMsal} from '@azure/msal-react';
// import Button from 'react-bootstrap/Button'
import {Button} from '@progress/kendo-react-buttons';
import {loginRequest} from '../Config';
import './Landing.css';
import logo from '../assets/logo/logo_white.png';
import {userIcon} from '@progress/kendo-svg-icons';
import {Loader} from '@progress/kendo-react-indicators';

function handleLogin(instance) {
	//sessionStorage.removeItem(keyName);
	console.log('login');
	sessionStorage.clear();
	instance.loginRedirect(loginRequest).catch((e) => {
		console.error(e);
	});
}

export const Landing = ({charging = false,ErrorMessage}) => {
    const [chargingg, setCharging] = useState(charging);
	const {instance} = useMsal();
	return (
		<>
			<div className="landingPage">
				<div className="landingLogo">
					<img href="www.siigo.com" src={logo} />
				</div>
				<div>
					<h1 className="landingTitle">DataSmart</h1>
				</div>
				<br />
				<br />
				<br />
				<div className="grid-container">
					<div className="grid-item">
						{chargingg ? (
							<div className="loading">
								<Loader themeColor={'light'} />
							</div>
						) : (
							<Button
								themeColor={'base'}
								onClick={() => {
									setCharging(true);
									handleLogin(instance);
								  }}
								svgIcon={userIcon}
								style={{fontSize: '16px'}}>
								Log in
							</Button>
						)}
					</div>
					<div className="grid-item">
						{ErrorMessage ? (
							<div className="error red">{ErrorMessage}</div>
						) : (
							<div className="error"></div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
