import { Button } from "@progress/kendo-react-buttons";

export const MyCommandCell = props => {
  const {
    dataItem
  } = props;
  const inEdit = dataItem[props.editField];
  const isNewItem = dataItem.id_actividad === undefined;
  return inEdit ? <td className="k-command-cell">
      <Button size={"small"} themeColor={"base"} fillMode={"outline"} rounded={"medium"}  onClick={() => isNewItem ? props.add(dataItem) : props.update(dataItem)}>
        {isNewItem ? "Agregar" : "Actualizar"}
      </Button>
      <Button size={"small"} themeColor={"base"} fillMode={"outline"} rounded={"medium"}  onClick={() => isNewItem ? props.discard(dataItem) : props.cancel(dataItem)}>
        {isNewItem ? "Desechar" : "Cancelar"}
      </Button>
    </td> : <td >
      <Button size={"small"} themeColor={"base"} fillMode={"outline"} rounded={"medium"}  onClick={() => props.edit(dataItem) }>
        Editar
      </Button>
      <Button size={"small"} themeColor={"base"} fillMode={"outline"} rounded={"medium"} onClick={() => window.confirm("Está seguro que desea borrar : " + dataItem.nombreActividad) && props.remove(dataItem)}>
        Eliminar
      </Button>
    </td>;
};

export const MyCommandCellTopics = props => {
  const {
    dataItem
  } = props;
  const inEdit = dataItem[props.editField];
  const isNewItem = dataItem.idActividad === undefined;
  return inEdit ? <td >
      <Button size={"small"} themeColor={"success"} fillMode={"outline"} rounded={"medium"} onClick={() => isNewItem ? props.add(dataItem) : props.update(dataItem)} style={{
    marginRight: '5px',
    fontSize: '11px'
}}>
        {isNewItem ? "Agregar" : "Actualizar"}
      </Button>
      <Button size={"small"} themeColor={"error"} fillMode={"outline"} rounded={"medium"}  onClick={() => isNewItem ? props.discard(dataItem) : props.cancel(dataItem)} style={{
    marginRight: '5px',
    fontSize: '11px'
}}>
        {isNewItem ? "Cancelar" : "Cancelar"}
      </Button>
    </td> : <td>
      <Button size={"small"} themeColor={"info"} fillMode={"outline"} rounded={"medium"}  onClick={() => props.edit(dataItem) } style={{
    marginRight: '5px',
    fontSize: '11px'
}}>
        Editar
      </Button>
      <Button size={"small"} themeColor={"error"} fillMode={"outline"} rounded={"medium"} onClick={() => window.confirm("Está seguro que desea borrar : " + dataItem.nombreActividad) && props.remove(dataItem)}  style={{
    marginRight: '5px',
    fontSize: '11px'
}}>
        Eliminar
      </Button>
    </td>;
};

export const MyCommandCellParticipantes = props => {
  const {
    dataItem
  } = props;
  const inEdit = dataItem[props.editField];
  const isNewItem = dataItem.idParticipante === undefined;
  return inEdit ? <td className="k-command-cell">
      <Button size={"small"} themeColor={"success"} fillMode={"outline"} rounded={"medium"}  onClick={() => isNewItem ? props.add(dataItem) : props.update(dataItem)} style={{
    marginRight: '5px',
    fontSize: '11px'
}}>
        {isNewItem ? "Agregar" : "Actualizar"}
      </Button>
      <Button size={"small"} themeColor={"error"} fillMode={"outline"} rounded={"medium"}  onClick={() => isNewItem ? props.discard(dataItem) : props.cancel(dataItem)} style={{
    marginRight: '5px',
    fontSize: '11px'
}}>
        {isNewItem ? "Cancelar" : "Cancelar"}
      </Button>
    </td> : <td>
      <Button size={"small"} themeColor={"info"} fillMode={"outline"} rounded={"medium"}  onClick={() => props.edit(dataItem) }  style={{
    marginRight: '5px',
    fontSize: '11px'
}}>
        Editar
      </Button>
      <Button size={"small"} themeColor={"error"} fillMode={"outline"} rounded={"medium"} onClick={() => window.confirm("Está seguro que desea borrar : " + dataItem.nombreActividad) && props.remove(dataItem)}  style={{
    marginRight: '5px',
    fontSize: '11px'
}}>
        Eliminar
      </Button>
    </td>;
};