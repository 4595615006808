import * as React from 'react';
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Button} from '@progress/kendo-react-buttons';
export const MyCommandCell = (props) => {
	const {dataItem, paramsGrid} = props;
	const inEdit = dataItem[props.editField];
	const isNewItem = dataItem.KEY === undefined;
	const date = dataItem[paramsGrid.disabledDate];
	const [visible, setVisible] = React.useState(false);
	const onDeleteData = () => {
		props.remove(props.dataItem);
		setVisible(!visible);
	};
	const toggleDialog = () => {
		setVisible(!visible);
	};
	function getObjectIdCreationDate(objectId) {
		if (typeof objectId !== 'string' || objectId.length < 8) {
			return new Date();
		}
		const timestamp = parseInt(objectId.substring(0, 8), 16);
		if (isNaN(timestamp)) {
			// throw new Error('El objectId proporcionado no contiene un timestamp válido.');
			return new Date();
		}
		return new Date(timestamp * 1000);
	}

	function isBeforeThreeMonths(dateString) {
		if (paramsGrid.disabledDate === '_id') {
			dateString = getObjectIdCreationDate(dateString);
		}
		const inputDate = new Date(dateString);
		const currentDate = new Date();
		const threeMonthsAgo = new Date();
		threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

		return inputDate < threeMonthsAgo;
	}
	return (
		<td className="k-command-cell">
			<Button
				themeColor={'primary'}
				fillMode={'outline'}
				disabled={isBeforeThreeMonths(date)}
				onClick={() =>
					inEdit
						? isNewItem
							? props.add(dataItem)
							: props.update(dataItem)
						: props.edit(dataItem)
				}>
				{inEdit ? (isNewItem ? 'Add' : 'Update') : 'Edit'}
			</Button>
			<Button
				themeColor={'primary'}
				onClick={() =>
					inEdit
						? isNewItem
							? props.discard(dataItem)
							: props.cancel(dataItem)
						: paramsGrid.disabledDeleteButton
						? null
						: toggleDialog()
				}>
				{inEdit
					? isNewItem
						? 'Discard'
						: 'Cancel'
					: paramsGrid.disabledDeleteButton
					? ''
					: 'Remove'}
			</Button>
			{visible && (
				<Dialog title={'Delete Data'} onClose={toggleDialog} width={350}>
					<div>
						Are you sure you want to delete item with id {dataItem.KEY}?
					</div>
					<DialogActionsBar>
						<Button onClick={onDeleteData}>Delete</Button>
						<Button onClick={toggleDialog}>Cancel</Button>
					</DialogActionsBar>
				</Dialog>
			)}
		</td>
	);
};
