import React from 'react'
import { TranferencesHelperDashboardECU } from '../../components/Dashboards/Customer/TranferencesHelper/TranferencesHelperECU'


export const TranferencesHelperECU = () => {
    return (
        <>
            <TranferencesHelperDashboardECU />
        </>
    )
}


