import React from 'react';
import './Components.css';
import { AiOutlineClear } from "react-icons/ai";
const ChatInput = ({ input, setInput, sendMessage, clearChat }) => (
    <div className="chat-input-cb">
        <button onClick={clearChat} className="clear-chat-button-cb">
        <AiOutlineClear />
        </button>
        <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
            placeholder="Escribe una nueva pregunta..."
        />
        <button onClick={sendMessage}>Enviar</button>
    </div>
);

export default ChatInput;