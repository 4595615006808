import { Button } from '@progress/kendo-react-buttons';
import { Slider } from '@progress/kendo-react-inputs';
import axios, { Axios } from 'axios';
import React, { useState } from 'react';


const RangeScore = ({capacitacion}) => {
  const [score, setScore] = useState(capacitacion && capacitacion.score ? capacitacion.score : 0);
  const [isEditing, setIsEditing] = useState(false);

  const handleScoreChange = (event) => {
    let about=event.value;
    about=about.toFixed(1);
    setScore(about);
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };
  const handleSaveScore = () => {
    toggleEditing();

		axios.post(
				`/api/TNB/Training?id=${capacitacion.nombreCapacitacion}`,{score:score}
			).then((response) => {
				// dispatch(setDetalleCapacitacion(response.data));
			});
    // Axios.post('api/TNB/Training?id=1', {score:score})
    // .then((response) => {
    //   console.log(response);
    // });

  };

  return (
    <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
        <h1>Score</h1>
        <h2>{score}</h2>
      {isEditing ? (
        <div style={{display:'flex'}}>
          <Slider
            value={score}
            min={0}
            max={10}
            step={0.1}
            buttons={true}
            onChange={handleScoreChange}
          />
          <Button onClick={handleSaveScore}>Save</Button>
        </div>
      ) : (
        <div>
          <Button onClick={toggleEditing}>Edit</Button>
        </div>
      )}
    </div>
  );
};

export default RangeScore;