import React, {useState, useCallback} from 'react';
import { Field} from '@progress/kendo-react-form';
import { FormDatePicker, FormInput, FormDropDownList, FormDropDownListCountry, FormDropDownListArea, FormDropDownListSubtype } from './form-components';
import { idCapacitacionValidator, tipoCapacitacionValidator, subTipoCapacitacionValidator, countryValidator, areaValidator, dateValidator } from './validators';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CountryField, TrainingTypeField, TrainingSubTypeField, AreaField, AdditionalTrainercheck} from './editors.tsx';
import {Compass} from '../../pages/Compass'
import { Label, Error } from '@progress/kendo-react-labels';


const defaultDate = new Date();


export const AccountDetails = 
<div><br></br>
    <Field name={'nombreCapacitacion'} component={FormInput} label={'Id Capacitación'} validator={idCapacitacionValidator}/>
    <Field name={'tipoCapacitacion'} component={FormDropDownList} label={'Tipo Capacitación'} validator={tipoCapacitacionValidator}/>
    <Field name={'subTipoCapacitacion'} component={FormDropDownListSubtype} label={'SubTipo Capacitación'} validator={subTipoCapacitacionValidator}/>
    <Field name={'fechaIncioCapacitacion'} component={FormDatePicker}  label={'Fecha Inicio'}  defaultValue={defaultDate}  validator={dateValidator}/>
    <Field name={'fechaFinCapacitacion'} component={FormDatePicker} label={'Fecha Fin'}  defaultValue={defaultDate} validator={dateValidator}/>
    <Field name={'pais'} component={FormDropDownListCountry} label={'País'} validator={countryValidator}/>
    <Field name={'area'} component={FormDropDownListArea} label={'Área'} validator={areaValidator}/>


  </div>;