import React from 'react'
import { useParams } from 'react-router-dom';
import { Step2Requerimiento } from '../Requerimiento/Step2Requerimiento';
import { Step2Incidencia } from '../Incidencia/Step2Incidencia';
import { Step2Reporteria } from '../Reporteria/Step2Reporteria';

export const Step2RequestForm = () => {
    const TypeRequest = useParams().type;
    switch (TypeRequest) {
        case 'Incidencia':
            return (
                <Step2Incidencia />
            )
        case 'Requerimiento':
            return (
                <Step2Requerimiento />
            )
        case 'solicitud de Reporte':
            return (
                <Step2Reporteria />
            )
        default:
            return (
                <div>Algo Salió Mal</div>
            )
    }
}
