import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';
import { Container, Box, Button } from '@mui/material';
import ActivityCardJob from './ActivityCardJob';
import CommentCard from './CommentCard';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { CircularProgress } from '@mui/material';
import { Alert} from 'react-bootstrap';
import { ConstructionOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setEmptyInfoTicket } from '../../redux/helpDeskTicketSlice';
import { selectCredencialesArea, selectCredencialesRole } from '../../redux/credencialesReducer';
import { IsAuthorized } from '../auth/IsAuthorized';
const prioritys = [
    {
        value: 'Alta',
        label: 'Alta',
    },
    {
        value: 'Media',
        label: 'Media',
    },
    {
        value: 'Baja',
        label: 'Baja',
    }];
const impactArea = [
    {
        value: 'Alto',
        label: 'Alto',
    },
    {
        value: 'Medio',
        label: 'Medio',
    },
    {
        value: 'Bajo',
        label: 'Bajo',
    }];
const estates = [
    {
        value: 'Nuevo',
        label: 'Nuevo',
    },
    {
        value: 'Asignado',
        label: 'Asignado',
    },
    {
        value: 'Proceso',
        label: 'Proceso',
    },
    {
        value: 'Revisión',
        label: 'Revisión',
    },
    {
        value: 'Aprobación',
        label: 'Aprobación',
    },
    {
        value: 'Cerrado',
        label: 'Cerrado',
    }
];
const estateDataPartner = [
    {
        value: false,
        label: 'Desaprobado'
    },
    {
        value: true,
        label: 'Aprobado'
    }
]
const areasList = [
    {
        value: 'Sales and Marketing',
        label: 'Sales and Marketing'
    },
    {
        value: 'Operations',
        label: 'Operations'
    },
    {
        value: 'Strategy',
        label: 'Strategy'
    },
    {
        value: 'Tech',
        label: 'Tech'
    },
    {
        value: 'Finanzas',
        label: 'Finanzas'
    },
    {
        value: 'People and Culture',
        label: 'People and Culture'
    },
    {
        value: 'Educación y Fundación Siigo',
        label: 'Educación y Fundación Siigo'
    },
    {
        value: 'Customer and Success',
        label: 'Customer and Success'
    }
]
const techList=[{value: 'Home Tech',
        label: 'Home Tech'},
        {value: 'NA',
        label: 'NA'}]
const SalesMarkList=[
    {value: 'Venta Nueva',
    label: 'Venta Nueva'},
    {value: 'Operaciones Comerciales',
    label: 'Operaciones Comerciales'},
    {value: 'Marketing',
    label: 'Marketing'},
    {value: 'Cross-Selling',
    label: 'Cross-Selling'},
    {value: 'NA',
    label: 'NA'}]
const CustomerList=[{
    value: 'Onboarding y entrenamiento',
    label: 'Onboarding y entrenamiento'},
    {value: 'Enamoramiento',
    label: 'Enamoramiento'},
    {value: 'NA',
    label: 'NA'}]
const OperationsList=[
    {value: 'Workforce, Opstech, Servicio al Cliente',
    label: 'Workforce, Opstech, Servicio al Cliente'},
    {value: 'NA',
    label: 'NA'}]
const strategyList=[
    {value: 'Ecosistema/Alianzas, M and A, Proyectosss-Selling',
    label: 'Ecosistema/Alianzas, M and A, Proyectosss-Selling'},
    {value: 'NA',
    label: 'NA'}]
const FinanzasList=[
    {value: 'Contabilidad, Planeacion Financiera, Nomina y entrenamiento',
    label: 'Contabilidad, Planeacion Financiera, Nomina y entrenamiento'},
    {value: 'NA',
    label: 'NA'}
    ]
const PeopleCultureList=[
    {value: 'Talent Acquisition',
    label: 'Talent Acquisition'},
    {value: 'IC, C and W',
    label: 'IC, C and W'},
    {value: 'Compensation and Benefits',
    label: 'Compensation and Benefits'},
    {value: 'Learning and Development',
    label: 'Learning and Development'},
    {value: 'People Business Partne',
    label: 'People Business Partne'},
    {value: 'NA',
    label: 'NA'}]
const EducacionFundacionList=[
    {value: 'Datos del Sena, y potenciales leads',
    label: 'Datos del Sena, y potenciales leads'},
    {value: 'NA',
    label: 'NA'},
    ]
// Inicia la función del formulario 
    const DescriptionJob = () => {
    const jData =
    {
        title: "",
        requestorName: "",
        requestorEmail: "",
        requestorArea: "",
        requestorJobTitle: "",
        requestorOther: "",
        descriptionTask: "",
        requestDate: "",
        destinationArea: [],
        approvalDataPartnerName: "",
        approvalDataPartnerState: "",
        approvalDataPartnerDomain: "",
        approvalTechLeadName: "",
        approvalTechLeadState: "",
        approvalTechLeadDomain: "",
        approvalDataOwnerName: "",
        approvalDataOwnerSate: "",
        approvalDataOwnerDomain: "",
        requestType: "",
        associatedTicket: "",
        region: "",
        priority: "",
        usePerson: "",
        authorizedPersons: "",
        needAutomation: "",
        status: "",
        assignedToName: "",
        assignedToEmail: "",
        assignedArea: "",
        Activity: {},
        completedDate: "",
        requiresApproval: "",
        dateLastModified: "",
        numberPriority: "",
        ticketId: "",
        areas:"",
        subarea:""
    };
    const [searchParams, setSearchParams] = useSearchParams();
    const [objeto, setObjeto] = useState();
    const [newComment, setNewComment] = useState();
    // const [newDestinationArea, setNewDestinationArea] = useState([]);
    searchParams.get("id");
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const navigate=useNavigate();
    const dispatch=useDispatch();
    const id = useParams().id;
    const area = useSelector(selectCredencialesArea);
    const rol=useSelector(selectCredencialesRole);
    const isAllowed={
		"Data Partner": IsAuthorized('HelpDesk-dataPartner',area,rol ),
	}

    useEffect(()=>{
        if (id > 4191) {
            if (isAllowed["Data Partner"]) {
                navigate(`/HelpDesk/MyJob/EditTicket/${id}`);
            } else {
                navigate(`/HelpDesk/MyJob/DescriptionTicket/${id}`);
            }
        }
    },[id])
    if (id > 4191) {
        if (isAllowed["Data Partner"]) {
            navigate(`/HelpDesk/MyJob/EditTicket/${id}`);
        } else {
            navigate(`/HelpDesk/MyJob/DescriptionTicket/${id}`);
        }
    }
    useEffect(() => {
        return () => {
          dispatch(setEmptyInfoTicket());
        }
      }, [])
    localStorage.setItem('idTicket',id);
    const [DateReg, setDateReg] =useState();
    const [DateMod, setDateMod] = React.useState();
    const [DateClose, setDateClose] = React.useState();
    const [jComments, setjComments] = React.useState([]);
    const [Activitys, setActivitys] = React.useState([]);
    const [successMessage, setSuccessMessage] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(false);
    const [SelectPartner, setSelectPartner] = React.useState([]);
    const [SelectTechLead,setSelectTechLead]=React.useState([]);
    const [requestDate,setRequestDate]=React.useState();
    const [dateLastModified,setDateLastModified]=React.useState();
    const [completedDate,setCompletedDate]=React.useState();
    const handleChangeChild = (e) => {
        setObjeto((previu) => {
            return {
                ...previu,
                [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
            }
        });
    }
    const handleChangeDate = (newValue) => {
        setDateReg(newValue);
    };
    const handleChangeDateMod = (newValue) => {
        setDateMod(newValue);
    };
    const handleChangeDateClose = (newValue) => {
        setDateClose(newValue);
    };
    const handlerActivity = (data) => {
        let arreglo = [];
         data.component.getVisibleRows().forEach(element => {
            let newActivity = {};
            newActivity = element.data;
            newActivity['idActivity'] = newActivity['__KEY__'];
            arreglo.push(newActivity);
         });
        setActivitys(arreglo);
         console.log(Activitys);
    }
    const divStyle = { height: "15rem", overflowY: "scroll" }
    async function SetTicket(id) {
        let api_url = '/api/fullTicket';
        const data = { "numTicket": parseInt(id) };
        let response = await axios.post(api_url, data, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Origin': '*'
            }
        });
        //console.log("Paso3:" + response);
        return response;
    }
    function armaCadenaInicial(jsonTicket) {
        let destinationArea = jsonTicket.destinationArea[0].areaName;
        let subarea=jsonTicket.destinationArea[0].subArea[0].subAreaName;
        jData.title = jsonTicket.title;
        jData.requestorName = jsonTicket.requestor.name;
        jData.requestorEmail = jsonTicket.requestor.email;
        jData.requestorJobTitle = jsonTicket.requestor.jobTitle;
        jData.requestorArea = jsonTicket.requestor.area;
        jData.requestorOther = jsonTicket.requestor.other;
        jData.descriptionTask = jsonTicket.descriptionTask;
        jData.requestDate = jsonTicket.requestDate['$date'];
        jData.destinationArea = jsonTicket.destinationArea;
        jData.approvalDataPartnerName = jsonTicket.approval.dataPartner.Name;
        jData.approvalDataPartnerState = jsonTicket.approval.dataPartner.state;
        jData.approvalDataPartnerDomain = jsonTicket.approval.dataPartner.domain;
        jData.approvalTechLeadName = jsonTicket.approval.techLead.Name;
        jData.approvalTechLeadState = jsonTicket.approval.techLead.state;
        jData.approvalTechLeadDomain = jsonTicket.approval.techLead.domain;
        jData.approvalDataOwnerName = jsonTicket.approval.dataOwner.Name;
        jData.approvalDataOwnerSate = jsonTicket.approval.dataOwner.state;
        jData.approvalDataOwnerDomain = jsonTicket.approval.dataOwner.domain;
        jData.requestType = jsonTicket.requestType;
        jData.associatedTicket = jsonTicket.associatedTicket;
        jData.region = jsonTicket.region;
        jData.priority = jsonTicket.priority;
        jData.usePerson = jsonTicket.usePerson;
        jData.authorizedPersons = jsonTicket.authorizedPersons;
        jData.needAutomation = jsonTicket.needAutomation;
        jData.impactArea = jsonTicket.impactArea;
        jData.status = jsonTicket.status;
        jData.assignedToName = jsonTicket.assignedTo.name;
        jData.assignedToEmail = jsonTicket.assignedTo.email;
        jData.assignedArea = jsonTicket.assignedTo.area;
        jData.Activity = jsonTicket.Activity;
        jData.completedDate = jsonTicket.completedDate['$date'];
        jData.requiresApproval = jsonTicket.requiresApproval;
        jData.dateLastModified = jsonTicket.dateLastModified['$date'];
        jData.numberPriority = jsonTicket.numberPriority;
        jData.ticketId = jsonTicket.ticketId;
        jData.areas=destinationArea;
        jData.subarea=subarea;
    }
    

    
    const addComment = async () => {
        let api_url = '/api/addCommentsTickets';
        let currentDate = dayjs(new Date()).format('DD/MM/YYYY');
        console.log(currentDate);
        const data = {
            "ticketId": parseInt(id),
            "comments": newComment,
            "commentPerson": sessionStorage.getItem('name'),
            "responsibleUser":sessionStorage.getItem("usr")
        }
        console.log('request: ', data);
        await axios.post(api_url, data, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Origin': '*'
            }
        }).then(respuesta => {
            // console.log('AddComment: ',respuesta);
            setNewComment('');
            searchComments(id).then(respuesta => {
                if (respuesta !== undefined) {
                    setjComments(respuesta.data);
                }
                else {
                    setjComments([]);
                }
            });
        });
    }
    const searchComments = async (ticketId) => {
        let api_url = '/api/searchCommentsTickets';
        const data = {
            "ticketId": parseInt(ticketId),
        }
        console.log("terst1", data);
        let response = await axios.post(api_url, data, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Origin': '*'
            }
        });
        return response;
    }

    async function listDataParners() {
        let api_url = '/api/listDataParner';
        let data = {};
        axios.post(api_url, { data }, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Origin': '*'
            }
        })
            .then(response => {
                console.log("dataParnet: "+response.data);
                setSelectPartner(response.data);
            })
            .catch(err => {
                console.log(err);
                alert("No se encontro la información, favor de volver a intentar");
            });
    }

    async function listTechLeads() {
        let api_url = '/api/listTechLead';
        let data = {};
        axios.post(api_url, { data }, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Origin': '*'
            }
        })
            .then(response => {
                setSelectTechLead(response.data);
                console.log(response)
            })
            .catch(err => {
                console.log(err);
                alert("El requerimiento no se guardo, favor de revisar la información");
            });
    }

    useEffect(() => {
        if (id !== null) {
            SetTicket(id).then(response => {
                armaCadenaInicial(response.data[0]);
                setObjeto(jData);
                setActivitys(jData.Activity);
                console.log(jData.dateLastModified);
                listDataParners();
                listTechLeads();
                searchComments(id).then(respuesta => {
                    if (respuesta.data.message === undefined) {
                        setjComments(respuesta.data);
                    }
                    else {
                        setjComments([]);
                    }
                });
                let f1 = jData.requestDate.substring(0, 10);
                let f2 = jData.requestDate.substring(11, 19);
                setRequestDate(f1 + ' ' + f2);
                let f3 = jData.dateLastModified.substring(0, 10);
                let f4 = jData.dateLastModified.substring(11, 19);
                let t=f1 + ' ' + f2;
                setDateLastModified(t);
                if (jData.completedDate!==undefined){
                    let f5 = jData.completedDate.substring(0, 10);
                    let f6 = jData.completedDate.substring(11, 19);
                    setCompletedDate(f1 + ' ' + f2);
                }
                
            }).catch(err => {
                console.log(err);
            });
        }
    }, []);

    if (!objeto) return <div className='desktop-view-SDR'>
    <div id='reportContainerSDR'>
    <div className='centerDS'><CircularProgress/></div>
    </div>
    </div>;

    return (
        <>
            <div>
                <Container className="mb-5">
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1 },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Row>
                            <Col xs={12} sm={3}>
                                <h6 className="d-flex justify-content-between align-items-center mb-3">
                                    <h6 className='page-subheader'>Datos de la solicitud</h6>
                                </h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8} sm={4}>
                                <TextField
                                    sx={{ width: '20ch' }}
                                    disabled
                                    id='ticketId'
                                    name="ticketId"
                                    label="No. Solicitud"
                                    type="text"
                                    variant="standard"
                                    value={objeto.ticketId || ''}
                                    onChange={handleChangeChild.bind(this)} />
                            </Col>
                            <Col xs={12} sm={4}>
                                <TextField
                                    sx={{ width: '20ch' }}
                                    disabled
                                    required
                                    id="priority"
                                    name="priority"
                                    select
                                    label="Prioridad"
                                    value={objeto.priority || ''}
                                    onChange={handleChangeChild.bind(this)}
                                    variant="standard">
                                    {prioritys.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                            <Col xs={12} sm={4}>
                                <TextField
                                    sx={{ width: '20ch' }}
                                    disabled
                                    required
                                    id="status"
                                    name="status"
                                    select
                                    label="Estatus"
                                    value={objeto.status || ''}
                                    onChange={handleChangeChild.bind(this)}
                                    variant="standard">
                                    {estates.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6}>
                                <TextField
                                    sx={{ width: '58ch' }}
                                    disabled
                                    inputProps={{ maxLength: 250 }}
                                    required
                                    name="title"
                                    label="Título"
                                    defaultValue={objeto.title}
                                    type="text"
                                    variant="standard"
                                    onChange={handleChangeChild.bind(this)} />
                                <TextField
                                    sx={{ width: '28ch' }}
                                    disabled
                                    id='requestType'
                                    name='requestType'
                                    label="Tipo de solicitud"
                                    type="text"
                                    variant="standard"
                                    defaultValue={objeto.requestType || ''}
                                    onChange={handleChangeChild.bind(this)} />
                                { objeto.associatedTicket != "" ?<>
                                    <TextField
                                        sx={{ width: '28ch' }}
                                        disabled
                                        name='associatedTicket'
                                        label="Ticket asociado"
                                        type="text"
                                        variant="standard"
                                        defaultValue={objeto.associatedTicket || ''}
                                        onChange={handleChangeChild.bind(this)} />
                                </>:null}
                                <TextField
                                    sx={{ width: '20ch' }}
                                    disabled
                                    id='requestDate'
                                    name='requestDate'
                                    label="Fecha de solicitud"
                                    type="text"
                                    variant="standard"
                                    value={requestDate}
                                    onChange={handleChangeChild.bind(this)} />
                                <TextField
                                    disabled
                                    sx={{ width: '20ch' }}
                                    id='dateLastModified'
                                    name='dateLastModified'
                                    label="Fecha de última actualización"
                                    type="text"
                                    variant="standard"
                                    value={dateLastModified}
                                    onChange={handleChangeChild.bind(this)} />
                                {objeto.status === "Cerrado" ?
                                    <>
                                    <TextField
                                    disabled
                                    sx={{ width: '20ch' }}
                                    id='completedDate'
                                    name='completedDate'
                                    label="Fecha de Cierre"
                                    type="text"
                                    variant="standard"
                                    value={completedDate}
                                    onChange={handleChangeChild.bind(this)} />
                                    </> : null}
                                <TextField
                                    sx={{ width: '58ch' }}
                                    disabled
                                    id='descriptionTask'
                                    name='descriptionTask'
                                    label="Descripción de la tarea"
                                    multiline
                                    variant="standard"
                                    value={objeto.descriptionTask || ''}
                                    onChange={handleChangeChild.bind(this)} />
                                {objeto.requestType === "Requerimiento" ?
                                    <><TextField
                                        sx={{ width: '58ch' }}
                                        disabled
                                        id='usePerson'
                                        name='usePerson'
                                        label="Uso que le dará"
                                        multiline
                                        variant="standard"
                                        value={objeto.usePerson || ''}
                                        onChange={handleChangeChild.bind(this)} />
                                        <TextField
                                            sx={{ width: '58ch' }}
                                            disabled
                                            id='authorizedPersons'
                                            name='authorizedPersons'
                                            label="Personas que veran la información"
                                            multiline
                                            variant="standard"
                                            value={objeto.authorizedPersons || ''}
                                            onChange={handleChangeChild.bind(this)} /></>
                                    : null}
                                {objeto.requestType === "Incidencia" ?
                                    <>
                                        <TextField
                                            sx={{ width: '18ch' }}
                                            disabled
                                            id="impactArea"
                                            name="impactArea"
                                            select
                                            label="Impacto en el área"
                                            value={objeto.impactArea || ''}
                                            onChange={handleChangeChild.bind(this)}
                                            variant="standard">
                                            {impactArea.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </>
                                    : null}
                                <br />
                                    <TextField
                                        sx={{ width: '35ch' }}
                                        disabled
                                        required
                                        id="areas"
                                        name="areas"
                                        select
                                        label="Area"
                                        value={objeto.areas || ''}
                                        onChange={handleChangeChild.bind(this)}
                                        variant="standard">
                                        {areasList.map((area) => (
                                            <MenuItem key={area.value} value={area.value}>
                                                {area.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <br />
                                    {objeto.areas === "Sales and Marketing" &&
                                        <TextField
                                            sx={{ width: '35ch' }}
                                            required
                                            disabled
                                            id="subarea"
                                            name="subarea"
                                            select
                                            label="SubArea"
                                            value={objeto.subarea || ''}
                                            onChange={handleChangeChild.bind(this)}
                                            variant="standard">
                                            {SalesMarkList.map((subarea) => (
                                            <MenuItem key={subarea.value} value={subarea.value}>
                                                {subarea.label}
                                            </MenuItem>
                                            ))} 
                                        </TextField>
                                    }
                                    {objeto.areas === "Customer and Success" &&
                                        <TextField
                                            sx={{ width: '35ch' }}
                                            disabled
                                            required
                                            id="subarea"
                                            name="subarea"
                                            select
                                            label="SubArea"
                                            value={objeto.subarea || ''}
                                            onChange={handleChangeChild.bind(this)}
                                            variant="standard">
                                            {CustomerList.map((subarea) => (
                                            <MenuItem key={subarea.value} value={subarea.value}>
                                                {subarea.label}
                                            </MenuItem>
                                            ))}                
                                        </TextField>
                                    }
                                    {objeto.areas === "Operations" &&
                                        <TextField
                                            sx={{ width: '35ch' }}
                                            disabled
                                            required
                                            id="subarea"
                                            name="subarea"
                                            select
                                            label="SubArea"
                                            value={objeto.subarea || ''}
                                            onChange={handleChangeChild.bind(this)}
                                            variant="standard">
                                            {OperationsList.map((subarea) => (
                                            <MenuItem key={subarea.value} value={subarea.value}>
                                                {subarea.label}
                                            </MenuItem>
                                            ))}             
                                        </TextField>
                                    }
                                    {objeto.areas === "Strategy" &&
                                        <TextField
                                            sx={{ width: '35ch' }}
                                            disabled
                                            required
                                            id="subarea"
                                            name="subarea"
                                            select
                                            label="SubArea"
                                            value={objeto.subarea || ''}
                                            onChange={handleChangeChild.bind(this)}
                                            variant="standard">
                                            {strategyList.map((subarea) => (
                                            <MenuItem key={subarea.value} value={subarea.value}>
                                                {subarea.label}
                                            </MenuItem>
                                            ))}             
                                        </TextField>
                                    }
                                    {objeto.areas === "Tech" &&
                                        <TextField
                                            sx={{ width: '35ch' }}
                                            disabled
                                            required
                                            id="subarea"
                                            name="subarea"
                                            select
                                            label="SubArea"
                                            value={objeto.subarea || ''}
                                            onChange={handleChangeChild.bind(this)}
                                            variant="standard">
                                            {techList.map((subarea) => (
                                            <MenuItem key={subarea.value} value={subarea.value}>
                                                {subarea.label}
                                            </MenuItem>
                                            ))}                
                                        </TextField>
                                    }
                                    {objeto.areas === "Finanzas" &&
                                        <TextField
                                            sx={{ width: '35ch' }}
                                            disabled
                                            required
                                            id="subarea"
                                            name="subarea"
                                            select
                                            label="SubArea"
                                            value={objeto.subarea || ''}
                                            onChange={handleChangeChild.bind(this)}
                                            variant="standard">
                                            {FinanzasList.map((subarea) => (
                                            <MenuItem key={subarea.value} value={subarea.value}>
                                                {subarea.label}
                                            </MenuItem>
                                            ))}         
                                        </TextField>
                                    }
                                    {objeto.areas === "People and Culture" &&
                                        <TextField
                                            sx={{ width: '35ch' }}
                                            disabled
                                            required
                                            id="subarea"
                                            name="subarea"
                                            select
                                            label="SubArea"
                                            value={objeto.subarea || ''}
                                            onChange={handleChangeChild.bind(this)}
                                            variant="standard">
                                            {PeopleCultureList.map((subarea) => (
                                            <MenuItem key={subarea.value} value={subarea.value}>
                                                {subarea.label}
                                            </MenuItem>
                                            ))}                         
                                        </TextField>
                                    }
                                    {objeto.areas === "Educación y Fundación Siigo" &&
                                        <TextField
                                            sx={{ width: '35ch' }}
                                            required
                                            disabled
                                            id="subarea"
                                            name="subarea"
                                            select
                                            label="SubArea"
                                            value={objeto.subarea || ''}
                                            onChange={handleChangeChild.bind(this)}
                                            variant="standard">
                                            {EducacionFundacionList.map((subarea) => (
                                            <MenuItem key={subarea.value} value={subarea.value}>
                                                {subarea.label}
                                            </MenuItem>
                                            ))}                         
                                        </TextField>
                                    }
                            </Col>
                            <Col xs={12} sm={6}>
                                <br />
                                <h6>Información solicitante</h6>
                                <ul className="list-group mb-3">
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div>
                                            <TextField
                                                sx={{ width: '55ch' }}
                                                disabled
                                                required
                                                name='requestorName'
                                                label="Nombre"
                                                value={objeto.requestorName || ''}
                                                type="text"
                                                variant="standard"
                                                onChange={handleChangeChild.bind(this)} />
                                            <TextField
                                                sx={{ width: '55ch' }}
                                                disabled
                                                id='email'
                                                name="requestorEmail"
                                                label="Email"
                                                type="email"
                                                variant="standard"
                                                value={objeto.requestorEmail || ''}
                                                onChange={handleChangeChild.bind(this)} />
                                            <TextField
                                                sx={{ width: '55ch' }}
                                                disabled
                                                id='area'
                                                name="requestorArea"
                                                label="Area"
                                                type="text"
                                                variant="standard"
                                                value={objeto.requestorArea || ''}
                                                onChange={handleChangeChild.bind(this)} />
                                            { objeto.requestorOther != "" ?<>
                                                <TextField
                                                    sx={{ width: '55ch' }}
                                                    disabled
                                                    id='Other'
                                                    name="requestorOther"
                                                    label="Dueño del ticket"
                                                    type="text"
                                                    variant="standard"
                                                    value={objeto.requestorOther || ''}
                                                    onChange={handleChangeChild.bind(this)} />
                                            </>:null}
                                            <TextField
                                                sx={{ width: '27ch' }}
                                                disabled
                                                id='jobTitle'
                                                name="requestorJobTitle"
                                                label="Cargo"
                                                type="text"
                                                variant="standard"
                                                value={objeto.requestorJobTitle || ''}
                                                onChange={handleChangeChild.bind(this)} />
                                            <TextField
                                                sx={{ width: '26ch' }}
                                                disabled
                                                id='region'
                                                label="Región"
                                                type="text"
                                                variant="standard"
                                                defaultValue={objeto.region || ''}
                                                onChange={handleChangeChild.bind(this)} />
                                        </div>
                                    </li>
                                </ul>
                                <h6 className="d-flex justify-content-between align-items-center mb-3">
                                    Información Data Partner
                                </h6>
                                <ul className="list-group mb-3">
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div>
                                        <TextField
                                                sx={{ width: '55ch' }}
                                                disabled
                                                required
                                                name='approvalDataPartnerName'
                                                select
                                                label="Nombre"
                                                value={objeto.approvalDataPartnerName}
                                                type="text"
                                                variant="standard"
                                            >
                                                {SelectPartner.map((option) => (
                                                    <MenuItem key={option.name} value={option.name}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <TextField
                                                sx={{ width: '55ch' }}
                                                disabled
                                                id='approvalDataPartnerDomain'
                                                name="approvalDataPartnerDomain"
                                                label="Área"
                                                type="text"
                                                variant="standard"
                                                value={objeto.approvalDataPartnerDomain || ''}
                                                onChange={handleChangeChild.bind(this)} />
                                            <TextField
                                                sx={{ width: '18ch' }}
                                                required
                                                disabled
                                                id="approvalDataPartnerState"
                                                name="approvalDataPartnerState"
                                                select
                                                label="Aprobación"
                                                value={objeto.approvalDataPartnerState || ''}
                                                onChange={handleChangeChild.bind(this)}
                                                variant="standard">
                                                {estateDataPartner.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </li>
                                </ul>
                                { objeto.approvalTechLeadName!=="" ? <>
                                    <h6 className="d-flex justify-content-between align-items-center mb-3">
                                        Información Tech Lead
                                    </h6>
                                    <ul className="list-group mb-3">
                                        <li className="list-group-item d-flex justify-content-between lh-sm">
                                            <div>
                                                <TextField
                                                    sx={{ width: '55ch' }}
                                                    required
                                                    disabled
                                                    name='approvalTechLeadName'
                                                    select
                                                    label="Nombre"
                                                    value={objeto.approvalTechLeadName || ''}
                                                    type="text"
                                                    variant="standard"
                                                    onChange={handleChangeChild.bind(this)}
                                                >
                                                </TextField>
                                                <TextField
                                                    disabled
                                                    sx={{ width: '55ch' }}
                                                    id='approvalTechLeadDomain'
                                                    name="approvalTechLeadDomain"
                                                    label="Área"
                                                    type="text"
                                                    variant="standard"
                                                    value={objeto.approvalTechLeadDomain || ''}
                                                    onChange={handleChangeChild.bind(this)} />
                                                <TextField
                                                    sx={{ width: '18ch' }}
                                                    disabled
                                                    required
                                                    id="approvalTechLeadState"
                                                    name="approvalTechLeadState"
                                                    select
                                                    label="Aprobación"
                                                    value={objeto.approvalTechLeadState || ''}
                                                    onChange={handleChangeChild.bind(this)}
                                                    variant="standard">
                                                    {estateDataPartner.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </div>
                                        </li>
                                    </ul>
                                </>:null}
                                <h6 className="d-flex justify-content-between align-items-center mb-3">
                                    Comentarios
                                </h6>
                                {console.log(jComments)}
                                {jComments !== [] ?
                                    <Container style={divStyle}>
                                        <CommentCard jComments={jComments} />
                                    </Container>
                                    : <></>}
                                <Row>
                                    <Col xs={12} sm={3}>
                                        <TextField
                                            sx={{ width: '45ch' }}
                                            rows='2'
                                            id='newComment'
                                            name='newComment'
                                            label="Escribe un comentario"
                                            multiline
                                            variant="standard"
                                            value={newComment}
                                            onChange={(e) => setNewComment(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={2}>
                                        <Button onClick={addComment}>Agregar</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col xs={12} sm={12}>
                                <h6 className="d-flex justify-content-between align-items-center mb-3">
                                    Actividades
                                </h6>
                                <ActivityCardJob handlerActivity={handlerActivity} Activitys={Activitys} successMessage={successMessage} errorMessage={errorMessage}/>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs={6} sm={6}>
                                <Link to={"../MyJob"}><Button variant="contained">Regresar</Button></Link>
                            </Col>
                        </Row>
                    </Box>
                    { successMessage ? <Alert key={'success' } variant={'success' }>El requerimiento se guardo exitosamente.</Alert> : <></>}
                    { errorMessage ? <Alert key={'danger' } variant={'danger' }>El requerimiento no se guardo.</Alert> : <></>}
                </Container>
            </div></>
    );
}
export default DescriptionJob;