import React from 'react';
import './ChunkBar.css';
import {useSelector} from 'react-redux';
import {selecthistory} from '../../../../redux/ControlCenterReducer.js';

import CustomTooltip from './TooltipC.js';


const ChunkBar = () => {
	let statuses = useSelector(selecthistory) || [];
	// let statuses = arr;

	function classifyEventsByDay(events) {
		return events.reduce((acc, event) => {
			// Obtener la fecha (sin la parte de tiempo) de startTime
			const eventDate = new Date(event.startTime).toISOString().split('T')[0];

			// Si la fecha no existe en el acumulador, crear una nueva entrada
			if (!acc[eventDate]) {
				acc[eventDate] = [];
			}

			// Añadir el evento a la fecha correspondiente
			acc[eventDate].push(event);

			return acc;
		}, {});
	}

	// Usar la función para clasificar los eventos
	const classifiedEvents = classifyEventsByDay(statuses);

	return (
		<div className="ChunkBargrid-container">
			{Object.keys(classifiedEvents)
				.reverse()
				.slice(-8)
				.map((date) => (
					<div className="ChunkBargrid-column" key={date}>
						<div className="ChunkBargrid-date">{date}</div>
						{classifiedEvents[date].map((event) => (
							<CustomTooltip eventStatus={event.status} key={event.id} data={event}/>
						))}
					</div>
				))}
		</div>
	);
};

export default ChunkBar;
