
const chooseStatus = (id, list) => {
    let status = null;
    try {
        status = list[parseInt(id)];
    } catch (error) {}
    return status;
};
export const buildliststatus = ( status) => {
    let statusList = [];
    for (let key in status) {
        if (status.hasOwnProperty(key)) {
            let item = status[key];
			statusList.push(item.name);
		}
	}
    return statusList;
}
const buildDate = (inputDate) => {
    let date = new Date(inputDate);
    date.setHours(date.getHours() + 5);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hour = date.getHours();
    let minutes = date.getMinutes();

    // Formatear para salida
    return `${hour}:${minutes < 10 ? '0' + minutes : minutes} | ${day}/${month}/${year}`;
}

export const dataProcessing = (data, status,filterr) => {
	let statusList = buildliststatus(status)
	

    //console.log("🚀 ~ dataProcessing ~ statusList:", statusList)
	let listComments = [];
	if(filterr){
        data.map((item) => {
            item.comments.map((comment) => {
                listComments.push({
                    comment: comment.comment,
                    date: buildDate(comment.date),
                    commentOwner: comment.commentOwner,
                    timeElapsedStatus: comment.timeElapsedStatus,
                    status: chooseStatus(item._id, statusList),
                });
            });
        });
    }else{
        data.map((item) => {
            item.comments.map((comment) => {
                if(!comment.isSystem)
                listComments.push({
                    comment: comment.comment,
                    date: buildDate(comment.date),
                    commentOwner: comment.commentOwner,
                    timeElapsedStatus: comment.timeElapsedStatus,
                    status: chooseStatus(item._id, statusList),
                });
            });
        });
    }
    //console.log("🚀 ~ dataProcessing ~ listComments:", listComments)


const comentariosAgrupados = statusList.map(estado => ({
    title: estado,
    items: listComments.filter(comentario => comentario.status === estado)
}));

	return comentariosAgrupados;
};
