import React from 'react'
import { TranferencesHelperDashboardURU } from '../../components/Dashboards/Customer/TranferencesHelper/TranferencesHelperURU'


export const TranferencesHelperURU = () => {
    return (
        <>
            <TranferencesHelperDashboardURU />
        </>
    )
}


