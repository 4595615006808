import React, { useEffect } from 'react';
import axios from 'axios';
import { Loading } from '../Loading/Loading';
import './RealTimeStatesTable.css'
import { Box, Card, Typography } from '@mui/material';
import { Grid, Column, ColumnMenu } from './Grid';
import { ScheduleCell, StateTimeCell, AgentCell, agentState, TimestampCell } from './GridCells'

const RealTimeStatesTable = () => {

    const [loading, setLoading] = React.useState(true);
    const [usrEmail, setUsrEmail] = React.useState(null);
    const [tableRawData, setTableRawData] = React.useState(null);
    const [tableRealTimeData, setTableRealTimeData] = React.useState(null);
    const [lastUpdate, setLastUpdate] = React.useState(null);

    useEffect(() => {
        let mail = sessionStorage.getItem('usr')
        setUsrEmail(mail)
        const intervalId = setInterval(() => {
            getData();
        }, 360000);

        return () => clearInterval(intervalId);
    }, [])

    useEffect(() => {
        if (usrEmail) {
            getData()
        }
    }, [usrEmail])
    
    useEffect(() => {
        let lastUpdate = null;
        let finalData = [];
        if (tableRawData && tableRawData.agentStates) {
            for (const agent of tableRawData.agentStates) {
                if (agent.STATE != "Logout") {
                    agent.agentNameEmail = agent.AGENT_NAME +': '+agent.AGENT_EMAIL
                    let agentHC = tableRawData.hc.find(agentRow => agentRow.Email == agent.AGENT_EMAIL)
                    
                    // console.log(agent.schedule[0])
                    // agent.scheduleStart = (agent.schedule[0]["Hr Inicio"] != undefined) ? "-" : agent.schedule[0]["Hr Inicio"]
                    // agent.scheduleEnd = (agent.schedule[0]["Hr Fin"] != undefined) ? "-" : agent.schedule[0]["Hr Fin"]
                    // agent.breakStart1 = (agent.schedule[0]["IN Break 1"] != undefined) ? "-" : agent.schedule[0]["IN Break 1"]
                    // agent.breakEnd1 = (agent.schedule[0]["FIN Break 1"] != undefined) ? "-" : agent.schedule[0]["FIN Break 1"]
                    // agent.breakStart2 = (agent.schedule[0]["IN Break 2"] != undefined) ? "-" : agent.schedule[0]["IN Break 2"]
                    // agent.breakEnd2 = (agent.schedule[0]["FIN Break 2"] != undefined) ? "-" : agent.schedule[0]["FIN Break 2"]
                    // agent.lunchStart = (agent.schedule[0]["H Ini Alm"] != undefined) ? "-" : agent.schedule[0]["H Ini Alm"]
                    // agent.lunchEnd = (agent.schedule[0]["H Fin Alm"] != undefined) ? "-" : agent.schedule[0]["H Fin Alm"]
                    
                    if (agent.REASON_CODE != undefined) {
                        agent.REASON_CODE = '-'
                    }
                    if (agentHC) {
                        agent.Leader = agentHC.Lider
                        agent.Area = agentHC.Area
                        finalData.push(agent);
                    }
                    if (agent.LOGIN_TIME == undefined || agent.LOGIN_TIME == 'NaN') {
                        finalData.push(agent);
                    }
                }

                lastUpdate = agent.LastUpdated
            }
        }
        setLastUpdate(lastUpdate)
        setTableRealTimeData(finalData)
    }, [tableRawData])
    
    useEffect(() => {
        if (tableRealTimeData && tableRealTimeData.length > 0) {
            setLoading(false)
        }
    }, [tableRealTimeData])

    const getData = () => {
        setLastUpdate("Cargando...")
        Promise.all([
            axios.get('/api/siignalGetLatestStates', {})
        ]).then((states) => {
            setTableRawData({
                agentStates: states[0].data.agentStates[0].documents,   
                hc: states[0].data.hc
            })
        }).catch(err => {
            console.log(err)
        })
    }
    return (
        loading ? <Loading /> : <>
            <Box >
                <Card variant="outlined" className='boxShadow'>
                    <Typography component="div" sx={{ fontWeight: 800, fontSize: 19, color: '#00244c', fontFamily: 'inherit', paddingLeft: '5px' }}>
                        Tabla de datos
                    </Typography>
                    <Typography sx={{ fontWeight: 600, fontSize: 10, color: '#00244c', fontFamily: 'inherit', paddingLeft: '5px' }}>
                        Ultima actualizacion: {lastUpdate}
                    </Typography>
                    <br />
                    <Grid
                        data={tableRealTimeData}
                        resizable={true}
                    >
                        <Column className='tableColumn' title="Agente">
                            <Column className='tableColumn' columnMenu={ColumnMenu} width={"200px"} field="Area" title="Celula" />
                            <Column className='tableColumn' columnMenu={ColumnMenu} width={"200px"} field="Leader" title="Lider" />
                            <Column className='tableColumn' columnMenu={ColumnMenu} width={"200px"} cell={AgentCell} field="agentNameEmail" title="Agente" />
                            <Column className='tableColumn' columnMenu={ColumnMenu} width={"100px"} cell={agentState} field="STATE" title="Estado" />
                            <Column className='tableColumn' columnMenu={ColumnMenu} width={"100px"} cell={StateTimeCell} field="TIMESTAMP" title="Duración" />
                        </Column>

                        <Column className='tableColumn' title="Turno">
                            <Column className='tableColumn' columnMenu={ColumnMenu} width={"80px"} cell={ScheduleCell} field="scheduleStart" title="Inicio" />
                            <Column className='tableColumn' columnMenu={ColumnMenu} width={"80px"} cell={ScheduleCell} field="scheduleEnd" title="Fin" />
                        </Column>

                        <Column className='tableColumn' title="Break 1">
                            <Column className='tableColumn' columnMenu={ColumnMenu} width={"80px"} cell={ScheduleCell} field="breakStart1" title="Inicio" />
                            <Column className='tableColumn' columnMenu={ColumnMenu} width={"80px"} cell={ScheduleCell} field="breakEnd1" title="Fin" />
                        </Column>

                        <Column className='tableColumn' title="Break 2">
                            <Column className='tableColumn' columnMenu={ColumnMenu} width={"80px"} cell={ScheduleCell} field="breakStart2" title="Inicio" />
                            <Column className='tableColumn' columnMenu={ColumnMenu} width={"80px"} cell={ScheduleCell} field="breakEnd2" title="Fin" />
                        </Column>

                        <Column className='tableColumn' title="Almuerzo">
                            <Column className='tableColumn' columnMenu={ColumnMenu} width={"80px"} cell={ScheduleCell} field="lunchStart" title="Inicio" />
                            <Column className='tableColumn' columnMenu={ColumnMenu} width={"80px"} cell={ScheduleCell} field="lunchEnd" title="Fin" />
                        </Column>


                        <Column className='tableColumn' columnMenu={ColumnMenu} width={"2px"} field="REASON_CODE" title="REASON_CODE" />
                    </Grid>
                </Card>
            </Box>
        </>
    );
}
export { RealTimeStatesTable } 