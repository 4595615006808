import { AutoComplete, MultiSelect } from "@progress/kendo-react-dropdowns";
import { FieldWrapper } from "@progress/kendo-react-form";
import axios from "axios";
import { useState } from "react";
import { searchPeople } from "../../../Graph";
import {  Hint,Error } from "@progress/kendo-react-labels";
import { Loader } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-buttons";
export const AddWhoWillSee = (fieldRenderProps) => {

    async function getToken() {
        let api_url = '/tokenGraph';
        return axios.get(api_url,{
          headers: { 
            'Content-Type': 'application/json; charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
            'ConsistencyLevel':'eventual'
          }});
      }

    async function search(name) {
        if(name!==''){
            setLoading(true);
            await getToken().then((response)=>{
                searchPeople(response.data.access_token,name).then(response=>{
                    setListNames(response.value?.map(objeto => objeto.displayName));
                    setLoading(false);
                    if(listNames?.length > 0){
                        // setListShow(true);
                        // setLoading(false);
                    }
                    else{
                        if (!response){
                            console.error("no se puede conectar con Graph")
                        }
                        // setLoading(false);
                        // setListShow(false);
                    }
                    // setInitialSearch(false);
                })
            });
        }
    }



    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        type,
        value,
        optional,
        ...others
      } = fieldRenderProps;
      const [loading, setLoading] = useState(false)
      const [ listNames, setListNames ] = useState([]);
    const [timer, setTimer] = useState(null);
  
  const showValidationMessage = touched && validationMessage;
      const showHint = !showValidationMessage && hint;
      const hintId = showHint ? `${id}_hint` : '';
      const errorId = showValidationMessage ? `${id}_error` : '';
  

  const handleSearch = (event) => {   
    fieldRenderProps.onChange({
        value: event.value,
    })
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
        search(event.filter.value)
    }, 300); // 1000 ms = 1 segundo
    setTimer(newTimer);
  };

  return (
    <FieldWrapper>
        <div>Ingresa el nombre de las personas que veran la información:</div>
    <div style={{ display: 'flex' }}>
      
      {/* <AutoComplete
        valid={valid} type={type} id={id} disabled={disabled} ariaDescribedBy={`${hintId} ${errorId}`} {...others}
        data={listNames}
        onChange={handleSearch}
        value={value}
        placeholder="Escribe aquí el nombre"
        style={{
          width: "400px",
        }}
      /> */}
      <MultiSelect
        valid={valid} type={type} id={id} disabled={disabled} ariaDescribedBy={`${hintId} ${errorId}`} value={value} {...others}
        data={listNames}
        filterable={true}
        onFilterChange={handleSearch}
        style={{
          width: "450px",
        }}
        placeholder="Escribe el nombre..."
      />

      
      {loading && <Loader size="medium" />}
    </div>
    {showHint && <Hint id={hintId}>{hint}</Hint>}
    {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
 
    </FieldWrapper>
  )
}

