
import {Field} from '@progress/kendo-react-form';
import { useState } from 'react';
import { FormDropDownList, FormInput, FormRadioGroup } from '../../components/RequestForm/form-components';
import { ImpactTypes, typeOfReport, yes_no } from '../../components/RequestForm/data';
import { RadioButtonValidator, numTicketsValidator, requiredValidator } from '../../components/RequestForm/Validators';
export const Step3Reporteria = (props) => {
    const [enableOtherPanel, setEnableOtherPanel] = useState(false);

    const handleChangeChild = (event) => {
		if(event.value){
			setEnableOtherPanel(true)
		}else{
			setEnableOtherPanel(false)
		}
    };

	return(
		<div>
            <Field
			key={'requestImpact'}
			id={'requestImpact'}
			name={'requestImpact'}
			label={'¿Cúal es el impacto en la operación?'}
			layout={'horizontal'}			
			component={FormRadioGroup}
			data={ImpactTypes}
			validator={requiredValidator}
		/>
        <Field
			key={'typeOfdashboard'}
			id={'typeOfdashboard'}
			name={'typeOfdashboard'}
			label={'Tipo de DashBoard?'}
			component={FormDropDownList}
			data={typeOfReport}
			validator={requiredValidator}
		/>
		<Field
			key={'previousTickets'}
			id={'previousTickets'}
			name={'previousTickets'}
			label={'¿Hay un ticket previo relacionado con este?'}
			layout={'horizontal'}		
			onChange={handleChangeChild}	
			component={FormRadioGroup}
			data={yes_no}
			validator={RadioButtonValidator}
		/>
		{enableOtherPanel&&
			<Field
			key={'associatedTicketNumber'}
			id={'associatedTicketNumber'}
			name={'associatedTicketNumber'}
			label={'Indica el número de ticket previo:'}
			component={FormInput}
			validator={numTicketsValidator}
		/>
		}
        </div>
	);
}
