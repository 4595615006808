import React from 'react';

const EmployeeNode = ({ name, email }) => {
    return (
        <div className='EmployeeNod'>
            <p>Name: {name}</p>
            <p>Email: {email}</p>
        </div>
    );
};

export default EmployeeNode;
