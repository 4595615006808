import { createSlice } from '@reduxjs/toolkit'

export const renewalsTreeReducer = createSlice({
    name: 'renewalsTree',
    initialState: {
        FormData: null,
        currentStep: null,
        selectionHistory: null,
        selectionHistoryIds: null,
        shapesData: null,
        conectionsData: null,
        dynamicInputsData: {
            "user": sessionStorage.getItem('usr')
        },
        config: null
    },
    reducers: {
        setFormData: (state, action) => {
            state.FormData = action.payload;
        },
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        setSelectionHistory: (state, action) => {
            state.selectionHistory = action.payload;
        },
        setSelectionHistoryIds: (state, action) => {
            state.selectionHistoryIds = action.payload;
        },
        setShapesData: (state, action) => {
            state.shapesData = action.payload;
        },
        setConectionsData: (state, action) => {
            state.conectionsData = action.payload;
        },
        setDynamicInputsData: (state, action) => {
            state.dynamicInputsData = action.payload;
        },
        setConfig: (state, action) => {
            state.config = action.payload;
        }
    },
});

export const { setFormData, setCurrentStep, setSelectionHistory, setSelectionHistoryIds, setShapesData, setConectionsData, setDynamicInputsData, setConfig } = renewalsTreeReducer.actions;
export const selectFormData = state => state.renewalsTree.FormData;
export const selectCurrentStep = state => state.renewalsTree.currentStep;
export const selectSelectionHistory = state => state.renewalsTree.selectionHistory;
export const selectSelectionHistoryIds = state => state.renewalsTree.selectionHistoryIds;
export const selectShapesData = state => state.renewalsTree.shapesData;
export const selectConectionsData = state => state.renewalsTree.conectionsData;
export const selectDynamicInputsData = state => state.renewalsTree.dynamicInputsData;
export const selectConfig = state => state.renewalsTree.config;

export default renewalsTreeReducer.reducer;
