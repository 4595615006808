import {
	displayDate,
	parseAdjust,
	customModelFields,
} from './ExtractDta';
import {
	Scheduler,
	TimelineView,
	WeekView,
	MonthView,
	WorkWeekView,
	SchedulerEditItem,
} from '@progress/kendo-react-scheduler';
import {guid} from '@progress/kendo-react-common';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Loader} from '@progress/kendo-react-indicators';
import {
  changeIsLoadingAllActivities,
	selectAllActivities,
	selectLoadingActivities,
	thunkExtractActivities,
} from '../../redux/TimeReportingSlice';
import {Day} from '@progress/kendo-date-math';
import axios from 'axios';
import {FormWithCustomEditor} from '../TimeReporting/custom-form';
import CustomSnackbar from '../Notification/SnackBar';
export const SchedulerV2 = () => {


	let activities = useSelector(selectAllActivities)?.map((dataItem) => ({
		...dataItem,
		Start: parseAdjust(dataItem.Start),
		End: parseAdjust(dataItem.End),
	}));

	const isloadingActivities = useSelector(selectLoadingActivities);
	const dispatch = useDispatch();
	const email = sessionStorage.getItem('usr');
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [messageSnackBar, setMessageSnackBar] = useState(null)
	const [typeSnackBar, setTypeSnackBar] = useState("success")

	const handleOpenSnackbar = () => {
		setSnackbarOpen(true);
	  };
	
	  const handleCloseSnackbar = () => {
		setSnackbarOpen(false);
	  };

	useEffect(() => {
		dispatch(thunkExtractActivities(email));
	}, [dispatch, email]);

	const handleDataChange = async ({ created, updated, deleted }) => {
    dispatch(changeIsLoadingAllActivities(true))
	let message=''
    
    try {
        if (created[0] !== undefined) {
            message=await axios.post(`/api/TimeReporting?id=${email}`, created[0]);
			
        } else if (deleted[0] !== undefined) {
            message=await axios.delete(`/api/TRActivity?id=${email}`, { data: deleted[0] });
        } else if (updated[0] !== undefined) {
          if('TituloActividad' in updated[0]){//acá se vuelve a un if debido a que el EditForm trae claves diferentes a como lo trae el update cuando se mueve el horario, mejor dicho, hay dos objetos diferentes
            message=await axios.put(`/api/TimeReporting?id=${email}`, updated[0]);//aqui hay que hacer resto de arreglos
          }else{
            message=await axios.post(`/api/TRActivity?id=${email}`, updated[0]);//acá casi nada
          }

        }
		setMessageSnackBar(message.data.message)
		handleOpenSnackbar()
        // Despachar después de las operaciones
        dispatch(thunkExtractActivities(email));
    } catch (error) {
        console.error("Error en operación CRUD", error);
		setTypeSnackBar("error")
		setMessageSnackBar("Hubo un error, no pudimos completar la petición")
		handleOpenSnackbar()
		dispatch(thunkExtractActivities(email));
    }
};

	if (isloadingActivities) {
		return (
			<div>
					<Loader
						style={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%,-50%)',
						}}
						size="large"
						type={'converging-spinner'}
					/>
					<span>
						<p>Cargando Actividades...</p>
					</span>
				</div>
		);
	}

	return (
		<>
		<Scheduler
			data={activities}
			timezone={'America/Bogota'}
			onDataChange={handleDataChange}
			defaultView="week"
			height={820}
			editable={true}
			defaultDate={displayDate}
			modelFields={customModelFields}
			form={FormWithCustomEditor}>
			<WorkWeekView
				title="Work Week"
				workWeekStart={Day.Monday}
				workWeekEnd={Day.Friday}
				startTime={"06:00"}
				endTime={"19:00"}
				workDayStart={"08:00"}
				workDayEnd={"18:00"}
			/>
		</Scheduler>
		<CustomSnackbar
        open={snackbarOpen}
        handleClose={handleCloseSnackbar}
        message={messageSnackBar}
        severity={typeSnackBar}
      />
		</>
	);
};
