export const responseData = {
    "headcount": [
        {
            "_id": "4000",
            "Agente": "4000",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "larr4000@siigo.com",
            "Lider": "John Mario Perez Diaz",
            "Nombre": "Amparo Larrota Cely"
        },
        {
            "_id": "800580",
            "Agente": "800580",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "muno800580@siigo.com",
            "Lider": "Yessica Yulieth Quintero Martinez",
            "Nombre": "Estefany Rocio Muñoz Rojas"
        },
        {
            "_id": "185336",
            "Agente": "185336",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "eraz185336@siigo.com",
            "Lider": "Yeimy Milena Bustos Gutierrez",
            "Nombre": "Yamileth Erazo Agreda"
        },
        {
            "_id": "31411",
            "Agente": "31411",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "Carm31411@siigo.com",
            "Lider": "Sandra Catalina Suarez Castillo",
            "Nombre": "Natalia Carmona Escobar"
        },
        {
            "_id": "800019",
            "Agente": "800019",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "frad100019@siigo.com",
            "Lider": "Milady Andrea Guzman Garcia",
            "Nombre": "Luisa Fernanda Frade Martinez"
        },
        {
            "_id": "108113",
            "Agente": "108113",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mari108113@siigo.com",
            "Lider": "Leida Adriana Rico Herrera",
            "Nombre": "Santiago Marin Raba"
        },
        {
            "_id": "800499",
            "Agente": "800499",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "carr800499@siigo.com",
            "Lider": "Fernando Mario Paez Tovar",
            "Nombre": "Fabian David Carreño Leon"
        },
        {
            "_id": "111524",
            "Agente": "111524",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "segu111524@siigo.com",
            "Lider": "Juan Sebastian Molina Linares",
            "Nombre": "Liliana Andrea Segura Martinez"
        },
        {
            "_id": "584",
            "Agente": "584",
            "Area": "Product",
            "Division": "Siigo Mexico",
            "Email": "r_lopez@aspel.com.mx",
            "Lider": "Oscar Francisco Plaza Giler",
            "Nombre": "Reyna Lopez Perez"
        },
        {
            "_id": "112513",
            "Agente": "112513",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cano112513@siigo.com",
            "Lider": "Leidy Stefany Castillo 0",
            "Nombre": "Lizeth Jhojana Cano Jimenez"
        },
        {
            "_id": "800801",
            "Agente": "800801",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vill800801@siigo.com",
            "Lider": "Adriana Elizabeth Giron Parra",
            "Nombre": "Paula Andrea Villarraga Forero"
        },
        {
            "_id": "800351",
            "Agente": "800351",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cont800351@siigo.com",
            "Lider": "Gustavo Adolfo Espinosa Toledo",
            "Nombre": "Walter David Contreras Martinez"
        },
        {
            "_id": "800173",
            "Agente": "800173",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "moli800173@siigo.com",
            "Lider": "Vicky Alejandra Hernandez Mendez",
            "Nombre": "Maria Fernanda Molina Ramirez"
        },
        {
            "_id": "1877",
            "Agente": "1877",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ablanco@aspel.com.mx",
            "Lider": "Gerardo Marquez Perez",
            "Nombre": "Adair Blanco Landin"
        },
        {
            "_id": "801465",
            "Agente": "801465",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rodr801465@siigo.com",
            "Lider": "Jessica Paola Paez Quintero",
            "Nombre": "Jorge Andres Rodriguez Anchique"
        },
        {
            "_id": "800949",
            "Agente": "800949",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cast800949@siigo.com",
            "Lider": "Diana Maria Naranjo Mattheus",
            "Nombre": "Juan Sebastian Castellanos Prieto"
        },
        {
            "_id": "802145",
            "Agente": "802145",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ball802145@siigo.com",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre": "Yesmy Alejandra Ballesta Tous"
        },
        {
            "_id": "800226",
            "Agente": "800226",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "sica800226@siigo.com",
            "Lider": "Carlos Javier Mendez Vera",
            "Nombre": "Juan Diego Sicacha Cortes"
        },
        {
            "_id": "802459",
            "Agente": "802459",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ruiz802459@siigo.com",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre": "Daniela Johana Ruiz Torres"
        },
        {
            "_id": "801661",
            "Agente": "801661",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "roab801651@siigo.com",
            "Lider": "Juan Carlos Valdes Martin",
            "Nombre": "Angela Maria Roa Betancourt"
        },
        {
            "_id": "112512",
            "Agente": "112512",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "fern112512@siigo.com",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre": "Efrain Fernandez Trujillo"
        },
        {
            "_id": "803602",
            "Agente": "803602",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gonz803602@siigo.com",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre": "Yorleinis Melissa Gonzalez Rivas"
        },
        {
            "_id": "801026",
            "Agente": "801026",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "roja801026@siigo.com",
            "Lider": "Diana Yaneth Espitia Acevedo",
            "Nombre": "Harold Esteban Rojas Rangel"
        },
        {
            "_id": "3247",
            "Agente": "3247",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "acalzada@aspel.com.mx",
            "Lider": "Marisela Yasmin Franco Cruz",
            "Nombre": "Aldo Uriel Calzada Vargas"
        },
        {
            "_id": "3462",
            "Agente": "3462",
            "Area": "People & Culture",
            "Division": "Siigo Mexico",
            "Email": "xcabanas@aspel.com.mx",
            "Lider": "Laura Alejandra Rios Jaramillo",
            "Nombre": "Ximena Cabañas Ramírez"
        },
        {
            "_id": "111909",
            "Agente": "111909",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "lope111909@siigo.com",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre": "Carlos Andres Lopera 0"
        },
        {
            "_id": "801408",
            "Agente": "801408",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "narv801408@siigo.com",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre": "Erika Marcela Narváez Barco"
        },
        {
            "_id": "804162",
            "Agente": "804162",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "fran804162@siigo.com",
            "Lider": "Juvenal Reyes Silva",
            "Nombre": "Damaris Cilena Franco Sanabria"
        },
        {
            "_id": "801084",
            "Agente": "801084",
            "Area": "Marketing",
            "Division": "Siigo Uruguay",
            "Email": "olaz801084@siigo.com",
            "Lider": "Harrison Martinez Callejas",
            "Nombre": "Andrea Teresita Olazabal Casales"
        },
        {
            "_id": "1602",
            "Agente": "1602",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "kescoto@aspel.com.mx",
            "Lider": "Liliana Ramirez Vanegas",
            "Nombre": "Karla Nora Paola Escoto Perez"
        },
        {
            "_id": "2633",
            "Agente": "2633",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "avega@aspel.com.mx",
            "Lider": "Rene Gerardo Paredes Alcantara",
            "Nombre": "Adrian Vargas Vega"
        },
        {
            "_id": "2200",
            "Agente": "2200",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ireyes@aspel.com.mx",
            "Lider": "Marisol Vargas Cortes",
            "Nombre": "Sergio Iván Valdez Reyes"
        },
        {
            "_id": "112220",
            "Agente": "112220",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cont112220@siigo.com",
            "Lider": "Jefferson Arvey Ramirez Ibañez",
            "Nombre": "Jennifer Tatiana Contreras Mendoza"
        },
        {
            "_id": "803343",
            "Agente": "803343",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "palm803343@siigo.com",
            "Lider": "Fabian David Carreño Leon",
            "Nombre": "Alexander Palma Hernandez"
        },
        {
            "_id": "230669",
            "Agente": "230669",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "Zuni230669@siigo.com",
            "Lider": "Jesus Dario Sanchez Nava",
            "Nombre": "Jhon Emmanuel Zuñiga Paredes"
        },
        {
            "_id": "800136",
            "Agente": "800136",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gome800136@siigo.com",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre": "Hillary Cristina Gomez Pineda"
        },
        {
            "_id": "802839",
            "Agente": "802839",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vera802839@siigo.com",
            "Lider": "Hernan Dario Parra Osorio",
            "Nombre": "Julieth Paola Vera Patiño"
        },
        {
            "_id": "111822",
            "Agente": "111822",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "quir111822@siigo.com",
            "Lider": "Juliana Castro Diaz",
            "Nombre": "Yeimy Alejandra Quiroga Rodriguez"
        },
        {
            "_id": "800603",
            "Agente": "800603",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "barr800603@siigo.com",
            "Lider": "Juan Francisco Lavayen Iñamagua",
            "Nombre": "Janna Elizabeth Barreto Castro"
        },
        {
            "_id": "3380",
            "Agente": "3380",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jcasco@aspel.com.mx",
            "Lider": "Ivan Alejandro Roa Franco",
            "Nombre": "Jannet Casco Flores"
        },
        {
            "_id": "1507",
            "Agente": "1507",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "cku@aspel.com.mx",
            "Lider": "Sara Noemi Euan Madera",
            "Nombre": "Carmen Del Sagrario Ku Yah"
        },
        {
            "_id": "800787",
            "Agente": "800787",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "varg800787@siigo.com",
            "Lider": "Diana Sofia Ramos Castillo",
            "Nombre": "Rafael Eduardo Vargas Agamez"
        },
        {
            "_id": "801742",
            "Agente": "801742",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "eraz801742@siigo.com",
            "Lider": "Natalia Andrea Contreras Mejia",
            "Nombre": "Leydi Rocio Erazo Paruma"
        },
        {
            "_id": "2660",
            "Agente": "2660",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "lcanul@aspel.com.mx",
            "Lider": "Rodrigo Sanchez Godinez",
            "Nombre": "Lucia Yolanda Canul Yam"
        },
        {
            "_id": "800527",
            "Agente": "800527",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "muri800527@siigo.com",
            "Lider": "Cristian Camilo Hernandez Gonzalez",
            "Nombre": "Jenny Julieth Murillo Palacios"
        },
        {
            "_id": "6288",
            "Agente": "6288",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sanc6288@siigo.com",
            "Lider": "Yessica Yulieth Quintero Martinez",
            "Nombre": "Carlos Eduardo Sanchez Castaño"
        },
        {
            "_id": "802277",
            "Agente": "802277",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "pine802277@siigo.com",
            "Lider": "Milady Andrea Guzman Garcia",
            "Nombre": "Yenny Rocio Pineda Gonzalez"
        },
        {
            "_id": "112211",
            "Agente": "112211",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "jime112211@siigo.com",
            "Lider": "Nataly Carolina Gonzalez Sandoval",
            "Nombre": "Richard Eduardo Jimenez Vergara"
        },
        {
            "_id": "801009",
            "Agente": "801009",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "monc801009@siigo.com",
            "Lider": "Eddie Steven Hurtado Herrera",
            "Nombre": "Sergio Xavier Moncayo Pionce"
        },
        {
            "_id": "17506",
            "Agente": "17506",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "garc17506@siigo.com",
            "Lider": "Saul Steven Carpio Ochoa",
            "Nombre": "Yesika Viviana Garcia Niño"
        },
        {
            "_id": "800387",
            "Agente": "800387",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "corr800387@siigo.com",
            "Lider": "Erika Mildred Avila Olaya",
            "Nombre": "Luisa Fernanda Corredor Gomez"
        },
        {
            "_id": "3411",
            "Agente": "3411",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "gperez@aspel.com.mx",
            "Lider": "Jose Luis Carranza Valdes",
            "Nombre": "Gustavo Alejandro Perez Martinez"
        },
        {
            "_id": "800486",
            "Agente": "800486",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "gall800486@siigo.com",
            "Lider": "Harrison Martinez Callejas",
            "Nombre": "Lizlley Andrea Gallego Orrego"
        },
        {
            "_id": "802126",
            "Agente": "802126",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vale802126@siigo.com",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre": "Azly Valencia Gonzalez"
        },
        {
            "_id": "802148",
            "Agente": "802148",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rami802148@siigo.com",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre": "Leydi Nataly Ramirez Barreto"
        },
        {
            "_id": "109927",
            "Agente": "109927",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "salg109927@siigo.com",
            "Lider": "Juan Carlos Buitrago Rios",
            "Nombre": "Claudia Ximena Salgado Castiblanco"
        },
        {
            "_id": "804368",
            "Agente": "804368",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "mart804368@siigo.com",
            "Lider": "Juan David Lopez Fuentes",
            "Nombre": "Manuel Santiago Martinez Benavidez"
        },
        {
            "_id": "801324",
            "Agente": "801324",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "zamb801324@siigo.com",
            "Lider": "Gustavo Adolfo Espinosa Toledo",
            "Nombre": "Claudia Milena Zambrano Pajoy"
        },
        {
            "_id": "4004",
            "Agente": "4004",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "Sanc4004@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Gloria Stella Sanchez Garzon"
        },
        {
            "_id": "27197",
            "Agente": "27197",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "soto27197@siigo.com",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre": "Oswaldo Moises Soto Ramirez"
        },
        {
            "_id": "112258",
            "Agente": "112258",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "casa112258@siigo.com",
            "Lider": "Natalia Herrera Rey",
            "Nombre": "Juan Diego Casallas Bernal"
        },
        {
            "_id": "112273",
            "Agente": "112273",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "viva112273@siigo.com",
            "Lider": "Juan Carlos Valdes Martin",
            "Nombre": "Alejandra Vanessa Vivas Vargas"
        },
        {
            "_id": "112147",
            "Agente": "112147",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "ruiz112147@siigo.com",
            "Lider": "Ivon Andrea Rios Rodriguez",
            "Nombre": "Laura Paola Ruiz Mejia"
        },
        {
            "_id": "2248",
            "Agente": "2248",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "vsanchez@aspel.com.mx",
            "Lider": "Rene Soriano Estrada",
            "Nombre": "Valeria Sanchez Horta"
        },
        {
            "_id": "800252",
            "Agente": "800252",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "herr800252@siigo.com",
            "Lider": "Nestor Oswaldo Becerra Prieto",
            "Nombre": "Diana Milena Herrera Rodriguez"
        },
        {
            "_id": "112269",
            "Agente": "112269",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "villa112269@siigo.com",
            "Lider": "Eliana Maria Fajardo Prada",
            "Nombre": "Ana Milena Villarraga Guasca"
        },
        {
            "_id": "112489",
            "Agente": "112489",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "nava112489@siigo.com",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre": "Orlando Elias Navarro Vasquez"
        },
        {
            "_id": "801301",
            "Agente": "801301",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "parr801301@siigo.com",
            "Lider": "William Jesus Sotaquira Ayala",
            "Nombre": "Hernan Dario Parra Osorio"
        },
        {
            "_id": "112051",
            "Agente": "112051",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "berm112051@siigo.com",
            "Lider": "Juan Carlos Sanchez Velez",
            "Nombre": "Luisa Fernanda Bermudez Rivera"
        },
        {
            "_id": "2755",
            "Agente": "2755",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "lbarrera@aspel.com.mx",
            "Lider": "Eliseo Lopez Cruz",
            "Nombre": "Leslie Guadalupe Barrera Roque"
        },
        {
            "_id": "802234",
            "Agente": "802234",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pint802234@siigo.com",
            "Lider": "Angy Paola Umbacia Diaz",
            "Nombre": "Michell Pintor Gutierrez"
        },
        {
            "_id": "1878",
            "Agente": "1878",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "aortega@aspel.com.mx",
            "Lider": "Alejandro Lopez Manzo",
            "Nombre": "Alma Cinthya Ortega Lomeli"
        },
        {
            "_id": "802309",
            "Agente": "802309",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "corr802309@siigo.com",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre": "Ana Maria Corro Altahona"
        },
        {
            "_id": "803447",
            "Agente": "803447",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "suar803447@siigo.com",
            "Lider": "Carol Andrea Cifuentes Alcala",
            "Nombre": "Fabian Alexander Suarez Beltran"
        },
        {
            "_id": "801439",
            "Agente": "801439",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cubi801439@siigo.com",
            "Lider": "Aleida Bibiana Garcia Suarez",
            "Nombre": "Magaly Cubillos Diaz"
        },
        {
            "_id": "800296",
            "Agente": "800296",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mend800296@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Marlon Frederick Mendoza Hernandez"
        },
        {
            "_id": "802195",
            "Agente": "802195",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "mont802195@siigo.com",
            "Lider": "Wilzon Camilo Liscano Galindo",
            "Nombre": "Alexis Montaña Ramirez"
        },
        {
            "_id": "112531",
            "Agente": "112531",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "sala112531@siigo.com",
            "Lider": "Diana Mercedes Moncada Peraza",
            "Nombre": "Mahycol Estivens Salazar Turmeque"
        },
        {
            "_id": "801113",
            "Agente": "801113",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "filq801113@siigo.com",
            "Lider": "Ivan Elias Jusid Machado",
            "Nombre": "Mauro Filgueira Pennacino"
        },
        {
            "_id": "3216",
            "Agente": "3216",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "ricsanchez@aspel.com.mx",
            "Lider": "Marisela Yasmin Franco Cruz",
            "Nombre": "Ricardo Sanchez Perez"
        },
        {
            "_id": "216856",
            "Agente": "216856",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Esco216856@siigo.com",
            "Lider": "Leida Adriana Rico Herrera",
            "Nombre": "Ruby Andrea Escobar Caro"
        },
        {
            "_id": "3260",
            "Agente": "3260",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "efigueroa@aspel.com.mx",
            "Lider": "Marisela Yasmin Franco Cruz",
            "Nombre": "Eric Eduardo Figueroa Gonzalez"
        },
        {
            "_id": "3558",
            "Agente": "3558",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "ssalinas@aspel.com.mx",
            "Lider": "Santos Benjamin Martinez Martinez",
            "Nombre": "Sarai Carolina Salinas Ramirez"
        },
        {
            "_id": "111673",
            "Agente": "111673",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "gonz111673@siigo.com",
            "Lider": "Frankgel Ribely Machado Pantoja",
            "Nombre": "Angie Nataly Gonzalez Aya"
        },
        {
            "_id": "802089",
            "Agente": "802089",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pini802089@siigo.com",
            "Lider": "Jenny Paola Mendez 0",
            "Nombre": "Diana Andrea Pinilla Basto"
        },
        {
            "_id": "800881",
            "Agente": "800881",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "roja800881@siigo.com",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre": "Marianella Rojas Caicedo"
        },
        {
            "_id": "802791",
            "Agente": "802791",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "game802791@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Eduardo Luis Gamero Villegas"
        },
        {
            "_id": "803723",
            "Agente": "803723",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "sana803723@siigo.com",
            "Lider": "Helder Yesid Castrillon Cobo",
            "Nombre": "Yesid Giovanny Sanabria Orjuela"
        },
        {
            "_id": "800649",
            "Agente": "800649",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "rios800649@siigo.com",
            "Lider": "Angel Camilo Chavez Moreno",
            "Nombre": "Marcos Vicente Rios Castro"
        },
        {
            "_id": "4010",
            "Agente": "4010",
            "Area": "Country Management",
            "Division": "Siigo Perú",
            "Email": "Orti4010@siigo.com",
            "Lider": "Siigo Siigo ",
            "Nombre": "Ricardo Alejandro Ortiz Deulofeut"
        },
        {
            "_id": "111458",
            "Agente": "111458",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "espi111458@siigo.com",
            "Lider": "Diana Patricia Atehortua Castaño",
            "Nombre": "Diana Yaneth Espitia Acevedo"
        },
        {
            "_id": "803703",
            "Agente": "803703",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cast803703@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Edwin Fernando Castellanos Abril"
        },
        {
            "_id": "4136",
            "Agente": "4136",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pere4136@siigo.com",
            "Lider": "John Jairo Mejia Acevedo",
            "Nombre": "John Mario Perez Diaz"
        },
        {
            "_id": "803646",
            "Agente": "803646",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vane803646@siigo.com",
            "Lider": "Francy Julieth Viracacha Gutierrez",
            "Nombre": "Erika Regina Vanegas Pedraza"
        },
        {
            "_id": "111498",
            "Agente": "111498",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "sala111498@siigo.com",
            "Lider": "Edison Arley Plaza Marin",
            "Nombre": "Manuel Fernando Salazar Diaz"
        },
        {
            "_id": "38542",
            "Agente": "38542",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "tama38542@siigo.com",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre": "Nancy Mayerly Tamara Ochoa"
        },
        {
            "_id": "2225",
            "Agente": "2225",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "amontanez@aspel.com.mx",
            "Lider": "Reyna Gricelda Ruiz Medina",
            "Nombre": "Adrian Alberto Montañez Chan"
        },
        {
            "_id": "800377",
            "Agente": "800377",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rodr800377@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Manuel Elkin Rodriguez Bohorquez"
        },
        {
            "_id": "804576",
            "Agente": "804576",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "guti804576@siigo.com",
            "Lider": "Juliana Castro Diaz",
            "Nombre": "Sandra Milena Gutierrez Donoso"
        },
        {
            "_id": "803501",
            "Agente": "803501",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "arch803501@siigo.com",
            "Lider": "Juan Carlos Sanchez Velez",
            "Nombre": "Andres Felipe Archila Diaz"
        },
        {
            "_id": "800551",
            "Agente": "800551",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "pine800551@siigo.com",
            "Lider": "Juan Francisco Lavayen Iñamagua",
            "Nombre": "Angel Andres Pineda Alvarado"
        },
        {
            "_id": "800308",
            "Agente": "800308",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "varg800308@siigo.com",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre": "Juan Camilo Vargas Pelayo"
        },
        {
            "_id": "800294",
            "Agente": "800294",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "guti800294@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Piere Angelys Gutierrez Chica"
        },
        {
            "_id": "802134",
            "Agente": "802134",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "aria802134@siigo.com",
            "Lider": "Jose Luis Perales Dueñas",
            "Nombre": "Francy Katherine Arias Villabona"
        },
        {
            "_id": "800800",
            "Agente": "800800",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "vera800800@siigo.com",
            "Lider": "Diana Marcela Guzman Caamaño",
            "Nombre": "Maria Fernanda Vera Soriano"
        },
        {
            "_id": "802939",
            "Agente": "802939",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "nava802939@siigo.com",
            "Lider": "Cristhian David Niño Barrera",
            "Nombre": "Angela Tatiana Navarro Salinas"
        },
        {
            "_id": "801013",
            "Agente": "801013",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "taba801013@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Alvaro Andres Tabara Baque"
        },
        {
            "_id": "801050",
            "Agente": "801050",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vall801050@siigo.com",
            "Lider": "Cristian Camilo Hernandez Gonzalez",
            "Nombre": "Albert Andrey Vallejo "
        },
        {
            "_id": "800613",
            "Agente": "800613",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "vera800613@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Jimmy Roberto Vera Calderon"
        },
        {
            "_id": "801636",
            "Agente": "801636",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "silv801636@siigo.com",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre": "Yerlys Silva Polanco"
        },
        {
            "_id": "801535",
            "Agente": "801535",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "espi801535@siigo.com",
            "Lider": "Mayra Alexandra Gutierrez Rojas",
            "Nombre": "Leidy Yohana Espitia Acevedo"
        },
        {
            "_id": "802212",
            "Agente": "802212",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "huer802212@siigo.com",
            "Lider": "Eliana Maria Fajardo Prada",
            "Nombre": "Alisson Daniela Huertas Roldan"
        },
        {
            "_id": "801515",
            "Agente": "801515",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "mayo801515@siigo.com",
            "Lider": "Gustavo Adolfo Valencia Chaparro",
            "Nombre": "Maria Helena Mayorga Guevara"
        },
        {
            "_id": "801804",
            "Agente": "801804",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "carr801804@siigo.com",
            "Lider": "Mario Fernando Nuñez Santos",
            "Nombre": "Regina Sofia Carroll Arango"
        },
        {
            "_id": "111886",
            "Agente": "111886",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "alza111886@siigo.com",
            "Lider": "Oscar Francisco Plaza Giler",
            "Nombre": "Laura Melissa Alzate Diaz"
        },
        {
            "_id": "800883",
            "Agente": "800883",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "pesa800883@siigo.com",
            "Lider": "Eddie Steven Hurtado Herrera",
            "Nombre": "Xavier Alexis Pesantes Avilés"
        },
        {
            "_id": "804006",
            "Agente": "804006",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "asis804006@siigo.com",
            "Lider": "Andrea Catalina Vega Gomez",
            "Nombre": "Redy Barney Asis Torrecilla"
        },
        {
            "_id": "111937",
            "Agente": "111937",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "lope111937@siigo.com",
            "Lider": "John Mario Perez Diaz",
            "Nombre": "Yenni Patricia Lopez Arenas"
        },
        {
            "_id": "267382",
            "Agente": "267382",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "ayah267382@siigo.com",
            "Lider": "Lina Maria Buitrago Herrera",
            "Nombre": "Dairo Edilson Aya Hernandez"
        },
        {
            "_id": "239663",
            "Agente": "239663",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "fern239663@siigo.com",
            "Lider": "John Alexander Benavides Cobos",
            "Nombre": "Sonia Mabel Fernandez Calderon"
        },
        {
            "_id": "803532",
            "Agente": "803532",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rodr803532@siigo.com",
            "Lider": "Yeimy Milena Bustos Gutierrez",
            "Nombre": "Geraldine Rodriguez Ospina"
        },
        {
            "_id": "2125",
            "Agente": "2125",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "oestrada@aspel.com.mx",
            "Lider": "Adair Blanco Landin",
            "Nombre": "Omar Leonardo Estrada Pacheco"
        },
        {
            "_id": "802890",
            "Agente": "802890",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "lope802890@siigo.com",
            "Lider": "David Alfredo Sanchez Bastidas",
            "Nombre": "Luisa Fernanda Lopez Pinto"
        },
        {
            "_id": "2140",
            "Agente": "2140",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "mlopezl@aspel.com.mx",
            "Lider": "Rene Gerardo Paredes Alcantara",
            "Nombre": "Maria De La Luz Lopez Lopez"
        },
        {
            "_id": "800397",
            "Agente": "800397",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "carr800397@siigo.com",
            "Lider": "Diana Maria Naranjo Mattheus",
            "Nombre": "Angela Maria Carreño Arcila"
        },
        {
            "_id": "803748",
            "Agente": "803748",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "marq803748@siigo.com",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre": "Ismael De Jesus Marquez Velasquez"
        },
        {
            "_id": "239842",
            "Agente": "239842",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "Nune239842@siigo.com",
            "Lider": "Juan Pablo Henao Muñoz",
            "Nombre": "Jazbleydi Andrea Nunez Montoya"
        },
        {
            "_id": "550",
            "Agente": "550",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "mmendez@aspel.com.mx",
            "Lider": "William Jesus Sotaquira Ayala",
            "Nombre": "Maria Magdalena Mendez Romero"
        },
        {
            "_id": "803746",
            "Agente": "803746",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "melo803746@siigo.com",
            "Lider": "Glorizeth Ardila Cañon",
            "Nombre": "Diana Carolina Melo Jimenez"
        },
        {
            "_id": "112244",
            "Agente": "112244",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "arzu112244@siigo.com",
            "Lider": "Liliana Patricia Giraldo Gomez",
            "Nombre": "Jesus Daniel Arzuza Roman"
        },
        {
            "_id": "2963",
            "Agente": "2963",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "rchavez@aspel.com.mx",
            "Lider": "Alejandro Lopez Manzo",
            "Nombre": "Ricardo Chavez Garcia"
        },
        {
            "_id": "1164",
            "Agente": "1164",
            "Area": "People & Culture",
            "Division": "Siigo Mexico",
            "Email": "lrios@aspel.com.mx",
            "Lider": "Carlos Arturo Huertas Salgado",
            "Nombre": "Laura Alejandra Rios Jaramillo"
        },
        {
            "_id": "14989",
            "Agente": "14989",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "parr14989@siigo.com",
            "Lider": "Angela Yolima Bohada Arias",
            "Nombre": "Jorge Alberto Parra Rocha"
        },
        {
            "_id": "112186",
            "Agente": "112186",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "mend112186@siigo.com",
            "Lider": "Fernando Gutierrez Paramio",
            "Nombre": "Jairo Andres Mendieta 0"
        },
        {
            "_id": "800462",
            "Agente": "800462",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "reye800462@siigo.com",
            "Lider": "Carlos Arturo Huertas Salgado",
            "Nombre": "Lyda Patricia Reyes Pradilla"
        },
        {
            "_id": "801402",
            "Agente": "801402",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gamb801402@siigo.com",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre": "Linna Tatiana Gamboa Ramirez"
        },
        {
            "_id": "112442",
            "Agente": "112442",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rodr112442@siigo.com",
            "Lider": "Carlos Eduardo Sanchez Castaño",
            "Nombre": "Angel Stiven Rodriguez Montaño"
        },
        {
            "_id": "800362",
            "Agente": "800362",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "saba800362@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Yessenya Sabad Quesada"
        },
        {
            "_id": "802190",
            "Agente": "802190",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "boca802190@siigo.com",
            "Lider": "Miguel Oswaldo Velasquez Fragiel",
            "Nombre": "Fabian Andres Bocanegra Castillo"
        },
        {
            "_id": "2271",
            "Agente": "2271",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "agongora@aspel.com.mx",
            "Lider": "Gustavo Adolfo Villada Tapias",
            "Nombre": "Arumy Stefanny Uh Gongora"
        },
        {
            "_id": "802568",
            "Agente": "802568",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "bogo802568@siigo.com",
            "Lider": "Diana Carolina Perez Cerinza",
            "Nombre": "Camila Tatiana Bogotá Sanchez"
        },
        {
            "_id": "800167",
            "Agente": "800167",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gilm800167@siigo.com",
            "Lider": "Luz Angelica Torres Quintero",
            "Nombre": "Angie Paola Gil Moreira"
        },
        {
            "_id": "802492",
            "Agente": "802492",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bedo802492@siigo.com",
            "Lider": "Adriana Elizabeth Giron Parra",
            "Nombre": "Miguel Angel Bedoya Muñoz"
        },
        {
            "_id": "112484",
            "Agente": "112484",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bayo112484@siigo.com",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre": "Daniela Bayona Gaviria"
        },
        {
            "_id": "801434",
            "Agente": "801434",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cure801434@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Daniela Alexandra Cure Labao"
        },
        {
            "_id": "801056",
            "Agente": "801056",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "alva801056@siigo.com",
            "Lider": "Liliana Andrea Segura Martinez",
            "Nombre": "Juanita Alvarado Larrota"
        },
        {
            "_id": "802712",
            "Agente": "802712",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "rest802712@siigo.com",
            "Lider": "Juan Carlos Valdes Martin",
            "Nombre": "Lizeth Maria Restrepo Arboleda"
        },
        {
            "_id": "2416",
            "Agente": "2416",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "vdiaz@aspel.com.mx",
            "Lider": "Alma Rosa Romero Garcia",
            "Nombre": "Victor Alejandro Martinez Diaz"
        },
        {
            "_id": "802423",
            "Agente": "802423",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "suar802423@siigo.com",
            "Lider": "Luz Katerine Maya Mogollón",
            "Nombre": "Yiselt Nataly Suarez Mesa"
        },
        {
            "_id": "803588",
            "Agente": "803588",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "leon803588@siigo.com",
            "Lider": "Andres David Leon Guerra",
            "Nombre": "Laura Victoria Leon Casanova"
        },
        {
            "_id": "802687",
            "Agente": "802687",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "fran802687@siigo.com",
            "Lider": "Carlos Javier Mendez Vera",
            "Nombre": "Fabian Andres Franco Chica"
        },
        {
            "_id": "800642",
            "Agente": "800642",
            "Area": "Finance & Administration",
            "Division": "Siigo Ecuador",
            "Email": "arte800642@siigo.com",
            "Lider": "Aura Cristina Rodriguez Manrique",
            "Nombre": "Gabriela Lisbeth Arteaga Morante"
        },
        {
            "_id": "199139",
            "Agente": "199139",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "Rome199139@siigo.com",
            "Lider": "Martin Leonel Romero Vega",
            "Nombre": "Luis Arturo Romero Garzon"
        },
        {
            "_id": "800565",
            "Agente": "800565",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "sala800565@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Cinthya Veronica Salazar Arevalo"
        },
        {
            "_id": "112343",
            "Agente": "112343",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "rome112343@siigo.com",
            "Lider": "Milady Andrea Guzman Garcia",
            "Nombre": "Valery Jassiby Romero Tapias"
        },
        {
            "_id": "112505",
            "Agente": "112505",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vill112505@siigo.com",
            "Lider": "Juan Carlos Corazzo Santos",
            "Nombre": "Lilian Paola Villanueva Ospina"
        },
        {
            "_id": "801662",
            "Agente": "801662",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "roja801662@siigo.com",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre": "Daisy Dayana Rojas Bernal"
        },
        {
            "_id": "801393",
            "Agente": "801393",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "arob801393@siigo.com",
            "Lider": "Alcides Martin Salas Orozco",
            "Nombre": "Diego Arobba Corbo"
        },
        {
            "_id": "2038",
            "Agente": "2038",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "amesas@aspel.com.mx",
            "Lider": "Gabriel Chavez Carrera",
            "Nombre": "Adriana Mireya Mesas Galvan"
        },
        {
            "_id": "111856",
            "Agente": "111856",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "sanc111856@siigo.com",
            "Lider": "Liliana Patricia Giraldo Gomez",
            "Nombre": "David Alfredo Sanchez Bastidas"
        },
        {
            "_id": "801474",
            "Agente": "801474",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "sala801474@siigo.com",
            "Lider": "Juan Carlos Valdes Martin",
            "Nombre": "Juan Jose Salazar Acero"
        },
        {
            "_id": "803477",
            "Agente": "803477",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "garz803477@siigo.com",
            "Lider": "Julian Bernardo Fernandez Garcia",
            "Nombre": "Maria Paula Garzon Ortega"
        },
        {
            "_id": "109915",
            "Agente": "109915",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "bern109915@siigo.com",
            "Lider": "John Mario Perez Diaz",
            "Nombre": "Carol Michelle Bernal Ortega"
        },
        {
            "_id": "802325",
            "Agente": "802325",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pata802325@siigo.com",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre": "Manuel Elkin Patarroyo Herrera"
        },
        {
            "_id": "803315",
            "Agente": "803315",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "vela803315@siigo.com",
            "Lider": "Andres Felipe Baron Ballen",
            "Nombre": "Claudia Marlen Velandia Latorre"
        },
        {
            "_id": "1219",
            "Agente": "1219",
            "Area": "People & Culture",
            "Division": "Siigo Mexico",
            "Email": "cmiranda@aspel.com.mx",
            "Lider": "Laura Alejandra Rios Jaramillo",
            "Nombre": "Maria Del Carmen Miranda Yines"
        },
        {
            "_id": "800686",
            "Agente": "800686",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "villa800686@siigo.com",
            "Lider": "Alix Dayana Archila Castillo",
            "Nombre": "Wilson Javier Villamil Benitez"
        },
        {
            "_id": "803913",
            "Agente": "803913",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "guti803913@siigo.com",
            "Lider": "Ivon Andrea Rios Rodriguez",
            "Nombre": "Guisel Catherin Gutierrez Ramirez"
        },
        {
            "_id": "112462",
            "Agente": "112462",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "hern112462@siigo.com",
            "Lider": "Diana Sofia Ramos Castillo",
            "Nombre": "Viviana Patricia Hernandez Hurtado"
        },
        {
            "_id": "40340",
            "Agente": "40340",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rodr40340@siigo.com",
            "Lider": "Daniel Eduardo Pinto Cepeda",
            "Nombre": "Leidy Tatiana Rodriguez Blanco"
        },
        {
            "_id": "800714",
            "Agente": "800714",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "flor800714@siigo.com",
            "Lider": "Walter Antonio Muñoz Antepara",
            "Nombre": "Vladimir Ismael Flores Vera"
        },
        {
            "_id": "800590",
            "Agente": "800590",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "sevi800590@siigo.com",
            "Lider": "Nestor Oswaldo Becerra Prieto",
            "Nombre": "Ginger Jacqueline Sevilla Mendez"
        },
        {
            "_id": "802877",
            "Agente": "802877",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "guer802877@siigo.com",
            "Lider": "Diana Mercedes Moncada Peraza",
            "Nombre": "Yeison William Guerrero Suarez"
        },
        {
            "_id": "801892",
            "Agente": "801892",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "delg801892@siigo.com",
            "Lider": "Carlos Arturo Huertas Salgado",
            "Nombre": "Maria Fernanda Delgadillo Bernal"
        },
        {
            "_id": "3232",
            "Agente": "3232",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "lmelendez@aspel.com.mx",
            "Lider": "Luz Angelica Torres Quintero",
            "Nombre": "Libia Sanchez Melendez"
        },
        {
            "_id": "803336",
            "Agente": "803336",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rodr803336@siigo.com",
            "Lider": "Aleida Bibiana Garcia Suarez",
            "Nombre": "Jorge Andres Rodriguez Forero"
        },
        {
            "_id": "800651",
            "Agente": "800651",
            "Area": "Marketing",
            "Division": "Siigo Ecuador",
            "Email": "lope800651@siigo.com",
            "Lider": "Juan Carlos Valdes Martin",
            "Nombre": "Richard Javier Lopez Molina"
        },
        {
            "_id": "112216",
            "Agente": "112216",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "sali112216@siigo.com",
            "Lider": "Gustavo Adolfo Valencia Chaparro",
            "Nombre": "Melany Jeinary Salinas Moreno"
        },
        {
            "_id": "1212",
            "Agente": "1212",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "areyes@aspel.com.mx",
            "Lider": "Maira Elisa Ortiz Perez",
            "Nombre": "Aurea Maria Reyes Castillo"
        },
        {
            "_id": "803802",
            "Agente": "803802",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "lope803802@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "George Javier Lopez Sosa"
        },
        {
            "_id": "112490",
            "Agente": "112490",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "vela112490@siigo.com",
            "Lider": "Alma Rosa Romero Garcia",
            "Nombre": "Laura Vanessa Velasquez Olivar"
        },
        {
            "_id": "112096",
            "Agente": "112096",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gali112096@siigo.com",
            "Lider": "Angie Camila Diaz Escobar",
            "Nombre": "Jeimy Tatiana Galindo Mora"
        },
        {
            "_id": "3020",
            "Agente": "3020",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jdamian@aspel.com.mx",
            "Lider": "Marisol Vargas Cortes",
            "Nombre": "Yesica Guadalupe Garcia Damian"
        },
        {
            "_id": "111559",
            "Agente": "111559",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "lope111559@siigo.com",
            "Lider": "Yeimy Milena Bustos Gutierrez",
            "Nombre": "Leidy Johanna Lopez Rubio"
        },
        {
            "_id": "2716",
            "Agente": "2716",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "rpacab@aspel.com.mx",
            "Lider": "Rodrigo Sanchez Godinez",
            "Nombre": "Ricardo Raymundo Pacab Canul"
        },
        {
            "_id": "804054",
            "Agente": "804054",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "duqu804051@siigo.com",
            "Lider": "Laura Erika Utrera Utrera",
            "Nombre": "Adriana Marcela Duque "
        },
        {
            "_id": "2081",
            "Agente": "2081",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "sdzul@aspel.com.mx",
            "Lider": "César Augusto Rejón Vermont",
            "Nombre": "Suriel Alberto Dzul Chuc"
        },
        {
            "_id": "3498",
            "Agente": "3498",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "bdominguez@aspel.com.mx",
            "Lider": "Marcela Vanessa Hernandez Moreno",
            "Nombre": "Brenda Michel Dominguez Galavis"
        },
        {
            "_id": "801395",
            "Agente": "801395",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "arri801395@siigo.com",
            "Lider": "Ivan Elias Jusid Machado",
            "Nombre": "Martin Arrillaga Rodriguez"
        },
        {
            "_id": "111952",
            "Agente": "111952",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "loza111952@siigo.com",
            "Lider": "William Andres Reyes Silva",
            "Nombre": "Ricardo Javier Lozano Britto"
        },
        {
            "_id": "802525",
            "Agente": "802525",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gonz802525@siigo.com",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre": "Melanie Paola Gonzalez Rodriguez"
        },
        {
            "_id": "1887",
            "Agente": "1887",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "mortiz@aspel.com.mx",
            "Lider": "Miguel Angel Suárez Hernández",
            "Nombre": "Maira Elisa Ortiz Perez"
        },
        {
            "_id": "2281",
            "Agente": "2281",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ncouder@aspel.com.mx",
            "Lider": "Adair Blanco Landin",
            "Nombre": "Noe Couder Chiñas"
        },
        {
            "_id": "194335",
            "Agente": "194335",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Rico194335@siigo.com",
            "Lider": "Liliana Patricia Giraldo Gomez",
            "Nombre": "Leida Adriana Rico Herrera"
        },
        {
            "_id": "802269",
            "Agente": "802269",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "mora802269@siigo.com",
            "Lider": "Andrea Catalina Vega Gomez",
            "Nombre": "Lizeth Geraldine Morales Chacon"
        },
        {
            "_id": "800434",
            "Agente": "800434",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "sanc800434@siigo.com",
            "Lider": "Sandra Catalina Suarez Castillo",
            "Nombre": "Yeimy Liliana Sanchez Bahamon"
        },
        {
            "_id": "802055",
            "Agente": "802055",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rodr802055@siigo.com",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre": "Karen Daniela Rodriguez Torres"
        },
        {
            "_id": "800098",
            "Agente": "800098",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "alva800098@siigo.com",
            "Lider": "Emre Pehlivan 0",
            "Nombre": "Nathalia Alvaran Salazar"
        },
        {
            "_id": "802097",
            "Agente": "802097",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "anga802097@siigo.com",
            "Lider": "Yamid Ramiro Quintero Montaño",
            "Nombre": "Jenny Paola Angarita Perez"
        },
        {
            "_id": "111881",
            "Agente": "111881",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gome111881@siigo.com",
            "Lider": "Manuel Andres Solano Duran",
            "Nombre": "Angie Johanna Gomez Lopez"
        },
        {
            "_id": "801279",
            "Agente": "801279",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "nava801279@siigo.com",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre": "Luz Neyla Navas Paredes"
        },
        {
            "_id": "112421",
            "Agente": "112421",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "boca112421@siigo.com",
            "Lider": "Natalia Catherine Triviño Cortes",
            "Nombre": "Narley Esperanza Bocanegra Riaño"
        },
        {
            "_id": "3365",
            "Agente": "3365",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "cdelgado@aspel.com.mx",
            "Lider": "Luis Alejandro Salgado Vergara",
            "Nombre": "César Jesús Delgado Tellez"
        },
        {
            "_id": "801045",
            "Agente": "801045",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "casa801045@siigo.com",
            "Lider": "Yeimy Milena Bustos Gutierrez",
            "Nombre": "Cristian Fabian Casas Rodriguez"
        },
        {
            "_id": "13613",
            "Agente": "13613",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "faja13613@siigo.com",
            "Lider": "John Alexander Benavides Cobos",
            "Nombre": "Eliana Maria Fajardo Prada"
        },
        {
            "_id": "263607",
            "Agente": "263607",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sott263607@siigo.com",
            "Lider": "Juan Pablo Henao Muñoz",
            "Nombre": "Fernando Sotto Cardozo"
        },
        {
            "_id": "803895",
            "Agente": "803895",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "acer803895@siigo.com",
            "Lider": "Jefferson Arvey Ramirez Ibañez",
            "Nombre": "Jhonatan Stiven Acero Romero"
        },
        {
            "_id": "1782",
            "Agente": "1782",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "jgutierrez@aspel.com.mx",
            "Lider": "María de Jesús Pérez Sanabria",
            "Nombre": "Juan Pablo Gutierrez Trujillo"
        },
        {
            "_id": "802439",
            "Agente": "802439",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "yust802439@siigo.com",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre": "David Steven Yusti Buitrago"
        },
        {
            "_id": "112286",
            "Agente": "112286",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "corr112286@siigo.com",
            "Lider": "Jose Daniel Huerfano Martinez",
            "Nombre": "Astrid Viviana Corredor Herreño"
        },
        {
            "_id": "800060",
            "Agente": "800060",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "vela800060@siigo.com",
            "Lider": "Ahylin Dahyan Jamaica Mendez",
            "Nombre": "Ingrid Viviana Velasquez Vargas"
        },
        {
            "_id": "800045",
            "Agente": "800045",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "corr800045@siigo.com",
            "Lider": "Sandra Catalina Suarez Castillo",
            "Nombre": "Sandra Maricela Corredor Caina"
        },
        {
            "_id": "800523",
            "Agente": "800523",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "torr800523@siigo.com",
            "Lider": "Juan Pablo Henao Muñoz",
            "Nombre": "Angie Nicoll Torres Celemin"
        },
        {
            "_id": "1502",
            "Agente": "1502",
            "Area": "Product",
            "Division": "Siigo Mexico",
            "Email": "rdominguez@aspel.com.mx",
            "Lider": "Reyna Lopez Perez",
            "Nombre": "Rodrigo Garzon Dominguez"
        },
        {
            "_id": "2623",
            "Agente": "2623",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "mgarcia@aspel.com.mx",
            "Lider": "Jonathan Oviedo Gonzalez",
            "Nombre": "Maria Del Rosario Garcia Mendez"
        },
        {
            "_id": "800185",
            "Agente": "800185",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "triv800185@siigo.com",
            "Lider": "Martha Isabel Diaz Gomez",
            "Nombre": "Karen Dayanna Triviño Miranda"
        },
        {
            "_id": "800925",
            "Agente": "800925",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "ospi800925@siigo.com",
            "Lider": "Diego Armando Alzate Rios",
            "Nombre": "Erika Yinneth Ospicio Ramirez"
        },
        {
            "_id": "800562",
            "Agente": "800562",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "sesm800562@siigo.com",
            "Lider": "Astrid Gisel Duque Rico",
            "Nombre": "Carlos Anthony Sesme Vera"
        },
        {
            "_id": "112257",
            "Agente": "112257",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "medi112257@siigo.com",
            "Lider": "Luz Andrea Sanchez Chacon",
            "Nombre": "Silvia Vanessa Medina Mendez"
        },
        {
            "_id": "800064",
            "Agente": "800064",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "belt800064@siigo.com",
            "Lider": "Adriana Elizabeth Giron Parra",
            "Nombre": "Jose Alejandro Beltran Amaya"
        },
        {
            "_id": "223509",
            "Agente": "223509",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cast223509@siigo.com",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre": "Ana Consuelo Castro Mendoza"
        },
        {
            "_id": "800063",
            "Agente": "800063",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "abel800063@siigo.com",
            "Lider": "Yessica Yulieth Quintero Martinez",
            "Nombre": "Sory Yaqueline Abello Polo"
        },
        {
            "_id": "800784",
            "Agente": "800784",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "suar800784@siigo.com",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre": "Jenny Johanna Suarez Gomez"
        },
        {
            "_id": "800707",
            "Agente": "800707",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "vern800707@siigo.com",
            "Lider": "Diana Michelle Matamoros Del Rosario",
            "Nombre": "Johany Jazmin Vernaza Vargas"
        },
        {
            "_id": "801150",
            "Agente": "801150",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rian801150@siigo.com",
            "Lider": "Jose Enrique Rangel Trujillo",
            "Nombre": "John Jairo Riaño Martinez"
        },
        {
            "_id": "801037",
            "Agente": "801037",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "barr801037@siigo.com",
            "Lider": "Yamid Ramiro Quintero Montaño",
            "Nombre": "Ailyn Del Carmen De las Aguas Barrios"
        },
        {
            "_id": "110115",
            "Agente": "110115",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "reye110115@siigo.com",
            "Lider": "Liliana Patricia Giraldo Gomez",
            "Nombre": "Juvenal Reyes Silva"
        },
        {
            "_id": "801811",
            "Agente": "801811",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "rubi801811@siigo.com",
            "Lider": "Gina Estefania Melo Sanchez",
            "Nombre": "Oscar Daniel Rubio Romero"
        },
        {
            "_id": "802000",
            "Agente": "802000",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "velo802000@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Dana Victoria Veloz Garcia"
        },
        {
            "_id": "800039",
            "Agente": "800039",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "yate800039@siigo.com",
            "Lider": "Andres Felipe Baron Ballen",
            "Nombre": "Rodrigo Yate Villalobos"
        },
        {
            "_id": "800573",
            "Agente": "800573",
            "Area": "Marketing",
            "Division": "Siigo Ecuador",
            "Email": "meji800573@siigo.com",
            "Lider": "Magda de Jesús Becerra Castañeda",
            "Nombre": "Diana Yomira Mejia Herrera"
        },
        {
            "_id": "801674",
            "Agente": "801674",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "zamb801674@siigo.com",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre": "Jose Armel Zambrano Trujillo"
        },
        {
            "_id": "109687",
            "Agente": "109687",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "carr109687@siigo.com",
            "Lider": "Yenny Milena Lopez Florez",
            "Nombre": "Juan Pablo Carreño Patiño"
        },
        {
            "_id": "801377",
            "Agente": "801377",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "hern801377@siigo.com",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre": "Cristhian David Hernandez Jimenez"
        },
        {
            "_id": "800893",
            "Agente": "800893",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "carr800893@siigo.com",
            "Lider": "Jessica Paola Paez Quintero",
            "Nombre": "Wendy Johanna Carreño Molina"
        },
        {
            "_id": "803490",
            "Agente": "803490",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "maru803490@siigo.com",
            "Lider": "Marcelo Botero Gomez",
            "Nombre": "Jhonny Alejandro Marulanda Valencia"
        },
        {
            "_id": "1546",
            "Agente": "1546",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "amujica@aspel.com.mx",
            "Lider": "Rene Soriano Estrada",
            "Nombre": "Alejandra Mujica Flores"
        },
        {
            "_id": "800579",
            "Agente": "800579",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "here800579@siigo.com",
            "Lider": "Alvin Diaz Guerrero",
            "Nombre": "Estefania Vanessa Heredia Jimenez"
        },
        {
            "_id": "800366",
            "Agente": "800366",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bece800366@siigo.com",
            "Lider": "Nestor Oswaldo Becerra Prieto",
            "Nombre": "Harold Sebastian Becerra Pacheco"
        },
        {
            "_id": "803351",
            "Agente": "803351",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "beja803351@siigo.com",
            "Lider": "Natalia Maria Mora Rios",
            "Nombre": "Nestor Augusto Bejarano Morales"
        },
        {
            "_id": "803629",
            "Agente": "803629",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "lope803629@siigo.com",
            "Lider": "Jesus Daniel Arzuza Roman",
            "Nombre": "Rosaura Katherine Lopez Garcia"
        },
        {
            "_id": "112337",
            "Agente": "112337",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "bece112337@siigo.com",
            "Lider": "Leida Adriana Rico Herrera",
            "Nombre": "Omar Gerardo Becerra Dominguez"
        },
        {
            "_id": "804195",
            "Agente": "804195",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cant804195@siigo.com",
            "Lider": "Yuleidy Josefina Briceño Fernandez",
            "Nombre": "Katherinne Vanessa Cantor Ceballos"
        },
        {
            "_id": "800287",
            "Agente": "800287",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "arbo800287@siigo.com",
            "Lider": "Yamid Ramiro Quintero Montaño",
            "Nombre": "Daniela Arboleda Alvarez"
        },
        {
            "_id": "803206",
            "Agente": "803206",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "vela803206@siigo.com",
            "Lider": "Marcelo Botero Gomez",
            "Nombre": "Anni Melissa Velandia Echeverry"
        },
        {
            "_id": "629",
            "Agente": "629",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ifraga@aspel.com.mx",
            "Lider": "Adair Blanco Landin",
            "Nombre": "Claudia Ivonne Fraga Chavez"
        },
        {
            "_id": "800241",
            "Agente": "800241",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "mola800241@siigo.com",
            "Lider": "Lina Maria Buitrago Herrera",
            "Nombre": "Angie Natalia Molano Moreno"
        },
        {
            "_id": "802119",
            "Agente": "802119",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mari802119@siigo.com",
            "Lider": "Carlos Mario Espinosa Fernandez",
            "Nombre": "Natalia Marinez Gonzalez"
        },
        {
            "_id": "2084",
            "Agente": "2084",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "sdelarosa@aspel.com.mx",
            "Lider": "Fernando Contreras Ocaña",
            "Nombre": "Saul Jonathan De la Rosa Blanco"
        },
        {
            "_id": "801985",
            "Agente": "801985",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "carr801985@siigo.com",
            "Lider": "Yesika Viviana Garcia Niño",
            "Nombre": "Silvia Melissa Carrillo Parada"
        },
        {
            "_id": "800474",
            "Agente": "800474",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rico800474@siigo.com",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre": "Gustavo Alberto Rico Ruiz"
        },
        {
            "_id": "800310",
            "Agente": "800310",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rive800310@siigo.com",
            "Lider": "Carlos Eduardo Sanchez Castaño",
            "Nombre": "Sthephanie Nathalia Rivera Cabrera"
        },
        {
            "_id": "111857",
            "Agente": "111857",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "buit111857@siigo.com",
            "Lider": "Lina Maria Buitrago Herrera",
            "Nombre": "Victor Alexis Buitrago Garay"
        },
        {
            "_id": "17218",
            "Agente": "17218",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "Pati17218@siigo.com",
            "Lider": "Felipe Ricardo Reyes Lega",
            "Nombre": "Luz Mery Patino Infante"
        },
        {
            "_id": "802272",
            "Agente": "802272",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "guzm802272@siigo.com",
            "Lider": "Miguel Ricardo Julio Babilonia",
            "Nombre": "Diego Alejandro Guzman Moncada"
        },
        {
            "_id": "801114",
            "Agente": "801114",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "rodr801114@siigo.com",
            "Lider": "Alcides Martin Salas Orozco",
            "Nombre": "Maximiliano Ariel Rodriguez Deangelis"
        },
        {
            "_id": "800398",
            "Agente": "800398",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "card800398@siigo.com",
            "Lider": "Gerardo Enrique Aldecua Matamoros",
            "Nombre": "Jonathan Andres Cardenas Pabón"
        },
        {
            "_id": "800627",
            "Agente": "800627",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "marq800627@siigo.com",
            "Lider": "Miguel Oswaldo Velasquez Fragiel",
            "Nombre": "Victor Gabriel Marquez Veliz"
        },
        {
            "_id": "802084",
            "Agente": "802084",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "more802084@siigo.com",
            "Lider": "Ivan Dario Corchuelo Castro",
            "Nombre": "Gustavo Adolfo Moreno Muñoz"
        },
        {
            "_id": "801590",
            "Agente": "801590",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rami801590@siigo.com",
            "Lider": "Diego Andres Montoya Blandon",
            "Nombre": "Hector Fabio Ramirez Franco"
        },
        {
            "_id": "801242",
            "Agente": "801242",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "bena801242@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Maria Isabel Benavides Cedeño"
        },
        {
            "_id": "804009",
            "Agente": "804009",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "fons804009@siigo.com",
            "Lider": "Marcelo Botero Gomez",
            "Nombre": "Jhon Alexander Fonseca Garzon"
        },
        {
            "_id": "112126",
            "Agente": "112126",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "hern112126@siigo.com",
            "Lider": "Daniel Eduardo Pinto Cepeda",
            "Nombre": "Jordan Hernandez Villegas"
        },
        {
            "_id": "800569",
            "Agente": "800569",
            "Area": "Finance & Administration",
            "Division": "Siigo Ecuador",
            "Email": "pinc800569@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Dariany Yelena Pincay Coronel"
        },
        {
            "_id": "2058",
            "Agente": "2058",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "evelazquez@aspel.com.mx",
            "Lider": "Rene Gerardo Paredes Alcantara",
            "Nombre": "Eduardo Velazquez Cruz"
        },
        {
            "_id": "33172",
            "Agente": "33172",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "estr33172@siigo.com",
            "Lider": "Juvenal Reyes Silva",
            "Nombre": "Juan Carlos Estrada Chacon"
        },
        {
            "_id": "803771",
            "Agente": "803771",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "roja803771@siigo.com",
            "Lider": "Wilson Medina Nuñez",
            "Nombre": "Yudy Paola Rojas Rojas"
        },
        {
            "_id": "800585",
            "Agente": "800585",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "rome800585@siigo.com",
            "Lider": "Wilson Medina Nuñez",
            "Nombre": "Gabriel Alexander Romero Mora"
        },
        {
            "_id": "1156",
            "Agente": "1156",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "ccalva@aspel.com.mx",
            "Lider": "Fernando Villasana Gutierrez",
            "Nombre": "Carlos Calva Toledo"
        },
        {
            "_id": "800524",
            "Agente": "800524",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "avil800524@siigo.com",
            "Lider": "Angy Paola Umbacia Diaz",
            "Nombre": "Yennifer Nicoll Avila Infante"
        },
        {
            "_id": "2014",
            "Agente": "2014",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "fjavier@aspel.com.mx",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Francisco Javier Vazquez Hernandez"
        },
        {
            "_id": "1853",
            "Agente": "1853",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "dflores@aspel.com.mx",
            "Lider": "Fernando Villasana Gutierrez",
            "Nombre": "Ricardo Daniel Flores Medina"
        },
        {
            "_id": "800292",
            "Agente": "800292",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "aria800292@siigo.com",
            "Lider": "Alix Dayana Archila Castillo",
            "Nombre": "Brayan Andres Arias Silva"
        },
        {
            "_id": "800750",
            "Agente": "800750",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "burb800750@siigo.com",
            "Lider": "Anyeliz Beatriz Rico Torres",
            "Nombre": "Carlos Andres Burbano Palechor"
        },
        {
            "_id": "802271",
            "Agente": "802271",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "vill802271@siigo.com",
            "Lider": "Andrea Catalina Nieto Fernandez",
            "Nombre": "Juanita Villamizar Garnica"
        },
        {
            "_id": "800172",
            "Agente": "800172",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "tave800172@siigo.com",
            "Lider": "Jefferson Arvey Ramirez Ibañez",
            "Nombre": "Carlos Alfredo Tavera Tamayo"
        },
        {
            "_id": "802720",
            "Agente": "802720",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "bern802720@siigo.com",
            "Lider": "Jaime Andres Aguilar Rivera",
            "Nombre": "Jennifer Alexandra Bernal Pineda"
        },
        {
            "_id": "800236",
            "Agente": "800236",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "guac800236@siigo.com",
            "Lider": "Paola Denisse Veloz Garcia",
            "Nombre": "Angela Maria Guacheta Ibarra"
        },
        {
            "_id": "110322",
            "Agente": "110322",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "torr110322@siigo.com",
            "Lider": "Melany Cristal ",
            "Nombre": "Angie Katherine Torres Alvarez"
        },
        {
            "_id": "801727",
            "Agente": "801727",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gome801727@siigo.com",
            "Lider": "Yeimy Milena Bustos Gutierrez",
            "Nombre": "Laura Camila Gomez Cuellar"
        },
        {
            "_id": "800410",
            "Agente": "800410",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "pere800410@siigo.com",
            "Lider": "Francy Idaly Muelas Muelas",
            "Nombre": "Laura Yolima Perez Cabezas"
        },
        {
            "_id": "800183",
            "Agente": "800183",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "mora800183@siigo.com",
            "Lider": "Carlos German Lopez Murcia",
            "Nombre": "Julian Ernesto Morales Garzon"
        },
        {
            "_id": "21002",
            "Agente": "21002",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gran21002@siigo.com",
            "Lider": "Juan Carlos Buitrago Rios",
            "Nombre": "Aracely del Pilar Granizo Muñoz"
        },
        {
            "_id": "800092",
            "Agente": "800092",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mora800092@siigo.com",
            "Lider": "Juan Carlos Buitrago Rios",
            "Nombre": "Yizeth Paola Mora Barco"
        },
        {
            "_id": "111808",
            "Agente": "111808",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "meji111808@siigo.com",
            "Lider": "Esteban Tellez Garcia",
            "Nombre": "John Jairo Mejia Acevedo"
        },
        {
            "_id": "800906",
            "Agente": "800906",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rico800906@siigo.com",
            "Lider": "Andres Felipe Baron Ballen",
            "Nombre": "Paola Rico Escobar"
        },
        {
            "_id": "800505",
            "Agente": "800505",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gome800505@siigo.com",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre": "Luna Nicol Gomez Carvajal"
        },
        {
            "_id": "800696",
            "Agente": "800696",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pard800696@siigo.com",
            "Lider": "Carlos Eduardo Sanchez Castaño",
            "Nombre": "Jeimmy Janeth Pardo Guerrero"
        },
        {
            "_id": "112304",
            "Agente": "112304",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rodr112304@siigo.com",
            "Lider": "Hernan Dario Parra Osorio",
            "Nombre": "Silvya Fernanda Rodriguez Niño"
        },
        {
            "_id": "112508",
            "Agente": "112508",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "agam112508@siigo.com",
            "Lider": "Mario Alberto Rojas Gamboa",
            "Nombre": "Esteban de Jesus Agamez Montero"
        },
        {
            "_id": "111975",
            "Agente": "111975",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "guti111975@siigo.com",
            "Lider": "Juan Diego Ortiz Orozco",
            "Nombre": "Andres Gutierrez Bello"
        },
        {
            "_id": "3297",
            "Agente": "3297",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "enanco@aspel.com.mx",
            "Lider": "Jose Luis Carranza Valdes",
            "Nombre": "Erick Rodriguez Nanco"
        },
        {
            "_id": "800139",
            "Agente": "800139",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "yepe800139@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "David Felipe Yepes Sanchez"
        },
        {
            "_id": "190245",
            "Agente": "190245",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "Roja190245@siigo.com",
            "Lider": "Jesus Dario Sanchez Nava",
            "Nombre": "Mario Alberto Rojas Gamboa"
        },
        {
            "_id": "20098",
            "Agente": "20098",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "torr20098@siigo.com",
            "Lider": "Juan Pablo Carreño Patiño",
            "Nombre": "Yamile Patricia Torres Palacio"
        },
        {
            "_id": "1324",
            "Agente": "1324",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "vramirez@aspel.com.mx",
            "Lider": "Miguel Oswaldo Velasquez Fragiel",
            "Nombre": "Virginia Guadalupe Ramirez Hernandez"
        },
        {
            "_id": "112333",
            "Agente": "112333",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "rinc112333@siigo.com",
            "Lider": "Lina Maria Buitrago Herrera",
            "Nombre": "Andres Felipe Rincon Delgado"
        },
        {
            "_id": "804018",
            "Agente": "804018",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cerp804018@siigo.com",
            "Lider": "Juan Pablo Carreño Patiño",
            "Nombre": "Angie Cerpa Meza"
        },
        {
            "_id": "110018",
            "Agente": "110018",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "leon110018@siigo.com",
            "Lider": "Luis Felipe Zea Obando",
            "Nombre": "Yudy Natali Leon Barrera"
        },
        {
            "_id": "800539",
            "Agente": "800539",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "salg800539@siigo.com",
            "Lider": "David Alfredo Sanchez Bastidas",
            "Nombre": "Olga Lucia Salgado Salamanca"
        },
        {
            "_id": "112210",
            "Agente": "112210",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vira112210@siigo.com",
            "Lider": "John Alexander Benavides Cobos",
            "Nombre": "Francy Julieth Viracacha Gutierrez"
        },
        {
            "_id": "803719",
            "Agente": "803719",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gual803719@siigo.com",
            "Lider": "Diana Michelle Matamoros Del Rosario",
            "Nombre": "Liliana Gualteros Alfonso"
        },
        {
            "_id": "800583",
            "Agente": "800583",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "masa800583@siigo.com",
            "Lider": "Angie Camila Diaz Escobar",
            "Nombre": "Gabriela Solange Masacela Cabrera"
        },
        {
            "_id": "803726",
            "Agente": "803726",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rued803726@siigo.com",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre": "Sonia Rosa Rueda Gomez"
        },
        {
            "_id": "800058",
            "Agente": "800058",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gavi800058@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Yord Fredy Gaviria Garcia"
        },
        {
            "_id": "2245",
            "Agente": "2245",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "jaramirez@aspel.com.mx",
            "Lider": "Brandon Enrique Gomez Rosales",
            "Nombre": "Jesus Alejandro Ramirez Diaz"
        },
        {
            "_id": "112246",
            "Agente": "112246",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rive112246@siigo.com",
            "Lider": "Cesar Augusto Torres Benavides",
            "Nombre": "Joan Manuel Rivera Guerrero"
        },
        {
            "_id": "112097",
            "Agente": "112097",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "muno112097@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Erika Mariana Muñoz Peña"
        },
        {
            "_id": "4262",
            "Agente": "4262",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "Suar4262@siigo.com",
            "Lider": "Manuel Enrique Ramirez Rodriguez",
            "Nombre": "Miguel Angel Suárez Hernández"
        },
        {
            "_id": "800605",
            "Agente": "800605",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "vele800605@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Jean Carlo Velez Palacios"
        },
        {
            "_id": "801694",
            "Agente": "801694",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "yate801694@siigo.com",
            "Lider": "Gloria Estela Osorio Montoya",
            "Nombre": "Dana Camila Yate Sarmiento"
        },
        {
            "_id": "800016",
            "Agente": "800016",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mend800016@siigo.com",
            "Lider": "Diana Sofia Ramos Castillo",
            "Nombre": "Jhoanna Mendivelso Gavidia"
        },
        {
            "_id": "800180",
            "Agente": "800180",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "segu800180@siigo.com",
            "Lider": "Paola Cely Prada",
            "Nombre": "Luis Eduardo Segura Melo"
        },
        {
            "_id": "802256",
            "Agente": "802256",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "jime802256@siigo.com",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre": "Alix Dayanna Jimenez Lemus"
        },
        {
            "_id": "801588",
            "Agente": "801588",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "niet801588@siigo.com",
            "Lider": "Nataly Carolina Gonzalez Sandoval",
            "Nombre": "Juan Felipe Nieto Abril"
        },
        {
            "_id": "800286",
            "Agente": "800286",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "medi800286@siigo.com",
            "Lider": "Diana Sofia Ramos Castillo",
            "Nombre": "Michael Enrique Medina Poveda"
        },
        {
            "_id": "800340",
            "Agente": "800340",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "garn800340@siigo.com",
            "Lider": "David Alfredo Sanchez Bastidas",
            "Nombre": "Maribel Garnica Lopez"
        },
        {
            "_id": "800290",
            "Agente": "800290",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "diaz800290@siigo.com",
            "Lider": "Claudia Patricia Estevez Alvarez",
            "Nombre": "Diego Fernando Diaz Corredor"
        },
        {
            "_id": "112354",
            "Agente": "112354",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cabe112354@siigo.com",
            "Lider": "Adriana Elizabeth Giron Parra",
            "Nombre": "Paula Andrea Cabezas Pardo"
        },
        {
            "_id": "31608",
            "Agente": "31608",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Quin31608@siigo.com",
            "Lider": "Jesus Daniel Arzuza Roman",
            "Nombre": "Shirley Margarita Quintero Olivares"
        },
        {
            "_id": "803734",
            "Agente": "803734",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "varg803734@siigo.com",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre": "Cesar Wilfrido Vargas Romero"
        },
        {
            "_id": "801970",
            "Agente": "801970",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "larc801970@siigo.com",
            "Lider": "Walter Antonio Muñoz Antepara",
            "Nombre": "Carlos Luis Larco Cabrera"
        },
        {
            "_id": "3583",
            "Agente": "3583",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "dgarcia@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Victor Daniel Garcia Torres"
        },
        {
            "_id": "2173",
            "Agente": "2173",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "nestrada@aspel.com.mx",
            "Lider": "Adair Blanco Landin",
            "Nombre": "Nydia Michelle Estrada Romero"
        },
        {
            "_id": "111847",
            "Agente": "111847",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "avil111847@siigo.com",
            "Lider": "William Andres Reyes Silva",
            "Nombre": "Narly Johana Avila Olaya"
        },
        {
            "_id": "800656",
            "Agente": "800656",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "maya800656@siigo.com",
            "Lider": "Andres Alberto Gomez Baño",
            "Nombre": "Ricardo David Maya Herrera"
        },
        {
            "_id": "111888",
            "Agente": "111888",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "garc111888@siigo.com",
            "Lider": "Hollman Antonio Aponte Quiroga",
            "Nombre": "Aleida Bibiana Garcia Suarez"
        },
        {
            "_id": "800352",
            "Agente": "800352",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sala800352@siigo.com",
            "Lider": "Natalia Marinez Gonzalez",
            "Nombre": "Alcides Martin Salas Orozco"
        },
        {
            "_id": "112523",
            "Agente": "112523",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mant112523@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Daniela Yizeth Mantilla Lizarazo"
        },
        {
            "_id": "3021",
            "Agente": "3021",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "mjfernandez@aspel.com.mx",
            "Lider": "Carlos Maximo Garrido Mascott",
            "Nombre": "Maria Jose Palma Fernandez"
        },
        {
            "_id": "801766",
            "Agente": "801766",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "reye801766@siigo.com",
            "Lider": "Giovanni Baquero Hernandez",
            "Nombre": "Angelica Maritza Reyes Castro"
        },
        {
            "_id": "800576",
            "Agente": "800576",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "lope800576@siigo.com",
            "Lider": "Giovanni Baquero Hernandez",
            "Nombre": "Claudy Lorena Lopez Carmona"
        },
        {
            "_id": "800072",
            "Agente": "800072",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "orju800072@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Monica Daniela Orjuela Mayorga"
        },
        {
            "_id": "215127",
            "Agente": "215127",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "boha215127@siigo.com",
            "Lider": "Ana Rita Ortiz Herrera",
            "Nombre": "Angela Yolima Bohada Arias"
        },
        {
            "_id": "2505",
            "Agente": "2505",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "lrodriguez@aspel.com.mx",
            "Lider": "Pedro Arturo Rico Martinez",
            "Nombre": "Lizbeth Lopez Rodriguez"
        },
        {
            "_id": "800798",
            "Agente": "800798",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pard800798@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Leidy Katerin Pardo Garavito"
        },
        {
            "_id": "802343",
            "Agente": "802343",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "alva802343@siigo.com",
            "Lider": "Diana Michelle Matamoros Del Rosario",
            "Nombre": "Jasson Adonis Alvarado Mosquera"
        },
        {
            "_id": "800702",
            "Agente": "800702",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "roja800702@siigo.com",
            "Lider": "Fernando Mario Paez Tovar",
            "Nombre": "David Julian Rojas Prado"
        },
        {
            "_id": "803921",
            "Agente": "803921",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "aran803921@siigo.com",
            "Lider": "David Julian Rojas Prado",
            "Nombre": "Cesar Eduardo Aranda Pinilla"
        },
        {
            "_id": "802141",
            "Agente": "802141",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "varg802141@siigo.com",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre": "Salma Daniela Vargas Tocora"
        },
        {
            "_id": "111743",
            "Agente": "111743",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "roar111743@siigo.com",
            "Lider": "David Felipe Penagos Mosquera",
            "Nombre": "Luis Felipe Roa Rosero"
        },
        {
            "_id": "801385",
            "Agente": "801385",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "orti801385@siigo.com",
            "Lider": "Edith Angelica Gil Umbarila",
            "Nombre": "Paula Andrea Ortiz Rodriguez"
        },
        {
            "_id": "112355",
            "Agente": "112355",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "manr112355@siigo.com",
            "Lider": "Lina Maria Buitrago Herrera",
            "Nombre": "Elyana Manrique Chaparro"
        },
        {
            "_id": "4055",
            "Agente": "4055",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "Corz4055@siigo.com",
            "Lider": "Luis Felipe Zea Obando",
            "Nombre": "Jairo Enrique Corzo Lizarazo"
        },
        {
            "_id": "801732",
            "Agente": "801732",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mart801732@siigo.com",
            "Lider": "Yeimy Milena Bustos Gutierrez",
            "Nombre": "Nijhireth Dagnimanegzy Martinez Campos"
        },
        {
            "_id": "802426",
            "Agente": "802426",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "pere802426@siigo.com",
            "Lider": "Aura Cristina Rodriguez Manrique",
            "Nombre": "Yuneris Perez Rodriguez"
        },
        {
            "_id": "4770",
            "Agente": "4770",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "Bece4770@siigo.com",
            "Lider": "Jarbin Dadey Rodriguez Peña",
            "Nombre": "Magda de Jesús Becerra Castañeda"
        },
        {
            "_id": "802663",
            "Agente": "802663",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "sang802663@siigo.com",
            "Lider": "Ivan Elias Jusid Machado",
            "Nombre": "Camila Claren Sanguinetti Casas"
        },
        {
            "_id": "801483",
            "Agente": "801483",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "torr801483@siigo.com",
            "Lider": "Johanna Patricia Torres Quintero",
            "Nombre": "Rosa Camila Torres Galvis"
        },
        {
            "_id": "29511",
            "Agente": "29511",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "Flor29511@siigo.com",
            "Lider": "Lina Maria Buitrago Herrera",
            "Nombre": "Sergio Arturo Florez Jimenez"
        },
        {
            "_id": "801265",
            "Agente": "801265",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "bara801265@siigo.com",
            "Lider": "Luz Mery Patino Infante",
            "Nombre": "Ginna Patricia Barajas Diaz"
        },
        {
            "_id": "801603",
            "Agente": "801603",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "naic801603@siigo.com",
            "Lider": "Diego Andres Montoya Blandon",
            "Nombre": "Jefry Esteban Naicipa Ortiz"
        },
        {
            "_id": "803648",
            "Agente": "803648",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cale803648@siigo.com",
            "Lider": "Francy Julieth Viracacha Gutierrez",
            "Nombre": "Juan Sebastian Calero Gil"
        },
        {
            "_id": "800661",
            "Agente": "800661",
            "Area": "Finance & Administration",
            "Division": "Siigo Ecuador",
            "Email": "coel800661@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Priscilla Alexandra Coello Delgado"
        },
        {
            "_id": "4031",
            "Agente": "4031",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "Bogo4031@siigo.com",
            "Lider": "Manuel Enrique Ramirez Rodriguez",
            "Nombre": "Hector Orlando Bogoya Aguilar"
        },
        {
            "_id": "258875",
            "Agente": "258875",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Quiz258875@siigo.com",
            "Lider": "Daniel Eduardo Pinto Cepeda",
            "Nombre": "Erika Maria Quiza Morera"
        },
        {
            "_id": "111988",
            "Agente": "111988",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "meji111988@siigo.com",
            "Lider": "David Rosero Calle",
            "Nombre": "Oscar Daniel Mejia Canaval"
        },
        {
            "_id": "802421",
            "Agente": "802421",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "espi802421@siigo.com",
            "Lider": "Sory Yaqueline Abello Polo",
            "Nombre": "Yenifer Edilsa Espitia Acevedo"
        },
        {
            "_id": "1859",
            "Agente": "1859",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "jbernal@aspel.com.mx",
            "Lider": "Abraham Octavio Rendon Cruz",
            "Nombre": "Juan Carlos Bernal Mendoza"
        },
        {
            "_id": "800549",
            "Agente": "800549",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "choe800549@siigo.com",
            "Lider": "Diana Sofia Ramos Castillo",
            "Nombre": "Andrea Stefania Choez Reyes"
        },
        {
            "_id": "800343",
            "Agente": "800343",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "duar800343@siigo.com",
            "Lider": "Adriana Elizabeth Giron Parra",
            "Nombre": "Maria Helena Duarte Rugeles"
        },
        {
            "_id": "2278",
            "Agente": "2278",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "mcabrera@aspel.com.mx",
            "Lider": "Laura Erika Utrera Utrera",
            "Nombre": "Mario Eliseo Cabrera Durand"
        },
        {
            "_id": "802273",
            "Agente": "802273",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mond802273@siigo.com",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre": "Harold Mondragón Jaramillo"
        },
        {
            "_id": "801206",
            "Agente": "801206",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "rein801206@siigo.com",
            "Lider": "Cesar Augusto Torres Benavides",
            "Nombre": "Edison Isaias Reinoso Medina"
        },
        {
            "_id": "800491",
            "Agente": "800491",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "monc800491@siigo.com",
            "Lider": "Cristian Camilo Hernandez Gonzalez",
            "Nombre": "Evelyn Dayana Moncada Diaz"
        },
        {
            "_id": "197251",
            "Agente": "197251",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "Roja197251@siigo.com",
            "Lider": "Wilson Medina Nuñez",
            "Nombre": "Mary Stella Rojas Brausin"
        },
        {
            "_id": "802442",
            "Agente": "802442",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cruz802442@siigo.com",
            "Lider": "Sory Yaqueline Abello Polo",
            "Nombre": "Roy Cruz Garcia"
        },
        {
            "_id": "800313",
            "Agente": "800313",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "garc800313@siigo.com",
            "Lider": "Hollman Antonio Aponte Quiroga",
            "Nombre": "Paula Alejandra Garcia Lujan"
        },
        {
            "_id": "15301",
            "Agente": "15301",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "Quin15301@siigo.com",
            "Lider": "Martin Leonel Romero Vega",
            "Nombre": "Nataly Quintana Bernal"
        },
        {
            "_id": "802168",
            "Agente": "802168",
            "Area": "Finance & Administration",
            "Division": "Siigo Ecuador",
            "Email": "vane802168@siigo.com",
            "Lider": "Aura Cristina Rodriguez Manrique",
            "Nombre": "Luis Alberto Vanegas Vasquez"
        },
        {
            "_id": "802245",
            "Agente": "802245",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "camp802245@siigo.com",
            "Lider": "Luz Katerine Maya Mogollón",
            "Nombre": "Alejandro Campos Arango"
        },
        {
            "_id": "108590",
            "Agente": "108590",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cifu108590@siigo.com",
            "Lider": "José Manuel Aguayo Escandon",
            "Nombre": "Carol Andrea Cifuentes Alcala"
        },
        {
            "_id": "109442",
            "Agente": "109442",
            "Area": "Customer Success",
            "Division": "Siigo Perú",
            "Email": "Lope109442@siigo.com",
            "Lider": "Ricardo Alejandro Ortiz Deulofeut",
            "Nombre": "Jessica Consuelo Lopez Castillo"
        },
        {
            "_id": "801723",
            "Agente": "801723",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "segu801723@siigo.com",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Andres Segura Martinez"
        },
        {
            "_id": "801172",
            "Agente": "801172",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rios801172@siigo.com",
            "Lider": "Yuleidy Josefina Briceño Fernandez",
            "Nombre": "Angel Eduardo Rios Nieto"
        },
        {
            "_id": "800212",
            "Agente": "800212",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "buit800212@siigo.com",
            "Lider": "Jarbin Dadey Rodriguez Peña",
            "Nombre": "Lina Maria Buitrago Herrera"
        },
        {
            "_id": "801367",
            "Agente": "801367",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "barb801367@siigo.com",
            "Lider": "Diana Milena Roa Hurtado",
            "Nombre": "Estefania Sofia Barbosa Fajardo"
        },
        {
            "_id": "801101",
            "Agente": "801101",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "cent801101@siigo.com",
            "Lider": "Ivan Elias Jusid Machado",
            "Nombre": "Jonny Centrone 0"
        },
        {
            "_id": "803916",
            "Agente": "803916",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cast803916@siigo.com",
            "Lider": "Alcides Martin Salas Orozco",
            "Nombre": "Karen Valentina Castellanos Muñoz"
        },
        {
            "_id": "800587",
            "Agente": "800587",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "muri800587@siigo.com",
            "Lider": "Cesar Augusto Torres Benavides",
            "Nombre": "Geancarlo Murillo Mendoza"
        },
        {
            "_id": "803604",
            "Agente": "803604",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "utri803604@siigo.com",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre": "Sheyla Mercedes Utria Gomez"
        },
        {
            "_id": "239051",
            "Agente": "239051",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "comb239051@siigo.com",
            "Lider": "Juan Pablo Carreño Patiño",
            "Nombre": "Ninza Aidee Combita Nunez"
        },
        {
            "_id": "801837",
            "Agente": "801837",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "torr801837@siigo.com",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre": "Luz Elena Torres Baslanoa"
        },
        {
            "_id": "804569",
            "Agente": "804569",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "estr804569@siigo.com",
            "Lider": "Angel Alfonso Arciniegas Guerrero",
            "Nombre": "Jonathan Alfonso Estrada Vega"
        },
        {
            "_id": "800609",
            "Agente": "800609",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "gaib800609@siigo.com",
            "Lider": "Jhon Emmanuel Zuñiga Paredes",
            "Nombre": "Jesus Amado Gaibor Yanez"
        },
        {
            "_id": "800248",
            "Agente": "800248",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gelv800248@siigo.com",
            "Lider": "Yamid Ramiro Quintero Montaño",
            "Nombre": "Maryury Tatiana Gelves Ariza"
        },
        {
            "_id": "110038",
            "Agente": "110038",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ramo110038@siigo.com",
            "Lider": "Diego Armando Aristizabal Bedoya",
            "Nombre": "Diana Sofia Ramos Castillo"
        },
        {
            "_id": "802845",
            "Agente": "802845",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "artu802845@siigo.com",
            "Lider": "Santiago Jose Porras Brieva",
            "Nombre": "Cindy Joan Artunduaga Muñoz"
        },
        {
            "_id": "802645",
            "Agente": "802645",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "jiovanna.botello@siigo.com",
            "Lider": "Juan Carlos Corazzo Santos",
            "Nombre": "Jiovanna Gisela Botello Rivera"
        },
        {
            "_id": "25642",
            "Agente": "25642",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "lope25642@siigo.com",
            "Lider": "Marcos Damian Lizarraga Elizalde",
            "Nombre": "Cesar Eduardo Lopez Suescun"
        },
        {
            "_id": "803784",
            "Agente": "803784",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "monr803784@siigo.com",
            "Lider": "Juan Pablo Carreño Patiño",
            "Nombre": "Nelcy Yurany Monroy Becerra"
        },
        {
            "_id": "802427",
            "Agente": "802427",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "herr802427@siigo.com",
            "Lider": "Yesika Viviana Garcia Niño",
            "Nombre": "Valentina Herrera Sierra"
        },
        {
            "_id": "802627",
            "Agente": "802627",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "corr802627@siigo.com",
            "Lider": "Diana Maria Naranjo Mattheus",
            "Nombre": "Juan Sebastian Correa Sierra"
        },
        {
            "_id": "803658",
            "Agente": "803658",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "herr803658@siigo.com",
            "Lider": "Juliana Castro Diaz",
            "Nombre": "Yuwi Tatiana Herrera Garzon"
        },
        {
            "_id": "804531",
            "Agente": "804531",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "alva804531@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Oscar David Alvarez Niebles"
        },
        {
            "_id": "804538",
            "Agente": "804538",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gome804538@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Nicolas Mateo Gomez Parra"
        },
        {
            "_id": "803848",
            "Agente": "803848",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "arro8036848@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Jackson Richard Arroba Pluas"
        },
        {
            "_id": "803779",
            "Agente": "803779",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mont803779@siigo.com",
            "Lider": "Jenny Paola Mendez 0",
            "Nombre": "Ana Virgelina Montoya Castañeda"
        },
        {
            "_id": "804073",
            "Agente": "804073",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bola804073@siigo.com",
            "Lider": "Yamid Ramiro Quintero Montaño",
            "Nombre": "Carmen Elizabeth Bolaños 0"
        },
        {
            "_id": "800928",
            "Agente": "800928",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ospi800928@siigo.com",
            "Lider": "Yuleidy Josefina Briceño Fernandez",
            "Nombre": "Sindy Lorena Ospitia Gonzalez"
        },
        {
            "_id": "3390",
            "Agente": "3390",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ecuellar@aspel.com.mx",
            "Lider": "Ivan Alejandro Roa Franco",
            "Nombre": "Emilio Cuellar Arroyo"
        },
        {
            "_id": "2576",
            "Agente": "2576",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jleal@aspel.com.mx",
            "Lider": "Gabriel Chavez Carrera",
            "Nombre": "Jesús Francisco Silva Leal"
        },
        {
            "_id": "801592",
            "Agente": "801592",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "huer801592@siigo.com",
            "Lider": "David Ortiz Diaz",
            "Nombre": "Carlos Arturo Huertas Salgado"
        },
        {
            "_id": "801964",
            "Agente": "801964",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "altu801964@siigo.com",
            "Lider": "Johanna Patricia Torres Quintero",
            "Nombre": "Maria Paula Alturo Ramirez"
        },
        {
            "_id": "801407",
            "Agente": "801407",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "delv801407@siigo.com",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre": "Maria Paula Del Villar De la Hoz"
        },
        {
            "_id": "803449",
            "Agente": "803449",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cely803449@siigo.com",
            "Lider": "Diana Michelle Matamoros Del Rosario",
            "Nombre": "Erika Maritza Cely Amaya"
        },
        {
            "_id": "802545",
            "Agente": "802545",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "gome802545@siigo.com",
            "Lider": "Luis Alfredo Leon Britto",
            "Nombre": "Julian Andres Gomez Urrutia"
        },
        {
            "_id": "800423",
            "Agente": "800423",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ruiz800423@siigo.com",
            "Lider": "John Mario Perez Diaz",
            "Nombre": "Betty Yisel Ruiz Parra"
        },
        {
            "_id": "802048",
            "Agente": "802048",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rive802048@siigo.com",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre": "Juan Sebastian Rivera Chavez"
        },
        {
            "_id": "112026",
            "Agente": "112026",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "ateh112026@siigo.com",
            "Lider": "Luisa Fernanda Caro Camargo",
            "Nombre": "Diana Patricia Atehortua Castaño"
        },
        {
            "_id": "801539",
            "Agente": "801539",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "fete801539@siigo.com",
            "Lider": "David Alfredo Sanchez Bastidas",
            "Nombre": "Ana Milena Fetecua Rojas"
        },
        {
            "_id": "802495",
            "Agente": "802495",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bola802495@siigo.com",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre": "Jhon Sebastian Bolaño Rua"
        },
        {
            "_id": "112170",
            "Agente": "112170",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "toba112170@siigo.com",
            "Lider": "Cristian Andres Collazos Ruiz",
            "Nombre": "Jose Julio Tobar Cifuentes"
        },
        {
            "_id": "801669",
            "Agente": "801669",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "rodr801669@siigo.com",
            "Lider": "Andrea Catalina Vega Gomez",
            "Nombre": "Yennifer Rodriguez Avila"
        },
        {
            "_id": "112522",
            "Agente": "112522",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "varg112522@siigo.com",
            "Lider": "Cristian Camilo Hernandez Gonzalez",
            "Nombre": "Adriana Mercedes Vargas Gonzalez"
        },
        {
            "_id": "802548",
            "Agente": "802548",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "vasq802548@siigo.com",
            "Lider": "Juan David Lopez Fuentes",
            "Nombre": "Edison Antonio Vasquez Burbano"
        },
        {
            "_id": "803636",
            "Agente": "803636",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "chav803636@siigo.com",
            "Lider": "Juan Carlos Buitrago Rios",
            "Nombre": "Diego Enrique De Jesus Chaves Rosas"
        },
        {
            "_id": "802595",
            "Agente": "802595",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "mart802595@siigo.com",
            "Lider": "Ivan Elias Jusid Machado",
            "Nombre": "Jimena Martiarena Schenck"
        },
        {
            "_id": "803833",
            "Agente": "803833",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mart803833@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Jhon Mario Martinez Tordecilla"
        },
        {
            "_id": "26101",
            "Agente": "26101",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "mend26101@siigo.com",
            "Lider": "Jimena Marcela Prieto Bohorquez",
            "Nombre": "Yenifer Mendez Cardenas"
        },
        {
            "_id": "803364",
            "Agente": "803364",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "roja803364@siigo.com",
            "Lider": "Andrea Catalina Vega Gomez",
            "Nombre": "Mary Anyel Rojano Salas"
        },
        {
            "_id": "803752",
            "Agente": "803752",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "monr803752@siigo.com",
            "Lider": "Maria Paula Garzon Ortega",
            "Nombre": "Laura Fernanda Monroy Blanco"
        },
        {
            "_id": "111552",
            "Agente": "111552",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "arev111552@siigo.com",
            "Lider": "William Andres Reyes Silva",
            "Nombre": "Brahian Camilo Arevalo Barbosa"
        },
        {
            "_id": "804186",
            "Agente": "804186",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "esqu804186@siigo.com",
            "Lider": "Aleida Bibiana Garcia Suarez",
            "Nombre": "Lineys Patricia Esquea De"
        },
        {
            "_id": "803352",
            "Agente": "803352",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mart803352@siigo.com",
            "Lider": "Aleida Bibiana Garcia Suarez",
            "Nombre": "Esteban David Martinez Castañeda"
        },
        {
            "_id": "32604",
            "Agente": "32604",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Baqu32604@siigo.com",
            "Lider": "Magda Lilian Leal Rozo",
            "Nombre": "Giovanni Baquero Hernandez"
        },
        {
            "_id": "803432",
            "Agente": "803432",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rinc803432@siigo.com",
            "Lider": "Manuel Fernando Zambrano Ceron",
            "Nombre": "Nelson Eduardo Rincon Cubillos"
        },
        {
            "_id": "803624",
            "Agente": "803624",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "jara803624@siigo.com",
            "Lider": "Marcos Damian Lizarraga Elizalde",
            "Nombre": "Diego Felipe Jaramillo Alvarez"
        },
        {
            "_id": "800662",
            "Agente": "800662",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "espi800662@siigo.com",
            "Lider": "Francisco Jose Bohorquez Torres",
            "Nombre": "Nécker Iván Espinosa Feijóo"
        },
        {
            "_id": "802143",
            "Agente": "802143",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ozae802143@siigo.com",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre": "Luisa Fernanda Ozaeta Rojas"
        },
        {
            "_id": "801381",
            "Agente": "801381",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rodr801381@siigo.com",
            "Lider": "Eliana Maria Fajardo Prada",
            "Nombre": "Sindy Alejandra Rodriguez Giraldo"
        },
        {
            "_id": "800817",
            "Agente": "800817",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vela800817@siigo.com",
            "Lider": "Yeimy Milena Bustos Gutierrez",
            "Nombre": "Valerye Alejandra Velandia Reyes"
        },
        {
            "_id": "802070",
            "Agente": "802070",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "quit802070@siigo.com",
            "Lider": "Johana Catalina Peña ",
            "Nombre": "Harryson Steven Quitian Figueroa"
        },
        {
            "_id": "801881",
            "Agente": "801881",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "roma801881@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Paula Andrea Romaña Julio"
        },
        {
            "_id": "802135",
            "Agente": "802135",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ruiz802135@siigo.com",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre": "Tiffany Michelle Ruiz Velasquez"
        },
        {
            "_id": "217148",
            "Agente": "217148",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "jaim217148@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Elsy Johanna Jaimes Bermudez"
        },
        {
            "_id": "801336",
            "Agente": "801336",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rang801336@siigo.com",
            "Lider": "Jesus Daniel Arzuza Roman",
            "Nombre": "Diana Marcela Rangel Oñate"
        },
        {
            "_id": "800835",
            "Agente": "800835",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "lope800835@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Manuel de Jesus Lopez Orellana"
        },
        {
            "_id": "802149",
            "Agente": "802149",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "corr802149@siigo.com",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre": "Leidy Daniela Corral Aranzalez"
        },
        {
            "_id": "110400",
            "Agente": "110400",
            "Area": "Sales",
            "Division": "Siigo Perú",
            "Email": "tafu110400@siigo.com",
            "Lider": "Jessica Consuelo Lopez Castillo",
            "Nombre": "Yuri Tereza Tafur Tafur"
        },
        {
            "_id": "2906",
            "Agente": "2906",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "mrojas@aspel.com.mx",
            "Lider": "Alejandro Lopez Manzo",
            "Nombre": "Maribel De Jesus De Jesus Rojas"
        },
        {
            "_id": "802912",
            "Agente": "802912",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "agui802912@siigo.com",
            "Lider": "Aleida Bibiana Garcia Suarez",
            "Nombre": "Jazmin Aguirre Gonzalez"
        },
        {
            "_id": "802303",
            "Agente": "802303",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "acos802303@siigo.com",
            "Lider": "Juan Pablo Carreño Patiño",
            "Nombre": "Jonathan Estiven Acosta Payan"
        },
        {
            "_id": "800501",
            "Agente": "800501",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "garc800501@siigo.com",
            "Lider": "Kristein Johan Ordoñez Lopez",
            "Nombre": "Oscar Mauricio Garcia Mesa"
        },
        {
            "_id": "801851",
            "Agente": "801851",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "plaz801851@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Ninoska Thalia Plaza Alvarado"
        },
        {
            "_id": "109928",
            "Agente": "109928",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cast109928@siigo.com",
            "Lider": "Yessica Yulieth Quintero Martinez",
            "Nombre": "Leidy Stefany Castillo 0"
        },
        {
            "_id": "112166",
            "Agente": "112166",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "lope112166@siigo.com",
            "Lider": "Miguel Oswaldo Velasquez Fragiel",
            "Nombre": "Juan David Lopez Fuentes"
        },
        {
            "_id": "217769",
            "Agente": "217769",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "zaba217769@siigo.com",
            "Lider": "Diego Armando Aristizabal Bedoya",
            "Nombre": "Jorge Andres Zabala Mayorga"
        },
        {
            "_id": "803886",
            "Agente": "803886",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "lope803886@siigo.com",
            "Lider": "Angie Valentina Lopez Rodriguez",
            "Nombre": "Andres Felipe Lopez Mejia"
        },
        {
            "_id": "803633",
            "Agente": "803633",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "herr803633@siigo.com",
            "Lider": "Diana Mercedes Moncada Peraza",
            "Nombre": "Jenifer Paola Herrera Alfonso"
        },
        {
            "_id": "111830",
            "Agente": "111830",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pena111830@siigo.com",
            "Lider": "Hernan Dario Parra Osorio",
            "Nombre": "Daniel Alejandro Peña Martinez"
        },
        {
            "_id": "802821",
            "Agente": "802821",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "corr802821@siigo.com",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre": "Valentina Correa Delgado"
        },
        {
            "_id": "803584",
            "Agente": "803584",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "alar803584@siigo.com",
            "Lider": "Lyda Patricia Reyes Pradilla",
            "Nombre": "Luisa Fernanda Alarcon Peña"
        },
        {
            "_id": "803754",
            "Agente": "803754",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "estr803754@siigo.com",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre": "Karen Lizeth Estrada Pacheco"
        },
        {
            "_id": "803905",
            "Agente": "803905",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "tole803905@siigo.com",
            "Lider": "Angie Camila Diaz Escobar",
            "Nombre": "Angelica Rossana Tole Tique"
        },
        {
            "_id": "801490",
            "Agente": "801490",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "zaba801490@siigo.com",
            "Lider": "Yeimy Milena Bustos Gutierrez",
            "Nombre": "Yesica Alejandra Zabala Culma"
        },
        {
            "_id": "801500",
            "Agente": "801500",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "serr801500@siigo.com",
            "Lider": "Luz Katerine Maya Mogollón",
            "Nombre": "Leydi Carolina Serrano Ortega"
        },
        {
            "_id": "3355",
            "Agente": "3355",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "bestrada@aspel.com.mx",
            "Lider": "Maria Teresa Vazquez Garza",
            "Nombre": "Braulio Resendiz Estrada"
        },
        {
            "_id": "802251",
            "Agente": "802251",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "carr802251@siigo.com",
            "Lider": "Yeimy Milena Bustos Gutierrez",
            "Nombre": "Eilen Tatiana Carrillo Rada"
        },
        {
            "_id": "802057",
            "Agente": "802057",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ardi802057@siigo.com",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre": "Maria Jose Ardila Ojeda"
        },
        {
            "_id": "801498",
            "Agente": "801498",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "nava801498@siigo.com",
            "Lider": "Leidy Stefany Castillo 0",
            "Nombre": "Wendy Johana Navarro Osorio"
        },
        {
            "_id": "2467",
            "Agente": "2467",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jhuerta@aspel.com.mx",
            "Lider": "Belem Chavez Ruiz",
            "Nombre": "Jhosep Huerta Martinez"
        },
        {
            "_id": "800764",
            "Agente": "800764",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mart800764@siigo.com",
            "Lider": "Johana Catalina Peña ",
            "Nombre": "Jhon Edison Martinez Lesmes"
        },
        {
            "_id": "800591",
            "Agente": "800591",
            "Area": "Finance & Administration",
            "Division": "Siigo Ecuador",
            "Email": "quim8005911@siigo.com",
            "Lider": "Luz Derly Bernal Malaver",
            "Nombre": "Hellen Rudy Quimi Vera"
        },
        {
            "_id": "800793",
            "Agente": "800793",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "medi800793@siigo.com",
            "Lider": "Cristian Camilo Hernandez Gonzalez",
            "Nombre": "Andres Felipe Medina Rincon"
        },
        {
            "_id": "800637",
            "Agente": "800637",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "gome800637@siigo.com",
            "Lider": "Angel Andres Pineda Alvarado",
            "Nombre": "Sophia Denisse Gomez Quimi"
        },
        {
            "_id": "801438",
            "Agente": "801438",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pere801438@siigo.com",
            "Lider": "Yuleidy Josefina Briceño Fernandez",
            "Nombre": "Yamile Perez Pabon"
        },
        {
            "_id": "801753",
            "Agente": "801753",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "maca801753@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Estefanny Paulina Macanchi Carpio"
        },
        {
            "_id": "802023",
            "Agente": "802023",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "here802023@siigo.com",
            "Lider": "Leidy Stefany Castillo 0",
            "Nombre": "Libardo Andres Heredia Posada"
        },
        {
            "_id": "800792",
            "Agente": "800792",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "arch800792@siigo.com",
            "Lider": "Carlos Roberto Alvarado Mancera",
            "Nombre": "Alix Dayana Archila Castillo"
        },
        {
            "_id": "802435",
            "Agente": "802435",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rami802435@siigo.com",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre": "Gabrielle Alejandra Ramirez Celi"
        },
        {
            "_id": "802326",
            "Agente": "802326",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "flor802326@siigo.com",
            "Lider": "Maria Paula Garzon Ortega",
            "Nombre": "Maria Sthefany Florez Quinto"
        },
        {
            "_id": "801632",
            "Agente": "801632",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "sier801632@siigo.com",
            "Lider": "Juan Carlos Buitrago Rios",
            "Nombre": "Luz Omaira Sierra Bernal"
        },
        {
            "_id": "802547",
            "Agente": "802547",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "vega802547@siigo.com",
            "Lider": "Cesar Julian Rivera Benitez",
            "Nombre": "Cristian Camilo Vega Dueñas"
        },
        {
            "_id": "11813",
            "Agente": "11813",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "Prie11813@siigo.com",
            "Lider": "Hector Orlando Bogoya Aguilar",
            "Nombre": "Sandra Marcela Prieto Palacios"
        },
        {
            "_id": "802123",
            "Agente": "802123",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pach802123@siigo.com",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre": "Luis Enrique Pacheco Romero"
        },
        {
            "_id": "19095",
            "Agente": "19095",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "Bece19095@siigo.com",
            "Lider": "Yessica Yulieth Quintero Martinez",
            "Nombre": "Nestor Oswaldo Becerra Prieto"
        },
        {
            "_id": "801115",
            "Agente": "801115",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "meli801115@siigo.com",
            "Lider": "Ivan Elias Jusid Machado",
            "Nombre": "Pablo Gabriel Melian Guerra"
        },
        {
            "_id": "804125",
            "Agente": "804125",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "agui804125@siigo.com",
            "Lider": "Camilo Andres Muñoz Guerra",
            "Nombre": "Jaime Andres Aguilar Rivera"
        },
        {
            "_id": "111796",
            "Agente": "111796",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "tell111796@siigo.com",
            "Lider": "David Ortiz Diaz",
            "Nombre": "Esteban Tellez Garcia"
        },
        {
            "_id": "803619",
            "Agente": "803619",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "polo803619@siigo.com",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre": "Aurora Margarita Polo Alean"
        },
        {
            "_id": "803507",
            "Agente": "803507",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "aris803507@siigo.com",
            "Lider": "Diego Felipe Jaramillo Alvarez",
            "Nombre": "Oscar Orlando Aristizabal Jaimes"
        },
        {
            "_id": "803655",
            "Agente": "803655",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vale803655@siigo.com",
            "Lider": "Yeimy Milena Bustos Gutierrez",
            "Nombre": "Viviana Valencia Martinez"
        },
        {
            "_id": "803790",
            "Agente": "803790",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "bena803790@siigo.com",
            "Lider": "Alison Anaid Chica Ezeta",
            "Nombre": "Diego Alejandro Benavides Gonzalez"
        },
        {
            "_id": "804056",
            "Agente": "804056",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "alva804056@siigo.com",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre": "Sara Lucia Alvarez Charrys"
        },
        {
            "_id": "803822",
            "Agente": "803822",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pera803822@siigo.com",
            "Lider": "Paul Rogelio Fuentes Tabarez",
            "Nombre": "Nicolas Peralta Paez"
        },
        {
            "_id": "803844",
            "Agente": "803844",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "niev803844@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Karol Daniela Nieves Henriquez"
        },
        {
            "_id": "803608",
            "Agente": "803608",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bord803608@siigo.com",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre": "Christian Camilo Borda Sanabria"
        },
        {
            "_id": "802923",
            "Agente": "802923",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gonz802923@siigo.com",
            "Lider": "Cristhian David Niño Barrera",
            "Nombre": "Jonathan Camilo Gonzalez Cortes"
        },
        {
            "_id": "804549",
            "Agente": "804549",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "more804549@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Jhonny Estefan Moreno Alvarez"
        },
        {
            "_id": "802593",
            "Agente": "802593",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "card802593@siigo.com",
            "Lider": "Gina Estefania Melo Sanchez",
            "Nombre": "Julieth Katherine Cardona Gonzalez"
        },
        {
            "_id": "803652",
            "Agente": "803652",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rodr803652@siigo.com",
            "Lider": "Diego Andres Montoya Blandon",
            "Nombre": "Daniel Rodriguez Gonzalez"
        },
        {
            "_id": "800895",
            "Agente": "800895",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rodr800895@siigo.com",
            "Lider": "Giovanni Baquero Hernandez",
            "Nombre": "Francy Viviana Rodriguez Fonseca"
        },
        {
            "_id": "801140",
            "Agente": "801140",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "garc801140@siigo.com",
            "Lider": "Heliberto Antonio Obando Pomeo",
            "Nombre": "Osler Nicolas Garcia Muñoz"
        },
        {
            "_id": "800704",
            "Agente": "800704",
            "Area": "Product",
            "Division": "Siigo Ecuador",
            "Email": "diaz800704@siigo.com",
            "Lider": "Silvia Johanna Ruiz Cuevas",
            "Nombre": "Evelyn Gabriela Diaz Portilla"
        },
        {
            "_id": "28056",
            "Agente": "28056",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "huer28056@siigo.com",
            "Lider": "Carlos Maximo Garrido Mascott",
            "Nombre": "Jose Daniel Huerfano Martinez"
        },
        {
            "_id": "110350",
            "Agente": "110350",
            "Area": "Customer Success",
            "Division": "Siigo Perú",
            "Email": "medi110350@siigo.com",
            "Lider": "Jessica Consuelo Lopez Castillo",
            "Nombre": "Nadia Estefania Medina Carhuatanta"
        },
        {
            "_id": "802172",
            "Agente": "802172",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cabr802172@siigo.com",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre": "Adriana Maria Cabrera Rios"
        },
        {
            "_id": "800685",
            "Agente": "800685",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sanc800685@siigo.com",
            "Lider": "Cristian Camilo Hernandez Gonzalez",
            "Nombre": "Samuel Antonio Sanchez Jimenez"
        },
        {
            "_id": "800936",
            "Agente": "800936",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "garc800936@siigo.com",
            "Lider": "Alix Dayana Archila Castillo",
            "Nombre": "Juan Sebastian Garcia Vega"
        },
        {
            "_id": "801190",
            "Agente": "801190",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "jojo801190@siigo.com",
            "Lider": "Carlos Javier Mendez Vera",
            "Nombre": "Hamilton Daniel Jojoa Cordoba"
        },
        {
            "_id": "801819",
            "Agente": "801819",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "suar801819@siigo.com",
            "Lider": "Angel Alfonso Arciniegas Guerrero",
            "Nombre": "Hillary Viviana Suarez Hinojosa"
        },
        {
            "_id": "112383",
            "Agente": "112383",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "melo112383@siigo.com",
            "Lider": "Diana Mercedes Moncada Peraza",
            "Nombre": "Laurita Melo Benitez"
        },
        {
            "_id": "801516",
            "Agente": "801516",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "tasc801516@siigo.com",
            "Lider": "Paul Rogelio Fuentes Tabarez",
            "Nombre": "Rafael Enrique Tascon Chavez"
        },
        {
            "_id": "801943",
            "Agente": "801943",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "urib801943@siigo.com",
            "Lider": "Luz Katerine Maya Mogollón",
            "Nombre": "Sully Stephany Uribe Mayoral"
        },
        {
            "_id": "802252",
            "Agente": "802252",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "guzm802252@siigo.com",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre": "Deisy Guzman Penagos"
        },
        {
            "_id": "217287",
            "Agente": "217287",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "Cana217287@siigo.com",
            "Lider": "Deimer Esteban Peña Gonzalez",
            "Nombre": "Mayerlin Canate Reyes"
        },
        {
            "_id": "802619",
            "Agente": "802619",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "dela802619@siigo.com",
            "Lider": "Luis Ignacio Ferreira Duarte",
            "Nombre": "Karla Patricia De la Rosa Gracia"
        },
        {
            "_id": "111787",
            "Agente": "111787",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "avil111787@siigo.com",
            "Lider": "Vicky Alejandra Hernandez Mendez",
            "Nombre": "Yeraldin Avila Olaya"
        },
        {
            "_id": "803825",
            "Agente": "803825",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "boli803825@siigo.com",
            "Lider": "Lida Katerine Puentes Navarro",
            "Nombre": "Yesennia Bolivar Sanchez"
        },
        {
            "_id": "110111",
            "Agente": "110111",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "duqu110111@siigo.com",
            "Lider": "Edison Arley Plaza Marin",
            "Nombre": "Andrea Duque Botero"
        },
        {
            "_id": "804047",
            "Agente": "804047",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "quir804047@siigo.com",
            "Lider": "Vicky Alejandra Hernandez Mendez",
            "Nombre": "Juliet Carolina Quiroz Lozano"
        },
        {
            "_id": "107897",
            "Agente": "107897",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pime107897@siigo.com",
            "Lider": "Jesus Daniel Arzuza Roman",
            "Nombre": "Eddy Pimentel Trujillo"
        },
        {
            "_id": "802904",
            "Agente": "802904",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "alza802904@siigo.com",
            "Lider": "Manuel Fernando Salazar Diaz",
            "Nombre": "Leidy Tatiana Alzate Hurtado"
        },
        {
            "_id": "25640",
            "Agente": "25640",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "Rati25640@siigo.com",
            "Lider": "James Alberto Valencia Caicedo",
            "Nombre": "Carlos Alberto Rativa Quintero"
        },
        {
            "_id": "801243",
            "Agente": "801243",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "gavi801243@siigo.com",
            "Lider": "Angie Camila Diaz Escobar",
            "Nombre": "Melanie Rachell Gavilanes Montoya"
        },
        {
            "_id": "3564",
            "Agente": "3564",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "avidal@aspel.com.mx",
            "Lider": "Santos Benjamin Martinez Martinez",
            "Nombre": "Andrea Arumi Vidal Santiago"
        },
        {
            "_id": "800862",
            "Agente": "800862",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "hern800862@siigo.com",
            "Lider": "Yamid Ramiro Quintero Montaño",
            "Nombre": "Mayerlys Teresa Hernandez Luna"
        },
        {
            "_id": "2988",
            "Agente": "2988",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "bramirez@aspel.com.mx",
            "Lider": "Fernando Contreras Ocaña",
            "Nombre": "Berenice Carolina Ramirez Castro"
        },
        {
            "_id": "800985",
            "Agente": "800985",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "acos800985@siigo.com",
            "Lider": "Zayda Smith Afanador Silva",
            "Nombre": "Diana Marcela Acosta Sanchez"
        },
        {
            "_id": "111843",
            "Agente": "111843",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "puen111843@siigo.com",
            "Lider": "Diana Sofia Ramos Castillo",
            "Nombre": "Cristian Alexander Puentes Quiroz"
        },
        {
            "_id": "800940",
            "Agente": "800940",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mons800940@siigo.com",
            "Lider": "Jessica Paola Paez Quintero",
            "Nombre": "Dayron Fabian Monsalve Patiño"
        },
        {
            "_id": "800743",
            "Agente": "800743",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "more800743@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Juan Eduardo Morera Cotera"
        },
        {
            "_id": "801845",
            "Agente": "801845",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pine801845@siigo.com",
            "Lider": "Luz Katerine Maya Mogollón",
            "Nombre": "Cristian Alexander Pineda Socha"
        },
        {
            "_id": "800617",
            "Agente": "800617",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "agua800617@siigo.com",
            "Lider": "John Jairo Mejia Acevedo",
            "Nombre": "José Manuel Aguayo Escandon"
        },
        {
            "_id": "800483",
            "Agente": "800483",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rodr800483@siigo.com",
            "Lider": "Alix Dayana Archila Castillo",
            "Nombre": "Angie Carolina Rodriguez Rojas"
        },
        {
            "_id": "802183",
            "Agente": "802183",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mira802183@siigo.com",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre": "Milagro De Jesus Miranda Suarez"
        },
        {
            "_id": "802580",
            "Agente": "802580",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "fran802580@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Roxana De Jesus Franco Peralta"
        },
        {
            "_id": "801503",
            "Agente": "801503",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ovie801503@siigo.com",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre": "Luis Carlos Oviedo Diaz"
        },
        {
            "_id": "800515",
            "Agente": "800515",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "card800515@siigo.com",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre": "Natalia Cardona Gutierrez"
        },
        {
            "_id": "801853",
            "Agente": "801853",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ospi801853@siigo.com",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre": "José Gregorio Ospino Sandoval"
        },
        {
            "_id": "21295",
            "Agente": "21295",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rami21295@siigo.com",
            "Lider": "Geomar Eliecer Delgado Molina",
            "Nombre": "Yellian Eduardo Ramirez Patiño"
        },
        {
            "_id": "802127",
            "Agente": "802127",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "alva802127@siigo.com",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre": "Yennis Elena Alvarez Cuello"
        },
        {
            "_id": "110152",
            "Agente": "110152",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rued110152@siigo.com",
            "Lider": "Juan Carlos Buitrago Rios",
            "Nombre": "Jerley Andrea Rueda Garzon"
        },
        {
            "_id": "803443",
            "Agente": "803443",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "dela803443@siigo.com",
            "Lider": "Gustavo Adolfo Valencia Chaparro",
            "Nombre": "Ana Maria De la Ossa Muñoz"
        },
        {
            "_id": "108107",
            "Agente": "108107",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "lina108107@siigo.com",
            "Lider": "Diana Mercedes Moncada Peraza",
            "Nombre": "Elvis Ricardo Linares Amador"
        },
        {
            "_id": "109874",
            "Agente": "109874",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "duar109874@siigo.com",
            "Lider": "Miguel Ricardo Julio Babilonia",
            "Nombre": "Maria Paula Duarte Sanchez"
        },
        {
            "_id": "32313",
            "Agente": "32313",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Mart32313@siigo.com",
            "Lider": "Liliana Patricia Giraldo Gomez",
            "Nombre": "John Misael Martinez Montero"
        },
        {
            "_id": "32601",
            "Agente": "32601",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "Rami32601@siigo.com",
            "Lider": "Eddy Pinzon Peña",
            "Nombre": "Leidy Diana Ramirez 0"
        },
        {
            "_id": "802863",
            "Agente": "802863",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "tova802863@siigo.com",
            "Lider": "Walter Antonio Muñoz Antepara",
            "Nombre": "Jhonathan Steven Tovar Tovar"
        },
        {
            "_id": "40937",
            "Agente": "40937",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bust40937@siigo.com",
            "Lider": "John Alexander Benavides Cobos",
            "Nombre": "Yeimy Milena Bustos Gutierrez"
        },
        {
            "_id": "804071",
            "Agente": "804071",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "yace804071@siigo.com",
            "Lider": "Yamid Ramiro Quintero Montaño",
            "Nombre": "Vanessa Estefania Yacelga Leiton"
        },
        {
            "_id": "804374",
            "Agente": "804374",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rome804374@siigo.com",
            "Lider": "Andrea Duque Botero",
            "Nombre": "Jeisson Stiven Romero Paz"
        },
        {
            "_id": "803526",
            "Agente": "803526",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "herr803526@siigo.com",
            "Lider": "Gustavo Adolfo Valencia Chaparro",
            "Nombre": "Sebastian Andres Herrera Bautista"
        },
        {
            "_id": "111813",
            "Agente": "111813",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "godo111813@siigo.com",
            "Lider": "Juan Diego Casallas Bernal",
            "Nombre": "Oscar Sebastian Godoy Ramirez"
        },
        {
            "_id": "802641",
            "Agente": "802641",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pine802641@siigo.com",
            "Lider": "Jessica Paola Paez Quintero",
            "Nombre": "Juan Guillermo Pineda Garcia"
        },
        {
            "_id": "802861",
            "Agente": "802861",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "aven802861@siigo.com",
            "Lider": "Jesus Daniel Arzuza Roman",
            "Nombre": "Nicolas Avendaño Romero"
        },
        {
            "_id": "803845",
            "Agente": "803845",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "guru803845@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Ricardo Saul Gurumendi Carreño"
        },
        {
            "_id": "3378",
            "Agente": "3378",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "lgomez@aspel.com.mx",
            "Lider": "Adriana Rodriguez Castro",
            "Nombre": "Leticia Perez Gomez"
        },
        {
            "_id": "108689",
            "Agente": "108689",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rami108689@siigo.com",
            "Lider": "Magda Lilian Leal Rozo",
            "Nombre": "Jefferson Arvey Ramirez Ibañez"
        },
        {
            "_id": "800809",
            "Agente": "800809",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "sanc800809@siigo.com",
            "Lider": "Sandra Catalina Suarez Castillo",
            "Nombre": "Angie Paola Sanchez Saenz"
        },
        {
            "_id": "801085",
            "Agente": "801085",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "serp801085@siigo.com",
            "Lider": "Ivan Elias Jusid Machado",
            "Nombre": "Carla Daniela Serpa De Vargas"
        },
        {
            "_id": "801638",
            "Agente": "801638",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "hurt801638@siigo.com",
            "Lider": "Nestor Oswaldo Becerra Prieto",
            "Nombre": "Jenifer Paola Hurtado Eslava"
        },
        {
            "_id": "1237",
            "Agente": "1237",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "ahernandez@aspel.com.mx",
            "Lider": "Virginia Guadalupe Ramirez Hernandez",
            "Nombre": "Agustin Hernandez Lopez"
        },
        {
            "_id": "800774",
            "Agente": "800774",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pena800774@siigo.com",
            "Lider": "José Manuel Aguayo Escandon",
            "Nombre": "Johana Catalina Peña "
        },
        {
            "_id": "800161",
            "Agente": "800161",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pine800161@siigo.com",
            "Lider": "Angie Camila Diaz Escobar",
            "Nombre": "Darianis Pineda Torres"
        },
        {
            "_id": "802125",
            "Agente": "802125",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pere802125@siigo.com",
            "Lider": "Mario Fernando Nuñez Santos",
            "Nombre": "Manuel Antonio Perez Rodriguez"
        },
        {
            "_id": "803478",
            "Agente": "803478",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "narv803478@siigo.com",
            "Lider": "Angel Andres Pineda Alvarado",
            "Nombre": "Erika Lissette Narvaez Lucio"
        },
        {
            "_id": "802695",
            "Agente": "802695",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "arbo802695@siigo.com",
            "Lider": "Angie Camila Diaz Escobar",
            "Nombre": "Sandra Milena Arboleda Moreno"
        },
        {
            "_id": "802752",
            "Agente": "802752",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "quir802752@siigo.com",
            "Lider": "Vicky Alejandra Hernandez Mendez",
            "Nombre": "Karol Viviana Quiroga Olarte"
        },
        {
            "_id": "802553",
            "Agente": "802553",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "berr802553@siigo.com",
            "Lider": "Sory Yaqueline Abello Polo",
            "Nombre": "Roymer Eduardo Berrio Garcia"
        },
        {
            "_id": "111980",
            "Agente": "111980",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "sepu111980@siigo.com",
            "Lider": "James Alberto Valencia Caicedo",
            "Nombre": "Lucia Sepulveda Zabala"
        },
        {
            "_id": "802540",
            "Agente": "802540",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "varg802540@siigo.com",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre": "Manuel Octavio Vargas Ferro"
        },
        {
            "_id": "803319",
            "Agente": "803319",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "guer803319@siigo.com",
            "Lider": "Diana Mercedes Moncada Peraza",
            "Nombre": "Darlis Esther Guerra Jerez"
        },
        {
            "_id": "801830",
            "Agente": "801830",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "duar801830@siigo.com",
            "Lider": "Johanna Patricia Torres Quintero",
            "Nombre": "Wendy Vanessa Duarte Pinzon"
        },
        {
            "_id": "803910",
            "Agente": "803910",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "nino803910@siigo.com",
            "Lider": "Alcides Martin Salas Orozco",
            "Nombre": "Leidy Katherine Niño Salazar"
        },
        {
            "_id": "804076",
            "Agente": "804076",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "muno804076@siigo.com",
            "Lider": "Carlos Eduardo Sanchez Castaño",
            "Nombre": "Paula Alexandra Muñoz Narvaez"
        },
        {
            "_id": "804059",
            "Agente": "804059",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "riva804059@siigo.com",
            "Lider": "Giovanni Baquero Hernandez",
            "Nombre": "Natalia Rivas Lizcano"
        },
        {
            "_id": "803733",
            "Agente": "803733",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "muno803733@siigo.com",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre": "Schalia Muñoz Lerma"
        },
        {
            "_id": "802885",
            "Agente": "802885",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "suar802885@siigo.com",
            "Lider": "Aleida Bibiana Garcia Suarez",
            "Nombre": "Jose Manuel Suarez Rodriguez"
        },
        {
            "_id": "803757",
            "Agente": "803757",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "padi803757@siigo.com",
            "Lider": "Wilzon Camilo Liscano Galindo",
            "Nombre": "Juan Eduardo Padilla Gonzalez"
        },
        {
            "_id": "111916",
            "Agente": "111916",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "more111916@siigo.com",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre": "Elizabeth Moreno Vargas"
        },
        {
            "_id": "803767",
            "Agente": "803767",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pach803767@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Luis Manuel Pacheco Buelvas"
        },
        {
            "_id": "803834",
            "Agente": "803834",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "holg803834@siigo.com",
            "Lider": "Jose Luis Perales Dueñas",
            "Nombre": "Milton Fabian Holguin Mejia"
        },
        {
            "_id": "804397",
            "Agente": "804397",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "amay804397@siigo.com",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Giovanny Andres Amaya 0"
        },
        {
            "_id": "803692",
            "Agente": "803692",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ocam803692@siigo.com",
            "Lider": "Jefferson Arvey Ramirez Ibañez",
            "Nombre": "Yuliana Marcela Ocampo Escobar"
        },
        {
            "_id": "803908",
            "Agente": "803908",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "more803908@siigo.com",
            "Lider": "David Julian Rojas Prado",
            "Nombre": "Julian David Moreno Marin"
        },
        {
            "_id": "801944",
            "Agente": "801944",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "carr801944@siigo.com",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre": "Neidy Lorena Carranza Ramirez"
        },
        {
            "_id": "2354",
            "Agente": "2354",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "amoje@aspel.com.mx",
            "Lider": "Luis Alberto Flores Castillo",
            "Nombre": "Adrian Idelfonso Moje Chim"
        },
        {
            "_id": "801715",
            "Agente": "801715",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rive801715@siigo.com",
            "Lider": "Cristhian David Niño Barrera",
            "Nombre": "John Mauricio Rivera Paez"
        },
        {
            "_id": "800770",
            "Agente": "800770",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mart800770@siigo.com",
            "Lider": "Johana Catalina Peña ",
            "Nombre": "Nataly Martinez Montilla"
        },
        {
            "_id": "112342",
            "Agente": "112342",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gavi112342@siigo.com",
            "Lider": "Jefferson Arvey Ramirez Ibañez",
            "Nombre": "Isidro Gavilan 0"
        },
        {
            "_id": "3206",
            "Agente": "3206",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "cpmoreno@aspel.com.mx",
            "Lider": "Maria Teresa Vazquez Garza",
            "Nombre": "Christian Pablo Moreno Moscosa"
        },
        {
            "_id": "800975",
            "Agente": "800975",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rinc800975@siigo.com",
            "Lider": "Alvaro Jose Acosta Madroñero",
            "Nombre": "Haider Esteban Rincon Montero"
        },
        {
            "_id": "800520",
            "Agente": "800520",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "rinc800520@siigo.com",
            "Lider": "Edwin Alfredo Guayacan Cardenal",
            "Nombre": "Monica Yulieth Navia Rincon"
        },
        {
            "_id": "802915",
            "Agente": "802915",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "lope802915@siigo.com",
            "Lider": "Emre Pehlivan 0",
            "Nombre": "Angie Valentina Lopez Rodriguez"
        },
        {
            "_id": "802928",
            "Agente": "802928",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "sala802928@siigo.com",
            "Lider": "Alison Anaid Chica Ezeta",
            "Nombre": "Ana Luidt Salamanca Wilches"
        },
        {
            "_id": "802008",
            "Agente": "802008",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sant802008@siigo.com",
            "Lider": "Angy Paola Umbacia Diaz",
            "Nombre": "Elsa Carolina Santos Garcia"
        },
        {
            "_id": "801931",
            "Agente": "801931",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "hern801931@siigo.com",
            "Lider": "Jesus Daniel Arzuza Roman",
            "Nombre": "Margarita Rosa Hernandez Aguilar"
        },
        {
            "_id": "801786",
            "Agente": "801786",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "dela801786@siigo.com",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre": "Georgelly Johanna De la Hoz Fontalvo"
        },
        {
            "_id": "802448",
            "Agente": "802448",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "vela802448@siigo.com",
            "Lider": "Ivon Andrea Rios Rodriguez",
            "Nombre": "Liseth Yolima Velandia Suescun"
        },
        {
            "_id": "801183",
            "Agente": "801183",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "blan801183@siigo.com",
            "Lider": "Carlos Eduardo Sanchez Castaño",
            "Nombre": "Leidy Paola Blanco Cortina"
        },
        {
            "_id": "802927",
            "Agente": "802927",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "truj802927@siigo.com",
            "Lider": "Angel Camilo Chavez Moreno",
            "Nombre": "Cristian Fernando Trujillo Bolaños"
        },
        {
            "_id": "111978",
            "Agente": "111978",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "orti111978@siigo.com",
            "Lider": "Hector Orlando Bogoya Aguilar",
            "Nombre": "Juan Diego Ortiz Orozco"
        },
        {
            "_id": "803194",
            "Agente": "803194",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "paez803194@siigo.com",
            "Lider": "Zayda Smith Afanador Silva",
            "Nombre": "Liliam Paola Paez Perez"
        },
        {
            "_id": "802081",
            "Agente": "802081",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "caip802081@siigo.com",
            "Lider": "Carlos Alberto Beltran Melo",
            "Nombre": "Harold Alberto Caipa Sehuanes"
        },
        {
            "_id": "801980",
            "Agente": "801980",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "arci801980@siigo.com",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre": "Miguel Alberto Arciria Guevara"
        },
        {
            "_id": "804534",
            "Agente": "804534",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "muno804534@siigo.com",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre": "Daniel Alexander Muñoz Triana"
        },
        {
            "_id": "3322",
            "Agente": "3322",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "acleto@aspel.com.mx",
            "Lider": "Jonathan Oviedo Gonzalez",
            "Nombre": "Alejandro Cleto Herrera"
        },
        {
            "_id": "802864",
            "Agente": "802864",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rome802864@siigo.com",
            "Lider": "Frankgel Ribely Machado Pantoja",
            "Nombre": "Martin Leonel Romero Vega"
        },
        {
            "_id": "803446",
            "Agente": "803446",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mayo803446@siigo.com",
            "Lider": "Walter Antonio Muñoz Antepara",
            "Nombre": "Lady Marcela Mayorga Laverde"
        },
        {
            "_id": "882",
            "Agente": "882",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "cmacin@aspel.com.mx",
            "Lider": "Luisa Fernanda Caro Camargo",
            "Nombre": "Christian Macin Ramirez"
        },
        {
            "_id": "2002",
            "Agente": "2002",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "amedina@aspel.com.mx",
            "Lider": "César Augusto Rejón Vermont",
            "Nombre": "Allam Francisco Medina Yama"
        },
        {
            "_id": "803787",
            "Agente": "803787",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "diaz803787@siigo.com",
            "Lider": "Marco Antonio Ortega Piedrahita",
            "Nombre": "Luis Felipe Diaz Acosta"
        },
        {
            "_id": "804554",
            "Agente": "804554",
            "Area": "Finance & Administration",
            "Division": "Siigo Ecuador",
            "Email": "vill804554@siigo.com",
            "Lider": "Diana Elizabeth Davila Murillo",
            "Nombre": "Keyla Xiomara Villareal 0"
        },
        {
            "_id": "111456",
            "Agente": "111456",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "arev111456@siigo.com",
            "Lider": "Gustavo Adolfo Espinosa Toledo",
            "Nombre": "Mery Arevalo Navarro"
        },
        {
            "_id": "3342",
            "Agente": "3342",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jtrodriguez@aspel.com.mx",
            "Lider": "Adriana Rodriguez Castro",
            "Nombre": "Jose Alfredo Tlahuechtl Rodriguez"
        },
        {
            "_id": "803799",
            "Agente": "803799",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "pere803799@siigo.com",
            "Lider": "Andrea Catalina Vega Gomez",
            "Nombre": "Miriana Perez Castro"
        },
        {
            "_id": "803373",
            "Agente": "803373",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "mart803373@siigo.com",
            "Lider": "Jonathan Uribe Mendoza",
            "Nombre": "Itturiel Martinez Benitez"
        },
        {
            "_id": "804061",
            "Agente": "804061",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "guev804061@siigo.com",
            "Lider": "Diego Armando Alzate Rios",
            "Nombre": "Yime Alexander Guevara Peña"
        },
        {
            "_id": "803525",
            "Agente": "803525",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "acos803525@siigo.com",
            "Lider": "Yeimy Milena Bustos Gutierrez",
            "Nombre": "Jessica Patricia Acosta Charris"
        },
        {
            "_id": "802411",
            "Agente": "802411",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "tria802411@siigo.com",
            "Lider": "Alison Anaid Chica Ezeta",
            "Nombre": "Luisa Fernanda Triana Jaramillo"
        },
        {
            "_id": "36216",
            "Agente": "36216",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cubi36216@siigo.com",
            "Lider": "Yenny Milena Lopez Florez",
            "Nombre": "Anderson Cubillos Ayala"
        },
        {
            "_id": "1563",
            "Agente": "1563",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "vestrada@aspel.com.mx",
            "Lider": "Carlos Mario Espinosa Fernandez",
            "Nombre": "Vicente Estrada Sanchez"
        },
        {
            "_id": "802472",
            "Agente": "802472",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "lond802472@siigo.com",
            "Lider": "Yeimy Milena Bustos Gutierrez",
            "Nombre": "Leydi Vanesa Londoño Meneses"
        },
        {
            "_id": "2265",
            "Agente": "2265",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jmora@aspel.com.mx",
            "Lider": "Armando Uriel Lopez Vazquez",
            "Nombre": "Jazmin Mora Vazquez"
        },
        {
            "_id": "800803",
            "Agente": "800803",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sern800803@siigo.com",
            "Lider": "Diana Patricia Villarraga Forero",
            "Nombre": "Yessica Paola Serna Hoyos"
        },
        {
            "_id": "802018",
            "Agente": "802018",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "cano802018@siigo.com",
            "Lider": "Maria Fernanda Delgadillo Bernal",
            "Nombre": "Claudia Marcela Cano Forero"
        },
        {
            "_id": "802339",
            "Agente": "802339",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cruz802339@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Yurin Tatiana Cruz Castillo"
        },
        {
            "_id": "801752",
            "Agente": "801752",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "alve801752@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Johnny Israel Alvear Jimenez"
        },
        {
            "_id": "802146",
            "Agente": "802146",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "lobo802146@siigo.com",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre": "Karen Melissa Lobo Gonzalez"
        },
        {
            "_id": "802342",
            "Agente": "802342",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "card802342@siigo.com",
            "Lider": "Johana Catalina Peña ",
            "Nombre": "Viviana Libet Cardenas Benavides"
        },
        {
            "_id": "801763",
            "Agente": "801763",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "alac801763@siigo.com",
            "Lider": "Eliana Maria Fajardo Prada",
            "Nombre": "Adriana Rocio Alarcon Cardenas"
        },
        {
            "_id": "801510",
            "Agente": "801510",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "lema801510@siigo.com",
            "Lider": "Walter Antonio Muñoz Antepara",
            "Nombre": "Angel Eduardo Lema Orta"
        },
        {
            "_id": "803430",
            "Agente": "803430",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "quev803430@siigo.com",
            "Lider": "Fabian David Carreño Leon",
            "Nombre": "Brayann Yefferson Ariel Quevedo Benito"
        },
        {
            "_id": "802121",
            "Agente": "802121",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "polo802121@siigo.com",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre": "Kevin Alberto Polo Lozano"
        },
        {
            "_id": "112392",
            "Agente": "112392",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vele112392@siigo.com",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre": "Clara Elena Velez Perez"
        },
        {
            "_id": "801283",
            "Agente": "801283",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bala801283@siigo.com",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre": "Yuly Alexandra Balaguera Rodriguez"
        },
        {
            "_id": "804142",
            "Agente": "804142",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "trig804142@siigo.com",
            "Lider": "Jairo Andres Mendieta 0",
            "Nombre": "Leoneider Trigos Guerreros"
        },
        {
            "_id": "802465",
            "Agente": "802465",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "garc802465@siigo.com",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre": "Laura Lucia Garcia Acosta"
        },
        {
            "_id": "802412",
            "Agente": "802412",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mora802412@siigo.com",
            "Lider": "Angel Alfonso Arciniegas Guerrero",
            "Nombre": "Nataly Mora Beltrán"
        },
        {
            "_id": "3504",
            "Agente": "3504",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "emagallanes@aspel.com.mx",
            "Lider": "Arturo Alvarez Martinez",
            "Nombre": "Edith Patricia Magallanes Ramirez"
        },
        {
            "_id": "2960",
            "Agente": "2960",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "cbarrera@aspel.com.mx",
            "Lider": "Agustin Hernandez Lopez",
            "Nombre": "Cecilia Viridiana Barrera Duran"
        },
        {
            "_id": "801816",
            "Agente": "801816",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "toro801816@siigo.com",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre": "Angie Julieth Toro Robayo"
        },
        {
            "_id": "800282",
            "Agente": "800282",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "agui800282@siigo.com",
            "Lider": "Magda de Jesús Becerra Castañeda",
            "Nombre": "Sebastian Aguirre Ramirez"
        },
        {
            "_id": "803627",
            "Agente": "803627",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "cama803627@siigo.com",
            "Lider": "Juan Carlos Guarin Walteros",
            "Nombre": "Luis Fernando Camargo Pineda"
        },
        {
            "_id": "3169",
            "Agente": "3169",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "elorenzana@aspel.com.mx",
            "Lider": "Maira Elisa Ortiz Perez",
            "Nombre": "Emely Esmeralda Lorenzana Rodriguez"
        },
        {
            "_id": "803737",
            "Agente": "803737",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rami803737@siigo.com",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre": "Francy Ney Ramirez Bustamante"
        },
        {
            "_id": "111671",
            "Agente": "111671",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vasq111671@siigo.com",
            "Lider": "Luz Angelica Torres Quintero",
            "Nombre": "Ingrit Jhoana Vasquez Fuquen"
        },
        {
            "_id": "800497",
            "Agente": "800497",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rubi800497@siigo.com",
            "Lider": "Jesus Daniel Arzuza Roman",
            "Nombre": "Nataly Rubiano Murcia"
        },
        {
            "_id": "804130",
            "Agente": "804130",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rebo804130@siigo.com",
            "Lider": "Cristian Andres Collazos Ruiz",
            "Nombre": "Andres Sebastian Rebolledo Sierra"
        },
        {
            "_id": "803710",
            "Agente": "803710",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mont803710@siigo.com",
            "Lider": "Leidy Johana Rodriguez Garcia",
            "Nombre": "Yudi Veronica Montenegro Jerez"
        },
        {
            "_id": "802420",
            "Agente": "802420",
            "Area": "Country Management",
            "Division": "Siigo Colombia",
            "Email": "sanc802420@siigo.com",
            "Lider": "David Ortiz Diaz",
            "Nombre": "Maria Angelica Sanchez Rivera"
        },
        {
            "_id": "802603",
            "Agente": "802603",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "varg802603@siigo.com",
            "Lider": "Jesus Dario Sanchez Nava",
            "Nombre": "Peter Anderson Vargas Grajales"
        },
        {
            "_id": "111893",
            "Agente": "111893",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "ocam111893@siigo.com",
            "Lider": "Diana Patricia Atehortua Castaño",
            "Nombre": "Bibiana Maria Ocampo Marin"
        },
        {
            "_id": "1714",
            "Agente": "1714",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "izavala@aspel.com.mx",
            "Lider": "Pedro Arturo Rico Martinez",
            "Nombre": "Ivan Zavala Flores"
        },
        {
            "_id": "800931",
            "Agente": "800931",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "jime800931@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Chris Samantha Jimenez Cubillos"
        },
        {
            "_id": "802253",
            "Agente": "802253",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "silv802253@siigo.com",
            "Lider": "Mario Fernando Nuñez Santos",
            "Nombre": "Adriana Silva Curaca"
        },
        {
            "_id": "800823",
            "Agente": "800823",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "fern800823@siigo.com",
            "Lider": "Manuel Fernando Zambrano Ceron",
            "Nombre": "Cesar David Fernandez Charry"
        },
        {
            "_id": "3540",
            "Agente": "3540",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "omontes@aspel.com.mx",
            "Lider": "Arturo Alvarez Martinez",
            "Nombre": "Omar Isaias Montes de Oca de Oca Campos"
        },
        {
            "_id": "801033",
            "Agente": "801033",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pint801033@siigo.com",
            "Lider": "Yamid Ramiro Quintero Montaño",
            "Nombre": "Heiber Armando Pinto Martinez"
        },
        {
            "_id": "2685",
            "Agente": "2685",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "afernandez@aspel.com.mx",
            "Lider": "Elia Karina Lopez Romero",
            "Nombre": "Adolfo Israel Fernandez Hernandez"
        },
        {
            "_id": "800920",
            "Agente": "800920",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pena800920@siigo.com",
            "Lider": "Nestor Oswaldo Becerra Prieto",
            "Nombre": "Shirley Catalina Peña Neira"
        },
        {
            "_id": "801929",
            "Agente": "801929",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "fuen801929@siigo.com",
            "Lider": "Diana Mercedes Moncada Peraza",
            "Nombre": "Maira Cecilia Fuentes Manrique"
        },
        {
            "_id": "801246",
            "Agente": "801246",
            "Area": "Marketing",
            "Division": "Siigo Ecuador",
            "Email": "moli801246@siigo.com",
            "Lider": "Magda de Jesús Becerra Castañeda",
            "Nombre": "Jeanella Lisbeth Molina Molina"
        },
        {
            "_id": "802841",
            "Agente": "802841",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "roja802841@siigo.com",
            "Lider": "Cindy Joan Artunduaga Muñoz",
            "Nombre": "Cesar Augusto Rojas Belalcazar"
        },
        {
            "_id": "803187",
            "Agente": "803187",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "mell803187@siigo.com",
            "Lider": "Diego Felipe Jaramillo Alvarez",
            "Nombre": "Luis Miguel Mellizo Fernandez"
        },
        {
            "_id": "1752",
            "Agente": "1752",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "ccanul@aspel.com.mx",
            "Lider": "Juan Carlos Gallardo Valadez",
            "Nombre": "Cindy Canul Rivera"
        },
        {
            "_id": "802515",
            "Agente": "802515",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "nais802515@siigo.com",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre": "Naiceth Dajana Naissir Torres"
        },
        {
            "_id": "803617",
            "Agente": "803617",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cast803617@siigo.com",
            "Lider": "Mario Fernando Nuñez Santos",
            "Nombre": "Nelson Jesus Castro Rodriguez"
        },
        {
            "_id": "804564",
            "Agente": "804564",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "guti804564@siigo.com",
            "Lider": "John Alexander Benavides Cobos",
            "Nombre": "Andres Fabian Gutierrez Gutierrez"
        },
        {
            "_id": "803592",
            "Agente": "803592",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "alba803592@siigo.com",
            "Lider": "Milady Andrea Guzman Garcia",
            "Nombre": "Veronica Albadan Castrillon"
        },
        {
            "_id": "2065",
            "Agente": "2065",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jcortez@aspel.com.mx",
            "Lider": "Adair Blanco Landin",
            "Nombre": "Jose Osvaldo Cortez Martinez"
        },
        {
            "_id": "800253",
            "Agente": "800253",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "guzm800253@siigo.com",
            "Lider": "Harrison Martinez Callejas",
            "Nombre": "Milady Andrea Guzman Garcia"
        },
        {
            "_id": "111508",
            "Agente": "111508",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "triv111508@siigo.com",
            "Lider": "Liliana Ramirez Vanegas",
            "Nombre": "Stefania Triviño Penagos"
        },
        {
            "_id": "804547",
            "Agente": "804547",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sanc804547@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Estefany Carolina Sanchez Vesga"
        },
        {
            "_id": "112509",
            "Agente": "112509",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "mont112509@siigo.com",
            "Lider": "Mario Alberto Rojas Gamboa",
            "Nombre": "Jaider Manuel Montes Cortes"
        },
        {
            "_id": "802586",
            "Agente": "802586",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "jara802586@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Valeria Nicole Jaramillo Ramirez"
        },
        {
            "_id": "111957",
            "Agente": "111957",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "herr111957@siigo.com",
            "Lider": "Marisela Yasmin Franco Cruz",
            "Nombre": "Johana Carolina Herrera Sopo"
        },
        {
            "_id": "111707",
            "Agente": "111707",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "ariz111707@siigo.com",
            "Lider": "Lina Maria Buitrago Herrera",
            "Nombre": "Yuri Andrea Ariza Diaz"
        },
        {
            "_id": "802860",
            "Agente": "802860",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "otal802860@siigo.com",
            "Lider": "Gustavo Adolfo Espinosa Toledo",
            "Nombre": "Julia Mercedes Otalora Jimenez"
        },
        {
            "_id": "803598",
            "Agente": "803598",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "varg803598@siigo.com",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre": "Angelica Juliana Vargas Espinosa"
        },
        {
            "_id": "804155",
            "Agente": "804155",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "leon804155@siigo.com",
            "Lider": "David Alfredo Sanchez Bastidas",
            "Nombre": "Camilo Ernesto Leon Garzón"
        },
        {
            "_id": "3536",
            "Agente": "3536",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ashernandez@aspel.com.mx",
            "Lider": "Arturo Alvarez Martinez",
            "Nombre": "Alejandra Santiesteban Hernandez"
        },
        {
            "_id": "803884",
            "Agente": "803884",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pere803884@siigo.com",
            "Lider": "Leida Adriana Rico Herrera",
            "Nombre": "Maria Jose Perez Manjarres"
        },
        {
            "_id": "802425",
            "Agente": "802425",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vega802425@siigo.com",
            "Lider": "Johanna Patricia Torres Quintero",
            "Nombre": "Davith Jose Vega Borja"
        },
        {
            "_id": "802875",
            "Agente": "802875",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "flor802875@siigo.com",
            "Lider": "Aleida Bibiana Garcia Suarez",
            "Nombre": "William Alberto Florez Parra"
        },
        {
            "_id": "3351",
            "Agente": "3351",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "anajera@aspel.com.mx",
            "Lider": "Luis Alejandro Salgado Vergara",
            "Nombre": "Andres Najera Alarcon"
        },
        {
            "_id": "1771",
            "Agente": "1771",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "tmendez@aspel.com.mx",
            "Lider": "Bismark Ivan Rios Campos",
            "Nombre": "Teresa De Jesus Mendez Valencia"
        },
        {
            "_id": "2188",
            "Agente": "2188",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "bbello@aspel.com.mx",
            "Lider": "Gabriel Chavez Carrera",
            "Nombre": "Berenice Bello Camacho"
        },
        {
            "_id": "2889",
            "Agente": "2889",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "clima@aspel.com.mx",
            "Lider": "Jesus Alfonso Leyra Galan",
            "Nombre": "Carlos Daniel Lima Gamez"
        },
        {
            "_id": "801273",
            "Agente": "801273",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "pose801273@siigo.com",
            "Lider": "Luis Ignacio Ferreira Duarte",
            "Nombre": "German Pose Vaucher"
        },
        {
            "_id": "226492",
            "Agente": "226492",
            "Area": "Customer Success",
            "Division": "Siigo Perú",
            "Email": "Vasq226492@siigo.com",
            "Lider": "Jessica Consuelo Lopez Castillo",
            "Nombre": "Roberto Carlos Vasquez Vera"
        },
        {
            "_id": "800557",
            "Agente": "800557",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "garc800556@siigo.com",
            "Lider": "Alvin Diaz Guerrero",
            "Nombre": "Bryan Oswaldo Garces Murillo"
        },
        {
            "_id": "801062",
            "Agente": "801062",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "olar801062@siigo.com",
            "Lider": "Andrea Catalina Vega Gomez",
            "Nombre": "Gloria Stefani Olarte Gamboa"
        },
        {
            "_id": "800665",
            "Agente": "800665",
            "Area": "Product",
            "Division": "Siigo Ecuador",
            "Email": "velo800665@siigo.com",
            "Lider": "Mileny Yelissa Durán Mendoza",
            "Nombre": "Paola Denisse Veloz Garcia"
        },
        {
            "_id": "803691",
            "Agente": "803691",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "quir803691@siigo.com",
            "Lider": "Francy Julieth Viracacha Gutierrez",
            "Nombre": "Leidy Johana Quiroga Rios"
        },
        {
            "_id": "802392",
            "Agente": "802392",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "boho802392@siigo.com",
            "Lider": "Johana Catalina Peña ",
            "Nombre": "Yaneth Alejandra Bohorquez Oliveros"
        },
        {
            "_id": "803718",
            "Agente": "803718",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "carr803718@siigo.com",
            "Lider": "Cristhian David Niño Barrera",
            "Nombre": "Jhonathan Camilo Carrillo Romero"
        },
        {
            "_id": "804019",
            "Agente": "804019",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "chat804019@siigo.com",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre": "Juan Andres Chate Capote"
        },
        {
            "_id": "802584",
            "Agente": "802584",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "mera802584@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Angie Rosa Mera Ruiz"
        },
        {
            "_id": "800604",
            "Agente": "800604",
            "Area": "Product",
            "Division": "Siigo Ecuador",
            "Email": "sala800604@siigo.com",
            "Lider": "Oscar Francisco Plaza Giler",
            "Nombre": "Jorge Luis Salas Sarmiento"
        },
        {
            "_id": "804535",
            "Agente": "804535",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "penn804535@siigo.com",
            "Lider": "Edgar Fabian Ruano Daza",
            "Nombre": "Andres Felipe Penna Ramirez"
        },
        {
            "_id": "802872",
            "Agente": "802872",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "manc802872@siigo.com",
            "Lider": "Juvenal Reyes Silva",
            "Nombre": "Juan Carlos Mancipe Nuñez"
        },
        {
            "_id": "802867",
            "Agente": "802867",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "otal802867@siigo.com",
            "Lider": "John Misael Martinez Montero",
            "Nombre": "Kerly Melissa Otalora Rojas"
        },
        {
            "_id": "804519",
            "Agente": "804519",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bast804519@siigo.com",
            "Lider": "Jose Luis Perales Dueñas",
            "Nombre": "Anyela Patricia Basto Zambrano"
        },
        {
            "_id": "802913",
            "Agente": "802913",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "hern802913@siigo.com",
            "Lider": "Aleida Bibiana Garcia Suarez",
            "Nombre": "Jhon Carlos Hernandez Restrepo"
        },
        {
            "_id": "803795",
            "Agente": "803795",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gome803795@siigo.com",
            "Lider": "Jenny Paola Mendez 0",
            "Nombre": "Jhon Alexander Gomez Erazo"
        },
        {
            "_id": "111555",
            "Agente": "111555",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "nino111555@siigo.com",
            "Lider": "Hollman Antonio Aponte Quiroga",
            "Nombre": "Cristhian David Niño Barrera"
        },
        {
            "_id": "804559",
            "Agente": "804559",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "amorales@aspel.com.mx",
            "Lider": "Arturo Alvarez Martinez",
            "Nombre": "Angie Lorena Morales Cabulo"
        },
        {
            "_id": "111914",
            "Agente": "111914",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "tama111914@siigo.com",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre": "Diego Tamayo Muñoz"
        },
        {
            "_id": "802830",
            "Agente": "802830",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "rodr802830@siigo.com",
            "Lider": "Andrea Catalina Vega Gomez",
            "Nombre": "Leidi Jimena Rodriguez Gonzalez"
        },
        {
            "_id": "803907",
            "Agente": "803907",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gile803907@siigo.com",
            "Lider": "Alcides Martin Salas Orozco",
            "Nombre": "Claudia Yamile Gil Estupiñan"
        },
        {
            "_id": "32170",
            "Agente": "32170",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "ayal32170@siigo.com",
            "Lider": "Wendy Dayana Rojas Rojas",
            "Nombre": "David Leonardo Ayala 0"
        },
        {
            "_id": "800478",
            "Agente": "800478",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "diaz800478@siigo.com",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre": "Nazly Yulieth Diaz Benitez"
        },
        {
            "_id": "2154",
            "Agente": "2154",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "prico@aspel.com.mx",
            "Lider": "Brian Yukio Nishizaki Simon",
            "Nombre": "Pedro Arturo Rico Martinez"
        },
        {
            "_id": "31761",
            "Agente": "31761",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "garc31761@siigo.com",
            "Lider": "Luz Angelica Torres Quintero",
            "Nombre": "Alexandra Garcia Rozo"
        },
        {
            "_id": "197201",
            "Agente": "197201",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sola197201@siigo.com",
            "Lider": "Juan Carlos Corazzo Santos",
            "Nombre": "Manuel Andres Solano Duran"
        },
        {
            "_id": "801411",
            "Agente": "801411",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "yate801411@siigo.com",
            "Lider": "Martha Isabel Diaz Gomez",
            "Nombre": "Tatiana Julieth Yate Alape"
        },
        {
            "_id": "804062",
            "Agente": "804062",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "diaz804062@siigo.com",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre": "Yuliza Katherine Diaz Cuaran"
        },
        {
            "_id": "802921",
            "Agente": "802921",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "corr802921@siigo.com",
            "Lider": "Gloria Estela Osorio Montoya",
            "Nombre": "Wilmer Alejandro Correa Carmona"
        },
        {
            "_id": "801106",
            "Agente": "801106",
            "Area": "Product",
            "Division": "Siigo Uruguay",
            "Email": "abel801106@siigo.com",
            "Lider": "Mauricio Ramirez Minetti",
            "Nombre": "Luis Ignacio Abeldaño Carbonell"
        },
        {
            "_id": "801300",
            "Agente": "801300",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "espi801300@siigo.com",
            "Lider": "Juan David Lopez Fuentes",
            "Nombre": "Rafael Alejandro Espinosa Garcia"
        },
        {
            "_id": "801686",
            "Agente": "801686",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cruz801686@siigo.com",
            "Lider": "Carlos Eduardo Sanchez Castaño",
            "Nombre": "Mayra Alejandra Cruz Gomez"
        },
        {
            "_id": "802093",
            "Agente": "802093",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "beta802093@siigo.com",
            "Lider": "Diana Patricia Villarraga Forero",
            "Nombre": "Laura Katheryn Betancourt Sarmiento"
        },
        {
            "_id": "801169",
            "Agente": "801169",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "flor801169@siigo.com",
            "Lider": "Yuleidy Josefina Briceño Fernandez",
            "Nombre": "Luz Adriana Florez Fuentes"
        },
        {
            "_id": "801231",
            "Agente": "801231",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "ferr801231@siigo.com",
            "Lider": "Ivan Elias Jusid Machado",
            "Nombre": "Ana Gabriela Ferreira Alvarez"
        },
        {
            "_id": "804050",
            "Agente": "804050",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "mora804050@siigo.com",
            "Lider": "Diego Armando Alzate Rios",
            "Nombre": "Carlos Andres Morales Salazar"
        },
        {
            "_id": "2662",
            "Agente": "2662",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ilopezp@aspel.com.mx",
            "Lider": "Maria Magdalena Mendez Romero",
            "Nombre": "Irma Beatriz Lopez Peña"
        },
        {
            "_id": "802615",
            "Agente": "802615",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "espi802615@siigo.com",
            "Lider": "Silvia Zilah Fernandez Almeida",
            "Nombre": "Eduardo Adrian Espinosa Massa"
        },
        {
            "_id": "803440",
            "Agente": "803440",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "sanc803440@siigo.com",
            "Lider": "Gustavo Adolfo Valencia Chaparro",
            "Nombre": "Sandy Daniela Sanchez Soache"
        },
        {
            "_id": "3561",
            "Agente": "3561",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "mahuacatitan@aspel.com.mx",
            "Lider": "Brandon Enrique Gomez Rosales",
            "Nombre": "Michelle Ahuacatitan Espindola"
        },
        {
            "_id": "802301",
            "Agente": "802301",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "uria802301@siigo.com",
            "Lider": "Francy Julieth Viracacha Gutierrez",
            "Nombre": "Darling Alberto Urian Vargas"
        },
        {
            "_id": "801768",
            "Agente": "801768",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cort801768@siigo.com",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre": "Juliet Tatiana Cortés Acevedo"
        },
        {
            "_id": "801824",
            "Agente": "801824",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rami801824@siigo.com",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre": "Yenny Veronica Ramirez Gil"
        },
        {
            "_id": "803707",
            "Agente": "803707",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "prie803707@siigo.com",
            "Lider": "Aleida Bibiana Garcia Suarez",
            "Nombre": "Luz Diana Maritza Prieto Molano"
        },
        {
            "_id": "800324",
            "Agente": "800324",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "chav800324@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Angie Paola Chaves Bolaños"
        },
        {
            "_id": "802706",
            "Agente": "802706",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "canc802706@siigo.com",
            "Lider": "Cristhian David Niño Barrera",
            "Nombre": "Luisa Fernanda Cancelado Barón"
        },
        {
            "_id": "803625",
            "Agente": "803625",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "vela803625@siigo.com",
            "Lider": "Cesar Julian Rivera Benitez",
            "Nombre": "Jhony Velasco Yacumal"
        },
        {
            "_id": "802869",
            "Agente": "802869",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "duen802869@siigo.com",
            "Lider": "David Alfredo Sanchez Bastidas",
            "Nombre": "Katherine Lizeth Dueñas Garzon"
        },
        {
            "_id": "803600",
            "Agente": "803600",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "fern803600@siigo.com",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre": "Ana Karina De Jesus Fernandez Glen"
        },
        {
            "_id": "803701",
            "Agente": "803701",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "dear803701@siigo.com",
            "Lider": "Daniel Eduardo Pinto Cepeda",
            "Nombre": "Angelith Del Carmen De Armas Lopez"
        },
        {
            "_id": "803717",
            "Agente": "803717",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ordo803717@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Dario Ordoñez Cerquera"
        },
        {
            "_id": "4012",
            "Agente": "4012",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "Orti4012@siigo.com",
            "Lider": "Manuel Andres Solano Duran",
            "Nombre": "Sandra Lucia Ortiz Deulofeut"
        },
        {
            "_id": "803730",
            "Agente": "803730",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "card803730@siigo.com",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre": "Laura Juliana Cardenas Montero"
        },
        {
            "_id": "1825",
            "Agente": "1825",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "rmacias@aspel.com.mx",
            "Lider": "Rene Gerardo Paredes Alcantara",
            "Nombre": "Raul Avendaño Macias"
        },
        {
            "_id": "3442",
            "Agente": "3442",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "gbarrios@aspel.com.mx",
            "Lider": "Luis Alejandro Salgado Vergara",
            "Nombre": "Gladis Barrios Licona"
        },
        {
            "_id": "800690",
            "Agente": "800690",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "blan800690@siigo.com",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre": "Gabriel Angel Blanco Rojas"
        },
        {
            "_id": "801119",
            "Agente": "801119",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "samu801119@siigo.com",
            "Lider": "Alcides Martin Salas Orozco",
            "Nombre": "Sharon Evelyn Samurio Nuñez de Moraes"
        },
        {
            "_id": "3154",
            "Agente": "3154",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "iromero@aspel.com.mx",
            "Lider": "Juan Carlos Valdes Martin",
            "Nombre": "Amador Ivan Dominguez Romero"
        },
        {
            "_id": "800802",
            "Agente": "800802",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vale800802@siigo.com",
            "Lider": "Leidy Stefany Castillo 0",
            "Nombre": "Yeisson Valencia 0"
        },
        {
            "_id": "802118",
            "Agente": "802118",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ball802118@siigo.com",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre": "Aaron Andres Ballen Perez"
        },
        {
            "_id": "800281",
            "Agente": "800281",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "boli800281@siigo.com",
            "Lider": "Yuleidy Josefina Briceño Fernandez",
            "Nombre": "Dolly Esperanza Bolivar Oviedo"
        },
        {
            "_id": "221022",
            "Agente": "221022",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "Risc221022@siigo.com",
            "Lider": "Sandra Lucia Ortiz Deulofeut",
            "Nombre": "Jhon Jairo Riscanevo Martinez"
        },
        {
            "_id": "804516",
            "Agente": "804516",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "golaya@aspel.com.mx",
            "Lider": "Arturo Alvarez Martinez",
            "Nombre": "Gina Marcela Olaya Castro"
        },
        {
            "_id": "800626",
            "Agente": "800626",
            "Area": "Marketing",
            "Division": "Siigo Ecuador",
            "Email": "vill800626@siigo.com",
            "Lider": "Sandra Catalina Suarez Castillo",
            "Nombre": "Juliana Stefania Villegas Gomez"
        },
        {
            "_id": "112451",
            "Agente": "112451",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "andrea.zuluaga@siigo.com",
            "Lider": "David Ortiz Diaz",
            "Nombre": "Andrea Zuluaga Uribe"
        },
        {
            "_id": "27",
            "Agente": "27",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "elopez@aspel.com.mx",
            "Lider": "Claudia Valdez Carbajal",
            "Nombre": "Eliseo Lopez Cruz"
        },
        {
            "_id": "801622",
            "Agente": "801622",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "puel801622@siigo.com",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre": "Raquel Puello Pajaro"
        },
        {
            "_id": "803720",
            "Agente": "803720",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pere803720@siigo.com",
            "Lider": "Diana Michelle Matamoros Del Rosario",
            "Nombre": "Jesus David Perez Mendoza"
        },
        {
            "_id": "803705",
            "Agente": "803705",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mart803705@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Angel Alfonso Martinez Vargas"
        },
        {
            "_id": "802385",
            "Agente": "802385",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "afan802385@siigo.com",
            "Lider": "Alison Anaid Chica Ezeta",
            "Nombre": "Ailyn Alicia Afanador Linero"
        },
        {
            "_id": "802429",
            "Agente": "802429",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "arau802429@siigo.com",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre": "Jhonatan Araujo Guette"
        },
        {
            "_id": "804150",
            "Agente": "804150",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "gali804159@siigo.com",
            "Lider": "Miguel Angel Suárez Hernández",
            "Nombre": "Maria Fernanda Galindo Martinez"
        },
        {
            "_id": "802625",
            "Agente": "802625",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "fern802625@siigo.com",
            "Lider": "Angel Alfonso Arciniegas Guerrero",
            "Nombre": "Leidy Alexandra Fernandez Arevalo"
        },
        {
            "_id": "803367",
            "Agente": "803367",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "porr803367@siigo.com",
            "Lider": "Diana Milena Roa Hurtado",
            "Nombre": "Santiago Jose Porras Brieva"
        },
        {
            "_id": "3038",
            "Agente": "3038",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "rgomez@aspel.com.mx",
            "Lider": "Alejandro Lopez Manzo",
            "Nombre": "Ricardo Lopez Gomez"
        },
        {
            "_id": "156",
            "Agente": "156",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "mcreus@aspel.com.mx",
            "Lider": "Claudia Valdez Carbajal",
            "Nombre": "Enna Maria Creus Castellanos"
        },
        {
            "_id": "802375",
            "Agente": "802375",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cogo802375@siigo.com",
            "Lider": "Alison Anaid Chica Ezeta",
            "Nombre": "Ivan Fernando Cogollo Moreno"
        },
        {
            "_id": "802664",
            "Agente": "802664",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "penu802664@siigo.com",
            "Lider": "Andrea Catalina Vega Gomez",
            "Nombre": "Maria Teresa Peñuela Roldan"
        },
        {
            "_id": "802209",
            "Agente": "802209",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cast802209@siigo.com",
            "Lider": "Angel Alfonso Arciniegas Guerrero",
            "Nombre": "Pedro Enrique Castro Villamil"
        },
        {
            "_id": "802260",
            "Agente": "802260",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "lope802260@siigo.com",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre": "Eliana Marcela Lopez Sierra"
        },
        {
            "_id": "802490",
            "Agente": "802490",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "vele802490@siigo.com",
            "Lider": "Martin Leonel Romero Vega",
            "Nombre": "Johanna Carolina Velez Bravo"
        },
        {
            "_id": "802017",
            "Agente": "802017",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "merc802017@siigo.com",
            "Lider": "Jenny Paola Mendez 0",
            "Nombre": "Jose Angel Mercado Romero"
        },
        {
            "_id": "802597",
            "Agente": "802597",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "piam802597@siigo.com",
            "Lider": "Miguel Oswaldo Velasquez Fragiel",
            "Nombre": "Jhon Alejandro Piamba Monsalve"
        },
        {
            "_id": "800706",
            "Agente": "800706",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "apun800706@siigo.com",
            "Lider": "Walter Antonio Muñoz Antepara",
            "Nombre": "Viviana Cecilia Apuntes Garcia"
        },
        {
            "_id": "802659",
            "Agente": "802659",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "roah802659@siigo.com",
            "Lider": "Esteban Tellez Garcia",
            "Nombre": "Diana Milena Roa Hurtado"
        },
        {
            "_id": "801541",
            "Agente": "801541",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "bote801541@siigo.com",
            "Lider": "Fernando Mario Paez Tovar",
            "Nombre": "Marcelo Botero Gomez"
        },
        {
            "_id": "801104",
            "Agente": "801104",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "taff801104@siigo.com",
            "Lider": "Ivan Elias Jusid Machado",
            "Nombre": "Leidy Taffura 0"
        },
        {
            "_id": "800726",
            "Agente": "800726",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "beni800726@siigo.com",
            "Lider": "Johana Catalina Peña ",
            "Nombre": "Karen Xiomara Benitez Riveros"
        },
        {
            "_id": "802013",
            "Agente": "802013",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "sosa802013@siigo.com",
            "Lider": "Yuleidy Josefina Briceño Fernandez",
            "Nombre": "Diego Fabian Sosa Orjuela"
        },
        {
            "_id": "804133",
            "Agente": "804133",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "zamb804133@siigo.com",
            "Lider": "Juan Camilo Gonzalez Saldaña",
            "Nombre": "Yesika Lorena Zambrano Londoño"
        },
        {
            "_id": "801204",
            "Agente": "801204",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "cast801204@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Kevin Lester Castellano Sanchez"
        },
        {
            "_id": "803712",
            "Agente": "803712",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "lope803712@siigo.com",
            "Lider": "Yuleidy Josefina Briceño Fernandez",
            "Nombre": "Jessica Paola Lopez Alvarez"
        },
        {
            "_id": "801549",
            "Agente": "801549",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "amay801549@siigo.com",
            "Lider": "Gabriel Alexander Romero Mora",
            "Nombre": "Lina Marcela Amaya Velasquez"
        },
        {
            "_id": "802722",
            "Agente": "802722",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rodr802722@siigo.com",
            "Lider": "Hollman Antonio Aponte Quiroga",
            "Nombre": "Leidy Marcela Rodriguez Rivera"
        },
        {
            "_id": "803280",
            "Agente": "803280",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "nino803280@siigo.com",
            "Lider": "Marcelo Botero Gomez",
            "Nombre": "Andres Fernando Niño Viancha"
        },
        {
            "_id": "3359",
            "Agente": "3359",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "sbravo@aspel.com.mx",
            "Lider": "Ivan Alejandro Roa Franco",
            "Nombre": "Scarlet del Carmen Bravo Ramos"
        },
        {
            "_id": "802180",
            "Agente": "802180",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "diaz802180@siigo.com",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre": "Gisella Del Carmen Diazgranados Vergara"
        },
        {
            "_id": "2171",
            "Agente": "2171",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ysolis@aspel.com.mx",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Yessica Yadira Solis Aguirre"
        },
        {
            "_id": "800896",
            "Agente": "800896",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rinc800896@siigo.com",
            "Lider": "Giovanni Baquero Hernandez",
            "Nombre": "Maria Erlly Rincon Bravo"
        },
        {
            "_id": "2423",
            "Agente": "2423",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "nleyva@aspel.com.mx",
            "Lider": "Fernando Villasana Gutierrez",
            "Nombre": "Noe Leyva Meneses"
        },
        {
            "_id": "804181",
            "Agente": "804181",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pira804181@siigo.com",
            "Lider": "Aleida Bibiana Garcia Suarez",
            "Nombre": "Suleith Yulieth Piragauta Amado"
        },
        {
            "_id": "802918",
            "Agente": "802918",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "bern802918@siigo.com",
            "Lider": "Yuleidy Josefina Briceño Fernandez",
            "Nombre": "Sandra Liseth Bernal 0"
        },
        {
            "_id": "802917",
            "Agente": "802917",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "anil802917@siigo.com",
            "Lider": "Gloria Estela Osorio Montoya",
            "Nombre": "Laura Vanessa Anillo Beltran"
        },
        {
            "_id": "801287",
            "Agente": "801287",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pala801287@siigo.com",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre": "Jose Fernando Palacio Manotas"
        },
        {
            "_id": "2011",
            "Agente": "2011",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "onavarro@aspel.com.mx",
            "Lider": "Maria Magdalena Mendez Romero",
            "Nombre": "Oscar Rodrigo Navarro Hernandez"
        },
        {
            "_id": "802344",
            "Agente": "802344",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cast802344@siigo.com",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre": "David Felipe Castro Leon"
        },
        {
            "_id": "803529",
            "Agente": "803529",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pabo803529@siigo.com",
            "Lider": "Johanna Patricia Torres Quintero",
            "Nombre": "Anyi Alejandra Pabon Parrado"
        },
        {
            "_id": "802612",
            "Agente": "802612",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "cape802612@siigo.com",
            "Lider": "Nelson Juan Carlos Acosta Drouet",
            "Nombre": "Jenny Carolina Capera Rodriguez"
        },
        {
            "_id": "802028",
            "Agente": "802028",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "fons802028@siigo.com",
            "Lider": "Alix Dayana Archila Castillo",
            "Nombre": "Karen Daniela Fonseca Gallego"
        },
        {
            "_id": "801289",
            "Agente": "801289",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "reyv801289@siigo.com",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre": "Luz Adriana Rey Villarraga"
        },
        {
            "_id": "801266",
            "Agente": "801266",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "andi801266@siigo.com",
            "Lider": "Luis Ignacio Ferreira Duarte",
            "Nombre": "Leofer Matias Andino Garcia"
        },
        {
            "_id": "802718",
            "Agente": "802718",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "parr802718@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Monica Rocio Parra Solano"
        },
        {
            "_id": "800746",
            "Agente": "800746",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rest800746@siigo.com",
            "Lider": "Gustavo Adolfo Moreno Muñoz",
            "Nombre": "Diego Alejandro Restrepo Vela"
        },
        {
            "_id": "801342",
            "Agente": "801342",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "galv801342@siigo.com",
            "Lider": "Juvenal Reyes Silva",
            "Nombre": "Marcela Galviz Reyes"
        },
        {
            "_id": "804183",
            "Agente": "804183",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mala804183@siigo.com",
            "Lider": "Maria Ximena Manrique Isaziga",
            "Nombre": "Adelia Maria Malagon Moreno"
        },
        {
            "_id": "803704",
            "Agente": "803704",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "guzm803704@siigo.com",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Maria Paz Guzman Parrado"
        },
        {
            "_id": "801071",
            "Agente": "801071",
            "Area": "Tech",
            "Division": "Siigo Uruguay",
            "Email": "mont801071@siigo.com",
            "Lider": "Diego Felipe Jaramillo Alvarez",
            "Nombre": "Maria Victoria Montalban Bueno"
        },
        {
            "_id": "801624",
            "Agente": "801624",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "garc801624@siigo.com",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre": "Yildre Mirley Garcia Dominguez"
        },
        {
            "_id": "803445",
            "Agente": "803445",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rodr803445@siigo.com",
            "Lider": "Alison Anaid Chica Ezeta",
            "Nombre": "Yicely Stefanny Rodriguez Camargo"
        },
        {
            "_id": "802046",
            "Agente": "802046",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "cast802046@siigo.com",
            "Lider": "Alvaro Jose Almendrales Pabon",
            "Nombre": "Kevin Esteban Castaño Castañeda"
        },
        {
            "_id": "802321",
            "Agente": "802321",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "sant802321@siigo.com",
            "Lider": "Ivan Elias Jusid Machado",
            "Nombre": "Camila Santana Cruz"
        },
        {
            "_id": "3207",
            "Agente": "3207",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "ogallardo@aspel.com.mx",
            "Lider": "Marisela Yasmin Franco Cruz",
            "Nombre": "Oscar Eduardo Gallardo Lopez"
        },
        {
            "_id": "801572",
            "Agente": "801572",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "rodr801572@siigo.com",
            "Lider": "Andrea Catalina Vega Gomez",
            "Nombre": "Leidy Johana Rodriguez Garcia"
        },
        {
            "_id": "804005",
            "Agente": "804005",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "corr804005@siigo.com",
            "Lider": "Gustavo Adolfo Villada Tapias",
            "Nombre": "Cristian Camilo Correa Grajales"
        },
        {
            "_id": "802514",
            "Agente": "802514",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cald802514@siigo.com",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre": "Luis Cesar Calderon Lopez"
        },
        {
            "_id": "112141",
            "Agente": "112141",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "gual112141@siigo.com",
            "Lider": "Wendy Dayana Rojas Rojas",
            "Nombre": "Nicolas Gualtero Tovar"
        },
        {
            "_id": "880",
            "Agente": "880",
            "Area": "Product",
            "Division": "Siigo Mexico",
            "Email": "ahuerta@aspel.com.mx",
            "Lider": "Oscar Francisco Plaza Giler",
            "Nombre": "Alfredo Carlos Huerta Ayala"
        },
        {
            "_id": "110109",
            "Agente": "110109",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "pena110109@siigo.com",
            "Lider": "Fernando Gutierrez Paramio",
            "Nombre": "David Felipe Penagos Mosquera"
        },
        {
            "_id": "802919",
            "Agente": "802919",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "urre802919@siigo.com",
            "Lider": "Gloria Estela Osorio Montoya",
            "Nombre": "Fredy Alejandro Urrego Perez"
        },
        {
            "_id": "3236",
            "Agente": "3236",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "jislas@aspel.com.mx",
            "Lider": "Marisela Yasmin Franco Cruz",
            "Nombre": "Jose Luis Islas Jose"
        },
        {
            "_id": "111749",
            "Agente": "111749",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "caba111749@siigo.com",
            "Lider": "Lida Katerine Puentes Navarro",
            "Nombre": "Yulie Maricsa Caballero Mateus"
        },
        {
            "_id": "3239",
            "Agente": "3239",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jbaez@aspel.com.mx",
            "Lider": "Jose Luis Carranza Valdes",
            "Nombre": "Jesus Enrique Martinez Baez"
        },
        {
            "_id": "111766",
            "Agente": "111766",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ordu111766@siigo.com",
            "Lider": "Leida Adriana Rico Herrera",
            "Nombre": "Diego Armando Orduz Gonzalez"
        },
        {
            "_id": "3252",
            "Agente": "3252",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "scruz@aspel.com.mx",
            "Lider": "Laura Erika Utrera Utrera",
            "Nombre": "Salvador Cruz Garcia"
        },
        {
            "_id": "801569",
            "Agente": "801569",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "arbo801569@siigo.com",
            "Lider": "Diana Michelle Matamoros Del Rosario",
            "Nombre": "Lissette Nicole Arboleda Barreiro"
        },
        {
            "_id": "2111",
            "Agente": "2111",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "lalonso@aspel.com.mx",
            "Lider": "Adair Blanco Landin",
            "Nombre": "Luis Jesus Alonso Vratny"
        },
        {
            "_id": "801135",
            "Agente": "801135",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "chic801135@siigo.com",
            "Lider": "Lizeth Janney Ayala Zuluaga",
            "Nombre": "Laura Catalina Chica Lopez"
        },
        {
            "_id": "3287",
            "Agente": "3287",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ozepeda@aspel.com.mx",
            "Lider": "Luis Alejandro Salgado Vergara",
            "Nombre": "Omar Zepeda Valencia"
        },
        {
            "_id": "802507",
            "Agente": "802507",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "hern802507@siigo.com",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre": "Laura Camila Hernandez Dimate"
        },
        {
            "_id": "800909",
            "Agente": "800909",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gome800909@siigo.com",
            "Lider": "Yesika Viviana Garcia Niño",
            "Nombre": "Paula Lorena Gomez Figueroa"
        },
        {
            "_id": "802501",
            "Agente": "802501",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ramo802501@siigo.com",
            "Lider": "Diana Maria Naranjo Mattheus",
            "Nombre": "Marinela Ramos Cruz"
        },
        {
            "_id": "803193",
            "Agente": "803193",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "alva803193@siigo.com",
            "Lider": "Luis Felipe Sanchez Gomez",
            "Nombre": "Felipe Alvarez Duque"
        },
        {
            "_id": "802317",
            "Agente": "802317",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "truy802317@siigo.com",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre": "Carlos Andres Truyol Gutierrez"
        },
        {
            "_id": "804160",
            "Agente": "804160",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "prad804160@siigo.com",
            "Lider": "Juvenal Reyes Silva",
            "Nombre": "Estefanía Prada Rueda"
        },
        {
            "_id": "2261",
            "Agente": "2261",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "kgonzalez@aspel.com.mx",
            "Lider": "Pedro Arturo Rico Martinez",
            "Nombre": "Karen Abigail Nayely Gonzalez Barajas"
        },
        {
            "_id": "802937",
            "Agente": "802937",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "more802937@siigo.com",
            "Lider": "Angel Alfonso Arciniegas Guerrero",
            "Nombre": "Angela Catherine Moreno Macias"
        },
        {
            "_id": "803682",
            "Agente": "803682",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "verg803682@siigo.com",
            "Lider": "Daniel Eduardo Pinto Cepeda",
            "Nombre": "Karen Margarita Vergara Mora"
        },
        {
            "_id": "802004",
            "Agente": "802004",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "barr802004@siigo.com",
            "Lider": "Angy Paola Umbacia Diaz",
            "Nombre": "Maria Nydia Barrientos Orjuela"
        },
        {
            "_id": "801508",
            "Agente": "801508",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cast801508@siigo.com",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre": "Carolina Castro Castaño"
        },
        {
            "_id": "801004",
            "Agente": "801004",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vaqu801004@siigo.com",
            "Lider": "Carlos Eduardo Sanchez Castaño",
            "Nombre": "Angie Lorena Vaquiro Fonseca"
        },
        {
            "_id": "800857",
            "Agente": "800857",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "horn800857@siigo.com",
            "Lider": "Esteban Tellez Garcia",
            "Nombre": "Claudio Federico Hornes Ismail"
        },
        {
            "_id": "2119",
            "Agente": "2119",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "cguzman@aspel.com.mx",
            "Lider": "Gabriel Chavez Carrera",
            "Nombre": "Cristian Jair Guzman Mancilla"
        },
        {
            "_id": "800038",
            "Agente": "800038",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "lope800038@siigo.com",
            "Lider": "Juliana Castro Diaz",
            "Nombre": "Hellen Andrea Lopez Cristancho"
        },
        {
            "_id": "802016",
            "Agente": "802016",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mart802016@siigo.com",
            "Lider": "Nestor Oswaldo Becerra Prieto",
            "Nombre": "Valentina Martinez Piza"
        },
        {
            "_id": "800885",
            "Agente": "800885",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "mosc800885@siigo.com",
            "Lider": "Diana Sofia Ramos Castillo",
            "Nombre": "Joan Carlos Moscoso Villavicencio"
        },
        {
            "_id": "800330",
            "Agente": "800330",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "bric800330@siigo.com",
            "Lider": "Hollman Antonio Aponte Quiroga",
            "Nombre": "Yuleidy Josefina Briceño Fernandez"
        },
        {
            "_id": "803282",
            "Agente": "803282",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "segu803282@siigo.com",
            "Lider": "Omar Eduardo Roa Quintero",
            "Nombre": "Miguel Alejandro Segura Cardenas"
        },
        {
            "_id": "803879",
            "Agente": "803879",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "hern803879@siigo.com",
            "Lider": "John Misael Martinez Montero",
            "Nombre": "Angel Eduardo Hernandez Muñoz"
        },
        {
            "_id": "801312",
            "Agente": "801312",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "chai801312@siigo.com",
            "Lider": "Carlos German Lopez Murcia",
            "Nombre": "Yasser Mohamed Chain Martinez"
        },
        {
            "_id": "802480",
            "Agente": "802480",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "mest802480@siigo.com",
            "Lider": "Martha Isabel Diaz Gomez",
            "Nombre": "Diana Carolina Mestra 0"
        },
        {
            "_id": "803274",
            "Agente": "803274",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "neva803274@siigo.com",
            "Lider": "Angie Camila Diaz Escobar",
            "Nombre": "Ingri Katherine Neva 0"
        },
        {
            "_id": "803480",
            "Agente": "803480",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "gran803480@siigo.com",
            "Lider": "Claudia Patricia Estevez Alvarez",
            "Nombre": "Andrea Tatiana Granados Acevedo"
        },
        {
            "_id": "803290",
            "Agente": "803290",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rend803290@siigo.com",
            "Lider": "Sonia Mabel Fernandez Calderon",
            "Nombre": "Daniela Alejandra Rendon 0"
        },
        {
            "_id": "112544",
            "Agente": "112544",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mora112544@siigo.com",
            "Lider": "Jesus Daniel Arzuza Roman",
            "Nombre": "Alvaro Enrique Mora Lopez"
        },
        {
            "_id": "803287",
            "Agente": "803287",
            "Area": "Marketing",
            "Division": "Siigo Uruguay",
            "Email": "marc803287@siigo.com",
            "Lider": "Gina Estefania Melo Sanchez",
            "Nombre": "Martin Alejandro Marcher Bertotto"
        },
        {
            "_id": "800877",
            "Agente": "800877",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mart800877@siigo.com",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre": "Gabriella Caroline Martinez Bolivar"
        },
        {
            "_id": "800831",
            "Agente": "800831",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "ospi800831@siigo.com",
            "Lider": "Diego Andres Montoya Blandon",
            "Nombre": "Martha Liliana Ospina Murillo"
        },
        {
            "_id": "3549",
            "Agente": "3549",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "aidominguez@aspel.com.mx",
            "Lider": "Alejandro Lopez Manzo",
            "Nombre": "Aranza Ivonne Dominguez Carrillo"
        },
        {
            "_id": "112265",
            "Agente": "112265",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "reye112265@siigo.com",
            "Lider": "Vicky Alejandra Hernandez Mendez",
            "Nombre": "Jenifer Carolain Reyes Pire"
        },
        {
            "_id": "1723",
            "Agente": "1723",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jdib@aspel.com.mx",
            "Lider": "Rene Gerardo Paredes Alcantara",
            "Nombre": "Julian Dib Lopez"
        },
        {
            "_id": "803196",
            "Agente": "803196",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "cald803196@siigo.com",
            "Lider": "Hector Orlando Bogoya Aguilar",
            "Nombre": "Fernando Ivan Calderon Beltran"
        },
        {
            "_id": "801917",
            "Agente": "801917",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "medi801917@siigo.com",
            "Lider": "Luz Katerine Maya Mogollón",
            "Nombre": "William Fernando Medina Camargo"
        },
        {
            "_id": "802061",
            "Agente": "802061",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rued802061@siigo.com",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre": "Leidy Vanessa Rueda Bravo"
        },
        {
            "_id": "801578",
            "Agente": "801578",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "dura801578@siigo.com",
            "Lider": "Felipe Ricardo Reyes Lega",
            "Nombre": "Mileny Yelissa Durán Mendoza"
        },
        {
            "_id": "801589",
            "Agente": "801589",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "para801589@siigo.com",
            "Lider": "Diego Andres Montoya Blandon",
            "Nombre": "Miguel Angel Parada Cañon"
        },
        {
            "_id": "801081",
            "Agente": "801081",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "rome801081@siigo.com",
            "Lider": "Milady Andrea Guzman Garcia",
            "Nombre": "Johan Santiago Romero Mendez"
        },
        {
            "_id": "802092",
            "Agente": "802092",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "rued802092@siigo.com",
            "Lider": "Natalia Catherine Triviño Cortes",
            "Nombre": "Laura Milena Rueda Muñoz"
        },
        {
            "_id": "800956",
            "Agente": "800956",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rome800956@siigo.com",
            "Lider": "David Alfredo Sanchez Bastidas",
            "Nombre": "Yeison Fabian Romero 0"
        },
        {
            "_id": "801011",
            "Agente": "801011",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "guti801011@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Maria De Los Angeles Gutierrez Correa"
        },
        {
            "_id": "2139",
            "Agente": "2139",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jgaytan@aspel.com.mx",
            "Lider": "Armando Mariano Cirilo",
            "Nombre": "Jair Josue Gaytan Gonzalez"
        },
        {
            "_id": "802239",
            "Agente": "802239",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "card802239@siigo.com",
            "Lider": "Angie Camila Diaz Escobar",
            "Nombre": "Yuly Stefany Cardozo Polania"
        },
        {
            "_id": "802162",
            "Agente": "802162",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "tera802162@siigo.com",
            "Lider": "Anyeliz Beatriz Rico Torres",
            "Nombre": "Andres Felipe Teran Ortega"
        },
        {
            "_id": "801536",
            "Agente": "801536",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "came801536@siigo.com",
            "Lider": "Angie Camila Diaz Escobar",
            "Nombre": "Karen Gisell Camelo Alonso"
        },
        {
            "_id": "3289",
            "Agente": "3289",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "eleyra@aspel.com.mx",
            "Lider": "Rodrigo Sanchez Godinez",
            "Nombre": "Elizabeth Diana Leyra Gonzalez"
        },
        {
            "_id": "2100",
            "Agente": "2100",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "alima@aspel.com.mx",
            "Lider": "Agustin Hernandez Lopez",
            "Nombre": "Ana Isabel Lima Banderas"
        },
        {
            "_id": "801285",
            "Agente": "801285",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gilg801285@siigo.com",
            "Lider": "Cristian Camilo Hernandez Gonzalez",
            "Nombre": "Leidy Johana Gil Garavito"
        },
        {
            "_id": "801555",
            "Agente": "801555",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "cace801555@siigo.com",
            "Lider": "David Rosero Calle",
            "Nombre": "Luis Alexander Caceres Vega"
        },
        {
            "_id": "3425",
            "Agente": "3425",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "mmaldonado@aspel.com.mx",
            "Lider": "Jorge Alfredo Huesca Martinez",
            "Nombre": "Miguel Maldonado Garcia"
        },
        {
            "_id": "800401",
            "Agente": "800401",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "juli800401@siigo.com",
            "Lider": "Silvia Johanna Ruiz Cuevas",
            "Nombre": "Miguel Ricardo Julio Babilonia"
        },
        {
            "_id": "801983",
            "Agente": "801983",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "guer801983@siigo.com",
            "Lider": "Jesus Dario Sanchez Nava",
            "Nombre": "Alberto Guerrero Garcia"
        },
        {
            "_id": "803663",
            "Agente": "803663",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rome803663@siigo.com",
            "Lider": "Hollman Antonio Aponte Quiroga",
            "Nombre": "Diego Alejandro Romero 0"
        },
        {
            "_id": "803828",
            "Agente": "803828",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "nast803828@siigo.com",
            "Lider": "Carlos Alberto Beltran Melo",
            "Nombre": "Valeria Nastar 0"
        },
        {
            "_id": "804169",
            "Agente": "804169",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "aris804169@siigo.com",
            "Lider": "Juan Sebastian Cifuentes Guiffo",
            "Nombre": "Manuel Sebastian Aristizabal Leguizamon"
        },
        {
            "_id": "803841",
            "Agente": "803841",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "lope803841@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Michael Javier Lopez 0"
        },
        {
            "_id": "803437",
            "Agente": "803437",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "rodr803437@siigo.com",
            "Lider": "Bismark Ivan Rios Campos",
            "Nombre": "Diana Mabel Rodriguez Oviedo"
        },
        {
            "_id": "802610",
            "Agente": "802610",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "torr802610@siigo.com",
            "Lider": "Angie Camila Diaz Escobar",
            "Nombre": "Kevin David Torres 0"
        },
        {
            "_id": "802815",
            "Agente": "802815",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "fuer802815@siigo.com",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre": "Danilo Leonel Fuertes Romero"
        },
        {
            "_id": "3552",
            "Agente": "3552",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "mbravo@aspel.com.mx",
            "Lider": "Adriana Rodriguez Castro",
            "Nombre": "Miguel Jimenez Bravo"
        },
        {
            "_id": "803365",
            "Agente": "803365",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "rinc803365@siigo.com",
            "Lider": "Ivon Andrea Rios Rodriguez",
            "Nombre": "Sharyth Nicol Rincon 0"
        },
        {
            "_id": "802086",
            "Agente": "802086",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "cont802086@siigo.com",
            "Lider": "Ana Maria Ramirez Sanchez",
            "Nombre": "Cristian Camilo Contreras Castañeda"
        },
        {
            "_id": "802155",
            "Agente": "802155",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "roaq802155@siigo.com",
            "Lider": "Juan Francisco Lavayen Iñamagua",
            "Nombre": "Omar Eduardo Roa Quintero"
        },
        {
            "_id": "801244",
            "Agente": "801244",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "cort801244@siigo.com",
            "Lider": "Angie Camila Diaz Escobar",
            "Nombre": "Hanny Melissa Cortez Moncayo"
        },
        {
            "_id": "801219",
            "Agente": "801219",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "pale801219@siigo.com",
            "Lider": "Anyela Marcela Rivera Mera",
            "Nombre": "Mariana De Jesus Palencia Merlano"
        },
        {
            "_id": "802182",
            "Agente": "802182",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sier802182@siigo.com",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre": "Yuder Steven Sierra Aguilar"
        },
        {
            "_id": "801665",
            "Agente": "801665",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rios801665@siigo.com",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre": "Sebastian Andres Rios Guevara"
        },
        {
            "_id": "802261",
            "Agente": "802261",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "aran802261@siigo.com",
            "Lider": "Luz Katerine Maya Mogollón",
            "Nombre": "Silvia Dahyana Arango Cardona"
        },
        {
            "_id": "801251",
            "Agente": "801251",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "lope801251@siigo.com",
            "Lider": "Gladys Mariela Sarmiento Murcia",
            "Nombre": "Ana Constanza Lopez Venegas"
        },
        {
            "_id": "801271",
            "Agente": "801271",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "segu801271@siigo.com",
            "Lider": "Luis Ignacio Ferreira Duarte",
            "Nombre": "Fatima Tatiana Segundo Toya"
        },
        {
            "_id": "3177",
            "Agente": "3177",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "ebonilla@aspel.com.mx",
            "Lider": "Laura Erika Utrera Utrera",
            "Nombre": "Edain Isabel Canul Bonilla"
        },
        {
            "_id": "801100",
            "Agente": "801100",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "ruiz801100@siigo.com",
            "Lider": "Alcides Martin Salas Orozco",
            "Nombre": "Johana Micaela Ruiz Diaz Santuro"
        },
        {
            "_id": "801088",
            "Agente": "801088",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "alon801088@siigo.com",
            "Lider": "Ivan Elias Jusid Machado",
            "Nombre": "Alejandro Alonso Rocha"
        },
        {
            "_id": "801111",
            "Agente": "801111",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "vier801111@siigo.com",
            "Lider": "Silvia Zilah Fernandez Almeida",
            "Nombre": "Matias Alejandro Viera Curcio"
        },
        {
            "_id": "801778",
            "Agente": "801778",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pozo801778@siigo.com",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre": "Alexandra Vanesa Pozo Padilla"
        },
        {
            "_id": "801095",
            "Agente": "801095",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "redo801095@siigo.com",
            "Lider": "Ivan Elias Jusid Machado",
            "Nombre": "Fernando Daniel Redondo Martinez"
        },
        {
            "_id": "803829",
            "Agente": "803829",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "esca803829@siigo.com",
            "Lider": "Aura Cristina Rodriguez Manrique",
            "Nombre": "Angie Millie Escandon Martinez"
        },
        {
            "_id": "803760",
            "Agente": "803760",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "lope803760@siigo.com",
            "Lider": "Milady Andrea Guzman Garcia",
            "Nombre": "Karen Tatiana Lopez "
        },
        {
            "_id": "803263",
            "Agente": "803263",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "belt803263@siigo.com",
            "Lider": "Mario Alberto Rojas Gamboa",
            "Nombre": "Kevin Andres Beltran 0"
        },
        {
            "_id": "5633",
            "Agente": "5633",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Aran5633@siigo.com",
            "Lider": "Diana Maria Naranjo Mattheus",
            "Nombre": "Maria Guadalupe Aranda Zapata"
        },
        {
            "_id": "802340",
            "Agente": "802340",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "ayal802340@siigo.com",
            "Lider": "Estefania Sofia Barbosa Fajardo",
            "Nombre": "Lizeth Janney Ayala Zuluaga"
        },
        {
            "_id": "803901",
            "Agente": "803901",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "lope803901@siigo.com",
            "Lider": "Eddie Steven Hurtado Herrera",
            "Nombre": "Darwin Vinicio Lopez Arcos"
        },
        {
            "_id": "801197",
            "Agente": "801197",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "pill801197@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Ramon Ulpiano Pilla Salinas"
        },
        {
            "_id": "801963",
            "Agente": "801963",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mart801963@siigo.com",
            "Lider": "Francy Julieth Viracacha Gutierrez",
            "Nombre": "Daniela Martinez Soler"
        },
        {
            "_id": "801924",
            "Agente": "801924",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "barr801924@siigo.com",
            "Lider": "Hugo Ernesto Cruz Casallas",
            "Nombre": "Santiago Barrios Grijalba"
        },
        {
            "_id": "801171",
            "Agente": "801171",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ruiz801171@siigo.com",
            "Lider": "Aleida Bibiana Garcia Suarez",
            "Nombre": "Maria Camila Ruiz Uribe"
        },
        {
            "_id": "801034",
            "Agente": "801034",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "hern801034@siigo.com",
            "Lider": "Paola Cely Prada",
            "Nombre": "Maria Fernanda Hernandez Pulido"
        },
        {
            "_id": "801109",
            "Agente": "801109",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "kant801109@siigo.com",
            "Lider": "Ivan Elias Jusid Machado",
            "Nombre": "Mathias Kantor Edelstein"
        },
        {
            "_id": "3125",
            "Agente": "3125",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "mreyes@aspel.com.mx",
            "Lider": "Pedro Arturo Rico Martinez",
            "Nombre": "Martin Alberto Reyes De los Rios"
        },
        {
            "_id": "2926",
            "Agente": "2926",
            "Area": "People & Culture",
            "Division": "Siigo Mexico",
            "Email": "dcifuentes@aspel.com.mx",
            "Lider": "Blanca Alcantara Escobedo",
            "Nombre": "Daniela Cifuentes Lopez"
        },
        {
            "_id": "3403",
            "Agente": "3403",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "aaguilar@aspel.com.mx",
            "Lider": "Jorge Alfredo Huesca Martinez",
            "Nombre": "Altair Alfonso Aguilar Gonzalez"
        },
        {
            "_id": "801143",
            "Agente": "801143",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "diaz801143@siigo.com",
            "Lider": "Milady Andrea Guzman Garcia",
            "Nombre": "Alfonzina Isabel Diaz Guerrero"
        },
        {
            "_id": "802834",
            "Agente": "802834",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "guat802834@siigo.com",
            "Lider": "Nelsy Adriana Sanchez Ramirez",
            "Nombre": "Brigitte Juliana Guatame 0"
        },
        {
            "_id": "803346",
            "Agente": "803346",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "fore803346@siigo.com",
            "Lider": "Martha Isabel Diaz Gomez",
            "Nombre": "Valerit Jinneth Forero Saldaña"
        },
        {
            "_id": "803361",
            "Agente": "803361",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "gonz803361@siigo.com",
            "Lider": "Nelsy Adriana Sanchez Ramirez",
            "Nombre": "Andrea Gonzalez Ortega"
        },
        {
            "_id": "800378",
            "Agente": "800378",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "caji800378@siigo.com",
            "Lider": "Adriana Elizabeth Giron Parra",
            "Nombre": "Norma Constanza Cajica Rodriguez"
        },
        {
            "_id": "801630",
            "Agente": "801630",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sote801630@siigo.com",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre": "Karen Julieth Sotelo Gomez"
        },
        {
            "_id": "802296",
            "Agente": "802296",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "hinc802296@siigo.com",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre": "Andres Felipe Hincapie Navas"
        },
        {
            "_id": "802223",
            "Agente": "802223",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "aria802223@siigo.com",
            "Lider": "Alix Dayana Archila Castillo",
            "Nombre": "Itais Osiris Arias Acosta"
        },
        {
            "_id": "801893",
            "Agente": "801893",
            "Area": "Customer Success",
            "Division": "Siigo Perú",
            "Email": "cali801893@siigo.com",
            "Lider": "Jessica Consuelo Lopez Castillo",
            "Nombre": "Daleska Mayra Calixtro Lopez"
        },
        {
            "_id": "801883",
            "Agente": "801883",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "diaz801883@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Yeraldine Diaz Villa"
        },
        {
            "_id": "801647",
            "Agente": "801647",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "gonz801647@siigo.com",
            "Lider": "David Ortiz Diaz",
            "Nombre": "Claudia Cecilia Gonzalez Clavijo"
        },
        {
            "_id": "801504",
            "Agente": "801504",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "diaz801504@siigo.com",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre": "Cristian David Diaz Giraldo"
        },
        {
            "_id": "801887",
            "Agente": "801887",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "eche801887@siigo.com",
            "Lider": "David Alfredo Sanchez Bastidas",
            "Nombre": "Alexandra Echeverry Echeverry"
        },
        {
            "_id": "802090",
            "Agente": "802090",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "agud802090@siigo.com",
            "Lider": "Leidy Stefany Castillo 0",
            "Nombre": "Leidy Johanna Agudelo Arias"
        },
        {
            "_id": "800747",
            "Agente": "800747",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "faja800747@siigo.com",
            "Lider": "Edwin Fermin Gomez Quiñonez",
            "Nombre": "Neider Alejandro Fajardo Cardona"
        },
        {
            "_id": "801746",
            "Agente": "801746",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "more801746@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Cristian Armando Moreira Drouet"
        },
        {
            "_id": "801511",
            "Agente": "801511",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "mala801511@siigo.com",
            "Lider": "Angel Alfonso Arciniegas Guerrero",
            "Nombre": "Eulalia Gregoria Malave Leon"
        },
        {
            "_id": "801488",
            "Agente": "801488",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "rest801488@siigo.com",
            "Lider": "Miguel Ricardo Julio Babilonia",
            "Nombre": "Juan Esteban Restrepo Cardona"
        },
        {
            "_id": "801389",
            "Agente": "801389",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "sand801389@siigo.com",
            "Lider": "John Misael Martinez Montero",
            "Nombre": "Marlon Giovany Sandoval Mesa"
        },
        {
            "_id": "801257",
            "Agente": "801257",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "mont801257@siigo.com",
            "Lider": "Marcos Damian Lizarraga Elizalde",
            "Nombre": "Diego Andres Montoya Blandon"
        },
        {
            "_id": "801210",
            "Agente": "801210",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "dani801210@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Ana Sofia Dani Vargas"
        },
        {
            "_id": "801562",
            "Agente": "801562",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "tacu801562@siigo.com",
            "Lider": "Omar Eduardo Roa Quintero",
            "Nombre": "Jose Daniel Tacue Posada"
        },
        {
            "_id": "802105",
            "Agente": "802105",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "wilc802105@siigo.com",
            "Lider": "Eliana Maria Fajardo Prada",
            "Nombre": "Ingrid Paola Wilchez Leal"
        },
        {
            "_id": "3254",
            "Agente": "3254",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "jvillarreal@aspel.com.mx",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Juan Manuel Villarreal Ramirez"
        },
        {
            "_id": "801384",
            "Agente": "801384",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "herr801384@siigo.com",
            "Lider": "Andrea Catalina Vega Gomez",
            "Nombre": "Waldemar Herrera Rico"
        },
        {
            "_id": "801350",
            "Agente": "801350",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "salv801350@siigo.com",
            "Lider": "Martha Isabel Diaz Gomez",
            "Nombre": "Angie Lorena Salvo Tellez"
        },
        {
            "_id": "800389",
            "Agente": "800389",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "pehl800389@siigo.com",
            "Lider": "Carlos Arturo Huertas Salgado",
            "Nombre": "Emre Pehlivan 0"
        },
        {
            "_id": "112527",
            "Agente": "112527",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "zamb112527@siigo.com",
            "Lider": "Sandra Catalina Suarez Castillo",
            "Nombre": "Yarselyt Noilet Zambrano Arroyo"
        },
        {
            "_id": "803842",
            "Agente": "803842",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "garc803842@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Joel Alberto Garcia Chacon"
        },
        {
            "_id": "803266",
            "Agente": "803266",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "murc803266@siigo.com",
            "Lider": "Leidy Paola Rubiano Vargas",
            "Nombre": "Maidy Liseth Murcia 0"
        },
        {
            "_id": "804167",
            "Agente": "804167",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "port804167@siigo.com",
            "Lider": "Nelsy Adriana Sanchez Ramirez",
            "Nombre": "Paula Andrea Portocarrero Caicedo"
        },
        {
            "_id": "803272",
            "Agente": "803272",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "home803272@siigo.com",
            "Lider": "Nelson Juan Carlos Acosta Drouet",
            "Nombre": "Juan Sebastian Homes 0"
        },
        {
            "_id": "803614",
            "Agente": "803614",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "acev803614@siigo.com",
            "Lider": "Luz Katerine Maya Mogollón",
            "Nombre": "Maritza Jeraldin Acevedo Nope"
        },
        {
            "_id": "801399",
            "Agente": "801399",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cast801399@siigo.com",
            "Lider": "Alix Dayana Archila Castillo",
            "Nombre": "Karina Mayerly Castellanos Jaimes"
        },
        {
            "_id": "801921",
            "Agente": "801921",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "guat801921@siigo.com",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre": "Maria Jose Guatibonza Florez"
        },
        {
            "_id": "801386",
            "Agente": "801386",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mach801386@siigo.com",
            "Lider": "Juliana Castro Diaz",
            "Nombre": "Yeferson Estiben Machado Gaviria"
        },
        {
            "_id": "800768",
            "Agente": "800768",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "sola800768@siigo.com",
            "Lider": "Juan Carlos Sanchez Velez",
            "Nombre": "Valentina Solano Gutierrez"
        },
        {
            "_id": "801468",
            "Agente": "801468",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "onor801468@siigo.com",
            "Lider": "Miguel Angel Suárez Hernández",
            "Nombre": "Eyleen Stefany Oñoro Peña"
        },
        {
            "_id": "801486",
            "Agente": "801486",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "agam801486@siigo.com",
            "Lider": "Lizeth Janney Ayala Zuluaga",
            "Nombre": "Paula Andrea Agamez Paez"
        },
        {
            "_id": "800892",
            "Agente": "800892",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "port800892@siigo.com",
            "Lider": "Nataly Carolina Gonzalez Sandoval",
            "Nombre": "Juan Miguel Portilla Perez"
        },
        {
            "_id": "801075",
            "Agente": "801075",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cely801075@siigo.com",
            "Lider": "Johana Catalina Peña ",
            "Nombre": "Monica Alejandra Cely Ortiz"
        },
        {
            "_id": "802298",
            "Agente": "802298",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "loai802298@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Johan Sebastian Loaiza Lopez"
        },
        {
            "_id": "801860",
            "Agente": "801860",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "muno801860@siigo.com",
            "Lider": "Juan Pablo Carreño Patiño",
            "Nombre": "Stefanny Muñoz Gasca"
        },
        {
            "_id": "802229",
            "Agente": "802229",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vill802229@siigo.com",
            "Lider": "Diana Patricia Villarraga Forero",
            "Nombre": "Gregori Isaac Villanueva Villalobos"
        },
        {
            "_id": "802122",
            "Agente": "802122",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "espi802122@siigo.com",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre": "Angie Gabriela Espinosa Molina"
        },
        {
            "_id": "801388",
            "Agente": "801388",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "bece801388@siigo.com",
            "Lider": "Juan Carlos Buitrago Rios",
            "Nombre": "Cristian Estiven Becerra Sanabria"
        },
        {
            "_id": "801181",
            "Agente": "801181",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cast801181@siigo.com",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre": "Derly Cristina Castro Niño"
        },
        {
            "_id": "1826",
            "Agente": "1826",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "cguillen@aspel.com.mx",
            "Lider": "Hector Duque Vergara",
            "Nombre": "Cristobal Gomez Guillen"
        },
        {
            "_id": "800648",
            "Agente": "800648",
            "Area": "Marketing",
            "Division": "Siigo Ecuador",
            "Email": "rosa800648@siigo.com",
            "Lider": "Magda de Jesús Becerra Castañeda",
            "Nombre": "Ronald Miguel Rosales Palma"
        },
        {
            "_id": "800035",
            "Agente": "800035",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "aven800035@siigo.com",
            "Lider": "Vicky Alejandra Hernandez Mendez",
            "Nombre": "Edison Steve Avendaño Noy"
        },
        {
            "_id": "801667",
            "Agente": "801667",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "cris801667@siigo.com",
            "Lider": "Jairo Andres Mendieta 0",
            "Nombre": "Alberto Jose Cristancho Useche"
        },
        {
            "_id": "802275",
            "Agente": "802275",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "mach802275@siigo.com",
            "Lider": "Miguel Oswaldo Velasquez Fragiel",
            "Nombre": "Frankgel Ribely Machado Pantoja"
        },
        {
            "_id": "3550",
            "Agente": "3550",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "aflores@aspel.com.mx",
            "Lider": "Alejandro Lopez Manzo",
            "Nombre": "Alma Karina Flores Muñiz"
        },
        {
            "_id": "804014",
            "Agente": "804014",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rive804014@siigo.com",
            "Lider": "Jenny Paola Mendez 0",
            "Nombre": "Jesus Javier Rivero Escalona"
        },
        {
            "_id": "803284",
            "Agente": "803284",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "alga803284@siigo.com",
            "Lider": "Angie Camila Diaz Escobar",
            "Nombre": "Cheryn Daney Algarra "
        },
        {
            "_id": "803892",
            "Agente": "803892",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "rinc803892@siigo.com",
            "Lider": "Nelsy Adriana Sanchez Ramirez",
            "Nombre": "Lina Maria Rincon 0"
        },
        {
            "_id": "803261",
            "Agente": "803261",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "boni803261@siigo.com",
            "Lider": "Martha Isabel Diaz Gomez",
            "Nombre": "Ingrid Natalia Bonilla Baez"
        },
        {
            "_id": "803197",
            "Agente": "803197",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "mari803197@siigo.com",
            "Lider": "Hector Orlando Bogoya Aguilar",
            "Nombre": "Miguel Angel Marin Dedios"
        },
        {
            "_id": "802549",
            "Agente": "802549",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "rodr802549@siigo.com",
            "Lider": "Edith Angelica Gil Umbarila",
            "Nombre": "Jonathan Rodriguez Sanabria"
        },
        {
            "_id": "801625",
            "Agente": "801625",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gila801625@siigo.com",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre": "Valentina Gil Agudelo"
        },
        {
            "_id": "804533",
            "Agente": "804533",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cruz804533@siigo.com",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre": "Hammer Jair Cruz Guaje"
        },
        {
            "_id": "802290",
            "Agente": "802290",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "bedo802290@siigo.com",
            "Lider": "Mario Alberto Rojas Gamboa",
            "Nombre": "Carlos Eduardo Bedoya Valencia"
        },
        {
            "_id": "801616",
            "Agente": "801616",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "hern801616@siigo.com",
            "Lider": "Juvenal Reyes Silva",
            "Nombre": "Slendy Stephanny Hernandez Correa"
        },
        {
            "_id": "802137",
            "Agente": "802137",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "angu802137@siigo.com",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre": "Rosemary Angulo Borja"
        },
        {
            "_id": "800973",
            "Agente": "800973",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "guti800973@siigo.com",
            "Lider": "Yilmer Ramiro Guativa Rincon",
            "Nombre": "Andres Felipe Gutierrez Vivas"
        },
        {
            "_id": "801514",
            "Agente": "801514",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "boni801514@siigo.com",
            "Lider": "Harol Perez Giraldo",
            "Nombre": "Kenny Roger Bonilla Calderon"
        },
        {
            "_id": "800981",
            "Agente": "800981",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "cuzc800981@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Walter Efrain Cuzco Caicedo"
        },
        {
            "_id": "801077",
            "Agente": "801077",
            "Area": "Finance & Administration",
            "Division": "Siigo Uruguay",
            "Email": "uriw801077@siigo.com",
            "Lider": "Luisa Fernanda Caro Camargo",
            "Nombre": "Ariel Uri Wajswol"
        },
        {
            "_id": "801451",
            "Agente": "801451",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pere801451@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Deyssy Zulay Perez Forero"
        },
        {
            "_id": "801382",
            "Agente": "801382",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "guzm801382@siigo.com",
            "Lider": "Lida Katerine Puentes Navarro",
            "Nombre": "Alejandra Guzman Sanchez"
        },
        {
            "_id": "801209",
            "Agente": "801209",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "reye801209@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Marjorie Angelica Reyes Cedeño"
        },
        {
            "_id": "801247",
            "Agente": "801247",
            "Area": "Product",
            "Division": "Siigo Ecuador",
            "Email": "herr801247@siigo.com",
            "Lider": "Paola Denisse Veloz Garcia",
            "Nombre": "Melanie Angie Herrera Andrade"
        },
        {
            "_id": "800870",
            "Agente": "800870",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pere800870@siigo.com",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre": "Jessica Lorena Perez Barrera"
        },
        {
            "_id": "801396",
            "Agente": "801396",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "puen801396@siigo.com",
            "Lider": "Sandra Catalina Suarez Castillo",
            "Nombre": "Angie Natalia Puentes Puentes"
        },
        {
            "_id": "801587",
            "Agente": "801587",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "dura801587@siigo.com",
            "Lider": "Juan Carlos Sanchez Velez",
            "Nombre": "Sol Virginia Duran Gantiva"
        },
        {
            "_id": "801272",
            "Agente": "801272",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "mech801272@siigo.com",
            "Lider": "Silvia Zilah Fernandez Almeida",
            "Nombre": "Tania Menchaca Almeida"
        },
        {
            "_id": "801280",
            "Agente": "801280",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "jime801280@siigo.com",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre": "Angellys Cecilia Jimenez Aguas"
        },
        {
            "_id": "3466",
            "Agente": "3466",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "dsanchez@aspel.com.mx",
            "Lider": "Christian Macin Ramirez",
            "Nombre": "Dalia Yoselin Sanchez Espinosa"
        },
        {
            "_id": "111801",
            "Agente": "111801",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "navi111801@siigo.com",
            "Lider": "David Ortiz Diaz",
            "Nombre": "Jorge Alberto Navia Charria"
        },
        {
            "_id": "802837",
            "Agente": "802837",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "guti802837@siigo.com",
            "Lider": "Martha Isabel Diaz Gomez",
            "Nombre": "Laura Katerine Gutierrez Sanchez"
        },
        {
            "_id": "803893",
            "Agente": "803893",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "melo803893@siigo.com",
            "Lider": "Sonia Mabel Fernandez Calderon",
            "Nombre": "Shanon Nicol Melo 0"
        },
        {
            "_id": "803839",
            "Agente": "803839",
            "Area": "People & Culture",
            "Division": "Siigo Ecuador",
            "Email": "padi803839@siigo.com",
            "Lider": "Joan Sebastian Rodriguez 0",
            "Nombre": "Victor David Padilla 0"
        },
        {
            "_id": "804536",
            "Agente": "804536",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "garc804536@siigo.com",
            "Lider": "Milady Andrea Guzman Garcia",
            "Nombre": "Angie Paola Garcia Hurtado"
        },
        {
            "_id": "802604",
            "Agente": "802604",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "cald802604@siigo.com",
            "Lider": "Milady Andrea Guzman Garcia",
            "Nombre": "Neidy Vanesa Calderon Gomez"
        },
        {
            "_id": "802351",
            "Agente": "802351",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sant802351@siigo.com",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre": "Yerly Paola Santamaria Riveros"
        },
        {
            "_id": "800374",
            "Agente": "800374",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "aran800374@siigo.com",
            "Lider": "Hernan Dario Parra Osorio",
            "Nombre": "Viviana Andrea Aranda Pinilla"
        },
        {
            "_id": "801618",
            "Agente": "801618",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "zamb801618@siigo.com",
            "Lider": "Joan Sebastian Rodriguez 0",
            "Nombre": "Julieta Zambrano Penagos"
        },
        {
            "_id": "801529",
            "Agente": "801529",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "aria801529@siigo.com",
            "Lider": "Diego Andres Montoya Blandon",
            "Nombre": "Juan Jose Arias Rodas"
        },
        {
            "_id": "800779",
            "Agente": "800779",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "hern800779@siigo.com",
            "Lider": "Andrea Duque Botero",
            "Nombre": "Cristian David Hernandez Pertuz"
        },
        {
            "_id": "801847",
            "Agente": "801847",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "meza801847@siigo.com",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre": "Maryuris Sarife Meza Fernandez"
        },
        {
            "_id": "800827",
            "Agente": "800827",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "gale800827@siigo.com",
            "Lider": "Leidy Paola Rubiano Vargas",
            "Nombre": "Angie Vanessa Galeano Lara"
        },
        {
            "_id": "802166",
            "Agente": "802166",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cald802166@siigo.com",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre": "Luisa Fernanda Calderon Torres"
        },
        {
            "_id": "802029",
            "Agente": "802029",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rive802029@siigo.com",
            "Lider": "Jenny Paola Mendez 0",
            "Nombre": "Imna Nohemi Rivera Perez"
        },
        {
            "_id": "801650",
            "Agente": "801650",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "mend801650@siigo.com",
            "Lider": "Juan David Correa Malagon",
            "Nombre": "Javier Eduardo Mendoza Castillo"
        },
        {
            "_id": "802255",
            "Agente": "802255",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "zapa802255@siigo.com",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre": "Karen Vanessa Zapata Candela"
        },
        {
            "_id": "801681",
            "Agente": "801681",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sabo801681@siigo.com",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre": "Chirlly Esneidy Sabogal Castro"
        },
        {
            "_id": "801462",
            "Agente": "801462",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "paez801462@siigo.com",
            "Lider": "Jessica Paola Paez Quintero",
            "Nombre": "Andrea Paez Davila"
        },
        {
            "_id": "800868",
            "Agente": "800868",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "hern800868@siigo.com",
            "Lider": "Carlos Roberto Alvarado Mancera",
            "Nombre": "Fabian Andres Hernandez Fajardo"
        },
        {
            "_id": "2645",
            "Agente": "2645",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "hguzman@aspel.com.mx",
            "Lider": "Bismark Ivan Rios Campos",
            "Nombre": "Heira Guzman Hernandez"
        },
        {
            "_id": "801751",
            "Agente": "801751",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "cont801751@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Anny Gabriela Contreras Pincay"
        },
        {
            "_id": "2133",
            "Agente": "2133",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "mespinosa@aspel.com.mx",
            "Lider": "Adair Blanco Landin",
            "Nombre": "Montserrat Espinosa Galindo"
        },
        {
            "_id": "801621",
            "Agente": "801621",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "meza801621@siigo.com",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre": "Ivana Teresa Meza de la Rosa"
        },
        {
            "_id": "800571",
            "Agente": "800571",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "mata800571@siigo.com",
            "Lider": "José Manuel Aguayo Escandon",
            "Nombre": "Diana Michelle Matamoros Del Rosario"
        },
        {
            "_id": "801875",
            "Agente": "801875",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "novo801875@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Paula Karina Novoa Puentes"
        },
        {
            "_id": "801302",
            "Agente": "801302",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sota801302@siigo.com",
            "Lider": "Andrea Zuluaga Uribe",
            "Nombre": "William Jesus Sotaquira Ayala"
        },
        {
            "_id": "801226",
            "Agente": "801226",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "rive801226@siigo.com",
            "Lider": "Diana Patricia Atehortua Castaño",
            "Nombre": "Mauricio Andres Rivera Guerrero"
        },
        {
            "_id": "801894",
            "Agente": "801894",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "garc801894@siigo.com",
            "Lider": "Leida Adriana Rico Herrera",
            "Nombre": "Johana Andrea Garcia Guevara"
        },
        {
            "_id": "3566",
            "Agente": "3566",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "curibe@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Cinthya Uribe Rioverde"
        },
        {
            "_id": "2570",
            "Agente": "2570",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "kmuriedas@aspel.com.mx",
            "Lider": "Marisol Vargas Cortes",
            "Nombre": "Karen Cecilia Muriedas Hernandez"
        },
        {
            "_id": "3404",
            "Agente": "3404",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jvaldivia@aspel.com.mx",
            "Lider": "Jorge Alfredo Huesca Martinez",
            "Nombre": "Jose Ruben Valdivia Espinosa"
        },
        {
            "_id": "3409",
            "Agente": "3409",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "lmartinez@aspel.com.mx",
            "Lider": "Jorge Alfredo Huesca Martinez",
            "Nombre": "Luis Antonio Martinez Sosa"
        },
        {
            "_id": "3553",
            "Agente": "3553",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "mrosales@aspel.com.mx",
            "Lider": "Adriana Rodriguez Castro",
            "Nombre": "Maria Citlali Ramos Rosales"
        },
        {
            "_id": "804573",
            "Agente": "804573",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "urre804573@siigo.com",
            "Lider": "Nelsy Adriana Sanchez Ramirez",
            "Nombre": "Darlin Dayana Urrea Ceballos"
        },
        {
            "_id": "803378",
            "Agente": "803378",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "vela803378@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Daniela Del Carmen Velandia "
        },
        {
            "_id": "2567",
            "Agente": "2567",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "cmonterrey@aspel.com.mx",
            "Lider": "Rene Gerardo Paredes Alcantara",
            "Nombre": "Carlos Monterrey Camacho"
        },
        {
            "_id": "1536",
            "Agente": "1536",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "balbarran@aspel.com.mx",
            "Lider": "Pedro Arturo Rico Martinez",
            "Nombre": "Blanca Minerva Albarran Tenorio"
        },
        {
            "_id": "3293",
            "Agente": "3293",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "rchim@aspel.com.mx",
            "Lider": "Belem Chavez Ruiz",
            "Nombre": "Milddre Del Rosario Romero Chim"
        },
        {
            "_id": "2886",
            "Agente": "2886",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jeuan@aspel.com.mx",
            "Lider": "Gerardo Enrique Aldecua Matamoros",
            "Nombre": "Juan Ricardo Euan Pool"
        },
        {
            "_id": "800314",
            "Agente": "800314",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "muno800314@siigo.com",
            "Lider": "Carlos German Lopez Murcia",
            "Nombre": "Camilo Andres Muñoz Guerra"
        },
        {
            "_id": "111956",
            "Agente": "111956",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rami111956@siigo.com",
            "Lider": "David Ortiz Diaz",
            "Nombre": "Manuel Enrique Ramirez Rodriguez"
        },
        {
            "_id": "802936",
            "Agente": "802936",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "sanc802936@siigo.com",
            "Lider": "Walter Antonio Muñoz Antepara",
            "Nombre": "Saraith Virginia Sanchez Marquez"
        },
        {
            "_id": "800814",
            "Agente": "800814",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "briz800814@siigo.com",
            "Lider": "Aura Cristina Rodriguez Manrique",
            "Nombre": "Yanine Auribel Brizuela Garcia"
        },
        {
            "_id": "803273",
            "Agente": "803273",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "varg803273@siigo.com",
            "Lider": "Jose Daniel Huerfano Martinez",
            "Nombre": "Nasly Yulieth Vargas 0"
        },
        {
            "_id": "802835",
            "Agente": "802835",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "corr802835@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Jhordy Yesid Correa Quintana"
        },
        {
            "_id": "804026",
            "Agente": "804026",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "gila804026@siigo.com",
            "Lider": "Jose Daniel Huerfano Martinez",
            "Nombre": "Valery Gil Agudelo"
        },
        {
            "_id": "804173",
            "Agente": "804173",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "hern804173@siigo.com",
            "Lider": "Karen Alejandra Casas Mesa",
            "Nombre": "Laura Viviana Hernandez Pava"
        },
        {
            "_id": "802574",
            "Agente": "802574",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "muno802574@siigo.com",
            "Lider": "Claudia Cecilia Gonzalez Clavijo",
            "Nombre": "Maria Camila Muñoz 0"
        },
        {
            "_id": "804401",
            "Agente": "804401",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "vela804401@siigo.com",
            "Lider": "Paola Andrea Arango Ospina",
            "Nombre": "Andres Felipe Velasco Bravo"
        },
        {
            "_id": "803275",
            "Agente": "803275",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "virg803275@siigo.com",
            "Lider": "Nelson Juan Carlos Acosta Drouet",
            "Nombre": "Laura Tatiana Virguez "
        },
        {
            "_id": "804170",
            "Agente": "804170",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "vare801005@siigo.com",
            "Lider": "Katherine Ayala Garcia",
            "Nombre": "Camila Stefany Varela Ladino"
        },
        {
            "_id": "803347",
            "Agente": "803347",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "gonz803347@siigo.com",
            "Lider": "Erika Mildred Avila Olaya",
            "Nombre": "Flor Maria Gonzalez 0"
        },
        {
            "_id": "803368",
            "Agente": "803368",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "angu803368@siigo.com",
            "Lider": "Nelsy Adriana Sanchez Ramirez",
            "Nombre": "Leidy Tatiana Angulo 0"
        },
        {
            "_id": "802130",
            "Agente": "802130",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pere802130@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Mildred Perez Ramirez"
        },
        {
            "_id": "112078",
            "Agente": "112078",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "herr112078@siigo.com",
            "Lider": "Felipe Ricardo Reyes Lega",
            "Nombre": "Natalia Herrera Rey"
        },
        {
            "_id": "802893",
            "Agente": "802893",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "esqu802893@siigo.com",
            "Lider": "Juan Carlos Valdes Martin",
            "Nombre": "Angie Tatiana Esquinas Alfonso"
        },
        {
            "_id": "33772",
            "Agente": "33772",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "coll33772@siigo.com",
            "Lider": "Edison Arley Plaza Marin",
            "Nombre": "Cristian Andres Collazos Ruiz"
        },
        {
            "_id": "803776",
            "Agente": "803776",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pach803776@siigo.com",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre": "Leidy Johana Pacheco Salcedo"
        },
        {
            "_id": "800795",
            "Agente": "800795",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "varo800795@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Brenda Johanna Varon Lombana"
        },
        {
            "_id": "112524",
            "Agente": "112524",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "belt112524@siigo.com",
            "Lider": "Jimena Marcela Prieto Bohorquez",
            "Nombre": "Danna Fernanda Beltran Guevara"
        },
        {
            "_id": "33774",
            "Agente": "33774",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rive33774@siigo.com",
            "Lider": "Jhon Emmanuel Zuñiga Paredes",
            "Nombre": "Cesar Julian Rivera Benitez"
        },
        {
            "_id": "2731",
            "Agente": "2731",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "dreyes@aspel.com.mx",
            "Lider": "Peter Anderson Vargas Grajales",
            "Nombre": "Daniel Noe Pat Reyes"
        },
        {
            "_id": "1822",
            "Agente": "1822",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "agarduno@aspel.com.mx",
            "Lider": "Enna Maria Creus Castellanos",
            "Nombre": "Addy Guadalupe Garduño Miranda"
        },
        {
            "_id": "802916",
            "Agente": "802916",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "aran802916@siigo.com",
            "Lider": "Gloria Estela Osorio Montoya",
            "Nombre": "Yeisker Enrique Aranguren Fernandez"
        },
        {
            "_id": "801668",
            "Agente": "801668",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "jaim801668@siigo.com",
            "Lider": "Jairo Andres Mendieta 0",
            "Nombre": "David Alejandro Jaimes Romero"
        },
        {
            "_id": "801637",
            "Agente": "801637",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gonz801637@siigo.com",
            "Lider": "Angie Camila Diaz Escobar",
            "Nombre": "Andrea Sofhya Gonzalez Martinez"
        },
        {
            "_id": "800113",
            "Agente": "800113",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "carra800113@siigo.com",
            "Lider": "Adriana Elizabeth Giron Parra",
            "Nombre": "Alejandra Maria Carrasco Castañeda"
        },
        {
            "_id": "112293",
            "Agente": "112293",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "mora112293@siigo.com",
            "Lider": "Rocxy Daniela Fernandez Fernandez",
            "Nombre": "Wendy Catherin Mora Cruz"
        },
        {
            "_id": "803375",
            "Agente": "803375",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "belt803375@siigo.com",
            "Lider": "Diana Carolina Perdomo Rodriguez",
            "Nombre": "Laura Alejandra Beltran Martinez"
        },
        {
            "_id": "112009",
            "Agente": "112009",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "rodr112009@siigo.com",
            "Lider": "Rocxy Daniela Fernandez Fernandez",
            "Nombre": "Maria Elena Rodriguez Moreno"
        },
        {
            "_id": "802842",
            "Agente": "802842",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "gach802842@siigo.com",
            "Lider": "Leydi Julieth Sosa Parra",
            "Nombre": "Karen Lizeth Gacharna 0"
        },
        {
            "_id": "802833",
            "Agente": "802833",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "leon802833@siigo.com",
            "Lider": "Ahylin Dahyan Jamaica Mendez",
            "Nombre": "Ana Valentina Leon Salas"
        },
        {
            "_id": "803262",
            "Agente": "803262",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "more803262@siigo.com",
            "Lider": "Ahylin Dahyan Jamaica Mendez",
            "Nombre": "Melanny Mildred Moreno 0"
        },
        {
            "_id": "803820",
            "Agente": "803820",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "acev803820@siigo.com",
            "Lider": "Sonia Mabel Fernandez Calderon",
            "Nombre": "Xiomara Juliet Acevedo 0"
        },
        {
            "_id": "3719",
            "Agente": "3719",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "bzamudio@aspel.com.mx",
            "Lider": "Marisela Yasmin Franco Cruz",
            "Nombre": "Brenda Berenice Zamudio "
        },
        {
            "_id": "804010",
            "Agente": "804010",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "melo804010@siigo.com",
            "Lider": "Aura Cristina Rodriguez Manrique",
            "Nombre": "Yiseth Nayibe Melo Forero"
        },
        {
            "_id": "802701",
            "Agente": "802701",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "zapa802701@siigo.com",
            "Lider": "Nelsy Adriana Sanchez Ramirez",
            "Nombre": "Kimberly Yagara Zapata 0"
        },
        {
            "_id": "800693",
            "Agente": "800693",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "more800693@siigo.com",
            "Lider": "Martin Leonel Romero Vega",
            "Nombre": "Javier Andres Moreno Escamilla"
        },
        {
            "_id": "801184",
            "Agente": "801184",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "monr801184@siigo.com",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre": "Nidian Carolina Monroy Serrano"
        },
        {
            "_id": "27883",
            "Agente": "27883",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "niet27883@siigo.com",
            "Lider": "Wendy Dayana Rojas Rojas",
            "Nombre": "Mario Alberto Nieto Bernal"
        },
        {
            "_id": "802225",
            "Agente": "802225",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "muna802225@siigo.com",
            "Lider": "Cristhian David Niño Barrera",
            "Nombre": "Deisy Marley Munar Castiblanco"
        },
        {
            "_id": "802497",
            "Agente": "802497",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "beja802497@siigo.com",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre": "Suly Johanna Bejarano Loaiza"
        },
        {
            "_id": "800411",
            "Agente": "800411",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rive800411@siigo.com",
            "Lider": "James Alberto Valencia Caicedo",
            "Nombre": "Anyela Marcela Rivera Mera"
        },
        {
            "_id": "112488",
            "Agente": "112488",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "pala112488@siigo.com",
            "Lider": "Sandra Catalina Suarez Castillo",
            "Nombre": "Alexandra Palacio Sandoval"
        },
        {
            "_id": "803653",
            "Agente": "803653",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cepe803653@siigo.com",
            "Lider": "Francy Julieth Viracacha Gutierrez",
            "Nombre": "Laura Natali Cepeda Vidal"
        },
        {
            "_id": "803340",
            "Agente": "803340",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "hurt803340@siigo.com",
            "Lider": "Juan Jose Correa Acosta",
            "Nombre": "Luz Adriana Hurtado Navarro"
        },
        {
            "_id": "800669",
            "Agente": "800669",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "huez800669@siigo.com",
            "Lider": "Liliana Ramirez Vanegas",
            "Nombre": "Juan Diego Huezo Galindo"
        },
        {
            "_id": "802932",
            "Agente": "802932",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "agui802932@siigo.com",
            "Lider": "Cristhian David Niño Barrera",
            "Nombre": "Katerine Esther Aguirre Velasquez"
        },
        {
            "_id": "3186",
            "Agente": "3186",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "msantos@aspel.com.mx",
            "Lider": "Brandon Enrique Gomez Rosales",
            "Nombre": "Marisol Santos Zamora"
        },
        {
            "_id": "1996",
            "Agente": "1996",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "rramirez@aspel.com.mx",
            "Lider": "Pedro Arturo Rico Martinez",
            "Nombre": "Rene Maclovio Ramirez Espino"
        },
        {
            "_id": "405",
            "Agente": "405",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "cbarraza@aspel.com.mx",
            "Lider": "Enna Maria Creus Castellanos",
            "Nombre": "Claudia Alejandra Barraza Aguilar"
        },
        {
            "_id": "3002",
            "Agente": "3002",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "fcontreras@aspel.com.mx",
            "Lider": "Brian Yukio Nishizaki Simon",
            "Nombre": "Fernando Contreras Ocaña"
        },
        {
            "_id": "2216",
            "Agente": "2216",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "vhuerta@aspel.com.mx",
            "Lider": "Vicente Estrada Sanchez",
            "Nombre": "Victor Hugo Huerta Lopez"
        },
        {
            "_id": "3575",
            "Agente": "3575",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "cbarcenas@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Cesar Arturo Barcenas Hernandez"
        },
        {
            "_id": "3545",
            "Agente": "3545",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "alopez@aspel.com.mx",
            "Lider": "Jesus Alfonso Leyra Galan",
            "Nombre": "Angel Daniel Lopez Vazquez"
        },
        {
            "_id": "804180",
            "Agente": "804180",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "boni804180@siigo.com",
            "Lider": "Yuleidy Josefina Briceño Fernandez",
            "Nombre": "Juana Yris Bonifertty Amaya"
        },
        {
            "_id": "802565",
            "Agente": "802565",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "carr802565@siigo.com",
            "Lider": "Juan David Correa Malagon",
            "Nombre": "Yaher Oscar Carrillo Domador"
        },
        {
            "_id": "803353",
            "Agente": "803353",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "guer803353@siigo.com",
            "Lider": "Aleida Bibiana Garcia Suarez",
            "Nombre": "Rosiris Marialec Guerrero Gonzalez"
        },
        {
            "_id": "3581",
            "Agente": "3581",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "nbernal@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Natally Joan Bernal Romero"
        },
        {
            "_id": "800858",
            "Agente": "800858",
            "Area": "Tech",
            "Division": "Siigo Uruguay",
            "Email": "liza800858@siigo.com",
            "Lider": "Hector Enrique Becerra Perez",
            "Nombre": "Marcos Damian Lizarraga Elizalde"
        },
        {
            "_id": "3675",
            "Agente": "3675",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "kurriza@aspel.com.mx",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Karla Ivette Urriza Sanchez"
        },
        {
            "_id": "801180",
            "Agente": "801180",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "meza801180@siigo.com",
            "Lider": "Yasser Mohamed Chain Martinez",
            "Nombre": "Andres Mauricio Meza Mendez"
        },
        {
            "_id": "804152",
            "Agente": "804152",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "jord804152@siigo.com",
            "Lider": "Glorizeth Ardila Cañon",
            "Nombre": "Santiago Jordan Vargas"
        },
        {
            "_id": "803267",
            "Agente": "803267",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "orti803267@siigo.com",
            "Lider": "Claudia Marcela Cano Forero",
            "Nombre": "Narschal Mariam Ortiz 0"
        },
        {
            "_id": "803269",
            "Agente": "803269",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "belt803269@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Wendy Michell Beltran 0"
        },
        {
            "_id": "804075",
            "Agente": "804075",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "soli804075@siigo.com",
            "Lider": "Alberto Guerrero Garcia",
            "Nombre": "Kevin Noe Solis Arias"
        },
        {
            "_id": "3718",
            "Agente": "3718",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "aestrada@aspel.com.mx",
            "Lider": "Oscar Rodrigo Navarro Hernandez",
            "Nombre": "Araceli Ramirez "
        },
        {
            "_id": "804128",
            "Agente": "804128",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "puer804128@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Jennifer Paola Puerto "
        },
        {
            "_id": "3555",
            "Agente": "3555",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "arojas@aspel.com.mx",
            "Lider": "Luis Alejandro Salgado Vergara",
            "Nombre": "Anayeli Rojas Mejia"
        },
        {
            "_id": "803441",
            "Agente": "803441",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rodr803441@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Helen Dayana Rodriguez 0"
        },
        {
            "_id": "802816",
            "Agente": "802816",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bedo802816@siigo.com",
            "Lider": "Alix Dayana Archila Castillo",
            "Nombre": "Luis Angel Bedoya Parra"
        },
        {
            "_id": "112259",
            "Agente": "112259",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "diaz112259@siigo.com",
            "Lider": "Jairo Enrique Corzo Lizarazo",
            "Nombre": "Marien Carolina Diaz Garay"
        },
        {
            "_id": "2284",
            "Agente": "2284",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "lalvarez@aspel.com.mx",
            "Lider": "Christian Macin Ramirez",
            "Nombre": "Lot Alvarez Diaz"
        },
        {
            "_id": "3508",
            "Agente": "3508",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "lrrodriguez@aspel.com.mx",
            "Lider": "Arturo Alvarez Martinez",
            "Nombre": "Luis Rodriguez Rodriguez"
        },
        {
            "_id": "112127",
            "Agente": "112127",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "alva112127@siigo.com",
            "Lider": "Juan Carlos Buitrago Rios",
            "Nombre": "Jorge Hernan Alvaran Ospina"
        },
        {
            "_id": "3676",
            "Agente": "3676",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "bmurguia@aspel.com.mx",
            "Lider": "Agustin Hernandez Lopez",
            "Nombre": "Brenely Jaquez Murguia"
        },
        {
            "_id": "801400",
            "Agente": "801400",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "boli801400@siigo.com",
            "Lider": "Yesika Viviana Garcia Niño",
            "Nombre": "Isledia Josefina Bolivar 0"
        },
        {
            "_id": "800267",
            "Agente": "800267",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "cast800267@siigo.com",
            "Lider": "Lina Maria Buitrago Herrera",
            "Nombre": "Andreina De la Guadalupe Castro Radaelli"
        },
        {
            "_id": "803764",
            "Agente": "803764",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "muri803764@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Marie Cristhiam Murillo Lino"
        },
        {
            "_id": "112357",
            "Agente": "112357",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rico112357@siigo.com",
            "Lider": "Miguel Oswaldo Velasquez Fragiel",
            "Nombre": "Anyeliz Beatriz Rico Torres"
        },
        {
            "_id": "3724",
            "Agente": "3724",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "tmartinez@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Tania Martinez Tapia"
        },
        {
            "_id": "802735",
            "Agente": "802735",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "leon802735@siigo.com",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre": "Brayan Enrique Leon Aguilar"
        },
        {
            "_id": "112010",
            "Agente": "112010",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "raig112010@siigo.com",
            "Lider": "Rocxy Daniela Fernandez Fernandez",
            "Nombre": "Sergio Alberto Raigosa Hernandez"
        },
        {
            "_id": "804391",
            "Agente": "804391",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "vall804291@siigo.com",
            "Lider": "Katherine Ayala Garcia",
            "Nombre": "Jacobo Jose Vallejo Urueña"
        },
        {
            "_id": "804378",
            "Agente": "804378",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "narv804378@siigo.com",
            "Lider": "Melissa Cristina Russo Coba",
            "Nombre": "Daniela Narvaez Ramirez"
        },
        {
            "_id": "804421",
            "Agente": "804421",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "rodr804421@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Jeimy Lorena Rodriguez Abril"
        },
        {
            "_id": "802814",
            "Agente": "802814",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cruz802814@siigo.com",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre": "Omar Santiago Cruz Muñoz"
        },
        {
            "_id": "3532",
            "Agente": "3532",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "vramos@aspel.com.mx",
            "Lider": "Ivan Alejandro Roa Franco",
            "Nombre": "Victor Hugo Ramos "
        },
        {
            "_id": "3554",
            "Agente": "3554",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jgalvan@aspel.com.mx",
            "Lider": "Luis Alejandro Salgado Vergara",
            "Nombre": "Juan Francisco Galvan Gutierrez"
        },
        {
            "_id": "804069",
            "Agente": "804069",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "gonz804069@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Judy Cristina Gonzalez Barriga"
        },
        {
            "_id": "803295",
            "Agente": "803295",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "suar803295@siigo.com",
            "Lider": "Andrea Catalina Vega Gomez",
            "Nombre": "Aura Maria Suarez 0"
        },
        {
            "_id": "804068",
            "Agente": "804068",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "carv804068@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Nathalia Andrea Carvajal Silvera"
        },
        {
            "_id": "3127",
            "Agente": "3127",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "mpech@aspel.com.mx",
            "Lider": "Reyna Del Rosario Camal Uc",
            "Nombre": "Marisela Del Carmen Pech Tamayo"
        },
        {
            "_id": "3189",
            "Agente": "3189",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "mariaz@aspel.com.mx",
            "Lider": "Bismark Ivan Rios Campos",
            "Nombre": "Mariazell Arias Zepeda"
        },
        {
            "_id": "802649",
            "Agente": "802649",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "reye802649@siigo.com",
            "Lider": "Santiago Jose Porras Brieva",
            "Nombre": "Yasnaia Ilithya De La Caridad Reyes Coll"
        },
        {
            "_id": "802331",
            "Agente": "802331",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "gonz802331@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Jonathan Jose Gonzalez Varela"
        },
        {
            "_id": "3567",
            "Agente": "3567",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "ecarmona@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Elia Viridiana Carmona Gonzalez"
        },
        {
            "_id": "801478",
            "Agente": "801478",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rome801478@siigo.com",
            "Lider": "Janna Elizabeth Barreto Castro",
            "Nombre": "Jose Gregorio Romero Cordova"
        },
        {
            "_id": "3570",
            "Agente": "3570",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "gdurazo@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Irma Geovanna Durazo Cortez"
        },
        {
            "_id": "3674",
            "Agente": "3674",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "paguirre@aspel.com.mx",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Polet Magali Aguirre Valdez"
        },
        {
            "_id": "802926",
            "Agente": "802926",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "para802926@siigo.com",
            "Lider": "Cristhian David Niño Barrera",
            "Nombre": "Luis Francisco Parada Rigaud"
        },
        {
            "_id": "803285",
            "Agente": "803285",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "sana803285@siigo.com",
            "Lider": "Diana Carolina Perez Cerinza",
            "Nombre": "Maria Andreina Sanabria Quevedo"
        },
        {
            "_id": "3457",
            "Agente": "3457",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "acapetillo@aspel.com.mx",
            "Lider": "Virginia Guadalupe Ramirez Hernandez",
            "Nombre": "Abril Capetillo Loeza"
        },
        {
            "_id": "112430",
            "Agente": "112430",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "guti112430@siigo.com",
            "Lider": "Manuel Enrique Ramirez Rodriguez",
            "Nombre": "Fernando Gutierrez Paramio"
        },
        {
            "_id": "803371",
            "Agente": "803371",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "orop803371@siigo.com",
            "Lider": "Hugo Ernesto Cruz Casallas",
            "Nombre": "Rosanny Josefina Oropeza Marquez"
        },
        {
            "_id": "3716",
            "Agente": "3716",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "amonzalvo@aspel.com.mx",
            "Lider": "Christian Macin Ramirez",
            "Nombre": "Adrian Alberto Monzalvo Rodríguez"
        },
        {
            "_id": "803435",
            "Agente": "803435",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "mart803435@siigo.com",
            "Lider": "Diana Carolina Perdomo Rodriguez",
            "Nombre": "John Alexander Martinez Salanueva"
        },
        {
            "_id": "800482",
            "Agente": "800482",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "herr800482@siigo.com",
            "Lider": "Rocxy Daniela Fernandez Fernandez",
            "Nombre": "Diana Ximena Herrera Sanchez"
        },
        {
            "_id": "28325",
            "Agente": "28325",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "Fern28325@siigo.com",
            "Lider": "Diana Carolina Perdomo Rodriguez",
            "Nombre": "Rocxy Daniela Fernandez Fernandez"
        },
        {
            "_id": "803259",
            "Agente": "803259",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "perd803259@siigo.com",
            "Lider": "Carlos Arturo Huertas Salgado",
            "Nombre": "Diana Carolina Perdomo Rodriguez"
        },
        {
            "_id": "804065",
            "Agente": "804065",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "agud804065@siigo.com",
            "Lider": "Aura Cristina Rodriguez Manrique",
            "Nombre": "Angel Stefanny Agudelo Conde"
        },
        {
            "_id": "802812",
            "Agente": "802812",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "andr802812@siigo.com",
            "Lider": "Mario Fernando Nuñez Santos",
            "Nombre": "Laura Vanessa Andrade Hurtado"
        },
        {
            "_id": "804405",
            "Agente": "804405",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pard804405@siigo.com",
            "Lider": "Magda Lilian Leal Rozo",
            "Nombre": "Lizeth Johanna Pardo Espinosa"
        },
        {
            "_id": "802650",
            "Agente": "802650",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "turr802650@siigo.com",
            "Lider": "Luz Derly Bernal Malaver",
            "Nombre": "Leidy Vanessa Turriago 0"
        },
        {
            "_id": "803753",
            "Agente": "803753",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "liza803753@siigo.com",
            "Lider": "Peter Anderson Vargas Grajales",
            "Nombre": "Gisel Tatiana Lizarazo 0"
        },
        {
            "_id": "803902",
            "Agente": "803902",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "colm803902@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Charly Jose Colmenares Hernandez"
        },
        {
            "_id": "803329",
            "Agente": "803329",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "esco803329@siigo.com",
            "Lider": "Nelsy Adriana Sanchez Ramirez",
            "Nombre": "Dayana Escobar 0"
        },
        {
            "_id": "804166",
            "Agente": "804166",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "ruiz804166@siigo.com",
            "Lider": "Diana Carolina Perdomo Rodriguez",
            "Nombre": "Maria del Pilar Ruiz Buitrago"
        },
        {
            "_id": "191429",
            "Agente": "191429",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Monc191429@siigo.com",
            "Lider": "Liliana Patricia Giraldo Gomez",
            "Nombre": "Diana Mercedes Moncada Peraza"
        },
        {
            "_id": "801612",
            "Agente": "801612",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "fran801612@siigo.com",
            "Lider": "Leida Adriana Rico Herrera",
            "Nombre": "Jose Alexander Franco Oviedo"
        },
        {
            "_id": "3263",
            "Agente": "3263",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jcamacho@aspel.com.mx",
            "Lider": "Maria Teresa Vazquez Garza",
            "Nombre": "Jorge Luis Aguilar Camacho"
        },
        {
            "_id": "2564",
            "Agente": "2564",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "inicolas@aspel.com.mx",
            "Lider": "Rene Gerardo Paredes Alcantara",
            "Nombre": "Ivan Nicolas Osorio"
        },
        {
            "_id": "800602",
            "Agente": "800602",
            "Area": "Product",
            "Division": "Siigo Ecuador",
            "Email": "cast800602@siigo.com",
            "Lider": "Luis Felipe Zea Obando",
            "Nombre": "Jaime Rodrigo Castells Perez"
        },
        {
            "_id": "800666",
            "Agente": "800666",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "plaz800666@siigo.com",
            "Lider": "David Ortiz Diaz",
            "Nombre": "Oscar Francisco Plaza Giler"
        },
        {
            "_id": "111876",
            "Agente": "111876",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "bust111876@siigo.com",
            "Lider": "Marisela Yasmin Franco Cruz",
            "Nombre": "Keibert Antonio Bustos Ortega"
        },
        {
            "_id": "802723",
            "Agente": "802723",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "meji802723@siigo.com",
            "Lider": "Cristhian David Niño Barrera",
            "Nombre": "Akira Oscar Mejias Molero"
        },
        {
            "_id": "112432",
            "Agente": "112432",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rome112432@siigo.com",
            "Lider": "Edison Arley Plaza Marin",
            "Nombre": "Juan Diego Romero Bocourt"
        },
        {
            "_id": "800274",
            "Agente": "800274",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "cris800274@siigo.com",
            "Lider": "Ana Maria Ramirez Sanchez",
            "Nombre": "Melany Cristal "
        },
        {
            "_id": "3720",
            "Agente": "3720",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "emcorona@aspel.com.mx",
            "Lider": "Marisela Yasmin Franco Cruz",
            "Nombre": "Eline Michelle Corona "
        },
        {
            "_id": "802908",
            "Agente": "802908",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "nino802908@siigo.com",
            "Lider": "Nelsy Adriana Sanchez Ramirez",
            "Nombre": "Gloria Alejandra Niño 0"
        },
        {
            "_id": "804409",
            "Agente": "804409",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cons804409@siigo.com",
            "Lider": "Sonia Mabel Fernandez Calderon",
            "Nombre": "Angela Chiptu Constan Medero"
        },
        {
            "_id": "803436",
            "Agente": "803436",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "carv803436@siigo.com",
            "Lider": "Kristian David Valero Salamanca",
            "Nombre": "Laura Maria Carvajal Taborda"
        },
        {
            "_id": "803821",
            "Agente": "803821",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "boli803821@siigo.com",
            "Lider": "Sonia Mabel Fernandez Calderon",
            "Nombre": "Ledy Laura Bolivar 0"
        },
        {
            "_id": "14300",
            "Agente": "14300",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "asca14300@siigo.com",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre": "Angelica Maria Ascanio Rueda"
        },
        {
            "_id": "802032",
            "Agente": "802032",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "olmo802032@siigo.com",
            "Lider": "Alix Dayana Archila Castillo",
            "Nombre": "Angie Carolina Olmos Rodriguez"
        },
        {
            "_id": "801365",
            "Agente": "801365",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "tede801365@siigo.com",
            "Lider": "Ivan Elias Jusid Machado",
            "Nombre": "Angel Nicolas Tedesco "
        },
        {
            "_id": "800156",
            "Agente": "800156",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "diaz800156@siigo.com",
            "Lider": "Juan Pablo Carreño Patiño",
            "Nombre": "John Sebastian Diaz Mosquera"
        },
        {
            "_id": "111965",
            "Agente": "111965",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "jime111965@siigo.com",
            "Lider": "Edgar Fabian Ruano Daza",
            "Nombre": "Julian David Jimenez Marin"
        },
        {
            "_id": "800379",
            "Agente": "800379",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pard800379@siigo.com",
            "Lider": "Nestor Oswaldo Becerra Prieto",
            "Nombre": "Viviana Angelica Pardo Giraldo"
        },
        {
            "_id": "803188",
            "Agente": "803188",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "chil803188@siigo.com",
            "Lider": "Zayda Smith Afanador Silva",
            "Nombre": "Andry Liliana Chilito Galindez"
        },
        {
            "_id": "800935",
            "Agente": "800935",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "reye800935@siigo.com",
            "Lider": "Juan Pablo Henao Muñoz",
            "Nombre": "Angie Tatiana Reyes Perez"
        },
        {
            "_id": "803444",
            "Agente": "803444",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "hern803444@siigo.com",
            "Lider": "Angel Alfonso Arciniegas Guerrero",
            "Nombre": "Wendy Carolina Hernandez Mariño"
        },
        {
            "_id": "800010",
            "Agente": "800010",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mora800010@siigo.com",
            "Lider": "Diana Sofia Ramos Castillo",
            "Nombre": "Laura Catalina Mora Morales"
        },
        {
            "_id": "3356",
            "Agente": "3356",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "vdominguez@aspel.com.mx",
            "Lider": "Oscar Rodrigo Navarro Hernandez",
            "Nombre": "Victor Manuel Dominguez Nava"
        },
        {
            "_id": "2231",
            "Agente": "2231",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jagarcia@aspel.com.mx",
            "Lider": "Gabriel Chavez Carrera",
            "Nombre": "Jenifer Aquino Garcia"
        },
        {
            "_id": "3350",
            "Agente": "3350",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "almartinez@aspel.com.mx",
            "Lider": "Jose Luis Carranza Valdes",
            "Nombre": "Alberto Martinez Martinez"
        },
        {
            "_id": "800550",
            "Agente": "800550",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "gome800550@siigo.com",
            "Lider": "Fernando Gutierrez Paramio",
            "Nombre": "Andres Alberto Gomez Baño"
        },
        {
            "_id": "3573",
            "Agente": "3573",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "ylopez@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Yadira Guadalupe Lopez Moreno"
        },
        {
            "_id": "802227",
            "Agente": "802227",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "card802227@siigo.com",
            "Lider": "Alix Dayana Archila Castillo",
            "Nombre": "Luis Armando Cardenas Gonzalez"
        },
        {
            "_id": "803638",
            "Agente": "803638",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rami803638@siigo.com",
            "Lider": "Hugo Ernesto Cruz Casallas",
            "Nombre": "Ezequias Gabriel Ramirez Serrano"
        },
        {
            "_id": "804168",
            "Agente": "804168",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "range804168@siigo.com",
            "Lider": "Angel Camilo Chavez Moreno",
            "Nombre": "Manuel Salvador Rangel Moreno"
        },
        {
            "_id": "3593",
            "Agente": "3593",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "jggarcia@aspel.com.mx",
            "Lider": "Pedro Arturo Rico Martinez",
            "Nombre": "Jonathan Gómez Gonzalez"
        },
        {
            "_id": "3571",
            "Agente": "3571",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "mherrera@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Maria Fernanda Herrera Hernandez"
        },
        {
            "_id": "800273",
            "Agente": "800273",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "duqu800273@siigo.com",
            "Lider": "Juan Francisco Lavayen Iñamagua",
            "Nombre": "Astrid Gisel Duque Rico"
        },
        {
            "_id": "801685",
            "Agente": "801685",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "here801685@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Dayana Isabel Heredia Pizarro"
        },
        {
            "_id": "803872",
            "Agente": "803872",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "domi803872@siigo.com",
            "Lider": "Andres Felipe Baron Ballen",
            "Nombre": "Yholmery Carolina Dominguez Quintero"
        },
        {
            "_id": "801997",
            "Agente": "801997",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "reye801997@siigo.com",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre": "Katherine Kiatusca Reyes Balza"
        },
        {
            "_id": "800865",
            "Agente": "800865",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cald800865@siigo.com",
            "Lider": "Yamid Ramiro Quintero Montaño",
            "Nombre": "Aurilde Jose Caldera Martinez"
        },
        {
            "_id": "3576",
            "Agente": "3576",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "dtorres@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Daniela Jariffe Torres Gonzalez"
        },
        {
            "_id": "801415",
            "Agente": "801415",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "vela801415@siigo.com",
            "Lider": "Hector Enrique Becerra Perez",
            "Nombre": "Miguel Oswaldo Velasquez Fragiel"
        },
        {
            "_id": "803889",
            "Agente": "803889",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "silv803889@siigo.com",
            "Lider": "Daniel Eduardo Pinto Cepeda",
            "Nombre": "Karla Patricia Silva Ocando"
        },
        {
            "_id": "804153",
            "Agente": "804153",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "vald804153@siigo.com",
            "Lider": "Aura Cristina Rodriguez Manrique",
            "Nombre": "Reiser Ayurami Valdivieso Piña"
        },
        {
            "_id": "804527",
            "Agente": "804527",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "rodr804527@siigo.com",
            "Lider": "Cristian Enrique Albornoz Arias",
            "Nombre": "María Jose Rodriguez Arboleda"
        },
        {
            "_id": "804418",
            "Agente": "804418",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "soto804418@siigo.com",
            "Lider": "Juan Jose Correa Acosta",
            "Nombre": "Edward Daniel Soto Mejia"
        },
        {
            "_id": "803297",
            "Agente": "803297",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "mart803297@siigo.com",
            "Lider": "Mario Alberto Rojas Gamboa",
            "Nombre": "Edward Santiago Martinez 0"
        },
        {
            "_id": "804515",
            "Agente": "804515",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "more804515@siigo.com",
            "Lider": "Nelsy Adriana Sanchez Ramirez",
            "Nombre": "Maria Alejandra Moreno Gonzalez"
        },
        {
            "_id": "803840",
            "Agente": "803840",
            "Area": "Finance & Administration",
            "Division": "Siigo Ecuador",
            "Email": "davi803840@siigo.com",
            "Lider": "Sergio Alberto Caicedo Ramirez",
            "Nombre": "Kerly Stefannie Davila Murillo"
        },
        {
            "_id": "804067",
            "Agente": "804067",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "mend804067@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Yismary De Los Angeles Mendez Farfan"
        },
        {
            "_id": "40450",
            "Agente": "40450",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "paez40450@siigo.com",
            "Lider": "Juan Sebastian Molina Linares",
            "Nombre": "Jessica Paola Paez Quintero"
        },
        {
            "_id": "111936",
            "Agente": "111936",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "tama111936@siigo.com",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre": "Tatiana Marcela Tamayo Serna"
        },
        {
            "_id": "109924",
            "Agente": "109924",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "nino109924@siigo.com",
            "Lider": "Anyeliz Beatriz Rico Torres",
            "Nombre": "Oscar Andres Niño Avila"
        },
        {
            "_id": "800514",
            "Agente": "800514",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sanc800514@siigo.com",
            "Lider": "Juan Pablo Henao Muñoz",
            "Nombre": "Jefrey Julian Sanchez Celemin"
        },
        {
            "_id": "803344",
            "Agente": "803344",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "moli803344@siigo.com",
            "Lider": "Wilson Medina Nuñez",
            "Nombre": "Lina Marisol Molina Insuasty"
        },
        {
            "_id": "802489",
            "Agente": "802489",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "cant802489@siigo.com",
            "Lider": "Alvaro Jose Acosta Madroñero",
            "Nombre": "Miguel Angel Cantor Lopez"
        },
        {
            "_id": "801745",
            "Agente": "801745",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "hern801745@siigo.com",
            "Lider": "Diana Michelle Matamoros Del Rosario",
            "Nombre": "Jampier Benjamin Hernandez Hunt"
        },
        {
            "_id": "801016",
            "Agente": "801016",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "bedo801016@siigo.com",
            "Lider": "Cesar Julian Rivera Benitez",
            "Nombre": "Andres Felipe Bedoya Valencia"
        },
        {
            "_id": "803370",
            "Agente": "803370",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "medi803370@siigo.com",
            "Lider": "Nelsy Adriana Sanchez Ramirez",
            "Nombre": "Andrea Medina Coy"
        },
        {
            "_id": "38",
            "Agente": "38",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "asantana@aspel.com.mx",
            "Lider": "Marcela Vanessa Hernandez Moreno",
            "Nombre": "Alicia Santana Vallejo"
        },
        {
            "_id": "800574",
            "Agente": "800574",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "palo800574@siigo.com",
            "Lider": "Manuel Fernando Salazar Diaz",
            "Nombre": "Diego Romario Palomeque Gomez"
        },
        {
            "_id": "801487",
            "Agente": "801487",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "sanc801487@siigo.com",
            "Lider": "Gustavo Adolfo Valencia Chaparro",
            "Nombre": "Natalia Sanchez Gama"
        },
        {
            "_id": "112439",
            "Agente": "112439",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "wilc112439@siigo.com",
            "Lider": "Lida Katerine Puentes Navarro",
            "Nombre": "Angie Daniela Wilches Peñaloza"
        },
        {
            "_id": "803264",
            "Agente": "803264",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "aceb803264@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Narcisa Elizabeth Acebo Loor"
        },
        {
            "_id": "800492",
            "Agente": "800492",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "reye800492@siigo.com",
            "Lider": "Oscar Francisco Plaza Giler",
            "Nombre": "Felipe Ricardo Reyes Lega"
        },
        {
            "_id": "801570",
            "Agente": "801570",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "pere801570@siigo.com",
            "Lider": "Laura Valentina Navarro Olaya",
            "Nombre": "Carlos Esteban Perez Uribe"
        },
        {
            "_id": "801122",
            "Agente": "801122",
            "Area": "Tech",
            "Division": "Siigo Uruguay",
            "Email": "dome801122@siigo.com",
            "Lider": "Diego Felipe Jaramillo Alvarez",
            "Nombre": "Eduardo Sebastian Domenech Favout"
        },
        {
            "_id": "802809",
            "Agente": "802809",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cero802809@siigo.com",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre": "Jaime Daniel Ceron Rosales"
        },
        {
            "_id": "801138",
            "Agente": "801138",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "clav801138@siigo.com",
            "Lider": "Carlos Javier Mendez Vera",
            "Nombre": "Cristian Fernando Clavijo Morales"
        },
        {
            "_id": "803755",
            "Agente": "803755",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "guer803755@siigo.com",
            "Lider": "Luz Katerine Maya Mogollón",
            "Nombre": "Susana Del Carmen Guerra Lujan"
        },
        {
            "_id": "803875",
            "Agente": "803875",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "manr803875@siigo.com",
            "Lider": "Andres Felipe Baron Ballen",
            "Nombre": "Laura Valentina Manrique Gonzalez"
        },
        {
            "_id": "32602",
            "Agente": "32602",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pint32602@siigo.com",
            "Lider": "Liliana Patricia Giraldo Gomez",
            "Nombre": "Daniel Eduardo Pinto Cepeda"
        },
        {
            "_id": "800636",
            "Agente": "800636",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "desi800636@siigo.com",
            "Lider": "Francisco Jose Bohorquez Torres",
            "Nombre": "Kattya Roxana Desiderio Sanchez"
        },
        {
            "_id": "27284",
            "Agente": "27284",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rami27284@siigo.com",
            "Lider": "Jhony Alexander Alzate Soto",
            "Nombre": "Luis Alberto Ramirez Robayo"
        },
        {
            "_id": "1768",
            "Agente": "1768",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "mrojo@aspel.com.mx",
            "Lider": "Fernando Villasana Gutierrez",
            "Nombre": "Martin Misael Rojo Lopez"
        },
        {
            "_id": "802691",
            "Agente": "802691",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "guzm802691@siigo.com",
            "Lider": "Diana Patricia Villarraga Forero",
            "Nombre": "Yenitir Guzman Bulfor"
        },
        {
            "_id": "111992",
            "Agente": "111992",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "mach111992@siigo.com",
            "Lider": "Sindy Paola Lasso Velasco",
            "Nombre": "Angel Santiago Machado Restrepo"
        },
        {
            "_id": "803826",
            "Agente": "803826",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "sala803826@siigo.com",
            "Lider": "Glorizeth Ardila Cañon",
            "Nombre": "Lina Maria Salamanca Gutierrez"
        },
        {
            "_id": "800244",
            "Agente": "800244",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mart800244@siigo.com",
            "Lider": "Cristian Camilo Hernandez Gonzalez",
            "Nombre": "Yinary Lorena Martinez Aguirre"
        },
        {
            "_id": "802074",
            "Agente": "802074",
            "Area": "People & Culture",
            "Division": "Siigo Ecuador",
            "Email": "truj802074@siigo.com",
            "Lider": "Carlos Arturo Huertas Salgado",
            "Nombre": "Michael Alberto Trujillo Barre"
        },
        {
            "_id": "804124",
            "Agente": "804124",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "pere80124@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Wendy Yaneth Perez "
        },
        {
            "_id": "110180",
            "Agente": "110180",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Sole110180@siigo.com",
            "Lider": "John Misael Martinez Montero",
            "Nombre": "Sandra Aydee Soler Becerra"
        },
        {
            "_id": "110035",
            "Agente": "110035",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "barr110035@siigo.com",
            "Lider": "Cristian Armando Rozo Bermudez",
            "Nombre": "German Arturo Barros Garcia"
        },
        {
            "_id": "108542",
            "Agente": "108542",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "salc108542@siigo.com",
            "Lider": "Juan Pablo Henao Muñoz",
            "Nombre": "Hancel Rafael Salcedo Mendoza"
        },
        {
            "_id": "802233",
            "Agente": "802233",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "garc802233@siigo.com",
            "Lider": "Yesika Viviana Garcia Niño",
            "Nombre": "Elkin Jose Garcia Alvarado"
        },
        {
            "_id": "803650",
            "Agente": "803650",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "flor803650@siigo.com",
            "Lider": "Miguel Angel Suárez Hernández",
            "Nombre": "Andres Camilo Florez Villota"
        },
        {
            "_id": "17507",
            "Agente": "17507",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Piza17507@siigo.com",
            "Lider": "Juvenal Reyes Silva",
            "Nombre": "Rosa Helena Piza 0"
        },
        {
            "_id": "800736",
            "Agente": "800736",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "feli800736@siigo.com",
            "Lider": "Angel Alfonso Arciniegas Guerrero",
            "Nombre": "Johan Dario Felix Altamirano"
        },
        {
            "_id": "802359",
            "Agente": "802359",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "figu802359@siigo.com",
            "Lider": "Juan Sebastian Cifuentes Guiffo",
            "Nombre": "Rafael Leonardo Figueroa Lopez"
        },
        {
            "_id": "803647",
            "Agente": "803647",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "loza803647@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Joan Gabriel Lozano Guaño"
        },
        {
            "_id": "1201",
            "Agente": "1201",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "rcamal@aspel.com.mx",
            "Lider": "Luis Alberto Baas Valdez",
            "Nombre": "Reyna Del Rosario Camal Uc"
        },
        {
            "_id": "800725",
            "Agente": "800725",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "caic800725@siigo.com",
            "Lider": "Luis Alfredo Leon Britto",
            "Nombre": "Gersy Stiven Caicedo Rocha"
        },
        {
            "_id": "801880",
            "Agente": "801880",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "acos801880@siigo.com",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre": "Idalia Marcela Acosta Rodriguez"
        },
        {
            "_id": "800876",
            "Agente": "800876",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "mont800876@siigo.com",
            "Lider": "Leidy Paola Rubiano Vargas",
            "Nombre": "Johan Sebastian Montero Duarte"
        },
        {
            "_id": "802449",
            "Agente": "802449",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "nori802449@siigo.com",
            "Lider": "Angy Paola Umbacia Diaz",
            "Nombre": "Jennifer Noriega Cadavid"
        },
        {
            "_id": "111835",
            "Agente": "111835",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "goez111835@siigo.com",
            "Lider": "Juan Carlos Buitrago Rios",
            "Nombre": "Marcos Goez Bolivar"
        },
        {
            "_id": "800641",
            "Agente": "800641",
            "Area": "Finance & Administration",
            "Division": "Siigo Ecuador",
            "Email": "aria800641@siigo.com",
            "Lider": "Aura Cristina Rodriguez Manrique",
            "Nombre": "Joselyn Silvana Arias Villareal"
        },
        {
            "_id": "800749",
            "Agente": "800749",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "mart800749@siigo.com",
            "Lider": "Luis Alfredo Leon Britto",
            "Nombre": "Wilmar Francisco Martinez Laverde"
        },
        {
            "_id": "802854",
            "Agente": "802854",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "aran802854@siigo.com",
            "Lider": "Karen Alejandra Casas Mesa",
            "Nombre": "Nicole Karina Arandia Castañeda"
        },
        {
            "_id": "803341",
            "Agente": "803341",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "meno803341@siigo.com",
            "Lider": "Angel Camilo Chavez Moreno",
            "Nombre": "Allisson Karen Menoscal Murillo"
        },
        {
            "_id": "800187",
            "Agente": "800187",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mald800187@siigo.com",
            "Lider": "Andres Felipe Baron Ballen",
            "Nombre": "Paola Andrea Maldonado Velez"
        },
        {
            "_id": "33372",
            "Agente": "33372",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "carv33372@siigo.com",
            "Lider": "John Misael Martinez Montero",
            "Nombre": "Raul Ernesto Carvajal Acosta"
        },
        {
            "_id": "644",
            "Agente": "644",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "ldelarosa@aspel.com.mx",
            "Lider": "Pedro Arturo Rico Martinez",
            "Nombre": "Lizbeth De la Rosa Lavalle"
        },
        {
            "_id": "803693",
            "Agente": "803693",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sanc803693@siigo.com",
            "Lider": "Johanna Patricia Torres Quintero",
            "Nombre": "Andrea Sanchez Hernandez"
        },
        {
            "_id": "800829",
            "Agente": "800829",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "moli800829@siigo.com",
            "Lider": "Claudio Federico Hornes Ismail",
            "Nombre": "Juan Sebastian Molina Linares"
        },
        {
            "_id": "801729",
            "Agente": "801729",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "sanc801729@siigo.com",
            "Lider": "Sandra Catalina Suarez Castillo",
            "Nombre": "Adriana Del Pilar Sanchez Herrera"
        },
        {
            "_id": "802164",
            "Agente": "802164",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "roja802164@siigo.com",
            "Lider": "James Alberto Valencia Caicedo",
            "Nombre": "Wendy Dayana Rojas Rojas"
        },
        {
            "_id": "803870",
            "Agente": "803870",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "boni803870@siigo.com",
            "Lider": "James Alberto Valencia Caicedo",
            "Nombre": "Omar Ricardo Bonilla Hernandez"
        },
        {
            "_id": "802734",
            "Agente": "802734",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "lisc802734@siigo.com",
            "Lider": "Fernando Gutierrez Paramio",
            "Nombre": "Wilzon Camilo Liscano Galindo"
        },
        {
            "_id": "112169",
            "Agente": "112169",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "muno112169@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Leydy Tatiana Muñoz Guerrero"
        },
        {
            "_id": "32253",
            "Agente": "32253",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "Silv32253@siigo.com",
            "Lider": "Juan Carlos Corazzo Santos",
            "Nombre": "Julian Andres Silva Torralba"
        },
        {
            "_id": "800225",
            "Agente": "800225",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "cast800225@siigo.com",
            "Lider": "Andrea Catalina Vega Gomez",
            "Nombre": "Nicol Estefanni Castro Piraquive"
        },
        {
            "_id": "803697",
            "Agente": "803697",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "vela803697@siigo.com",
            "Lider": "William Andres Reyes Silva",
            "Nombre": "Fanny Karine Velasquez Guiza"
        },
        {
            "_id": "801889",
            "Agente": "801889",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ariz801889@siigo.com",
            "Lider": "Juvenal Reyes Silva",
            "Nombre": "Maria Camila Ariza Triana"
        },
        {
            "_id": "803890",
            "Agente": "803890",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ruiz803890@siigo.com",
            "Lider": "William Andres Reyes Silva",
            "Nombre": "Wilder Andres Ruiz Porras"
        },
        {
            "_id": "803628",
            "Agente": "803628",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "acos803628@siigo.com",
            "Lider": "Gustavo Adolfo Espinosa Toledo",
            "Nombre": "Valentina Acosta Gonzalez"
        },
        {
            "_id": "207617",
            "Agente": "207617",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "Sanc207617@siigo.com",
            "Lider": "David Ortiz Diaz",
            "Nombre": "Nelsy Adriana Sanchez Ramirez"
        },
        {
            "_id": "803308",
            "Agente": "803308",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rinc803308@siigo.com",
            "Lider": "William Andres Reyes Silva",
            "Nombre": "Anyela Mercedes Rincon Ramos"
        },
        {
            "_id": "801690",
            "Agente": "801690",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": null,
            "Lider": "Juan Carlos Corazzo Santos",
            "Nombre": "Jorge Mario Ortega Velasquez"
        },
        {
            "_id": "801573",
            "Agente": "801573",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "paez801573@siigo.com",
            "Lider": "Jorge Alberto Navia Charria",
            "Nombre": "Fernando Mario Paez Tovar"
        },
        {
            "_id": "1948",
            "Agente": "1948",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "fhernandez@aspel.com.mx",
            "Lider": "Gabriel Chavez Carrera",
            "Nombre": "Fernando Hernandez Salas"
        },
        {
            "_id": "801417",
            "Agente": "801417",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "truj801417@siigo.com",
            "Lider": "Leydi Julieth Sosa Parra",
            "Nombre": "Angie Tatiana Trujillo Morales"
        },
        {
            "_id": "803793",
            "Agente": "803793",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rodr803793@siigo.com",
            "Lider": "Adriana Elizabeth Giron Parra",
            "Nombre": "Karol Vanessa Rodriguez Castillo"
        },
        {
            "_id": "111786",
            "Agente": "111786",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "diaz111786@siigo.com",
            "Lider": "Juan Francisco Lavayen Iñamagua",
            "Nombre": "Alvin Diaz Guerrero"
        },
        {
            "_id": "1836",
            "Agente": "1836",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "rcampos@aspel.com.mx",
            "Lider": "Juan Carlos Gallardo Valadez",
            "Nombre": "Rodrigo Omar Campos Concha"
        },
        {
            "_id": "800560",
            "Agente": "800560",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "pove800560@siigo.com",
            "Lider": "Juan Jose Correa Acosta",
            "Nombre": "Carlos Jesus Poveda Zapata"
        },
        {
            "_id": "802316",
            "Agente": "802316",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "oval802316@siigo.com",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre": "Alejandro David Ovalle Escobar"
        },
        {
            "_id": "800929",
            "Agente": "800929",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cast800929@siigo.com",
            "Lider": "Nestor Oswaldo Becerra Prieto",
            "Nombre": "Lina Marcela Castañeda Martinez"
        },
        {
            "_id": "802407",
            "Agente": "802407",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "belt802407@siigo.com",
            "Lider": "Angel Alfonso Arciniegas Guerrero",
            "Nombre": "Julitte Jannette Beltran Achagua"
        },
        {
            "_id": "111757",
            "Agente": "111757",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ruiz111757@siigo.com",
            "Lider": "Andres Felipe Baron Ballen",
            "Nombre": "Anyi Maribel Ruiz Porras"
        },
        {
            "_id": "111880",
            "Agente": "111880",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "boho111880@siigo.com",
            "Lider": "Paola Denisse Veloz Garcia",
            "Nombre": "Ivan Felipe Bohorquez Ramirez"
        },
        {
            "_id": "800734",
            "Agente": "800734",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "came800734@siigo.com",
            "Lider": "Diana Mercedes Moncada Peraza",
            "Nombre": "Luz Angela Camelo Virguez"
        },
        {
            "_id": "217147",
            "Agente": "217147",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vale217147@siigo.com",
            "Lider": "Yenny Milena Lopez Florez",
            "Nombre": "Carlos Andres Valencia Bravo"
        },
        {
            "_id": "801262",
            "Agente": "801262",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "orte801262@siigo.com",
            "Lider": "Jhon Emmanuel Zuñiga Paredes",
            "Nombre": "Marco Antonio Ortega Piedrahita"
        },
        {
            "_id": "2908",
            "Agente": "2908",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "rpacheco@aspel.com.mx",
            "Lider": "César Augusto Rejón Vermont",
            "Nombre": "Rogel Antonio Pacheco Yah"
        },
        {
            "_id": "243825",
            "Agente": "243825",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "Buit243825@siigo.com",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre": "Maria Zenaida Buitrago Carrillo"
        },
        {
            "_id": "804176",
            "Agente": "804176",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "oval804176@siigo.com",
            "Lider": "Maria Ximena Manrique Isaziga",
            "Nombre": "Maria Cecilia Ovalle Mejia"
        },
        {
            "_id": "803715",
            "Agente": "803715",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "timo803715@siigo.com",
            "Lider": "Liliana Andrea Segura Martinez",
            "Nombre": "Sandy Dayana Timote Rodriguez"
        },
        {
            "_id": "801288",
            "Agente": "801288",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rodr801288@siigo.com",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre": "Leslie Geraldine Rodriguez 0"
        },
        {
            "_id": "802355",
            "Agente": "802355",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "mond802355@siigo.com",
            "Lider": "Carlos Alberto Beltran Melo",
            "Nombre": "Joan Sebastian Mondragon Muñoz"
        },
        {
            "_id": "803512",
            "Agente": "803512",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "orti803512@siigo.com",
            "Lider": "Andres Alberto Gomez Baño",
            "Nombre": "Ivan Orlando Ortiz Barreto"
        },
        {
            "_id": "36011",
            "Agente": "36011",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "beja36011@siigo.com",
            "Lider": "Diana Maria Naranjo Mattheus",
            "Nombre": "Diana Bejarano Gacharna"
        },
        {
            "_id": "802179",
            "Agente": "802179",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rico802179@siigo.com",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre": "Daniela Adriana Rico Rubiano"
        },
        {
            "_id": "640",
            "Agente": "640",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "asepulveda@aspel.com.mx",
            "Lider": "Jose De Jesus De Aquino Curiel",
            "Nombre": "Armando Sepulveda Velazquez"
        },
        {
            "_id": "800866",
            "Agente": "800866",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "daza800866@siigo.com",
            "Lider": "Diana Patricia Villarraga Forero",
            "Nombre": "Cristian Camilo Daza Coronado"
        },
        {
            "_id": "803195",
            "Agente": "803195",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "gome803195@siigo.com",
            "Lider": "Juan Diego Avilan Prieto",
            "Nombre": "Angel Hernan Gomez Nemocon"
        },
        {
            "_id": "803738",
            "Agente": "803738",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "roch803738@siigo.com",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre": "Liliana Margarita Rocha Perez"
        },
        {
            "_id": "111814",
            "Agente": "111814",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gonz111814@siigo.com",
            "Lider": "Esteban Tellez Garcia",
            "Nombre": "Juan Camilo Gonzalez Saldaña"
        },
        {
            "_id": "802167",
            "Agente": "802167",
            "Area": "Finance & Administration",
            "Division": "Siigo Ecuador",
            "Email": "alva802167@siigo.com",
            "Lider": "Diana Elizabeth Davila Murillo",
            "Nombre": "Magdalena Cinzia Alvarado Vergara"
        },
        {
            "_id": "802555",
            "Agente": "802555",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "alva802555@siigo.com",
            "Lider": "Sory Yaqueline Abello Polo",
            "Nombre": "Moises Alvarez Niebles"
        },
        {
            "_id": "803780",
            "Agente": "803780",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vela803780@siigo.com",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre": "Mery Jicela Velandia Torres"
        },
        {
            "_id": "800615",
            "Agente": "800615",
            "Area": "Product",
            "Division": "Siigo Ecuador",
            "Email": "cepe800615@siigo.com",
            "Lider": "Jorge Luis Salas Sarmiento",
            "Nombre": "Bianca Alice Cepeda Campoverde"
        },
        {
            "_id": "801167",
            "Agente": "801167",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cont801167@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Jesus David Contreras Bello"
        },
        {
            "_id": "802682",
            "Agente": "802682",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "char802682@siigo.com",
            "Lider": "Alix Dayana Archila Castillo",
            "Nombre": "Carla Yojana Charry Rincon"
        },
        {
            "_id": "800325",
            "Agente": "800325",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ibar800325@siigo.com",
            "Lider": "Cristian Camilo Hernandez Gonzalez",
            "Nombre": "Carolina Andrea Ibarguen Arcos"
        },
        {
            "_id": "802363",
            "Agente": "802363",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "layt802363@siigo.com",
            "Lider": "John Mario Perez Diaz",
            "Nombre": "Jeysson Andres Layton Paez"
        },
        {
            "_id": "800084",
            "Agente": "800084",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pard800084@siigo.com",
            "Lider": "Andres Felipe Baron Ballen",
            "Nombre": "Maria Fernanda Pardo Mora"
        },
        {
            "_id": "803508",
            "Agente": "803508",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "vane803508@siigo.com",
            "Lider": "Hugo Ernesto Cruz Casallas",
            "Nombre": "Victor Manuel Vanegas Rodriguez"
        },
        {
            "_id": "800946",
            "Agente": "800946",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "moli800946@siigo.com",
            "Lider": "Nataly Carolina Gonzalez Sandoval",
            "Nombre": "Jessica Alejandra Molina Hernandez"
        },
        {
            "_id": "803801",
            "Agente": "803801",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "tagl803801@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Marielena Beatriz Tagle Arce"
        },
        {
            "_id": "800148",
            "Agente": "800148",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mend800148@siigo.com",
            "Lider": "Hernan Dario Parra Osorio",
            "Nombre": "Laura Marcela Mendoza Calderon"
        },
        {
            "_id": "801747",
            "Agente": "801747",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "anch801747@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Jazmin Alexandra Anchundia Tenorio"
        },
        {
            "_id": "900166",
            "Agente": "900166",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "zule804603@siigo.com",
            "Lider": "Brandon Enrique Gomez Rosales",
            "Nombre": "Camila Andrea Zuleta Estrada"
        },
        {
            "_id": "900350",
            "Agente": "900350",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "agud900350@siigo.com",
            "Lider": "Johana Catalina Peña ",
            "Nombre": "Leider Andres Agudelo Jimenez"
        },
        {
            "_id": "900352",
            "Agente": "900352",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gonz900352@siigo.com",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Julieth Gonzalez Cruz"
        },
        {
            "_id": "2294",
            "Agente": "2294",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "emorales@aspel.com.mx",
            "Lider": "Gabriel Chavez Carrera",
            "Nombre": "Erick Andres Morales Cuevas"
        },
        {
            "_id": "3499",
            "Agente": "3499",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "cvcruz@aspel.com.mx",
            "Lider": "Virginia Guadalupe Ramirez Hernandez",
            "Nombre": "Ciria Verónica de la Cruz Flores"
        },
        {
            "_id": "802044",
            "Agente": "802044",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cont802044@siigo.com",
            "Lider": "Jenny Paola Mendez 0",
            "Nombre": "Sarah Angelica Contreras Diaz"
        },
        {
            "_id": "1744",
            "Agente": "1744",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "rsanchez@aspel.com.mx",
            "Lider": "Luz Angelica Torres Quintero",
            "Nombre": "Raquel Sanchez Vazquez"
        },
        {
            "_id": "44",
            "Agente": "44",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "tvazquez@aspel.com.mx",
            "Lider": "Vicente Estrada Sanchez",
            "Nombre": "Maria Teresa Vazquez Garza"
        },
        {
            "_id": "3673",
            "Agente": "3673",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "alramirez@aspel.com.mx",
            "Lider": "Juan Carlos Valdes Martin",
            "Nombre": "Alina Ramirez 0"
        },
        {
            "_id": "16",
            "Agente": "16",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "hduque@aspel.com.mx",
            "Lider": "Fernando Contreras Ocaña",
            "Nombre": "Hector Duque Vergara"
        },
        {
            "_id": "1719",
            "Agente": "1719",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "klopez@aspel.com.mx",
            "Lider": "Alicia Santana Vallejo",
            "Nombre": "Karina Lopez Cabañas"
        },
        {
            "_id": "3253",
            "Agente": "3253",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "umolina@aspel.com.mx",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Ulises Miranda Molina"
        },
        {
            "_id": "3283",
            "Agente": "3283",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jgudino@aspel.com.mx",
            "Lider": "Jose Luis Carranza Valdes",
            "Nombre": "Javier Gudiño Rico"
        },
        {
            "_id": "2001",
            "Agente": "2001",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "dcoronel@aspel.com.mx",
            "Lider": "Carlos Maximo Garrido Mascott",
            "Nombre": "Diego Alejandro Coronel Garcia"
        },
        {
            "_id": "3460",
            "Agente": "3460",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "kleeder@aspel.com.mx",
            "Lider": "Seidy Guadalupe Perez 0",
            "Nombre": "Kelvin Brayloswky Leeder Gurigutia"
        },
        {
            "_id": "2232",
            "Agente": "2232",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jlizarraga@aspel.com.mx",
            "Lider": "Pedro Miguel Ocampo Ix",
            "Nombre": "Juan Manuel Sanchez Lizarraga"
        },
        {
            "_id": "1933",
            "Agente": "1933",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "vvazquez@aspel.com.mx",
            "Lider": "Nicolas Cifuentes Echeverry",
            "Nombre": "Virginia Vazquez Cortes"
        },
        {
            "_id": "900182",
            "Agente": "900182",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "monr804600@siigo.com",
            "Lider": "Glorizeth Ardila Cañon",
            "Nombre": "Maria Paula Monroy Sastoque"
        },
        {
            "_id": "900189",
            "Agente": "900189",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "perez804604@siigo.com",
            "Lider": "Juan Carlos Valdes Martin",
            "Nombre": "Giovanny Andres Perez Lopez"
        },
        {
            "_id": "900205",
            "Agente": "900205",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "guer804607@siigo.com",
            "Lider": "Rocxy Daniela Fernandez Fernandez",
            "Nombre": "Yinnet Nathalia Guerrero Rodriguez"
        },
        {
            "_id": "900219",
            "Agente": "900219",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "tova804611@siigo.com",
            "Lider": "Zayda Smith Afanador Silva",
            "Nombre": "Julian Alexander Tovar Rios"
        },
        {
            "_id": "900344",
            "Agente": "900344",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "guti900344@siigo.com",
            "Lider": "Vicky Alejandra Hernandez Mendez",
            "Nombre": "Andrea Paola Gutierrez Monroy"
        },
        {
            "_id": "900546",
            "Agente": "900546",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "Prad900546@siigo.com",
            "Lider": "Jose Daniel Huerfano Martinez",
            "Nombre": "Lilia Lucia Prado Martinez"
        },
        {
            "_id": "900553",
            "Agente": "900553",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "Mend900553@siigo.com",
            "Lider": "Martha Isabel Diaz Gomez",
            "Nombre": "Alison Dajanna Mendieta Velasco"
        },
        {
            "_id": "900628",
            "Agente": "900628",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "sanc900628@siigo.com",
            "Lider": "Leydi Julieth Sosa Parra",
            "Nombre": "Joaquin Roberto Sanchez Saez"
        },
        {
            "_id": "900632",
            "Agente": "900632",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "deicy.calderon@siigo.com",
            "Lider": "Arturo Alvarez Martinez",
            "Nombre": "Deicy Geraldin Calderon Montenegro"
        },
        {
            "_id": "800511",
            "Agente": "800511",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ardi800511@siigo.com",
            "Lider": "Eliana Maria Fajardo Prada",
            "Nombre": "Jojhana Idaly Ardila Becerra"
        },
        {
            "_id": "900100",
            "Agente": "900100",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mend804597@siigo.com",
            "Lider": "Angie Camila Diaz Escobar",
            "Nombre": "Manuela Mendoza Sanchez"
        },
        {
            "_id": "900230",
            "Agente": "900230",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "gira804629@siigo.com",
            "Lider": "Claudia Patricia Estevez Alvarez",
            "Nombre": "Laura Camila Giraldo Soto"
        },
        {
            "_id": "900272",
            "Agente": "900272",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cepe900269@siigo.com",
            "Lider": "Vicky Alejandra Hernandez Mendez",
            "Nombre": "Shanon Paola Cepeda Chaparro"
        },
        {
            "_id": "900338",
            "Agente": "900338",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rest900338@siigo.com",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Jennifer Restrepo Ortiz"
        },
        {
            "_id": "900340",
            "Agente": "900340",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ruiz900340@siigo.com",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Ingrid Yorcidia  Ruiz Cavanzo"
        },
        {
            "_id": "900348",
            "Agente": "900348",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "eras900348@siigo.com",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Pablo Orlando Eraso Arteaga"
        },
        {
            "_id": "900461",
            "Agente": "900461",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ferr900461@siigo.com",
            "Lider": "Giovanni Baquero Hernandez",
            "Nombre": "Richard Eduardo  Ferrer Muller"
        },
        {
            "_id": "900463",
            "Agente": "900463",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mart900463@siigo.com",
            "Lider": "Juliana Castro Diaz",
            "Nombre": "Henry Alberto Martinez Ladino"
        },
        {
            "_id": "900467",
            "Agente": "900467",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "saav900467@siigo.com",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Marley Liliana Saavedra Mancipe"
        },
        {
            "_id": "900477",
            "Agente": "900477",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "galv900477@siigo.com",
            "Lider": "William Andres Reyes Silva",
            "Nombre": "Luz Angelica Galvis Camargo"
        },
        {
            "_id": "900516",
            "Agente": "900516",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": null,
            "Lider": "Luis Ignacio Ferreira Duarte",
            "Nombre": "Patricia Janneth Veloso Blanco"
        },
        {
            "_id": "900524",
            "Agente": "900524",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "ross804636@siigo.com",
            "Lider": "Milady Andrea Guzman Garcia",
            "Nombre": "Liseth Paola Rosso Arrieta"
        },
        {
            "_id": "900526",
            "Agente": "900526",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "quin804640@siigo.com",
            "Lider": "Sonia Mabel Fernandez Calderon",
            "Nombre": "Lida Marcela Quintero Buitrago"
        },
        {
            "_id": "900610",
            "Agente": "900610",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "meji900610@siigo.com",
            "Lider": "Arturo Alvarez Martinez",
            "Nombre": "Carol Tatiana Mejia Arevalo"
        },
        {
            "_id": "1815",
            "Agente": "1815",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "rsoriano@aspel.com.mx",
            "Lider": "Virginia Guadalupe Ramirez Hernandez",
            "Nombre": "Rene Soriano Estrada"
        },
        {
            "_id": "1868",
            "Agente": "1868",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "iroa@aspel.com.mx",
            "Lider": "Vicente Estrada Sanchez",
            "Nombre": "Ivan Alejandro Roa Franco"
        },
        {
            "_id": "2064",
            "Agente": "2064",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jfrias@aspel.com.mx",
            "Lider": "Luz Angelica Torres Quintero",
            "Nombre": "Jose Antonio Hernandez Frias"
        },
        {
            "_id": "3148",
            "Agente": "3148",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "bcardenas@aspel.com.mx",
            "Lider": "Juan Mauricio Jimenez Espinoza",
            "Nombre": "Bradley Robertson Cardenas Alcocer"
        },
        {
            "_id": "1899",
            "Agente": "1899",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "tjeitani@aspel.com.mx",
            "Lider": "Hector Duque Vergara",
            "Nombre": "Toni Frangieh Jeitani Jeitani"
        },
        {
            "_id": "116",
            "Agente": "116",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "jgarcia@aspel.com.mx",
            "Lider": "Fernando Contreras Ocaña",
            "Nombre": "Juan Carlos Garcia Ibarra"
        },
        {
            "_id": "1949",
            "Agente": "1949",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "ivargas@aspel.com.mx",
            "Lider": "Pedro Arturo Rico Martinez",
            "Nombre": "Juan Ignacio Hernandez Vargas"
        },
        {
            "_id": "1939",
            "Agente": "1939",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "dbracamonte@aspel.com.mx",
            "Lider": "Celia Lizeth Lopez Panduro",
            "Nombre": "Daniel Martin Bracamonte Arizmendiz"
        },
        {
            "_id": "2142",
            "Agente": "2142",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "avillalba@aspel.com.mx",
            "Lider": "Alma Rosa Romero Garcia",
            "Nombre": "Ana Gabriela Villalba Perez"
        },
        {
            "_id": "52",
            "Agente": "52",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "vmartinez@aspel.com.mx",
            "Lider": "Claudia Valdez Carbajal",
            "Nombre": "Victor De Jesus Martinez Bolio"
        },
        {
            "_id": "2987",
            "Agente": "2987",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "psaavedra@aspel.com.mx",
            "Lider": "Pedro Arturo Rico Martinez",
            "Nombre": "Paloma Gabriela Diaz Saavedra"
        },
        {
            "_id": "182105",
            "Agente": "182105",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "arjo182105@siigo.com",
            "Lider": "Hilder Alonso Penagos Rubio",
            "Nombre": "Alexandra Arjona Arroyave"
        },
        {
            "_id": "900001",
            "Agente": "900001",
            "Area": "Country Management",
            "Division": "Siigo Colombia",
            "Email": null,
            "Lider": null,
            "Nombre": "Siigo Siigo "
        },
        {
            "_id": "3408",
            "Agente": "3408",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "mhernandez@aspel.com.mx",
            "Lider": "Oscar Rodrigo Navarro Hernandez",
            "Nombre": "Marcos Daniel Hernandez Martinez"
        },
        {
            "_id": "1558",
            "Agente": "1558",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "smarquez@aspel.com.mx",
            "Lider": "Adair Blanco Landin",
            "Nombre": "Sergio Aaron Valdez Marquez"
        },
        {
            "_id": "558",
            "Agente": "558",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "mcancino@aspel.com.mx",
            "Lider": "Claudia Valdez Carbajal",
            "Nombre": "Mercy Karla Cancino Mendez"
        },
        {
            "_id": "1415",
            "Agente": "1415",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "bchavez@aspel.com.mx",
            "Lider": "Virginia Guadalupe Ramirez Hernandez",
            "Nombre": "Belem Chavez Ruiz"
        },
        {
            "_id": "2394",
            "Agente": "2394",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "smena@aspel.com.mx",
            "Lider": "Reyna Gricelda Ruiz Medina",
            "Nombre": "Saul Mena Avila"
        },
        {
            "_id": "3087",
            "Agente": "3087",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "vrpuc@aspel.com.mx",
            "Lider": "Pedro Miguel Ocampo Ix",
            "Nombre": "Victor Rodrigo Puc Estrella"
        },
        {
            "_id": "3093",
            "Agente": "3093",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "csantamaria@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Claudia Sofia Santamaria Gonzalez"
        },
        {
            "_id": "900168",
            "Agente": "900168",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": null,
            "Lider": "Nelsy Adriana Sanchez Ramirez",
            "Nombre": "William Felipe Sanchez Martinez"
        },
        {
            "_id": "3497",
            "Agente": "3497",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "jhernandez@aspel.com.mx",
            "Lider": "Alejandro Lopez Manzo",
            "Nombre": "Juan Pablo Frias Hernandez"
        },
        {
            "_id": "3459",
            "Agente": "3459",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "ebustos@aspel.com.mx",
            "Lider": "Juan Carlos Valdes Martin",
            "Nombre": "Estephania Anaid Bustos Mendez"
        },
        {
            "_id": "900054",
            "Agente": "900054",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": null,
            "Lider": "Hugo Ernesto Cruz Casallas",
            "Nombre": "Torres  Sarmiento  Jonathan  David "
        },
        {
            "_id": "900500",
            "Agente": "900500",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pedr900500@siigo.com",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Lida Marcela Pedraza Aleman"
        },
        {
            "_id": "900622",
            "Agente": "900622",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "parr900622@siigo.com",
            "Lider": "Ahylin Dahyan Jamaica Mendez",
            "Nombre": "Valentina Parra Rojas"
        },
        {
            "_id": "900483",
            "Agente": "900483",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gilg900483@siigo.com",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Jenifer Constanza Gil Gutierrez"
        },
        {
            "_id": "900465",
            "Agente": "900465",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "silv900465@siigo.com",
            "Lider": "Harrison Martinez Callejas",
            "Nombre": "Gloria Sofia Silva Lizarazo"
        },
        {
            "_id": "900380",
            "Agente": "900380",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cort900380@siigo.com",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Sergio Sneyder  Cortes Gil"
        },
        {
            "_id": "900618",
            "Agente": "900618",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": null,
            "Lider": "Julian Ernesto Morales Garzon",
            "Nombre": "Yenny Magaly Prieto Toro"
        },
        {
            "_id": "900636",
            "Agente": "900636",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "alie900636@siigo.com",
            "Lider": "Joan Sebastian Rodriguez 0",
            "Nombre": "Jeancarlo Clemente Aliendres Blanco"
        },
        {
            "_id": "900471",
            "Agente": "900471",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "diaz900471@siigo.com",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Luis Angel  Diaz Torres"
        },
        {
            "_id": "900085",
            "Agente": "900085",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "sepu804595@siigo.com",
            "Lider": "Luz Derly Bernal Malaver",
            "Nombre": "Anyi Sepulveda Ricardo"
        },
        {
            "_id": "900098",
            "Agente": "900098",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": null,
            "Lider": "Nelson Juan Carlos Acosta Drouet",
            "Nombre": "Nuval Andres Acevedo Navarro"
        },
        {
            "_id": "900061",
            "Agente": "900061",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "leyt804593@siigo.com",
            "Lider": "Brandon Enrique Gomez Rosales",
            "Nombre": "Faiber Leyton Ducuara"
        },
        {
            "_id": "900068",
            "Agente": "900068",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ruiz804594@siigo.com",
            "Lider": "Jesus Daniel Arzuza Roman",
            "Nombre": "Maira  Alejandra  Ruiz  Espitia"
        },
        {
            "_id": "900253",
            "Agente": "900253",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "valb804638@siigo.com",
            "Lider": "Andrea Catalina Nieto Fernandez",
            "Nombre": "Daniel  Santiago Valbuena  Bermudez"
        },
        {
            "_id": "900083",
            "Agente": "900083",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rodr804613@siigo.com",
            "Lider": "John Misael Martinez Montero",
            "Nombre": "Lady Alexandra Rodriguez Bernal"
        },
        {
            "_id": "900624",
            "Agente": "900624",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "paola.vargas@siigo.com",
            "Lider": "Arturo Alvarez Martinez",
            "Nombre": "Paola Andrea Vargas Gonzalez"
        },
        {
            "_id": "900246",
            "Agente": "900246",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "cort804637@siigo.com",
            "Lider": "Andrea Catalina Nieto Fernandez",
            "Nombre": "Jorge Camilo Cortes Torres"
        },
        {
            "_id": "900459",
            "Agente": "900459",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "lass900459@siigo.com",
            "Lider": "Juliana Castro Diaz",
            "Nombre": "Cristian Camilo Lasso Gamboa"
        },
        {
            "_id": "900481",
            "Agente": "900481",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "faja900481@siigo.com",
            "Lider": "Harrison Martinez Callejas",
            "Nombre": "Martha Humbelina Fajardo Marin"
        },
        {
            "_id": "900469",
            "Agente": "900469",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rodri900469@siigo.com",
            "Lider": "Johana Catalina Peña ",
            "Nombre": "Diana Carolina Rodriguez Sarmiento"
        },
        {
            "_id": "900473",
            "Agente": "900473",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "garz900473@siigo.com",
            "Lider": "Johana Catalina Peña ",
            "Nombre": "Angie Daniela Garzon Bustos"
        },
        {
            "_id": "900241",
            "Agente": "900241",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "garc804635@siigo.com",
            "Lider": "Andrea Catalina Vega Gomez",
            "Nombre": "Erika Paola Garces Gonzalez"
        },
        {
            "_id": "362",
            "Agente": "362",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "afranco@aspel.com.mx",
            "Lider": "Eliseo Lopez Cruz",
            "Nombre": "Abraham Franco Nares"
        },
        {
            "_id": "2258",
            "Agente": "2258",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "lsanchez@aspel.com.mx",
            "Lider": "Norma Florentina Quiñones Martin",
            "Nombre": "Luis Angel Sanchez Ek"
        },
        {
            "_id": "2452",
            "Agente": "2452",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "amay@aspel.com.mx",
            "Lider": "Reyna Gricelda Ruiz Medina",
            "Nombre": "Anahi Marisol Pech May"
        },
        {
            "_id": "884",
            "Agente": "884",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "rruiz@aspel.com.mx",
            "Lider": "Victor De Jesus Martinez Bolio",
            "Nombre": "Reyna Gricelda Ruiz Medina"
        },
        {
            "_id": "2693",
            "Agente": "2693",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jpina@aspel.com.mx",
            "Lider": "Marisol Vargas Cortes",
            "Nombre": "José Pablo Aguilar Piña"
        },
        {
            "_id": "802204",
            "Agente": "802204",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "chav802204@siigo.com",
            "Lider": "Sory Yaqueline Abello Polo",
            "Nombre": "Wilmer Andres Chavez Bermejo"
        },
        {
            "_id": "3363",
            "Agente": "3363",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ccruz@aspel.com.mx",
            "Lider": "Ivan Alejandro Roa Franco",
            "Nombre": "Cecilia Cruz Luna"
        },
        {
            "_id": "3348",
            "Agente": "3348",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "kyama@aspel.com.mx",
            "Lider": "Manuel Ramirez Alvarez",
            "Nombre": "Karen Leticia Yama Yama"
        },
        {
            "_id": "2268",
            "Agente": "2268",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "fsalmeron@aspel.com.mx",
            "Lider": "Alma Rosa Romero Garcia",
            "Nombre": "Fanny Salmeron Munguia"
        },
        {
            "_id": "900031",
            "Agente": "900031",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ahum804616@siigo.com",
            "Lider": "David Alfredo Sanchez Bastidas",
            "Nombre": "Erika Del Mar Ahumada Leon"
        },
        {
            "_id": "900052",
            "Agente": "900052",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": null,
            "Lider": "John Misael Martinez Montero",
            "Nombre": "Laura Caro Olarte"
        },
        {
            "_id": "900115",
            "Agente": "900115",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "gome804598@siigo.com",
            "Lider": "Angie Lorena Perez Espitia",
            "Nombre": "Juan David Gomez Pregonero"
        },
        {
            "_id": "900222",
            "Agente": "900222",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "cast804639@siigo.com",
            "Lider": "Maria Fernanda Delgadillo Bernal",
            "Nombre": "Erika Dayan Castiblanco Peralta"
        },
        {
            "_id": "804055",
            "Agente": "804055",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "cubi804055@siigo.com",
            "Lider": "Katherine Ayala Garcia",
            "Nombre": "Natalia Carolina Cubillos Rico"
        },
        {
            "_id": "900238",
            "Agente": "900238",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "arte804634@siigo.com",
            "Lider": "Maria Paula Garzon Ortega",
            "Nombre": "Erika Alejandra Arteaga Cabrera"
        },
        {
            "_id": "900346",
            "Agente": "900346",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "penu900346@siigo.com",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Leidy Marcela  Peñuela Ruiz"
        },
        {
            "_id": "900354",
            "Agente": "900354",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "hoyo900354@siigo.com",
            "Lider": "Diana Michelle Matamoros Del Rosario",
            "Nombre": "Andry Patricia Hoyos Perez"
        },
        {
            "_id": "900475",
            "Agente": "900475",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pint900475@siigo.com",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Viviana Andrea Pinto Caicedo"
        },
        {
            "_id": "900479",
            "Agente": "900479",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "lara900479@siigo.com",
            "Lider": "Johana Catalina Peña ",
            "Nombre": "Brayan Camilo Lara Peña"
        },
        {
            "_id": "900514",
            "Agente": "900514",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": null,
            "Lider": "Silvia Zilah Fernandez Almeida",
            "Nombre": "Leandro Brian Barcos Bobadilla"
        },
        {
            "_id": "900538",
            "Agente": "900538",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "Cuai900538@siigo.com",
            "Lider": "Nelson Juan Carlos Acosta Drouet",
            "Nombre": "Luna Giuliana Cuaical Bernal"
        },
        {
            "_id": "900551",
            "Agente": "900551",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": null,
            "Lider": "Martha Isabel Diaz Gomez",
            "Nombre": "Danna Ximena Wilches Huertas"
        },
        {
            "_id": "900544",
            "Agente": "900544",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "alda900544@siigo.com",
            "Lider": null,
            "Nombre": "Nikole Andrea Aldana Rey"
        },
        {
            "_id": "801550",
            "Agente": "801550",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "marr801550@siigo.com",
            "Lider": "James Alberto Valencia Caicedo",
            "Nombre": "Santiago Jose Marrugo Monsalve"
        },
        {
            "_id": "1473",
            "Agente": "1473",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jperales@aspel.com.mx",
            "Lider": "Natalia Marinez Gonzalez",
            "Nombre": "Jose Luis Perales Dueñas"
        },
        {
            "_id": "2426",
            "Agente": "2426",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "bjeronimo@aspel.com.mx",
            "Lider": "Agustin Hernandez Lopez",
            "Nombre": "Brenda Jeronimo Clemente"
        },
        {
            "_id": "3209",
            "Agente": "3209",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "cmaldonado@aspel.com.mx",
            "Lider": "Luis Alberto Flores Castillo",
            "Nombre": "Carlos Alberto Vera Maldonado"
        },
        {
            "_id": "1247",
            "Agente": "1247",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "dmay@aspel.com.mx",
            "Lider": "Moises Raul Aguilar Puc",
            "Nombre": "Daniel Enrique May Pech"
        },
        {
            "_id": "3722",
            "Agente": "3722",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "glujan@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Gabriela Del Carmen Lujan Contreras"
        },
        {
            "_id": "2393",
            "Agente": "2393",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jmukul@aspel.com.mx",
            "Lider": "Reyna Gricelda Ruiz Medina",
            "Nombre": "Juan José Mukul Chable"
        },
        {
            "_id": "2211",
            "Agente": "2211",
            "Area": "Product",
            "Division": "Siigo Mexico",
            "Email": "ohernandez@aspel.com.mx",
            "Lider": "Elizabeth Moreno Orozco",
            "Nombre": "Olidia Sanchez Hernandez"
        },
        {
            "_id": "1777",
            "Agente": "1777",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "spineda@aspel.com.mx",
            "Lider": "Gabriel Chavez Carrera",
            "Nombre": "Sergio Rodriguez Pineda"
        },
        {
            "_id": "3215",
            "Agente": "3215",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "lguarneros@aspel.com.mx",
            "Lider": "Brandon Enrique Gomez Rosales",
            "Nombre": "Larissa Katary Guarneros Gutierrez"
        },
        {
            "_id": "3006",
            "Agente": "3006",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "lguadarrama@aspel.com.mx",
            "Lider": "Ivan Zavala Flores",
            "Nombre": "Leidy Guadarrama Reyna"
        },
        {
            "_id": "900041",
            "Agente": "900041",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "roaj804614@siigo.com",
            "Lider": "Alexis Abraham Sanchez Portillo",
            "Nombre": "Jessika  Katherine Roa "
        },
        {
            "_id": "900126",
            "Agente": "900126",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "eche804615@siigo.com",
            "Lider": "Diana Milena Roa Hurtado",
            "Nombre": "Nicolas Cifuentes Echeverry"
        },
        {
            "_id": "900133",
            "Agente": "900133",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "barr804599@siigo.com",
            "Lider": "Diana Mercedes Moncada Peraza",
            "Nombre": "Mildred Valeria Barrera Diaz"
        },
        {
            "_id": "900170",
            "Agente": "900170",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "perd804618@siigo.com",
            "Lider": "Edwin Ricardo Botia Florez",
            "Nombre": "Dilan Gabriel Perdomo  Sanchez"
        },
        {
            "_id": "900264",
            "Agente": "900264",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "tama900264@siigo.com",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Ana Maria  Tamayo  Villarroel"
        },
        {
            "_id": "900357",
            "Agente": "900357",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rodr804642@siigo.com",
            "Lider": "Mario Alberto Rojas Gamboa",
            "Nombre": "Edith  Johana Rodriguez  Tunjuelo"
        },
        {
            "_id": "900389",
            "Agente": "900389",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rico900389@siigo.com",
            "Lider": "Angel Alfonso Arciniegas Guerrero",
            "Nombre": "Gabriela Rico Cardona"
        },
        {
            "_id": "900396",
            "Agente": "900396",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cort900396@siigo.com",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Diva Fabiola Cortes Velasco"
        },
        {
            "_id": "900493",
            "Agente": "900493",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "orti900488@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Melissa Elizabeth Ortiz Caiza"
        },
        {
            "_id": "900522",
            "Agente": "900522",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": null,
            "Lider": "Luis Ignacio Ferreira Duarte",
            "Nombre": "Analia Lopez Pintos Centurion"
        },
        {
            "_id": "900548",
            "Agente": "900548",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "tera900548@siigo.com",
            "Lider": "Yasnaia Ilithya De La Caridad Reyes Coll",
            "Nombre": "Andres Fernando Teran Giraldo"
        },
        {
            "_id": "900620",
            "Agente": "900620",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "domi900620@siigo.com",
            "Lider": "Ahylin Dahyan Jamaica Mendez",
            "Nombre": "Katherin Lizet Domico Ibarra"
        },
        {
            "_id": "900626",
            "Agente": "900626",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "sarr900626@siigo.com",
            "Lider": "Nelsy Adriana Sanchez Ramirez",
            "Nombre": "Alicia Sarria Ceballos"
        },
        {
            "_id": "900630",
            "Agente": "900630",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "torr900630@siigo.com",
            "Lider": "Jose Daniel Huerfano Martinez",
            "Nombre": "Maria Cristina  Torres Gutierrez"
        },
        {
            "_id": "900634",
            "Agente": "900634",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "suar900634@siigo.com",
            "Lider": "Cristian Enrique Albornoz Arias",
            "Nombre": "Yury Lisberth Suarez Gaona"
        },
        {
            "_id": "900334",
            "Agente": "900334",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "gira804641@siigo.com",
            "Lider": "Juan David Lopez Fuentes",
            "Nombre": "Juan Camilo Giraldo Gallego"
        },
        {
            "_id": "900336",
            "Agente": "900336",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pine900336@siigo.com",
            "Lider": "Alison Anaid Chica Ezeta",
            "Nombre": "Sara Yalile Pineda Albarracin"
        },
        {
            "_id": "900342",
            "Agente": "900342",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ayao900342@siigo.com",
            "Lider": "Jefferson Arvey Ramirez Ibañez",
            "Nombre": "Yudy Milena Aya Ortiz"
        },
        {
            "_id": "1528",
            "Agente": "1528",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "crejon@aspel.com.mx",
            "Lider": "Sara Noemi Euan Madera",
            "Nombre": "César Augusto Rejón Vermont"
        },
        {
            "_id": "2742",
            "Agente": "2742",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jcool@aspel.com.mx",
            "Lider": "Gustavo Adolfo Villada Tapias",
            "Nombre": "Jessica Guadalupe Cool Couoh"
        },
        {
            "_id": "2493",
            "Agente": "2493",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "bsanchez@aspel.com.mx",
            "Lider": "Armando Uriel Lopez Vazquez",
            "Nombre": "Brenda Sánchez Sarmiento"
        },
        {
            "_id": "3275",
            "Agente": "3275",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "lereyes@aspel.com.mx",
            "Lider": "Jorge Alfredo Huesca Martinez",
            "Nombre": "Layla Edith Reyes Gutierrez"
        },
        {
            "_id": "3437",
            "Agente": "3437",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "mmartinez@aspel.com.mx",
            "Lider": "Luis Alejandro Salgado Vergara",
            "Nombre": "Moises Martinez Lopez"
        },
        {
            "_id": "2566",
            "Agente": "2566",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "uflores@aspel.com.mx",
            "Lider": "Armando Uriel Lopez Vazquez",
            "Nombre": "Jonathan Uriel Flores Martinez"
        },
        {
            "_id": "2076",
            "Agente": "2076",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "dlopez@aspel.com.mx",
            "Lider": "Adair Blanco Landin",
            "Nombre": "David Lopez Marquez"
        },
        {
            "_id": "658",
            "Agente": "658",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "jgallardo@aspel.com.mx",
            "Lider": "Fernando Contreras Ocaña",
            "Nombre": "Juan Carlos Gallardo Valadez"
        },
        {
            "_id": "3009",
            "Agente": "3009",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "egomez@aspel.com.mx",
            "Lider": "Armando Uriel Lopez Vazquez",
            "Nombre": "Erik Antonio Vega Gomez"
        },
        {
            "_id": "1833",
            "Agente": "1833",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "mpizano@aspel.com.mx",
            "Lider": "María de Jesús Pérez Sanabria",
            "Nombre": "Maribel Calderon Pizano"
        },
        {
            "_id": "2061",
            "Agente": "2061",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "mcortes@aspel.com.mx",
            "Lider": "Juan Jose Correa Acosta",
            "Nombre": "Marisol Vargas Cortes"
        },
        {
            "_id": "1279",
            "Agente": "1279",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "fvillasana@aspel.com.mx",
            "Lider": "Marcela Vanessa Hernandez Moreno",
            "Nombre": "Fernando Villasana Gutierrez"
        },
        {
            "_id": "3146",
            "Agente": "3146",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jmunoz@aspel.com.mx",
            "Lider": "Juan Mauricio Jimenez Espinoza",
            "Nombre": "Josue Isaias Us Muñoz"
        },
        {
            "_id": "1663",
            "Agente": "1663",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "pperez@aspel.com.mx",
            "Lider": "Juan Carlos Garcia Ibarra",
            "Nombre": "Patricia Perez Gallardo"
        },
        {
            "_id": "3338",
            "Agente": "3338",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "atello@aspel.com.mx",
            "Lider": "Jesus Alfonso Leyra Galan",
            "Nombre": "Javier Alberto Arguello Tello"
        },
        {
            "_id": "2647",
            "Agente": "2647",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "ggranados@aspel.com.mx",
            "Lider": "Jesus Alfonso Leyra Galan",
            "Nombre": "Geovanni Antonio Granados"
        },
        {
            "_id": "800805",
            "Agente": "800805",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "carr800805@siigo.com",
            "Lider": "Yamid Ramiro Quintero Montaño",
            "Nombre": "Anmary Chiquinquira Carrero Marquena"
        },
        {
            "_id": "3317",
            "Agente": "3317",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "valopez@aspel.com.mx",
            "Lider": "Maria Teresa Vazquez Garza",
            "Nombre": "Victor Alfonso Lopez Perez"
        },
        {
            "_id": "3107",
            "Agente": "3107",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "mjhernandez@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Maria De Jesus Hernandez Rodriguez"
        },
        {
            "_id": "79",
            "Agente": "79",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "seuan@aspel.com.mx",
            "Lider": "Claudia Valdez Carbajal",
            "Nombre": "Sara Noemi Euan Madera"
        },
        {
            "_id": "2129",
            "Agente": "2129",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "lcarmen@aspel.com.mx",
            "Lider": "Adair Blanco Landin",
            "Nombre": "Lauriano Carmen Manuel"
        },
        {
            "_id": "1526",
            "Agente": "1526",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "lflores@aspel.com.mx",
            "Lider": "Luis Alberto Baas Valdez",
            "Nombre": "Luis Alberto Flores Castillo"
        },
        {
            "_id": "900191",
            "Agente": "900191",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ordo804605@siigo.com",
            "Lider": "Daniel Eduardo Pinto Cepeda",
            "Nombre": "Rocio Del Pilar Ordoñez  Bermudez"
        },
        {
            "_id": "900198",
            "Agente": "900198",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pere804606@siigo.com",
            "Lider": "Leida Adriana Rico Herrera",
            "Nombre": "Diana  Patricia  Perez  Bravo"
        },
        {
            "_id": "900217",
            "Agente": "900217",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "orte804609@siigo.com",
            "Lider": "Leida Adriana Rico Herrera",
            "Nombre": "Paola Andrea Ortega Meneses"
        },
        {
            "_id": "900234",
            "Agente": "900234",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "leon804633@siigo.com",
            "Lider": "James Alberto Valencia Caicedo",
            "Nombre": "Heidy Jazmin Leon Torres"
        },
        {
            "_id": "900518",
            "Agente": "900518",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": null,
            "Lider": "Luis Ignacio Ferreira Duarte",
            "Nombre": "Luis Joel Pereyra Martine"
        },
        {
            "_id": "900520",
            "Agente": "900520",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "ricardo.morales@siigo.com",
            "Lider": "Luis Ignacio Ferreira Duarte",
            "Nombre": "Ricardo Martin Morales Carreras"
        },
        {
            "_id": "803635",
            "Agente": "803635",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "hern803635@siigo.com",
            "Lider": "Juan Carlos Buitrago Rios",
            "Nombre": "Angie Julieth Hernandez Zapata"
        },
        {
            "_id": "900145",
            "Agente": "900145",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "orti804601@siigo.com",
            "Lider": "Gustavo Adolfo Espinosa Toledo",
            "Nombre": "Jose Alejandro  Ortiz  Perez"
        },
        {
            "_id": "900152",
            "Agente": "900152",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "herr804602@siigo.com",
            "Lider": "Gustavo Adolfo Espinosa Toledo",
            "Nombre": "Angie Daniela Herrera Morales"
        },
        {
            "_id": "900154",
            "Agente": "900154",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "liza804617@siigo.com",
            "Lider": "Claudia Marcela Cano Forero",
            "Nombre": "Yessica  Katerine  Lizarazo  Riaño"
        },
        {
            "_id": "900228",
            "Agente": "900228",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "vane804612@siigo.com",
            "Lider": "Juvenal Reyes Silva",
            "Nombre": "Diana Carolina Vanegas Pedraza"
        },
        {
            "_id": "111806",
            "Agente": "111806",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "guar111806@siigo.com",
            "Lider": "Ivan Dario Corchuelo Castro",
            "Nombre": "Juan Carlos Guarin Walteros"
        },
        {
            "_id": "803792",
            "Agente": "803792",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "orte803792@siigo.com",
            "Lider": "Sory Yaqueline Abello Polo",
            "Nombre": "Angela Marcela Ortega Realpe"
        },
        {
            "_id": "110130",
            "Agente": "110130",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "bust110130@siigo.com",
            "Lider": "Liliana Ramirez Vanegas",
            "Nombre": "Maria Del Pilar Bustos Gomez"
        },
        {
            "_id": "804132",
            "Agente": "804132",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "pini804132@siigo.com",
            "Lider": "Omar Eduardo Roa Quintero",
            "Nombre": "John Alexander Pinilla Celis"
        },
        {
            "_id": "3503",
            "Agente": "3503",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "atorres@aspel.com.mx",
            "Lider": "Arturo Alvarez Martinez",
            "Nombre": "Amaru Torres Reyes"
        },
        {
            "_id": "801455",
            "Agente": "801455",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "more801455@siigo.com",
            "Lider": "Maria Ximena Manrique Isaziga",
            "Nombre": "Yomaira Yazmin Moreno Muñoz"
        },
        {
            "_id": "801446",
            "Agente": "801446",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mora801446@siigo.com",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Angie Viviana Mora Quintero"
        },
        {
            "_id": "800692",
            "Agente": "800692",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mend800692@siigo.com",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre": "Elisa Maria Mendez Manjarres"
        },
        {
            "_id": "802675",
            "Agente": "802675",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "meza802675@siigo.com",
            "Lider": "Alix Dayana Archila Castillo",
            "Nombre": "Yuliana Maritza Meza Jossa"
        },
        {
            "_id": "1988",
            "Agente": "1988",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "clopez@aspel.com.mx",
            "Lider": "Fernando Contreras Ocaña",
            "Nombre": "Celia Lizeth Lopez Panduro"
        },
        {
            "_id": "800861",
            "Agente": "800861",
            "Area": "Finance & Administration",
            "Division": "Siigo Uruguay",
            "Email": "keym800861@siigo.com",
            "Lider": "Luisa Fernanda Caro Camargo",
            "Nombre": "Giselda Keymetlian 0"
        },
        {
            "_id": "802537",
            "Agente": "802537",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "clar802537@siigo.com",
            "Lider": "Juan Pablo Carreño Patiño",
            "Nombre": "Veronica Claros Marin"
        },
        {
            "_id": "801433",
            "Agente": "801433",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "barb801433@siigo.com",
            "Lider": "Nataly Carolina Gonzalez Sandoval",
            "Nombre": "Paula Andrea Barbosa Borja"
        },
        {
            "_id": "800902",
            "Agente": "800902",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "loai800902@siigo.com",
            "Lider": "Yuleidy Josefina Briceño Fernandez",
            "Nombre": "Enith Yohana Loaiza Gonzalez"
        },
        {
            "_id": "801801",
            "Agente": "801801",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "moli801801@siigo.com",
            "Lider": "Lida Katerine Puentes Navarro",
            "Nombre": "Ronald Alberto Molina Palacio"
        },
        {
            "_id": "801112",
            "Agente": "801112",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "muen801112@siigo.com",
            "Lider": "Ivan Elias Jusid Machado",
            "Nombre": "Mauricio Javier Muenzzo Albistur"
        },
        {
            "_id": "28257",
            "Agente": "28257",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "Garc28257@siigo.com",
            "Lider": "Carlos Mario Espinosa Fernandez",
            "Nombre": "Ecna Lisette Garcia Espinosa"
        },
        {
            "_id": "801296",
            "Agente": "801296",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "alva801296@siigo.com",
            "Lider": "Leidy Stefany Castillo 0",
            "Nombre": "Angie Daniela Alvarez Bohorquez"
        },
        {
            "_id": "801142",
            "Agente": "801142",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "barc801142@siigo.com",
            "Lider": "Gustavo Adolfo Villada Tapias",
            "Nombre": "Ivonne Carolina Barco Enriquez"
        },
        {
            "_id": "803659",
            "Agente": "803659",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pere803659@siigo.com",
            "Lider": "Jefferson Arvey Ramirez Ibañez",
            "Nombre": "Manuel Alejandro Perez Gutierrez"
        },
        {
            "_id": "803538",
            "Agente": "803538",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vald803538@siigo.com",
            "Lider": "Lida Katerine Puentes Navarro",
            "Nombre": "Junior Enrique Valderrama Bravo"
        },
        {
            "_id": "2221",
            "Agente": "2221",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "jmacias@aspel.com.mx",
            "Lider": "Alma Rosa Romero Garcia",
            "Nombre": "Josefina Aidee Macias Hernandez"
        },
        {
            "_id": "2234",
            "Agente": "2234",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "afloresh@aspel.com.mx",
            "Lider": "María Elizabeth Mani Jimenez",
            "Nombre": "Alicia Hernandez Flores"
        },
        {
            "_id": "2330",
            "Agente": "2330",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "mcampos@aspel.com.mx",
            "Lider": "Marisol Vargas Cortes",
            "Nombre": "Rosa Miriam Campos Cruz"
        },
        {
            "_id": "803586",
            "Agente": "803586",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "eche803586@siigo.com",
            "Lider": "Luis Ignacio Ferreira Duarte",
            "Nombre": "Martin Esteban Echegorri Almiron"
        },
        {
            "_id": "112341",
            "Agente": "112341",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gale112341@siigo.com",
            "Lider": "William Andres Reyes Silva",
            "Nombre": "Calendaria Galeano Cruz"
        },
        {
            "_id": "803613",
            "Agente": "803613",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "prad803613@siigo.com",
            "Lider": "Luz Katerine Maya Mogollón",
            "Nombre": "Jhon Weimar Prada Rojas"
        },
        {
            "_id": "803800",
            "Agente": "803800",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "baja803800@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "David Antonio Bajaña Troya"
        },
        {
            "_id": "802637",
            "Agente": "802637",
            "Area": "Finance & Administration",
            "Division": "Siigo Ecuador",
            "Email": "tumb802637@siigo.com",
            "Lider": "Diana Elizabeth Davila Murillo",
            "Nombre": "Luiggi Ivan Tumbaco Miranda"
        },
        {
            "_id": "804521",
            "Agente": "804521",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mora804521@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Ana Karina Mora Arias"
        },
        {
            "_id": "803778",
            "Agente": "803778",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "corr803778@siigo.com",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre": "Jesus Alfonso Correa Camargo"
        },
        {
            "_id": "800077",
            "Agente": "800077",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cele800077@siigo.com",
            "Lider": "Yamid Ramiro Quintero Montaño",
            "Nombre": "Lady Dayan Celeita Correal"
        },
        {
            "_id": "803612",
            "Agente": "803612",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bent803612@siigo.com",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre": "Katerine Paola Benthan Gutierrez"
        },
        {
            "_id": "110114",
            "Agente": "110114",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "rodr110114@siigo.com",
            "Lider": "Edith Angelica Gil Umbarila",
            "Nombre": "Aura Cristina Rodriguez Manrique"
        },
        {
            "_id": "803500",
            "Agente": "803500",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "carv803500@siigo.com",
            "Lider": "Liliana Andrea Segura Martinez",
            "Nombre": "Laura Carvajal Chaparro"
        },
        {
            "_id": "111883",
            "Agente": "111883",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cano111883@siigo.com",
            "Lider": "Gloria Estela Osorio Montoya",
            "Nombre": "Juliana Maria Cano Alvarez"
        },
        {
            "_id": "111799",
            "Agente": "111799",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "nava111799@siigo.com",
            "Lider": "Yeimy Milena Bustos Gutierrez",
            "Nombre": "Jennifer Eliana Navarro Olaya"
        },
        {
            "_id": "804027",
            "Agente": "804027",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "paez804027@siigo.com",
            "Lider": "Carlos Jesus Poveda Zapata",
            "Nombre": "Nicolas Mauricio Paez Cortes"
        },
        {
            "_id": "109550",
            "Agente": "109550",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "diaz109550@siigo.com",
            "Lider": "Edith Angelica Gil Umbarila",
            "Nombre": "Martha Isabel Diaz Gomez"
        },
        {
            "_id": "800717",
            "Agente": "800717",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "ller800717@siigo.com",
            "Lider": "Juan Carlos Sanchez Velez",
            "Nombre": "Stephanie Carolina Llerena Alfonzo"
        },
        {
            "_id": "803837",
            "Agente": "803837",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rang803837@siigo.com",
            "Lider": "Jose Luis Perales Dueñas",
            "Nombre": "Julian David Rangel Hincapie"
        },
        {
            "_id": "3183",
            "Agente": "3183",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "jhidalgo@aspel.com.mx",
            "Lider": "Fernando Villasana Gutierrez",
            "Nombre": "Jose Angel Hidalgo Vargas"
        },
        {
            "_id": "803509",
            "Agente": "803509",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "lope803509@siigo.com",
            "Lider": "Ana Maria Ramirez Sanchez",
            "Nombre": "Diana Paola Lopez "
        },
        {
            "_id": "3347",
            "Agente": "3347",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "klerista@aspel.com.mx",
            "Lider": "Ivan Alejandro Roa Franco",
            "Nombre": "Karina De la Rosa Lerista"
        },
        {
            "_id": "3415",
            "Agente": "3415",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "hmeza@aspel.com.mx",
            "Lider": "Ivan Alejandro Roa Franco",
            "Nombre": "Hector Meza Hernandez"
        },
        {
            "_id": "3255",
            "Agente": "3255",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "cagarcia@aspel.com.mx",
            "Lider": "Victor Hugo Huerta Lopez",
            "Nombre": "Carlos Alberto Garcia Castillo"
        },
        {
            "_id": "40235",
            "Agente": "40235",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "diaz40235@siigo.com",
            "Lider": "John Mario Perez Diaz",
            "Nombre": "Jotman Sandy Diaz Hurtado"
        },
        {
            "_id": "801913",
            "Agente": "801913",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "estr801913@siigo.com",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre": "Luz Daniela Estrada Pino"
        },
        {
            "_id": "801939",
            "Agente": "801939",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "xiqu801939@siigo.com",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre": "Gabriela Maury Xiques Jimenez"
        },
        {
            "_id": "801834",
            "Agente": "801834",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "loza801834@siigo.com",
            "Lider": "Nestor Oswaldo Becerra Prieto",
            "Nombre": "Ingrid Jhoana Lozano Betancourth"
        },
        {
            "_id": "112410",
            "Agente": "112410",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "para112410@siigo.com",
            "Lider": "David Julian Rojas Prado",
            "Nombre": "Jorge Luis Parada Moreno"
        },
        {
            "_id": "3178",
            "Agente": "3178",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "zelisa@aspel.com.mx",
            "Lider": "Laura Erika Utrera Utrera",
            "Nombre": "Zaira Elisa Us Uc"
        },
        {
            "_id": "802693",
            "Agente": "802693",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sala802693@siigo.com",
            "Lider": "Alix Dayana Archila Castillo",
            "Nombre": "Estefanni Salazar Rey"
        },
        {
            "_id": "803302",
            "Agente": "803302",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gera803302@siigo.com",
            "Lider": "William Andres Reyes Silva",
            "Nombre": "Rances David Geraldino Triana"
        },
        {
            "_id": "804553",
            "Agente": "804553",
            "Area": "Finance & Administration",
            "Division": "Siigo Ecuador",
            "Email": "campo804553@siigo.com",
            "Lider": "Sergio Alberto Caicedo Ramirez",
            "Nombre": "Keila Del Rocio Campozano Lopez"
        },
        {
            "_id": "803539",
            "Agente": "803539",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "palo803539@siigo.com",
            "Lider": "Lida Katerine Puentes Navarro",
            "Nombre": "Jeferson Jose Palomino Guerrero"
        },
        {
            "_id": "1711",
            "Agente": "1711",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "erodriguez@aspel.com.mx",
            "Lider": "Carlos German Lopez Murcia",
            "Nombre": "Elvira Lopez Rodriguez"
        },
        {
            "_id": "803887",
            "Agente": "803887",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "saag803887@siigo.com",
            "Lider": "Carlos Roberto Alvarado Mancera",
            "Nombre": "Hector Andres Saa Gallego"
        },
        {
            "_id": "802511",
            "Agente": "802511",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vale802511@siigo.com",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre": "Cristian David Valencia Alvarez"
        },
        {
            "_id": "803772",
            "Agente": "803772",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rodr803772@siigo.com",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre": "Juan David Rodriguez Trujilo"
        },
        {
            "_id": "112549",
            "Agente": "112549",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "leal112549@siigo.com",
            "Lider": "Juan Camilo Gonzalez Saldaña",
            "Nombre": "Magda Lilian Leal Rozo"
        },
        {
            "_id": "30852",
            "Agente": "30852",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "Oroz30852@siigo.com",
            "Lider": "Jose Daniel Huerfano Martinez",
            "Nombre": "Dina Liceth Orozco Rivera"
        },
        {
            "_id": "110133",
            "Agente": "110133",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "fuen110133@siigo.com",
            "Lider": "Magda de Jesús Becerra Castañeda",
            "Nombre": "Lucy Esperanza Fuentes Martinez"
        },
        {
            "_id": "800677",
            "Agente": "800677",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cast800677@siigo.com",
            "Lider": "Lida Katerine Puentes Navarro",
            "Nombre": "Cindy Carolina Castrillon Castillo"
        },
        {
            "_id": "800840",
            "Agente": "800840",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rive800840@siigo.com",
            "Lider": "Francy Julieth Viracacha Gutierrez",
            "Nombre": "Martha Yazmin Rivera Vega"
        },
        {
            "_id": "2022",
            "Agente": "2022",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "kchim@aspel.com.mx",
            "Lider": "Claudia Valdez Carbajal",
            "Nombre": "Katy Guadalupe Chim Moo"
        },
        {
            "_id": "802716",
            "Agente": "802716",
            "Area": "Finance & Administration",
            "Division": "Siigo Ecuador",
            "Email": "maci802716@siigo.com",
            "Lider": "Diana Elizabeth Davila Murillo",
            "Nombre": "Maria Pamela Macias Zurita"
        },
        {
            "_id": "801193",
            "Agente": "801193",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "reye801193@siigo.com",
            "Lider": "Mario Alberto Rojas Gamboa",
            "Nombre": "Johan Alejandro Reyes Cruz"
        },
        {
            "_id": "801777",
            "Agente": "801777",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "muno801777@siigo.com",
            "Lider": "Yeimy Milena Bustos Gutierrez",
            "Nombre": "Ana Gabriela Muñoz Rodriguez"
        },
        {
            "_id": "800703",
            "Agente": "800703",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "mend800703@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Richard Mendez Garcia"
        },
        {
            "_id": "801245",
            "Agente": "801245",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "mora801245@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Natasha Lissette Mora Vanegas"
        },
        {
            "_id": "801821",
            "Agente": "801821",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "meji801821@siigo.com",
            "Lider": "Leidy Stefany Castillo 0",
            "Nombre": "Nayelis Carolina Mejía Suárez"
        },
        {
            "_id": "801311",
            "Agente": "801311",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "ojed801311@siigo.com",
            "Lider": "Marco Antonio Ortega Piedrahita",
            "Nombre": "Fredy Fabian Ojeda Ospino"
        },
        {
            "_id": "801292",
            "Agente": "801292",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bece801292@siigo.com",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre": "Stefanny Becerra Garcia"
        },
        {
            "_id": "801832",
            "Agente": "801832",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sana801832@siigo.com",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre": "Monica Lizeth Sanabria Vargas"
        },
        {
            "_id": "802533",
            "Agente": "802533",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "roja802533@siigo.com",
            "Lider": "Francy Julieth Viracacha Gutierrez",
            "Nombre": "Duvan Alejandro Rojas Lasso"
        },
        {
            "_id": "40762",
            "Agente": "40762",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "sern40762@siigo.com",
            "Lider": "Diana Maria Naranjo Mattheus",
            "Nombre": "Ana Maria Serna Gonzalez"
        },
        {
            "_id": "802220",
            "Agente": "802220",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "rome802220@siigo.com",
            "Lider": "Joan Sebastian Rodriguez 0",
            "Nombre": "Laura Camila Romero Rubio"
        },
        {
            "_id": "801141",
            "Agente": "801141",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "gavi801141@siigo.com",
            "Lider": "Manuel Fernando Zambrano Ceron",
            "Nombre": "Deiber Gaviria Alvarado"
        },
        {
            "_id": "803871",
            "Agente": "803871",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rubi803871@siigo.com",
            "Lider": "Andres Felipe Baron Ballen",
            "Nombre": "Johana Stefany Rubio Rivera"
        },
        {
            "_id": "803503",
            "Agente": "803503",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "foll803503@siigo.com",
            "Lider": "Liliana Andrea Segura Martinez",
            "Nombre": "Paulo Alejandro Folleco Segovia"
        },
        {
            "_id": "273694",
            "Agente": "273694",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "Leon273694@siigo.com",
            "Lider": "Natalia Marinez Gonzalez",
            "Nombre": "Claudia Marcela Leon Cerinza"
        },
        {
            "_id": "803912",
            "Agente": "803912",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mate803912@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Andrea Paola Mateus Rodriguez"
        },
        {
            "_id": "803669",
            "Agente": "803669",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ruiz803669@siigo.com",
            "Lider": "John Misael Martinez Montero",
            "Nombre": "Luisa Fernanda Ruiz Cardenas"
        },
        {
            "_id": "802684",
            "Agente": "802684",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "zeab802684@siigo.com",
            "Lider": "Yesika Viviana Garcia Niño",
            "Nombre": "Jessica Marcela Zea Ballesteros"
        },
        {
            "_id": "803510",
            "Agente": "803510",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "caba803510@siigo.com",
            "Lider": "Frankgel Ribely Machado Pantoja",
            "Nombre": "Luis Gustavo Caballero Sierra"
        },
        {
            "_id": "804022",
            "Agente": "804022",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "roch804022@siigo.com",
            "Lider": "Diana Michelle Matamoros Del Rosario",
            "Nombre": "Julian Andres Rocha Rodriguez"
        },
        {
            "_id": "800732",
            "Agente": "800732",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "leon800732@siigo.com",
            "Lider": "Angel Alfonso Arciniegas Guerrero",
            "Nombre": "Jaime Fernando Leon Morales"
        },
        {
            "_id": "111810",
            "Agente": "111810",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "roac111810@siigo.com",
            "Lider": "Jessica Paola Paez Quintero",
            "Nombre": "Diego Alejandro Roa Contreras"
        },
        {
            "_id": "111761",
            "Agente": "111761",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "duen111761@siigo.com",
            "Lider": "Hugo Ernesto Cruz Casallas",
            "Nombre": "Rodrigo Dueñas Chaparro"
        },
        {
            "_id": "3007",
            "Agente": "3007",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "nferreira@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Nataly Ferreira Carrera"
        },
        {
            "_id": "1519",
            "Agente": "1519",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "lmis@aspel.com.mx",
            "Lider": "Norma Florentina Quiñones Martin",
            "Nombre": "Luis Mauricio Mis Can"
        },
        {
            "_id": "804563",
            "Agente": "804563",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mene804563@siigo.com",
            "Lider": "Marisela Yasmin Franco Cruz",
            "Nombre": "Jessica Carolina Meneses Arango"
        },
        {
            "_id": "800420",
            "Agente": "800420",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "sala800420@siigo.com",
            "Lider": "David Alfredo Sanchez Bastidas",
            "Nombre": "Ruth Veronica Salas Montañez"
        },
        {
            "_id": "23913",
            "Agente": "23913",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bern23913@siigo.com",
            "Lider": "Yenny Milena Lopez Florez",
            "Nombre": "Luzdary Bernal Villarreal"
        },
        {
            "_id": "111930",
            "Agente": "111930",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rest111930@siigo.com",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre": "Natalia Restrepo Garcia"
        },
        {
            "_id": "33421",
            "Agente": "33421",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "arev33421@siigo.com",
            "Lider": "John Misael Martinez Montero",
            "Nombre": "Diana Milena Arevalo Hernandez"
        },
        {
            "_id": "803830",
            "Agente": "803830",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "vale803830@siigo.com",
            "Lider": "James Alberto Valencia Caicedo",
            "Nombre": "Maribel Valera Peña"
        },
        {
            "_id": "802727",
            "Agente": "802727",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "cast802727@siigo.com",
            "Lider": "Andres David Leon Guerra",
            "Nombre": "Mariana Castaño Rincon"
        },
        {
            "_id": "804175",
            "Agente": "804175",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mont804175@siigo.com",
            "Lider": "Gloria Estela Osorio Montoya",
            "Nombre": "Laura Nataly Montaño Suarez"
        },
        {
            "_id": "803504",
            "Agente": "803504",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "palm803504@siigo.com",
            "Lider": "Liliana Andrea Segura Martinez",
            "Nombre": "Katerine Andrea Palma Ripoll"
        },
        {
            "_id": "3551",
            "Agente": "3551",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "mbuchan@aspel.com.mx",
            "Lider": "Nelson Juan Carlos Acosta Drouet",
            "Nombre": "Miguel Angel Buchan Ayala"
        },
        {
            "_id": "3100",
            "Agente": "3100",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "bgsanchez@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Blanca Guadalupe Sanchez Sanchez"
        },
        {
            "_id": "803640",
            "Agente": "803640",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "guti803640@siigo.com",
            "Lider": "Francy Julieth Viracacha Gutierrez",
            "Nombre": "Yenny Andrea Gutierrez Acevedo"
        },
        {
            "_id": "3560",
            "Agente": "3560",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "kjesus@aspel.com.mx",
            "Lider": "Brandon Enrique Gomez Rosales",
            "Nombre": "Karen Selene De Jesus Angeles"
        },
        {
            "_id": "802282",
            "Agente": "802282",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "hern802282@siigo.com",
            "Lider": "Hugo Ernesto Cruz Casallas",
            "Nombre": "Maria Elisa Hernandez Martinez"
        },
        {
            "_id": "3246",
            "Agente": "3246",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "tbanda@aspel.com.mx",
            "Lider": "Marisela Yasmin Franco Cruz",
            "Nombre": "Tomas Israel Fernandez Banda"
        },
        {
            "_id": "800570",
            "Agente": "800570",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "moro800570@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "David Morocho Guarnizo"
        },
        {
            "_id": "3509",
            "Agente": "3509",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "arementeria@aspel.com.mx",
            "Lider": "Arturo Alvarez Martinez",
            "Nombre": "Alina Montserrat Rementeria Cruz"
        },
        {
            "_id": "802602",
            "Agente": "802602",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "cast802602@siigo.com",
            "Lider": "Miguel Oswaldo Velasquez Fragiel",
            "Nombre": "Helder Yesid Castrillon Cobo"
        },
        {
            "_id": "803289",
            "Agente": "803289",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "cast803289@siigo.com",
            "Lider": "Melissa Cristina Russo Coba",
            "Nombre": "Leidy Alejandra Castro Gamba"
        },
        {
            "_id": "800993",
            "Agente": "800993",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "andr800993@siigo.com",
            "Lider": "Hernan Dario Parra Osorio",
            "Nombre": "Maria Camila Andrade Montaño"
        },
        {
            "_id": "204477",
            "Agente": "204477",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pera204477@siigo.com",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre": "Elvis Manuel Peralta Quintero"
        },
        {
            "_id": "803654",
            "Agente": "803654",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gera803654@siigo.com",
            "Lider": "Lida Katerine Puentes Navarro",
            "Nombre": "Miguel Angel Geraldino Romero"
        },
        {
            "_id": "3548",
            "Agente": "3548",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "ldgonzalez@aspel.com.mx",
            "Lider": "Juan Carlos Valdes Martin",
            "Nombre": "Lizbeth Diaz Gonzalez Gonzalez Gonzalez"
        },
        {
            "_id": "2651",
            "Agente": "2651",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "rsil@aspel.com.mx",
            "Lider": "Bismark Ivan Rios Campos",
            "Nombre": "Roberto Patricio Sil Guevara"
        },
        {
            "_id": "802897",
            "Agente": "802897",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "medi802897@siigo.com",
            "Lider": "Miguel Oswaldo Velasquez Fragiel",
            "Nombre": "Wilson Medina Nuñez"
        },
        {
            "_id": "2020",
            "Agente": "2020",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "aavila@aspel.com.mx",
            "Lider": "Moises Raul Aguilar Puc",
            "Nombre": "Edwin Armando Avila Ruiz"
        },
        {
            "_id": "2523",
            "Agente": "2523",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "cmarquezm@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Christhian Marquez Marquez"
        },
        {
            "_id": "800059",
            "Agente": "800059",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "hern800059@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Tatiana Hernandez Vargas"
        },
        {
            "_id": "802382",
            "Agente": "802382",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cano802382@siigo.com",
            "Lider": "David Alfredo Sanchez Bastidas",
            "Nombre": "Yuri Alexandra Cañon Bustos"
        },
        {
            "_id": "801318",
            "Agente": "801318",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "duar801318@siigo.com",
            "Lider": "Gustavo Adolfo Espinosa Toledo",
            "Nombre": "Yesica Paola Duarte Moreno"
        },
        {
            "_id": "801349",
            "Agente": "801349",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "sala801349@siigo.com",
            "Lider": "Giovanni Baquero Hernandez",
            "Nombre": "Maria Alexandra Salazar Velasquez"
        },
        {
            "_id": "800217",
            "Agente": "800217",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "lipe800217@siigo.com",
            "Lider": "Juvenal Reyes Silva",
            "Nombre": "Ronald Gerardo Lipez Calderon"
        },
        {
            "_id": "170",
            "Agente": "170",
            "Area": "Product",
            "Division": "Siigo Mexico",
            "Email": "lperezm@aspel.com.mx",
            "Lider": "Rodrigo Garzon Dominguez",
            "Nombre": "Maria De Lourdes Perez Martinez"
        },
        {
            "_id": "201724",
            "Agente": "201724",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Buit201724@siigo.com",
            "Lider": "Liliana Patricia Giraldo Gomez",
            "Nombre": "Juan Carlos Buitrago Rios"
        },
        {
            "_id": "800247",
            "Agente": "800247",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "card800247@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Yoan Sebastian Cardona Pedraza"
        },
        {
            "_id": "804131",
            "Agente": "804131",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "guev804131@siigo.com",
            "Lider": "Gustavo Adolfo Villada Tapias",
            "Nombre": "Juan Felipe Guevara Garcia"
        },
        {
            "_id": "1765",
            "Agente": "1765",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "galvarez@aspel.com.mx",
            "Lider": "Alejandro Lopez Manzo",
            "Nombre": "Guillermo Alvarez Moran"
        },
        {
            "_id": "800309",
            "Agente": "800309",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "prec800309@siigo.com",
            "Lider": "Yuleidy Josefina Briceño Fernandez",
            "Nombre": "Sandra Bibiana Preciado Vera"
        },
        {
            "_id": "32671",
            "Agente": "32671",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "lope32671@siigo.com",
            "Lider": "Carlos Mario Espinosa Fernandez",
            "Nombre": "Yenny Milena Lopez Florez"
        },
        {
            "_id": "111915",
            "Agente": "111915",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "quin111915@siigo.com",
            "Lider": "Sindy Paola Lasso Velasco",
            "Nombre": "Edison Armando Quintero 0"
        },
        {
            "_id": "2585",
            "Agente": "2585",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "cgarcia@aspel.com.mx",
            "Lider": "Belem Chavez Ruiz",
            "Nombre": "Carlos Alberto Reyes Garcia"
        },
        {
            "_id": "802758",
            "Agente": "802758",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "amay802758@siigo.com",
            "Lider": "Giovanni Baquero Hernandez",
            "Nombre": "Harold Arley Amaya Monroy"
        },
        {
            "_id": "2419",
            "Agente": "2419",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "amanjarrez@aspel.com.mx",
            "Lider": "Victor Hugo Huerta Lopez",
            "Nombre": "Andrea Yaquelin Lopez Manjarrez"
        },
        {
            "_id": "802019",
            "Agente": "802019",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gamb802019@siigo.com",
            "Lider": "Carlos Eduardo Sanchez Castaño",
            "Nombre": "Rosa Angélica Gamboa León"
        },
        {
            "_id": "3175",
            "Agente": "3175",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "rcalixto@aspel.com.mx",
            "Lider": "María Elizabeth Mani Jimenez",
            "Nombre": "Roberto Calixto Zamora"
        },
        {
            "_id": "803644",
            "Agente": "803644",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "boli803644@siigo.com",
            "Lider": "Jairo Andres Mendieta 0",
            "Nombre": "Simon Alberto Bolivar Gordillo"
        },
        {
            "_id": "2912",
            "Agente": "2912",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "mgonzalez@aspel.com.mx",
            "Lider": "Armando Uriel Lopez Vazquez",
            "Nombre": "Martin Gonzalez Gonzalez"
        },
        {
            "_id": "802499",
            "Agente": "802499",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "deal802499@siigo.com",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre": "Harol Styd De Alba Taibel"
        },
        {
            "_id": "3284",
            "Agente": "3284",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "dandrade@aspel.com.mx",
            "Lider": "Maria Teresa Vazquez Garza",
            "Nombre": "Daniel Andrade Contreras"
        },
        {
            "_id": "800393",
            "Agente": "800393",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mont800393@siigo.com",
            "Lider": "Giovanni Baquero Hernandez",
            "Nombre": "Harold Jose Montenegro Galindo"
        },
        {
            "_id": "3299",
            "Agente": "3299",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ivaldivia@aspel.com.mx",
            "Lider": "Maria Teresa Vazquez Garza",
            "Nombre": "Itzel Hernandez Valdivia"
        },
        {
            "_id": "804158",
            "Agente": "804158",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gome804158@siigo.com",
            "Lider": "Diana Maria Naranjo Mattheus",
            "Nombre": "Carlos Andres Gomez Cubillos"
        },
        {
            "_id": "800640",
            "Agente": "800640",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "quir800640@siigo.com",
            "Lider": "Gabriel Alexander Romero Mora",
            "Nombre": "Lenny Aracely Quiroz Manzalba"
        },
        {
            "_id": "803580",
            "Agente": "803580",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bena803580@siigo.com",
            "Lider": "William Jesus Sotaquira Ayala",
            "Nombre": "John Alexander Benavides Cobos"
        },
        {
            "_id": "804074",
            "Agente": "804074",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mora804074@siigo.com",
            "Lider": "Leidy Stefany Castillo 0",
            "Nombre": "Anderson Yesid Mora Muñoz"
        },
        {
            "_id": "801775",
            "Agente": "801775",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "nava801775@siigo.com",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre": "William Andres Navas Cárdenas"
        },
        {
            "_id": "801127",
            "Agente": "801127",
            "Area": "Product",
            "Division": "Siigo Uruguay",
            "Email": "rami801127@siigo.com",
            "Lider": "Oscar Francisco Plaza Giler",
            "Nombre": "Mauricio Ramirez Minetti"
        },
        {
            "_id": "803770",
            "Agente": "803770",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "hern803770@siigo.com",
            "Lider": "Carlos Eduardo Sanchez Castaño",
            "Nombre": "Javier Alonzo Hernandez Ramirez"
        },
        {
            "_id": "803522",
            "Agente": "803522",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "garc803522@siigo.com",
            "Lider": "Eliana Maria Fajardo Prada",
            "Nombre": "Ana Milena Garcia Urrego"
        },
        {
            "_id": "802406",
            "Agente": "802406",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "gilc802406@siigo.com",
            "Lider": "Julian Ernesto Morales Garzon",
            "Nombre": "Maria Camila Gil Castañeda"
        },
        {
            "_id": "3150",
            "Agente": "3150",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jluna@aspel.com.mx",
            "Lider": "Maria Virginia Lopez Salazar",
            "Nombre": "Juan Lorenzo Luna Canul"
        },
        {
            "_id": "33806",
            "Agente": "33806",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "otal33806@siigo.com",
            "Lider": "Nataly Carolina Gonzalez Sandoval",
            "Nombre": "Angelica Maria Otalvaro Rios"
        },
        {
            "_id": "803798",
            "Agente": "803798",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "perd803798@siigo.com",
            "Lider": "Sory Yaqueline Abello Polo",
            "Nombre": "Jennifer Tatiana Perdomo Ordoñez"
        },
        {
            "_id": "2726",
            "Agente": "2726",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "dparra@aspel.com.mx",
            "Lider": "Celia Lizeth Lopez Panduro",
            "Nombre": "David Victor Parra Cano"
        },
        {
            "_id": "3039",
            "Agente": "3039",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "mpeniche@aspel.com.mx",
            "Lider": "Belem Chavez Ruiz",
            "Nombre": "Manuel Peniche Castillo"
        },
        {
            "_id": "800215",
            "Agente": "800215",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "loza800215@siigo.com",
            "Lider": "David Alfredo Sanchez Bastidas",
            "Nombre": "Viviana Carolina Lozano 0"
        },
        {
            "_id": "800007",
            "Agente": "800007",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "more800007@siigo.com",
            "Lider": "Gustavo Adolfo Valencia Chaparro",
            "Nombre": "Ana Maria Moreno Mora"
        },
        {
            "_id": "803667",
            "Agente": "803667",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "jord803667@siigo.com",
            "Lider": "Sara Carolina Chica Lopez",
            "Nombre": "Carolina Jordan Naranjo"
        },
        {
            "_id": "803621",
            "Agente": "803621",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "casa803621@siigo.com",
            "Lider": "Luis Ignacio Ferreira Duarte",
            "Nombre": "Juan Martin Casavalle Bonilla"
        },
        {
            "_id": "803372",
            "Agente": "803372",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "acer803372@siigo.com",
            "Lider": "Cristian Andres Collazos Ruiz",
            "Nombre": "Harlin Ferney Acero Acero"
        },
        {
            "_id": "199668",
            "Agente": "199668",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "giro199668@siigo.com",
            "Lider": "Yessica Yulieth Quintero Martinez",
            "Nombre": "Adriana Elizabeth Giron Parra"
        },
        {
            "_id": "802882",
            "Agente": "802882",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "salg802882@siigo.com",
            "Lider": "Aleida Bibiana Garcia Suarez",
            "Nombre": "Younis Yanubis Salgado Guzman"
        },
        {
            "_id": "802457",
            "Agente": "802457",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "dura802457@siigo.com",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre": "Kevin Andres Duran Bravo"
        },
        {
            "_id": "803819",
            "Agente": "803819",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "casa803819@siigo.com",
            "Lider": "Andres David Leon Guerra",
            "Nombre": "Ricardo Andres Casallas Ariza"
        },
        {
            "_id": "802855",
            "Agente": "802855",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "vela802855@siigo.com",
            "Lider": "Andres Alberto Gomez Baño",
            "Nombre": "Sebastian Velasquez Quintero"
        },
        {
            "_id": "801996",
            "Agente": "801996",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "dura801996@siigo.com",
            "Lider": "Angie Camila Diaz Escobar",
            "Nombre": "Carlos Miguel Durango Panesso"
        },
        {
            "_id": "803735",
            "Agente": "803735",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "ceti803735@siigo.com",
            "Lider": "Cesar Eduardo Lopez Suescun",
            "Nombre": "Maycol Cetina Lozada"
        },
        {
            "_id": "802887",
            "Agente": "802887",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "tafu802887@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Wendy Johana Tafur Fonseca"
        },
        {
            "_id": "803431",
            "Agente": "803431",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "lago803431@siigo.com",
            "Lider": "Yilmer Ramiro Guativa Rincon",
            "Nombre": "Jhon Alexis Lagos Acosta"
        },
        {
            "_id": "800365",
            "Agente": "800365",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pove800365@siigo.com",
            "Lider": "Nestor Oswaldo Becerra Prieto",
            "Nombre": "Maria Angelica Poveda Piñeros"
        },
        {
            "_id": "112463",
            "Agente": "112463",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "gome112463@siigo.com",
            "Lider": "Yudy Natali Leon Barrera",
            "Nombre": "Oscar Santiago Gomez Rangel"
        },
        {
            "_id": "2193",
            "Agente": "2193",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "ahmendoza@aspel.com.mx",
            "Lider": "Abraham Octavio Rendon Cruz",
            "Nombre": "Alicia Hernandez Mendoza"
        },
        {
            "_id": "112264",
            "Agente": "112264",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "murc112264@siigo.com",
            "Lider": "Jefferson Arvey Ramirez Ibañez",
            "Nombre": "Liliam Murcia Ganem"
        },
        {
            "_id": "112003",
            "Agente": "112003",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "guer112003@siigo.com",
            "Lider": "Leydi Julieth Sosa Parra",
            "Nombre": "Jhon Jairo Guerrero Contreras"
        },
        {
            "_id": "205300",
            "Agente": "205300",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "Ramo205300@siigo.com",
            "Lider": "Frankgel Ribely Machado Pantoja",
            "Nombre": "Durley Andres Ramos Riaño"
        },
        {
            "_id": "2822",
            "Agente": "2822",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "ggonzalez@aspel.com.mx",
            "Lider": "Juan Mauricio Jimenez Espinoza",
            "Nombre": "Gider Josue Porras Gonzalez"
        },
        {
            "_id": "21038",
            "Agente": "21038",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pena21038@siigo.com",
            "Lider": "Juvenal Reyes Silva",
            "Nombre": "Eslendy Johanna Peña Jaimes"
        },
        {
            "_id": "804031",
            "Agente": "804031",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "bubu804031@siigo.com",
            "Lider": "Maria Paula Garzon Ortega",
            "Nombre": "Sindy Lorena Bubu Malagon"
        },
        {
            "_id": "3373",
            "Agente": "3373",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ijuarez@aspel.com.mx",
            "Lider": "Adriana Rodriguez Castro",
            "Nombre": "Ignacio De Jesus Vazquez Juarez"
        },
        {
            "_id": "2894",
            "Agente": "2894",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "caguilar@aspel.com.mx",
            "Lider": "Armando Uriel Lopez Vazquez",
            "Nombre": "Ceina Catalina Hernandez Aguilar"
        },
        {
            "_id": "800644",
            "Agente": "800644",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "ceva800644@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Lisbeth Corina Cevallos Zoleta"
        },
        {
            "_id": "800577",
            "Agente": "800577",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "obre800577@siigo.com",
            "Lider": "Diana Sofia Ramos Castillo",
            "Nombre": "Eliseo Natanael Obregon Reyes"
        },
        {
            "_id": "801015",
            "Agente": "801015",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "gonz801015@siigo.com",
            "Lider": "Diana Carolina Perez Cerinza",
            "Nombre": "Andres Felipe Gonzalez Urrego"
        },
        {
            "_id": "1282",
            "Agente": "1282",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "aramirez@aspel.com.mx",
            "Lider": "Manuel Ramirez Alvarez",
            "Nombre": "Alfredo Ramirez Gonzalez"
        },
        {
            "_id": "803835",
            "Agente": "803835",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cavi803283@siigo.com",
            "Lider": "Jenny Paola Mendez 0",
            "Nombre": "Karla Caviedes Muñeton"
        },
        {
            "_id": "801237",
            "Agente": "801237",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "lemo801237@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Jhocelyn Ived Lemos Castillo"
        },
        {
            "_id": "1496",
            "Agente": "1496",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jcarranza@aspel.com.mx",
            "Lider": "Vicente Estrada Sanchez",
            "Nombre": "Jose Luis Carranza Valdes"
        },
        {
            "_id": "1661",
            "Agente": "1661",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "eromero@aspel.com.mx",
            "Lider": "Fernando Contreras Ocaña",
            "Nombre": "Elia Karina Lopez Romero"
        },
        {
            "_id": "801452",
            "Agente": "801452",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "espi801452@siigo.com",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Lina Vanessa Espinosa Poveda"
        },
        {
            "_id": "2118",
            "Agente": "2118",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ymorales@aspel.com.mx",
            "Lider": "Rene Gerardo Paredes Alcantara",
            "Nombre": "Ollinyoliliztli Morales Yañez"
        },
        {
            "_id": "802376",
            "Agente": "802376",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "sant802376@siigo.com",
            "Lider": "Alison Anaid Chica Ezeta",
            "Nombre": "Valentina Santafe Gutierrez"
        },
        {
            "_id": "1666",
            "Agente": "1666",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "gmarquez@aspel.com.mx",
            "Lider": "Carlos Mario Espinosa Fernandez",
            "Nombre": "Gerardo Marquez Perez"
        },
        {
            "_id": "3208",
            "Agente": "3208",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "faguilar@aspel.com.mx",
            "Lider": "Ivan Alejandro Roa Franco",
            "Nombre": "Alejandro Aguilar Franco"
        },
        {
            "_id": "3241",
            "Agente": "3241",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "mjerez@aspel.com.mx",
            "Lider": "Marisela Yasmin Franco Cruz",
            "Nombre": "Juan Manuel Jerez Ramirez"
        },
        {
            "_id": "3717",
            "Agente": "3717",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "mloyola@aspel.com.mx",
            "Lider": "Santos Benjamin Martinez Martinez",
            "Nombre": "Manuel Loyola Avila"
        },
        {
            "_id": "801951",
            "Agente": "801951",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "usec801951@siigo.com",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre": "Maria Angelica Useche Cardenas"
        },
        {
            "_id": "2508",
            "Agente": "2508",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "jmorales@aspel.com.mx",
            "Lider": "Alicia Santana Vallejo",
            "Nombre": "Jesus Emmanuel Morales Campos"
        },
        {
            "_id": "802899",
            "Agente": "802899",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "baez802899@siigo.com",
            "Lider": "Alcides Martin Salas Orozco",
            "Nombre": "Wilson Fernando Baez Bentos"
        },
        {
            "_id": "801027",
            "Agente": "801027",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "more801027@siigo.com",
            "Lider": "Angie Valentina Lopez Rodriguez",
            "Nombre": "Juan Camilo Moreno Romero"
        },
        {
            "_id": "802871",
            "Agente": "802871",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "segu802871@siigo.com",
            "Lider": "Daniel Eduardo Pinto Cepeda",
            "Nombre": "Juan Carlos Segura Masmela"
        },
        {
            "_id": "801359",
            "Agente": "801359",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "diaz801359@siigo.com",
            "Lider": "Milady Andrea Guzman Garcia",
            "Nombre": "Juliana Diaz Murcia"
        },
        {
            "_id": "802419",
            "Agente": "802419",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ovie802419@siigo.com",
            "Lider": "Alison Anaid Chica Ezeta",
            "Nombre": "Haider Fabian Oviedo Ruiz"
        },
        {
            "_id": "802759",
            "Agente": "802759",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "muno802759@siigo.com",
            "Lider": "Andres Felipe Baron Ballen",
            "Nombre": "Maria Alejandra Muñoz Giraldo"
        },
        {
            "_id": "803680",
            "Agente": "803680",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "lara803680@siigo.com",
            "Lider": "Juan Carlos Buitrago Rios",
            "Nombre": "Sila Milena Lara Sanchez"
        },
        {
            "_id": "2909",
            "Agente": "2909",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "amanzo@aspel.com.mx",
            "Lider": "Carlos German Lopez Murcia",
            "Nombre": "Alejandro Lopez Manzo"
        },
        {
            "_id": "800555",
            "Agente": "800555",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "guru800555@siigo.com",
            "Lider": "Eddie Steven Hurtado Herrera",
            "Nombre": "Bryan Isaac Gurumendi Carreño"
        },
        {
            "_id": "804159",
            "Agente": "804159",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "camp804159@siigo.com",
            "Lider": "Diana Maria Naranjo Mattheus",
            "Nombre": "Alvaro Hernando Campuzano Florez"
        },
        {
            "_id": "803643",
            "Agente": "803643",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "aran803643@siigo.com",
            "Lider": "Yeimy Milena Bustos Gutierrez",
            "Nombre": "Gladys Mariela Arango Suarez"
        },
        {
            "_id": "112008",
            "Agente": "112008",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "garc112008@siigo.com",
            "Lider": "Mario Fernando Nuñez Santos",
            "Nombre": "Diana Paola Garcia Arias"
        },
        {
            "_id": "4180",
            "Agente": "4180",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Torr4180@siigo.com",
            "Lider": "Leida Adriana Rico Herrera",
            "Nombre": "Yandra Julissa Torres Velandia"
        },
        {
            "_id": "695",
            "Agente": "695",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "vbriceno@aspel.com.mx",
            "Lider": "Reyna Del Rosario Camal Uc",
            "Nombre": "Victor Manuel Briceno Balandra"
        },
        {
            "_id": "32258",
            "Agente": "32258",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "urre32258@siigo.com",
            "Lider": "Jimena Marcela Prieto Bohorquez",
            "Nombre": "Gissel Patricia Urrego Mendez"
        },
        {
            "_id": "112130",
            "Agente": "112130",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rive112130@siigo.com",
            "Lider": "John Mario Perez Diaz",
            "Nombre": "Mary Shiseth Riveros Palma"
        },
        {
            "_id": "2181",
            "Agente": "2181",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "ereyes@aspel.com.mx",
            "Lider": "Fernando Villasana Gutierrez",
            "Nombre": "Carlos Enrique Reyes Sanchez"
        },
        {
            "_id": "802494",
            "Agente": "802494",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "many802494@siigo.com",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre": "Gisela Manyoma Angulo"
        },
        {
            "_id": "112237",
            "Agente": "112237",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "chac112237@siigo.com",
            "Lider": "Juliana Castro Diaz",
            "Nombre": "Maritza Chacon Martin"
        },
        {
            "_id": "803622",
            "Agente": "803622",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "quin803622@siigo.com",
            "Lider": "Diana Maria Naranjo Mattheus",
            "Nombre": "Victoria Eugenia Quintero Lopez"
        },
        {
            "_id": "3047",
            "Agente": "3047",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "fchan@aspel.com.mx",
            "Lider": "Carmen Del Sagrario Ku Yah",
            "Nombre": "Pedro Francisco Chan Basto"
        },
        {
            "_id": "801739",
            "Agente": "801739",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "medi801739@siigo.com",
            "Lider": "Juvenal Reyes Silva",
            "Nombre": "Nathalia Medina Lopez"
        },
        {
            "_id": "1807",
            "Agente": "1807",
            "Area": "Strategy",
            "Division": "Siigo Mexico",
            "Email": "lmontes@aspel.com.mx",
            "Lider": "Fabian David Carreño Leon",
            "Nombre": "Lina Garcia Montes"
        },
        {
            "_id": "804021",
            "Agente": "804021",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "vega804021@siigo.com",
            "Lider": "Edwin Fermin Gomez Quiñonez",
            "Nombre": "Jorge Eduardo Vega Camacho"
        },
        {
            "_id": "3723",
            "Agente": "3723",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "ktadeo@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Karen Danae Tadeo Granados"
        },
        {
            "_id": "803481",
            "Agente": "803481",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "guzm803481@siigo.com",
            "Lider": "Liliana Andrea Segura Martinez",
            "Nombre": "Jessica Del Pilar Guzman Rojas"
        },
        {
            "_id": "803487",
            "Agente": "803487",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gali803487@siigo.com",
            "Lider": "Liliana Andrea Segura Martinez",
            "Nombre": "Melisa Valentina Galindo Bonilla"
        },
        {
            "_id": "803190",
            "Agente": "803190",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "ardi803190@siigo.com",
            "Lider": "Miguel Angel Suárez Hernández",
            "Nombre": "Glorizeth Ardila Cañon"
        },
        {
            "_id": "803338",
            "Agente": "803338",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "insu803338@siigo.com",
            "Lider": "Marcelo Botero Gomez",
            "Nombre": "Andres Felipe Insuasty Chamorro"
        },
        {
            "_id": "804545",
            "Agente": "804545",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "more804545@siigo.com",
            "Lider": "Jose Luis Perales Dueñas",
            "Nombre": "Johanna Carolina Moreno Romero"
        },
        {
            "_id": "111750",
            "Agente": "111750",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "roba111750@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Jhoan Andrey Robayo Gomez"
        },
        {
            "_id": "207722",
            "Agente": "207722",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "Alva207722@siigo.com",
            "Lider": "Juan Carlos Sanchez Velez",
            "Nombre": "Mayra Alejandra Alvarado Onoa"
        },
        {
            "_id": "803922",
            "Agente": "803922",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "hern803922@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Kevin Stiven Hernandez Arias"
        },
        {
            "_id": "32264",
            "Agente": "32264",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Arev32264@siigo.com",
            "Lider": "John Misael Martinez Montero",
            "Nombre": "Jenny Paola Arevalo Garcia"
        },
        {
            "_id": "803791",
            "Agente": "803791",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "avil803791@siigo.com",
            "Lider": "Mario Fernando Nuñez Santos",
            "Nombre": "Wendy Yolany Avila Avila"
        },
        {
            "_id": "802714",
            "Agente": "802714",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rinc802714@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Edwin Ricardo Rincon Ortega"
        },
        {
            "_id": "3203",
            "Agente": "3203",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "jrojas@aspel.com.mx",
            "Lider": "Marisela Yasmin Franco Cruz",
            "Nombre": "Jose Maria Rivera Rojas"
        },
        {
            "_id": "803749",
            "Agente": "803749",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "lora803749@siigo.com",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre": "Carlos Andres Lora Deavila"
        },
        {
            "_id": "109948",
            "Agente": "109948",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "arci109948@siigo.com",
            "Lider": "Daniel Eduardo Pinto Cepeda",
            "Nombre": "Luz Adriana Arcila Navarro"
        },
        {
            "_id": "803878",
            "Agente": "803878",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "bust803878@siigo.com",
            "Lider": "David Alfredo Sanchez Bastidas",
            "Nombre": "Fredy Alexander Bustamente Gutierrez"
        },
        {
            "_id": "20965",
            "Agente": "20965",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "puli20965@siigo.com",
            "Lider": "Sandra Catalina Suarez Castillo",
            "Nombre": "Milton Enrique Pulido Bautista"
        },
        {
            "_id": "64",
            "Agente": "64",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "mvargas@aspel.com.mx",
            "Lider": "Claudia Valdez Carbajal",
            "Nombre": "Maria Jose Vargas Braga"
        },
        {
            "_id": "802483",
            "Agente": "802483",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "cruz802483@siigo.com",
            "Lider": "Fernando Gutierrez Paramio",
            "Nombre": "Hugo Ernesto Cruz Casallas"
        },
        {
            "_id": "223915",
            "Agente": "223915",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pere223915@siigo.com",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre": "Ana Bolena Perez Martinez"
        },
        {
            "_id": "803320",
            "Agente": "803320",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pint803320@siigo.com",
            "Lider": "Juvenal Reyes Silva",
            "Nombre": "Maria Angelica Pinto Pinzon"
        },
        {
            "_id": "804541",
            "Agente": "804541",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cely804541@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "María Camila Cely Pineda"
        },
        {
            "_id": "3288",
            "Agente": "3288",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "abaeza@aspel.com.mx",
            "Lider": "Bismark Ivan Rios Campos",
            "Nombre": "Ana Gabriela Garcia Baeza"
        },
        {
            "_id": "2940",
            "Agente": "2940",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "cnajera@aspel.com.mx",
            "Lider": "Jose De Jesus De Aquino Curiel",
            "Nombre": "Cinthia Lissette Najera Valadez"
        },
        {
            "_id": "801484",
            "Agente": "801484",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "albo801481@siigo.com",
            "Lider": "Santiago Jose Porras Brieva",
            "Nombre": "Cristian Enrique Albornoz Arias"
        },
        {
            "_id": "2068",
            "Agente": "2068",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "fflores@aspel.com.mx",
            "Lider": "Rene Gerardo Paredes Alcantara",
            "Nombre": "Francisco Javier Flores"
        },
        {
            "_id": "801829",
            "Agente": "801829",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vele801829@siigo.com",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre": "Luis Edgar Velez Guerrero"
        },
        {
            "_id": "804546",
            "Agente": "804546",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "zorr804546@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Brayan Steven Zorro Botia"
        },
        {
            "_id": "215108",
            "Agente": "215108",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "cely215108@siigo.com",
            "Lider": "Natalia Herrera Rey",
            "Nombre": "Paola Cely Prada"
        },
        {
            "_id": "804029",
            "Agente": "804029",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "duqu804029@siigo.com",
            "Lider": "Lina Maria Buitrago Herrera",
            "Nombre": "Alexander Duque Jimenez"
        },
        {
            "_id": "2519",
            "Agente": "2519",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "oalva@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Oscar Alva Hernandez"
        },
        {
            "_id": "111539",
            "Agente": "111539",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "aren111539@siigo.com",
            "Lider": "Juvenal Reyes Silva",
            "Nombre": "Maria Camila Arenas Gomez"
        },
        {
            "_id": "800303",
            "Agente": "800303",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vane800303@siigo.com",
            "Lider": "Sory Yaqueline Abello Polo",
            "Nombre": "Lidia Enith Vanegas Granados"
        },
        {
            "_id": "1162",
            "Agente": "1162",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "jaquino@aspel.com.mx",
            "Lider": "Fernando Contreras Ocaña",
            "Nombre": "Jose De Jesus De Aquino Curiel"
        },
        {
            "_id": "2451",
            "Agente": "2451",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "mjoaquin@aspel.com.mx",
            "Lider": "Armando Uriel Lopez Vazquez",
            "Nombre": "Mayte Guadalupe Joaquin Sanchez"
        },
        {
            "_id": "800623",
            "Agente": "800623",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "muno800623@siigo.com",
            "Lider": "José Manuel Aguayo Escandon",
            "Nombre": "Walter Antonio Muñoz Antepara"
        },
        {
            "_id": "1553",
            "Agente": "1553",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "joviedo@aspel.com.mx",
            "Lider": "Juan Mauricio Jimenez Espinoza",
            "Nombre": "Jonathan Oviedo Gonzalez"
        },
        {
            "_id": "4170",
            "Agente": "4170",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "Sarm4170@siigo.com",
            "Lider": "David Ortiz Diaz",
            "Nombre": "Gladys Mariela Sarmiento Murcia"
        },
        {
            "_id": "803651",
            "Agente": "803651",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "boti803651@siigo.com",
            "Lider": "Camilo Andres Muñoz Guerra",
            "Nombre": "Edwin Ricardo Botia Florez"
        },
        {
            "_id": "2228",
            "Agente": "2228",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "aborja@aspel.com.mx",
            "Lider": "Gabriel Chavez Carrera",
            "Nombre": "Adrian Borja Primitivo"
        },
        {
            "_id": "23203",
            "Agente": "23203",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Yosc23203@siigo.com",
            "Lider": "John Mario Perez Diaz",
            "Nombre": "Maria Elizabeth Yoscua Ubaque"
        },
        {
            "_id": "112438",
            "Agente": "112438",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bara112438@siigo.com",
            "Lider": "Adriana Elizabeth Giron Parra",
            "Nombre": "Freddy Alexander Barajas Moreno"
        },
        {
            "_id": "2552",
            "Agente": "2552",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "volivares@aspel.com.mx",
            "Lider": "Armando Uriel Lopez Vazquez",
            "Nombre": "Victor Eduardo Olivares Noria"
        },
        {
            "_id": "803596",
            "Agente": "803596",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "barb803596@siigo.com",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre": "Leidy Alejandra Barbosa Amorocho"
        },
        {
            "_id": "803645",
            "Agente": "803645",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "leon803645@siigo.com",
            "Lider": "Lida Katerine Puentes Navarro",
            "Nombre": "Camila Alejandra Leon Acosta"
        },
        {
            "_id": "803439",
            "Agente": "803439",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "niet803439@siigo.com",
            "Lider": "Diana Michelle Matamoros Del Rosario",
            "Nombre": "Vanessa Viviana Nieto Garcia"
        },
        {
            "_id": "802293",
            "Agente": "802293",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "sanc802293@siigo.com",
            "Lider": "Juan Diego Romero Bocourt",
            "Nombre": "Juan Carlos Sanchez Morante"
        },
        {
            "_id": "803656",
            "Agente": "803656",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "dela803656@siigo.com",
            "Lider": "Francy Julieth Viracacha Gutierrez",
            "Nombre": "Dayana Andrea De la Rosa Aguirrez"
        },
        {
            "_id": "801001",
            "Agente": "801001",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rodr801001@siigo.com",
            "Lider": "Saul Steven Carpio Ochoa",
            "Nombre": "Sebastian Alejandro Rodriguez Duque"
        },
        {
            "_id": "804544",
            "Agente": "804544",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "guti804544@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Jeferson David Gutiérrez Sánchez"
        },
        {
            "_id": "233201",
            "Agente": "233201",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sonp233201@siigo.com",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre": "Diomedis Son Perdomo"
        },
        {
            "_id": "112447",
            "Agente": "112447",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "suar112447@siigo.com",
            "Lider": "Jarbin Dadey Rodriguez Peña",
            "Nombre": "Sandra Catalina Suarez Castillo"
        },
        {
            "_id": "803632",
            "Agente": "803632",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "paez803632@siigo.com",
            "Lider": "Diana Mercedes Moncada Peraza",
            "Nombre": "Yeimy Daniela Paez Arenas"
        },
        {
            "_id": "800815",
            "Agente": "800815",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ahum800815@siigo.com",
            "Lider": "Eliana Maria Fajardo Prada",
            "Nombre": "Estefany Ahumada Torres"
        },
        {
            "_id": "800633",
            "Agente": "800633",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "bola800633@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Steven Isaac Bolaños Vera"
        },
        {
            "_id": "800344",
            "Agente": "800344",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "fand800344@siigo.com",
            "Lider": "Adriana Elizabeth Giron Parra",
            "Nombre": "Diana Carolina Fandiño Hernandez"
        },
        {
            "_id": "804058",
            "Agente": "804058",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "estr804058@siigo.com",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre": "Giselle Karina Estrada Banderas"
        },
        {
            "_id": "801019",
            "Agente": "801019",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "guti801019@siigo.com",
            "Lider": "Sindy Paola Lasso Velasco",
            "Nombre": "Nelson David Gutierrez Barbosa"
        },
        {
            "_id": "802581",
            "Agente": "802581",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "medi802581@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Andres Homero Medina Mite"
        },
        {
            "_id": "802892",
            "Agente": "802892",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "yepe802892@siigo.com",
            "Lider": "Gustavo Adolfo Espinosa Toledo",
            "Nombre": "Anselmo Jose Yepes Jaraba"
        },
        {
            "_id": "800718",
            "Agente": "800718",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "vaca800718@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Piedad Vaca Sanchez"
        },
        {
            "_id": "4121",
            "Agente": "4121",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Oroz4121@siigo.com",
            "Lider": "Daniel Eduardo Pinto Cepeda",
            "Nombre": "Nancy Amparo Orozco Campos"
        },
        {
            "_id": "804362",
            "Agente": "804362",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "cast804362@siigo.com",
            "Lider": "Juan David Lopez Fuentes",
            "Nombre": "Yordan Castelblanco Jimenez"
        },
        {
            "_id": "2239",
            "Agente": "2239",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "iacosta@aspel.com.mx",
            "Lider": "Agustin Hernandez Lopez",
            "Nombre": "Itzel Acosta Mendez"
        },
        {
            "_id": "801582",
            "Agente": "801582",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "alda801582@siigo.com",
            "Lider": "Ahylin Dahyan Jamaica Mendez",
            "Nombre": "Valentina Aldana Gonzalez"
        },
        {
            "_id": "802487",
            "Agente": "802487",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "vill802487@siigo.com",
            "Lider": "Luis Alfredo Leon Britto",
            "Nombre": "Juan Jose Villa Alzate"
        },
        {
            "_id": "801208",
            "Agente": "801208",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "quir801208@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Karen Denisse Quiroz Rodriguez"
        },
        {
            "_id": "800700",
            "Agente": "800700",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "rodr800700@siigo.com",
            "Lider": "Natalia Catherine Triviño Cortes",
            "Nombre": "Braidy Alejandra Rodriguez Peña"
        },
        {
            "_id": "801269",
            "Agente": "801269",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "andr801269@siigo.com",
            "Lider": "Luis Ignacio Ferreira Duarte",
            "Nombre": "Sabrina Andrade Todaro"
        },
        {
            "_id": "803257",
            "Agente": "803257",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "sant803257@siigo.com",
            "Lider": "Kristein Johan Ordoñez Lopez",
            "Nombre": "Juan Camilo Santana Barahona"
        },
        {
            "_id": "3587",
            "Agente": "3587",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jborges@aspel.com.mx",
            "Lider": "Juan Mauricio Jimenez Espinoza",
            "Nombre": "José Isaak Borges Puc"
        },
        {
            "_id": "112375",
            "Agente": "112375",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gome112375@siigo.com",
            "Lider": "Adriana Elizabeth Giron Parra",
            "Nombre": "Luis Fernando Gomez Aviles"
        },
        {
            "_id": "3295",
            "Agente": "3295",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jlopezd@aspel.com.mx",
            "Lider": "Jorge Alfredo Huesca Martinez",
            "Nombre": "Jose Carlos Lopez Diaz"
        },
        {
            "_id": "804147",
            "Agente": "804147",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rinc804147@siigo.com",
            "Lider": "Juan Carlos Guarin Walteros",
            "Nombre": "Ivan Camilo Rincon Perez"
        },
        {
            "_id": "801291",
            "Agente": "801291",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mart801291@siigo.com",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre": "Nathaly Martinez Villamil"
        },
        {
            "_id": "803639",
            "Agente": "803639",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "more803639@siigo.com",
            "Lider": "Lida Katerine Puentes Navarro",
            "Nombre": "Ofir Moreno Suarez"
        },
        {
            "_id": "804016",
            "Agente": "804016",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "prie804016@siigo.com",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre": "Tatiana Veronica Prieto Prieto"
        },
        {
            "_id": "804520",
            "Agente": "804520",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "guzm804520@siigo.com",
            "Lider": "Jose Luis Perales Dueñas",
            "Nombre": "Diego Jose Guzman Barrios"
        },
        {
            "_id": "801485",
            "Agente": "801485",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "rico801485@siigo.com",
            "Lider": "Gustavo Adolfo Valencia Chaparro",
            "Nombre": "Juan David Rico Torres"
        },
        {
            "_id": "801284",
            "Agente": "801284",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pala801284@siigo.com",
            "Lider": "Cristian Camilo Hernandez Gonzalez",
            "Nombre": "Hernan David Palacio Mejia"
        },
        {
            "_id": "802744",
            "Agente": "802744",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "leon802744@siigo.com",
            "Lider": "Marcos Damian Lizarraga Elizalde",
            "Nombre": "Luis Alfredo Leon Britto"
        },
        {
            "_id": "800860",
            "Agente": "800860",
            "Area": "Country Management",
            "Division": "Siigo Uruguay",
            "Email": "cora800860@siigo.com",
            "Lider": "Andrea Zuluaga Uribe",
            "Nombre": "Juan Carlos Corazzo Santos"
        },
        {
            "_id": "801147",
            "Agente": "801147",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "vald801147@siigo.com",
            "Lider": "Diana Milena Roa Hurtado",
            "Nombre": "Juan Carlos Valdes Martin"
        },
        {
            "_id": "3397",
            "Agente": "3397",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "imendez@aspel.com.mx",
            "Lider": "Rodrigo Sanchez Godinez",
            "Nombre": "Adrian Ignacio Mendez Nieves"
        },
        {
            "_id": "3461",
            "Agente": "3461",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "dceron@aspel.com.mx",
            "Lider": "Alicia Santana Vallejo",
            "Nombre": "Daniela Nicte Ceron Chavez"
        },
        {
            "_id": "3324",
            "Agente": "3324",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "lsabino@aspel.com.mx",
            "Lider": "Bismark Ivan Rios Campos",
            "Nombre": "Leslie Sabino Rodríguez"
        },
        {
            "_id": "801730",
            "Agente": "801730",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "moya801730@siigo.com",
            "Lider": "Eliana Maria Fajardo Prada",
            "Nombre": "Hector Camilo Moyano Giraldo"
        },
        {
            "_id": "2287",
            "Agente": "2287",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "iocampo@aspel.com.mx",
            "Lider": "Rene Gerardo Paredes Alcantara",
            "Nombre": "Isaac Ocampo Delgado"
        },
        {
            "_id": "802590",
            "Agente": "802590",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "prie802590@siigo.com",
            "Lider": "Nelson Juan Carlos Acosta Drouet",
            "Nombre": "Joan Sebastian Prieto Cadena"
        },
        {
            "_id": "801028",
            "Agente": "801028",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "card801028@siigo.com",
            "Lider": "Carlos Eduardo Sanchez Castaño",
            "Nombre": "Daniela Valentina Cardenas Rubiano"
        },
        {
            "_id": "803581",
            "Agente": "803581",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "garz803581@siigo.com",
            "Lider": "Cesar Eduardo Lopez Suescun",
            "Nombre": "Paula Camila Garzon Hilarion"
        },
        {
            "_id": "802528",
            "Agente": "802528",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "palm802528@siigo.com",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre": "Ana Marcela Palma Arvilla"
        },
        {
            "_id": "1764",
            "Agente": "1764",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "msanchez@aspel.com.mx",
            "Lider": "Juan Carlos Garcia Ibarra",
            "Nombre": "Marlen Sanchez Cortes"
        },
        {
            "_id": "802150",
            "Agente": "802150",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "oroz802150@siigo.com",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre": "Maria Luisa Orozco Suarez"
        },
        {
            "_id": "801481",
            "Agente": "801481",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "rodr801481@siigo.com",
            "Lider": "Nelson Juan Carlos Acosta Drouet",
            "Nombre": "Maria Fernanda Rodriguez Moyano"
        },
        {
            "_id": "802865",
            "Agente": "802865",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "trej802865@siigo.com",
            "Lider": "John Misael Martinez Montero",
            "Nombre": "Samir Omaira Trejos Vargas"
        },
        {
            "_id": "111694",
            "Agente": "111694",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "loai111694@siigo.com",
            "Lider": "Leida Adriana Rico Herrera",
            "Nombre": "Maria Del Pilar Loaiza Gonzalez"
        },
        {
            "_id": "801233",
            "Agente": "801233",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "cedr801233@siigo.com",
            "Lider": "Ivan Elias Jusid Machado",
            "Nombre": "Rodrigo Nahuel Cedrez Moreira"
        },
        {
            "_id": "804066",
            "Agente": "804066",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "niet804066@siigo.com",
            "Lider": "Vicky Alejandra Hernandez Mendez",
            "Nombre": "Adriana Patricia Nieto Hernandez"
        },
        {
            "_id": "802313",
            "Agente": "802313",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gome802313@siigo.com",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre": "Ivan Fernando Gomez Mercado"
        },
        {
            "_id": "3382",
            "Agente": "3382",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "sarellano@aspel.com.mx",
            "Lider": "Ivan Alejandro Roa Franco",
            "Nombre": "Sandra Azucena Arellano Juarez"
        },
        {
            "_id": "3582",
            "Agente": "3582",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "ttrejo@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Thalia Trejo Garcia"
        },
        {
            "_id": "803304",
            "Agente": "803304",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "silg803304@siigo.com",
            "Lider": "Jesus Daniel Arzuza Roman",
            "Nombre": "Silvia Silgado Blanco"
        },
        {
            "_id": "804030",
            "Agente": "804030",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "rein804030@siigo.com",
            "Lider": "Lina Maria Buitrago Herrera",
            "Nombre": "Wendy Yurany Reina Ricardo"
        },
        {
            "_id": "804548",
            "Agente": "804548",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sana804548@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Laura Sofia Sanabria Rojas"
        },
        {
            "_id": "1928",
            "Agente": "1928",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "vlopez@aspel.com.mx",
            "Lider": "Juan Mauricio Jimenez Espinoza",
            "Nombre": "Maria Virginia Lopez Salazar"
        },
        {
            "_id": "802485",
            "Agente": "802485",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rodr802485@siigo.com",
            "Lider": "Frankgel Ribely Machado Pantoja",
            "Nombre": "Sonia Pilar Rodriguez Perez"
        },
        {
            "_id": "3276",
            "Agente": "3276",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "mjacobo@aspel.com.mx",
            "Lider": "Victor Hugo Huerta Lopez",
            "Nombre": "Marco Antonio Jacobo Lopez"
        },
        {
            "_id": "800863",
            "Agente": "800863",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "diaz800863@siigo.com",
            "Lider": "Carlos Jesus Poveda Zapata",
            "Nombre": "Angie Camila Diaz Escobar"
        },
        {
            "_id": "800674",
            "Agente": "800674",
            "Area": "Finance & Administration",
            "Division": "Siigo Ecuador",
            "Email": "caic800674@siigo.com",
            "Lider": "Luisa Fernanda Caro Camargo",
            "Nombre": "Sergio Alberto Caicedo Ramirez"
        },
        {
            "_id": "801460",
            "Agente": "801460",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "rami801460@siigo.com",
            "Lider": "Nelson Juan Carlos Acosta Drouet",
            "Nombre": "John Harold Ramirez Daza"
        },
        {
            "_id": "3589",
            "Agente": "3589",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "acamacho@aspel.com.mx",
            "Lider": "Santos Benjamin Martinez Martinez",
            "Nombre": "Adriana Camacho Camacho"
        },
        {
            "_id": "801298",
            "Agente": "801298",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "alza801298@siigo.com",
            "Lider": "Manuel Andres Solano Duran",
            "Nombre": "Jhony Alexander Alzate Soto"
        },
        {
            "_id": "802292",
            "Agente": "802292",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "guti802292@siigo.com",
            "Lider": "Juan Esteban Restrepo Cardona",
            "Nombre": "Juan Felipe Gutierrez Soto"
        },
        {
            "_id": "3167",
            "Agente": "3167",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "msanabria@aspel.com.mx",
            "Lider": "Carlos Arturo Huertas Salgado",
            "Nombre": "María de Jesús Pérez Sanabria"
        },
        {
            "_id": "801968",
            "Agente": "801968",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "figu801968@siigo.com",
            "Lider": "Walter Antonio Muñoz Antepara",
            "Nombre": "Maite Valentina Figueroa Morán"
        },
        {
            "_id": "1793",
            "Agente": "1793",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "fcarmona@aspel.com.mx",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Francisco Damian Carmona Briones"
        },
        {
            "_id": "800873",
            "Agente": "800873",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mart800873@siigo.com",
            "Lider": "Cristian Camilo Hernandez Gonzalez",
            "Nombre": "Paula Valentina Martinez Caita"
        },
        {
            "_id": "802639",
            "Agente": "802639",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "sanc802639@siigo.com",
            "Lider": "Jessica Paola Paez Quintero",
            "Nombre": "Luisa Valentina Sanchez Quintero"
        },
        {
            "_id": "800723",
            "Agente": "800723",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "alme800723@siigo.com",
            "Lider": "Walter Antonio Muñoz Antepara",
            "Nombre": "Kevin Eduardo Almeida Medrano"
        },
        {
            "_id": "3399",
            "Agente": "3399",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "iramirez@aspel.com.mx",
            "Lider": "Jose Luis Carranza Valdes",
            "Nombre": "Isai Ramirez Mejia"
        },
        {
            "_id": "3304",
            "Agente": "3304",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jacosta@aspel.com.mx",
            "Lider": "Adriana Rodriguez Castro",
            "Nombre": "Juan Octavio Zuñiga Acosta"
        },
        {
            "_id": "802906",
            "Agente": "802906",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "cifu802906@siigo.com",
            "Lider": "David Julian Rojas Prado",
            "Nombre": "Juan Sebastian Cifuentes Guiffo"
        },
        {
            "_id": "800988",
            "Agente": "800988",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "sier800988@siigo.com",
            "Lider": "Juan Carlos Sanchez Velez",
            "Nombre": "David Santiago Sierra Pulido"
        },
        {
            "_id": "801469",
            "Agente": "801469",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "vale801469@siigo.com",
            "Lider": "Miguel Angel Suárez Hernández",
            "Nombre": "Laura Vanessa Valencia Mora"
        },
        {
            "_id": "801046",
            "Agente": "801046",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "vega801046@siigo.com",
            "Lider": "Harrison Martinez Callejas",
            "Nombre": "Andrea Catalina Vega Gomez"
        },
        {
            "_id": "804532",
            "Agente": "804532",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "zamb804532@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "William Gabriel Zambrano Briceño"
        },
        {
            "_id": "801196",
            "Agente": "801196",
            "Area": "Product",
            "Division": "Siigo Ecuador",
            "Email": "mald801196@siigo.com",
            "Lider": "Bianca Alice Cepeda Campoverde",
            "Nombre": "George Adolfo Maldonado De la Cruz"
        },
        {
            "_id": "801249",
            "Agente": "801249",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "rome801249@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Steven Snaider Romero Solorzano"
        },
        {
            "_id": "802729",
            "Agente": "802729",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "guar802729@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Kelvin Eduardo Guaranda Choez"
        },
        {
            "_id": "3159",
            "Agente": "3159",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "lrivera@aspel.com.mx",
            "Lider": "Bismark Ivan Rios Campos",
            "Nombre": "Laura Esther Rivera De la Cruz"
        },
        {
            "_id": "801564",
            "Agente": "801564",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "herr801564@siigo.com",
            "Lider": "Astrid Gisel Duque Rico",
            "Nombre": "Fabian Leonardo Herrera Martinez"
        },
        {
            "_id": "3141",
            "Agente": "3141",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jcoba@aspel.com.mx",
            "Lider": "Maria Virginia Lopez Salazar",
            "Nombre": "Jesus Ricardo Coba Poot"
        },
        {
            "_id": "3184",
            "Agente": "3184",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "eoropeza@aspel.com.mx",
            "Lider": "Cindy Joan Artunduaga Muñoz",
            "Nombre": "Enrique Oropeza Diaz"
        },
        {
            "_id": "801839",
            "Agente": "801839",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "prie801839@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Laura Valentina Prieto Dueñas"
        },
        {
            "_id": "3151",
            "Agente": "3151",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jbolio@aspel.com.mx",
            "Lider": "Allam Francisco Medina Yama",
            "Nombre": "José Rodrigo Bolio Farah"
        },
        {
            "_id": "3292",
            "Agente": "3292",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jchan@aspel.com.mx",
            "Lider": "Yazmin Gonzalez Fernandez",
            "Nombre": "Jose Ricardo Chan Pech"
        },
        {
            "_id": "801444",
            "Agente": "801444",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ariz801444@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Luisa Fernanda Ariza 0"
        },
        {
            "_id": "801403",
            "Agente": "801403",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pach801403@siigo.com",
            "Lider": "Jose Luis Perales Dueñas",
            "Nombre": "Cristian David Pacheco Maldonado"
        },
        {
            "_id": "2733",
            "Agente": "2733",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "crivera@aspel.com.mx",
            "Lider": "Angel Manuel Dominguez Romero",
            "Nombre": "Carla Alejandra Rivera Garcia"
        },
        {
            "_id": "802520",
            "Agente": "802520",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cama802520@siigo.com",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre": "Edwin Alexis Camargo Contreras"
        },
        {
            "_id": "3591",
            "Agente": "3591",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "bcortes@aspel.com.mx",
            "Lider": "Virginia Guadalupe Ramirez Hernandez",
            "Nombre": "Briseyda Judith Cortés Garcia"
        },
        {
            "_id": "3282",
            "Agente": "3282",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jclopez@aspel.com.mx",
            "Lider": "Victor Hugo Huerta Lopez",
            "Nombre": "Juan Carlos Lopez Lopez"
        },
        {
            "_id": "3198",
            "Agente": "3198",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "victor.hernandez@aspel.com.mx",
            "Lider": "Fernando Contreras Ocaña",
            "Nombre": "Victor Adrian Hernandez Contreras"
        },
        {
            "_id": "800986",
            "Agente": "800986",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "hern800986@siigo.com",
            "Lider": "Andres David Leon Guerra",
            "Nombre": "Francisco De Jesus Hernandez Torres"
        },
        {
            "_id": "801006",
            "Agente": "801006",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "segu801006@siigo.com",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre": "Diana Katherin Segura Leguizamon"
        },
        {
            "_id": "20556",
            "Agente": "20556",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "Pard20556@siigo.com",
            "Lider": "Miguel Angel Suárez Hernández",
            "Nombre": "Cristan Lorena Pardo Castaño"
        },
        {
            "_id": "800500",
            "Agente": "800500",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "guti800500@siigo.com",
            "Lider": "Gustavo Adolfo Moreno Muñoz",
            "Nombre": "Jorge Andres Gutierrez Chavez"
        },
        {
            "_id": "2734",
            "Agente": "2734",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "hvelazquez@aspel.com.mx",
            "Lider": "Marcela Vanessa Hernandez Moreno",
            "Nombre": "Hilda Velazquez Lucio"
        },
        {
            "_id": "2744",
            "Agente": "2744",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "juc@aspel.com.mx",
            "Lider": "Norma Florentina Quiñones Martin",
            "Nombre": "Jonathan De Jesus Uc Chan"
        },
        {
            "_id": "3117",
            "Agente": "3117",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "jrossell@aspel.com.mx",
            "Lider": "Pedro Arturo Rico Martinez",
            "Nombre": "Julio Cesar Rossell Caletty"
        },
        {
            "_id": "2324",
            "Agente": "2324",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "mcruz@aspel.com.mx",
            "Lider": "Belem Chavez Ruiz",
            "Nombre": "Mario Alberto Cruz Campos"
        },
        {
            "_id": "800672",
            "Agente": "800672",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "pala800672@siigo.com",
            "Lider": "Sandra Catalina Suarez Castillo",
            "Nombre": "Luz Adriana Palacios Rodriguez"
        },
        {
            "_id": "800628",
            "Agente": "800628",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "borb800628@siigo.com",
            "Lider": "Astrid Gisel Duque Rico",
            "Nombre": "Karen Lilibeth Borbor Moreira"
        },
        {
            "_id": "802578",
            "Agente": "802578",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gall802578@siigo.com",
            "Lider": "Yeimy Milena Bustos Gutierrez",
            "Nombre": "Hellen Gabriela Gallo Charry"
        },
        {
            "_id": "3187",
            "Agente": "3187",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "egonzalez@aspel.com.mx",
            "Lider": "Laura Erika Utrera Utrera",
            "Nombre": "Erik Alejandro González Villanueva"
        },
        {
            "_id": "2757",
            "Agente": "2757",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "jmares@aspel.com.mx",
            "Lider": "Carlos Maximo Garrido Mascott",
            "Nombre": "Juan Diego Mares Almanza"
        },
        {
            "_id": "333",
            "Agente": "333",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "vhernandez@aspel.com.mx",
            "Lider": "Carlos Arturo Huertas Salgado",
            "Nombre": "Marcela Vanessa Hernandez Moreno"
        },
        {
            "_id": "801406",
            "Agente": "801406",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "diaz801406@siigo.com",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre": "William Yamid Diaz Buitrago"
        },
        {
            "_id": "549",
            "Agente": "549",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "emani@aspel.com.mx",
            "Lider": "Diana Carolina Perdomo Rodriguez",
            "Nombre": "María Elizabeth Mani Jimenez"
        },
        {
            "_id": "3311",
            "Agente": "3311",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jcarrasco@aspel.com.mx",
            "Lider": "Adriana Rodriguez Castro",
            "Nombre": "Janeth Fernandez Carrasco"
        },
        {
            "_id": "3118",
            "Agente": "3118",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "brosales@aspel.com.mx",
            "Lider": "Alexis Abraham Sanchez Portillo",
            "Nombre": "Brandon Enrique Gomez Rosales"
        },
        {
            "_id": "2897",
            "Agente": "2897",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "mvelasco@aspel.com.mx",
            "Lider": "Victor Hugo Huerta Lopez",
            "Nombre": "Miguel Angel Velasco Nambo"
        },
        {
            "_id": "801094",
            "Agente": "801094",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "medi801094@siigo.com",
            "Lider": "Ivan Elias Jusid Machado",
            "Nombre": "Federico Medina 0"
        },
        {
            "_id": "801268",
            "Agente": "801268",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "delg801268@siigo.com",
            "Lider": "Silvia Zilah Fernandez Almeida",
            "Nombre": "Alexis Delgado Jaimes"
        },
        {
            "_id": "801297",
            "Agente": "801297",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "guev801297@siigo.com",
            "Lider": "Yesika Viviana Garcia Niño",
            "Nombre": "Diana Katherin Guevara Cubillos"
        },
        {
            "_id": "111927",
            "Agente": "111927",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "mont111927@siigo.com",
            "Lider": "Oscar Francisco Plaza Giler",
            "Nombre": "Leidy Viviana Montoya Gomez"
        },
        {
            "_id": "800995",
            "Agente": "800995",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "baut800995@siigo.com",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre": "Maria Fernanda Bautista Sanchez"
        },
        {
            "_id": "800855",
            "Agente": "800855",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "lond800855@siigo.com",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre": "Daniel Londoño Garces"
        },
        {
            "_id": "32597",
            "Agente": "32597",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "Alfo32597@siigo.com",
            "Lider": "Lina Maria Buitrago Herrera",
            "Nombre": "Julie Ximena Alfonso Chavarria"
        },
        {
            "_id": "803631",
            "Agente": "803631",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gali803631@siigo.com",
            "Lider": "Jesus Daniel Arzuza Roman",
            "Nombre": "Ana Yuranis Galindo Martinez"
        },
        {
            "_id": "800869",
            "Agente": "800869",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rodr800869@siigo.com",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre": "Fanny Elvira Rodriguez 0"
        },
        {
            "_id": "801120",
            "Agente": "801120",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "fern801120@siigo.com",
            "Lider": "Juan Sebastian Molina Linares",
            "Nombre": "Silvia Zilah Fernandez Almeida"
        },
        {
            "_id": "801099",
            "Agente": "801099",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "lope801099@siigo.com",
            "Lider": "Alcides Martin Salas Orozco",
            "Nombre": "Javier Nicolas Lopez Pesquera"
        },
        {
            "_id": "804123",
            "Agente": "804123",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "pove804123@siigo.com",
            "Lider": "Diego Andres Montoya Blandon",
            "Nombre": "Jeyson Steven Poveda Rodriguez"
        },
        {
            "_id": "803758",
            "Agente": "803758",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "macc803758@siigo.com",
            "Lider": "Lizeth Janney Ayala Zuluaga",
            "Nombre": "Lissa Susana Macchi Alvarez"
        },
        {
            "_id": "3427",
            "Agente": "3427",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "gflores@aspel.com.mx",
            "Lider": "Brandon Enrique Gomez Rosales",
            "Nombre": "Gustavo Flores Hernandez"
        },
        {
            "_id": "801069",
            "Agente": "801069",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "rodr801069@siigo.com",
            "Lider": "Carlos Arturo Huertas Salgado",
            "Nombre": "Joan Sebastian Rodriguez 0"
        },
        {
            "_id": "801310",
            "Agente": "801310",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "vald801310@siigo.com",
            "Lider": "Natalia Catherine Triviño Cortes",
            "Nombre": "Daniela Valderrama Pinzon"
        },
        {
            "_id": "111720",
            "Agente": "111720",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "lape111720@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Sebastian Lapeira Figueroa"
        },
        {
            "_id": "802026",
            "Agente": "802026",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "muno802026@siigo.com",
            "Lider": "Nestor Oswaldo Becerra Prieto",
            "Nombre": "Leidy Yethcenia Muñoz Rodríguez"
        },
        {
            "_id": "801195",
            "Agente": "801195",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "lasc801195@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Bryan Steven Lascano Aranea"
        },
        {
            "_id": "112384",
            "Agente": "112384",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "silv112384@siigo.com",
            "Lider": "Diana Mercedes Moncada Peraza",
            "Nombre": "Sandra Milena Silva Gutierrez"
        },
        {
            "_id": "801060",
            "Agente": "801060",
            "Area": "Finance & Administration",
            "Division": "Siigo Uruguay",
            "Email": "mami801060@siigo.com",
            "Lider": "Edith Angelica Gil Umbarila",
            "Nombre": "Gabriela Mamigonian "
        },
        {
            "_id": "803484",
            "Agente": "803484",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gonz803484@sigo.com",
            "Lider": "Juan Carlos Sanchez Velez",
            "Nombre": "Jose Luis Gonzalez Gutierrez"
        },
        {
            "_id": "804526",
            "Agente": "804526",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "chim804526@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Kennthuel Raul Chima Marrugo"
        },
        {
            "_id": "801431",
            "Agente": "801431",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "muri801431@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Cristian Camilo Murillo Rodriguez"
        },
        {
            "_id": "800878",
            "Agente": "800878",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sant800878@siigo.com",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre": "Maria Camila Santos Nuñez"
        },
        {
            "_id": "803670",
            "Agente": "803670",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "dela803670@siigo.com",
            "Lider": "Johanna Patricia Torres Quintero",
            "Nombre": "Andrea Gissel De la Rans Ipuz"
        },
        {
            "_id": "801307",
            "Agente": "801307",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "rome801307@siigo.com",
            "Lider": "Ivan Elias Jusid Machado",
            "Nombre": "Romina Tatiana Romero Olivera"
        },
        {
            "_id": "801108",
            "Agente": "801108",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "mene801108@siigo.com",
            "Lider": "Alcides Martin Salas Orozco",
            "Nombre": "Manuel Menendez Sanguiñedo"
        },
        {
            "_id": "3568",
            "Agente": "3568",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "frojas@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Fabiola Rojas Lindero"
        },
        {
            "_id": "801110",
            "Agente": "801110",
            "Area": "Product",
            "Division": "Siigo Uruguay",
            "Email": "mene801110@siigo.com",
            "Lider": "Mauricio Ramirez Minetti",
            "Nombre": "Matias Alejandro Menendez Sanguiñedo"
        },
        {
            "_id": "111874",
            "Agente": "111874",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cast111874@siigo.com",
            "Lider": "Diana Maria Naranjo Mattheus",
            "Nombre": "Gloria Liliana Castaño Ossa"
        },
        {
            "_id": "801083",
            "Agente": "801083",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "boli801083@siigo.com",
            "Lider": "Milady Andrea Guzman Garcia",
            "Nombre": "Nini Johana Bolivar Perez"
        },
        {
            "_id": "800728",
            "Agente": "800728",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "corr800728@siigo.com",
            "Lider": "Carol Andrea Cifuentes Alcala",
            "Nombre": "Ingrid Johanna Correa Garzon"
        },
        {
            "_id": "803610",
            "Agente": "803610",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sanc803610@siigo.com",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre": "Maira Alejandra Sanchez Villa"
        },
        {
            "_id": "801270",
            "Agente": "801270",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "germ801270@siigo.com",
            "Lider": "Silvia Zilah Fernandez Almeida",
            "Nombre": "Jessica Gabriela Germano Suarez"
        },
        {
            "_id": "802356",
            "Agente": "802356",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "dela802356@siigo.com",
            "Lider": "Harol Perez Giraldo",
            "Nombre": "Santiago De la Cruz Idarraga"
        },
        {
            "_id": "803713",
            "Agente": "803713",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "boho803713@siigo.com",
            "Lider": "Jessica Paola Paez Quintero",
            "Nombre": "Paola Andrea Bohorquez Mejia"
        },
        {
            "_id": "802124",
            "Agente": "802124",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "saav802124@siigo.com",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre": "Leidy Paola Saavedra Parada"
        },
        {
            "_id": "804024",
            "Agente": "804024",
            "Area": "Finance & Administration",
            "Division": "Siigo Ecuador",
            "Email": "roja804024@siigo.com",
            "Lider": "Diana Elizabeth Davila Murillo",
            "Nombre": "Cynthia Nathaly Rojas Carrion"
        },
        {
            "_id": "804034",
            "Agente": "804034",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "canc804034@siigo.com",
            "Lider": "Luis Ignacio Ferreira Duarte",
            "Nombre": "Natalia Cancela Rodriguez"
        },
        {
            "_id": "112201",
            "Agente": "112201",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "more112201@siigo.com",
            "Lider": "Juan Carlos Valdes Martin",
            "Nombre": "Wendy Julieth Moreno Barrero"
        },
        {
            "_id": "802450",
            "Agente": "802450",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "agui802450@siigo.com",
            "Lider": "Carlos Eduardo Sanchez Castaño",
            "Nombre": "Anggie Carolina Aguilar Delgado"
        },
        {
            "_id": "804064",
            "Agente": "804064",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "cord804064@siigo.com",
            "Lider": "Juan Carlos Guarin Walteros",
            "Nombre": "David Alejandro Cordoba Restrepo"
        },
        {
            "_id": "19783",
            "Agente": "19783",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "Gilu19783@siigo.com",
            "Lider": "Lyda Patricia Reyes Pradilla",
            "Nombre": "Edith Angelica Gil Umbarila"
        },
        {
            "_id": "804572",
            "Agente": "804572",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "celi804572@siigo.com",
            "Lider": "Walter Antonio Muñoz Antepara",
            "Nombre": "Angy Marcela Celi Garcia"
        },
        {
            "_id": "110029",
            "Agente": "110029",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "card110029@siigo.com",
            "Lider": "Diana Maria Naranjo Mattheus",
            "Nombre": "Jose Steven Cardona Betancur"
        },
        {
            "_id": "804003",
            "Agente": "804003",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "ball804003@siigo.com",
            "Lider": "Wendy Dayana Rojas Rojas",
            "Nombre": "Julian Andres Ballesteros Barreto"
        },
        {
            "_id": "801933",
            "Agente": "801933",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "bern801933@siigo.com",
            "Lider": "Edgar Fabian Ruano Daza",
            "Nombre": "David Hernando Bernal Hernandez"
        },
        {
            "_id": "800513",
            "Agente": "800513",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mier800513@siigo.com",
            "Lider": "Yesika Viviana Garcia Niño",
            "Nombre": "Erika Tatiana Mier Galindo"
        },
        {
            "_id": "1927",
            "Agente": "1927",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "amendez@aspel.com.mx",
            "Lider": "Elia Karina Lopez Romero",
            "Nombre": "Alejandra Marlen Mendez Morales"
        },
        {
            "_id": "800950",
            "Agente": "800950",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "garc800950@siigo.com",
            "Lider": "Yuleidy Josefina Briceño Fernandez",
            "Nombre": "Angie Vanessa Garcia Arara"
        },
        {
            "_id": "802488",
            "Agente": "802488",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "garc802488@siigo.com",
            "Lider": "Francy Idaly Muelas Muelas",
            "Nombre": "Octavio Alexander Garcia Carvajalino"
        },
        {
            "_id": "800847",
            "Agente": "800847",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "matt800847@siigo.com",
            "Lider": "Francy Julieth Viracacha Gutierrez",
            "Nombre": "Laura Alejandra Matta Villareal"
        },
        {
            "_id": "3156",
            "Agente": "3156",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "aojeda@aspel.com.mx",
            "Lider": "Agustin Hernandez Lopez",
            "Nombre": "Alejandro Osorio Ojeda"
        },
        {
            "_id": "804072",
            "Agente": "804072",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "andr804072@siigo.com",
            "Lider": "Leidy Stefany Castillo 0",
            "Nombre": "Edgar David Andrade Varon"
        },
        {
            "_id": "801441",
            "Agente": "801441",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "boho801441@siigo.com",
            "Lider": "Maria Ximena Manrique Isaziga",
            "Nombre": "Maikol Fernando Bohorquez Garcia"
        },
        {
            "_id": "802467",
            "Agente": "802467",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pale802467@siigo.com",
            "Lider": "Diana Sofia Ramos Castillo",
            "Nombre": "Andrea Paola Palencia Manjarres"
        },
        {
            "_id": "801097",
            "Agente": "801097",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "jusi801097@siigo.com",
            "Lider": "Natalia Marinez Gonzalez",
            "Nombre": "Ivan Elias Jusid Machado"
        },
        {
            "_id": "801241",
            "Agente": "801241",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "guer801241@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Veronica Estefania Guerrero Merchan"
        },
        {
            "_id": "800812",
            "Agente": "800812",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pere800812@siigo.com",
            "Lider": "Eliana Maria Fajardo Prada",
            "Nombre": "Karen Perez Daza"
        },
        {
            "_id": "2196",
            "Agente": "2196",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "kzapata@aspel.com.mx",
            "Lider": "Reyna Del Rosario Camal Uc",
            "Nombre": "Karen Nadime Zapata Chale"
        },
        {
            "_id": "800983",
            "Agente": "800983",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "pere800983@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Cirilo Perez Mallia"
        },
        {
            "_id": "801236",
            "Agente": "801236",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "guti801236@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Tatiana Katherine Gutierrez Murillo"
        },
        {
            "_id": "111723",
            "Agente": "111723",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "card111723@siigo.com",
            "Lider": "Magda de Jesús Becerra Castañeda",
            "Nombre": "Jose Israel Cardenas Cortes"
        },
        {
            "_id": "803616",
            "Agente": "803616",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "orti803616@siigo.com",
            "Lider": "Erika Mildred Avila Olaya",
            "Nombre": "Veronica Paola Ortiz Zapata"
        },
        {
            "_id": "803599",
            "Agente": "803599",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cubi803599@siigo.com",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre": "Mariana Del Pilar Cubides Cely"
        },
        {
            "_id": "804008",
            "Agente": "804008",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "vent804008@siigo.com",
            "Lider": "Diego Armando Alzate Rios",
            "Nombre": "Brayan Mateo Vente Arenas"
        },
        {
            "_id": "803762",
            "Agente": "803762",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "caic803762@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Maria Fernanda Caicedo Escobar"
        },
        {
            "_id": "802640",
            "Agente": "802640",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "alva802640@siigo.com",
            "Lider": "Yuleidy Josefina Briceño Fernandez",
            "Nombre": "Jesus David Alvarez Lopez"
        },
        {
            "_id": "3726",
            "Agente": "3726",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "gcontreras@aspel.com.mx",
            "Lider": "Alejandro Lopez Manzo",
            "Nombre": "Grisell Contreras Pavon"
        },
        {
            "_id": "801725",
            "Agente": "801725",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gord801725@siigo.com",
            "Lider": "Cristhian David Niño Barrera",
            "Nombre": "Yenni Paola Gordillo Chacon"
        },
        {
            "_id": "800394",
            "Agente": "800394",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "more800394@siigo.com",
            "Lider": "Martha Isabel Diaz Gomez",
            "Nombre": "Ecmy Mahilluth Moreno Calderon"
        },
        {
            "_id": "800903",
            "Agente": "800903",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gome800903@siigo.com",
            "Lider": "Jefferson Arvey Ramirez Ibañez",
            "Nombre": "Wendy Johanna Gomez Herrera"
        },
        {
            "_id": "28509",
            "Agente": "28509",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "Mart28509@siigo.com",
            "Lider": "Anyela Marcela Rivera Mera",
            "Nombre": "Yuri Jissette Martinez Tique"
        },
        {
            "_id": "802677",
            "Agente": "802677",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "yepe802677@siigo.com",
            "Lider": "Diana Patricia Villarraga Forero",
            "Nombre": "Jose Roberto Yepes Andrade"
        },
        {
            "_id": "802911",
            "Agente": "802911",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "ayal802911@siigo.com",
            "Lider": "Jorge Alberto Navia Charria",
            "Nombre": "Katherine Ayala Garcia"
        },
        {
            "_id": "3546",
            "Agente": "3546",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jrayas@aspel.com.mx",
            "Lider": "Virginia Guadalupe Ramirez Hernandez",
            "Nombre": "Jessica Andrea Rayas Mendoza"
        },
        {
            "_id": "3110",
            "Agente": "3110",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "smartinez@aspel.com.mx",
            "Lider": "Alexis Abraham Sanchez Portillo",
            "Nombre": "Santos Benjamin Martinez Martinez"
        },
        {
            "_id": "803716",
            "Agente": "803716",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mosq803716@siigo.com",
            "Lider": "Juan Carlos Sanchez Velez",
            "Nombre": "Anny Yulieth Mosquera Ibarguen"
        },
        {
            "_id": "29615",
            "Agente": "29615",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "Maya29615@siigo.com",
            "Lider": "Juan Pablo Henao Muñoz",
            "Nombre": "Luz Katerine Maya Mogollón"
        },
        {
            "_id": "802508",
            "Agente": "802508",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "avel802508@siigo.com",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre": "Laura Jireth Avellaneda Puentes"
        },
        {
            "_id": "3725",
            "Agente": "3725",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jarteaga@aspel.com.mx",
            "Lider": "Luz Angelica Torres Quintero",
            "Nombre": "Jair Arteaga Wong"
        },
        {
            "_id": "801628",
            "Agente": "801628",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "esco801628@siigo.com",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre": "Marilin Julieth Escorcia Torres"
        },
        {
            "_id": "2925",
            "Agente": "2925",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "llujan@aspel.com.mx",
            "Lider": "Armando Uriel Lopez Vazquez",
            "Nombre": "Luis Alejandro Martinez Lujan"
        },
        {
            "_id": "804185",
            "Agente": "804185",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "orti804185@siigo.com",
            "Lider": "Yuleidy Josefina Briceño Fernandez",
            "Nombre": "Angie Vanessa Ortiz Contreras"
        },
        {
            "_id": "3501",
            "Agente": "3501",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "fuen803498@siigo.com",
            "Lider": "Andrea Zuluaga Uribe",
            "Nombre": "Paul Rogelio Fuentes Tabarez"
        },
        {
            "_id": "1762",
            "Agente": "1762",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "mordonez@aspel.com.mx",
            "Lider": "Jesus Alfonso Leyra Galan",
            "Nombre": "Miguel Angel Ordoñez Vega"
        },
        {
            "_id": "801200",
            "Agente": "801200",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "vera801200@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Ramon Isaac Vera Rodriguez"
        },
        {
            "_id": "804528",
            "Agente": "804528",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "uraz804528@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Diana Marcela Urazan Chacon"
        },
        {
            "_id": "2327",
            "Agente": "2327",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jblanco@aspel.com.mx",
            "Lider": "Rene Soriano Estrada",
            "Nombre": "Jose Manuel Blanco Peralta"
        },
        {
            "_id": "800882",
            "Agente": "800882",
            "Area": "Finance & Administration",
            "Division": "Siigo Ecuador",
            "Email": "aure800882@siigo.com",
            "Lider": "Sergio Alberto Caicedo Ramirez",
            "Nombre": "Karla Cristina Aurea Rendon"
        },
        {
            "_id": "804161",
            "Agente": "804161",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gome804161@siigo.com",
            "Lider": "Gustavo Adolfo Espinosa Toledo",
            "Nombre": "Claudia Lorena Gomez Carvajal"
        },
        {
            "_id": "630",
            "Agente": "630",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "galdecua@aspel.com.mx",
            "Lider": "Jhon Emmanuel Zuñiga Paredes",
            "Nombre": "Gerardo Enrique Aldecua Matamoros"
        },
        {
            "_id": "800681",
            "Agente": "800681",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "amay800681@siigo.com",
            "Lider": "Juan Carlos Valdes Martin",
            "Nombre": "Laura Fernanda Amaya Jimenez"
        },
        {
            "_id": "801335",
            "Agente": "801335",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "garc801335@siigo.com",
            "Lider": "Juvenal Reyes Silva",
            "Nombre": "Carolina Garcia Morales"
        },
        {
            "_id": "800711",
            "Agente": "800711",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "barr800711@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Luis Angel Barragan Alvarado"
        },
        {
            "_id": "801557",
            "Agente": "801557",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "gome801557@siigo.com",
            "Lider": "Edwin Fermin Gomez Quiñonez",
            "Nombre": "Maria Lucia Gomez Rodriguez"
        },
        {
            "_id": "800916",
            "Agente": "800916",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mart800916@siigo.com",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre": "Janeth Paola Martinez Cuartas"
        },
        {
            "_id": "3321",
            "Agente": "3321",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "asalas@aspel.com.mx",
            "Lider": "Luis Alejandro Salgado Vergara",
            "Nombre": "Alan Arturo Aguillon Salas"
        },
        {
            "_id": "800898",
            "Agente": "800898",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "sanc800898@siigo.com",
            "Lider": "Nataly Carolina Gonzalez Sandoval",
            "Nombre": "Miguel Angel Sanchez Pulido"
        },
        {
            "_id": "802662",
            "Agente": "802662",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "gome802662@siigo.com",
            "Lider": "David Julian Rojas Prado",
            "Nombre": "Erwin Eduardo Gomez Movilla"
        },
        {
            "_id": "111674",
            "Agente": "111674",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "more111674@siigo.com",
            "Lider": "Natalia Herrera Rey",
            "Nombre": "Jenny Carolina Moreno Duarte"
        },
        {
            "_id": "802770",
            "Agente": "802770",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "bern802770@siigo.com",
            "Lider": "Alison Anaid Chica Ezeta",
            "Nombre": "Angee Julieth Bernal Gonzalez"
        },
        {
            "_id": "802358",
            "Agente": "802358",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "torr802358@siigo.com",
            "Lider": "Miguel Oswaldo Velasquez Fragiel",
            "Nombre": "Cesar Augusto Torres Benavides"
        },
        {
            "_id": "802045",
            "Agente": "802045",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "alva802045@siigo.com",
            "Lider": "Alvaro Jose Almendrales Pabon",
            "Nombre": "David Alejandro Alvarado Castro"
        },
        {
            "_id": "800998",
            "Agente": "800998",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pena800998@siigo.com",
            "Lider": "Cristian Camilo Hernandez Gonzalez",
            "Nombre": "Leidy Natalia Peña Ayala"
        },
        {
            "_id": "801410",
            "Agente": "801410",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "diaz801410@siigo.com",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre": "Gina Brigette Diaz Gomez"
        },
        {
            "_id": "801360",
            "Agente": "801360",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "sier801360@siigo.com",
            "Lider": "Jairo Andres Mendieta 0",
            "Nombre": "Duwan Estiven Sierra Guerrero"
        },
        {
            "_id": "3101",
            "Agente": "3101",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "fherrera@aspel.com.mx",
            "Lider": "Abraham Franco Nares",
            "Nombre": "Francisco Javier Chi Herrera"
        },
        {
            "_id": "801065",
            "Agente": "801065",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "puer801065@siigo.com",
            "Lider": "Gustavo Adolfo Valencia Chaparro",
            "Nombre": "Adriana Paola Puerto Niño"
        },
        {
            "_id": "1874",
            "Agente": "1874",
            "Area": "Product",
            "Division": "Siigo Mexico",
            "Email": "uperez@aspel.com.mx",
            "Lider": "Candelaria Marquez Vazquez",
            "Nombre": "Ulises Perez Velazquez"
        },
        {
            "_id": "803642",
            "Agente": "803642",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ardi803642@siigo.com",
            "Lider": "Eliana Maria Fajardo Prada",
            "Nombre": "Merelyn Johanna Ardila Perez"
        },
        {
            "_id": "801090",
            "Agente": "801090",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "ferr801090@siigo.com",
            "Lider": "Luis Ignacio Ferreira Duarte",
            "Nombre": "Diego Sebastian Ferreira Roybal"
        },
        {
            "_id": "802007",
            "Agente": "802007",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mesa802007@siigo.com",
            "Lider": "Diana Sofia Ramos Castillo",
            "Nombre": "Jose David Mesa Quintero"
        },
        {
            "_id": "3454",
            "Agente": "3454",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "aldo.suarez@aspel.com.mx",
            "Lider": "Santos Benjamin Martinez Martinez",
            "Nombre": "Aldo Ruben Suarez Segovia"
        },
        {
            "_id": "3228",
            "Agente": "3228",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "fascencion@aspel.com.mx",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Fernando Ascencion Ronquillo"
        },
        {
            "_id": "801914",
            "Agente": "801914",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cerq801914@siigo.com",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre": "Liset Viviana Cerquera Cuellar"
        },
        {
            "_id": "802241",
            "Agente": "802241",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "ocho802241@siigo.com",
            "Lider": "Juan Carlos Valdes Martin",
            "Nombre": "Luis Carlos Ochoa Munevar"
        },
        {
            "_id": "3344",
            "Agente": "3344",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "oerosas@aspel.com.mx",
            "Lider": "Jorge Alfredo Huesca Martinez",
            "Nombre": "Oscar Ezequiel Lopez Rosas"
        },
        {
            "_id": "802244",
            "Agente": "802244",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "acos802244@siigo.com",
            "Lider": "Mario Fernando Nuñez Santos",
            "Nombre": "Sara Lucia Acosta Valle"
        },
        {
            "_id": "6",
            "Agente": "6",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "mjimenez@aspel.com.mx",
            "Lider": "Jesus Dario Sanchez Nava",
            "Nombre": "Juan Mauricio Jimenez Espinoza"
        },
        {
            "_id": "801461",
            "Agente": "801461",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "beja801461@siigo.com",
            "Lider": "Jessica Paola Paez Quintero",
            "Nombre": "Jhons Rene Bejarano Garcia"
        },
        {
            "_id": "108975",
            "Agente": "108975",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "prie108975@siigo.com",
            "Lider": "Jarbin Dadey Rodriguez Peña",
            "Nombre": "Jimena Marcela Prieto Bohorquez"
        },
        {
            "_id": "801168",
            "Agente": "801168",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "meza801168@siigo.com",
            "Lider": "Carlos Javier Mendez Vera",
            "Nombre": "Juan Pablo Meza Gazabon"
        },
        {
            "_id": "801429",
            "Agente": "801429",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "parr801429@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Alba Liliana Parra Castiblanco"
        },
        {
            "_id": "801261",
            "Agente": "801261",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "guat801261@siigo.com",
            "Lider": "Edison Arley Plaza Marin",
            "Nombre": "Yilmer Ramiro Guativa Rincon"
        },
        {
            "_id": "802099",
            "Agente": "802099",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "quin802099@siigo.com",
            "Lider": "Jenny Paola Mendez 0",
            "Nombre": "Yesica Alexandra Quiñonez Solano"
        },
        {
            "_id": "801502",
            "Agente": "801502",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gome801502@siigo.com",
            "Lider": "Luz Katerine Maya Mogollón",
            "Nombre": "Karina Maria Gomez Alvarez"
        },
        {
            "_id": "800564",
            "Agente": "800564",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "madr800564@siigo.com",
            "Lider": "Andres Alberto Gomez Baño",
            "Nombre": "Cindy Eleana Madrid Romero"
        },
        {
            "_id": "802147",
            "Agente": "802147",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "varg802147@siigo.com",
            "Lider": "Angie Camila Diaz Escobar",
            "Nombre": "Sebastian Alejandro Vargas Poveda"
        },
        {
            "_id": "802719",
            "Agente": "802719",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cruz802719@siigo.com",
            "Lider": "Aleida Bibiana Garcia Suarez",
            "Nombre": "Sofia Cruz Fernandez"
        },
        {
            "_id": "109914",
            "Agente": "109914",
            "Area": "Sales",
            "Division": "Siigo Perú",
            "Email": "ypar109914@siigo.com",
            "Lider": "Jessica Consuelo Lopez Castillo",
            "Nombre": "Yvan Joe Yparraguirre Yarleque"
        },
        {
            "_id": "3393",
            "Agente": "3393",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "mpena@aspel.com.mx",
            "Lider": "Jorge Alfredo Huesca Martinez",
            "Nombre": "Marco Antonio Lopez Peña"
        },
        {
            "_id": "802200",
            "Agente": "802200",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pint802200@siigo.com",
            "Lider": "Angel Alfonso Arciniegas Guerrero",
            "Nombre": "Maira Alejandra Pinto Pinzon"
        },
        {
            "_id": "801928",
            "Agente": "801928",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "corr801928@siigo.com",
            "Lider": "Jefferson Arvey Ramirez Ibañez",
            "Nombre": "Yazmin Andrea Correcha Pinzon"
        },
        {
            "_id": "801540",
            "Agente": "801540",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "aris801540@siigo.com",
            "Lider": "Juan Jose Correa Acosta",
            "Nombre": "Diego Armando Aristizabal Bedoya"
        },
        {
            "_id": "111729",
            "Agente": "111729",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "oban111729@siigo.com",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre": "Angelica Obando Nieto"
        },
        {
            "_id": "800954",
            "Agente": "800954",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pena800954@siigo.com",
            "Lider": "Hernan Dario Parra Osorio",
            "Nombre": "Jeisson Fabian Peña Cardona"
        },
        {
            "_id": "800996",
            "Agente": "800996",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vill800996@siigo.com",
            "Lider": "Diego Armando Aristizabal Bedoya",
            "Nombre": "Diana Patricia Villarraga Forero"
        },
        {
            "_id": "802562",
            "Agente": "802562",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sosa802562@siigo.com",
            "Lider": "Jenny Paola Mendez 0",
            "Nombre": "Michel Dayana Sosa Caceres"
        },
        {
            "_id": "2994",
            "Agente": "2994",
            "Area": "People & Culture",
            "Division": "Siigo Mexico",
            "Email": "savendano@aspel.com.mx",
            "Lider": "Ivon Andrea Rios Rodriguez",
            "Nombre": "Sonia Avendaño Ortega"
        },
        {
            "_id": "3507",
            "Agente": "3507",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "pmontano@aspel.com.mx",
            "Lider": "Arturo Alvarez Martinez",
            "Nombre": "Paola Nayely Montaño Lopez"
        },
        {
            "_id": "803786",
            "Agente": "803786",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "orti803786@siigo.com",
            "Lider": "Manuel Fernando Salazar Diaz",
            "Nombre": "Roberth Alexander Ortiz Chavaco"
        },
        {
            "_id": "3537",
            "Agente": "3537",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "yrueda@aspel.com.mx",
            "Lider": "Arturo Alvarez Martinez",
            "Nombre": "Yeni Rueda Villaseñor"
        },
        {
            "_id": "803450",
            "Agente": "803450",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "garn803450@siigo.com",
            "Lider": "Diana Michelle Matamoros Del Rosario",
            "Nombre": "Nisy Llosira Garnica Hernandez"
        },
        {
            "_id": "33897",
            "Agente": "33897",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mend33897@siigo.com",
            "Lider": "John Misael Martinez Montero",
            "Nombre": "Nidia Carolina Mendez Suarez"
        },
        {
            "_id": "109964",
            "Agente": "109964",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "melo109964@siigo.com",
            "Lider": "Julian Bernardo Fernandez Garcia",
            "Nombre": "Gina Estefania Melo Sanchez"
        },
        {
            "_id": "803763",
            "Agente": "803763",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "urre803763@siigo.com",
            "Lider": "Jenny Paola Mendez 0",
            "Nombre": "Alexandra Urrego Montejo"
        },
        {
            "_id": "220530",
            "Agente": "220530",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Mora220530@siigo.com",
            "Lider": "Juvenal Reyes Silva",
            "Nombre": "Julieth Morales Tabares"
        },
        {
            "_id": "803919",
            "Agente": "803919",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "jere803919@siigo.com",
            "Lider": "Alcides Martin Salas Orozco",
            "Nombre": "Paola Alejandra Jerez Pedraza"
        },
        {
            "_id": "803761",
            "Agente": "803761",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mart803761@siigo.com",
            "Lider": "Sory Yaqueline Abello Polo",
            "Nombre": "Jeyson Alejandro Martinez Mendoza"
        },
        {
            "_id": "802769",
            "Agente": "802769",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "onti802769@siigo.com",
            "Lider": "Alison Anaid Chica Ezeta",
            "Nombre": "Susan Patricia Ontibon Pineda"
        },
        {
            "_id": "802025",
            "Agente": "802025",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sand802025@siigo.com",
            "Lider": "Nestor Oswaldo Becerra Prieto",
            "Nombre": "María Alejandra Sandoval Jiménez"
        },
        {
            "_id": "801551",
            "Agente": "801551",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "rios801551@siigo.com",
            "Lider": "Andrea Carolina Cabrera Gutierrez",
            "Nombre": "Ivon Andrea Rios Rodriguez"
        },
        {
            "_id": "110011",
            "Agente": "110011",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "garc110011@siigo.com",
            "Lider": "Jesus Daniel Arzuza Roman",
            "Nombre": "Maria Gabriel Garcia Cotes"
        },
        {
            "_id": "802020",
            "Agente": "802020",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rami802020@siigo.com",
            "Lider": "Carlos Eduardo Sanchez Castaño",
            "Nombre": "Karen Alejandra Ramirez Rodriguez"
        },
        {
            "_id": "3440",
            "Agente": "3440",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ehernandez@aspel.com.mx",
            "Lider": "Cristian Camilo Hernandez Gonzalez",
            "Nombre": "Emmanuel Hernandez Jimenez"
        },
        {
            "_id": "107914",
            "Agente": "107914",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pena107914@siigo.com",
            "Lider": "Jesus Daniel Arzuza Roman",
            "Nombre": "Maria Fernanda Peñaloza Oñate"
        },
        {
            "_id": "3182",
            "Agente": "3182",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "jgalavis@aspel.com.mx",
            "Lider": "Alejandro Lopez Manzo",
            "Nombre": "Jonathan Dominguez Galavis"
        },
        {
            "_id": "801947",
            "Agente": "801947",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "carr801947@siigo.com",
            "Lider": "Luz Angelica Torres Quintero",
            "Nombre": "German Orlando Carreño Orellano"
        },
        {
            "_id": "801211",
            "Agente": "801211",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "quir801211@siigo.com",
            "Lider": "Alix Dayana Archila Castillo",
            "Nombre": "Nicole Vanesa Quiroga Fuquen"
        },
        {
            "_id": "802174",
            "Agente": "802174",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vill802174@siigo.com",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre": "Adriana Villamizar Jaimes"
        },
        {
            "_id": "804011",
            "Agente": "804011",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "diaz804011@siigo.com",
            "Lider": "Juan Carlos Valdes Martin",
            "Nombre": "Maria Camila Alejandra Diaz Barrios"
        },
        {
            "_id": "3235",
            "Agente": "3235",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "chernandezm@aspel.com.mx",
            "Lider": "Marisela Yasmin Franco Cruz",
            "Nombre": "Carlos Eduardo Hernandez Martinez"
        },
        {
            "_id": "800874",
            "Agente": "800874",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "more800874@siigo.com",
            "Lider": "Cristian Camilo Hernandez Gonzalez",
            "Nombre": "Stefania Moreno Soler"
        },
        {
            "_id": "3414",
            "Agente": "3414",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jgomez@aspel.com.mx",
            "Lider": "Adriana Rodriguez Castro",
            "Nombre": "Jose Luis Gomez Carrasco"
        },
        {
            "_id": "3267",
            "Agente": "3267",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "eyepez@aspel.com.mx",
            "Lider": "Pedro Arturo Rico Martinez",
            "Nombre": "Edith Berenice Andrade Yepez"
        },
        {
            "_id": "800584",
            "Agente": "800584",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "apup800584@siigo.com",
            "Lider": "Manuel Fernando Salazar Diaz",
            "Nombre": "Luis Fernando Apupalo del Rosario"
        },
        {
            "_id": "803595",
            "Agente": "803595",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "ruiz803595@siigo.com",
            "Lider": "Andres David Leon Guerra",
            "Nombre": "Juan Pablo Ruiz Contreras"
        },
        {
            "_id": "110062",
            "Agente": "110062",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "espi110062@siigo.com",
            "Lider": "Liliana Patricia Giraldo Gomez",
            "Nombre": "Gustavo Adolfo Espinosa Toledo"
        },
        {
            "_id": "804587",
            "Agente": "804587",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "wald804587@siigo.com",
            "Lider": "Arturo Alvarez Martinez",
            "Nombre": "Yaneydi Waldo Quinto"
        },
        {
            "_id": "800328",
            "Agente": "800328",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "orti800328@siigo.com",
            "Lider": "Ahylin Dahyan Jamaica Mendez",
            "Nombre": "Lady Nataly Ortiz Grijalba"
        },
        {
            "_id": "12020",
            "Agente": "12020",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "nune12020@siigo.com",
            "Lider": "Juan Pablo Henao Muñoz",
            "Nombre": "Mario Fernando Nuñez Santos"
        },
        {
            "_id": "33819",
            "Agente": "33819",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vare33819@siigo.com",
            "Lider": "Yenny Milena Lopez Florez",
            "Nombre": "Ivan Enrique varela Lasso"
        },
        {
            "_id": "803897",
            "Agente": "803897",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "olar803897@siigo.com",
            "Lider": "Giovanni Baquero Hernandez",
            "Nombre": "Wilson Olarte Tovar"
        },
        {
            "_id": "803662",
            "Agente": "803662",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "jime803662@siigo.com",
            "Lider": "Diana Mercedes Moncada Peraza",
            "Nombre": "Erika Yurani Jimenez Beltran"
        },
        {
            "_id": "32670",
            "Agente": "32670",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Cont32670@siigo.com",
            "Lider": "Juvenal Reyes Silva",
            "Nombre": "Naimen Jose Contreras Jimenez"
        },
        {
            "_id": "33771",
            "Agente": "33771",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "ruan33771@siigo.com",
            "Lider": "Miguel Oswaldo Velasquez Fragiel",
            "Nombre": "Edgar Fabian Ruano Daza"
        },
        {
            "_id": "804196",
            "Agente": "804196",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "zule804196@siigo.com",
            "Lider": "Jessica Paola Paez Quintero",
            "Nombre": "Mario Alexander Zuleta Restrepo"
        },
        {
            "_id": "800588",
            "Agente": "800588",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "delg800588@siigo.com",
            "Lider": "Manuel Andres Solano Duran",
            "Nombre": "Geomar Eliecer Delgado Molina"
        },
        {
            "_id": "25641",
            "Agente": "25641",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "jara25641@siigo.com",
            "Lider": "James Alberto Valencia Caicedo",
            "Nombre": "Pedro León Jaramillo Carvajal"
        },
        {
            "_id": "802617",
            "Agente": "802617",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "nune802617@siigo.com",
            "Lider": "Luis Ignacio Ferreira Duarte",
            "Nombre": "Marcos Nicolas Nuñez Clavijo"
        },
        {
            "_id": "111918",
            "Agente": "111918",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "arci111918@siigo.com",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre": "Francisco Luis Arcila Luna"
        },
        {
            "_id": "803530",
            "Agente": "803530",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rome803530@siigo.com",
            "Lider": "Johanna Patricia Torres Quintero",
            "Nombre": "Viviana Patricia Romero Osorio"
        },
        {
            "_id": "802666",
            "Agente": "802666",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cast802666@siigo.com",
            "Lider": "Juan Camilo Gonzalez Saldaña",
            "Nombre": "Diana Catalina Castro Leon"
        },
        {
            "_id": "800963",
            "Agente": "800963",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "gari800963@siigo.com",
            "Lider": "Martha Isabel Diaz Gomez",
            "Nombre": "Tania Andrea Garibello Gil"
        },
        {
            "_id": "801418",
            "Agente": "801418",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "cast801418@siigo.com",
            "Lider": "Alvaro Jose Acosta Madroñero",
            "Nombre": "Briggitte Tatiana Castañeda Camargo"
        },
        {
            "_id": "800921",
            "Agente": "800921",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bric800921@siigo.com",
            "Lider": "Luz Katerine Maya Mogollón",
            "Nombre": "Adrian Esteban Briceño Bautista"
        },
        {
            "_id": "3174",
            "Agente": "3174",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "jreyes@aspel.com.mx",
            "Lider": "Jose De Jesus De Aquino Curiel",
            "Nombre": "Jose Francisco Reyes Santiago"
        },
        {
            "_id": "801212",
            "Agente": "801212",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "card801212@siigo.com",
            "Lider": "Andrea Catalina Vega Gomez",
            "Nombre": "Lady Janneth Cardenas Cardona"
        },
        {
            "_id": "801217",
            "Agente": "801217",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "came801217@siigo.com",
            "Lider": "Leidy Paola Rubiano Vargas",
            "Nombre": "Xiomara Natali Camelo Fonseca"
        },
        {
            "_id": "801366",
            "Agente": "801366",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "beni801366@siigo.com",
            "Lider": "Alcides Martin Salas Orozco",
            "Nombre": "Andres Jorge Benitez Caffaro"
        },
        {
            "_id": "3273",
            "Agente": "3273",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "rmunoz@aspel.com.mx",
            "Lider": "Luis Alejandro Salgado Vergara",
            "Nombre": "Ricardo Aurelio Muñoz Valdes"
        },
        {
            "_id": "802117",
            "Agente": "802117",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cald802117@siigo.com",
            "Lider": "Jenny Paola Mendez 0",
            "Nombre": "Leidy Daniela Calderon Velasquez"
        },
        {
            "_id": "801750",
            "Agente": "801750",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "gabi801750@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Kevin Josias Gabino Cabrera"
        },
        {
            "_id": "111902",
            "Agente": "111902",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "leon111902@siigo.com",
            "Lider": "Nelson Juan Carlos Acosta Drouet",
            "Nombre": "Angela Catherine Leon Perilla"
        },
        {
            "_id": "804523",
            "Agente": "804523",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sand804523@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Yurley Paola Sandoval Gelves"
        },
        {
            "_id": "112027",
            "Agente": "112027",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "rubi112027@siigo.com",
            "Lider": "Sandy Yurley Galeano Lara",
            "Nombre": "Leidy Paola Rubiano Vargas"
        },
        {
            "_id": "15948",
            "Agente": "15948",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "pena15948@siigo.com",
            "Lider": "Ana Rita Ortiz Herrera",
            "Nombre": "Deimer Esteban Peña Gonzalez"
        },
        {
            "_id": "800195",
            "Agente": "800195",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "pere800195@siigo.com",
            "Lider": "Jorge Alberto Navia Charria",
            "Nombre": "Diana Carolina Perez Cerinza"
        },
        {
            "_id": "803896",
            "Agente": "803896",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "pach803896@siigo.com",
            "Lider": "Aura Cristina Rodriguez Manrique",
            "Nombre": "Karen Sofia Pachon Mahecha"
        },
        {
            "_id": "800480",
            "Agente": "800480",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "flor800480@siigo.com",
            "Lider": "Leidy Stefany Castillo 0",
            "Nombre": "Eduard Andres Florez Anaya"
        },
        {
            "_id": "111882",
            "Agente": "111882",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "padi111882@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Adriana Catalina Padilla Pacheco"
        },
        {
            "_id": "802377",
            "Agente": "802377",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rein802377@siigo.com",
            "Lider": "Alison Anaid Chica Ezeta",
            "Nombre": "Diego Andres Reina Barbosa"
        },
        {
            "_id": "803744",
            "Agente": "803744",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mart803744@siigo.com",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre": "Mayra Alejandra Martinez Del Guercio"
        },
        {
            "_id": "800297",
            "Agente": "800297",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cero800297@siigo.com",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre": "Luis Alberto Ceron Velasco"
        },
        {
            "_id": "802853",
            "Agente": "802853",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "casa802853@siigo.com",
            "Lider": "Maria Fernanda Delgadillo Bernal",
            "Nombre": "Karen Alejandra Casas Mesa"
        },
        {
            "_id": "111924",
            "Agente": "111924",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ruiz111924@siigo.com",
            "Lider": "Angie Johanna Gomez Lopez",
            "Nombre": "Julieth Andrea Ruiz Penagos"
        },
        {
            "_id": "3240",
            "Agente": "3240",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "osierra@aspel.com.mx",
            "Lider": "Maria Teresa Vazquez Garza",
            "Nombre": "Oscar Ulisses Sierra Hernandez"
        },
        {
            "_id": "803591",
            "Agente": "803591",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "beja803591@siigo.com",
            "Lider": "Milady Andrea Guzman Garcia",
            "Nombre": "Yaharys Balentina Bejarano Hernandez"
        },
        {
            "_id": "3326",
            "Agente": "3326",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "hlozano@aspel.com.mx",
            "Lider": "Victor Hugo Huerta Lopez",
            "Nombre": "Hugo Lozano Rivera"
        },
        {
            "_id": "2934",
            "Agente": "2934",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "lcel@aspel.com.mx",
            "Lider": "Edgar Fabian Ruano Daza",
            "Nombre": "Luis Angel Cel Balam"
        },
        {
            "_id": "802072",
            "Agente": "802072",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "arci802072@siigo.com",
            "Lider": "José Manuel Aguayo Escandon",
            "Nombre": "Angel Alfonso Arciniegas Guerrero"
        },
        {
            "_id": "803518",
            "Agente": "803518",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "alza803518@siigo.com",
            "Lider": "Francy Julieth Viracacha Gutierrez",
            "Nombre": "Esmeralda Alzate Zuleta"
        },
        {
            "_id": "801390",
            "Agente": "801390",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "arci801390@siigo.com",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre": "Leydi Vanessa Arciniegas Castañeda"
        },
        {
            "_id": "112542",
            "Agente": "112542",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ortg112542@siigo.com",
            "Lider": "Jesus Daniel Arzuza Roman",
            "Nombre": "Vanessa Del Carmen Ortega Ramirez"
        },
        {
            "_id": "801008",
            "Agente": "801008",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "puen801008@siigo.com",
            "Lider": "John Alexander Benavides Cobos",
            "Nombre": "Lida Katerine Puentes Navarro"
        },
        {
            "_id": "801054",
            "Agente": "801054",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mina801054@siigo.com",
            "Lider": "Carlos Eduardo Sanchez Castaño",
            "Nombre": "Jhoan David Mina Hurtado"
        },
        {
            "_id": "800822",
            "Agente": "800822",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "sola800822@siigo.com",
            "Lider": "Janna Elizabeth Barreto Castro",
            "Nombre": "Miguel Alexis Solano Jimenez"
        },
        {
            "_id": "800243",
            "Agente": "800243",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "roba800243@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Juan David Robayo Acosta"
        },
        {
            "_id": "801454",
            "Agente": "801454",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "vane801454@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Ana Carolina Vanegas Peralta"
        },
        {
            "_id": "111921",
            "Agente": "111921",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rubi111921@siigo.com",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre": "Jhon Dayron Rubio Villegas"
        },
        {
            "_id": "3418",
            "Agente": "3418",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "acastillo@aspel.com.mx",
            "Lider": "Victor Hugo Huerta Lopez",
            "Nombre": "Luis Angel Castillo Morales"
        },
        {
            "_id": "3264",
            "Agente": "3264",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "vbastian@aspel.com.mx",
            "Lider": "Jose Luis Carranza Valdes",
            "Nombre": "Victor Manuel Bastian Gomez"
        },
        {
            "_id": "801213",
            "Agente": "801213",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "sanc801213@siigo.com",
            "Lider": "Luis Felipe Sanchez Gomez",
            "Nombre": "Maria Alejandra Sanchez Cabrera"
        },
        {
            "_id": "800081",
            "Agente": "800081",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "varg800081@siigo.com",
            "Lider": "Jefferson Arvey Ramirez Ibañez",
            "Nombre": "Andrea Catalina Vargas Ardila"
        },
        {
            "_id": "803306",
            "Agente": "803306",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "duqu803306@siigo.com",
            "Lider": "Diana Maria Naranjo Mattheus",
            "Nombre": "Sebastian Duque Echavarria"
        },
        {
            "_id": "111984",
            "Agente": "111984",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "raig111984@siigo.com",
            "Lider": "Juan Diego Ortiz Orozco",
            "Nombre": "Sebastian Raigosa Mejia"
        },
        {
            "_id": "800891",
            "Agente": "800891",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "quit800891@siigo.com",
            "Lider": "Jefferson Arvey Ramirez Ibañez",
            "Nombre": "Julieth Quitian Figueroa"
        },
        {
            "_id": "109989",
            "Agente": "109989",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "gale109989@siigo.com",
            "Lider": "Lyda Patricia Reyes Pradilla",
            "Nombre": "Sandy Yurley Galeano Lara"
        },
        {
            "_id": "2563",
            "Agente": "2563",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ecruz@aspel.com.mx",
            "Lider": "Armando Uriel Lopez Vazquez",
            "Nombre": "Michell Estefania Cruz Rodriguez"
        },
        {
            "_id": "2300",
            "Agente": "2300",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "imorales@aspel.com.mx",
            "Lider": "Gabriel Chavez Carrera",
            "Nombre": "Isaias Morales Rubio"
        },
        {
            "_id": "111981",
            "Agente": "111981",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "corr111981@siigo.com",
            "Lider": "Juan Diego Ortiz Orozco",
            "Nombre": "Manuel Alejandro Correa Quintana"
        },
        {
            "_id": "2574",
            "Agente": "2574",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "rsan@aspel.com.mx",
            "Lider": "Jorge Alfredo Huesca Martinez",
            "Nombre": "Ricardo Johnatan San Nicolas Morales"
        },
        {
            "_id": "111416",
            "Agente": "111416",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "herr111416@siigo.com",
            "Lider": "Yeimy Milena Bustos Gutierrez",
            "Nombre": "Nancy Johanna Herrera Vargas"
        },
        {
            "_id": "4016",
            "Agente": "4016",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "Aria4016@siigo.com",
            "Lider": "Miguel Oswaldo Velasquez Fragiel",
            "Nombre": "Miguel Angel Arias Diaz-Granados"
        },
        {
            "_id": "112047",
            "Agente": "112047",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gali112047@siigo.com",
            "Lider": "Vicky Alejandra Hernandez Mendez",
            "Nombre": "Jazmin Galindo Cuadrado"
        },
        {
            "_id": "111811",
            "Agente": "111811",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "lope111811@siigo.com",
            "Lider": "Esteban Tellez Garcia",
            "Nombre": "Carlos German Lopez Murcia"
        },
        {
            "_id": "111866",
            "Agente": "111866",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pala111866@siigo.com",
            "Lider": "Jesus Daniel Arzuza Roman",
            "Nombre": "Ana Cecilia Palacin Cantillo"
        },
        {
            "_id": "112171",
            "Agente": "112171",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "lass112171@siigo.com",
            "Lider": "Wilson Medina Nuñez",
            "Nombre": "Sindy Paola Lasso Velasco"
        },
        {
            "_id": "802024",
            "Agente": "802024",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cant802024@siigo.com",
            "Lider": "Jenny Paola Mendez 0",
            "Nombre": "Maicol Stidt Cantillo Guette"
        },
        {
            "_id": "803824",
            "Agente": "803824",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mira803824@siigo.com",
            "Lider": "Johanna Patricia Torres Quintero",
            "Nombre": "Maria Fernanda Miranda Perez"
        },
        {
            "_id": "804134",
            "Agente": "804134",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rian804134@siigo.com",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre": "Maria Alejandra Riaño Rubiano"
        },
        {
            "_id": "803493",
            "Agente": "803493",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "dela803493@siigo.com",
            "Lider": "Juan Carlos Sanchez Velez",
            "Nombre": "Leidy De la Rosa Acero"
        },
        {
            "_id": "804577",
            "Agente": "804577",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "varg804577@siigo.com",
            "Lider": "Angel Alfonso Arciniegas Guerrero",
            "Nombre": "Maritza Vargas Pabon"
        },
        {
            "_id": "802232",
            "Agente": "802232",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "tell802232@siigo.com",
            "Lider": "Alix Dayana Archila Castillo",
            "Nombre": "Kevin Samuel Tello Castillo"
        },
        {
            "_id": "800581",
            "Agente": "800581",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "cres800581@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Evelyn Lucia Crespo Yagual"
        },
        {
            "_id": "800199",
            "Agente": "800199",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "lope800199@siigo.com",
            "Lider": "Jose Mario Gomez Rodriguez",
            "Nombre": "Diego Alberto Lopez Arias"
        },
        {
            "_id": "800926",
            "Agente": "800926",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "anay800926@siigo.com",
            "Lider": "Jessica Paola Paez Quintero",
            "Nombre": "Nancy Rosa Anaya 0"
        },
        {
            "_id": "800837",
            "Agente": "800837",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "jama800837@siigo.com",
            "Lider": "Edith Angelica Gil Umbarila",
            "Nombre": "Ahylin Dahyan Jamaica Mendez"
        },
        {
            "_id": "800777",
            "Agente": "800777",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "achi800777@siigo.com",
            "Lider": "Wilson Medina Nuñez",
            "Nombre": "Nury Astrid Achicué Cruz"
        },
        {
            "_id": "802552",
            "Agente": "802552",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "torr802552@siigo.com",
            "Lider": "Leidy Stefany Castillo 0",
            "Nombre": "Mayerlis Torres Ramires"
        },
        {
            "_id": "1947",
            "Agente": "1947",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "afarfan@aspel.com.mx",
            "Lider": "Alicia Santana Vallejo",
            "Nombre": "Agustin Hernandez Farfan"
        },
        {
            "_id": "801643",
            "Agente": "801643",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "corr801643@siigo.com",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre": "Nicole Stefani Corredor Herreño"
        },
        {
            "_id": "800832",
            "Agente": "800832",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "urib800832@siigo.com",
            "Lider": "Miguel Ricardo Julio Babilonia",
            "Nombre": "Jonathan Uribe Mendoza"
        },
        {
            "_id": "801443",
            "Agente": "801443",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "barr801443@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Cristian Sadad Barrera Venegas"
        },
        {
            "_id": "800694",
            "Agente": "800694",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "lave800694@siigo.com",
            "Lider": "Luz Derly Bernal Malaver",
            "Nombre": "Derly Paola Laverde Castiblanco"
        },
        {
            "_id": "803506",
            "Agente": "803506",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "hurt803506@siigo.com",
            "Lider": "Angie Valentina Lopez Rodriguez",
            "Nombre": "Camilo Hurtado Varela"
        },
        {
            "_id": "1712",
            "Agente": "1712",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jespinosa@aspel.com.mx",
            "Lider": "Ady Natividad Cob Poot",
            "Nombre": "Jhony Alonso Espinosa Chuil"
        },
        {
            "_id": "199643",
            "Agente": "199643",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "More199643@siigo.com",
            "Lider": "John Misael Martinez Montero",
            "Nombre": "Rocio del Pilar Moreno Oviedo"
        },
        {
            "_id": "26",
            "Agente": "26",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jleyra@aspel.com.mx",
            "Lider": "Eliseo Lopez Cruz",
            "Nombre": "Jesus Alfonso Leyra Galan"
        },
        {
            "_id": "803359",
            "Agente": "803359",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "pinz803359@siigo.com",
            "Lider": "Wilson Medina Nuñez",
            "Nombre": "Eddy Pinzon Peña"
        },
        {
            "_id": "801277",
            "Agente": "801277",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "jals801277@siigo.com",
            "Lider": "Silvia Zilah Fernandez Almeida",
            "Nombre": "Camila Jalsevac Foscaldi"
        },
        {
            "_id": "111760",
            "Agente": "111760",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "gome111760@siigo.com",
            "Lider": "Manuel Fernando Salazar Diaz",
            "Nombre": "Miguel Angel Gomez Espinosa"
        },
        {
            "_id": "803818",
            "Agente": "803818",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "jojo803818@siigo.com",
            "Lider": "Edwin Fermin Gomez Quiñonez",
            "Nombre": "Diego Fernando Jojoa Salas"
        },
        {
            "_id": "109845",
            "Agente": "109845",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ramo109845@siigo.com",
            "Lider": "Nataly Carolina Gonzalez Sandoval",
            "Nombre": "Ana Sugdit Ramos Riaño"
        },
        {
            "_id": "804575",
            "Agente": "804575",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "quit804575@siigo.com",
            "Lider": "Juliana Castro Diaz",
            "Nombre": "Mairon Fabian Quitian Figueroa"
        },
        {
            "_id": "111536",
            "Agente": "111536",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "corc111536@siigo.com",
            "Lider": "Jaime Adalberto Lopez Vivas",
            "Nombre": "Ivan Dario Corchuelo Castro"
        },
        {
            "_id": "3336",
            "Agente": "3336",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jsantillan@aspel.com.mx",
            "Lider": "Victor Hugo Huerta Lopez",
            "Nombre": "Jose Alberto Hernandez Santillan"
        },
        {
            "_id": "33773",
            "Agente": "33773",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "gome33773@siigo.com",
            "Lider": "Ivan Dario Corchuelo Castro",
            "Nombre": "Edwin Fermin Gomez Quiñonez"
        },
        {
            "_id": "802783",
            "Agente": "802783",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "jara802783@siigo.com",
            "Lider": "Walter Antonio Muñoz Antepara",
            "Nombre": "Nancy Luz Jarava Navarro"
        },
        {
            "_id": "803626",
            "Agente": "803626",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "espi803626@siigo.com",
            "Lider": "Angel Camilo Chavez Moreno",
            "Nombre": "Allison Eloisa Espinoza Bermeo"
        },
        {
            "_id": "803519",
            "Agente": "803519",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "cuas803519@siigo.com",
            "Lider": "Anyeliz Beatriz Rico Torres",
            "Nombre": "Jorge Armando Cuaspud Zuñiga"
        },
        {
            "_id": "801170",
            "Agente": "801170",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pati801170@siigo.com",
            "Lider": "Yuleidy Josefina Briceño Fernandez",
            "Nombre": "Oscar Leonardo Patiño Perez"
        },
        {
            "_id": "222765",
            "Agente": "222765",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "Alva222765@siigo.com",
            "Lider": "Libia Amparo Osorio Rodriguez",
            "Nombre": "Mariela Edith Alvarado Onoa"
        },
        {
            "_id": "800924",
            "Agente": "800924",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "hern800924@siigo.com",
            "Lider": "David Alfredo Sanchez Bastidas",
            "Nombre": "Ligia Paola Hernandez Vargas"
        },
        {
            "_id": "802288",
            "Agente": "802288",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rami802288@siigo.com",
            "Lider": "David Rosero Calle",
            "Nombre": "Santiago Andres Ramirez Chavez"
        },
        {
            "_id": "800813",
            "Agente": "800813",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "guzm800813@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Manuela Alejandra Guzman Hernandez"
        },
        {
            "_id": "800799",
            "Agente": "800799",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "vela800799@siigo.com",
            "Lider": "Wendy Dayana Rojas Rojas",
            "Nombre": "Ligia Ines Velasquez Builes"
        },
        {
            "_id": "800017",
            "Agente": "800017",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "hern800017@siigo.com",
            "Lider": "Juan Pablo Henao Muñoz",
            "Nombre": "Cristian Camilo Hernandez Gonzalez"
        },
        {
            "_id": "802347",
            "Agente": "802347",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "nava802347@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Yuri Samantha Navarro Olaya"
        },
        {
            "_id": "112303",
            "Agente": "112303",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "tell112303@siigo.com",
            "Lider": "Magda de Jesús Becerra Castañeda",
            "Nombre": "Duvan Alexis Tello Hinojosa"
        },
        {
            "_id": "800455",
            "Agente": "800455",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "garc800455@siigo.com",
            "Lider": "Liliana Ramirez Vanegas",
            "Nombre": "Caren Yuliana Garcia Angulo"
        },
        {
            "_id": "3510",
            "Agente": "3510",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "eramirez@aspel.com.mx",
            "Lider": "Arturo Alvarez Martinez",
            "Nombre": "Elizabeth Ramirez Garcia"
        },
        {
            "_id": "801239",
            "Agente": "801239",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "jara801239@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Yandry Uribe Jaramillo Payaroso"
        },
        {
            "_id": "800464",
            "Agente": "800464",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "vele800464@siigo.com",
            "Lider": "Luz Derly Bernal Malaver",
            "Nombre": "Diana Marcela Velez Velez"
        },
        {
            "_id": "36959",
            "Agente": "36959",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "guti36959@siigo.com",
            "Lider": "Jimena Marcela Prieto Bohorquez",
            "Nombre": "Margarita Rosa Gutierrez Mendez"
        },
        {
            "_id": "6284",
            "Agente": "6284",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ospi6284@siigo.com",
            "Lider": "Nataly Carolina Gonzalez Sandoval",
            "Nombre": "Rubby Esperanza Ospina Deulofeut"
        },
        {
            "_id": "800889",
            "Agente": "800889",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "devi800889@siigo.com",
            "Lider": "Melany Cristal ",
            "Nombre": "Tatiana Devia Guzman"
        },
        {
            "_id": "800151",
            "Agente": "800151",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gonz800151@siigo.com",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre": "Monica Johana Gonzalez Espinosa"
        },
        {
            "_id": "804157",
            "Agente": "804157",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "fran804157@siigo.com",
            "Lider": "Diana Maria Naranjo Mattheus",
            "Nombre": "Luz Arelix Franco Palma"
        },
        {
            "_id": "800630",
            "Agente": "800630",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "pach800630@siigo.com",
            "Lider": "Diana Sofia Ramos Castillo",
            "Nombre": "Karla Stefania Pacheco Guerrero"
        },
        {
            "_id": "23862",
            "Agente": "23862",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "este23862@siigo.com",
            "Lider": "Ana Rita Ortiz Herrera",
            "Nombre": "Claudia Patricia Estevez Alvarez"
        },
        {
            "_id": "3402",
            "Agente": "3402",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "acamposr@aspel.com.mx",
            "Lider": "Ivan Alejandro Roa Franco",
            "Nombre": "Alfredo Campos Rodriguez"
        },
        {
            "_id": "802517",
            "Agente": "802517",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "reye802517@siigo.com",
            "Lider": "Kristian David Valero Salamanca",
            "Nombre": "Karolina Reyes Parra"
        },
        {
            "_id": "112526",
            "Agente": "112526",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "casa112526@siigo.com",
            "Lider": "Jesus Amado Gaibor Yanez",
            "Nombre": "Michael Andres Casallas Malagon"
        },
        {
            "_id": "803514",
            "Agente": "803514",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gonz803514@siigo.com",
            "Lider": "Johanna Patricia Torres Quintero",
            "Nombre": "Sandra Isabel Gonzalez Morales"
        },
        {
            "_id": "800498",
            "Agente": "800498",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "avil800498@siigo.com",
            "Lider": "Jose Miguel Rey Roman",
            "Nombre": "Juan Diego Avilan Prieto"
        },
        {
            "_id": "804192",
            "Agente": "804192",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rene804192@siigo.com",
            "Lider": "Jessica Paola Paez Quintero",
            "Nombre": "Edwin Rene Montaña"
        },
        {
            "_id": "111872",
            "Agente": "111872",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gira111872@siigo.com",
            "Lider": "John Jairo Mejia Acevedo",
            "Nombre": "Liliana Patricia Giraldo Gomez"
        },
        {
            "_id": "804163",
            "Agente": "804163",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "guer804163@siigo.com",
            "Lider": "Juan Carlos Valdes Martin",
            "Nombre": "Ana Maria Guerrero Talero"
        },
        {
            "_id": "111989",
            "Agente": "111989",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pulg111989@siigo.com",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre": "Natalia Fernanda Pulgarin Garcia"
        },
        {
            "_id": "801878",
            "Agente": "801878",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "barr801878@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Angie Lorena Barrera Ciendua"
        },
        {
            "_id": "800927",
            "Agente": "800927",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cifu800927@siigo.com",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre": "Lady Tatiana Cifuentes Avila"
        },
        {
            "_id": "801709",
            "Agente": "801709",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "roja801709@siigo.com",
            "Lider": "Gloria Estela Osorio Montoya",
            "Nombre": "Wendy Yajarris Rojas Perilla"
        },
        {
            "_id": "801166",
            "Agente": "801166",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "diaz801166@siigo.com",
            "Lider": "Yuleidy Josefina Briceño Fernandez",
            "Nombre": "Diana Marcela Diaz Agudelo"
        },
        {
            "_id": "110112",
            "Agente": "110112",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "zamb110112@siigo.com",
            "Lider": "Ivan Dario Corchuelo Castro",
            "Nombre": "Manuel Fernando Zambrano Ceron"
        },
        {
            "_id": "111471",
            "Agente": "111471",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gome111471@siigo.com",
            "Lider": "William Andres Reyes Silva",
            "Nombre": "Leidy Alejandra Gomez Bernal"
        },
        {
            "_id": "801401",
            "Agente": "801401",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "moli801401@siigo.com",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre": "Yuri Andrea Molina Castillo"
        },
        {
            "_id": "3416",
            "Agente": "3416",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "daboytes@aspel.com.mx",
            "Lider": "Luis Alejandro Salgado Vergara",
            "Nombre": "Daniela Aboytes Martinez"
        },
        {
            "_id": "801627",
            "Agente": "801627",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mala801627@siigo.com",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre": "Marilyn Vanessa Malambo Montoya"
        },
        {
            "_id": "1633",
            "Agente": "1633",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "faragon@aspel.com.mx",
            "Lider": "Manuel Fernando Salazar Diaz",
            "Nombre": "Francis Leonor Aragon Chim"
        },
        {
            "_id": "802151",
            "Agente": "802151",
            "Area": "Finance & Administration",
            "Division": "Siigo Uruguay",
            "Email": "bona802151@siigo.com",
            "Lider": "Giselda Keymetlian 0",
            "Nombre": "Suhail Marina Bonaguro Gamboa"
        },
        {
            "_id": "32",
            "Agente": "32",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "cvaldez@aspel.com.mx",
            "Lider": "Hector Enrique Becerra Perez",
            "Nombre": "Claudia Valdez Carbajal"
        },
        {
            "_id": "3121",
            "Agente": "3121",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "mdramirez@aspel.com.mx",
            "Lider": "Pedro Arturo Rico Martinez",
            "Nombre": "Michelle Daniel Ramirez Hernandez"
        },
        {
            "_id": "800794",
            "Agente": "800794",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mosq800794@siigo.com",
            "Lider": "Hernan Dario Parra Osorio",
            "Nombre": "Barbara Andrea Mosquera Garcia"
        },
        {
            "_id": "3309",
            "Agente": "3309",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jdominguez@aspel.com.mx",
            "Lider": "Maria Teresa Vazquez Garza",
            "Nombre": "Jair Abisain Dominguez Gonzalez"
        },
        {
            "_id": "804015",
            "Agente": "804015",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ruiz804015@siigo.com",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre": "Laura Yulieth Ruiz Rodriguez"
        },
        {
            "_id": "32254",
            "Agente": "32254",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "sarm32254@siigo.com",
            "Lider": "Frankgel Ribely Machado Pantoja",
            "Nombre": "Nicolas Alejandro Sarmiento Poveda"
        },
        {
            "_id": "2191",
            "Agente": "2191",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "ifranco@aspel.com.mx",
            "Lider": "Moises Raul Aguilar Puc",
            "Nombre": "Irvin Manuel Franco Segovia"
        },
        {
            "_id": "803339",
            "Agente": "803339",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "pach803339@siigo.com",
            "Lider": "Juan Diego Avilan Prieto",
            "Nombre": "Daniel Esteban Pachon 0"
        },
        {
            "_id": "804517",
            "Agente": "804517",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "pule804517@siigo.com",
            "Lider": "Milady Andrea Guzman Garcia",
            "Nombre": "Sharick Tatiana Pulecio Sanchez"
        },
        {
            "_id": "3212",
            "Agente": "3212",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "mperezp@aspel.com.mx",
            "Lider": "Marisol Vargas Cortes",
            "Nombre": "Marisol Reyes Perez"
        },
        {
            "_id": "800237",
            "Agente": "800237",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "muel800237@siigo.com",
            "Lider": "Frankgel Ribely Machado Pantoja",
            "Nombre": "Francy Idaly Muelas Muelas"
        },
        {
            "_id": "804146",
            "Agente": "804146",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "hurt804146@siigo.com",
            "Lider": "Frankgel Ribely Machado Pantoja",
            "Nombre": "Juan Carlos Hurtado Castro"
        },
        {
            "_id": "111922",
            "Agente": "111922",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rodr111922@siigo.com",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre": "Juan Carlos Rodriguez Pineda"
        },
        {
            "_id": "804522",
            "Agente": "804522",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "arev804522@siigo.com",
            "Lider": "Jose Luis Perales Dueñas",
            "Nombre": "Johan Esteban Arevalo Carrascal"
        },
        {
            "_id": "803823",
            "Agente": "803823",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "gonz803823@siigo.com",
            "Lider": "Astrid Gisel Duque Rico",
            "Nombre": "Rafael Alexander Gonzalez Diaz"
        },
        {
            "_id": "804179",
            "Agente": "804179",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "boni804179@siigo.com",
            "Lider": "Yuleidy Josefina Briceño Fernandez",
            "Nombre": "Eduardo Andres Bonilla Tabare"
        },
        {
            "_id": "802840",
            "Agente": "802840",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "hena802840@siigo.com",
            "Lider": "William Jesus Sotaquira Ayala",
            "Nombre": "Juan Pablo Henao Muñoz"
        },
        {
            "_id": "800184",
            "Agente": "800184",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "gale800184@siigo.com",
            "Lider": "Lina Maria Buitrago Herrera",
            "Nombre": "Santiago Galeano Pedraza"
        },
        {
            "_id": "800015",
            "Agente": "800015",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "silv800015@siigo.com",
            "Lider": "Lina Maria Buitrago Herrera",
            "Nombre": "Natalia Silva Valderrama"
        },
        {
            "_id": "800208",
            "Agente": "800208",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "guti800208@siigo.com",
            "Lider": "Andres Felipe Baron Ballen",
            "Nombre": "Henry Antonio Gutierrez Rondon"
        },
        {
            "_id": "3592",
            "Agente": "3592",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "elperez@aspel.com.mx",
            "Lider": "Maria Virginia Lopez Salazar",
            "Nombre": "Elizabeth León Perez"
        },
        {
            "_id": "804551",
            "Agente": "804551",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mrodriguez@aspel.com.mx",
            "Lider": "Arturo Alvarez Martinez",
            "Nombre": "Maria Camila Rodriguez Guayacundo"
        },
        {
            "_id": "112358",
            "Agente": "112358",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "parr112358@siigo.com",
            "Lider": "Ivan Dario Corchuelo Castro",
            "Nombre": "Luis Ernesto Parrado Ortiz"
        },
        {
            "_id": "109056",
            "Agente": "109056",
            "Area": "Finance & Administration",
            "Division": "Siigo Ecuador",
            "Email": "arco109056@siigo.com",
            "Lider": "Diana Patricia Atehortua Castaño",
            "Nombre": "Blanca Janeth Arcos Guerrero"
        },
        {
            "_id": "3227",
            "Agente": "3227",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "inajera@aspel.com.mx",
            "Lider": "Jose Luis Carranza Valdes",
            "Nombre": "Sandra Isabel Najera Lopez"
        },
        {
            "_id": "25111",
            "Agente": "25111",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cast25111@siigo.com",
            "Lider": "Magda Lilian Leal Rozo",
            "Nombre": "Juliana Castro Diaz"
        },
        {
            "_id": "111745",
            "Agente": "111745",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "reye111745@siigo.com",
            "Lider": "Magda Lilian Leal Rozo",
            "Nombre": "William Andres Reyes Silva"
        },
        {
            "_id": "802390",
            "Agente": "802390",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "monc802390@siigo.com",
            "Lider": "Diana Maria Naranjo Mattheus",
            "Nombre": "Carmen Yolanda Moncaleano Avila"
        },
        {
            "_id": "27306",
            "Agente": "27306",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "oban27306@siigo.com",
            "Lider": "Magda de Jesús Becerra Castañeda",
            "Nombre": "Angie Lisbet Obando Ortiz"
        },
        {
            "_id": "802506",
            "Agente": "802506",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vald802506@siigo.com",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre": "Monica Judith Valderrama Tibaduiza"
        },
        {
            "_id": "111929",
            "Agente": "111929",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bece111929@siigo.com",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre": "Nancy Olivia Becerra Calderon"
        },
        {
            "_id": "804156",
            "Agente": "804156",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "herr804156@siigo.com",
            "Lider": "Diana Mercedes Moncada Peraza",
            "Nombre": "Duvan Felipe Herrera Barbosa"
        },
        {
            "_id": "803589",
            "Agente": "803589",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "flor803589@siigo.com",
            "Lider": "Milady Andrea Guzman Garcia",
            "Nombre": "Leyla Vanessa Florez Quinto"
        },
        {
            "_id": "111812",
            "Agente": "111812",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "cepe111812@siigo.com",
            "Lider": "Diana Marcela Guzman Caamaño",
            "Nombre": "Gilliam Aylein Cepeda Chaparro"
        },
        {
            "_id": "803816",
            "Agente": "803816",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "ardi803816@siigo.com",
            "Lider": "Andres David Leon Guerra",
            "Nombre": "Jairo Andres Ardila Quiroga"
        },
        {
            "_id": "220528",
            "Agente": "220528",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "silv220528@siigo.com",
            "Lider": "Juan Pablo Carreño Patiño",
            "Nombre": "Belsy Silva Mendez"
        },
        {
            "_id": "112238",
            "Agente": "112238",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "marq112238@siigo.com",
            "Lider": "Juliana Castro Diaz",
            "Nombre": "Paula Alejandra Marquez Gomez"
        },
        {
            "_id": "801634",
            "Agente": "801634",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "suar801634@siigo.com",
            "Lider": "Sory Yaqueline Abello Polo",
            "Nombre": "John Walter Suarez Sotelo"
        },
        {
            "_id": "803783",
            "Agente": "803783",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "tiba803783@siigo.com",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre": "Diana Rocio Tibata Bernal"
        },
        {
            "_id": "803781",
            "Agente": "803781",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mele803781@siigo.com",
            "Lider": "Sory Yaqueline Abello Polo",
            "Nombre": "Leidy Jhohana Melendez Torres"
        },
        {
            "_id": "112533",
            "Agente": "112533",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "jime112533@siigo.com",
            "Lider": "Natalia Marinez Gonzalez",
            "Nombre": "Cristian Felipe Jimenez Roncancio"
        },
        {
            "_id": "802859",
            "Agente": "802859",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "muno802859@siigo.com",
            "Lider": "Yudy Paola Rojas Rojas",
            "Nombre": "Adriana Elizabeth Muñoz Burbano"
        },
        {
            "_id": "800517",
            "Agente": "800517",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rond800517@siigo.com",
            "Lider": "Alix Dayana Archila Castillo",
            "Nombre": "Sara Natalia Rondon Martinez"
        },
        {
            "_id": "108114",
            "Agente": "108114",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "barr108114@siigo.com",
            "Lider": "John Misael Martinez Montero",
            "Nombre": "Eliana Heidi Barreto Tovar"
        },
        {
            "_id": "804235",
            "Agente": "804235",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "cama804231@siigo.com",
            "Lider": "Helder Yesid Castrillon Cobo",
            "Nombre": "Maria Camila Camacho Posso"
        },
        {
            "_id": "802551",
            "Agente": "802551",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "narv802551@siigo.com",
            "Lider": "Sory Yaqueline Abello Polo",
            "Nombre": "Yessica Paola Narvaez Ospino"
        },
        {
            "_id": "803299",
            "Agente": "803299",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "ruiz803299@siigo.com",
            "Lider": "Francisco Jose Bohorquez Torres",
            "Nombre": "Ivan Camilo Ruiz Chitiva"
        },
        {
            "_id": "800991",
            "Agente": "800991",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "muno800991@siigo.com",
            "Lider": "Cristian Camilo Hernandez Gonzalez",
            "Nombre": "Fabian Alfonso Muñoz Moreno"
        },
        {
            "_id": "800333",
            "Agente": "800333",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "chap800333@siigo.com",
            "Lider": "Jimena Marcela Prieto Bohorquez",
            "Nombre": "Brigith Jhoana Chaparro Camacho"
        },
        {
            "_id": "111721",
            "Agente": "111721",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "faja111721@siigo.com",
            "Lider": "Diana Mercedes Moncada Peraza",
            "Nombre": "Liza Fernanda Fajardo Contreras"
        },
        {
            "_id": "800228",
            "Agente": "800228",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mart800228@siigo.com",
            "Lider": "Gustavo Adolfo Espinosa Toledo",
            "Nombre": "Laura Daniela Martinez Medina"
        },
        {
            "_id": "111833",
            "Agente": "111833",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gome111833@siigo.com",
            "Lider": "Marisela Yasmin Franco Cruz",
            "Nombre": "Yoany Gomez Rios"
        },
        {
            "_id": "230680",
            "Agente": "230680",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "Plaz230680@siigo.com",
            "Lider": "Jaime Adalberto Lopez Vivas",
            "Nombre": "Edison Arley Plaza Marin"
        },
        {
            "_id": "800299",
            "Agente": "800299",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cabu800298@siigo.com",
            "Lider": "Eliana Maria Fajardo Prada",
            "Nombre": "Doli Alexandra Cabuya "
        },
        {
            "_id": "803205",
            "Agente": "803205",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "capa803205@siigo.com",
            "Lider": "Cesar Eduardo Lopez Suescun",
            "Nombre": "Jhohan Eduardo Capador Diaz"
        },
        {
            "_id": "802708",
            "Agente": "802708",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rodr802708@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Catalina Rodriguez Convers"
        },
        {
            "_id": "803582",
            "Agente": "803582",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "reye803582@siigo.com",
            "Lider": "Juan Carlos Valdes Martin",
            "Nombre": "Santiago Reyes Castillo"
        },
        {
            "_id": "196422",
            "Agente": "196422",
            "Area": "Country Management",
            "Division": "Siigo Colombia",
            "Email": "orti196422@siigo.com",
            "Lider": "Siigo Siigo ",
            "Nombre": "David Ortiz Diaz"
        },
        {
            "_id": "10786",
            "Agente": "10786",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "guay10786@siigo.com",
            "Lider": "Luz Mery Patino Infante",
            "Nombre": "Edwin Alfredo Guayacan Cardenal"
        },
        {
            "_id": "802902",
            "Agente": "802902",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "ospi802902@siigo.com",
            "Lider": "Juan Carlos Valdes Martin",
            "Nombre": "Sebastian Ospina Suarez"
        },
        {
            "_id": "802582",
            "Agente": "802582",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "mont802582@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Gisella Belen Montero Castillo"
        },
        {
            "_id": "107912",
            "Agente": "107912",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "grau107912@siigo.com",
            "Lider": "Magda de Jesús Becerra Castañeda",
            "Nombre": "Lizeth Dayana Grau Ortega"
        },
        {
            "_id": "802563",
            "Agente": "802563",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cast802563@siigo.com",
            "Lider": "Leidy Stefany Castillo 0",
            "Nombre": "Arnil Yesid Castellon Barrios"
        },
        {
            "_id": "803516",
            "Agente": "803516",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "leyv803516@siigo.com",
            "Lider": "Yeimy Milena Bustos Gutierrez",
            "Nombre": "Daniel Arturo Leyva Santana"
        },
        {
            "_id": "803745",
            "Agente": "803745",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "jara803745@siigo.com",
            "Lider": "Jairo Andres Mendieta 0",
            "Nombre": "Cristian David Jaramillo Duran"
        },
        {
            "_id": "800646",
            "Agente": "800646",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "rodr800646@siigo.com",
            "Lider": "Diana Sofia Ramos Castillo",
            "Nombre": "Luis Andres Rodriguez Morales"
        },
        {
            "_id": "801995",
            "Agente": "801995",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rodr801995@siigo.com",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre": "Nelson Manuel Rodriguez Marrugo"
        },
        {
            "_id": "800546",
            "Agente": "800546",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "chic800546@siigo.com",
            "Lider": "José Manuel Aguayo Escandon",
            "Nombre": "Alison Anaid Chica Ezeta"
        },
        {
            "_id": "238004",
            "Agente": "238004",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "Espi238004@siigo.com",
            "Lider": "Andrea Zuluaga Uribe",
            "Nombre": "Carlos Mario Espinosa Fernandez"
        },
        {
            "_id": "802021",
            "Agente": "802021",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "roam802021@siigo.com",
            "Lider": "Adriana Elizabeth Giron Parra",
            "Nombre": "María Paula Roa Moreno"
        },
        {
            "_id": "802199",
            "Agente": "802199",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "rami802199@siigo.com",
            "Lider": "David Ortiz Diaz",
            "Nombre": "Ana Maria Ramirez Sanchez"
        },
        {
            "_id": "800160",
            "Agente": "800160",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "guiz800160@siigo.com",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre": "Johanna Marcela Güiza Moya"
        },
        {
            "_id": "802747",
            "Agente": "802747",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "quit802747@siigo.com",
            "Lider": "Vicky Alejandra Hernandez Mendez",
            "Nombre": "Jairo Eduardo Quitian Figueroa"
        },
        {
            "_id": "803517",
            "Agente": "803517",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gome803517@siigo.com",
            "Lider": "Johanna Patricia Torres Quintero",
            "Nombre": "Leidy Katherine Gomez Angarita"
        },
        {
            "_id": "109172",
            "Agente": "109172",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "umba109172@siigo.com",
            "Lider": "Pedro Felipe Arana Lastra",
            "Nombre": "Angy Paola Umbacia Diaz"
        },
        {
            "_id": "2368",
            "Agente": "2368",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "schan@aspel.com.mx",
            "Lider": "Seidy Guadalupe Perez 0",
            "Nombre": "Sergio Antonio Chan Samos"
        },
        {
            "_id": "802063",
            "Agente": "802063",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rodr802063@siigo.com",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre": "Cindy Rodriguez Bautista"
        },
        {
            "_id": "800073",
            "Agente": "800073",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rodr800073@siigo.com",
            "Lider": "Diego Armando Aristizabal Bedoya",
            "Nombre": "Jason Steve Rodriguez Navarrete"
        },
        {
            "_id": "110110",
            "Agente": "110110",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "boni110110@siigo.com",
            "Lider": "David Felipe Penagos Mosquera",
            "Nombre": "Jeimmy Susana Bonilla Mendez"
        },
        {
            "_id": "801909",
            "Agente": "801909",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "land801909@siigo.com",
            "Lider": "Mario Fernando Nuñez Santos",
            "Nombre": "Sulma Carolina Landinez Alvarado"
        },
        {
            "_id": "800219",
            "Agente": "800219",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mend800219@siigo.com",
            "Lider": "Yessica Yulieth Quintero Martinez",
            "Nombre": "Jenny Paola Mendez 0"
        },
        {
            "_id": "803768",
            "Agente": "803768",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "segu803768@siigo.com",
            "Lider": "Jose Luis Perales Dueñas",
            "Nombre": "Maria Carolina Segura Sanabria"
        },
        {
            "_id": "3502",
            "Agente": "3502",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ebalderas@aspel.com.mx",
            "Lider": "Arturo Alvarez Martinez",
            "Nombre": "Edith Betzabet Balderas Padilla"
        },
        {
            "_id": "110226",
            "Agente": "110226",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "baro110226@siigo.com",
            "Lider": "Magda Lilian Leal Rozo",
            "Nombre": "Andres Felipe Baron Ballen"
        },
        {
            "_id": "112245",
            "Agente": "112245",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "ordo112245@siigo.com",
            "Lider": "Ivan Dario Corchuelo Castro",
            "Nombre": "Kristein Johan Ordoñez Lopez"
        },
        {
            "_id": "3334",
            "Agente": "3334",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "lamartinez@aspel.com.mx",
            "Lider": "Adriana Rodriguez Castro",
            "Nombre": "Luis Alfredo Ramirez Martinez"
        },
        {
            "_id": "111719",
            "Agente": "111719",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "rami111719@siigo.com",
            "Lider": "Sandy Yurley Galeano Lara",
            "Nombre": "Liliana Ramirez Vanegas"
        },
        {
            "_id": "800335",
            "Agente": "800335",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "triv800335@siigo.com",
            "Lider": "Edith Angelica Gil Umbarila",
            "Nombre": "Natalia Catherine Triviño Cortes"
        },
        {
            "_id": "107164",
            "Agente": "107164",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "more107164@siigo.com",
            "Lider": "John Mario Perez Diaz",
            "Nombre": "Jenifer Andrea Moreno Ospina"
        },
        {
            "_id": "800608",
            "Agente": "800608",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "rami800608@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Jose Miguel Ramirez Leon"
        },
        {
            "_id": "802409",
            "Agente": "802409",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "lond802409@siigo.com",
            "Lider": "Daniel Eduardo Pinto Cepeda",
            "Nombre": "Boris Ivan Londoño Fernandez"
        },
        {
            "_id": "1942",
            "Agente": "1942",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "aromero@aspel.com.mx",
            "Lider": "Jarbin Dadey Rodriguez Peña",
            "Nombre": "Alma Rosa Romero Garcia"
        },
        {
            "_id": "801577",
            "Agente": "801577",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "caba801577@siigo.com",
            "Lider": "Walter Antonio Muñoz Antepara",
            "Nombre": "Nicole Dennis Cabanilla Parrales"
        },
        {
            "_id": "2062",
            "Agente": "2062",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "abarrios@aspel.com.mx",
            "Lider": "Adair Blanco Landin",
            "Nombre": "Ayax Barrios Contreras"
        },
        {
            "_id": "111935",
            "Agente": "111935",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gonz111935@siigo.com",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre": "Sandra Milena Gonzalez Restrepo"
        },
        {
            "_id": "1412",
            "Agente": "1412",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "lutrera@aspel.com.mx",
            "Lider": "Virginia Guadalupe Ramirez Hernandez",
            "Nombre": "Laura Erika Utrera Utrera"
        },
        {
            "_id": "803846",
            "Agente": "803846",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "pena803846@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Bianca Analia Peña Arias"
        },
        {
            "_id": "18429",
            "Agente": "18429",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "duar18429@siigo.com",
            "Lider": "John Mario Perez Diaz",
            "Nombre": "Diana Milena Duarte Bermudez"
        },
        {
            "_id": "42263",
            "Agente": "42263",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "boho42263@siigo.com",
            "Lider": "Jimena Marcela Prieto Bohorquez",
            "Nombre": "Jessica Alexandra Bohorquez Ramos"
        },
        {
            "_id": "3069",
            "Agente": "3069",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "ylarios@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Yadira Larios Hernandez"
        },
        {
            "_id": "110078",
            "Agente": "110078",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "tron110078@siigo.com",
            "Lider": "Hector Orlando Bogoya Aguilar",
            "Nombre": "Alexander Troncoso Morales"
        },
        {
            "_id": "803911",
            "Agente": "803911",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "hurt803911@siigo.com",
            "Lider": "Jose Luis Perales Dueñas",
            "Nombre": "Alexandra Hurtado Uribe"
        },
        {
            "_id": "803609",
            "Agente": "803609",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "muri803609@siigo.com",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre": "Dalia Rocio Murillo Paramo"
        },
        {
            "_id": "3091",
            "Agente": "3091",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "csalcido@aspel.com.mx",
            "Lider": "Jose De Jesus De Aquino Curiel",
            "Nombre": "Cinthya Cecilia Mejia Salcido"
        },
        {
            "_id": "802476",
            "Agente": "802476",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "trej802476@siigo.com",
            "Lider": "Diana Patricia Villarraga Forero",
            "Nombre": "Carolina Trejos Gonzales"
        },
        {
            "_id": "32262",
            "Agente": "32262",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Herr32262@siigo.com",
            "Lider": "John Mario Perez Diaz",
            "Nombre": "Alba Marcela Herrera Gonzalez"
        },
        {
            "_id": "804580",
            "Agente": "804580",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "alva804580@siigo.com",
            "Lider": "William Andres Reyes Silva",
            "Nombre": "Derly Dayan Alvarez Castañeda"
        },
        {
            "_id": "802746",
            "Agente": "802746",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "buit802746@siigo.com",
            "Lider": "Gina Estefania Melo Sanchez",
            "Nombre": "Juan Pablo Buitron Castillo"
        },
        {
            "_id": "801412",
            "Agente": "801412",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "tria801412@siigo.com",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre": "Jenny Lysbeth Triana Padilla"
        },
        {
            "_id": "801489",
            "Agente": "801489",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "cerp801489@siigo.com",
            "Lider": "Juan Esteban Restrepo Cardona",
            "Nombre": "Steven Cerpa Pinto"
        },
        {
            "_id": "804558",
            "Agente": "804558",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "afan804558@siigo.com",
            "Lider": "Manuel Enrique Ramirez Rodriguez",
            "Nombre": "Zayda Smith Afanador Silva"
        },
        {
            "_id": "804525",
            "Agente": "804525",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "alda804525@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Jenny Fernanda Aldana Santana"
        },
        {
            "_id": "801449",
            "Agente": "801449",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "roja801449@siigo.com",
            "Lider": "Gloria Estela Osorio Montoya",
            "Nombre": "Javier Steev Rojas Franco"
        },
        {
            "_id": "804543",
            "Agente": "804543",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gonz804543@siigo.com",
            "Lider": "Jose Luis Perales Dueñas",
            "Nombre": "Andres Estiven Gonzalez Moreno"
        },
        {
            "_id": "800376",
            "Agente": "800376",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "murc800376@siigo.com",
            "Lider": "Magda de Jesús Becerra Castañeda",
            "Nombre": "Adriana Patricia Murcia Mahecha"
        },
        {
            "_id": "802422",
            "Agente": "802422",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "holg802422@siigo.com",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre": "Karem Xiomara Holguin Aragon"
        },
        {
            "_id": "111959",
            "Agente": "111959",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "sanc111959@siigo.com",
            "Lider": "Manuel Enrique Ramirez Rodriguez",
            "Nombre": "Jesus Dario Sanchez Nava"
        },
        {
            "_id": "28763",
            "Agente": "28763",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rodr28763@siigo.com",
            "Lider": "Yenny Milena Lopez Florez",
            "Nombre": "Luisa Fernanda Rodriguez Larrota"
        },
        {
            "_id": "3590",
            "Agente": "3590",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "jsanchez@aspel.com.mx",
            "Lider": "Brandon Enrique Gomez Rosales",
            "Nombre": "Jessica Sanchez Torres"
        },
        {
            "_id": "803708",
            "Agente": "803708",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "more803708@siigo.com",
            "Lider": "Walter Antonio Muñoz Antepara",
            "Nombre": "Yeraldine Lizeth Moreno Alba"
        },
        {
            "_id": "803664",
            "Agente": "803664",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pena803664@siigo.com",
            "Lider": "Diana Mercedes Moncada Peraza",
            "Nombre": "Karen Alejandra Penagos Zamudio"
        },
        {
            "_id": "801866",
            "Agente": "801866",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "vale801866@siigo.com",
            "Lider": "Andrea Carolina Cabrera Gutierrez",
            "Nombre": "Kristian David Valero Salamanca"
        },
        {
            "_id": "109985",
            "Agente": "109985",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "rive109985@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Alejandra Maria Rivera Bustamante"
        },
        {
            "_id": "111949",
            "Agente": "111949",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "manr111949@siigo.com",
            "Lider": "Juan Sebastian Molina Linares",
            "Nombre": "Maria Ximena Manrique Isaziga"
        },
        {
            "_id": "800346",
            "Agente": "800346",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "urib800346@siigo.com",
            "Lider": "Nestor Oswaldo Becerra Prieto",
            "Nombre": "Yessica Paola Uribe Castaño"
        },
        {
            "_id": "801772",
            "Agente": "801772",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "mart801772@siigo.com",
            "Lider": "Claudia Cecilia Gonzalez Clavijo",
            "Nombre": "Andrea Mireya Martinez Riaño"
        },
        {
            "_id": "802532",
            "Agente": "802532",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vela802532@siigo.com",
            "Lider": "Juan Pablo Carreño Patiño",
            "Nombre": "Leidy Tatiana Vela Molina"
        },
        {
            "_id": "802898",
            "Agente": "802898",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "pach802898@siigo.com",
            "Lider": "Alcides Martin Salas Orozco",
            "Nombre": "Brahyan Matias Pacheco Abeldaño"
        },
        {
            "_id": "4071",
            "Agente": "4071",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": null,
            "Lider": "Hector Orlando Bogoya Aguilar",
            "Nombre": "Elsa Eugenia Gomez Gonzalez"
        },
        {
            "_id": "803759",
            "Agente": "803759",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "vale803759@siigo.com",
            "Lider": "Nicolas Cifuentes Echeverry",
            "Nombre": "Luisa Maria Valencia Muñoz"
        },
        {
            "_id": "800332",
            "Agente": "800332",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pere800332@siigo.com",
            "Lider": "Cristian Camilo Hernandez Gonzalez",
            "Nombre": "Yadira Perez Ballesteros"
        },
        {
            "_id": "801835",
            "Agente": "801835",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gall801835@siigo.com",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre": "Karoll Dayanna Gallego Preciado"
        },
        {
            "_id": "2246",
            "Agente": "2246",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "acalderon@aspel.com.mx",
            "Lider": "Alma Rosa Romero Garcia",
            "Nombre": "Abigail Guevara Calderon"
        },
        {
            "_id": "802369",
            "Agente": "802369",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "torr802369@siigo.com",
            "Lider": "William Andres Reyes Silva",
            "Nombre": "Diana Patricia Torres Retavisca"
        },
        {
            "_id": "800392",
            "Agente": "800392",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "zapa800392@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Sara Yinneth Zapata Velez"
        },
        {
            "_id": "2159",
            "Agente": "2159",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "rgodinez@aspel.com.mx",
            "Lider": "Mercy Karla Cancino Mendez",
            "Nombre": "Rodrigo Sanchez Godinez"
        },
        {
            "_id": "800399",
            "Agente": "800399",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rose800399@siigo.com",
            "Lider": "Jhon Emmanuel Zuñiga Paredes",
            "Nombre": "David Rosero Calle"
        },
        {
            "_id": "800493",
            "Agente": "800493",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "berm800493@siigo.com",
            "Lider": "Andres Fabian Gutierrez Gutierrez",
            "Nombre": "Owen Steven Bermudez Arias"
        },
        {
            "_id": "800521",
            "Agente": "800521",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mera800521@siigo.com",
            "Lider": "Cristian Camilo Hernandez Gonzalez",
            "Nombre": "Katherine Lisseth Mera Ramos"
        },
        {
            "_id": "803740",
            "Agente": "803740",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cald803740@siigo.com",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre": "Yulys Del Carmen Calderon Perez"
        },
        {
            "_id": "800371",
            "Agente": "800371",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "torr800371@siigo.com",
            "Lider": "John Alexander Benavides Cobos",
            "Nombre": "Johanna Patricia Torres Quintero"
        },
        {
            "_id": "112496",
            "Agente": "112496",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "fons112496@siigo.com",
            "Lider": "Sandra Catalina Suarez Castillo",
            "Nombre": "Jenny Esperanza Fonseca Joya"
        },
        {
            "_id": "3463",
            "Agente": "3463",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "mgcamarena@aspel.com.mx",
            "Lider": "Jose De Jesus De Aquino Curiel",
            "Nombre": "Marlene Guadalupe Gonzalez Camarena"
        },
        {
            "_id": "112012",
            "Agente": "112012",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "font112012@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Kelly Jojana Fontecha Santamaria"
        },
        {
            "_id": "110298",
            "Agente": "110298",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "vill110298@siigo.com",
            "Lider": "Juan Carlos Buitrago Rios",
            "Nombre": "Maria Jose Villegas Vidal"
        },
        {
            "_id": "1208",
            "Agente": "1208",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "mraguilar@aspel.com.mx",
            "Lider": "Maria Jose Vargas Braga",
            "Nombre": "Moises Raul Aguilar Puc"
        },
        {
            "_id": "111848",
            "Agente": "111848",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "olav111848@siigo.com",
            "Lider": "Eliana Maria Fajardo Prada",
            "Nombre": "Lady Johanna Olave Vargas"
        },
        {
            "_id": "110042",
            "Agente": "110042",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "cabr110042@siigo.com",
            "Lider": "Frankgel Ribely Machado Pantoja",
            "Nombre": "Armando Cecilio Cabrera Recio"
        },
        {
            "_id": "801224",
            "Agente": "801224",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "aran801224@siigo.com",
            "Lider": "Andrea Zuluaga Uribe",
            "Nombre": "Pedro Felipe Arana Lastra"
        },
        {
            "_id": "803618",
            "Agente": "803618",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ramo803618@siigo.com",
            "Lider": "Mario Fernando Nuñez Santos",
            "Nombre": "Daniela Patricia Ramos Acosta"
        },
        {
            "_id": "803607",
            "Agente": "803607",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "jime803607@siigo.com",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre": "Keidy Talia Jimenez Suarez"
        },
        {
            "_id": "803523",
            "Agente": "803523",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cabr803523@siigo.com",
            "Lider": "Eliana Maria Fajardo Prada",
            "Nombre": "Heivert Andres Cabrera Gamero"
        },
        {
            "_id": "111711",
            "Agente": "111711",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "guti111711@siigo.com",
            "Lider": "John Mario Perez Diaz",
            "Nombre": "Daniel Alejandro Gutierrez Gutierrez"
        },
        {
            "_id": "802330",
            "Agente": "802330",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "sanc802330@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Andy Fabricio Sanchez Paiva"
        },
        {
            "_id": "4123",
            "Agente": "4123",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "orti4123@siigo.com",
            "Lider": "Felipe Ricardo Reyes Lega",
            "Nombre": "Ana Rita Ortiz Herrera"
        },
        {
            "_id": "112515",
            "Agente": "112515",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mala112515@siigo.com",
            "Lider": "Adriana Elizabeth Giron Parra",
            "Nombre": "Maria Liliana Malagon Valero"
        },
        {
            "_id": "802704",
            "Agente": "802704",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "rino802704@siigo.com",
            "Lider": "Luis Ignacio Ferreira Duarte",
            "Nombre": "Luis Ignacio Riñon Martinez"
        },
        {
            "_id": "804570",
            "Agente": "804570",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gome804570@siigo.com",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre": "Katerin Alejandra Gomez Valero"
        },
        {
            "_id": "800370",
            "Agente": "800370",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "sanc800370@siigo.com",
            "Lider": "Magda de Jesús Becerra Castañeda",
            "Nombre": "Zahory Joulin Sanchez Zambrano"
        },
        {
            "_id": "803817",
            "Agente": "803817",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "aran803817@siigo.com",
            "Lider": "Claudia Cecilia Gonzalez Clavijo",
            "Nombre": "Paola Andrea Arango Ospina"
        },
        {
            "_id": "802197",
            "Agente": "802197",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "card802197@siigo.com",
            "Lider": "Diana Carolina Perez Cerinza",
            "Nombre": "Sebastian Cardona Perez"
        },
        {
            "_id": "112387",
            "Agente": "112387",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "hern112387@siigo.com",
            "Lider": "John Mario Perez Diaz",
            "Nombre": "Carlos Alberto Hernandez Mejia"
        },
        {
            "_id": "800624",
            "Agente": "800624",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "amay800624@siigo.com",
            "Lider": "Yessica Yulieth Quintero Martinez",
            "Nombre": "Juan Francisco Amaya Alcivar"
        },
        {
            "_id": "3084",
            "Agente": "3084",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ptenorio@aspel.com.mx",
            "Lider": "Armando Uriel Lopez Vazquez",
            "Nombre": "Pedro Guadalupe Tenorio Jimenez"
        },
        {
            "_id": "32256",
            "Agente": "32256",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "Torr32256@siigo.com",
            "Lider": "Juan Jose Correa Acosta",
            "Nombre": "Luz Angelica Torres Quintero"
        },
        {
            "_id": "802518",
            "Agente": "802518",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ramo802518@siigo.com",
            "Lider": "Juan Pablo Carreño Patiño",
            "Nombre": "Orleidys Paola Ramos Salgado"
        },
        {
            "_id": "801222",
            "Agente": "801222",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "aria801222@siigo.com",
            "Lider": "Jonathan Uribe Mendoza",
            "Nombre": "Diego Mateo Arias Vargas"
        },
        {
            "_id": "804012",
            "Agente": "804012",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "suaz804012@siigo.com",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre": "Luisa Fernanda Suazo Tamayo"
        },
        {
            "_id": "3274",
            "Agente": "3274",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "isuarez@aspel.com.mx",
            "Lider": "Luis Alejandro Salgado Vergara",
            "Nombre": "Ingrid Eunice Alcantara Suarez"
        },
        {
            "_id": "800655",
            "Agente": "800655",
            "Area": "Finance & Administration",
            "Division": "Siigo Ecuador",
            "Email": "cana800655@siigo.com",
            "Lider": "Sandy Yurley Galeano Lara",
            "Nombre": "Maria Lucia Canales Parrales"
        },
        {
            "_id": "3423",
            "Agente": "3423",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "lhernandez@aspel.com.mx",
            "Lider": "Ivan Alejandro Roa Franco",
            "Nombre": "Luis Alexander Hernandez Escorcia"
        },
        {
            "_id": "800285",
            "Agente": "800285",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rome800285@siigo.com",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre": "Cristian Camilo Romero Acevedo"
        },
        {
            "_id": "800453",
            "Agente": "800453",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "caro800453@siigo.com",
            "Lider": "Carlos Arturo Huertas Salgado",
            "Nombre": "Luisa Fernanda Caro Camargo"
        },
        {
            "_id": "3424",
            "Agente": "3424",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "lruano@aspel.com.mx",
            "Lider": "Jose Luis Carranza Valdes",
            "Nombre": "Leonardo Ruano Hernandez"
        },
        {
            "_id": "800181",
            "Agente": "800181",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "lara800181@siigo.com",
            "Lider": "Daniel Eduardo Pinto Cepeda",
            "Nombre": "Johan Leandro Lara Ibañez"
        },
        {
            "_id": "111907",
            "Agente": "111907",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "robl111907@siigo.com",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre": "Arlen Rafael Robles Mejia"
        },
        {
            "_id": "110259",
            "Agente": "110259",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "loza110259@siigo.com",
            "Lider": "Marcos Damian Lizarraga Elizalde",
            "Nombre": "Omar Alexander Lozano Rubio"
        },
        {
            "_id": "800239",
            "Agente": "800239",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "muno800239@siigo.com",
            "Lider": "Wendy Dayana Rojas Rojas",
            "Nombre": "Mallory Julieth Muñoz Gutierrez"
        },
        {
            "_id": "800004",
            "Agente": "800004",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "vasq800004@siigo.com",
            "Lider": "Daniel Eduardo Pinto Cepeda",
            "Nombre": "Luisa Alejandra Vasquez Arias"
        },
        {
            "_id": "197198",
            "Agente": "197198",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "Herr197198@siigo.com",
            "Lider": "Oscar Francisco Plaza Giler",
            "Nombre": "Harold Herrera Chavarro"
        },
        {
            "_id": "800023",
            "Agente": "800023",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "alva800023@siigo.com",
            "Lider": "Pedro Felipe Arana Lastra",
            "Nombre": "Carlos Roberto Alvarado Mancera"
        },
        {
            "_id": "111890",
            "Agente": "111890",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "osor111890@siigo.com",
            "Lider": "Hollman Antonio Aponte Quiroga",
            "Nombre": "Gloria Estela Osorio Montoya"
        },
        {
            "_id": "803747",
            "Agente": "803747",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ahum803747@siigo.com",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre": "Cindy Margarita Ahumada Bustamante"
        },
        {
            "_id": "802328",
            "Agente": "802328",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mora802328@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Jasbleidy Morales Borja"
        },
        {
            "_id": "107898",
            "Agente": "107898",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "vill107898@siigo.com",
            "Lider": "Jimena Marcela Prieto Bohorquez",
            "Nombre": "Yulibeth Esther Villalba Altamar"
        },
        {
            "_id": "111763",
            "Agente": "111763",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "nava111763@siigo.com",
            "Lider": "Santiago Jose Porras Brieva",
            "Nombre": "Laura Valentina Navarro Olaya"
        },
        {
            "_id": "112199",
            "Agente": "112199",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "roja112199@siigo.com",
            "Lider": "Andrea Catalina Vega Gomez",
            "Nombre": "Carlos Andres Rojas Montaño"
        },
        {
            "_id": "802600",
            "Agente": "802600",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pabo802600@siigo.com",
            "Lider": "Angel Alfonso Arciniegas Guerrero",
            "Nombre": "Luis Fernando Pabon Torres"
        },
        {
            "_id": "801852",
            "Agente": "801852",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "beni801852@siigo.com",
            "Lider": "Diana Sofia Ramos Castillo",
            "Nombre": "Maria Luisa Benitez Tapia"
        },
        {
            "_id": "802221",
            "Agente": "802221",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "ladi802221@siigo.com",
            "Lider": "Joan Sebastian Rodriguez 0",
            "Nombre": "Daniel Sebastian Ladino Cortes"
        },
        {
            "_id": "802680",
            "Agente": "802680",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gome802680@siigo.com",
            "Lider": "Sory Yaqueline Abello Polo",
            "Nombre": "Diana Mayerli Gomez Esparza"
        },
        {
            "_id": "800618",
            "Agente": "800618",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "chav800618@siigo.com",
            "Lider": "Juan Francisco Lavayen Iñamagua",
            "Nombre": "Angel Camilo Chavez Moreno"
        },
        {
            "_id": "802900",
            "Agente": "802900",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "sali802900@siigo.com",
            "Lider": "Angie Lorena Perez Espitia",
            "Nombre": "Jolman Ferley Salinas Miranda"
        },
        {
            "_id": "804574",
            "Agente": "804574",
            "Area": "Finance & Administration",
            "Division": "Siigo Uruguay",
            "Email": "mode804574@siigo.com",
            "Lider": "Giselda Keymetlian 0",
            "Nombre": "Micaela Lourdes Modernell Moliterno"
        },
        {
            "_id": "800635",
            "Agente": "800635",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "vall800635@siigo.com",
            "Lider": "Angel Andres Pineda Alvarado",
            "Nombre": "Steffany Mabell Vallejo Castro"
        },
        {
            "_id": "14",
            "Agente": "14",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "avargas@aspel.com.mx",
            "Lider": "Juan Mauricio Jimenez Espinoza",
            "Nombre": "Jose Antonio Vargas Uribe"
        },
        {
            "_id": "262268",
            "Agente": "262268",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "Vale262268@siigo.com",
            "Lider": "Miguel Oswaldo Velasquez Fragiel",
            "Nombre": "James Alberto Valencia Caicedo"
        },
        {
            "_id": "20592",
            "Agente": "20592",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "puli20592@siigo.com",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre": "Katalina Pulido Quimbayo"
        },
        {
            "_id": "20972",
            "Agente": "20972",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "Sanc20972@siigo.com",
            "Lider": "Luz Mery Patino Infante",
            "Nombre": "Luz Andrea Sanchez Chacon"
        },
        {
            "_id": "3323",
            "Agente": "3323",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "knoriega@aspel.com.mx",
            "Lider": "Bismark Ivan Rios Campos",
            "Nombre": "Karla Lizet Noriega Perez"
        },
        {
            "_id": "2575",
            "Agente": "2575",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ajimenez@aspel.com.mx",
            "Lider": "Armando Uriel Lopez Vazquez",
            "Nombre": "Marco Antonio Jimenez Bernal"
        },
        {
            "_id": "3341",
            "Agente": "3341",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "gvilleda@aspel.com.mx",
            "Lider": "Adriana Rodriguez Castro",
            "Nombre": "Willy Gonzalez Villeda"
        },
        {
            "_id": "2152",
            "Agente": "2152",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "esanchezr@aspel.com.mx",
            "Lider": "Pedro Arturo Rico Martinez",
            "Nombre": "Edgar Sanchez Rodriguez"
        },
        {
            "_id": "803920",
            "Agente": "803920",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "card803920@siigo.com",
            "Lider": "Cristian Andres Collazos Ruiz",
            "Nombre": "Joan Sebastian Cardona Gonzalez"
        },
        {
            "_id": "705",
            "Agente": "705",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "maramirez@aspel.com.mx",
            "Lider": "Eliseo Lopez Cruz",
            "Nombre": "Manuel Ramirez Alvarez"
        },
        {
            "_id": "804529",
            "Agente": "804529",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sarr804529@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Alexander Sarrazola Arango"
        },
        {
            "_id": "111805",
            "Agente": "111805",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "boho111805@siigo.com",
            "Lider": "Edison Arley Plaza Marin",
            "Nombre": "Francisco Jose Bohorquez Torres"
        },
        {
            "_id": "800683",
            "Agente": "800683",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "lina800683@siigo.com",
            "Lider": "Juan Pablo Henao Muñoz",
            "Nombre": "Diana Carolina Linares Gonzalez"
        },
        {
            "_id": "22242",
            "Agente": "22242",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Hern22242@siigo.com",
            "Lider": "Magda Lilian Leal Rozo",
            "Nombre": "Vicky Alejandra Hernandez Mendez"
        },
        {
            "_id": "802474",
            "Agente": "802474",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "more802474@siigo.com",
            "Lider": "Andrea Catalina Vega Gomez",
            "Nombre": "David Alexander Moreno Paipa"
        },
        {
            "_id": "800659",
            "Agente": "800659",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "ponc800659@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Rafael Enrique Ponce Medina"
        },
        {
            "_id": "3410",
            "Agente": "3410",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "lbaltazar@aspel.com.mx",
            "Lider": "Jorge Alfredo Huesca Martinez",
            "Nombre": "Liz Fernanda Baltazar Garcia"
        },
        {
            "_id": "2292",
            "Agente": "2292",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "aalvarez@aspel.com.mx",
            "Lider": "Armando Mariano Cirilo",
            "Nombre": "Arturo Alvarez Martinez"
        },
        {
            "_id": "803876",
            "Agente": "803876",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gome803876@siigo.com",
            "Lider": "Juliana Castro Diaz",
            "Nombre": "Vanessa Maria Gomez Mazo"
        },
        {
            "_id": "111892",
            "Agente": "111892",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "tria111892@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Andrea Del Pilar Triana Muñoz"
        },
        {
            "_id": "4021",
            "Agente": "4021",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "Bece4021@siigo.com",
            "Lider": "Manuel Enrique Ramirez Rodriguez",
            "Nombre": "Hector Enrique Becerra Perez"
        },
        {
            "_id": "803917",
            "Agente": "803917",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "lope803917@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Erika Paola Lopez Cative"
        },
        {
            "_id": "4158",
            "Agente": "4158",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "Rodr4158@siigo.com",
            "Lider": "Carlos German Lopez Murcia",
            "Nombre": "Jarbin Dadey Rodriguez Peña"
        },
        {
            "_id": "802925",
            "Agente": "802925",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "jara802925@siigo.com",
            "Lider": "Gloria Estela Osorio Montoya",
            "Nombre": "Narda Viviana Jaramillo Peña"
        },
        {
            "_id": "803687",
            "Agente": "803687",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cort803687@siigo.com",
            "Lider": "Juliana Castro Diaz",
            "Nombre": "Paola Andrea Cortes Vega"
        },
        {
            "_id": "803303",
            "Agente": "803303",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gavi803303@siigo.com",
            "Lider": "Andres Felipe Baron Ballen",
            "Nombre": "Leidy Johana Gaviria Sanchez"
        },
        {
            "_id": "802935",
            "Agente": "802935",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cast802935@siigo.com",
            "Lider": "Diana Sofia Ramos Castillo",
            "Nombre": "Erick Saaid Castellanos Castro"
        },
        {
            "_id": "802362",
            "Agente": "802362",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mart802362@siigo.com",
            "Lider": "John Mario Perez Diaz",
            "Nombre": "Lourdes Vanessa Martinez Cruz"
        },
        {
            "_id": "2703",
            "Agente": "2703",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "hgonzalez@aspel.com.mx",
            "Lider": "Fernando Contreras Ocaña",
            "Nombre": "Heriberto Fernando A Gonzalez Solis"
        },
        {
            "_id": "1598",
            "Agente": "1598",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "arendon@aspel.com.mx",
            "Lider": "Fernando Contreras Ocaña",
            "Nombre": "Abraham Octavio Rendon Cruz"
        },
        {
            "_id": "2141",
            "Agente": "2141",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "lsalgado@aspel.com.mx",
            "Lider": "Vicente Estrada Sanchez",
            "Nombre": "Luis Alejandro Salgado Vergara"
        },
        {
            "_id": "28176",
            "Agente": "28176",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "Roja28176@siigo.com",
            "Lider": "Sandra Catalina Suarez Castillo",
            "Nombre": "Fany Yaneht Rojas Trujillo"
        },
        {
            "_id": "800619",
            "Agente": "800619",
            "Area": "Marketing",
            "Division": "Siigo Ecuador",
            "Email": "acos800619@siigo.com",
            "Lider": "Juan Carlos Valdes Martin",
            "Nombre": "Nelson Juan Carlos Acosta Drouet"
        },
        {
            "_id": "800182",
            "Agente": "800182",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "muno800182@siigo.com",
            "Lider": "Carlos Eduardo Sanchez Castaño",
            "Nombre": "Valentina Muñoz Males"
        },
        {
            "_id": "801773",
            "Agente": "801773",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "quin801773@siigo.com",
            "Lider": "Cesar Augusto Torres Benavides",
            "Nombre": "Sebastian Quintero Marin"
        },
        {
            "_id": "33953",
            "Agente": "33953",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "garc33953@siigo.com",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre": "Martha Isabel Garcia Claros"
        },
        {
            "_id": "1864",
            "Agente": "1864",
            "Area": "Product",
            "Division": "Siigo Mexico",
            "Email": "emoreno@aspel.com.mx",
            "Lider": "Alfredo Carlos Huerta Ayala",
            "Nombre": "Elizabeth Moreno Orozco"
        },
        {
            "_id": "800967",
            "Agente": "800967",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "seda800967@siigo.com",
            "Lider": "Claudia Cecilia Gonzalez Clavijo",
            "Nombre": "Maria Camila Sedano Fuentes"
        },
        {
            "_id": "2388",
            "Agente": "2388",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "vsanchezm@aspel.com.mx",
            "Lider": "Maria Virginia Lopez Salazar",
            "Nombre": "Vianey Sanchez Martinez"
        },
        {
            "_id": "800367",
            "Agente": "800367",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "garz800367@siigo.com",
            "Lider": "Marisol Torres Hernandez",
            "Nombre": "Alejandra Garzon Samudio"
        },
        {
            "_id": "112050",
            "Agente": "112050",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "varg112050@siigo.com",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre": "Dilan Vargas Suaza"
        },
        {
            "_id": "802509",
            "Agente": "802509",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rest802509@siigo.com",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre": "Ariel De Jesus Restrepo Lambertino"
        },
        {
            "_id": "804145",
            "Agente": "804145",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "ball804145@siigo.com",
            "Lider": "Janna Elizabeth Barreto Castro",
            "Nombre": "Eduardo Isaac Ballesteros Muñoz"
        },
        {
            "_id": "2741",
            "Agente": "2741",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "raguilar@aspel.com.mx",
            "Lider": "Armando Uriel Lopez Vazquez",
            "Nombre": "Carlos Rodrigo Aguilar Lopez"
        },
        {
            "_id": "3377",
            "Agente": "3377",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "yaguilar@aspel.com.mx",
            "Lider": "Adriana Rodriguez Castro",
            "Nombre": "Yuridia Martinez Aguilar"
        },
        {
            "_id": "803885",
            "Agente": "803885",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mald803885@siigo.com",
            "Lider": "David Alfredo Sanchez Bastidas",
            "Nombre": "Gabriel Andres Maldonado Hernandez"
        },
        {
            "_id": "804048",
            "Agente": "804048",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "meji804048@siigo.com",
            "Lider": "Cristian Andres Collazos Ruiz",
            "Nombre": "Sebastian Mejia Serna"
        },
        {
            "_id": "112492",
            "Agente": "112492",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mosq112492@siigo.com",
            "Lider": "Daniel Eduardo Pinto Cepeda",
            "Nombre": "Erika Geraldine Mosquera Peña"
        },
        {
            "_id": "803774",
            "Agente": "803774",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "neva803774@siigo.com",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre": "Joselyn Maileth Nevado Lopez"
        },
        {
            "_id": "112121",
            "Agente": "112121",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "leon112121@siigo.com",
            "Lider": "Leida Adriana Rico Herrera",
            "Nombre": "Oscar Alejandro Leon Rojas"
        },
        {
            "_id": "803775",
            "Agente": "803775",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "reye803775@siigo.com",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre": "Johanna Isabel Reyes Vargas"
        },
        {
            "_id": "111877",
            "Agente": "111877",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pulg111877@siigo.com",
            "Lider": "Diana Maria Naranjo Mattheus",
            "Nombre": "Lina Maria Pulgarin Fonnegra"
        },
        {
            "_id": "22172",
            "Agente": "22172",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "Dura22172@siigo.com",
            "Lider": "Lina Maria Buitrago Herrera",
            "Nombre": "Arianis Josefina Duran Gutierrez"
        },
        {
            "_id": "2604",
            "Agente": "2604",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "rucan@aspel.com.mx",
            "Lider": "Abraham Franco Nares",
            "Nombre": "Ricardo Daniel Ucan Ucan"
        },
        {
            "_id": "11965",
            "Agente": "11965",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "nara11965@siigo.com",
            "Lider": "Liliana Patricia Giraldo Gomez",
            "Nombre": "Diana Maria Naranjo Mattheus"
        },
        {
            "_id": "804164",
            "Agente": "804164",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "gara804164@siigo.com",
            "Lider": "Laura Valentina Navarro Olaya",
            "Nombre": "Cindy Lorena Garay Robayo"
        },
        {
            "_id": "803486",
            "Agente": "803486",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rios803486@siigo.com",
            "Lider": "Jessica Paola Paez Quintero",
            "Nombre": "Maria Camila Rios Sanchez"
        },
        {
            "_id": "111961",
            "Agente": "111961",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "cast111961@siigo.com",
            "Lider": "James Alberto Valencia Caicedo",
            "Nombre": "Edwin Castañeda Olave"
        },
        {
            "_id": "802038",
            "Agente": "802038",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "petr802038@siigo.com",
            "Lider": "Sory Yaqueline Abello Polo",
            "Nombre": "Jose Alfredo Petro Martinez"
        },
        {
            "_id": "16038",
            "Agente": "16038",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "Nino16038@siigo.com",
            "Lider": "Hector Orlando Bogoya Aguilar",
            "Nombre": "Luz Mireya Nino Duran"
        },
        {
            "_id": "801256",
            "Agente": "801256",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "alme801256@siigo.com",
            "Lider": "David Julian Rojas Prado",
            "Nombre": "Alvaro Jose Almendrales Pabon"
        },
        {
            "_id": "804571",
            "Agente": "804571",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "hern804571@siigo.com",
            "Lider": "Diana Michelle Matamoros Del Rosario",
            "Nombre": "Eliana Carolina Hernandez Arango"
        },
        {
            "_id": "801199",
            "Agente": "801199",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "baqu801199@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Suanny Nicole Baquerizzo Puyol"
        },
        {
            "_id": "3211",
            "Agente": "3211",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "pvillanueva@aspel.com.mx",
            "Lider": "Marisela Yasmin Franco Cruz",
            "Nombre": "Pablo Cesar Arturo Villanueva Martinez"
        },
        {
            "_id": "802408",
            "Agente": "802408",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mart802408@siigo.com",
            "Lider": "John Mario Perez Diaz",
            "Nombre": "Darsy Mabel Martinez Pachon"
        },
        {
            "_id": "3279",
            "Agente": "3279",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jatorres@aspel.com.mx",
            "Lider": "Norma Florentina Quiñones Martin",
            "Nombre": "Javier Daniel Gonzalez Torres"
        },
        {
            "_id": "802620",
            "Agente": "802620",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "alba802620@siigo.com",
            "Lider": "Luis Ignacio Ferreira Duarte",
            "Nombre": "Victor Andres Albano Vallarino"
        },
        {
            "_id": "2178",
            "Agente": "2178",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "vvelasco@aspel.com.mx",
            "Lider": "Carlos German Lopez Murcia",
            "Nombre": "Luis Velasco Velasco"
        },
        {
            "_id": "801797",
            "Agente": "801797",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cerr801797@siigo.com",
            "Lider": "Luz Katerine Maya Mogollón",
            "Nombre": "Keyla Maritza Cerra Pacheco"
        },
        {
            "_id": "803661",
            "Agente": "803661",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "sanc803661@siigo.com",
            "Lider": "Sara Carolina Chica Lopez",
            "Nombre": "Maria Valentina Sanchez Cardenas"
        },
        {
            "_id": "1124",
            "Agente": "1124",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "pocampo@aspel.com.mx",
            "Lider": "Victor De Jesus Martinez Bolio",
            "Nombre": "Pedro Miguel Ocampo Ix"
        },
        {
            "_id": "802333",
            "Agente": "802333",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "apon802333@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Ana Maria Aponte Navarrete"
        },
        {
            "_id": "802400",
            "Agente": "802400",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "diaz802400@siigo.com",
            "Lider": "Alison Anaid Chica Ezeta",
            "Nombre": "Laura Katherine Diaz Mogollon"
        },
        {
            "_id": "802464",
            "Agente": "802464",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "diaz802464@siigo.com",
            "Lider": "Mario Fernando Nuñez Santos",
            "Nombre": "Dayana Vanesa Diaz Mercado"
        },
        {
            "_id": "802583",
            "Agente": "802583",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "pint802583@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Anthony Fabrizzio Pinto Macias"
        },
        {
            "_id": "197199",
            "Agente": "197199",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "Guzm197199@siigo.com",
            "Lider": "Mileny Yelissa Durán Mendoza",
            "Nombre": "Diana Marcela Guzman Caamaño"
        },
        {
            "_id": "803731",
            "Agente": "803731",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "anga803731@siigo.com",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre": "Lisleidy Angarita Prada"
        },
        {
            "_id": "802724",
            "Agente": "802724",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "avil802724@siigo.com",
            "Lider": "Aleida Bibiana Garcia Suarez",
            "Nombre": "July Andrea Avila Guaquez"
        },
        {
            "_id": "800629",
            "Agente": "800629",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "avil800629@siigo.com",
            "Lider": "Walter Antonio Muñoz Antepara",
            "Nombre": "Vicente Roberto Aviles Cardenas"
        },
        {
            "_id": "802040",
            "Agente": "802040",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "huer802040@siigo.com",
            "Lider": "Jenny Paola Mendez 0",
            "Nombre": "Nelson Makensy Huertas Jimenes"
        },
        {
            "_id": "800177",
            "Agente": "800177",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ayal800177@siigo.com",
            "Lider": "Juliana Castro Diaz",
            "Nombre": "Juan Sebastian Ayala Pirazan"
        },
        {
            "_id": "800476",
            "Agente": "800476",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ariz800476@siigo.com",
            "Lider": "Diana Patricia Villarraga Forero",
            "Nombre": "Nathalia Carolina Ariza Fernandez"
        },
        {
            "_id": "33371",
            "Agente": "33371",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "boti33371@siigo.com",
            "Lider": "Leida Adriana Rico Herrera",
            "Nombre": "Juan Camilo Botia Suarez"
        },
        {
            "_id": "801749",
            "Agente": "801749",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "camp801749@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Michelle Nydia Campaña Haro"
        },
        {
            "_id": "802721",
            "Agente": "802721",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "mart802721@siigo.com",
            "Lider": "Juan Esteban Restrepo Cardona",
            "Nombre": "Jose Eduardo Martinez Alvarez"
        },
        {
            "_id": "802104",
            "Agente": "802104",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "echa802104@siigo.com",
            "Lider": "Diana Sofia Ramos Castillo",
            "Nombre": "Arantxa Echavez Molina"
        },
        {
            "_id": "800660",
            "Agente": "800660",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "pala800660@siigo.com",
            "Lider": "Angel Andres Pineda Alvarado",
            "Nombre": "Miguel Alexander Palacios Guzman"
        },
        {
            "_id": "801574",
            "Agente": "801574",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "oliv801574@siigo.com",
            "Lider": "Diana Michelle Matamoros Del Rosario",
            "Nombre": "Jorge Antonio Olivos Cadena"
        },
        {
            "_id": "801676",
            "Agente": "801676",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "guti801676@siigo.com",
            "Lider": "Juan Pablo Carreño Patiño",
            "Nombre": "Shayla Alejandra Gutierrez Ledesma"
        },
        {
            "_id": "801148",
            "Agente": "801148",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "mari801148@siigo.com",
            "Lider": "Gustavo Adolfo Villada Tapias",
            "Nombre": "Daniel Felipe Marin Zuñiga"
        },
        {
            "_id": "801981",
            "Agente": "801981",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sana801981@siigo.com",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre": "Jessica Andrea Sanabria Gerena"
        },
        {
            "_id": "111450",
            "Agente": "111450",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "avil111450@siigo.com",
            "Lider": "Jarbin Dadey Rodriguez Peña",
            "Nombre": "Erika Mildred Avila Olaya"
        },
        {
            "_id": "111548",
            "Agente": "111548",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "card111548@siigo.com",
            "Lider": "Juan Carlos Buitrago Rios",
            "Nombre": "John Alexander Cardona Hurtado"
        },
        {
            "_id": "802267",
            "Agente": "802267",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "gall802267@siigo.com",
            "Lider": "Juan Sebastian Cifuentes Guiffo",
            "Nombre": "Juan Diego Gallego Mira"
        },
        {
            "_id": "804007",
            "Agente": "804007",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "casa804007@siigo.com",
            "Lider": "David Rosero Calle",
            "Nombre": "Sergio Andres Casas Neira"
        },
        {
            "_id": "801954",
            "Agente": "801954",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "gome801954@siigo.com",
            "Lider": "Ivan Elias Jusid Machado",
            "Nombre": "Nicolas Gomez Tabarez"
        },
        {
            "_id": "802692",
            "Agente": "802692",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "padi802692@siigo.com",
            "Lider": "Yesika Viviana Garcia Niño",
            "Nombre": "Nayelis Del Camen Padilla Peralta"
        },
        {
            "_id": "2000",
            "Agente": "2000",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jmoo@aspel.com.mx",
            "Lider": "Luis Alberto Flores Castillo",
            "Nombre": "Johny Llubiel Moo Can"
        },
        {
            "_id": "802353",
            "Agente": "802353",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "zamb802353@siigo.com",
            "Lider": "Natalia Andrea Contreras Mejia",
            "Nombre": "Andrea Cristina Zambrano Lasso"
        },
        {
            "_id": "3290",
            "Agente": "3290",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "apasos@aspel.com.mx",
            "Lider": "Allam Francisco Medina Yama",
            "Nombre": "David Alfonso Pasos Canche"
        },
        {
            "_id": "2203",
            "Agente": "2203",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "cmoguel@aspel.com.mx",
            "Lider": "Carmen Del Sagrario Ku Yah",
            "Nombre": "Carlos David Moguel Pat"
        },
        {
            "_id": "803260",
            "Agente": "803260",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "vale803260@siigo.com",
            "Lider": "Miguel Oswaldo Velasquez Fragiel",
            "Nombre": "Daniela Valencia Guzman"
        },
        {
            "_id": "801585",
            "Agente": "801585",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mart801585@siigo.com",
            "Lider": "Nataly Carolina Gonzalez Sandoval",
            "Nombre": "Eider Martinez Cruz"
        },
        {
            "_id": "804149",
            "Agente": "804149",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "reye804149@siigo.com",
            "Lider": "Gerardo Enrique Aldecua Matamoros",
            "Nombre": "Ruben Orlando Reyes Gonzalez"
        },
        {
            "_id": "1916",
            "Agente": "1916",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "gmorales@aspel.com.mx",
            "Lider": "María de Jesús Pérez Sanabria",
            "Nombre": "Gabriela Morales De la Paz"
        },
        {
            "_id": "803499",
            "Agente": "803499",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "bena803499@siigo.com",
            "Lider": "Jessica Paola Paez Quintero",
            "Nombre": "Diana Marcela Benavides Franco"
        },
        {
            "_id": "803433",
            "Agente": "803433",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "belt803433@siigo.com",
            "Lider": "Ivan Dario Corchuelo Castro",
            "Nombre": "Carlos Alberto Beltran Melo"
        },
        {
            "_id": "801868",
            "Agente": "801868",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "guev801868@siigo.com",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre": "Bibiana Yaneth Guevara Cubillos"
        },
        {
            "_id": "111958",
            "Agente": "111958",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gonz111958@siigo.com",
            "Lider": "Juan Sebastian Molina Linares",
            "Nombre": "Nataly Carolina Gonzalez Sandoval"
        },
        {
            "_id": "802265",
            "Agente": "802265",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gall802265@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Maribel Gallego Estrada"
        },
        {
            "_id": "801526",
            "Agente": "801526",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "merc801526@siigo.com",
            "Lider": "Kristein Johan Ordoñez Lopez",
            "Nombre": "Body Jhon Mercado Cuadrado"
        },
        {
            "_id": "1517",
            "Agente": "1517",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jrejon@aspel.com.mx",
            "Lider": "Ady Natividad Cob Poot",
            "Nombre": "Jose Ivan Medina Rejon"
        },
        {
            "_id": "800607",
            "Agente": "800607",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "prad800607@siigo.com",
            "Lider": "Angel Andres Pineda Alvarado",
            "Nombre": "Jeffrey Gabriel Prado Reyes"
        },
        {
            "_id": "800240",
            "Agente": "800240",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "mera800240@siigo.com",
            "Lider": "Wendy Dayana Rojas Rojas",
            "Nombre": "Miller Andres Mera Inga"
        },
        {
            "_id": "802128",
            "Agente": "802128",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "barr802128@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Hugo Alfonso Barros Bolaño"
        },
        {
            "_id": "801948",
            "Agente": "801948",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "para801948@siigo.com",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre": "Elsy Tatiana Parada Basabe"
        },
        {
            "_id": "800512",
            "Agente": "800512",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "boli800512@siigo.com",
            "Lider": "Lida Katerine Puentes Navarro",
            "Nombre": "Julieth Daniela Bolivar Guzman"
        },
        {
            "_id": "801962",
            "Agente": "801962",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "olar801962@siigo.com",
            "Lider": "Yeimy Milena Bustos Gutierrez",
            "Nombre": "Karol Stepfania Olarte Roa"
        },
        {
            "_id": "112095",
            "Agente": "112095",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "herr112095@siigo.com",
            "Lider": "Yamid Ramiro Quintero Montaño",
            "Nombre": "Angie Paola Herran Arias"
        },
        {
            "_id": "800964",
            "Agente": "800964",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "gonz800964@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Lilia Johana Gonzalez Parrado"
        },
        {
            "_id": "803192",
            "Agente": "803192",
            "Area": "Finance & Administration",
            "Division": "Siigo Uruguay",
            "Email": "char803192@siigo.com",
            "Lider": "Giselda Keymetlian 0",
            "Nombre": "Helen Noemi Charbonnier Leal"
        },
        {
            "_id": "803699",
            "Agente": "803699",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mont803699@siigo.com",
            "Lider": "Leida Adriana Rico Herrera",
            "Nombre": "Eduin Montoya Barbosa"
        },
        {
            "_id": "800141",
            "Agente": "800141",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "rozo800141@siigo.com",
            "Lider": "Ana Rita Ortiz Herrera",
            "Nombre": "Cristian Armando Rozo Bermudez"
        },
        {
            "_id": "800509",
            "Agente": "800509",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "pinz800509@siigo.com",
            "Lider": "Claudia Marcela Cano Forero",
            "Nombre": "July Andrea Pinzon Rodriguez"
        },
        {
            "_id": "63",
            "Agente": "63",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "sperez@aspel.com.mx",
            "Lider": "Maria Jose Vargas Braga",
            "Nombre": "Seidy Guadalupe Perez 0"
        },
        {
            "_id": "2004",
            "Agente": "2004",
            "Area": "Product",
            "Division": "Siigo Mexico",
            "Email": "mtorresh@aspel.com.mx",
            "Lider": "Mileny Yelissa Durán Mendoza",
            "Nombre": "Marisol Torres Hernandez"
        },
        {
            "_id": "803283",
            "Agente": "803283",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "sala803283@siigo.com",
            "Lider": "Emre Pehlivan 0",
            "Nombre": "Marcela Salazar Acevedo"
        },
        {
            "_id": "803374",
            "Agente": "803374",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "medi803374@siigo.com",
            "Lider": "Milady Andrea Guzman Garcia",
            "Nombre": "Guillermo Alberto Medina Garces"
        },
        {
            "_id": "802438",
            "Agente": "802438",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "tovi802438@siigo.com",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre": "Angie Carolina Tovio Machado"
        },
        {
            "_id": "803496",
            "Agente": "803496",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "bayo803496@siigo.com",
            "Lider": "Liliana Andrea Segura Martinez",
            "Nombre": "Nidia Marcela Bayona Sanchez"
        },
        {
            "_id": "2910",
            "Agente": "2910",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "cquintana@aspel.com.mx",
            "Lider": "Luis Alejandro Salgado Vergara",
            "Nombre": "Cristian Ivan Quintana Ramirez"
        },
        {
            "_id": "804187",
            "Agente": "804187",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rodr804187@siigo.com",
            "Lider": "Liliana Andrea Segura Martinez",
            "Nombre": "Eider Steven Rodriguez Barragan"
        },
        {
            "_id": "802638",
            "Agente": "802638",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "muno802638@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Pedro Andres Muñoz Rojas"
        },
        {
            "_id": "802446",
            "Agente": "802446",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mald802446@siigo.com",
            "Lider": "John Misael Martinez Montero",
            "Nombre": "Pablo Cesar Maldonado Betancourt"
        },
        {
            "_id": "802681",
            "Agente": "802681",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "belt802681@siigo.com",
            "Lider": "Yesika Viviana Garcia Niño",
            "Nombre": "Ingry Vanessa Beltran Medina"
        },
        {
            "_id": "803326",
            "Agente": "803326",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "sala803326@siigo.com",
            "Lider": "Gustavo Adolfo Espinosa Toledo",
            "Nombre": "Maria Alejandra Salazar Rodriguez"
        },
        {
            "_id": "804537",
            "Agente": "804537",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "sanc804537@siigo.com",
            "Lider": "Alexis Abraham Sanchez Portillo",
            "Nombre": "Maria Camila Sanchez Sanchez"
        },
        {
            "_id": "803724",
            "Agente": "803724",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "nune803724@siigo.com",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre": "Mariluz Nuñez Martinez"
        },
        {
            "_id": "802069",
            "Agente": "802069",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gira802069@siigo.com",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre": "Jose David Giraldo Otalvaro"
        },
        {
            "_id": "802380",
            "Agente": "802380",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "sena802380@siigo.com",
            "Lider": "Johana Catalina Peña ",
            "Nombre": "Wendy Lorraine Seña Torres"
        },
        {
            "_id": "802075",
            "Agente": "802075",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "torr802075@siigo.com",
            "Lider": "Juan Pablo Carreño Patiño",
            "Nombre": "Lilia Stella Torres Urrego"
        },
        {
            "_id": "802250",
            "Agente": "802250",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "diaz802250@siigo.com",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre": "Lina Marcela Diaz Mosquera"
        },
        {
            "_id": "804017",
            "Agente": "804017",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "leiv804017@siigo.com",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre": "Carolay Patricia Leiva Buelvas"
        },
        {
            "_id": "804408",
            "Agente": "804408",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "garc804408@siigo.com",
            "Lider": "Bismark Ivan Rios Campos",
            "Nombre": "Brigett Natalia Garcia Arevalo"
        },
        {
            "_id": "802005",
            "Agente": "802005",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bast802005@siigo.com",
            "Lider": "Yesika Viviana Garcia Niño",
            "Nombre": "Linda Mayerly Bastidas Charry"
        },
        {
            "_id": "800265",
            "Agente": "800265",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "torre800265@siigo.com",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre": "Juan Jose Torres Mora"
        },
        {
            "_id": "801849",
            "Agente": "801849",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "lass801849@siigo.com",
            "Lider": "Eddie Steven Hurtado Herrera",
            "Nombre": "Melanie Thalia Lasso Acosta"
        },
        {
            "_id": "801941",
            "Agente": "801941",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "quin801941@siigo.com",
            "Lider": "Angy Paola Umbacia Diaz",
            "Nombre": "Camilo Andres Quintana Hernandez"
        },
        {
            "_id": "801950",
            "Agente": "801950",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ayaz801950@siigo.com",
            "Lider": "Sory Yaqueline Abello Polo",
            "Nombre": "Eliana Ayazo Rodriguez"
        },
        {
            "_id": "802471",
            "Agente": "802471",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cruz802471@siigo.com",
            "Lider": "Angie Camila Diaz Escobar",
            "Nombre": "Paula Andrea Cruz Marin"
        },
        {
            "_id": "802314",
            "Agente": "802314",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "muno802314@siigo.com",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre": "Leidy Johana Muñoz Suarez"
        },
        {
            "_id": "3219",
            "Agente": "3219",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jalbarran@aspel.com.mx",
            "Lider": "Victor Hugo Huerta Lopez",
            "Nombre": "Jessica Yadira Albarran Sanchez"
        },
        {
            "_id": "802728",
            "Agente": "802728",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "mora802728@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Gina Gabriela Morales Montufar"
        },
        {
            "_id": "800984",
            "Agente": "800984",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "pare800984@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Guillermo Adrian Paredes Maquilon"
        },
        {
            "_id": "3578",
            "Agente": "3578",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "gramirez@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Gerardo Ramirez Gil"
        },
        {
            "_id": "801651",
            "Agente": "801651",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rome801651@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Cristhiam Camilo Romero Niño"
        },
        {
            "_id": "3142",
            "Agente": "3142",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "velizalde@aspel.com.mx",
            "Lider": "Brandon Enrique Gomez Rosales",
            "Nombre": "Viviana Ramirez Elizalde"
        },
        {
            "_id": "109906",
            "Agente": "109906",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "baez109906@siigo.com",
            "Lider": "John Mario Perez Diaz",
            "Nombre": "Yordy Yamir Baez Salcedo"
        },
        {
            "_id": "2269",
            "Agente": "2269",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "hgranados@aspel.com.mx",
            "Lider": "Rene Gerardo Paredes Alcantara",
            "Nombre": "Heriberto Granados Perez"
        },
        {
            "_id": "111689",
            "Agente": "111689",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "gome111689@siigo.com",
            "Lider": "Peter Anderson Vargas Grajales",
            "Nombre": "Jose Mario Gomez Rodriguez"
        },
        {
            "_id": "1954",
            "Agente": "1954",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "svenegas@aspel.com.mx",
            "Lider": "Belem Chavez Ruiz",
            "Nombre": "Emmanuel Samuel Venegas Ramos"
        },
        {
            "_id": "3332",
            "Agente": "3332",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "cvalencia@aspel.com.mx",
            "Lider": "Victor Hugo Huerta Lopez",
            "Nombre": "Carlos Alberto Velazquez Valencia"
        },
        {
            "_id": "803271",
            "Agente": "803271",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "jime803271@siigo.com",
            "Lider": "Zayda Smith Afanador Silva",
            "Nombre": "Edgar Andres Jimenez Rodriguez"
        },
        {
            "_id": "802564",
            "Agente": "802564",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "eraz802564@siigo.com",
            "Lider": "Jose Enrique Rangel Trujillo",
            "Nombre": "Alvaro Daniel Erazo Ramirez"
        },
        {
            "_id": "3539",
            "Agente": "3539",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "lramirez@aspel.com.mx",
            "Lider": "Arturo Alvarez Martinez",
            "Nombre": "Luis Arturo Ramirez Hernandez"
        },
        {
            "_id": "3074",
            "Agente": "3074",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "divasquez@aspel.com.mx",
            "Lider": "Marisol Vargas Cortes",
            "Nombre": "Diana Lisbeth Garcia Vasquez"
        },
        {
            "_id": "802191",
            "Agente": "802191",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "quin802191@siigo.com",
            "Lider": "Libia Amparo Osorio Rodriguez",
            "Nombre": "Yessica Yulieth Quintero Martinez"
        },
        {
            "_id": "804020",
            "Agente": "804020",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bald804020@siigo.com",
            "Lider": "Mario Fernando Nuñez Santos",
            "Nombre": "Yudis Maria Baldovino Hoyos"
        },
        {
            "_id": "802862",
            "Agente": "802862",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "lope802862@siigo.com",
            "Lider": "David Alfredo Sanchez Bastidas",
            "Nombre": "Lady Carolina Lopez Velandia"
        },
        {
            "_id": "800251",
            "Agente": "800251",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mont800251@siigo.com",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre": "Luis Eduardo Montoya Gomez"
        },
        {
            "_id": "803434",
            "Agente": "803434",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "pere803434@siigo.com",
            "Lider": "Ivan Dario Corchuelo Castro",
            "Nombre": "Harol Perez Giraldo"
        },
        {
            "_id": "802878",
            "Agente": "802878",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "regu802878@siigo.com",
            "Lider": "Diana Michelle Matamoros Del Rosario",
            "Nombre": "Sonia Viviana Reguillo Leon"
        },
        {
            "_id": "804194",
            "Agente": "804194",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "medi804194@siigo.com",
            "Lider": "Liliana Andrea Segura Martinez",
            "Nombre": "Oscar Julian Medina Perez"
        },
        {
            "_id": "803485",
            "Agente": "803485",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "kame803485@siigo.com",
            "Lider": "Liliana Andrea Segura Martinez",
            "Nombre": "Abeth Sebastian Kamell Carrera"
        },
        {
            "_id": "803494",
            "Agente": "803494",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "bara803494@siigo.com",
            "Lider": "Liliana Andrea Segura Martinez",
            "Nombre": "Lyda Teresa Barahona Romero"
        },
        {
            "_id": "801774",
            "Agente": "801774",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "jaim801774@siigo.com",
            "Lider": "Jaime Andres Aguilar Rivera",
            "Nombre": "Silvana Camila Jaimes Gáfaro"
        },
        {
            "_id": "803615",
            "Agente": "803615",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "moli803615@siigo.com",
            "Lider": "Mario Fernando Nuñez Santos",
            "Nombre": "Derlis Milena Molina Rada"
        },
        {
            "_id": "803606",
            "Agente": "803606",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "reye803606@siigo.com",
            "Lider": "Luz Katerine Maya Mogollón",
            "Nombre": "Alejandra Reyes Nieto"
        },
        {
            "_id": "800622",
            "Agente": "800622",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "lava800622@siigo.com",
            "Lider": "Jaime Adalberto Lopez Vivas",
            "Nombre": "Juan Francisco Lavayen Iñamagua"
        },
        {
            "_id": "803590",
            "Agente": "803590",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "pela803590@siigo.com",
            "Lider": "Andrea Catalina Vega Gomez",
            "Nombre": "Luz Mery Pelaez Arrieta"
        },
        {
            "_id": "3565",
            "Agente": "3565",
            "Area": "People & Culture",
            "Division": "Siigo Mexico",
            "Email": "fmenchaca@aspel.com.mx",
            "Lider": "Angela Maria Roa Betancourt",
            "Nombre": "Fanny Noemi Menchaca Salmeron"
        },
        {
            "_id": "803672",
            "Agente": "803672",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gord803672@siigo.com",
            "Lider": "Leida Adriana Rico Herrera",
            "Nombre": "Monica Alejandra Gordillo Patiño"
        },
        {
            "_id": "801509",
            "Agente": "801509",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "ponc801509@siigo.com",
            "Lider": "Angie Lorena Perez Espitia",
            "Nombre": "Sebastian Alexander Ponce Torres"
        },
        {
            "_id": "800976",
            "Agente": "800976",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "mart800976@siigo.com",
            "Lider": "Heliberto Antonio Obando Pomeo",
            "Nombre": "Laura Girleza Martinez Sandoval"
        },
        {
            "_id": "802493",
            "Agente": "802493",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "barr802493@siigo.com",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre": "Elias David Barrios Alvarez"
        },
        {
            "_id": "800933",
            "Agente": "800933",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "garz800933@siigo.com",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre": "Jhon Jairo Garzon Ramirez"
        },
        {
            "_id": "3316",
            "Agente": "3316",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "amarquez@aspel.com.mx",
            "Lider": "Maria Teresa Vazquez Garza",
            "Nombre": "Abigail Lopez Marquez"
        },
        {
            "_id": "804426",
            "Agente": "804426",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "reyr804426@siigo.com",
            "Lider": "Jorge Alberto Navia Charria",
            "Nombre": "Jose Miguel Rey Roman"
        },
        {
            "_id": "804530",
            "Agente": "804530",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "cast804530@siigo.com",
            "Lider": "Luis Ernesto Parrado Ortiz",
            "Nombre": "Wilmer Emiro Castrillón Calderón"
        },
        {
            "_id": "803696",
            "Agente": "803696",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pala803696@siigo.com",
            "Lider": "Aleida Bibiana Garcia Suarez",
            "Nombre": "Gisela Palacios Palacios"
        },
        {
            "_id": "803883",
            "Agente": "803883",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "silv803883@siigo.com",
            "Lider": "Juan Carlos Buitrago Rios",
            "Nombre": "Oscar Eduardo Silva Lebaza"
        },
        {
            "_id": "1115",
            "Agente": "1115",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "vgonzalez@aspel.com.mx",
            "Lider": "Liliana Ramirez Vanegas",
            "Nombre": "Victor Gonzalez Lopez"
        },
        {
            "_id": "3222",
            "Agente": "3222",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "dsoriano@aspel.com.mx",
            "Lider": "Maria Teresa Vazquez Garza",
            "Nombre": "Daniel Soriano Mendoza"
        },
        {
            "_id": "802694",
            "Agente": "802694",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "reye802694@siigo.com",
            "Lider": "Angie Camila Diaz Escobar",
            "Nombre": "Zamira Lucia Reyes Carreño"
        },
        {
            "_id": "802685",
            "Agente": "802685",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "corr802685@siigo.com",
            "Lider": "Andrea Zuluaga Uribe",
            "Nombre": "Juan Jose Correa Acosta"
        },
        {
            "_id": "585",
            "Agente": "585",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "dbasurto@aspel.com.mx",
            "Lider": "Brian Yukio Nishizaki Simon",
            "Nombre": "Maria Dolores Basurto Rodriguez"
        },
        {
            "_id": "802858",
            "Agente": "802858",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "apon802858@siigo.com",
            "Lider": "Claudio Federico Hornes Ismail",
            "Nombre": "Hollman Antonio Aponte Quiroga"
        },
        {
            "_id": "802634",
            "Agente": "802634",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "acos802634@siigo.com",
            "Lider": "Juan Francisco Lavayen Iñamagua",
            "Nombre": "Alvaro Jose Acosta Madroñero"
        },
        {
            "_id": "803489",
            "Agente": "803489",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cort803489@siigo.com",
            "Lider": "Liliana Andrea Segura Martinez",
            "Nombre": "Luisa Fernanda Cortes Puerta"
        },
        {
            "_id": "804046",
            "Agente": "804046",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "sast804046@siigo.com",
            "Lider": "Wendy Dayana Rojas Rojas",
            "Nombre": "Laura Elena Sastoque Barraza"
        },
        {
            "_id": "804539",
            "Agente": "804539",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vane804539@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Jorsany Patricia Vanegas Fontalvo"
        },
        {
            "_id": "801648",
            "Agente": "801648",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "cabr801648@siigo.com",
            "Lider": "Carlos Arturo Huertas Salgado",
            "Nombre": "Andrea Carolina Cabrera Gutierrez"
        },
        {
            "_id": "19229",
            "Agente": "19229",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rodr19229@siigo.com",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre": "Wilmar Andres Rodriguez Hernandez"
        },
        {
            "_id": "803698",
            "Agente": "803698",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "egui803698@siigo.com",
            "Lider": "Diana Mercedes Moncada Peraza",
            "Nombre": "Angingler Johana Eguis Garcia"
        },
        {
            "_id": "801677",
            "Agente": "801677",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vela801677@siigo.com",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre": "Laura Natalia Velandia Ramirez"
        },
        {
            "_id": "803700",
            "Agente": "803700",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "apaz803700@siigo.com",
            "Lider": "John Misael Martinez Montero",
            "Nombre": "Angela Maria Paz 0"
        },
        {
            "_id": "800752",
            "Agente": "800752",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "ahum800752@siigo.com",
            "Lider": "Luis Ernesto Parrado Ortiz",
            "Nombre": "Anthony Jordan Ahumada Duarte"
        },
        {
            "_id": "111934",
            "Agente": "111934",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "vill111934@siigo.com",
            "Lider": "Yudy Paola Rojas Rojas",
            "Nombre": "Robinson Arley Villegas Campo"
        },
        {
            "_id": "111933",
            "Agente": "111933",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vela111933@siigo.com",
            "Lider": "Yenny Milena Lopez Florez",
            "Nombre": "Pierre Fardey Velasquez Sanchez"
        },
        {
            "_id": "802905",
            "Agente": "802905",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "fern802905@siigo.com",
            "Lider": "Carlos German Lopez Murcia",
            "Nombre": "Julian Bernardo Fernandez Garcia"
        },
        {
            "_id": "801843",
            "Agente": "801843",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "guet801843@siigo.com",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre": "Kiara Yarnelyne Guette Ramirez"
        },
        {
            "_id": "801248",
            "Agente": "801248",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "vele801248@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Luis Enrique Velez Delgado"
        },
        {
            "_id": "3401",
            "Agente": "3401",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "banaya@aspel.com.mx",
            "Lider": "Maria Teresa Vazquez Garza",
            "Nombre": "Brian Eduardo Anaya Ruiz"
        },
        {
            "_id": "802665",
            "Agente": "802665",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "jime802665@siigo.com",
            "Lider": "Andrea Catalina Vega Gomez",
            "Nombre": "Brigithe Vanessa Jimenez Roncancio"
        },
        {
            "_id": "803452",
            "Agente": "803452",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "niet803452@siigo.com",
            "Lider": "Johana Catalina Peña ",
            "Nombre": "Erwin Esteban Nieto Caballero"
        },
        {
            "_id": "3298",
            "Agente": "3298",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jmarin@aspel.com.mx",
            "Lider": "Adriana Rodriguez Castro",
            "Nombre": "Jocelyn Angelica Marin Garcia"
        },
        {
            "_id": "801688",
            "Agente": "801688",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pove801688@siigo.com",
            "Lider": "Nestor Oswaldo Becerra Prieto",
            "Nombre": "Karen Dayana Poveda García"
        },
        {
            "_id": "111669",
            "Agente": "111669",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "alfo111669@siigo.com",
            "Lider": "Sandra Catalina Suarez Castillo",
            "Nombre": "Paula Andrea Alfonso Chacon"
        },
        {
            "_id": "803827",
            "Agente": "803827",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "pola803827@siigo.com",
            "Lider": "Diego Andres Montoya Blandon",
            "Nombre": "David Polania Mejia"
        },
        {
            "_id": "1907",
            "Agente": "1907",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "gchavez@aspel.com.mx",
            "Lider": "Gerardo Marquez Perez",
            "Nombre": "Gabriel Chavez Carrera"
        },
        {
            "_id": "3394",
            "Agente": "3394",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "dmartInez@aspel.com.mx",
            "Lider": "Ivan Alejandro Roa Franco",
            "Nombre": "Donay Arnaldo Martinez Garcia"
        },
        {
            "_id": "802572",
            "Agente": "802572",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "mora802572@siigo.com",
            "Lider": "Andres David Leon Guerra",
            "Nombre": "Natalia Maria Mora Rios"
        },
        {
            "_id": "3538",
            "Agente": "3538",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jaguirre@aspel.com.mx",
            "Lider": "Arturo Alvarez Martinez",
            "Nombre": "Jose Eduardo Aguirre Colin"
        },
        {
            "_id": "2283",
            "Agente": "2283",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "yrioja@aspel.com.mx",
            "Lider": "Rene Soriano Estrada",
            "Nombre": "Yoselin Rioja Morales"
        },
        {
            "_id": "2730",
            "Agente": "2730",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "llomeli@aspel.com.mx",
            "Lider": "Peter Anderson Vargas Grajales",
            "Nombre": "Liliana Angelina Hernandez Lomeli"
        },
        {
            "_id": "804193",
            "Agente": "804193",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gris804193@siigo.com",
            "Lider": "Jessica Paola Paez Quintero",
            "Nombre": "Yuliana Alejandra Grisales Chico"
        },
        {
            "_id": "802306",
            "Agente": "802306",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "isaz802306@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Paula Andrea Isaza Valencia"
        },
        {
            "_id": "1842",
            "Agente": "1842",
            "Area": "Strategy",
            "Division": "Siigo Mexico",
            "Email": "gcamara@aspel.com.mx",
            "Lider": "Fabian David Carreño Leon",
            "Nombre": "Goretty Aracelly J Camara Mendez"
        },
        {
            "_id": "803765",
            "Agente": "803765",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "toma803765@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Lissette Adaleyn Tomala Cabezas"
        },
        {
            "_id": "804060",
            "Agente": "804060",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rend804060@siigo.com",
            "Lider": "Giovanni Baquero Hernandez",
            "Nombre": "Luisa Fernanda Rendon Sepulveda"
        },
        {
            "_id": "804049",
            "Agente": "804049",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "espi804049@siigo.com",
            "Lider": "Diego Armando Alzate Rios",
            "Nombre": "Dubier Esneider Espitia Aristizabal"
        },
        {
            "_id": "804151",
            "Agente": "804151",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "acev804151@siigo.com",
            "Lider": "Miguel Oswaldo Velasquez Fragiel",
            "Nombre": "Breidy Aleis Acevedo Diaz"
        },
        {
            "_id": "803637",
            "Agente": "803637",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "mora803637@siigo.com",
            "Lider": "Omar Eduardo Roa Quintero",
            "Nombre": "Daniel Alberto Mora Velandia"
        },
        {
            "_id": "802561",
            "Agente": "802561",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gilc802561@siigo.com",
            "Lider": "Jenny Paola Mendez 0",
            "Nombre": "Neiza Lizeth Gil Castrillo"
        },
        {
            "_id": "804565",
            "Agente": "804565",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "aven804565@siigo.com",
            "Lider": "Maria Paula Garzon Ortega",
            "Nombre": "Lorena Avendaño Fonseca"
        },
        {
            "_id": "801639",
            "Agente": "801639",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gome801639@siigo.com",
            "Lider": "Cristian Camilo Hernandez Gonzalez",
            "Nombre": "Luis Jose Gomez Villarreal"
        },
        {
            "_id": "803797",
            "Agente": "803797",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pinz803797@siigo.com",
            "Lider": "Jenny Paola Mendez 0",
            "Nombre": "Sebastian Camilo Pinzon Davila"
        },
        {
            "_id": "801724",
            "Agente": "801724",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "manc801724@siigo.com",
            "Lider": "Cristhian David Niño Barrera",
            "Nombre": "Adriana Cecilia Mancilla Villarreal"
        },
        {
            "_id": "802496",
            "Agente": "802496",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "zara802496@siigo.com",
            "Lider": "Sory Yaqueline Abello Polo",
            "Nombre": "Brandon Zarante Davila"
        },
        {
            "_id": "801542",
            "Agente": "801542",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "mart801542@siigo.com",
            "Lider": "Julian Ernesto Morales Garzon",
            "Nombre": "Harrison Martinez Callejas"
        },
        {
            "_id": "710",
            "Agente": "710",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "mventas@aspel.com.mx",
            "Lider": "Fernando Contreras Ocaña",
            "Nombre": "Ramon Ricardo Huerta Limon"
        },
        {
            "_id": "801240",
            "Agente": "801240",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "pena801240@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Kleber Mauricio Peña Campuzano"
        },
        {
            "_id": "2072",
            "Agente": "2072",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ulopez@aspel.com.mx",
            "Lider": "Gerardo Marquez Perez",
            "Nombre": "Armando Uriel Lopez Vazquez"
        },
        {
            "_id": "801118",
            "Agente": "801118",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "pifa801118@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Sebastian Pifano Moreno"
        },
        {
            "_id": "802101",
            "Agente": "802101",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "tapi802101@siigo.com",
            "Lider": "Alix Dayana Archila Castillo",
            "Nombre": "Jeisson Alirio Tapiero Gonzalez"
        },
        {
            "_id": "802780",
            "Agente": "802780",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rued802780@siigo.com",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre": "Deicy Dayana Rueda Gomez"
        },
        {
            "_id": "3387",
            "Agente": "3387",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "pbonilla@aspel.com.mx",
            "Lider": "Jorge Alfredo Huesca Martinez",
            "Nombre": "Pedro Bonilla Sosa"
        },
        {
            "_id": "801201",
            "Agente": "801201",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "cepe801201@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "Patricia Alexandra Cepeda Mocha"
        },
        {
            "_id": "803874",
            "Agente": "803874",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gome803874@siigo.com",
            "Lider": "Jefferson Arvey Ramirez Ibañez",
            "Nombre": "Juan Carlos Gomez Escobar"
        },
        {
            "_id": "3242",
            "Agente": "3242",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "egalindo@aspel.com.mx",
            "Lider": "Marisela Yasmin Franco Cruz",
            "Nombre": "Erick Galindo rojas"
        },
        {
            "_id": "800808",
            "Agente": "800808",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "more800808@siigo.com",
            "Lider": "Yesika Viviana Garcia Niño",
            "Nombre": "Erica Johana Moreno Perdomo"
        },
        {
            "_id": "3345",
            "Agente": "3345",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "lfragoso@aspel.com.mx",
            "Lider": "Adriana Rodriguez Castro",
            "Nombre": "Leticia Flores Fragoso"
        },
        {
            "_id": "800531",
            "Agente": "800531",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "pere800531@siigo.com",
            "Lider": "Anyela Marcela Rivera Mera",
            "Nombre": "Hernando Augusto Perez Zabala"
        },
        {
            "_id": "3354",
            "Agente": "3354",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "amperez@aspel.com.mx",
            "Lider": "Ivan Alejandro Roa Franco",
            "Nombre": "Aldair Moreno Perez"
        },
        {
            "_id": "802594",
            "Agente": "802594",
            "Area": "People & Culture",
            "Division": "Siigo Ecuador",
            "Email": "sosa802594@siigo.com",
            "Lider": "Joan Sebastian Rodriguez 0",
            "Nombre": "William Armando Sosa Espinoza"
        },
        {
            "_id": "1855",
            "Agente": "1855",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "iaranda@aspel.com.mx",
            "Lider": "Rene Gerardo Paredes Alcantara",
            "Nombre": "Isaac Obed Garcia Aranda"
        },
        {
            "_id": "2477",
            "Agente": "2477",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "iolivares@aspel.com.mx",
            "Lider": "Gabriel Chavez Carrera",
            "Nombre": "Irving Olivares Felix"
        },
        {
            "_id": "2096",
            "Agente": "2096",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "ldiaz@aspel.com.mx",
            "Lider": "Maira Elisa Ortiz Perez",
            "Nombre": "Ma Luisa Del Socorro Diaz Canto"
        },
        {
            "_id": "3584",
            "Agente": "3584",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "nzarate@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Natividad Zarate Quileo"
        },
        {
            "_id": "802558",
            "Agente": "802558",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pere802558@siigo.com",
            "Lider": "Sory Yaqueline Abello Polo",
            "Nombre": "Adela Vanessa Perez Urina"
        },
        {
            "_id": "802660",
            "Agente": "802660",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "flor802660@siigo.com",
            "Lider": "Ana Maria Ramirez Sanchez",
            "Nombre": "Nelson Andres Florez Espinosa"
        },
        {
            "_id": "801850",
            "Agente": "801850",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "meji801850@siigo.com",
            "Lider": "Nestor Oswaldo Becerra Prieto",
            "Nombre": "Luis Enrique Mejia Pincay"
        },
        {
            "_id": "802433",
            "Agente": "802433",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "varg802433@siigo.com",
            "Lider": "Mario Fernando Nuñez Santos",
            "Nombre": "Heidy Viviana Vargas Vargas"
        },
        {
            "_id": "802751",
            "Agente": "802751",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rodr802751@siigo.com",
            "Lider": "Vicky Alejandra Hernandez Mendez",
            "Nombre": "Jeymi Astrid Rodriguez Bernal"
        },
        {
            "_id": "802291",
            "Agente": "802291",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "vale802291@siigo.com",
            "Lider": "Estefania Sofia Barbosa Fajardo",
            "Nombre": "Gustavo Adolfo Valencia Chaparro"
        },
        {
            "_id": "111573",
            "Agente": "111573",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "mend111573@siigo.com",
            "Lider": "Carlos German Lopez Murcia",
            "Nombre": "Leidy Mendoza Espitia"
        },
        {
            "_id": "110150",
            "Agente": "110150",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mora110150@siigo.com",
            "Lider": "Gustavo Adolfo Espinosa Toledo",
            "Nombre": "Andres Felipe Morales Velandia"
        },
        {
            "_id": "801623",
            "Agente": "801623",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sues801623@siigo.com",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre": "Angel Guillermo Junior Suesca Novoa"
        },
        {
            "_id": "801886",
            "Agente": "801886",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "buit801886@siigo.com",
            "Lider": "Juan Pablo Carreño Patiño",
            "Nombre": "David Steban Buitrago Duran"
        },
        {
            "_id": "803325",
            "Agente": "803325",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "sote803325@siigo.com",
            "Lider": "Gustavo Adolfo Espinosa Toledo",
            "Nombre": "Angie Carolina Sotelo Riaño"
        },
        {
            "_id": "30654",
            "Agente": "30654",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Aria30654@siigo.com",
            "Lider": "Juan Carlos Sanchez Velez",
            "Nombre": "Marcela Alexandra Arias Echavarria"
        },
        {
            "_id": "802486",
            "Agente": "802486",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "cont802486@siigo.com",
            "Lider": "Wilson Medina Nuñez",
            "Nombre": "Natalia Andrea Contreras Mejia"
        },
        {
            "_id": "803301",
            "Agente": "803301",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "blan803301@siigo.com",
            "Lider": "Sonia Mabel Fernandez Calderon",
            "Nombre": "Lady Valentina Blanco Sevilla"
        },
        {
            "_id": "802707",
            "Agente": "802707",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "quij802707@siigo.com",
            "Lider": "Pedro Felipe Arana Lastra",
            "Nombre": "Ana Maria Quijano Ardila"
        },
        {
            "_id": "802504",
            "Agente": "802504",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "muri802504@siigo.com",
            "Lider": "Luz Derly Bernal Malaver",
            "Nombre": "Lady Yurany Murillo Rubiano"
        },
        {
            "_id": "800277",
            "Agente": "800277",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gira800277@siigo.com",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre": "Raimundo Enrique Giraldo Giraldo"
        },
        {
            "_id": "801189",
            "Agente": "801189",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "buit801189@siigo.com",
            "Lider": "Luis Ernesto Parrado Ortiz",
            "Nombre": "Jair Buitron "
        },
        {
            "_id": "803448",
            "Agente": "803448",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "moya803448@siigo.com",
            "Lider": "Johana Catalina Peña ",
            "Nombre": "Giovanni Alberto Moya Farfan"
        },
        {
            "_id": "803479",
            "Agente": "803479",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "pere803479@siigo.com",
            "Lider": "Camilo Andres Muñoz Guerra",
            "Nombre": "Angie Lorena Perez Espitia"
        },
        {
            "_id": "2009",
            "Agente": "2009",
            "Area": "Product",
            "Division": "Siigo Mexico",
            "Email": "aguerrero@aspel.com.mx",
            "Lider": "Luz Mery Patino Infante",
            "Nombre": "Adalid Ruiz Guerrero"
        },
        {
            "_id": "801722",
            "Agente": "801722",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "caro801722@siigo.com",
            "Lider": "Nataly Carolina Gonzalez Sandoval",
            "Nombre": "Sandra Patricia Caro Aponte"
        },
        {
            "_id": "3370",
            "Agente": "3370",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "amcampos@aspel.com.mx",
            "Lider": "Jose Luis Carranza Valdes",
            "Nombre": "Alexander Montes de Oca Campos"
        },
        {
            "_id": "2274",
            "Agente": "2274",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "grodriguez@aspel.com.mx",
            "Lider": "Gabriel Chavez Carrera",
            "Nombre": "Gustavo Rodriguez Garcia"
        },
        {
            "_id": "2967",
            "Agente": "2967",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "ddominguez@aspel.com.mx",
            "Lider": "Yazmin Gonzalez Fernandez",
            "Nombre": "Daniel Dominguez Zapata"
        },
        {
            "_id": "2050",
            "Agente": "2050",
            "Area": "Product",
            "Division": "Siigo Mexico",
            "Email": "ttehuitzil@aspel.com.mx",
            "Lider": "Rodrigo Garzon Dominguez",
            "Nombre": "Teresa Tehuitzil Villarreal"
        },
        {
            "_id": "801758",
            "Agente": "801758",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rome801758@siigo.com",
            "Lider": "Vicky Alejandra Hernandez Mendez",
            "Nombre": "Diana Patricia Romero Ramirez"
        },
        {
            "_id": "2008",
            "Agente": "2008",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jhuesca@aspel.com.mx",
            "Lider": "Vicente Estrada Sanchez",
            "Nombre": "Jorge Alfredo Huesca Martinez"
        },
        {
            "_id": "802230",
            "Agente": "802230",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "farf802230@siigo.com",
            "Lider": "Angie Camila Diaz Escobar",
            "Nombre": "Santiago Farfan Ramirez"
        },
        {
            "_id": "802240",
            "Agente": "802240",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "arbo802240@siigo.com",
            "Lider": "Yeimy Milena Bustos Gutierrez",
            "Nombre": "Yenny Arboleda Torres"
        },
        {
            "_id": "801264",
            "Agente": "801264",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "rang801264@siigo.com",
            "Lider": "Marcos Damian Lizarraga Elizalde",
            "Nombre": "Jose Enrique Rangel Trujillo"
        },
        {
            "_id": "801322",
            "Agente": "801322",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mart801322@siigo.com",
            "Lider": "Juan Carlos Buitrago Rios",
            "Nombre": "Adriana Cecilia Martinez Martinez"
        },
        {
            "_id": "802278",
            "Agente": "802278",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "borj802278@siigo.com",
            "Lider": "Martha Isabel Diaz Gomez",
            "Nombre": "Tiffani Esther Borja Gary"
        },
        {
            "_id": "801684",
            "Agente": "801684",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "estr801684@siigo.com",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre": "Melissa Maria Estrada Vargas"
        },
        {
            "_id": "647",
            "Agente": "647",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "arodriguez@aspel.com.mx",
            "Lider": "Vicente Estrada Sanchez",
            "Nombre": "Adriana Rodriguez Castro"
        },
        {
            "_id": "1871",
            "Agente": "1871",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "acampos@aspel.com.mx",
            "Lider": "Carlos Maximo Garrido Mascott",
            "Nombre": "Alejandra Zuleidha Campos Aguilar"
        },
        {
            "_id": "204167",
            "Agente": "204167",
            "Area": "Finance & Administration",
            "Division": "Siigo Perú",
            "Email": "mend204167@siigo.com",
            "Lider": "Ricardo Alejandro Ortiz Deulofeut",
            "Nombre": "Daniel Ricardo Mendoza Torpoco"
        },
        {
            "_id": "3349",
            "Agente": "3349",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jagamboa@aspel.com.mx",
            "Lider": "Gerardo Enrique Aldecua Matamoros",
            "Nombre": "Jorge Antonio Gamboa Cardeña"
        },
        {
            "_id": "450",
            "Agente": "450",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "gestrella@aspel.com.mx",
            "Lider": "Claudia Valdez Carbajal",
            "Nombre": "Guadalupe De La Cruz Estrella Varguez"
        },
        {
            "_id": "3300",
            "Agente": "3300",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jboyzo@aspel.com.mx",
            "Lider": "Victor Hugo Huerta Lopez",
            "Nombre": "Jorge Luis Sartillo Boyzo"
        },
        {
            "_id": "800373",
            "Agente": "800373",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "chap800373@siigo.com",
            "Lider": "Nestor Oswaldo Becerra Prieto",
            "Nombre": "Estefania Chaparro Varon"
        },
        {
            "_id": "111741",
            "Agente": "111741",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "oban111741@siigo.com",
            "Lider": "Miguel Oswaldo Velasquez Fragiel",
            "Nombre": "Heliberto Antonio Obando Pomeo"
        },
        {
            "_id": "803915",
            "Agente": "803915",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "zeam803915@siigo.com",
            "Lider": "Alcides Martin Salas Orozco",
            "Nombre": "Tania Milena Zea Morales"
        },
        {
            "_id": "801800",
            "Agente": "801800",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bece801800@siigo.com",
            "Lider": "Luz Katerine Maya Mogollón",
            "Nombre": "Katherine Becerra Garcia"
        },
        {
            "_id": "800260",
            "Agente": "800260",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "mart800260@siigo.com",
            "Lider": "Jimena Marcela Prieto Bohorquez",
            "Nombre": "Julian David Martinez Lara"
        },
        {
            "_id": "802215",
            "Agente": "802215",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "leon802215@siigo.com",
            "Lider": "Cristhian David Niño Barrera",
            "Nombre": "Janny Milena Leon Hernandez"
        },
        {
            "_id": "802559",
            "Agente": "802559",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "card802559@siigo.com",
            "Lider": "Carlos Eduardo Sanchez Castaño",
            "Nombre": "Yumary Cardona Salazar"
        },
        {
            "_id": "803605",
            "Agente": "803605",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "quin803605@siigo.com",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre": "Venis Quintana Herrera"
        },
        {
            "_id": "230679",
            "Agente": "230679",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "Lope230679@siigo.com",
            "Lider": "Manuel Enrique Ramirez Rodriguez",
            "Nombre": "Jaime Adalberto Lopez Vivas"
        },
        {
            "_id": "111859",
            "Agente": "111859",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rodr111859@siigo.com",
            "Lider": "Juliana Castro Diaz",
            "Nombre": "Fabio Hernan Rodriguez Romero"
        },
        {
            "_id": "802372",
            "Agente": "802372",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "orju802372@siigo.com",
            "Lider": "Juan Carlos Buitrago Rios",
            "Nombre": "Liliana Orjuela Castañeda"
        },
        {
            "_id": "110124",
            "Agente": "110124",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "mend110124@siigo.com",
            "Lider": "Fernando Gutierrez Paramio",
            "Nombre": "Carlos Javier Mendez Vera"
        },
        {
            "_id": "801543",
            "Agente": "801543",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "diaz801543@siigo.com",
            "Lider": "Jonathan Uribe Mendoza",
            "Nombre": "Natalia Diaz Barreto"
        },
        {
            "_id": "1242",
            "Agente": "1242",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "nquinones@aspel.com.mx",
            "Lider": "Mercy Karla Cancino Mendez",
            "Nombre": "Norma Florentina Quiñones Martin"
        },
        {
            "_id": "3268",
            "Agente": "3268",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "daguilar@aspel.com.mx",
            "Lider": "Laura Erika Utrera Utrera",
            "Nombre": "Diego Armando Ramirez Aguilar"
        },
        {
            "_id": "2554",
            "Agente": "2554",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "promero@aspel.com.mx",
            "Lider": "Marisol Vargas Cortes",
            "Nombre": "Alexis Romero Perez"
        },
        {
            "_id": "3272",
            "Agente": "3272",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jbrito@aspel.com.mx",
            "Lider": "Luis Alejandro Salgado Vergara",
            "Nombre": "José Eduardo Diaz Brito"
        },
        {
            "_id": "3396",
            "Agente": "3396",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "osanchez@aspel.com.mx",
            "Lider": "Adriana Rodriguez Castro",
            "Nombre": "Oscar Omar Adony Sanchez Nava"
        },
        {
            "_id": "2168",
            "Agente": "2168",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "asantillan@aspel.com.mx",
            "Lider": "Gabriel Chavez Carrera",
            "Nombre": "Adriana Mendoza Santillan"
        },
        {
            "_id": "801136",
            "Agente": "801136",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "diaz801136@siigo.com",
            "Lider": "Wilson Medina Nuñez",
            "Nombre": "Kely Andrea Diaz Granados"
        },
        {
            "_id": "1703",
            "Agente": "1703",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "llabastida@aspel.com.mx",
            "Lider": "Nelson Juan Carlos Acosta Drouet",
            "Nombre": "Luis Alberto Labastida Arroyo"
        },
        {
            "_id": "2959",
            "Agente": "2959",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "epascual@aspel.com.mx",
            "Lider": "Alejandro Lopez Manzo",
            "Nombre": "Erick Pascual Carrasco"
        },
        {
            "_id": "1890",
            "Agente": "1890",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "pbaleon@aspel.com.mx",
            "Lider": "Rene Gerardo Paredes Alcantara",
            "Nombre": "Paul Baleon Rico"
        },
        {
            "_id": "802058",
            "Agente": "802058",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "card802058@siigo.com",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre": "Angelica Cardenas Rodriguez"
        },
        {
            "_id": "800923",
            "Agente": "800923",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "salo800923@siigo.com",
            "Lider": "Juan Pablo Henao Muñoz",
            "Nombre": "Adrian Alberto Salom Berrio"
        },
        {
            "_id": "801675",
            "Agente": "801675",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "suar801675@siigo.com",
            "Lider": "Juan Pablo Carreño Patiño",
            "Nombre": "Paula Valentina Suarez Ramirez"
        },
        {
            "_id": "801332",
            "Agente": "801332",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "alon801332@siigo.com",
            "Lider": "Gustavo Adolfo Espinosa Toledo",
            "Nombre": "Francy Julieth Alonso Vargas"
        },
        {
            "_id": "801602",
            "Agente": "801602",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "ocho801602@siigo.com",
            "Lider": "Alvaro Jose Almendrales Pabon",
            "Nombre": "Yennifer Paola Ochoa Pachon"
        },
        {
            "_id": "801235",
            "Agente": "801235",
            "Area": "Product",
            "Division": "Siigo Ecuador",
            "Email": "brio801235@siigo.com",
            "Lider": "Jaime Rodrigo Castells Perez",
            "Nombre": "Steven Luciano Briones Yunapanta"
        },
        {
            "_id": "2636",
            "Agente": "2636",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "aespino@aspel.com.mx",
            "Lider": "Angel Manuel Dominguez Romero",
            "Nombre": "Alberto Espino Lopez"
        },
        {
            "_id": "800263",
            "Agente": "800263",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "reye800263@siigo.com",
            "Lider": "Leidy Marcela Rodriguez Rivera",
            "Nombre": "Lizeth Fernanda Reyes Pire"
        },
        {
            "_id": "803729",
            "Agente": "803729",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mora803729@siigo.com",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre": "Luisa Fernanda Mora Hurtado"
        },
        {
            "_id": "1545",
            "Agente": "1545",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "rparedes@aspel.com.mx",
            "Lider": "Gerardo Marquez Perez",
            "Nombre": "Rene Gerardo Paredes Alcantara"
        },
        {
            "_id": "801702",
            "Agente": "801702",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "guzm801702@siigo.com",
            "Lider": "Cristhian David Niño Barrera",
            "Nombre": "Cindy Estefania Guzman Mora"
        },
        {
            "_id": "802661",
            "Agente": "802661",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "ruiz802661@siigo.com",
            "Lider": "Fabian David Carreño Leon",
            "Nombre": "Diego Alexander Ruiz Sabogal"
        },
        {
            "_id": "2655",
            "Agente": "2655",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "mborgez@aspel.com.mx",
            "Lider": "Pedro Miguel Ocampo Ix",
            "Nombre": "Mario Alejandro Borgez Pech"
        },
        {
            "_id": "801512",
            "Agente": "801512",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mart801512@siigo.com",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre": "Maria Paula Martinez Perez"
        },
        {
            "_id": "800276",
            "Agente": "800276",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "toro800276@siigo.com",
            "Lider": "Eliana Maria Fajardo Prada",
            "Nombre": "Harold Mauricio Toro Vargas"
        },
        {
            "_id": "2933",
            "Agente": "2933",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "mluna@aspel.com.mx",
            "Lider": "Victor Hugo Huerta Lopez",
            "Nombre": "Maribel Espinosa Luna"
        },
        {
            "_id": "277",
            "Agente": "277",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "aperez@aspel.com.mx",
            "Lider": "Brian Yukio Nishizaki Simon",
            "Nombre": "Maria Anabel Perez Martinez"
        },
        {
            "_id": "2966",
            "Agente": "2966",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "cgarrido@aspel.com.mx",
            "Lider": "Carlos German Lopez Murcia",
            "Nombre": "Carlos Maximo Garrido Mascott"
        },
        {
            "_id": "802076",
            "Agente": "802076",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "russ802076@siigo.com",
            "Lider": "Andrea Catalina Nieto Fernandez",
            "Nombre": "Melissa Cristina Russo Coba"
        },
        {
            "_id": "801872",
            "Agente": "801872",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "carr801872@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "German Andres Carrascal Heilbron"
        },
        {
            "_id": "801378",
            "Agente": "801378",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cald801378@siigo.com",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre": "Maria Alejandra Caldas Garcia"
        },
        {
            "_id": "801218",
            "Agente": "801218",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "niet801218@siigo.com",
            "Lider": "Carlos Arturo Huertas Salgado",
            "Nombre": "Andrea Catalina Nieto Fernandez"
        },
        {
            "_id": "802249",
            "Agente": "802249",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mato802249@siigo.com",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre": "Karen Yisell Matos Gamero"
        },
        {
            "_id": "802116",
            "Agente": "802116",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "nune802116@siigo.com",
            "Lider": "Juan Carlos Corazzo Santos",
            "Nombre": "Alberto Nuñez Angarita"
        },
        {
            "_id": "802129",
            "Agente": "802129",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rinc802129@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Julieth Paola Rincon Mercado"
        },
        {
            "_id": "801435",
            "Agente": "801435",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pedr801435@siigo.com",
            "Lider": "Jessica Paola Paez Quintero",
            "Nombre": "Astrid Milena Pedrozo Lombana"
        },
        {
            "_id": "801276",
            "Agente": "801276",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "rein801276@siigo.com",
            "Lider": "Luis Ignacio Ferreira Duarte",
            "Nombre": "Andres Maximiliano Reinaldo Abrines"
        },
        {
            "_id": "801012",
            "Agente": "801012",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "hida801012@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "William Jose Hidalgo Armijos"
        },
        {
            "_id": "800671",
            "Agente": "800671",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "bern800671@siigo.com",
            "Lider": "Diana Patricia Atehortua Castaño",
            "Nombre": "Luz Derly Bernal Malaver"
        },
        {
            "_id": "801093",
            "Agente": "801093",
            "Area": "Tech",
            "Division": "Siigo Uruguay",
            "Email": "moli801093@siigo.com",
            "Lider": "Mario Alberto Rojas Gamboa",
            "Nombre": "Federico Mauricio Molina Lassalle"
        },
        {
            "_id": "800731",
            "Agente": "800731",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "arau800731@siigo.com",
            "Lider": "Heliberto Antonio Obando Pomeo",
            "Nombre": "Fernando Jose Araujo Figueroa"
        },
        {
            "_id": "802110",
            "Agente": "802110",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cast802110@siigo.com",
            "Lider": "Jesus Daniel Arzuza Roman",
            "Nombre": "Jenifer Castro Martinez"
        },
        {
            "_id": "802153",
            "Agente": "802153",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "zeao802153@siigo.com",
            "Lider": "Felipe Ricardo Reyes Lega",
            "Nombre": "Luis Felipe Zea Obando"
        },
        {
            "_id": "801988",
            "Agente": "801988",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "delg801988@siigo.com",
            "Lider": "Diana Michelle Matamoros Del Rosario",
            "Nombre": "Itaty Melany Delgado Romero"
        },
        {
            "_id": "801517",
            "Agente": "801517",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "mami801517@siigo.com",
            "Lider": "Santiago Jose Porras Brieva",
            "Nombre": "Marlon Felipe Mamian Rodriguez"
        },
        {
            "_id": "802891",
            "Agente": "802891",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mari802891@siigo.com",
            "Lider": "Juan Carlos Buitrago Rios",
            "Nombre": "Camila Marin Ramirez"
        },
        {
            "_id": "802304",
            "Agente": "802304",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rodr802304@siigo.com",
            "Lider": "Yuleidy Josefina Briceño Fernandez",
            "Nombre": "Hernan Gabriel Rodriguez Peña"
        },
        {
            "_id": "803488",
            "Agente": "803488",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rami803488@siigo.com",
            "Lider": "Liliana Andrea Segura Martinez",
            "Nombre": "Paula Ximena Ramirez Rey"
        },
        {
            "_id": "802039",
            "Agente": "802039",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "yepe802039@siigo.com",
            "Lider": "Leidy Stefany Castillo 0",
            "Nombre": "Yasmina Yepes Osma"
        },
        {
            "_id": "803736",
            "Agente": "803736",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "sand803736@siigo.com",
            "Lider": "Cesar Eduardo Lopez Suescun",
            "Nombre": "Cesar Andres Sandoval Muñoz"
        },
        {
            "_id": "264796",
            "Agente": "264796",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pena264796@siigo.com",
            "Lider": "Manuel Andres Solano Duran",
            "Nombre": "Hilder Alonso Penagos Rubio"
        },
        {
            "_id": "1999",
            "Agente": "1999",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "vdamian@aspel.com.mx",
            "Lider": "Gabriel Chavez Carrera",
            "Nombre": "Victor Manuel Damian Meneses"
        },
        {
            "_id": "3190",
            "Agente": "3190",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "nhorta@aspel.com.mx",
            "Lider": "María Elizabeth Mani Jimenez",
            "Nombre": "Nataly Denis De Horta Hernandez"
        },
        {
            "_id": "802210",
            "Agente": "802210",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "moli802210@siigo.com",
            "Lider": "Sory Yaqueline Abello Polo",
            "Nombre": "Luis Fernando Molina Orozco"
        },
        {
            "_id": "801720",
            "Agente": "801720",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "guer801720@siigo.com",
            "Lider": "Gloria Estela Osorio Montoya",
            "Nombre": "Karen Dayana Guerra Rincon"
        },
        {
            "_id": "801971",
            "Agente": "801971",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "rome801971@siigo.com",
            "Lider": "David Felipe Penagos Mosquera",
            "Nombre": "Alfredo David Romero Tigua"
        },
        {
            "_id": "1707",
            "Agente": "1707",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "imartinez@aspel.com.mx",
            "Lider": "Fernando Contreras Ocaña",
            "Nombre": "Marlene Ivonne Martinez Castañeda"
        },
        {
            "_id": "478",
            "Agente": "478",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "cespino@aspel.com.mx",
            "Lider": "Abraham Franco Nares",
            "Nombre": "Claudia Espino Becerril"
        },
        {
            "_id": "3547",
            "Agente": "3547",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "ayal803583@siigo.com",
            "Lider": "Esteban Tellez Garcia",
            "Nombre": "Gabriela Ayala Garcia"
        },
        {
            "_id": "2396",
            "Agente": "2396",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "mdiaz@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Maricruz Diaz Gonzalez"
        },
        {
            "_id": "802755",
            "Agente": "802755",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "colm802755@siigo.com",
            "Lider": "Alexandra Geraldine Sanchez Paiva",
            "Nombre": "Rosembert Alexander Colmenares Viana"
        },
        {
            "_id": "801116",
            "Agente": "801116",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "dame801116@siigo.com",
            "Lider": "Luis Ignacio Ferreira Duarte",
            "Nombre": "Patricia Yvette Damele Garcia"
        },
        {
            "_id": "801955",
            "Agente": "801955",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "nune801955@siigo.com",
            "Lider": "Alcides Martin Salas Orozco",
            "Nombre": "Juan Manuel Nuñez Mateos"
        },
        {
            "_id": "802237",
            "Agente": "802237",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rami802237@siigo.com",
            "Lider": "Diana Patricia Villarraga Forero",
            "Nombre": "Katherine Julieth Ramirez Barreto"
        },
        {
            "_id": "800769",
            "Agente": "800769",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "chap800769@siigo.com",
            "Lider": "Carlos Javier Mendez Vera",
            "Nombre": "Juan Camilo Chaparro Riveros"
        },
        {
            "_id": "800886",
            "Agente": "800886",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "ruiz800886@siigo.com",
            "Lider": "Felipe Ricardo Reyes Lega",
            "Nombre": "Silvia Johanna Ruiz Cuevas"
        },
        {
            "_id": "800894",
            "Agente": "800894",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "loai800894@siigo.com",
            "Lider": "Giovanni Baquero Hernandez",
            "Nombre": "Lina Maria Loaiza Arias"
        },
        {
            "_id": "802159",
            "Agente": "802159",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "vale802159@siigo.com",
            "Lider": "Andrea Duque Botero",
            "Nombre": "Jose David Valeta Hermosillas"
        },
        {
            "_id": "800979",
            "Agente": "800979",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "carr800979@siigo.com",
            "Lider": "Mario Alberto Rojas Gamboa",
            "Nombre": "Dario Javier Carranza Zambrano"
        },
        {
            "_id": "801030",
            "Agente": "801030",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "guti801030@siigo.com",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre": "Angie Marcela Gutierrez Moreno"
        },
        {
            "_id": "801882",
            "Agente": "801882",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cast801882@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Adriana Paola Castiblanco Yepes"
        },
        {
            "_id": "802102",
            "Agente": "802102",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gome802102@siigo.com",
            "Lider": "Eliana Maria Fajardo Prada",
            "Nombre": "Valentina Gomez Vanegas"
        },
        {
            "_id": "801432",
            "Agente": "801432",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pine801432@siigo.com",
            "Lider": "Gloria Estela Osorio Montoya",
            "Nombre": "Aleyne Paola Pineda Coronado"
        },
        {
            "_id": "802922",
            "Agente": "802922",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "jime802922@siigo.com",
            "Lider": "Cristhian David Niño Barrera",
            "Nombre": "Diana Geraldine Jimenez Toloza"
        },
        {
            "_id": "867",
            "Agente": "867",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "amariano@aspel.com.mx",
            "Lider": "Paul Rogelio Fuentes Tabarez",
            "Nombre": "Armando Mariano Cirilo"
        },
        {
            "_id": "802541",
            "Agente": "802541",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ruam802541@siigo.com",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre": "Maria Fernanda Rua Mendoza"
        },
        {
            "_id": "800612",
            "Agente": "800612",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "jara800612@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Jose Aaron Jaramillo Saverio"
        },
        {
            "_id": "802073",
            "Agente": "802073",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "dura802073@siigo.com",
            "Lider": "Alvaro Jose Almendrales Pabon",
            "Nombre": "Nicolas Duran Muñoz"
        },
        {
            "_id": "800966",
            "Agente": "800966",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "sanc800966@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Angie Daniela Sanchez Paredes"
        },
        {
            "_id": "2415",
            "Agente": "2415",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "vrodriguez@aspel.com.mx",
            "Lider": "Jose Antonio Vargas Uribe",
            "Nombre": "Priscila Viridiana Rodriguez Velasco"
        },
        {
            "_id": "3266",
            "Agente": "3266",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "egijon@aspel.com.mx",
            "Lider": "Pedro Arturo Rico Martinez",
            "Nombre": "Jatzumy Elizabeth Guzman Gijon"
        },
        {
            "_id": "3495",
            "Agente": "3495",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "candriano@aspel.com.mx",
            "Lider": "Virginia Guadalupe Ramirez Hernandez",
            "Nombre": "Cesar Octavio Andriano Granados"
        },
        {
            "_id": "800941",
            "Agente": "800941",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gilg800941@siigo.com",
            "Lider": "Jessica Paola Paez Quintero",
            "Nombre": "Maria Alejandra Gil Gonzalez"
        },
        {
            "_id": "2985",
            "Agente": "2985",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "emulet@aspel.com.mx",
            "Lider": "Fernando Contreras Ocaña",
            "Nombre": "Enrique Francisco Ochoa Mulet"
        },
        {
            "_id": "801952",
            "Agente": "801952",
            "Area": "Finance & Administration",
            "Division": "Siigo Ecuador",
            "Email": "tumb801952@siigo.com",
            "Lider": "Aura Cristina Rodriguez Manrique",
            "Nombre": "Luis Fernando Tumbaco Vera"
        },
        {
            "_id": "801726",
            "Agente": "801726",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "baqu801726@siigo.com",
            "Lider": "Cristhian David Niño Barrera",
            "Nombre": "Jennifer Rocio Baquero Sandoval"
        },
        {
            "_id": "800816",
            "Agente": "800816",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "bece800816@siigo.com",
            "Lider": "Lida Katerine Puentes Navarro",
            "Nombre": "Adriana Patricia Becerra Bermejo"
        },
        {
            "_id": "802096",
            "Agente": "802096",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mate802096@siigo.com",
            "Lider": "Nestor Oswaldo Becerra Prieto",
            "Nombre": "Rudy Alexandra Mateus Rodriguez"
        },
        {
            "_id": "801505",
            "Agente": "801505",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "merc801505@siigo.com",
            "Lider": "Leidy Stefany Castillo 0",
            "Nombre": "Andrea Carolina Mercado Vasquez"
        },
        {
            "_id": "800887",
            "Agente": "800887",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "chic800887@siigo.com",
            "Lider": "Diana Milena Roa Hurtado",
            "Nombre": "Sara Carolina Chica Lopez"
        },
        {
            "_id": "801663",
            "Agente": "801663",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "alda801663@siigo.com",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre": "Miguel Angel Aldana Barragan"
        },
        {
            "_id": "801596",
            "Agente": "801596",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "puli801596@siigo.com",
            "Lider": "Juan Carlos Guarin Walteros",
            "Nombre": "Ginna Fernanda Puliche Corrales"
        },
        {
            "_id": "801422",
            "Agente": "801422",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "brin801422@siigo.com",
            "Lider": "Nataly Carolina Gonzalez Sandoval",
            "Nombre": "Yury Andrea Briñez Briñez"
        },
        {
            "_id": "801162",
            "Agente": "801162",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "mera801162@siigo.com",
            "Lider": "Juan Carlos Valdes Martin",
            "Nombre": "Valentina Mera Ovalle"
        },
        {
            "_id": "3456",
            "Agente": "3456",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "lsandoval@aspel.com.mx",
            "Lider": "Brandon Enrique Gomez Rosales",
            "Nombre": "Laura Estefania Sandoval Navarrete"
        },
        {
            "_id": "3371",
            "Agente": "3371",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "mcisneros@aspel.com.mx",
            "Lider": "Jose Luis Carranza Valdes",
            "Nombre": "Jose Mario Cisneros Suastegui"
        },
        {
            "_id": "3331",
            "Agente": "3331",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "vgamas@aspel.com.mx",
            "Lider": "Luis Alejandro Salgado Vergara",
            "Nombre": "Vanessa Gamas Velasco"
        },
        {
            "_id": "1906",
            "Agente": "1906",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "eparedes@aspel.com.mx",
            "Lider": "Adair Blanco Landin",
            "Nombre": "Enrique Fernando Paredes Cicero"
        },
        {
            "_id": "1420",
            "Agente": "1420",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "mflores@aspel.com.mx",
            "Lider": "Fernando Villasana Gutierrez",
            "Nombre": "Miguel Flores Basulto"
        },
        {
            "_id": "3375",
            "Agente": "3375",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "gcarreon@aspel.com.mx",
            "Lider": "Oscar Rodrigo Navarro Hernandez",
            "Nombre": "Geovanni Daniel Carreon Rico"
        },
        {
            "_id": "1964",
            "Agente": "1964",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "ifernandez@aspel.com.mx",
            "Lider": "Santos Benjamin Martinez Martinez",
            "Nombre": "Isaac Fernandez Velazquez"
        },
        {
            "_id": "802360",
            "Agente": "802360",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "osor802360@siigo.com",
            "Lider": "Andrea Zuluaga Uribe",
            "Nombre": "Libia Amparo Osorio Rodriguez"
        },
        {
            "_id": "801714",
            "Agente": "801714",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "ardi801714@siigo.com",
            "Lider": "Cristhian David Niño Barrera",
            "Nombre": "Astrid Carolina Ardila Hurtado"
        },
        {
            "_id": "802219",
            "Agente": "802219",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "medi802219@siigo.com",
            "Lider": "Santiago Jose Porras Brieva",
            "Nombre": "Luis Felipe Medina Alfonso"
        },
        {
            "_id": "802228",
            "Agente": "802228",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "aria802228@siigo.com",
            "Lider": "Diana Patricia Villarraga Forero",
            "Nombre": "Silvia Margarita Arias Larrea"
        },
        {
            "_id": "2108",
            "Agente": "2108",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "aportillo@aspel.com.mx",
            "Lider": "Marcela Vanessa Hernandez Moreno",
            "Nombre": "Alexis Abraham Sanchez Portillo"
        },
        {
            "_id": "2288",
            "Agente": "2288",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "osilva@aspel.com.mx",
            "Lider": "Alma Rosa Romero Garcia",
            "Nombre": "Olga Olivia Silva Bravo"
        },
        {
            "_id": "3419",
            "Agente": "3419",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "bmanjarrez@aspel.com.mx",
            "Lider": "Cristian Camilo Hernandez Gonzalez",
            "Nombre": "Brayan Enrique Manjarrez Gomez"
        },
        {
            "_id": "3237",
            "Agente": "3237",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "mmolina@aspel.com.mx",
            "Lider": "Marisela Yasmin Franco Cruz",
            "Nombre": "Mauricio Molina Resendiz"
        },
        {
            "_id": "801855",
            "Agente": "801855",
            "Area": "Finance & Administration",
            "Division": "Siigo Ecuador",
            "Email": "cruz801855@siigo.com",
            "Lider": "Diana Elizabeth Davila Murillo",
            "Nombre": "Dorys Elisa Cruz Sandoya"
        },
        {
            "_id": "801987",
            "Agente": "801987",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "alva801987@siigo.com",
            "Lider": "Diana Michelle Matamoros Del Rosario",
            "Nombre": "Eliana Edilma Alvarado Canales"
        },
        {
            "_id": "800908",
            "Agente": "800908",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rodr800908@siigo.com",
            "Lider": "Aleida Bibiana Garcia Suarez",
            "Nombre": "Eliana Rodriguez Cubillos"
        },
        {
            "_id": "800962",
            "Agente": "800962",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "sosa800962@siigo.com",
            "Lider": "Luisa Fernanda Caro Camargo",
            "Nombre": "Leydi Julieth Sosa Parra"
        },
        {
            "_id": "801036",
            "Agente": "801036",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "diaz801036@siigo.com",
            "Lider": "Diana Patricia Villarraga Forero",
            "Nombre": "Anyeli Valentina Diaz Rocha"
        },
        {
            "_id": "801137",
            "Agente": "801137",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "fern801137@siigo.com",
            "Lider": "James Alberto Valencia Caicedo",
            "Nombre": "Ingri Silvana Fernandez Espada"
        },
        {
            "_id": "801132",
            "Agente": "801132",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "cort801132@siigo.com",
            "Lider": "Cristian Andres Collazos Ruiz",
            "Nombre": "Sergio Luis Cortina Bassa"
        },
        {
            "_id": "801718",
            "Agente": "801718",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "diaz801718@siigo.com",
            "Lider": "Cristhian David Niño Barrera",
            "Nombre": "Yuri Nataly Diaz Acosta"
        },
        {
            "_id": "801721",
            "Agente": "801721",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gonz801721@siigo.com",
            "Lider": "Gloria Estela Osorio Montoya",
            "Nombre": "Katherine Vanessa Gonzalez Ospina"
        },
        {
            "_id": "802235",
            "Agente": "802235",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sola802235@siigo.com",
            "Lider": "Yesika Viviana Garcia Niño",
            "Nombre": "Luis David Solano Deavila"
        },
        {
            "_id": "3367",
            "Agente": "3367",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "jchavez@aspel.com.mx",
            "Lider": "Jose Luis Carranza Valdes",
            "Nombre": "Jose Luis Chavez Flores"
        },
        {
            "_id": "2323",
            "Agente": "2323",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "rlara@aspel.com.mx",
            "Lider": "Fernando Villasana Gutierrez",
            "Nombre": "Roberto Carlos Lara Esteban"
        },
        {
            "_id": "2568",
            "Agente": "2568",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "evargas@aspel.com.mx",
            "Lider": "Carlos Maximo Garrido Mascott",
            "Nombre": "Enrique Vargas Dominguez"
        },
        {
            "_id": "204476",
            "Agente": "204476",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "nava204476@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Carlos Julio Navarro Marin"
        },
        {
            "_id": "2631",
            "Agente": "2631",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "mcouoh@aspel.com.mx",
            "Lider": "Luis Alberto Flores Castillo",
            "Nombre": "Manuel Emeterio Couoh Flota"
        },
        {
            "_id": "800643",
            "Agente": "800643",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "carp800643@siigo.com",
            "Lider": "Juan Jose Correa Acosta",
            "Nombre": "Saul Steven Carpio Ochoa"
        },
        {
            "_id": "3586",
            "Agente": "3586",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jcahuich@aspel.com.mx",
            "Lider": "Juan Mauricio Jimenez Espinoza",
            "Nombre": "Jose Rodrigo Cahuich Torres"
        },
        {
            "_id": "800606",
            "Agente": "800606",
            "Area": "Marketing",
            "Division": "Siigo Ecuador",
            "Email": "cont800606@siigo.com",
            "Lider": "Jimena Marcela Prieto Bohorquez",
            "Nombre": "Jose Maria Contreras Pincay"
        },
        {
            "_id": "3417",
            "Agente": "3417",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "acasarin@aspel.com.mx",
            "Lider": "Ivan Alejandro Roa Franco",
            "Nombre": "Ariday Danae Casarin Delgado"
        },
        {
            "_id": "800278",
            "Agente": "800278",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "cant800278@siigo.com",
            "Lider": "Luis Alfredo Leon Britto",
            "Nombre": "Daniel Santiago Cantor Ortiz"
        },
        {
            "_id": "802470",
            "Agente": "802470",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "agam802470@siigo.com",
            "Lider": "Mario Fernando Nuñez Santos",
            "Nombre": "Winndy Johanis Agamez Mendoza"
        },
        {
            "_id": "801642",
            "Agente": "801642",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "hern801642@siigo.com",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre": "July Andrea Hernandez Chivata"
        },
        {
            "_id": "800380",
            "Agente": "800380",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "hern800380@siigo.com",
            "Lider": "Magda de Jesús Becerra Castañeda",
            "Nombre": "Jerson Fabian Hernandez Capera"
        },
        {
            "_id": "803482",
            "Agente": "803482",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "figu803482@siigo.com",
            "Lider": "Jessica Paola Paez Quintero",
            "Nombre": "Richard Alexander Figueroa Montero"
        },
        {
            "_id": "801576",
            "Agente": "801576",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "acos801576@siigo.com",
            "Lider": "Angel Alfonso Arciniegas Guerrero",
            "Nombre": "Daniel Eduardo Acosta Mera"
        },
        {
            "_id": "802226",
            "Agente": "802226",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "varg802226@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Yordhi Sebastian Vargas Parra"
        },
        {
            "_id": "802056",
            "Agente": "802056",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "blan802056@siigo.com",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre": "Yesica Patricia Blanco Alvarado"
        },
        {
            "_id": "800907",
            "Agente": "800907",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "agui800907@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Fabio Enrique Aguilar Ponton"
        },
        {
            "_id": "1717",
            "Agente": "1717",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "bnishizaki@aspel.com.mx",
            "Lider": "Gabriela Ayala Garcia",
            "Nombre": "Brian Yukio Nishizaki Simon"
        },
        {
            "_id": "1951",
            "Agente": "1951",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "mbarrientos@aspel.com.mx",
            "Lider": "Adair Blanco Landin",
            "Nombre": "Martha Neri Barrientos Velazquez"
        },
        {
            "_id": "3164",
            "Agente": "3164",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "jmaldonado@aspel.com.mx",
            "Lider": "Carlos Maximo Garrido Mascott",
            "Nombre": "Juana Maria Alonso Maldonado"
        },
        {
            "_id": "802246",
            "Agente": "802246",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rosa802246@siigo.com",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre": "Yulianys Paola Rosado Ospino"
        },
        {
            "_id": "802243",
            "Agente": "802243",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cast802243@siigo.com",
            "Lider": "Diana Patricia Villarraga Forero",
            "Nombre": "Melissa Paola Castro Mercado"
        },
        {
            "_id": "802170",
            "Agente": "802170",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mira802170@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Yenni Marsella Miranda Torres"
        },
        {
            "_id": "800755",
            "Agente": "800755",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pere800755@siigo.com",
            "Lider": "Estefany Rocio Muñoz Rojas",
            "Nombre": "John Stiven Perez Castro"
        },
        {
            "_id": "800756",
            "Agente": "800756",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "zuni800756@siigo.com",
            "Lider": "Luis Ernesto Parrado Ortiz",
            "Nombre": "Luis Carlos Zuñiga Chalapud"
        },
        {
            "_id": "800838",
            "Agente": "800838",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "cama800838@siigo.com",
            "Lider": "Kristein Johan Ordoñez Lopez",
            "Nombre": "Wilmer Andres Camacho Velasco"
        },
        {
            "_id": "801049",
            "Agente": "801049",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "loai801049@siigo.com",
            "Lider": "Andrea Catalina Vega Gomez",
            "Nombre": "Valentina Loaiza Gonzalez"
        },
        {
            "_id": "801405",
            "Agente": "801405",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "arro801405@siigo.com",
            "Lider": "Carlos Eduardo Sanchez Castaño",
            "Nombre": "Karen Lorena Arroyo Guerra"
        },
        {
            "_id": "802064",
            "Agente": "802064",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "abri802064@siigo.com",
            "Lider": "Luz Katerine Maya Mogollón",
            "Nombre": "Wilson Daniel Abril Garcia"
        },
        {
            "_id": "801936",
            "Agente": "801936",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "anga801936@siigo.com",
            "Lider": "Yesika Viviana Garcia Niño",
            "Nombre": "Aura Fernanda Angarita Martinez"
        },
        {
            "_id": "3464",
            "Agente": "3464",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "dlandrade@aspel.com.mx",
            "Lider": "Bismark Ivan Rios Campos",
            "Nombre": "Diana Andrade Garcia"
        },
        {
            "_id": "112203",
            "Agente": "112203",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "torr112203@siigo.com",
            "Lider": "Lina Maria Buitrago Herrera",
            "Nombre": "Mayra Victoria Torres Fernandez"
        },
        {
            "_id": "1597",
            "Agente": "1597",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "mmoo@aspel.com.mx",
            "Lider": "Adair Blanco Landin",
            "Nombre": "Maria Esmeralda Moo Can"
        },
        {
            "_id": "3313",
            "Agente": "3313",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "kavelazquez@aspel.com.mx",
            "Lider": "Jose Luis Carranza Valdes",
            "Nombre": "Karla Angelica Velazquez Maqueda"
        },
        {
            "_id": "800572",
            "Agente": "800572",
            "Area": "Finance & Administration",
            "Division": "Siigo Ecuador",
            "Email": "davi800572@siigo.com",
            "Lider": "Sergio Alberto Caicedo Ramirez",
            "Nombre": "Diana Elizabeth Davila Murillo"
        },
        {
            "_id": "267209",
            "Agente": "267209",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "Rive267209@siigo.com",
            "Lider": "Francy Idaly Muelas Muelas",
            "Nombre": "Edilberto Rivera Pacheco"
        },
        {
            "_id": "112270",
            "Agente": "112270",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "lope112270@siigo.com",
            "Lider": "John Mario Perez Diaz",
            "Nombre": "Jenny Paola Lopez Lopez"
        },
        {
            "_id": "3085",
            "Agente": "3085",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ocastro@aspel.com.mx",
            "Lider": "Armando Uriel Lopez Vazquez",
            "Nombre": "Oswaldo Castro Alva"
        },
        {
            "_id": "3443",
            "Agente": "3443",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "ablanchot@aspel.com.mx",
            "Lider": "Jorge Alfredo Huesca Martinez",
            "Nombre": "Aldahir Antonio Blanchot Escalona"
        },
        {
            "_id": "800596",
            "Agente": "800596",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "pina800596@siigo.com",
            "Lider": "Helder Yesid Castrillon Cobo",
            "Nombre": "Jorge Anibal Pinargote Figueroa"
        },
        {
            "_id": "803739",
            "Agente": "803739",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pere803739@siigo.com",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre": "David Santiago Perea Torres"
        },
        {
            "_id": "800597",
            "Agente": "800597",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "fern800597@siigo.com",
            "Lider": "Fernando Gutierrez Paramio",
            "Nombre": "Israel Andres Fernandez Armijos"
        },
        {
            "_id": "800790",
            "Agente": "800790",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "casa800790@siigo.com",
            "Lider": "Adalid Ruiz Guerrero",
            "Nombre": "Viviana Casallas Lozano"
        },
        {
            "_id": "112460",
            "Agente": "112460",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "corr112460@siigo.com",
            "Lider": "Fernando Gutierrez Paramio",
            "Nombre": "Juan David Correa Malagon"
        },
        {
            "_id": "803491",
            "Agente": "803491",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "gali803491@siigo.com",
            "Lider": "Liliana Andrea Segura Martinez",
            "Nombre": "Claudia Patricia Galindo 0"
        },
        {
            "_id": "801769",
            "Agente": "801769",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "tori801769@siigo.com",
            "Lider": "William Andres Reyes Silva",
            "Nombre": "Beatriz Eugenia Torijano Victoria"
        },
        {
            "_id": "801679",
            "Agente": "801679",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "espi801679@siigo.com",
            "Lider": "Diana Sofia Ramos Castillo",
            "Nombre": "Yiset Espinosa Villaquiran"
        },
        {
            "_id": "3192",
            "Agente": "3192",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "despinoza@aspel.com.mx",
            "Lider": "Alejandro Lopez Manzo",
            "Nombre": "Daniel Espinoza Juarez"
        },
        {
            "_id": "3294",
            "Agente": "3294",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "adorantes@aspel.com.mx",
            "Lider": "Manuel Ramirez Alvarez",
            "Nombre": "Alexis Osvaldo Dorantes Ku"
        },
        {
            "_id": "1977",
            "Agente": "1977",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "arios@aspel.com.mx",
            "Lider": "Yasser Mohamed Chain Martinez",
            "Nombre": "Ana Maria Lopez Rios"
        },
        {
            "_id": "3569",
            "Agente": "3569",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "gosnaya@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Galilea Osnaya Mendoza"
        },
        {
            "_id": "2397",
            "Agente": "2397",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "cmendoza@aspel.com.mx",
            "Lider": "Laura Erika Utrera Utrera",
            "Nombre": "Cristian Emmanuel Mendoza Barredo"
        },
        {
            "_id": "1957",
            "Agente": "1957",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "florenzo@aspel.com.mx",
            "Lider": "Armando Mariano Cirilo",
            "Nombre": "Francisco Javier Lorenzo Vargas"
        },
        {
            "_id": "1338",
            "Agente": "1338",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "mbeltran@aspel.com.mx",
            "Lider": "Christian Macin Ramirez",
            "Nombre": "Mayra Ivonne Del Valle Beltran"
        },
        {
            "_id": "3277",
            "Agente": "3277",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "eguevara@aspel.com.mx",
            "Lider": "Maria Teresa Vazquez Garza",
            "Nombre": "Elsa Julia Huitrón Guevara"
        },
        {
            "_id": "801042",
            "Agente": "801042",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "acev801042@siigo.com",
            "Lider": "Alix Dayana Archila Castillo",
            "Nombre": "Monica Acevedo Yarce"
        },
        {
            "_id": "801202",
            "Agente": "801202",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "zamb801202@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Luis Miguel Zambrano Ferrin"
        },
        {
            "_id": "801453",
            "Agente": "801453",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "chap801453@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Dayan Yineth Chaparro Pabon"
        },
        {
            "_id": "801436",
            "Agente": "801436",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "tapi801436@siigo.com",
            "Lider": "Gloria Estela Osorio Montoya",
            "Nombre": "Maria Alejandra Tapiero Coro"
        },
        {
            "_id": "801728",
            "Agente": "801728",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sanc801728@siigo.com",
            "Lider": "Francy Julieth Viracacha Gutierrez",
            "Nombre": "Daniela Sanchez Rojas"
        },
        {
            "_id": "801640",
            "Agente": "801640",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vale801640@siigo.com",
            "Lider": "Adriana Elizabeth Giron Parra",
            "Nombre": "Angie Yuranny Valencia Tarazona"
        },
        {
            "_id": "801844",
            "Agente": "801844",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "torr801844@siigo.com",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre": "Paula Andrea Torres Fajardo"
        },
        {
            "_id": "802103",
            "Agente": "802103",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "brav802103@siigo.com",
            "Lider": "Sory Yaqueline Abello Polo",
            "Nombre": "Luis Gregorio Bravo Diazgranados"
        },
        {
            "_id": "800972",
            "Agente": "800972",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "corc800972@siigo.com",
            "Lider": "Gustavo Adolfo Moreno Muñoz",
            "Nombre": "David Santiago Corchuelo Castro"
        },
        {
            "_id": "800999",
            "Agente": "800999",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cace800999@siigo.com",
            "Lider": "Cristian Camilo Hernandez Gonzalez",
            "Nombre": "German Camilo Caceres Castillo"
        },
        {
            "_id": "2580",
            "Agente": "2580",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "nclemente@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Nivardo Yonel Clemente Colon"
        },
        {
            "_id": "2079",
            "Agente": "2079",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "avillanueva@aspel.com.mx",
            "Lider": "Jose Antonio Vargas Uribe",
            "Nombre": "Alejandro Villanueva Jimenez"
        },
        {
            "_id": "800542",
            "Agente": "800542",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "sanc800542@siigo.com",
            "Lider": "Natalia Marinez Gonzalez",
            "Nombre": "Alexandra Geraldine Sanchez Paiva"
        },
        {
            "_id": "2494",
            "Agente": "2494",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "ecorro@aspel.com.mx",
            "Lider": "Alicia Santana Vallejo",
            "Nombre": "Eliab Corro Contreras"
        },
        {
            "_id": "24679",
            "Agente": "24679",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "Sanc24679@siigo.com",
            "Lider": "Juan Sebastian Molina Linares",
            "Nombre": "Juan Carlos Sanchez Velez"
        },
        {
            "_id": "112213",
            "Agente": "112213",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mora112213@siigo.com",
            "Lider": "Andres Felipe Baron Ballen",
            "Nombre": "Giovanni Alexander Morales Cruz"
        },
        {
            "_id": "1245",
            "Agente": "1245",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "acob@aspel.com.mx",
            "Lider": "Claudia Valdez Carbajal",
            "Nombre": "Ady Natividad Cob Poot"
        },
        {
            "_id": "111967",
            "Agente": "111967",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "alza111967@siigo.com",
            "Lider": "Miguel Oswaldo Velasquez Fragiel",
            "Nombre": "Diego Armando Alzate Rios"
        },
        {
            "_id": "3353",
            "Agente": "3353",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "evazquez@aspel.com.mx",
            "Lider": "Luis Alejandro Salgado Vergara",
            "Nombre": "Elios Daniel Vazquez Ferra"
        },
        {
            "_id": "109994",
            "Agente": "109994",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "garc109994@siigo.com",
            "Lider": "Juan Carlos Buitrago Rios",
            "Nombre": "Franklin Robinson Garcia Reina"
        },
        {
            "_id": "112153",
            "Agente": "112153",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ospi112153@siigo.com",
            "Lider": "Juan Pablo Henao Muñoz",
            "Nombre": "Olga Lucia Ospina Vidal"
        },
        {
            "_id": "2775",
            "Agente": "2775",
            "Area": "Finance & Administration",
            "Division": "Siigo Mexico",
            "Email": "gestrada@aspel.com.mx",
            "Lider": "María de Jesús Pérez Sanabria",
            "Nombre": "Gabriela Estrada Ramirez"
        },
        {
            "_id": "802929",
            "Agente": "802929",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "beta802929@siigo.com",
            "Lider": "Carol Andrea Cifuentes Alcala",
            "Nombre": "Carlos Alberto Betancourth Bedoya"
        },
        {
            "_id": "31297",
            "Agente": "31297",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "quin31297@siigo.com",
            "Lider": "Yessica Yulieth Quintero Martinez",
            "Nombre": "Yamid Ramiro Quintero Montaño"
        },
        {
            "_id": "2702",
            "Agente": "2702",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "cfigueroa@aspel.com.mx",
            "Lider": "Fernando Contreras Ocaña",
            "Nombre": "Claudia Veronica Figueroa Villarreal"
        },
        {
            "_id": "801568",
            "Agente": "801568",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "chav801568@siigo.com",
            "Lider": "Lizeth Janney Ayala Zuluaga",
            "Nombre": "Milena Chaves Amado"
        },
        {
            "_id": "111772",
            "Agente": "111772",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "villa111772@siigo.com",
            "Lider": "Juan Francisco Lavayen Iñamagua",
            "Nombre": "Gustavo Adolfo Villada Tapias"
        },
        {
            "_id": "802644",
            "Agente": "802644",
            "Area": "People & Culture",
            "Division": "Siigo Ecuador",
            "Email": "coro802644@siigo.com",
            "Lider": "Joan Sebastian Rodriguez 0",
            "Nombre": "Raul Alejandro Corona Amador"
        },
        {
            "_id": "802523",
            "Agente": "802523",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "liza802523@siigo.com",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre": "Yuliana Paola Lizarazo Muñoz"
        },
        {
            "_id": "802374",
            "Agente": "802374",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "quin802374@siigo.com",
            "Lider": "Alison Anaid Chica Ezeta",
            "Nombre": "Kellys Marcela Quintana Correa"
        },
        {
            "_id": "802576",
            "Agente": "802576",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "lope802576@siigo.com",
            "Lider": "Marcos Damian Lizarraga Elizalde",
            "Nombre": "Javier Oswaldo Lopez Baldion"
        },
        {
            "_id": "802527",
            "Agente": "802527",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "osor802527@siigo.com",
            "Lider": "Juan Pablo Carreño Patiño",
            "Nombre": "Oscar Andres Osorio Muñoz"
        },
        {
            "_id": "802938",
            "Agente": "802938",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "rued802938@siigo.com",
            "Lider": "Johana Catalina Peña ",
            "Nombre": "Cristian Camilo Rueda Tovar"
        },
        {
            "_id": "803537",
            "Agente": "803537",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pati803537@siigo.com",
            "Lider": "Lida Katerine Puentes Navarro",
            "Nombre": "Eliana Marcela Patiño Ramirez"
        },
        {
            "_id": "804177",
            "Agente": "804177",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "verg804177@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Danna Valentina Vergara Marsiglia"
        },
        {
            "_id": "803888",
            "Agente": "803888",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "beto803888@siigo.com",
            "Lider": "Daniel Eduardo Pinto Cepeda",
            "Nombre": "Angy Carolina Beto Marroquin"
        },
        {
            "_id": "802337",
            "Agente": "802337",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "baut802337@siigo.com",
            "Lider": "Claudia Marcela Leon Cerinza",
            "Nombre": "Sthefany Baute Guerrero"
        },
        {
            "_id": "803345",
            "Agente": "803345",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "chav803345@siigo.com",
            "Lider": "Aleida Bibiana Garcia Suarez",
            "Nombre": "Alejandra Chaves Uribe"
        },
        {
            "_id": "802370",
            "Agente": "802370",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "aria802370@siigo.com",
            "Lider": "Giovanni Baquero Hernandez",
            "Nombre": "Oscar Yamith Arias Rodriguez"
        },
        {
            "_id": "802676",
            "Agente": "802676",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "eras802676@siigo.com",
            "Lider": "Alix Dayana Archila Castillo",
            "Nombre": "Lady Katherine Eraso Josa"
        },
        {
            "_id": "802031",
            "Agente": "802031",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "torr802031@siigo.com",
            "Lider": "Jenny Paola Mendez 0",
            "Nombre": "Diana Patricia Torres Quiroz"
        },
        {
            "_id": "801831",
            "Agente": "801831",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sala801831@siigo.com",
            "Lider": "Mario Fernando Nuñez Santos",
            "Nombre": "Lina Johana Salazar Garavito"
        },
        {
            "_id": "802283",
            "Agente": "802283",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "cabr802283@siigo.com",
            "Lider": "Hugo Ernesto Cruz Casallas",
            "Nombre": "Maidy Lucila Cabrera Penna"
        },
        {
            "_id": "804013",
            "Agente": "804013",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "esco804013@siigo.com",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre": "Karina Pilar Escobar Pichon"
        },
        {
            "_id": "111713",
            "Agente": "111713",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "aran111713@siigo.com",
            "Lider": "Jose Mario Gomez Rodriguez",
            "Nombre": "Yors William Aranda Gaon"
        },
        {
            "_id": "803611",
            "Agente": "803611",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "joya803611@siigo.com",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre": "Dennis Lizeth Joya Coba"
        },
        {
            "_id": "800041",
            "Agente": "800041",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "sanc800041@siigo.com",
            "Lider": "Jorge Alberto Navia Charria",
            "Nombre": "Luis Felipe Sanchez Gomez"
        },
        {
            "_id": "800368",
            "Agente": "800368",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "neus800368@siigo.com",
            "Lider": "Nestor Oswaldo Becerra Prieto",
            "Nombre": "Karen Valentina Neusa Cufiño"
        },
        {
            "_id": "801799",
            "Agente": "801799",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "alva801799@siigo.com",
            "Lider": "Angie Camila Diaz Escobar",
            "Nombre": "Claudia Liliana Alvarez Ponguta"
        },
        {
            "_id": "803305",
            "Agente": "803305",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "boho803305@siigo.com",
            "Lider": "David Alfredo Sanchez Bastidas",
            "Nombre": "Alejandro Antonio Bohorquez Navarro"
        },
        {
            "_id": "800056",
            "Agente": "800056",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "cano800056@siigo.com",
            "Lider": "Martha Isabel Diaz Gomez",
            "Nombre": "Sully Cano Herrera"
        },
        {
            "_id": "801553",
            "Agente": "801553",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "barb801553@siigo.com",
            "Lider": "Nicolas Cifuentes Echeverry",
            "Nombre": "Juliana Inés Barbosa Fajardo"
        },
        {
            "_id": "54",
            "Agente": "54",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "lbaas@aspel.com.mx",
            "Lider": "Claudia Valdez Carbajal",
            "Nombre": "Luis Alberto Baas Valdez"
        },
        {
            "_id": "2162",
            "Agente": "2162",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "jcerda@aspel.com.mx",
            "Lider": "Maira Elisa Ortiz Perez",
            "Nombre": "Janet Alejandra Cerda Mukul"
        },
        {
            "_id": "801999",
            "Agente": "801999",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "cald801999@siigo.com",
            "Lider": "Walter Antonio Muñoz Antepara",
            "Nombre": "Genaro Calderón Vergara"
        },
        {
            "_id": "2315",
            "Agente": "2315",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "vmateo@aspel.com.mx",
            "Lider": "Alma Rosa Romero Garcia",
            "Nombre": "Veronica Mateo Jimenez"
        },
        {
            "_id": "800544",
            "Agente": "800544",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "cast800544@siigo.com",
            "Lider": "Gabriel Alexander Romero Mora",
            "Nombre": "Alfredo Alexander Castañeda Buenaventura"
        },
        {
            "_id": "800363",
            "Agente": "800363",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "pinz800363@siigo.com",
            "Lider": "Ecna Lisette Garcia Espinosa",
            "Nombre": "Tania Carolina Pinzon Granados"
        },
        {
            "_id": "802851",
            "Agente": "802851",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "amay802851@siigo.com",
            "Lider": "Wendy Dayana Rojas Rojas",
            "Nombre": "Paula Andrea Amaya Gil"
        },
        {
            "_id": "802393",
            "Agente": "802393",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "garc802393@siigo.com",
            "Lider": "Alison Anaid Chica Ezeta",
            "Nombre": "Andrea Natalia Garcia "
        },
        {
            "_id": "803376",
            "Agente": "803376",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "cast803376@siigo.com",
            "Lider": "Santiago Jose Porras Brieva",
            "Nombre": "Andres Felipe Castañeda Vega"
        },
        {
            "_id": "2550",
            "Agente": "2550",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "mcante@aspel.com.mx",
            "Lider": "César Augusto Rejón Vermont",
            "Nombre": "Miguel Martin Cante Cambranis"
        },
        {
            "_id": "802035",
            "Agente": "802035",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "gamb802035@siigo.com",
            "Lider": "Jenny Paola Mendez 0",
            "Nombre": "Marlene Gamboa Bohórquez"
        },
        {
            "_id": "1863",
            "Agente": "1863",
            "Area": "Product",
            "Division": "Siigo Mexico",
            "Email": "cmarquez@aspel.com.mx",
            "Lider": "Alfredo Carlos Huerta Ayala",
            "Nombre": "Candelaria Marquez Vazquez"
        },
        {
            "_id": "803316",
            "Agente": "803316",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "guer803316@siigo.com",
            "Lider": "Vicky Alejandra Hernandez Mendez",
            "Nombre": "Holmes Enrique Guerra Mendoza"
        },
        {
            "_id": "802524",
            "Agente": "802524",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "rodr802524@siigo.com",
            "Lider": "Juan Pablo Carreño Patiño",
            "Nombre": "Alonso Andres Rodriguez Acevedo"
        },
        {
            "_id": "801734",
            "Agente": "801734",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "guer801734@siigo.com",
            "Lider": "Daniel Eduardo Pinto Cepeda",
            "Nombre": "Yuli Vanessa Guerrero Ocampo"
        },
        {
            "_id": "802713",
            "Agente": "802713",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "muno802713@siigo.com",
            "Lider": "Paula Alejandra Garcia Lujan",
            "Nombre": "Cindy Milet Muñoz Martinez"
        },
        {
            "_id": "800884",
            "Agente": "800884",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "mart800884@siigo.com",
            "Lider": "David Julian Rojas Prado",
            "Nombre": "Mario Andrés Martinez Orjuela"
        },
        {
            "_id": "804184",
            "Agente": "804184",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "yepe804184@siigo.com",
            "Lider": "Maria Ximena Manrique Isaziga",
            "Nombre": "James Smit Lewis Yepez Martinez"
        },
        {
            "_id": "802534",
            "Agente": "802534",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "mora802534@siigo.com",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre": "Andrea Liliana Morales Martinez"
        },
        {
            "_id": "801096",
            "Agente": "801096",
            "Area": "Sales",
            "Division": "Siigo Uruguay",
            "Email": "ferr801096@siigo.com",
            "Lider": "John Jairo Mejia Acevedo",
            "Nombre": "Luis Ignacio Ferreira Duarte"
        },
        {
            "_id": "802338",
            "Agente": "802338",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "arjo802338@siigo.com",
            "Lider": "Jose Luis Perales Dueñas",
            "Nombre": "Angelica Nathalia Arjona Arroyabe"
        },
        {
            "_id": "2390",
            "Agente": "2390",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "echin@aspel.com.mx",
            "Lider": "Jose Antonio Vargas Uribe",
            "Nombre": "Emmanuel Chin Chan"
        },
        {
            "_id": "112209",
            "Agente": "112209",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "amad112209@siigo.com",
            "Lider": "Diana Mercedes Moncada Peraza",
            "Nombre": "Alberto Amado Malagon"
        },
        {
            "_id": "800568",
            "Agente": "800568",
            "Area": "Marketing",
            "Division": "Siigo Ecuador",
            "Email": "vill800568@siigo.com",
            "Lider": "Magda de Jesús Becerra Castañeda",
            "Nombre": "Daniela Noemi Villamar Cabrera"
        },
        {
            "_id": "800575",
            "Agente": "800575",
            "Area": "Tech",
            "Division": "Siigo Ecuador",
            "Email": "hurt800575@siigo.com",
            "Lider": "Juan Francisco Lavayen Iñamagua",
            "Nombre": "Eddie Steven Hurtado Herrera"
        },
        {
            "_id": "801736",
            "Agente": "801736",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "leon801736@siigo.com",
            "Lider": "Jorge Alberto Navia Charria",
            "Nombre": "Andres David Leon Guerra"
        },
        {
            "_id": "2698",
            "Agente": "2698",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "jzuniga@aspel.com.mx",
            "Lider": "Jose De Jesus De Aquino Curiel",
            "Nombre": "Joel Zuñiga Zuñiga"
        },
        {
            "_id": "802207",
            "Agente": "802207",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "alar802207@siigo.com",
            "Lider": "Walter Antonio Muñoz Antepara",
            "Nombre": "Christian Felipe Alarcon Nuñez"
        },
        {
            "_id": "3376",
            "Agente": "3376",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "lroman@aspel.com.mx",
            "Lider": "Oscar Rodrigo Navarro Hernandez",
            "Nombre": "Luis Antonio Matias Roman"
        },
        {
            "_id": "800969",
            "Agente": "800969",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "llor800969@siigo.com",
            "Lider": "Diana Michelle Matamoros Del Rosario",
            "Nombre": "Carlos Marcelo Llore Garcia"
        },
        {
            "_id": "2109",
            "Agente": "2109",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "cgaspar@aspel.com.mx",
            "Lider": "Armando Mariano Cirilo",
            "Nombre": "Cuauhtemoc Lopez Gaspar"
        },
        {
            "_id": "1687",
            "Agente": "1687",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "adominguez@aspel.com.mx",
            "Lider": "Fernando Contreras Ocaña",
            "Nombre": "Angel Manuel Dominguez Romero"
        },
        {
            "_id": "803722",
            "Agente": "803722",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "gilr803722@siigo.com",
            "Lider": "Marco Antonio Ortega Piedrahita",
            "Nombre": "Fabio Gil Rivera"
        },
        {
            "_id": "803881",
            "Agente": "803881",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "colm803881@siigo.com",
            "Lider": "Juliana Castro Diaz",
            "Nombre": "Derly Catherine Colmenares Martinez"
        },
        {
            "_id": "3579",
            "Agente": "3579",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "jhurtado@aspel.com.mx",
            "Lider": "Maria Dolores Basurto Rodriguez",
            "Nombre": "Jhovanna Magdalena Hurtado Flores"
        },
        {
            "_id": "1658",
            "Agente": "1658",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "ygonzalez@aspel.com.mx",
            "Lider": "Sara Noemi Euan Madera",
            "Nombre": "Yazmin Gonzalez Fernandez"
        },
        {
            "_id": "803880",
            "Agente": "803880",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "idro803880@siigo.com",
            "Lider": "Gustavo Adolfo Espinosa Toledo",
            "Nombre": "Yodi Yohana Idrobo Betancur"
        },
        {
            "_id": "802188",
            "Agente": "802188",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "carv802188@siigo.com",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre": "Andres Felipe Carvajal Vega"
        },
        {
            "_id": "802622",
            "Agente": "802622",
            "Area": "Finance & Administration",
            "Division": "Siigo Colombia",
            "Email": "coro802622@siigo.com",
            "Lider": "Maria Lucia Canales Parrales",
            "Nombre": "Gissell Yudelquy Coronado Medina"
        },
        {
            "_id": "800982",
            "Agente": "800982",
            "Area": "Customer Success",
            "Division": "Siigo Ecuador",
            "Email": "lami800982@siigo.com",
            "Lider": "Juan Francisco Amaya Alcivar",
            "Nombre": "Kevin Javier Lamilla Vera"
        },
        {
            "_id": "803258",
            "Agente": "803258",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "roja803258@siigo.com",
            "Lider": "Zayda Smith Afanador Silva",
            "Nombre": "Viviana Andrea Rojas Cortes"
        },
        {
            "_id": "802389",
            "Agente": "802389",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "fran802389@siigo.com",
            "Lider": "John Misael Martinez Montero",
            "Nombre": "Andrea Liseth Franco Martinez"
        },
        {
            "_id": "801228",
            "Agente": "801228",
            "Area": "Customer Success",
            "Division": "Siigo Uruguay",
            "Email": "mine801228@siigo.com",
            "Lider": "Ivan Elias Jusid Machado",
            "Nombre": "Diego Martin Minervini Ledesma"
        },
        {
            "_id": "803502",
            "Agente": "803502",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cald803502@siigo.com",
            "Lider": "Jessica Paola Paez Quintero",
            "Nombre": "Yenny Andrea Calderon Rodriguez"
        },
        {
            "_id": "802607",
            "Agente": "802607",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "gali802607@siigo.com",
            "Lider": "Milady Andrea Guzman Garcia",
            "Nombre": "Karen Yulieth Galindo Cuadrado"
        },
        {
            "_id": "801998",
            "Agente": "801998",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "enca801998@siigo.com",
            "Lider": "Walter Antonio Muñoz Antepara",
            "Nombre": "David Isaac Encalada Plaza"
        },
        {
            "_id": "802491",
            "Agente": "802491",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "mosq802491@siigo.com",
            "Lider": "Francisco Jose Bohorquez Torres",
            "Nombre": "David Ricardo Mosquera Muñoz"
        },
        {
            "_id": "801352",
            "Agente": "801352",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "scot801352@siigo.com",
            "Lider": "Diana Michelle Matamoros Del Rosario",
            "Nombre": "Kevin Arthur Scott Brito"
        },
        {
            "_id": "2498",
            "Agente": "2498",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "schim@aspel.com.mx",
            "Lider": "Maria Virginia Lopez Salazar",
            "Nombre": "Silvia Del Carmen Chim Can"
        },
        {
            "_id": "3223",
            "Agente": "3223",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "yfranco@aspel.com.mx",
            "Lider": "Juan Camilo Gonzalez Saldaña",
            "Nombre": "Marisela Yasmin Franco Cruz"
        },
        {
            "_id": "801794",
            "Agente": "801794",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "zamb801794@siigo.com",
            "Lider": "Milady Andrea Guzman Garcia",
            "Nombre": "Angie Lorena Zambrano Gomez"
        },
        {
            "_id": "1601",
            "Agente": "1601",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "brios@aspel.com.mx",
            "Lider": "Harrison Martinez Callejas",
            "Nombre": "Bismark Ivan Rios Campos"
        },
        {
            "_id": "802236",
            "Agente": "802236",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "domi802236@siigo.com",
            "Lider": "Diana Patricia Villarraga Forero",
            "Nombre": "Gianina Del Carmen Dominguez Echenique"
        },
        {
            "_id": "2317",
            "Agente": "2317",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "agalicia@aspel.com.mx",
            "Lider": "Marisol Vargas Cortes",
            "Nombre": "Alejandra Galicia Ramirez"
        },
        {
            "_id": "2117",
            "Agente": "2117",
            "Area": "People & Culture",
            "Division": "Siigo Mexico",
            "Email": "balcantara@aspel.com.mx",
            "Lider": "Laura Alejandra Rios Jaramillo",
            "Nombre": "Blanca Alcantara Escobedo"
        },
        {
            "_id": "802036",
            "Agente": "802036",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "polo802036@siigo.com",
            "Lider": "Francy Julieth Viracacha Gutierrez",
            "Nombre": "Yisel Paola Polo Alcazar"
        },
        {
            "_id": "2598",
            "Agente": "2598",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "ocordoba@aspel.com.mx",
            "Lider": "Pedro Miguel Ocampo Ix",
            "Nombre": "Oscar Jair Cordoba Alfonsin"
        },
        {
            "_id": "802829",
            "Agente": "802829",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "tima802829@siigo.com",
            "Lider": "Wendy Dayana Rojas Rojas",
            "Nombre": "Raul Alejandro Timaran Vinelli"
        },
        {
            "_id": "803497",
            "Agente": "803497",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "mart803497@siigo.com",
            "Lider": "Liliana Andrea Segura Martinez",
            "Nombre": "Tania Lizeth Martinez Fuentes"
        },
        {
            "_id": "802519",
            "Agente": "802519",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "arri802519@siigo.com",
            "Lider": "Juan Pablo Carreño Patiño",
            "Nombre": "Valentina Arrieta Jimenez"
        },
        {
            "_id": "802745",
            "Agente": "802745",
            "Area": "Marketing",
            "Division": "Siigo Colombia",
            "Email": "toba802745@siigo.com",
            "Lider": "Gina Estefania Melo Sanchez",
            "Nombre": "Felipe Tobar Rodriguez"
        },
        {
            "_id": "803521",
            "Agente": "803521",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "barr803521@siigo.com",
            "Lider": "Francy Julieth Viracacha Gutierrez",
            "Nombre": "Wendy Sandry Barraza Correa"
        },
        {
            "_id": "801593",
            "Agente": "801593",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "sala801593@siigo.com",
            "Lider": "Juan Carlos Sanchez Velez",
            "Nombre": "Maria Camila Salazar Triviño"
        },
        {
            "_id": "802819",
            "Agente": "802819",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "baqu802819@siigo.com",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre": "Yesid Baquero Florez"
        },
        {
            "_id": "801506",
            "Agente": "801506",
            "Area": "Sales",
            "Division": "Siigo Ecuador",
            "Email": "reca801506@siigo.com",
            "Lider": "Angel Alfonso Arciniegas Guerrero",
            "Nombre": "Ivonny Gabriela Recalde Andrade"
        },
        {
            "_id": "801558",
            "Agente": "801558",
            "Area": "Tech",
            "Division": "Siigo Colombia",
            "Email": "ullo801558@siigo.com",
            "Lider": "Heliberto Antonio Obando Pomeo",
            "Nombre": "Diva Andrea Ulloa Acero"
        },
        {
            "_id": "804191",
            "Agente": "804191",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "pere804191@siigo.com",
            "Lider": "Gloria Estela Osorio Montoya",
            "Nombre": "Karol Dallana Perez Charry"
        },
        {
            "_id": "801791",
            "Agente": "801791",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "lope801791@siigo.com",
            "Lider": "Luz Katerine Maya Mogollón",
            "Nombre": "Ana Constanza Lopez Reina"
        },
        {
            "_id": "2659",
            "Agente": "2659",
            "Area": "Tech",
            "Division": "Siigo Mexico",
            "Email": "dcanto@aspel.com.mx",
            "Lider": "Carmen Del Sagrario Ku Yah",
            "Nombre": "Daniel Alberto Canto Sanchez"
        },
        {
            "_id": "800753",
            "Agente": "800753",
            "Area": "Strategy",
            "Division": "Siigo Colombia",
            "Email": "mend800753@siigo.com",
            "Lider": "Fabian David Carreño Leon",
            "Nombre": "Diego Andres Mendez Beltran"
        },
        {
            "_id": "803694",
            "Agente": "803694",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "hida803694@siigo.com",
            "Lider": "Francy Julieth Viracacha Gutierrez",
            "Nombre": "Jessica Maria Hidalgo Bonilla"
        },
        {
            "_id": "802214",
            "Agente": "802214",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "sanc802214@siigo.com",
            "Lider": "Eliana Maria Fajardo Prada",
            "Nombre": "Meribeth Sanchez Sanchez"
        },
        {
            "_id": "803313",
            "Agente": "803313",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "ensu803313@siigo.com",
            "Lider": "Juan Jose Correa Acosta",
            "Nombre": "Beder Ensuncho Ferrer"
        },
        {
            "_id": "801608",
            "Agente": "801608",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "cast801608@siigo.com",
            "Lider": "Jesus Daniel Arzuza Roman",
            "Nombre": "Deisy Niyer Castellanos Cortes"
        },
        {
            "_id": "3199",
            "Agente": "3199",
            "Area": "Sales",
            "Division": "Siigo Mexico",
            "Email": "amontoya@aspel.com.mx",
            "Lider": "Fernando Contreras Ocaña",
            "Nombre": "Jose Antonio Herrera Montoya"
        },
        {
            "_id": "801450",
            "Agente": "801450",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "more801450@siigo.com",
            "Lider": "Nataly Carolina Gonzalez Sandoval",
            "Nombre": "Jenny Cristina Moreno Bonilla"
        },
        {
            "_id": "802231",
            "Agente": "802231",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "quir802231@siigo.com",
            "Lider": "Diana Patricia Villarraga Forero",
            "Nombre": "Gustavo Adolfo Quiroz Noriega"
        },
        {
            "_id": "802068",
            "Agente": "802068",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "vale802068@siigo.com",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre": "Darlly Maryiber Valencia Velasquez"
        },
        {
            "_id": "111815",
            "Agente": "111815",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "aria111815@siigo.com",
            "Lider": "Leida Adriana Rico Herrera",
            "Nombre": "Camilo Andres Arias Espinosa"
        },
        {
            "_id": "802050",
            "Agente": "802050",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "cepe802050@siigo.com",
            "Lider": "Jenny Paola Mendez 0",
            "Nombre": "Lesly Dayana Cepeda Mariño"
        },
        {
            "_id": "111875",
            "Agente": "111875",
            "Area": "Sales",
            "Division": "Siigo Colombia",
            "Email": "orte111875@siigo.com",
            "Lider": "Diana Maria Naranjo Mattheus",
            "Nombre": "Jadith Vanessa Ortega Ruiz"
        },
        {
            "_id": "801854",
            "Agente": "801854",
            "Area": "Customer Success",
            "Division": "Siigo Colombia",
            "Email": "comb801854@siigo.com",
            "Lider": "Diana Sofia Ramos Castillo",
            "Nombre": "Nidia Consuelo Combariza Tinjacá"
        },
        {
            "_id": "110020",
            "Agente": "110020",
            "Area": "Product",
            "Division": "Siigo Colombia",
            "Email": "belt110020@siigo.com",
            "Lider": "Adalid Ruiz Guerrero",
            "Nombre": "Deisy Viviana Beltran Beltran"
        },
        {
            "_id": "801309",
            "Agente": "801309",
            "Area": "People & Culture",
            "Division": "Siigo Colombia",
            "Email": "rozo801309@siigo.com",
            "Lider": "Claudia Marcela Cano Forero",
            "Nombre": "Karol Lizeth Rozo Pinzon"
        },
        {
            "_id": "2005",
            "Agente": "2005",
            "Area": "Customer Success",
            "Division": "Siigo Mexico",
            "Email": "overa@aspel.com.mx",
            "Lider": "Adair Blanco Landin",
            "Nombre": "Oscar Vera Perez"
        },
        {
            "_id": "1335",
            "Agente": "1335",
            "Area": "Marketing",
            "Division": "Siigo Mexico",
            "Email": "ibrenam@aspel.com.mx",
            "Lider": "Julian Ernesto Morales Garzon",
            "Nombre": "Karla Ivette Breña Martinez"
        }
    ],
    "hcDimensionamiento": [
        {
            "_id": "1143269894",
            "Cedula": "1143269894",
            "Celula": "Texto Nube",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre Completo": "Angellys Cecilia Jimenez Aguas"
        },
        {
            "_id": "1001043634",
            "Cedula": "1001043634",
            "Celula": "Texto Nube",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre Completo": "Angie Gabriela Espinosa Molina"
        },
        {
            "_id": "1024560724",
            "Cedula": "1024560724",
            "Celula": "Texto Nube",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre Completo": "Angie Marcela Gutierrez Moreno"
        },
        {
            "_id": "1098787159",
            "Cedula": "1098787159",
            "Celula": "Texto Nube",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre Completo": "Azly Valencia Gonzalez"
        },
        {
            "_id": "1022369392",
            "Cedula": "1022369392",
            "Celula": "Texto Nube",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre Completo": "Cindy Rodriguez Bautista"
        },
        {
            "_id": "1035440213",
            "Cedula": "1035440213",
            "Celula": "Texto Nube",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre Completo": "Daniela Bayona Gaviria"
        },
        {
            "_id": "1006820388",
            "Cedula": "1006820388",
            "Celula": "Texto Nube",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre Completo": "Daniela Johana Ruiz Torres"
        },
        {
            "_id": "1024494631",
            "Cedula": "1024494631",
            "Celula": "Texto Nube",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre Completo": "Fanny Elvira Rodriguez"
        },
        {
            "_id": "53930236",
            "Cedula": "53930236",
            "Celula": "Texto Nube",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre Completo": "Luz Adriana Rey Villarraga"
        },
        {
            "_id": "1083026746",
            "Cedula": "1083026746",
            "Celula": "Texto Nube",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre Completo": "Luz Elena Torres Baslanoa"
        },
        {
            "_id": "1234095390",
            "Cedula": "1234095390",
            "Celula": "Texto Nube",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre Completo": "Maryuris Sarife Meza Fernandez"
        },
        {
            "_id": "1000502517",
            "Cedula": "1000502517",
            "Celula": "Texto Nube",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre Completo": "Nicole Stefani Corredor"
        },
        {
            "_id": "1057594815",
            "Cedula": "1057594815",
            "Celula": "Texto Nube",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre Completo": "William Andres Navas Cardenas"
        },
        {
            "_id": "1072430137",
            "Cedula": "1072430137",
            "Celula": "Texto Nube",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre Completo": "Laura Juliana Cardenas Montero"
        },
        {
            "_id": "1140889018",
            "Cedula": "1140889018",
            "Celula": "Texto Nube",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre Completo": "Lisleidy Angarita Prada"
        },
        {
            "_id": "1107520695",
            "Cedula": "1107520695",
            "Celula": "Texto Nube",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre Completo": "Laura Vanessa Andrade Hurtado"
        },
        {
            "_id": "1049649123",
            "Cedula": "1049649123",
            "Celula": "Texto Nube",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre Completo": "Angelica Juliana Vargas Espinosa"
        },
        {
            "_id": "1057591347",
            "Cedula": "1057591347",
            "Celula": "Call Nube",
            "Lider": "Luz Katerine Maya Mogollon",
            "Nombre Completo": "Yiselt Nataly Suarez Mesa"
        },
        {
            "_id": "1002370313",
            "Cedula": "1002370313",
            "Celula": "Texto Nube",
            "Lider": "Olga Lucia Ospina Vidal",
            "Nombre Completo": "Christian Camilo Borda Sanabria"
        },
        {
            "_id": "1143464229",
            "Cedula": "1143464229",
            "Celula": "Call Nube",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre Completo": "Alexandra Vanesa Pozo Padilla"
        },
        {
            "_id": "1050040490",
            "Cedula": "1050040490",
            "Celula": "Call Nube",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre Completo": "Carlos Andres Lora Deavila"
        },
        {
            "_id": "52838173",
            "Cedula": "52838173",
            "Celula": "Call Nube",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre Completo": "Carolina Castro Castano"
        },
        {
            "_id": "1143439034",
            "Cedula": "1143439034",
            "Celula": "Call Nube",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre Completo": "Cesar Wilfrido Vargas Romero"
        },
        {
            "_id": "1111202735",
            "Cedula": "1111202735",
            "Celula": "Call Nube",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre Completo": "Cristian David Diaz Giraldo"
        },
        {
            "_id": "1007552616",
            "Cedula": "1007552616",
            "Celula": "Call Nube",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre Completo": "Dionicio Pacheco Ibarra"
        },
        {
            "_id": "1143268053",
            "Cedula": "1143268053",
            "Celula": "Call Nube",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre Completo": "Georgelly Johanna De la hoz Fontalvo"
        },
        {
            "_id": "1018417353",
            "Cedula": "1018417353",
            "Celula": "Call Nube",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre Completo": "Jhon Jairo Garzon Ramirez"
        },
        {
            "_id": "1010213611",
            "Cedula": "1010213611",
            "Celula": "Call Nube",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre Completo": "Natalia Cardona Gutierrez"
        },
        {
            "_id": "1007744681",
            "Cedula": "1007744681",
            "Celula": "Call Nube",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre Completo": "Maria Jose Ardila Ojeda"
        },
        {
            "_id": "1057600396",
            "Cedula": "1057600396",
            "Celula": "Call Nube",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre Completo": "Maria Jose Guatibonza Florez"
        },
        {
            "_id": "1052021077",
            "Cedula": "1052021077",
            "Celula": "Call Nube",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre Completo": "Maria Paula Martinez Perez"
        },
        {
            "_id": "1057604381",
            "Cedula": "1057604381",
            "Celula": "Call Nube",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre Completo": "Mariana Del Pilar Cubides Cely"
        },
        {
            "_id": "1005417933",
            "Cedula": "1005417933",
            "Celula": "Call Nube",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre Completo": "Miguel Alberto Arciria Guevara"
        },
        {
            "_id": "1006513195",
            "Cedula": "1006513195",
            "Celula": "Call Nube",
            "Lider": "Adrian Alberto Salom Berrio",
            "Nombre Completo": "Paula valentina Suarez"
        },
        {
            "_id": "1143408555",
            "Cedula": "1143408555",
            "Celula": "Call Nube",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre Completo": "Angie Carolina Tovio Machado"
        },
        {
            "_id": "1007273704",
            "Cedula": "1007273704",
            "Celula": "Call Nube",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre Completo": "Bibiana Yaneth Guevara Cubillos"
        },
        {
            "_id": "1113685444",
            "Cedula": "1113685444",
            "Celula": "Call Nube",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre Completo": "Brayan David Molina Castaneda"
        },
        {
            "_id": "1129581345",
            "Cedula": "1129581345",
            "Celula": "Call Nube",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre Completo": "Cindy Margarita Ahumada Bustamante"
        },
        {
            "_id": "1045741957",
            "Cedula": "1045741957",
            "Celula": "Call Nube",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre Completo": "David Santiago Perea Torres"
        },
        {
            "_id": "1052020835",
            "Cedula": "1052020835",
            "Celula": "Call Nube",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre Completo": "Derly Cristina Castro Nino"
        },
        {
            "_id": "10545489",
            "Cedula": "10545489",
            "Celula": "Call Nube",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre Completo": "Efrain Fernandez Trujillo"
        },
        {
            "_id": "1049646905",
            "Cedula": "1049646905",
            "Celula": "Call Nube",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre Completo": "Eliana Marcela Lopez Sierra"
        },
        {
            "_id": "1065653410",
            "Cedula": "1065653410",
            "Celula": "Call Nube",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre Completo": "Jhonatan Araujo Guette"
        },
        {
            "_id": "80183109",
            "Cedula": "80183109",
            "Celula": "Call Nube",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre Completo": "Luis Alberto Ceron Velasco"
        },
        {
            "_id": "1113676129",
            "Cedula": "1113676129",
            "Celula": "Call Nube",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre Completo": "Luis Edgar Velez Guerrero"
        },
        {
            "_id": "1143369164",
            "Cedula": "1143369164",
            "Celula": "Call Nube",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre Completo": "Luz Neyla Navas Paredes"
        },
        {
            "_id": "1002032821",
            "Cedula": "1002032821",
            "Celula": "Call Nube",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre Completo": "Shayla Alejandra Gutierrez Ledesma"
        },
        {
            "_id": "1004710578",
            "Cedula": "1004710578",
            "Celula": "Call Nube",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre Completo": "Omar Santiago Cruz Munoz"
        },
        {
            "_id": "1082850928",
            "Cedula": "1082850928",
            "Celula": "Call Nube",
            "Lider": "Angie Nicoll Torres Celemin",
            "Nombre Completo": "Yorleinis Melissa Gonzalez Rivas"
        },
        {
            "_id": "1143384265",
            "Cedula": "1143384265",
            "Celula": "Call Nube",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre Completo": "Ariel De Jesus Restrepo Lambertino"
        },
        {
            "_id": "1002682679",
            "Cedula": "1002682679",
            "Celula": "Call Nube",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre Completo": "Daisy Dayana Rojas Bernal"
        },
        {
            "_id": "1074486488",
            "Cedula": "1074486488",
            "Celula": "Call Nube",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre Completo": "Francy Ney Ramirez Bustamante"
        },
        {
            "_id": "1007133977",
            "Cedula": "1007133977",
            "Celula": "Call Nube",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre Completo": "Harol Styd De Alba Taibel"
        },
        {
            "_id": "1140903389",
            "Cedula": "1140903389",
            "Celula": "Call Nube",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre Completo": "Jhon Sebastian Bolano Rua"
        },
        {
            "_id": "1000273818",
            "Cedula": "1000273818",
            "Celula": "Call Nube",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre Completo": "Laura Jireth Avellaneda Puentes"
        },
        {
            "_id": "1110478157",
            "Cedula": "1110478157",
            "Celula": "Call Nube",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre Completo": "Leidy Alejandra Barbosa Amorocho"
        },
        {
            "_id": "1045738299",
            "Cedula": "1045738299",
            "Celula": "Call Nube",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre Completo": "Luis Cesar Calderon Lopez"
        },
        {
            "_id": "1049627596",
            "Cedula": "1049627596",
            "Celula": "Call Nube",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre Completo": "Luis Felipe Diaz Gonzalez"
        },
        {
            "_id": "1000439869",
            "Cedula": "1000439869",
            "Celula": "Call Nube",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre Completo": "Luisa Fernanda Suazo Tamayo"
        },
        {
            "_id": "1007648700",
            "Cedula": "1007648700",
            "Celula": "Call Nube",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre Completo": "Naiceth Dajana Naissir Torres"
        },
        {
            "_id": "37579480",
            "Cedula": "37579480",
            "Celula": "Call Nube",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre Completo": "Nidian Carolina Monroy Serrano"
        },
        {
            "_id": "1047472692",
            "Cedula": "1047472692",
            "Celula": "Call Nube",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre Completo": "Raquel Puello Pajaro"
        },
        {
            "_id": "1018467331",
            "Cedula": "1018467331",
            "Celula": "Call Nube",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre Completo": "Orledys Paola Ramos Salgado"
        },
        {
            "_id": "1048266579",
            "Cedula": "1048266579",
            "Celula": "Call Nube",
            "Lider": "Angie Tatiana Reyes Perez",
            "Nombre Completo": "Yulys Del Carmen Calderon Perez"
        },
        {
            "_id": "1000518042",
            "Cedula": "1000518042",
            "Celula": "Call Nube",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre Completo": "David Steven Yusti Buitrago"
        },
        {
            "_id": "1024524725",
            "Cedula": "1024524725",
            "Celula": "Call Nube",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre Completo": "Diana Katherin Segura Leguizamon"
        },
        {
            "_id": "1052996202",
            "Cedula": "1052996202",
            "Celula": "Call Nube",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre Completo": "Elisa Maria Mendez Manjarres"
        },
        {
            "_id": "1143155469",
            "Cedula": "1143155469",
            "Celula": "Call Nube",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre Completo": "Gabriel Angel Blanco Rojas"
        },
        {
            "_id": "1001829762",
            "Cedula": "1001829762",
            "Celula": "Call Nube",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre Completo": "Ismael De Jesus Marquez Velasquez"
        },
        {
            "_id": "1193276556",
            "Cedula": "1193276556",
            "Celula": "Call Nube",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre Completo": "Karen Lizeth Estrada Pacheco"
        },
        {
            "_id": "1030677333",
            "Cedula": "1030677333",
            "Celula": "Call Nube",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre Completo": "Leidy Paola Saavedra Parada"
        },
        {
            "_id": "1143266330",
            "Cedula": "1143266330",
            "Celula": "Call Nube",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre Completo": "Luis Enrique Pacheco Romero"
        },
        {
            "_id": "1070624656",
            "Cedula": "1070624656",
            "Celula": "Call Nube",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre Completo": "Maria Camila Santos Nunez"
        },
        {
            "_id": "1045759235",
            "Cedula": "1045759235",
            "Celula": "Call Nube",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre Completo": "Maria Paula Del Villar De La Hoz"
        },
        {
            "_id": "1045697690",
            "Cedula": "1045697690",
            "Celula": "Call Nube",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre Completo": "Orlando Elias Navarro Vasquez"
        },
        {
            "_id": "1007314177",
            "Cedula": "1007314177",
            "Celula": "Call Nube",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre Completo": "Sheyla Mercedes Utria Gomez"
        },
        {
            "_id": "1032360860",
            "Cedula": "1032360860",
            "Celula": "Call Nube",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre Completo": "Sonia Rosa Rueda Gomez"
        },
        {
            "_id": "1051447664",
            "Cedula": "1051447664",
            "Celula": "Call Nube",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre Completo": "Venis Quintana Herrera"
        },
        {
            "_id": "1100971009",
            "Cedula": "1100971009",
            "Celula": "Call Nube",
            "Lider": "Diana Carolina Linares Gonzalez",
            "Nombre Completo": "Yuri Andrea Molina Castillo"
        },
        {
            "_id": "45564382",
            "Cedula": "45564382",
            "Celula": "Call Nube",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre Completo": "Aurora Margarita Polo Alean"
        },
        {
            "_id": "65702125",
            "Cedula": "65702125",
            "Celula": "Call Nube",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre Completo": "Dalia Rocio Murillo Paramo"
        },
        {
            "_id": "1026261209",
            "Cedula": "1026261209",
            "Celula": "Call Nube",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre Completo": "Gustavo Alberto Rico Ruiz"
        },
        {
            "_id": "1140900349",
            "Cedula": "1140900349",
            "Celula": "Call Nube",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre Completo": "Idalia Marcela Acosta Rodriguez"
        },
        {
            "_id": "1234092366",
            "Cedula": "1234092366",
            "Celula": "Call Nube",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre Completo": "Jose Fernando Palacio Manotas"
        },
        {
            "_id": "1023979425",
            "Cedula": "1023979425",
            "Celula": "Call Nube",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre Completo": "Karoll Dayanna Gallego Preciado"
        },
        {
            "_id": "1083570775",
            "Cedula": "1083570775",
            "Celula": "Call Nube",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre Completo": "Kiara Yarnelyne Guette Ramirez"
        },
        {
            "_id": "1016074644",
            "Cedula": "1016074644",
            "Celula": "Call Nube",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre Completo": "Lady Tatiana Cifuentes Avila"
        },
        {
            "_id": "1050956592",
            "Cedula": "1050956592",
            "Celula": "Call Nube",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre Completo": "Liliana Margarita Rocha Perez"
        },
        {
            "_id": "1006032469",
            "Cedula": "1006032469",
            "Celula": "Call Nube",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre Completo": "Maria Angelica Useche Cardenas"
        },
        {
            "_id": "1143263414",
            "Cedula": "1143263414",
            "Celula": "Call Nube",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre Completo": "Stefanny Becerra Garcia"
        },
        {
            "_id": "1007395400",
            "Cedula": "1007395400",
            "Celula": "Call Nube",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre Completo": "Monica Judith Valderrama Tibaduiza"
        },
        {
            "_id": "1003520110",
            "Cedula": "1003520110",
            "Celula": "Call Nube",
            "Lider": "Fernando Sotto Cardozo",
            "Nombre Completo": "Yully Stephania Pena Guzman"
        },
        {
            "_id": "1033790465",
            "Cedula": "1033790465",
            "Celula": "Call Nube",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre Completo": "Aaron Andres Ballen Perez"
        },
        {
            "_id": "53089344",
            "Cedula": "53089344",
            "Celula": "Call Nube",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre Completo": "Angelica Cardenas Rodriguez"
        },
        {
            "_id": "1053614127",
            "Cedula": "1053614127",
            "Celula": "Call Nube",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre Completo": "July Andrea Hernandez Chivata"
        },
        {
            "_id": "1003913422",
            "Cedula": "1003913422",
            "Celula": "Call Nube",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre Completo": "Laura Yulieth Ruiz Rodriguez"
        },
        {
            "_id": "1049648819",
            "Cedula": "1049648819",
            "Celula": "Call Nube",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre Completo": "Tatiana Marisol Faura Pizon"
        },
        {
            "_id": "1121967175",
            "Cedula": "1121967175",
            "Celula": "Call Nube",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre Completo": "Karem Xiomara Holguin Aragon"
        },
        {
            "_id": "1144166594",
            "Cedula": "1144166594",
            "Celula": "Call Nube",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre Completo": "Luisa Fernanda Mora Hurtado"
        },
        {
            "_id": "1053855438",
            "Cedula": "1053855438",
            "Celula": "Call Nube",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre Completo": "Maira Alejandra Sanchez Villa"
        },
        {
            "_id": "1047407537",
            "Cedula": "1047407537",
            "Celula": "Call Nube",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre Completo": "Mariluz Nunez Martinez"
        },
        {
            "_id": "1042457263",
            "Cedula": "1042457263",
            "Celula": "Call Nube",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre Completo": "Mayra Alejandra Martinez Del Guercio"
        },
        {
            "_id": "1057590136",
            "Cedula": "1057590136",
            "Celula": "Call Nube",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre Completo": "Monica Lizeth Sanabria Vargas"
        },
        {
            "_id": "1001876558",
            "Cedula": "1001876558",
            "Celula": "Call Nube",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre Completo": "Yerlys Silva Polanco"
        },
        {
            "_id": "1143169160",
            "Cedula": "1143169160",
            "Celula": "Call Nube",
            "Lider": "Hancel Rafael Salcedo Mendoza",
            "Nombre Completo": "Karina Pilar Escobar Pichon"
        },
        {
            "_id": "1033807543",
            "Cedula": "1033807543",
            "Celula": "Call Nube",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre Completo": "Chirlly Esneidy Sabogal Castro"
        },
        {
            "_id": "1051667894",
            "Cedula": "1051667894",
            "Celula": "Call Nube",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre Completo": "Katerine Paola Benthan Gutierrez"
        },
        {
            "_id": "1106779797",
            "Cedula": "1106779797",
            "Celula": "Call Nube",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre Completo": "Liset Viviana Cerquera Cuellar"
        },
        {
            "_id": "1001995941",
            "Cedula": "1001995941",
            "Celula": "Call Nube",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre Completo": "Schalia Munoz Lerma"
        },
        {
            "_id": "1116249046",
            "Cedula": "1116249046",
            "Celula": "Call Nube",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre Completo": "Suly Johanna Bejarano Loaiza"
        },
        {
            "_id": "79886467",
            "Cedula": "79886467",
            "Celula": "Call Nube",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre Completo": "William Yamid Diaz Buitrago"
        },
        {
            "_id": "1056804373",
            "Cedula": "1056804373",
            "Celula": "Call Nube",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre Completo": "Yenny Veronica Ramirez Gil"
        },
        {
            "_id": "1098749965",
            "Cedula": "1098749965",
            "Celula": "Call Nube",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre Completo": "Yesica Patricia Blanco Alvarado"
        },
        {
            "_id": "1061820333",
            "Cedula": "1061820333",
            "Celula": "Call Nube",
            "Lider": "Jazbleydi Andrea Nunez Montoya",
            "Nombre Completo": "Juan Andres Chate Capote"
        },
        {
            "_id": "1094249274",
            "Cedula": "1094249274",
            "Celula": "Call Nube",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre Completo": "Adriana Villamizar Jaimes"
        },
        {
            "_id": "1221978212",
            "Cedula": "1221978212",
            "Celula": "Call Nube",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre Completo": "Ana Karina De Jesus Fernandez Glen"
        },
        {
            "_id": "1007288313",
            "Cedula": "1007288313",
            "Celula": "Call Nube",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre Completo": "Eliana Yulitza Moreno Mendez"
        },
        {
            "_id": "1192816756",
            "Cedula": "1192816756",
            "Celula": "Call Nube",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre Completo": "Elias David Barrios Alvarez"
        },
        {
            "_id": "1001053560",
            "Cedula": "1001053560",
            "Celula": "Call Nube",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre Completo": "Gabrielle Alejandra Ramirez Celi"
        },
        {
            "_id": "1121864298",
            "Cedula": "1121864298",
            "Celula": "Call Nube",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre Completo": "Jennyffer Adriana Murillo Betancourt"
        },
        {
            "_id": "1099215393",
            "Cedula": "1099215393",
            "Celula": "Call Nube",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre Completo": "Jessica Andrea Sanabria Gerena"
        },
        {
            "_id": "1001933734",
            "Cedula": "1001933734",
            "Celula": "Call Nube",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre Completo": "Kevin Andres Duran Bravo"
        },
        {
            "_id": "1003519488",
            "Cedula": "1003519488",
            "Celula": "Call Nube",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre Completo": "Laura Lucia Garcia Acosta"
        },
        {
            "_id": "1023905191",
            "Cedula": "1023905191",
            "Celula": "Call Nube",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre Completo": "Leslie Geraldine Rodriguez"
        },
        {
            "_id": "1057598995",
            "Cedula": "1057598995",
            "Celula": "Call Nube",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre Completo": "Leidy Johana Pacheco Salcedo"
        },
        {
            "_id": "1056076052",
            "Cedula": "1056076052",
            "Celula": "Call Nube",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre Completo": "Paula Andrea Torres Fajardo"
        },
        {
            "_id": "1001897305",
            "Cedula": "1001897305",
            "Celula": "Call Nube",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre Completo": "Richard Eduardo Carrillo Villa"
        },
        {
            "_id": "1014211561",
            "Cedula": "1014211561",
            "Celula": "Call Nube",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre Completo": "Yuly Alexandra Balaguera Rodriguez"
        },
        {
            "_id": "1140891373",
            "Cedula": "1140891373",
            "Celula": "Call Nube",
            "Lider": "Jefrey Julian Sanchez Celemin",
            "Nombre Completo": "Carolay Patricia Leiva Buelvas"
        },
        {
            "_id": "1235038508",
            "Cedula": "1235038508",
            "Celula": "Call Nube",
            "Lider": "Luz Katerine Maya Mogollon",
            "Nombre Completo": "Adrian Esteban Briceno Bautista"
        },
        {
            "_id": "1005718580",
            "Cedula": "1005718580",
            "Celula": "Call Nube",
            "Lider": "Luz Katerine Maya Mogollon",
            "Nombre Completo": "Alejandra Reyes Nieto"
        },
        {
            "_id": "1012358258",
            "Cedula": "1012358258",
            "Celula": "Call Nube",
            "Lider": "Luz Katerine Maya Mogollon",
            "Nombre Completo": "Ana Constanza Lopez Reina"
        },
        {
            "_id": "1004346066",
            "Cedula": "1004346066",
            "Celula": "Call Nube",
            "Lider": "Luz Katerine Maya Mogollon",
            "Nombre Completo": "Giselle Karina Estrada Banderas"
        },
        {
            "_id": "1110554630",
            "Cedula": "1110554630",
            "Celula": "Call Nube",
            "Lider": "Luz Katerine Maya Mogollon",
            "Nombre Completo": "Jhon Weimar Prada Rojas"
        },
        {
            "_id": "55307136",
            "Cedula": "55307136",
            "Celula": "Call Nube",
            "Lider": "Luz Katerine Maya Mogollon",
            "Nombre Completo": "Karina Maria Gomez Alvarez"
        },
        {
            "_id": "1143251202",
            "Cedula": "1143251202",
            "Celula": "Call Nube",
            "Lider": "Luz Katerine Maya Mogollon",
            "Nombre Completo": "Katherine Becerra Garcia"
        },
        {
            "_id": "1042439205",
            "Cedula": "1042439205",
            "Celula": "Call Nube",
            "Lider": "Luz Katerine Maya Mogollon",
            "Nombre Completo": "Keyla Maritza Cerra Pacheco"
        },
        {
            "_id": "1013676352",
            "Cedula": "1013676352",
            "Celula": "Call Nube",
            "Lider": "Luz Katerine Maya Mogollon",
            "Nombre Completo": "Leydi Carolina Serrano Ortega"
        },
        {
            "_id": "1020799659",
            "Cedula": "1020799659",
            "Celula": "Call Nube",
            "Lider": "Luz Katerine Maya Mogollon",
            "Nombre Completo": "Maritza Jeraldin Acevedo Nope"
        },
        {
            "_id": "1106789499",
            "Cedula": "1106789499",
            "Celula": "Call Nube",
            "Lider": "Luz Katerine Maya Mogollon",
            "Nombre Completo": "Silvia Dahyana Arango Cardona"
        },
        {
            "_id": "1143437949",
            "Cedula": "1143437949",
            "Celula": "Call Nube",
            "Lider": "Luz Katerine Maya Mogollon",
            "Nombre Completo": "Susana Del Carmen Guera Lujan"
        },
        {
            "_id": "40343317",
            "Cedula": "40343317",
            "Celula": "Call Nube",
            "Lider": "Luz Katerine Maya Mogollon",
            "Nombre Completo": "Tatiana Veronica Prieto Prieto"
        },
        {
            "_id": "1018477685",
            "Cedula": "1018477685",
            "Celula": "Call Nube",
            "Lider": "Luz Katerine Maya Mogollon",
            "Nombre Completo": "William Fernando Medina Camargo"
        },
        {
            "_id": "1057596225",
            "Cedula": "1057596225",
            "Celula": "Call Nube",
            "Lider": "Mario Fernando Nunez Santos",
            "Nombre Completo": "Alejandro Rosas Chaparro"
        },
        {
            "_id": "1044422128",
            "Cedula": "1044422128",
            "Celula": "Call Nube",
            "Lider": "Mario Fernando Nunez Santos",
            "Nombre Completo": "Angie Paola Macias Corro"
        },
        {
            "_id": "43828216",
            "Cedula": "43828216",
            "Celula": "Call Nube",
            "Lider": "Mario Fernando Nunez Santos",
            "Nombre Completo": "Clara Elena Velez Perez"
        },
        {
            "_id": "1045742631",
            "Cedula": "1045742631",
            "Celula": "Call Nube",
            "Lider": "Mario Fernando Nunez Santos",
            "Nombre Completo": "Daniela Patricia Ramos Acosta"
        },
        {
            "_id": "53075007",
            "Cedula": "53075007",
            "Celula": "Call Nube",
            "Lider": "Mario Fernando Nunez Santos",
            "Nombre Completo": "Diana Paola Garcia Arias"
        },
        {
            "_id": "1075291230",
            "Cedula": "1075291230",
            "Celula": "Call Nube",
            "Lider": "Mario Fernando Nunez Santos",
            "Nombre Completo": "Karen Vanessa Zapata Candela"
        },
        {
            "_id": "1057603754",
            "Cedula": "1057603754",
            "Celula": "Call Nube",
            "Lider": "Mario Fernando Nunez Santos",
            "Nombre Completo": "Manuel Antonio Perez Rodriguez"
        },
        {
            "_id": "1143265076",
            "Cedula": "1143265076",
            "Celula": "Call Nube",
            "Lider": "Mario Fernando Nunez Santos",
            "Nombre Completo": "Nelson Jesus Castro Rodriguez"
        },
        {
            "_id": "1143162879",
            "Cedula": "1143162879",
            "Celula": "Call Nube",
            "Lider": "Mario Fernando Nunez Santos",
            "Nombre Completo": "Regina Sofia Carroll Arango"
        },
        {
            "_id": "1007157285",
            "Cedula": "1007157285",
            "Celula": "Call Nube",
            "Lider": "Mario Fernando Nunez Santos",
            "Nombre Completo": "Sara Lucia Acosta Valle"
        },
        {
            "_id": "1057601587",
            "Cedula": "1057601587",
            "Celula": "Call Nube",
            "Lider": "Mario Fernando Nunez Santos",
            "Nombre Completo": "Sulma Carolina Landinez Alvarado"
        },
        {
            "_id": "1001882094",
            "Cedula": "1001882094",
            "Celula": "Call Nube",
            "Lider": "Mario Fernando Nunez Santos",
            "Nombre Completo": "Yudis Maria Baldovino Hoyos"
        },
        {
            "_id": "1049618411",
            "Cedula": "1049618411",
            "Celula": "Call Nube",
            "Lider": "Mario Fernando Nunez Santos",
            "Nombre Completo": "Wendy Yolany Avila Avila"
        },
        {
            "_id": "1143163733",
            "Cedula": "1143163733",
            "Celula": "Call Nube",
            "Lider": "Mario Fernando Nunez Santos",
            "Nombre Completo": "Winndy Johanis Agamez Mendoza"
        },
        {
            "_id": "1083021763",
            "Cedula": "1083021763",
            "Celula": "Ilimitada",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre Completo": "Ana Marcela Palma Arvilla"
        },
        {
            "_id": "84074002",
            "Cedula": "84074002",
            "Celula": "Ilimitada",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre Completo": "Arlen Rafael Robles Mejia"
        },
        {
            "_id": "98701553",
            "Cedula": "98701553",
            "Celula": "Ilimitada",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre Completo": "Carlos Andres Lopera"
        },
        {
            "_id": "1214732469",
            "Cedula": "1214732469",
            "Celula": "Ilimitada",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre Completo": "Cristian Camilo Romero Acevedo"
        },
        {
            "_id": "42773481",
            "Cedula": "42773481",
            "Celula": "Ilimitada",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre Completo": "Elizabeth Moreno Vargas"
        },
        {
            "_id": "1085299564",
            "Cedula": "1085299564",
            "Celula": "Ilimitada",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre Completo": "Erika Marcela Narvaez Barco"
        },
        {
            "_id": "15531454",
            "Cedula": "15531454",
            "Celula": "Ilimitada",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre Completo": "Francisco Luis Arcila Luna"
        },
        {
            "_id": "1020842214",
            "Cedula": "1020842214",
            "Celula": "Ilimitada",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre Completo": "Gabriella Caroline Martinez Bolivar"
        },
        {
            "_id": "1095824513",
            "Cedula": "1095824513",
            "Celula": "Ilimitada",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre Completo": "Gina Brigette Diaz Gomez"
        },
        {
            "_id": "1017140019",
            "Cedula": "1017140019",
            "Celula": "Ilimitada",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre Completo": "Hillary Cristina Gomez Pineda"
        },
        {
            "_id": "1002242786",
            "Cedula": "1002242786",
            "Celula": "Ilimitada",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre Completo": "Ivana Teresa Meza de la Rosa"
        },
        {
            "_id": "1024567924",
            "Cedula": "1024567924",
            "Celula": "Ilimitada",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre Completo": "Jessica Lorena Perez Barrera"
        },
        {
            "_id": "1006026464",
            "Cedula": "1006026464",
            "Celula": "Ilimitada",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre Completo": "Leidy Daniela Corral Aranzalez"
        },
        {
            "_id": "1000160911",
            "Cedula": "1000160911",
            "Celula": "Ilimitada",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre Completo": "Leydi Nataly Ramirez Barreto"
        },
        {
            "_id": "1014263052",
            "Cedula": "1014263052",
            "Celula": "Ilimitada",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre Completo": "Luisa Fernanda Calderon Torres"
        },
        {
            "_id": "1110569471",
            "Cedula": "1110569471",
            "Celula": "Ilimitada",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre Completo": "Luisa Fernanda Ozaeta Rojas"
        },
        {
            "_id": "1045755978",
            "Cedula": "1045755978",
            "Celula": "Ilimitada",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre Completo": "Marianella Rojas Caicedo"
        },
        {
            "_id": "1083027495",
            "Cedula": "1083027495",
            "Celula": "Ilimitada",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre Completo": "Marilin Julieth Escorcia Torres"
        },
        {
            "_id": "1002233134",
            "Cedula": "1002233134",
            "Celula": "Ilimitada",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre Completo": "Milagro De Jesus Miranda Suarez"
        },
        {
            "_id": "1039453778",
            "Cedula": "1039453778",
            "Celula": "Ilimitada",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre Completo": "Monica Johana Gonzalez Espinosa"
        },
        {
            "_id": "43870207",
            "Cedula": "43870207",
            "Celula": "Ilimitada",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre Completo": "Nancy Olivia Becerra Calderon"
        },
        {
            "_id": "21527717",
            "Cedula": "21527717",
            "Celula": "Ilimitada",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre Completo": "Natalia Restrepo Garcia"
        },
        {
            "_id": "1110516714",
            "Cedula": "1110516714",
            "Celula": "Ilimitada",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre Completo": "Salma Daniela Vargas Tocora"
        },
        {
            "_id": "1140850712",
            "Cedula": "1140850712",
            "Celula": "Ilimitada",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre Completo": "Sara Lucia Alvarez Charrys"
        },
        {
            "_id": "43276584",
            "Cedula": "43276584",
            "Celula": "Ilimitada",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre Completo": "Tatiana Marcela Tamayo Serna"
        },
        {
            "_id": "1233894407",
            "Cedula": "1233894407",
            "Celula": "Ilimitada",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre Completo": "Valentina Gil Agudelo"
        },
        {
            "_id": "35145448",
            "Cedula": "35145448",
            "Celula": "Ilimitada",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre Completo": "Yennis Elena Alvarez Cuello"
        },
        {
            "_id": "1047482161",
            "Cedula": "1047482161",
            "Celula": "Ilimitada",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre Completo": "Yesmy Alejandra Ballesta Tous"
        },
        {
            "_id": "1030566220",
            "Cedula": "1030566220",
            "Celula": "Ilimitada",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre Completo": "Yildre Mirley Garcia Dominguez"
        },
        {
            "_id": "1086302692",
            "Cedula": "1086302692",
            "Celula": "Ilimitada",
            "Lider": "Pierre Fardey Velasquez Sanchez",
            "Nombre Completo": "Yuliza Katherine Diaz Cuaran"
        },
        {
            "_id": "1065611568",
            "Cedula": "1065611568",
            "Celula": "Pyme",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre Completo": "Alejandro David Ovalle Escobar"
        },
        {
            "_id": "1143168645",
            "Cedula": "1143168645",
            "Celula": "Pyme",
            "Lider": "Juan Pablo Carreno Patino",
            "Nombre Completo": "Alonso Andres Rodriguez Acevedo"
        },
        {
            "_id": "52444190",
            "Cedula": "52444190",
            "Celula": "Pyme",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre Completo": "Ana Consuelo Castro Mendoza"
        },
        {
            "_id": "1044432800",
            "Cedula": "1044432800",
            "Celula": "Pyme",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre Completo": "Ana Maria Corro Altahona"
        },
        {
            "_id": "1001887471",
            "Cedula": "1001887471",
            "Celula": "Pyme",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre Completo": "Andrea Liliana Morales Martinez"
        },
        {
            "_id": "28151938",
            "Cedula": "28151938",
            "Celula": "Pyme",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre Completo": "Angelica Maria Ascanio Rueda"
        },
        {
            "_id": "63490108",
            "Cedula": "63490108",
            "Celula": "Pyme",
            "Lider": "Juan Pablo Carreno Patino",
            "Nombre Completo": "Belsy Silva Mendez"
        },
        {
            "_id": "1007787788",
            "Cedula": "1007787788",
            "Celula": "Pyme",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre Completo": "Daniela Adriana Rico Rubiano"
        },
        {
            "_id": "1000077679",
            "Cedula": "1000077679",
            "Celula": "Pyme",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre Completo": "David Felipe Castro Leon"
        },
        {
            "_id": "1000362943",
            "Cedula": "1000362943",
            "Celula": "Pyme",
            "Lider": "Juan Pablo Carreno Patino",
            "Nombre Completo": "David Steban Buitrago Duran"
        },
        {
            "_id": "1047362685",
            "Cedula": "1047362685",
            "Celula": "Pyme",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre Completo": "Carlos Andres Truyol Gutierrez"
        },
        {
            "_id": "1090503390",
            "Cedula": "1090503390",
            "Celula": "Pyme",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre Completo": "Edwin Alexis Camargo Contreras"
        },
        {
            "_id": "72008479",
            "Cedula": "72008479",
            "Celula": "Pyme",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre Completo": "Elvis Manuel Peralta Quintero"
        },
        {
            "_id": "1043021782",
            "Cedula": "1043021782",
            "Celula": "Pyme",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre Completo": "Ivan Fernando Gomez Mercado"
        },
        {
            "_id": "53030421",
            "Cedula": "53030421",
            "Celula": "Pyme",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre Completo": "Jenny Johanna Suarez Gomez"
        },
        {
            "_id": "1110572750",
            "Cedula": "1110572750",
            "Celula": "Pyme",
            "Lider": "Juan Pablo Carreno Patino",
            "Nombre Completo": "John Sebastian Diaz Mosquera"
        },
        {
            "_id": "1006120931",
            "Cedula": "1006120931",
            "Celula": "Pyme",
            "Lider": "Juan Pablo Carreno Patino",
            "Nombre Completo": "Jonathan Estiven Acosta Payan"
        },
        {
            "_id": "1143163436",
            "Cedula": "1143163436",
            "Celula": "Pyme",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre Completo": "Jose Gregorio Ospino Sandoval"
        },
        {
            "_id": "1018491156",
            "Cedula": "1018491156",
            "Celula": "Pyme",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre Completo": "Juan Camilo Vargas Pelayo"
        },
        {
            "_id": "1015994852",
            "Cedula": "1015994852",
            "Celula": "Pyme",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre Completo": "Juan Jose Torres Mora"
        },
        {
            "_id": "1140892356",
            "Cedula": "1140892356",
            "Celula": "Pyme",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre Completo": "Karen Melissa Lobo Gonzalez"
        },
        {
            "_id": "1001939649",
            "Cedula": "1001939649",
            "Celula": "Pyme",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre Completo": "Kevin Alberto Polo Lozano"
        },
        {
            "_id": "1049644047",
            "Cedula": "1049644047",
            "Celula": "Pyme",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre Completo": "Leidy Johana Munoz Suarez"
        },
        {
            "_id": "1144148835",
            "Cedula": "1144148835",
            "Celula": "Pyme",
            "Lider": "Juan Pablo Carreno Patino",
            "Nombre Completo": "Leidy Tatiana Vela Molina"
        },
        {
            "_id": "1098779597",
            "Cedula": "1098779597",
            "Celula": "Pyme",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre Completo": "Leydi Vanessa Arciniegas Castaneda"
        },
        {
            "_id": "1073382799",
            "Cedula": "1073382799",
            "Celula": "Pyme",
            "Lider": "Juan Pablo Carreno Patino",
            "Nombre Completo": "Lilia Stella Torres Urrego"
        },
        {
            "_id": "1121923094",
            "Cedula": "1121923094",
            "Celula": "Pyme",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre Completo": "Manuel Elkin Patarroyo Herrera"
        },
        {
            "_id": "39750071",
            "Cedula": "39750071",
            "Celula": "Pyme",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre Completo": "Katalina Pulido Quimbayo"
        },
        {
            "_id": "1233495696",
            "Cedula": "1233495696",
            "Celula": "Pyme",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre Completo": "Maria Fernanda Bautista Sanchez"
        },
        {
            "_id": "1001999817",
            "Cedula": "1001999817",
            "Celula": "Pyme",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre Completo": "Maria Fernanda Rua Mendoza"
        },
        {
            "_id": "55313011",
            "Cedula": "55313011",
            "Celula": "Pyme",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre Completo": "Maria Luisa Orozco Suarez"
        },
        {
            "_id": "37272087",
            "Cedula": "37272087",
            "Celula": "Pyme",
            "Lider": "Juan Pablo Carreno Patino",
            "Nombre Completo": "Maria Zenaida Buitrago Carrillo"
        },
        {
            "_id": "1001919200",
            "Cedula": "1001919200",
            "Celula": "Pyme",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre Completo": "Melanie Paola Gonzalez Rodriguez"
        },
        {
            "_id": "1007290150",
            "Cedula": "1007290150",
            "Celula": "Pyme",
            "Lider": "Carlos Andres Valencia Bravo",
            "Nombre Completo": "Nazly Yulieth Diaz Benitez"
        },
        {
            "_id": "65630848",
            "Cedula": "65630848",
            "Celula": "Pyme",
            "Lider": "Juan Pablo Carreno Patino",
            "Nombre Completo": "Ninza Aidee Combita Nunez"
        },
        {
            "_id": "1111810353",
            "Cedula": "1111810353",
            "Celula": "Pyme",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre Completo": "Rosemary Angulo Borja"
        },
        {
            "_id": "1006505793",
            "Cedula": "1006505793",
            "Celula": "Pyme",
            "Lider": "Juan Pablo Carreno Patino",
            "Nombre Completo": "Stefanny Munoz Gasca"
        },
        {
            "_id": "1002212542",
            "Cedula": "1002212542",
            "Celula": "Pyme",
            "Lider": "Juan Pablo Carreno Patino",
            "Nombre Completo": "Valentina Arrieta Jimenez"
        },
        {
            "_id": "1054993459",
            "Cedula": "1054993459",
            "Celula": "Pyme",
            "Lider": "Juan Pablo Carreno Patino",
            "Nombre Completo": "Veronica Claros Marin"
        },
        {
            "_id": "80249132",
            "Cedula": "80249132",
            "Celula": "Pyme",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre Completo": "Wilmar Andres Rodriguez Hernandez"
        },
        {
            "_id": "57430122",
            "Cedula": "57430122",
            "Celula": "Pyme",
            "Lider": "Juan Pablo Carreno Patino",
            "Nombre Completo": "Yamile Patricia Torres Palacio"
        },
        {
            "_id": "1143464050",
            "Cedula": "1143464050",
            "Celula": "Pyme",
            "Lider": "Luzdary Bernal Villarreal",
            "Nombre Completo": "Yuliana Paola Lizarazo Munoz"
        },
        {
            "_id": "1033695430",
            "Cedula": "1033695430",
            "Celula": "Pyme",
            "Lider": "Ivan Enrique Varela Lasso",
            "Nombre Completo": "Jose Armel Zambrano Trujillo"
        },
        {
            "_id": "1033798686",
            "Cedula": "1033798686",
            "Celula": "Pyme",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre Completo": "Maria Alejandra Caldas Garcia"
        },
        {
            "_id": "1143457291",
            "Cedula": "1143457291",
            "Celula": "Pyme",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre Completo": "Jesus Alfonso Correa Camargo"
        },
        {
            "_id": "1057598657",
            "Cedula": "1057598657",
            "Celula": "Pyme",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre Completo": "Juan David Rodriguez Trujillo"
        },
        {
            "_id": "1001994591",
            "Cedula": "1001994591",
            "Celula": "Pyme",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre Completo": "Joselyn Maileth Nevado Lopez"
        },
        {
            "_id": "1049645353",
            "Cedula": "1049645353",
            "Celula": "Pyme",
            "Lider": "Ivan Enrique varela Lasso",
            "Nombre Completo": "Mery Jicela Velandia Torres"
        },
        {
            "_id": "1016102019",
            "Cedula": "1016102019",
            "Celula": "Pyme",
            "Lider": "Luisa Fernanda Rodriguez Larrota",
            "Nombre Completo": "Tiffany Michelle Ruiz Velasquez"
        },
        {
            "_id": "1233696242",
            "Cedula": "1233696242",
            "Celula": "Tecnicos",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre Completo": "Andres Felipe Hincapie Navas"
        },
        {
            "_id": "1015428735",
            "Cedula": "1015428735",
            "Celula": "Tecnicos",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre Completo": "Angelica Obando Nieto"
        },
        {
            "_id": "1097406302",
            "Cedula": "1097406302",
            "Celula": "Tecnicos",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre Completo": "Angie Julieth Toro Robayo"
        },
        {
            "_id": "1007538543",
            "Cedula": "1007538543",
            "Celula": "Tecnicos",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre Completo": "Cristhian David Hernandez Jimenez"
        },
        {
            "_id": "1005876345",
            "Cedula": "1005876345",
            "Celula": "Tecnicos",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre Completo": "Daniel Alexander Munoz Triana"
        },
        {
            "_id": "1037666090",
            "Cedula": "1037666090",
            "Celula": "Tecnicos",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre Completo": "Daniel Londono Garces"
        },
        {
            "_id": "37550900",
            "Cedula": "37550900",
            "Celula": "Tecnicos",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre Completo": "Deicy Dayana Rueda Gomez"
        },
        {
            "_id": "98557841",
            "Cedula": "98557841",
            "Celula": "Tecnicos",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre Completo": "Diego Tamayo Munoz"
        },
        {
            "_id": "1000894166",
            "Cedula": "1000894166",
            "Celula": "Tecnicos",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre Completo": "Dilan Vargas Suaza"
        },
        {
            "_id": "1113695644",
            "Cedula": "1113695644",
            "Celula": "Tecnicos",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre Completo": "Harold Mondragon Jaramillo"
        },
        {
            "_id": "1085264472",
            "Cedula": "1085264472",
            "Celula": "Tecnicos",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre Completo": "Jaime Daniel Ceron Rosales"
        },
        {
            "_id": "1113681582",
            "Cedula": "1113681582",
            "Celula": "Tecnicos",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre Completo": "Juan Sebastian Rivera Chavez"
        },
        {
            "_id": "1010061898",
            "Cedula": "1010061898",
            "Celula": "Tecnicos",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre Completo": "Miguel Angel Aldana Barragan"
        },
        {
            "_id": "1036669427",
            "Cedula": "1036669427",
            "Celula": "Tecnicos",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre Completo": "Sebastian Andres Rios Guevara"
        },
        {
            "_id": "1006098888",
            "Cedula": "1006098888",
            "Celula": "Tecnicos",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre Completo": "Valentina Correa Delgado"
        },
        {
            "_id": "80827588",
            "Cedula": "80827588",
            "Celula": "Tecnicos",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre Completo": "Yesid Baquero Florez"
        },
        {
            "_id": "1032481325",
            "Cedula": "1032481325",
            "Celula": "Tecnicos",
            "Lider": "Anderson Cubillos Ayala",
            "Nombre Completo": "Hammer Jair Cruz Guaje"
        }
    ],
    "basePersonal": [
        {
            "_id": "basePersonal",
            "data": [
                {
                    "IDENTIFICACION": "29121173",
                    "FECHA INICIAL": "2015-11-24",
                    "FECHA OPERACION": "2015-11-24",
                    "NOMBRES": "Alejandra Maria",
                    "APELLIDOS": "Rivera Bustamante",
                    "NOMBRES COMPLETOS": "Alejandra Maria Rivera Bustamante",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Maria Lucia Canales Parrales",
                    "CODIGO USUARIO": "rive109985",
                    "USUARIOFIVE9": "rive109985@siigo.com",
                    "USUARIO RED": "rive109985",
                    "CORREOELECTRONICO": "rive109985@siigo.com",
                    "STATION ID": "2606394",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Maria Lucia Canales Parrales",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022947797",
                    "FECHA INICIAL": "2019-08-08",
                    "FECHA OPERACION": "2019-08-23",
                    "NOMBRES": "Ana Milena",
                    "APELLIDOS": "Villarraga Guasca",
                    "NOMBRES COMPLETOS": "Ana Milena Villarraga Guasca",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "John Alexander Benavides Cobos",
                    "CODIGO USUARIO": "villa112269",
                    "USUARIOFIVE9": "villa112269@siigo.com",
                    "USUARIO RED": "villa112269",
                    "CORREOELECTRONICO": "villa112269@siigo.com",
                    "STATION ID": "2606395",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_John Alexander Benavides Cobos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016064290",
                    "FECHA INICIAL": "2012-04-09",
                    "FECHA OPERACION": "2012-04-24",
                    "NOMBRES": "Angelica Gil",
                    "APELLIDOS": "Umbarila",
                    "NOMBRES COMPLETOS": "Angelica Gil Umbarila",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ventas",
                    "CARGO": "Coordinador Cartera",
                    "JEFE INMEDIATO": "Lyda Patricia Reyes Pradilla",
                    "CODIGO USUARIO": "gilu19783",
                    "USUARIOFIVE9": "gilu19783@siigo.com",
                    "USUARIO RED": "gilu19783",
                    "CORREOELECTRONICO": "gilu19783@siigo.com",
                    "STATION ID": "2605979",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Lyda Patricia Reyes Pradilla",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016017538",
                    "FECHA INICIAL": "2018-12-19",
                    "FECHA OPERACION": "2018-12-19",
                    "NOMBRES": "Camilo Andres",
                    "APELLIDOS": "Arias Espinosa",
                    "NOMBRES COMPLETOS": "Camilo Andres Arias Espinosa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leida Adriana Rico Herrera",
                    "CODIGO USUARIO": "aria111815",
                    "USUARIOFIVE9": "aria111815@siigo.com",
                    "USUARIO RED": "aria111815",
                    "CORREOELECTRONICO": "aria111815@siigo.com",
                    "STATION ID": "2606472",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leida Adriana Rico Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016030419",
                    "FECHA INICIAL": "2019-04-01",
                    "FECHA OPERACION": "2019-04-01",
                    "NOMBRES": "Anyi Maribel",
                    "APELLIDOS": "Ruiz Porras",
                    "NOMBRES COMPLETOS": "Anyi Maribel Ruiz Porras",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Andres Felipe Baron Ballen",
                    "CODIGO USUARIO": "ruiz111757",
                    "USUARIOFIVE9": "ruiz111757@siigo.com",
                    "USUARIO RED": "ruiz111757",
                    "CORREOELECTRONICO": "ruiz111757@siigo.com",
                    "STATION ID": "2606506",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andres Felipe Baron Ballen",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "46664329",
                    "FECHA INICIAL": "2008-07-21",
                    "FECHA OPERACION": "2008-08-05",
                    "NOMBRES": "Sonia Mabel",
                    "APELLIDOS": "Fernandez Calderon",
                    "NOMBRES COMPLETOS": "Sonia Mabel Fernandez Calderon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Lider Renovaciones",
                    "JEFE INMEDIATO": "John Alexander Benavides Cobos",
                    "CODIGO USUARIO": "fern239663",
                    "USUARIOFIVE9": "fern239663@siigo.com",
                    "USUARIO RED": "fern239663",
                    "CORREOELECTRONICO": "fern239663@siigo.com",
                    "STATION ID": "2606410",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_John Alexander Benavides Cobos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022402670",
                    "FECHA INICIAL": "2020-01-15",
                    "FECHA OPERACION": "2020-01-15",
                    "NOMBRES": "Darianis Pineda",
                    "APELLIDOS": "Torres",
                    "NOMBRES COMPLETOS": "Darianis Pineda Torres",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones ECU",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Angie Camila Diaz Escobar",
                    "CODIGO USUARIO": "pine800161",
                    "USUARIOFIVE9": "pine800161@siigo.com",
                    "USUARIO RED": "pine800161",
                    "CORREOELECTRONICO": "pine800161@siigo.com",
                    "STATION ID": "2606980",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Camila Diaz Escobar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1020827383",
                    "FECHA INICIAL": "2019-06-04",
                    "FECHA OPERACION": "2019-06-04",
                    "NOMBRES": "Andrea",
                    "APELLIDOS": "Galindo Mora",
                    "NOMBRES COMPLETOS": "Jeimy Tatiana Galindo Mora",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones ECU",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Angie Camila Diaz Escobar",
                    "CODIGO USUARIO": "gali112096",
                    "USUARIOFIVE9": "gali112096@siigo.com",
                    "USUARIO RED": "gali112096",
                    "CORREOELECTRONICO": "gali112096@siigo.com",
                    "STATION ID": "2606867",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Camila Diaz Escobar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1110502191",
                    "FECHA INICIAL": "2011-02-02",
                    "FECHA OPERACION": "2011-02-02",
                    "NOMBRES": "Daniel Eduardo",
                    "APELLIDOS": "Pinto Cepeda",
                    "NOMBRES COMPLETOS": "Daniel Eduardo Pinto Cepeda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Liliana Patricia Giraldo Gomez",
                    "CODIGO USUARIO": "nara11965",
                    "USUARIOFIVE9": "nara11965@siigo.com",
                    "USUARIO RED": "nara11965",
                    "CORREOELECTRONICO": "nara11965@siigo.com",
                    "STATION ID": "2606489",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Patricia Giraldo Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52087649",
                    "FECHA INICIAL": "2011-04-18",
                    "FECHA OPERACION": "2011-04-18",
                    "NOMBRES": "Eliana Maria",
                    "APELLIDOS": "Fajardo Prada",
                    "NOMBRES COMPLETOS": "Eliana Maria Fajardo Prada",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Lider Renovaciones",
                    "JEFE INMEDIATO": "John Alexander Benavides Cobos",
                    "CODIGO USUARIO": "faja13613",
                    "USUARIOFIVE9": "faja13613@siigo.com",
                    "USUARIO RED": "faja13613",
                    "CORREOELECTRONICO": "faja13613@siigo.com",
                    "STATION ID": "2606398",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_John Alexander Benavides Cobos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1020820984",
                    "FECHA INICIAL": "2019-06-04",
                    "FECHA OPERACION": "2019-06-04",
                    "NOMBRES": "Erika Mariana",
                    "APELLIDOS": "Munoz Pena",
                    "NOMBRES COMPLETOS": "Erika Mariana Munoz Pena",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "muno112097",
                    "USUARIOFIVE9": "muno112097@siigo.com",
                    "USUARIO RED": "muno112097",
                    "CORREOELECTRONICO": "muno112097@siigo.com",
                    "STATION ID": "2606399",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79665511",
                    "FECHA INICIAL": "2019-09-04",
                    "FECHA OPERACION": "2019-09-04",
                    "NOMBRES": "Isidro",
                    "APELLIDOS": "Gavilan Chirivi",
                    "NOMBRES COMPLETOS": "Isidro Gavilan Chirivi",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jefferson Arvey Ramirez Ibanez",
                    "CODIGO USUARIO": "gavi112342",
                    "USUARIOFIVE9": "gavi112342@siigo.com",
                    "USUARIO RED": "gavi112342",
                    "CORREOELECTRONICO": "gavi112342@siigo.com",
                    "STATION ID": "2606511",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jefferson Arvey Ramirez Ibanez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015447027",
                    "FECHA INICIAL": "2018-07-26",
                    "FECHA OPERACION": "2018-07-26",
                    "NOMBRES": "Jhoan Andrey",
                    "APELLIDOS": "Robayo Gomez",
                    "NOMBRES COMPLETOS": "Jhoan Andrey Robayo Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Maria Lucia Canales Parrales",
                    "CODIGO USUARIO": "roba111750",
                    "USUARIOFIVE9": "roba111750@siigo.com",
                    "USUARIO RED": "roba111750",
                    "CORREOELECTRONICO": "roba111750@siigo.com",
                    "STATION ID": "2606400",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Maria Lucia Canales Parrales",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "42683158",
                    "FECHA INICIAL": "2019-02-19",
                    "FECHA OPERACION": "2019-02-19",
                    "NOMBRES": "Gloria Estela",
                    "APELLIDOS": "Osorio Montoya",
                    "NOMBRES COMPLETOS": "Gloria Estela Osorio Montoya",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Julian Ernesto Morales Garzon",
                    "CODIGO USUARIO": "osor111890",
                    "USUARIOFIVE9": "osor111890@siigo.com",
                    "USUARIO RED": "osor111890",
                    "CORREOELECTRONICO": "osor111890@siigo.com",
                    "STATION ID": "2606582",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Julian Ernesto Morales Garzon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "28822069",
                    "FECHA INICIAL": "2017-04-20",
                    "FECHA OPERACION": "2017-04-20",
                    "NOMBRES": "Nancy Johanna",
                    "APELLIDOS": "Herrera Vargas",
                    "NOMBRES COMPLETOS": "Nancy Johanna Herrera Vargas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Yeimy Milena Bustos Gutierrez",
                    "CODIGO USUARIO": "herr111416",
                    "USUARIOFIVE9": "herr111416@siigo.com",
                    "USUARIO RED": "herr111416",
                    "CORREOELECTRONICO": "herr111416@siigo.com",
                    "STATION ID": "2606409",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yeimy Milena Bustos Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019040787",
                    "FECHA INICIAL": "2018-05-07",
                    "FECHA OPERACION": "2018-05-07",
                    "NOMBRES": "Juliana",
                    "APELLIDOS": "Castro Diaz",
                    "NOMBRES COMPLETOS": "Juliana Castro Diaz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Magda Lilian Leal Rozo",
                    "CODIGO USUARIO": "cast25111",
                    "USUARIOFIVE9": "cast25111@siigo.com",
                    "USUARIO RED": "cast25111",
                    "CORREOELECTRONICO": "cast25111@siigo.com",
                    "STATION ID": "2606513",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Magda Lilian Leal Rozo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032491050",
                    "FECHA INICIAL": "2019-04-04",
                    "FECHA OPERACION": "2019-04-04",
                    "NOMBRES": "Kelly Jojana",
                    "APELLIDOS": "Fontecha Santamaria",
                    "NOMBRES COMPLETOS": "Kelly Jojana Fontecha Santamaria",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Maria Lucia Canales Parrales",
                    "CODIGO USUARIO": "font112012",
                    "USUARIOFIVE9": "font112012@siigo.com",
                    "USUARIO RED": "font112012",
                    "CORREOELECTRONICO": "font112012@siigo.com",
                    "STATION ID": "2605988",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Maria Lucia Canales Parrales",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010186332",
                    "FECHA INICIAL": "2018-08-03",
                    "FECHA OPERACION": "2018-08-18",
                    "NOMBRES": "Leidy Johanna",
                    "APELLIDOS": "Lopez Rubio",
                    "NOMBRES COMPLETOS": "Leidy Johanna Lopez Rubio",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Yeimy Milena Bustos Gutierrez",
                    "CODIGO USUARIO": "lope111559",
                    "USUARIOFIVE9": "lope111559@siigo.com",
                    "USUARIO RED": "lope111559",
                    "CORREOELECTRONICO": "lope111559@siigo.com",
                    "STATION ID": "2606403",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yeimy Milena Bustos Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53129005",
                    "FECHA INICIAL": "2018-06-13",
                    "FECHA OPERACION": "2018-06-28",
                    "NOMBRES": "Liliana Ramirez",
                    "APELLIDOS": "Vanegas",
                    "NOMBRES COMPLETOS": "Liliana Ramirez Vanegas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Sandy Yurley Galeano Lara",
                    "CODIGO USUARIO": "rami111719",
                    "USUARIOFIVE9": "rami111719@siigo.com",
                    "USUARIO RED": "rami111719",
                    "CORREOELECTRONICO": "rami111719@siigo.com",
                    "STATION ID": "2606406",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Sandy Yurley Galeano Lara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1233892993",
                    "FECHA INICIAL": "2020-03-03",
                    "FECHA OPERACION": "2020-03-03",
                    "NOMBRES": "Luisa Fernanda",
                    "APELLIDOS": "Corredor Gomez",
                    "NOMBRES COMPLETOS": "Luisa Fernanda Corredor Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Erika Mildred Avila Olaya",
                    "CODIGO USUARIO": "corr800387",
                    "USUARIOFIVE9": "corr800387@siigo.com",
                    "USUARIO RED": "corr800387",
                    "CORREOELECTRONICO": "corr800387@siigo.com",
                    "STATION ID": "2606839",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Erika Mildred Avila Olaya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010020678",
                    "FECHA INICIAL": "2019-01-21",
                    "FECHA OPERACION": "2019-01-21",
                    "NOMBRES": "Lady Johanna",
                    "APELLIDOS": "Olave Vargas",
                    "NOMBRES COMPLETOS": "Lady Johanna Olave Vargas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Johanna Patricia Torres Quintero",
                    "CODIGO USUARIO": "olav111848",
                    "USUARIOFIVE9": "olav111848@siigo.com",
                    "USUARIO RED": "olav111848",
                    "CORREOELECTRONICO": "olav111848@siigo.com",
                    "STATION ID": "2606405",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Johanna Patricia Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53065735",
                    "FECHA INICIAL": "2015-04-06",
                    "FECHA OPERACION": "2015-04-21",
                    "NOMBRES": "Martha Isabel",
                    "APELLIDOS": "Diaz Gomez",
                    "NOMBRES COMPLETOS": "Martha Isabel Diaz Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Lider Renovaciones",
                    "JEFE INMEDIATO": "John Alexander Benavides Cobos",
                    "CODIGO USUARIO": "diaz109550",
                    "USUARIOFIVE9": "diaz109550@siigo.com",
                    "USUARIO RED": "diaz109550",
                    "CORREOELECTRONICO": "diaz109550@siigo.com",
                    "STATION ID": "2606408",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_John Alexander Benavides Cobos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52433048",
                    "FECHA INICIAL": "2019-12-16",
                    "FECHA OPERACION": "2019-12-31",
                    "NOMBRES": "Sully Cano",
                    "APELLIDOS": "Herrera",
                    "NOMBRES COMPLETOS": "Sully Cano Herrera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ventas",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "cano800056",
                    "USUARIOFIVE9": "cano800056@siigo.com",
                    "USUARIO RED": "cano800056",
                    "CORREOELECTRONICO": "cano800056@siigo.com",
                    "STATION ID": "2606889",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53045437",
                    "FECHA INICIAL": "2020-02-17",
                    "FECHA OPERACION": "2020-03-03",
                    "NOMBRES": "Doli Alexandra",
                    "APELLIDOS": "Cabuya",
                    "NOMBRES COMPLETOS": "Doli Alexandra Cabuya",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Johanna Patricia Torres Quintero",
                    "CODIGO USUARIO": "cabu800298",
                    "USUARIOFIVE9": "cabu800298@siigo.com",
                    "USUARIO RED": "cabu800298",
                    "CORREOELECTRONICO": "cabu800298@siigo.com",
                    "STATION ID": "2607084",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Johanna Patricia Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019072856",
                    "FECHA INICIAL": "2019-04-22",
                    "FECHA OPERACION": "2019-04-22",
                    "NOMBRES": "Leidy Paola",
                    "APELLIDOS": "Rubiano Vargas",
                    "NOMBRES COMPLETOS": "Leidy Paola Rubiano Vargas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Sandy Yurley Galeano Lara",
                    "CODIGO USUARIO": "rubi112027",
                    "USUARIOFIVE9": "rubi112027@siigo.com",
                    "USUARIO RED": "rubi112027",
                    "CORREOELECTRONICO": "rubi112027@siigo.com",
                    "STATION ID": "2606404",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Sandy Yurley Galeano Lara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013683163",
                    "FECHA INICIAL": "2019-12-16",
                    "FECHA OPERACION": "2019-12-16",
                    "NOMBRES": "Yizeth Paola",
                    "APELLIDOS": "Mora Barco",
                    "NOMBRES COMPLETOS": "Yizeth Paola Mora Barco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Buitrago Rios",
                    "CODIGO USUARIO": "mora800092",
                    "USUARIOFIVE9": "mora800092@siigo.com",
                    "USUARIO RED": "mora800092",
                    "CORREOELECTRONICO": "mora800092@siigo.com",
                    "STATION ID": "2606883",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Buitrago Rios",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1071166954",
                    "FECHA INICIAL": "2020-02-25",
                    "FECHA OPERACION": "2020-02-25",
                    "NOMBRES": "Natalia Catherine",
                    "APELLIDOS": "Trivino Cortes",
                    "NOMBRES COMPLETOS": "Natalia Catherine Trivino Cortes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Uy",
                    "CARGO": "Lider Cartera",
                    "JEFE INMEDIATO": "Edith Angelica Gil Umbarila",
                    "CODIGO USUARIO": "triv800335",
                    "USUARIOFIVE9": "triv800335@siigo.com",
                    "USUARIO RED": "triv800335",
                    "CORREOELECTRONICO": "triv800335@siigo.com",
                    "STATION ID": "2607078",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Edith Angelica Gil Umbarila",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1100482287",
                    "FECHA INICIAL": "2019-07-29",
                    "FECHA OPERACION": "2019-07-29",
                    "NOMBRES": "Alberto Amado",
                    "APELLIDOS": "Malagon",
                    "NOMBRES COMPLETOS": "Alberto Amado Malagon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Mercedes Moncada Peraza",
                    "CODIGO USUARIO": "amad112209",
                    "USUARIOFIVE9": "amad112209@siigo.com",
                    "USUARIO RED": "amad112209",
                    "CORREOELECTRONICO": "amad112209@siigo.com",
                    "STATION ID": "2606537",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Mercedes Moncada Peraza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "72248118",
                    "FECHA INICIAL": "2019-12-02",
                    "FECHA OPERACION": "2019-12-02",
                    "NOMBRES": "Alvaro Enrique",
                    "APELLIDOS": "Mora Lopez",
                    "NOMBRES COMPLETOS": "Alvaro Enrique Mora Lopez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jesus Daniel Arzuza Roman",
                    "CODIGO USUARIO": "mora112544",
                    "USUARIOFIVE9": "mora112544@siigo.com",
                    "USUARIO RED": "mora112544",
                    "CORREOELECTRONICO": "mora112544@siigo.com",
                    "STATION ID": "2606935",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jesus Daniel Arzuza Roman",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "51727379",
                    "FECHA INICIAL": "1998-09-01",
                    "FECHA OPERACION": "1998-09-01",
                    "NOMBRES": "Amparo",
                    "APELLIDOS": "Larrota Cely",
                    "NOMBRES COMPLETOS": "Amparo Larrota Cely",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Recuperaciones",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Mario Perez Diaz",
                    "CODIGO USUARIO": "Larr4000",
                    "USUARIOFIVE9": "Larr4000@siigo.com",
                    "USUARIO RED": "Larr4000",
                    "CORREOELECTRONICO": "Larr4000@siigo.com",
                    "STATION ID": "2606469",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Mario Perez Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1140830506",
                    "FECHA INICIAL": "2019-02-06",
                    "FECHA OPERACION": "2019-02-06",
                    "NOMBRES": "Ana Cecilia",
                    "APELLIDOS": "Palacin Cantillo",
                    "NOMBRES COMPLETOS": "Ana Cecilia Palacin Cantillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jesus Daniel Arzuza Roman",
                    "CODIGO USUARIO": "pala111866",
                    "USUARIOFIVE9": "pala111866@siigo.com",
                    "USUARIO RED": "pala111866",
                    "CORREOELECTRONICO": "pala111866@siigo.com",
                    "STATION ID": "2606436",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jesus Daniel Arzuza Roman",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1152686873",
                    "FECHA INICIAL": "2014-10-06",
                    "FECHA OPERACION": "2014-10-06",
                    "NOMBRES": "Ana Maria",
                    "APELLIDOS": "Serna Gonzalez",
                    "NOMBRES COMPLETOS": "Ana Maria Serna Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Maria Naranjo Mattheus",
                    "CODIGO USUARIO": "sern40762",
                    "USUARIOFIVE9": "sern40762@siigo.com",
                    "USUARIO RED": "sern40762",
                    "CORREOELECTRONICO": "sern40762@siigo.com",
                    "STATION ID": "2606665",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Maria Naranjo Mattheus",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018492667",
                    "FECHA INICIAL": "2016-06-01",
                    "FECHA OPERACION": "2016-06-01",
                    "NOMBRES": "Andres Felipe",
                    "APELLIDOS": "Morales Velandia",
                    "NOMBRES COMPLETOS": "Andres Felipe Morales Velandia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gustavo Adolfo Espinosa Toledo",
                    "CODIGO USUARIO": "mora110150",
                    "USUARIOFIVE9": "mora110150@siigo.com",
                    "USUARIO RED": "mora110150",
                    "CORREOELECTRONICO": "mora110150@siigo.com",
                    "STATION ID": "2606652",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gustavo Adolfo Espinosa Toledo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "59828714",
                    "FECHA INICIAL": "2012-06-05",
                    "FECHA OPERACION": "2012-06-05",
                    "NOMBRES": "Aracely Del Pilar",
                    "APELLIDOS": "Granizo Munoz",
                    "NOMBRES COMPLETOS": "Aracely Del Pilar Granizo Munoz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Pasto",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Buitrago Rios",
                    "CODIGO USUARIO": "gran21002",
                    "USUARIOFIVE9": "gran21002@siigo.com",
                    "USUARIO RED": "gran21002",
                    "CORREOELECTRONICO": "gran21002@siigo.com",
                    "STATION ID": "2606458",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Buitrago Rios",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52873956",
                    "FECHA INICIAL": "2012-08-13",
                    "FECHA OPERACION": "2012-08-13",
                    "NOMBRES": "Arianis Duran",
                    "APELLIDOS": "Gutierrez",
                    "NOMBRES COMPLETOS": "Arianis Duran Gutierrez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Lina Maria Buitrago Herrera",
                    "CODIGO USUARIO": "dura22172",
                    "USUARIOFIVE9": "dura22172@siigo.com",
                    "USUARIO RED": "dura22172",
                    "CORREOELECTRONICO": "dura22172@siigo.com",
                    "STATION ID": "2606471",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Lina Maria Buitrago Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "38793128",
                    "FECHA INICIAL": "2015-08-03",
                    "FECHA OPERACION": "2015-08-03",
                    "NOMBRES": "Claudia Ximena",
                    "APELLIDOS": "Salgado Castiblanco",
                    "NOMBRES COMPLETOS": "Claudia Ximena Salgado Castiblanco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Buitrago Rios",
                    "CODIGO USUARIO": "salg109927",
                    "USUARIOFIVE9": "salg109927@siigo.com",
                    "USUARIO RED": "salg109927",
                    "CORREOELECTRONICO": "salg109927@siigo.com",
                    "STATION ID": "2606460",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Buitrago Rios",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015408825",
                    "FECHA INICIAL": "2018-09-10",
                    "FECHA OPERACION": "2018-09-10",
                    "NOMBRES": "Cristhian David",
                    "APELLIDOS": "Nino Barrera",
                    "NOMBRES COMPLETOS": "Cristhian David Nino Barrera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Julian Ernesto Morales Garzon",
                    "CODIGO USUARIO": "nino111555",
                    "USUARIOFIVE9": "nino111555@siigo.com",
                    "USUARIO RED": "nino111555",
                    "CORREOELECTRONICO": "nino111555@siigo.com",
                    "STATION ID": "2606470",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Julian Ernesto Morales Garzon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "7714533",
                    "FECHA INICIAL": "2010-06-02",
                    "FECHA OPERACION": "2010-06-02",
                    "NOMBRES": "Dairo Edilson",
                    "APELLIDOS": "Aya Hernandez",
                    "NOMBRES COMPLETOS": "Dairo Edilson Aya Hernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Neiva",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Lina Maria Buitrago Herrera",
                    "CODIGO USUARIO": "ayah267382",
                    "USUARIOFIVE9": "ayah267382@siigo.com",
                    "USUARIO RED": "ayah267382",
                    "CORREOELECTRONICO": "ayah267382@siigo.com",
                    "STATION ID": "2606424",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Lina Maria Buitrago Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80259708",
                    "FECHA INICIAL": "2014-02-03",
                    "FECHA OPERACION": "2014-02-03",
                    "NOMBRES": "David Alfredo",
                    "APELLIDOS": "Sanchez Bastidas",
                    "NOMBRES COMPLETOS": "David Alfredo Sanchez Bastidas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Magda Lilian Leal Rozo",
                    "CODIGO USUARIO": "pint32602",
                    "USUARIOFIVE9": "pint32602@siigo.com",
                    "USUARIO RED": "pint32602",
                    "CORREOELECTRONICO": "pint32602@siigo.com",
                    "STATION ID": "2606527",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Magda Lilian Leal Rozo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52104462",
                    "FECHA INICIAL": "2019-01-28",
                    "FECHA OPERACION": "2019-01-28",
                    "NOMBRES": "Diana Maria",
                    "APELLIDOS": "Naranjo Mattheus",
                    "NOMBRES COMPLETOS": "Diana Maria Naranjo Mattheus",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Liliana Patricia Giraldo Gomez",
                    "CODIGO USUARIO": "sanc111856",
                    "USUARIOFIVE9": "sanc111856@siigo.com",
                    "USUARIO RED": "sanc111856",
                    "CORREOELECTRONICO": "sanc111856@siigo.com",
                    "STATION ID": "2606074",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Patricia Giraldo Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "29116159",
                    "FECHA INICIAL": "2014-04-01",
                    "FECHA OPERACION": "2014-04-01",
                    "NOMBRES": "Diana Bejarano",
                    "APELLIDOS": "Gacharna",
                    "NOMBRES COMPLETOS": "Diana Bejarano Gacharna",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Maria Naranjo Mattheus",
                    "CODIGO USUARIO": "beja36011",
                    "USUARIOFIVE9": "beja36011@siigo.com",
                    "USUARIO RED": "beja36011",
                    "CORREOELECTRONICO": "beja36011@siigo.com",
                    "STATION ID": "2606485",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Maria Naranjo Mattheus",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015393309",
                    "FECHA INICIAL": "2014-03-06",
                    "FECHA OPERACION": "2014-03-06",
                    "NOMBRES": "Diana Milena",
                    "APELLIDOS": "Arevalo Hernandez",
                    "NOMBRES COMPLETOS": "Diana Milena Arevalo Hernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Misael Martinez Montero",
                    "CODIGO USUARIO": "arev33421",
                    "USUARIOFIVE9": "arev33421@siigo.com",
                    "USUARIO RED": "arev33421",
                    "CORREOELECTRONICO": "arev33421@siigo.com",
                    "STATION ID": "2606450",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Misael Martinez Montero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019027475",
                    "FECHA INICIAL": "2019-09-17",
                    "FECHA OPERACION": "2019-09-17",
                    "NOMBRES": "Diana Moncada",
                    "APELLIDOS": "Peraza",
                    "NOMBRES COMPLETOS": "Diana Moncada Peraza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Liliana Patricia Giraldo Gomez",
                    "CODIGO USUARIO": "monc191429",
                    "USUARIOFIVE9": "monc191429@siigo.com",
                    "USUARIO RED": "monc191429",
                    "CORREOELECTRONICO": "monc191429@siigo.com",
                    "STATION ID": "2606815",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Patricia Giraldo Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80093428",
                    "FECHA INICIAL": "2019-04-03",
                    "FECHA OPERACION": "2019-04-03",
                    "NOMBRES": "Diego Armando",
                    "APELLIDOS": "Orduz Gonzalez",
                    "NOMBRES COMPLETOS": "Diego Armando Orduz Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leida Adriana Rico Herrera",
                    "CODIGO USUARIO": "ordu111766",
                    "USUARIOFIVE9": "ordu111766@siigo.com",
                    "USUARIO RED": "ordu111766",
                    "CORREOELECTRONICO": "ordu111766@siigo.com",
                    "STATION ID": "2606473",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leida Adriana Rico Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1129564524",
                    "FECHA INICIAL": "2015-02-02",
                    "FECHA OPERACION": "2015-02-02",
                    "NOMBRES": "Eddy Pimentel",
                    "APELLIDOS": "Trujillo",
                    "NOMBRES COMPLETOS": "Eddy Pimentel Trujillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jesus Daniel Arzuza Roman",
                    "CODIGO USUARIO": "pime107897",
                    "USUARIOFIVE9": "pime107897@siigo.com",
                    "USUARIO RED": "pime107897",
                    "CORREOELECTRONICO": "pime107897@siigo.com",
                    "STATION ID": "2606438",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jesus Daniel Arzuza Roman",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53038566",
                    "FECHA INICIAL": "2015-02-09",
                    "FECHA OPERACION": "2015-02-09",
                    "NOMBRES": "Eliana Heidi",
                    "APELLIDOS": "Barreto Tovar",
                    "NOMBRES COMPLETOS": "Eliana Heidi Barreto Tovar",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Misael Martinez Montero",
                    "CODIGO USUARIO": "barr108114",
                    "USUARIOFIVE9": "barr108114@siigo.com",
                    "USUARIO RED": "barr108114",
                    "CORREOELECTRONICO": "barr108114@siigo.com",
                    "STATION ID": "2606600",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Misael Martinez Montero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1110530286",
                    "FECHA INICIAL": "2019-11-05",
                    "FECHA OPERACION": "2019-11-05",
                    "NOMBRES": "Erika Geraldine",
                    "APELLIDOS": "Mosquera Pena",
                    "NOMBRES COMPLETOS": "Erika Geraldine Mosquera Pena",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Daniel Eduardo Pinto Cepeda",
                    "CODIGO USUARIO": "mosq112492",
                    "USUARIOFIVE9": "mosq112492@siigo.com",
                    "USUARIO RED": "mosq112492",
                    "CORREOELECTRONICO": "mosq112492@siigo.com",
                    "STATION ID": "2606976",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Daniel Eduardo Pinto Cepeda",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "36309845",
                    "FECHA INICIAL": "2009-11-17",
                    "FECHA OPERACION": "2009-11-17",
                    "NOMBRES": "Erika Maria",
                    "APELLIDOS": "Quiza Morera",
                    "NOMBRES COMPLETOS": "Erika Maria Quiza Morera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Neiva",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Daniel Eduardo Pinto Cepeda",
                    "CODIGO USUARIO": "quiz258875",
                    "USUARIOFIVE9": "quiz258875@siigo.com",
                    "USUARIO RED": "quiz258875",
                    "CORREOELECTRONICO": "quiz258875@siigo.com",
                    "STATION ID": "2606426",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Daniel Eduardo Pinto Cepeda",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "12752227",
                    "FECHA INICIAL": "2015-12-21",
                    "FECHA OPERACION": "2015-12-21",
                    "NOMBRES": "Franklin Robinson",
                    "APELLIDOS": "Garcia Reina",
                    "NOMBRES COMPLETOS": "Franklin Robinson Garcia Reina",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Pasto",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Buitrago Rios",
                    "CODIGO USUARIO": "garc109994",
                    "USUARIOFIVE9": "garc109994@siigo.com",
                    "USUARIO RED": "garc109994",
                    "CORREOELECTRONICO": "garc109994@siigo.com",
                    "STATION ID": "2606462",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Buitrago Rios",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "30309950",
                    "FECHA INICIAL": "2019-02-15",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Gloria Liliana",
                    "APELLIDOS": "Castano Ossa",
                    "NOMBRES COMPLETOS": "Gloria Liliana Castano Ossa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Maria Naranjo Mattheus",
                    "CODIGO USUARIO": "cast111874",
                    "USUARIOFIVE9": "cast111874@siigo.com",
                    "USUARIO RED": "cast111874",
                    "CORREOELECTRONICO": "cast111874@siigo.com",
                    "STATION ID": "2606491",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Maria Naranjo Mattheus",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "32107896",
                    "FECHA INICIAL": "2019-02-15",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Jadith Vanessa",
                    "APELLIDOS": "Ortega Ruiz",
                    "NOMBRES COMPLETOS": "Jadith Vanessa Ortega Ruiz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Maria Naranjo Mattheus",
                    "CODIGO USUARIO": "orte111875",
                    "USUARIOFIVE9": "orte111875@siigo.com",
                    "USUARIO RED": "orte111875",
                    "CORREOELECTRONICO": "orte111875@siigo.com",
                    "STATION ID": "2606492",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Maria Naranjo Mattheus",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012414508",
                    "FECHA INICIAL": "2018-11-08",
                    "FECHA OPERACION": "2018-11-08",
                    "NOMBRES": "Jennifer Eliana",
                    "APELLIDOS": "Navarro Olaya",
                    "NOMBRES COMPLETOS": "Jennifer Eliana Navarro Olaya",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Eliana Maria Fajardo Prada",
                    "CODIGO USUARIO": "nava111799",
                    "USUARIOFIVE9": "nava111799@siigo.com",
                    "USUARIO RED": "nava111799",
                    "CORREOELECTRONICO": "nava111799@siigo.com",
                    "STATION ID": "2606938",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Eliana Maria Fajardo Prada",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030624319",
                    "FECHA INICIAL": "2014-01-20",
                    "FECHA OPERACION": "2014-01-20",
                    "NOMBRES": "Jenny Paola",
                    "APELLIDOS": "Arevalo Garcia",
                    "NOMBRES COMPLETOS": "Jenny Paola Arevalo Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Misael Martinez Montero",
                    "CODIGO USUARIO": "arev32264",
                    "USUARIOFIVE9": "arev32264@siigo.com",
                    "USUARIO RED": "arev32264",
                    "CORREOELECTRONICO": "arev32264@siigo.com",
                    "STATION ID": "2606419",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Misael Martinez Montero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "38669175",
                    "FECHA INICIAL": "2016-06-02",
                    "FECHA OPERACION": "2016-06-02",
                    "NOMBRES": "Jerley Andrea",
                    "APELLIDOS": "Rueda Garzon",
                    "NOMBRES COMPLETOS": "Jerley Andrea Rueda Garzon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Buitrago Rios",
                    "CODIGO USUARIO": "rued110152",
                    "USUARIOFIVE9": "rued110152@siigo.com",
                    "USUARIO RED": "rued110152",
                    "CORREOELECTRONICO": "rued110152@siigo.com",
                    "STATION ID": "2606463",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Buitrago Rios",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016027995",
                    "FECHA INICIAL": "2014-10-01",
                    "FECHA OPERACION": "2014-10-01",
                    "NOMBRES": "Jessica Paola",
                    "APELLIDOS": "Paez Quintero",
                    "NOMBRES COMPLETOS": "Jessica Paola Paez Quintero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Hollman Antonio Aponte Quiroga",
                    "CODIGO USUARIO": "paez40450",
                    "USUARIOFIVE9": "paez40450@siigo.com",
                    "USUARIO RED": "paez40450",
                    "CORREOELECTRONICO": "paez40450@siigo.com",
                    "STATION ID": "2606476",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Hollman Antonio Aponte Quiroga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "72278102",
                    "FECHA INICIAL": "2019-08-01",
                    "FECHA OPERACION": "2019-08-01",
                    "NOMBRES": "Jesus Daniel",
                    "APELLIDOS": "Arzuza Roman",
                    "NOMBRES COMPLETOS": "Gustavo Adolfo Espinosa Toledo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Liliana Patricia Giraldo Gomez",
                    "CODIGO USUARIO": "arzu112244",
                    "USUARIOFIVE9": "arzu112244@siigo.com",
                    "USUARIO RED": "arzu112244",
                    "CORREOELECTRONICO": "arzu112244@siigo.com",
                    "STATION ID": "2606817",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Patricia Giraldo Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1110478025",
                    "FECHA INICIAL": "2020-02-03",
                    "FECHA OPERACION": "2020-02-03",
                    "NOMBRES": "Johan Leandro",
                    "APELLIDOS": "Lara Ibanez",
                    "NOMBRES COMPLETOS": "Johan Leandro Lara Ibanez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Daniel Eduardo Pinto Cepeda",
                    "CODIGO USUARIO": "lara800181",
                    "USUARIOFIVE9": "lara800181@siigo.com",
                    "USUARIO RED": "lara800181",
                    "CORREOELECTRONICO": "lara800181@siigo.com",
                    "STATION ID": "2607046",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Daniel Eduardo Pinto Cepeda",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1130674405",
                    "FECHA INICIAL": "2018-08-01",
                    "FECHA OPERACION": "2018-08-01",
                    "NOMBRES": "John Alexander",
                    "APELLIDOS": "Cardona Hurtado",
                    "NOMBRES COMPLETOS": "John Alexander Cardona Hurtado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Buitrago Rios",
                    "CODIGO USUARIO": "card111548",
                    "USUARIOFIVE9": "card111548@siigo.com",
                    "USUARIO RED": "card111548",
                    "CORREOELECTRONICO": "card111548@siigo.com",
                    "STATION ID": "2606464",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Buitrago Rios",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79624021",
                    "FECHA INICIAL": "2014-01-20",
                    "FECHA OPERACION": "2014-01-20",
                    "NOMBRES": "John Misael",
                    "APELLIDOS": "Martinez Montero",
                    "NOMBRES COMPLETOS": "Jesus Daniel Arzuza Roman",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Liliana Patricia Giraldo Gomez",
                    "CODIGO USUARIO": "mart32313",
                    "USUARIOFIVE9": "mart32313@siigo.com",
                    "USUARIO RED": "mart32313",
                    "CORREOELECTRONICO": "mart32313@siigo.com",
                    "STATION ID": "2606818",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Patricia Giraldo Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1082908974",
                    "FECHA INICIAL": "2019-06-10",
                    "FECHA OPERACION": "2019-06-10",
                    "NOMBRES": "Jordan Hernandez",
                    "APELLIDOS": "Villegas",
                    "NOMBRES COMPLETOS": "Jordan Hernandez Villegas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Daniel Eduardo Pinto Cepeda",
                    "CODIGO USUARIO": "hern112126",
                    "USUARIOFIVE9": "hern112126@siigo.com",
                    "USUARIO RED": "hern112126",
                    "CORREOELECTRONICO": "hern112126@siigo.com",
                    "STATION ID": "2606428",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Daniel Eduardo Pinto Cepeda",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "94320202",
                    "FECHA INICIAL": "2019-06-10",
                    "FECHA OPERACION": "2019-06-10",
                    "NOMBRES": "Jorge Hernan",
                    "APELLIDOS": "Alvaran Ospina",
                    "NOMBRES COMPLETOS": "Jorge Hernan Alvaran Ospina",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Buitrago Rios",
                    "CODIGO USUARIO": "alva112127",
                    "USUARIOFIVE9": "alva112127@siigo.com",
                    "USUARIO RED": "alva112127",
                    "CORREOELECTRONICO": "alva112127@siigo.com",
                    "STATION ID": "2606465",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Buitrago Rios",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1088302658",
                    "FECHA INICIAL": "2016-03-01",
                    "FECHA OPERACION": "2016-03-01",
                    "NOMBRES": "Jose Steven",
                    "APELLIDOS": "Cardona Betancur",
                    "NOMBRES COMPLETOS": "Jose Steven Cardona Betancur",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Pereira",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Maria Naranjo Mattheus",
                    "CODIGO USUARIO": "card110029",
                    "USUARIOFIVE9": "card110029@siigo.com",
                    "USUARIO RED": "card110029",
                    "CORREOELECTRONICO": "card110029@siigo.com",
                    "STATION ID": "2606493",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Maria Naranjo Mattheus",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80007910",
                    "FECHA INICIAL": "2014-03-03",
                    "FECHA OPERACION": "2014-03-03",
                    "NOMBRES": "Juan Camilo",
                    "APELLIDOS": "Botia Suarez",
                    "NOMBRES COMPLETOS": "Juan Camilo Botia Suarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leida Adriana Rico Herrera",
                    "CODIGO USUARIO": "boti33371",
                    "USUARIOFIVE9": "boti33371@siigo.com",
                    "USUARIO RED": "boti33371",
                    "CORREOELECTRONICO": "boti33371@siigo.com",
                    "STATION ID": "2606478",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leida Adriana Rico Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79953477",
                    "FECHA INICIAL": "2006-05-30",
                    "FECHA OPERACION": "2006-05-30",
                    "NOMBRES": "Juan Carlos",
                    "APELLIDOS": "Buitrago Rios",
                    "NOMBRES COMPLETOS": "John Misael Martinez Montero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Liliana Patricia Giraldo Gomez",
                    "CODIGO USUARIO": "buit201724",
                    "USUARIOFIVE9": "buit201724@siigo.com",
                    "USUARIO RED": "buit201724",
                    "CORREOELECTRONICO": "buit201724@siigo.com",
                    "STATION ID": "2606819",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Patricia Giraldo Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "70812513",
                    "FECHA INICIAL": "2013-01-16",
                    "FECHA OPERACION": "2013-01-16",
                    "NOMBRES": "Juan Carlos",
                    "APELLIDOS": "Sanchez Velez",
                    "NOMBRES COMPLETOS": "Juan Carlos Sanchez Velez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Julian Ernesto Morales Garzon",
                    "CODIGO USUARIO": "sanc24679",
                    "USUARIOFIVE9": "sanc24679@siigo.com",
                    "USUARIO RED": "sanc24679",
                    "CORREOELECTRONICO": "sanc24679@siigo.com",
                    "STATION ID": "2606494",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Julian Ernesto Morales Garzon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1095914096",
                    "FECHA INICIAL": "2007-05-30",
                    "FECHA OPERACION": "2007-05-30",
                    "NOMBRES": "Julieth Morales",
                    "APELLIDOS": "Tabares",
                    "NOMBRES COMPLETOS": "Julieth Morales Tabares",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juvenal Reyes Silva",
                    "CODIGO USUARIO": "mora220530",
                    "USUARIOFIVE9": "mora220530@siigo.com",
                    "USUARIO RED": "mora220530",
                    "CORREOELECTRONICO": "mora220530@siigo.com",
                    "STATION ID": "2606496",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juvenal Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "13746674",
                    "FECHA INICIAL": "2016-05-02",
                    "FECHA OPERACION": "2016-05-02",
                    "NOMBRES": "Juvenal Reyes",
                    "APELLIDOS": "Silva",
                    "NOMBRES COMPLETOS": "Juan Carlos Buitrago Rios",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Liliana Patricia Giraldo Gomez",
                    "CODIGO USUARIO": "reye110115",
                    "USUARIOFIVE9": "reye110115@siigo.com",
                    "USUARIO RED": "reye110115",
                    "CORREOELECTRONICO": "reye110115@siigo.com",
                    "STATION ID": "2606486",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Patricia Giraldo Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1127958966",
                    "FECHA INICIAL": "2019-02-15",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Keibert Antonio",
                    "APELLIDOS": "Bustos Ortega",
                    "NOMBRES COMPLETOS": "Keibert Antonio Bustos Ortega",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Chile",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Chile",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "bust111876",
                    "USUARIOFIVE9": "bust111876@siigo.com",
                    "USUARIO RED": "bust111876",
                    "CORREOELECTRONICO": "bust111876@siigo.com",
                    "STATION ID": "2606498",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1020798354",
                    "FECHA INICIAL": "2020-02-04",
                    "FECHA OPERACION": "2020-02-04",
                    "NOMBRES": "Laura Daniela",
                    "APELLIDOS": "Martinez Mediana",
                    "NOMBRES COMPLETOS": "Laura Daniela Martinez Mediana",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gustavo Adolfo Espinosa Toledo",
                    "CODIGO USUARIO": "mart800228",
                    "USUARIOFIVE9": "mart800228@siigo.com",
                    "USUARIO RED": "mart800228",
                    "CORREOELECTRONICO": "mart800228@siigo.com",
                    "STATION ID": "2607099",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gustavo Adolfo Espinosa Toledo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016069423",
                    "FECHA INICIAL": "2019-09-17",
                    "FECHA OPERACION": "2019-09-17",
                    "NOMBRES": "Laurita Melo",
                    "APELLIDOS": "Benitez",
                    "NOMBRES COMPLETOS": "Laurita Melo Benitez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Mercedes Moncada Peraza",
                    "CODIGO USUARIO": "melo112383",
                    "USUARIOFIVE9": "melo112383@siigo.com",
                    "USUARIO RED": "melo112383",
                    "CORREOELECTRONICO": "melo112383@siigo.com",
                    "STATION ID": "2606432",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Mercedes Moncada Peraza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "39763290",
                    "FECHA INICIAL": "2006-01-23",
                    "FECHA OPERACION": "2006-01-23",
                    "NOMBRES": "Leida Adriana",
                    "APELLIDOS": "Rico Herrera",
                    "NOMBRES COMPLETOS": "Juvenal Reyes Silva",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Liliana Patricia Giraldo Gomez",
                    "CODIGO USUARIO": "rico194335",
                    "USUARIOFIVE9": "rico194335@siigo.com",
                    "USUARIO RED": "rico194335",
                    "CORREOELECTRONICO": "rico194335@siigo.com",
                    "STATION ID": "2606820",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Patricia Giraldo Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1020758990",
                    "FECHA INICIAL": "2014-09-22",
                    "FECHA OPERACION": "2014-09-22",
                    "NOMBRES": "Leidy Tatiana",
                    "APELLIDOS": "Rodriguez Blanco",
                    "NOMBRES COMPLETOS": "Leidy Tatiana Rodriguez Blanco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Daniel Eduardo Pinto Cepeda",
                    "CODIGO USUARIO": "rodr40340",
                    "USUARIOFIVE9": "rodr40340@siigo.com",
                    "USUARIO RED": "rodr40340",
                    "CORREOELECTRONICO": "rodr40340@siigo.com",
                    "STATION ID": "2606499",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Daniel Eduardo Pinto Cepeda",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "43827259",
                    "FECHA INICIAL": "2019-02-15",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Liliana Patricia",
                    "APELLIDOS": "Giraldo Gomez",
                    "NOMBRES COMPLETOS": "Liliana Patricia Giraldo Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "John Jairo Mejia acevedo",
                    "CODIGO USUARIO": "gira111872",
                    "USUARIOFIVE9": "gira111872@siigo.com",
                    "USUARIO RED": "gira111872",
                    "CORREOELECTRONICO": "gira111872@siigo.com",
                    "STATION ID": "2606821",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Jairo Mejia acevedo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024548044",
                    "FECHA INICIAL": "2020-02-03",
                    "FECHA OPERACION": "2020-02-03",
                    "NOMBRES": "Lina Maria",
                    "APELLIDOS": "Buitrago Herrera",
                    "NOMBRES COMPLETOS": "Lina Maria Buitrago Herrera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Jarbin Dadey Rodriguez Pena",
                    "CODIGO USUARIO": "buit800212",
                    "USUARIOFIVE9": "buit800212@siigo.com",
                    "USUARIO RED": "buit800212",
                    "CORREOELECTRONICO": "buit800212@siigo.com",
                    "STATION ID": "2607102",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jarbin Dadey Rodriguez Pena",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "42773034",
                    "FECHA INICIAL": "2019-02-15",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Lina Maria",
                    "APELLIDOS": "Pulgarin Fonnegra",
                    "NOMBRES COMPLETOS": "Lina Maria Pulgarin Fonnegra",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Maria Naranjo Mattheus",
                    "CODIGO USUARIO": "pulg111877",
                    "USUARIOFIVE9": "pulg111877@siigo.com",
                    "USUARIO RED": "pulg111877",
                    "CORREOELECTRONICO": "pulg111877@siigo.com",
                    "STATION ID": "2606421",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Maria Naranjo Mattheus",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1233909009",
                    "FECHA INICIAL": "2019-05-21",
                    "FECHA OPERACION": "2019-05-21",
                    "NOMBRES": "Liza Fernanda",
                    "APELLIDOS": "Fajardo Contreras",
                    "NOMBRES COMPLETOS": "Liza Fernanda Fajardo Contreras",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Mercedes Moncada Peraza",
                    "CODIGO USUARIO": "faja111721",
                    "USUARIOFIVE9": "faja111721@siigo.com",
                    "USUARIO RED": "faja111721",
                    "CORREOELECTRONICO": "faja111721@siigo.com",
                    "STATION ID": "2606540",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Mercedes Moncada Peraza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1110497813",
                    "FECHA INICIAL": "2019-12-02",
                    "FECHA OPERACION": "2019-12-02",
                    "NOMBRES": "Luisa Alejandra",
                    "APELLIDOS": "Vasquez Arias",
                    "NOMBRES COMPLETOS": "Luisa Alejandra Vasquez Arias",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Daniel Eduardo Pinto Cepeda",
                    "CODIGO USUARIO": "vasq800004",
                    "USUARIOFIVE9": "vasq800004@siigo.com",
                    "USUARIO RED": "vasq800004",
                    "CORREOELECTRONICO": "vasq800004@siigo.com",
                    "STATION ID": "2606903",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Daniel Eduardo Pinto Cepeda",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "42142324",
                    "FECHA INICIAL": "2015-10-01",
                    "FECHA OPERACION": "2015-10-01",
                    "NOMBRES": "Luz Adriana",
                    "APELLIDOS": "Arcila Navarro",
                    "NOMBRES COMPLETOS": "Luz Adriana Arcila Navarro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Daniel Eduardo Pinto Cepeda",
                    "CODIGO USUARIO": "arci109948",
                    "USUARIOFIVE9": "arci109948@siigo.com",
                    "USUARIO RED": "arci109948",
                    "CORREOELECTRONICO": "arci109948@siigo.com",
                    "STATION ID": "2606429",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Daniel Eduardo Pinto Cepeda",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "16378696",
                    "FECHA INICIAL": "2019-07-03",
                    "FECHA OPERACION": "2019-07-03",
                    "NOMBRES": "Marcos Goez",
                    "APELLIDOS": "Bolivar",
                    "NOMBRES COMPLETOS": "Marcos Goez Bolivar",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Buitrago Rios",
                    "CODIGO USUARIO": "goez111835",
                    "USUARIOFIVE9": "goez111835@siigo.com",
                    "USUARIO RED": "goez111835",
                    "CORREOELECTRONICO": "goez111835@siigo.com",
                    "STATION ID": "2606466",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Buitrago Rios",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098732030",
                    "FECHA INICIAL": "2018-08-01",
                    "FECHA OPERACION": "2018-08-01",
                    "NOMBRES": "Maria Camila",
                    "APELLIDOS": "Arenas Gomez",
                    "NOMBRES COMPLETOS": "Maria Camila Arenas Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juvenal Reyes Silva",
                    "CODIGO USUARIO": "aren111539",
                    "USUARIOFIVE9": "aren111539@siigo.com",
                    "USUARIO RED": "aren111539",
                    "CORREOELECTRONICO": "aren111539@siigo.com",
                    "STATION ID": "2606488",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juvenal Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1140876557",
                    "FECHA INICIAL": "2015-02-02",
                    "FECHA OPERACION": "2015-02-02",
                    "NOMBRES": "Maria Fernanda",
                    "APELLIDOS": "Penaloza Onate",
                    "NOMBRES COMPLETOS": "Maria Fernanda Penaloza Onate",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jesus Daniel Arzuza Roman",
                    "CODIGO USUARIO": "pena107914",
                    "USUARIOFIVE9": "pena107914@siigo.com",
                    "USUARIO RED": "pena107914",
                    "CORREOELECTRONICO": "pena107914@siigo.com",
                    "STATION ID": "2606853",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jesus Daniel Arzuza Roman",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "24603813",
                    "FECHA INICIAL": "1999-10-11",
                    "FECHA OPERACION": "1999-10-11",
                    "NOMBRES": "Maria Guadalupe",
                    "APELLIDOS": "Aranda Zapata",
                    "NOMBRES COMPLETOS": "Maria Guadalupe Aranda Zapata",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Maria Naranjo Mattheus",
                    "CODIGO USUARIO": "aran5633",
                    "USUARIOFIVE9": "aran5633@siigo.com",
                    "USUARIO RED": "aran5633",
                    "CORREOELECTRONICO": "aran5633@siigo.com",
                    "STATION ID": "2606501",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Maria Naranjo Mattheus",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1144161072",
                    "FECHA INICIAL": "2016-11-08",
                    "FECHA OPERACION": "2016-11-08",
                    "NOMBRES": "Maria Jose",
                    "APELLIDOS": "Villegas Vidal",
                    "NOMBRES COMPLETOS": "Maria Jose Villegas Vidal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Buitrago Rios",
                    "CODIGO USUARIO": "vill110298",
                    "USUARIOFIVE9": "vill110298@siigo.com",
                    "USUARIO RED": "vill110298",
                    "CORREOELECTRONICO": "vill110298@siigo.com",
                    "STATION ID": "2606459",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Buitrago Rios",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032468744",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-04-03",
                    "NOMBRES": "Maribel",
                    "APELLIDOS": "Garnica Lopez",
                    "NOMBRES COMPLETOS": "Maribel Garnica Lopez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Vicky Alejandra Hernandez Mendez",
                    "CODIGO USUARIO": "garn800340",
                    "USUARIOFIVE9": "garn800340@siigo.com",
                    "USUARIO RED": "garn800340",
                    "CORREOELECTRONICO": "garn800340@siigo.com",
                    "STATION ID": "2605978",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Vicky Alejandra Hernandez Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "39622293",
                    "FECHA INICIAL": "1998-09-01",
                    "FECHA OPERACION": "1998-09-01",
                    "NOMBRES": "Nancy Amparo",
                    "APELLIDOS": "Orozco Campos",
                    "NOMBRES COMPLETOS": "Nancy Amparo Orozco Campos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Daniel Eduardo Pinto Cepeda",
                    "CODIGO USUARIO": "oroz4121",
                    "USUARIOFIVE9": "oroz4121@siigo.com",
                    "USUARIO RED": "oroz4121",
                    "CORREOELECTRONICO": "oroz4121@siigo.com",
                    "STATION ID": "2606430",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Daniel Eduardo Pinto Cepeda",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53153835",
                    "FECHA INICIAL": "2014-03-20",
                    "FECHA OPERACION": "2014-03-20",
                    "NOMBRES": "Nidia Carolina",
                    "APELLIDOS": "Mendez Suarez",
                    "NOMBRES COMPLETOS": "Nidia Carolina Mendez Suarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Misael Martinez Montero",
                    "CODIGO USUARIO": "mend33897",
                    "USUARIOFIVE9": "mend33897@siigo.com",
                    "USUARIO RED": "mend33897",
                    "CORREOELECTRONICO": "mend33897@siigo.com",
                    "STATION ID": "2606422",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Misael Martinez Montero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1073504280",
                    "FECHA INICIAL": "2019-09-02",
                    "FECHA OPERACION": "2019-09-02",
                    "NOMBRES": "Omar Gerardo",
                    "APELLIDOS": "Becerra Dominguez",
                    "NOMBRES COMPLETOS": "Omar Gerardo Becerra Dominguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leida Adriana Rico Herrera",
                    "CODIGO USUARIO": "bece112337",
                    "USUARIOFIVE9": "bece112337@siigo.com",
                    "USUARIO RED": "bece112337",
                    "CORREOELECTRONICO": "bece112337@siigo.com",
                    "STATION ID": "2606480",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leida Adriana Rico Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014224026",
                    "FECHA INICIAL": "2019-06-10",
                    "FECHA OPERACION": "2019-06-10",
                    "NOMBRES": "Oscar Alejandro",
                    "APELLIDOS": "Leon Rojas",
                    "NOMBRES COMPLETOS": "Oscar Alejandro Leon Rojas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leida Adriana Rico Herrera",
                    "CODIGO USUARIO": "leon112121",
                    "USUARIOFIVE9": "leon112121@siigo.com",
                    "USUARIO RED": "leon112121",
                    "CORREOELECTRONICO": "leon112121@siigo.com",
                    "STATION ID": "2606481",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leida Adriana Rico Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79993409",
                    "FECHA INICIAL": "2014-03-03",
                    "FECHA OPERACION": "2014-03-03",
                    "NOMBRES": "Raul Ernesto",
                    "APELLIDOS": "Carvajal Acosta",
                    "NOMBRES COMPLETOS": "Raul Ernesto Carvajal Acosta",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Misael Martinez Montero",
                    "CODIGO USUARIO": "carv33372",
                    "USUARIOFIVE9": "carv33372@siigo.com",
                    "USUARIO RED": "carv33372",
                    "CORREOELECTRONICO": "carv33372@siigo.com",
                    "STATION ID": "2606455",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Misael Martinez Montero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52025700",
                    "FECHA INICIAL": "2006-04-18",
                    "FECHA OPERACION": "2006-04-18",
                    "NOMBRES": "Rocio Del Pilar",
                    "APELLIDOS": "Moreno Oviedo",
                    "NOMBRES COMPLETOS": "Rocio Del Pilar Moreno Oviedo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Misael Martinez Montero",
                    "CODIGO USUARIO": "more199643",
                    "USUARIOFIVE9": "more199643@siigo.com",
                    "USUARIO RED": "more199643",
                    "CORREOELECTRONICO": "more199643@siigo.com",
                    "STATION ID": "2606610",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Misael Martinez Montero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1095925158",
                    "FECHA INICIAL": "2020-02-03",
                    "FECHA OPERACION": "2020-02-03",
                    "NOMBRES": "Ronald Gerardo",
                    "APELLIDOS": "Lopez Calderon",
                    "NOMBRES COMPLETOS": "Ronald Gerardo Lopez Calderon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juvenal Reyes Silva",
                    "CODIGO USUARIO": "lipe800217",
                    "USUARIOFIVE9": "lipe800217@siigo.com",
                    "USUARIO RED": "lipe800217",
                    "CORREOELECTRONICO": "lipe800217@siigo.com",
                    "STATION ID": "2607111",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juvenal Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1070585948",
                    "FECHA INICIAL": "2011-12-01",
                    "FECHA OPERACION": "2011-12-01",
                    "NOMBRES": "Rosa Helena",
                    "APELLIDOS": "Piza",
                    "NOMBRES COMPLETOS": "Rosa Helena Piza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juvenal Reyes Silva",
                    "CODIGO USUARIO": "piza17507",
                    "USUARIOFIVE9": "piza17507@siigo.com",
                    "USUARIO RED": "piza17507",
                    "CORREOELECTRONICO": "piza17507@siigo.com",
                    "STATION ID": "2606706",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juvenal Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023864762",
                    "FECHA INICIAL": "2007-03-20",
                    "FECHA OPERACION": "2007-03-20",
                    "NOMBRES": "Ruby Andrea",
                    "APELLIDOS": "Escobar Caro",
                    "NOMBRES COMPLETOS": "Ruby Andrea Escobar Caro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leida Adriana Rico Herrera",
                    "CODIGO USUARIO": "esco216856",
                    "USUARIOFIVE9": "esco216856@siigo.com",
                    "USUARIO RED": "esco216856",
                    "CORREOELECTRONICO": "esco216856@siigo.com",
                    "STATION ID": "2606482",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leida Adriana Rico Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "46671763",
                    "FECHA INICIAL": "2005-03-01",
                    "FECHA OPERACION": "2005-03-01",
                    "NOMBRES": "Sandra Aydee",
                    "APELLIDOS": "Soler Becerra",
                    "NOMBRES COMPLETOS": "Sandra Aydee Soler Becerra",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Misael Martinez Montero",
                    "CODIGO USUARIO": "sole110180",
                    "USUARIOFIVE9": "sole110180@siigo.com",
                    "USUARIO RED": "sole110180",
                    "CORREOELECTRONICO": "sole110180@siigo.com",
                    "STATION ID": "2606502",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Misael Martinez Montero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010163345",
                    "FECHA INICIAL": "2019-09-17",
                    "FECHA OPERACION": "2019-09-17",
                    "NOMBRES": "Sandra Milena",
                    "APELLIDOS": "Silva Gutierrez",
                    "NOMBRES COMPLETOS": "Sandra Milena Silva Gutierrez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Mercedes Moncada Peraza",
                    "CODIGO USUARIO": "silv112384",
                    "USUARIOFIVE9": "silv112384@siigo.com",
                    "USUARIO RED": "silv112384",
                    "CORREOELECTRONICO": "silv112384@siigo.com",
                    "STATION ID": "2606434",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Mercedes Moncada Peraza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1094926645",
                    "FECHA INICIAL": "2015-02-09",
                    "FECHA OPERACION": "2015-02-09",
                    "NOMBRES": "Santiago",
                    "APELLIDOS": "Marin Raba",
                    "NOMBRES COMPLETOS": "Santiago Marin Raba",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Pereira",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leida Adriana Rico Herrera",
                    "CODIGO USUARIO": "mari108113",
                    "USUARIOFIVE9": "mari108113@siigo.com",
                    "USUARIO RED": "mari108113",
                    "CORREOELECTRONICO": "mari108113@siigo.com",
                    "STATION ID": "2606503",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leida Adriana Rico Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "22618996",
                    "FECHA INICIAL": "2013-12-16",
                    "FECHA OPERACION": "2013-12-16",
                    "NOMBRES": "Shirley Margarita",
                    "APELLIDOS": "Quintero Olivares",
                    "NOMBRES COMPLETOS": "Shirley Margarita Quintero Olivares",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jesus Daniel Arzuza Roman",
                    "CODIGO USUARIO": "quin31608",
                    "USUARIOFIVE9": "quin31608@siigo.com",
                    "USUARIO RED": "quin31608",
                    "CORREOELECTRONICO": "quin31608@siigo.com",
                    "STATION ID": "2606442",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jesus Daniel Arzuza Roman",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1129573369",
                    "FECHA INICIAL": "2019-12-02",
                    "FECHA OPERACION": "2019-12-02",
                    "NOMBRES": "Vanessa Del Carmen",
                    "APELLIDOS": "Ortega Ramirez",
                    "NOMBRES COMPLETOS": "Vanessa Del Carmen Ortega Ramirez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jesus Daniel Arzuza Roman",
                    "CODIGO USUARIO": "ortg112542",
                    "USUARIOFIVE9": "ortg112542@siigo.com",
                    "USUARIO RED": "ortg112542",
                    "CORREOELECTRONICO": "ortg112542@siigo.com",
                    "STATION ID": "2606936",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jesus Daniel Arzuza Roman",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1140840350",
                    "FECHA INICIAL": "2012-08-16",
                    "FECHA OPERACION": "2012-08-16",
                    "NOMBRES": "Vicky Alejandra",
                    "APELLIDOS": "Hernandez Mendez",
                    "NOMBRES COMPLETOS": "Vicky Alejandra Hernandez Mendez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Liliana Patricia Giraldo Gomez",
                    "CODIGO USUARIO": "hern22242",
                    "USUARIOFIVE9": "hern22242@siigo.com",
                    "USUARIO RED": "hern22242",
                    "CORREOELECTRONICO": "hern22242@siigo.com",
                    "STATION ID": "2606443",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Patricia Giraldo Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013589580",
                    "FECHA INICIAL": "2020-02-03",
                    "FECHA OPERACION": "2020-02-03",
                    "NOMBRES": "Viviana Carolina",
                    "APELLIDOS": "Lozano",
                    "NOMBRES COMPLETOS": "Viviana Carolina Lozano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Vicky Alejandra Hernandez Mendez",
                    "CODIGO USUARIO": "loza800215",
                    "USUARIOFIVE9": "loza800215@siigo.com",
                    "USUARIO RED": "loza800215",
                    "CORREOELECTRONICO": "loza800215@siigo.com",
                    "STATION ID": "2607114",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Vicky Alejandra Hernandez Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79898468",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-04-03",
                    "NOMBRES": "Walter David",
                    "APELLIDOS": "Contreras Martinez",
                    "NOMBRES COMPLETOS": "Walter David Contreras Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gustavo Adolfo Espinosa Toledo",
                    "CODIGO USUARIO": "cont800351",
                    "USUARIOFIVE9": "cont800351@siigo.com",
                    "USUARIO RED": "cont800351",
                    "CORREOELECTRONICO": "cont800351@siigo.com",
                    "STATION ID": "2605984",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gustavo Adolfo Espinosa Toledo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52078542",
                    "FECHA INICIAL": "1998-09-01",
                    "FECHA OPERACION": "1998-09-01",
                    "NOMBRES": "Yandra Julissa",
                    "APELLIDOS": "Torres Velandia",
                    "NOMBRES COMPLETOS": "Yandra Julissa Torres Velandia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leida Adriana Rico Herrera",
                    "CODIGO USUARIO": "torr4180",
                    "USUARIOFIVE9": "torr4180@siigo.com",
                    "USUARIO RED": "torr4180",
                    "CORREOELECTRONICO": "torr4180@siigo.com",
                    "STATION ID": "2606483",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leida Adriana Rico Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1058844971",
                    "FECHA INICIAL": "2019-07-03",
                    "FECHA OPERACION": "2019-07-03",
                    "NOMBRES": "Yoany Gomez",
                    "APELLIDOS": "Rios",
                    "NOMBRES COMPLETOS": "Yoany Gomez Rios",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "gome111833",
                    "USUARIOFIVE9": "gome111833@siigo.com",
                    "USUARIO RED": "gome111833",
                    "CORREOELECTRONICO": "gome111833@siigo.com",
                    "STATION ID": "2606467",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "43914787",
                    "FECHA INICIAL": "2019-02-19",
                    "FECHA OPERACION": "2019-02-19",
                    "NOMBRES": "Aleida Bibiana",
                    "APELLIDOS": "Garcia Suarez",
                    "NOMBRES COMPLETOS": "Aleida Bibiana Garcia Suarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Julian Ernesto Morales Garzon",
                    "CODIGO USUARIO": "garc111888",
                    "USUARIOFIVE9": "garc111888@siigo.com",
                    "USUARIO RED": "garc111888",
                    "CORREOELECTRONICO": "garc111888@siigo.com",
                    "STATION ID": "2606575",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Julian Ernesto Morales Garzon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52424243",
                    "FECHA INICIAL": "2015-04-08",
                    "FECHA OPERACION": "2015-04-08",
                    "NOMBRES": "Ana Sugdit",
                    "APELLIDOS": "Ramos Riano",
                    "NOMBRES COMPLETOS": "Ana Sugdit Ramos Riano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "ramo109845",
                    "USUARIOFIVE9": "ramo109845@siigo.com",
                    "USUARIO RED": "ramo109845",
                    "CORREOELECTRONICO": "ramo109845@siigo.com",
                    "STATION ID": "2606576",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1102857280",
                    "FECHA INICIAL": "2019-09-18",
                    "FECHA OPERACION": "2019-09-18",
                    "NOMBRES": "Carlos Alberto",
                    "APELLIDOS": "Hernandez Mejia",
                    "NOMBRES COMPLETOS": "Carlos Alberto Hernandez Mejia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Recuperaciones",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Mario Perez Diaz",
                    "CODIGO USUARIO": "hern112387",
                    "USUARIOFIVE9": "hern112387@siigo.com",
                    "USUARIO RED": "hern112387",
                    "CORREOELECTRONICO": "hern112387@siigo.com",
                    "STATION ID": "2606859",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Mario Perez Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032070489",
                    "FECHA INICIAL": "2019-08-08",
                    "FECHA OPERACION": "2019-08-08",
                    "NOMBRES": "Jenny Paola",
                    "APELLIDOS": "Lopez Lopez",
                    "NOMBRES COMPLETOS": "Jenny Paola Lopez Lopez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Recuperaciones",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Mario Perez Diaz",
                    "CODIGO USUARIO": "lope112270",
                    "USUARIOFIVE9": "lope112270@siigo.com",
                    "USUARIO RED": "lope112270",
                    "CORREOELECTRONICO": "lope112270@siigo.com",
                    "STATION ID": "2606584",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Mario Perez Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80087814",
                    "FECHA INICIAL": "2018-11-02",
                    "FECHA OPERACION": "2018-12-02",
                    "NOMBRES": "Esteban Tellez",
                    "APELLIDOS": "Garcia",
                    "NOMBRES COMPLETOS": "Esteban Tellez Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial",
                    "CARGO": "Vicepresidente",
                    "JEFE INMEDIATO": "David Ortiz Diaz",
                    "CODIGO USUARIO": "tell111796",
                    "USUARIOFIVE9": "tell111796@siigo.com",
                    "USUARIO RED": "tell111796",
                    "CORREOELECTRONICO": "tell111796@siigo.com",
                    "STATION ID": "2606845",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_David Ortiz Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "10134220",
                    "FECHA INICIAL": "2018-12-03",
                    "FECHA OPERACION": "2018-12-03",
                    "NOMBRES": "John Jairo",
                    "APELLIDOS": "Mejia Acevedo",
                    "NOMBRES COMPLETOS": "John Jairo Mejia Acevedo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Chile",
                    "CARGO": "Director",
                    "JEFE INMEDIATO": "Esteban Tellez Garcia",
                    "CODIGO USUARIO": "john.mejia",
                    "USUARIOFIVE9": "john.mejia@siigo.com",
                    "USUARIO RED": "john.mejia",
                    "CORREOELECTRONICO": "john.mejia@siigo.com",
                    "STATION ID": "2606814",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Esteban Tellez Garcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79533487",
                    "FECHA INICIAL": "1998-09-01",
                    "FECHA OPERACION": "1998-10-01",
                    "NOMBRES": "John Mario",
                    "APELLIDOS": "Perez Diaz",
                    "NOMBRES COMPLETOS": "John Mario Perez Diaz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial",
                    "CARGO": "Director",
                    "JEFE INMEDIATO": "John Jairo Mejia acevedo",
                    "CODIGO USUARIO": "pere4136",
                    "USUARIOFIVE9": "pere4136@siigo.com",
                    "USUARIO RED": "pere4136",
                    "CORREOELECTRONICO": "pere4136@siigo.com",
                    "STATION ID": "2606812",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Jairo Mejia acevedo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79948470",
                    "FECHA INICIAL": "2018-12-17",
                    "FECHA OPERACION": "2019-01-16",
                    "NOMBRES": "Juan Camilo",
                    "APELLIDOS": "Gonzalez Saldana",
                    "NOMBRES COMPLETOS": "Juan Camilo Gonzalez Saldana",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial",
                    "CARGO": "Director",
                    "JEFE INMEDIATO": "Esteban Tellez Garcia",
                    "CODIGO USUARIO": "gonz111814",
                    "USUARIOFIVE9": "gonz111814@siigo.com",
                    "USUARIO RED": "gonz111814",
                    "CORREOELECTRONICO": "gonz111814@siigo.com",
                    "STATION ID": "2606813",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Esteban Tellez Garcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52694622",
                    "FECHA INICIAL": "2019-12-16",
                    "FECHA OPERACION": "2019-12-16",
                    "NOMBRES": "Andrea Catalina",
                    "APELLIDOS": "Vargas Ardila",
                    "NOMBRES COMPLETOS": "Andrea Catalina Vargas Ardila",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jefferson Arvey Ramirez Ibanez",
                    "CODIGO USUARIO": "varg800081",
                    "USUARIOFIVE9": "varg800081@siigo.com",
                    "USUARIO RED": "varg800081",
                    "CORREOELECTRONICO": "varg800081@siigo.com",
                    "STATION ID": "2606969",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jefferson Arvey Ramirez Ibanez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019043258",
                    "FECHA INICIAL": "2016-09-12",
                    "FECHA OPERACION": "2016-09-12",
                    "NOMBRES": "Andres Felipe",
                    "APELLIDOS": "Baron Ballen",
                    "NOMBRES COMPLETOS": "Andres Felipe Baron Ballen",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Magda Lilian Leal Rozo",
                    "CODIGO USUARIO": "baro110226",
                    "USUARIOFIVE9": "baro110226@siigo.com",
                    "USUARIO RED": "baro110226",
                    "CORREOELECTRONICO": "baro110226@siigo.com",
                    "STATION ID": "2606577",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Magda Lilian Leal Rozo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033793204",
                    "FECHA INICIAL": "2018-08-01",
                    "FECHA OPERACION": "2018-08-01",
                    "NOMBRES": "Brahian Camilo",
                    "APELLIDOS": "Arevalo Barbosa",
                    "NOMBRES COMPLETOS": "Brahian Camilo Arevalo Barbosa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "William Andres Reyes Silva",
                    "CODIGO USUARIO": "arev111552",
                    "USUARIOFIVE9": "arev111552@siigo.com",
                    "USUARIO RED": "arev111552",
                    "CORREOELECTRONICO": "arev111552@siigo.com",
                    "STATION ID": "2606519",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_William Andres Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52999148",
                    "FECHA INICIAL": "2019-09-04",
                    "FECHA OPERACION": "2019-09-04",
                    "NOMBRES": "Calendaria Galeano",
                    "APELLIDOS": "Cruz",
                    "NOMBRES COMPLETOS": "Calendaria Galeano Cruz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "William Andres Reyes Silva",
                    "CODIGO USUARIO": "gale112341",
                    "USUARIOFIVE9": "gale112341@siigo.com",
                    "USUARIO RED": "gale112341",
                    "CORREOELECTRONICO": "gale112341@siigo.com",
                    "STATION ID": "2606009",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_William Andres Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79537797",
                    "FECHA INICIAL": "2020-02-03",
                    "FECHA OPERACION": "2020-02-03",
                    "NOMBRES": "Carlos Alfredo",
                    "APELLIDOS": "Tavera Tamayo",
                    "NOMBRES COMPLETOS": "Carlos Alfredo Tavera Tamayo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jefferson Arvey Ramirez Ibanez",
                    "CODIGO USUARIO": "tave800172",
                    "USUARIOFIVE9": "tave800172@siigo.com",
                    "USUARIO RED": "tave800172",
                    "CORREOELECTRONICO": "tave800172@siigo.com",
                    "STATION ID": "2607123",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jefferson Arvey Ramirez Ibanez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1031142753",
                    "FECHA INICIAL": "2015-03-02",
                    "FECHA OPERACION": "2015-03-02",
                    "NOMBRES": "Carol Andrea",
                    "APELLIDOS": "Cifuentes Alcala",
                    "NOMBRES COMPLETOS": "Carol Andrea Cifuentes Alcala",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Ecuador",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Jose Manuel Aguayo Escandon",
                    "CODIGO USUARIO": "cifu108590",
                    "USUARIOFIVE9": "cifu108590@siigo.com",
                    "USUARIO RED": "cifu108590",
                    "CORREOELECTRONICO": "cifu108590@siigo.com",
                    "STATION ID": "2606520",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jose Manuel Aguayo Escandon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014196916",
                    "FECHA INICIAL": "2019-12-12",
                    "FECHA OPERACION": "2019-12-12",
                    "NOMBRES": "Edison Steve",
                    "APELLIDOS": "Avendano Noy",
                    "NOMBRES COMPLETOS": "Edison Steve Avendano Noy",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "David Alfredo Sanchez Bastidas",
                    "CODIGO USUARIO": "aven800035",
                    "USUARIOFIVE9": "aven800035@siigo.com",
                    "USUARIO RED": "aven800035",
                    "CORREOELECTRONICO": "aven800035@siigo.com",
                    "STATION ID": "2606964",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_David Alfredo Sanchez Bastidas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032456259",
                    "FECHA INICIAL": "2020-03-09",
                    "FECHA OPERACION": "2020-03-09",
                    "NOMBRES": "Harold Jose",
                    "APELLIDOS": "Montenegro Galindo",
                    "NOMBRES COMPLETOS": "Harold Jose Montenegro Galindo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "David Alfredo Sanchez Bastidas",
                    "CODIGO USUARIO": "mont800393",
                    "USUARIOFIVE9": "mont800393@siigo.com",
                    "USUARIO RED": "mont800393",
                    "CORREOELECTRONICO": "mont800393@siigo.com",
                    "STATION ID": "2605967",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_David Alfredo Sanchez Bastidas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013600219",
                    "FECHA INICIAL": "2020-02-03",
                    "FECHA OPERACION": "2020-02-03",
                    "NOMBRES": "Juan Sebastian",
                    "APELLIDOS": "Ayala Pirazan",
                    "NOMBRES COMPLETOS": "Juan Sebastian Ayala Pirazan",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juliana Castro Diaz",
                    "CODIGO USUARIO": "ayal800177",
                    "USUARIOFIVE9": "ayal800177@siigo.com",
                    "USUARIO RED": "ayal800177",
                    "CORREOELECTRONICO": "ayal800177@siigo.com",
                    "STATION ID": "2607098",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juliana Castro Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014238912",
                    "FECHA INICIAL": "2018-02-23",
                    "FECHA OPERACION": "2018-02-23",
                    "NOMBRES": "Leidy Alejandra",
                    "APELLIDOS": "Gomez Bernal",
                    "NOMBRES COMPLETOS": "Leidy Alejandra Gomez Bernal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "William Andres Reyes Silva",
                    "CODIGO USUARIO": "gome111471",
                    "USUARIOFIVE9": "gome111471@siigo.com",
                    "USUARIO RED": "gome111471",
                    "CORREOELECTRONICO": "gome111471@siigo.com",
                    "STATION ID": "2606529",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_William Andres Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53063881",
                    "FECHA INICIAL": "2019-11-22",
                    "FECHA OPERACION": "2019-11-22",
                    "NOMBRES": "Magda Lilian",
                    "APELLIDOS": "Leal Rozo",
                    "NOMBRES COMPLETOS": "Magda Lilian Leal Rozo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Juan Camilo Gonzalez Saldana",
                    "CODIGO USUARIO": "leal112549",
                    "USUARIOFIVE9": "leal112549@siigo.com",
                    "USUARIO RED": "leal112549",
                    "CORREOELECTRONICO": "leal112549@siigo.com",
                    "STATION ID": "2606846",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Camilo Gonzalez Saldana",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015418497",
                    "FECHA INICIAL": "2020-02-03",
                    "FECHA OPERACION": "2020-02-03",
                    "NOMBRES": "Maria Fernanda",
                    "APELLIDOS": "Molina Ramirez",
                    "NOMBRES COMPLETOS": "Maria Fernanda Molina Ramirez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "David Alfredo Sanchez Bastidas",
                    "CODIGO USUARIO": "moli800173",
                    "USUARIOFIVE9": "moli800173@siigo.com",
                    "USUARIO RED": "moli800173",
                    "CORREOELECTRONICO": "moli800173@siigo.com",
                    "STATION ID": "2607104",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_David Alfredo Sanchez Bastidas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53932071",
                    "FECHA INICIAL": "2019-12-16",
                    "FECHA OPERACION": "2019-12-16",
                    "NOMBRES": "Maria Fernanda",
                    "APELLIDOS": "Pardo Mora",
                    "NOMBRES COMPLETOS": "Maria Fernanda Pardo Mora",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Andres Felipe Baron Ballen",
                    "CODIGO USUARIO": "pardo800084",
                    "USUARIOFIVE9": "pardo800084@siigo.com",
                    "USUARIO RED": "pardo800084",
                    "CORREOELECTRONICO": "pardo800084@siigo.com",
                    "STATION ID": "2606971",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andres Felipe Baron Ballen",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016042119",
                    "FECHA INICIAL": "2019-03-04",
                    "FECHA OPERACION": "2019-03-04",
                    "NOMBRES": "Maria Ximena",
                    "APELLIDOS": "Manrique Isaziga",
                    "NOMBRES COMPLETOS": "Maria Ximena Manrique Isaziga",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Hollman Antonio Aponte Quiroga",
                    "CODIGO USUARIO": "manr111949",
                    "USUARIOFIVE9": "manr111949@siigo.com",
                    "USUARIO RED": "manr111949",
                    "CORREOELECTRONICO": "manr111949@siigo.com",
                    "STATION ID": "2606507",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Hollman Antonio Aponte Quiroga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022325288",
                    "FECHA INICIAL": "2019-03-04",
                    "FECHA OPERACION": "2019-03-04",
                    "NOMBRES": "Nataly Carolina",
                    "APELLIDOS": "Gonzalez Sandoval",
                    "NOMBRES COMPLETOS": "Nataly Carolina Gonzalez Sandoval",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Julian Ernesto Morales Garzon",
                    "CODIGO USUARIO": "gonz111958",
                    "USUARIOFIVE9": "gonz111958@siigo.com",
                    "USUARIO RED": "gonz111958",
                    "CORREOELECTRONICO": "gonz111958@siigo.com",
                    "STATION ID": "2606508",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Julian Ernesto Morales Garzon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53039575",
                    "FECHA INICIAL": "2020-02-03",
                    "FECHA OPERACION": "2020-02-03",
                    "NOMBRES": "Paola Andrea",
                    "APELLIDOS": "Maldonado Velez",
                    "NOMBRES COMPLETOS": "Paola Andrea Maldonado Velez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Andres Felipe Baron Ballen",
                    "CODIGO USUARIO": "mald800187",
                    "USUARIOFIVE9": "mald800187@siigo.com",
                    "USUARIO RED": "mald800187",
                    "CORREOELECTRONICO": "mald800187@siigo.com",
                    "STATION ID": "2607109",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andres Felipe Baron Ballen",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79638295",
                    "FECHA INICIAL": "2019-03-04",
                    "FECHA OPERACION": "2019-03-04",
                    "NOMBRES": "Ricardo Javier",
                    "APELLIDOS": "Lozano Britto",
                    "NOMBRES COMPLETOS": "Ricardo Javier Lozano Britto",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "William Andres Reyes Silva",
                    "CODIGO USUARIO": "loza111952",
                    "USUARIOFIVE9": "loza111952@siigo.com",
                    "USUARIO RED": "loza111952",
                    "CORREOELECTRONICO": "loza111952@siigo.com",
                    "STATION ID": "2606515",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_William Andres Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033710273",
                    "FECHA INICIAL": "2019-12-12",
                    "FECHA OPERACION": "2019-12-12",
                    "NOMBRES": "Rodrigo Yate",
                    "APELLIDOS": "Villalobos",
                    "NOMBRES COMPLETOS": "Rodrigo Yate Villalobos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Andres Felipe Baron Ballen",
                    "CODIGO USUARIO": "yate800039",
                    "USUARIOFIVE9": "yate800039@siigo.com",
                    "USUARIO RED": "yate800039",
                    "CORREOELECTRONICO": "yate800039@siigo.com",
                    "STATION ID": "2606963",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andres Felipe Baron Ballen",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1077148743",
                    "FECHA INICIAL": "2020-02-03",
                    "FECHA OPERACION": "2020-02-03",
                    "NOMBRES": "Santiago Galeano",
                    "APELLIDOS": "Pedraza",
                    "NOMBRES COMPLETOS": "Santiago Galeano Pedraza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Lina Maria Buitrago Herrera",
                    "CODIGO USUARIO": "gale800184",
                    "USUARIOFIVE9": "gale800184@siigo.com",
                    "USUARIO RED": "gale800184",
                    "CORREOELECTRONICO": "gale800184@siigo.com",
                    "STATION ID": "2607112",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Lina Maria Buitrago Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1101688662",
                    "FECHA INICIAL": "2018-07-16",
                    "FECHA OPERACION": "2018-07-16",
                    "NOMBRES": "William Andres",
                    "APELLIDOS": "Reyes Silva",
                    "NOMBRES COMPLETOS": "William Andres Reyes Silva",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Magda Lilian Leal Rozo",
                    "CODIGO USUARIO": "reye111745",
                    "USUARIOFIVE9": "reye111745@siigo.com",
                    "USUARIO RED": "reye111745",
                    "CORREOELECTRONICO": "reye111745@siigo.com",
                    "STATION ID": "2606822",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Magda Lilian Leal Rozo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "40783195",
                    "FECHA INICIAL": "2019-08-06",
                    "FECHA OPERACION": "2019-08-06",
                    "NOMBRES": "Liliam Murcia",
                    "APELLIDOS": "Ganem",
                    "NOMBRES COMPLETOS": "Liliam Murcia Ganem",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jefferson Arvey Ramirez Ibanez",
                    "CODIGO USUARIO": "murc112264",
                    "USUARIOFIVE9": "murc112264@siigo.com",
                    "USUARIO RED": "murc112264",
                    "CORREOELECTRONICO": "murc112264@siigo.com",
                    "STATION ID": "2606940",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jefferson Arvey Ramirez Ibanez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022965676",
                    "FECHA INICIAL": "2019-08-01",
                    "FECHA OPERACION": "2019-08-01",
                    "NOMBRES": "Maritza Chacon",
                    "APELLIDOS": "Martin",
                    "NOMBRES COMPLETOS": "Maritza Chacon Martin",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juliana Castro Diaz",
                    "CODIGO USUARIO": "chac112237",
                    "USUARIOFIVE9": "chac112237@siigo.com",
                    "USUARIO RED": "chac112237",
                    "CORREOELECTRONICO": "chac112237@siigo.com",
                    "STATION ID": "2606536",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juliana Castro Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032390749",
                    "FECHA INICIAL": "2019-07-29",
                    "FECHA OPERACION": "2019-07-29",
                    "NOMBRES": "Giovanni Alexander",
                    "APELLIDOS": "Morales Cruz",
                    "NOMBRES COMPLETOS": "Giovanni Alexander Morales Cruz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Andres Felipe Baron Ballen",
                    "CODIGO USUARIO": "mora112213",
                    "USUARIOFIVE9": "mora112213@siigo.com",
                    "USUARIO RED": "mora112213",
                    "CORREOELECTRONICO": "mora112213@siigo.com",
                    "STATION ID": "2606547",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andres Felipe Baron Ballen",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1053347477",
                    "FECHA INICIAL": "2019-07-29",
                    "FECHA OPERACION": "2019-07-29",
                    "NOMBRES": "Melany Jeinary",
                    "APELLIDOS": "Salinas Moreno",
                    "NOMBRES COMPLETOS": "Melany Jeinary Salinas Moreno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Mercadeo",
                    "CARGO": "Analista Mercadeo",
                    "JEFE INMEDIATO": "Estefania Sofia Barbosa Fajardo",
                    "CODIGO USUARIO": "sali112216",
                    "USUARIOFIVE9": "sali112216@siigo.com",
                    "USUARIO RED": "sali112216",
                    "CORREOELECTRONICO": "sali112216@siigo.com",
                    "STATION ID": "2606556",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Estefania Sofia Barbosa Fajardo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012359172",
                    "FECHA INICIAL": "2019-01-21",
                    "FECHA OPERACION": "2019-01-21",
                    "NOMBRES": "Narly Johana",
                    "APELLIDOS": "Avila Olaya",
                    "NOMBRES COMPLETOS": "Narly Johana Avila Olaya",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "William Andres Reyes Silva",
                    "CODIGO USUARIO": "avil111847",
                    "USUARIOFIVE9": "avil111847@siigo.com",
                    "USUARIO RED": "avil111847",
                    "CORREOELECTRONICO": "avil111847@siigo.com",
                    "STATION ID": "2606557",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_William Andres Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013630290",
                    "FECHA INICIAL": "2019-08-01",
                    "FECHA OPERACION": "2019-08-01",
                    "NOMBRES": "Paula Alejandra",
                    "APELLIDOS": "Marquez Gomez",
                    "NOMBRES COMPLETOS": "Paula Alejandra Marquez Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juliana Castro Diaz",
                    "CODIGO USUARIO": "marq112238",
                    "USUARIOFIVE9": "marq112238@siigo.com",
                    "USUARIO RED": "marq112238",
                    "CORREOELECTRONICO": "marq112238@siigo.com",
                    "STATION ID": "2606559",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juliana Castro Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53139514",
                    "FECHA INICIAL": "2021-05-12",
                    "FECHA OPERACION": "2021-05-12",
                    "NOMBRES": "Andrea",
                    "APELLIDOS": "Paez Davila",
                    "NOMBRES COMPLETOS": "Andrea Paez Davila",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jessica Paola Paez Quintero",
                    "CODIGO USUARIO": "paez801462",
                    "USUARIOFIVE9": "paez801462@siigo.com",
                    "USUARIO RED": "paez801462",
                    "CORREOELECTRONICO": "paez801462@siigo.com",
                    "STATION ID": "2606272",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jessica Paola Paez Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015393721",
                    "FECHA INICIAL": "2020-02-11",
                    "FECHA OPERACION": "2020-02-11",
                    "NOMBRES": "Lizeth Fernanda",
                    "APELLIDOS": "Reyes Pire",
                    "NOMBRES COMPLETOS": "Lizeth Fernanda Reyes Pire",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leidy Marcela Rodriguez Rivera",
                    "CODIGO USUARIO": "reye800263",
                    "USUARIOFIVE9": "reye800263@siigo.com",
                    "USUARIO RED": "reye800263",
                    "CORREOELECTRONICO": "reye800263@siigo.com",
                    "STATION ID": "2607019",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leidy Marcela Rodriguez Rivera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007773230",
                    "FECHA INICIAL": "2019-12-02",
                    "FECHA OPERACION": "2019-12-02",
                    "NOMBRES": "Luisa Fernanda",
                    "APELLIDOS": "Frade Martinez",
                    "NOMBRES COMPLETOS": "Luisa Fernanda Frade Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "frad100019",
                    "USUARIOFIVE9": "frad100019@siigo.com",
                    "USUARIO RED": "frad100019",
                    "CORREOELECTRONICO": "frad100019@siigo.com",
                    "STATION ID": "2606569",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012400496",
                    "FECHA INICIAL": "2020-02-11",
                    "FECHA OPERACION": "2020-02-11",
                    "NOMBRES": "Milady Andrea",
                    "APELLIDOS": "Guzman Garcia",
                    "NOMBRES COMPLETOS": "Milady Andrea Guzman Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Lider Perfilamiento",
                    "JEFE INMEDIATO": "Harrison Martinez Callejas",
                    "CODIGO USUARIO": "guzm800253",
                    "USUARIOFIVE9": "guzm800253@siigo.com",
                    "USUARIO RED": "guzm800253",
                    "CORREOELECTRONICO": "guzm800253@siigo.com",
                    "STATION ID": "2607020",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Harrison Martinez Callejas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1048605631",
                    "FECHA INICIAL": "2019-09-03",
                    "FECHA OPERACION": "2019-09-03",
                    "NOMBRES": "Valery Jassiby",
                    "APELLIDOS": "Romero Tapias",
                    "NOMBRES COMPLETOS": "Valery Jassiby Romero Tapias",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "rome112343",
                    "USUARIOFIVE9": "rome112343@siigo.com",
                    "USUARIO RED": "rome112343",
                    "CORREOELECTRONICO": "rome112343@siigo.com",
                    "STATION ID": "2606570",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "5487452",
                    "FECHA INICIAL": "2019-11-12",
                    "FECHA OPERACION": "2019-11-12",
                    "NOMBRES": "Yarselyt Noilet",
                    "APELLIDOS": "Zambrano Arroyo",
                    "NOMBRES COMPLETOS": "Yarselyt Noilet Zambrano Arroyo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Mercedes Moncada Peraza",
                    "CODIGO USUARIO": "zamb112527",
                    "USUARIOFIVE9": "zamb112527@siigo.com",
                    "USUARIO RED": "zamb112527",
                    "CORREOELECTRONICO": "zamb112527@siigo.com",
                    "STATION ID": "2606572",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Mercedes Moncada Peraza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "5959324",
                    "FECHA INICIAL": "2020-02-10",
                    "FECHA OPERACION": "2020-02-10",
                    "NOMBRES": "Andreina De La Guadalupe",
                    "APELLIDOS": "Castro Radaelli",
                    "NOMBRES COMPLETOS": "Andreina De La Guadalupe Castro Radaelli",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Lina Maria Buitrago Herrera",
                    "CODIGO USUARIO": "cast800267",
                    "USUARIOFIVE9": "cast800267@siigo.com",
                    "USUARIO RED": "cast800267",
                    "CORREOELECTRONICO": "cast800267@siigo.com",
                    "STATION ID": "2607021",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Lina Maria Buitrago Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52235083",
                    "FECHA INICIAL": "2014-01-20",
                    "FECHA OPERACION": "2014-01-20",
                    "NOMBRES": "Alba Marcela",
                    "APELLIDOS": "Herrera Gonzalez",
                    "NOMBRES COMPLETOS": "Alba Marcela Herrera Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Recuperaciones",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Mario Perez Diaz",
                    "CODIGO USUARIO": "herr32262",
                    "USUARIOFIVE9": "herr32262@siigo.com",
                    "USUARIO RED": "herr32262",
                    "CORREOELECTRONICO": "herr32262@siigo.com",
                    "STATION ID": "2606574",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Mario Perez Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52529023",
                    "FECHA INICIAL": "2015-06-04",
                    "FECHA OPERACION": "2015-06-04",
                    "NOMBRES": "Carol Michelle",
                    "APELLIDOS": "Bernal Ortega",
                    "NOMBRES COMPLETOS": "Carol Michelle Bernal Ortega",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Recuperaciones",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Mario Perez Diaz",
                    "CODIGO USUARIO": "bern109915",
                    "USUARIOFIVE9": "bern109915@siigo.com",
                    "USUARIO RED": "bern109915",
                    "CORREOELECTRONICO": "bern109915@siigo.com",
                    "STATION ID": "2606579",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Mario Perez Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1233504830",
                    "FECHA INICIAL": "2018-05-16",
                    "FECHA OPERACION": "2018-05-16",
                    "NOMBRES": "Daniel Alejandro",
                    "APELLIDOS": "Gutierrez Gutierrez",
                    "NOMBRES COMPLETOS": "Daniel Alejandro Gutierrez Gutierrez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Recuperaciones",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Mario Perez Diaz",
                    "CODIGO USUARIO": "guti111711",
                    "USUARIOFIVE9": "guti111711@siigo.com",
                    "USUARIO RED": "guti111711",
                    "CORREOELECTRONICO": "guti111711@siigo.com",
                    "STATION ID": "2606580",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Mario Perez Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52774527",
                    "FECHA INICIAL": "2012-02-01",
                    "FECHA OPERACION": "2012-02-01",
                    "NOMBRES": "Diana Milena",
                    "APELLIDOS": "Duarte Bermudez",
                    "NOMBRES COMPLETOS": "Diana Milena Duarte Bermudez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Recuperaciones",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Mario Perez Diaz",
                    "CODIGO USUARIO": "duar18429",
                    "USUARIOFIVE9": "duar18429@siigo.com",
                    "USUARIO RED": "duar18429",
                    "CORREOELECTRONICO": "duar18429@siigo.com",
                    "STATION ID": "2606581",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Mario Perez Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030570900",
                    "FECHA INICIAL": "2014-12-22",
                    "FECHA OPERACION": "2014-12-22",
                    "NOMBRES": "Jenifer Andrea",
                    "APELLIDOS": "Moreno Ospina",
                    "NOMBRES COMPLETOS": "Jenifer Andrea Moreno Ospina",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Recuperaciones",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Mario Perez Diaz",
                    "CODIGO USUARIO": "more107164",
                    "USUARIOFIVE9": "more107164@siigo.com",
                    "USUARIO RED": "more107164",
                    "CORREOELECTRONICO": "more107164@siigo.com",
                    "STATION ID": "2606583",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Mario Perez Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79660251",
                    "FECHA INICIAL": "2014-09-17",
                    "FECHA OPERACION": "2014-09-17",
                    "NOMBRES": "Jotman Sandy",
                    "APELLIDOS": "Diaz Hurtado",
                    "NOMBRES COMPLETOS": "Jotman Sandy Diaz Hurtado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Recuperaciones",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Mario Perez Diaz",
                    "CODIGO USUARIO": "diaz40235",
                    "USUARIOFIVE9": "diaz40235@siigo.com",
                    "USUARIO RED": "diaz40235",
                    "CORREOELECTRONICO": "diaz40235@siigo.com",
                    "STATION ID": "2606585",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Mario Perez Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "43869026",
                    "FECHA INICIAL": "2019-02-19",
                    "FECHA OPERACION": "2019-02-19",
                    "NOMBRES": "Juliana Maria",
                    "APELLIDOS": "Cano Alvarez",
                    "NOMBRES COMPLETOS": "Juliana Maria Cano Alvarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "cano111883",
                    "USUARIOFIVE9": "cano111883@siigo.com",
                    "USUARIO RED": "cano111883",
                    "CORREOELECTRONICO": "cano111883@siigo.com",
                    "STATION ID": "2606586",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015997229",
                    "FECHA INICIAL": "2018-06-07",
                    "FECHA OPERACION": "2018-06-07",
                    "NOMBRES": "Liliana Andrea",
                    "APELLIDOS": "Segura Martinez",
                    "NOMBRES COMPLETOS": "Liliana Andrea Segura Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Hollman Antonio Aponte Quiroga",
                    "CODIGO USUARIO": "segu111524",
                    "USUARIOFIVE9": "segu111524@siigo.com",
                    "USUARIO RED": "segu111524",
                    "CORREOELECTRONICO": "segu111524@siigo.com",
                    "STATION ID": "2606588",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Hollman Antonio Aponte Quiroga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018449150",
                    "FECHA INICIAL": "2012-12-03",
                    "FECHA OPERACION": "2012-12-03",
                    "NOMBRES": "Maria Elizabeth",
                    "APELLIDOS": "Yoscua Ubaque",
                    "NOMBRES COMPLETOS": "Maria Elizabeth Yoscua Ubaque",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Recuperaciones",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Mario Perez Diaz",
                    "CODIGO USUARIO": "yosc23203",
                    "USUARIOFIVE9": "yosc23203@siigo.com",
                    "USUARIO RED": "yosc23203",
                    "CORREOELECTRONICO": "yosc23203@siigo.com",
                    "STATION ID": "2606589",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Mario Perez Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1075272716",
                    "FECHA INICIAL": "2019-06-11",
                    "FECHA OPERACION": "2019-06-11",
                    "NOMBRES": "Mary Shiseth",
                    "APELLIDOS": "Riveros Palma",
                    "NOMBRES COMPLETOS": "Mary Shiseth Riveros Palma",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Recuperaciones",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Mario Perez Diaz",
                    "CODIGO USUARIO": "rive112130",
                    "USUARIOFIVE9": "rive112130@siigo.com",
                    "USUARIO RED": "rive112130",
                    "CORREOELECTRONICO": "rive112130@siigo.com",
                    "STATION ID": "2606902",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Mario Perez Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "43604502",
                    "FECHA INICIAL": "2019-02-15",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Yenni Patricia",
                    "APELLIDOS": "Lopez Arenas",
                    "NOMBRES COMPLETOS": "Yenni Patricia Lopez Arenas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Recuperaciones",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Mario Perez Diaz",
                    "CODIGO USUARIO": "lope111937",
                    "USUARIOFIVE9": "lope111937@siigo.com",
                    "USUARIO RED": "lope111937",
                    "CORREOELECTRONICO": "lope111937@siigo.com",
                    "STATION ID": "2606901",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Mario Perez Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024548231",
                    "FECHA INICIAL": "2015-05-11",
                    "FECHA OPERACION": "2015-05-11",
                    "NOMBRES": "Yordy Yamir",
                    "APELLIDOS": "Baez Salcedo",
                    "NOMBRES COMPLETOS": "Yordy Yamir Baez Salcedo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Recuperaciones",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Mario Perez Diaz",
                    "CODIGO USUARIO": "baez109906",
                    "USUARIOFIVE9": "baez109906@siigo.com",
                    "USUARIO RED": "baez109906",
                    "CORREOELECTRONICO": "baez109906@siigo.com",
                    "STATION ID": "2606593",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Mario Perez Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022381745",
                    "FECHA INICIAL": "2018-07-16",
                    "FECHA OPERACION": "2018-07-16",
                    "NOMBRES": "Yuri Andrea",
                    "APELLIDOS": "Ariza Diaz",
                    "NOMBRES COMPLETOS": "Yuri Andrea Ariza Diaz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Lina Maria Buitrago Herrera",
                    "CODIGO USUARIO": "ariz111707",
                    "USUARIOFIVE9": "ariz111707@siigo.com",
                    "USUARIO RED": "ariz111707",
                    "CORREOELECTRONICO": "ariz111707@siigo.com",
                    "STATION ID": "2605942",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Lina Maria Buitrago Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52353130",
                    "FECHA INICIAL": "2018-02-01",
                    "FECHA OPERACION": "2019-07-11",
                    "NOMBRES": "Mery",
                    "APELLIDOS": "Arevalo Navarro",
                    "NOMBRES COMPLETOS": "Mery Arevalo Navarro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gustavo Adolfo Espinosa Toledo",
                    "CODIGO USUARIO": "arev111456",
                    "USUARIOFIVE9": "arev111456@siigo.com",
                    "USUARIO RED": "arev111456",
                    "CORREOELECTRONICO": "arev111456@siigo.com",
                    "STATION ID": "2606417",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gustavo Adolfo Espinosa Toledo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52792171",
                    "FECHA INICIAL": "2020-04-13",
                    "FECHA OPERACION": "2020-05-20",
                    "NOMBRES": "Betty Yisel",
                    "APELLIDOS": "Ruiz Parra",
                    "NOMBRES COMPLETOS": "Betty Yisel Ruiz Parra",
                    "PAIS ORIGEN": "Chile",
                    "PAIS AL QUE LABORA": "Chile",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Chile",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Mario Perez Diaz",
                    "CODIGO USUARIO": "ruiz800423",
                    "USUARIOFIVE9": "ruiz800423@siigo.com",
                    "USUARIO RED": "ruiz800423",
                    "CORREOELECTRONICO": "ruiz800423@siigo.com",
                    "STATION ID": "2605994",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Mario Perez Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1110510824",
                    "FECHA INICIAL": "2016-03-28",
                    "FECHA OPERACION": "2016-03-28",
                    "NOMBRES": "Gustavo Adolfo",
                    "APELLIDOS": "Espinosa Toledo",
                    "NOMBRES COMPLETOS": "Leida Adriana Rico Herrera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Liliana Patricia Giraldo Gomez",
                    "CODIGO USUARIO": "espi110062",
                    "USUARIOFIVE9": "espi110062@siigo.com",
                    "USUARIO RED": "espi110062",
                    "CORREOELECTRONICO": "espi110062@siigo.com",
                    "STATION ID": "2606427",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Patricia Giraldo Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "42782958",
                    "FECHA INICIAL": "2019-02-15",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Bibiana Maria",
                    "APELLIDOS": "Ocampo Marin",
                    "NOMBRES COMPLETOS": "Bibiana Maria Ocampo Marin",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Contabilidad",
                    "SUBAREA": "Contabilidad",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Diana Patricia Atehortua Castano",
                    "CODIGO USUARIO": "ocam111893",
                    "USUARIOFIVE9": "ocam111893@siigo.com",
                    "USUARIO RED": "ocam111893",
                    "CORREOELECTRONICO": "ocam111893@siigo.com",
                    "STATION ID": "2606630",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Contabilidad_Diana Patricia Atehortua Castano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1077867997",
                    "FECHA INICIAL": "2020-04-13",
                    "FECHA OPERACION": "2020-05-20",
                    "NOMBRES": "Yeimy Liliana",
                    "APELLIDOS": "Sanchez Bahamon",
                    "NOMBRES COMPLETOS": "Yeimy Liliana Sanchez Bahamon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoraciones",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Sandra Catalina Suarez Castillo",
                    "CODIGO USUARIO": "sanc800434",
                    "USUARIOFIVE9": "sanc800434@siigo.com",
                    "USUARIO RED": "sanc800434",
                    "CORREOELECTRONICO": "sanc800434@siigo.com",
                    "STATION ID": "2606000",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Sandra Catalina Suarez Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022424914",
                    "FECHA INICIAL": "2019-02-19",
                    "FECHA OPERACION": "2019-02-19",
                    "NOMBRES": "Angie Johanna",
                    "APELLIDOS": "Gomez Lopez",
                    "NOMBRES COMPLETOS": "Angie Johanna Gomez Lopez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Manuel Andres Solano Duran",
                    "CODIGO USUARIO": "gome111881",
                    "USUARIOFIVE9": "gome111881@siigo.com",
                    "USUARIO RED": "gome111881",
                    "CORREOELECTRONICO": "gome111881@siigo.com",
                    "STATION ID": "2606653",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Manuel Andres Solano Duran",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032492954",
                    "FECHA INICIAL": "2019-08-05",
                    "FECHA OPERACION": "2019-08-05",
                    "NOMBRES": "Juan Diego",
                    "APELLIDOS": "Casallas Bernal",
                    "NOMBRES COMPLETOS": "Juan Diego Casallas Bernal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "casa112258",
                    "USUARIOFIVE9": "casa112258@siigo.com",
                    "USUARIO RED": "casa112258",
                    "CORREOELECTRONICO": "casa112258@siigo.com",
                    "STATION ID": "2606774",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032462323",
                    "FECHA INICIAL": "2016-05-11",
                    "FECHA OPERACION": "2016-05-11",
                    "NOMBRES": "Carlos Javier",
                    "APELLIDOS": "Mendez Vera",
                    "NOMBRES COMPLETOS": "Carlos Javier Mendez Vera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Fernando Gutierrez Paramio",
                    "CODIGO USUARIO": "mend110124",
                    "USUARIOFIVE9": "mend110124@siigo.com",
                    "USUARIO RED": "mend110124",
                    "CORREOELECTRONICO": "mend110124@siigo.com",
                    "STATION ID": "2606904",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Fernando Gutierrez Paramio",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018464958",
                    "FECHA INICIAL": "2020-01-07",
                    "FECHA OPERACION": "2020-01-07",
                    "NOMBRES": "David Felipe",
                    "APELLIDOS": "Yepes Sanchez",
                    "NOMBRES COMPLETOS": "David Felipe Yepes Sanchez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "yepe800139",
                    "USUARIOFIVE9": "yepe800139@siigo.com",
                    "USUARIO RED": "yepe800139",
                    "CORREOELECTRONICO": "yepe800139@siigo.com",
                    "STATION ID": "2606951",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024512188",
                    "FECHA INICIAL": "2016-02-16",
                    "FECHA OPERACION": "2016-02-16",
                    "NOMBRES": "Deisy Viviana",
                    "APELLIDOS": "Beltran Beltran",
                    "NOMBRES COMPLETOS": "Deisy Viviana Beltran Beltran",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Adalid Ruiz Guerrero",
                    "CODIGO USUARIO": "belt110020",
                    "USUARIOFIVE9": "belt110020@siigo.com",
                    "USUARIO RED": "belt110020",
                    "CORREOELECTRONICO": "belt110020@siigo.com",
                    "STATION ID": "2606599",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Adalid Ruiz Guerrero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52981784",
                    "FECHA INICIAL": "2014-02-03",
                    "FECHA OPERACION": "2014-02-03",
                    "NOMBRES": "Ecna Lisette",
                    "APELLIDOS": "Garcia Espinosa",
                    "NOMBRES COMPLETOS": "Ecna Lisette Garcia Espinosa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Carlos Mario Espinosa Fernandez",
                    "CODIGO USUARIO": "garc28257",
                    "USUARIOFIVE9": "garc28257@siigo.com",
                    "USUARIO RED": "garc28257",
                    "CORREOELECTRONICO": "garc28257@siigo.com",
                    "STATION ID": "2606672",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Mario Espinosa Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "708005",
                    "FECHA INICIAL": "2019-10-01",
                    "FECHA OPERACION": "2019-10-01",
                    "NOMBRES": "Fernando Gutierrez",
                    "APELLIDOS": "Paramio",
                    "NOMBRES COMPLETOS": "Fernando Gutierrez Paramio",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Manuel Enrique Ramirez Rodriguez",
                    "CODIGO USUARIO": "guti112430",
                    "USUARIOFIVE9": "guti112430@siigo.com",
                    "USUARIO RED": "guti112430",
                    "CORREOELECTRONICO": "guti112430@siigo.com",
                    "STATION ID": "2605962",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Manuel Enrique Ramirez Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79530166",
                    "FECHA INICIAL": "2009-01-01",
                    "FECHA OPERACION": "2009-01-01",
                    "NOMBRES": "Hector Enrique",
                    "APELLIDOS": "Becerra Perez",
                    "NOMBRES COMPLETOS": "Hector Enrique Becerra Perez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Edgardo Artusi Etcheto",
                    "CODIGO USUARIO": "bece4021",
                    "USUARIOFIVE9": "bece4021@siigo.com",
                    "USUARIO RED": "bece4021",
                    "CORREOELECTRONICO": "bece4021@siigo.com",
                    "STATION ID": "2605961",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Edgardo Artusi Etcheto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1106773001",
                    "FECHA INICIAL": "2010-11-08",
                    "FECHA OPERACION": "2010-11-08",
                    "NOMBRES": "Hilder Penagos",
                    "APELLIDOS": "Rubio",
                    "NOMBRES COMPLETOS": "Hilder Penagos Rubio",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Manuel Andres Solano Duran",
                    "CODIGO USUARIO": "pena264796",
                    "USUARIOFIVE9": "pena264796@siigo.com",
                    "USUARIO RED": "pena264796",
                    "CORREOELECTRONICO": "pena264796@siigo.com",
                    "STATION ID": "2606949",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Manuel Andres Solano Duran",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "10293707",
                    "FECHA INICIAL": "2008-01-08",
                    "FECHA OPERACION": "2008-01-08",
                    "NOMBRES": "Jaime Adalberto",
                    "APELLIDOS": "Lopez Vivas",
                    "NOMBRES COMPLETOS": "Jaime Adalberto Lopez Vivas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Popayan",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Manuel Enrique Ramirez Rodriguez",
                    "CODIGO USUARIO": "lope230679",
                    "USUARIOFIVE9": "lope230679@siigo.com",
                    "USUARIO RED": "lope230679",
                    "CORREOELECTRONICO": "lope230679@siigo.com",
                    "STATION ID": "2605963",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Manuel Enrique Ramirez Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80019376",
                    "FECHA INICIAL": "1998-09-01",
                    "FECHA OPERACION": "1998-09-01",
                    "NOMBRES": "Jairo Enrique",
                    "APELLIDOS": "Corzo Lizarazo",
                    "NOMBRES COMPLETOS": "Jairo Enrique Corzo Lizarazo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Teach Lead",
                    "JEFE INMEDIATO": "Luis Felipe Zea Obando",
                    "CODIGO USUARIO": "corz4055",
                    "USUARIOFIVE9": "corz4055@siigo.com",
                    "USUARIO RED": "corz4055",
                    "CORREOELECTRONICO": "corz4055@siigo.com",
                    "STATION ID": "2606946",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Luis Felipe Zea Obando",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80091586",
                    "FECHA INICIAL": "2007-06-13",
                    "FECHA OPERACION": "2007-06-13",
                    "NOMBRES": "Jhon Jairo",
                    "APELLIDOS": "Riscanevo Martinez",
                    "NOMBRES COMPLETOS": "Jhon Jairo Riscanevo Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo de Calidad",
                    "JEFE INMEDIATO": "Sandra Lucia Ortiz Deulofeut",
                    "CODIGO USUARIO": "risc221022",
                    "USUARIOFIVE9": "risc221022@siigo.com",
                    "USUARIO RED": "risc221022",
                    "CORREOELECTRONICO": "risc221022@siigo.com",
                    "STATION ID": "2607028",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Sandra Lucia Ortiz Deulofeut",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1020812587",
                    "FECHA INICIAL": "2019-03-11",
                    "FECHA OPERACION": "2019-03-11",
                    "NOMBRES": "Manuel Enrique",
                    "APELLIDOS": "Ramirez Rodriguez",
                    "NOMBRES COMPLETOS": "Manuel Enrique Ramirez Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Lider Desarrollo",
                    "JEFE INMEDIATO": "Edgardo Artusi Etcheto",
                    "CODIGO USUARIO": "rami111956",
                    "USUARIOFIVE9": "rami111956@siigo.com",
                    "USUARIO RED": "rami111956",
                    "CORREOELECTRONICO": "rami111956@siigo.com",
                    "STATION ID": "2607027",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Edgardo Artusi Etcheto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014300834",
                    "FECHA INICIAL": "2018-09-03",
                    "FECHA OPERACION": "2018-09-03",
                    "NOMBRES": "Miguel Angel",
                    "APELLIDOS": "Gomez Espinosa",
                    "NOMBRES COMPLETOS": "Miguel Angel Gomez Espinosa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Andrea Duque Botero",
                    "CODIGO USUARIO": "gome111760",
                    "USUARIOFIVE9": "gome111760@siigo.com",
                    "USUARIO RED": "gome111760",
                    "CORREOELECTRONICO": "gome111760@siigo.com",
                    "STATION ID": "2606948",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Andrea Duque Botero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030621964",
                    "FECHA INICIAL": "2012-02-02",
                    "FECHA OPERACION": "2012-02-02",
                    "NOMBRES": "Nataly Quintana",
                    "APELLIDOS": "Bernal",
                    "NOMBRES COMPLETOS": "Nataly Quintana Bernal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "quin15301",
                    "USUARIOFIVE9": "quin15301@siigo.com",
                    "USUARIO RED": "quin15301",
                    "CORREOELECTRONICO": "quin15301@siigo.com",
                    "STATION ID": "2606950",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014250062",
                    "FECHA INICIAL": "2018-09-03",
                    "FECHA OPERACION": "2018-09-03",
                    "NOMBRES": "Rodrigo Duenas",
                    "APELLIDOS": "Chaparro",
                    "NOMBRES COMPLETOS": "Rodrigo Duenas Chaparro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "David Felipe Penagos Mosquera",
                    "CODIGO USUARIO": "duen111761",
                    "USUARIOFIVE9": "duen111761@siigo.com",
                    "USUARIO RED": "duen111761",
                    "CORREOELECTRONICO": "duen111761@siigo.com",
                    "STATION ID": "2606947",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_David Felipe Penagos Mosquera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79627058",
                    "FECHA INICIAL": "2010-05-28",
                    "FECHA OPERACION": "2010-05-28",
                    "NOMBRES": "Edilberto",
                    "APELLIDOS": "Rivera Pacheco",
                    "NOMBRES COMPLETOS": "Edilberto Rivera Pacheco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Francy Idaly Muela Muelas",
                    "CODIGO USUARIO": "rive267209",
                    "USUARIOFIVE9": "rive267209@siigo.com",
                    "USUARIO RED": "rive267209",
                    "CORREOELECTRONICO": "rive267209@siigo.com",
                    "STATION ID": "2606655",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Francy Idaly Muela Muelas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "71266991",
                    "FECHA INICIAL": "2011-02-15",
                    "FECHA OPERACION": "2011-02-15",
                    "NOMBRES": "Edison Armando",
                    "APELLIDOS": "Quintero",
                    "NOMBRES COMPLETOS": "Edison Armando Quintero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Tech",
                    "SUBAREA": "QA",
                    "CARGO": "QA",
                    "JEFE INMEDIATO": "Virginia Guadalupe Ramirez Hernandez",
                    "CODIGO USUARIO": "quin111915",
                    "USUARIOFIVE9": "quin111915@siigo.com",
                    "USUARIO RED": "quin111915",
                    "CORREOELECTRONICO": "quin111915@siigo.com",
                    "STATION ID": "2606802",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Virginia Guadalupe Ramirez Hernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80036793",
                    "FECHA INICIAL": "2013-06-12",
                    "FECHA OPERACION": "2013-06-12",
                    "NOMBRES": "Mario Alberto",
                    "APELLIDOS": "Nieto Bernal",
                    "NOMBRES COMPLETOS": "Mario Alberto Nieto Bernal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Kely Andrea Diaz Granados",
                    "CODIGO USUARIO": "niet27883",
                    "USUARIOFIVE9": "niet27883@siigo.com",
                    "USUARIO RED": "niet27883",
                    "CORREOELECTRONICO": "niet27883@siigo.com",
                    "STATION ID": "2606623",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Kely Andrea Diaz Granados",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024558597",
                    "FECHA INICIAL": "2014-01-20",
                    "FECHA OPERACION": "2014-01-20",
                    "NOMBRES": "Nicolas Alejandro",
                    "APELLIDOS": "Sarmiento Poveda",
                    "NOMBRES COMPLETOS": "Nicolas Alejandro Sarmiento Poveda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "QA",
                    "CARGO": "QA",
                    "JEFE INMEDIATO": "Frankgel Ribely Machado Pantoja",
                    "CODIGO USUARIO": "sarm32254",
                    "USUARIOFIVE9": "sarm32254@siigo.com",
                    "USUARIO RED": "sarm32254",
                    "CORREOELECTRONICO": "sarm32254@siigo.com",
                    "STATION ID": "2606608",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Frankgel Ribely Machado Pantoja",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033757779",
                    "FECHA INICIAL": "2012-05-14",
                    "FECHA OPERACION": "2012-05-14",
                    "NOMBRES": "Cristan Lorena",
                    "APELLIDOS": "Pardo Castano",
                    "NOMBRES COMPLETOS": "Cristan Lorena Pardo Castano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "pard20556",
                    "USUARIOFIVE9": "pard20556@siigo.com",
                    "USUARIO RED": "pard20556",
                    "CORREOELECTRONICO": "pard20556@siigo.com",
                    "STATION ID": "2606930",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79636388",
                    "FECHA INICIAL": "2013-06-04",
                    "FECHA OPERACION": "2013-06-04",
                    "NOMBRES": "Jose Daniel",
                    "APELLIDOS": "Huerfano Martinez",
                    "NOMBRES COMPLETOS": "Jose Daniel Huerfano Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Divulgacion",
                    "CARGO": "Divulgacion",
                    "JEFE INMEDIATO": "Carlos Maximo Garrido Mascott",
                    "CODIGO USUARIO": "huer28056",
                    "USUARIOFIVE9": "huer28056@siigo.com",
                    "USUARIO RED": "huer28056",
                    "CORREOELECTRONICO": "huer28056@siigo.com",
                    "STATION ID": "3690341",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Carlos Maximo Garrido Mascott",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012433173",
                    "FECHA INICIAL": "2017-12-21",
                    "FECHA OPERACION": "2017-12-21",
                    "NOMBRES": "Erika Mildred",
                    "APELLIDOS": "Avila Olaya",
                    "NOMBRES COMPLETOS": "Erika Mildred Avila Olaya",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Jarbin Dadey Rodriguez Pena",
                    "CODIGO USUARIO": "avil111450",
                    "USUARIOFIVE9": "avil111450@siigo.com",
                    "USUARIO RED": "avil111450",
                    "CORREOELECTRONICO": "avil111450@siigo.com",
                    "STATION ID": "2606893",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jarbin Dadey Rodriguez Pena",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79660702",
                    "FECHA INICIAL": "1998-09-01",
                    "FECHA OPERACION": "1998-09-01",
                    "NOMBRES": "Jarbin Dadey",
                    "APELLIDOS": "Rodriguez Pena",
                    "NOMBRES COMPLETOS": "Jarbin Dadey Rodriguez Pena",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Gerente Entrenamiento y Divulgacion",
                    "JEFE INMEDIATO": "Carlos German Lopez Murcia",
                    "CODIGO USUARIO": "rodr4158",
                    "USUARIOFIVE9": "rodr4158@siigo.com",
                    "USUARIO RED": "rodr4158",
                    "CORREOELECTRONICO": "rodr4158@siigo.com",
                    "STATION ID": "2606895",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Carlos German Lopez Murcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80095455",
                    "FECHA INICIAL": "2018-07-03",
                    "FECHA OPERACION": "2018-07-03",
                    "NOMBRES": "Jefferson Arvey",
                    "APELLIDOS": "Ramirez Ibanez",
                    "NOMBRES COMPLETOS": "Jefferson Arvey Ramirez Ibanez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Magda Lilian Leal Rozo",
                    "CODIGO USUARIO": "rami108689",
                    "USUARIOFIVE9": "rami108689@siigo.com",
                    "USUARIO RED": "rami108689",
                    "CORREOELECTRONICO": "rami108689@siigo.com",
                    "STATION ID": "2606894",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Magda Lilian Leal Rozo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1026279043",
                    "FECHA INICIAL": "2015-03-16",
                    "FECHA OPERACION": "2015-03-16",
                    "NOMBRES": "Jimena Marcela",
                    "APELLIDOS": "Prieto Bohorquez",
                    "NOMBRES COMPLETOS": "Jimena Marcela Prieto Bohorquez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Jarbin Dadey Rodriguez Pena",
                    "CODIGO USUARIO": "prie108975",
                    "USUARIOFIVE9": "prie108975@siigo.com",
                    "USUARIO RED": "prie108975",
                    "CORREOELECTRONICO": "prie108975@siigo.com",
                    "STATION ID": "2606602",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jarbin Dadey Rodriguez Pena",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019072367",
                    "FECHA INICIAL": "2013-09-02",
                    "FECHA OPERACION": "2013-09-02",
                    "NOMBRES": "Sergio Arturo",
                    "APELLIDOS": "Florez Jimenez",
                    "NOMBRES COMPLETOS": "Sergio Arturo Florez Jimenez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Lina Maria Buitrago Herrera",
                    "CODIGO USUARIO": "flor29511",
                    "USUARIOFIVE9": "flor29511@siigo.com",
                    "USUARIO RED": "flor29511",
                    "CORREOELECTRONICO": "flor29511@siigo.com",
                    "STATION ID": "2606663",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Lina Maria Buitrago Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79938267",
                    "FECHA INICIAL": "2019-01-28",
                    "FECHA OPERACION": "2019-01-28",
                    "NOMBRES": "Victor Alexis",
                    "APELLIDOS": "Buitrago Garay",
                    "NOMBRES COMPLETOS": "Victor Alexis Buitrago Garay",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Lina Maria Buitrago Herrera",
                    "CODIGO USUARIO": "buit111857",
                    "USUARIOFIVE9": "buit111857@siigo.com",
                    "USUARIO RED": "buit111857",
                    "CORREOELECTRONICO": "buit111857@siigo.com",
                    "STATION ID": "2606516",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Lina Maria Buitrago Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79522864",
                    "FECHA INICIAL": "2006-03-06",
                    "FECHA OPERACION": "2006-03-06",
                    "NOMBRES": "Harold Herrera",
                    "APELLIDOS": "Chavarro",
                    "NOMBRES COMPLETOS": "Harold Herrera Chavarro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Jenny Carolina Moreno Duarte",
                    "CODIGO USUARIO": "herr197198",
                    "USUARIOFIVE9": "herr197198@siigo.com",
                    "USUARIO RED": "herr197198",
                    "CORREOELECTRONICO": "herr197198@siigo.com",
                    "STATION ID": "2606657",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Jenny Carolina Moreno Duarte",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "14241101",
                    "FECHA INICIAL": "2016-04-12",
                    "FECHA OPERACION": "2016-04-12",
                    "NOMBRES": "Alexander Troncoso",
                    "APELLIDOS": "Morales",
                    "NOMBRES COMPLETOS": "Alexander Troncoso Morales",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Hector Orlando Bogoya aguilar",
                    "CODIGO USUARIO": "tron110078",
                    "USUARIOFIVE9": "tron110078@siigo.com",
                    "USUARIO RED": "tron110078",
                    "CORREOELECTRONICO": "tron110078@siigo.com",
                    "STATION ID": "2606979",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Hector Orlando Bogoya aguilar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79880291",
                    "FECHA INICIAL": "2013-03-04",
                    "FECHA OPERACION": "2013-03-04",
                    "NOMBRES": "Carlos Alberto",
                    "APELLIDOS": "Rativa Quintero",
                    "NOMBRES COMPLETOS": "Carlos Alberto Rativa Quintero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Yudy Paola Rojas Rojas",
                    "CODIGO USUARIO": "rati25640",
                    "USUARIOFIVE9": "rati25640@siigo.com",
                    "USUARIO RED": "rati25640",
                    "CORREOELECTRONICO": "rati25640@siigo.com",
                    "STATION ID": "2606986",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Yudy Paola Rojas Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "51952674",
                    "FECHA INICIAL": "1998-09-01",
                    "FECHA OPERACION": "1998-09-01",
                    "NOMBRES": "Elsa Gomez",
                    "APELLIDOS": "Gonzalez",
                    "NOMBRES COMPLETOS": "Elsa Gomez Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Hector Orlando Bogoya aguilar",
                    "CODIGO USUARIO": "gome4071",
                    "USUARIOFIVE9": "gome4071@siigo.com",
                    "USUARIO RED": "gome4071",
                    "CORREOELECTRONICO": "gome4071@siigo.com",
                    "STATION ID": "2606988",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Hector Orlando Bogoya aguilar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52418361",
                    "FECHA INICIAL": "2011-09-12",
                    "FECHA OPERACION": "2011-09-12",
                    "NOMBRES": "Luz Mireya",
                    "APELLIDOS": "Nino Duran",
                    "NOMBRES COMPLETOS": "Luz Mireya Nino Duran",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Hector Orlando Bogoya aguilar",
                    "CODIGO USUARIO": "nino16038",
                    "USUARIOFIVE9": "nino16038@siigo.com",
                    "USUARIO RED": "nino16038",
                    "CORREOELECTRONICO": "nino16038@siigo.com",
                    "STATION ID": "2606984",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Hector Orlando Bogoya aguilar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018407691",
                    "FECHA INICIAL": "2015-07-06",
                    "FECHA OPERACION": "2015-07-06",
                    "NOMBRES": "Oscar Andres",
                    "APELLIDOS": "Nino Avila",
                    "NOMBRES COMPLETOS": "Oscar Andres Nino Avila",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Juan Carlos Guarin Walteros",
                    "CODIGO USUARIO": "oscar.nino",
                    "USUARIOFIVE9": "oscar.nino@siigo.com",
                    "USUARIO RED": "oscar.nino",
                    "CORREOELECTRONICO": "oscar.nino@siigo.com",
                    "STATION ID": "2606987",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Juan Carlos Guarin Walteros",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "51873081",
                    "FECHA INICIAL": "2001-04-09",
                    "FECHA OPERACION": "2001-04-09",
                    "NOMBRES": "Sandra Marcela",
                    "APELLIDOS": "Prieto Palacios",
                    "NOMBRES COMPLETOS": "Sandra Marcela Prieto Palacios",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Hector Orlando Bogoya aguilar",
                    "CODIGO USUARIO": "prie11813",
                    "USUARIOFIVE9": "prie11813@siigo.com",
                    "USUARIO RED": "prie11813",
                    "CORREOELECTRONICO": "prie11813@siigo.com",
                    "STATION ID": "2606983",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Hector Orlando Bogoya aguilar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "5873786",
                    "FECHA INICIAL": "2019-07-24",
                    "FECHA OPERACION": "2019-07-24",
                    "NOMBRES": "Mayra Victoria",
                    "APELLIDOS": "Torrez Fernandez",
                    "NOMBRES COMPLETOS": "Mayra Victoria Torres Fernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Magda de Jesus Becerra Castaneda",
                    "CODIGO USUARIO": "torr112203",
                    "USUARIOFIVE9": "torr112203@siigo.com",
                    "USUARIO RED": "torr112203",
                    "CORREOELECTRONICO": "torr112203@siigo.com",
                    "STATION ID": "2606896",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Magda de Jesus Becerra Castaneda",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019090171",
                    "FECHA INICIAL": "2018-09-01",
                    "FECHA OPERACION": "2018-09-01",
                    "NOMBRES": "Paula Andrea",
                    "APELLIDOS": "Alfonso Chacon",
                    "NOMBRES COMPLETOS": "Paula Andrea Alfonso Canchon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoraciones",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Sandra Catalina Suarez Castillo",
                    "CODIGO USUARIO": "alfo111669",
                    "USUARIOFIVE9": "alfo111669@siigo.com",
                    "USUARIO RED": "alfo111669",
                    "CORREOELECTRONICO": "alfo111669@siigo.com",
                    "STATION ID": "2606892",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Sandra Catalina Suarez Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1031173844",
                    "FECHA INICIAL": "2018-12-26",
                    "FECHA OPERACION": "2018-12-26",
                    "NOMBRES": "Daniel Alejandro",
                    "APELLIDOS": "Pena Martinez",
                    "NOMBRES COMPLETOS": "Daniel Alejandro Pena Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Workfoce & Reporting",
                    "CARGO": "Analista de Workforce",
                    "JEFE INMEDIATO": "Julieth Paola Vera Patino",
                    "CODIGO USUARIO": "pena111830",
                    "USUARIOFIVE9": "pena111830@siigo.com",
                    "USUARIO RED": "pena111830",
                    "CORREOELECTRONICO": "pena111830@siigo.com",
                    "STATION ID": "2606598",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Julieth Paola Vera Patino",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013609545",
                    "FECHA INICIAL": "2019-07-29",
                    "FECHA OPERACION": "2019-07-29",
                    "NOMBRES": "Francy Julieth",
                    "APELLIDOS": "Viracacha Gutierrez",
                    "NOMBRES COMPLETOS": "Francy Julieth Viracacha Gutierrez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Lider Renovaciones",
                    "JEFE INMEDIATO": "John Alexander Benavides Cobos",
                    "CODIGO USUARIO": "vira112210",
                    "USUARIOFIVE9": "vira112210@siigo.com",
                    "USUARIO RED": "vira112210",
                    "CORREOELECTRONICO": "vira112210@siigo.com",
                    "STATION ID": "2606546",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_John Alexander Benavides Cobos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015477076",
                    "FECHA INICIAL": "2019-12-02",
                    "FECHA OPERACION": "2019-12-02",
                    "NOMBRES": "Ana Maria",
                    "APELLIDOS": "Moreno Mora",
                    "NOMBRES COMPLETOS": "Ana Maria Moreno Mora",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Mercadeo",
                    "CARGO": "Mercadeo",
                    "JEFE INMEDIATO": "Lizeth Janney Ayala Zuluaga",
                    "CODIGO USUARIO": "more800007",
                    "USUARIOFIVE9": "more800007@siigo.com",
                    "USUARIO RED": "more800007",
                    "CORREOELECTRONICO": "more800007@siigo.com",
                    "STATION ID": "2606564",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Lizeth Janney Ayala Zuluaga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032368295",
                    "FECHA INICIAL": "2019-11-07",
                    "FECHA OPERACION": "2019-11-07",
                    "NOMBRES": "Lilian Paola",
                    "APELLIDOS": "Villanueva Ospina",
                    "NOMBRES COMPLETOS": "Lilian Paola Villanueva Ospina",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Producto Back-Office",
                    "CARGO": "Experience Manager",
                    "JEFE INMEDIATO": "Juan Carlos Corazzo Santos",
                    "CODIGO USUARIO": "vill112505",
                    "USUARIOFIVE9": "vill112505@siigo.com",
                    "USUARIO RED": "vill112505",
                    "CORREOELECTRONICO": "vill112505@siigo.com",
                    "STATION ID": "2606811",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Carlos Corazzo Santos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "63524543",
                    "FECHA INICIAL": "2019-08-13",
                    "FECHA OPERACION": "2019-08-13",
                    "NOMBRES": "Eslendy Johanna",
                    "APELLIDOS": "Pena Jaimes",
                    "NOMBRES COMPLETOS": "Eslendy Johanna Pena Jaimes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juvenal Reyes Silva",
                    "CODIGO USUARIO": "pena21038",
                    "USUARIOFIVE9": "pena21038@siigo.com",
                    "USUARIO RED": "pena21038",
                    "CORREOELECTRONICO": "pena21038@siigo.com",
                    "STATION ID": "2606715",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juvenal Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023867323",
                    "FECHA INICIAL": "2014-01-20",
                    "FECHA OPERACION": "2014-01-20",
                    "NOMBRES": "Gissel Patricia",
                    "APELLIDOS": "Urrego Mendez",
                    "NOMBRES COMPLETOS": "Gissel Patricia Urrego Mendez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Jimena Marcela Prieto Bohorquez",
                    "CODIGO USUARIO": "urre32258",
                    "USUARIOFIVE9": "urre32258@siigo.com",
                    "USUARIO RED": "urre32258",
                    "CORREOELECTRONICO": "urre32258@siigo.com",
                    "STATION ID": "2606601",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jimena Marcela Prieto Bohorquez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "93237369",
                    "FECHA INICIAL": "2007-04-09",
                    "FECHA OPERACION": "2007-04-09",
                    "NOMBRES": "Jorge Andres",
                    "APELLIDOS": "Zabala Mayorga",
                    "NOMBRES COMPLETOS": "Jorge Andres Zabala Mayorga",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Administrativos",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Diego Armando Aristizabal Bedoya",
                    "CODIGO USUARIO": "zaba217769",
                    "USUARIOFIVE9": "zaba217769@siigo.com",
                    "USUARIO RED": "zaba217769",
                    "CORREOELECTRONICO": "zaba217769@siigo.com",
                    "STATION ID": "2606693",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diego Armando Aristizabal Bedoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52906984",
                    "FECHA INICIAL": "2014-02-03",
                    "FECHA OPERACION": "2014-02-03",
                    "NOMBRES": "Julie Ximena",
                    "APELLIDOS": "Alfonso Chavarria",
                    "NOMBRES COMPLETOS": "Julie Ximena Alfonso Chavarria",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Lina Maria Buitrago Herrera",
                    "CODIGO USUARIO": "alfo32597",
                    "USUARIOFIVE9": "alfo32597@siigo.com",
                    "USUARIO RED": "alfo32597",
                    "CORREOELECTRONICO": "alfo32597@siigo.com",
                    "STATION ID": "2606620",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Lina Maria Buitrago Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1128453002",
                    "FECHA INICIAL": "2019-02-15",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Julieth Andrea",
                    "APELLIDOS": "Ruiz Penagos",
                    "NOMBRES COMPLETOS": "Julieth Andrea Ruiz Penagos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Angie Johanna Gomez Lopez",
                    "CODIGO USUARIO": "ruiz111924",
                    "USUARIOFIVE9": "ruiz111924@siigo.com",
                    "USUARIO RED": "ruiz111924",
                    "CORREOELECTRONICO": "ruiz111924@siigo.com",
                    "STATION ID": "2606612",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Angie Johanna Gomez Lopez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52081636",
                    "FECHA INICIAL": "2016-05-16",
                    "FECHA OPERACION": "2016-05-16",
                    "NOMBRES": "Lucy Esperanza",
                    "APELLIDOS": "Fuentes Martinez",
                    "NOMBRES COMPLETOS": "Lucy Esperanza Fuentes Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoracion",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "fuen110133",
                    "USUARIOFIVE9": "fuen110133@siigo.com",
                    "USUARIO RED": "fuen110133",
                    "CORREOELECTRONICO": "fuen110133@siigo.com",
                    "STATION ID": "2606660",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1026294963",
                    "FECHA INICIAL": "2019-11-13",
                    "FECHA OPERACION": "2019-11-13",
                    "NOMBRES": "Cristian Felipe",
                    "APELLIDOS": "Jimenez Roncancio",
                    "NOMBRES COMPLETOS": "Cristian Felipe Jimenez Roncancio",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Uruguay",
                    "CARGO": "Experience Manager Jr",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "jime112533",
                    "USUARIOFIVE9": "jime112533@siigo.com",
                    "USUARIO RED": "jime112533",
                    "CORREOELECTRONICO": "jime112533@siigo.com",
                    "STATION ID": "2606810",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52515751",
                    "FECHA INICIAL": "2014-10-15",
                    "FECHA OPERACION": "2014-10-15",
                    "NOMBRES": "Yeimy Milena",
                    "APELLIDOS": "Bustos Gutierrez",
                    "NOMBRES COMPLETOS": "Yeimy Milena Bustos Gutierrez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Lider Renovaciones",
                    "JEFE INMEDIATO": "John Alexander Benavides Cobos",
                    "CODIGO USUARIO": "bust40937",
                    "USUARIOFIVE9": "bust40937@siigo.com",
                    "USUARIO RED": "bust40937",
                    "CORREOELECTRONICO": "bust40937@siigo.com",
                    "STATION ID": "2606385",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_John Alexander Benavides Cobos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1116802103",
                    "FECHA INICIAL": "2019-10-09",
                    "FECHA OPERACION": "2019-10-09",
                    "NOMBRES": "Angelica Maria",
                    "APELLIDOS": "Guzman Gonzalez",
                    "NOMBRES COMPLETOS": "Angelica Maria Guzman Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Fundacion",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "guzm112455",
                    "USUARIOFIVE9": "guzm112455@siigo.com",
                    "USUARIO RED": "guzm112455",
                    "CORREOELECTRONICO": "guzm112455@siigo.com",
                    "STATION ID": "2606927",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012457571",
                    "FECHA INICIAL": "2019-08-01",
                    "FECHA OPERACION": "2019-08-01",
                    "NOMBRES": "Astrid Viviana",
                    "APELLIDOS": "Corredor Herreno",
                    "NOMBRES COMPLETOS": "Astrid Viviana Corredor Herreno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Divulgacion",
                    "CARGO": "Divulgacion",
                    "JEFE INMEDIATO": "Jose Daniel Huerfano Martinez",
                    "CODIGO USUARIO": "corr112286",
                    "USUARIOFIVE9": "corr112286@siigo.com",
                    "USUARIO RED": "corr112286",
                    "CORREOELECTRONICO": "corr112286@siigo.com",
                    "STATION ID": "2606923",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jose Daniel Huerfano Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1069435389",
                    "FECHA INICIAL": "2020-08-03",
                    "FECHA OPERACION": "2020-08-03",
                    "NOMBRES": "Owen Stiven",
                    "APELLIDOS": "Bermudez Arias",
                    "NOMBRES COMPLETOS": "Owen Stiven Bermudez Arias",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "berm800493",
                    "USUARIOFIVE9": "berm800493@siigo.com",
                    "USUARIO RED": "berm800493",
                    "CORREOELECTRONICO": "berm800493@siigo.com",
                    "STATION ID": "2606022",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "20500106",
                    "FECHA INICIAL": "2006-10-02",
                    "FECHA OPERACION": "2006-10-02",
                    "NOMBRES": "Nelsy Adriana",
                    "APELLIDOS": "Sanchez Ramirez",
                    "NOMBRES COMPLETOS": "Nelsy Adriana Sanchez Ramirez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Director",
                    "JEFE INMEDIATO": "David Ortiz Diaz",
                    "CODIGO USUARIO": "sanc207617",
                    "USUARIOFIVE9": "sanc207617@siigo.com",
                    "USUARIO RED": "sanc207617",
                    "CORREOELECTRONICO": "sanc207617@siigo.com",
                    "STATION ID": "2606926",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_David Ortiz Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "20723918",
                    "FECHA INICIAL": "2011-05-02",
                    "FECHA OPERACION": "2011-05-02",
                    "NOMBRES": "Nubia Abril",
                    "APELLIDOS": "Arevalo",
                    "NOMBRES COMPLETOS": "Nubia Abril Arevalo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Fundacion",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "abri11170",
                    "USUARIOFIVE9": "abri11170@siigo.com",
                    "USUARIO RED": "abri11170",
                    "CORREOELECTRONICO": "abri11170@siigo.com",
                    "STATION ID": "2606917",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033803664",
                    "FECHA INICIAL": "2018-09-20",
                    "FECHA OPERACION": "2018-09-20",
                    "NOMBRES": "Yuranis Oliveros",
                    "APELLIDOS": "Ariza",
                    "NOMBRES COMPLETOS": "Yuranis Oliveros Ariza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Fundacion",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "oliv111679",
                    "USUARIOFIVE9": "oliv111679@siigo.com",
                    "USUARIO RED": "oliv111679",
                    "CORREOELECTRONICO": "oliv111679@siigo.com",
                    "STATION ID": "2606921",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1193250956",
                    "FECHA INICIAL": "2020-05-06",
                    "FECHA OPERACION": "2020-05-06",
                    "NOMBRES": "Esteban De Jesus",
                    "APELLIDOS": "Agamez Montero",
                    "NOMBRES COMPLETOS": "Esteban De Jesus Agamez Montero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Tech",
                    "SUBAREA": "Infraestructura",
                    "CARGO": "Infraestructura",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "agam112508",
                    "USUARIOFIVE9": "agam112508@siigo.com",
                    "USUARIO RED": "agam112508",
                    "CORREOELECTRONICO": "agam112508@siigo.com",
                    "STATION ID": "2606942",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "422618",
                    "FECHA INICIAL": "2019-03-06",
                    "FECHA OPERACION": "2019-03-06",
                    "NOMBRES": "Jesus Dario",
                    "APELLIDOS": "Sanchez Nava",
                    "NOMBRES COMPLETOS": "Jesus Dario Sanchez Nava",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Edgardo Artusi Etcheto",
                    "CODIGO USUARIO": "jesus.sanchez",
                    "USUARIOFIVE9": "jesus.sanchez@siigo.com",
                    "USUARIO RED": "jesus.sanchez",
                    "CORREOELECTRONICO": "jesus.sanchez@siigo.com",
                    "STATION ID": "2606907",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Edgardo Artusi Etcheto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023867327",
                    "FECHA INICIAL": "2018-05-02",
                    "FECHA OPERACION": "2018-05-02",
                    "NOMBRES": "Jose Mario",
                    "APELLIDOS": "Gomez Rodriguez",
                    "NOMBRES COMPLETOS": "Jose Mario Gomez Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Tecnologia",
                    "CARGO": "Director TI",
                    "JEFE INMEDIATO": "Peter Anderson Vargas Grajales",
                    "CODIGO USUARIO": "gome111689",
                    "USUARIOFIVE9": "jose.gomez@siigo.com",
                    "USUARIO RED": "gome111689",
                    "CORREOELECTRONICO": "jose.gomez@siigo.com",
                    "STATION ID": "2607025",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Peter Anderson Vargas Grajales",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "13724101",
                    "FECHA INICIAL": "2010-09-01",
                    "FECHA OPERACION": "2010-09-01",
                    "NOMBRES": "Mario Alberto",
                    "APELLIDOS": "Rojas Gamboa",
                    "NOMBRES COMPLETOS": "Mario Alberto Rojas Gamboa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "IT",
                    "SUBAREA": "Tecnologia",
                    "CARGO": "Infraestructura",
                    "JEFE INMEDIATO": "Alberto Guerrero Garcia",
                    "CODIGO USUARIO": "roja190245",
                    "USUARIOFIVE9": "roja190245@siigo.com",
                    "USUARIO RED": "roja190245",
                    "CORREOELECTRONICO": "roja190245@siigo.com",
                    "STATION ID": "2606905",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "IT_Alberto Guerrero Garcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030665800",
                    "FECHA INICIAL": "2019-11-12",
                    "FECHA OPERACION": "2019-11-12",
                    "NOMBRES": "Michael Andres",
                    "APELLIDOS": "Casallas Malagon",
                    "NOMBRES COMPLETOS": "Michael Andres Casallas Malagon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "IT",
                    "SUBAREA": "Tecnologia",
                    "CARGO": "Infraestructura",
                    "JEFE INMEDIATO": "Jesus Amado Gaibor Yanez",
                    "CODIGO USUARIO": "casa112526",
                    "USUARIOFIVE9": "casa112526@siigo.com",
                    "USUARIO RED": "casa112526",
                    "CORREOELECTRONICO": "casa112526@siigo.com",
                    "STATION ID": "2606989",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "IT_Jesus Amado Gaibor Yanez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022376143",
                    "FECHA INICIAL": "2019-09-25",
                    "FECHA OPERACION": "2019-09-25",
                    "NOMBRES": "Oscar Daniel",
                    "APELLIDOS": "Mejia Canaval",
                    "NOMBRES COMPLETOS": "Oscar Daniel Mejia Canaval",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "IT",
                    "SUBAREA": "Tecnologia",
                    "CARGO": "Infraestructura",
                    "JEFE INMEDIATO": "David Rosero Calle",
                    "CODIGO USUARIO": "meji111988",
                    "USUARIOFIVE9": "meji111988@siigo.com",
                    "USUARIO RED": "meji111988",
                    "CORREOELECTRONICO": "meji111988@siigo.com",
                    "STATION ID": "2606945",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "IT_David Rosero Calle",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023023899",
                    "FECHA INICIAL": "2018-12-10",
                    "FECHA OPERACION": "2018-12-10",
                    "NOMBRES": "Yors William",
                    "APELLIDOS": "Aranda Gaona",
                    "NOMBRES COMPLETOS": "Yors William Aranda Gaona",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "IT",
                    "SUBAREA": "Tecnologia",
                    "CARGO": "Infraestructura",
                    "JEFE INMEDIATO": "Jose Mario Gomez Rodriguez",
                    "CODIGO USUARIO": "aran111713",
                    "USUARIOFIVE9": "aran111713@siigo.com",
                    "USUARIO RED": "aran111713",
                    "CORREOELECTRONICO": "aran111713@siigo.com",
                    "STATION ID": "2606906",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "IT_Jose Mario Gomez Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018437430",
                    "FECHA INICIAL": "2019-02-01",
                    "FECHA OPERACION": "2019-02-01",
                    "NOMBRES": "Gina Estefania",
                    "APELLIDOS": "Melo Sanchez",
                    "NOMBRES COMPLETOS": "Gina Estefania Melo Sanchez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Mercadeo",
                    "CARGO": "Analista Mercadeo",
                    "JEFE INMEDIATO": "Julian Bernardo Fernandez Garcia",
                    "CODIGO USUARIO": "Melo109964",
                    "USUARIOFIVE9": "melo109964@siigo.com",
                    "USUARIO RED": "Melo109964",
                    "CORREOELECTRONICO": "melo109964@siigo.com",
                    "STATION ID": "-",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Julian Bernardo Fernandez Garcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79795205",
                    "FECHA INICIAL": "2020-01-27",
                    "FECHA OPERACION": "2020-01-27",
                    "NOMBRES": "Julian Ernesto",
                    "APELLIDOS": "Morales Garzon",
                    "NOMBRES COMPLETOS": "Julian Ernesto Morales Garzon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Director Venta Cruzada",
                    "JEFE INMEDIATO": "Claudio Federico Hornes Ismail",
                    "CODIGO USUARIO": "mora800183",
                    "USUARIOFIVE9": "mora800183@siigo.com",
                    "USUARIO RED": "mora800183",
                    "CORREOELECTRONICO": "mora800183@siigo.com",
                    "STATION ID": "2605999",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Claudio Federico Hornes Ismail",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010044780",
                    "FECHA INICIAL": "2019-06-11",
                    "FECHA OPERACION": "2019-06-11",
                    "NOMBRES": "Laura Paola",
                    "APELLIDOS": "Ruiz Mejia",
                    "NOMBRES COMPLETOS": "Laura Paola Ruiz Mejia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cultura",
                    "SUBAREA": "Cultura",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Ivon Andrea Rios Rodriguez",
                    "CODIGO USUARIO": "ruiz112147",
                    "USUARIOFIVE9": "ruiz112147@siigo.com",
                    "USUARIO RED": "ruiz112147",
                    "CORREOELECTRONICO": "ruiz112147@siigo.com",
                    "STATION ID": "2606934",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cultura_Ivon Andrea Rios Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1006129743",
                    "FECHA INICIAL": "2014-01-02",
                    "FECHA OPERACION": "2014-01-02",
                    "NOMBRES": "Rocxy Daniela",
                    "APELLIDOS": "Fernandez Fernandez",
                    "NOMBRES COMPLETOS": "Rocxy Daniela Fernandez Fernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "RRHH",
                    "CARGO": "Coordinador RRHH",
                    "JEFE INMEDIATO": "Diana Carolina Perdomo Rodriguez",
                    "CODIGO USUARIO": "fern28325",
                    "USUARIOFIVE9": "fern28325@siigo.com",
                    "USUARIO RED": "fern28325",
                    "CORREOELECTRONICO": "fern28325@siigo.com",
                    "STATION ID": "2606931",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Diana Carolina Perdomo Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024462622",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-03-02",
                    "NOMBRES": "Adriana Patricia",
                    "APELLIDOS": "Murcia Mahecha",
                    "NOMBRES COMPLETOS": "Adriana Patricia Murcia Mahecha",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoracion",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "murc800376",
                    "USUARIOFIVE9": "murc800376@siigo.com",
                    "USUARIO RED": "murc800376",
                    "CORREOELECTRONICO": "murc800376@siigo.com",
                    "STATION ID": "2605948",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "84074002",
                    "FECHA INICIAL": "2018-12-27",
                    "FECHA OPERACION": "2018-12-27",
                    "NOMBRES": "Arlen Rafael",
                    "APELLIDOS": "Robles Mejia",
                    "NOMBRES COMPLETOS": "Arlen Rafael Robles Mejia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Pierre Fardey Velasquez Sanchez",
                    "CODIGO USUARIO": "robl111907",
                    "USUARIOFIVE9": "robl111907@siigo.com",
                    "USUARIO RED": "robl111907",
                    "CORREOELECTRONICO": "robl111907@siigo.com",
                    "STATION ID": "2606629",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Pierre Fardey Velasquez Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1067462305",
                    "FECHA INICIAL": "2015-08-13",
                    "FECHA OPERACION": "2015-08-13",
                    "NOMBRES": "Leidy Stefany",
                    "APELLIDOS": "Castillo",
                    "NOMBRES COMPLETOS": "Leidy Stefany Castillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Yessica Yulieth Quintero Martinez",
                    "CODIGO USUARIO": "cast109928",
                    "USUARIOFIVE9": "cast109928@siigo.com",
                    "USUARIO RED": "cast109928",
                    "CORREOELECTRONICO": "cast109928@siigo.com",
                    "STATION ID": "2606644",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yessica Yulieth Quintero Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "39562133",
                    "FECHA INICIAL": "2009-05-11",
                    "FECHA OPERACION": "2009-05-11",
                    "NOMBRES": "Alexandra Garcia",
                    "APELLIDOS": "Rozo",
                    "NOMBRES COMPLETOS": "Alexandra Garcia Rozo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Contenidos",
                    "CARGO": "Analista Gestion del Conocimiento",
                    "JEFE INMEDIATO": "Luz Angelica Torres Quintero",
                    "CODIGO USUARIO": "garc31761",
                    "USUARIOFIVE9": "garc31761@siigo.com",
                    "USUARIO RED": "garc31761",
                    "CORREOELECTRONICO": "garc31761@siigo.com",
                    "STATION ID": "2606664",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Angelica Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033777002",
                    "FECHA INICIAL": "2018-07-01",
                    "FECHA OPERACION": "2018-07-01",
                    "NOMBRES": "Ingrit Jhoana",
                    "APELLIDOS": "Vasquez Fuquen",
                    "NOMBRES COMPLETOS": "Ingrit Jhoana Vasquez Fuquen",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Contenidos",
                    "CARGO": "Analista Gestion del Conocimiento",
                    "JEFE INMEDIATO": "Luz Angelica Torres Quintero",
                    "CODIGO USUARIO": "vasq111671",
                    "USUARIOFIVE9": "vasq111671@siigo.com",
                    "USUARIO RED": "vasq111671",
                    "CORREOELECTRONICO": "vasq111671@siigo.com",
                    "STATION ID": "2606618",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Angelica Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80130188",
                    "FECHA INICIAL": "2013-01-14",
                    "FECHA OPERACION": "2013-01-14",
                    "NOMBRES": "Milton Enrique",
                    "APELLIDOS": "Pulido Bautista",
                    "NOMBRES COMPLETOS": "Milton Enrique Pulido Bautista",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoraciones",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Sandra Catalina Suarez Castillo",
                    "CODIGO USUARIO": "puli20965",
                    "USUARIOFIVE9": "puli20965@siigo.com",
                    "USUARIO RED": "puli20965",
                    "CORREOELECTRONICO": "puli20965@siigo.com",
                    "STATION ID": "2606606",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Sandra Catalina Suarez Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030581231",
                    "FECHA INICIAL": "2020-02-17",
                    "FECHA OPERACION": "2020-02-17",
                    "NOMBRES": "Harold Mauricio",
                    "APELLIDOS": "Toro Vargas",
                    "NOMBRES COMPLETOS": "Harold Mauricio Toro Vargas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Eliana Maria Fajardo Prada",
                    "CODIGO USUARIO": "toro800276",
                    "USUARIOFIVE9": "toro800276@siigo.com",
                    "USUARIO RED": "toro800276",
                    "CORREOELECTRONICO": "toro800276@siigo.com",
                    "STATION ID": "2607083",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Eliana Maria Fajardo Prada",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000565425",
                    "FECHA INICIAL": "2019-07-02",
                    "FECHA OPERACION": "2019-07-02",
                    "NOMBRES": "Angel Santiago",
                    "APELLIDOS": "Machado Restrepo",
                    "NOMBRES COMPLETOS": "Angel Santiago Machado Restrepo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Quality Control",
                    "JEFE INMEDIATO": "Edison Armando Quintero",
                    "CODIGO USUARIO": "mach111992",
                    "USUARIOFIVE9": "mach111992@siigo.com",
                    "USUARIO RED": "mach111992",
                    "CORREOELECTRONICO": "mach111992@siigo.com",
                    "STATION ID": "2606628",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Edison Armando Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "98701553",
                    "FECHA INICIAL": "2010-03-29",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Carlos Andres",
                    "APELLIDOS": "Lopera",
                    "NOMBRES COMPLETOS": "Carlos Andres Lopera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Andres Valencia Bravo",
                    "CODIGO USUARIO": "lope111909",
                    "USUARIOFIVE9": "lope111909@siigo.com",
                    "USUARIO RED": "lope111909",
                    "CORREOELECTRONICO": "lope111909@siigo.com",
                    "STATION ID": "2606631",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Andres Valencia Bravo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "43828216",
                    "FECHA INICIAL": "2019-09-18",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Clara Elena",
                    "APELLIDOS": "Velez Perez",
                    "NOMBRES COMPLETOS": "Clara Elena Velez Perez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "vele112392",
                    "USUARIOFIVE9": "vele112392@siigo.com",
                    "USUARIO RED": "vele112392",
                    "CORREOELECTRONICO": "vele112392@siigo.com",
                    "STATION ID": "2606847",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "43276584",
                    "FECHA INICIAL": "2018-01-16",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Tatiana Marcela",
                    "APELLIDOS": "Tamayo Serna",
                    "NOMBRES COMPLETOS": "Tatiana Marcela Tamayo Serna",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Pierre Fardey Velasquez Sanchez",
                    "CODIGO USUARIO": "tama111936",
                    "USUARIOFIVE9": "tama111936@siigo.com",
                    "USUARIO RED": "tama111936",
                    "CORREOELECTRONICO": "tama111936@siigo.com",
                    "STATION ID": "2606642",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Pierre Fardey Velasquez Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "43870207",
                    "FECHA INICIAL": "2012-03-26",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Nancy Olivia",
                    "APELLIDOS": "Becerra Calderon",
                    "NOMBRES COMPLETOS": "Nancy Olivia Becerra Calderon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Pierre Fardey Velasquez Sanchez",
                    "CODIGO USUARIO": "bece111929",
                    "USUARIOFIVE9": "bece111929@siigo.com",
                    "USUARIO RED": "bece111929",
                    "CORREOELECTRONICO": "bece111929@siigo.com",
                    "STATION ID": "2606639",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Pierre Fardey Velasquez Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "43180149",
                    "FECHA INICIAL": "2014-11-10",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Leidy Viviana",
                    "APELLIDOS": "Montoya Gomez",
                    "NOMBRES COMPLETOS": "Leidy Viviana Montoya Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Oscar Francisco Plaza Giler",
                    "CODIGO USUARIO": "mont111927",
                    "USUARIOFIVE9": "mont111927@siigo.com",
                    "USUARIO RED": "mont111927",
                    "CORREOELECTRONICO": "mont111927@siigo.com",
                    "STATION ID": "2606638",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Oscar Francisco Plaza Giler",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "42773481",
                    "FECHA INICIAL": "1997-01-07",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Elizabeth Moreno",
                    "APELLIDOS": "Vargas",
                    "NOMBRES COMPLETOS": "Elizabeth Moreno Vargas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Pierre Fardey Velasquez Sanchez",
                    "CODIGO USUARIO": "more111916",
                    "USUARIOFIVE9": "more111916@siigo.com",
                    "USUARIO RED": "more111916",
                    "CORREOELECTRONICO": "more111916@siigo.com",
                    "STATION ID": "2606744",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Pierre Fardey Velasquez Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1039453778",
                    "FECHA INICIAL": "2020-01-07",
                    "FECHA OPERACION": "2020-01-07",
                    "NOMBRES": "Monica Johana",
                    "APELLIDOS": "Gonzalez Espinosa",
                    "NOMBRES COMPLETOS": "Monica Johana Gonzalez Espinosa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Pierre Fardey Velasquez Sanchez",
                    "CODIGO USUARIO": "gonz800151",
                    "USUARIOFIVE9": "gonz800151@siigo.com",
                    "USUARIO RED": "gonz800151",
                    "CORREOELECTRONICO": "gonz800151@siigo.com",
                    "STATION ID": "2606996",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Pierre Fardey Velasquez Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "87941323",
                    "FECHA INICIAL": "2014-04-03",
                    "FECHA OPERACION": "2014-04-03",
                    "NOMBRES": "Anderson Cubillos",
                    "APELLIDOS": "Ayala",
                    "NOMBRES COMPLETOS": "Anderson Cubillos Ayala",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Yenny Milena Lopez Florez",
                    "CODIGO USUARIO": "cubi36216",
                    "USUARIOFIVE9": "cubi36216@siigo.com",
                    "USUARIO RED": "cubi36216",
                    "CORREOELECTRONICO": "cubi36216@siigo.com",
                    "STATION ID": "2606646",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yenny Milena Lopez Florez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030594169",
                    "FECHA INICIAL": "2019-02-18",
                    "FECHA OPERACION": "2019-02-18",
                    "NOMBRES": "Ivan Felipe",
                    "APELLIDOS": "Bohorquez Ramirez",
                    "NOMBRES COMPLETOS": "Ivan Felipe Bohorquez Ramirez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Oscar Francisco Plaza Giler",
                    "CODIGO USUARIO": "boho111880",
                    "USUARIOFIVE9": "boho111880@siigo.com",
                    "USUARIO RED": "boho111880",
                    "CORREOELECTRONICO": "boho111880@siigo.com",
                    "STATION ID": "2606658",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Oscar Francisco Plaza Giler",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014997399",
                    "FECHA INICIAL": "2018-12-17",
                    "FECHA OPERACION": "2018-12-17",
                    "NOMBRES": "Gilliam Aylein",
                    "APELLIDOS": "Cepeda Chaparro",
                    "NOMBRES COMPLETOS": "Gilliam Aylein Cepeda Chaparro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "QA",
                    "CARGO": "QA",
                    "JEFE INMEDIATO": "Diana Marcela Guzman Caamano",
                    "CODIGO USUARIO": "cepe111812",
                    "USUARIOFIVE9": "cepe111812@siigo.com",
                    "USUARIO RED": "cepe111812",
                    "CORREOELECTRONICO": "cepe111812@siigo.com",
                    "STATION ID": "2606656",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Diana Marcela Guzman Caamano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1026577540",
                    "FECHA INICIAL": "2018-12-17",
                    "FECHA OPERACION": "2018-12-17",
                    "NOMBRES": "Oscar Sebastian",
                    "APELLIDOS": "Godoy Ramirez",
                    "NOMBRES COMPLETOS": "Oscar Sebastian Godoy Ramirez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Juan Diego Casallas Bernal",
                    "CODIGO USUARIO": "godo111813",
                    "USUARIOFIVE9": "godo111813@siigo.com",
                    "USUARIO RED": "godo111813",
                    "CORREOELECTRONICO": "godo111813@siigo.com",
                    "STATION ID": "2606662",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Juan Diego Casallas Bernal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030697324",
                    "FECHA INICIAL": "2018-07-01",
                    "FECHA OPERACION": "2018-07-01",
                    "NOMBRES": "Angie Nataly",
                    "APELLIDOS": "Gonzalez Aya",
                    "NOMBRES COMPLETOS": "Angie Nataly Gonzalez Aya",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Juan Carlos Hurtado Castro",
                    "CODIGO USUARIO": "gonz111673",
                    "USUARIOFIVE9": "gonz111673@siigo.com",
                    "USUARIO RED": "gonz111673",
                    "CORREOELECTRONICO": "gonz111673@siigo.com",
                    "STATION ID": "2606595",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Juan Carlos Hurtado Castro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1026293879",
                    "FECHA INICIAL": "2019-07-02",
                    "FECHA OPERACION": "2019-07-02",
                    "NOMBRES": "Nicolas Gualtero",
                    "APELLIDOS": "Tovar",
                    "NOMBRES COMPLETOS": "Nicolas Gualtero Tovar",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Sindy Paola Lasso Velasco",
                    "CODIGO USUARIO": "gual112141",
                    "USUARIOFIVE9": "gual112141@siigo.com",
                    "USUARIO RED": "gual112141",
                    "CORREOELECTRONICO": "gual112141@siigo.com",
                    "STATION ID": "2606624",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Sindy Paola Lasso Velasco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "16139251",
                    "FECHA INICIAL": "2013-03-04",
                    "FECHA OPERACION": "2013-03-04",
                    "NOMBRES": "Pedro Leon",
                    "APELLIDOS": "Jaramillo Carvajal",
                    "NOMBRES COMPLETOS": "Pedro Leon Jaramillo Carvajal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Localizacion",
                    "CARGO": "QC",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "jara25641",
                    "USUARIOFIVE9": "jara25641@siigo.com",
                    "USUARIO RED": "jara25641",
                    "CORREOELECTRONICO": "jara25641@siigo.com",
                    "STATION ID": "2606609",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000706599",
                    "FECHA INICIAL": "2020-02-24",
                    "FECHA OPERACION": "2020-02-24",
                    "NOMBRES": "Angie Paola",
                    "APELLIDOS": "Chaves Bolanos",
                    "NOMBRES COMPLETOS": "Angie Paola Chaves Bolanos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Ximena Manrique Isaziga",
                    "CODIGO USUARIO": "chav800324",
                    "USUARIOFIVE9": "chav800324@siigo.com",
                    "USUARIO RED": "chav800324",
                    "CORREOELECTRONICO": "chav800324@siigo.com",
                    "STATION ID": "2606841",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Ximena Manrique Isaziga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024538175",
                    "FECHA INICIAL": "2020-02-17",
                    "FECHA OPERACION": "2020-02-17",
                    "NOMBRES": "Lady Nataly",
                    "APELLIDOS": "Ortiz Grijalba",
                    "NOMBRES COMPLETOS": "Lady Nataly Ortiz Grijalba",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Uy",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Edith Angelica Gil Umbarila",
                    "CODIGO USUARIO": "orti800328",
                    "USUARIOFIVE9": "orti800328@siigo.com",
                    "USUARIO RED": "orti800328",
                    "CORREOELECTRONICO": "orti800328@siigo.com",
                    "STATION ID": "2607086",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Edith Angelica Gil Umbarila",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016032987",
                    "FECHA INICIAL": "2019-02-18",
                    "FECHA OPERACION": "2019-02-18",
                    "NOMBRES": "Adriana Catalina",
                    "APELLIDOS": "Padilla Pacheco",
                    "NOMBRES COMPLETOS": "Adriana Catalina Padilla Pacheco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "padi111882",
                    "USUARIOFIVE9": "padi111882@siigo.com",
                    "USUARIO RED": "padi111882",
                    "CORREOELECTRONICO": "padi111882@siigo.com",
                    "STATION ID": "2606649",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1070019453",
                    "FECHA INICIAL": "2019-12-16",
                    "FECHA OPERACION": "2019-12-31",
                    "NOMBRES": "Ingrid Viviana",
                    "APELLIDOS": "Velasquez Vargas",
                    "NOMBRES COMPLETOS": "Ingrid Viviana Velasquez Vargas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones Uy",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "vela800060",
                    "USUARIOFIVE9": "vela800060@siigo.com",
                    "USUARIO RED": "vela800060",
                    "CORREOELECTRONICO": "vela800060@siigo.com",
                    "STATION ID": "2606873",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032457834",
                    "FECHA INICIAL": "2020-02-24",
                    "FECHA OPERACION": "2020-02-24",
                    "NOMBRES": "Paula Alejandra",
                    "APELLIDOS": "Garcia Lujan",
                    "NOMBRES COMPLETOS": "Paula Alejandra Garcia Lujan",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Hollman Antonio Aponte Quiroga",
                    "CODIGO USUARIO": "garc800313",
                    "USUARIOFIVE9": "garc800313@siigo.com",
                    "USUARIO RED": "garc800313",
                    "CORREOELECTRONICO": "garc800313@siigo.com",
                    "STATION ID": "2607030",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Hollman Antonio Aponte Quiroga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1193048862",
                    "FECHA INICIAL": "2019-12-16",
                    "FECHA OPERACION": "2019-12-16",
                    "NOMBRES": "Tatiana Hernandez",
                    "APELLIDOS": "Vargas",
                    "NOMBRES COMPLETOS": "Tatiana Hernandez Vargas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "hern800059",
                    "USUARIOFIVE9": "hern800059@siigo.com",
                    "USUARIO RED": "hern800059",
                    "CORREOELECTRONICO": "hern800059@siigo.com",
                    "STATION ID": "2606872",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "71212827",
                    "FECHA INICIAL": "2007-03-16",
                    "FECHA OPERACION": "2007-03-16",
                    "NOMBRES": "Carlos Andres",
                    "APELLIDOS": "Valencia Bravo",
                    "NOMBRES COMPLETOS": "Carlos Andres Valencia Bravo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Yenny Milena Lopez Florez",
                    "CODIGO USUARIO": "vale217147",
                    "USUARIOFIVE9": "vale217147@siigo.com",
                    "USUARIO RED": "vale217147",
                    "CORREOELECTRONICO": "vale217147@siigo.com",
                    "STATION ID": "2606667",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yenny Milena Lopez Florez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1049644239",
                    "FECHA INICIAL": "2018-07-01",
                    "FECHA OPERACION": "2018-07-01",
                    "NOMBRES": "Leidy Mendoza",
                    "APELLIDOS": "Espitia",
                    "NOMBRES COMPLETOS": "Leidy Mendoza Espitia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Mercadeo",
                    "CARGO": "Analista Mercadeo",
                    "JEFE INMEDIATO": "Carlos German Lopez Murcia",
                    "CODIGO USUARIO": "mend111573",
                    "USUARIOFIVE9": "mend111573@siigo.com",
                    "USUARIO RED": "mend111573",
                    "CORREOELECTRONICO": "mend111573@siigo.com",
                    "STATION ID": "2606603",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Carlos German Lopez Murcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53036681",
                    "FECHA INICIAL": "2016-03-01",
                    "FECHA OPERACION": "2016-03-01",
                    "NOMBRES": "Diana Sofia",
                    "APELLIDOS": "Ramos Castillo",
                    "NOMBRES COMPLETOS": "Diana Sofia Ramos Castillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Libia Amparo Osorio Rodriguez",
                    "CODIGO USUARIO": "ramo110038",
                    "USUARIOFIVE9": "ramo110038@siigo.com",
                    "USUARIO RED": "ramo110038",
                    "CORREOELECTRONICO": "ramo110038@siigo.com",
                    "STATION ID": "2606615",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Libia Amparo Osorio Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1106898028",
                    "FECHA INICIAL": "2019-07-02",
                    "FECHA OPERACION": "2019-07-02",
                    "NOMBRES": "Olga Lucia",
                    "APELLIDOS": "Ospina Vidal",
                    "NOMBRES COMPLETOS": "Olga Lucia Ospina Vidal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Juan Pablo Henao Munoz",
                    "CODIGO USUARIO": "ospi112153",
                    "USUARIOFIVE9": "ospi112153@siigo.com",
                    "USUARIO RED": "ospi112153",
                    "CORREOELECTRONICO": "ospi112153@siigo.com",
                    "STATION ID": "2606625",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Henao Munoz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "15531454",
                    "FECHA INICIAL": "2019-02-15",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Francisco Luis",
                    "APELLIDOS": "Arcila Luna",
                    "NOMBRES COMPLETOS": "Francisco Luis Arcila Luna",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Pierre Fardey Velasquez Sanchez",
                    "CODIGO USUARIO": "arci111918",
                    "USUARIOFIVE9": "arci111918@siigo.com",
                    "USUARIO RED": "arci111918",
                    "CORREOELECTRONICO": "arci111918@siigo.com",
                    "STATION ID": "2606634",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Pierre Fardey Velasquez Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022361332",
                    "FECHA INICIAL": "2013-03-20",
                    "FECHA OPERACION": "2013-03-20",
                    "NOMBRES": "Yenifer Mendez",
                    "APELLIDOS": "Cardenas",
                    "NOMBRES COMPLETOS": "Yenifer Mendez Cardenas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Jimena Marcela Prieto Bohorquez",
                    "CODIGO USUARIO": "mend26101",
                    "USUARIOFIVE9": "mend26101@siigo.com",
                    "USUARIO RED": "mend26101",
                    "CORREOELECTRONICO": "mend26101@siigo.com",
                    "STATION ID": "2606611",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jimena Marcela Prieto Bohorquez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014191411",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-03-09",
                    "NOMBRES": "Angel Eduardo",
                    "APELLIDOS": "Rios Nieto",
                    "NOMBRES COMPLETOS": "Angel Eduardo Rios Nieto",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Yuleidy Josefina Briceno Fernandez",
                    "CODIGO USUARIO": "rios801172",
                    "USUARIOFIVE9": "rios801172@siigo.com",
                    "USUARIO RED": "rios801172",
                    "CORREOELECTRONICO": "rios801172@siigo.com",
                    "STATION ID": "2606861",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Yuleidy Josefina Briceno Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1214732469",
                    "FECHA INICIAL": "2020-02-17",
                    "FECHA OPERACION": "2020-02-17",
                    "NOMBRES": "Cristian Camilo",
                    "APELLIDOS": "Romero  Acevedo",
                    "NOMBRES COMPLETOS": "Cristian Camilo Romero Acevedo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Andres Valencia Bravo",
                    "CODIGO USUARIO": "rome800285",
                    "USUARIOFIVE9": "rome800285@siigo.com",
                    "USUARIO RED": "rome800285",
                    "CORREOELECTRONICO": "rome800285@siigo.com",
                    "STATION ID": "2607095",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Andres Valencia Bravo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1095824513",
                    "FECHA INICIAL": "2021-05-06",
                    "FECHA OPERACION": "2021-05-06",
                    "NOMBRES": "Gina Brigette",
                    "APELLIDOS": "Diaz Gomez",
                    "NOMBRES COMPLETOS": "Gina Brigette Diaz Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Andres Valencia Bravo",
                    "CODIGO USUARIO": "diaz801410",
                    "USUARIOFIVE9": "diaz801410@siigo.com",
                    "USUARIO RED": "diaz801410",
                    "CORREOELECTRONICO": "diaz801410@siigo.com",
                    "STATION ID": "3127464",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Andres Valencia Bravo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024567924",
                    "FECHA INICIAL": "2020-11-23",
                    "FECHA OPERACION": "2020-11-23",
                    "NOMBRES": "Jessica Lorena",
                    "APELLIDOS": "Perez Barrera",
                    "NOMBRES COMPLETOS": "Jessica Lorena Perez Barrera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Andres Valencia Bravo",
                    "CODIGO USUARIO": "pere800870",
                    "USUARIOFIVE9": "pere800870@siigo.com",
                    "USUARIO RED": "pere800870",
                    "CORREOELECTRONICO": "pere800870@siigo.com",
                    "STATION ID": "2606246",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Andres Valencia Bravo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024508766",
                    "FECHA INICIAL": "2020-10-08",
                    "FECHA OPERACION": "2020-10-08",
                    "NOMBRES": "Barbara Andrea",
                    "APELLIDOS": "Mosquera Garcia",
                    "NOMBRES COMPLETOS": "Barbara Andrea Mosquera Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Workfoce & Reporting",
                    "CARGO": "Mission Controller",
                    "JEFE INMEDIATO": "Julieth Paola Vera Patino",
                    "CODIGO USUARIO": "mosq800794",
                    "USUARIOFIVE9": "mosq800794@siigo.com",
                    "USUARIO RED": "mosq800794",
                    "CORREOELECTRONICO": "mosq800794@siigo.com",
                    "STATION ID": "2606151",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Julieth Paola Vera Patino",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52762289",
                    "FECHA INICIAL": "2006-02-13",
                    "FECHA OPERACION": "2006-02-13",
                    "NOMBRES": "Alexandra Arjona",
                    "APELLIDOS": "Arroyave",
                    "NOMBRES COMPLETOS": "Alexandra Arjona Arroyave",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Hilder Alonso Penagos Rubio",
                    "CODIGO USUARIO": "arjo182105",
                    "USUARIOFIVE9": "arjo182105@siigo.com",
                    "USUARIO RED": "arjo182105",
                    "CORREOELECTRONICO": "arjo182105@siigo.com",
                    "STATION ID": "2606597",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Hilder Alonso Penagos Rubio",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1045755978",
                    "FECHA INICIAL": "2020-11-23",
                    "FECHA OPERACION": "2020-11-23",
                    "NOMBRES": "Marianella",
                    "APELLIDOS": "Rojas Caicedo",
                    "NOMBRES COMPLETOS": "Marianella Rojas Caicedo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Andres Valencia Bravo",
                    "CODIGO USUARIO": "roja800881",
                    "USUARIOFIVE9": "roja800881@siigo.com",
                    "USUARIO RED": "roja800881",
                    "CORREOELECTRONICO": "roja800881@siigo.com",
                    "STATION ID": "2606254",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Andres Valencia Bravo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1070965929",
                    "FECHA INICIAL": "2019-01-14",
                    "FECHA OPERACION": "2019-01-14",
                    "NOMBRES": "Cristian Alexander",
                    "APELLIDOS": "Puentes Quiroz",
                    "NOMBRES COMPLETOS": "Cristian Alexander Puentes Quiroz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Diana Sofia Ramos Castillo",
                    "CODIGO USUARIO": "puen111843",
                    "USUARIOFIVE9": "puen111843@siigo.com",
                    "USUARIO RED": "puen111843",
                    "CORREOELECTRONICO": "puen111843@siigo.com",
                    "STATION ID": "2606654",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Sofia Ramos Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1083027495",
                    "FECHA INICIAL": "2021-08-03",
                    "FECHA OPERACION": "2021-08-03",
                    "NOMBRES": "Marilin Julieth",
                    "APELLIDOS": "Escorcia Torres",
                    "NOMBRES COMPLETOS": "Marilin Julieth Escorcia Torres",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Pierre Fardey Velasquez Sanchez",
                    "CODIGO USUARIO": "esco801628",
                    "USUARIOFIVE9": "esco801628@siigo.com",
                    "USUARIO RED": "esco801628",
                    "CORREOELECTRONICO": "esco801628@siigo.com",
                    "STATION ID": "2606868",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Pierre Fardey Velasquez Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030632901",
                    "FECHA INICIAL": "2020-02-24",
                    "FECHA OPERACION": "2020-02-24",
                    "NOMBRES": "Carolina Andrea",
                    "APELLIDOS": "Ibarguen Arcos",
                    "NOMBRES COMPLETOS": "Carolina Andrea Ibarguen Arcos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Cristian Camilo Hernandez Gonzalez",
                    "CODIGO USUARIO": "ibar800325",
                    "USUARIOFIVE9": "ibar800325@siigo.com",
                    "USUARIO RED": "ibar800325",
                    "CORREOELECTRONICO": "ibar800325@siigo.com",
                    "STATION ID": "2606843",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Cristian Camilo Hernandez Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033807543",
                    "FECHA INICIAL": "2021-08-17",
                    "FECHA OPERACION": "2021-08-18",
                    "NOMBRES": "Chirlly Esneidy",
                    "APELLIDOS": "Sabogal Castro",
                    "NOMBRES COMPLETOS": "Chirlly Esneidy Sabogal Castro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jazbleidy Andrea Nunez Montoya",
                    "CODIGO USUARIO": "sabo801681",
                    "USUARIOFIVE9": "sabo801681@siigo.com",
                    "USUARIO RED": "sabo801681",
                    "CORREOELECTRONICO": "sabo801681@siigo.com",
                    "STATION ID": "3127548",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jazbleidy Andrea Nunez Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030678025",
                    "FECHA INICIAL": "2020-08-13",
                    "FECHA OPERACION": "2020-08-13",
                    "NOMBRES": "Julieth Daniela",
                    "APELLIDOS": "Bolivar Guzman",
                    "NOMBRES COMPLETOS": "Julieth Daniela Bolivar Guzman",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Lida Katerine Puentes Navarro",
                    "CODIGO USUARIO": "boli800512",
                    "USUARIOFIVE9": "boli800512@siigo.com",
                    "USUARIO RED": "boli800512",
                    "CORREOELECTRONICO": "boli800512@siigo.com",
                    "STATION ID": "3127473",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Lida Katerine Puentes Navarro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1110453915",
                    "FECHA INICIAL": "2014-02-03",
                    "FECHA OPERACION": "2014-02-03",
                    "NOMBRES": "Leidy Diana",
                    "APELLIDOS": "Ramirez",
                    "NOMBRES COMPLETOS": "Leidy Diana Ramirez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "QC",
                    "JEFE INMEDIATO": "Eddy Pinzon Pena",
                    "CODIGO USUARIO": "rami32601",
                    "USUARIOFIVE9": "rami32601@siigo.com",
                    "USUARIO RED": "rami32601",
                    "CORREOELECTRONICO": "rami32601@siigo.com",
                    "STATION ID": "2606604",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Eddy Pinzon Pena",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1233694670",
                    "FECHA INICIAL": "2019-12-16",
                    "FECHA OPERACION": "2019-12-16",
                    "NOMBRES": "Monica Daniela",
                    "APELLIDOS": "Orjuela Mayorga",
                    "NOMBRES COMPLETOS": "Monica Daniela Orjuela Mayorga",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "orju800072",
                    "USUARIOFIVE9": "orju800072@siigo.com",
                    "USUARIO RED": "orju800072",
                    "CORREOELECTRONICO": "orju800072@siigo.com",
                    "STATION ID": "2606882",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030676068",
                    "FECHA INICIAL": "2021-08-03",
                    "FECHA OPERACION": "2021-08-03",
                    "NOMBRES": "Marilyn Vanessa",
                    "APELLIDOS": "Malambo Montoya",
                    "NOMBRES COMPLETOS": "Marilyn Vanessa Malambo Montoya",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Sandy Yurley Galeano Lara",
                    "CODIGO USUARIO": "mala801627",
                    "USUARIOFIVE9": "mala801627@siigo.com",
                    "USUARIO RED": "mala801627",
                    "CORREOELECTRONICO": "mala801627@siigo.com",
                    "STATION ID": "2606994",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Sandy Yurley Galeano Lara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1233499064",
                    "FECHA INICIAL": "2019-12-16",
                    "FECHA OPERACION": "2019-12-16",
                    "NOMBRES": "Sory Yaquelin",
                    "APELLIDOS": "Abello Polo",
                    "NOMBRES COMPLETOS": "Sory Yaqueline Abello Polo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Yessica Yulieth Quintero Martinez",
                    "CODIGO USUARIO": "abel800063",
                    "USUARIOFIVE9": "abel800063@siigo.com",
                    "USUARIO RED": "abel800063",
                    "CORREOELECTRONICO": "abel800063@siigo.com",
                    "STATION ID": "2606876",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yessica Yulieth Quintero Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52845843",
                    "FECHA INICIAL": "2019-11-12",
                    "FECHA OPERACION": "2019-11-12",
                    "NOMBRES": "Adriana Mercedes",
                    "APELLIDOS": "Vargas Gonzalez",
                    "NOMBRES COMPLETOS": "Adriana Mercedes Vargas Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Cristian Camilo Hernandez Gonzalez",
                    "CODIGO USUARIO": "varg112522",
                    "USUARIOFIVE9": "varg112522@siigo.com",
                    "USUARIO RED": "varg112522",
                    "CORREOELECTRONICO": "varg112522@siigo.com",
                    "STATION ID": "2606869",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Cristian Camilo Hernandez Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52768268",
                    "FECHA INICIAL": "2019-12-16",
                    "FECHA OPERACION": "2019-12-16",
                    "NOMBRES": "Sandra Maricela",
                    "APELLIDOS": "Corredor Caina",
                    "NOMBRES COMPLETOS": "Sandra Maricela Corredor Caina",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoraciones",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Sandra Catalina Suarez Castillo",
                    "CODIGO USUARIO": "corr800045",
                    "USUARIOFIVE9": "corr800045@siigo.com",
                    "USUARIO RED": "corr800045",
                    "CORREOELECTRONICO": "corr800045@siigo.com",
                    "STATION ID": "2606885",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Sandra Catalina Suarez Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014275403",
                    "FECHA INICIAL": "2020-07-28",
                    "FECHA OPERACION": "2020-07-28",
                    "NOMBRES": "Diana Ximena",
                    "APELLIDOS": "Herrera Sanchez",
                    "NOMBRES COMPLETOS": "Diana Ximena Herrera Sanchez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Rocxy Daniela Fernandez Fernandez",
                    "CODIGO USUARIO": "herr800482",
                    "USUARIOFIVE9": "herr800482@siigo.com",
                    "USUARIO RED": "herr800482",
                    "CORREOELECTRONICO": "herr800482@siigo.com",
                    "STATION ID": "2606043",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Rocxy Daniela Fernandez Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030566220",
                    "FECHA INICIAL": "2021-08-03",
                    "FECHA OPERACION": "2021-08-03",
                    "NOMBRES": "Yildre Mirley",
                    "APELLIDOS": "Garcia Dominguez",
                    "NOMBRES COMPLETOS": "Yildre Mirley Garcia Dominguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Pierre Fardey Velasquez Sanchez",
                    "CODIGO USUARIO": "garc801624",
                    "USUARIOFIVE9": "garc801624@siigo.com",
                    "USUARIO RED": "garc801624",
                    "CORREOELECTRONICO": "garc801624@siigo.com",
                    "STATION ID": "2607050",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Pierre Fardey Velasquez Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1039449777",
                    "FECHA INICIAL": "2019-02-15",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Sandra Milena",
                    "APELLIDOS": "Gonzalez Restrepo",
                    "NOMBRES COMPLETOS": "Sandra Milena Gonzalez Restrepo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Jazbleidy Andrea Nunez Montoya",
                    "CODIGO USUARIO": "gonz111935",
                    "USUARIOFIVE9": "gonz111935@siigo.com",
                    "USUARIO RED": "gonz111935",
                    "CORREOELECTRONICO": "gonz111935@siigo.com",
                    "STATION ID": "2606915",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jazbleidy Andrea Nunez Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000188260",
                    "FECHA INICIAL": "2020-03-09",
                    "FECHA OPERACION": "2020-03-09",
                    "NOMBRES": "Sara Yinneth",
                    "APELLIDOS": "Zapata Velez",
                    "NOMBRES COMPLETOS": "Sara Yinneth Zapata Velez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Maria Lucia Canales Parrales",
                    "CODIGO USUARIO": "zapa800392",
                    "USUARIOFIVE9": "zapa800392@siigo.com",
                    "USUARIO RED": "zapa800392",
                    "CORREOELECTRONICO": "zapa800392@siigo.com",
                    "STATION ID": "2605931",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Maria Lucia Canales Parrales",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1193238006",
                    "FECHA INICIAL": "2020-01-27",
                    "FECHA OPERACION": "2020-02-11",
                    "NOMBRES": "Karen Dayanna",
                    "APELLIDOS": "Trivino Miranda",
                    "NOMBRES COMPLETOS": "Karen Dayanna Trivino Miranda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ventas",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "triv800185",
                    "USUARIOFIVE9": "triv800185@siigo.com",
                    "USUARIO RED": "triv800185",
                    "CORREOELECTRONICO": "triv800185@siigo.com",
                    "STATION ID": "2607007",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "43987660",
                    "FECHA INICIAL": "2014-03-17",
                    "FECHA OPERACION": "2014-03-17",
                    "NOMBRES": "Angelica Maria",
                    "APELLIDOS": "Otalvaro Rios",
                    "NOMBRES COMPLETOS": "Angelica Maria Otalvaro Rios",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "otal33806",
                    "USUARIOFIVE9": "otal33806@siigo.com",
                    "USUARIO RED": "otal33806",
                    "CORREOELECTRONICO": "otal33806@siigo.com",
                    "STATION ID": "2606666",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "14677374",
                    "FECHA INICIAL": "2014-03-18",
                    "FECHA OPERACION": "2014-03-18",
                    "NOMBRES": "Ivan Enrique",
                    "APELLIDOS": "Varela Lasso",
                    "NOMBRES COMPLETOS": "Ivan Enrique Varela Lasso",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Yenny Milena Lopez Florez",
                    "CODIGO USUARIO": "vare33819",
                    "USUARIOFIVE9": "vare33819@siigo.com",
                    "USUARIO RED": "vare33819",
                    "CORREOELECTRONICO": "vare33819@siigo.com",
                    "STATION ID": "2606647",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yenny Milena Lopez Florez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1020842214",
                    "FECHA INICIAL": "2020-11-23",
                    "FECHA OPERACION": "2020-11-23",
                    "NOMBRES": "Gabriella Caroline",
                    "APELLIDOS": "Martinez Bolivar",
                    "NOMBRES COMPLETOS": "Gabriella Caroline Martinez Bolivar",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Pierre Fardey Velasquez Sanchez",
                    "CODIGO USUARIO": "mart800877",
                    "USUARIOFIVE9": "mart800877@siigo.com",
                    "USUARIO RED": "mart800877",
                    "CORREOELECTRONICO": "mart800877@siigo.com",
                    "STATION ID": "2606245",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Pierre Fardey Velasquez Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1085299564",
                    "FECHA INICIAL": "2021-05-06",
                    "FECHA OPERACION": "2021-05-06",
                    "NOMBRES": "Erika Marcela",
                    "APELLIDOS": "Narvaez Barco",
                    "NOMBRES COMPLETOS": "Erika Marcela Narvaez Barco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Pasto",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Pierre Fardey Velasquez Sanchez",
                    "CODIGO USUARIO": "narv801408",
                    "USUARIOFIVE9": "narv801408@siigo.com",
                    "USUARIO RED": "narv801408",
                    "CORREOELECTRONICO": "narv801408@siigo.com",
                    "STATION ID": "3127462",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Pierre Fardey Velasquez Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1140848742",
                    "FECHA INICIAL": "2016-03-01",
                    "FECHA OPERACION": "2016-03-01",
                    "NOMBRES": "Armando Cecilio",
                    "APELLIDOS": "Cabrera Recio",
                    "NOMBRES COMPLETOS": "Armando Cecilio Cabrera Recio",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Nicolas Alejandro Sarmiento Poveda",
                    "CODIGO USUARIO": "cabr110042",
                    "USUARIOFIVE9": "cabr110042@siigo.com",
                    "USUARIO RED": "cabr110042",
                    "CORREOELECTRONICO": "cabr110042@siigo.com",
                    "STATION ID": "2606670",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Nicolas Alejandro Sarmiento Poveda",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "72251215",
                    "FECHA INICIAL": "2006-07-10",
                    "FECHA OPERACION": "2006-07-10",
                    "NOMBRES": "Carlos Julio",
                    "APELLIDOS": "Navarro Marin",
                    "NOMBRES COMPLETOS": "Carlos Julio Navarro Marin",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "nava204476",
                    "USUARIOFIVE9": "nava204476@siigo.com",
                    "USUARIO RED": "nava204476",
                    "CORREOELECTRONICO": "nava204476@siigo.com",
                    "STATION ID": "2606671",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1233894407",
                    "FECHA INICIAL": "2021-08-03",
                    "FECHA OPERACION": "2021-08-03",
                    "NOMBRES": "Valentina",
                    "APELLIDOS": "Gil Agudelo",
                    "NOMBRES COMPLETOS": "Valentina Gil Agudelo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Pierre Fardey Velasquez Sanchez",
                    "CODIGO USUARIO": "gila801625",
                    "USUARIOFIVE9": "gila801625@siigo.com",
                    "USUARIO RED": "gila801625",
                    "CORREOELECTRONICO": "gila801625@siigo.com",
                    "STATION ID": "2607008",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Pierre Fardey Velasquez Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1045693304",
                    "FECHA INICIAL": "2015-02-02",
                    "FECHA OPERACION": "2015-02-02",
                    "NOMBRES": "Lizeth Dayana",
                    "APELLIDOS": "Grau Ortega",
                    "NOMBRES COMPLETOS": "Lizeth Dayana Grau Ortega",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoracion",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "grau107912",
                    "USUARIOFIVE9": "grau107912@siigo.com",
                    "USUARIO RED": "grau107912",
                    "CORREOELECTRONICO": "grau107912@siigo.com",
                    "STATION ID": "2606677",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "55220365",
                    "FECHA INICIAL": "2014-05-12",
                    "FECHA OPERACION": "2014-05-12",
                    "NOMBRES": "Margarita Rosa",
                    "APELLIDOS": "Gutierrez Mendez",
                    "NOMBRES COMPLETOS": "Margarita Rosa Gutierrez Mendez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Jimena Marcela Prieto Bohorquez",
                    "CODIGO USUARIO": "guti36959",
                    "USUARIOFIVE9": "guti36959@siigo.com",
                    "USUARIO RED": "guti36959",
                    "CORREOELECTRONICO": "guti36959@siigo.com",
                    "STATION ID": "2606678",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jimena Marcela Prieto Bohorquez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "32583889",
                    "FECHA INICIAL": "2007-03-23",
                    "FECHA OPERACION": "2007-03-23",
                    "NOMBRES": "Mayerlin Canate",
                    "APELLIDOS": "Reyes",
                    "NOMBRES COMPLETOS": "Mayerlin Canate Reyes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Deimer Esteban Pena Gonzalez",
                    "CODIGO USUARIO": "cana217287",
                    "USUARIOFIVE9": "cana217287@siigo.com",
                    "USUARIO RED": "cana217287",
                    "CORREOELECTRONICO": "cana217287@siigo.com",
                    "STATION ID": "2606679",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Deimer Esteban Pena Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1129576814",
                    "FECHA INICIAL": "2015-02-02",
                    "FECHA OPERACION": "2015-02-02",
                    "NOMBRES": "Yulibeth Esther",
                    "APELLIDOS": "Villalba Altamar",
                    "NOMBRES COMPLETOS": "Yulibeth Esther Villalba Altamar",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Jimena Marcela Prieto Bohorquez",
                    "CODIGO USUARIO": "vill107898",
                    "USUARIOFIVE9": "vill107898@siigo.com",
                    "USUARIO RED": "vill107898",
                    "CORREOELECTRONICO": "vill107898@siigo.com",
                    "STATION ID": "2606680",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jimena Marcela Prieto Bohorquez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1094909663",
                    "FECHA INICIAL": "2013-05-20",
                    "FECHA OPERACION": "2013-05-20",
                    "NOMBRES": "Angie Lisbet",
                    "APELLIDOS": "Obando Ortiz",
                    "NOMBRES COMPLETOS": "Angie Lisbet Obando Ortiz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Armenia",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoracion",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "oban27306",
                    "USUARIOFIVE9": "oban27306@siigo.com",
                    "USUARIO RED": "oban27306",
                    "CORREOELECTRONICO": "oban27306@siigo.com",
                    "STATION ID": "2606681",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "41945955",
                    "FECHA INICIAL": "2005-03-01",
                    "FECHA OPERACION": "2005-03-01",
                    "NOMBRES": "Jessica Alexandra",
                    "APELLIDOS": "Bohorquez Ramos",
                    "NOMBRES COMPLETOS": "Jessica Alexandra Bohorquez Ramos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Jimena Marcela Prieto Bohorquez",
                    "CODIGO USUARIO": "boho42263",
                    "USUARIOFIVE9": "boho42263@siigo.com",
                    "USUARIO RED": "boho42263",
                    "CORREOELECTRONICO": "boho42263@siigo.com",
                    "STATION ID": "2606683",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jimena Marcela Prieto Bohorquez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1047472692",
                    "FECHA INICIAL": "2021-08-03",
                    "FECHA OPERACION": "2021-08-03",
                    "NOMBRES": "Raquel",
                    "APELLIDOS": "Puello Pajaro",
                    "NOMBRES COMPLETOS": "Raquel Puello Pajaro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Tatiana Reyes Perez",
                    "CODIGO USUARIO": "puel801622",
                    "USUARIOFIVE9": "puel801622@siigo.com",
                    "USUARIO RED": "puel801622",
                    "CORREOELECTRONICO": "puel801622@siigo.com",
                    "STATION ID": "3127490",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Tatiana Reyes Perez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "42128573",
                    "FECHA INICIAL": "2005-10-10",
                    "FECHA OPERACION": "2005-10-10",
                    "NOMBRES": "Natalia Carmona",
                    "APELLIDOS": "Escobar",
                    "NOMBRES COMPLETOS": "Natalia Carmona Escobar",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Pereira",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoraciones",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Sandra Catalina Suarez Castillo",
                    "CODIGO USUARIO": "carm31411",
                    "USUARIOFIVE9": "carm31411@siigo.com",
                    "USUARIO RED": "carm31411",
                    "CORREOELECTRONICO": "carm31411@siigo.com",
                    "STATION ID": "2606685",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Sandra Catalina Suarez Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1002242786",
                    "FECHA INICIAL": "2021-08-03",
                    "FECHA OPERACION": "2021-08-03",
                    "NOMBRES": "Ivana Teresa",
                    "APELLIDOS": "Meza De La Rosa",
                    "NOMBRES COMPLETOS": "Ivana Teresa Meza De La Rosa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Andres Valencia Bravo",
                    "CODIGO USUARIO": "meza801621",
                    "USUARIOFIVE9": "meza801621@siigo.com",
                    "USUARIO RED": "meza801621",
                    "CORREOELECTRONICO": "meza801621@siigo.com",
                    "STATION ID": "3127491",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Andres Valencia Bravo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "21527717",
                    "FECHA INICIAL": "2019-02-15",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Natalia Restrepo",
                    "APELLIDOS": "Garcia",
                    "NOMBRES COMPLETOS": "Natalia Restrepo Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Andres Valencia Bravo",
                    "CODIGO USUARIO": "rest111930",
                    "USUARIOFIVE9": "rest111930@siigo.com",
                    "USUARIO RED": "rest111930",
                    "CORREOELECTRONICO": "rest111930@siigo.com",
                    "STATION ID": "2606640",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Andres Valencia Bravo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "65780621",
                    "FECHA INICIAL": "2003-07-01",
                    "FECHA OPERACION": "2003-07-01",
                    "NOMBRES": "Fany Yaneht",
                    "APELLIDOS": "Rojas Trujillo",
                    "NOMBRES COMPLETOS": "Fany Yaneht Rojas Trujillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoraciones",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Sandra Catalina Suarez Castillo",
                    "CODIGO USUARIO": "roja28176",
                    "USUARIOFIVE9": "roja28176@siigo.com",
                    "USUARIO RED": "roja28176",
                    "CORREOELECTRONICO": "roja28176@siigo.com",
                    "STATION ID": "2606690",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Sandra Catalina Suarez Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1117501224",
                    "FECHA INICIAL": "2010-02-25",
                    "FECHA OPERACION": "2010-02-25",
                    "NOMBRES": "Fernando Sotto",
                    "APELLIDOS": "Cardozo",
                    "NOMBRES COMPLETOS": "Fernando Sotto Cardozo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Florencia",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Juan Pablo Henao Munoz",
                    "CODIGO USUARIO": "sott263607",
                    "USUARIOFIVE9": "sott263607@siigo.com",
                    "USUARIO RED": "sott263607",
                    "CORREOELECTRONICO": "sott263607@siigo.com",
                    "STATION ID": "2606691",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Henao Munoz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "93406013",
                    "FECHA INICIAL": "2003-03-25",
                    "FECHA OPERACION": "2003-03-25",
                    "NOMBRES": "Luis Alberto",
                    "APELLIDOS": "Ramirez Robayo",
                    "NOMBRES COMPLETOS": "Luis Alberto Ramirez Robayo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Manuel Andres Solano Duran",
                    "CODIGO USUARIO": "rami27284",
                    "USUARIOFIVE9": "rami27284@siigo.com",
                    "USUARIO RED": "rami27284",
                    "CORREOELECTRONICO": "rami27284@siigo.com",
                    "STATION ID": "2606694",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Manuel Andres Solano Duran",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "93408599",
                    "FECHA INICIAL": "2001-04-16",
                    "FECHA OPERACION": "2001-04-16",
                    "NOMBRES": "Mario Fernando",
                    "APELLIDOS": "Nunez Santos",
                    "NOMBRES COMPLETOS": "Mario Fernando Nunez Santos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Juan Pablo Henao Munoz",
                    "CODIGO USUARIO": "nune12020",
                    "USUARIOFIVE9": "nune12020@siigo.com",
                    "USUARIO RED": "nune12020",
                    "CORREOELECTRONICO": "nune12020@siigo.com",
                    "STATION ID": "2606695",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Henao Munoz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "35145448",
                    "FECHA INICIAL": "2021-10-21",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Yennis Elena",
                    "APELLIDOS": "Alvarez Cuello",
                    "NOMBRES COMPLETOS": "Yennis Elena Alvarez Cuello",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Andres Valencia Bravo",
                    "CODIGO USUARIO": "alva802127",
                    "USUARIOFIVE9": "alva802127@siigo.com",
                    "USUARIO RED": "alva802127",
                    "CORREOELECTRONICO": "alva802127@siigo.com",
                    "STATION ID": "3690255",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Andres Valencia Bravo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1110516714",
                    "FECHA INICIAL": "2021-10-21",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Salma Daniela",
                    "APELLIDOS": "Vargas Tocora",
                    "NOMBRES COMPLETOS": "Salma Daniela Vargas Tocora",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Andres Valencia Bravo",
                    "CODIGO USUARIO": "varg802141",
                    "USUARIOFIVE9": "varg802141@siigo.com",
                    "USUARIO RED": "varg802141",
                    "CORREOELECTRONICO": "varg802141@siigo.com",
                    "STATION ID": "3690269",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Andres Valencia Bravo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1110569471",
                    "FECHA INICIAL": "2021-10-21",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Luisa Fernanda",
                    "APELLIDOS": "Ozaeta Rojas",
                    "NOMBRES COMPLETOS": "Luisa Fernanda Ozaeta Rojas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Andres Valencia Bravo",
                    "CODIGO USUARIO": "ozae802143",
                    "USUARIOFIVE9": "ozae802143@siigo.com",
                    "USUARIO RED": "ozae802143",
                    "CORREOELECTRONICO": "ozae802143@siigo.com",
                    "STATION ID": "3690271",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Andres Valencia Bravo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1047482161",
                    "FECHA INICIAL": "2021-10-22",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Yesmy Alejandra",
                    "APELLIDOS": "Ballesta Tous",
                    "NOMBRES COMPLETOS": "Yesmy Alejandra Ballesta Tous",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Andres Valencia Bravo",
                    "CODIGO USUARIO": "ball802145",
                    "USUARIOFIVE9": "ball802145@siigo.com",
                    "USUARIO RED": "ball802145",
                    "CORREOELECTRONICO": "ball802145@siigo.com",
                    "STATION ID": "3690273",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Andres Valencia Bravo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000160911",
                    "FECHA INICIAL": "2021-10-22",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Leydi Nataly",
                    "APELLIDOS": "Ramirez Barreto",
                    "NOMBRES COMPLETOS": "Leydi Nataly Ramirez Barreto",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Pierre Fardey Velasquez Sanchez",
                    "CODIGO USUARIO": "rami802148",
                    "USUARIOFIVE9": "rami802148@siigo.com",
                    "USUARIO RED": "rami802148",
                    "CORREOELECTRONICO": "rami802148@siigo.com",
                    "STATION ID": "3690275",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Pierre Fardey Velasquez Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1096512609",
                    "FECHA INICIAL": "2015-04-13",
                    "FECHA OPERACION": "2015-04-13",
                    "NOMBRES": "Juan Pablo",
                    "APELLIDOS": "Carreno Patino",
                    "NOMBRES COMPLETOS": "Juan Pablo Carreno Patino",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Yenny Milena Lopez Florez",
                    "CODIGO USUARIO": "carr109687",
                    "USUARIOFIVE9": "carr109687@siigo.com",
                    "USUARIO RED": "carr109687",
                    "CORREOELECTRONICO": "carr109687@siigo.com",
                    "STATION ID": "2606703",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yenny Milena Lopez Florez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1004306333",
                    "FECHA INICIAL": "2021-09-08",
                    "FECHA OPERACION": "2021-09-08",
                    "NOMBRES": "Maria Luisa",
                    "APELLIDOS": "Benitez Tapia",
                    "NOMBRES COMPLETOS": "Maria Luisa Benitez Tapia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Santa Marta",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Diana Sofia Ramos Castillo",
                    "CODIGO USUARIO": "beni801852",
                    "USUARIOFIVE9": "beni801852@siigo.com",
                    "USUARIO RED": "beni801852",
                    "CORREOELECTRONICO": "beni801852@siigo.com",
                    "STATION ID": "3267887",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Sofia Ramos Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "18970341",
                    "FECHA INICIAL": "2014-02-03",
                    "FECHA OPERACION": "2014-02-03",
                    "NOMBRES": "Naimen Jose",
                    "APELLIDOS": "Contreras Jimenez",
                    "NOMBRES COMPLETOS": "Naimen Jose Contreras Jimenez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cucuta",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juvenal Reyes Silva",
                    "CODIGO USUARIO": "cont32670",
                    "USUARIOFIVE9": "cont32670@siigo.com",
                    "USUARIO RED": "cont32670",
                    "CORREOELECTRONICO": "cont32670@siigo.com",
                    "STATION ID": "2606705",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juvenal Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53139480",
                    "FECHA INICIAL": "2007-02-19",
                    "FECHA OPERACION": "2007-02-19",
                    "NOMBRES": "Angela Yolima",
                    "APELLIDOS": "Bohada Arias",
                    "NOMBRES COMPLETOS": "Angela Yolima Bohada Arias",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Product Owner",
                    "JEFE INMEDIATO": "Ana Rita Ortiz Herrera",
                    "CODIGO USUARIO": "boha215127",
                    "USUARIOFIVE9": "boha215127@siigo.com",
                    "USUARIO RED": "boha215127",
                    "CORREOELECTRONICO": "boha215127@siigo.com",
                    "STATION ID": "2605977",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Ana Rita Ortiz Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80215872",
                    "FECHA INICIAL": "2008-06-11",
                    "FECHA OPERACION": "2008-06-11",
                    "NOMBRES": "Carlos Mario",
                    "APELLIDOS": "Espinosa Fernandez",
                    "NOMBRES COMPLETOS": "Carlos Mario Espinosa Fernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Director",
                    "JEFE INMEDIATO": "Andrea Zuluaga Uribe",
                    "CODIGO USUARIO": "carlos.esponisa",
                    "USUARIOFIVE9": "carlos.espinosa@siigo.com",
                    "USUARIO RED": "carlos.esponisa",
                    "CORREOELECTRONICO": "carlos.espinosa@siigo.com",
                    "STATION ID": "2606826",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andrea Zuluaga Uribe",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016109838",
                    "FECHA INICIAL": "2019-12-09",
                    "FECHA OPERACION": "2019-12-09",
                    "NOMBRES": "Carlos Roberto",
                    "APELLIDOS": "Alvarado Mancera",
                    "NOMBRES COMPLETOS": "Carlos Roberto Alvarado Mancera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Experience Manager",
                    "JEFE INMEDIATO": "Pedro Felipe Arana Lastra",
                    "CODIGO USUARIO": "alva800023",
                    "USUARIOFIVE9": "alva800023@siigo.com",
                    "USUARIO RED": "alva800023",
                    "CORREOELECTRONICO": "alva800023@siigo.com",
                    "STATION ID": "2606807",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Pedro Felipe Arana Lastra",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52959859",
                    "FECHA INICIAL": "2010-11-17",
                    "FECHA OPERACION": "2010-11-17",
                    "NOMBRES": "Claudia Patricia",
                    "APELLIDOS": "Estevez Alvarez",
                    "NOMBRES COMPLETOS": "Claudia Patricia Estevez Alvarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Investigador",
                    "CARGO": "Investigador e Implementador de UX",
                    "JEFE INMEDIATO": "Luz Mery Patino Infante",
                    "CODIGO USUARIO": "este23862",
                    "USUARIOFIVE9": "este23862@siigo.com",
                    "USUARIO RED": "este23862",
                    "CORREOELECTRONICO": "este23862@siigo.com",
                    "STATION ID": "2606026",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Luz Mery Patino Infante",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014184371",
                    "FECHA INICIAL": "2010-12-01",
                    "FECHA OPERACION": "2010-12-01",
                    "NOMBRES": "Edwin Alfredo",
                    "APELLIDOS": "Guayacan Cardenal",
                    "NOMBRES COMPLETOS": "Edwin Alfredo Guayacan Cardenal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Investigador",
                    "CARGO": "Product Owner",
                    "JEFE INMEDIATO": "Luz Mery Patino Infante",
                    "CODIGO USUARIO": "guay10786",
                    "USUARIOFIVE9": "guay10786@siigo.com",
                    "USUARIO RED": "guay10786",
                    "CORREOELECTRONICO": "guay10786@siigo.com",
                    "STATION ID": "2606076",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Luz Mery Patino Infante",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1031176776",
                    "FECHA INICIAL": "2019-12-23",
                    "FECHA OPERACION": "2019-12-23",
                    "NOMBRES": "Jason Steve",
                    "APELLIDOS": "Rodriguez Navarrete",
                    "NOMBRES COMPLETOS": "Jason Steve Rodriguez Navarrete",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Bussiness Inteligence",
                    "JEFE INMEDIATO": "Diego Armando Aristizabal Bedoya",
                    "CODIGO USUARIO": "rodr800073",
                    "USUARIOFIVE9": "rodr800073@siigo.com",
                    "USUARIO RED": "rodr800073",
                    "CORREOELECTRONICO": "rodr800073@siigo.com",
                    "STATION ID": "2606974",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diego Armando Aristizabal Bedoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52828290",
                    "FECHA INICIAL": "2012-06-04",
                    "FECHA OPERACION": "2012-06-04",
                    "NOMBRES": "Luz Andrea",
                    "APELLIDOS": "Sanchez Chacon",
                    "NOMBRES COMPLETOS": "Luz Andrea Sanchez Chacon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Product Owner",
                    "JEFE INMEDIATO": "Luz Mery Patino Infante",
                    "CODIGO USUARIO": "sanc20972",
                    "USUARIOFIVE9": "sanc20972@siigo.com",
                    "USUARIO RED": "sanc20972",
                    "CORREOELECTRONICO": "sanc20972@siigo.com",
                    "STATION ID": "2606247",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Luz Mery Patino Infante",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52307379",
                    "FECHA INICIAL": "2014-01-20",
                    "FECHA OPERACION": "2014-01-16",
                    "NOMBRES": "Luz Angelica",
                    "APELLIDOS": "Torres Quintero",
                    "NOMBRES COMPLETOS": "Luz Angelica Torres Quintero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Libia Amparo Osorio Rodriguez",
                    "CODIGO USUARIO": "torr32256",
                    "USUARIOFIVE9": "torr32256@siigo.com",
                    "USUARIO RED": "torr32256",
                    "CORREOELECTRONICO": "torr32256@siigo.com",
                    "STATION ID": "2605987",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Libia Amparo Osorio Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80092396",
                    "FECHA INICIAL": "2006-03-06",
                    "FECHA OPERACION": "2006-03-06",
                    "NOMBRES": "Manuel Andres",
                    "APELLIDOS": "Solano Duran",
                    "NOMBRES COMPLETOS": "Manuel Andres Solano Duran",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Product Manager",
                    "JEFE INMEDIATO": "Juan Carlos Corazzo Santos",
                    "CODIGO USUARIO": "sola197201",
                    "USUARIOFIVE9": "sola197201@siigo.com",
                    "USUARIO RED": "sola197201",
                    "CORREOELECTRONICO": "sola197201@siigo.com",
                    "STATION ID": "2606257",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Juan Carlos Corazzo Santos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018433603",
                    "FECHA INICIAL": "2015-04-13",
                    "FECHA OPERACION": "2015-04-13",
                    "NOMBRES": "Maria Paula",
                    "APELLIDOS": "Duarte Sanchez",
                    "NOMBRES COMPLETOS": "Maria Paula Duarte Sanchez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Investigador",
                    "CARGO": "Product Owner",
                    "JEFE INMEDIATO": "Silvia Johanna Ruiz Cuevas",
                    "CODIGO USUARIO": "duar109874",
                    "USUARIOFIVE9": "duar109874@siigo.com",
                    "USUARIO RED": "duar109874",
                    "CORREOELECTRONICO": "duar109874@siigo.com",
                    "STATION ID": "2607081",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Silvia Johanna Ruiz Cuevas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "39776889",
                    "FECHA INICIAL": "1998-09-01",
                    "FECHA OPERACION": "1998-09-01",
                    "NOMBRES": "Sandra Lucia",
                    "APELLIDOS": "Ortiz Deulofeut",
                    "NOMBRES COMPLETOS": "Sandra Lucia Ortiz Deulofeut",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Product Owner",
                    "JEFE INMEDIATO": "Manuel Andres Solano Duran",
                    "CODIGO USUARIO": "sandra.ortiz",
                    "USUARIOFIVE9": "sandra.ortiz@siigo.com",
                    "USUARIO RED": "sandra.ortiz",
                    "CORREOELECTRONICO": "sandra.ortiz@siigo.com",
                    "STATION ID": "2606827",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Manuel Andres Solano Duran",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "39629094",
                    "FECHA INICIAL": "2010-10-19",
                    "FECHA OPERACION": "2010-10-19",
                    "NOMBRES": "Claudia Marcela",
                    "APELLIDOS": "Leon Cerinza",
                    "NOMBRES COMPLETOS": "Claudia Marcela Leon Cerinza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Saul Steven Carpio Ochoa",
                    "CODIGO USUARIO": "leon273694",
                    "USUARIOFIVE9": "leon273694@siigo.com",
                    "USUARIO RED": "leon273694",
                    "CORREOELECTRONICO": "leon273694@siigo.com",
                    "STATION ID": "2606596",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Saul Steven Carpio Ochoa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1129571789",
                    "FECHA INICIAL": "2015-03-02",
                    "FECHA OPERACION": "2015-03-02",
                    "NOMBRES": "Hancel Rafael",
                    "APELLIDOS": "Salcedo Mendoza",
                    "NOMBRES COMPLETOS": "Hancel Rafael Salcedo Mendoza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Juan Pablo Henao Munoz",
                    "CODIGO USUARIO": "salc108542",
                    "USUARIOFIVE9": "salc108542@siigo.com",
                    "USUARIO RED": "salc108542",
                    "CORREOELECTRONICO": "salc108542@siigo.com",
                    "STATION ID": "2606110",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Henao Munoz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53108498",
                    "FECHA INICIAL": "2008-07-29",
                    "FECHA OPERACION": "2008-07-29",
                    "NOMBRES": "Jazbleydi Andrea",
                    "APELLIDOS": "Nunez Montoya",
                    "NOMBRES COMPLETOS": "Jazbleydi Andrea Nunez Montoya",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Juan Pablo Henao Munoz",
                    "CODIGO USUARIO": "nune239842",
                    "USUARIOFIVE9": "nune239842@siigo.com",
                    "USUARIO RED": "nune239842",
                    "CORREOELECTRONICO": "nune239842@siigo.com",
                    "STATION ID": "2606124",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Henao Munoz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80828273",
                    "FECHA INICIAL": "2014-01-20",
                    "FECHA OPERACION": "2014-01-20",
                    "NOMBRES": "Julian Andres",
                    "APELLIDOS": "Silva Torralba",
                    "NOMBRES COMPLETOS": "Julian Andres Silva Torralba",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Producto Back-Office",
                    "CARGO": "Experience Manager",
                    "JEFE INMEDIATO": "Juan Carlos Corazzo Santos",
                    "CODIGO USUARIO": "silv32253",
                    "USUARIOFIVE9": "silv32253@siigo.com",
                    "USUARIO RED": "silv32253",
                    "CORREOELECTRONICO": "silv32253@siigo.com",
                    "STATION ID": "2606183",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Carlos Corazzo Santos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1037610127",
                    "FECHA INICIAL": "2019-02-15",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Laura Melissa",
                    "APELLIDOS": "Alzate Diaz",
                    "NOMBRES COMPLETOS": "Laura Melissa Alzate Diaz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Estrategia",
                    "SUBAREA": "Estrategia",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Oscar Francisco Plaza Giler",
                    "CODIGO USUARIO": "alza111886",
                    "USUARIOFIVE9": "alza111886@siigo.com",
                    "USUARIO RED": "alza111886",
                    "CORREOELECTRONICO": "alza111886@siigo.com",
                    "STATION ID": "2606208",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Estrategia_Oscar Francisco Plaza Giler",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013637702",
                    "FECHA INICIAL": "2011-12-01",
                    "FECHA OPERACION": "2011-12-01",
                    "NOMBRES": "Yesika Viviana",
                    "APELLIDOS": "Garcia Nino",
                    "NOMBRES COMPLETOS": "Yesika Viviana Garcia Nino",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Experience Manager",
                    "JEFE INMEDIATO": "Diego Armando Aristizabal Bedoya",
                    "CODIGO USUARIO": "garc17506",
                    "USUARIOFIVE9": "garc17506@siigo.com",
                    "USUARIO RED": "garc17506",
                    "CORREOELECTRONICO": "garc17506@siigo.com",
                    "STATION ID": "2606392",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diego Armando Aristizabal Bedoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52996555",
                    "FECHA INICIAL": "2019-10-21",
                    "FECHA OPERACION": "2019-10-21",
                    "NOMBRES": "Andrea Zuluaga",
                    "APELLIDOS": "Uribe",
                    "NOMBRES COMPLETOS": "Andrea Zuluaga Uribe",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Vicepresidente",
                    "JEFE INMEDIATO": "David Ortiz Diaz",
                    "CODIGO USUARIO": "andrea.zuluaga",
                    "USUARIOFIVE9": "andrea.zuluaga@siigo.com",
                    "USUARIO RED": "andrea.zuluaga",
                    "CORREOELECTRONICO": "andrea.zuluaga@siigo.com",
                    "STATION ID": "2606825",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_David Ortiz Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52837345",
                    "FECHA INICIAL": "2014-07-16",
                    "FECHA OPERACION": "2014-07-16",
                    "NOMBRES": "Adriana Elizabeth",
                    "APELLIDOS": "Giron Parra",
                    "NOMBRES COMPLETOS": "Adriana Elizabeth Giron Parra",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Yessica Yulieth Quintero Martinez",
                    "CODIGO USUARIO": "giro199668",
                    "USUARIOFIVE9": "giro199668@siigo.com",
                    "USUARIO RED": "giro199668",
                    "CORREOELECTRONICO": "giro199668@siigo.com",
                    "STATION ID": "2605943",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yessica Yulieth Quintero Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "94371711",
                    "FECHA INICIAL": "2010-01-12",
                    "FECHA OPERACION": "2010-01-12",
                    "NOMBRES": "Carlos Eduardo",
                    "APELLIDOS": "Sanchez Castano",
                    "NOMBRES COMPLETOS": "Carlos Eduardo Sanchez Castano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Yessica Yulieth Quintero Martinez",
                    "CODIGO USUARIO": "sanc6288",
                    "USUARIOFIVE9": "sanc6288@siigo.com",
                    "USUARIO RED": "sanc6288",
                    "CORREOELECTRONICO": "sanc6288@siigo.com",
                    "STATION ID": "2606011",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yessica Yulieth Quintero Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "43544312",
                    "FECHA INICIAL": "2007-02-12",
                    "FECHA OPERACION": "2007-02-12",
                    "NOMBRES": "Luz Katerine",
                    "APELLIDOS": "Maya Mogollon",
                    "NOMBRES COMPLETOS": "Luz Katerine Maya Mogollon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Juan Pablo Henao Munoz",
                    "CODIGO USUARIO": "maya29615",
                    "USUARIOFIVE9": "maya29615@siigo.com",
                    "USUARIO RED": "maya29615",
                    "CORREOELECTRONICO": "maya29615@siigo.com",
                    "STATION ID": "2606248",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Henao Munoz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024557237",
                    "FECHA INICIAL": "2014-02-17",
                    "FECHA OPERACION": "2014-02-17",
                    "NOMBRES": "Luisa Fernanda",
                    "APELLIDOS": "Rodriguez Larrota",
                    "NOMBRES COMPLETOS": "Luisa Fernanda Rodriguez Larrota",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Yenny Milena Lopez Florez",
                    "CODIGO USUARIO": "rodr28763",
                    "USUARIOFIVE9": "rodr28763@siigo.com",
                    "USUARIO RED": "rodr28763",
                    "CORREOELECTRONICO": "rodr28763@siigo.com",
                    "STATION ID": "2606621",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yenny Milena Lopez Florez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "46667357",
                    "FECHA INICIAL": "1999-02-08",
                    "FECHA OPERACION": "1999-02-08",
                    "NOMBRES": "Magda De Jesus",
                    "APELLIDOS": "Becerra Castaneda",
                    "NOMBRES COMPLETOS": "Magda De Jesus Becerra Castaneda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoracion",
                    "CARGO": "Gerente Excelencia Operativa",
                    "JEFE INMEDIATO": "Jarbin Dadey Rodriguez Pena",
                    "CODIGO USUARIO": "bece4770",
                    "USUARIOFIVE9": "bece4770@siigo.com",
                    "USUARIO RED": "bece4770",
                    "CORREOELECTRONICO": "bece4770@siigo.com",
                    "STATION ID": "2606251",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jarbin Dadey Rodriguez Pena",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80005625",
                    "FECHA INICIAL": "2012-03-05",
                    "FECHA OPERACION": "2012-03-05",
                    "NOMBRES": "Nestor Oswaldo",
                    "APELLIDOS": "Becerra Prieto",
                    "NOMBRES COMPLETOS": "Nestor Oswaldo Becerra Prieto",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Yessica Yulieth Quintero Martinez",
                    "CODIGO USUARIO": "bece19095",
                    "USUARIOFIVE9": "bece19095@siigo.com",
                    "USUARIO RED": "bece19095",
                    "CORREOELECTRONICO": "bece19095@siigo.com",
                    "STATION ID": "2606607",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yessica Yulieth Quintero Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "22476938",
                    "FECHA INICIAL": "2002-09-05",
                    "FECHA OPERACION": "2002-09-05",
                    "NOMBRES": "Luzdary Bernal",
                    "APELLIDOS": "Villarreal",
                    "NOMBRES COMPLETOS": "Luzdary Bernal Villarreal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Yenny Milena Lopez Florez",
                    "CODIGO USUARIO": "bern23913",
                    "USUARIOFIVE9": "bern23913@siigo.com",
                    "USUARIO RED": "bern23913",
                    "CORREOELECTRONICO": "bern23913@siigo.com",
                    "STATION ID": "2606249",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yenny Milena Lopez Florez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022342992",
                    "FECHA INICIAL": "2018-12-04",
                    "FECHA OPERACION": "2018-12-04",
                    "NOMBRES": "Yamid Ramiro",
                    "APELLIDOS": "Quintero Montano",
                    "NOMBRES COMPLETOS": "Yamid Ramiro Quintero Montano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Yessica Yulieth Quintero Martinez",
                    "CODIGO USUARIO": "quin31297",
                    "USUARIOFIVE9": "quin31297@siigo.com",
                    "USUARIO RED": "quin31297",
                    "CORREOELECTRONICO": "quin31297@siigo.com",
                    "STATION ID": "2606379",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yessica Yulieth Quintero Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "63509764",
                    "FECHA INICIAL": "2014-04-21",
                    "FECHA OPERACION": "2014-04-21",
                    "NOMBRES": "Yenny Milena",
                    "APELLIDOS": "Lopez Florez",
                    "NOMBRES COMPLETOS": "Yenny Milena Lopez Florez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Director Customer",
                    "JEFE INMEDIATO": "Carlos Mario Espinosa Fernandez",
                    "CODIGO USUARIO": "lope32671",
                    "USUARIOFIVE9": "lope32671@siigo.com",
                    "USUARIO RED": "lope32671",
                    "CORREOELECTRONICO": "lope32671@siigo.com",
                    "STATION ID": "2606390",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Mario Espinosa Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000473412",
                    "FECHA INICIAL": "2020-07-28",
                    "FECHA OPERACION": "2020-07-28",
                    "NOMBRES": "Angie Carolina",
                    "APELLIDOS": "Rodriguez Rojas",
                    "NOMBRES COMPLETOS": "Angie Carolina Rodriguez Rojas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alix Dayana Archila Castillo",
                    "CODIGO USUARIO": "rodr800483",
                    "USUARIOFIVE9": "rodr800483@siigo.com",
                    "USUARIO RED": "rodr800483",
                    "CORREOELECTRONICO": "rodr800483@siigo.com",
                    "STATION ID": "2606044",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alix Dayana Archila Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014263052",
                    "FECHA INICIAL": "2021-10-28",
                    "FECHA OPERACION": "2021-11-01",
                    "NOMBRES": "Luisa Fernanda",
                    "APELLIDOS": "Calderon Torres",
                    "NOMBRES COMPLETOS": "Luisa Fernanda Calderon Torres",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Pierre Fardey Velasquez Sanchez",
                    "CODIGO USUARIO": "cald802166",
                    "USUARIOFIVE9": "cald802166@siigo.com",
                    "USUARIO RED": "cald802166",
                    "CORREOELECTRONICO": "cald802166@siigo.com",
                    "STATION ID": "3690280",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Pierre Fardey Velasquez Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52916991",
                    "FECHA INICIAL": "2021-10-28",
                    "FECHA OPERACION": "2021-11-01",
                    "NOMBRES": "Adriana Maria",
                    "APELLIDOS": "Cabrera Rios",
                    "NOMBRES COMPLETOS": "Adriana Maria Cabrera Rios",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Anderson Cubillos Ayala",
                    "CODIGO USUARIO": "cabr802172",
                    "USUARIOFIVE9": "cabr802172@siigo.com",
                    "USUARIO RED": "cabr802172",
                    "CORREOELECTRONICO": "cabr802172@siigo.com",
                    "STATION ID": "3690285",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Anderson Cubillos Ayala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1002233134",
                    "FECHA INICIAL": "2021-10-28",
                    "FECHA OPERACION": "2021-11-01",
                    "NOMBRES": "Milagro De Jesus",
                    "APELLIDOS": "Miranda Suarez",
                    "NOMBRES COMPLETOS": "Milagro De Jesus Miranda Suarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Andres Valencia Bravo",
                    "CODIGO USUARIO": "mira802183",
                    "USUARIOFIVE9": "mira802183@siigo.com",
                    "USUARIO RED": "mira802183",
                    "CORREOELECTRONICO": "mira802183@siigo.com",
                    "STATION ID": "3720431",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Andres Valencia Bravo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "71711419",
                    "FECHA INICIAL": "2019-02-15",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Pierre Fardey",
                    "APELLIDOS": "Velasquez Sanchez",
                    "NOMBRES COMPLETOS": "Pierre Fardey Velasquez Sanchez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Yenny Milena Lopez Florez",
                    "CODIGO USUARIO": "vela111933",
                    "USUARIOFIVE9": "vela111933@siigo.com",
                    "USUARIO RED": "vela111933",
                    "CORREOELECTRONICO": "vela111933@siigo.com",
                    "STATION ID": "2606747",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yenny Milena Lopez Florez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "4978365",
                    "FECHA INICIAL": "2019-12-23",
                    "FECHA OPERACION": "2019-12-23",
                    "NOMBRES": "Alejandra Maria",
                    "APELLIDOS": "Carrasco Castaneda",
                    "NOMBRES COMPLETOS": "Alejandra Maria Carrasco Castaneda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Elizabeth Giron Parra",
                    "CODIGO USUARIO": "carra800113",
                    "USUARIOFIVE9": "carra800113@siigo.com",
                    "USUARIO RED": "carra800113",
                    "CORREOELECTRONICO": "carra800113@siigo.com",
                    "STATION ID": "2606910",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Elizabeth Giron Parra",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023942896",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-03-02",
                    "NOMBRES": "Diana Carolina",
                    "APELLIDOS": "Fandino Hernandez",
                    "NOMBRES COMPLETOS": "Diana Carolina Fandino Hernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Elizabeth Giron Parra",
                    "CODIGO USUARIO": "fand800344",
                    "USUARIOFIVE9": "fand800344@siigo.com",
                    "USUARIO RED": "fand800344",
                    "CORREOELECTRONICO": "fand800344@siigo.com",
                    "STATION ID": "2605946",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Elizabeth Giron Parra",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "4516943",
                    "FECHA INICIAL": "2019-10-07",
                    "FECHA OPERACION": "2019-10-07",
                    "NOMBRES": "Freddy Alexander",
                    "APELLIDOS": "Barajas Moreno",
                    "NOMBRES COMPLETOS": "Freddy Alexander Barajas Moreno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Elizabeth Giron Parra",
                    "CODIGO USUARIO": "bara112438",
                    "USUARIOFIVE9": "bara112438@siigo.com",
                    "USUARIO RED": "bara112438",
                    "CORREOELECTRONICO": "bara112438@siigo.com",
                    "STATION ID": "2606721",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Elizabeth Giron Parra",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1045697407",
                    "FECHA INICIAL": "2022-01-06",
                    "FECHA OPERACION": "2022-01-11",
                    "NOMBRES": "Adela Vanessa",
                    "APELLIDOS": "Perez Urina",
                    "NOMBRES COMPLETOS": "Adela Vanessa Perez Urina",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Sory Yaqueline Abello Polo",
                    "CODIGO USUARIO": "pere802558",
                    "USUARIOFIVE9": "pere802558@siigo.com",
                    "USUARIO RED": "pere802558",
                    "CORREOELECTRONICO": "pere802558@siigo.com",
                    "STATION ID": "3127476",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sory Yaqueline Abello Polo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1083915875",
                    "FECHA INICIAL": "2019-09-16",
                    "FECHA OPERACION": "2019-09-16",
                    "NOMBRES": "Luis Fernando",
                    "APELLIDOS": "Gomez Aviles",
                    "NOMBRES COMPLETOS": "Luis Fernando Gomez Aviles",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Elizabeth Giron Parra",
                    "CODIGO USUARIO": "gome112375",
                    "USUARIOFIVE9": "gome112375@siigo.com",
                    "USUARIO RED": "gome112375",
                    "CORREOELECTRONICO": "gome112375@siigo.com",
                    "STATION ID": "2606724",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Elizabeth Giron Parra",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1115074343",
                    "FECHA INICIAL": "2019-09-02",
                    "FECHA OPERACION": "2019-09-02",
                    "NOMBRES": "Andres Felipe",
                    "APELLIDOS": "Rincon Delgado",
                    "NOMBRES COMPLETOS": "Andres Felipe Rincon Delgado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Lina Maria Buitrago Herrera",
                    "CODIGO USUARIO": "rinc112333",
                    "USUARIOFIVE9": "rinc112333@siigo.com",
                    "USUARIO RED": "rinc112333",
                    "CORREOELECTRONICO": "rinc112333@siigo.com",
                    "STATION ID": "2606734",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Lina Maria Buitrago Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1107092498",
                    "FECHA INICIAL": "2019-10-07",
                    "FECHA OPERACION": "2019-10-07",
                    "NOMBRES": "Angel Stiven",
                    "APELLIDOS": "Rodriguez Montano",
                    "NOMBRES COMPLETOS": "Angel Stiven Rodriguez Montano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Eduardo Sanchez Castano",
                    "CODIGO USUARIO": "rodr112442",
                    "USUARIOFIVE9": "rodr112442@siigo.com",
                    "USUARIO RED": "rodr112442",
                    "CORREOELECTRONICO": "rodr112442@siigo.com",
                    "STATION ID": "2606709",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Eduardo Sanchez Castano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024584767",
                    "FECHA INICIAL": "2021-01-04",
                    "FECHA OPERACION": "2021-01-04",
                    "NOMBRES": "Angie Lorena",
                    "APELLIDOS": "Vaquiro Fonseca",
                    "NOMBRES COMPLETOS": "Angie Lorena Vaquiro Fonseca",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Eduardo Sanchez Castano",
                    "CODIGO USUARIO": "vaqu801004",
                    "USUARIOFIVE9": "vaqu801004@siigo.com",
                    "USUARIO RED": "vaqu801004",
                    "CORREOELECTRONICO": "vaqu801004@siigo.com",
                    "STATION ID": "2606282",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Eduardo Sanchez Castano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1113681893",
                    "FECHA INICIAL": "2019-08-26",
                    "FECHA OPERACION": "2019-08-26",
                    "NOMBRES": "Duvan Alexis",
                    "APELLIDOS": "Tello Hinojosa",
                    "NOMBRES COMPLETOS": "Duvan Alexis Tello Hinojosa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoracion",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "tell112303",
                    "USUARIOFIVE9": "tell112303@siigo.com",
                    "USUARIO RED": "tell112303",
                    "CORREOELECTRONICO": "tell112303@siigo.com",
                    "STATION ID": "2606737",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143355544",
                    "FECHA INICIAL": "2020-12-07",
                    "FECHA OPERACION": "2020-12-07",
                    "NOMBRES": "Adrian Alberto",
                    "APELLIDOS": "Salom Berrio",
                    "NOMBRES COMPLETOS": "Adrian Alberto Salom Berrio",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Juan Pablo Henao Munoz",
                    "CODIGO USUARIO": "salo800923",
                    "USUARIOFIVE9": "salo800923@siigo.com",
                    "USUARIO RED": "salo800923",
                    "CORREOELECTRONICO": "salo800923@siigo.com",
                    "STATION ID": "2606318",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Henao Munoz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1233694671",
                    "FECHA INICIAL": "2021-01-15",
                    "FECHA OPERACION": "2021-01-19",
                    "NOMBRES": "Daniela Valentina",
                    "APELLIDOS": "Cardenas Rubiano",
                    "NOMBRES COMPLETOS": "Daniela Valentina Cardenas Rubiano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Eduardo Sanchez Castano",
                    "CODIGO USUARIO": "card801028",
                    "USUARIOFIVE9": "card801028@siigo.com",
                    "USUARIO RED": "card801028",
                    "CORREOELECTRONICO": "card801028@siigo.com",
                    "STATION ID": "2606303",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Eduardo Sanchez Castano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000185503",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-03-02",
                    "NOMBRES": "Alejandra Garzon",
                    "APELLIDOS": "Samudio",
                    "NOMBRES COMPLETOS": "Alejandra Garzon Samudio",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "garz800367",
                    "USUARIOFIVE9": "garz800367@siigo.com",
                    "USUARIO RED": "garz800367",
                    "CORREOELECTRONICO": "garz800367@siigo.com",
                    "STATION ID": "2605930",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024587129",
                    "FECHA INICIAL": "2020-02-10",
                    "FECHA OPERACION": "2020-02-10",
                    "NOMBRES": "Angie Natalia",
                    "APELLIDOS": "Molano Moreno",
                    "NOMBRES COMPLETOS": "Angie Natalia Molano Moreno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Lina Maria Buitrago Herrera",
                    "CODIGO USUARIO": "mola800241",
                    "USUARIOFIVE9": "mola800241@siigo.com",
                    "USUARIO RED": "mola800241",
                    "CORREOELECTRONICO": "mola800241@siigo.com",
                    "STATION ID": "2607066",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Lina Maria Buitrago Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023872988",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-03-02",
                    "NOMBRES": "Johanna Patricia",
                    "APELLIDOS": "Torres Quintero",
                    "NOMBRES COMPLETOS": "Johanna Patricia Torres Quintero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Lider Renovaciones",
                    "JEFE INMEDIATO": "John Alexander Benavides Cobos",
                    "CODIGO USUARIO": "torr800371",
                    "USUARIOFIVE9": "torr800371@siigo.com",
                    "USUARIO RED": "torr800371",
                    "CORREOELECTRONICO": "torr800371@siigo.com",
                    "STATION ID": "2605945",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_John Alexander Benavides Cobos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "28411695",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-03-02",
                    "NOMBRES": "Maria Helena",
                    "APELLIDOS": "Duarte Rugeles",
                    "NOMBRES COMPLETOS": "Maria Helena Duarte Rugeles",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Elizabeth Giron Parra",
                    "CODIGO USUARIO": "duar800343",
                    "USUARIOFIVE9": "duar800343@siigo.com",
                    "USUARIO RED": "duar800343",
                    "CORREOELECTRONICO": "duar800343@siigo.com",
                    "STATION ID": "2605955",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Elizabeth Giron Parra",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023903938",
                    "FECHA INICIAL": "2019-10-01",
                    "FECHA OPERACION": "2019-10-16",
                    "NOMBRES": "Narley Esperanza",
                    "APELLIDOS": "Bocanegra Riano",
                    "NOMBRES COMPLETOS": "Narley Esperanza Bocanegra Riano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ventas",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "boca112421",
                    "USUARIOFIVE9": "boca112421@siigo.com",
                    "USUARIO RED": "boca112421",
                    "CORREOELECTRONICO": "boca112421@siigo.com",
                    "STATION ID": "2606719",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019091668",
                    "FECHA INICIAL": "2019-09-11",
                    "FECHA OPERACION": "2019-09-11",
                    "NOMBRES": "Paula Andrea",
                    "APELLIDOS": "Cabezas Pardo",
                    "NOMBRES COMPLETOS": "Paula Andrea Cabezas Pardo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Elizabeth Giron Parra",
                    "CODIGO USUARIO": "cabe112354",
                    "USUARIOFIVE9": "cabe112354@siigo.com",
                    "USUARIO RED": "cabe112354",
                    "CORREOELECTRONICO": "cabe112354@siigo.com",
                    "STATION ID": "2606729",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Elizabeth Giron Parra",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1005981306",
                    "FECHA INICIAL": "2021-01-19",
                    "FECHA OPERACION": "2021-01-21",
                    "NOMBRES": "Jhoan David",
                    "APELLIDOS": "Mina Hurtado",
                    "NOMBRES COMPLETOS": "Jhoan David Mina Hurtado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Tulua",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Eduardo Sanchez Castano",
                    "CODIGO USUARIO": "mina801054",
                    "USUARIOFIVE9": "mina801054@siigo.com",
                    "USUARIO RED": "mina801054",
                    "CORREOELECTRONICO": "mina801054@siigo.com",
                    "STATION ID": "2606220",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Eduardo Sanchez Castano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013632516",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-03-17",
                    "NOMBRES": "Viviana Andrea",
                    "APELLIDOS": "Aranda Pinilla",
                    "NOMBRES COMPLETOS": "Viviana Andrea Aranda Pinilla",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Workfoce & Reporting",
                    "CARGO": "Mission Controller",
                    "JEFE INMEDIATO": "Julieth Paola Vera Patino",
                    "CODIGO USUARIO": "aran800374",
                    "USUARIOFIVE9": "aran800374@siigo.com",
                    "USUARIO RED": "aran800374",
                    "CORREOELECTRONICO": "aran800374@siigo.com",
                    "STATION ID": "2605937",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Julieth Paola Vera Patino",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1006026464",
                    "FECHA INICIAL": "2021-11-02",
                    "FECHA OPERACION": "2021-11-02",
                    "NOMBRES": "Leidy Daniela",
                    "APELLIDOS": "Corral Aranzalez",
                    "NOMBRES COMPLETOS": "Leidy Daniela Corral Aranzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Andres Valencia Bravo",
                    "CODIGO USUARIO": "corr802149",
                    "USUARIOFIVE9": "corr802149@siigo.com",
                    "USUARIO RED": "corr802149",
                    "CORREOELECTRONICO": "corr802149@siigo.com",
                    "STATION ID": "2605972",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Andres Valencia Bravo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018347442",
                    "FECHA INICIAL": "2020-02-17",
                    "FECHA OPERACION": "2020-02-17",
                    "NOMBRES": "Daniela Arboleda",
                    "APELLIDOS": "Alvarez",
                    "NOMBRES COMPLETOS": "Daniela Arboleda Alvarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Yamid Ramiro Quintero Montano",
                    "CODIGO USUARIO": "arbo800287",
                    "USUARIOFIVE9": "arbo800287@siigo.com",
                    "USUARIO RED": "arbo800287",
                    "CORREOELECTRONICO": "arbo800287@siigo.com",
                    "STATION ID": "2607097",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yamid Ramiro Quintero Montano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1067941458",
                    "FECHA INICIAL": "2021-06-11",
                    "FECHA OPERACION": "2021-06-11",
                    "NOMBRES": "Andrea Carolina",
                    "APELLIDOS": "Mercado Vasquez",
                    "NOMBRES COMPLETOS": "Andrea Carolina Mercado Vasquez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Leidy Stefany Castillo",
                    "CODIGO USUARIO": "merc801505",
                    "USUARIOFIVE9": "merc801505@siigo.com",
                    "USUARIO RED": "merc801505",
                    "CORREOELECTRONICO": "merc801505@siigo.com",
                    "STATION ID": "2606531",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Leidy Stefany Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52838173",
                    "FECHA INICIAL": "2021-06-11",
                    "FECHA OPERACION": "2021-06-11",
                    "NOMBRES": "Carolina",
                    "APELLIDOS": "Castro Castano",
                    "NOMBRES COMPLETOS": "Carolina Castro Castano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adrian Alberto Salom Berrio",
                    "CODIGO USUARIO": "cast801508",
                    "USUARIOFIVE9": "cast801508@siigo.com",
                    "USUARIO RED": "cast801508",
                    "CORREOELECTRONICO": "cast801508@siigo.com",
                    "STATION ID": "2606525",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adrian Alberto Salom Berrio",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1045720185",
                    "FECHA INICIAL": "2019-11-05",
                    "FECHA OPERACION": "2019-11-05",
                    "NOMBRES": "Alexandra Palacio",
                    "APELLIDOS": "Sandoval",
                    "NOMBRES COMPLETOS": "Alexandra Palacio Sandoval",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoraciones",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Sandra Catalina Suarez Castillo",
                    "CODIGO USUARIO": "pala112488",
                    "USUARIOFIVE9": "pala112488@siigo.com",
                    "USUARIO RED": "pala112488",
                    "CORREOELECTRONICO": "pala112488@siigo.com",
                    "STATION ID": "2606752",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Sandra Catalina Suarez Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "46456808",
                    "FECHA INICIAL": "2020-02-24",
                    "FECHA OPERACION": "2020-02-24",
                    "NOMBRES": "Brigith Johana",
                    "APELLIDOS": "Chaparro Camacho",
                    "NOMBRES COMPLETOS": "Brigith Jhoana Chaparro Camacho",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Jimena Marcela Prieto Bohorquez",
                    "CODIGO USUARIO": "chap800333",
                    "USUARIOFIVE9": "chap800333@siigo.com",
                    "USUARIO RED": "chap800333",
                    "CORREOELECTRONICO": "chap800333@siigo.com",
                    "STATION ID": "2606842",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jimena Marcela Prieto Bohorquez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000181605",
                    "FECHA INICIAL": "2021-01-04",
                    "FECHA OPERACION": "2021-01-04",
                    "NOMBRES": "Camila Stefany",
                    "APELLIDOS": "Varela Ladino",
                    "NOMBRES COMPLETOS": "Camila Stefany Varela Ladino",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Estrategia",
                    "SUBAREA": "Estrategia",
                    "CARGO": "Estrategia",
                    "JEFE INMEDIATO": "Katherine Ayala Garcia",
                    "CODIGO USUARIO": "vare801005",
                    "USUARIOFIVE9": "vare801005@siigo.com",
                    "USUARIO RED": "vare801005",
                    "CORREOELECTRONICO": "vare801005@siigo.com",
                    "STATION ID": "2606283",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Estrategia_Katherine Ayala Garcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057596580",
                    "FECHA INICIAL": "2019-12-02",
                    "FECHA OPERACION": "2019-12-02",
                    "NOMBRES": "Cristian Camilo",
                    "APELLIDOS": "Hernandez Gonzalez",
                    "NOMBRES COMPLETOS": "Cristian Camilo Hernandez Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Juan Pablo Henao Munoz",
                    "CODIGO USUARIO": "hern800017",
                    "USUARIOFIVE9": "hern800017@siigo.com",
                    "USUARIO RED": "hern800017",
                    "CORREOELECTRONICO": "hern800017@siigo.com",
                    "STATION ID": "2606759",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Henao Munoz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "931232151",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-03-10",
                    "NOMBRES": "Karen Denisse",
                    "APELLIDOS": "Quiroz Rodriguez",
                    "NOMBRES COMPLETOS": "Karen Denisse Quiroz Rodriguez",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "quir801208",
                    "USUARIOFIVE9": "quir801208@siigo.com",
                    "USUARIO RED": "quir801208",
                    "CORREOELECTRONICO": "quir801208@siigo.com",
                    "STATION ID": "2606195",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1002528381",
                    "FECHA INICIAL": "2019-11-08",
                    "FECHA OPERACION": "2019-11-08",
                    "NOMBRES": "Danna Fernanda",
                    "APELLIDOS": "Beltran Guevara",
                    "NOMBRES COMPLETOS": "Danna Fernanda Beltran Guevara",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Jimena Marcela Prieto Bohorquez",
                    "CODIGO USUARIO": "belt112524",
                    "USUARIOFIVE9": "belt112524@siigo.com",
                    "USUARIO RED": "belt112524",
                    "CORREOELECTRONICO": "belt112524@siigo.com",
                    "STATION ID": "2606761",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jimena Marcela Prieto Bohorquez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1107096874",
                    "FECHA INICIAL": "2020-02-10",
                    "FECHA OPERACION": "2020-02-10",
                    "NOMBRES": "Diana Milena",
                    "APELLIDOS": "Herrera Rodriguez",
                    "NOMBRES COMPLETOS": "Diana Milena Herrera Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "UCI Fidelizacion",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Nestor Oswaldo Becerra Prieto",
                    "CODIGO USUARIO": "herr800252",
                    "USUARIOFIVE9": "herr800252@siigo.com",
                    "USUARIO RED": "herr800252",
                    "CORREOELECTRONICO": "herr800252@siigo.com",
                    "STATION ID": "2607053",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Nestor Oswaldo Becerra Prieto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057604134",
                    "FECHA INICIAL": "2020-02-17",
                    "FECHA OPERACION": "2020-02-17",
                    "NOMBRES": "Diego Fernando",
                    "APELLIDOS": "Diaz Corredor",
                    "NOMBRES COMPLETOS": "Diego Fernando Diaz Corredor",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Claudia Patricia Estevez Alvarez",
                    "CODIGO USUARIO": "diaz800290",
                    "USUARIOFIVE9": "diaz800290@siigo.com",
                    "USUARIO RED": "diaz800290",
                    "CORREOELECTRONICO": "diaz800290@siigo.com",
                    "STATION ID": "2607094",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Claudia Patricia Estevez Alvarez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1049632394",
                    "FECHA INICIAL": "2019-11-05",
                    "FECHA OPERACION": "2019-11-05",
                    "NOMBRES": "Jenny Esperanza",
                    "APELLIDOS": "Fonseca Joya",
                    "NOMBRES COMPLETOS": "Jenny Esperanza Fonseca Joya",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoraciones",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Sandra Catalina Suarez Castillo",
                    "CODIGO USUARIO": "fons112496",
                    "USUARIOFIVE9": "fons112496@siigo.com",
                    "USUARIO RED": "fons112496",
                    "CORREOELECTRONICO": "fons112496@siigo.com",
                    "STATION ID": "2606762",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Sandra Catalina Suarez Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1002414337",
                    "FECHA INICIAL": "2019-12-02",
                    "FECHA OPERACION": "2019-12-02",
                    "NOMBRES": "Jhoanna Mendivelso",
                    "APELLIDOS": "Gavidia",
                    "NOMBRES COMPLETOS": "Jhoanna Mendivelso Gavidia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Diana Sofia Ramos Castillo",
                    "CODIGO USUARIO": "mend800016",
                    "USUARIOFIVE9": "mend800016@siigo.com",
                    "USUARIO RED": "mend800016",
                    "CORREOELECTRONICO": "mend800016@siigo.com",
                    "STATION ID": "2606763",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Sofia Ramos Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1063176062",
                    "FECHA INICIAL": "2020-07-28",
                    "FECHA OPERACION": "2020-07-28",
                    "NOMBRES": "Eduard Andres",
                    "APELLIDOS": "Florez Anaya",
                    "NOMBRES COMPLETOS": "Eduard Andres Florez Anaya",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Leidy Stefany Castillo",
                    "CODIGO USUARIO": "flor800480",
                    "USUARIOFIVE9": "flor800480@siigo.com",
                    "USUARIO RED": "flor800480",
                    "CORREOELECTRONICO": "flor800480@siigo.com",
                    "STATION ID": "2606040",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Leidy Stefany Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012396655",
                    "FECHA INICIAL": "2020-12-07",
                    "FECHA OPERACION": "2020-12-07",
                    "NOMBRES": "Erika Yinneth",
                    "APELLIDOS": "Ospicio Ramirez",
                    "NOMBRES COMPLETOS": "Erika Yinneth Ospicio Ramirez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Diego Armando Alzate Rios",
                    "CODIGO USUARIO": "ospi800925",
                    "USUARIOFIVE9": "ospi800925@siigo.com",
                    "USUARIO RED": "ospi800925",
                    "CORREOELECTRONICO": "ospi800925@siigo.com",
                    "STATION ID": "2606319",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Diego Armando Alzate Rios",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "37949431",
                    "FECHA INICIAL": "2020-02-24",
                    "FECHA OPERACION": "2020-02-24",
                    "NOMBRES": "Yadira Perez",
                    "APELLIDOS": "Ballesteros",
                    "NOMBRES COMPLETOS": "Yadira Perez Ballesteros",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Cristian Camilo Hernandez Gonzalez",
                    "CODIGO USUARIO": "pere800332",
                    "USUARIOFIVE9": "pere800332@siigo.com",
                    "USUARIO RED": "pere800332",
                    "CORREOELECTRONICO": "pere800332@siigo.com",
                    "STATION ID": "2607037",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Cristian Camilo Hernandez Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1083021763",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Ana",
                    "APELLIDOS": "Marcela Palma",
                    "NOMBRES COMPLETOS": "Ana Marcela Palma Arvilla",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soledad",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Pierre Fardey Velasquez Sanchez",
                    "CODIGO USUARIO": "palm802528",
                    "USUARIOFIVE9": "palm802528@siigo.com",
                    "USUARIO RED": "palm802528",
                    "CORREOELECTRONICO": "palm802528@siigo.com",
                    "STATION ID": "3720392",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Pierre Fardey Velasquez Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000685158",
                    "FECHA INICIAL": "2020-08-13",
                    "FECHA OPERACION": "2020-08-13",
                    "NOMBRES": "Erika Tatiana",
                    "APELLIDOS": "Mier Galindo",
                    "NOMBRES COMPLETOS": "Erika Tatiana Mier Galindo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Leidy Paola Rubiano Vargas",
                    "CODIGO USUARIO": "mier800513",
                    "USUARIOFIVE9": "mier800513@siigo.com",
                    "USUARIO RED": "mier800513",
                    "CORREOELECTRONICO": "mier800513@siigo.com",
                    "STATION ID": "2606061",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Leidy Paola Rubiano Vargas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52444190",
                    "FECHA INICIAL": "2007-08-08",
                    "FECHA OPERACION": "2007-08-08",
                    "NOMBRES": "Ana Consuelo",
                    "APELLIDOS": "Castro Mendoza",
                    "NOMBRES COMPLETOS": "Ana Consuelo Castro Mendoza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Andres Valencia Bravo",
                    "CODIGO USUARIO": "cast223509",
                    "USUARIOFIVE9": "cast223509@siigo.com",
                    "USUARIO RED": "cast223509",
                    "CORREOELECTRONICO": "cast223509@siigo.com",
                    "STATION ID": "2606594",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Andres Valencia Bravo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030661682",
                    "FECHA INICIAL": "2020-08-13",
                    "FECHA OPERACION": "2020-08-13",
                    "NOMBRES": "Jojhana Idaly",
                    "APELLIDOS": "Ardila Becerra",
                    "NOMBRES COMPLETOS": "Jojhana Idaly Ardila Becerra",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoraciones",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Sandra Catalina Suarez Castillo",
                    "CODIGO USUARIO": "ardi800511",
                    "USUARIOFIVE9": "ardi800511@siigo.com",
                    "USUARIO RED": "ardi800511",
                    "CORREOELECTRONICO": "ardi800511@siigo.com",
                    "STATION ID": "2606053",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Sandra Catalina Suarez Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015477894",
                    "FECHA INICIAL": "2020-01-20",
                    "FECHA OPERACION": "2020-01-20",
                    "NOMBRES": "Angie Paola",
                    "APELLIDOS": "Gil Moreira",
                    "NOMBRES COMPLETOS": "Angie Paola Gil Moreira",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Contenidos",
                    "CARGO": "Analista Gestion del Conocimiento",
                    "JEFE INMEDIATO": "Luz Angelica Torres Quintero",
                    "CODIGO USUARIO": "gilm800167",
                    "USUARIOFIVE9": "gilm800167@siigo.com",
                    "USUARIO RED": "gilm800167",
                    "CORREOELECTRONICO": "gilm800167@siigo.com",
                    "STATION ID": "2607004",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Angelica Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007398370",
                    "FECHA INICIAL": "2019-09-11",
                    "FECHA OPERACION": "2019-09-11",
                    "NOMBRES": "Elyana Manrique",
                    "APELLIDOS": "Chaparro",
                    "NOMBRES COMPLETOS": "Elyana Manrique Chaparro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Lina Maria Buitrago Herrera",
                    "CODIGO USUARIO": "manr112355",
                    "USUARIOFIVE9": "manr112355@siigo.com",
                    "USUARIO RED": "manr112355",
                    "CORREOELECTRONICO": "manr112355@siigo.com",
                    "STATION ID": "2606773",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Lina Maria Buitrago Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024467380",
                    "FECHA INICIAL": "2020-02-03",
                    "FECHA OPERACION": "2020-02-03",
                    "NOMBRES": "Jenny Paola",
                    "APELLIDOS": "Mendez",
                    "NOMBRES COMPLETOS": "Jenny Paola Mendez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Yessica Yulieth Quintero Martinez",
                    "CODIGO USUARIO": "mend800219",
                    "USUARIOFIVE9": "mend800219@siigo.com",
                    "USUARIO RED": "mend800219",
                    "CORREOELECTRONICO": "mend800219@siigo.com",
                    "STATION ID": "2607011",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yessica Yulieth Quintero Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "39750071",
                    "FECHA INICIAL": "2013-01-08",
                    "FECHA OPERACION": "2013-01-08",
                    "NOMBRES": "Katalina",
                    "APELLIDOS": "Pulido Quimbayo",
                    "NOMBRES COMPLETOS": "Katalina Pulido Quimbayo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luzdary Bernal Villarreal",
                    "CODIGO USUARIO": "puli20592",
                    "USUARIOFIVE9": "puli20592@siigo.com",
                    "USUARIO RED": "puli20592",
                    "CORREOELECTRONICO": "puli20592@siigo.com",
                    "STATION ID": "2606605",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luzdary Bernal Villarreal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52911843",
                    "FECHA INICIAL": "2019-12-16",
                    "FECHA OPERACION": "2019-12-16",
                    "NOMBRES": "Lady Dayan",
                    "APELLIDOS": "Celeita Correal",
                    "NOMBRES COMPLETOS": "Lady Dayan Celeita Correal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Yamid Ramiro Quintero Montano",
                    "CODIGO USUARIO": "cele800077",
                    "USUARIOFIVE9": "cele800077@siigo.com",
                    "USUARIO RED": "cele800077",
                    "CORREOELECTRONICO": "cele800077@siigo.com",
                    "STATION ID": "2606862",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yamid Ramiro Quintero Montano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022418951",
                    "FECHA INICIAL": "2019-08-05",
                    "FECHA OPERACION": "2019-08-05",
                    "NOMBRES": "Marien Carolina",
                    "APELLIDOS": "Diaz Garay",
                    "NOMBRES COMPLETOS": "Marien Carolina Diaz Garay",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Jairo Enrique Corzo Lizarazo",
                    "CODIGO USUARIO": "diaz112259",
                    "USUARIOFIVE9": "diaz112259@siigo.com",
                    "USUARIO RED": "diaz112259",
                    "CORREOELECTRONICO": "diaz112259@siigo.com",
                    "STATION ID": "2606780",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Jairo Enrique Corzo Lizarazo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007769901",
                    "FECHA INICIAL": "2020-02-10",
                    "FECHA OPERACION": "2020-02-10",
                    "NOMBRES": "Maryury Tatiana",
                    "APELLIDOS": "Gelves Ariza",
                    "NOMBRES COMPLETOS": "Maryury Tatiana Gelves Ariza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Yamid Ramiro Quintero Montano",
                    "CODIGO USUARIO": "gelv800248",
                    "USUARIOFIVE9": "gelv800248@siigo.com",
                    "USUARIO RED": "gelv800248",
                    "CORREOELECTRONICO": "gelv800248@siigo.com",
                    "STATION ID": "2607061",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yamid Ramiro Quintero Montano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033765282",
                    "FECHA INICIAL": "2020-02-10",
                    "FECHA OPERACION": "2020-02-10",
                    "NOMBRES": "Yoan Sebastian",
                    "APELLIDOS": "Cardona Pedraza",
                    "NOMBRES COMPLETOS": "Yoan Sebastian Cardona Pedraza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Paula Alejandra Garcia Lujan",
                    "CODIGO USUARIO": "card800247",
                    "USUARIOFIVE9": "card800247@siigo.com",
                    "USUARIO RED": "card800247",
                    "CORREOELECTRONICO": "card800247@siigo.com",
                    "STATION ID": "2607064",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Paula Alejandra Garcia Lujan",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098803520",
                    "FECHA INICIAL": "2020-02-17",
                    "FECHA OPERACION": "2020-02-17",
                    "NOMBRES": "Brayan Andres",
                    "APELLIDOS": "Arias Silva",
                    "NOMBRES COMPLETOS": "Brayan Andres Arias Silva",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Roberto Alvarado Mancera",
                    "CODIGO USUARIO": "aria800292",
                    "USUARIOFIVE9": "aria800292@siigo.com",
                    "USUARIO RED": "aria800292",
                    "CORREOELECTRONICO": "aria800292@siigo.com",
                    "STATION ID": "2607090",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Roberto Alvarado Mancera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1233495696",
                    "FECHA INICIAL": "2021-01-04",
                    "FECHA OPERACION": "2021-01-04",
                    "NOMBRES": "Maria Fernanda",
                    "APELLIDOS": "Bautista Sanchez",
                    "NOMBRES COMPLETOS": "Maria Fernanda Bautista Sanchez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luisa Fernanda Rodriguez Larrota",
                    "CODIGO USUARIO": "baut800995",
                    "USUARIOFIVE9": "baut800995@siigo.com",
                    "USUARIO RED": "baut800995",
                    "CORREOELECTRONICO": "baut800995@siigo.com",
                    "STATION ID": "2606273",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luisa Fernanda Rodriguez Larrota",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098800899",
                    "FECHA INICIAL": "2019-10-21",
                    "FECHA OPERACION": "2019-10-21",
                    "NOMBRES": "Oscar Santiago",
                    "APELLIDOS": "Gomez Rangel",
                    "NOMBRES COMPLETOS": "Oscar Santiago Gomez Rangel",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Yudy Natali Leon Barrera",
                    "CODIGO USUARIO": "gome112463",
                    "USUARIOFIVE9": "gome112463@siigo.com",
                    "USUARIO RED": "gome112463",
                    "CORREOELECTRONICO": "gome112463@siigo.com",
                    "STATION ID": "2606786",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Yudy Natali Leon Barrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1095835052",
                    "FECHA INICIAL": "2020-02-17",
                    "FECHA OPERACION": "2020-03-03",
                    "NOMBRES": "Piere Angelys",
                    "APELLIDOS": "Gutierrez Chica",
                    "NOMBRES COMPLETOS": "Piere Angelys Gutierrez Chica",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "guti800294",
                    "USUARIOFIVE9": "guti800294@siigo.com",
                    "USUARIO RED": "guti800294",
                    "CORREOELECTRONICO": "guti800294@siigo.com",
                    "STATION ID": "2607092",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018491156",
                    "FECHA INICIAL": "2020-02-24",
                    "FECHA OPERACION": "2020-02-24",
                    "NOMBRES": "Juan Camilo",
                    "APELLIDOS": "Vargas Pelayo",
                    "NOMBRES COMPLETOS": "Juan Camilo Vargas Pelayo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Enrique Varela Lasso",
                    "CODIGO USUARIO": "varg800308",
                    "USUARIOFIVE9": "varg800308@siigo.com",
                    "USUARIO RED": "varg800308",
                    "CORREOELECTRONICO": "varg800308@siigo.com",
                    "STATION ID": "2606898",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Enrique Varela Lasso",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098819442",
                    "FECHA INICIAL": "2019-08-26",
                    "FECHA OPERACION": "2019-08-26",
                    "NOMBRES": "Silvya Fernanda",
                    "APELLIDOS": "Rodriguez Nino",
                    "NOMBRES COMPLETOS": "Silvya Fernanda Rodriguez Nino",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Workfoce & Reporting",
                    "CARGO": "Mission Controller",
                    "JEFE INMEDIATO": "Julieth Paola Vera Patino",
                    "CODIGO USUARIO": "rodr112304",
                    "USUARIOFIVE9": "rodr112304@siigo.com",
                    "USUARIO RED": "rodr112304",
                    "CORREOELECTRONICO": "rodr112304@siigo.com",
                    "STATION ID": "2606720",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Julieth Paola Vera Patino",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1127361720",
                    "FECHA INICIAL": "2019-10-21",
                    "FECHA OPERACION": "2019-10-21",
                    "NOMBRES": "Viviana Patricia",
                    "APELLIDOS": "Hernandez Hurtado",
                    "NOMBRES COMPLETOS": "Viviana Patricia Hernandez Hurtado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Diana Sofia Ramos Castillo",
                    "CODIGO USUARIO": "hern112462",
                    "USUARIOFIVE9": "hern112462@siigo.com",
                    "USUARIO RED": "hern112462",
                    "CORREOELECTRONICO": "hern112462@siigo.com",
                    "STATION ID": "2606788",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Sofia Ramos Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "44155541",
                    "FECHA INICIAL": "2003-12-01",
                    "FECHA OPERACION": "2003-12-01",
                    "NOMBRES": "Dina Liceth",
                    "APELLIDOS": "Orozco Rivera",
                    "NOMBRES COMPLETOS": "Dina Liceth Orozco Rivera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Divulgacion",
                    "CARGO": "Divulgacion",
                    "JEFE INMEDIATO": "Jose Daniel Huerfano Martinez",
                    "CODIGO USUARIO": "oroz30852",
                    "USUARIOFIVE9": "oroz30852@siigo.com",
                    "USUARIO RED": "oroz30852",
                    "CORREOELECTRONICO": "oroz30852@siigo.com",
                    "STATION ID": "2606753",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jose Daniel Huerfano Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "71791012",
                    "FECHA INICIAL": "2019-12-16",
                    "FECHA OPERACION": "2019-12-16",
                    "NOMBRES": "Yord Fredy",
                    "APELLIDOS": "Gaviria Garcia",
                    "NOMBRES COMPLETOS": "Yord Fredy Gaviria Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "gavi800058",
                    "USUARIOFIVE9": "gavi800058@siigo.com",
                    "USUARIO RED": "gavi800058",
                    "CORREOELECTRONICO": "gavi800058@siigo.com",
                    "STATION ID": "2606911",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "37272087",
                    "FECHA INICIAL": "2011-11-08",
                    "FECHA OPERACION": "2011-11-08",
                    "NOMBRES": "Maria Zenaida",
                    "APELLIDOS": "Buitrago Carrillo",
                    "NOMBRES COMPLETOS": "Maria Zenaida Buitrago Carrillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cucuta",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Juan Pablo Carreno Patino",
                    "CODIGO USUARIO": "buit243825",
                    "USUARIOFIVE9": "buit243825@siigo.com",
                    "USUARIO RED": "buit243825",
                    "CORREOELECTRONICO": "buit243825@siigo.com",
                    "STATION ID": "2606704",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Carreno Patino",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80249132",
                    "FECHA INICIAL": "2019-02-01",
                    "FECHA OPERACION": "2019-02-01",
                    "NOMBRES": "Wilmar Andres",
                    "APELLIDOS": "Rodriguez Hernandez",
                    "NOMBRES COMPLETOS": "Wilmar Andres Rodriguez Hernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luisa Fernanda Rodriguez Larrota",
                    "CODIGO USUARIO": "rodr19229",
                    "USUARIOFIVE9": "rodr19229@siigo.com",
                    "USUARIO RED": "rodr19229",
                    "CORREOELECTRONICO": "rodr19229@siigo.com",
                    "STATION ID": "2606627",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luisa Fernanda Rodriguez Larrota",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1006513195",
                    "FECHA INICIAL": "2021-08-17",
                    "FECHA OPERACION": "2021-08-18",
                    "NOMBRES": "Paula Valentina",
                    "APELLIDOS": "Suarez Ramirez",
                    "NOMBRES COMPLETOS": "Paula Valentina Suarez Ramirez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adrian Alberto Salom Berrio",
                    "CODIGO USUARIO": "suar801675",
                    "USUARIOFIVE9": "suar801675@siigo.com",
                    "USUARIO RED": "suar801675",
                    "CORREOELECTRONICO": "suar801675@siigo.com",
                    "STATION ID": "3127468",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adrian Alberto Salom Berrio",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1002032821",
                    "FECHA INICIAL": "2021-08-17",
                    "FECHA OPERACION": "2021-08-18",
                    "NOMBRES": "Shayla Alejandra",
                    "APELLIDOS": "Gutierrez Ledesma",
                    "NOMBRES COMPLETOS": "Shayla Alejandra Gutierrez Ledesma",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Nicoll Torres Celemin",
                    "CODIGO USUARIO": "guti801676",
                    "USUARIOFIVE9": "guti801676@siigo.com",
                    "USUARIO RED": "guti801676",
                    "CORREOELECTRONICO": "guti801676@siigo.com",
                    "STATION ID": "3127515",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Nicoll Torres Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018506996",
                    "FECHA INICIAL": "2020-01-27",
                    "FECHA OPERACION": "2020-01-27",
                    "NOMBRES": "Juan Diego",
                    "APELLIDOS": "Sicacha Cortes",
                    "NOMBRES COMPLETOS": "Juan Diego Sicacha Cortes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Gerardo Enrique Aldecua Matamoros",
                    "CODIGO USUARIO": "sica800226",
                    "USUARIOFIVE9": "sica800226@siigo.com",
                    "USUARIO RED": "sica800226",
                    "CORREOELECTRONICO": "sica800226@siigo.com",
                    "STATION ID": "2607013",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Gerardo Enrique Aldecua Matamoros",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "951975853",
                    "FECHA INICIAL": "2021-09-09",
                    "FECHA OPERACION": "2021-09-09",
                    "NOMBRES": "Ninoska Thalia",
                    "APELLIDOS": "Plaza Alvarado",
                    "NOMBRES COMPLETOS": "Ninoska Thalia Plaza Alvarado",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Customer",
                    "SUBAREA": "Firma Electronica",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "plaz801851",
                    "USUARIOFIVE9": "plaz801851@siigo.com",
                    "USUARIO RED": "plaz801851",
                    "CORREOELECTRONICO": "plaz801851@siigo.com",
                    "STATION ID": "3267900",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057592747",
                    "FECHA INICIAL": "2021-09-08",
                    "FECHA OPERACION": "2021-09-08",
                    "NOMBRES": "Nidia Consuelo",
                    "APELLIDOS": "Combariza Tinjaca",
                    "NOMBRES COMPLETOS": "Nidia Consuelo Combariza Tinjaca",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Sogamoso",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Diana Sofia Ramos Castillo",
                    "CODIGO USUARIO": "comb801854",
                    "USUARIOFIVE9": "comb801854@siigo.com",
                    "USUARIO RED": "comb801854",
                    "CORREOELECTRONICO": "comb801854@siigo.com",
                    "STATION ID": "3267889",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Sofia Ramos Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1095947864",
                    "FECHA INICIAL": "2020-01-07",
                    "FECHA OPERACION": "2020-01-07",
                    "NOMBRES": "Laura Marcela",
                    "APELLIDOS": "Mendoza Calderon",
                    "NOMBRES COMPLETOS": "Laura Marcela Mendoza Calderon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Workfoce & Reporting",
                    "CARGO": "Mission Controller",
                    "JEFE INMEDIATO": "Julieth Paola Vera Patino",
                    "CODIGO USUARIO": "mend800148",
                    "USUARIOFIVE9": "mend800148@siigo.com",
                    "USUARIO RED": "mend800148",
                    "CORREOELECTRONICO": "mend800148@siigo.com",
                    "STATION ID": "2606954",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Julieth Paola Vera Patino",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030579646",
                    "FECHA INICIAL": "2018-06-15",
                    "FECHA OPERACION": "2018-06-15",
                    "NOMBRES": "Jose Israel",
                    "APELLIDOS": "Cardenas Cortes",
                    "NOMBRES COMPLETOS": "Jose Israel Cardenas Cortes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoracion",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "card111723",
                    "USUARIOFIVE9": "card111723@siigo.com",
                    "USUARIO RED": "card111723",
                    "CORREOELECTRONICO": "card111723@siigo.com",
                    "STATION ID": "2606797",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019108958",
                    "FECHA INICIAL": "2020-10-05",
                    "FECHA OPERACION": "2020-10-14",
                    "NOMBRES": "Cristian David",
                    "APELLIDOS": "Hernandez Pertuz",
                    "NOMBRES COMPLETOS": "Cristian David Hernandez Pertuz",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Omar Eduardo Roa Quintero",
                    "CODIGO USUARIO": "hern800779",
                    "USUARIOFIVE9": "hern800779@siigo.com",
                    "USUARIO RED": "hern800779",
                    "CORREOELECTRONICO": "hern800779@siigo.com",
                    "STATION ID": "2606167",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Omar Eduardo Roa Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143849012",
                    "FECHA INICIAL": "2021-08-17",
                    "FECHA OPERACION": "2021-08-18",
                    "NOMBRES": "Yiset",
                    "APELLIDOS": "Espinosa Villaquiran",
                    "NOMBRES COMPLETOS": "Yiset Espinosa Villaquiran",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Diana Sofia Ramos Castillo",
                    "CODIGO USUARIO": "espi801679",
                    "USUARIOFIVE9": "espi801679@siigo.com",
                    "USUARIO RED": "espi801679",
                    "CORREOELECTRONICO": "espi801679@siigo.com",
                    "STATION ID": "2607058",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Sofia Ramos Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "18008288",
                    "FECHA INICIAL": "2012-06-25",
                    "FECHA OPERACION": "2012-06-25",
                    "NOMBRES": "Yellian Eduardo",
                    "APELLIDOS": "Ramirez Patino",
                    "NOMBRES COMPLETOS": "Yellian Eduardo Ramirez Patino",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Geomar Eliecer Delgado Molina",
                    "CODIGO USUARIO": "rami21295",
                    "USUARIOFIVE9": "rami21295@siigo.com",
                    "USUARIO RED": "rami21295",
                    "CORREOELECTRONICO": "rami21295@siigo.com",
                    "STATION ID": "2606648",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Geomar Eliecer Delgado Molina",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "72188114",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-03-02",
                    "NOMBRES": "Alcides Martin",
                    "APELLIDOS": "Salas Orozco",
                    "NOMBRES COMPLETOS": "Alcides Martin Salas Orozco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Uruguay",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "sala800352",
                    "USUARIOFIVE9": "sala800352@siigo.com",
                    "USUARIO RED": "sala800352",
                    "CORREOELECTRONICO": "sala800352@siigo.com",
                    "STATION ID": "2605958",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1069753033",
                    "FECHA INICIAL": "2019-11-12",
                    "FECHA OPERACION": "2019-11-12",
                    "NOMBRES": "Maria Liliana",
                    "APELLIDOS": "Malagon Valero",
                    "NOMBRES COMPLETOS": "Maria Liliana Malagon Valero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Elizabeth Giron Parra",
                    "CODIGO USUARIO": "mala112515",
                    "USUARIOFIVE9": "mala112515@siigo.com",
                    "USUARIO RED": "mala112515",
                    "CORREOELECTRONICO": "mala112515@siigo.com",
                    "STATION ID": "2606727",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Elizabeth Giron Parra",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032443070",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-03-02",
                    "NOMBRES": "Estefania Chaparro",
                    "APELLIDOS": "Varon",
                    "NOMBRES COMPLETOS": "Estefania Chaparro Varon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "UCI Fidelizacion",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Nestor Oswaldo Becerra Prieto",
                    "CODIGO USUARIO": "chap800373",
                    "USUARIOFIVE9": "chap800373@siigo.com",
                    "USUARIO RED": "chap800373",
                    "CORREOELECTRONICO": "chap800373@siigo.com",
                    "STATION ID": "2605950",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Nestor Oswaldo Becerra Prieto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000802452",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-03-02",
                    "NOMBRES": "Harold Sebastian",
                    "APELLIDOS": "Becerra Pacheco",
                    "NOMBRES COMPLETOS": "Harold Sebastian Becerra Pacheco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "UCI Fidelizacion",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Nestor Oswaldo Becerra Prieto",
                    "CODIGO USUARIO": "bece800366",
                    "USUARIOFIVE9": "bece800366@siigo.com",
                    "USUARIO RED": "bece800366",
                    "CORREOELECTRONICO": "bece800366@siigo.com",
                    "STATION ID": "2605933",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Nestor Oswaldo Becerra Prieto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018480887",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-03-02",
                    "NOMBRES": "Jerson Fabian",
                    "APELLIDOS": "Hernandez Capera",
                    "NOMBRES COMPLETOS": "Jerson Fabian Hernandez Capera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoracion",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "hern800380",
                    "USUARIOFIVE9": "hern800380@siigo.com",
                    "USUARIO RED": "hern800380",
                    "CORREOELECTRONICO": "hern800380@siigo.com",
                    "STATION ID": "2605940",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1069305623",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-03-02",
                    "NOMBRES": "Manuel Elkin",
                    "APELLIDOS": "Rodriguez Bohorquez",
                    "NOMBRES COMPLETOS": "Manuel Elkin Rodriguez Bohorquez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "rodr800377",
                    "USUARIOFIVE9": "rodr800377@siigo.com",
                    "USUARIO RED": "rodr800377",
                    "CORREOELECTRONICO": "rodr800377@siigo.com",
                    "STATION ID": "2605952",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1031150974",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-03-02",
                    "NOMBRES": "Maria Angelica",
                    "APELLIDOS": "Poveda Pineros",
                    "NOMBRES COMPLETOS": "Maria Angelica Poveda Pineros",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "UCI Fidelizacion",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Nestor Oswaldo Becerra Prieto",
                    "CODIGO USUARIO": "pove800365",
                    "USUARIOFIVE9": "pove800365@siigo.com",
                    "USUARIO RED": "pove800365",
                    "CORREOELECTRONICO": "pove800365@siigo.com",
                    "STATION ID": "2605949",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Nestor Oswaldo Becerra Prieto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014216932",
                    "FECHA INICIAL": "2019-10-07",
                    "FECHA OPERACION": "2019-10-07",
                    "NOMBRES": "Sandra Catalina",
                    "APELLIDOS": "Suarez Castillo",
                    "NOMBRES COMPLETOS": "Sandra Catalina Suarez Castillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoracion",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Magda de Jesus Becerra Castaneda",
                    "CODIGO USUARIO": "suar112447",
                    "USUARIOFIVE9": "suar112447@siigo.com",
                    "USUARIO RED": "suar112447",
                    "CORREOELECTRONICO": "suar112447@siigo.com",
                    "STATION ID": "2606626",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Magda de Jesus Becerra Castaneda",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015480515",
                    "FECHA INICIAL": "2019-08-05",
                    "FECHA OPERACION": "2019-08-05",
                    "NOMBRES": "Silvia Vanessa",
                    "APELLIDOS": "Medina Mendez",
                    "NOMBRES COMPLETOS": "Silvia Vanessa Medina Mendez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Edwin Alfredo Guayacan Cardenal",
                    "CODIGO USUARIO": "medi112257",
                    "USUARIOFIVE9": "medi112257@siigo.com",
                    "USUARIO RED": "medi112257",
                    "CORREOELECTRONICO": "medi112257@siigo.com",
                    "STATION ID": "2606731",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Edwin Alfredo Guayacan Cardenal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1233499800",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-03-02",
                    "NOMBRES": "Tania Carolina",
                    "APELLIDOS": "Pinzon Granados",
                    "NOMBRES COMPLETOS": "Tania Carolina Pinzon Granados",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "pinz800363",
                    "USUARIOFIVE9": "pinz800363@siigo.com",
                    "USUARIO RED": "pinz800363",
                    "CORREOELECTRONICO": "pinz800363@siigo.com",
                    "STATION ID": "2605954",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53094353",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-03-02",
                    "NOMBRES": "Viviana Angelica",
                    "APELLIDOS": "Pardo Giraldo",
                    "NOMBRES COMPLETOS": "Viviana Angelica Pardo Giraldo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "UCI Fidelizacion",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Nestor Oswaldo Becerra Prieto",
                    "CODIGO USUARIO": "pard800379",
                    "USUARIOFIVE9": "pard800379@siigo.com",
                    "USUARIO RED": "pard800379",
                    "CORREOELECTRONICO": "pard800379@siigo.com",
                    "STATION ID": "2605956",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Nestor Oswaldo Becerra Prieto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000330337",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-03-02",
                    "NOMBRES": "Yessenya Sabad",
                    "APELLIDOS": "Quesada",
                    "NOMBRES COMPLETOS": "Yessenya Sabad Quesada",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "saba800362",
                    "USUARIOFIVE9": "saba800362@siigo.com",
                    "USUARIO RED": "saba800362",
                    "CORREOELECTRONICO": "saba800362@siigo.com",
                    "STATION ID": "2605932",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010237720",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-03-02",
                    "NOMBRES": "Yessica Paola",
                    "APELLIDOS": "Uribe Castano",
                    "NOMBRES COMPLETOS": "Yessica Paola Uribe Castano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "UCI Fidelizacion",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Nestor Oswaldo Becerra Prieto",
                    "CODIGO USUARIO": "urib800346",
                    "USUARIOFIVE9": "urib800346@siigo.com",
                    "USUARIO RED": "urib800346",
                    "CORREOELECTRONICO": "urib800346@siigo.com",
                    "STATION ID": "2605935",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Nestor Oswaldo Becerra Prieto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022345487",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-03-02",
                    "NOMBRES": "Zahory Joulin",
                    "APELLIDOS": "Sanchez Zambrano",
                    "NOMBRES COMPLETOS": "Zahory Joulin Sanchez Zambrano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoracion",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "sanc800370",
                    "USUARIOFIVE9": "sanc800370@siigo.com",
                    "USUARIO RED": "sanc800370",
                    "CORREOELECTRONICO": "sanc800370@siigo.com",
                    "STATION ID": "2605944",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032488507",
                    "FECHA INICIAL": "2019-07-24",
                    "FECHA OPERACION": "2019-07-24",
                    "NOMBRES": "Wendy Julieth",
                    "APELLIDOS": "Moreno Barrero",
                    "NOMBRES COMPLETOS": "Wendy Julieth Moreno Barrero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Mercadeo",
                    "CARGO": "Analista Mercadeo",
                    "JEFE INMEDIATO": "Giovanny Andres Perez Lopez",
                    "CODIGO USUARIO": "more112201",
                    "USUARIOFIVE9": "more112201@siigo.com",
                    "USUARIO RED": "more112201",
                    "CORREOELECTRONICO": "more112201@siigo.com",
                    "STATION ID": "2606571",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Giovanny Andres Perez Lopez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1099364910",
                    "FECHA INICIAL": "2019-05-29",
                    "FECHA OPERACION": "2020-06-23",
                    "NOMBRES": "Natalia",
                    "APELLIDOS": "Herrera Rey",
                    "NOMBRES COMPLETOS": "Natalia Herrera Rey",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Lider Desarrollo",
                    "JEFE INMEDIATO": "Felipe Ricardo Reyes Lega",
                    "CODIGO USUARIO": "herr112078",
                    "USUARIOFIVE9": "herr112078@siigo.com",
                    "USUARIO RED": "herr112078",
                    "CORREOELECTRONICO": "herr112078@siigo.com",
                    "STATION ID": "2605974",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Felipe Ricardo Reyes Lega",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000592601",
                    "FECHA INICIAL": "2020-07-06",
                    "FECHA OPERACION": "2020-06-01",
                    "NOMBRES": "Caren Yuliana",
                    "APELLIDOS": "Garcia Angulo",
                    "NOMBRES COMPLETOS": "Caren Yuliana Garcia Angulo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Liliana Ramirez Vanegas",
                    "CODIGO USUARIO": "garc800455",
                    "USUARIOFIVE9": "garc800455@siigo.com",
                    "USUARIO RED": "garc800455",
                    "CORREOELECTRONICO": "garc800455@siigo.com",
                    "STATION ID": "2606008",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Liliana Ramirez Vanegas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52355874",
                    "FECHA INICIAL": "2020-07-14",
                    "FECHA OPERACION": "2020-07-15",
                    "NOMBRES": "Lyda Patricia",
                    "APELLIDOS": "Reyes Pradilla",
                    "NOMBRES COMPLETOS": "Lyda Patricia Reyes Pradilla",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Otros",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Carlos Arturo Huertas Salgado",
                    "CODIGO USUARIO": "reye800462",
                    "USUARIOFIVE9": "reye800462@siigo.com",
                    "USUARIO RED": "reye800462",
                    "CORREOELECTRONICO": "reye800462@siigo.com",
                    "STATION ID": "2606018",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Carlos Arturo Huertas Salgado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013640890",
                    "FECHA INICIAL": "2020-07-14",
                    "FECHA OPERACION": "2020-07-21",
                    "NOMBRES": "Andrea Tatiana",
                    "APELLIDOS": "Ruiz Moncada",
                    "NOMBRES COMPLETOS": "Andrea Tatiana Ruiz Moncada",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Fundacion",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "ruiz800470",
                    "USUARIOFIVE9": "ruiz800470@siigo.com",
                    "USUARIO RED": "ruiz800470",
                    "CORREOELECTRONICO": "ruiz800470@siigo.com",
                    "STATION ID": "2606031",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014256473",
                    "FECHA INICIAL": "2020-10-08",
                    "FECHA OPERACION": "2020-10-08",
                    "NOMBRES": "Brenda Johanna",
                    "APELLIDOS": "Varon Lombana",
                    "NOMBRES COMPLETOS": "Brenda Johanna Varon Lombana",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "varo800795",
                    "USUARIOFIVE9": "varo800795@siigo.com",
                    "USUARIO RED": "varo800795",
                    "CORREOELECTRONICO": "varo800795@siigo.com",
                    "STATION ID": "2606152",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "57463902",
                    "FECHA INICIAL": "2020-07-28",
                    "FECHA OPERACION": "2020-07-28",
                    "NOMBRES": "Nathalia Carolina",
                    "APELLIDOS": "Ariza Fernandez",
                    "NOMBRES COMPLETOS": "Nathalia Carolina Ariza Fernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Patricia Villarraga Forero",
                    "CODIGO USUARIO": "ariz800476",
                    "USUARIOFIVE9": "ariz800476@siigo.com",
                    "USUARIO RED": "ariz800476",
                    "CORREOELECTRONICO": "ariz800476@siigo.com",
                    "STATION ID": "2606034",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Patricia Villarraga Forero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1052416714",
                    "FECHA INICIAL": "2020-02-10",
                    "FECHA OPERACION": "2020-02-10",
                    "NOMBRES": "Julian David",
                    "APELLIDOS": "Martinez Lara",
                    "NOMBRES COMPLETOS": "Julian David Martinez Lara",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Jimena Marcela Prieto Bohorquez",
                    "CODIGO USUARIO": "mart800260",
                    "USUARIOFIVE9": "mart800260@siigo.com",
                    "USUARIO RED": "mart800260",
                    "CORREOELECTRONICO": "mart800260@siigo.com",
                    "STATION ID": "2607067",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jimena Marcela Prieto Bohorquez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1107090488",
                    "FECHA INICIAL": "2020-07-28",
                    "FECHA OPERACION": "2020-07-28",
                    "NOMBRES": "Lizlley Andrea",
                    "APELLIDOS": "Gallego Orrego",
                    "NOMBRES COMPLETOS": "Lizlley Andrea Gallego Orrego",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Mission Controller",
                    "JEFE INMEDIATO": "Harrison Martinez Callejas",
                    "CODIGO USUARIO": "gall800486",
                    "USUARIOFIVE9": "gall800486@siigo.com",
                    "USUARIO RED": "gall800486",
                    "CORREOELECTRONICO": "gall800486@siigo.com",
                    "STATION ID": "2606047",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Harrison Martinez Callejas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1111202735",
                    "FECHA INICIAL": "2021-06-11",
                    "FECHA OPERACION": "2021-06-11",
                    "NOMBRES": "Cristian David",
                    "APELLIDOS": "Diaz Giraldo",
                    "NOMBRES COMPLETOS": "Cristian David Diaz Giraldo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adrian Alberto Salom Berrio",
                    "CODIGO USUARIO": "diaz801504",
                    "USUARIOFIVE9": "diaz801504@siigo.com",
                    "USUARIO RED": "diaz801504",
                    "CORREOELECTRONICO": "diaz801504@siigo.com",
                    "STATION ID": "2606748",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adrian Alberto Salom Berrio",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030566568",
                    "FECHA INICIAL": "2020-08-03",
                    "FECHA OPERACION": "2020-08-03",
                    "NOMBRES": "Nataly",
                    "APELLIDOS": "Rubiano Murcia",
                    "NOMBRES COMPLETOS": "Nataly Rubiano Murcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jesus Daniel Arzuza Roman",
                    "CODIGO USUARIO": "rubi800497",
                    "USUARIOFIVE9": "rubi800497@siigo.com",
                    "USUARIO RED": "rubi800497",
                    "CORREOELECTRONICO": "rubi800497@siigo.com",
                    "STATION ID": "2606021",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jesus Daniel Arzuza Roman",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1005825216",
                    "FECHA INICIAL": "2020-11-18",
                    "FECHA OPERACION": "2020-11-19",
                    "NOMBRES": "Laura Alejandra",
                    "APELLIDOS": "Matta Villareal",
                    "NOMBRES COMPLETOS": "Laura Alejandra Matta Villareal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Francy Julieth Viracacha Gutierrez",
                    "CODIGO USUARIO": "matt800847",
                    "USUARIOFIVE9": "matt800847@siigo.com",
                    "USUARIO RED": "matt800847",
                    "CORREOELECTRONICO": "matt800847@siigo.com",
                    "STATION ID": "2606203",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Francy Julieth Viracacha Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1071165167",
                    "FECHA INICIAL": "2021-01-04",
                    "FECHA OPERACION": "2021-01-04",
                    "NOMBRES": "Fabian Alfonso",
                    "APELLIDOS": "Munoz Moreno",
                    "NOMBRES COMPLETOS": "Fabian Alfonso Munoz Moreno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Cristian Camilo Hernandez Gonzalez",
                    "CODIGO USUARIO": "muno800991",
                    "USUARIOFIVE9": "muno800991@siigo.com",
                    "USUARIO RED": "muno800991",
                    "CORREOELECTRONICO": "muno800991@siigo.com",
                    "STATION ID": "2606269",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Cristian Camilo Hernandez Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098662416",
                    "FECHA INICIAL": "2021-01-04",
                    "FECHA OPERACION": "2021-01-04",
                    "NOMBRES": "Lida Katerine",
                    "APELLIDOS": "Puentes Navarro",
                    "NOMBRES COMPLETOS": "Lida Katerine Puentes Navarro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Lider Renovaciones",
                    "JEFE INMEDIATO": "John Alexander Benavides Cobos",
                    "CODIGO USUARIO": "puen801008",
                    "USUARIOFIVE9": "puen801008@siigo.com",
                    "USUARIO RED": "puen801008",
                    "CORREOELECTRONICO": "puen801008@siigo.com",
                    "STATION ID": "2606300",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_John Alexander Benavides Cobos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012364037",
                    "FECHA INICIAL": "2021-05-04",
                    "FECHA OPERACION": "2021-05-04",
                    "NOMBRES": "Sindy Alejandra",
                    "APELLIDOS": "Rodriguez Giraldo",
                    "NOMBRES COMPLETOS": "Sindy Alejandra Rodriguez Giraldo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Eliana Maria Fajardo Prada",
                    "CODIGO USUARIO": "rodr801381",
                    "USUARIOFIVE9": "rodr801381@siigo.com",
                    "USUARIO RED": "rodr801381",
                    "CORREOELECTRONICO": "rodr801381@siigo.com",
                    "STATION ID": "2607047",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Eliana Maria Fajardo Prada",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018402208",
                    "FECHA INICIAL": "2020-08-19",
                    "FECHA OPERACION": "2020-08-18",
                    "NOMBRES": "Hernando Augusto",
                    "APELLIDOS": "Perez Zabala",
                    "NOMBRES COMPLETOS": "Hernando Augusto Perez Zabala",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "QA",
                    "JEFE INMEDIATO": "Anyela Marcela Rivera Mera",
                    "CODIGO USUARIO": "pere800531",
                    "USUARIOFIVE9": "pere800531@siigo.com",
                    "USUARIO RED": "pere800531",
                    "CORREOELECTRONICO": "pere800531@siigo.com",
                    "STATION ID": "2606081",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Anyela Marcela Rivera Mera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023972304",
                    "FECHA INICIAL": "2020-08-19",
                    "FECHA OPERACION": "2020-08-19",
                    "NOMBRES": "Jenny Julieth",
                    "APELLIDOS": "Murillo Palacios",
                    "NOMBRES COMPLETOS": "Jenny Julieth Murillo Palacios",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Cristian Camilo Hernandez Gonzalez",
                    "CODIGO USUARIO": "muri800527",
                    "USUARIOFIVE9": "muri800527@siigo.com",
                    "USUARIO RED": "muri800527",
                    "CORREOELECTRONICO": "muri800527@siigo.com",
                    "STATION ID": "2606083",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Cristian Camilo Hernandez Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019148684",
                    "FECHA INICIAL": "2020-08-19",
                    "FECHA OPERACION": "2020-08-19",
                    "NOMBRES": "Angie Nicoll",
                    "APELLIDOS": "Torres Celemin",
                    "NOMBRES COMPLETOS": "Angie Nicoll Torres Celemin",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Juan Pablo Henao Munoz",
                    "CODIGO USUARIO": "torr800523",
                    "USUARIOFIVE9": "torr800523@siigo.com",
                    "USUARIO RED": "torr800523",
                    "CORREOELECTRONICO": "torr800523@siigo.com",
                    "STATION ID": "2606086",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Henao Munoz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1031179317",
                    "FECHA INICIAL": "2020-08-19",
                    "FECHA OPERACION": "2020-08-19",
                    "NOMBRES": "Yennifer Nicoll",
                    "APELLIDOS": "Avila Infante",
                    "NOMBRES COMPLETOS": "Yennifer Nicoll Avila Infante",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angy Paola Umbacia Diaz",
                    "CODIGO USUARIO": "avil800524",
                    "USUARIOFIVE9": "avil800524@siigo.com",
                    "USUARIO RED": "avil800524",
                    "CORREOELECTRONICO": "avil800524@siigo.com",
                    "STATION ID": "2606087",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angy Paola Umbacia Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012358258",
                    "FECHA INICIAL": "2021-09-01",
                    "FECHA OPERACION": "2021-09-01",
                    "NOMBRES": "Ana Constanza",
                    "APELLIDOS": "Lopez Reina",
                    "NOMBRES COMPLETOS": "Ana Constanza Lopez Reina",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luz Katerine Maya Mogollon",
                    "CODIGO USUARIO": "lope801791",
                    "USUARIOFIVE9": "lope801791@siigo.com",
                    "USUARIO RED": "lope801791",
                    "CORREOELECTRONICO": "lope801791@siigo.com",
                    "STATION ID": "3267831",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Katerine Maya Mogollon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1059986377",
                    "FECHA INICIAL": "2020-08-19",
                    "FECHA OPERACION": "2020-08-19",
                    "NOMBRES": "Monica Yulieth",
                    "APELLIDOS": "Navia Rincon",
                    "NOMBRES COMPLETOS": "Monica Yulieth Navia Rincon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Jairo Enrique Corzo Lizarazo",
                    "CODIGO USUARIO": "rinc800520",
                    "USUARIOFIVE9": "rinc800520@siigo.com",
                    "USUARIO RED": "rinc800520",
                    "CORREOELECTRONICO": "rinc800520@siigo.com",
                    "STATION ID": "2606090",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Jairo Enrique Corzo Lizarazo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143933814",
                    "FECHA INICIAL": "2020-08-19",
                    "FECHA OPERACION": "2020-08-19",
                    "NOMBRES": "Katherine Lisseth",
                    "APELLIDOS": "Mera Ramos",
                    "NOMBRES COMPLETOS": "Katherine Lisseth Mera Ramos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Cristian Camilo Hernandez Gonzalez",
                    "CODIGO USUARIO": "mera800521",
                    "USUARIOFIVE9": "mera800521@siigo.com",
                    "USUARIO RED": "mera800521",
                    "CORREOELECTRONICO": "mera800521@siigo.com",
                    "STATION ID": "2606091",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Cristian Camilo Hernandez Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013657349",
                    "FECHA INICIAL": "2020-08-19",
                    "FECHA OPERACION": "2020-08-19",
                    "NOMBRES": "Sara Natalia",
                    "APELLIDOS": "Rondon Martinez",
                    "NOMBRES COMPLETOS": "Sara Natalia Rondon Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alix Dayana Archila Castillo",
                    "CODIGO USUARIO": "rond800517",
                    "USUARIOFIVE9": "rond800517@siigo.com",
                    "USUARIO RED": "rond800517",
                    "CORREOELECTRONICO": "rond800517@siigo.com",
                    "STATION ID": "2606094",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alix Dayana Archila Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024560724",
                    "FECHA INICIAL": "2021-01-19",
                    "FECHA OPERACION": "2021-01-21",
                    "NOMBRES": "Angie Marcela",
                    "APELLIDOS": "Gutierrez Moreno",
                    "NOMBRES COMPLETOS": "Angie Marcela Gutierrez Moreno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Colombia",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Olga Lucia Ospina Vidal",
                    "CODIGO USUARIO": "guti801030",
                    "USUARIOFIVE9": "guti801030@siigo.com",
                    "USUARIO RED": "guti801030",
                    "CORREOELECTRONICO": "guti801030@siigo.com",
                    "STATION ID": "2606315",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Olga Lucia Ospina Vidal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000832473",
                    "FECHA INICIAL": "2020-08-19",
                    "FECHA OPERACION": "2020-08-19",
                    "NOMBRES": "Jefrey Julian",
                    "APELLIDOS": "Sanchez Celemin",
                    "NOMBRES COMPLETOS": "Jefrey Julian Sanchez Celemin",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Juan Pablo Henao Munoz",
                    "CODIGO USUARIO": "sanc800514",
                    "USUARIOFIVE9": "sanc800514@siigo.com",
                    "USUARIO RED": "sanc800514",
                    "CORREOELECTRONICO": "sanc800514@siigo.com",
                    "STATION ID": "2606097",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Henao Munoz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033801475",
                    "FECHA INICIAL": "2020-12-07",
                    "FECHA OPERACION": "2020-12-07",
                    "NOMBRES": "Chris Samantha",
                    "APELLIDOS": "Jimenez Cubillos",
                    "NOMBRES COMPLETOS": "Chris Samantha Jimenez Cubillos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "jime800931",
                    "USUARIOFIVE9": "jime800931@siigo.com",
                    "USUARIO RED": "jime800931",
                    "CORREOELECTRONICO": "jime800931@siigo.com",
                    "STATION ID": "2606322",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "37535402",
                    "FECHA INICIAL": "2020-09-01",
                    "FECHA OPERACION": "2020-09-01",
                    "NOMBRES": "Olga Lucia",
                    "APELLIDOS": "Salgado Salamanca",
                    "NOMBRES COMPLETOS": "Olga Lucia Salgado Salamanca",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Vicky Alejandra Hernandez Mendez",
                    "CODIGO USUARIO": "salg800539",
                    "USUARIOFIVE9": "salg800539@siigo.com",
                    "USUARIO RED": "salg800539",
                    "CORREOELECTRONICO": "salg800539@siigo.com",
                    "STATION ID": "2606108",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Vicky Alejandra Hernandez Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012380168",
                    "FECHA INICIAL": "2020-09-01",
                    "FECHA OPERACION": "2020-09-01",
                    "NOMBRES": "Claudy Lorena",
                    "APELLIDOS": "Lopez Carmona",
                    "NOMBRES COMPLETOS": "Claudy Lorena Lopez Carmona",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "David Alfredo Sanchez Bastidas",
                    "CODIGO USUARIO": "lope800576",
                    "USUARIOFIVE9": "lope800576@siigo.com",
                    "USUARIO RED": "lope800576",
                    "CORREOELECTRONICO": "lope800576@siigo.com",
                    "STATION ID": "2606112",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_David Alfredo Sanchez Bastidas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000604559",
                    "FECHA INICIAL": "2020-09-07",
                    "FECHA OPERACION": "2020-09-07",
                    "NOMBRES": "Juan Diego",
                    "APELLIDOS": "Huezo Galindo",
                    "NOMBRES COMPLETOS": "Juan Diego Huezo Galindo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Liliana Ramirez Vanegas",
                    "CODIGO USUARIO": "huez800669",
                    "USUARIOFIVE9": "huez800669@siigo.com",
                    "USUARIO RED": "huez800669",
                    "CORREOELECTRONICO": "huez800669@siigo.com",
                    "STATION ID": "2606118",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Liliana Ramirez Vanegas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012461206",
                    "FECHA INICIAL": "2020-09-07",
                    "FECHA OPERACION": "2020-09-07",
                    "NOMBRES": "Luz Adriana",
                    "APELLIDOS": "Palacios Rodriguez",
                    "NOMBRES COMPLETOS": "Luz Adriana Palacios Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoraciones",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Sandra Catalina Suarez Castillo",
                    "CODIGO USUARIO": "pala800672",
                    "USUARIOFIVE9": "pala800672@siigo.com",
                    "USUARIO RED": "pala800672",
                    "CORREOELECTRONICO": "pala800672@siigo.com",
                    "STATION ID": "2606260",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Sandra Catalina Suarez Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1020810688",
                    "FECHA INICIAL": "2021-05-05",
                    "FECHA OPERACION": "2021-05-05",
                    "NOMBRES": "Angie Natalia",
                    "APELLIDOS": "Puentes Puentes",
                    "NOMBRES COMPLETOS": "Angie Natalia Puentes Puentes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoraciones",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Sandra Catalina Suarez Castillo",
                    "CODIGO USUARIO": "puen801396",
                    "USUARIOFIVE9": "puen801396@siigo.com",
                    "USUARIO RED": "puen801396",
                    "CORREOELECTRONICO": "puen801396@siigo.com",
                    "STATION ID": "2607065",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Sandra Catalina Suarez Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1069305921",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-03-02",
                    "NOMBRES": "Norma Constanza",
                    "APELLIDOS": "Cajica Rodriguez",
                    "NOMBRES COMPLETOS": "Norma Constanza Cajica Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Elizabeth Giron Parra",
                    "CODIGO USUARIO": "caji800378",
                    "USUARIOFIVE9": "caji800378@siigo.com",
                    "USUARIO RED": "caji800378",
                    "CORREOELECTRONICO": "caji800378@siigo.com",
                    "STATION ID": "2605953",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Elizabeth Giron Parra",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "7700070311019",
                    "FECHA INICIAL": "2021-05-06",
                    "FECHA OPERACION": "2021-05-06",
                    "NOMBRES": "Isledia Josefina",
                    "APELLIDOS": "Bolivar",
                    "NOMBRES COMPLETOS": "Isledia Josefina Bolivar",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "boli801400",
                    "USUARIOFIVE9": "boli801400@siigo.com",
                    "USUARIO RED": "boli801400",
                    "CORREOELECTRONICO": "boli801400@siigo.com",
                    "STATION ID": "3127487",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014306688",
                    "FECHA INICIAL": "2020-09-17",
                    "FECHA OPERACION": "2020-09-17",
                    "NOMBRES": "Derly Paola",
                    "APELLIDOS": "Laverde Castiblanco",
                    "NOMBRES COMPLETOS": "Derly Paola Laverde Castiblanco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Contabilidad",
                    "SUBAREA": "Contabilidad",
                    "CARGO": "Auxiliar Contable",
                    "JEFE INMEDIATO": "Luz Derly Bernal Malaver",
                    "CODIGO USUARIO": "lave800694",
                    "USUARIOFIVE9": "lave800694@siigo.com",
                    "USUARIO RED": "lave800694",
                    "CORREOELECTRONICO": "lave800694@siigo.com",
                    "STATION ID": "2606004",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Contabilidad_Luz Derly Bernal Malaver",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143165700",
                    "FECHA INICIAL": "2020-09-17",
                    "FECHA OPERACION": "2020-09-17",
                    "NOMBRES": "Javier Andres",
                    "APELLIDOS": "Moreno Escamilla",
                    "NOMBRES COMPLETOS": "Javier Andres Moreno Escamilla",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "more800693",
                    "USUARIOFIVE9": "more800693@siigo.com",
                    "USUARIO RED": "more800693",
                    "CORREOELECTRONICO": "more800693@siigo.com",
                    "STATION ID": "2606005",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024524725",
                    "FECHA INICIAL": "2021-01-04",
                    "FECHA OPERACION": "2021-01-04",
                    "NOMBRES": "Diana Katherin",
                    "APELLIDOS": "Segura Leguizamon",
                    "NOMBRES COMPLETOS": "Diana Katherin Segura Leguizamon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Carolina Linares Gonzalez",
                    "CODIGO USUARIO": "segu801006",
                    "USUARIOFIVE9": "segu801006@siigo.com",
                    "USUARIO RED": "segu801006",
                    "CORREOELECTRONICO": "segu801006@siigo.com",
                    "STATION ID": "2606284",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Carolina Linares Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143166398",
                    "FECHA INICIAL": "2019-11-05",
                    "FECHA OPERACION": "2019-11-05",
                    "NOMBRES": "Laura Vanessa",
                    "APELLIDOS": "Velasquez Olivar",
                    "NOMBRES COMPLETOS": "Laura Vanessa Velasquez Olivar",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoracion",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Alma Rosa Romero Garcia",
                    "CODIGO USUARIO": "vela112490",
                    "USUARIOFIVE9": "vela112490@siigo.com",
                    "USUARIO RED": "vela112490",
                    "CORREOELECTRONICO": "vela112490@siigo.com",
                    "STATION ID": "2606755",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Alma Rosa Romero Garcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033717583",
                    "FECHA INICIAL": "2020-09-17",
                    "FECHA OPERACION": "2020-09-17",
                    "NOMBRES": "Wilson Javier",
                    "APELLIDOS": "Villamil Benitez",
                    "NOMBRES COMPLETOS": "Wilson Javier Villamil Benitez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Roberto Alvarado Mancera",
                    "CODIGO USUARIO": "villa80068",
                    "USUARIOFIVE9": "villa800686@siigo.com",
                    "USUARIO RED": "villa80068",
                    "CORREOELECTRONICO": "villa800686@siigo.com",
                    "STATION ID": "2606023",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Roberto Alvarado Mancera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014271756",
                    "FECHA INICIAL": "2020-09-17",
                    "FECHA OPERACION": "2020-09-17",
                    "NOMBRES": "Samuel Antonio",
                    "APELLIDOS": "Sanchez Jimenez",
                    "NOMBRES COMPLETOS": "Samuel Antonio Sanchez Jimenez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoraciones",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "sanc800685",
                    "USUARIOFIVE9": "sanc800685@siigo.com",
                    "USUARIO RED": "sanc800685",
                    "CORREOELECTRONICO": "sanc800685@siigo.com",
                    "STATION ID": "2606024",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000226596",
                    "FECHA INICIAL": "2021-08-17",
                    "FECHA OPERACION": "2021-08-18",
                    "NOMBRES": "Karen Dayana",
                    "APELLIDOS": "Poveda Garcia",
                    "NOMBRES COMPLETOS": "Karen Dayana Poveda Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "UCI",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Nestor Oswaldo Becerra Prieto",
                    "CODIGO USUARIO": "pove801688",
                    "USUARIOFIVE9": "pove801688@siigo.com",
                    "USUARIO RED": "pove801688",
                    "CORREOELECTRONICO": "pove801688@siigo.com",
                    "STATION ID": "3127558",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Nestor Oswaldo Becerra Prieto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007794117",
                    "FECHA INICIAL": "2020-09-21",
                    "FECHA OPERACION": "2020-09-21",
                    "NOMBRES": "Braidy Alejandra",
                    "APELLIDOS": "Rodriguez Pena",
                    "NOMBRES COMPLETOS": "Braidy Alejandra Rodriguez Pena",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ventas",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "rodr800700",
                    "USUARIOFIVE9": "rodr800700@siigo.com",
                    "USUARIO RED": "rodr800700",
                    "CORREOELECTRONICO": "rodr800700@siigo.com",
                    "STATION ID": "2606060",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1716849979",
                    "FECHA INICIAL": "2020-10-01",
                    "FECHA OPERACION": "2020-10-01",
                    "NOMBRES": "Johan Dario",
                    "APELLIDOS": "Felix Altamirano",
                    "NOMBRES COMPLETOS": "Johan Dario Felix Altamirano",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Angel Alfonso Arciniegas Guerrero",
                    "CODIGO USUARIO": "feli800736",
                    "USUARIOFIVE9": "feli800736@siigo.com",
                    "USUARIO RED": "feli800736",
                    "CORREOELECTRONICO": "feli800736@siigo.com",
                    "STATION ID": "2606050",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Angel Alfonso Arciniegas Guerrero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1708983778",
                    "FECHA INICIAL": "2020-10-01",
                    "FECHA OPERACION": "2020-10-01",
                    "NOMBRES": "Jaime Fernando",
                    "APELLIDOS": "Leon Morales",
                    "NOMBRES COMPLETOS": "Jaime Fernando Leon Morales",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Angel Alfonso Arciniegas Guerrero",
                    "CODIGO USUARIO": "leon800732",
                    "USUARIOFIVE9": "leon800732@siigo.com",
                    "USUARIO RED": "leon800732",
                    "CORREOELECTRONICO": "leon800732@siigo.com",
                    "STATION ID": "2606062",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Angel Alfonso Arciniegas Guerrero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "931208904",
                    "FECHA INICIAL": "2019-11-11",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Daniela Noemi",
                    "APELLIDOS": "Villamar Cabrera",
                    "NOMBRES COMPLETOS": "Daniela Noemi Villamar Cabrera",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoracion",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "vill800568",
                    "USUARIOFIVE9": "vill800568@siigo.com",
                    "USUARIO RED": "vill800568",
                    "CORREOELECTRONICO": "vill800568@siigo.com",
                    "STATION ID": "2606240",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "707050498",
                    "FECHA INICIAL": "2020-07-13",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "David",
                    "APELLIDOS": "Morocho Guarnizo",
                    "NOMBRES COMPLETOS": "David Morocho Guarnizo",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "moro800570",
                    "USUARIOFIVE9": "moro800570@siigo.com",
                    "USUARIO RED": "moro800570",
                    "CORREOELECTRONICO": "moro800570@siigo.com",
                    "STATION ID": "2606215",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010228596",
                    "FECHA INICIAL": "2020-10-01",
                    "FECHA OPERACION": "2020-10-01",
                    "NOMBRES": "Anthony Jordan",
                    "APELLIDOS": "Ahumada Duarte",
                    "NOMBRES COMPLETOS": "Anthony Jordan Ahumada Duarte",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Luis Ernesto Parrado Ortiz",
                    "CODIGO USUARIO": "ahum800752",
                    "USUARIOFIVE9": "ahum800752@siigo.com",
                    "USUARIO RED": "ahum800752",
                    "CORREOELECTRONICO": "ahum800752@siigo.com",
                    "STATION ID": "2606070",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Luis Ernesto Parrado Ortiz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012443866",
                    "FECHA INICIAL": "2020-10-01",
                    "FECHA OPERACION": "2020-10-01",
                    "NOMBRES": "Diego Andres",
                    "APELLIDOS": "Mendez Beltran",
                    "NOMBRES COMPLETOS": "Diego Andres Mendez Beltran",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Estrategia",
                    "SUBAREA": "Data Analytics",
                    "CARGO": "Data Analist",
                    "JEFE INMEDIATO": "Fabian David Carreno Leon",
                    "CODIGO USUARIO": "mend800753",
                    "USUARIOFIVE9": "mend800753@siigo.com",
                    "USUARIO RED": "mend800753",
                    "CORREOELECTRONICO": "mend800753@siigo.com",
                    "STATION ID": "2606071",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Estrategia_Fabian David Carreno Leon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000163801",
                    "FECHA INICIAL": "2021-03-10",
                    "FECHA OPERACION": "2021-03-12",
                    "NOMBRES": "Ana Sofia",
                    "APELLIDOS": "Dani Vargas",
                    "NOMBRES COMPLETOS": "Ana Sofia Dani Vargas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Operativo Customer",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "dani801210",
                    "USUARIOFIVE9": "dani801210@siigo.com",
                    "USUARIO RED": "dani801210",
                    "CORREOELECTRONICO": "dani801210@siigo.com",
                    "STATION ID": "2606302",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "952648384",
                    "FECHA INICIAL": "2022-01-12",
                    "FECHA OPERACION": "2022-01-17",
                    "NOMBRES": "Angie Rosa",
                    "APELLIDOS": "Mera Ruiz",
                    "NOMBRES COMPLETOS": "Angie Rosa Mera Ruiz",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Firma Electronica",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "mera802584",
                    "USUARIOFIVE9": "mera802584@siigo.com",
                    "USUARIO RED": "mera802584",
                    "CORREOELECTRONICO": "mera802584@siigo.com",
                    "STATION ID": "2606592",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019003126",
                    "FECHA INICIAL": "2020-10-01",
                    "FECHA OPERACION": "2020-10-01",
                    "NOMBRES": "Jhon Edison",
                    "APELLIDOS": "Martinez Lesmes",
                    "NOMBRES COMPLETOS": "Jhon Edison Martinez Lesmes",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jose Manuel Aguayo Escandon",
                    "CODIGO USUARIO": "mart800764",
                    "USUARIOFIVE9": "mart800764@siigo.com",
                    "USUARIO RED": "mart800764",
                    "CORREOELECTRONICO": "mart800764@siigo.com",
                    "STATION ID": "2606079",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jose Manuel Aguayo Escandon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033763651",
                    "FECHA INICIAL": "2020-09-17",
                    "FECHA OPERACION": "2020-09-17",
                    "NOMBRES": "Diana Carolina",
                    "APELLIDOS": "Linares Gonzalez",
                    "NOMBRES COMPLETOS": "Diana Carolina Linares Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Juan Pablo Henao Munoz",
                    "CODIGO USUARIO": "lina800683",
                    "USUARIOFIVE9": "lina800683@siigo.com",
                    "USUARIO RED": "lina800683",
                    "CORREOELECTRONICO": "lina800683@siigo.com",
                    "STATION ID": "2606029",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Henao Munoz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53072558",
                    "FECHA INICIAL": "2020-10-01",
                    "FECHA OPERACION": "2020-10-01",
                    "NOMBRES": "Luz Angela",
                    "APELLIDOS": "Camelo Virguez",
                    "NOMBRES COMPLETOS": "Luz Angela Camelo Virguez",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Mercedes Moncada Peraza",
                    "CODIGO USUARIO": "came800734",
                    "USUARIOFIVE9": "came800734@siigo.com",
                    "USUARIO RED": "came800734",
                    "CORREOELECTRONICO": "came800734@siigo.com",
                    "STATION ID": "2606101",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Mercedes Moncada Peraza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1073131256",
                    "FECHA INICIAL": "2020-10-01",
                    "FECHA OPERACION": "2020-10-01",
                    "NOMBRES": "Karen Xiomara",
                    "APELLIDOS": "Benitez Riveros",
                    "NOMBRES COMPLETOS": "Karen Xiomara Benitez Riveros",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jose Manuel Aguayo Escandon",
                    "CODIGO USUARIO": "beni800726",
                    "USUARIOFIVE9": "beni800726@siigo.com",
                    "USUARIO RED": "beni800726",
                    "CORREOELECTRONICO": "beni800726@siigo.com",
                    "STATION ID": "2606106",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jose Manuel Aguayo Escandon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "952179497",
                    "FECHA INICIAL": "2022-01-12",
                    "FECHA OPERACION": "2022-01-17",
                    "NOMBRES": "Valeria Nicole",
                    "APELLIDOS": "Jaramillo Ramirez",
                    "NOMBRES COMPLETOS": "Valeria Nicole Jaramillo Ramirez",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Firma Electronica",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "jara802586",
                    "USUARIOFIVE9": "jara802586@siigo.com",
                    "USUARIO RED": "jara802586",
                    "CORREOELECTRONICO": "jara802586@siigo.com",
                    "STATION ID": "2607126",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "931744254",
                    "FECHA INICIAL": "2020-09-24",
                    "FECHA OPERACION": "2020-10-02",
                    "NOMBRES": "Vladimir Ismael",
                    "APELLIDOS": "Flores Vera",
                    "NOMBRES COMPLETOS": "Vladimir Ismael Flores Vera",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Walter Antonio Munoz Antepara",
                    "CODIGO USUARIO": "flor800714",
                    "USUARIOFIVE9": "flor800714@siigo.com",
                    "USUARIO RED": "flor800714",
                    "CORREOELECTRONICO": "flor800714@siigo.com",
                    "STATION ID": "2606116",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Walter Antonio Munoz Antepara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "952484590",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-03-09",
                    "NOMBRES": "Edison Isaias",
                    "APELLIDOS": "Reinoso Medina",
                    "NOMBRES COMPLETOS": "Edison Isaias Reinoso Medina",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Jesus Amado Gaibor Yanez",
                    "CODIGO USUARIO": "rein801206",
                    "USUARIOFIVE9": "rein801206@siigo.com",
                    "USUARIO RED": "rein801206",
                    "CORREOELECTRONICO": "rein801206@siigo.com",
                    "STATION ID": "2606838",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Jesus Amado Gaibor Yanez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1206071449",
                    "FECHA INICIAL": "2020-09-24",
                    "FECHA OPERACION": "2020-10-02",
                    "NOMBRES": "Viviana Cecilia",
                    "APELLIDOS": "Apuntes Garcia",
                    "NOMBRES COMPLETOS": "Viviana Cecilia Apuntes Garcia",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Walter Antonio Munoz Antepara",
                    "CODIGO USUARIO": "apun800706",
                    "USUARIOFIVE9": "apun800706@siigo.com",
                    "USUARIO RED": "apun800706",
                    "CORREOELECTRONICO": "apun800706@siigo.com",
                    "STATION ID": "2606122",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Walter Antonio Munoz Antepara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "951385251",
                    "FECHA INICIAL": "2022-02-01",
                    "FECHA OPERACION": "2022-02-15",
                    "NOMBRES": "Pedro Andres",
                    "APELLIDOS": "Munoz Rojas",
                    "NOMBRES COMPLETOS": "Pedro Andres Munoz Rojas",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "muno802638",
                    "USUARIOFIVE9": "muno802638@siigo.com",
                    "USUARIO RED": "muno802638",
                    "CORREOELECTRONICO": "muno802638@siigo.com",
                    "STATION ID": "3720359",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "929017846",
                    "FECHA INICIAL": "2020-09-24",
                    "FECHA OPERACION": "2020-10-02",
                    "NOMBRES": "Kevin Eduardo",
                    "APELLIDOS": "Almeida Medrano",
                    "NOMBRES COMPLETOS": "Kevin Eduardo Almeida Medrano",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Walter Antonio Munoz Antepara",
                    "CODIGO USUARIO": "alme800723",
                    "USUARIOFIVE9": "alme800723@siigo.com",
                    "USUARIO RED": "alme800723",
                    "CORREOELECTRONICO": "alme800723@siigo.com",
                    "STATION ID": "2606127",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Walter Antonio Munoz Antepara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "919105197",
                    "FECHA INICIAL": "2020-09-24",
                    "FECHA OPERACION": "2020-10-02",
                    "NOMBRES": "Johany Jazmin",
                    "APELLIDOS": "Vernaza Vargas",
                    "NOMBRES COMPLETOS": "Johany Jazmin Vernaza Vargas",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Cucuta",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Michelle Matamoros Del Rosario",
                    "CODIGO USUARIO": "vern800707",
                    "USUARIOFIVE9": "vern800707@siigo.com",
                    "USUARIO RED": "vern800707",
                    "CORREOELECTRONICO": "vern800707@siigo.com",
                    "STATION ID": "2606130",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Michelle Matamoros Del Rosario",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52807495",
                    "FECHA INICIAL": "2020-10-01",
                    "FECHA OPERACION": "2020-10-02",
                    "NOMBRES": "Johana Catalina",
                    "APELLIDOS": "Pena",
                    "NOMBRES COMPLETOS": "Johana Catalina Pena",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jose Manuel Aguayo Escandon",
                    "CODIGO USUARIO": "pena800774",
                    "USUARIOFIVE9": "pena800774@siigo.com",
                    "USUARIO RED": "pena800774",
                    "CORREOELECTRONICO": "pena800774@siigo.com",
                    "STATION ID": "2606134",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jose Manuel Aguayo Escandon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030623949",
                    "FECHA INICIAL": "2020-10-01",
                    "FECHA OPERACION": "2020-10-02",
                    "NOMBRES": "Nataly",
                    "APELLIDOS": "Martinez Montilla",
                    "NOMBRES COMPLETOS": "Nataly Martinez Montilla",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jose Manuel Aguayo Escandon",
                    "CODIGO USUARIO": "mart800770",
                    "USUARIOFIVE9": "mart800770@siigo.com",
                    "USUARIO RED": "mart800770",
                    "CORREOELECTRONICO": "mart800770@siigo.com",
                    "STATION ID": "2606137",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jose Manuel Aguayo Escandon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "926536178",
                    "FECHA INICIAL": "2015-10-12",
                    "FECHA OPERACION": "2020-10-05",
                    "NOMBRES": "Diana Michelle",
                    "APELLIDOS": "Matamoros Del Rosario",
                    "NOMBRES COMPLETOS": "Diana Michelle Matamoros Del Rosario",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Ecuador",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Jose Manuel Aguayo Escandon",
                    "CODIGO USUARIO": "mata800571",
                    "USUARIOFIVE9": "mata800571@siigo.com",
                    "USUARIO RED": "mata800571",
                    "CORREOELECTRONICO": "mata800571@siigo.com",
                    "STATION ID": "2605996",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jose Manuel Aguayo Escandon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "914316633",
                    "FECHA INICIAL": "2019-12-02",
                    "FECHA OPERACION": "2020-10-05",
                    "NOMBRES": "Jose Manuel",
                    "APELLIDOS": "Aguayo Escandon",
                    "NOMBRES COMPLETOS": "Jose Manuel Aguayo Escandon",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Ecuador",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "John Jairo Mejia Acevedo",
                    "CODIGO USUARIO": "agua800617",
                    "USUARIOFIVE9": "agua800617@siigo.com",
                    "USUARIO RED": "agua800617",
                    "CORREOELECTRONICO": "agua800617@siigo.com",
                    "STATION ID": "2606113",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Jairo Mejia Acevedo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1083570775",
                    "FECHA INICIAL": "2021-09-10",
                    "FECHA OPERACION": "2021-09-10",
                    "NOMBRES": "Kiara Yarnelyne",
                    "APELLIDOS": "Guette Ramirez",
                    "NOMBRES COMPLETOS": "Kiara Yarnelyne Guette Ramirez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Santa Marta",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Fernando Sotto Cardozo",
                    "CODIGO USUARIO": "guet801843",
                    "USUARIOFIVE9": "guet801843@siigo.com",
                    "USUARIO RED": "guet801843",
                    "CORREOELECTRONICO": "guet801843@siigo.com",
                    "STATION ID": "3267919",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Fernando Sotto Cardozo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010029191",
                    "FECHA INICIAL": "2020-10-08",
                    "FECHA OPERACION": "2020-10-08",
                    "NOMBRES": "Viviana",
                    "APELLIDOS": "Casallas Lozano",
                    "NOMBRES COMPLETOS": "Viviana Casallas Lozano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Luz Andrea Sanchez Chacon",
                    "CODIGO USUARIO": "casa800790",
                    "USUARIOFIVE9": "casa800790@siigo.com",
                    "USUARIO RED": "casa800790",
                    "CORREOELECTRONICO": "casa800790@siigo.com",
                    "STATION ID": "2606146",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Luz Andrea Sanchez Chacon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014261449",
                    "FECHA INICIAL": "2020-10-08",
                    "FECHA OPERACION": "2020-10-08",
                    "NOMBRES": "Alix Dayana",
                    "APELLIDOS": "Archila Castillo",
                    "NOMBRES COMPLETOS": "Alix Dayana Archila Castillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Carlos Roberto Alvarado Mancera",
                    "CODIGO USUARIO": "arch800792",
                    "USUARIOFIVE9": "arch800792@siigo.com",
                    "USUARIO RED": "arch800792",
                    "CORREOELECTRONICO": "arch800792@siigo.com",
                    "STATION ID": "2606148",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Roberto Alvarado Mancera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016113990",
                    "FECHA INICIAL": "2020-10-08",
                    "FECHA OPERACION": "2020-10-08",
                    "NOMBRES": "Andres Felipe",
                    "APELLIDOS": "Medina Rincon",
                    "NOMBRES COMPLETOS": "Andres Felipe Medina Rincon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Cristian Camilo Hernandez Gonzalez",
                    "CODIGO USUARIO": "medi800793",
                    "USUARIOFIVE9": "medi800793@siigo.com",
                    "USUARIO RED": "medi800793",
                    "CORREOELECTRONICO": "medi800793@siigo.com",
                    "STATION ID": "2606150",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Cristian Camilo Hernandez Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1056076052",
                    "FECHA INICIAL": "2021-09-10",
                    "FECHA OPERACION": "2021-09-10",
                    "NOMBRES": "Paula Andrea",
                    "APELLIDOS": "Torres Fajardo",
                    "NOMBRES COMPLETOS": "Paula Andrea Torres Fajardo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Boyaca",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jefrey Julian Sanchez Celemin",
                    "CODIGO USUARIO": "torr801844",
                    "USUARIOFIVE9": "torr801844@siigo.com",
                    "USUARIO RED": "torr801844",
                    "CORREOELECTRONICO": "torr801844@siigo.com",
                    "STATION ID": "3470382",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jefrey Julian Sanchez Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1052996202",
                    "FECHA INICIAL": "2020-09-17",
                    "FECHA OPERACION": "2020-09-17",
                    "NOMBRES": "Elisa Maria",
                    "APELLIDOS": "Mendez Manjarres",
                    "NOMBRES COMPLETOS": "Elisa Maria Mendez Manjarres",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Carolina Linares Gonzalez",
                    "CODIGO USUARIO": "mend800692",
                    "USUARIOFIVE9": "mend800692@siigo.com",
                    "USUARIO RED": "mend800692",
                    "CORREOELECTRONICO": "mend800692@siigo.com",
                    "STATION ID": "2606007",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Carolina Linares Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "950524942",
                    "FECHA INICIAL": "2019-07-23",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Diana Yomira",
                    "APELLIDOS": "Mejia Herrera",
                    "NOMBRES COMPLETOS": "Diana Yomira Mejia Herrera",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoracion",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "meji800573",
                    "USUARIOFIVE9": "meji800573@siigo.com",
                    "USUARIO RED": "meji800573",
                    "CORREOELECTRONICO": "meji800573@siigo.com",
                    "STATION ID": "2606241",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "926783630",
                    "FECHA INICIAL": "2022-06-13",
                    "FECHA OPERACION": "2022-06-14",
                    "NOMBRES": "Narcisa Elizabeth",
                    "APELLIDOS": "Acebo Loor",
                    "NOMBRES COMPLETOS": "Narcisa Elizabeth Acebo Loor",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Firma Electronica",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "aceb803264",
                    "USUARIOFIVE9": "aceb803264@siigo.com",
                    "USUARIO RED": "aceb803264",
                    "CORREOELECTRONICO": "aceb803264@siigo.com",
                    "STATION ID": "4421825",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1031143842",
                    "FECHA INICIAL": "2020-10-08",
                    "FECHA OPERACION": "2020-10-08",
                    "NOMBRES": "Maria Fernanda",
                    "APELLIDOS": "Vera Soriano",
                    "NOMBRES COMPLETOS": "Maria Fernanda Vera Soriano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Diana Marcela Guzman Caamano",
                    "CODIGO USUARIO": "vera800800",
                    "USUARIOFIVE9": "vera800800@siigo.com",
                    "USUARIO RED": "vera800800",
                    "CORREOELECTRONICO": "vera800800@siigo.com",
                    "STATION ID": "2606157",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Diana Marcela Guzman Caamano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022391458",
                    "FECHA INICIAL": "2020-10-08",
                    "FECHA OPERACION": "2020-10-08",
                    "NOMBRES": "Erica Johana",
                    "APELLIDOS": "Moreno Perdomo",
                    "NOMBRES COMPLETOS": "Erica Johana Moreno Perdomo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "more800808",
                    "USUARIOFIVE9": "more800808@siigo.com",
                    "USUARIO RED": "more800808",
                    "CORREOELECTRONICO": "more800808@siigo.com",
                    "STATION ID": "2606165",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "954035002",
                    "FECHA INICIAL": "2020-07-22",
                    "FECHA OPERACION": "2020-10-13",
                    "NOMBRES": "Gabriela Solange",
                    "APELLIDOS": "Masacela Cabrera",
                    "NOMBRES COMPLETOS": "Gabriela Solange Masacela Cabrera",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones ECU",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Angie Camila Diaz Escobar",
                    "CODIGO USUARIO": "masa800583",
                    "USUARIOFIVE9": "masa800583@siigo.com",
                    "USUARIO RED": "masa800583",
                    "CORREOELECTRONICO": "masa800583@siigo.com",
                    "STATION ID": "2607033",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Camila Diaz Escobar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "931667737",
                    "FECHA INICIAL": "2016-05-30",
                    "FECHA OPERACION": "2020-10-05",
                    "NOMBRES": "Cinthya Veronica",
                    "APELLIDOS": "Salazar Arevalo",
                    "NOMBRES COMPLETOS": "Cinthya Veronica Salazar Arevalo",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "sala800565",
                    "USUARIOFIVE9": "sala800565@siigo.com",
                    "USUARIO RED": "sala800565",
                    "CORREOELECTRONICO": "sala800565@siigo.com",
                    "STATION ID": "2605941",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "951114404",
                    "FECHA INICIAL": "2020-02-18",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Ginger Jacqueline",
                    "APELLIDOS": "Sevilla Mendez",
                    "NOMBRES COMPLETOS": "Ginger Jacqueline Sevilla Mendez",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "UCI",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Nestor Oswaldo Becerra Prieto",
                    "CODIGO USUARIO": "sevi800590",
                    "USUARIOFIVE9": "sevi800590@siigo.com",
                    "USUARIO RED": "sevi800590",
                    "CORREOELECTRONICO": "sevi800590@siigo.com",
                    "STATION ID": "2606229",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Nestor Oswaldo Becerra Prieto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "927485078",
                    "FECHA INICIAL": "2017-11-21",
                    "FECHA OPERACION": "2020-10-14",
                    "NOMBRES": "Maria Lucia",
                    "APELLIDOS": "Canales Parrales",
                    "NOMBRES COMPLETOS": "Maria Lucia Canales Parrales",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Sandy Yurley Galeano Lara",
                    "CODIGO USUARIO": "cana800655",
                    "USUARIOFIVE9": "cana800655@siigo.com",
                    "USUARIO RED": "cana800655",
                    "CORREOELECTRONICO": "cana800655@siigo.com",
                    "STATION ID": "2606169",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Sandy Yurley Galeano Lara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018414980",
                    "FECHA INICIAL": "2020-10-13",
                    "FECHA OPERACION": "2020-10-16",
                    "NOMBRES": "Karen",
                    "APELLIDOS": "Perez Daza",
                    "NOMBRES COMPLETOS": "Karen Perez Daza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Eliana Maria Fajardo Prada",
                    "CODIGO USUARIO": "pere800812",
                    "USUARIOFIVE9": "pere800812@siigo.com",
                    "USUARIO RED": "pere800812",
                    "CORREOELECTRONICO": "pere800812@siigo.com",
                    "STATION ID": "2606136",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Eliana Maria Fajardo Prada",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000578760",
                    "FECHA INICIAL": "2020-10-19",
                    "FECHA OPERACION": "2020-10-22",
                    "NOMBRES": "Valerye Alejandra",
                    "APELLIDOS": "Velandia Reyes",
                    "NOMBRES COMPLETOS": "Valerye Alejandra Velandia Reyes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Yeimy Milena Bustos Gutierrez",
                    "CODIGO USUARIO": "vela800817",
                    "USUARIOFIVE9": "vela800817@siigo.com",
                    "USUARIO RED": "vela800817",
                    "CORREOELECTRONICO": "vela800817@siigo.com",
                    "STATION ID": "2606171",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yeimy Milena Bustos Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030546364",
                    "FECHA INICIAL": "2020-10-19",
                    "FECHA OPERACION": "2020-10-22",
                    "NOMBRES": "Adriana Patricia",
                    "APELLIDOS": "Becerra Bermejo",
                    "NOMBRES COMPLETOS": "Adriana Patricia Becerra Bermejo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Lida Katerine Puentes Navarro",
                    "CODIGO USUARIO": "bece800816",
                    "USUARIOFIVE9": "bece800816@siigo.com",
                    "USUARIO RED": "bece800816",
                    "CORREOELECTRONICO": "bece800816@siigo.com",
                    "STATION ID": "2606172",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Lida Katerine Puentes Navarro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1136885122",
                    "FECHA INICIAL": "2021-06-08",
                    "FECHA OPERACION": "2021-06-08",
                    "NOMBRES": "Rosa Camila",
                    "APELLIDOS": "Torres Galvis",
                    "NOMBRES COMPLETOS": "Rosa Camila Torres Galvis",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Johanna Patricia Torres Quintero",
                    "CODIGO USUARIO": "torr801483",
                    "USUARIOFIVE9": "torr801483@siigo.com",
                    "USUARIO RED": "torr801483",
                    "CORREOELECTRONICO": "torr801483@siigo.com",
                    "STATION ID": "2606085",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Johanna Patricia Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1049479",
                    "FECHA INICIAL": "2020-10-19",
                    "FECHA OPERACION": "2020-10-22",
                    "NOMBRES": "Yanine Auribel",
                    "APELLIDOS": "Brizuela Garcia",
                    "NOMBRES COMPLETOS": "Yanine Auribel Brizuela Garcia",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ecuador",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Edith Angelica Gil Umbarila",
                    "CODIGO USUARIO": "briz800814",
                    "USUARIOFIVE9": "briz800814@siigo.com",
                    "USUARIO RED": "briz800814",
                    "CORREOELECTRONICO": "briz800814@siigo.com",
                    "STATION ID": "2606174",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Edith Angelica Gil Umbarila",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1110543150",
                    "FECHA INICIAL": "2020-10-19",
                    "FECHA OPERACION": "2020-10-22",
                    "NOMBRES": "Manuela Alejandra",
                    "APELLIDOS": "Guzman Hernandez",
                    "NOMBRES COMPLETOS": "Manuela Alejandra Guzman Hernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Maria Lucia Canales Parrales",
                    "CODIGO USUARIO": "guzm800813",
                    "USUARIOFIVE9": "guzm800813@siigo.com",
                    "USUARIO RED": "guzm800813",
                    "CORREOELECTRONICO": "guzm800813@siigo.com",
                    "STATION ID": "2606175",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Maria Lucia Canales Parrales",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1207734672",
                    "FECHA INICIAL": "2020-02-10",
                    "FECHA OPERACION": "2020-11-09",
                    "NOMBRES": "Alison Anaid",
                    "APELLIDOS": "Chica Ezeta",
                    "NOMBRES COMPLETOS": "Alison Anaid Chica Ezeta",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jose Manuel Aguayo Escandon",
                    "CODIGO USUARIO": "chic800546",
                    "USUARIOFIVE9": "chic800546@siigo.com",
                    "USUARIO RED": "chic800546",
                    "CORREOELECTRONICO": "chic800546@siigo.com",
                    "STATION ID": "2606059",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jose Manuel Aguayo Escandon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "923963011",
                    "FECHA INICIAL": "2016-08-01",
                    "FECHA OPERACION": "2020-11-10",
                    "NOMBRES": "Walter Antonio",
                    "APELLIDOS": "Munoz Antepara",
                    "NOMBRES COMPLETOS": "Walter Antonio Munoz Antepara",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Ecuador",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Jose Manuel Aguayo Escandon",
                    "CODIGO USUARIO": "muno800623",
                    "USUARIOFIVE9": "muno800623@siigo.com",
                    "USUARIO RED": "muno800623",
                    "CORREOELECTRONICO": "muno800623@siigo.com",
                    "STATION ID": "2606188",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jose Manuel Aguayo Escandon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "916908072",
                    "FECHA INICIAL": "2020-07-13",
                    "FECHA OPERACION": "2020-11-10",
                    "NOMBRES": "Vicente Roberto",
                    "APELLIDOS": "Aviles Cardenas",
                    "NOMBRES COMPLETOS": "Vicente Roberto Aviles Cardenas",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Walter Antonio Munoz Antepara",
                    "CODIGO USUARIO": "avil800629",
                    "USUARIOFIVE9": "avil800629@siigo.com",
                    "USUARIO RED": "avil800629",
                    "CORREOELECTRONICO": "avil800629@siigo.com",
                    "STATION ID": "2606189",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Walter Antonio Munoz Antepara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "930430632",
                    "FECHA INICIAL": "2020-07-20",
                    "FECHA OPERACION": "2020-11-10",
                    "NOMBRES": "Hellen Rudy",
                    "APELLIDOS": "Quimi Vera",
                    "NOMBRES COMPLETOS": "Hellen Rudy Quimi Vera",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Contabilidad",
                    "SUBAREA": "Contabilidad",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Luz Derly Bernal Malaver",
                    "CODIGO USUARIO": "quim8005911",
                    "USUARIOFIVE9": "quim8005911@siigo.com",
                    "USUARIO RED": "quim8005911",
                    "CORREOELECTRONICO": "quim8005911@siigo.com",
                    "STATION ID": "2606198",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Contabilidad_Luz Derly Bernal Malaver",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1725323503",
                    "FECHA INICIAL": "2018-04-18",
                    "FECHA OPERACION": "2020-11-10",
                    "NOMBRES": "Joselyn Silvana",
                    "APELLIDOS": "Arias Villareal",
                    "NOMBRES COMPLETOS": "Joselyn Silvana Arias Villareal",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ecuador",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Edith Angelica Gil Umbarila",
                    "CODIGO USUARIO": "aria800641",
                    "USUARIOFIVE9": "aria800641@siigo.com",
                    "USUARIO RED": "aria800641",
                    "CORREOELECTRONICO": "aria800641@siigo.com",
                    "STATION ID": "2606200",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Edith Angelica Gil Umbarila",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "927456673",
                    "FECHA INICIAL": "2017-10-26",
                    "FECHA OPERACION": "2020-11-10",
                    "NOMBRES": "Gabriela Lisbeth",
                    "APELLIDOS": "Arteaga Morante",
                    "NOMBRES COMPLETOS": "Gabriela Lisbeth Arteaga Morante",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ecuador",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Edith Angelica Gil Umbarila",
                    "CODIGO USUARIO": "arte800642",
                    "USUARIOFIVE9": "arte800642@siigo.com",
                    "USUARIO RED": "arte800642",
                    "CORREOELECTRONICO": "arte800642@siigo.com",
                    "STATION ID": "2606201",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Edith Angelica Gil Umbarila",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010243210",
                    "FECHA INICIAL": "2020-10-01",
                    "FECHA OPERACION": "2020-10-01",
                    "NOMBRES": "Diego Alejandro",
                    "APELLIDOS": "Restrepo Vela",
                    "NOMBRES COMPLETOS": "Diego Alejandro Restrepo Vela",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Edwin Fermin Gomez Quinonez",
                    "CODIGO USUARIO": "rest800746",
                    "USUARIOFIVE9": "rest800746@siigo.com",
                    "USUARIO RED": "rest800746",
                    "CORREOELECTRONICO": "rest800746@siigo.com",
                    "STATION ID": "2606067",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Edwin Fermin Gomez Quinonez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "952271740",
                    "FECHA INICIAL": "2019-07-04",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Evelyn Lucia",
                    "APELLIDOS": "Crespo Yagual",
                    "NOMBRES COMPLETOS": "Evelyn Lucia Crespo Yagual",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnico Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "cres800581",
                    "USUARIOFIVE9": "cres800581@siigo.com",
                    "USUARIO RED": "cres800581",
                    "CORREOELECTRONICO": "cres800581@siigo.com",
                    "STATION ID": "2606236",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "923220073",
                    "FECHA INICIAL": "2019-02-21",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Alexandra Geraldine",
                    "APELLIDOS": "Sanchez Paiva",
                    "NOMBRES COMPLETOS": "Alexandra Geraldine Sanchez Paiva",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Ecuador",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "sanc800542",
                    "USUARIOFIVE9": "sanc800542@siigo.com",
                    "USUARIO RED": "sanc800542",
                    "CORREOELECTRONICO": "sanc800542@siigo.com",
                    "STATION ID": "2606216",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "925475949",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-03-09",
                    "NOMBRES": "Bryan Steven",
                    "APELLIDOS": "Lascano Aranea",
                    "NOMBRES COMPLETOS": "Bryan Steven Lascano Aranea",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "lasc801195",
                    "USUARIOFIVE9": "lasc801195@siigo.com",
                    "USUARIO RED": "lasc801195",
                    "CORREOELECTRONICO": "lasc801195@siigo.com",
                    "STATION ID": "2606823",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "954230207",
                    "FECHA INICIAL": "2020-07-13",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Jose Aaron",
                    "APELLIDOS": "Jaramillo Saverio",
                    "NOMBRES COMPLETOS": "Jose Aaron Jaramillo Saverio",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "jara800612",
                    "USUARIOFIVE9": "jara800612@siigo.com",
                    "USUARIO RED": "jara800612",
                    "CORREOELECTRONICO": "jara800612@siigo.com",
                    "STATION ID": "2606218",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "930901335",
                    "FECHA INICIAL": "2021-01-07",
                    "FECHA OPERACION": "2021-01-14",
                    "NOMBRES": "Alvaro Andres",
                    "APELLIDOS": "Tabara Baque",
                    "NOMBRES COMPLETOS": "Alvaro Andres Tabara Baque",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "taba801013",
                    "USUARIOFIVE9": "taba801013@siigo.com",
                    "USUARIO RED": "taba801013",
                    "CORREOELECTRONICO": "taba801013@siigo.com",
                    "STATION ID": "2606285",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "926518754",
                    "FECHA INICIAL": "2011-08-17",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Estefany Rocio",
                    "APELLIDOS": "Munoz Rojas",
                    "NOMBRES COMPLETOS": "Estefany Rocio Munoz Rojas",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Ecuador",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Yessica Yulieth Quintero Martinez",
                    "CODIGO USUARIO": "muno800580",
                    "USUARIOFIVE9": "muno800580@siigo.com",
                    "USUARIO RED": "muno800580",
                    "CORREOELECTRONICO": "muno800580@siigo.com",
                    "STATION ID": "2606224",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yessica Yulieth Quintero Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "954392957",
                    "FECHA INICIAL": "2020-07-22",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Bianca Alice",
                    "APELLIDOS": "Cepeda Campoverde",
                    "NOMBRES COMPLETOS": "Bianca Alice Cepeda Campoverde",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Jorge Luis Salas Sarmiento",
                    "CODIGO USUARIO": "cepe800615",
                    "USUARIOFIVE9": "cepe800615@siigo.com",
                    "USUARIO RED": "cepe800615",
                    "CORREOELECTRONICO": "cepe800615@siigo.com",
                    "STATION ID": "2606226",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Jorge Luis Salas Sarmiento",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "954279535",
                    "FECHA INICIAL": "2020-01-20",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Eliseo Natanael",
                    "APELLIDOS": "Obregon Reyes",
                    "NOMBRES COMPLETOS": "Eliseo Natanael Obregon Reyes",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Diana Sofia Ramos Castillo",
                    "CODIGO USUARIO": "obre800577",
                    "USUARIOFIVE9": "obre800577@siigo.com",
                    "USUARIO RED": "obre800577",
                    "CORREOELECTRONICO": "obre800577@siigo.com",
                    "STATION ID": "2606227",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Sofia Ramos Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "926237892",
                    "FECHA INICIAL": "2018-09-12",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Geomar Eliecer",
                    "APELLIDOS": "Delgado Molina",
                    "NOMBRES COMPLETOS": "Geomar Eliecer Delgado Molina",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Manuel Andres Solano Duran",
                    "CODIGO USUARIO": "delg800588",
                    "USUARIOFIVE9": "delg800588@siigo.com",
                    "USUARIO RED": "delg800588",
                    "CORREOELECTRONICO": "delg800588@siigo.com",
                    "STATION ID": "2606228",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Manuel Andres Solano Duran",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "954515987",
                    "FECHA INICIAL": "2020-12-21",
                    "FECHA OPERACION": "2020-12-29",
                    "NOMBRES": "Cirilo",
                    "APELLIDOS": "Perez Mallia",
                    "NOMBRES COMPLETOS": "Cirilo Perez Mallia",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "pere800983",
                    "USUARIOFIVE9": "pere800983@siigo.com",
                    "USUARIO RED": "pere800983",
                    "CORREOELECTRONICO": "pere800983@siigo.com",
                    "STATION ID": "2606210",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "930161971",
                    "FECHA INICIAL": "2018-09-13",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Juan Francisco",
                    "APELLIDOS": "Amaya Alcivar",
                    "NOMBRES COMPLETOS": "Juan Francisco Amaya Alcivar",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Jorge Luis Salas Sarmiento",
                    "CODIGO USUARIO": "amay800624",
                    "USUARIOFIVE9": "amay800624@siigo.com",
                    "USUARIO RED": "amay800624",
                    "CORREOELECTRONICO": "amay800624@siigo.com",
                    "STATION ID": "2606230",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Jorge Luis Salas Sarmiento",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1052398717",
                    "FECHA INICIAL": "2019-11-12",
                    "FECHA OPERACION": "2019-11-12",
                    "NOMBRES": "Daniela Yizeth",
                    "APELLIDOS": "Mantilla Lizarazo",
                    "NOMBRES COMPLETOS": "Daniela Yizeth Mantilla Lizarazo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "mant112523",
                    "USUARIOFIVE9": "mant112523@siigo.com",
                    "USUARIO RED": "mant112523",
                    "CORREOELECTRONICO": "mant112523@siigo.com",
                    "STATION ID": "2606760",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "953876877",
                    "FECHA INICIAL": "2020-01-02",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Luis Andres",
                    "APELLIDOS": "Rodriguez Morales",
                    "NOMBRES COMPLETOS": "Luis Andres Rodriguez Morales",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Diana Sofia Ramos Castillo",
                    "CODIGO USUARIO": "rodr800646",
                    "USUARIOFIVE9": "rodr800646@siigo.com",
                    "USUARIO RED": "rodr800646",
                    "CORREOELECTRONICO": "rodr800646@siigo.com",
                    "STATION ID": "2606232",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Sofia Ramos Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1002022019",
                    "FECHA INICIAL": "2020-10-01",
                    "FECHA OPERACION": "2020-10-01",
                    "NOMBRES": "Juan Eduardo",
                    "APELLIDOS": "Morera Cotera",
                    "NOMBRES COMPLETOS": "Juan Eduardo Morera Cotera",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "more800743",
                    "USUARIOFIVE9": "more800743@siigo.com",
                    "USUARIO RED": "more800743",
                    "CORREOELECTRONICO": "more800743@siigo.com",
                    "STATION ID": "2606096",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022433178",
                    "FECHA INICIAL": "2020-03-02",
                    "FECHA OPERACION": "2020-03-02",
                    "NOMBRES": "Luis Eduardo",
                    "APELLIDOS": "Segura Melo",
                    "NOMBRES COMPLETOS": "Luis Eduardo Segura Melo",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Paola Cely Prada",
                    "CODIGO USUARIO": "segu800180",
                    "USUARIOFIVE9": "segu800180@siigo.com",
                    "USUARIO RED": "segu800180",
                    "CORREOELECTRONICO": "segu800180@siigo.com",
                    "STATION ID": "2607000",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Paola Cely Prada",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018482244",
                    "FECHA INICIAL": "2020-10-08",
                    "FECHA OPERACION": "2020-10-08",
                    "NOMBRES": "Leidy Katerin",
                    "APELLIDOS": "Pardo Garavito",
                    "NOMBRES COMPLETOS": "Leidy Katerin Pardo Garavito",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Ticket Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "pard800798",
                    "USUARIOFIVE9": "pard800798@siigo.com",
                    "USUARIO RED": "pard800798",
                    "CORREOELECTRONICO": "pard800798@siigo.com",
                    "STATION ID": "2606155",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "950625061",
                    "FECHA INICIAL": "2019-08-20",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Lenny Aracely",
                    "APELLIDOS": "Quiroz Manzaba",
                    "NOMBRES COMPLETOS": "Lenny Aracely Quiroz Manzalba",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Quality Control",
                    "JEFE INMEDIATO": "Gabriel Alexander Romero Mora",
                    "CODIGO USUARIO": "quir800640",
                    "USUARIOFIVE9": "quir800640@siigo.com",
                    "USUARIO RED": "quir800640",
                    "CORREOELECTRONICO": "quir800640@siigo.com",
                    "STATION ID": "2606237",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Gabriel Alexander Romero Mora",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "924453178",
                    "FECHA INICIAL": "2019-11-11",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Jose Maria",
                    "APELLIDOS": "Contreras Pincay",
                    "NOMBRES COMPLETOS": "Jose Maria Contreras Pincay",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Jimena Marcela Prieto Bohorquez",
                    "CODIGO USUARIO": "cont800606",
                    "USUARIOFIVE9": "cont800606@siigo.com",
                    "USUARIO RED": "cont800606",
                    "CORREOELECTRONICO": "cont800606@siigo.com",
                    "STATION ID": "2606238",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jimena Marcela Prieto Bohorquez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "943996520",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Melanie Angie",
                    "APELLIDOS": "Herrera Andrade",
                    "NOMBRES COMPLETOS": "Melanie Angie Herrera Andrade",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Juan Francisco Amaya Alcivar",
                    "CODIGO USUARIO": "herr801247",
                    "USUARIOFIVE9": "herr801247@siigo.com",
                    "USUARIO RED": "herr801247",
                    "CORREOELECTRONICO": "herr801247@siigo.com",
                    "STATION ID": "2606728",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Juan Francisco Amaya Alcivar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "926398983",
                    "FECHA INICIAL": "2019-11-11",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Alfredo Alexander",
                    "APELLIDOS": "Castaneda Buenaventura",
                    "NOMBRES COMPLETOS": "Alfredo Alexander Castaneda Buenaventura",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "QA",
                    "JEFE INMEDIATO": "Gabriel Alexander Romero Mora",
                    "CODIGO USUARIO": "cast800544",
                    "USUARIOFIVE9": "cast800544@siigo.com",
                    "USUARIO RED": "cast800544",
                    "CORREOELECTRONICO": "cast800544@siigo.com",
                    "STATION ID": "2606242",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Gabriel Alexander Romero Mora",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1233509565",
                    "FECHA INICIAL": "2021-08-23",
                    "FECHA OPERACION": "2021-08-23",
                    "NOMBRES": "Daniela",
                    "APELLIDOS": "Sanchez Rojas",
                    "NOMBRES COMPLETOS": "Daniela Sanchez Rojas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Francy Julieth Viracacha Gutierrez",
                    "CODIGO USUARIO": "sanc801728",
                    "USUARIOFIVE9": "sanc801728@siigo.com",
                    "USUARIO RED": "sanc801728",
                    "CORREOELECTRONICO": "sanc801728@siigo.com",
                    "STATION ID": "3127571",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Francy Julieth Viracacha Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018491397",
                    "FECHA INICIAL": "2020-11-23",
                    "FECHA OPERACION": "2020-11-23",
                    "NOMBRES": "Angie Camila",
                    "APELLIDOS": "Diaz Escobar",
                    "NOMBRES COMPLETOS": "Angie Camila Diaz Escobar",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones ECU",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "John Alexander Benavides Cobos",
                    "CODIGO USUARIO": "diaz800863",
                    "USUARIOFIVE9": "diaz800863@siigo.com",
                    "USUARIO RED": "diaz800863",
                    "CORREOELECTRONICO": "diaz800863@siigo.com",
                    "STATION ID": "2606206",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_John Alexander Benavides Cobos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "930935390",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-03-09",
                    "NOMBRES": "George Adolfo",
                    "APELLIDOS": "Maldonado De La Cruz",
                    "NOMBRES COMPLETOS": "George Adolfo Maldonado De La Cruz",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Bianca Alice Cepeda Campoverde",
                    "CODIGO USUARIO": "mald801196",
                    "USUARIOFIVE9": "mald801196@siigo.com",
                    "USUARIO RED": "mald801196",
                    "CORREOELECTRONICO": "mald801196@siigo.com",
                    "STATION ID": "2606828",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Bianca Alice Cepeda Campoverde",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "9527592060119",
                    "FECHA INICIAL": "2020-11-23",
                    "FECHA OPERACION": "2020-11-23",
                    "NOMBRES": "Aurilde Jose",
                    "APELLIDOS": "Caldera Martinez",
                    "NOMBRES COMPLETOS": "Aurilde Jose Caldera Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Yamid Ramiro Quintero Montano",
                    "CODIGO USUARIO": "cald800865",
                    "USUARIOFIVE9": "cald800865@siigo.com",
                    "USUARIO RED": "cald800865",
                    "CORREOELECTRONICO": "cald800865@siigo.com",
                    "STATION ID": "2606209",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yamid Ramiro Quintero Montano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019017196",
                    "FECHA INICIAL": "2020-11-23",
                    "FECHA OPERACION": "2020-11-23",
                    "NOMBRES": "Cristian Camilo",
                    "APELLIDOS": "Daza Coronado",
                    "NOMBRES COMPLETOS": "Cristian Camilo Daza Coronado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Patricia Villarraga Forero",
                    "CODIGO USUARIO": "daza800866",
                    "USUARIOFIVE9": "daza800866@siigo.com",
                    "USUARIO RED": "daza800866",
                    "CORREOELECTRONICO": "daza800866@siigo.com",
                    "STATION ID": "2606211",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Patricia Villarraga Forero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000856203",
                    "FECHA INICIAL": "2020-11-23",
                    "FECHA OPERACION": "2020-11-23",
                    "NOMBRES": "Fabian Andres",
                    "APELLIDOS": "Hernandez Fajardo",
                    "NOMBRES COMPLETOS": "Fabian Andres Hernandez Fajardo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Bussiness Inteligence",
                    "JEFE INMEDIATO": "Ana Maria Quijano",
                    "CODIGO USUARIO": "hern800868",
                    "USUARIOFIVE9": "hern800868@siigo.com",
                    "USUARIO RED": "hern800868",
                    "CORREOELECTRONICO": "hern800868@siigo.com",
                    "STATION ID": "2606243",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ana Maria Quijano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1193063936",
                    "FECHA INICIAL": "2020-07-28",
                    "FECHA OPERACION": "2020-07-28",
                    "NOMBRES": "Evelyn Dayana",
                    "APELLIDOS": "Moncada Diaz",
                    "NOMBRES COMPLETOS": "Evelyn Dayana Moncada Diaz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Cristian Camilo Hernandez Gonzalez",
                    "CODIGO USUARIO": "monc800491",
                    "USUARIOFIVE9": "monc800491@siigo.com",
                    "USUARIO RED": "monc800491",
                    "CORREOELECTRONICO": "monc800491@siigo.com",
                    "STATION ID": "2606052",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Cristian Camilo Hernandez Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000362943",
                    "FECHA INICIAL": "2021-09-08",
                    "FECHA OPERACION": "2021-09-09",
                    "NOMBRES": "David Steban",
                    "APELLIDOS": "Buitrago Duran",
                    "NOMBRES COMPLETOS": "David Steban Buitrago Duran",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Juan Pablo Carreno Patino",
                    "CODIGO USUARIO": "buit801886",
                    "USUARIOFIVE9": "buit801886@siigo.com",
                    "USUARIO RED": "buit801886",
                    "CORREOELECTRONICO": "buit801886@siigo.com",
                    "STATION ID": "3471505",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Carreno Patino",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80259815",
                    "FECHA INICIAL": "2021-01-20",
                    "FECHA OPERACION": "2021-01-28",
                    "NOMBRES": "Albert",
                    "APELLIDOS": "Andrey Vallejo",
                    "NOMBRES COMPLETOS": "Albert Andrey Vallejo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Cristian Camilo Hernandez Gonzalez",
                    "CODIGO USUARIO": "vall801050",
                    "USUARIOFIVE9": "vall801050@siigo.com",
                    "USUARIO RED": "vall801050",
                    "CORREOELECTRONICO": "vall801050@siigo.com",
                    "STATION ID": "2606381",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Cristian Camilo Hernandez Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1090500988",
                    "FECHA INICIAL": "2020-12-07",
                    "FECHA OPERACION": "2020-12-07",
                    "NOMBRES": "Fabio Enrique",
                    "APELLIDOS": "Aguilar Ponton",
                    "NOMBRES COMPLETOS": "Fabio Enrique Aguilar Ponton",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "agui800907",
                    "USUARIOFIVE9": "agui800907@siigo.com",
                    "USUARIO RED": "agui800907",
                    "CORREOELECTRONICO": "agui800907@siigo.com",
                    "STATION ID": "2606164",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "55303548",
                    "FECHA INICIAL": "2020-11-23",
                    "FECHA OPERACION": "2020-11-23",
                    "NOMBRES": "Mayerlys Teresa",
                    "APELLIDOS": "Hernandez Luna",
                    "NOMBRES COMPLETOS": "Mayerlys Teresa Hernandez Luna",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Yamid Ramiro Quintero Montano",
                    "CODIGO USUARIO": "hern800862",
                    "USUARIOFIVE9": "hern800862@siigo.com",
                    "USUARIO RED": "hern800862",
                    "CORREOELECTRONICO": "hern800862@siigo.com",
                    "STATION ID": "2606255",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yamid Ramiro Quintero Montano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1035440213",
                    "FECHA INICIAL": "2019-11-04",
                    "FECHA OPERACION": "2019-11-04",
                    "NOMBRES": "Daniela Bayona",
                    "APELLIDOS": "Gaviria",
                    "NOMBRES COMPLETOS": "Daniela Bayona Gaviria",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Colombia",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Olga Lucia Ospina Vidal",
                    "CODIGO USUARIO": "bayo112484",
                    "USUARIOFIVE9": "bayo112484@siigo.com",
                    "USUARIO RED": "bayo112484",
                    "CORREOELECTRONICO": "bayo112484@siigo.com",
                    "STATION ID": "2606944",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Olga Lucia Ospina Vidal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1095832659",
                    "FECHA INICIAL": "2020-11-23",
                    "FECHA OPERACION": "2020-11-23",
                    "NOMBRES": "Stefania",
                    "APELLIDOS": "Moreno Soler",
                    "NOMBRES COMPLETOS": "Stefania Moreno Soler",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Cristian Camilo Hernandez Gonzalez",
                    "CODIGO USUARIO": "more800874",
                    "USUARIOFIVE9": "more800874@siigo.com",
                    "USUARIO RED": "more800874",
                    "CORREOELECTRONICO": "more800874@siigo.com",
                    "STATION ID": "2606261",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Cristian Camilo Hernandez Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143269894",
                    "FECHA INICIAL": "2021-04-07",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Angellys",
                    "APELLIDOS": "Jimenez Aguas",
                    "NOMBRES COMPLETOS": "Angellys Cecilia Jimenez Aguas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Colombia",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Olga Lucia Ospina Vidal",
                    "CODIGO USUARIO": "jime801280",
                    "USUARIOFIVE9": "jime801280@siigo.com",
                    "USUARIO RED": "jime801280",
                    "CORREOELECTRONICO": "jime801280@siigo.com",
                    "STATION ID": "2606899",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Olga Lucia Ospina Vidal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1068976153",
                    "FECHA INICIAL": "2021-04-07",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Diana Katherin",
                    "APELLIDOS": "Guevara Cubillos",
                    "NOMBRES COMPLETOS": "Diana Katherin Guevara Cubillos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "guev801297",
                    "USUARIOFIVE9": "guev801297@siigo.com",
                    "USUARIO RED": "guev801297",
                    "CORREOELECTRONICO": "guev801297@siigo.com",
                    "STATION ID": "2606955",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024494631",
                    "FECHA INICIAL": "2020-11-23",
                    "FECHA OPERACION": "2020-11-23",
                    "NOMBRES": "Fanny Elvira",
                    "APELLIDOS": "Rodriguez",
                    "NOMBRES COMPLETOS": "Fanny Elvira Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Colombia",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Olga Lucia Ospina Vidal",
                    "CODIGO USUARIO": "rodr800869",
                    "USUARIOFIVE9": "rodr800869@siigo.com",
                    "USUARIO RED": "rodr800869",
                    "CORREOELECTRONICO": "rodr800869@siigo.com",
                    "STATION ID": "2606244",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Olga Lucia Ospina Vidal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1235038508",
                    "FECHA INICIAL": "2020-12-07",
                    "FECHA OPERACION": "2020-12-07",
                    "NOMBRES": "Adrian Esteban",
                    "APELLIDOS": "Briceno Bautista",
                    "NOMBRES COMPLETOS": "Adrian Esteban Briceno Bautista",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luz Katerine Maya Mogollon",
                    "CODIGO USUARIO": "bric800921",
                    "USUARIOFIVE9": "bric800921@siigo.com",
                    "USUARIO RED": "bric800921",
                    "CORREOELECTRONICO": "bric800921@siigo.com",
                    "STATION ID": "2606317",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Katerine Maya Mogollon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1127574313",
                    "FECHA INICIAL": "2021-08-17",
                    "FECHA OPERACION": "2021-08-18",
                    "NOMBRES": "Dayana Isabel",
                    "APELLIDOS": "Heredia Pizarro",
                    "NOMBRES COMPLETOS": "Dayana Isabel Heredia Pizarro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "here801685",
                    "USUARIOFIVE9": "here801685@siigo.com",
                    "USUARIO RED": "here801685",
                    "CORREOELECTRONICO": "here801685@siigo.com",
                    "STATION ID": "3127553",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057595206",
                    "FECHA INICIAL": "2019-12-02",
                    "FECHA OPERACION": "2019-12-02",
                    "NOMBRES": "Laura Catalina",
                    "APELLIDOS": "Mora Morales",
                    "NOMBRES COMPLETOS": "Laura Catalina Mora Morales",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Diana Sofia Ramos Castillo",
                    "CODIGO USUARIO": "mora800010",
                    "USUARIOFIVE9": "mora800010@siigo.com",
                    "USUARIO RED": "mora800010",
                    "CORREOELECTRONICO": "mora800010@siigo.com",
                    "STATION ID": "2606764",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Sofia Ramos Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1088347740",
                    "FECHA INICIAL": "2020-12-07",
                    "FECHA OPERACION": "2020-12-07",
                    "NOMBRES": "Angie Tatiana",
                    "APELLIDOS": "Reyes Perez",
                    "NOMBRES COMPLETOS": "Angie Tatiana Reyes Perez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Juan Pablo Henao Munoz",
                    "CODIGO USUARIO": "reye800935",
                    "USUARIOFIVE9": "reye800935@siigo.com",
                    "USUARIO RED": "reye800935",
                    "CORREOELECTRONICO": "reye800935@siigo.com",
                    "STATION ID": "2606324",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Henao Munoz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143453027",
                    "FECHA INICIAL": "2021-03-10",
                    "FECHA OPERACION": "2021-03-09",
                    "NOMBRES": "Leidy Paola",
                    "APELLIDOS": "Blanco Cortina",
                    "NOMBRES COMPLETOS": "Leidy Paola Blanco Cortina",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Eduardo Sanchez Castano",
                    "CODIGO USUARIO": "blan801183",
                    "USUARIOFIVE9": "blan801183@siigo.com",
                    "USUARIO RED": "blan801183",
                    "CORREOELECTRONICO": "blan801183@siigo.com",
                    "STATION ID": "2606784",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Eduardo Sanchez Castano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018417353",
                    "FECHA INICIAL": "2020-12-07",
                    "FECHA OPERACION": "2020-12-07",
                    "NOMBRES": "Jhon Jairo",
                    "APELLIDOS": "Garzon Ramirez",
                    "NOMBRES COMPLETOS": "Jhon Jairo Garzon Ramirez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adrian Alberto Salom Berrio",
                    "CODIGO USUARIO": "garz800933",
                    "USUARIOFIVE9": "garz800933@siigo.com",
                    "USUARIO RED": "garz800933",
                    "CORREOELECTRONICO": "garz800933@siigo.com",
                    "STATION ID": "2606323",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adrian Alberto Salom Berrio",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1113680646",
                    "FECHA INICIAL": "2021-04-07",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Hernan David",
                    "APELLIDOS": "Palacio Mejia",
                    "NOMBRES COMPLETOS": "Hernan David Palacio Mejia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Cristian Camilo Hernandez Gonzalez",
                    "CODIGO USUARIO": "pala801284",
                    "USUARIOFIVE9": "pala801284@siigo.com",
                    "USUARIO RED": "pala801284",
                    "CORREOELECTRONICO": "pala801284@siigo.com",
                    "STATION ID": "2606919",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Cristian Camilo Hernandez Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098816861",
                    "FECHA INICIAL": "2020-12-07",
                    "FECHA OPERACION": "2020-12-07",
                    "NOMBRES": "Juan Sebastian",
                    "APELLIDOS": "Garcia Vega",
                    "NOMBRES COMPLETOS": "Juan Sebastian Garcia Vega",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Roberto Alvarado Mancera",
                    "CODIGO USUARIO": "garc800936",
                    "USUARIOFIVE9": "garc800936@siigo.com",
                    "USUARIO RED": "garc800936",
                    "CORREOELECTRONICO": "garc800936@siigo.com",
                    "STATION ID": "2606325",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Roberto Alvarado Mancera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "91516234",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-03",
                    "NOMBRES": "Elvis Ricardo",
                    "APELLIDOS": "Linares Amador",
                    "NOMBRES COMPLETOS": "Elvis Ricardo Linares Amador",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Mercedes Moncada Peraza",
                    "CODIGO USUARIO": "lina108107",
                    "USUARIOFIVE9": "lina108107@siigo.com",
                    "USUARIO RED": "lina108107",
                    "CORREOELECTRONICO": "lina108107@siigo.com",
                    "STATION ID": "2606327",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Mercedes Moncada Peraza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024470230",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-03",
                    "NOMBRES": "Julieth",
                    "APELLIDOS": "Quitian Figueroa",
                    "NOMBRES COMPLETOS": "Julieth Quitian Figueroa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jefferson Arvey Ramirez Ibanez",
                    "CODIGO USUARIO": "quit800891",
                    "USUARIOFIVE9": "quit800891@siigo.com",
                    "USUARIO RED": "quit800891",
                    "CORREOELECTRONICO": "quit800891@siigo.com",
                    "STATION ID": "2606330",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jefferson Arvey Ramirez Ibanez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1112459430",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-03",
                    "NOMBRES": "Lina Maria",
                    "APELLIDOS": "Loaiza Arias",
                    "NOMBRES COMPLETOS": "Lina Maria Loaiza Arias",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juliana Castro Diaz",
                    "CODIGO USUARIO": "loai800894",
                    "USUARIOFIVE9": "loai800894@siigo.com",
                    "USUARIO RED": "loai800894",
                    "CORREOELECTRONICO": "loai800894@siigo.com",
                    "STATION ID": "2606333",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juliana Castro Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53076635",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-03",
                    "NOMBRES": "Francy Viviana",
                    "APELLIDOS": "Rodriguez Fonseca",
                    "NOMBRES COMPLETOS": "Francy Viviana Rodriguez Fonseca",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "David Alfredo Sanchez Bastidas",
                    "CODIGO USUARIO": "rodr800895",
                    "USUARIOFIVE9": "rodr800895@siigo.com",
                    "USUARIO RED": "rodr800895",
                    "CORREOELECTRONICO": "rodr800895@siigo.com",
                    "STATION ID": "2606334",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_David Alfredo Sanchez Bastidas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032429546",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-03",
                    "NOMBRES": "Maria Erlly",
                    "APELLIDOS": "Rincon Bravo",
                    "NOMBRES COMPLETOS": "Maria Erlly Rincon Bravo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "William Andres Reyes Silva",
                    "CODIGO USUARIO": "rinc800896",
                    "USUARIOFIVE9": "rinc800896@siigo.com",
                    "USUARIO RED": "rinc800896",
                    "CORREOELECTRONICO": "rinc800896@siigo.com",
                    "STATION ID": "2606335",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_William Andres Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143979144",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-03",
                    "NOMBRES": "Angie Vanessa",
                    "APELLIDOS": "Garcia Arara",
                    "NOMBRES COMPLETOS": "Angie Vanessa Garcia Arara",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Yuleidy Josefina Briceno Fernandez",
                    "CODIGO USUARIO": "garc800950",
                    "USUARIOFIVE9": "garc800950@siigo.com",
                    "USUARIO RED": "garc800950",
                    "CORREOELECTRONICO": "garc800950@siigo.com",
                    "STATION ID": "2606369",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Yuleidy Josefina Briceno Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1026282247",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-03",
                    "NOMBRES": "Wendy Johanna",
                    "APELLIDOS": "Gomez Herrera",
                    "NOMBRES COMPLETOS": "Wendy Johanna Gomez Herrera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jefferson Arvey Ramirez Ibanez",
                    "CODIGO USUARIO": "gome800903",
                    "USUARIOFIVE9": "gome800903@siigo.com",
                    "USUARIO RED": "gome800903",
                    "CORREOELECTRONICO": "gome800903@siigo.com",
                    "STATION ID": "2606343",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jefferson Arvey Ramirez Ibanez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52860300",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-03",
                    "NOMBRES": "Paola",
                    "APELLIDOS": "Rico Escobar",
                    "NOMBRES COMPLETOS": "Paola Rico Escobar",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Andres Felipe Baron Ballen",
                    "CODIGO USUARIO": "rico800906",
                    "USUARIOFIVE9": "rico800906@siigo.com",
                    "USUARIO RED": "rico800906",
                    "CORREOELECTRONICO": "rico800906@siigo.com",
                    "STATION ID": "2606344",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andres Felipe Baron Ballen",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032427513",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-03",
                    "NOMBRES": "Eliana",
                    "APELLIDOS": "Rodriguez Cubillos",
                    "NOMBRES COMPLETOS": "Eliana Rodriguez Cubillos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Aleida Bibiana Garcia Suarez",
                    "CODIGO USUARIO": "rodr800908",
                    "USUARIOFIVE9": "rodr800908@siigo.com",
                    "USUARIO RED": "rodr800908",
                    "CORREOELECTRONICO": "rodr800908@siigo.com",
                    "STATION ID": "2606345",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Aleida Bibiana Garcia Suarez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1079608482",
                    "FECHA INICIAL": "2021-07-16",
                    "FECHA OPERACION": "2021-07-21",
                    "NOMBRES": "Eider",
                    "APELLIDOS": "Martinez Cruz",
                    "NOMBRES COMPLETOS": "Eider Martinez Cruz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "mart801585",
                    "USUARIOFIVE9": "mart801585@siigo.com",
                    "USUARIO RED": "mart801585",
                    "CORREOELECTRONICO": "mart801585@siigo.com",
                    "STATION ID": "2606446",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010169322",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-03",
                    "NOMBRES": "Ligia Paola",
                    "APELLIDOS": "Hernandez Vargas",
                    "NOMBRES COMPLETOS": "Ligia Paola Hernandez Vargas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Vicky Alejandra Hernandez Mendez",
                    "CODIGO USUARIO": "hern800924",
                    "USUARIOFIVE9": "hern800924@siigo.com",
                    "USUARIO RED": "hern800924",
                    "CORREOELECTRONICO": "hern800924@siigo.com",
                    "STATION ID": "2606351",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Vicky Alejandra Hernandez Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1043120029",
                    "FECHA INICIAL": "2021-05-13",
                    "FECHA OPERACION": "2021-05-13",
                    "NOMBRES": "Astrid Milena",
                    "APELLIDOS": "Pedrozo Lombana",
                    "NOMBRES COMPLETOS": "Astrid Milena Pedrozo Lombana",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jessica Paola Paez Quintero",
                    "CODIGO USUARIO": "pedr801435",
                    "USUARIOFIVE9": "pedr801435@siigo.com",
                    "USUARIO RED": "pedr801435",
                    "CORREOELECTRONICO": "pedr801435@siigo.com",
                    "STATION ID": "2607122",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jessica Paola Paez Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52716646",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-03-09",
                    "NOMBRES": "Diana Marcela",
                    "APELLIDOS": "Diaz Agudelo",
                    "NOMBRES COMPLETOS": "Diana Marcela Diaz Agudelo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Yuleidy Josefina Briceno Fernandez",
                    "CODIGO USUARIO": "diaz801166",
                    "USUARIOFIVE9": "diaz801166@siigo.com",
                    "USUARIO RED": "diaz801166",
                    "CORREOELECTRONICO": "diaz801166@siigo.com",
                    "STATION ID": "2606844",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Yuleidy Josefina Briceno Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1095944355",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-03",
                    "NOMBRES": "Dayron Fabian",
                    "APELLIDOS": "Monsalve Patino",
                    "NOMBRES COMPLETOS": "Dayron Fabian Monsalve Patino",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jessica Paola Paez Quintero",
                    "CODIGO USUARIO": "mons800940",
                    "USUARIOFIVE9": "mons800940@siigo.com",
                    "USUARIO RED": "mons800940",
                    "CORREOELECTRONICO": "mons800940@siigo.com",
                    "STATION ID": "2606359",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jessica Paola Paez Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019102411",
                    "FECHA INICIAL": "2018-12-10",
                    "FECHA OPERACION": "2018-12-10",
                    "NOMBRES": "Diego Alejandro",
                    "APELLIDOS": "Roa Contreras",
                    "NOMBRES COMPLETOS": "Diego Alejandro Roa Contreras",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jessica Paola Paez Quintero",
                    "CODIGO USUARIO": "roac111810",
                    "USUARIOFIVE9": "roac111810@siigo.com",
                    "USUARIO RED": "roac111810",
                    "CORREOELECTRONICO": "roac111810@siigo.com",
                    "STATION ID": "2606415",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jessica Paola Paez Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52787237",
                    "FECHA INICIAL": "2020-02-17",
                    "FECHA OPERACION": "2020-02-17",
                    "NOMBRES": "Dolly Esperanza",
                    "APELLIDOS": "Bolivar Oviedo",
                    "NOMBRES COMPLETOS": "Dolly Esperanza Bolivar Oviedo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Yuleidy Josefina Briceno Fernandez",
                    "CODIGO USUARIO": "boli800281",
                    "USUARIOFIVE9": "boli800281@siigo.com",
                    "USUARIO RED": "boli800281",
                    "CORREOELECTRONICO": "boli800281@siigo.com",
                    "STATION ID": "2607082",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Yuleidy Josefina Briceno Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024537565",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-03",
                    "NOMBRES": "Jeisson Fabian",
                    "APELLIDOS": "Pena Cardona",
                    "NOMBRES COMPLETOS": "Jeisson Fabian Pena Cardona",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Workfoce & Reporting",
                    "CARGO": "Mission Controller",
                    "JEFE INMEDIATO": "Julieth Paola Vera Patino",
                    "CODIGO USUARIO": "pena800954",
                    "USUARIOFIVE9": "pena800954@siigo.com",
                    "USUARIO RED": "pena800954",
                    "CORREOELECTRONICO": "pena800954@siigo.com",
                    "STATION ID": "2606373",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Julieth Paola Vera Patino",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057919057",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-03",
                    "NOMBRES": "Yeison Fabian",
                    "APELLIDOS": "Romero Avila",
                    "NOMBRES COMPLETOS": "Yeison Fabian Romero Avila",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Vicky Alejandra Hernandez Mendez",
                    "CODIGO USUARIO": "rome800956",
                    "USUARIOFIVE9": "rome800956@siigo.com",
                    "USUARIO RED": "rome800956",
                    "CORREOELECTRONICO": "rome800956@siigo.com",
                    "STATION ID": "2606375",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Vicky Alejandra Hernandez Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1054709208",
                    "FECHA INICIAL": "2020-12-07",
                    "FECHA OPERACION": "2020-12-07",
                    "NOMBRES": "Tania Andrea",
                    "APELLIDOS": "Garibello Gil",
                    "NOMBRES COMPLETOS": "Tania Andrea Garibello Gil",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Santa Marta",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ventas",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "gari800963",
                    "USUARIOFIVE9": "gari800963@siigo.com",
                    "USUARIO RED": "gari800963",
                    "CORREOELECTRONICO": "gari800963@siigo.com",
                    "STATION ID": "2606376",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019085219",
                    "FECHA INICIAL": "2019-12-18",
                    "FECHA OPERACION": "2020-12-10",
                    "NOMBRES": "Luis Felipe",
                    "APELLIDOS": "Sanchez Gomez",
                    "NOMBRES COMPLETOS": "Luis Felipe Sanchez Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Estrategia",
                    "SUBAREA": "Estrategia",
                    "CARGO": "Lider",
                    "JEFE INMEDIATO": "Jorge Alberto Navia Charria",
                    "CODIGO USUARIO": "sanc800041",
                    "USUARIOFIVE9": "sanc800041@siigo.com",
                    "USUARIO RED": "sanc800041",
                    "CORREOELECTRONICO": "sanc800041@siigo.com",
                    "STATION ID": "2606380",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Estrategia_Jorge Alberto Navia Charria",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "86072688",
                    "FECHA INICIAL": "2014-09-09",
                    "FECHA OPERACION": "2020-12-22",
                    "NOMBRES": "David Leonardo",
                    "APELLIDOS": "Ayala",
                    "NOMBRES COMPLETOS": "David Leonardo Ayala",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Gabriel Alexander Romero Mora",
                    "CODIGO USUARIO": "ayal32170",
                    "USUARIOFIVE9": "ayal32170@siigo.com",
                    "USUARIO RED": "ayal32170",
                    "CORREOELECTRONICO": "ayal32170@siigo.com",
                    "STATION ID": "2606027",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Gabriel Alexander Romero Mora",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1061750280",
                    "FECHA INICIAL": "2020-03-18",
                    "FECHA OPERACION": "2020-12-22",
                    "NOMBRES": "Angela Maria",
                    "APELLIDOS": "Guacheta Ibarra",
                    "NOMBRES COMPLETOS": "Angela Maria Guacheta Ibarra",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Popayan",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "QA",
                    "JEFE INMEDIATO": "Jenny Carolina Moreno Duarte",
                    "CODIGO USUARIO": "guac800236",
                    "USUARIOFIVE9": "guac800236@siigo.com",
                    "USUARIO RED": "guac800236",
                    "CORREOELECTRONICO": "guac800236@siigo.com",
                    "STATION ID": "2606048",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Jenny Carolina Moreno Duarte",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1088320593",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-03",
                    "NOMBRES": "Enith Yohana",
                    "APELLIDOS": "Loaiza Gonzalez",
                    "NOMBRES COMPLETOS": "Enith Yohana Loaiza Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Manizales",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Yuleidy Josefina Briceno Fernandez",
                    "CODIGO USUARIO": "loai800902",
                    "USUARIOFIVE9": "loai800902@siigo.com",
                    "USUARIO RED": "loai800902",
                    "CORREOELECTRONICO": "loai800902@siigo.com",
                    "STATION ID": "2606341",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Yuleidy Josefina Briceno Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1714298229",
                    "FECHA INICIAL": "2020-12-09",
                    "FECHA OPERACION": "2020-12-28",
                    "NOMBRES": "Carlos Marcelo",
                    "APELLIDOS": "Llore Garcia",
                    "NOMBRES COMPLETOS": "Carlos Marcelo Llore Garcia",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Michelle Matamoros Del Rosario",
                    "CODIGO USUARIO": "llor800969",
                    "USUARIOFIVE9": "llor800969@siigo.com",
                    "USUARIO RED": "llor800969",
                    "CORREOELECTRONICO": "llor800969@siigo.com",
                    "STATION ID": "2605975",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Michelle Matamoros Del Rosario",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "931216113",
                    "FECHA INICIAL": "2021-03-24",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Steven Luciano",
                    "APELLIDOS": "Briones Yunapanta",
                    "NOMBRES COMPLETOS": "Steven Luciano Briones Yunapanta",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Jaime Rodrigo Catells Perez",
                    "CODIGO USUARIO": "brio801235",
                    "USUARIOFIVE9": "brio801235@siigo.com",
                    "USUARIO RED": "brio801235",
                    "CORREOELECTRONICO": "brio801235@siigo.com",
                    "STATION ID": "2606425",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Jaime Rodrigo Catells Perez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015427613",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-03",
                    "NOMBRES": "Jessica",
                    "APELLIDOS": "Molina",
                    "NOMBRES COMPLETOS": "Jessica Molina",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "moli800946",
                    "USUARIOFIVE9": "moli800946@siigo.com",
                    "USUARIO RED": "moli800946",
                    "CORREOELECTRONICO": "moli800946@siigo.com",
                    "STATION ID": "2606365",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "930574728",
                    "FECHA INICIAL": "2021-03-24",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Jhocelyn Ived",
                    "APELLIDOS": "Lemos Castillo",
                    "NOMBRES COMPLETOS": "Jhocelyn Ived Lemos Castillo",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "lemo801237",
                    "USUARIOFIVE9": "lemo801237@siigo.com",
                    "USUARIO RED": "lemo801237",
                    "CORREOELECTRONICO": "lemo801237@siigo.com",
                    "STATION ID": "2606688",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "951699156",
                    "FECHA INICIAL": "2020-02-03",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Jimmy Roberto",
                    "APELLIDOS": "Vera Calderon",
                    "NOMBRES COMPLETOS": "Jimmy Roberto Vera Calderon",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "vera800613",
                    "USUARIOFIVE9": "vera800613@siigo.com",
                    "USUARIO RED": "vera800613",
                    "CORREOELECTRONICO": "vera800613@siigo.com",
                    "STATION ID": "2606217",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1724122047",
                    "FECHA INICIAL": "2020-12-21",
                    "FECHA OPERACION": "2020-12-29",
                    "NOMBRES": "Guillermo Adrian",
                    "APELLIDOS": "Paredes Maquilon",
                    "NOMBRES COMPLETOS": "Guillermo Adrian Paredes Maquilon",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "pare800984",
                    "USUARIOFIVE9": "pare800984@siigo.com",
                    "USUARIO RED": "pare800984",
                    "CORREOELECTRONICO": "pare800984@siigo.com",
                    "STATION ID": "2606265",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1054092706",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-29",
                    "NOMBRES": "Juan Sebastian",
                    "APELLIDOS": "Castellanos Prieto",
                    "NOMBRES COMPLETOS": "Juan Sebastian Castellanos Prieto",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Maria Naranjo Mattheus",
                    "CODIGO USUARIO": "cast800949",
                    "USUARIOFIVE9": "cast800949@siigo.com",
                    "USUARIO RED": "cast800949",
                    "CORREOELECTRONICO": "cast800949@siigo.com",
                    "STATION ID": "2606368",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Maria Naranjo Mattheus",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033700433",
                    "FECHA INICIAL": "2020-09-17",
                    "FECHA OPERACION": "2020-09-17",
                    "NOMBRES": "Jeimmy Janeth",
                    "APELLIDOS": "Pardo Guerrero",
                    "NOMBRES COMPLETOS": "Jeimmy Janeth Pardo Guerrero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Eduardo Sanchez Castano",
                    "CODIGO USUARIO": "pard800696",
                    "USUARIOFIVE9": "pard800696@siigo.com",
                    "USUARIO RED": "pard800696",
                    "CORREOELECTRONICO": "pard800696@siigo.com",
                    "STATION ID": "2605995",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Eduardo Sanchez Castano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1144102308",
                    "FECHA INICIAL": "2020-10-08",
                    "FECHA OPERACION": "2020-10-08",
                    "NOMBRES": "Paula Andrea",
                    "APELLIDOS": "Villarraga Forero",
                    "NOMBRES COMPLETOS": "Paula Andrea Villarraga Forero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Elizabeth Giron Parra",
                    "CODIGO USUARIO": "vill800801",
                    "USUARIOFIVE9": "vill800801@siigo.com",
                    "USUARIO RED": "vill800801",
                    "CORREOELECTRONICO": "vill800801@siigo.com",
                    "STATION ID": "2606158",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Elizabeth Giron Parra",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018514382",
                    "FECHA INICIAL": "2021-01-04",
                    "FECHA OPERACION": "2021-01-04",
                    "NOMBRES": "Maria Camila",
                    "APELLIDOS": "Andrade Montano",
                    "NOMBRES COMPLETOS": "Maria Camila Andrade Montano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Workfoce & Reporting",
                    "CARGO": "Analista de Workforce",
                    "JEFE INMEDIATO": "Julieth Paola Vera Patino",
                    "CODIGO USUARIO": "andr800993",
                    "USUARIOFIVE9": "andr800993@siigo.com",
                    "USUARIO RED": "andr800993",
                    "CORREOELECTRONICO": "andr800993@siigo.com",
                    "STATION ID": "2606271",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Julieth Paola Vera Patino",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52801277",
                    "FECHA INICIAL": "2021-01-04",
                    "FECHA OPERACION": "2021-01-04",
                    "NOMBRES": "Diana Patricia",
                    "APELLIDOS": "Villarraga Forero",
                    "NOMBRES COMPLETOS": "Diana Patricia Villarraga Forero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Diego Armando Aristizabal Bedoya",
                    "CODIGO USUARIO": "vill800996",
                    "USUARIOFIVE9": "vill800996@siigo.com",
                    "USUARIO RED": "vill800996",
                    "CORREOELECTRONICO": "vill800996@siigo.com",
                    "STATION ID": "2606274",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diego Armando Aristizabal Bedoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143155469",
                    "FECHA INICIAL": "2020-09-17",
                    "FECHA OPERACION": "2020-09-17",
                    "NOMBRES": "Gabriel Angel",
                    "APELLIDOS": "Blanco Rojas",
                    "NOMBRES COMPLETOS": "Gabriel Angel Blanco Rojas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Carolina Linares Gonzalez",
                    "CODIGO USUARIO": "blan800690",
                    "USUARIOFIVE9": "blan800690@siigo.com",
                    "USUARIO RED": "blan800690",
                    "CORREOELECTRONICO": "blan800690@siigo.com",
                    "STATION ID": "2606013",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Carolina Linares Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098809774",
                    "FECHA INICIAL": "2021-01-04",
                    "FECHA OPERACION": "2021-01-04",
                    "NOMBRES": "German Camilo",
                    "APELLIDOS": "Caceres Castillo",
                    "NOMBRES COMPLETOS": "German Camilo Caceres Castillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Cristian Camilo Hernandez Gonzalez",
                    "CODIGO USUARIO": "cace800999",
                    "USUARIOFIVE9": "cace800999@siigo.com",
                    "USUARIO RED": "cace800999",
                    "CORREOELECTRONICO": "cace800999@siigo.com",
                    "STATION ID": "2606277",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Cristian Camilo Hernandez Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1112473936",
                    "FECHA INICIAL": "2021-01-04",
                    "FECHA OPERACION": "2021-01-04",
                    "NOMBRES": "Sebastian Alejandro",
                    "APELLIDOS": "Rodriguez Duque",
                    "NOMBRES COMPLETOS": "Sebastian Alejandro Rodriguez Duque",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Bussiness Inteligence",
                    "JEFE INMEDIATO": "Saul Steven Carpio Ochoa",
                    "CODIGO USUARIO": "rodr801001",
                    "USUARIOFIVE9": "rodr801001@siigo.com",
                    "USUARIO RED": "rodr801001",
                    "CORREOELECTRONICO": "rodr801001@siigo.com",
                    "STATION ID": "2606279",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Saul Steven Carpio Ochoa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1049649888",
                    "FECHA INICIAL": "2019-11-05",
                    "FECHA OPERACION": "2019-11-05",
                    "NOMBRES": "Lizeth Jhojana",
                    "APELLIDOS": "Cano Jimenez",
                    "NOMBRES COMPLETOS": "Lizeth Jhojana Cano Jimenez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Chile",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Chile",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Leidy Stefany Castillo",
                    "CODIGO USUARIO": "cano112513",
                    "USUARIOFIVE9": "cano112513@siigo.com",
                    "USUARIO RED": "cano112513",
                    "CORREOELECTRONICO": "cano112513@siigo.com",
                    "STATION ID": "2606765",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Leidy Stefany Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1026261209",
                    "FECHA INICIAL": "2020-07-28",
                    "FECHA OPERACION": "2020-07-28",
                    "NOMBRES": "Gustavo Alberto",
                    "APELLIDOS": "Rico Ruiz",
                    "NOMBRES COMPLETOS": "Gustavo Alberto Rico Ruiz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Fernando Sotto Cardozo",
                    "CODIGO USUARIO": "rico800474",
                    "USUARIOFIVE9": "rico800474@siigo.com",
                    "USUARIO RED": "rico800474",
                    "CORREOELECTRONICO": "rico800474@siigo.com",
                    "STATION ID": "2606032",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Fernando Sotto Cardozo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "954422994",
                    "FECHA INICIAL": "2017-01-17",
                    "FECHA OPERACION": "2020-12-30",
                    "NOMBRES": "Bryan Isaac",
                    "APELLIDOS": "Gurumendi Carreno",
                    "NOMBRES COMPLETOS": "Bryan Isaac Gurumendi Carreno",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Angel Andres Pineda Alvarado",
                    "CODIGO USUARIO": "guru800555",
                    "USUARIOFIVE9": "guru800555@siigo.com",
                    "USUARIO RED": "guru800555",
                    "CORREOELECTRONICO": "guru800555@siigo.com",
                    "STATION ID": "2606286",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Angel Andres Pineda Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "950490458",
                    "FECHA INICIAL": "2019-03-19",
                    "FECHA OPERACION": "2020-12-30",
                    "NOMBRES": "Miguel Alexander",
                    "APELLIDOS": "Palacios Guzman",
                    "NOMBRES COMPLETOS": "Miguel Alexander Palacios Guzman",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Eddie Steven Hurtado Herrera",
                    "CODIGO USUARIO": "pala800660",
                    "USUARIOFIVE9": "pala800660@siigo.com",
                    "USUARIO RED": "pala800660",
                    "CORREOELECTRONICO": "pala800660@siigo.com",
                    "STATION ID": "2606288",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Eddie Steven Hurtado Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "953018330",
                    "FECHA INICIAL": "2019-07-01",
                    "FECHA OPERACION": "2020-12-30",
                    "NOMBRES": "Andrea Estefania",
                    "APELLIDOS": "Choez Reyes",
                    "NOMBRES COMPLETOS": "Andrea Estefania Choez Reyes",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Diana Sofia Ramos Castillo",
                    "CODIGO USUARIO": "choe800549",
                    "USUARIOFIVE9": "choe800549@siigo.com",
                    "USUARIO RED": "choe800549",
                    "CORREOELECTRONICO": "choe800549@siigo.com",
                    "STATION ID": "2606289",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Sofia Ramos Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "940358872",
                    "FECHA INICIAL": "2019-11-05",
                    "FECHA OPERACION": "2020-12-30",
                    "NOMBRES": "Juliana Stefania",
                    "APELLIDOS": "Villegas Gomez",
                    "NOMBRES COMPLETOS": "Juliana Stefania Villegas Gomez",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoraciones",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Sandra Catalina Suarez Castillo",
                    "CODIGO USUARIO": "vill800626",
                    "USUARIOFIVE9": "vill800626@siigo.com",
                    "USUARIO RED": "vill800626",
                    "CORREOELECTRONICO": "vill800626@siigo.com",
                    "STATION ID": "2606290",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Sandra Catalina Suarez Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "930862560",
                    "FECHA INICIAL": "2016-02-22",
                    "FECHA OPERACION": "2020-12-30",
                    "NOMBRES": "Karla Estefania",
                    "APELLIDOS": "Pacheco Guerrero",
                    "NOMBRES COMPLETOS": "Karla Estefania Pacheco Guerrero",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Diana Sofia Ramos Castillo",
                    "CODIGO USUARIO": "pach800630",
                    "USUARIOFIVE9": "pach800630@siigo.com",
                    "USUARIO RED": "pach800630",
                    "CORREOELECTRONICO": "pach800630@siigo.com",
                    "STATION ID": "2606291",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Sofia Ramos Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "930731740",
                    "FECHA INICIAL": "2015-02-24",
                    "FECHA OPERACION": "2020-12-30",
                    "NOMBRES": "Paola Denisse",
                    "APELLIDOS": "Veloz Garcia",
                    "NOMBRES COMPLETOS": "Paola Denisse Veloz Garcia",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Product Owner",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "velo800665",
                    "USUARIOFIVE9": "velo800665@siigo.com",
                    "USUARIO RED": "velo800665",
                    "CORREOELECTRONICO": "velo800665@siigo.com",
                    "STATION ID": "2606292",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "950579664",
                    "FECHA INICIAL": "2020-01-20",
                    "FECHA OPERACION": "2020-12-30",
                    "NOMBRES": "Joan Carlos",
                    "APELLIDOS": "Moscoso Villavicencio",
                    "NOMBRES COMPLETOS": "Joan Carlos Moscoso Villavicencio",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Diana Sofia Ramos Castillo",
                    "CODIGO USUARIO": "mosc800885",
                    "USUARIOFIVE9": "mosc800885@siigo.com",
                    "USUARIO RED": "mosc800885",
                    "CORREOELECTRONICO": "mosc800885@siigo.com",
                    "STATION ID": "2606293",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Sofia Ramos Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "55148706",
                    "FECHA INICIAL": "2014-03-19",
                    "FECHA OPERACION": "2014-03-19",
                    "NOMBRES": "Martha Isabel",
                    "APELLIDOS": "Garcia Claros",
                    "NOMBRES COMPLETOS": "Martha Isabel Garcia Claros",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Neiva",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Lida Katerine Puentes Navarro",
                    "CODIGO USUARIO": "garc33953",
                    "USUARIOFIVE9": "garc33953@siigo.com",
                    "USUARIO RED": "garc33953",
                    "CORREOELECTRONICO": "garc33953@siigo.com",
                    "STATION ID": "2606696",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Lida Katerine Puentes Navarro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1073520131",
                    "FECHA INICIAL": "2021-05-06",
                    "FECHA OPERACION": "2021-05-06",
                    "NOMBRES": "Jenny Lysbeth",
                    "APELLIDOS": "Triana Padilla",
                    "NOMBRES COMPLETOS": "Jenny Lysbeth Triana Padilla",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Jazbleidy Andrea Nunez Montoya",
                    "CODIGO USUARIO": "tria801412",
                    "USUARIOFIVE9": "tria801412@siigo.com",
                    "USUARIO RED": "tria801412",
                    "CORREOELECTRONICO": "tria801412@siigo.com",
                    "STATION ID": "3127466",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jazbleidy Andrea Nunez Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "951873843",
                    "FECHA INICIAL": "2021-03-24",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Jeanella Lisbeth",
                    "APELLIDOS": "Molina Molina",
                    "NOMBRES COMPLETOS": "Jeanella Lisbeth Molina Molina",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoracion",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "moli801246",
                    "USUARIOFIVE9": "moli801246@siigo.com",
                    "USUARIO RED": "moli801246",
                    "CORREOELECTRONICO": "moli801246@siigo.com",
                    "STATION ID": "2606726",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "922168430",
                    "FECHA INICIAL": "2020-01-27",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Jean Carlo",
                    "APELLIDOS": "Velez Palacios",
                    "NOMBRES COMPLETOS": "Jean Carlo Velez Palacios",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "vele800605",
                    "USUARIOFIVE9": "vele800605@siigo.com",
                    "USUARIO RED": "vele800605",
                    "CORREOELECTRONICO": "vele800605@siigo.com",
                    "STATION ID": "2606235",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "924522667",
                    "FECHA INICIAL": "2020-07-17",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Jose Miguel",
                    "APELLIDOS": "Ramirez Leon",
                    "NOMBRES COMPLETOS": "Jose Miguel Ramirez Leon",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "rami800608",
                    "USUARIOFIVE9": "rami800608@siigo.com",
                    "USUARIO RED": "rami800608",
                    "CORREOELECTRONICO": "rami800608@siigo.com",
                    "STATION ID": "2606219",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098795047",
                    "FECHA INICIAL": "2021-01-15",
                    "FECHA OPERACION": "2021-01-19",
                    "NOMBRES": "Heiber Armando",
                    "APELLIDOS": "Pinto Martinez",
                    "NOMBRES COMPLETOS": "Heiber Armando Pinto Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Yamid Ramiro Quintero Montano",
                    "CODIGO USUARIO": "pint801033",
                    "USUARIOFIVE9": "pint801033@siigo.com",
                    "USUARIO RED": "pint801033",
                    "CORREOELECTRONICO": "pint801033@siigo.com",
                    "STATION ID": "2606297",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yamid Ramiro Quintero Montano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098813670",
                    "FECHA INICIAL": "2021-01-18",
                    "FECHA OPERACION": "2021-01-19",
                    "NOMBRES": "Maria Fernanda",
                    "APELLIDOS": "Hernandez Pulido",
                    "NOMBRES COMPLETOS": "Maria Fernanda Hernandez Pulido",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Paola Cely Prada",
                    "CODIGO USUARIO": "hern801034",
                    "USUARIOFIVE9": "hern801034@siigo.com",
                    "USUARIO RED": "hern801034",
                    "CORREOELECTRONICO": "hern801034@siigo.com",
                    "STATION ID": "2606298",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Paola Cely Prada",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1129493849",
                    "FECHA INICIAL": "2021-01-18",
                    "FECHA OPERACION": "2021-01-19",
                    "NOMBRES": "Aylin De Las Aguas",
                    "APELLIDOS": "Barrios",
                    "NOMBRES COMPLETOS": "Aylin De Las Aguas Barrios",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Yamid Ramiro Quintero Montano",
                    "CODIGO USUARIO": "barr801037",
                    "USUARIOFIVE9": "barr801037@siigo.com",
                    "USUARIO RED": "barr801037",
                    "CORREOELECTRONICO": "barr801037@siigo.com",
                    "STATION ID": "2606299",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yamid Ramiro Quintero Montano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000017775",
                    "FECHA INICIAL": "2021-01-18",
                    "FECHA OPERACION": "2021-01-19",
                    "NOMBRES": "Anyeli Valentina",
                    "APELLIDOS": "Diaz Rocha",
                    "NOMBRES COMPLETOS": "Anyeli Valentina Diaz Rocha",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Patricia Villarraga Forero",
                    "CODIGO USUARIO": "diaz801036",
                    "USUARIOFIVE9": "diaz801036@siigo.com",
                    "USUARIO RED": "diaz801036",
                    "CORREOELECTRONICO": "diaz801036@siigo.com",
                    "STATION ID": "2606301",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Patricia Villarraga Forero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143433638",
                    "FECHA INICIAL": "2021-08-17",
                    "FECHA OPERACION": "2021-08-18",
                    "NOMBRES": "Mayra Alejandra",
                    "APELLIDOS": "Cruz Gomez",
                    "NOMBRES COMPLETOS": "Mayra Alejandra Cruz Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Eduardo Sanchez Castano",
                    "CODIGO USUARIO": "cruz801686",
                    "USUARIOFIVE9": "cruz801686@siigo.com",
                    "USUARIO RED": "cruz801686",
                    "CORREOELECTRONICO": "cruz801686@siigo.com",
                    "STATION ID": "3127556",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Eduardo Sanchez Castano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1040326345",
                    "FECHA INICIAL": "2021-01-20",
                    "FECHA OPERACION": "2021-01-21",
                    "NOMBRES": "Monica",
                    "APELLIDOS": "Acevedo Yarce",
                    "NOMBRES COMPLETOS": "Monica Acevedo Yarce",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Roberto Alvarado Mancera",
                    "CODIGO USUARIO": "acev801042",
                    "USUARIOFIVE9": "acev801042@siigo.com",
                    "USUARIO RED": "acev801042",
                    "CORREOELECTRONICO": "acev801042@siigo.com",
                    "STATION ID": "2606309",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Roberto Alvarado Mancera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1121931860",
                    "FECHA INICIAL": "2021-05-12",
                    "FECHA OPERACION": "2021-05-12",
                    "NOMBRES": "Jhons Rene",
                    "APELLIDOS": "Bejarano Garcia",
                    "NOMBRES COMPLETOS": "Jhons Rene Bejarano Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jessica Paola Paez Quintero",
                    "CODIGO USUARIO": "beja801461",
                    "USUARIOFIVE9": "beja801461@siigo.com",
                    "USUARIO RED": "beja801461",
                    "CORREOELECTRONICO": "beja801461@siigo.com",
                    "STATION ID": "2606145",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jessica Paola Paez Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007845528",
                    "FECHA INICIAL": "2020-12-07",
                    "FECHA OPERACION": "2020-12-07",
                    "NOMBRES": "Janeth Paola",
                    "APELLIDOS": "Martinez Cuartas",
                    "NOMBRES COMPLETOS": "Janeth Paola Martinez Cuartas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jefrey Julian Sanchez Celemin",
                    "CODIGO USUARIO": "mart800916",
                    "USUARIOFIVE9": "mart800916@siigo.com",
                    "USUARIO RED": "mart800916",
                    "CORREOELECTRONICO": "mart800916@siigo.com",
                    "STATION ID": "2606313",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jefrey Julian Sanchez Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52507146",
                    "FECHA INICIAL": "2021-01-25",
                    "FECHA OPERACION": "2021-01-21",
                    "NOMBRES": "Andrea Catalina",
                    "APELLIDOS": "Vega Gomez",
                    "NOMBRES COMPLETOS": "Andrea Catalina Vega Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Lider Perfilamiento",
                    "JEFE INMEDIATO": "Harrison Martinez Callejas",
                    "CODIGO USUARIO": "vega801046",
                    "USUARIOFIVE9": "vega801046@siigo.com",
                    "USUARIO RED": "vega801046",
                    "CORREOELECTRONICO": "vega801046@siigo.com",
                    "STATION ID": "2606346",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Harrison Martinez Callejas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033782240",
                    "FECHA INICIAL": "2021-09-29",
                    "FECHA OPERACION": "2021-10-04",
                    "NOMBRES": "Karol Stepfania",
                    "APELLIDOS": "Olarte Roa",
                    "NOMBRES COMPLETOS": "Karol Stepfania Olarte Roa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Yeimy Milena Bustos Gutierrez",
                    "CODIGO USUARIO": "olar801962",
                    "USUARIOFIVE9": "olar801962@siigo.com",
                    "USUARIO RED": "olar801962",
                    "CORREOELECTRONICO": "olar801962@siigo.com",
                    "STATION ID": "3267883",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yeimy Milena Bustos Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030650619",
                    "FECHA INICIAL": "2021-07-19",
                    "FECHA OPERACION": "2021-07-26",
                    "NOMBRES": "Juan Felipe",
                    "APELLIDOS": "Nieto Abril",
                    "NOMBRES COMPLETOS": "Juan Felipe Nieto Abril",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "niet801588",
                    "USUARIOFIVE9": "niet801588@siigo.com",
                    "USUARIO RED": "niet801588",
                    "CORREOELECTRONICO": "niet801588@siigo.com",
                    "STATION ID": "2606128",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1053853992",
                    "FECHA INICIAL": "2021-01-20",
                    "FECHA OPERACION": "2021-01-28",
                    "NOMBRES": "Valentina",
                    "APELLIDOS": "Loaiza Gonzalez",
                    "NOMBRES COMPLETOS": "Valentina Loaiza Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Manizales",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Andrea Catalina Vega Gomez",
                    "CODIGO USUARIO": "loai801049",
                    "USUARIOFIVE9": "loai801049@siigo.com",
                    "USUARIO RED": "loai801049",
                    "CORREOELECTRONICO": "loai801049@siigo.com",
                    "STATION ID": "2606382",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andrea Catalina Vega Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014223176",
                    "FECHA INICIAL": "2021-02-08",
                    "FECHA OPERACION": "2021-02-10",
                    "NOMBRES": "Monica Alejandra",
                    "APELLIDOS": "Cely Ortiz",
                    "NOMBRES COMPLETOS": "Monica Alejandra Cely Ortiz",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jose Manuel Aguayo Escandon",
                    "CODIGO USUARIO": "cely801075",
                    "USUARIOFIVE9": "cely801075@siigo.com",
                    "USUARIO RED": "cely801075",
                    "CORREOELECTRONICO": "cely801075@siigo.com",
                    "STATION ID": "2605959",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jose Manuel Aguayo Escandon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1713822458",
                    "FECHA INICIAL": "2015-03-16",
                    "FECHA OPERACION": "2021-02-02",
                    "NOMBRES": "Blanca Janeth",
                    "APELLIDOS": "Arcos Guerrero",
                    "NOMBRES COMPLETOS": "Blanca Janeth Arcos Guerrero",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Contabilidad",
                    "SUBAREA": "Contabilidad",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Diana Patricia Atehortua Castano",
                    "CODIGO USUARIO": "arco109056",
                    "USUARIOFIVE9": "arco109056@siigo.com",
                    "USUARIO RED": "arco109056",
                    "CORREOELECTRONICO": "arco109056@siigo.com",
                    "STATION ID": "2606280",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Contabilidad_Diana Patricia Atehortua Castano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "913747515",
                    "FECHA INICIAL": "2020-03-23",
                    "FECHA OPERACION": "2021-02-11",
                    "NOMBRES": "Richard Javier",
                    "APELLIDOS": "Lopez Molina",
                    "NOMBRES COMPLETOS": "Richard Javier Lopez Molina",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Mercadeo",
                    "CARGO": "Analista Mercadeo",
                    "JEFE INMEDIATO": "Cristian Enrique Albornoz Arias",
                    "CODIGO USUARIO": "lope800651",
                    "USUARIOFIVE9": "lope800651@siigo.com",
                    "USUARIO RED": "lope800651",
                    "CORREOELECTRONICO": "lope800651@siigo.com",
                    "STATION ID": "-",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Cristian Enrique Albornoz Arias",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "42703975",
                    "FECHA INICIAL": "2008-08-08",
                    "FECHA OPERACION": "2021-03-08",
                    "NOMBRES": "Alejandro",
                    "APELLIDOS": "Alonso",
                    "NOMBRES COMPLETOS": "Alejandro Alonso",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Canelones",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "alon801088",
                    "USUARIOFIVE9": "alon801088@siigo.com",
                    "USUARIO RED": "alon801088",
                    "CORREOELECTRONICO": "alon801088@siigo.com",
                    "STATION ID": "2606567",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "43251995",
                    "FECHA INICIAL": "2011-07-01",
                    "FECHA OPERACION": "2021-02-15",
                    "NOMBRES": "Diego",
                    "APELLIDOS": "Ferreira",
                    "NOMBRES COMPLETOS": "Diego Ferreira",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Luis Ignacio Ferreira Duarte",
                    "CODIGO USUARIO": "ferr801090",
                    "USUARIOFIVE9": "ferr801090@siigo.com",
                    "USUARIO RED": "ferr801090",
                    "CORREOELECTRONICO": "ferr801090@siigo.com",
                    "STATION ID": "2606153",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Luis Ignacio Ferreira Duarte",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "28648204",
                    "FECHA INICIAL": "1999-10-20",
                    "FECHA OPERACION": "2021-02-15",
                    "NOMBRES": "Andrea",
                    "APELLIDOS": "Olazabal",
                    "NOMBRES COMPLETOS": "Andrea Olazabal",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Harrison Martinez Callejas",
                    "CODIGO USUARIO": "olaz801084",
                    "USUARIOFIVE9": "olaz801084@siigo.com",
                    "USUARIO RED": "olaz801084",
                    "CORREOELECTRONICO": "olaz801084@siigo.com",
                    "STATION ID": "2606354",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Harrison Martinez Callejas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "50975219",
                    "FECHA INICIAL": "2019-11-01",
                    "FECHA OPERACION": "2021-02-15",
                    "NOMBRES": "Carla",
                    "APELLIDOS": "Serpa",
                    "NOMBRES COMPLETOS": "Carla Serpa",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Elias Jusid Machado",
                    "CODIGO USUARIO": "serp801085",
                    "USUARIOFIVE9": "serp801085@siigo.com",
                    "USUARIO RED": "serp801085",
                    "CORREOELECTRONICO": "serp801085@siigo.com",
                    "STATION ID": "2606144",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Elias Jusid Machado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "43159434",
                    "FECHA INICIAL": "2021-04-30",
                    "FECHA OPERACION": "2021-04-30",
                    "NOMBRES": "Andres",
                    "APELLIDOS": "Benitez Caffaro",
                    "NOMBRES COMPLETOS": "Andres Benitez Caffaro",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "Call Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alcides Martin Salas Orozco",
                    "CODIGO USUARIO": "beni801366",
                    "USUARIOFIVE9": "beni801366@siigo.com",
                    "USUARIO RED": "beni801366",
                    "CORREOELECTRONICO": "beni801366@siigo.com",
                    "STATION ID": "2606252",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alcides Martin Salas Orozco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "41307790",
                    "FECHA INICIAL": "2020-11-03",
                    "FECHA OPERACION": "2021-02-15",
                    "NOMBRES": "Ignacio",
                    "APELLIDOS": "Ferreira",
                    "NOMBRES COMPLETOS": "Ignacio Ferreira",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Uruguay",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "John Jairo Mejia Acevedo",
                    "CODIGO USUARIO": "ferr801096",
                    "USUARIOFIVE9": "ferr801096@siigo.com",
                    "USUARIO RED": "ferr801096",
                    "CORREOELECTRONICO": "ferr801096@siigo.com",
                    "STATION ID": "2606391",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Jairo Mejia Acevedo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "45721829",
                    "FECHA INICIAL": "2016-07-19",
                    "FECHA OPERACION": "2021-02-15",
                    "NOMBRES": "Johana",
                    "APELLIDOS": "Ruiz Diaz",
                    "NOMBRES COMPLETOS": "Johana Ruiz Diaz",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "Call Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alcides Martin Salas Orozco",
                    "CODIGO USUARIO": "ruiz80110",
                    "USUARIOFIVE9": "ruiz801100@siigo.com",
                    "USUARIO RED": "ruiz80110",
                    "CORREOELECTRONICO": "ruiz801100@siigo.com",
                    "STATION ID": "2606420",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alcides Martin Salas Orozco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52781457",
                    "FECHA INICIAL": "2021-02-09",
                    "FECHA OPERACION": "2021-02-15",
                    "NOMBRES": "Nini Johana",
                    "APELLIDOS": "Bolivar Perez",
                    "NOMBRES COMPLETOS": "Nini Johana Bolivar Perez",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "boli801083",
                    "USUARIOFIVE9": "boli801083@siigo.com",
                    "USUARIO RED": "boli801083",
                    "CORREOELECTRONICO": "boli801083@siigo.com",
                    "STATION ID": "2606433",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1073704907",
                    "FECHA INICIAL": "2020-09-10",
                    "FECHA OPERACION": "2021-02-23",
                    "NOMBRES": "Omar Stive",
                    "APELLIDOS": "Aparicio Orjuela",
                    "NOMBRES COMPLETOS": "Omar Stive Aparicio Orjuela",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cultura",
                    "SUBAREA": "Cultura",
                    "CARGO": "Cultura",
                    "JEFE INMEDIATO": "Olga Itzel Alvarez Ramirez",
                    "CODIGO USUARIO": "apar800678",
                    "USUARIOFIVE9": "apar800678@siigo.com",
                    "USUARIO RED": "apar800678",
                    "CORREOELECTRONICO": "apar800678@siigo.com",
                    "STATION ID": "2606111",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cultura_Olga Itzel Alvarez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013667135",
                    "FECHA INICIAL": "2020-12-09",
                    "FECHA OPERACION": "2021-02-23",
                    "NOMBRES": "Maria Camila",
                    "APELLIDOS": "Sedano Fuentes",
                    "NOMBRES COMPLETOS": "Maria Camila Sedano Fuentes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cultura",
                    "SUBAREA": "Cultura",
                    "CARGO": "Cultura",
                    "JEFE INMEDIATO": "Claudia Cecilia Gonzalez Clavijo",
                    "CODIGO USUARIO": "seda800967",
                    "USUARIOFIVE9": "seda800967@siigo.com",
                    "USUARIO RED": "seda800967",
                    "CORREOELECTRONICO": "seda800967@siigo.com",
                    "STATION ID": "2606451",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cultura_Claudia Cecilia Gonzalez Clavijo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016017814",
                    "FECHA INICIAL": "2021-02-08",
                    "FECHA OPERACION": "2021-02-23",
                    "NOMBRES": "Joan Sebastian",
                    "APELLIDOS": "Rodriguez",
                    "NOMBRES COMPLETOS": "Joan Sebastian Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cultura",
                    "SUBAREA": "Cultura",
                    "CARGO": "Cultura",
                    "JEFE INMEDIATO": "Carlos Arturo Huertas Salgado",
                    "CODIGO USUARIO": "rodr801069",
                    "USUARIOFIVE9": "rodr801069@siigo.com",
                    "USUARIO RED": "rodr801069",
                    "CORREOELECTRONICO": "rodr801069@siigo.com",
                    "STATION ID": "2606453",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cultura_Carlos Arturo Huertas Salgado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "42124428",
                    "FECHA INICIAL": "2013-04-03",
                    "FECHA OPERACION": "2021-03-01",
                    "NOMBRES": "Silvia",
                    "APELLIDOS": "Fernandez",
                    "NOMBRES COMPLETOS": "Silvia Zilah Fernandez Almeida",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Uruguay",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Julian Ernesto Morales Garzon",
                    "CODIGO USUARIO": "fern801120",
                    "USUARIOFIVE9": "fern801120@siigo.com",
                    "USUARIO RED": "fern801120",
                    "CORREOELECTRONICO": "fern801120@siigo.com",
                    "STATION ID": "2606461",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Julian Ernesto Morales Garzon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "931353270",
                    "FECHA INICIAL": "2019-02-11",
                    "FECHA OPERACION": "2021-11-24",
                    "NOMBRES": "Carlos Jesus",
                    "APELLIDOS": "Poveda Zapata",
                    "NOMBRES COMPLETOS": "Carlos Jesus Poveda Zapata",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "BI",
                    "JEFE INMEDIATO": "Juan Jose Correa Acosta",
                    "CODIGO USUARIO": "pove800560",
                    "USUARIOFIVE9": "pove800560@siigo.com",
                    "USUARIO RED": "pove800560",
                    "CORREOELECTRONICO": "pove800560@siigo.com",
                    "STATION ID": "-",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Jose Correa Acosta",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1127595777",
                    "FECHA INICIAL": "2021-02-22",
                    "FECHA OPERACION": "2021-03-05",
                    "NOMBRES": "Alfonzina Isabel",
                    "APELLIDOS": "Diaz Guerrero",
                    "NOMBRES COMPLETOS": "Alfonzina Isabel Diaz Guerrero",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "diaz801143",
                    "USUARIOFIVE9": "diaz801143@siigo.com",
                    "USUARIO RED": "diaz801143",
                    "CORREOELECTRONICO": "diaz801143@siigo.com",
                    "STATION ID": "2606448",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "49871074",
                    "FECHA INICIAL": "2019-05-17",
                    "FECHA OPERACION": "2021-03-08",
                    "NOMBRES": "Sharon",
                    "APELLIDOS": "Samurio",
                    "NOMBRES COMPLETOS": "Sharon Samurio",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "Call Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alcides Martin Salas Orozco",
                    "CODIGO USUARIO": "samu801119",
                    "USUARIOFIVE9": "samu801119@siigo.com",
                    "USUARIO RED": "samu801119",
                    "CORREOELECTRONICO": "samu801119@siigo.com",
                    "STATION ID": "2606475",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alcides Martin Salas Orozco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "38574792",
                    "FECHA INICIAL": "2020-09-29",
                    "FECHA OPERACION": "2021-03-08",
                    "NOMBRES": "Patricia",
                    "APELLIDOS": "Damele",
                    "NOMBRES COMPLETOS": "Patricia Damele",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Luis Ignacio Ferreira Duarte",
                    "CODIGO USUARIO": "dame801116",
                    "USUARIOFIVE9": "dame801116@siigo.com",
                    "USUARIO RED": "dame801116",
                    "CORREOELECTRONICO": "dame801116@siigo.com",
                    "STATION ID": "2606490",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Luis Ignacio Ferreira Duarte",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "27831626",
                    "FECHA INICIAL": "2021-02-12",
                    "FECHA OPERACION": "2021-03-08",
                    "NOMBRES": "Pablo",
                    "APELLIDOS": "Melian",
                    "NOMBRES COMPLETOS": "Pablo Melian",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Elias Jusid Machado",
                    "CODIGO USUARIO": "meli801115",
                    "USUARIOFIVE9": "meli801115@siigo.com",
                    "USUARIO RED": "meli801115",
                    "CORREOELECTRONICO": "meli801115@siigo.com",
                    "STATION ID": "2606495",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Elias Jusid Machado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "37985542",
                    "FECHA INICIAL": "2021-06-24",
                    "FECHA OPERACION": "2021-06-24",
                    "NOMBRES": "Federico",
                    "APELLIDOS": "Medina",
                    "NOMBRES COMPLETOS": "Federico Medina",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "Call Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Elias Jusid Machado",
                    "CODIGO USUARIO": "medi801094",
                    "USUARIOFIVE9": "medi801094@siigo.com",
                    "USUARIO RED": "medi801094",
                    "CORREOELECTRONICO": "medi801094@siigo.com",
                    "STATION ID": "3127520",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Elias Jusid Machado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52164757",
                    "FECHA INICIAL": "2020-06-10",
                    "FECHA OPERACION": "2021-03-08",
                    "NOMBRES": "Mauro",
                    "APELLIDOS": "Filqueira",
                    "NOMBRES COMPLETOS": "Mauro Filqueira",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Elias Jusid Machado",
                    "CODIGO USUARIO": "filq801113",
                    "USUARIOFIVE9": "filq801113@siigo.com",
                    "USUARIO RED": "filq801113",
                    "CORREOELECTRONICO": "filq801113@siigo.com",
                    "STATION ID": "2606509",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Elias Jusid Machado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "48074063",
                    "FECHA INICIAL": "2016-02-11",
                    "FECHA OPERACION": "2021-02-15",
                    "NOMBRES": "Federico",
                    "APELLIDOS": "Molina",
                    "NOMBRES COMPLETOS": "Federico Molina",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Tech",
                    "SUBAREA": "Infraestructura",
                    "CARGO": "Infraestructura",
                    "JEFE INMEDIATO": "Jonathan Oviedo Gonzalez",
                    "CODIGO USUARIO": "moli801093",
                    "USUARIOFIVE9": "moli801093@siigo.com",
                    "USUARIO RED": "moli801093",
                    "CORREOELECTRONICO": "moli801093@siigo.com",
                    "STATION ID": "2606384",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Jonathan Oviedo Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "18690691",
                    "FECHA INICIAL": "2004-05-10",
                    "FECHA OPERACION": "2021-03-08",
                    "NOMBRES": "Luis",
                    "APELLIDOS": "Abeldano",
                    "NOMBRES COMPLETOS": "Luis Abeldano",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "abel801106",
                    "USUARIOFIVE9": "abel801106@siigo.com",
                    "USUARIO RED": "abel801106",
                    "CORREOELECTRONICO": "abel801106@siigo.com",
                    "STATION ID": "2606538",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "32787789",
                    "FECHA INICIAL": "2016-06-20",
                    "FECHA OPERACION": "2021-02-15",
                    "NOMBRES": "Fernando",
                    "APELLIDOS": "Redondo",
                    "NOMBRES COMPLETOS": "Fernando Redondo",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "Call Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Elias Jusid Machado",
                    "CODIGO USUARIO": "redo801095",
                    "USUARIOFIVE9": "redo801095@siigo.com",
                    "USUARIO RED": "redo801095",
                    "CORREOELECTRONICO": "redo801095@siigo.com",
                    "STATION ID": "2606386",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Elias Jusid Machado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "47103417",
                    "FECHA INICIAL": "2015-07-23",
                    "FECHA OPERACION": "2021-02-15",
                    "NOMBRES": "Ivan",
                    "APELLIDOS": "Jusid",
                    "NOMBRES COMPLETOS": "Ivan Jusid",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Uruguay",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "jusi801097",
                    "USUARIOFIVE9": "jusi801097@siigo.com",
                    "USUARIO RED": "jusi801097",
                    "CORREOELECTRONICO": "jusi801097@siigo.com",
                    "STATION ID": "2606396",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "45804037",
                    "FECHA INICIAL": "2013-06-14",
                    "FECHA OPERACION": "2021-03-08",
                    "NOMBRES": "Matias",
                    "APELLIDOS": "Menendez",
                    "NOMBRES COMPLETOS": "Matias Menendez",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "mene801110",
                    "USUARIOFIVE9": "mene801110@siigo.com",
                    "USUARIO RED": "mene801110",
                    "CORREOELECTRONICO": "mene801110@siigo.com",
                    "STATION ID": "2606558",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "54924769",
                    "FECHA INICIAL": "2019-11-19",
                    "FECHA OPERACION": "2021-03-08",
                    "NOMBRES": "Matias Alejandro",
                    "APELLIDOS": "Viera Curcio",
                    "NOMBRES COMPLETOS": "Matias Alejandro Viera Curcio",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Silvia Zilah Fernandez Almeida",
                    "CODIGO USUARIO": "vier801111",
                    "USUARIOFIVE9": "vier801111@siigo.com",
                    "USUARIO RED": "vier801111",
                    "CORREOELECTRONICO": "vier801111@siigo.com",
                    "STATION ID": "2606562",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Silvia Zilah Fernandez Almeida",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "17961403",
                    "FECHA INICIAL": "2015-02-01",
                    "FECHA OPERACION": "2021-02-15",
                    "NOMBRES": "Jonny",
                    "APELLIDOS": "Centrone",
                    "NOMBRES COMPLETOS": "Jonny Centrone",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "Call Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Elias Jusid Machado",
                    "CODIGO USUARIO": "cent801101",
                    "USUARIOFIVE9": "cent801101@siigo.com",
                    "USUARIO RED": "cent801101",
                    "CORREOELECTRONICO": "cent801101@siigo.com",
                    "STATION ID": "2606233",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Elias Jusid Machado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019146746",
                    "FECHA INICIAL": "2021-06-09",
                    "FECHA OPERACION": "2021-06-09",
                    "NOMBRES": "Jorge Andres",
                    "APELLIDOS": "Rodriguez Anchique",
                    "NOMBRES COMPLETOS": "Jorge Andres Rodriguez Anchique",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jessica Paola Paez Quintero",
                    "CODIGO USUARIO": "rodr801465",
                    "USUARIOFIVE9": "rodr801465@siigo.com",
                    "USUARIO RED": "rodr801465",
                    "CORREOELECTRONICO": "rodr801465@siigo.com",
                    "STATION ID": "2606225",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jessica Paola Paez Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1234338309",
                    "FECHA INICIAL": "2021-03-10",
                    "FECHA OPERACION": "2021-03-09",
                    "NOMBRES": "Andres Mauricio",
                    "APELLIDOS": "Meza Mendez",
                    "NOMBRES COMPLETOS": "Andres Mauricio Meza Mendez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Mercadeo",
                    "SUBAREA": "E-Commerce",
                    "CARGO": "Analista E-Commerce",
                    "JEFE INMEDIATO": "Yasser Mohamed Chain Martinez",
                    "CODIGO USUARIO": "meza801180",
                    "USUARIOFIVE9": "meza801180@siigo.com",
                    "USUARIO RED": "meza801180",
                    "CORREOELECTRONICO": "meza801180@siigo.com",
                    "STATION ID": "2606779",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Yasser Mohamed Chain Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018507332",
                    "FECHA INICIAL": "2020-02-24",
                    "FECHA OPERACION": "2020-02-24",
                    "NOMBRES": "Sthephanie Nathalia",
                    "APELLIDOS": "Rivera Cabrera",
                    "NOMBRES COMPLETOS": "Sthephanie Nathalia Rivera Cabrera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Eduardo Sanchez Castano",
                    "CODIGO USUARIO": "rive800310",
                    "USUARIOFIVE9": "rive800310@siigo.com",
                    "USUARIO RED": "rive800310",
                    "CORREOELECTRONICO": "rive800310@siigo.com",
                    "STATION ID": "2607034",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Eduardo Sanchez Castano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1002682679",
                    "FECHA INICIAL": "2021-08-12",
                    "FECHA OPERACION": "2021-08-13",
                    "NOMBRES": "Daisy Dayana",
                    "APELLIDOS": "Rojas Bernal",
                    "NOMBRES COMPLETOS": "Daisy Dayana Rojas Bernal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Tatiana Reyes Perez",
                    "CODIGO USUARIO": "roja801662",
                    "USUARIOFIVE9": "roja801662@siigo.com",
                    "USUARIO RED": "roja801662",
                    "CORREOELECTRONICO": "roja801662@siigo.com",
                    "STATION ID": "2606890",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Tatiana Reyes Perez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53075007",
                    "FECHA INICIAL": "2018-12-03",
                    "FECHA OPERACION": "2018-12-03",
                    "NOMBRES": "Diana Paola",
                    "APELLIDOS": "Garcia Arias",
                    "NOMBRES COMPLETOS": "Diana Paola Garcia Arias",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "garc112008",
                    "USUARIOFIVE9": "garc112008@siigo.com",
                    "USUARIO RED": "garc112008",
                    "CORREOELECTRONICO": "garc112008@siigo.com",
                    "STATION ID": "2606750",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1042439205",
                    "FECHA INICIAL": "2021-09-01",
                    "FECHA OPERACION": "2021-09-01",
                    "NOMBRES": "Keyla Maritza",
                    "APELLIDOS": "Cerra Pacheco",
                    "NOMBRES COMPLETOS": "Keyla Maritza Cerra Pacheco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luz Katerine Maya Mogollon",
                    "CODIGO USUARIO": "cerr801797",
                    "USUARIOFIVE9": "cerr801797@siigo.com",
                    "USUARIO RED": "cerr801797",
                    "CORREOELECTRONICO": "cerr801797@siigo.com",
                    "STATION ID": "3127524",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Katerine Maya Mogollon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1052020835",
                    "FECHA INICIAL": "2021-03-10",
                    "FECHA OPERACION": "2021-03-09",
                    "NOMBRES": "Derly Cristina",
                    "APELLIDOS": "Castro Nino",
                    "NOMBRES COMPLETOS": "Derly Cristina Castro Nino",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Nicoll Torres Celemin",
                    "CODIGO USUARIO": "cast801181",
                    "USUARIOFIVE9": "cast801181@siigo.com",
                    "USUARIO RED": "cast801181",
                    "CORREOELECTRONICO": "cast801181@siigo.com",
                    "STATION ID": "2606805",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Nicoll Torres Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1116251962",
                    "FECHA INICIAL": "2020-10-08",
                    "FECHA OPERACION": "2020-10-08",
                    "NOMBRES": "Ligia",
                    "APELLIDOS": "Velasquez Builes",
                    "NOMBRES COMPLETOS": "Ligia Velasquez Builes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Edison Armando Quintero",
                    "CODIGO USUARIO": "vela800799",
                    "USUARIOFIVE9": "vela800799@siigo.com",
                    "USUARIO RED": "vela800799",
                    "CORREOELECTRONICO": "vela800799@siigo.com",
                    "STATION ID": "2606156",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Edison Armando Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "914453725",
                    "FECHA INICIAL": "2021-03-24",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Kleber Mauricio",
                    "APELLIDOS": "Pena Campuzano",
                    "NOMBRES COMPLETOS": "Kleber Mauricio Pena Campuzano",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "pena801240",
                    "USUARIOFIVE9": "pena801240@siigo.com",
                    "USUARIO RED": "pena801240",
                    "CORREOELECTRONICO": "pena801240@siigo.com",
                    "STATION ID": "2606712",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1073710890",
                    "FECHA INICIAL": "2020-10-01",
                    "FECHA OPERACION": "2020-10-01",
                    "NOMBRES": "John Stiven",
                    "APELLIDOS": "Perez Castro",
                    "NOMBRES COMPLETOS": "John Stiven Perez Castro",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "pere800755",
                    "USUARIOFIVE9": "pere800755@siigo.com",
                    "USUARIO RED": "pere800755",
                    "CORREOELECTRONICO": "pere800755@siigo.com",
                    "STATION ID": "2606073",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1727026609",
                    "FECHA INICIAL": "2020-12-09",
                    "FECHA OPERACION": "2020-12-28",
                    "NOMBRES": "Angie Daniela",
                    "APELLIDOS": "Sanchez Paredes",
                    "NOMBRES COMPLETOS": "Angie Daniela Sanchez Paredes",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "sanc800966",
                    "USUARIOFIVE9": "sanc800966@siigo.com",
                    "USUARIO RED": "sanc800966",
                    "CORREOELECTRONICO": "sanc800966@siigo.com",
                    "STATION ID": "2606082",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "954184750",
                    "FECHA INICIAL": "2020-12-21",
                    "FECHA OPERACION": "2020-12-29",
                    "NOMBRES": "Kevin Javier",
                    "APELLIDOS": "Lamilla Vera",
                    "NOMBRES COMPLETOS": "Kevin Javier Lamilla Vera",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "lami800982",
                    "USUARIOFIVE9": "lami800982@siigo.com",
                    "USUARIO RED": "lami800982",
                    "CORREOELECTRONICO": "lami800982@siigo.com",
                    "STATION ID": "2606190",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032467569",
                    "FECHA INICIAL": "2018-11-26",
                    "FECHA OPERACION": "2018-11-26",
                    "NOMBRES": "Sebastian Lapeira",
                    "APELLIDOS": "Figueroa",
                    "NOMBRES COMPLETOS": "Sebastian Lapeira Figueroa",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "lape111720",
                    "USUARIOFIVE9": "lape111720@siigo.com",
                    "USUARIO RED": "lape111720",
                    "CORREOELECTRONICO": "lape111720@siigo.com",
                    "STATION ID": "2606800",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1309894721",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-03-09",
                    "NOMBRES": "Luis Miguel",
                    "APELLIDOS": "Zambrano Ferrin",
                    "NOMBRES COMPLETOS": "Luis Miguel Zambrano Ferrin",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "zamb801202",
                    "USUARIOFIVE9": "zamb801202@siigo.com",
                    "USUARIO RED": "zamb801202",
                    "CORREOELECTRONICO": "zamb801202@siigo.com",
                    "STATION ID": "2606834",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "931526289",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-03-09",
                    "NOMBRES": "Kevin Lester",
                    "APELLIDOS": "Castellano Sanchez",
                    "NOMBRES COMPLETOS": "Kevin Lester Castellano Sanchez",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "cast801204",
                    "USUARIOFIVE9": "cast801204@siigo.com",
                    "USUARIO RED": "cast801204",
                    "CORREOELECTRONICO": "cast801204@siigo.com",
                    "STATION ID": "2606836",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "954157467",
                    "FECHA INICIAL": "2020-06-08",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Lisbeth Corina",
                    "APELLIDOS": "Cevallos Zoleta",
                    "NOMBRES COMPLETOS": "Lisbeth Corina Cevallos Zoleta",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "ceva800644",
                    "USUARIOFIVE9": "ceva800644@siigo.com",
                    "USUARIO RED": "ceva800644",
                    "CORREOELECTRONICO": "ceva800644@siigo.com",
                    "STATION ID": "2606231",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "931875520",
                    "FECHA INICIAL": "2020-09-24",
                    "FECHA OPERACION": "2020-10-02",
                    "NOMBRES": "Luis Angel",
                    "APELLIDOS": "Barragan Alvarado",
                    "NOMBRES COMPLETOS": "Luis Angel Barragan Alvarado",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Ecuador",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Yessica Yulieth Quintero Martinez",
                    "CODIGO USUARIO": "barr800711",
                    "USUARIOFIVE9": "barr800711@siigo.com",
                    "USUARIO RED": "barr800711",
                    "CORREOELECTRONICO": "barr800711@siigo.com",
                    "STATION ID": "2606120",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yessica Yulieth Quintero Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014289069",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-03-09",
                    "NOMBRES": "Jesus David",
                    "APELLIDOS": "Contreras Bello",
                    "NOMBRES COMPLETOS": "Jesus David Contreras Bello",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Ximena Manrique Isaziga",
                    "CODIGO USUARIO": "cont801167",
                    "USUARIOFIVE9": "cont801167@siigo.com",
                    "USUARIO RED": "cont801167",
                    "CORREOELECTRONICO": "cont801167@siigo.com",
                    "STATION ID": "2606848",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Ximena Manrique Isaziga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013665071",
                    "FECHA INICIAL": "2021-01-20",
                    "FECHA OPERACION": "2021-01-21",
                    "NOMBRES": "Juanita",
                    "APELLIDOS": "Alvarado Larrota",
                    "NOMBRES COMPLETOS": "Juanita Alvarado Larrota",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Yuleidy Josefina Briceno Fernandez",
                    "CODIGO USUARIO": "alva801056",
                    "USUARIOFIVE9": "alva801056@siigo.com",
                    "USUARIO RED": "alva801056",
                    "CORREOELECTRONICO": "alva801056@siigo.com",
                    "STATION ID": "2606311",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Yuleidy Josefina Briceno Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016087294",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-03-09",
                    "NOMBRES": "Maria Camila",
                    "APELLIDOS": "Ruiz Uribe",
                    "NOMBRES COMPLETOS": "Maria Camila Ruiz Uribe",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Aleida Bibiana Garcia Suarez",
                    "CODIGO USUARIO": "ruiz801171",
                    "USUARIOFIVE9": "ruiz801171@siigo.com",
                    "USUARIO RED": "ruiz801171",
                    "CORREOELECTRONICO": "ruiz801171@siigo.com",
                    "STATION ID": "2606856",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Aleida Bibiana Garcia Suarez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098620239",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-03-09",
                    "NOMBRES": "Luz Adriana",
                    "APELLIDOS": "Florez Fuentes",
                    "NOMBRES COMPLETOS": "Luz Adriana Florez Fuentes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Yuleidy Josefina Briceno Fernandez",
                    "CODIGO USUARIO": "flor801169",
                    "USUARIOFIVE9": "flor801169@siigo.com",
                    "USUARIO RED": "flor801169",
                    "CORREOELECTRONICO": "flor801169@siigo.com",
                    "STATION ID": "2606849",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Yuleidy Josefina Briceno Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1310105745",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Luis Enrique",
                    "APELLIDOS": "Velez Delgado",
                    "NOMBRES COMPLETOS": "Luis Enrique Velez Delgado",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "vele801248",
                    "USUARIOFIVE9": "vele801248@siigo.com",
                    "USUARIO RED": "vele801248",
                    "CORREOELECTRONICO": "vele801248@siigo.com",
                    "STATION ID": "2606732",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "952228088",
                    "FECHA INICIAL": "2021-01-07",
                    "FECHA OPERACION": "2021-01-14",
                    "NOMBRES": "Maria De Los Angeles",
                    "APELLIDOS": "Gutierrez Correa",
                    "NOMBRES COMPLETOS": "Maria De Los Angeles Gutierrez Correa",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "guti801011",
                    "USUARIOFIVE9": "guti801011@siigo.com",
                    "USUARIO RED": "guti801011",
                    "CORREOELECTRONICO": "guti801011@siigo.com",
                    "STATION ID": "3895080",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1020776424",
                    "FECHA INICIAL": "2020-01-14",
                    "FECHA OPERACION": "2020-01-14",
                    "NOMBRES": "Johanna Marcela",
                    "APELLIDOS": "Guiza Moya",
                    "NOMBRES COMPLETOS": "Johanna Marcela Guiza Moya",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "guiz800160",
                    "USUARIOFIVE9": "guiz800160@siigo.com",
                    "USUARIO RED": "guiz800160",
                    "CORREOELECTRONICO": "guiz800160@siigo.com",
                    "STATION ID": "2606978",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "941025231",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-03-12",
                    "NOMBRES": "Marjorie Angelic",
                    "APELLIDOS": "Reyes Cedeno",
                    "NOMBRES COMPLETOS": "Marjorie Angelica Reyes Cedeno",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "reye801209",
                    "USUARIOFIVE9": "reye801209@siigo.com",
                    "USUARIO RED": "reye801209",
                    "CORREOELECTRONICO": "reye801209@siigo.com",
                    "STATION ID": "2606362",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52902885",
                    "FECHA INICIAL": "2021-03-10",
                    "FECHA OPERACION": "2021-03-16",
                    "NOMBRES": "Lady Janneth",
                    "APELLIDOS": "Cardenas Cardona",
                    "NOMBRES COMPLETOS": "Lady Janneth Cardenas Cardona",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Andrea Catalina Vega Gomez",
                    "CODIGO USUARIO": "card801212",
                    "USUARIOFIVE9": "card801212@siigo.com",
                    "USUARIO RED": "card801212",
                    "CORREOELECTRONICO": "card801212@siigo.com",
                    "STATION ID": "2606371",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andrea Catalina Vega Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018485514",
                    "FECHA INICIAL": "2021-03-10",
                    "FECHA OPERACION": "2021-03-16",
                    "NOMBRES": "Carlos Andres",
                    "APELLIDOS": "Rojas Montano",
                    "NOMBRES COMPLETOS": "Carlos Andres Rojas Montano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Andrea Catalina Vega Gomez",
                    "CODIGO USUARIO": "roja112199",
                    "USUARIOFIVE9": "roja112199@siigo.com",
                    "USUARIO RED": "roja112199",
                    "CORREOELECTRONICO": "roja112199@siigo.com",
                    "STATION ID": "2606659",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andrea Catalina Vega Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "921018628",
                    "FECHA INICIAL": "2018-05-25",
                    "FECHA OPERACION": "2021-03-22",
                    "NOMBRES": "Jorge Luis",
                    "APELLIDOS": "Salas Sarmiento",
                    "NOMBRES COMPLETOS": "Jorge Luis Salas Sarmiento",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Oscar Francisco Plaza Giler",
                    "CODIGO USUARIO": "sala800604",
                    "USUARIOFIVE9": "sala800604@siigo.com",
                    "USUARIO RED": "sala800604",
                    "CORREOELECTRONICO": "sala800604@siigo.com",
                    "STATION ID": "2606682",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Oscar Francisco Plaza Giler",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1113640439",
                    "FECHA INICIAL": "2021-03-23",
                    "FECHA OPERACION": "2021-03-29",
                    "NOMBRES": "Pedro Felipe",
                    "APELLIDOS": "Arana Lastra",
                    "NOMBRES COMPLETOS": "Pedro Felipe Arana Lastra",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Experience Manager",
                    "JEFE INMEDIATO": "Andrea Zuluaga Uribe",
                    "CODIGO USUARIO": "aran801224",
                    "USUARIOFIVE9": "aran801224@siigo.com",
                    "USUARIO RED": "aran801224",
                    "CORREOELECTRONICO": "aran801224@siigo.com",
                    "STATION ID": "2605992",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andrea Zuluaga Uribe",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "50920270",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Ana Gabriela",
                    "APELLIDOS": "Ferreira Alvarez",
                    "NOMBRES COMPLETOS": "Ana Gabriela Ferreira Alvarez",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Elias Jusid Machado",
                    "CODIGO USUARIO": "ferr801231",
                    "USUARIOFIVE9": "ferr801231@siigo.com",
                    "USUARIO RED": "ferr801231",
                    "CORREOELECTRONICO": "ferr801231@siigo.com",
                    "STATION ID": "2606100",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Elias Jusid Machado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "953561743",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Steven Snaider",
                    "APELLIDOS": "Romero Solorzano",
                    "NOMBRES COMPLETOS": "Steven Snaider Romero Solorzano",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "rome801249",
                    "USUARIOFIVE9": "rome801249@siigo.com",
                    "USUARIO RED": "rome801249",
                    "CORREOELECTRONICO": "rome801249@siigo.com",
                    "STATION ID": "2606730",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "954682985",
                    "FECHA INICIAL": "2020-07-17",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Ronald Miguel",
                    "APELLIDOS": "Rosales Palma",
                    "NOMBRES COMPLETOS": "Ronald Miguel Rosales Palma",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoracion",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "rosa800648",
                    "USUARIOFIVE9": "rosa800648@siigo.com",
                    "USUARIO RED": "rosa800648",
                    "CORREOELECTRONICO": "rosa800648@siigo.com",
                    "STATION ID": "2606223",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "606169670",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Maria Isabel",
                    "APELLIDOS": "Benavides Cedeno",
                    "NOMBRES COMPLETOS": "Maria Isabel Benavides Cedeno",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "bena801242",
                    "USUARIOFIVE9": "bena801242@siigo.com",
                    "USUARIO RED": "bena801242",
                    "CORREOELECTRONICO": "bena801242@siigo.com",
                    "STATION ID": "2606716",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "956572333",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Natasha Lissette",
                    "APELLIDOS": "Mora Vanegas",
                    "NOMBRES COMPLETOS": "Natasha Lissette Mora Vanegas",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "mora801245",
                    "USUARIOFIVE9": "mora801245@siigo.com",
                    "USUARIO RED": "mora801245",
                    "CORREOELECTRONICO": "mora801245@siigo.com",
                    "STATION ID": "2606725",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "930297643",
                    "FECHA INICIAL": "2020-07-17",
                    "FECHA OPERACION": "2020-11-13",
                    "NOMBRES": "Rafael Enrique",
                    "APELLIDOS": "Ponce Medina",
                    "NOMBRES COMPLETOS": "Rafael Enrique Ponce Medina",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "ponc800659",
                    "USUARIOFIVE9": "ponc800659@siigo.com",
                    "USUARIO RED": "ponc800659",
                    "CORREOELECTRONICO": "ponc800659@siigo.com",
                    "STATION ID": "2606221",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "953442415",
                    "FECHA INICIAL": "2021-03-24",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Veronica Estefania",
                    "APELLIDOS": "Guerrero Merchan",
                    "NOMBRES COMPLETOS": "Veronica Estefania Guerrero Merchan",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "guer801241",
                    "USUARIOFIVE9": "guer801241@siigo.com",
                    "USUARIO RED": "guer801241",
                    "CORREOELECTRONICO": "guer801241@siigo.com",
                    "STATION ID": "2606714",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "950306886",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Melanie Rachell",
                    "APELLIDOS": "Gavilanes Montoya",
                    "NOMBRES COMPLETOS": "Melanie Rachell Gavilanes Montoya",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "gavi801243",
                    "USUARIOFIVE9": "gavi801243@siigo.com",
                    "USUARIO RED": "gavi801243",
                    "CORREOELECTRONICO": "gavi801243@siigo.com",
                    "STATION ID": "2606717",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "926384389",
                    "FECHA INICIAL": "2021-01-04",
                    "FECHA OPERACION": "2021-01-19",
                    "NOMBRES": "William Jose",
                    "APELLIDOS": "Hidalgo Armijos",
                    "NOMBRES COMPLETOS": "William Jose Hidalgo Armijos",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Ticket Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "hida801012",
                    "USUARIOFIVE9": "hida801012@siigo.com",
                    "USUARIO RED": "hida801012",
                    "CORREOELECTRONICO": "hida801012@siigo.com",
                    "STATION ID": "2606305",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "927184226",
                    "FECHA INICIAL": "2021-03-24",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Yandry Uribe",
                    "APELLIDOS": "Jaramillo Payaroso",
                    "NOMBRES COMPLETOS": "Yandry Uribe Jaramillo Payaroso",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "jara801239",
                    "USUARIOFIVE9": "jara801239@siigo.com",
                    "USUARIO RED": "jara801239",
                    "CORREOELECTRONICO": "jara801239@siigo.com",
                    "STATION ID": "2606711",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "930763065",
                    "FECHA INICIAL": "2020-12-21",
                    "FECHA OPERACION": "2020-12-28",
                    "NOMBRES": "Walter Efrain",
                    "APELLIDOS": "Cuzco Caicedo",
                    "NOMBRES COMPLETOS": "Walter Efrain Cuzco Caicedo",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "cuzc800981",
                    "USUARIOFIVE9": "cuzc800981@siigo.com",
                    "USUARIO RED": "cuzc800981",
                    "CORREOELECTRONICO": "cuzc800981@siigo.com",
                    "STATION ID": "2606123",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "928281500",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-03-09",
                    "NOMBRES": "Patricia Alexandra",
                    "APELLIDOS": "Cepeda Mocha",
                    "NOMBRES COMPLETOS": "Patricia Alexandra Cepeda Mocha",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "cepe801201",
                    "USUARIOFIVE9": "cepe801201@siigo.com",
                    "USUARIO RED": "cepe801201",
                    "CORREOELECTRONICO": "cepe801201@siigo.com",
                    "STATION ID": "2606833",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "955473194",
                    "FECHA INICIAL": "2020-09-24",
                    "FECHA OPERACION": "2020-10-02",
                    "NOMBRES": "Piedad",
                    "APELLIDOS": "Vaca Sanchez",
                    "NOMBRES COMPLETOS": "Piedad Vaca Sanchez",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "vaca800718",
                    "USUARIOFIVE9": "vaca800718@siigo.com",
                    "USUARIO RED": "vaca800718",
                    "CORREOELECTRONICO": "vaca800718@siigo.com",
                    "STATION ID": "2606069",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "48245272",
                    "FECHA INICIAL": "2021-03-23",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Leofer",
                    "APELLIDOS": "Andino",
                    "NOMBRES COMPLETOS": "Leofer Andino",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Luis Ignacio Ferreira Duarte",
                    "CODIGO USUARIO": "andi801266",
                    "USUARIOFIVE9": "andi801266@siigo.com",
                    "USUARIO RED": "andi801266",
                    "CORREOELECTRONICO": "andi801266@siigo.com",
                    "STATION ID": "2606757",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Luis Ignacio Ferreira Duarte",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "48618568",
                    "FECHA INICIAL": "2021-03-23",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Fatima",
                    "APELLIDOS": "Segundo Toya",
                    "NOMBRES COMPLETOS": "Fatima Segundo Toya",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Luis Ignacio Ferreira Duarte",
                    "CODIGO USUARIO": "segu801271",
                    "USUARIOFIVE9": "segu801271@siigo.com",
                    "USUARIO RED": "segu801271",
                    "CORREOELECTRONICO": "segu801271@siigo.com",
                    "STATION ID": "2606766",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Luis Ignacio Ferreira Duarte",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "25989128",
                    "FECHA INICIAL": "2021-03-23",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Tania",
                    "APELLIDOS": "Menchaca Almeida",
                    "NOMBRES COMPLETOS": "Tania Menchaca Almeida",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Silvia Zilah Fernandez Almeida",
                    "CODIGO USUARIO": "mech801272",
                    "USUARIOFIVE9": "mech801272@siigo.com",
                    "USUARIO RED": "mech801272",
                    "CORREOELECTRONICO": "mech801272@siigo.com",
                    "STATION ID": "2606769",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Silvia Zilah Fernandez Almeida",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "48597839",
                    "FECHA INICIAL": "2021-03-23",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "German",
                    "APELLIDOS": "Pose",
                    "NOMBRES COMPLETOS": "German Pose",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Luis Ignacio Ferreira Duarte",
                    "CODIGO USUARIO": "pose801273",
                    "USUARIOFIVE9": "pose801273@siigo.com",
                    "USUARIO RED": "pose801273",
                    "CORREOELECTRONICO": "pose801273@siigo.com",
                    "STATION ID": "2606775",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Luis Ignacio Ferreira Duarte",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "46385252",
                    "FECHA INICIAL": "2021-03-23",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Camila",
                    "APELLIDOS": "Jalsevac",
                    "NOMBRES COMPLETOS": "Camila Jalsevac",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Silvia Zilah Fernandez Almeida",
                    "CODIGO USUARIO": "jals801277",
                    "USUARIOFIVE9": "jals801277@siigo.com",
                    "USUARIO RED": "jals801277",
                    "CORREOELECTRONICO": "jals801277@siigo.com",
                    "STATION ID": "2606830",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Silvia Zilah Fernandez Almeida",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "54404616",
                    "FECHA INICIAL": "2021-03-23",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Alexis",
                    "APELLIDOS": "Delgado Jaimes",
                    "NOMBRES COMPLETOS": "Alexis Delgado Jaimes",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Silvia Zilah Fernandez Almeida",
                    "CODIGO USUARIO": "delg801268",
                    "USUARIOFIVE9": "delg801268@siigo.com",
                    "USUARIO RED": "delg801268",
                    "CORREOELECTRONICO": "delg801268@siigo.com",
                    "STATION ID": "2606858",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Silvia Zilah Fernandez Almeida",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "48844032",
                    "FECHA INICIAL": "2021-03-23",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Jessica Gabriela",
                    "APELLIDOS": "Germano Suarez",
                    "NOMBRES COMPLETOS": "Jessica Gabriela Germano Suarez",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Silvia Zilah Fernandez Almeida",
                    "CODIGO USUARIO": "germ801270",
                    "USUARIOFIVE9": "germ801270@siigo.com",
                    "USUARIO RED": "germ801270",
                    "CORREOELECTRONICO": "germ801270@siigo.com",
                    "STATION ID": "2606871",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Silvia Zilah Fernandez Almeida",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143369164",
                    "FECHA INICIAL": "2021-04-07",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Luz Neyla",
                    "APELLIDOS": "Navas Paredes",
                    "NOMBRES COMPLETOS": "Luz Neyla Navas Paredes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Nicoll Torres Celemin",
                    "CODIGO USUARIO": "nava801279",
                    "USUARIOFIVE9": "nava801279@siigo.com",
                    "USUARIO RED": "nava801279",
                    "CORREOELECTRONICO": "nava801279@siigo.com",
                    "STATION ID": "2606884",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Nicoll Torres Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "65630848",
                    "FECHA INICIAL": "2010-01-18",
                    "FECHA OPERACION": "2010-01-18",
                    "NOMBRES": "Ninza Aidee",
                    "APELLIDOS": "Combita Nunez",
                    "NOMBRES COMPLETOS": "Ninza Aidee Combita Nunez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Juan Pablo Carreno Patino",
                    "CODIGO USUARIO": "comb239051",
                    "USUARIOFIVE9": "comb239051@siigo.com",
                    "USUARIO RED": "comb239051",
                    "CORREOELECTRONICO": "comb239051@siigo.com",
                    "STATION ID": "2606697",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Carreno Patino",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000517307",
                    "FECHA INICIAL": "2021-09-10",
                    "FECHA OPERACION": "2021-09-10",
                    "NOMBRES": "Laura Valentina",
                    "APELLIDOS": "Prieto Duenas",
                    "NOMBRES COMPLETOS": "Laura Valentina Prieto Duenas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "prie801839",
                    "USUARIOFIVE9": "prie801839@siigo.com",
                    "USUARIO RED": "prie801839",
                    "CORREOELECTRONICO": "prie801839@siigo.com",
                    "STATION ID": "3267917",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014211561",
                    "FECHA INICIAL": "2021-04-07",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Yuly Alexandra",
                    "APELLIDOS": "Balaguera Rodriguez",
                    "NOMBRES COMPLETOS": "Yuly Alexandra Balaguera Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jefrey Julian Sanchez Celemin",
                    "CODIGO USUARIO": "bala801283",
                    "USUARIOFIVE9": "bala801283@siigo.com",
                    "USUARIO RED": "bala801283",
                    "CORREOELECTRONICO": "bala801283@siigo.com",
                    "STATION ID": "2606918",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jefrey Julian Sanchez Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001184897",
                    "FECHA INICIAL": "2020-11-23",
                    "FECHA OPERACION": "2020-11-23",
                    "NOMBRES": "Johan Sebastian",
                    "APELLIDOS": "Montero Duarte",
                    "NOMBRES COMPLETOS": "Johan Sebastian Montero Duarte",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Maria Lucia Canales Parrales",
                    "CODIGO USUARIO": "mont800876",
                    "USUARIOFIVE9": "mont800876@siigo.com",
                    "USUARIO RED": "mont800876",
                    "CORREOELECTRONICO": "mont800876@siigo.com",
                    "STATION ID": "2606250",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Maria Lucia Canales Parrales",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1118866207",
                    "FECHA INICIAL": "2021-08-17",
                    "FECHA OPERACION": "2021-08-18",
                    "NOMBRES": "Jorge Mario",
                    "APELLIDOS": "Ortega Velasquez",
                    "NOMBRES COMPLETOS": "Jorge Mario Ortega Velasquez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Producto Back-Office",
                    "CARGO": "Bussiness Inteligence",
                    "JEFE INMEDIATO": "Juan Carlos Corazzo Santos",
                    "CODIGO USUARIO": "orte801690",
                    "USUARIOFIVE9": "orte801690@siigo.com",
                    "USUARIO RED": "orte801690",
                    "CORREOELECTRONICO": "orte801690@siigo.com",
                    "STATION ID": "3127547",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Carlos Corazzo Santos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1234092366",
                    "FECHA INICIAL": "2021-04-07",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Jose Fernando",
                    "APELLIDOS": "Palacio Manotas",
                    "NOMBRES COMPLETOS": "Jose Fernando Palacio Manotas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Fernando Sotto Cardozo",
                    "CODIGO USUARIO": "pala801287",
                    "USUARIOFIVE9": "pala801287@siigo.com",
                    "USUARIO RED": "pala801287",
                    "CORREOELECTRONICO": "pala801287@siigo.com",
                    "STATION ID": "2606924",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Fernando Sotto Cardozo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "55307136",
                    "FECHA INICIAL": "2021-06-11",
                    "FECHA OPERACION": "2021-06-11",
                    "NOMBRES": "Karina Maria",
                    "APELLIDOS": "Gomez Alvarez",
                    "NOMBRES COMPLETOS": "Karina Maria Gomez Alvarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luz Katerine Maya Mogollon",
                    "CODIGO USUARIO": "gome801502",
                    "USUARIOFIVE9": "gome801502@siigo.com",
                    "USUARIO RED": "gome801502",
                    "CORREOELECTRONICO": "gome801502@siigo.com",
                    "STATION ID": "2606941",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Katerine Maya Mogollon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143263414",
                    "FECHA INICIAL": "2021-04-07",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Stefanny",
                    "APELLIDOS": "Becerra Garcia",
                    "NOMBRES COMPLETOS": "Stefanny Becerra Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Fernando Sotto Cardozo",
                    "CODIGO USUARIO": "bece801292",
                    "USUARIOFIVE9": "bece801292@siigo.com",
                    "USUARIO RED": "bece801292",
                    "CORREOELECTRONICO": "bece801292@siigo.com",
                    "STATION ID": "2606939",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Fernando Sotto Cardozo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010227545",
                    "FECHA INICIAL": "2021-04-07",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Angie Daniela",
                    "APELLIDOS": "Alvarez Bohorquez",
                    "NOMBRES COMPLETOS": "Angie Daniela Alvarez Bohorquez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Leidy Stefany Castillo",
                    "CODIGO USUARIO": "alva801296",
                    "USUARIOFIVE9": "alva801296@siigo.com",
                    "USUARIO RED": "alva801296",
                    "CORREOELECTRONICO": "alva801296@siigo.com",
                    "STATION ID": "2606952",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Leidy Stefany Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53930236",
                    "FECHA INICIAL": "2021-04-07",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Luz Adriana",
                    "APELLIDOS": "Rey Villarraga",
                    "NOMBRES COMPLETOS": "Luz Adriana Rey Villarraga",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Colombia",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Olga Lucia Ospina Vidal",
                    "CODIGO USUARIO": "reyv801289",
                    "USUARIOFIVE9": "reyv801289@siigo.com",
                    "USUARIO RED": "reyv801289",
                    "CORREOELECTRONICO": "reyv801289@siigo.com",
                    "STATION ID": "2606932",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Olga Lucia Ospina Vidal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80749409",
                    "FECHA INICIAL": "2021-04-12",
                    "FECHA OPERACION": "2021-04-13",
                    "NOMBRES": "William Jesus",
                    "APELLIDOS": "Sotaquira Ayala",
                    "NOMBRES COMPLETOS": "William Jesus Sotaquira Ayala",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Director de Calidad",
                    "JEFE INMEDIATO": "Andrea Zuluaga Uribe",
                    "CODIGO USUARIO": "sota801302",
                    "USUARIOFIVE9": "sota801302@siigo.com",
                    "USUARIO RED": "sota801302",
                    "CORREOELECTRONICO": "sota801302@siigo.com",
                    "STATION ID": "2606020",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andrea Zuluaga Uribe",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015466266",
                    "FECHA INICIAL": "2021-04-12",
                    "FECHA OPERACION": "2021-04-13",
                    "NOMBRES": "Daniela",
                    "APELLIDOS": "Valderrama Pinzon",
                    "NOMBRES COMPLETOS": "Daniela Valderrama Pinzon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ventas",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "vald801310",
                    "USUARIOFIVE9": "vald801310@siigo.com",
                    "USUARIO RED": "vald801310",
                    "CORREOELECTRONICO": "vald801310@siigo.com",
                    "STATION ID": "2606107",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "927285254",
                    "FECHA INICIAL": "2019-08-01",
                    "FECHA OPERACION": "2021-04-14",
                    "NOMBRES": "Saul Steven",
                    "APELLIDOS": "Carpio Ochoa",
                    "NOMBRES COMPLETOS": "Saul Steven Carpio Ochoa",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Experience Manager",
                    "JEFE INMEDIATO": "Juan Jose Correa Acosta",
                    "CODIGO USUARIO": "carp800643",
                    "USUARIOFIVE9": "carp800643@siigo.com",
                    "USUARIO RED": "carp800643",
                    "CORREOELECTRONICO": "carp800643@siigo.com",
                    "STATION ID": "2605990",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Jose Correa Acosta",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015472391",
                    "FECHA INICIAL": "2021-04-19",
                    "FECHA OPERACION": "2021-04-19",
                    "NOMBRES": "Maria Alejandra",
                    "APELLIDOS": "Sanchez Cabrera",
                    "NOMBRES COMPLETOS": "Maria Alejandra Sanchez Cabrera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Luis Felipe Sanchez Gomez",
                    "CODIGO USUARIO": "sanc801213",
                    "USUARIOFIVE9": "sanc801213@siigo.com",
                    "USUARIO RED": "sanc801213",
                    "CORREOELECTRONICO": "sanc801213@siigo.com",
                    "STATION ID": "-",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Luis Felipe Sanchez Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019069244",
                    "FECHA INICIAL": "2021-04-16",
                    "FECHA OPERACION": "2021-04-19",
                    "NOMBRES": "Yesica Paola",
                    "APELLIDOS": "Duarte Moreno",
                    "NOMBRES COMPLETOS": "Yesica Paola Duarte Moreno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gustavo Adolfo Espinosa Toledo",
                    "CODIGO USUARIO": "duar801318",
                    "USUARIOFIVE9": "duar801318@siigo.com",
                    "USUARIO RED": "duar801318",
                    "CORREOELECTRONICO": "duar801318@siigo.com",
                    "STATION ID": "2606178",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gustavo Adolfo Espinosa Toledo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52757401",
                    "FECHA INICIAL": "2021-04-16",
                    "FECHA OPERACION": "2021-04-19",
                    "NOMBRES": "Adriana Cecilia",
                    "APELLIDOS": "Martinez Martinez",
                    "NOMBRES COMPLETOS": "Adriana Cecilia Martinez Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Buitrago Rios",
                    "CODIGO USUARIO": "mart801322",
                    "USUARIOFIVE9": "mart801322@siigo.com",
                    "USUARIO RED": "mart801322",
                    "CORREOELECTRONICO": "mart801322@siigo.com",
                    "STATION ID": "2606474",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Buitrago Rios",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1080261186",
                    "FECHA INICIAL": "2021-04-16",
                    "FECHA OPERACION": "2021-04-19",
                    "NOMBRES": "Claudia Milena",
                    "APELLIDOS": "Zambrano Pajoy",
                    "NOMBRES COMPLETOS": "Claudia Milena Zambrano Pajoy",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gustavo Adolfo Espinosa Toledo",
                    "CODIGO USUARIO": "zamb801324",
                    "USUARIOFIVE9": "zamb801324@siigo.com",
                    "USUARIO RED": "zamb801324",
                    "CORREOELECTRONICO": "zamb801324@siigo.com",
                    "STATION ID": "2606617",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gustavo Adolfo Espinosa Toledo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1100960543",
                    "FECHA INICIAL": "2021-04-17",
                    "FECHA OPERACION": "2021-04-19",
                    "NOMBRES": "Marcela",
                    "APELLIDOS": "Galviz Reyes",
                    "NOMBRES COMPLETOS": "Marcela Galviz Reyes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juvenal Reyes Silva",
                    "CODIGO USUARIO": "galv801342",
                    "USUARIOFIVE9": "galv801342@siigo.com",
                    "USUARIO RED": "galv801342",
                    "CORREOELECTRONICO": "galv801342@siigo.com",
                    "STATION ID": "2606981",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juvenal Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1069728049",
                    "FECHA INICIAL": "2021-04-17",
                    "FECHA OPERACION": "2021-04-19",
                    "NOMBRES": "Francy Julieth",
                    "APELLIDOS": "Alonso Vargas",
                    "NOMBRES COMPLETOS": "Francy Julieth Alonso Vargas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gustavo Adolfo Espinosa Toledo",
                    "CODIGO USUARIO": "alon801332",
                    "USUARIOFIVE9": "alon801332@siigo.com",
                    "USUARIO RED": "alon801332",
                    "CORREOELECTRONICO": "alon801332@siigo.com",
                    "STATION ID": "2606993",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gustavo Adolfo Espinosa Toledo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1065871686",
                    "FECHA INICIAL": "2021-04-16",
                    "FECHA OPERACION": "2021-04-19",
                    "NOMBRES": "Diana Marcela",
                    "APELLIDOS": "Rangel Onate",
                    "NOMBRES COMPLETOS": "Diana Marcela Rangel Onate",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Aguachica",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jesus Daniel Arzuza Roman",
                    "CODIGO USUARIO": "rang801336",
                    "USUARIOFIVE9": "rang801336@siigo.com",
                    "USUARIO RED": "rang801336",
                    "CORREOELECTRONICO": "rang801336@siigo.com",
                    "STATION ID": "2606998",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jesus Daniel Arzuza Roman",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016093036",
                    "FECHA INICIAL": "2021-04-22",
                    "FECHA OPERACION": "2021-04-22",
                    "NOMBRES": "Angie Lorena",
                    "APELLIDOS": "Salvo Tellez",
                    "NOMBRES COMPLETOS": "Angie Lorena Salvo Tellez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ventas",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "salv801350",
                    "USUARIOFIVE9": "salv801350@siigo.com",
                    "USUARIO RED": "salv801350",
                    "CORREOELECTRONICO": "salv801350@siigo.com",
                    "STATION ID": "2607014",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80874133",
                    "FECHA INICIAL": "2021-04-14",
                    "FECHA OPERACION": "2021-04-23",
                    "NOMBRES": "Yasser Mohamed",
                    "APELLIDOS": "Chain Martinez",
                    "NOMBRES COMPLETOS": "Yasser Mohamed Chain Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Administrativos",
                    "CARGO": "Ecommerce Manager",
                    "JEFE INMEDIATO": "Carlos German Lopez Murcia",
                    "CODIGO USUARIO": "chai801312",
                    "USUARIOFIVE9": "chai801312@siigo.com",
                    "USUARIO RED": "chai801312",
                    "CORREOELECTRONICO": "chai801312@siigo.com",
                    "STATION ID": "2607022",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Carlos German Lopez Murcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "952814259",
                    "FECHA INICIAL": "2021-04-23",
                    "FECHA OPERACION": "2021-04-23",
                    "NOMBRES": "Kevin Arthur",
                    "APELLIDOS": "Scott Brito",
                    "NOMBRES COMPLETOS": "Kevin Arthur Scott Brito",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Michelle Matamoros Del Rosario",
                    "CODIGO USUARIO": "scot801352",
                    "USUARIOFIVE9": "scot801352@siigo.com",
                    "USUARIO RED": "scot801352",
                    "CORREOELECTRONICO": "scot801352@siigo.com",
                    "STATION ID": "2607036",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Michelle Matamoros Del Rosario",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "50618944",
                    "FECHA INICIAL": "2021-05-03",
                    "FECHA OPERACION": "2021-05-03",
                    "NOMBRES": "Martin",
                    "APELLIDOS": "Arrillaga",
                    "NOMBRES COMPLETOS": "Martin Arrillaga",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Elias Jusid Machado",
                    "CODIGO USUARIO": "arri801395",
                    "USUARIOFIVE9": "arri801395@siigo.com",
                    "USUARIO RED": "arri801395",
                    "CORREOELECTRONICO": "arri801395@siigo.com",
                    "STATION ID": "2606355",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Elias Jusid Machado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "37900231",
                    "FECHA INICIAL": "2008-10-20",
                    "FECHA OPERACION": "2021-03-08",
                    "NOMBRES": "Mathias",
                    "APELLIDOS": "Kantor",
                    "NOMBRES COMPLETOS": "Mathias Kantor",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "Call Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Elias Jusid Machado",
                    "CODIGO USUARIO": "kant801109",
                    "USUARIOFIVE9": "kant801109@siigo.com",
                    "USUARIO RED": "kant801109",
                    "CORREOELECTRONICO": "kant801109@siigo.com",
                    "STATION ID": "2606554",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Elias Jusid Machado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53029679",
                    "FECHA INICIAL": "2020-06-10",
                    "FECHA OPERACION": "2021-03-08",
                    "NOMBRES": "Maximiliano",
                    "APELLIDOS": "Rodriguez",
                    "NOMBRES COMPLETOS": "Maximiliano Rodriguez",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "Call Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alcides Martin Salas Orozco",
                    "CODIGO USUARIO": "rodr801114",
                    "USUARIOFIVE9": "rodr801114@siigo.com",
                    "USUARIO RED": "rodr801114",
                    "CORREOELECTRONICO": "rodr801114@siigo.com",
                    "STATION ID": "2606505",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alcides Martin Salas Orozco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "47457185",
                    "FECHA INICIAL": "2021-05-03",
                    "FECHA OPERACION": "2021-05-03",
                    "NOMBRES": "Diego",
                    "APELLIDOS": "Arobba",
                    "NOMBRES COMPLETOS": "Diego Arobba",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "Call Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alcides Martin Salas Orozco",
                    "CODIGO USUARIO": "arob801393",
                    "USUARIOFIVE9": "arob801393@siigo.com",
                    "USUARIO RED": "arob801393",
                    "CORREOELECTRONICO": "arob801393@siigo.com",
                    "STATION ID": "2606749",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alcides Martin Salas Orozco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "72268918",
                    "FECHA INICIAL": "2021-05-03",
                    "FECHA OPERACION": "2021-05-03",
                    "NOMBRES": "Waldemar",
                    "APELLIDOS": "Herrera Rico",
                    "NOMBRES COMPLETOS": "Waldemar Herrera Rico",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Andrea Catalina Vega Gomez",
                    "CODIGO USUARIO": "herr801384",
                    "USUARIOFIVE9": "herr801384@siigo.com",
                    "USUARIO RED": "herr801384",
                    "CORREOELECTRONICO": "herr801384@siigo.com",
                    "STATION ID": "2606367",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andrea Catalina Vega Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023979425",
                    "FECHA INICIAL": "2021-09-10",
                    "FECHA OPERACION": "2021-09-10",
                    "NOMBRES": "Karoll Dayanna",
                    "APELLIDOS": "Gallego Preciado",
                    "NOMBRES COMPLETOS": "Karoll Dayanna Gallego Preciado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Fernando Sotto Cardozo",
                    "CODIGO USUARIO": "gall801835",
                    "USUARIOFIVE9": "gall801835@siigo.com",
                    "USUARIO RED": "gall801835",
                    "CORREOELECTRONICO": "gall801835@siigo.com",
                    "STATION ID": "3267913",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Fernando Sotto Cardozo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012404507",
                    "FECHA INICIAL": "2021-05-03",
                    "FECHA OPERACION": "2021-05-03",
                    "NOMBRES": "Paula Andrea",
                    "APELLIDOS": "Ortiz Rodriguez",
                    "NOMBRES COMPLETOS": "Paula Andrea Ortiz Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Edith Angelica Gil Umbarila",
                    "CODIGO USUARIO": "orti801385",
                    "USUARIOFIVE9": "orti801385@siigo.com",
                    "USUARIO RED": "orti801385",
                    "CORREOELECTRONICO": "orti801385@siigo.com",
                    "STATION ID": "-",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Edith Angelica Gil Umbarila",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1004349664",
                    "FECHA INICIAL": "2021-10-07",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Yisel Paola",
                    "APELLIDOS": "Polo Alcazar",
                    "NOMBRES COMPLETOS": "Yisel Paola Polo Alcazar",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Santa Marta",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones ECU",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Angie Camila Diaz Escobar",
                    "CODIGO USUARIO": "polo802036",
                    "USUARIOFIVE9": "polo802036@siigo.com",
                    "USUARIO RED": "polo802036",
                    "CORREOELECTRONICO": "polo802036@siigo.com",
                    "STATION ID": "3483702",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Camila Diaz Escobar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000347588",
                    "FECHA INICIAL": "2021-10-15",
                    "FECHA OPERACION": "2021-10-20",
                    "NOMBRES": "Valentina",
                    "APELLIDOS": "Gomez Vanegas",
                    "NOMBRES COMPLETOS": "Valentina Gomez Vanegas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Yeimy Milena Bustos Gutierrez",
                    "CODIGO USUARIO": "gome802102",
                    "USUARIOFIVE9": "gome802102@siigo.com",
                    "USUARIO RED": "gome802102",
                    "CORREOELECTRONICO": "gome802102@siigo.com",
                    "STATION ID": "3690246",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yeimy Milena Bustos Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "925995045",
                    "FECHA INICIAL": "2021-05-05",
                    "FECHA OPERACION": "2021-05-05",
                    "NOMBRES": "Priscilla Alexandra",
                    "APELLIDOS": "Coello Delgado",
                    "NOMBRES COMPLETOS": "Priscilla Alexandra Coello Delgado",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "coel800661",
                    "USUARIOFIVE9": "coel800661@siigo.com",
                    "USUARIO RED": "coel800661",
                    "CORREOELECTRONICO": "coel800661@siigo.com",
                    "STATION ID": "2607076",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "920687597",
                    "FECHA INICIAL": "2021-05-06",
                    "FECHA OPERACION": "2021-05-06",
                    "NOMBRES": "Diana Elizabeth",
                    "APELLIDOS": "Davila Murillo",
                    "NOMBRES COMPLETOS": "Diana Elizabeth Davila Murillo",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Contabilidad",
                    "SUBAREA": "Contabilidad",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Sergio Alberto Caicedo Ramirez",
                    "CODIGO USUARIO": "davi800572",
                    "USUARIOFIVE9": "davi800572@siigo.com",
                    "USUARIO RED": "davi800572",
                    "CORREOELECTRONICO": "davi800572@siigo.com",
                    "STATION ID": "2607089",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Contabilidad_Sergio Alberto Caicedo Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "917243008",
                    "FECHA INICIAL": "2021-05-06",
                    "FECHA OPERACION": "2021-05-06",
                    "NOMBRES": "Karla Cristina",
                    "APELLIDOS": "Aurea Rendon",
                    "NOMBRES COMPLETOS": "Karla Cristina Aurea Rendon",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Contabilidad",
                    "SUBAREA": "Contabilidad",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Sergio Alberto Caicedo Ramirez",
                    "CODIGO USUARIO": "aure800882",
                    "USUARIOFIVE9": "aure800882@siigo.com",
                    "USUARIO RED": "aure800882",
                    "CORREOELECTRONICO": "aure800882@siigo.com",
                    "STATION ID": "2607103",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Contabilidad_Sergio Alberto Caicedo Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "10545489",
                    "FECHA INICIAL": "2019-11-12",
                    "FECHA OPERACION": "2019-11-12",
                    "NOMBRES": "Efrain Fernandez",
                    "APELLIDOS": "Trujillo",
                    "NOMBRES COMPLETOS": "Efrain Fernandez Trujillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Nicoll Torres Celemin",
                    "CODIGO USUARIO": "fern112512",
                    "USUARIOFIVE9": "fern112512@siigo.com",
                    "USUARIO RED": "fern112512",
                    "CORREOELECTRONICO": "fern112512@siigo.com",
                    "STATION ID": "2606738",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Nicoll Torres Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019020614",
                    "FECHA INICIAL": "2021-08-03",
                    "FECHA OPERACION": "2021-08-05",
                    "NOMBRES": "John Walter",
                    "APELLIDOS": "Suarez Sotelo",
                    "NOMBRES COMPLETOS": "John Walter Suarez Sotelo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Sory Yaqueline Abello Polo",
                    "CODIGO USUARIO": "suar801634",
                    "USUARIOFIVE9": "suar801634@siigo.com",
                    "USUARIO RED": "suar801634",
                    "CORREOELECTRONICO": "suar801634@siigo.com",
                    "STATION ID": "3127539",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sory Yaqueline Abello Polo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019148536",
                    "FECHA INICIAL": "2020-02-10",
                    "FECHA OPERACION": "2020-02-10",
                    "NOMBRES": "Juan David",
                    "APELLIDOS": "Robayo Acosta",
                    "NOMBRES COMPLETOS": "Juan David Robayo Acosta",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Maria Lucia Canales Parrales",
                    "CODIGO USUARIO": "roba800243",
                    "USUARIOFIVE9": "roba800243@siigo.com",
                    "USUARIO RED": "roba800243",
                    "CORREOELECTRONICO": "roba800243@siigo.com",
                    "STATION ID": "2607016",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Maria Lucia Canales Parrales",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000615794",
                    "FECHA INICIAL": "2021-01-01",
                    "FECHA OPERACION": "2021-01-01",
                    "NOMBRES": "Leidy Natalia",
                    "APELLIDOS": "Pena Ayala",
                    "NOMBRES COMPLETOS": "Leidy Natalia Pena Ayala",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Cristian Camilo Hernandez Gonzalez",
                    "CODIGO USUARIO": "pena800998",
                    "USUARIOFIVE9": "pena800998@siigo.com",
                    "USUARIO RED": "pena800998",
                    "CORREOELECTRONICO": "pena800998@siigo.com",
                    "STATION ID": "2606276",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Cristian Camilo Hernandez Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1140856553",
                    "FECHA INICIAL": "2021-05-06",
                    "FECHA OPERACION": "2021-05-06",
                    "NOMBRES": "Karen Lorena",
                    "APELLIDOS": "Arroyo Guerra",
                    "NOMBRES COMPLETOS": "Karen Lorena Arroyo Guerra",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Eduardo Sanchez Castano",
                    "CODIGO USUARIO": "arro801405",
                    "USUARIOFIVE9": "arro801405@siigo.com",
                    "USUARIO RED": "arro801405",
                    "CORREOELECTRONICO": "arro801405@siigo.com",
                    "STATION ID": "3127459",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Eduardo Sanchez Castano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1070624656",
                    "FECHA INICIAL": "2020-11-23",
                    "FECHA OPERACION": "2020-11-23",
                    "NOMBRES": "Maria Camila",
                    "APELLIDOS": "Santos Nunez",
                    "NOMBRES COMPLETOS": "Maria Camila Santos Nunez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Carolina Linares Gonzalez",
                    "CODIGO USUARIO": "sant800878",
                    "USUARIOFIVE9": "sant800878@siigo.com",
                    "USUARIO RED": "sant800878",
                    "CORREOELECTRONICO": "sant800878@siigo.com",
                    "STATION ID": "2606253",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Carolina Linares Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1045759235",
                    "FECHA INICIAL": "2021-05-06",
                    "FECHA OPERACION": "2021-05-06",
                    "NOMBRES": "Maria Paula",
                    "APELLIDOS": "Del Villar De La Oz",
                    "NOMBRES COMPLETOS": "Maria Paula Del Villar De La Oz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Carolina Linares Gonzalez",
                    "CODIGO USUARIO": "delv801407",
                    "USUARIOFIVE9": "delv801407@siigo.com",
                    "USUARIO RED": "delv801407",
                    "CORREOELECTRONICO": "delv801407@siigo.com",
                    "STATION ID": "3127461",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Carolina Linares Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "28151938",
                    "FECHA INICIAL": "2011-05-24",
                    "FECHA OPERACION": "2011-05-24",
                    "NOMBRES": "Angelica Maria",
                    "APELLIDOS": "Ascanio Rueda",
                    "NOMBRES COMPLETOS": "Angelica Maria Ascanio Rueda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Enrique Varela Lasso",
                    "CODIGO USUARIO": "asca14300",
                    "USUARIOFIVE9": "asca14300@siigo.com",
                    "USUARIO RED": "asca14300",
                    "CORREOELECTRONICO": "asca14300@siigo.com",
                    "STATION ID": "2606700",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Enrique Varela Lasso",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "63490108",
                    "FECHA INICIAL": "2007-05-30",
                    "FECHA OPERACION": "2007-05-30",
                    "NOMBRES": "Belsy Silva",
                    "APELLIDOS": "Mendez",
                    "NOMBRES COMPLETOS": "Belsy Silva Mendez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Juan Pablo Carreno Patino",
                    "CODIGO USUARIO": "silv220528",
                    "USUARIOFIVE9": "silv220528@siigo.com",
                    "USUARIO RED": "silv220528",
                    "CORREOELECTRONICO": "silv220528@siigo.com",
                    "STATION ID": "2606701",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Carreno Patino",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143163436",
                    "FECHA INICIAL": "2021-09-08",
                    "FECHA OPERACION": "2021-09-08",
                    "NOMBRES": "Jose Gregorio",
                    "APELLIDOS": "Ospino Sandoval",
                    "NOMBRES COMPLETOS": "Jose Gregorio Ospino Sandoval",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Enrique Varela Lasso",
                    "CODIGO USUARIO": "ospi801853",
                    "USUARIOFIVE9": "ospi801853@siigo.com",
                    "USUARIO RED": "ospi801853",
                    "CORREOELECTRONICO": "ospi801853@siigo.com",
                    "STATION ID": "3267888",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Enrique Varela Lasso",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022394721",
                    "FECHA INICIAL": "2021-05-06",
                    "FECHA OPERACION": "2021-05-06",
                    "NOMBRES": "Linna Tatiana",
                    "APELLIDOS": "Gamboa Ramirez",
                    "NOMBRES COMPLETOS": "Linna Tatiana Gamboa Ramirez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Jazbleidy Andrea Nunez Montoya",
                    "CODIGO USUARIO": "gamb801402",
                    "USUARIOFIVE9": "gamb801402@siigo.com",
                    "USUARIO RED": "gamb801402",
                    "CORREOELECTRONICO": "gamb801402@siigo.com",
                    "STATION ID": "3127456",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jazbleidy Andrea Nunez Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80183109",
                    "FECHA INICIAL": "2020-02-17",
                    "FECHA OPERACION": "2020-02-17",
                    "NOMBRES": "Luis Alberto",
                    "APELLIDOS": "Ceron Velasco",
                    "NOMBRES COMPLETOS": "Luis Alberto Ceron Velasco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Nicoll Torres Celemin",
                    "CODIGO USUARIO": "cero800297",
                    "USUARIOFIVE9": "cero800297@siigo.com",
                    "USUARIO RED": "cero800297",
                    "CORREOELECTRONICO": "cero800297@siigo.com",
                    "STATION ID": "2607088",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Nicoll Torres Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80832182",
                    "FECHA INICIAL": "2021-05-06",
                    "FECHA OPERACION": "2021-05-06",
                    "NOMBRES": "Cristian Estiven",
                    "APELLIDOS": "Becerra Sanabria",
                    "NOMBRES COMPLETOS": "Cristian Estiven Becerra Sanabria",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Buitrago Rios",
                    "CODIGO USUARIO": "bece801388",
                    "USUARIOFIVE9": "bece801388@siigo.com",
                    "USUARIO RED": "bece801388",
                    "CORREOELECTRONICO": "bece801388@siigo.com",
                    "STATION ID": "3127470",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Buitrago Rios",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016040727",
                    "FECHA INICIAL": "2021-05-06",
                    "FECHA OPERACION": "2021-05-06",
                    "NOMBRES": "Yeferson Estiben",
                    "APELLIDOS": "Machado Gaviria",
                    "NOMBRES COMPLETOS": "Yeferson Estiben Machado Gaviria",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juliana Castro Diaz",
                    "CODIGO USUARIO": "mach801386",
                    "USUARIOFIVE9": "mach801386@siigo.com",
                    "USUARIO RED": "mach801386",
                    "CORREOELECTRONICO": "mach801386@siigo.com",
                    "STATION ID": "3127472",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juliana Castro Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52359809",
                    "FECHA INICIAL": "2021-05-06",
                    "FECHA OPERACION": "2021-05-06",
                    "NOMBRES": "Maria Alexandra",
                    "APELLIDOS": "Salazar Velasquez",
                    "NOMBRES COMPLETOS": "Maria Alexandra Salazar Velasquez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juliana Castro Diaz",
                    "CODIGO USUARIO": "sala801349",
                    "USUARIOFIVE9": "sala801349@siigo.com",
                    "USUARIO RED": "sala801349",
                    "CORREOELECTRONICO": "sala801349@siigo.com",
                    "STATION ID": "3127478",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juliana Castro Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032407367",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-03-09",
                    "NOMBRES": "Oscar Leonardo",
                    "APELLIDOS": "Patino Perez",
                    "NOMBRES COMPLETOS": "Oscar Leonardo Patino Perez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Yuleidy Josefina Briceno Fernandez",
                    "CODIGO USUARIO": "pati801170",
                    "USUARIOFIVE9": "pati801170@siigo.com",
                    "USUARIO RED": "pati801170",
                    "CORREOELECTRONICO": "pati801170@siigo.com",
                    "STATION ID": "2606850",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Yuleidy Josefina Briceno Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52807185",
                    "FECHA INICIAL": "2021-05-13",
                    "FECHA OPERACION": "2021-05-13",
                    "NOMBRES": "Magaly",
                    "APELLIDOS": "Cubillos Diaz",
                    "NOMBRES COMPLETOS": "Magaly Cubillos Diaz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Aleida Bibiana Garcia Suarez",
                    "CODIGO USUARIO": "cubi801439",
                    "USUARIOFIVE9": "cubi801439@siigo.com",
                    "USUARIO RED": "cubi801439",
                    "CORREOELECTRONICO": "cubi801439@siigo.com",
                    "STATION ID": "2606879",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Aleida Bibiana Garcia Suarez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52184029",
                    "FECHA INICIAL": "2021-05-12",
                    "FECHA OPERACION": "2021-05-12",
                    "NOMBRES": "Yomaira Yazmin",
                    "APELLIDOS": "Moreno Munoz",
                    "NOMBRES COMPLETOS": "Yomaira Yazmin Moreno Munoz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Silvia Zilah Fernandez Almeida",
                    "CODIGO USUARIO": "more801455",
                    "USUARIOFIVE9": "more801455@siigo.com",
                    "USUARIO RED": "more801455",
                    "CORREOELECTRONICO": "more801455@siigo.com",
                    "STATION ID": "2606383",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Silvia Zilah Fernandez Almeida",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019067098",
                    "FECHA INICIAL": "2021-05-12",
                    "FECHA OPERACION": "2021-05-12",
                    "NOMBRES": "Dayan Yineth",
                    "APELLIDOS": "Chaparro Pabon",
                    "NOMBRES COMPLETOS": "Dayan Yineth Chaparro Pabon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Ximena Manrique Isaziga",
                    "CODIGO USUARIO": "chap801453",
                    "USUARIOFIVE9": "chap801453@siigo.com",
                    "USUARIO RED": "chap801453",
                    "CORREOELECTRONICO": "chap801453@siigo.com",
                    "STATION ID": "2606431",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Ximena Manrique Isaziga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019109634",
                    "FECHA INICIAL": "2021-05-13",
                    "FECHA OPERACION": "2021-05-13",
                    "NOMBRES": "Lina Vanessa",
                    "APELLIDOS": "Espinosa Poveda",
                    "NOMBRES COMPLETOS": "Lina Vanessa Espinosa Poveda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Nataly Carolina Gonzalez Sandoval",
                    "CODIGO USUARIO": "espi801452",
                    "USUARIOFIVE9": "espi801452@siigo.com",
                    "USUARIO RED": "espi801452",
                    "CORREOELECTRONICO": "espi801452@siigo.com",
                    "STATION ID": "2606435",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Nataly Carolina Gonzalez Sandoval",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015405358",
                    "FECHA INICIAL": "2021-05-13",
                    "FECHA OPERACION": "2021-05-13",
                    "NOMBRES": "Jenny Cristina",
                    "APELLIDOS": "Moreno Bonilla",
                    "NOMBRES COMPLETOS": "Jenny Cristina Moreno Bonilla",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "more801450",
                    "USUARIOFIVE9": "more801450@siigo.com",
                    "USUARIO RED": "more801450",
                    "CORREOELECTRONICO": "more801450@siigo.com",
                    "STATION ID": "2606514",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014239011",
                    "FECHA INICIAL": "2021-05-13",
                    "FECHA OPERACION": "2021-05-13",
                    "NOMBRES": "Javier Steev",
                    "APELLIDOS": "Rojas Franco",
                    "NOMBRES COMPLETOS": "Javier Steev Rojas Franco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "roja801449",
                    "USUARIOFIVE9": "roja801449@siigo.com",
                    "USUARIO RED": "roja801449",
                    "CORREOELECTRONICO": "roja801449@siigo.com",
                    "STATION ID": "2606524",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033777231",
                    "FECHA INICIAL": "2021-05-13",
                    "FECHA OPERACION": "2021-05-13",
                    "NOMBRES": "Luisa Fernanda",
                    "APELLIDOS": "Ariza",
                    "NOMBRES COMPLETOS": "Luisa Fernanda Ariza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Ximena Manrique Isaziga",
                    "CODIGO USUARIO": "ariz801444",
                    "USUARIOFIVE9": "ariz801444@siigo.com",
                    "USUARIO RED": "ariz801444",
                    "CORREOELECTRONICO": "ariz801444@siigo.com",
                    "STATION ID": "2606722",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Ximena Manrique Isaziga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52267762",
                    "FECHA INICIAL": "2020-02-24",
                    "FECHA OPERACION": "2020-02-24",
                    "NOMBRES": "Sandra Bibiana",
                    "APELLIDOS": "Preciado Vera",
                    "NOMBRES COMPLETOS": "Sandra Bibiana Preciado Vera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Yuleidy Josefina Briceno Fernandez",
                    "CODIGO USUARIO": "prec800309",
                    "USUARIOFIVE9": "prec800309@siigo.com",
                    "USUARIO RED": "prec800309",
                    "CORREOELECTRONICO": "prec800309@siigo.com",
                    "STATION ID": "2607031",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Yuleidy Josefina Briceno Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1118198578",
                    "FECHA INICIAL": "2021-05-13",
                    "FECHA OPERACION": "2021-05-13",
                    "NOMBRES": "Maikol Fernando",
                    "APELLIDOS": "Bohorquez Garcia",
                    "NOMBRES COMPLETOS": "Maikol Fernando Bohorquez Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Ximena Manrique Isaziga",
                    "CODIGO USUARIO": "boho801441",
                    "USUARIOFIVE9": "boho801441@siigo.com",
                    "USUARIO RED": "boho801441",
                    "CORREOELECTRONICO": "boho801441@siigo.com",
                    "STATION ID": "2606835",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Ximena Manrique Isaziga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "43149141",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-03",
                    "NOMBRES": "Nancy Rosa",
                    "APELLIDOS": "Anaya Velasquez",
                    "NOMBRES COMPLETOS": "Nancy Rosa Anaya Velasquez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jessica Paola Paez Quintero",
                    "CODIGO USUARIO": "anay800926",
                    "USUARIOFIVE9": "anay800926@siigo.com",
                    "USUARIO RED": "anay800926",
                    "CORREOELECTRONICO": "anay800926@siigo.com",
                    "STATION ID": "2606352",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jessica Paola Paez Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030550498",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-03",
                    "NOMBRES": "Sindy Lorena",
                    "APELLIDOS": "Ospitia Gonzalez",
                    "NOMBRES COMPLETOS": "Sindy Lorena Ospitia Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Yuleidy Josefina Briceno Fernandez",
                    "CODIGO USUARIO": "ospi800928",
                    "USUARIOFIVE9": "ospi800928@siigo.com",
                    "USUARIO RED": "ospi800928",
                    "CORREOELECTRONICO": "ospi800928@siigo.com",
                    "STATION ID": "2606353",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Yuleidy Josefina Briceno Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013657278",
                    "FECHA INICIAL": "2021-05-13",
                    "FECHA OPERACION": "2021-05-13",
                    "NOMBRES": "Maria Alejandra",
                    "APELLIDOS": "Tapiero Coro",
                    "NOMBRES COMPLETOS": "Maria Alejandra Tapiero Coro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Liliana Andrea Segura Martinez",
                    "CODIGO USUARIO": "tapi801436",
                    "USUARIOFIVE9": "tapi801436@siigo.com",
                    "USUARIO RED": "tapi801436",
                    "CORREOELECTRONICO": "tapi801436@siigo.com",
                    "STATION ID": "2607110",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Andrea Segura Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1044432038",
                    "FECHA INICIAL": "2021-05-13",
                    "FECHA OPERACION": "2021-05-13",
                    "NOMBRES": "Daniela Alexandra",
                    "APELLIDOS": "Cure Labao",
                    "NOMBRES COMPLETOS": "Daniela Alexandra Cure Labao",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Ximena Manrique Isaziga",
                    "CODIGO USUARIO": "cure801434",
                    "USUARIOFIVE9": "cure801434@siigo.com",
                    "USUARIO RED": "cure801434",
                    "CORREOELECTRONICO": "cure801434@siigo.com",
                    "STATION ID": "3127480",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Ximena Manrique Isaziga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1042454971",
                    "FECHA INICIAL": "2021-05-13",
                    "FECHA OPERACION": "2021-05-13",
                    "NOMBRES": "Paula Andrea",
                    "APELLIDOS": "Barbosa Borja",
                    "NOMBRES COMPLETOS": "Paula Andrea Barbosa Borja",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "barb801433",
                    "USUARIOFIVE9": "barb801433@siigo.com",
                    "USUARIO RED": "barb801433",
                    "CORREOELECTRONICO": "barb801433@siigo.com",
                    "STATION ID": "3127481",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1140884122",
                    "FECHA INICIAL": "2021-05-13",
                    "FECHA OPERACION": "2021-05-13",
                    "NOMBRES": "Aleyne Paola",
                    "APELLIDOS": "Pineda Coronado",
                    "NOMBRES COMPLETOS": "Aleyne Paola Pineda Coronado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "pine801432",
                    "USUARIOFIVE9": "pine801432@siigo.com",
                    "USUARIO RED": "pine801432",
                    "CORREOELECTRONICO": "pine801432@siigo.com",
                    "STATION ID": "3127482",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015412505",
                    "FECHA INICIAL": "2021-05-13",
                    "FECHA OPERACION": "2021-05-13",
                    "NOMBRES": "Alba Liliana",
                    "APELLIDOS": "Parra Castiblanco",
                    "NOMBRES COMPLETOS": "Alba Liliana Parra Castiblanco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Ximena Manrique Isaziga",
                    "CODIGO USUARIO": "parr801429",
                    "USUARIOFIVE9": "parr801429@siigo.com",
                    "USUARIO RED": "parr801429",
                    "CORREOELECTRONICO": "parr801429@siigo.com",
                    "STATION ID": "3127485",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Ximena Manrique Isaziga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1095823486",
                    "FECHA INICIAL": "2021-05-13",
                    "FECHA OPERACION": "2021-05-13",
                    "NOMBRES": "Yamile",
                    "APELLIDOS": "Perez Pabon",
                    "NOMBRES COMPLETOS": "Yamile Perez Pabon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Yuleidy Josefina Briceno Fernandez",
                    "CODIGO USUARIO": "pere801438",
                    "USUARIOFIVE9": "pere801438@siigo.com",
                    "USUARIO RED": "pere801438",
                    "CORREOELECTRONICO": "pere801438@siigo.com",
                    "STATION ID": "2607024",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Yuleidy Josefina Briceno Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52878914",
                    "FECHA INICIAL": "2021-05-13",
                    "FECHA OPERACION": "2021-05-13",
                    "NOMBRES": "Yury Andrea",
                    "APELLIDOS": "Brinez Brinez",
                    "NOMBRES COMPLETOS": "Yury Andrea Brinez Brinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "brin801422",
                    "USUARIOFIVE9": "brin801422@siigo.com",
                    "USUARIO RED": "brin801422",
                    "CORREOELECTRONICO": "brin801422@siigo.com",
                    "STATION ID": "3127492",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022976301",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-03",
                    "NOMBRES": "Wendy Johanna",
                    "APELLIDOS": "Carreno Molina",
                    "NOMBRES COMPLETOS": "Wendy Johanna Carreno Molina",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jessica Paola Paez Quintero",
                    "CODIGO USUARIO": "carr800893",
                    "USUARIOFIVE9": "carr800893@siigo.com",
                    "USUARIO RED": "carr800893",
                    "CORREOELECTRONICO": "carr800893@siigo.com",
                    "STATION ID": "2606332",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jessica Paola Paez Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007288880",
                    "FECHA INICIAL": "2021-05-26",
                    "FECHA OPERACION": "2021-05-26",
                    "NOMBRES": "Sandy Daniela",
                    "APELLIDOS": "Mendez Calderon",
                    "NOMBRES COMPLETOS": "Sandy Daniela Mendez Calderon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Fundacion",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "mend801463",
                    "USUARIOFIVE9": "mend801463@siigo.com",
                    "USUARIO RED": "mend801463",
                    "CORREOELECTRONICO": "mend801463@siigo.com",
                    "STATION ID": "2606016",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "3977927",
                    "FECHA INICIAL": "2020-02-24",
                    "FECHA OPERACION": "2020-02-24",
                    "NOMBRES": "Yuleidy Josefina",
                    "APELLIDOS": "Briceno Fernandez",
                    "NOMBRES COMPLETOS": "Yuleidy Josefina Briceno Fernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Hollman Antonio Aponte Quiroga",
                    "CODIGO USUARIO": "bric800330",
                    "USUARIOFIVE9": "bric800330@siigo.com",
                    "USUARIO RED": "bric800330",
                    "CORREOELECTRONICO": "bric800330@siigo.com",
                    "STATION ID": "2607075",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Hollman Antonio Aponte Quiroga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022442245",
                    "FECHA INICIAL": "2021-10-15",
                    "FECHA OPERACION": "2021-10-20",
                    "NOMBRES": "Ingrid Paola",
                    "APELLIDOS": "Wilchez Leal",
                    "NOMBRES COMPLETOS": "Ingrid Paola Wilchez Leal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Johanna Patricia Torres Quintero",
                    "CODIGO USUARIO": "wilc802105",
                    "USUARIOFIVE9": "wilc802105@siigo.com",
                    "USUARIO RED": "wilc802105",
                    "CORREOELECTRONICO": "wilc802105@siigo.com",
                    "STATION ID": "3690249",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Johanna Patricia Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007311689",
                    "FECHA INICIAL": "2021-06-09",
                    "FECHA OPERACION": "2021-06-09",
                    "NOMBRES": "Yesica Alejandra",
                    "APELLIDOS": "Zabala Culm",
                    "NOMBRES COMPLETOS": "Yesica Alejandra Zabala Culm",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Yeimy Milena Bustos Gutierrez",
                    "CODIGO USUARIO": "zaba801490",
                    "USUARIOFIVE9": "zaba801490@siigo.com",
                    "USUARIO RED": "zaba801490",
                    "CORREOELECTRONICO": "zaba801490@siigo.com",
                    "STATION ID": "2606176",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yeimy Milena Bustos Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098818175",
                    "FECHA INICIAL": "2021-08-03",
                    "FECHA OPERACION": "2021-08-05",
                    "NOMBRES": "Luis Jose",
                    "APELLIDOS": "Gomez Villarreal",
                    "NOMBRES COMPLETOS": "Luis Jose Gomez Villarreal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Cristian Camilo Hernandez Gonzalez",
                    "CODIGO USUARIO": "gome801639",
                    "USUARIOFIVE9": "gome801639@siigo.com",
                    "USUARIO RED": "gome801639",
                    "CORREOELECTRONICO": "gome801639@siigo.com",
                    "STATION ID": "3127538",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Cristian Camilo Hernandez Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1083026746",
                    "FECHA INICIAL": "2021-09-10",
                    "FECHA OPERACION": "2021-09-10",
                    "NOMBRES": "Luz Elena",
                    "APELLIDOS": "Torres Baslanoa",
                    "NOMBRES COMPLETOS": "Luz Elena Torres Baslanoa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Santa Marta",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Colombia",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Olga Lucia Ospina Vidal",
                    "CODIGO USUARIO": "torr801837",
                    "USUARIOFIVE9": "torr801837@siigo.com",
                    "USUARIO RED": "torr801837",
                    "CORREOELECTRONICO": "torr801837@siigo.com",
                    "STATION ID": "3267915",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Olga Lucia Ospina Vidal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1045697690",
                    "FECHA INICIAL": "2019-11-05",
                    "FECHA OPERACION": "2019-11-05",
                    "NOMBRES": "Orlando Elias",
                    "APELLIDOS": "Navarro Vasquez",
                    "NOMBRES COMPLETOS": "Orlando Elias Navarro Vasquez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Carolina Linares Gonzalez",
                    "CODIGO USUARIO": "nava112489",
                    "USUARIOFIVE9": "nava112489@siigo.com",
                    "USUARIO RED": "nava112489",
                    "CORREOELECTRONICO": "nava112489@siigo.com",
                    "STATION ID": "2606756",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Carolina Linares Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016074644",
                    "FECHA INICIAL": "2020-12-07",
                    "FECHA OPERACION": "2020-12-07",
                    "NOMBRES": "Lady Tatiana",
                    "APELLIDOS": "Cifuentes Avila",
                    "NOMBRES COMPLETOS": "Lady Tatiana Cifuentes Avila",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Fernando Sotto Cardozo",
                    "CODIGO USUARIO": "cifu800927",
                    "USUARIOFIVE9": "cifu800927@siigo.com",
                    "USUARIO RED": "cifu800927",
                    "CORREOELECTRONICO": "cifu800927@siigo.com",
                    "STATION ID": "2606320",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Fernando Sotto Cardozo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1069403860",
                    "FECHA INICIAL": "2021-04-07",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Leidy Johana",
                    "APELLIDOS": "Gil Garavito",
                    "NOMBRES COMPLETOS": "Leidy Johana Gil Garavito",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Cristian Camilo Hernandez Gonzalez",
                    "CODIGO USUARIO": "gilg801285",
                    "USUARIOFIVE9": "gilg801285@siigo.com",
                    "USUARIO RED": "gilg801285",
                    "CORREOELECTRONICO": "gilg801285@siigo.com",
                    "STATION ID": "2606920",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Cristian Camilo Hernandez Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023905191",
                    "FECHA INICIAL": "2021-04-07",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Leslie Geraldine",
                    "APELLIDOS": "Rodriguez",
                    "NOMBRES COMPLETOS": "Leslie Geraldine Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jefrey Julian Sanchez Celemin",
                    "CODIGO USUARIO": "rodr801288",
                    "USUARIOFIVE9": "rodr801288@siigo.com",
                    "USUARIO RED": "rodr801288",
                    "CORREOELECTRONICO": "rodr801288@siigo.com",
                    "STATION ID": "2606928",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jefrey Julian Sanchez Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013676352",
                    "FECHA INICIAL": "2021-06-11",
                    "FECHA OPERACION": "2021-06-11",
                    "NOMBRES": "Leydi Carolina",
                    "APELLIDOS": "Serrano Ortega",
                    "NOMBRES COMPLETOS": "Leydi Carolina Serrano Ortega",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luz Katerine Maya Mogollon",
                    "CODIGO USUARIO": "serr801500",
                    "USUARIOFIVE9": "serr801500@siigo.com",
                    "USUARIO RED": "serr801500",
                    "CORREOELECTRONICO": "serr801500@siigo.com",
                    "STATION ID": "2606967",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Katerine Maya Mogollon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1045692114",
                    "FECHA INICIAL": "2021-06-11",
                    "FECHA OPERACION": "2021-06-11",
                    "NOMBRES": "Wendy Johana",
                    "APELLIDOS": "Navarro Osorio",
                    "NOMBRES COMPLETOS": "Wendy Johana Navarro Osorio",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Leidy Stefany Castillo",
                    "CODIGO USUARIO": "nava801498",
                    "USUARIOFIVE9": "nava801498@siigo.com",
                    "USUARIO RED": "nava801498",
                    "CORREOELECTRONICO": "nava801498@siigo.com",
                    "STATION ID": "2607005",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Leidy Stefany Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000034858",
                    "FECHA INICIAL": "2020-08-11",
                    "FECHA OPERACION": "2020-08-11",
                    "NOMBRES": "Luna Nicol",
                    "APELLIDOS": "Gomez Carvajal",
                    "NOMBRES COMPLETOS": "Luna Nicol Gomez Carvajal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Jazbleidy Andrea Nunez Montoya",
                    "CODIGO USUARIO": "gome800505",
                    "USUARIOFIVE9": "gome800505@siigo.com",
                    "USUARIO RED": "gome800505",
                    "CORREOELECTRONICO": "gome800505@siigo.com",
                    "STATION ID": "2606042",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jazbleidy Andrea Nunez Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1053614127",
                    "FECHA INICIAL": "2021-08-03",
                    "FECHA OPERACION": "2021-08-05",
                    "NOMBRES": "July Andrea",
                    "APELLIDOS": "Hernandez Chivata",
                    "NOMBRES COMPLETOS": "July Andrea Hernandez Chivata",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Hancel Rafael Salcedo Mendoza",
                    "CODIGO USUARIO": "hern801642",
                    "USUARIOFIVE9": "hern801642@siigo.com",
                    "USUARIO RED": "hern801642",
                    "CORREOELECTRONICO": "hern801642@siigo.com",
                    "STATION ID": "3127542",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Hancel Rafael Salcedo Mendoza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79886467",
                    "FECHA INICIAL": "2021-05-06",
                    "FECHA OPERACION": "2021-05-06",
                    "NOMBRES": "William Yamid",
                    "APELLIDOS": "Diaz Buitrago",
                    "NOMBRES COMPLETOS": "William Yamid Diaz Buitrago",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jazbleidy Andrea Nunez Montoya",
                    "CODIGO USUARIO": "diaz801406",
                    "USUARIOFIVE9": "diaz801406@siigo.com",
                    "USUARIO RED": "diaz801406",
                    "CORREOELECTRONICO": "diaz801406@siigo.com",
                    "STATION ID": "3127460",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jazbleidy Andrea Nunez Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143160855",
                    "FECHA INICIAL": "2021-06-11",
                    "FECHA OPERACION": "2021-06-11",
                    "NOMBRES": "Luis Carlos",
                    "APELLIDOS": "Oviedo Diaz",
                    "NOMBRES COMPLETOS": "Luis Carlos Oviedo Diaz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "ovie801503",
                    "USUARIOFIVE9": "ovie801503@siigo.com",
                    "USUARIO RED": "ovie801503",
                    "CORREOELECTRONICO": "ovie801503@siigo.com",
                    "STATION ID": "2606864",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "34891453",
                    "FECHA INICIAL": "2021-06-11",
                    "FECHA OPERACION": "2021-06-11",
                    "NOMBRES": "Juan Carlos",
                    "APELLIDOS": "Corazzo",
                    "NOMBRES COMPLETOS": "Juan Carlos Corazzo",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Director de Producto",
                    "JEFE INMEDIATO": "Andrea Zuluaga Uribe",
                    "CODIGO USUARIO": "cora800860",
                    "USUARIOFIVE9": "cora800860@siigo.com",
                    "USUARIO RED": "cora800860",
                    "CORREOELECTRONICO": "cora800860@siigo.com",
                    "STATION ID": "3127497",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andrea Zuluaga Uribe",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "51345485",
                    "FECHA INICIAL": "2021-06-11",
                    "FECHA OPERACION": "2021-06-11",
                    "NOMBRES": "Romina",
                    "APELLIDOS": "Romero",
                    "NOMBRES COMPLETOS": "Romina Tatiana Romero Olivera",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "Firma Digital UY",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Elias Jusid Machado",
                    "CODIGO USUARIO": "rome801307",
                    "USUARIOFIVE9": "rome801307@siigo.com",
                    "USUARIO RED": "rome801307",
                    "CORREOELECTRONICO": "rome801307@siigo.com",
                    "STATION ID": "3127499",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Elias Jusid Machado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1712659729",
                    "FECHA INICIAL": "2021-06-15",
                    "FECHA OPERACION": "2021-06-15",
                    "NOMBRES": "Ivonny Gabriela",
                    "APELLIDOS": "Recalde Andrade",
                    "NOMBRES COMPLETOS": "Ivonny Gabriela Recalde Andrade",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Angel Alfonso Arciniegas Guerrero",
                    "CODIGO USUARIO": "reca801506",
                    "USUARIOFIVE9": "reca801506@siigo.com",
                    "USUARIO RED": "reca801506",
                    "CORREOELECTRONICO": "reca801506@siigo.com",
                    "STATION ID": "2606035",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Angel Alfonso Arciniegas Guerrero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1234095390",
                    "FECHA INICIAL": "2021-09-10",
                    "FECHA OPERACION": "2021-09-10",
                    "NOMBRES": "Maryuris Sarife",
                    "APELLIDOS": "Meza Fernandez",
                    "NOMBRES COMPLETOS": "Maryuris Sarife Meza Fernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Colombia",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Olga Lucia Ospina Vidal",
                    "CODIGO USUARIO": "meza801847",
                    "USUARIOFIVE9": "meza801847@siigo.com",
                    "USUARIO RED": "meza801847",
                    "CORREOELECTRONICO": "meza801847@siigo.com",
                    "STATION ID": "3471485",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Olga Lucia Ospina Vidal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1717975260",
                    "FECHA INICIAL": "2021-06-15",
                    "FECHA OPERACION": "2021-06-15",
                    "NOMBRES": "Angel Eduardo",
                    "APELLIDOS": "Lema Orta",
                    "NOMBRES COMPLETOS": "Angel Eduardo Lema Orta",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Walter Antonio Munoz Antepara",
                    "CODIGO USUARIO": "lema801510",
                    "USUARIOFIVE9": "lema801510@siigo.com",
                    "USUARIO RED": "lema801510",
                    "CORREOELECTRONICO": "lema801510@siigo.com",
                    "STATION ID": "2606804",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Walter Antonio Munoz Antepara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013577187",
                    "FECHA INICIAL": "2021-06-21",
                    "FECHA OPERACION": "2021-06-21",
                    "NOMBRES": "Ana Milena",
                    "APELLIDOS": "Fetecua Rojas",
                    "NOMBRES COMPLETOS": "Ana Milena Fetecua Rojas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Vicky Alejandra Hernandez Mendez",
                    "CODIGO USUARIO": "fete801539",
                    "USUARIOFIVE9": "fete801539@siigo.com",
                    "USUARIO RED": "fete801539",
                    "CORREOELECTRONICO": "fete801539@siigo.com",
                    "STATION ID": "3127510",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Vicky Alejandra Hernandez Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010247665",
                    "FECHA INICIAL": "2021-06-15",
                    "FECHA OPERACION": "2021-06-21",
                    "NOMBRES": "Sebastian Alexander",
                    "APELLIDOS": "Ponce Torres",
                    "NOMBRES COMPLETOS": "Sebastian Alexander Ponce Torres",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Planeacion Comercial",
                    "CARGO": "Bussiness Inteligence",
                    "JEFE INMEDIATO": "Angie Lorena Perez Espitia",
                    "CODIGO USUARIO": "ponc801509",
                    "USUARIOFIVE9": "ponc801509@siigo.com",
                    "USUARIO RED": "ponc801509",
                    "CORREOELECTRONICO": "ponc801509@siigo.com",
                    "STATION ID": "3127511",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Angie Lorena Perez Espitia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1070960362",
                    "FECHA INICIAL": "2020-09-03",
                    "FECHA OPERACION": "2021-06-22",
                    "NOMBRES": "Luz Derly",
                    "APELLIDOS": "Bernal Malaver",
                    "NOMBRES COMPLETOS": "Luz Derly Bernal Malaver",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Contabilidad",
                    "SUBAREA": "Contabilidad",
                    "CARGO": "Lider Contabilidad",
                    "JEFE INMEDIATO": "Diana Patricia Atehortua Castano",
                    "CODIGO USUARIO": "bern800671",
                    "USUARIOFIVE9": "bern800671@siigo.com",
                    "USUARIO RED": "bern800671",
                    "CORREOELECTRONICO": "bern800671@siigo.com",
                    "STATION ID": "3127512",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Contabilidad_Diana Patricia Atehortua Castano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "28078706",
                    "FECHA INICIAL": "2021-06-24",
                    "FECHA OPERACION": "2021-06-24",
                    "NOMBRES": "Giselda",
                    "APELLIDOS": "Keymetlian",
                    "NOMBRES COMPLETOS": "Giselda Keymetlian",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Administrativo",
                    "SUBAREA": "RRHH",
                    "CARGO": "Coordinador RRHH",
                    "JEFE INMEDIATO": "Luisa Fernanda Caro Camargo",
                    "CODIGO USUARIO": "keym800861",
                    "USUARIOFIVE9": "keym800861@siigo.com",
                    "USUARIO RED": "keym800861",
                    "CORREOELECTRONICO": "keym800861@siigo.com",
                    "STATION ID": "3127516",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Luisa Fernanda Caro Camargo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "18792889",
                    "FECHA INICIAL": "2021-06-24",
                    "FECHA OPERACION": "2021-06-24",
                    "NOMBRES": "Gabriela",
                    "APELLIDOS": "Mamigonian",
                    "NOMBRES COMPLETOS": "Gabriela Mamigonian",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Cartera",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Lider Contabilidad",
                    "JEFE INMEDIATO": "Edith Angelica Gil Umbarila",
                    "CODIGO USUARIO": "mami801060",
                    "USUARIOFIVE9": "mami801060@siigo.com",
                    "USUARIO RED": "mami801060",
                    "CORREOELECTRONICO": "mami801060@siigo.com",
                    "STATION ID": "2606356",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Edith Angelica Gil Umbarila",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "44808082",
                    "FECHA INICIAL": "2021-06-24",
                    "FECHA OPERACION": "2021-06-24",
                    "NOMBRES": "Ariel",
                    "APELLIDOS": "Uri Wajswol",
                    "NOMBRES COMPLETOS": "Ariel Uri Wajswol",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Contabilidad",
                    "SUBAREA": "Contabilidad",
                    "CARGO": "Lider Contabilidad",
                    "JEFE INMEDIATO": "Luisa Fernanda Caro Camargo",
                    "CODIGO USUARIO": "uriw801077",
                    "USUARIOFIVE9": "uriw801077@siigo.com",
                    "USUARIO RED": "uriw801077",
                    "CORREOELECTRONICO": "uriw801077@siigo.com",
                    "STATION ID": "2606357",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Contabilidad_Luisa Fernanda Caro Camargo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "32369307",
                    "FECHA INICIAL": "2021-06-24",
                    "FECHA OPERACION": "2021-06-24",
                    "NOMBRES": "Federico",
                    "APELLIDOS": "Hornes",
                    "NOMBRES COMPLETOS": "Federico Hornes",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Uruguay",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Esteban Tellez Garcia",
                    "CODIGO USUARIO": "horn800857",
                    "USUARIOFIVE9": "horn800857@siigo.com",
                    "USUARIO RED": "horn800857",
                    "CORREOELECTRONICO": "horn800857@siigo.com",
                    "STATION ID": "3127519",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Esteban Tellez Garcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "42354330",
                    "FECHA INICIAL": "2007-11-28",
                    "FECHA OPERACION": "2021-03-08",
                    "NOMBRES": "Sebastian",
                    "APELLIDOS": "Pifano",
                    "NOMBRES COMPLETOS": "Sebastian Pifano",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "pifa801118",
                    "USUARIOFIVE9": "pifa801118@siigo.com",
                    "USUARIO RED": "pifa801118",
                    "CORREOELECTRONICO": "pifa801118@siigo.com",
                    "STATION ID": "2606477",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80756737",
                    "FECHA INICIAL": "2021-06-24",
                    "FECHA OPERACION": "2021-06-24",
                    "NOMBRES": "Harrison",
                    "APELLIDOS": "Martinez Callejas",
                    "NOMBRES COMPLETOS": "Harrison Martinez Callejas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Lider Perfilamiento",
                    "JEFE INMEDIATO": "Julian Bernardo Fernandez Garcia",
                    "CODIGO USUARIO": "mart801542",
                    "USUARIOFIVE9": "mart801542@siigo.com",
                    "USUARIO RED": "mart801542",
                    "CORREOELECTRONICO": "mart801542@siigo.com",
                    "STATION ID": "2606213",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Julian Bernardo Fernandez Garcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024469767",
                    "FECHA INICIAL": "2021-06-16",
                    "FECHA OPERACION": "2021-07-09",
                    "NOMBRES": "Diego Armando",
                    "APELLIDOS": "Aristizabal Bedoya",
                    "NOMBRES COMPLETOS": "Diego Armando Aristizabal Bedoya",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Experience Manager",
                    "JEFE INMEDIATO": "Juan Jose Correa Acosta",
                    "CODIGO USUARIO": "aris801540",
                    "USUARIOFIVE9": "aris801540@siigo.com",
                    "USUARIO RED": "aris801540",
                    "CORREOELECTRONICO": "aris801540@siigo.com",
                    "STATION ID": "2606956",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Jose Correa Acosta",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "QURC950408SA6",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Cristian Ivan",
                    "APELLIDOS": "Quintana Ramirez",
                    "NOMBRES COMPLETOS": "Cristian Ivan Quintana Ramirez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Alejandro Salgado Vergara",
                    "CODIGO USUARIO": "cquintana",
                    "USUARIOFIVE9": "cquintana@aspel.com.mx",
                    "USUARIO RED": "cquintana",
                    "CORREOELECTRONICO": "cquintana@aspel.com.mx",
                    "STATION ID": "3857165",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Alejandro Salgado Vergara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012433371",
                    "FECHA INICIAL": "2021-07-12",
                    "FECHA OPERACION": "2021-07-12",
                    "NOMBRES": "Leidy Johana",
                    "APELLIDOS": "Rodriguez Garcia",
                    "NOMBRES COMPLETOS": "Leidy Johana Rodriguez Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Andrea Catalina Vega Gomez",
                    "CODIGO USUARIO": "rodr801572",
                    "USUARIOFIVE9": "rodr801572@siigo.com",
                    "USUARIO RED": "rodr801572",
                    "CORREOELECTRONICO": "rodr801572@siigo.com",
                    "STATION ID": "3127489",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andrea Catalina Vega Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "928774850",
                    "FECHA INICIAL": "2021-07-13",
                    "FECHA OPERACION": "2021-07-19",
                    "NOMBRES": "Lissette Nicole",
                    "APELLIDOS": "Arboleda Barreiro",
                    "NOMBRES COMPLETOS": "Lissette Nicole Arboleda Barreiro",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Michelle Matamoros Del Rosario",
                    "CODIGO USUARIO": "arbo801569",
                    "USUARIOFIVE9": "arbo801569@siigo.com",
                    "USUARIO RED": "arbo801569",
                    "CORREOELECTRONICO": "arbo801569@siigo.com",
                    "STATION ID": "2606041",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Michelle Matamoros Del Rosario",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "927783837",
                    "FECHA INICIAL": "2021-07-13",
                    "FECHA OPERACION": "2021-07-19",
                    "NOMBRES": "Jorge Antonio",
                    "APELLIDOS": "Olivos Cadena",
                    "NOMBRES COMPLETOS": "Jorge Antonio Olivos Cadena",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Michelle Matamoros Del Rosario",
                    "CODIGO USUARIO": "oliv801574",
                    "USUARIOFIVE9": "oliv801574@siigo.com",
                    "USUARIO RED": "oliv801574",
                    "CORREOELECTRONICO": "oliv801574@siigo.com",
                    "STATION ID": "2606088",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Michelle Matamoros Del Rosario",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "930864137",
                    "FECHA INICIAL": "2021-07-13",
                    "FECHA OPERACION": "2021-07-19",
                    "NOMBRES": "Nicole Denisse",
                    "APELLIDOS": "Cabanilla Parrales",
                    "NOMBRES COMPLETOS": "Nicole Denisse Cabanilla Parrales",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Walter Antonio Munoz Antepara",
                    "CODIGO USUARIO": "caba801577",
                    "USUARIOFIVE9": "caba801577@siigo.com",
                    "USUARIO RED": "caba801577",
                    "CORREOELECTRONICO": "caba801577@siigo.com",
                    "STATION ID": "2606154",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Walter Antonio Munoz Antepara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "919839795",
                    "FECHA INICIAL": "2021-07-09",
                    "FECHA OPERACION": "2021-07-21",
                    "NOMBRES": "Daniel Eduardo",
                    "APELLIDOS": "Acosta Mera",
                    "NOMBRES COMPLETOS": "Daniel Eduardo Acosta Mera",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Angel Alfonso Arciniegas Guerrero",
                    "CODIGO USUARIO": "acos801576",
                    "USUARIOFIVE9": "acos801576@siigo.com",
                    "USUARIO RED": "acos801576",
                    "CORREOELECTRONICO": "acos801576@siigo.com",
                    "STATION ID": "2606161",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Angel Alfonso Arciniegas Guerrero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033695345",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-03",
                    "NOMBRES": "Juan Miguel",
                    "APELLIDOS": "Portilla Perez",
                    "NOMBRES COMPLETOS": "Juan Miguel Portilla Perez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "port800892",
                    "USUARIOFIVE9": "port800892@siigo.com",
                    "USUARIO RED": "port800892",
                    "CORREOELECTRONICO": "port800892@siigo.com",
                    "STATION ID": "2606331",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023946406",
                    "FECHA INICIAL": "2021-08-23",
                    "FECHA OPERACION": "2021-08-23",
                    "NOMBRES": "Katherine Vanessa",
                    "APELLIDOS": "Gonzalez Ospina",
                    "NOMBRES COMPLETOS": "Katherine Vanessa Gonzalez Ospina",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "gonz801721",
                    "USUARIOFIVE9": "gonz801721@siigo.com",
                    "USUARIO RED": "gonz801721",
                    "CORREOELECTRONICO": "gonz801721@siigo.com",
                    "STATION ID": "3127579",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000351716",
                    "FECHA INICIAL": "2021-07-16",
                    "FECHA OPERACION": "2021-07-21",
                    "NOMBRES": "Valentina",
                    "APELLIDOS": "Aldana Gonzalez",
                    "NOMBRES COMPLETOS": "Valentina Aldana Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Uy",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Edith Angelica Gil Umbarila",
                    "CODIGO USUARIO": "alda801582",
                    "USUARIOFIVE9": "alda801582@siigo.com",
                    "USUARIO RED": "alda801582",
                    "CORREOELECTRONICO": "alda801582@siigo.com",
                    "STATION ID": "2606374",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Edith Angelica Gil Umbarila",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022426167",
                    "FECHA INICIAL": "2021-07-21",
                    "FECHA OPERACION": "2021-07-29",
                    "NOMBRES": "Maria Camila",
                    "APELLIDOS": "Salazar Trivino",
                    "NOMBRES COMPLETOS": "Maria Camila Salazar Trivino",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Sanchez Velez",
                    "CODIGO USUARIO": "sala801593",
                    "USUARIOFIVE9": "sala801593@siigo.com",
                    "USUARIO RED": "sala801593",
                    "CORREOELECTRONICO": "sala801593@siigo.com",
                    "STATION ID": "2605971",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Sanchez Velez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1719883488",
                    "FECHA INICIAL": "2006-10-01",
                    "FECHA OPERACION": "2021-01-28",
                    "NOMBRES": "Mayra Alejandra",
                    "APELLIDOS": "Alvarado Onoa",
                    "NOMBRES COMPLETOS": "Mayra Alejandra Alvarado Onoa",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Sanchez Velez",
                    "CODIGO USUARIO": "Mayra.Alvarado",
                    "USUARIOFIVE9": "Mayra.Alvarado@siigo.com",
                    "USUARIO RED": "Mayra.Alvarado",
                    "CORREOELECTRONICO": "Mayra.Alvarado@siigo.com",
                    "STATION ID": "2606366",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Sanchez Velez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018430848",
                    "FECHA INICIAL": "2020-12-03",
                    "FECHA OPERACION": "2020-12-03",
                    "NOMBRES": "Miguel Angel",
                    "APELLIDOS": "Sanchez Pulido",
                    "NOMBRES COMPLETOS": "Miguel Angel Sanchez Pulido",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "sanc800898",
                    "USUARIOFIVE9": "sanc800898@siigo.com",
                    "USUARIO RED": "sanc800898",
                    "CORREOELECTRONICO": "sanc800898@siigo.com",
                    "STATION ID": "2606337",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1110572750",
                    "FECHA INICIAL": "2020-01-07",
                    "FECHA OPERACION": "2020-01-07",
                    "NOMBRES": "John Sebastian",
                    "APELLIDOS": "Diaz Mosquera",
                    "NOMBRES COMPLETOS": "John Sebastian Diaz Mosquera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Juan Pablo Carreno Patino",
                    "CODIGO USUARIO": "diaz800156",
                    "USUARIOFIVE9": "diaz800156@siigo.com",
                    "USUARIO RED": "diaz800156",
                    "CORREOELECTRONICO": "diaz800156@siigo.com",
                    "STATION ID": "2606699",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Carreno Patino",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007290150",
                    "FECHA INICIAL": "2020-07-28",
                    "FECHA OPERACION": "2020-07-28",
                    "NOMBRES": "Nazly Yulieth",
                    "APELLIDOS": "Diaz Benitez",
                    "NOMBRES COMPLETOS": "Nazly Yulieth Diaz Benitez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Andres Valencia Bravo",
                    "CODIGO USUARIO": "diaz800478",
                    "USUARIOFIVE9": "diaz800478@siigo.com",
                    "USUARIO RED": "diaz800478",
                    "CORREOELECTRONICO": "diaz800478@siigo.com",
                    "STATION ID": "2606038",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Andres Valencia Bravo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015994852",
                    "FECHA INICIAL": "2020-02-10",
                    "FECHA OPERACION": "2020-02-10",
                    "NOMBRES": "Juan Jose",
                    "APELLIDOS": "Torres Mora",
                    "NOMBRES COMPLETOS": "Juan Jose Torres Mora",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Andres Valencia Bravo",
                    "CODIGO USUARIO": "torre800265",
                    "USUARIOFIVE9": "torre800265@siigo.com",
                    "USUARIO RED": "torre800265",
                    "CORREOELECTRONICO": "torre800265@siigo.com",
                    "STATION ID": "2607062",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Andres Valencia Bravo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1006505793",
                    "FECHA INICIAL": "2021-09-08",
                    "FECHA OPERACION": "2021-09-08",
                    "NOMBRES": "Stefanny",
                    "APELLIDOS": "Munoz Gasca",
                    "NOMBRES COMPLETOS": "Stefanny Munoz Gasca",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Juan Pablo Carreno Patino",
                    "CODIGO USUARIO": "muno801860",
                    "USUARIOFIVE9": "muno801860@siigo.com",
                    "USUARIO RED": "muno801860",
                    "CORREOELECTRONICO": "muno801860@siigo.com",
                    "STATION ID": "3267894",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Carreno Patino",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "72008479",
                    "FECHA INICIAL": "2006-07-17",
                    "FECHA OPERACION": "2006-07-17",
                    "NOMBRES": "Elvis Manuel",
                    "APELLIDOS": "Peralta Quintero",
                    "NOMBRES COMPLETOS": "Elvis Manuel Peralta Quintero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luzdary Bernal Villarreal",
                    "CODIGO USUARIO": "pera204477",
                    "USUARIOFIVE9": "pera204477@siigo.com",
                    "USUARIO RED": "pera204477",
                    "CORREOELECTRONICO": "pera204477@siigo.com",
                    "STATION ID": "2606674",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luzdary Bernal Villarreal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "57430122",
                    "FECHA INICIAL": "2012-04-23",
                    "FECHA OPERACION": "2012-04-23",
                    "NOMBRES": "Yamile Patricia",
                    "APELLIDOS": "Torres Palacio",
                    "NOMBRES COMPLETOS": "Yamile Patricia Torres Palacio",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Santa Marta",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Juan Pablo Carreno Patino",
                    "CODIGO USUARIO": "torr20098",
                    "USUARIOFIVE9": "torr20098@siigo.com",
                    "USUARIO RED": "torr20098",
                    "CORREOELECTRONICO": "torr20098@siigo.com",
                    "STATION ID": "2606687",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Carreno Patino",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012366824",
                    "FECHA INICIAL": "2021-08-02",
                    "FECHA OPERACION": "2021-08-03",
                    "NOMBRES": "Luz Omaira",
                    "APELLIDOS": "Sierra Bernal",
                    "NOMBRES COMPLETOS": "Luz Omaira Sierra Bernal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Buitrago Rios",
                    "CODIGO USUARIO": "sier801632",
                    "USUARIOFIVE9": "sier801632@siigo.com",
                    "USUARIO RED": "sier801632",
                    "CORREOELECTRONICO": "sier801632@siigo.com",
                    "STATION ID": "3127517",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Buitrago Rios",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098750672",
                    "FECHA INICIAL": "2021-08-02",
                    "FECHA OPERACION": "2021-08-03",
                    "NOMBRES": "Slendy Stephanny",
                    "APELLIDOS": "Hernandez Correa",
                    "NOMBRES COMPLETOS": "Slendy Stephanny Hernandez Correa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juvenal Reyes Silva",
                    "CODIGO USUARIO": "hern801616",
                    "USUARIOFIVE9": "hern801616@siigo.com",
                    "USUARIO RED": "hern801616",
                    "CORREOELECTRONICO": "hern801616@siigo.com",
                    "STATION ID": "3127522",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juvenal Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80854642",
                    "FECHA INICIAL": "2021-08-02",
                    "FECHA OPERACION": "2021-08-03",
                    "NOMBRES": "Alexander",
                    "APELLIDOS": "Franco Oviedo",
                    "NOMBRES COMPLETOS": "Alexander Franco Oviedo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leida Adriana Rico Herrera",
                    "CODIGO USUARIO": "fran801612",
                    "USUARIOFIVE9": "fran801612@siigo.com",
                    "USUARIO RED": "fran801612",
                    "CORREOELECTRONICO": "fran801612@siigo.com",
                    "STATION ID": "3127526",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leida Adriana Rico Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52783356",
                    "FECHA INICIAL": "2021-08-02",
                    "FECHA OPERACION": "2021-08-03",
                    "NOMBRES": "Deisy Niyer",
                    "APELLIDOS": "Castellanos Cortes",
                    "NOMBRES COMPLETOS": "Deisy Niyer Castellanos Cortes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jesus Daniel Arzuza Roman",
                    "CODIGO USUARIO": "cast801608",
                    "USUARIOFIVE9": "cast801608@siigo.com",
                    "USUARIO RED": "cast801608",
                    "CORREOELECTRONICO": "cast801608@siigo.com",
                    "STATION ID": "3127530",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jesus Daniel Arzuza Roman",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098800942",
                    "FECHA INICIAL": "2021-05-06",
                    "FECHA OPERACION": "2021-05-06",
                    "NOMBRES": "Karina Mayerly",
                    "APELLIDOS": "Castellanos Jaimes",
                    "NOMBRES COMPLETOS": "Karina Mayerly Castellanos Jaimes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alix Dayana Archila Castillo",
                    "CODIGO USUARIO": "cast801399",
                    "USUARIOFIVE9": "cast801399@siigo.com",
                    "USUARIO RED": "cast801399",
                    "CORREOELECTRONICO": "cast801399@siigo.com",
                    "STATION ID": "3127453",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alix Dayana Archila Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1102380441",
                    "FECHA INICIAL": "2021-08-03",
                    "FECHA OPERACION": "2021-08-05",
                    "NOMBRES": "Angie Yuranny",
                    "APELLIDOS": "Valencia Tarazona",
                    "NOMBRES COMPLETOS": "Angie Yuranny Valencia Tarazona",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "UCI",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Nestor Oswaldo Becerra Prieto",
                    "CODIGO USUARIO": "vale801640",
                    "USUARIOFIVE9": "vale801640@siigo.com",
                    "USUARIO RED": "vale801640",
                    "CORREOELECTRONICO": "vale801640@siigo.com",
                    "STATION ID": "3127537",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Nestor Oswaldo Becerra Prieto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000709045",
                    "FECHA INICIAL": "2021-03-10",
                    "FECHA OPERACION": "2021-03-12",
                    "NOMBRES": "Nicole Vanesa",
                    "APELLIDOS": "Quiroga Fuquen",
                    "NOMBRES COMPLETOS": "Nicole Vanesa Quiroga Fuquen",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexis Abraham Sanchez Portillo",
                    "CODIGO USUARIO": "quir801211",
                    "USUARIOFIVE9": "quir801211@siigo.com",
                    "USUARIO RED": "quir801211",
                    "CORREOELECTRONICO": "quir801211@siigo.com",
                    "STATION ID": "2606361",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexis Abraham Sanchez Portillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1052021077",
                    "FECHA INICIAL": "2021-06-11",
                    "FECHA OPERACION": "2021-06-11",
                    "NOMBRES": "Maria Paula",
                    "APELLIDOS": "Martinez Perez",
                    "NOMBRES COMPLETOS": "Maria Paula Martinez Perez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adrian Alberto Salom Berrio",
                    "CODIGO USUARIO": "mart801512",
                    "USUARIOFIVE9": "mart801512@siigo.com",
                    "USUARIO RED": "mart801512",
                    "CORREOELECTRONICO": "mart801512@siigo.com",
                    "STATION ID": "2607057",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adrian Alberto Salom Berrio",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "37579480",
                    "FECHA INICIAL": "2021-03-10",
                    "FECHA OPERACION": "2021-03-09",
                    "NOMBRES": "Nidian Carolina",
                    "APELLIDOS": "Monroy Serrano",
                    "NOMBRES COMPLETOS": "Nidian Carolina Monroy Serrano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Tatiana Reyes Perez",
                    "CODIGO USUARIO": "monr801184",
                    "USUARIOFIVE9": "monr801184@siigo.com",
                    "USUARIO RED": "monr801184",
                    "CORREOELECTRONICO": "monr801184@siigo.com",
                    "STATION ID": "2606785",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Tatiana Reyes Perez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "4901370",
                    "FECHA INICIAL": "2021-08-02",
                    "FECHA OPERACION": "2021-08-06",
                    "NOMBRES": "Andrea Sofhya",
                    "APELLIDOS": "Gonzalez Martinez",
                    "NOMBRES COMPLETOS": "Andrea Sofhya Gonzalez Martinez",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones ECU",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Angie Camila Diaz Escobar",
                    "CODIGO USUARIO": "gonz801637",
                    "USUARIOFIVE9": "gonz801637@siigo.com",
                    "USUARIO RED": "gonz801637",
                    "CORREOELECTRONICO": "gonz801637@siigo.com",
                    "STATION ID": "3127544",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Camila Diaz Escobar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057599436",
                    "FECHA INICIAL": "2021-08-02",
                    "FECHA OPERACION": "2021-08-10",
                    "NOMBRES": "Jenifer Paola",
                    "APELLIDOS": "Hurtado Eslava",
                    "NOMBRES COMPLETOS": "Jenifer Paola Hurtado Eslava",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "UCI Fidelizacion",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Nestor Oswaldo Becerra Prieto",
                    "CODIGO USUARIO": "hurt801638",
                    "USUARIOFIVE9": "hurt801638@siigo.com",
                    "USUARIO RED": "hurt801638",
                    "CORREOELECTRONICO": "hurt801638@siigo.com",
                    "STATION ID": "2606518",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Nestor Oswaldo Becerra Prieto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53030421",
                    "FECHA INICIAL": "2020-10-08",
                    "FECHA OPERACION": "2020-10-08",
                    "NOMBRES": "Jenny Johanna",
                    "APELLIDOS": "Suarez Gomez",
                    "NOMBRES COMPLETOS": "Jenny Johanna Suarez Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luzdary Bernal Villarreal",
                    "CODIGO USUARIO": "suar800784",
                    "USUARIOFIVE9": "suar800784@siigo.com",
                    "USUARIO RED": "suar800784",
                    "CORREOELECTRONICO": "suar800784@siigo.com",
                    "STATION ID": "2606141",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luzdary Bernal Villarreal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098779597",
                    "FECHA INICIAL": "2021-05-04",
                    "FECHA OPERACION": "2021-05-04",
                    "NOMBRES": "Leydi Vanessa",
                    "APELLIDOS": "Arciniegas Castaneda",
                    "NOMBRES COMPLETOS": "Leydi Vanessa Arciniegas Castaneda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luzdary Bernal Villarreal",
                    "CODIGO USUARIO": "arci801390",
                    "USUARIOFIVE9": "arci801390@siigo.com",
                    "USUARIO RED": "arci801390",
                    "CORREOELECTRONICO": "arci801390@siigo.com",
                    "STATION ID": "2607044",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luzdary Bernal Villarreal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "PEMG850313V8A",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-27",
                    "NOMBRES": "Gustavo Alejandro",
                    "APELLIDOS": "Perez Martinez",
                    "NOMBRES COMPLETOS": "Gustavo Alejandro Perez Martinez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Carranza Valdes",
                    "CODIGO USUARIO": "gperez",
                    "USUARIOFIVE9": "gperez@aspel.com.mx",
                    "USUARIO RED": "gperez",
                    "CORREOELECTRONICO": "gperez@aspel.com.mx",
                    "STATION ID": "3763881",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Carranza Valdes",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033798686",
                    "FECHA INICIAL": "2021-05-05",
                    "FECHA OPERACION": "2021-05-05",
                    "NOMBRES": "Maria Alejandra",
                    "APELLIDOS": "Caldas Garcia",
                    "NOMBRES COMPLETOS": "Maria Alejandra Caldas Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luisa Fernanda Rodriguez Larrota",
                    "CODIGO USUARIO": "cald801378",
                    "USUARIOFIVE9": "cald801378@siigo.com",
                    "USUARIO RED": "cald801378",
                    "CORREOELECTRONICO": "cald801378@siigo.com",
                    "STATION ID": "2607056",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luisa Fernanda Rodriguez Larrota",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1073382799",
                    "FECHA INICIAL": "2021-10-08",
                    "FECHA OPERACION": "2021-10-13",
                    "NOMBRES": "Lilia Stella",
                    "APELLIDOS": "Torres Urrego",
                    "NOMBRES COMPLETOS": "Lilia Stella Torres Urrego",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Juan Pablo Carreno Patino",
                    "CODIGO USUARIO": "torr802075",
                    "USUARIOFIVE9": "torr802075@siigo.com",
                    "USUARIO RED": "torr802075",
                    "CORREOELECTRONICO": "torr802075@siigo.com",
                    "STATION ID": "3690233",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Carreno Patino",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001939649",
                    "FECHA INICIAL": "2021-10-21",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Kevin Alberto",
                    "APELLIDOS": "Polo Lozano",
                    "NOMBRES COMPLETOS": "Kevin Alberto Polo Lozano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luisa Fernanda Rodriguez Larrota",
                    "CODIGO USUARIO": "polo802121",
                    "USUARIOFIVE9": "polo802121@siigo.com",
                    "USUARIO RED": "polo802121",
                    "CORREOELECTRONICO": "polo802121@siigo.com",
                    "STATION ID": "3127564",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luisa Fernanda Rodriguez Larrota",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1111810353",
                    "FECHA INICIAL": "2021-10-21",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Rosemary",
                    "APELLIDOS": "Angulo Borja",
                    "NOMBRES COMPLETOS": "Rosemary Angulo Borja",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Armenia",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Enrique Varela Lasso",
                    "CODIGO USUARIO": "angu802137",
                    "USUARIOFIVE9": "angu802137@siigo.com",
                    "USUARIO RED": "angu802137",
                    "CORREOELECTRONICO": "angu802137@siigo.com",
                    "STATION ID": "3690265",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Enrique Varela Lasso",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1026138234",
                    "FECHA INICIAL": "2019-02-15",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Robinson Arley",
                    "APELLIDOS": "Villegas Campo",
                    "NOMBRES COMPLETOS": "Robinson Arley Villegas Campo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "QC",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "vill111934",
                    "USUARIOFIVE9": "vill111934@siigo.com",
                    "USUARIO RED": "vill111934",
                    "CORREOELECTRONICO": "vill111934@siigo.com",
                    "STATION ID": "2606803",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1140892356",
                    "FECHA INICIAL": "2021-10-22",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Karen Melissa",
                    "APELLIDOS": "Lobo Gonzalez",
                    "NOMBRES COMPLETOS": "Karen Melissa Lobo Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Enrique Varela Lasso",
                    "CODIGO USUARIO": "lobo802146",
                    "USUARIOFIVE9": "lobo802146@siigo.com",
                    "USUARIO RED": "lobo802146",
                    "CORREOELECTRONICO": "lobo802146@siigo.com",
                    "STATION ID": "3690274",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Enrique Varela Lasso",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012372644",
                    "FECHA INICIAL": "2021-08-18",
                    "FECHA OPERACION": "2021-08-18",
                    "NOMBRES": "Yennifer",
                    "APELLIDOS": "Rodriguez Avila",
                    "NOMBRES COMPLETOS": "Yennifer Rodriguez Avila",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Andrea Catalina Vega Gomez",
                    "CODIGO USUARIO": "rodr801669",
                    "USUARIOFIVE9": "rodr801669@siigo.com",
                    "USUARIO RED": "rodr801669",
                    "CORREOELECTRONICO": "rodr801669@siigo.com",
                    "STATION ID": "3127546",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andrea Catalina Vega Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018510492",
                    "FECHA INICIAL": "2021-05-06",
                    "FECHA OPERACION": "2021-05-06",
                    "NOMBRES": "Tatiana Julieth",
                    "APELLIDOS": "Yate Alape",
                    "NOMBRES COMPLETOS": "Tatiana Julieth Yate Alape",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ventas",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "yate801411",
                    "USUARIOFIVE9": "yate801411@siigo.com",
                    "USUARIO RED": "yate801411",
                    "CORREOELECTRONICO": "yate801411@siigo.com",
                    "STATION ID": "3127465",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001876558",
                    "FECHA INICIAL": "2021-08-03",
                    "FECHA OPERACION": "2021-08-05",
                    "NOMBRES": "Yerlys",
                    "APELLIDOS": "Silva Polanco",
                    "NOMBRES COMPLETOS": "Yerlys Silva Polanco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Hancel Rafael Salcedo Mendoza",
                    "CODIGO USUARIO": "silv801636",
                    "USUARIOFIVE9": "silv801636@siigo.com",
                    "USUARIO RED": "silv801636",
                    "CORREOELECTRONICO": "silv801636@siigo.com",
                    "STATION ID": "3127540",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Hancel Rafael Salcedo Mendoza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1100971009",
                    "FECHA INICIAL": "2021-05-06",
                    "FECHA OPERACION": "2021-05-06",
                    "NOMBRES": "Yuri Andrea",
                    "APELLIDOS": "Molina Castillo",
                    "NOMBRES COMPLETOS": "Yuri Andrea Molina Castillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Carolina Linares Gonzalez",
                    "CODIGO USUARIO": "moli801401",
                    "USUARIOFIVE9": "moli801401@siigo.com",
                    "USUARIO RED": "moli801401",
                    "CORREOELECTRONICO": "moli801401@siigo.com",
                    "STATION ID": "3127455",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Carolina Linares Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1107095221",
                    "FECHA INICIAL": "2020-10-08",
                    "FECHA OPERACION": "2020-10-08",
                    "NOMBRES": "Yessica Paola",
                    "APELLIDOS": "Serna Hoyos",
                    "NOMBRES COMPLETOS": "Yessica Paola Serna Hoyos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Patricia Villarraga Forero",
                    "CODIGO USUARIO": "sern800803",
                    "USUARIOFIVE9": "sern800803@siigo.com",
                    "USUARIO RED": "sern800803",
                    "CORREOELECTRONICO": "sern800803@siigo.com",
                    "STATION ID": "2606160",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Patricia Villarraga Forero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143983270",
                    "FECHA INICIAL": "2020-01-27",
                    "FECHA OPERACION": "2020-01-27",
                    "NOMBRES": "Valentina Munoz",
                    "APELLIDOS": "Males",
                    "NOMBRES COMPLETOS": "Valentina Munoz Males",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gustavo Adolfo Espinosa Toledo",
                    "CODIGO USUARIO": "muno800182",
                    "USUARIOFIVE9": "muno800182@siigo.com",
                    "USUARIO RED": "muno800182",
                    "CORREOELECTRONICO": "muno800182@siigo.com",
                    "STATION ID": "2607009",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gustavo Adolfo Espinosa Toledo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1052409017",
                    "FECHA INICIAL": "2020-02-17",
                    "FECHA OPERACION": "2020-02-17",
                    "NOMBRES": "Marlon Frederick",
                    "APELLIDOS": "Mendoza Hernandez",
                    "NOMBRES COMPLETOS": "Marlon Frederick Mendoza Hernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "mend800296",
                    "USUARIOFIVE9": "mend800296@siigo.com",
                    "USUARIO RED": "mend800296",
                    "CORREOELECTRONICO": "mend800296@siigo.com",
                    "STATION ID": "2607093",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057547009",
                    "FECHA INICIAL": "2019-12-02",
                    "FECHA OPERACION": "2019-12-02",
                    "NOMBRES": "Natalia Silva",
                    "APELLIDOS": "Valderrama",
                    "NOMBRES COMPLETOS": "Natalia Silva Valderrama",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Lina Maria Buitrago Herrera",
                    "CODIGO USUARIO": "silv800015",
                    "USUARIOFIVE9": "silv800015@siigo.com",
                    "USUARIO RED": "silv800015",
                    "CORREOELECTRONICO": "silv800015@siigo.com",
                    "STATION ID": "2606767",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Lina Maria Buitrago Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1061808046",
                    "FECHA INICIAL": "2020-10-08",
                    "FECHA OPERACION": "2020-10-08",
                    "NOMBRES": "Yeisson",
                    "APELLIDOS": "Valencia Mosquera",
                    "NOMBRES COMPLETOS": "Yeisson Valencia Mosquera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Leidy Stefany Castillo",
                    "CODIGO USUARIO": "vale800802",
                    "USUARIOFIVE9": "vale800802@siigo.com",
                    "USUARIO RED": "vale800802",
                    "CORREOELECTRONICO": "vale800802@siigo.com",
                    "STATION ID": "2606159",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Leidy Stefany Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030600482",
                    "FECHA INICIAL": "2019-07-29",
                    "FECHA OPERACION": "2019-07-29",
                    "NOMBRES": "Richard Eduardo",
                    "APELLIDOS": "Jimenez Vergara",
                    "NOMBRES COMPLETOS": "Richard Eduardo Jimenez Vergara",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "jime112211",
                    "USUARIOFIVE9": "jime112211@siigo.com",
                    "USUARIO RED": "jime112211",
                    "CORREOELECTRONICO": "jime112211@siigo.com",
                    "STATION ID": "2606560",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001093154",
                    "FECHA INICIAL": "2021-08-23",
                    "FECHA OPERACION": "2021-08-23",
                    "NOMBRES": "Danna Camila",
                    "APELLIDOS": "Yate Sarmiento",
                    "NOMBRES COMPLETOS": "Danna Camila Yate Sarmiento",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Liliana Andrea Segura Martinez",
                    "CODIGO USUARIO": "yate801694",
                    "USUARIOFIVE9": "yate801694@siigo.com",
                    "USUARIO RED": "yate801694",
                    "CORREOELECTRONICO": "yate801694@siigo.com",
                    "STATION ID": "3127561",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Andrea Segura Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "41679924",
                    "FECHA INICIAL": "2000-03-01",
                    "FECHA OPERACION": "2000-03-01",
                    "NOMBRES": "Rubby Esperanza",
                    "APELLIDOS": "Ospina Deulofeut",
                    "NOMBRES COMPLETOS": "Rubby Esperanza Ospina Deulofeut",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "OSPI6284",
                    "USUARIOFIVE9": "OSPI6284@siigo.com",
                    "USUARIO RED": "OSPI6284",
                    "CORREOELECTRONICO": "OSPI6284@siigo.com",
                    "STATION ID": "2606806",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52810837",
                    "FECHA INICIAL": "2021-07-19",
                    "FECHA OPERACION": "2021-07-26",
                    "NOMBRES": "Sol Virginia",
                    "APELLIDOS": "Duran Gantiva",
                    "NOMBRES COMPLETOS": "Sol Virginia Duran Gantiva",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Sanchez Velez",
                    "CODIGO USUARIO": "dura801587",
                    "USUARIOFIVE9": "dura801587@siigo.com",
                    "USUARIO RED": "dura801587",
                    "CORREOELECTRONICO": "dura801587@siigo.com",
                    "STATION ID": "2606170",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Sanchez Velez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "33220865",
                    "FECHA INICIAL": "2021-08-23",
                    "FECHA OPERACION": "2021-08-23",
                    "NOMBRES": "Adriana Cecilia",
                    "APELLIDOS": "Mancilla Villareal",
                    "NOMBRES COMPLETOS": "Adriana Cecilia Mancilla Villareal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Cristhian David Nino Barrera",
                    "CODIGO USUARIO": "manc801724",
                    "USUARIOFIVE9": "manc801724@siigo.com",
                    "USUARIO RED": "manc801724",
                    "CORREOELECTRONICO": "manc801724@siigo.com",
                    "STATION ID": "3127576",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Cristhian David Nino Barrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143442787",
                    "FECHA INICIAL": "2021-10-28",
                    "FECHA OPERACION": "2021-11-01",
                    "NOMBRES": "Gisella Del Carmen",
                    "APELLIDOS": "Diazgranados Vergara",
                    "NOMBRES COMPLETOS": "Gisella Del Carmen Diazgranados Vergara",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Francy Julieth Viracacha Gutierrez",
                    "CODIGO USUARIO": "diaz802180",
                    "USUARIOFIVE9": "diaz802180@siigo.com",
                    "USUARIO RED": "diaz802180",
                    "CORREOELECTRONICO": "diaz802180@siigo.com",
                    "STATION ID": "3690293",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Francy Julieth Viracacha Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024481283",
                    "FECHA INICIAL": "2021-08-23",
                    "FECHA OPERACION": "2021-08-23",
                    "NOMBRES": "Adriana Del Pilar",
                    "APELLIDOS": "Sanchez Herrera",
                    "NOMBRES COMPLETOS": "Adriana Del Pilar Sanchez Herrera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoraciones",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Sandra Catalina Suarez Castillo",
                    "CODIGO USUARIO": "sanc801729",
                    "USUARIOFIVE9": "sanc801729@siigo.com",
                    "USUARIO RED": "sanc801729",
                    "CORREOELECTRONICO": "sanc801729@siigo.com",
                    "STATION ID": "3127570",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Sandra Catalina Suarez Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1070597183",
                    "FECHA INICIAL": "2021-11-09",
                    "FECHA OPERACION": "2021-11-10",
                    "NOMBRES": "Meribeth",
                    "APELLIDOS": "Sanchez Sanchez",
                    "NOMBRES COMPLETOS": "Meribeth Sanchez Sanchez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Eliana Maria Fajardo Prada",
                    "CODIGO USUARIO": "sanc802214",
                    "USUARIOFIVE9": "sanc802214@siigo.com",
                    "USUARIO RED": "sanc802214",
                    "CORREOELECTRONICO": "sanc802214@siigo.com",
                    "STATION ID": "3127588",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Eliana Maria Fajardo Prada",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023026552",
                    "FECHA INICIAL": "2021-08-23",
                    "FECHA OPERACION": "2021-08-23",
                    "NOMBRES": "Laura Camila",
                    "APELLIDOS": "Gomez Cuellar",
                    "NOMBRES COMPLETOS": "Laura Camila Gomez Cuellar",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Eliana Maria Fajardo Prada",
                    "CODIGO USUARIO": "gome801727",
                    "USUARIOFIVE9": "gome801727@siigo.com",
                    "USUARIO RED": "gome801727",
                    "CORREOELECTRONICO": "gome801727@siigo.com",
                    "STATION ID": "3127572",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Eliana Maria Fajardo Prada",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000353439",
                    "FECHA INICIAL": "2021-08-23",
                    "FECHA OPERACION": "2021-08-23",
                    "NOMBRES": "Karen Gisell",
                    "APELLIDOS": "Camelo Alonso",
                    "NOMBRES COMPLETOS": "Karen Gisell Camelo Alonso",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones ECU",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Angie Camila Diaz Escobar",
                    "CODIGO USUARIO": "came801536",
                    "USUARIOFIVE9": "came801536@siigo.com",
                    "USUARIO RED": "came801536",
                    "CORREOELECTRONICO": "came801536@siigo.com",
                    "STATION ID": "3127573",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Camila Diaz Escobar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023946619",
                    "FECHA INICIAL": "2021-08-23",
                    "FECHA OPERACION": "2021-08-23",
                    "NOMBRES": "Astrid Carolina",
                    "APELLIDOS": "Ardila Hurtado",
                    "NOMBRES COMPLETOS": "Astrid Carolina Ardila Hurtado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Cristhian David Nino Barrera",
                    "CODIGO USUARIO": "ardi801714",
                    "USUARIOFIVE9": "ardi801714@siigo.com",
                    "USUARIO RED": "ardi801714",
                    "CORREOELECTRONICO": "ardi801714@siigo.com",
                    "STATION ID": "3127586",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Cristhian David Nino Barrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016071011",
                    "FECHA INICIAL": "2021-08-23",
                    "FECHA OPERACION": "2021-08-23",
                    "NOMBRES": "Cindy Estefania",
                    "APELLIDOS": "Guzman Mora",
                    "NOMBRES COMPLETOS": "Cindy Estefania Guzman Mora",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Cristhian David Nino Barrera",
                    "CODIGO USUARIO": "guzm801702",
                    "USUARIOFIVE9": "guzm801702@siigo.com",
                    "USUARIO RED": "guzm801702",
                    "CORREOELECTRONICO": "guzm801702@siigo.com",
                    "STATION ID": "3127598",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Cristhian David Nino Barrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "CURM9406084N3",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Michell Estefania",
                    "APELLIDOS": "Cruz Rodriguez",
                    "NOMBRES COMPLETOS": "Michell Estefania Cruz Rodriguez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Rene Gerardo Paredes Alcantara",
                    "CODIGO USUARIO": "ecruz",
                    "USUARIOFIVE9": "ecruz@aspel.com.mx",
                    "USUARIO RED": "ecruz",
                    "CORREOELECTRONICO": "ecruz@aspel.com.mx",
                    "STATION ID": "3857162",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Rene Gerardo Paredes Alcantara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52075893",
                    "FECHA INICIAL": "2021-08-23",
                    "FECHA OPERACION": "2021-08-23",
                    "NOMBRES": "Sandra Patricia",
                    "APELLIDOS": "Caro Aponte",
                    "NOMBRES COMPLETOS": "Sandra Patricia Caro Aponte",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "caro801722",
                    "USUARIOFIVE9": "caro801722@siigo.com",
                    "USUARIO RED": "caro801722",
                    "CORREOELECTRONICO": "caro801722@siigo.com",
                    "STATION ID": "3127578",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "929219111",
                    "FECHA INICIAL": "2020-09-24",
                    "FECHA OPERACION": "2020-10-02",
                    "NOMBRES": "Stephanie Carolina",
                    "APELLIDOS": "Llerena Alfonzo",
                    "NOMBRES COMPLETOS": "Stephanie Carolina Llerena Alfonzo",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Sanchez Velez",
                    "CODIGO USUARIO": "ller800717",
                    "USUARIOFIVE9": "ller800717@siigo.com",
                    "USUARIO RED": "ller800717",
                    "CORREOELECTRONICO": "ller800717@siigo.com",
                    "STATION ID": "2606126",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Sanchez Velez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1070020738",
                    "FECHA INICIAL": "2021-08-23",
                    "FECHA OPERACION": "2021-08-23",
                    "NOMBRES": "Karen Dayana",
                    "APELLIDOS": "Guerra Rincon",
                    "NOMBRES COMPLETOS": "Karen Dayana Guerra Rincon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jessica Paola Paez Quintero",
                    "CODIGO USUARIO": "guer801720",
                    "USUARIOFIVE9": "guer801720@siigo.com",
                    "USUARIO RED": "guer801720",
                    "CORREOELECTRONICO": "guer801720@siigo.com",
                    "STATION ID": "3127580",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jessica Paola Paez Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010033811",
                    "FECHA INICIAL": "2020-10-01",
                    "FECHA OPERACION": "2020-10-02",
                    "NOMBRES": "Valentina",
                    "APELLIDOS": "Solano Gutierrez",
                    "NOMBRES COMPLETOS": "Valentina Solano Gutierrez",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Sanchez Velez",
                    "CODIGO USUARIO": "sola800768",
                    "USUARIOFIVE9": "sola800768@siigo.com",
                    "USUARIO RED": "sola800768",
                    "CORREOELECTRONICO": "sola800768@siigo.com",
                    "STATION ID": "2606133",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Sanchez Velez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030528361",
                    "FECHA INICIAL": "2021-08-23",
                    "FECHA OPERACION": "2021-08-23",
                    "NOMBRES": "Jennifer Rocio",
                    "APELLIDOS": "Baquero Sandoval",
                    "NOMBRES COMPLETOS": "Jennifer Rocio Baquero Sandoval",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Cristhian David Nino Barrera",
                    "CODIGO USUARIO": "baqu801726",
                    "USUARIOFIVE9": "baqu801726@siigo.com",
                    "USUARIO RED": "baqu801726",
                    "CORREOELECTRONICO": "baqu801726@siigo.com",
                    "STATION ID": "3127574",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Cristhian David Nino Barrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016013111",
                    "FECHA INICIAL": "2021-08-23",
                    "FECHA OPERACION": "2021-08-23",
                    "NOMBRES": "John Mauricio",
                    "APELLIDOS": "Rivera Paez",
                    "NOMBRES COMPLETOS": "John Mauricio Rivera Paez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Cristhian David Nino Barrera",
                    "CODIGO USUARIO": "rive801715",
                    "USUARIOFIVE9": "rive801715@siigo.com",
                    "USUARIO RED": "rive801715",
                    "CORREOELECTRONICO": "rive801715@siigo.com",
                    "STATION ID": "3127585",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Cristhian David Nino Barrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030579098",
                    "FECHA INICIAL": "2021-08-23",
                    "FECHA OPERACION": "2021-08-23",
                    "NOMBRES": "Wendy Yajarris",
                    "APELLIDOS": "Rojas Perilla",
                    "NOMBRES COMPLETOS": "Wendy Yajarris Rojas Perilla",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "roja801709",
                    "USUARIOFIVE9": "roja801709@siigo.com",
                    "USUARIO RED": "roja801709",
                    "CORREOELECTRONICO": "roja801709@siigo.com",
                    "STATION ID": "3127591",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52757068",
                    "FECHA INICIAL": "2021-08-23",
                    "FECHA OPERACION": "2021-08-23",
                    "NOMBRES": "Yenni Paola",
                    "APELLIDOS": "Gordillo Chacon",
                    "NOMBRES COMPLETOS": "Yenni Paola Gordillo Chacon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Cristhian David Nino Barrera",
                    "CODIGO USUARIO": "gord801725",
                    "USUARIOFIVE9": "gord801725@siigo.com",
                    "USUARIO RED": "gord801725",
                    "CORREOELECTRONICO": "gord801725@siigo.com",
                    "STATION ID": "3127575",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Cristhian David Nino Barrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1068973415",
                    "FECHA INICIAL": "2021-08-23",
                    "FECHA OPERACION": "2021-08-23",
                    "NOMBRES": "Yuri Nataly",
                    "APELLIDOS": "Diaz Acosta",
                    "NOMBRES COMPLETOS": "Yuri Nataly Diaz Acosta",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Cristhian David Nino Barrera",
                    "CODIGO USUARIO": "diaz801718",
                    "USUARIOFIVE9": "diaz801718@siigo.com",
                    "USUARIO RED": "diaz801718",
                    "CORREOELECTRONICO": "diaz801718@siigo.com",
                    "STATION ID": "3127582",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Cristhian David Nino Barrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022387095",
                    "FECHA INICIAL": "2021-09-01",
                    "FECHA OPERACION": "2021-09-01",
                    "NOMBRES": "Yuli Vanessa",
                    "APELLIDOS": "Guerrero Ocampo",
                    "NOMBRES COMPLETOS": "Yuli Vanessa Guerrero Ocampo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Daniel Eduardo Pinto Cepeda",
                    "CODIGO USUARIO": "guer801734",
                    "USUARIOFIVE9": "guer801734@siigo.com",
                    "USUARIO RED": "guer801734",
                    "CORREOELECTRONICO": "guer801734@siigo.com",
                    "STATION ID": "2606619",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Daniel Eduardo Pinto Cepeda",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057603948",
                    "FECHA INICIAL": "2021-09-01",
                    "FECHA OPERACION": "2021-09-01",
                    "NOMBRES": "Claudia Liliana",
                    "APELLIDOS": "Alvarez Ponguta",
                    "NOMBRES COMPLETOS": "Claudia Liliana Alvarez Ponguta",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones ECU",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Angie Camila Diaz Escobar",
                    "CODIGO USUARIO": "alva801799",
                    "USUARIOFIVE9": "alva801799@siigo.com",
                    "USUARIO RED": "alva801799",
                    "CORREOELECTRONICO": "alva801799@siigo.com",
                    "STATION ID": "3127493",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Camila Diaz Escobar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010213611",
                    "FECHA INICIAL": "2020-08-19",
                    "FECHA OPERACION": "2020-08-19",
                    "NOMBRES": "Natalia",
                    "APELLIDOS": "Cardona Gutierrez",
                    "NOMBRES COMPLETOS": "Natalia Cardona Gutierrez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adrian Alberto Salom Berrio",
                    "CODIGO USUARIO": "card800515",
                    "USUARIOFIVE9": "card800515@siigo.com",
                    "USUARIO RED": "card800515",
                    "CORREOELECTRONICO": "card800515@siigo.com",
                    "STATION ID": "2606098",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adrian Alberto Salom Berrio",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000502517",
                    "FECHA INICIAL": "2021-08-03",
                    "FECHA OPERACION": "2021-08-05",
                    "NOMBRES": "Nicole Stefani",
                    "APELLIDOS": "Corredor Herreno",
                    "NOMBRES COMPLETOS": "Nicole Stefani Corredor Herreno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Colombia",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Olga Lucia Ospina Vidal",
                    "CODIGO USUARIO": "corr801643",
                    "USUARIOFIVE9": "corr801643@siigo.com",
                    "USUARIO RED": "corr801643",
                    "CORREOELECTRONICO": "corr801643@siigo.com",
                    "STATION ID": "3127535",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Olga Lucia Ospina Vidal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143268053",
                    "FECHA INICIAL": "2021-09-01",
                    "FECHA OPERACION": "2021-09-01",
                    "NOMBRES": "Georgelly Johanna",
                    "APELLIDOS": "De La Hoz Fontalvo",
                    "NOMBRES COMPLETOS": "Georgelly Johanna De La Hoz Fontalvo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adrian Alberto Salom Berrio",
                    "CODIGO USUARIO": "dela801786",
                    "USUARIOFIVE9": "dela801786@siigo.com",
                    "USUARIO RED": "dela801786",
                    "CORREOELECTRONICO": "dela801786@siigo.com",
                    "STATION ID": "3267836",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adrian Alberto Salom Berrio",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143464229",
                    "FECHA INICIAL": "2021-09-01",
                    "FECHA OPERACION": "2021-09-01",
                    "NOMBRES": "Alexandra Vanessa",
                    "APELLIDOS": "Pozo Padilla",
                    "NOMBRES COMPLETOS": "Alexandra Vanessa Pozo Padilla",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adrian Alberto Salom Berrio",
                    "CODIGO USUARIO": "pozo801778",
                    "USUARIOFIVE9": "pozo801778@siigo.com",
                    "USUARIO RED": "pozo801778",
                    "CORREOELECTRONICO": "pozo801778@siigo.com",
                    "STATION ID": "3267841",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adrian Alberto Salom Berrio",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1040740638",
                    "FECHA INICIAL": "2021-09-01",
                    "FECHA OPERACION": "2021-09-01",
                    "NOMBRES": "Ana Gabriela",
                    "APELLIDOS": "Munoz Rodriguez",
                    "NOMBRES COMPLETOS": "Ana Gabriela Munoz Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Yeimy Milena Bustos Gutierrez",
                    "CODIGO USUARIO": "muno801777",
                    "USUARIOFIVE9": "muno801777@siigo.com",
                    "USUARIO RED": "muno801777",
                    "CORREOELECTRONICO": "muno801777@siigo.com",
                    "STATION ID": "3267842",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yeimy Milena Bustos Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000018632",
                    "FECHA INICIAL": "2020-12-07",
                    "FECHA OPERACION": "2020-12-07",
                    "NOMBRES": "Paula Lorena",
                    "APELLIDOS": "Gomez Figueroa",
                    "NOMBRES COMPLETOS": "Paula Lorena Gomez Figueroa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "gome800909",
                    "USUARIOFIVE9": "gome800909@siigo.com",
                    "USUARIO RED": "gome800909",
                    "CORREOELECTRONICO": "gome800909@siigo.com",
                    "STATION ID": "2606199",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143467570",
                    "FECHA INICIAL": "2021-09-01",
                    "FECHA OPERACION": "2021-09-01",
                    "NOMBRES": "Juliet Tatiana",
                    "APELLIDOS": "Cortes Acevedo",
                    "NOMBRES COMPLETOS": "Juliet Tatiana Cortes Acevedo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Cristian Camilo Hernandez Gonzalez",
                    "CODIGO USUARIO": "cort801768",
                    "USUARIOFIVE9": "cort801768@siigo.com",
                    "USUARIO RED": "cort801768",
                    "CORREOELECTRONICO": "cort801768@siigo.com",
                    "STATION ID": "3267845",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Cristian Camilo Hernandez Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057608088",
                    "FECHA INICIAL": "2021-09-01",
                    "FECHA OPERACION": "2021-09-01",
                    "NOMBRES": "Adriana Rocio",
                    "APELLIDOS": "Alarcon Cardenas",
                    "NOMBRES COMPLETOS": "Adriana Rocio Alarcon Cardenas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Eliana Maria Fajardo Prada",
                    "CODIGO USUARIO": "alac801763",
                    "USUARIOFIVE9": "alac801763@siigo.com",
                    "USUARIO RED": "alac801763",
                    "CORREOELECTRONICO": "alac801763@siigo.com",
                    "STATION ID": "3267847",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Eliana Maria Fajardo Prada",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012440532",
                    "FECHA INICIAL": "2021-09-01",
                    "FECHA OPERACION": "2021-09-01",
                    "NOMBRES": "Angie Lorena",
                    "APELLIDOS": "Zambrano Gomez",
                    "NOMBRES COMPLETOS": "Angie Lorena Zambrano Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "zamb801794",
                    "USUARIOFIVE9": "zamb801794@siigo.com",
                    "USUARIO RED": "zamb801794",
                    "CORREOELECTRONICO": "zamb801794@siigo.com",
                    "STATION ID": "3267858",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1090522256",
                    "FECHA INICIAL": "2021-09-01",
                    "FECHA OPERACION": "2021-09-01",
                    "NOMBRES": "Silvana Camila",
                    "APELLIDOS": "Jaimes Gafaro",
                    "NOMBRES COMPLETOS": "Silvana Camila Jaimes Gafaro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Planeacion Comercial",
                    "CARGO": "Bussiness Inteligence",
                    "JEFE INMEDIATO": "Jaime Andres Aguilar Rivera",
                    "CODIGO USUARIO": "jaim801774",
                    "USUARIOFIVE9": "jaim801774@siigo.com",
                    "USUARIO RED": "jaim801774",
                    "CORREOELECTRONICO": "jaim801774@siigo.com",
                    "STATION ID": "3690266",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jaime Andres Aguilar Rivera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "916554116",
                    "FECHA INICIAL": "2021-09-02",
                    "FECHA OPERACION": "2021-09-02",
                    "NOMBRES": "Jampier Benjamin",
                    "APELLIDOS": "Hernandez Hunt",
                    "NOMBRES COMPLETOS": "Jampier Benjamin Hernandez Hunt",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Michelle Matamoros Del Rosario",
                    "CODIGO USUARIO": "hern801745",
                    "USUARIOFIVE9": "hern801745@siigo.com",
                    "USUARIO RED": "hern801745",
                    "CORREOELECTRONICO": "hern801745@siigo.com",
                    "STATION ID": "2607001",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Michelle Matamoros Del Rosario",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "929722312",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-03-09",
                    "NOMBRES": "Ramon Isaac",
                    "APELLIDOS": "Vera Rodriguez",
                    "NOMBRES COMPLETOS": "Ramon Isaac Vera Rodriguez",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "vera801200",
                    "USUARIOFIVE9": "vera801200@siigo.com",
                    "USUARIO RED": "vera801200",
                    "CORREOELECTRONICO": "vera801200@siigo.com",
                    "STATION ID": "2606832",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "955743943",
                    "FECHA INICIAL": "2021-09-01",
                    "FECHA OPERACION": "2021-09-01",
                    "NOMBRES": "Johny Israel",
                    "APELLIDOS": "Alvear Jimenez",
                    "NOMBRES COMPLETOS": "Johny Israel Alvear Jimenez",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "alve801752",
                    "USUARIOFIVE9": "alve801752@siigo.com",
                    "USUARIO RED": "alve801752",
                    "CORREOELECTRONICO": "alve801752@siigo.com",
                    "STATION ID": "3267851",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "2400310088",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-03-09",
                    "NOMBRES": "Ramon Ulpiano",
                    "APELLIDOS": "Pilla Salinas",
                    "NOMBRES COMPLETOS": "Ramon Ulpiano Pilla Salinas",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "pill801197",
                    "USUARIOFIVE9": "pill801197@siigo.com",
                    "USUARIO RED": "pill801197",
                    "CORREOELECTRONICO": "pill801197@siigo.com",
                    "STATION ID": "2606829",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "953577392",
                    "FECHA INICIAL": "2021-09-01",
                    "FECHA OPERACION": "2021-09-01",
                    "NOMBRES": "Michelle Nydia",
                    "APELLIDOS": "Campana Haro",
                    "NOMBRES COMPLETOS": "Michelle Nydia Campana Haro",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "camp801749",
                    "USUARIOFIVE9": "camp801749@siigo.com",
                    "USUARIO RED": "camp801749",
                    "CORREOELECTRONICO": "camp801749@siigo.com",
                    "STATION ID": "3267854",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "931490049",
                    "FECHA INICIAL": "2020-09-24",
                    "FECHA OPERACION": "2020-10-02",
                    "NOMBRES": "Richard",
                    "APELLIDOS": "Mendez Garcia",
                    "NOMBRES COMPLETOS": "Richard Mendez Garcia",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "mend800703",
                    "USUARIOFIVE9": "mend800703@siigo.com",
                    "USUARIO RED": "mend800703",
                    "CORREOELECTRONICO": "mend800703@siigo.com",
                    "STATION ID": "2606125",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "953498680",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-03-09",
                    "NOMBRES": "Suanny Nicole",
                    "APELLIDOS": "Baquerizzo Puyol",
                    "NOMBRES COMPLETOS": "Suanny Nicole Baquerizzo Puyol",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "baqu801199",
                    "USUARIOFIVE9": "baqu801199@siigo.com",
                    "USUARIO RED": "baqu801199",
                    "CORREOELECTRONICO": "baqu801199@siigo.com",
                    "STATION ID": "2606831",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000586814",
                    "FECHA INICIAL": "2021-09-06",
                    "FECHA OPERACION": "2021-09-06",
                    "NOMBRES": "Oscar Daniel",
                    "APELLIDOS": "Rubio Romero",
                    "NOMBRES COMPLETOS": "Oscar Daniel Rubio Romero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Mercadeo",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Gina Estefania Melo Sanchez",
                    "CODIGO USUARIO": "rubi801811",
                    "USUARIOFIVE9": "rubi801811@siigo.com",
                    "USUARIO RED": "rubi801811",
                    "CORREOELECTRONICO": "rubi801811@siigo.com",
                    "STATION ID": "2606184",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Gina Estefania Melo Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143162879",
                    "FECHA INICIAL": "2021-09-06",
                    "FECHA OPERACION": "2021-09-06",
                    "NOMBRES": "Regina Sofia",
                    "APELLIDOS": "Carroll Arango",
                    "NOMBRES COMPLETOS": "Regina Sofia Carroll Arango",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "carr801804",
                    "USUARIOFIVE9": "carr801804@siigo.com",
                    "USUARIO RED": "carr801804",
                    "CORREOELECTRONICO": "carr801804@siigo.com",
                    "STATION ID": "3267870",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1047045690",
                    "FECHA INICIAL": "2021-09-06",
                    "FECHA OPERACION": "2021-09-06",
                    "NOMBRES": "Ronald Alberto",
                    "APELLIDOS": "Molina Palacio",
                    "NOMBRES COMPLETOS": "Ronald Alberto Molina Palacio",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones ECU",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Angie Camila Diaz Escobar",
                    "CODIGO USUARIO": "moli801801",
                    "USUARIOFIVE9": "moli801801@siigo.com",
                    "USUARIO RED": "moli801801",
                    "CORREOELECTRONICO": "moli801801@siigo.com",
                    "STATION ID": "3267873",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Camila Diaz Escobar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143251202",
                    "FECHA INICIAL": "2021-09-06",
                    "FECHA OPERACION": "2021-09-06",
                    "NOMBRES": "Katherine",
                    "APELLIDOS": "Becerra Garcia",
                    "NOMBRES COMPLETOS": "Katherine Becerra Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luz Katerine Maya Mogollon",
                    "CODIGO USUARIO": "bece801800",
                    "USUARIOFIVE9": "bece801800@siigo.com",
                    "USUARIO RED": "bece801800",
                    "CORREOELECTRONICO": "bece801800@siigo.com",
                    "STATION ID": "3267874",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Katerine Maya Mogollon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001890121",
                    "FECHA INICIAL": "2021-09-06",
                    "FECHA OPERACION": "2021-09-06",
                    "NOMBRES": "Nayelis Carolina",
                    "APELLIDOS": "Mejia Suarez",
                    "NOMBRES COMPLETOS": "Nayelis Carolina Mejia Suarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Leidy Stefany Castillo",
                    "CODIGO USUARIO": "meji801821",
                    "USUARIOFIVE9": "meji801821@siigo.com",
                    "USUARIO RED": "meji801821",
                    "CORREOELECTRONICO": "meji801821@siigo.com",
                    "STATION ID": "3267877",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Leidy Stefany Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "55313011",
                    "FECHA INICIAL": "2021-10-22",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Maria Luisa",
                    "APELLIDOS": "Orozco Suarez",
                    "NOMBRES COMPLETOS": "Maria Luisa Orozco Suarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luisa Fernanda Rodriguez Larrota",
                    "CODIGO USUARIO": "oroz802150",
                    "USUARIOFIVE9": "oroz802150@siigo.com",
                    "USUARIO RED": "oroz802150",
                    "CORREOELECTRONICO": "oroz802150@siigo.com",
                    "STATION ID": "3690277",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luisa Fernanda Rodriguez Larrota",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1205505355",
                    "FECHA INICIAL": "2021-09-07",
                    "FECHA OPERACION": "2021-09-07",
                    "NOMBRES": "Hillary Viviana",
                    "APELLIDOS": "Suarez Hinojosa",
                    "NOMBRES COMPLETOS": "Hillary Viviana Suarez Hinojosa",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Angel Alfonso Arciniegas Guerrero",
                    "CODIGO USUARIO": "suar801819",
                    "USUARIOFIVE9": "suar801819@siigo.com",
                    "USUARIO RED": "suar801819",
                    "CORREOELECTRONICO": "suar801819@siigo.com",
                    "STATION ID": "3267885",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Angel Alfonso Arciniegas Guerrero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1110509724",
                    "FECHA INICIAL": "2021-10-28",
                    "FECHA OPERACION": "2021-11-01",
                    "NOMBRES": "Yenni Marsella",
                    "APELLIDOS": "Miranda Torres",
                    "NOMBRES COMPLETOS": "Yenni Marsella Miranda Torres",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "mira802170",
                    "USUARIOFIVE9": "mira802170@siigo.com",
                    "USUARIO RED": "mira802170",
                    "CORREOELECTRONICO": "mira802170@siigo.com",
                    "STATION ID": "3690283",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1094249274",
                    "FECHA INICIAL": "2021-10-28",
                    "FECHA OPERACION": "2021-11-01",
                    "NOMBRES": "Adriana",
                    "APELLIDOS": "Villamizar Jaimes",
                    "NOMBRES COMPLETOS": "Adriana Villamizar Jaimes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jefrey Julian Sanchez Celemin",
                    "CODIGO USUARIO": "vill802174",
                    "USUARIOFIVE9": "vill802174@siigo.com",
                    "USUARIO RED": "vill802174",
                    "CORREOELECTRONICO": "vill802174@siigo.com",
                    "STATION ID": "3690287",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jefrey Julian Sanchez Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018477685",
                    "FECHA INICIAL": "2021-09-16",
                    "FECHA OPERACION": "2021-09-16",
                    "NOMBRES": "William Fernando",
                    "APELLIDOS": "Medina Camargo",
                    "NOMBRES COMPLETOS": "William Fernando Medina Camargo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luz Katerine Maya Mogollon",
                    "CODIGO USUARIO": "medi801917",
                    "USUARIOFIVE9": "medi801917@siigo.com",
                    "USUARIO RED": "medi801917",
                    "CORREOELECTRONICO": "medi801917@siigo.com",
                    "STATION ID": "3483703",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Katerine Maya Mogollon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013659327",
                    "FECHA INICIAL": "2020-02-10",
                    "FECHA OPERACION": "2020-02-10",
                    "NOMBRES": "Yinary Lorena",
                    "APELLIDOS": "Martinez Aguirre",
                    "NOMBRES COMPLETOS": "Yinary Lorena Martinez Aguirre",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Cristian Camilo Hernandez Gonzalez",
                    "CODIGO USUARIO": "mart800244",
                    "USUARIOFIVE9": "mart800244@siigo.com",
                    "USUARIO RED": "mart800244",
                    "CORREOELECTRONICO": "mart800244@siigo.com",
                    "STATION ID": "2607015",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Cristian Camilo Hernandez Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "917354714",
                    "FECHA INICIAL": "2021-03-08",
                    "FECHA OPERACION": "2021-04-07",
                    "NOMBRES": "Tatiana Katherine",
                    "APELLIDOS": "Gutierrez Murillo",
                    "NOMBRES COMPLETOS": "Tatiana Katherine Gutierrez Murillo",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "guti801236",
                    "USUARIOFIVE9": "guti801236@siigo.com",
                    "USUARIO RED": "guti801236",
                    "CORREOELECTRONICO": "guti801236@siigo.com",
                    "STATION ID": "2606742",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "929377620",
                    "FECHA INICIAL": "2021-09-09",
                    "FECHA OPERACION": "2021-09-09",
                    "NOMBRES": "Luis Enrique",
                    "APELLIDOS": "Mejia Pincay",
                    "NOMBRES COMPLETOS": "Luis Enrique Mejia Pincay",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Customer",
                    "SUBAREA": "UCI",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Nestor Oswaldo Becerra Prieto",
                    "CODIGO USUARIO": "meji801850",
                    "USUARIOFIVE9": "meji801850@siigo.com",
                    "USUARIO RED": "meji801850",
                    "CORREOELECTRONICO": "meji801850@siigo.com",
                    "STATION ID": "3267901",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Nestor Oswaldo Becerra Prieto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1056804373",
                    "FECHA INICIAL": "2021-09-10",
                    "FECHA OPERACION": "2021-09-10",
                    "NOMBRES": "Yenny Veronica",
                    "APELLIDOS": "Ramirez Gil",
                    "NOMBRES COMPLETOS": "Yenny Veronica Ramirez Gil",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jazbleidy Andrea Nunez Montoya",
                    "CODIGO USUARIO": "rami801824",
                    "USUARIOFIVE9": "rami801824@siigo.com",
                    "USUARIO RED": "rami801824",
                    "CORREOELECTRONICO": "rami801824@siigo.com",
                    "STATION ID": "3267902",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jazbleidy Andrea Nunez Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1113676129",
                    "FECHA INICIAL": "2021-09-10",
                    "FECHA OPERACION": "2021-09-10",
                    "NOMBRES": "Luis Edgar",
                    "APELLIDOS": "Velez Guerrero",
                    "NOMBRES COMPLETOS": "Luis Edgar Velez Guerrero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Palmira",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Nicoll Torres Celemin",
                    "CODIGO USUARIO": "vele801829",
                    "USUARIOFIVE9": "vele801829@siigo.com",
                    "USUARIO RED": "vele801829",
                    "CORREOELECTRONICO": "vele801829@siigo.com",
                    "STATION ID": "3267907",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Nicoll Torres Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1234094766",
                    "FECHA INICIAL": "2021-09-10",
                    "FECHA OPERACION": "2021-09-10",
                    "NOMBRES": "Wendy Vanessa",
                    "APELLIDOS": "Duarte Pinzon",
                    "NOMBRES COMPLETOS": "Wendy Vanessa Duarte Pinzon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Johanna Patricia Torres Quintero",
                    "CODIGO USUARIO": "duar801830",
                    "USUARIOFIVE9": "duar801830@siigo.com",
                    "USUARIO RED": "duar801830",
                    "CORREOELECTRONICO": "duar801830@siigo.com",
                    "STATION ID": "3267908",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Johanna Patricia Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1110595830",
                    "FECHA INICIAL": "2021-09-10",
                    "FECHA OPERACION": "2021-09-10",
                    "NOMBRES": "Lina Johana",
                    "APELLIDOS": "Salazar Garavito",
                    "NOMBRES COMPLETOS": "Lina Johana Salazar Garavito",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Armando Mariano Cirilo",
                    "CODIGO USUARIO": "sala801831",
                    "USUARIOFIVE9": "sala801831@siigo.com",
                    "USUARIO RED": "sala801831",
                    "CORREOELECTRONICO": "sala801831@siigo.com",
                    "STATION ID": "3267909",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Armando Mariano Cirilo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057590136",
                    "FECHA INICIAL": "2021-09-10",
                    "FECHA OPERACION": "2021-09-10",
                    "NOMBRES": "Monica Lizeth",
                    "APELLIDOS": "Sanabria Vargas",
                    "NOMBRES COMPLETOS": "Monica Lizeth Sanabria Vargas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Boyaca",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Hancel Rafael Salcedo Mendoza",
                    "CODIGO USUARIO": "sana801832",
                    "USUARIOFIVE9": "sana801832@siigo.com",
                    "USUARIO RED": "sana801832",
                    "CORREOELECTRONICO": "sana801832@siigo.com",
                    "STATION ID": "3267910",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Hancel Rafael Salcedo Mendoza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "706509999",
                    "FECHA INICIAL": "2021-09-01",
                    "FECHA OPERACION": "2021-09-01",
                    "NOMBRES": "Estefanny Paulina",
                    "APELLIDOS": "Macanchi Carpio",
                    "NOMBRES COMPLETOS": "Estefanny Paulina Macanchi Carpio",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "maca801753",
                    "USUARIOFIVE9": "maca801753@siigo.com",
                    "USUARIO RED": "maca801753",
                    "CORREOELECTRONICO": "maca801753@siigo.com",
                    "STATION ID": "3267850",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033695430",
                    "FECHA INICIAL": "2021-08-17",
                    "FECHA OPERACION": "2021-08-18",
                    "NOMBRES": "Jose Armel",
                    "APELLIDOS": "Zambrano Trujillo",
                    "NOMBRES COMPLETOS": "Jose Armel Zambrano Trujillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Enrique Varela Lasso",
                    "CODIGO USUARIO": "zamb801674",
                    "USUARIOFIVE9": "zamb801674@siigo.com",
                    "USUARIO RED": "zamb801674",
                    "CORREOELECTRONICO": "zamb801674@siigo.com",
                    "STATION ID": "2606991",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Enrique Varela Lasso",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022413540",
                    "FECHA INICIAL": "2020-10-08",
                    "FECHA OPERACION": "2020-10-08",
                    "NOMBRES": "Rafael Eduardo",
                    "APELLIDOS": "Vargas Agamez",
                    "NOMBRES COMPLETOS": "Rafael Eduardo Vargas Agamez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Diana Sofia Ramos Castillo",
                    "CODIGO USUARIO": "varg800787",
                    "USUARIOFIVE9": "varg800787@siigo.com",
                    "USUARIO RED": "varg800787",
                    "CORREOELECTRONICO": "varg800787@siigo.com",
                    "STATION ID": "2606143",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Sofia Ramos Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007787788",
                    "FECHA INICIAL": "2021-10-28",
                    "FECHA OPERACION": "2021-11-01",
                    "NOMBRES": "Daniela Adriana",
                    "APELLIDOS": "Rico Rubiano",
                    "NOMBRES COMPLETOS": "Daniela Adriana Rico Rubiano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luisa Fernanda Rodriguez Larrota",
                    "CODIGO USUARIO": "rico802179",
                    "USUARIOFIVE9": "rico802179@siigo.com",
                    "USUARIO RED": "rico802179",
                    "CORREOELECTRONICO": "rico802179@siigo.com",
                    "STATION ID": "3690292",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luisa Fernanda Rodriguez Larrota",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007273704",
                    "FECHA INICIAL": "2021-09-11",
                    "FECHA OPERACION": "2021-09-11",
                    "NOMBRES": "Bibiana Yaneth",
                    "APELLIDOS": "Guevara Cubillos",
                    "NOMBRES COMPLETOS": "Bibiana Yaneth Guevara Cubillos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Nicoll Torres Celemin",
                    "CODIGO USUARIO": "guev801868",
                    "USUARIOFIVE9": "guev801868@siigo.com",
                    "USUARIO RED": "guev801868",
                    "CORREOELECTRONICO": "guev801868@siigo.com",
                    "STATION ID": "3471488",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Nicoll Torres Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1192796326",
                    "FECHA INICIAL": "2021-09-11",
                    "FECHA OPERACION": "2021-09-11",
                    "NOMBRES": "German Andres",
                    "APELLIDOS": "Carrascal Heilbron",
                    "NOMBRES COMPLETOS": "German Andres Carrascal Heilbron",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leidy Marcela Rodriguez Rivera",
                    "CODIGO USUARIO": "carr801872",
                    "USUARIOFIVE9": "carr801872@siigo.com",
                    "USUARIO RED": "carr801872",
                    "CORREOELECTRONICO": "carr801872@siigo.com",
                    "STATION ID": "3471492",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leidy Marcela Rodriguez Rivera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000215939",
                    "FECHA INICIAL": "2021-09-11",
                    "FECHA OPERACION": "2021-09-11",
                    "NOMBRES": "Paula Karina",
                    "APELLIDOS": "Novoa Puentes",
                    "NOMBRES COMPLETOS": "Paula Karina Novoa Puentes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Paula Alejandra Garcia Lujan",
                    "CODIGO USUARIO": "novo801875",
                    "USUARIOFIVE9": "novo801875@siigo.com",
                    "USUARIO RED": "novo801875",
                    "CORREOELECTRONICO": "novo801875@siigo.com",
                    "STATION ID": "3471495",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Paula Alejandra Garcia Lujan",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057603999",
                    "FECHA INICIAL": "2021-09-11",
                    "FECHA OPERACION": "2021-09-11",
                    "NOMBRES": "Angie Lorena",
                    "APELLIDOS": "Barrera Ciendua",
                    "NOMBRES COMPLETOS": "Angie Lorena Barrera Ciendua",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Villavicencio",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Paula Alejandra Garcia Lujan",
                    "CODIGO USUARIO": "barr801878",
                    "USUARIOFIVE9": "barr801878@siigo.com",
                    "USUARIO RED": "barr801878",
                    "CORREOELECTRONICO": "barr801878@siigo.com",
                    "STATION ID": "3471498",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Paula Alejandra Garcia Lujan",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1045528033",
                    "FECHA INICIAL": "2021-09-11",
                    "FECHA OPERACION": "2021-09-11",
                    "NOMBRES": "Paula Andrea",
                    "APELLIDOS": "Romana Julio",
                    "NOMBRES COMPLETOS": "Paula Andrea Romana Julio",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Paula Alejandra Garcia Lujan",
                    "CODIGO USUARIO": "roma801881",
                    "USUARIOFIVE9": "roma801881@siigo.com",
                    "USUARIO RED": "roma801881",
                    "CORREOELECTRONICO": "roma801881@siigo.com",
                    "STATION ID": "3471500",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Paula Alejandra Garcia Lujan",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000831605",
                    "FECHA INICIAL": "2021-09-11",
                    "FECHA OPERACION": "2021-09-11",
                    "NOMBRES": "Adriana Paola",
                    "APELLIDOS": "Castiblanco Yepes",
                    "NOMBRES COMPLETOS": "Adriana Paola Castiblanco Yepes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Paula Alejandra Garcia Lujan",
                    "CODIGO USUARIO": "cast801882",
                    "USUARIOFIVE9": "cast801882@siigo.com",
                    "USUARIO RED": "cast801882",
                    "CORREOELECTRONICO": "cast801882@siigo.com",
                    "STATION ID": "3471501",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Paula Alejandra Garcia Lujan",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016083212",
                    "FECHA INICIAL": "2021-09-11",
                    "FECHA OPERACION": "2021-09-11",
                    "NOMBRES": "Yeraldine",
                    "APELLIDOS": "Diaz Villa",
                    "NOMBRES COMPLETOS": "Yeraldine Diaz Villa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Paula Alejandra Garcia Lujan",
                    "CODIGO USUARIO": "diaz801883",
                    "USUARIOFIVE9": "diaz801883@siigo.com",
                    "USUARIO RED": "diaz801883",
                    "CORREOELECTRONICO": "diaz801883@siigo.com",
                    "STATION ID": "3471502",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Paula Alejandra Garcia Lujan",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1140900349",
                    "FECHA INICIAL": "2021-09-14",
                    "FECHA OPERACION": "2021-09-14",
                    "NOMBRES": "Idalia Marcela",
                    "APELLIDOS": "Acosta Rodriguez",
                    "NOMBRES COMPLETOS": "Idalia Marcela Acosta Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Fernando Sotto Cardozo",
                    "CODIGO USUARIO": "acos801880",
                    "USUARIOFIVE9": "acos801880@siigo.com",
                    "USUARIO RED": "acos801880",
                    "CORREOELECTRONICO": "acos801880@siigo.com",
                    "STATION ID": "3471508",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Fernando Sotto Cardozo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016080486",
                    "FECHA INICIAL": "2021-09-14",
                    "FECHA OPERACION": "2021-09-14",
                    "NOMBRES": "Maria Camila",
                    "APELLIDOS": "Ariza Triana",
                    "NOMBRES COMPLETOS": "Maria Camila Ariza Triana",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juvenal Reyes Silva",
                    "CODIGO USUARIO": "ariz801889",
                    "USUARIOFIVE9": "ariz801889@siigo.com",
                    "USUARIO RED": "ariz801889",
                    "CORREOELECTRONICO": "ariz801889@siigo.com",
                    "STATION ID": "3483694",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juvenal Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "63448552",
                    "FECHA INICIAL": "2007-03-23",
                    "FECHA OPERACION": "2007-03-23",
                    "NOMBRES": "Elsy Johanna",
                    "APELLIDOS": "Jaimes Bermudez",
                    "NOMBRES COMPLETOS": "Elsy Johanna Jaimes Bermudez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "jaim217148",
                    "USUARIOFIVE9": "jaim217148@siigo.com",
                    "USUARIO RED": "jaim217148",
                    "CORREOELECTRONICO": "jaim217148@siigo.com",
                    "STATION ID": "2606673",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1065611568",
                    "FECHA INICIAL": "2021-12-01",
                    "FECHA OPERACION": "2021-12-06",
                    "NOMBRES": "Alejandro David",
                    "APELLIDOS": "Ovalle Escobar",
                    "NOMBRES COMPLETOS": "Alejandro David Ovalle Escobar",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Valledupar",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Enrique Varela Lasso",
                    "CODIGO USUARIO": "oval802316",
                    "USUARIOFIVE9": "oval802316@siigo.com",
                    "USUARIO RED": "oval802316",
                    "CORREOELECTRONICO": "oval802316@siigo.com",
                    "STATION ID": "2606072",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Enrique Varela Lasso",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1106779797",
                    "FECHA INICIAL": "2021-09-16",
                    "FECHA OPERACION": "2021-09-16",
                    "NOMBRES": "Liset Viviana",
                    "APELLIDOS": "Cerquera Cuellar",
                    "NOMBRES COMPLETOS": "Liset Viviana Cerquera Cuellar",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jazbleidy Andrea Nunez Montoya",
                    "CODIGO USUARIO": "cerq801914",
                    "USUARIOFIVE9": "cerq801914@siigo.com",
                    "USUARIO RED": "cerq801914",
                    "CORREOELECTRONICO": "cerq801914@siigo.com",
                    "STATION ID": "3483706",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jazbleidy Andrea Nunez Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001938467",
                    "FECHA INICIAL": "2021-09-16",
                    "FECHA OPERACION": "2021-09-16",
                    "NOMBRES": "Luz Daniela",
                    "APELLIDOS": "Estrada Pino",
                    "NOMBRES COMPLETOS": "Luz Daniela Estrada Pino",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call ADM",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Hancel Rafael Salcedo Mendoza",
                    "CODIGO USUARIO": "estr801913",
                    "USUARIOFIVE9": "estr801913@siigo.com",
                    "USUARIO RED": "estr801913",
                    "CORREOELECTRONICO": "estr801913@siigo.com",
                    "STATION ID": "3483707",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Hancel Rafael Salcedo Mendoza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1044432800",
                    "FECHA INICIAL": "2021-12-01",
                    "FECHA OPERACION": "2021-12-06",
                    "NOMBRES": "Ana Maria",
                    "APELLIDOS": "Corro Altahona",
                    "NOMBRES COMPLETOS": "Ana Maria Corro Altahona",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Enrique Varela Lasso",
                    "CODIGO USUARIO": "corr802309",
                    "USUARIOFIVE9": "corr802309@siigo.com",
                    "USUARIO RED": "corr802309",
                    "CORREOELECTRONICO": "corr802309@siigo.com",
                    "STATION ID": "2606139",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Enrique Varela Lasso",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057601587",
                    "FECHA INICIAL": "2021-09-16",
                    "FECHA OPERACION": "2021-09-16",
                    "NOMBRES": "Sulma Carolina",
                    "APELLIDOS": "Landinez Alvarado",
                    "NOMBRES COMPLETOS": "Sulma Carolina Landinez Alvarado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Boyaca",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "land801909",
                    "USUARIOFIVE9": "land801909@siigo.com",
                    "USUARIO RED": "land801909",
                    "CORREOELECTRONICO": "land801909@siigo.com",
                    "STATION ID": "3483711",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000077679",
                    "FECHA INICIAL": "2021-12-02",
                    "FECHA OPERACION": "2021-12-07",
                    "NOMBRES": "David Felipe",
                    "APELLIDOS": "Castro Leon",
                    "NOMBRES COMPLETOS": "David Felipe Castro Leon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luisa Fernanda Rodriguez Larrota",
                    "CODIGO USUARIO": "cast802344",
                    "USUARIOFIVE9": "cast802344@siigo.com",
                    "USUARIO RED": "cast802344",
                    "CORREOELECTRONICO": "cast802344@siigo.com",
                    "STATION ID": "2606740",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luisa Fernanda Rodriguez Larrota",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1121923094",
                    "FECHA INICIAL": "2021-12-01",
                    "FECHA OPERACION": "2021-12-07",
                    "NOMBRES": "Manuel Elkin",
                    "APELLIDOS": "Patarroyo Herrera",
                    "NOMBRES COMPLETOS": "Manuel Elkin Patarroyo Herrera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Villavicencio",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luisa Fernanda Rodriguez Larrota",
                    "CODIGO USUARIO": "pata802325",
                    "USUARIOFIVE9": "pata802325@siigo.com",
                    "USUARIO RED": "pata802325",
                    "CORREOELECTRONICO": "pata802325@siigo.com",
                    "STATION ID": "2607023",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luisa Fernanda Rodriguez Larrota",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057600396",
                    "FECHA INICIAL": "2021-09-16",
                    "FECHA OPERACION": "2021-09-16",
                    "NOMBRES": "Maria Jose",
                    "APELLIDOS": "Guatibonza Florez",
                    "NOMBRES COMPLETOS": "Maria Jose Guatibonza Florez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Boyaca",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adrian Alberto Salom Berrio",
                    "CODIGO USUARIO": "guat801921",
                    "USUARIOFIVE9": "guat801921@siigo.com",
                    "USUARIO RED": "guat801921",
                    "CORREOELECTRONICO": "guat801921@siigo.com",
                    "STATION ID": "3483720",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adrian Alberto Salom Berrio",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52494024",
                    "FECHA INICIAL": "2021-09-16",
                    "FECHA OPERACION": "2021-09-20",
                    "NOMBRES": "Yazmin Andrea",
                    "APELLIDOS": "Correcha Pinzon",
                    "NOMBRES COMPLETOS": "Yazmin Andrea Correcha Pinzon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jefferson Arvey Ramirez Ibanez",
                    "CODIGO USUARIO": "corr801928",
                    "USUARIOFIVE9": "corr801928@siigo.com",
                    "USUARIO RED": "corr801928",
                    "CORREOELECTRONICO": "corr801928@siigo.com",
                    "STATION ID": "3483724",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jefferson Arvey Ramirez Ibanez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1128326715",
                    "FECHA INICIAL": "2021-09-16",
                    "FECHA OPERACION": "2021-09-20",
                    "NOMBRES": "Margarita Rosa",
                    "APELLIDOS": "Hernandez Aguilar",
                    "NOMBRES COMPLETOS": "Margarita Rosa Hernandez Aguilar",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jesus Daniel Arzuza Roman",
                    "CODIGO USUARIO": "hern801931",
                    "USUARIOFIVE9": "hern801931@siigo.com",
                    "USUARIO RED": "hern801931",
                    "CORREOELECTRONICO": "hern801931@siigo.com",
                    "STATION ID": "3483727",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jesus Daniel Arzuza Roman",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "924448327",
                    "FECHA INICIAL": "2021-09-01",
                    "FECHA OPERACION": "2021-09-01",
                    "NOMBRES": "Anny Gabriela",
                    "APELLIDOS": "Contreras Pincay",
                    "NOMBRES COMPLETOS": "Anny Gabriela Contreras Pincay",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "cont801751",
                    "USUARIOFIVE9": "cont801751@siigo.com",
                    "USUARIO RED": "cont801751",
                    "CORREOELECTRONICO": "cont801751@siigo.com",
                    "STATION ID": "3267852",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143463735",
                    "FECHA INICIAL": "2021-09-22",
                    "FECHA OPERACION": "2021-09-23",
                    "NOMBRES": "Aura Fernanda",
                    "APELLIDOS": "Angarita Martinez",
                    "NOMBRES COMPLETOS": "Aura Fernanda Angarita Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "anga801936",
                    "USUARIOFIVE9": "anga801936@siigo.com",
                    "USUARIO RED": "anga801936",
                    "CORREOELECTRONICO": "anga801936@siigo.com",
                    "STATION ID": "2606080",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1077975934",
                    "FECHA INICIAL": "2021-09-22",
                    "FECHA OPERACION": "2021-09-23",
                    "NOMBRES": "Camilo Andres",
                    "APELLIDOS": "Quintana Hernandez",
                    "NOMBRES COMPLETOS": "Camilo Andres Quintana Hernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angy Paola Umbacia Diaz",
                    "CODIGO USUARIO": "quin801941",
                    "USUARIOFIVE9": "quin801941@siigo.com",
                    "USUARIO RED": "quin801941",
                    "CORREOELECTRONICO": "quin801941@siigo.com",
                    "STATION ID": "3483746",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angy Paola Umbacia Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019092616",
                    "FECHA INICIAL": "2021-09-22",
                    "FECHA OPERACION": "2021-09-23",
                    "NOMBRES": "Elsy Tatiana",
                    "APELLIDOS": "Parada Basabe",
                    "NOMBRES COMPLETOS": "Elsy Tatiana Parada Basabe",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "para801948",
                    "USUARIOFIVE9": "para801948@siigo.com",
                    "USUARIO RED": "para801948",
                    "CORREOELECTRONICO": "para801948@siigo.com",
                    "STATION ID": "2606877",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007692304",
                    "FECHA INICIAL": "2021-09-22",
                    "FECHA OPERACION": "2021-09-23",
                    "NOMBRES": "Gabriela Maury",
                    "APELLIDOS": "Xiques Jimenez",
                    "NOMBRES COMPLETOS": "Gabriela Maury Xiques Jimenez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexis Abraham Sanchez Portillo",
                    "CODIGO USUARIO": "xiqu801939",
                    "USUARIOFIVE9": "xiqu801939@siigo.com",
                    "USUARIO RED": "xiqu801939",
                    "CORREOELECTRONICO": "xiqu801939@siigo.com",
                    "STATION ID": "2606196",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexis Abraham Sanchez Portillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1047239177",
                    "FECHA INICIAL": "2021-09-22",
                    "FECHA OPERACION": "2021-09-23",
                    "NOMBRES": "German Orlando",
                    "APELLIDOS": "Carreno Orellano",
                    "NOMBRES COMPLETOS": "German Orlando Carreno Orellano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Contenidos",
                    "CARGO": "Analista Gestion del Conocimiento",
                    "JEFE INMEDIATO": "Luz Angelica Torres Quintero",
                    "CODIGO USUARIO": "carr801947",
                    "USUARIOFIVE9": "carr801947@siigo.com",
                    "USUARIO RED": "carr801947",
                    "CORREOELECTRONICO": "carr801947@siigo.com",
                    "STATION ID": "2606863",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Angelica Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1006032469",
                    "FECHA INICIAL": "2021-09-22",
                    "FECHA OPERACION": "2021-09-23",
                    "NOMBRES": "Maria Angelica",
                    "APELLIDOS": "Useche Cardenas",
                    "NOMBRES COMPLETOS": "Maria Angelica Useche Cardenas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Fernando Sotto Cardozo",
                    "CODIGO USUARIO": "usec801951",
                    "USUARIOFIVE9": "usec801951@siigo.com",
                    "USUARIO RED": "usec801951",
                    "CORREOELECTRONICO": "usec801951@siigo.com",
                    "STATION ID": "2607087",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Fernando Sotto Cardozo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1099209785",
                    "FECHA INICIAL": "2021-09-22",
                    "FECHA OPERACION": "2021-09-23",
                    "NOMBRES": "Sully Stephany",
                    "APELLIDOS": "Uribe Mayoral",
                    "NOMBRES COMPLETOS": "Sully Stephany Uribe Mayoral",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luz Katerine Maya Mogollon",
                    "CODIGO USUARIO": "urib801943",
                    "USUARIOFIVE9": "urib801943@siigo.com",
                    "USUARIO RED": "urib801943",
                    "CORREOELECTRONICO": "urib801943@siigo.com",
                    "STATION ID": "2606733",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Katerine Maya Mogollon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "28216942",
                    "FECHA INICIAL": "2021-09-29",
                    "FECHA OPERACION": "2021-09-29",
                    "NOMBRES": "Juan Manuel",
                    "APELLIDOS": "Nunez Mateos",
                    "NOMBRES COMPLETOS": "Juan Manuel Nunez Mateos",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Elias Jusid Machado",
                    "CODIGO USUARIO": "nune801955",
                    "USUARIOFIVE9": "nune801955@siigo.com",
                    "USUARIO RED": "nune801955",
                    "CORREOELECTRONICO": "nune801955@siigo.com",
                    "STATION ID": "2606933",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Elias Jusid Machado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "55188893",
                    "FECHA INICIAL": "2021-09-29",
                    "FECHA OPERACION": "2021-09-29",
                    "NOMBRES": "Nicolas",
                    "APELLIDOS": "Gomez Tabarez",
                    "NOMBRES COMPLETOS": "Nicolas Gomez Tabarez",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "Call Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Elias Jusid Machado",
                    "CODIGO USUARIO": "gome801954",
                    "USUARIOFIVE9": "gome801954@siigo.com",
                    "USUARIO RED": "gome801954",
                    "CORREOELECTRONICO": "gome801954@siigo.com",
                    "STATION ID": "2607119",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Elias Jusid Machado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "922406061",
                    "FECHA INICIAL": "2021-09-29",
                    "FECHA OPERACION": "2021-10-04",
                    "NOMBRES": "Carlos Luis",
                    "APELLIDOS": "Larco Cabrera",
                    "NOMBRES COMPLETOS": "Carlos Luis Larco Cabrera",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Walter Antonio Munoz Antepara",
                    "CODIGO USUARIO": "larc801970",
                    "USUARIOFIVE9": "larc801970@siigo.com",
                    "USUARIO RED": "larc801970",
                    "CORREOELECTRONICO": "larc801970@siigo.com",
                    "STATION ID": "2607121",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Walter Antonio Munoz Antepara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "922502836",
                    "FECHA INICIAL": "2021-09-29",
                    "FECHA OPERACION": "2021-10-04",
                    "NOMBRES": "Maite Valentina",
                    "APELLIDOS": "Figueroa Moran",
                    "NOMBRES COMPLETOS": "Maite Valentina Figueroa Moran",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Walter Antonio Munoz Antepara",
                    "CODIGO USUARIO": "figu801968",
                    "USUARIOFIVE9": "figu801968@siigo.com",
                    "USUARIO RED": "figu801968",
                    "CORREOELECTRONICO": "figu801968@siigo.com",
                    "STATION ID": "3127525",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Walter Antonio Munoz Antepara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1099215393",
                    "FECHA INICIAL": "2021-09-30",
                    "FECHA OPERACION": "2021-10-04",
                    "NOMBRES": "Jessica Andrea",
                    "APELLIDOS": "Sanabria Gerena",
                    "NOMBRES COMPLETOS": "Jessica Andrea Sanabria Gerena",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jefrey Julian Sanchez Celemin",
                    "CODIGO USUARIO": "sana801981",
                    "USUARIOFIVE9": "sana801981@siigo.com",
                    "USUARIO RED": "sana801981",
                    "CORREOELECTRONICO": "sana801981@siigo.com",
                    "STATION ID": "3127529",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jefrey Julian Sanchez Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1005417933",
                    "FECHA INICIAL": "2021-09-30",
                    "FECHA OPERACION": "2021-10-04",
                    "NOMBRES": "Miguel Alberto",
                    "APELLIDOS": "Arciria Guevara",
                    "NOMBRES COMPLETOS": "Miguel Alberto Arciria Guevara",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adrian Alberto Salom Berrio",
                    "CODIGO USUARIO": "arci801980",
                    "USUARIOFIVE9": "arci801980@siigo.com",
                    "USUARIO RED": "arci801980",
                    "CORREOELECTRONICO": "arci801980@siigo.com",
                    "STATION ID": "3127568",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adrian Alberto Salom Berrio",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1073502478",
                    "FECHA INICIAL": "2022-06-14",
                    "FECHA OPERACION": "2022-06-20",
                    "NOMBRES": "Lady Valentina",
                    "APELLIDOS": "Blanco Sevilla",
                    "NOMBRES COMPLETOS": "Lady Valentina Blanco Sevilla",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Funza",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Sonia Mabel Fernandez Calderon",
                    "CODIGO USUARIO": "blan803301",
                    "USUARIOFIVE9": "blan803301@siigo.com",
                    "USUARIO RED": "blan803301",
                    "CORREOELECTRONICO": "blan803301@siigo.com",
                    "STATION ID": "2606922",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sonia Mabel Fernandez Calderon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "940910359",
                    "FECHA INICIAL": "2021-09-30",
                    "FECHA OPERACION": "2021-10-04",
                    "NOMBRES": "Eliana Edilma",
                    "APELLIDOS": "Alvarado Canales",
                    "NOMBRES COMPLETOS": "Eliana Edilma Alvarado Canales",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Michelle Matamoros Del Rosario",
                    "CODIGO USUARIO": "alva801987",
                    "USUARIOFIVE9": "alva801987@siigo.com",
                    "USUARIO RED": "alva801987",
                    "CORREOELECTRONICO": "alva801987@siigo.com",
                    "STATION ID": "3483729",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Michelle Matamoros Del Rosario",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "953542461",
                    "FECHA INICIAL": "2021-09-30",
                    "FECHA OPERACION": "2021-10-04",
                    "NOMBRES": "Itaty Melany",
                    "APELLIDOS": "Delgado Romero",
                    "NOMBRES COMPLETOS": "Itaty Melany Delgado Romero",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Michelle Matamoros Del Rosario",
                    "CODIGO USUARIO": "delg801988",
                    "USUARIOFIVE9": "delg801988@siigo.com",
                    "USUARIO RED": "delg801988",
                    "CORREOELECTRONICO": "delg801988@siigo.com",
                    "STATION ID": "3483730",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Michelle Matamoros Del Rosario",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "923665004",
                    "FECHA INICIAL": "2021-10-01",
                    "FECHA OPERACION": "2021-10-04",
                    "NOMBRES": "David Isaac",
                    "APELLIDOS": "Encalada Plaza",
                    "NOMBRES COMPLETOS": "David Isaac Encalada Plaza",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Walter Antonio Munoz Antepara",
                    "CODIGO USUARIO": "enca801998",
                    "USUARIOFIVE9": "enca801998@siigo.com",
                    "USUARIO RED": "enca801998",
                    "CORREOELECTRONICO": "enca801998@siigo.com",
                    "STATION ID": "3483731",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Walter Antonio Munoz Antepara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "943635391",
                    "FECHA INICIAL": "2021-10-01",
                    "FECHA OPERACION": "2021-10-04",
                    "NOMBRES": "Dana Victoria",
                    "APELLIDOS": "Veloz Garcia",
                    "NOMBRES COMPLETOS": "Dana Victoria Veloz Garcia",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "velo802000",
                    "USUARIOFIVE9": "velo802000@siigo.com",
                    "USUARIO RED": "velo802000",
                    "CORREOELECTRONICO": "velo802000@siigo.com",
                    "STATION ID": "3483733",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1095821807",
                    "FECHA INICIAL": "2021-10-01",
                    "FECHA OPERACION": "2021-10-04",
                    "NOMBRES": "Silvia Melissa",
                    "APELLIDOS": "Carrillo Parada",
                    "NOMBRES COMPLETOS": "Silvia Melissa Carrillo Parada",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "carr801985",
                    "USUARIOFIVE9": "carr801985@siigo.com",
                    "USUARIO RED": "carr801985",
                    "CORREOELECTRONICO": "carr801985@siigo.com",
                    "STATION ID": "3483735",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1152704595",
                    "FECHA INICIAL": "2021-10-01",
                    "FECHA OPERACION": "2021-10-04",
                    "NOMBRES": "Carlos Miguel",
                    "APELLIDOS": "Durango Panesso",
                    "NOMBRES COMPLETOS": "Carlos Miguel Durango Panesso",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones ECU",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Angie Camila Diaz Escobar",
                    "CODIGO USUARIO": "dura801996",
                    "USUARIOFIVE9": "dura801996@siigo.com",
                    "USUARIO RED": "dura801996",
                    "CORREOELECTRONICO": "dura801996@siigo.com",
                    "STATION ID": "3483740",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Camila Diaz Escobar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "63321345",
                    "FECHA INICIAL": "2021-10-04",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Maria Nydia",
                    "APELLIDOS": "Barrientos Orjuela",
                    "NOMBRES COMPLETOS": "Maria Nydia Barrientos Orjuela",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angy Paola Umbacia Diaz",
                    "CODIGO USUARIO": "barr802004",
                    "USUARIOFIVE9": "barr802004@siigo.com",
                    "USUARIO RED": "barr802004",
                    "CORREOELECTRONICO": "barr802004@siigo.com",
                    "STATION ID": "2606049",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angy Paola Umbacia Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000515961",
                    "FECHA INICIAL": "2021-10-04",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Linda Mayerly",
                    "APELLIDOS": "Bastidas Charry",
                    "NOMBRES COMPLETOS": "Linda Mayerly Bastidas Charry",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "bast802005",
                    "USUARIOFIVE9": "bast802005@siigo.com",
                    "USUARIO RED": "bast802005",
                    "CORREOELECTRONICO": "bast802005@siigo.com",
                    "STATION ID": "2606064",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1005152744",
                    "FECHA INICIAL": "2021-10-04",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Jose David",
                    "APELLIDOS": "Mesa Quintero",
                    "NOMBRES COMPLETOS": "Jose David Mesa Quintero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Diana Sofia Ramos Castillo",
                    "CODIGO USUARIO": "mesa802007",
                    "USUARIOFIVE9": "mesa802007@siigo.com",
                    "USUARIO RED": "mesa802007",
                    "CORREOELECTRONICO": "mesa802007@siigo.com",
                    "STATION ID": "2606138",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Sofia Ramos Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098710503",
                    "FECHA INICIAL": "2021-10-04",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Elsa Carolina",
                    "APELLIDOS": "Santos Garcia",
                    "NOMBRES COMPLETOS": "Elsa Carolina Santos Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angy Paola Umbacia Diaz",
                    "CODIGO USUARIO": "sant802008",
                    "USUARIOFIVE9": "sant802008@siigo.com",
                    "USUARIO RED": "sant802008",
                    "CORREOELECTRONICO": "sant802008@siigo.com",
                    "STATION ID": "2606181",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angy Paola Umbacia Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1020769080",
                    "FECHA INICIAL": "2021-10-04",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Diego Fabian",
                    "APELLIDOS": "Sosa Orjuela",
                    "NOMBRES COMPLETOS": "Diego Fabian Sosa Orjuela",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Yuleidy Josefina Briceno Fernandez",
                    "CODIGO USUARIO": "sosa802013",
                    "USUARIOFIVE9": "sosa802013@siigo.com",
                    "USUARIO RED": "sosa802013",
                    "CORREOELECTRONICO": "sosa802013@siigo.com",
                    "STATION ID": "2606542",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Yuleidy Josefina Briceno Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1043027815",
                    "FECHA INICIAL": "2021-10-06",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Jose Angel",
                    "APELLIDOS": "Mercado Romero",
                    "NOMBRES COMPLETOS": "Jose Angel Mercado Romero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jenny Paola Mendez",
                    "CODIGO USUARIO": "merc802017",
                    "USUARIOFIVE9": "merc802017@siigo.com",
                    "USUARIO RED": "merc802017",
                    "CORREOELECTRONICO": "merc802017@siigo.com",
                    "STATION ID": "2606686",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jenny Paola Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022441964",
                    "FECHA INICIAL": "2021-10-06",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Valentina",
                    "APELLIDOS": "Martinez Piza",
                    "NOMBRES COMPLETOS": "Valentina Martinez Piza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "UCI Fidelizacion",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Nestor Oswaldo Becerra Prieto",
                    "CODIGO USUARIO": "mart802016",
                    "USUARIOFIVE9": "mart802016@siigo.com",
                    "USUARIO RED": "mart802016",
                    "CORREOELECTRONICO": "mart802016@siigo.com",
                    "STATION ID": "2606808",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Nestor Oswaldo Becerra Prieto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "66858284",
                    "FECHA INICIAL": "2007-02-01",
                    "FECHA OPERACION": "2007-02-16",
                    "NOMBRES": "Yamileth Erazo",
                    "APELLIDOS": "Agreda",
                    "NOMBRES COMPLETOS": "Yamileth Erazo Agreda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Eliana Maria Fajardo Prada",
                    "CODIGO USUARIO": "eraz185336",
                    "USUARIOFIVE9": "eraz185336@siigo.com",
                    "USUARIO RED": "eraz185336",
                    "CORREOELECTRONICO": "eraz185336@siigo.com",
                    "STATION ID": "2606411",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Eliana Maria Fajardo Prada",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1095941418",
                    "FECHA INICIAL": "2021-10-06",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Lesly Dayana",
                    "APELLIDOS": "Cepeda Marino",
                    "NOMBRES COMPLETOS": "Lesly Dayana Cepeda Marino",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jenny Paola Mendez",
                    "CODIGO USUARIO": "cepe802050",
                    "USUARIOFIVE9": "cepe802050@siigo.com",
                    "USUARIO RED": "cepe802050",
                    "CORREOELECTRONICO": "cepe802050@siigo.com",
                    "STATION ID": "2607035",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jenny Paola Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52666567",
                    "FECHA INICIAL": "2021-10-07",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Sarah Angelica",
                    "APELLIDOS": "Contreras Diaz",
                    "NOMBRES COMPLETOS": "Sarah Angelica Contreras Diaz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jenny Paola Mendez",
                    "CODIGO USUARIO": "cont802044",
                    "USUARIOFIVE9": "cont802044@siigo.com",
                    "USUARIO RED": "cont802044",
                    "CORREOELECTRONICO": "cont802044@siigo.com",
                    "STATION ID": "3127587",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jenny Paola Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033815218",
                    "FECHA INICIAL": "2021-10-07",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Nelson Makensy",
                    "APELLIDOS": "Huertas Jimenes",
                    "NOMBRES COMPLETOS": "Nelson Makensy Huertas Jimenes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Yamid Ramiro Quintero Montano",
                    "CODIGO USUARIO": "huer802040",
                    "USUARIOFIVE9": "huer802040@siigo.com",
                    "USUARIO RED": "huer802040",
                    "CORREOELECTRONICO": "huer802040@siigo.com",
                    "STATION ID": "3267918",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yamid Ramiro Quintero Montano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "11037252",
                    "FECHA INICIAL": "2021-10-07",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Jose Alfredo",
                    "APELLIDOS": "Petro Martinez",
                    "NOMBRES COMPLETOS": "Jose Alfredo Petro Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Sory Yaqueline Abello Polo",
                    "CODIGO USUARIO": "petr802038",
                    "USUARIOFIVE9": "petr802038@siigo.com",
                    "USUARIO RED": "petr802038",
                    "CORREOELECTRONICO": "petr802038@siigo.com",
                    "STATION ID": "3471487",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sory Yaqueline Abello Polo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012439608",
                    "FECHA INICIAL": "2018-07-26",
                    "FECHA OPERACION": "2018-07-26",
                    "NOMBRES": "Yulie Maricsa",
                    "APELLIDOS": "Caballero Mateus",
                    "NOMBRES COMPLETOS": "Yulie Maricsa Caballero Mateus",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Lida Katerine Puentes Navarro",
                    "CODIGO USUARIO": "caba111749",
                    "USUARIOFIVE9": "caba111749@siigo.com",
                    "USUARIO RED": "caba111749",
                    "CORREOELECTRONICO": "caba111749@siigo.com",
                    "STATION ID": "2606573",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Lida Katerine Puentes Navarro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52146653",
                    "FECHA INICIAL": "2021-10-07",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Marlene",
                    "APELLIDOS": "Gamboa Bohorquez",
                    "NOMBRES COMPLETOS": "Marlene Gamboa Bohorquez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jenny Paola Mendez",
                    "CODIGO USUARIO": "gamb802035",
                    "USUARIOFIVE9": "gamb802035@siigo.com",
                    "USUARIO RED": "gamb802035",
                    "CORREOELECTRONICO": "gamb802035@siigo.com",
                    "STATION ID": "3483704",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jenny Paola Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1140896332",
                    "FECHA INICIAL": "2021-10-07",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Angie Carolina",
                    "APELLIDOS": "Olmos Rodriguez",
                    "NOMBRES COMPLETOS": "Angie Carolina Olmos Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexis Abraham Sanchez Portillo",
                    "CODIGO USUARIO": "olmo802032",
                    "USUARIOFIVE9": "olmo802032@siigo.com",
                    "USUARIO RED": "olmo802032",
                    "CORREOELECTRONICO": "olmo802032@siigo.com",
                    "STATION ID": "3483748",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexis Abraham Sanchez Portillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143441829",
                    "FECHA INICIAL": "2021-10-07",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Diana Patricia",
                    "APELLIDOS": "Torres Quiroz",
                    "NOMBRES COMPLETOS": "Diana Patricia Torres Quiroz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jenny Paola Mendez",
                    "CODIGO USUARIO": "torr802031",
                    "USUARIOFIVE9": "torr802031@siigo.com",
                    "USUARIO RED": "torr802031",
                    "CORREOELECTRONICO": "torr802031@siigo.com",
                    "STATION ID": "3483749",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jenny Paola Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "761597",
                    "FECHA INICIAL": "2021-10-07",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Imna Nohemi",
                    "APELLIDOS": "Rivera Perez",
                    "NOMBRES COMPLETOS": "Imna Nohemi Rivera Perez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jenny Paola Mendez",
                    "CODIGO USUARIO": "rive802029",
                    "USUARIOFIVE9": "rive802029@siigo.com",
                    "USUARIO RED": "rive802029",
                    "CORREOELECTRONICO": "rive802029@siigo.com",
                    "STATION ID": "3483751",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jenny Paola Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1214748505",
                    "FECHA INICIAL": "2021-10-07",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Karen Daniela",
                    "APELLIDOS": "Fonseca Gallego",
                    "NOMBRES COMPLETOS": "Karen Daniela Fonseca Gallego",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Armando Mariano Cirilo",
                    "CODIGO USUARIO": "fons802028",
                    "USUARIOFIVE9": "fons802028@siigo.com",
                    "USUARIO RED": "fons802028",
                    "CORREOELECTRONICO": "fons802028@siigo.com",
                    "STATION ID": "3483752",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Armando Mariano Cirilo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014284259",
                    "FECHA INICIAL": "2021-10-07",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Leidy Yethcenia",
                    "APELLIDOS": "Munoz Rodriguez",
                    "NOMBRES COMPLETOS": "Leidy Yethcenia Munoz Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "UCI",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Nestor Oswaldo Becerra Prieto",
                    "CODIGO USUARIO": "muno802026",
                    "USUARIOFIVE9": "muno802026@siigo.com",
                    "USUARIO RED": "muno802026",
                    "CORREOELECTRONICO": "muno802026@siigo.com",
                    "STATION ID": "3483754",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Nestor Oswaldo Becerra Prieto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019078747",
                    "FECHA INICIAL": "2021-10-07",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Maria Alejandra",
                    "APELLIDOS": "Sandoval Jimerez",
                    "NOMBRES COMPLETOS": "Maria Alejandra Sandoval Jimenez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "UCI Fidelizacion",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Nestor Oswaldo Becerra Prieto",
                    "CODIGO USUARIO": "sand802025",
                    "USUARIOFIVE9": "sand802025@siigo.com",
                    "USUARIO RED": "sand802025",
                    "CORREOELECTRONICO": "sand802025@siigo.com",
                    "STATION ID": "3483755",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Nestor Oswaldo Becerra Prieto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014186545",
                    "FECHA INICIAL": "2021-10-07",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Libardo Andres",
                    "APELLIDOS": "Heredia Posada",
                    "NOMBRES COMPLETOS": "Libardo Andres Heredia Posada",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Leidy Stefany Castillo",
                    "CODIGO USUARIO": "here802023",
                    "USUARIOFIVE9": "here802023@siigo.com",
                    "USUARIO RED": "here802023",
                    "CORREOELECTRONICO": "here802023@siigo.com",
                    "STATION ID": "3483757",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Leidy Stefany Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1071170287",
                    "FECHA INICIAL": "2021-10-07",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Maria Paula",
                    "APELLIDOS": "Roa Moreno",
                    "NOMBRES COMPLETOS": "Maria Paula Roa Moreno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Jimena Marcela Prieto Bohorquez",
                    "CODIGO USUARIO": "roam802021",
                    "USUARIOFIVE9": "roam802021@siigo.com",
                    "USUARIO RED": "roam802021",
                    "CORREOELECTRONICO": "roam802021@siigo.com",
                    "STATION ID": "3483759",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jimena Marcela Prieto Bohorquez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1026592658",
                    "FECHA INICIAL": "2021-10-07",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Karen Alejandra",
                    "APELLIDOS": "Ramirez Rodriguez",
                    "NOMBRES COMPLETOS": "Karen Alejandra Ramirez Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoraciones",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "rami802020",
                    "USUARIOFIVE9": "rami802020@siigo.com",
                    "USUARIO RED": "rami802020",
                    "CORREOELECTRONICO": "rami802020@siigo.com",
                    "STATION ID": "3483760",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018497389",
                    "FECHA INICIAL": "2021-10-07",
                    "FECHA OPERACION": "2021-10-11",
                    "NOMBRES": "Rosa Angelica",
                    "APELLIDOS": "Gamboa Leon",
                    "NOMBRES COMPLETOS": "Rosa Angelica Gamboa Leon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Eduardo Sanchez Castano",
                    "CODIGO USUARIO": "gamb802019",
                    "USUARIOFIVE9": "gamb802019@siigo.com",
                    "USUARIO RED": "gamb802019",
                    "CORREOELECTRONICO": "gamb802019@siigo.com",
                    "STATION ID": "3483761",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Eduardo Sanchez Castano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1006118645",
                    "FECHA INICIAL": "2021-10-08",
                    "FECHA OPERACION": "2021-10-13",
                    "NOMBRES": "Karen Daniela",
                    "APELLIDOS": "Rodriguez Torres",
                    "NOMBRES COMPLETOS": "Karen Daniela Rodriguez Torres",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Call ADM",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Hancel Rafael Salcedo Mendoza",
                    "CODIGO USUARIO": "rodr802055",
                    "USUARIOFIVE9": "rodr802055@siigo.com",
                    "USUARIO RED": "rodr802055",
                    "CORREOELECTRONICO": "rodr802055@siigo.com",
                    "STATION ID": "3483763",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Hancel Rafael Salcedo Mendoza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098749965",
                    "FECHA INICIAL": "2021-10-08",
                    "FECHA OPERACION": "2021-10-13",
                    "NOMBRES": "Yesica Patricia",
                    "APELLIDOS": "Blanco Alvarado",
                    "NOMBRES COMPLETOS": "Yesica Patricia Blanco Alvarado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jazbleidy Andrea Nunez Montoya",
                    "CODIGO USUARIO": "blan802056",
                    "USUARIOFIVE9": "blan802056@siigo.com",
                    "USUARIO RED": "blan802056",
                    "CORREOELECTRONICO": "blan802056@siigo.com",
                    "STATION ID": "3483764",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jazbleidy Andrea Nunez Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007744681",
                    "FECHA INICIAL": "2021-10-08",
                    "FECHA OPERACION": "2021-10-13",
                    "NOMBRES": "Maria Jose",
                    "APELLIDOS": "Ardila Ojeda",
                    "NOMBRES COMPLETOS": "Maria Jose Ardila Ojeda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adrian Alberto Salom Berrio",
                    "CODIGO USUARIO": "ardi802057",
                    "USUARIOFIVE9": "ardi802057@siigo.com",
                    "USUARIO RED": "ardi802057",
                    "CORREOELECTRONICO": "ardi802057@siigo.com",
                    "STATION ID": "3483765",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adrian Alberto Salom Berrio",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53089344",
                    "FECHA INICIAL": "2021-10-08",
                    "FECHA OPERACION": "2021-10-13",
                    "NOMBRES": "Angelica",
                    "APELLIDOS": "Cardenas Rodriguez",
                    "NOMBRES COMPLETOS": "Angelica Cardenas Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Hancel Rafael Salcedo Mendoza",
                    "CODIGO USUARIO": "card802058",
                    "USUARIOFIVE9": "card802058@siigo.com",
                    "USUARIO RED": "card802058",
                    "CORREOELECTRONICO": "card802058@siigo.com",
                    "STATION ID": "3483766",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Hancel Rafael Salcedo Mendoza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057594815",
                    "FECHA INICIAL": "2021-09-01",
                    "FECHA OPERACION": "2021-09-01",
                    "NOMBRES": "William Andres",
                    "APELLIDOS": "Navas Cardenas",
                    "NOMBRES COMPLETOS": "William Andres Navas Cardenas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Colombia",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Olga Lucia Ospina Vidal",
                    "CODIGO USUARIO": "nava801775",
                    "USUARIOFIVE9": "nava801775@siigo.com",
                    "USUARIO RED": "nava801775",
                    "CORREOELECTRONICO": "nava801775@siigo.com",
                    "STATION ID": "3267843",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Olga Lucia Ospina Vidal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022369392",
                    "FECHA INICIAL": "2021-10-08",
                    "FECHA OPERACION": "2021-10-13",
                    "NOMBRES": "Cindy",
                    "APELLIDOS": "Rodriguez Bautista",
                    "NOMBRES COMPLETOS": "Cindy Rodriguez Bautista",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Colombia",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Olga Lucia Ospina Vidal",
                    "CODIGO USUARIO": "rodr802063",
                    "USUARIOFIVE9": "rodr802063@siigo.com",
                    "USUARIO RED": "rodr802063",
                    "CORREOELECTRONICO": "rodr802063@siigo.com",
                    "STATION ID": "3483771",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Olga Lucia Ospina Vidal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1047362685",
                    "FECHA INICIAL": "2021-12-01",
                    "FECHA OPERACION": "2021-12-07",
                    "NOMBRES": "Carlos Andres",
                    "APELLIDOS": "Truyol Gutierrez",
                    "NOMBRES COMPLETOS": "Carlos Andres Truyol Gutierrez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Enrique Varela Lasso",
                    "CODIGO USUARIO": "truy802317",
                    "USUARIOFIVE9": "truy802317@siigo.com",
                    "USUARIO RED": "truy802317",
                    "CORREOELECTRONICO": "truy802317@siigo.com",
                    "STATION ID": "3127552",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Enrique Varela Lasso",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79781919",
                    "FECHA INICIAL": "2021-10-08",
                    "FECHA OPERACION": "2021-10-13",
                    "NOMBRES": "Angel Alfonso",
                    "APELLIDOS": "Arciniegas Guerrero",
                    "NOMBRES COMPLETOS": "Angel Alfonso Arciniegas Guerrero",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Ecuador",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Jose Manuel Aguayo Escandon",
                    "CODIGO USUARIO": "arci802072",
                    "USUARIOFIVE9": "arci802072@siigo.com",
                    "USUARIO RED": "arci802072",
                    "CORREOELECTRONICO": "arci802072@siigo.com",
                    "STATION ID": "3690234",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jose Manuel Aguayo Escandon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032476641",
                    "FECHA INICIAL": "2019-10-07",
                    "FECHA OPERACION": "2019-10-22",
                    "NOMBRES": "Angie Daniela",
                    "APELLIDOS": "Wilches Penaloza",
                    "NOMBRES COMPLETOS": "Angie Daniela Wilches Penaloza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Lida Katerine Puentes Navarro",
                    "CODIGO USUARIO": "wilc112439",
                    "USUARIOFIVE9": "wilc112439@siigo.com",
                    "USUARIO RED": "wilc112439",
                    "CORREOELECTRONICO": "wilc112439@siigo.com",
                    "STATION ID": "2606710",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Lida Katerine Puentes Navarro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "63561019",
                    "FECHA INICIAL": "2021-10-15",
                    "FECHA OPERACION": "2021-10-20",
                    "NOMBRES": "Diana Andrea",
                    "APELLIDOS": "Pinilla Basto",
                    "NOMBRES COMPLETOS": "Diana Andrea Pinilla Basto",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jenny Paola Mendez",
                    "CODIGO USUARIO": "pini802089",
                    "USUARIOFIVE9": "pini802089@siigo.com",
                    "USUARIO RED": "pini802089",
                    "CORREOELECTRONICO": "pini802089@siigo.com",
                    "STATION ID": "3267892",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jenny Paola Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012320015",
                    "FECHA INICIAL": "2021-10-15",
                    "FECHA OPERACION": "2021-10-20",
                    "NOMBRES": "Leidy Johanna",
                    "APELLIDOS": "Agudelo Arias",
                    "NOMBRES COMPLETOS": "Leidy Johanna Agudelo Arias",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Leidy Stefany Castillo",
                    "CODIGO USUARIO": "agud802090",
                    "USUARIOFIVE9": "agud802090@siigo.com",
                    "USUARIO RED": "agud802090",
                    "CORREOELECTRONICO": "agud802090@siigo.com",
                    "STATION ID": "3267899",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Leidy Stefany Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1062405739",
                    "FECHA INICIAL": "2021-10-15",
                    "FECHA OPERACION": "2021-10-20",
                    "NOMBRES": "Laura Milena",
                    "APELLIDOS": "Rueda Munoz",
                    "NOMBRES COMPLETOS": "Laura Milena Rueda Munoz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ventas",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "rued802092",
                    "USUARIOFIVE9": "rued802092@siigo.com",
                    "USUARIO RED": "rued802092",
                    "CORREOELECTRONICO": "rued802092@siigo.com",
                    "STATION ID": "3483713",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013664720",
                    "FECHA INICIAL": "2021-10-15",
                    "FECHA OPERACION": "2021-10-20",
                    "NOMBRES": "Laura Katheryn",
                    "APELLIDOS": "Betacourt Sarmiento",
                    "NOMBRES COMPLETOS": "Laura Katheryn Betacourt Sarmiento",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Patricia Villarraga Forero",
                    "CODIGO USUARIO": "beta802093",
                    "USUARIOFIVE9": "beta802093@siigo.com",
                    "USUARIO RED": "beta802093",
                    "CORREOELECTRONICO": "beta802093@siigo.com",
                    "STATION ID": "3483774",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Patricia Villarraga Forero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030630814",
                    "FECHA INICIAL": "2021-10-15",
                    "FECHA OPERACION": "2021-10-20",
                    "NOMBRES": "Rudy Alexandra",
                    "APELLIDOS": "Mateus Rodriguez",
                    "NOMBRES COMPLETOS": "Rudy Alexandra Mateus Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "UCI Fidelizacion",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Nestor Oswaldo Becerra Prieto",
                    "CODIGO USUARIO": "mate802096",
                    "USUARIOFIVE9": "mate802096@siigo.com",
                    "USUARIO RED": "mate802096",
                    "CORREOELECTRONICO": "mate802096@siigo.com",
                    "STATION ID": "3690240",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Nestor Oswaldo Becerra Prieto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013690908",
                    "FECHA INICIAL": "2021-10-15",
                    "FECHA OPERACION": "2021-10-20",
                    "NOMBRES": "Jenny Paola",
                    "APELLIDOS": "Angarita Perez",
                    "NOMBRES COMPLETOS": "Jenny Paola Angarita Perez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Yamid Ramiro Quintero Montano",
                    "CODIGO USUARIO": "anga802097",
                    "USUARIOFIVE9": "anga802097@siigo.com",
                    "USUARIO RED": "anga802097",
                    "CORREOELECTRONICO": "anga802097@siigo.com",
                    "STATION ID": "3690241",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yamid Ramiro Quintero Montano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030578374",
                    "FECHA INICIAL": "2021-10-15",
                    "FECHA OPERACION": "2021-10-20",
                    "NOMBRES": "Jeisson Alirio",
                    "APELLIDOS": "Tapiero Gonzalez",
                    "NOMBRES COMPLETOS": "Jeisson Alirio Tapiero Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexis Abraham Sanchez Portillo",
                    "CODIGO USUARIO": "tapi802101",
                    "USUARIOFIVE9": "tapi802101@siigo.com",
                    "USUARIO RED": "tapi802101",
                    "CORREOELECTRONICO": "tapi802101@siigo.com",
                    "STATION ID": "3690245",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexis Abraham Sanchez Portillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030624946",
                    "FECHA INICIAL": "2020-09-14",
                    "FECHA OPERACION": "2020-09-14",
                    "NOMBRES": "Cindy Carolina",
                    "APELLIDOS": "Castrillon Castillo",
                    "NOMBRES COMPLETOS": "Cindy Carolina Castrillon Castillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Lida Katerine Puentes Navarro",
                    "CODIGO USUARIO": "cast800677",
                    "USUARIOFIVE9": "cast800677@siigo.com",
                    "USUARIO RED": "cast800677",
                    "CORREOELECTRONICO": "cast800677@siigo.com",
                    "STATION ID": "2605973",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Lida Katerine Puentes Navarro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143158556",
                    "FECHA INICIAL": "2021-10-15",
                    "FECHA OPERACION": "2021-10-20",
                    "NOMBRES": "Luis Gregorio",
                    "APELLIDOS": "Bravo Diazgranados",
                    "NOMBRES COMPLETOS": "Luis Gregorio Bravo Diazgranados",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Sory Yaqueline Abello Polo",
                    "CODIGO USUARIO": "brav802103",
                    "USUARIOFIVE9": "brav802103@siigo.com",
                    "USUARIO RED": "brav802103",
                    "CORREOELECTRONICO": "brav802103@siigo.com",
                    "STATION ID": "3690247",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sory Yaqueline Abello Polo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1234888265",
                    "FECHA INICIAL": "2021-10-15",
                    "FECHA OPERACION": "2021-10-20",
                    "NOMBRES": "Arantxa",
                    "APELLIDOS": "Echavez Molina",
                    "NOMBRES COMPLETOS": "Arantxa Echavez Molina",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Diana Sofia Ramos Castillo",
                    "CODIGO USUARIO": "echa802104",
                    "USUARIOFIVE9": "echa802104@siigo.com",
                    "USUARIO RED": "echa802104",
                    "CORREOELECTRONICO": "echa802104@siigo.com",
                    "STATION ID": "3690248",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Sofia Ramos Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007645559",
                    "FECHA INICIAL": "2020-10-19",
                    "FECHA OPERACION": "2020-10-22",
                    "NOMBRES": "Estefany",
                    "APELLIDOS": "Ahumada Torres",
                    "NOMBRES COMPLETOS": "Estefany Ahumada Torres",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Eliana Maria Fajardo Prada",
                    "CODIGO USUARIO": "ahum800815",
                    "USUARIOFIVE9": "ahum800815@siigo.com",
                    "USUARIO RED": "ahum800815",
                    "CORREOELECTRONICO": "ahum800815@siigo.com",
                    "STATION ID": "2606173",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Eliana Maria Fajardo Prada",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "64064028",
                    "FECHA INICIAL": "2021-10-25",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Suhail Marina",
                    "APELLIDOS": "Bonaguro Gamboa",
                    "NOMBRES COMPLETOS": "Suhail Marina Bonaguro Gamboa",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo Uruguay",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Giselda Keymetlian",
                    "CODIGO USUARIO": "bona802151",
                    "USUARIOFIVE9": "bona802151@siigo.com",
                    "USUARIO RED": "bona802151",
                    "CORREOELECTRONICO": "bona802151@siigo.com",
                    "STATION ID": "3690251",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Giselda Keymetlian",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143115501",
                    "FECHA INICIAL": "2021-10-20",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Jenifer",
                    "APELLIDOS": "Castro Martinez",
                    "NOMBRES COMPLETOS": "Jenifer Castro Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jesus Daniel Arzuza Roman",
                    "CODIGO USUARIO": "cast802110",
                    "USUARIOFIVE9": "cast802110@siigo.com",
                    "USUARIO RED": "cast802110",
                    "CORREOELECTRONICO": "cast802110@siigo.com",
                    "STATION ID": "2606030",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jesus Daniel Arzuza Roman",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80415800",
                    "FECHA INICIAL": "2021-10-21",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Alberto",
                    "APELLIDOS": "Nunez Angarita",
                    "NOMBRES COMPLETOS": "Alberto Nunez Angarita",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Producto Back-Office",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Juan Carlos Corazzo Santos",
                    "CODIGO USUARIO": "nune802116",
                    "USUARIOFIVE9": "nune802116@siigo.com",
                    "USUARIO RED": "nune802116",
                    "CORREOELECTRONICO": "nune802116@siigo.com",
                    "STATION ID": "2606614",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Carlos Corazzo Santos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023956389",
                    "FECHA INICIAL": "2021-10-21",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Leidy Daniela",
                    "APELLIDOS": "Calderon Velasquez",
                    "NOMBRES COMPLETOS": "Leidy Daniela Calderon Velasquez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jenny Paola Mendez",
                    "CODIGO USUARIO": "cald802117",
                    "USUARIOFIVE9": "cald802117@siigo.com",
                    "USUARIO RED": "cald802117",
                    "CORREOELECTRONICO": "cald802117@siigo.com",
                    "STATION ID": "2606692",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jenny Paola Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033790465",
                    "FECHA INICIAL": "2021-10-21",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Aaron Andres",
                    "APELLIDOS": "Ballen Perez",
                    "NOMBRES COMPLETOS": "Aaron Andres Ballen Perez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Hancel Rafael Salcedo Mendoza",
                    "CODIGO USUARIO": "ball802118",
                    "USUARIOFIVE9": "ball802118@siigo.com",
                    "USUARIO RED": "ball802118",
                    "CORREOELECTRONICO": "ball802118@siigo.com",
                    "STATION ID": "2607107",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Hancel Rafael Salcedo Mendoza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1049644047",
                    "FECHA INICIAL": "2021-12-01",
                    "FECHA OPERACION": "2021-12-07",
                    "NOMBRES": "Leidy Johana",
                    "APELLIDOS": "Munoz Suarez",
                    "NOMBRES COMPLETOS": "Leidy Johana Munoz Suarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luisa Fernanda Rodriguez Larrota",
                    "CODIGO USUARIO": "muno802314",
                    "USUARIOFIVE9": "muno802314@siigo.com",
                    "USUARIO RED": "muno802314",
                    "CORREOELECTRONICO": "muno802314@siigo.com",
                    "STATION ID": "3127599",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luisa Fernanda Rodriguez Larrota",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001043634",
                    "FECHA INICIAL": "2021-10-21",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Angie Gabriela",
                    "APELLIDOS": "Espinosa Molina",
                    "NOMBRES COMPLETOS": "Angie Gabriela Espinosa Molina",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Colombia",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Olga Lucia Ospina Vidal",
                    "CODIGO USUARIO": "espi802122",
                    "USUARIOFIVE9": "espi802122@siigo.com",
                    "USUARIO RED": "espi802122",
                    "CORREOELECTRONICO": "espi802122@siigo.com",
                    "STATION ID": "3127593",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Olga Lucia Ospina Vidal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143266330",
                    "FECHA INICIAL": "2021-10-21",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Luis Enrique",
                    "APELLIDOS": "Pacheco Romero",
                    "NOMBRES COMPLETOS": "Luis Enrique Pacheco Romero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Carolina Linares Gonzalez",
                    "CODIGO USUARIO": "pach802123",
                    "USUARIOFIVE9": "pach802123@siigo.com",
                    "USUARIO RED": "pach802123",
                    "CORREOELECTRONICO": "pach802123@siigo.com",
                    "STATION ID": "3267846",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Carolina Linares Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030677333",
                    "FECHA INICIAL": "2021-10-21",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Leidy Paola",
                    "APELLIDOS": "Saavedra Parada",
                    "NOMBRES COMPLETOS": "Leidy Paola Saavedra Parada",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Carolina Linares Gonzalez",
                    "CODIGO USUARIO": "saav802124",
                    "USUARIOFIVE9": "saav802124@siigo.com",
                    "USUARIO RED": "saav802124",
                    "CORREOELECTRONICO": "saav802124@siigo.com",
                    "STATION ID": "3690252",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Carolina Linares Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057603754",
                    "FECHA INICIAL": "2021-10-21",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Manuel Antonio",
                    "APELLIDOS": "Perez Rodriguez",
                    "NOMBRES COMPLETOS": "Manuel Antonio Perez Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "pere802125",
                    "USUARIOFIVE9": "pere802125@siigo.com",
                    "USUARIO RED": "pere802125",
                    "CORREOELECTRONICO": "pere802125@siigo.com",
                    "STATION ID": "3690253",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098787159",
                    "FECHA INICIAL": "2021-10-21",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Azly",
                    "APELLIDOS": "Valencia Gonzalez",
                    "NOMBRES COMPLETOS": "Azly Valencia Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Colombia",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Olga Lucia Ospina Vidal",
                    "CODIGO USUARIO": "vale802126",
                    "USUARIOFIVE9": "vale802126@siigo.com",
                    "USUARIO RED": "vale802126",
                    "CORREOELECTRONICO": "vale802126@siigo.com",
                    "STATION ID": "3690254",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Olga Lucia Ospina Vidal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1043021782",
                    "FECHA INICIAL": "2021-12-01",
                    "FECHA OPERACION": "2021-12-07",
                    "NOMBRES": "Ivan Fernando",
                    "APELLIDOS": "Gomez Mercado",
                    "NOMBRES COMPLETOS": "Ivan Fernando Gomez Mercado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luzdary Bernal Villarreal",
                    "CODIGO USUARIO": "gome802313",
                    "USUARIOFIVE9": "gome802313@siigo.com",
                    "USUARIO RED": "gome802313",
                    "CORREOELECTRONICO": "gome802313@siigo.com",
                    "STATION ID": "3127601",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luzdary Bernal Villarreal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1063967840",
                    "FECHA INICIAL": "2021-10-21",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Hugo Alfonso",
                    "APELLIDOS": "Barros Bolano",
                    "NOMBRES COMPLETOS": "Hugo Alfonso Barros Bolano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Santa Marta",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "barr802128",
                    "USUARIOFIVE9": "barr802128@siigo.com",
                    "USUARIO RED": "barr802128",
                    "CORREOELECTRONICO": "barr802128@siigo.com",
                    "STATION ID": "3690256",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143164484",
                    "FECHA INICIAL": "2021-10-21",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Julieth Paola",
                    "APELLIDOS": "Rincon Mercado",
                    "NOMBRES COMPLETOS": "Julieth Paola Rincon Mercado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "rinc802129",
                    "USUARIOFIVE9": "rinc802129@siigo.com",
                    "USUARIO RED": "rinc802129",
                    "CORREOELECTRONICO": "rinc802129@siigo.com",
                    "STATION ID": "3690257",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1102355440",
                    "FECHA INICIAL": "2021-10-21",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Mildred",
                    "APELLIDOS": "Perez Ramirez",
                    "NOMBRES COMPLETOS": "Mildred Perez Ramirez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "pere802130",
                    "USUARIOFIVE9": "pere802130@siigo.com",
                    "USUARIO RED": "pere802130",
                    "CORREOELECTRONICO": "pere802130@siigo.com",
                    "STATION ID": "3690258",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098754235",
                    "FECHA INICIAL": "2021-10-21",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Francy Katherine",
                    "APELLIDOS": "Arias Villabona",
                    "NOMBRES COMPLETOS": "Francy Katherine Arias Villabona",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "aria802134",
                    "USUARIOFIVE9": "aria802134@siigo.com",
                    "USUARIO RED": "aria802134",
                    "CORREOELECTRONICO": "aria802134@siigo.com",
                    "STATION ID": "3690262",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016102019",
                    "FECHA INICIAL": "2021-10-21",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Tiffany Michelle",
                    "APELLIDOS": "Ruiz Velasquez",
                    "NOMBRES COMPLETOS": "Tiffany Michelle Ruiz Velasquez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luisa Fernanda Rodriguez Larrota",
                    "CODIGO USUARIO": "ruiz802135",
                    "USUARIOFIVE9": "ruiz802135@siigo.com",
                    "USUARIO RED": "ruiz802135",
                    "CORREOELECTRONICO": "ruiz802135@siigo.com",
                    "STATION ID": "3690263",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luisa Fernanda Rodriguez Larrota",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1006120931",
                    "FECHA INICIAL": "2021-12-01",
                    "FECHA OPERACION": "2021-12-07",
                    "NOMBRES": "Jonathan Estiven",
                    "APELLIDOS": "Acosta Payan",
                    "NOMBRES COMPLETOS": "Jonathan Estiven Acosta Payan",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Juan Pablo Carreno Patino",
                    "CODIGO USUARIO": "acos802303",
                    "USUARIOFIVE9": "acos802303@siigo.com",
                    "USUARIO RED": "acos802303",
                    "CORREOELECTRONICO": "acos802303@siigo.com",
                    "STATION ID": "3483708",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Carreno Patino",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1006126681",
                    "FECHA INICIAL": "2021-12-01",
                    "FECHA OPERACION": "2021-12-07",
                    "NOMBRES": "Johan Sebastian",
                    "APELLIDOS": "Loaiza Lopez",
                    "NOMBRES COMPLETOS": "Johan Sebastian Loaiza Lopez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Liliana Ramirez Vanegas",
                    "CODIGO USUARIO": "loai802298",
                    "USUARIOFIVE9": "loai802298@siigo.com",
                    "USUARIO RED": "loai802298",
                    "CORREOELECTRONICO": "loai802298@siigo.com",
                    "STATION ID": "3690239",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Liliana Ramirez Vanegas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001999817",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Maria",
                    "APELLIDOS": "Fernanda Rua",
                    "NOMBRES COMPLETOS": "Maria Fernanda Rua Mendoza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Santo tomas",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luzdary Bernal Villarreal",
                    "CODIGO USUARIO": "ruam802541",
                    "USUARIOFIVE9": "ruam802541@siigo.com",
                    "USUARIO RED": "ruam802541",
                    "CORREOELECTRONICO": "ruam802541@siigo.com",
                    "STATION ID": "3720381",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luzdary Bernal Villarreal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1054993459",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Veronica",
                    "APELLIDOS": "Claros Marin",
                    "NOMBRES COMPLETOS": "Veronica Claros Marin",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Chinchina",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Juan Pablo Carreno Patino",
                    "CODIGO USUARIO": "clar802537",
                    "USUARIOFIVE9": "clar802537@siigo.com",
                    "USUARIO RED": "clar802537",
                    "CORREOELECTRONICO": "clar802537@siigo.com",
                    "STATION ID": "3720384",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Carreno Patino",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1049645888",
                    "FECHA INICIAL": "2021-10-22",
                    "FECHA OPERACION": "2021-10-25",
                    "NOMBRES": "Sebastian Alejandro",
                    "APELLIDOS": "Vargas Poveda",
                    "NOMBRES COMPLETOS": "Sebastian Alejandro Vargas Poveda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones ECU",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Angie Camila Diaz Escobar",
                    "CODIGO USUARIO": "varg802147",
                    "USUARIOFIVE9": "varg802147@siigo.com",
                    "USUARIO RED": "varg802147",
                    "CORREOELECTRONICO": "varg802147@siigo.com",
                    "STATION ID": "3690276",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Camila Diaz Escobar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001887471",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Andrea",
                    "APELLIDOS": "Liliana Morales",
                    "NOMBRES COMPLETOS": "Andrea Liliana Morales Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soledad",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luisa Fernanda Rodriguez Larrota",
                    "CODIGO USUARIO": "mora802534",
                    "USUARIOFIVE9": "mora802534@siigo.com",
                    "USUARIO RED": "mora802534",
                    "CORREOELECTRONICO": "mora802534@siigo.com",
                    "STATION ID": "3720387",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luisa Fernanda Rodriguez Larrota",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1144148835",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Leidy",
                    "APELLIDOS": "Tatiana Vela",
                    "NOMBRES COMPLETOS": "Leidy Tatiana Vela Molina",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Villamaria",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Juan Pablo Carreno Patino",
                    "CODIGO USUARIO": "vela802532",
                    "USUARIOFIVE9": "vela802532@siigo.com",
                    "USUARIO RED": "vela802532",
                    "CORREOELECTRONICO": "vela802532@siigo.com",
                    "STATION ID": "3720388",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Carreno Patino",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001919200",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Melanie",
                    "APELLIDOS": "Paola Gonzalez",
                    "NOMBRES COMPLETOS": "Melanie Paola Gonzalez Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soledad",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luisa Fernanda Rodriguez Larrota",
                    "CODIGO USUARIO": "gonz802525",
                    "USUARIOFIVE9": "gonz802525@siigo.com",
                    "USUARIO RED": "gonz802525",
                    "CORREOELECTRONICO": "gonz802525@siigo.com",
                    "STATION ID": "3720395",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luisa Fernanda Rodriguez Larrota",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143168645",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Alonso",
                    "APELLIDOS": "Andres Rodriguez",
                    "NOMBRES COMPLETOS": "Alonso Andres Rodriguez Acevedo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Juan Pablo Carreno Patino",
                    "CODIGO USUARIO": "rodr802524",
                    "USUARIOFIVE9": "rodr802524@siigo.com",
                    "USUARIO RED": "rodr802524",
                    "CORREOELECTRONICO": "rodr802524@siigo.com",
                    "STATION ID": "3720396",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Carreno Patino",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143464050",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Yuliana",
                    "APELLIDOS": "Paola Lizarazo",
                    "NOMBRES COMPLETOS": "Yuliana Paola Lizarazo Munoz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luzdary Bernal Villarreal",
                    "CODIGO USUARIO": "liza802523",
                    "USUARIOFIVE9": "liza802523@siigo.com",
                    "USUARIO RED": "liza802523",
                    "CORREOELECTRONICO": "liza802523@siigo.com",
                    "STATION ID": "3720397",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luzdary Bernal Villarreal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014246788",
                    "FECHA INICIAL": "2021-01-20",
                    "FECHA OPERACION": "2021-01-21",
                    "NOMBRES": "Cristian Fabian",
                    "APELLIDOS": "Casas Rodriguez",
                    "NOMBRES COMPLETOS": "Cristian Fabian Casas Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Yeimy Milena Bustos Gutierrez",
                    "CODIGO USUARIO": "casa801045",
                    "USUARIOFIVE9": "casa801045@siigo.com",
                    "USUARIO RED": "casa801045",
                    "CORREOELECTRONICO": "casa801045@siigo.com",
                    "STATION ID": "2606348",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yeimy Milena Bustos Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1090503390",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Edwin",
                    "APELLIDOS": "Alexis Camargo",
                    "NOMBRES COMPLETOS": "Edwin Alexis Camargo Contreras",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cucuta",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luisa Fernanda Rodriguez Larrota",
                    "CODIGO USUARIO": "cama802520",
                    "USUARIOFIVE9": "cama802520@siigo.com",
                    "USUARIO RED": "cama802520",
                    "CORREOELECTRONICO": "cama802520@siigo.com",
                    "STATION ID": "3720400",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luisa Fernanda Rodriguez Larrota",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1002212542",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Valentina",
                    "APELLIDOS": "Arrieta Jimenez",
                    "NOMBRES COMPLETOS": "Valentina Arrieta Jimenez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Juan Pablo Carreno Patino",
                    "CODIGO USUARIO": "arri802519",
                    "USUARIOFIVE9": "arri802519@siigo.com",
                    "USUARIO RED": "arri802519",
                    "CORREOELECTRONICO": "arri802519@siigo.com",
                    "STATION ID": "3720401",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Juan Pablo Carreno Patino",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018467331",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Orleidys",
                    "APELLIDOS": "Paola Ramos",
                    "NOMBRES COMPLETOS": "Orleidys Paola Ramos Salgado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Tatiana Reyes Perez",
                    "CODIGO USUARIO": "ramo802518",
                    "USUARIOFIVE9": "ramo802518@siigo.com",
                    "USUARIO RED": "ramo802518",
                    "CORREOELECTRONICO": "ramo802518@siigo.com",
                    "STATION ID": "3720402",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Tatiana Reyes Perez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "71717302",
                    "FECHA INICIAL": "2010-08-02",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Juan Carlos",
                    "APELLIDOS": "Rodriguez Pineda",
                    "NOMBRES COMPLETOS": "Juan Carlos Rodriguez Pineda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luzdary Bernal Villarreal",
                    "CODIGO USUARIO": "rodr111922",
                    "USUARIOFIVE9": "rodr111922@siigo.com",
                    "USUARIO RED": "rodr111922",
                    "CORREOELECTRONICO": "rodr111922@siigo.com",
                    "STATION ID": "2606636",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luzdary Bernal Villarreal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1039623911",
                    "FECHA INICIAL": "2019-01-16",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Natalia Fernanda",
                    "APELLIDOS": "Pulgarin Garcia",
                    "NOMBRES COMPLETOS": "Natalia Fernanda Pulgarin Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luzdary Bernal Villarreal",
                    "CODIGO USUARIO": "pulg111989",
                    "USUARIOFIVE9": "pulg111989@siigo.com",
                    "USUARIO RED": "pulg111989",
                    "CORREOELECTRONICO": "pulg111989@siigo.com",
                    "STATION ID": "2606641",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luzdary Bernal Villarreal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53131375",
                    "FECHA INICIAL": "2020-02-24",
                    "FECHA OPERACION": "2020-02-24",
                    "NOMBRES": "Lidia Enith",
                    "APELLIDOS": "Vanegas Granados",
                    "NOMBRES COMPLETOS": "Lidia Enith Vanegas Granados",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Sory Yaqueline Abello Polo",
                    "CODIGO USUARIO": "vane800303",
                    "USUARIOFIVE9": "vane800303@siigo.com",
                    "USUARIO RED": "vane800303",
                    "CORREOELECTRONICO": "vane800303@siigo.com",
                    "STATION ID": "2606916",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sory Yaqueline Abello Polo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "3107778491",
                    "FECHA INICIAL": "2021-11-05",
                    "FECHA OPERACION": "2021-11-08",
                    "NOMBRES": "Ana Maria",
                    "APELLIDOS": "Ramirez Sanchez",
                    "NOMBRES COMPLETOS": "Ana Maria Ramirez Sanchez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cultura",
                    "SUBAREA": "Cultura",
                    "CARGO": "Cultura",
                    "JEFE INMEDIATO": "Carlos Arturo Huertas Salgado",
                    "CODIGO USUARIO": "rami802199",
                    "USUARIOFIVE9": "rami802199@siigo.com",
                    "USUARIO RED": "rami802199",
                    "CORREOELECTRONICO": "rami802199@siigo.com",
                    "STATION ID": "3690299",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cultura_Carlos Arturo Huertas Salgado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098783236",
                    "FECHA INICIAL": "2021-11-05",
                    "FECHA OPERACION": "2021-11-08",
                    "NOMBRES": "Maira Alejandra",
                    "APELLIDOS": "Pinto Pinzon",
                    "NOMBRES COMPLETOS": "Maira Alejandra Pinto Pinzon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Angel Alfonso Arciniegas Guerrero",
                    "CODIGO USUARIO": "pint802200",
                    "USUARIOFIVE9": "pint802200@siigo.com",
                    "USUARIO RED": "pint802200",
                    "CORREOELECTRONICO": "pint802200@siigo.com",
                    "STATION ID": "3690300",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Angel Alfonso Arciniegas Guerrero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1048326072",
                    "FECHA INICIAL": "2021-11-08",
                    "FECHA OPERACION": "2021-11-10",
                    "NOMBRES": "Luis Fernando",
                    "APELLIDOS": "Molina Orozco",
                    "NOMBRES COMPLETOS": "Luis Fernando Molina Orozco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Sory Yaqueline Abello Polo",
                    "CODIGO USUARIO": "moli802210",
                    "USUARIOFIVE9": "moli802210@siigo.com",
                    "USUARIO RED": "moli802210",
                    "CORREOELECTRONICO": "moli802210@siigo.com",
                    "STATION ID": "2606192",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sory Yaqueline Abello Polo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143170304",
                    "FECHA INICIAL": "2022-01-06",
                    "FECHA OPERACION": "2022-01-11",
                    "NOMBRES": "Moises",
                    "APELLIDOS": "Alvarez Niebles",
                    "NOMBRES COMPLETOS": "Moises Alvarez Niebles",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoraciones",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Sandra Catalina Suarez Castillo",
                    "CODIGO USUARIO": "alva802555",
                    "USUARIOFIVE9": "alva802555@siigo.com",
                    "USUARIO RED": "alva802555",
                    "CORREOELECTRONICO": "alva802555@siigo.com",
                    "STATION ID": "2606063",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Sandra Catalina Suarez Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019066748",
                    "FECHA INICIAL": "2021-11-05",
                    "FECHA OPERACION": "2021-11-10",
                    "NOMBRES": "Christian Felipe",
                    "APELLIDOS": "Alarcon Nunez",
                    "NOMBRES COMPLETOS": "Christian Felipe Alarcon Nunez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Walter Antonio Munoz Antepara",
                    "CODIGO USUARIO": "alar802207",
                    "USUARIOFIVE9": "alar802207@siigo.com",
                    "USUARIO RED": "alar802207",
                    "CORREOELECTRONICO": "alar802207@siigo.com",
                    "STATION ID": "2606194",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Walter Antonio Munoz Antepara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022408281",
                    "FECHA INICIAL": "2021-05-04",
                    "FECHA OPERACION": "2021-05-04",
                    "NOMBRES": "Alejandra",
                    "APELLIDOS": "Guzman Sanchez",
                    "NOMBRES COMPLETOS": "Alejandra Guzman Sanchez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Lida Katerine Puentes Navarro",
                    "CODIGO USUARIO": "guzm801382",
                    "USUARIOFIVE9": "guzm801382@siigo.com",
                    "USUARIO RED": "guzm801382",
                    "CORREOELECTRONICO": "guzm801382@siigo.com",
                    "STATION ID": "2607045",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Lida Katerine Puentes Navarro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018491887",
                    "FECHA INICIAL": "2021-09-29",
                    "FECHA OPERACION": "2021-10-04",
                    "NOMBRES": "Maria Paula",
                    "APELLIDOS": "Alturo Ramirez",
                    "NOMBRES COMPLETOS": "Maria Paula Alturo Ramirez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Johanna Patricia Torres Quintero",
                    "CODIGO USUARIO": "altu801964",
                    "USUARIOFIVE9": "altu801964@siigo.com",
                    "USUARIO RED": "altu801964",
                    "CORREOELECTRONICO": "altu801964@siigo.com",
                    "STATION ID": "3267867",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Johanna Patricia Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023009051",
                    "FECHA INICIAL": "2021-11-09",
                    "FECHA OPERACION": "2021-11-10",
                    "NOMBRES": "Janny Milena",
                    "APELLIDOS": "Leon Hernandez",
                    "NOMBRES COMPLETOS": "Janny Milena Leon Hernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Cristhian David Nino Barrera",
                    "CODIGO USUARIO": "leon802215",
                    "USUARIOFIVE9": "leon802215@siigo.com",
                    "USUARIO RED": "leon802215",
                    "CORREOELECTRONICO": "leon802215@siigo.com",
                    "STATION ID": "3690309",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Cristhian David Nino Barrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "55227411",
                    "FECHA INICIAL": "2021-11-10",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Itais Osiris",
                    "APELLIDOS": "Arias Acosta",
                    "NOMBRES COMPLETOS": "Itais Osiris Arias Acosta",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexis Abraham Sanchez Portillo",
                    "CODIGO USUARIO": "aria802223",
                    "USUARIOFIVE9": "aria802223@siigo.com",
                    "USUARIO RED": "aria802223",
                    "CORREOELECTRONICO": "aria802223@siigo.com",
                    "STATION ID": "3690313",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexis Abraham Sanchez Portillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015405941",
                    "FECHA INICIAL": "2021-11-10",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Deisy Marley",
                    "APELLIDOS": "Castiblanco",
                    "NOMBRES COMPLETOS": "Deisy Marley Munar Castiblanco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Cristhian David Nino Barrera",
                    "CODIGO USUARIO": "muna802225",
                    "USUARIOFIVE9": "muna802225@siigo.com",
                    "USUARIO RED": "muna802225",
                    "CORREOELECTRONICO": "muna802225@siigo.com",
                    "STATION ID": "3690315",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Cristhian David Nino Barrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015427572",
                    "FECHA INICIAL": "2021-11-10",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Yordhi Sebastian",
                    "APELLIDOS": "Vargas Parra",
                    "NOMBRES COMPLETOS": "Yordhi Sebastian Vargas Parra",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Ximena Manrique Isaziga",
                    "CODIGO USUARIO": "varg802226",
                    "USUARIOFIVE9": "varg802226@siigo.com",
                    "USUARIO RED": "varg802226",
                    "CORREOELECTRONICO": "varg802226@siigo.com",
                    "STATION ID": "3690316",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Ximena Manrique Isaziga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1046713386",
                    "FECHA INICIAL": "2021-11-10",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Luis Armando",
                    "APELLIDOS": "Cardenas Gonzalez",
                    "NOMBRES COMPLETOS": "Luis Armando Cardenas Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexis Abraham Sanchez Portillo",
                    "CODIGO USUARIO": "card80227",
                    "USUARIOFIVE9": "card80227@siigo.com",
                    "USUARIO RED": "card80227",
                    "CORREOELECTRONICO": "card80227@siigo.com",
                    "STATION ID": "3690317",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexis Abraham Sanchez Portillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1048268311",
                    "FECHA INICIAL": "2021-11-10",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Silvia Margarita",
                    "APELLIDOS": "Arias Larrea",
                    "NOMBRES COMPLETOS": "Silvia Margarita Arias Larrea",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Patricia Villarraga Forero",
                    "CODIGO USUARIO": "aria802228",
                    "USUARIOFIVE9": "aria802228@siigo.com",
                    "USUARIO RED": "aria802228",
                    "CORREOELECTRONICO": "aria802228@siigo.com",
                    "STATION ID": "3690318",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Patricia Villarraga Forero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1193314012",
                    "FECHA INICIAL": "2021-11-10",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Gregori Isaac",
                    "APELLIDOS": "Villanueva Villalobos",
                    "NOMBRES COMPLETOS": "Gregori Isaac Villanueva Villalobos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Patricia Villarraga Forero",
                    "CODIGO USUARIO": "vill802229",
                    "USUARIOFIVE9": "vill802229@siigo.com",
                    "USUARIO RED": "vill802229",
                    "CORREOELECTRONICO": "vill802229@siigo.com",
                    "STATION ID": "3690319",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Patricia Villarraga Forero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000518968",
                    "FECHA INICIAL": "2021-11-10",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Santiago",
                    "APELLIDOS": "Farfan Ramirez",
                    "NOMBRES COMPLETOS": "Santiago Farfan Ramirez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "farf802230",
                    "USUARIOFIVE9": "farf802230@siigo.com",
                    "USUARIO RED": "farf802230",
                    "CORREOELECTRONICO": "farf802230@siigo.com",
                    "STATION ID": "3690320",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1129581018",
                    "FECHA INICIAL": "2021-11-10",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Gustavo Adolfo",
                    "APELLIDOS": "Quiroz Noriega",
                    "NOMBRES COMPLETOS": "Gustavo Adolfo Quiroz Noriega",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Patricia Villarraga Forero",
                    "CODIGO USUARIO": "quir802231",
                    "USUARIOFIVE9": "quir802231@siigo.com",
                    "USUARIO RED": "quir802231",
                    "CORREOELECTRONICO": "quir802231@siigo.com",
                    "STATION ID": "3690321",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Patricia Villarraga Forero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1005785062",
                    "FECHA INICIAL": "2021-11-10",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Kevin Samuel",
                    "APELLIDOS": "Tello Castillo",
                    "NOMBRES COMPLETOS": "Kevin Samuel Tello Castillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Armando Mariano Cirilo",
                    "CODIGO USUARIO": "tell802232",
                    "USUARIOFIVE9": "tell802232@siigo.com",
                    "USUARIO RED": "tell802232",
                    "CORREOELECTRONICO": "tell802232@siigo.com",
                    "STATION ID": "3690322",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Armando Mariano Cirilo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1045739596",
                    "FECHA INICIAL": "2021-11-10",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Elkin Jose",
                    "APELLIDOS": "Garcia Alvarado",
                    "NOMBRES COMPLETOS": "Elkin Jose Garcia Alvarado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "garc802233",
                    "USUARIOFIVE9": "garc802233@siigo.com",
                    "USUARIO RED": "garc802233",
                    "CORREOELECTRONICO": "garc802233@siigo.com",
                    "STATION ID": "3690323",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016101052",
                    "FECHA INICIAL": "2021-11-10",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Michell",
                    "APELLIDOS": "Pintor Gutierrez",
                    "NOMBRES COMPLETOS": "Michell Pintor Gutierrez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angy Paola Umbacia Diaz",
                    "CODIGO USUARIO": "pint802234",
                    "USUARIOFIVE9": "pint802234@siigo.com",
                    "USUARIO RED": "pint802234",
                    "CORREOELECTRONICO": "pint802234@siigo.com",
                    "STATION ID": "3690324",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angy Paola Umbacia Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1045717180",
                    "FECHA INICIAL": "2021-11-10",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Luis David",
                    "APELLIDOS": "Solano Deavila",
                    "NOMBRES COMPLETOS": "Luis David Solano Deavila",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "sola802235",
                    "USUARIOFIVE9": "sola802235@siigo.com",
                    "USUARIO RED": "sola802235",
                    "CORREOELECTRONICO": "sola802235@siigo.com",
                    "STATION ID": "3690325",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1140875492",
                    "FECHA INICIAL": "2021-11-10",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Gianina Del Carmen",
                    "APELLIDOS": "Dominguez Echenique",
                    "NOMBRES COMPLETOS": "Gianina Del Carmen Dominguez Echenique",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Patricia Villarraga Forero",
                    "CODIGO USUARIO": "domi802236",
                    "USUARIOFIVE9": "domi802236@siigo.com",
                    "USUARIO RED": "domi802236",
                    "CORREOELECTRONICO": "domi802236@siigo.com",
                    "STATION ID": "3690326",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Patricia Villarraga Forero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1233490470",
                    "FECHA INICIAL": "2021-11-10",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Katherine Julieth",
                    "APELLIDOS": "Ramirez Barreto",
                    "NOMBRES COMPLETOS": "Katherine Julieth Ramirez Barreto",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Patricia Villarraga Forero",
                    "CODIGO USUARIO": "rami802237",
                    "USUARIOFIVE9": "rami802237@siigo.com",
                    "USUARIO RED": "rami802237",
                    "CORREOELECTRONICO": "rami802237@siigo.com",
                    "STATION ID": "3690327",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Patricia Villarraga Forero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "44003668",
                    "FECHA INICIAL": "2021-11-12",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Maribel",
                    "APELLIDOS": "Gallego Estrada",
                    "NOMBRES COMPLETOS": "Maribel Gallego Estrada",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Paula Alejandra Garcia Lujan",
                    "CODIGO USUARIO": "gall802265",
                    "USUARIOFIVE9": "gall802265@siigo.com",
                    "USUARIO RED": "gall802265",
                    "CORREOELECTRONICO": "gall802265@siigo.com",
                    "STATION ID": "3690333",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Paula Alejandra Garcia Lujan",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1106789499",
                    "FECHA INICIAL": "2021-11-11",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Silvia Dahyana",
                    "APELLIDOS": "Arango Cardona",
                    "NOMBRES COMPLETOS": "Silvia Dahyana Arango Cardona",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luz Katerine Maya Mogollon",
                    "CODIGO USUARIO": "aran802261",
                    "USUARIOFIVE9": "aran802261@siigo.com",
                    "USUARIO RED": "aran802261",
                    "CORREOELECTRONICO": "aran802261@siigo.com",
                    "STATION ID": "3690337",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Katerine Maya Mogollon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1049646905",
                    "FECHA INICIAL": "2021-11-11",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Eliana Marcela",
                    "APELLIDOS": "Lopez Sierra",
                    "NOMBRES COMPLETOS": "Eliana Marcela Lopez Sierra",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Nicoll Torres Celemin",
                    "CODIGO USUARIO": "lope802260",
                    "USUARIOFIVE9": "lope802260@siigo.com",
                    "USUARIO RED": "lope802260",
                    "CORREOELECTRONICO": "lope802260@siigo.com",
                    "STATION ID": "3690338",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Nicoll Torres Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1045671192",
                    "FECHA INICIAL": "2021-11-11",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Yulianys Paola",
                    "APELLIDOS": "Rosado Ospino",
                    "NOMBRES COMPLETOS": "Yulianys Paola Rosado Ospino",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Cristian Camilo Hernandez Gonzalez",
                    "CODIGO USUARIO": "rosa802246",
                    "USUARIOFIVE9": "rosa802246@siigo.com",
                    "USUARIO RED": "rosa802246",
                    "CORREOELECTRONICO": "rosa802246@siigo.com",
                    "STATION ID": "3690342",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Cristian Camilo Hernandez Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007157285",
                    "FECHA INICIAL": "2021-11-11",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Sara Lucia",
                    "APELLIDOS": "Acosta Valle",
                    "NOMBRES COMPLETOS": "Sara Lucia Acosta Valle",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "acos802244",
                    "USUARIOFIVE9": "acos802244@siigo.com",
                    "USUARIO RED": "acos802244",
                    "CORREOELECTRONICO": "acos802244@siigo.com",
                    "STATION ID": "3690344",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1234094939",
                    "FECHA INICIAL": "2021-11-11",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Melissa Paola",
                    "APELLIDOS": "Castro Mercado",
                    "NOMBRES COMPLETOS": "Melissa Paola Castro Mercado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Patricia Villarraga Forero",
                    "CODIGO USUARIO": "cast802243",
                    "USUARIOFIVE9": "cast802243@siigo.com",
                    "USUARIO RED": "cast802243",
                    "CORREOELECTRONICO": "cast802243@siigo.com",
                    "STATION ID": "3690345",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Patricia Villarraga Forero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1090497576",
                    "FECHA INICIAL": "2021-11-11",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Alix Dayanna",
                    "APELLIDOS": "Jimenez Lemus",
                    "NOMBRES COMPLETOS": "Alix Dayanna Jimenez Lemus",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Call ADM",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Hancel Rafael Salcedo Mendoza",
                    "CODIGO USUARIO": "jime802256",
                    "USUARIOFIVE9": "jime802256@siigo.com",
                    "USUARIO RED": "jime802256",
                    "CORREOELECTRONICO": "jime802256@siigo.com",
                    "STATION ID": "3690346",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Hancel Rafael Salcedo Mendoza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1075291230",
                    "FECHA INICIAL": "2021-11-11",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Karen Vanessa",
                    "APELLIDOS": "Zapata Candela",
                    "NOMBRES COMPLETOS": "Karen Vanessa Zapata Candela",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Neiva",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "zapa802255",
                    "USUARIOFIVE9": "zapa802255@siigo.com",
                    "USUARIO RED": "zapa802255",
                    "CORREOELECTRONICO": "zapa802255@siigo.com",
                    "STATION ID": "3690347",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52768366",
                    "FECHA INICIAL": "2021-11-11",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Adriana",
                    "APELLIDOS": "Silva Curaca",
                    "NOMBRES COMPLETOS": "Adriana Silva Curaca",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Neiva",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexis Abraham Sanchez Portillo",
                    "CODIGO USUARIO": "silv802253",
                    "USUARIOFIVE9": "silv802253@siigo.com",
                    "USUARIO RED": "silv802253",
                    "CORREOELECTRONICO": "silv802253@siigo.com",
                    "STATION ID": "3690349",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexis Abraham Sanchez Portillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1078777607",
                    "FECHA INICIAL": "2021-11-11",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Deisy",
                    "APELLIDOS": "Guzman Penagos",
                    "NOMBRES COMPLETOS": "Deisy Guzman Penagos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Neiva",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "guzm802252",
                    "USUARIOFIVE9": "guzm802252@siigo.com",
                    "USUARIO RED": "guzm802252",
                    "CORREOELECTRONICO": "guzm802252@siigo.com",
                    "STATION ID": "3690350",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1140866663",
                    "FECHA INICIAL": "2021-11-11",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Eilen Tatiana",
                    "APELLIDOS": "Carrillo Rada",
                    "NOMBRES COMPLETOS": "Eilen Tatiana Carrillo Rada",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Eliana Maria Fajardo Prada",
                    "CODIGO USUARIO": "carr802251",
                    "USUARIOFIVE9": "carr802251@siigo.com",
                    "USUARIO RED": "carr802251",
                    "CORREOELECTRONICO": "carr802251@siigo.com",
                    "STATION ID": "3690351",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Eliana Maria Fajardo Prada",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1002162402",
                    "FECHA INICIAL": "2021-11-11",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Karen Yisell",
                    "APELLIDOS": "Matos Gamero",
                    "NOMBRES COMPLETOS": "Karen Yisell Matos Gamero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexis Abraham Sanchez Portillo",
                    "CODIGO USUARIO": "mato802249",
                    "USUARIOFIVE9": "mato802249@siigo.com",
                    "USUARIO RED": "mato802249",
                    "CORREOELECTRONICO": "mato802249@siigo.com",
                    "STATION ID": "3690353",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexis Abraham Sanchez Portillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1128465905",
                    "FECHA INICIAL": "2021-11-04",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Yessica Yulieth",
                    "APELLIDOS": "Quintero Martinez",
                    "NOMBRES COMPLETOS": "Yessica Yulieth Quintero Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Director Customer",
                    "JEFE INMEDIATO": "Libia Amparo Osorio Rodriguez",
                    "CODIGO USUARIO": "quin802191",
                    "USUARIOFIVE9": "quin802191@siigo.com",
                    "USUARIO RED": "quin802191",
                    "CORREOELECTRONICO": "quin802191@siigo.com",
                    "STATION ID": "3690354",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Libia Amparo Osorio Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1036653287",
                    "FECHA INICIAL": "2019-02-15",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Jhon Dayron",
                    "APELLIDOS": "Rubio Villegas",
                    "NOMBRES COMPLETOS": "Jhon Dayron Rubio Villegas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luzdary Bernal Villarreal",
                    "CODIGO USUARIO": "rubi111921",
                    "USUARIOFIVE9": "rubi111921@siigo.com",
                    "USUARIO RED": "rubi111921",
                    "CORREOELECTRONICO": "rubi111921@siigo.com",
                    "STATION ID": "2606635",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luzdary Bernal Villarreal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023024813",
                    "FECHA INICIAL": "2021-11-18",
                    "FECHA OPERACION": "2021-11-22",
                    "NOMBRES": "Lizeth Geraldine",
                    "APELLIDOS": "Morales Chacon",
                    "NOMBRES COMPLETOS": "Lizeth Geraldine Morales Chacon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ventas",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Edith Angelica Gil Umbarila",
                    "CODIGO USUARIO": "mora802269",
                    "USUARIOFIVE9": "mora802269@siigo.com",
                    "USUARIO RED": "mora802269",
                    "CORREOELECTRONICO": "mora802269@siigo.com",
                    "STATION ID": "3690356",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Edith Angelica Gil Umbarila",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007117819",
                    "FECHA INICIAL": "2021-11-19",
                    "FECHA OPERACION": "2021-11-22",
                    "NOMBRES": "Tiffani Esther",
                    "APELLIDOS": "Borja Gary",
                    "NOMBRES COMPLETOS": "Tiffani Esther Borja Gary",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ventas",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "borj802278",
                    "USUARIOFIVE9": "borj802278@siigo.com",
                    "USUARIO RED": "borj802278",
                    "CORREOELECTRONICO": "borj802278@siigo.com",
                    "STATION ID": "3690359",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "35421010",
                    "FECHA INICIAL": "2021-11-19",
                    "FECHA OPERACION": "2021-11-22",
                    "NOMBRES": "Yenny Rocio",
                    "APELLIDOS": "Pineda Gonzalez",
                    "NOMBRES COMPLETOS": "Yenny Rocio Pineda Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "pine802277",
                    "USUARIOFIVE9": "pine802277@siigo.com",
                    "USUARIO RED": "pine802277",
                    "CORREOELECTRONICO": "pine802277@siigo.com",
                    "STATION ID": "3690360",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "43071624",
                    "FECHA INICIAL": "2019-04-05",
                    "FECHA OPERACION": "2021-11-23",
                    "NOMBRES": "Maria Elena",
                    "APELLIDOS": "Rodriguez Moreno",
                    "NOMBRES COMPLETOS": "Maria Elena Rodriguez Moreno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Rocxy Daniela Fernandez Fernandez",
                    "CODIGO USUARIO": "rodr112009",
                    "USUARIOFIVE9": "rodr112009@siigo.com",
                    "USUARIO RED": "rodr112009",
                    "CORREOELECTRONICO": "rodr112009@siigo.com",
                    "STATION ID": "2607074",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Rocxy Daniela Fernandez Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057547999",
                    "FECHA INICIAL": "2021-08-03",
                    "FECHA OPERACION": "2021-08-03",
                    "NOMBRES": "Karen Julieth",
                    "APELLIDOS": "Sotelo Gomez",
                    "NOMBRES COMPLETOS": "Karen Julieth Sotelo Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luzdary Bernal Villarreal",
                    "CODIGO USUARIO": "sote801630",
                    "USUARIOFIVE9": "sote801630@siigo.com",
                    "USUARIO RED": "sote801630",
                    "CORREOELECTRONICO": "sote801630@siigo.com",
                    "STATION ID": "2606851",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luzdary Bernal Villarreal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014216479",
                    "FECHA INICIAL": "2021-12-01",
                    "FECHA OPERACION": "2021-12-06",
                    "NOMBRES": "Ana Maria",
                    "APELLIDOS": "Aponte Navarrete",
                    "NOMBRES COMPLETOS": "Ana Maria Aponte Navarrete",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "apon802333",
                    "USUARIOFIVE9": "apon802333@siigo.com",
                    "USUARIO RED": "apon802333",
                    "CORREOELECTRONICO": "apon802333@siigo.com",
                    "STATION ID": "2606129",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "66933415",
                    "FECHA INICIAL": "2007-08-16",
                    "FECHA OPERACION": "2007-08-16",
                    "NOMBRES": "Ana Bolena",
                    "APELLIDOS": "Perez Martinez",
                    "NOMBRES COMPLETOS": "Ana Bolena Perez Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luzdary Bernal Villarreal",
                    "CODIGO USUARIO": "pere223915",
                    "USUARIOFIVE9": "pere223915@siigo.com",
                    "USUARIO RED": "pere223915",
                    "CORREOELECTRONICO": "pere223915@siigo.com",
                    "STATION ID": "2606645",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luzdary Bernal Villarreal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "84069823",
                    "FECHA INICIAL": "2009-06-03",
                    "FECHA OPERACION": "2009-06-03",
                    "NOMBRES": "Oswaldo Moises",
                    "APELLIDOS": "Soto Ramirez",
                    "NOMBRES COMPLETOS": "Oswaldo Moises Soto Ramirez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luzdary Bernal Villarreal",
                    "CODIGO USUARIO": "soto27197",
                    "USUARIOFIVE9": "soto27197@siigo.com",
                    "USUARIO RED": "soto27197",
                    "CORREOELECTRONICO": "soto27197@siigo.com",
                    "STATION ID": "2606668",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luzdary Bernal Villarreal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "952980456",
                    "FECHA INICIAL": "2021-12-01",
                    "FECHA OPERACION": "2021-12-06",
                    "NOMBRES": "Andy Fabricio",
                    "APELLIDOS": "Sanchez Paiva",
                    "NOMBRES COMPLETOS": "Andy Fabricio Sanchez Paiva",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "sanc802330",
                    "USUARIOFIVE9": "sanc802330@siigo.com",
                    "USUARIO RED": "sanc802330",
                    "CORREOELECTRONICO": "sanc802330@siigo.com",
                    "STATION ID": "2606197",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1121872403",
                    "FECHA INICIAL": "2021-12-01",
                    "FECHA OPERACION": "2021-12-06",
                    "NOMBRES": "Angelica Nathalia",
                    "APELLIDOS": "Arjona Arroyabe",
                    "NOMBRES COMPLETOS": "Angelica Nathalia Arjona Arroyabe",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Villavicencio",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "arjo802338",
                    "USUARIOFIVE9": "arjo802338@siigo.com",
                    "USUARIO RED": "arjo802338",
                    "CORREOELECTRONICO": "arjo802338@siigo.com",
                    "STATION ID": "2606212",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "963709381",
                    "FECHA INICIAL": "2021-12-01",
                    "FECHA OPERACION": "2021-12-07",
                    "NOMBRES": "Jonathan Jose",
                    "APELLIDOS": "Gonzalez Varela",
                    "NOMBRES COMPLETOS": "Jonathan Jose Gonzalez Varela",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "gonz802331",
                    "USUARIOFIVE9": "gonz802331@siigo.com",
                    "USUARIO RED": "gonz802331",
                    "CORREOELECTRONICO": "gonz802331@siigo.com",
                    "STATION ID": "2606314",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "44071205",
                    "FECHA INICIAL": "2021-12-01",
                    "FECHA OPERACION": "2021-12-07",
                    "NOMBRES": "Camila",
                    "APELLIDOS": "Santana Cruz",
                    "NOMBRES COMPLETOS": "Camila Santana Cruz",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Elias Jusid Machado",
                    "CODIGO USUARIO": "sant802321",
                    "USUARIOFIVE9": "sant802321@siigo.com",
                    "USUARIO RED": "sant802321",
                    "CORREOELECTRONICO": "sant802321@siigo.com",
                    "STATION ID": "2606329",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Elias Jusid Machado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000179559",
                    "FECHA INICIAL": "2021-12-02",
                    "FECHA OPERACION": "2021-12-07",
                    "NOMBRES": "Yerly Paola",
                    "APELLIDOS": "Santamaria Riveros",
                    "NOMBRES COMPLETOS": "Yerly Paola Santamaria Riveros",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Jazbleidy Andrea Nunez Montoya",
                    "CODIGO USUARIO": "sant802351",
                    "USUARIOFIVE9": "sant802351@siigo.com",
                    "USUARIO RED": "sant802351",
                    "CORREOELECTRONICO": "sant802351@siigo.com",
                    "STATION ID": "2606338",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jazbleidy Andrea Nunez Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1073689693",
                    "FECHA INICIAL": "2021-12-02",
                    "FECHA OPERACION": "2021-12-07",
                    "NOMBRES": "Yuri Samantha",
                    "APELLIDOS": "Navarro Olaya",
                    "NOMBRES COMPLETOS": "Yuri Samantha Navarro Olaya",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "nava802347",
                    "USUARIOFIVE9": "nava802347@siigo.com",
                    "USUARIO RED": "nava802347",
                    "CORREOELECTRONICO": "nava802347@siigo.com",
                    "STATION ID": "2606528",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "46452244",
                    "FECHA INICIAL": "2014-07-07",
                    "FECHA OPERACION": "2014-07-07",
                    "NOMBRES": "Nancy Mayerly",
                    "APELLIDOS": "Tamara Ochoa",
                    "NOMBRES COMPLETOS": "Nancy Mayerly Tamara Ochoa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luzdary Bernal Villarreal",
                    "CODIGO USUARIO": "tama38542",
                    "USUARIOFIVE9": "tama38542@siigo.com",
                    "USUARIO RED": "tama38542",
                    "CORREOELECTRONICO": "tama38542@siigo.com",
                    "STATION ID": "2606684",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luzdary Bernal Villarreal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022369861",
                    "FECHA INICIAL": "2021-12-02",
                    "FECHA OPERACION": "2021-12-07",
                    "NOMBRES": "Viviana Libet",
                    "APELLIDOS": "Cardenas Benavides",
                    "NOMBRES COMPLETOS": "Viviana Libet Cardenas Benavides",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jose Manuel Aguayo Escandon",
                    "CODIGO USUARIO": "card802342",
                    "USUARIOFIVE9": "card802342@siigo.com",
                    "USUARIO RED": "card802342",
                    "CORREOELECTRONICO": "card802342@siigo.com",
                    "STATION ID": "2606768",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jose Manuel Aguayo Escandon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000695532",
                    "FECHA INICIAL": "2021-12-01",
                    "FECHA OPERACION": "2021-12-07",
                    "NOMBRES": "Yurin Tatiana",
                    "APELLIDOS": "Cruz Castillo",
                    "NOMBRES COMPLETOS": "Yurin Tatiana Cruz Castillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Villavicencio",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "cruz802339",
                    "USUARIOFIVE9": "cruz802339@siigo.com",
                    "USUARIO RED": "cruz802339",
                    "CORREOELECTRONICO": "cruz802339@siigo.com",
                    "STATION ID": "2606787",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001903649",
                    "FECHA INICIAL": "2021-12-01",
                    "FECHA OPERACION": "2021-12-07",
                    "NOMBRES": "Sthefany",
                    "APELLIDOS": "Baute Guerrero",
                    "NOMBRES COMPLETOS": "Sthefany Baute Guerrero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "baut802337",
                    "USUARIOFIVE9": "baut802337@siigo.com",
                    "USUARIO RED": "baut802337",
                    "CORREOELECTRONICO": "baut802337@siigo.com",
                    "STATION ID": "2606798",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1121874344",
                    "FECHA INICIAL": "2021-12-01",
                    "FECHA OPERACION": "2021-12-07",
                    "NOMBRES": "Jasbleidy",
                    "APELLIDOS": "Morales Borja",
                    "NOMBRES COMPLETOS": "Jasbleidy Morales Borja",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Villavicencio",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "mora802328",
                    "USUARIOFIVE9": "mora802328@siigo.com",
                    "USUARIO RED": "mora802328",
                    "CORREOELECTRONICO": "mora802328@siigo.com",
                    "STATION ID": "2606958",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143414775",
                    "FECHA INICIAL": "2021-12-01",
                    "FECHA OPERACION": "2021-12-07",
                    "NOMBRES": "Maria Sthefany",
                    "APELLIDOS": "Flores Quinto",
                    "NOMBRES COMPLETOS": "Maria Sthefany Flores Quinto",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Mercadeo",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Maria Paula Garzon Ortega",
                    "CODIGO USUARIO": "flor802326",
                    "USUARIOFIVE9": "flor802326@siigo.com",
                    "USUARIO RED": "flor802326",
                    "CORREOELECTRONICO": "flor802326@siigo.com",
                    "STATION ID": "2606975",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Maria Paula Garzon Ortega",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "4920282",
                    "FECHA INICIAL": "2008-02-18",
                    "FECHA OPERACION": "2008-02-18",
                    "NOMBRES": "Diomedis Son",
                    "APELLIDOS": "Perdomo",
                    "NOMBRES COMPLETOS": "Diomedis Son Perdomo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Neiva",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luzdary Bernal Villarreal",
                    "CODIGO USUARIO": "sonp233201",
                    "USUARIOFIVE9": "sonp233201@siigo.com",
                    "USUARIO RED": "sonp233201",
                    "CORREOELECTRONICO": "sonp233201@siigo.com",
                    "STATION ID": "2606689",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luzdary Bernal Villarreal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "77184582",
                    "FECHA INICIAL": "2014-03-03",
                    "FECHA OPERACION": "2014-03-03",
                    "NOMBRES": "Juan Carlos",
                    "APELLIDOS": "Estrada Chacon",
                    "NOMBRES COMPLETOS": "Juan Carlos Estrada Chacon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juvenal Reyes Silva",
                    "CODIGO USUARIO": "estr33172",
                    "USUARIOFIVE9": "estr33172@siigo.com",
                    "USUARIO RED": "estr33172",
                    "CORREOELECTRONICO": "estr33172@siigo.com",
                    "STATION ID": "2606702",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juvenal Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1017140019",
                    "FECHA INICIAL": "2019-12-23",
                    "FECHA OPERACION": "2019-12-23",
                    "NOMBRES": "Hillary Cristina",
                    "APELLIDOS": "Gomez Pineda",
                    "NOMBRES COMPLETOS": "Hillary Cristina Gomez Pineda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Pierre Fardey Velasquez Sanchez",
                    "CODIGO USUARIO": "gome800136",
                    "USUARIOFIVE9": "gome800136@siigo.com",
                    "USUARIO RED": "gome800136",
                    "CORREOELECTRONICO": "gome800136@siigo.com",
                    "STATION ID": "2606929",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Pierre Fardey Velasquez Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1045706026",
                    "FECHA INICIAL": "2016-03-02",
                    "FECHA OPERACION": "2016-03-02",
                    "NOMBRES": "German Arturo",
                    "APELLIDOS": "Barros Garcia",
                    "NOMBRES COMPLETOS": "German Arturo Barros Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Cristian Armando Rozo Bermudez",
                    "CODIGO USUARIO": "barr110035",
                    "USUARIOFIVE9": "barr110035@siigo.com",
                    "USUARIO RED": "barr110035",
                    "CORREOELECTRONICO": "barr110035@siigo.com",
                    "STATION ID": "2606675",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Cristian Armando Rozo Bermudez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "43971981",
                    "FECHA INICIAL": "2021-12-01",
                    "FECHA OPERACION": "2021-12-07",
                    "NOMBRES": "Paula Andrea",
                    "APELLIDOS": "Isaza Valencia",
                    "NOMBRES COMPLETOS": "Paula Andrea Isaza Valencia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Paula Alejandra Garcia Lujan",
                    "CODIGO USUARIO": "isaz802306",
                    "USUARIOFIVE9": "isaz802306@siigo.com",
                    "USUARIO RED": "isaz802306",
                    "CORREOELECTRONICO": "isaz802306@siigo.com",
                    "STATION ID": "3471503",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Paula Alejandra Garcia Lujan",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80748885",
                    "FECHA INICIAL": "2021-12-01",
                    "FECHA OPERACION": "2021-12-07",
                    "NOMBRES": "Hernan Gabriel",
                    "APELLIDOS": "Rodriguez Pena",
                    "NOMBRES COMPLETOS": "Hernan Gabriel Rodriguez Pena",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Yuleidy Josefina Briceno Fernandez",
                    "CODIGO USUARIO": "rodr802304",
                    "USUARIOFIVE9": "rodr802304@siigo.com",
                    "USUARIO RED": "rodr802304",
                    "CORREOELECTRONICO": "rodr802304@siigo.com",
                    "STATION ID": "3483705",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Yuleidy Josefina Briceno Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015428735",
                    "FECHA INICIAL": "2018-06-15",
                    "FECHA OPERACION": "2018-06-15",
                    "NOMBRES": "Angelica Obando",
                    "APELLIDOS": "Nieto",
                    "NOMBRES COMPLETOS": "Angelica Obando Nieto",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnicos",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Anderson Cubillos Ayala",
                    "CODIGO USUARIO": "oban111729",
                    "USUARIOFIVE9": "oban111729@siigo.com",
                    "USUARIO RED": "oban111729",
                    "CORREOELECTRONICO": "oban111729@siigo.com",
                    "STATION ID": "2606791",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Anderson Cubillos Ayala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007538543",
                    "FECHA INICIAL": "2021-05-05",
                    "FECHA OPERACION": "2021-05-05",
                    "NOMBRES": "Cristhian David",
                    "APELLIDOS": "Hernandez Jimenez",
                    "NOMBRES COMPLETOS": "Cristhian David Hernandez Jimenez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnicos",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Anderson Cubillos Ayala",
                    "CODIGO USUARIO": "hern801377",
                    "USUARIOFIVE9": "hern801377@siigo.com",
                    "USUARIO RED": "hern801377",
                    "CORREOELECTRONICO": "hern801377@siigo.com",
                    "STATION ID": "2607054",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Anderson Cubillos Ayala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010247270",
                    "FECHA INICIAL": "2021-11-09",
                    "FECHA OPERACION": "2021-11-10",
                    "NOMBRES": "Alisson Daniela",
                    "APELLIDOS": "Huertas Roldan",
                    "NOMBRES COMPLETOS": "Alisson Daniela Huertas Roldan",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Eliana Maria Fajardo Prada",
                    "CODIGO USUARIO": "huer802212",
                    "USUARIOFIVE9": "huer802212@siigo.com",
                    "USUARIO RED": "huer802212",
                    "CORREOELECTRONICO": "huer802212@siigo.com",
                    "STATION ID": "2606033",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Eliana Maria Fajardo Prada",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1037666090",
                    "FECHA INICIAL": "2020-11-17",
                    "FECHA OPERACION": "2020-11-30",
                    "NOMBRES": "Daniel",
                    "APELLIDOS": "Londono Garces",
                    "NOMBRES COMPLETOS": "Daniel Londono Garces",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnicos",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Anderson Cubillos Ayala",
                    "CODIGO USUARIO": "lond800855",
                    "USUARIOFIVE9": "lond800855@siigo.com",
                    "USUARIO RED": "lond800855",
                    "CORREOELECTRONICO": "lond800855@siigo.com",
                    "STATION ID": "2606264",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Anderson Cubillos Ayala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1144145236",
                    "FECHA INICIAL": "2021-12-07",
                    "FECHA OPERACION": "2021-12-14",
                    "NOMBRES": "Luisa Fernanda",
                    "APELLIDOS": "Triana Jaramillo",
                    "NOMBRES COMPLETOS": "Luisa Fernanda Triana Jaramillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alison Anaid Chica Ezeta",
                    "CODIGO USUARIO": "tria802411",
                    "USUARIOFIVE9": "tria802411@siigo.com",
                    "USUARIO RED": "tria802411",
                    "CORREOELECTRONICO": "tria802411@siigo.com",
                    "STATION ID": "3690289",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Alison Anaid Chica Ezeta",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013606016",
                    "FECHA INICIAL": "2021-12-07",
                    "FECHA OPERACION": "2021-12-14",
                    "NOMBRES": "Boris Ivan",
                    "APELLIDOS": "Londono Fernandez",
                    "NOMBRES COMPLETOS": "Boris Ivan Londono Fernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Daniel Eduardo Pinto Cepeda",
                    "CODIGO USUARIO": "lond802409",
                    "USUARIOFIVE9": "lond802409@siigo.com",
                    "USUARIO RED": "lond802409",
                    "CORREOELECTRONICO": "lond802409@siigo.com",
                    "STATION ID": "3690294",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Daniel Eduardo Pinto Cepeda",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016109397",
                    "FECHA INICIAL": "2021-12-07",
                    "FECHA OPERACION": "2021-12-14",
                    "NOMBRES": "Darsy Mabel",
                    "APELLIDOS": "Martinez Pachon",
                    "NOMBRES COMPLETOS": "Darsy Mabel Martinez Pachon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Armenia",
                    "AREA": "Comercial",
                    "SUBAREA": "Recuperaciones",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Mario Perez Diaz",
                    "CODIGO USUARIO": "mart802408",
                    "USUARIOFIVE9": "mart802408@siigo.com",
                    "USUARIO RED": "mart802408",
                    "CORREOELECTRONICO": "mart802408@siigo.com",
                    "STATION ID": "3690297",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Mario Perez Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018417041",
                    "FECHA INICIAL": "2021-12-06",
                    "FECHA OPERACION": "2021-12-14",
                    "NOMBRES": "Yaneth Alejandra",
                    "APELLIDOS": "Bohorquez Oliveros",
                    "NOMBRES COMPLETOS": "Yaneth Alejandra Bohorquez Oliveros",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jose Manuel Aguayo Escandon",
                    "CODIGO USUARIO": "boho802392",
                    "USUARIOFIVE9": "boho802392@siigo.com",
                    "USUARIO RED": "boho802392",
                    "CORREOELECTRONICO": "boho802392@siigo.com",
                    "STATION ID": "3690377",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jose Manuel Aguayo Escandon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "55171543",
                    "FECHA INICIAL": "2021-12-06",
                    "FECHA OPERACION": "2021-12-14",
                    "NOMBRES": "Carmen Yolanda",
                    "APELLIDOS": "Moncaleano Avila",
                    "NOMBRES COMPLETOS": "Carmen Yolanda Moncaleano Avila",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Maria Naranjo Mattheus",
                    "CODIGO USUARIO": "monc802390",
                    "USUARIOFIVE9": "monc802390@siigo.com",
                    "USUARIO RED": "monc802390",
                    "CORREOELECTRONICO": "monc802390@siigo.com",
                    "STATION ID": "3690379",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Maria Naranjo Mattheus",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52903343",
                    "FECHA INICIAL": "2021-12-06",
                    "FECHA OPERACION": "2021-12-14",
                    "NOMBRES": "Liliana",
                    "APELLIDOS": "Orjuela Castaneda",
                    "NOMBRES COMPLETOS": "Liliana Orjuela Castaneda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Buitrago Rios",
                    "CODIGO USUARIO": "orju802372",
                    "USUARIOFIVE9": "orju802372@siigo.com",
                    "USUARIO RED": "orju802372",
                    "CORREOELECTRONICO": "orju802372@siigo.com",
                    "STATION ID": "3720330",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Buitrago Rios",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010224410",
                    "FECHA INICIAL": "2021-12-06",
                    "FECHA OPERACION": "2021-12-14",
                    "NOMBRES": "Jeysson Andres",
                    "APELLIDOS": "Layton Paez",
                    "NOMBRES COMPLETOS": "Jeysson Andres Layton Paez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Recuperaciones",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Mario Perez Diaz",
                    "CODIGO USUARIO": "layt802363",
                    "USUARIOFIVE9": "layt802363@siigo.com",
                    "USUARIO RED": "layt802363",
                    "CORREOELECTRONICO": "layt802363@siigo.com",
                    "STATION ID": "3720331",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Mario Perez Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "45546842",
                    "FECHA INICIAL": "2021-12-06",
                    "FECHA OPERACION": "2021-12-14",
                    "NOMBRES": "Kellys Marcela",
                    "APELLIDOS": "Quintana Correa",
                    "NOMBRES COMPLETOS": "Kellys Marcela Quintana Correa",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alison Anaid Chica Ezeta",
                    "CODIGO USUARIO": "quin802374",
                    "USUARIOFIVE9": "quin802374@siigo.com",
                    "USUARIO RED": "quin802374",
                    "CORREOELECTRONICO": "quin802374@siigo.com",
                    "STATION ID": "3720333",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Alison Anaid Chica Ezeta",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143326618",
                    "FECHA INICIAL": "2021-12-06",
                    "FECHA OPERACION": "2021-12-14",
                    "NOMBRES": "Ivan Fernando",
                    "APELLIDOS": "Cogollo Moreno",
                    "NOMBRES COMPLETOS": "Ivan Fernando Cogollo Moreno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alison Anaid Chica Ezeta",
                    "CODIGO USUARIO": "cogo802375",
                    "USUARIOFIVE9": "cogo802375@siigo.com",
                    "USUARIO RED": "cogo802375",
                    "CORREOELECTRONICO": "cogo802375@siigo.com",
                    "STATION ID": "3720336",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Alison Anaid Chica Ezeta",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1053810772",
                    "FECHA INICIAL": "2021-12-06",
                    "FECHA OPERACION": "2021-12-14",
                    "NOMBRES": "Valentina",
                    "APELLIDOS": "Santafe Gutierrez",
                    "NOMBRES COMPLETOS": "Valentina Santafe Gutierrez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alison Anaid Chica Ezeta",
                    "CODIGO USUARIO": "sant802376",
                    "USUARIOFIVE9": "sant802376@siigo.com",
                    "USUARIO RED": "sant802376",
                    "CORREOELECTRONICO": "sant802376@siigo.com",
                    "STATION ID": "3720337",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Alison Anaid Chica Ezeta",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "3239312",
                    "FECHA INICIAL": "2021-12-06",
                    "FECHA OPERACION": "2021-12-14",
                    "NOMBRES": "Oscar Yamith",
                    "APELLIDOS": "Arias Rodriguez",
                    "NOMBRES COMPLETOS": "Oscar Yamith Arias Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bojaca",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Magda Lilian Leal Rozo",
                    "CODIGO USUARIO": "aria802370",
                    "USUARIOFIVE9": "aria802370@siigo.com",
                    "USUARIO RED": "aria802370",
                    "CORREOELECTRONICO": "aria802370@siigo.com",
                    "STATION ID": "3720339",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Magda Lilian Leal Rozo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52519507",
                    "FECHA INICIAL": "2021-12-06",
                    "FECHA OPERACION": "2021-12-14",
                    "NOMBRES": "Diana Patricia",
                    "APELLIDOS": "Torres Retavisca",
                    "NOMBRES COMPLETOS": "Diana Patricia Torres Retavisca",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "William Andres Reyes Silva",
                    "CODIGO USUARIO": "torr802369",
                    "USUARIOFIVE9": "torr802369@siigo.com",
                    "USUARIO RED": "torr802369",
                    "CORREOELECTRONICO": "torr802369@siigo.com",
                    "STATION ID": "3720343",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_William Andres Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1042350767",
                    "FECHA INICIAL": "2021-12-06",
                    "FECHA OPERACION": "2021-12-14",
                    "NOMBRES": "Lourdes Vanessa",
                    "APELLIDOS": "Martinez Cruz",
                    "NOMBRES COMPLETOS": "Lourdes Vanessa Martinez Cruz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Recuperaciones",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Mario Perez Diaz",
                    "CODIGO USUARIO": "mart802362",
                    "USUARIOFIVE9": "mart802362@siigo.com",
                    "USUARIO RED": "mart802362",
                    "CORREOELECTRONICO": "mart802362@siigo.com",
                    "STATION ID": "3720344",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Mario Perez Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1025141225",
                    "FECHA INICIAL": "2021-12-06",
                    "FECHA OPERACION": "2021-12-14",
                    "NOMBRES": "Yuri Alexandra",
                    "APELLIDOS": "Canon Bustos",
                    "NOMBRES COMPLETOS": "Yuri Alexandra Canon Bustos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Vicky Alejandra Hernandez Mendez",
                    "CODIGO USUARIO": "cano802382",
                    "USUARIOFIVE9": "cano802382@siigo.com",
                    "USUARIO RED": "cano802382",
                    "CORREOELECTRONICO": "cano802382@siigo.com",
                    "STATION ID": "3720350",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Vicky Alejandra Hernandez Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52969446",
                    "FECHA INICIAL": "2021-10-21",
                    "FECHA OPERACION": "2021-12-17",
                    "NOMBRES": "Natalia",
                    "APELLIDOS": "Marinez Gonzalez",
                    "NOMBRES COMPLETOS": "Natalia Marinez Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Director Customer",
                    "JEFE INMEDIATO": "Carlos Mario Espinosa Fernandez",
                    "CODIGO USUARIO": "mari802119",
                    "USUARIOFIVE9": "mari802119@siigo.com",
                    "USUARIO RED": "mari802119",
                    "CORREOELECTRONICO": "mari802119@siigo.com",
                    "STATION ID": "2606281",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Mario Espinosa Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1121830470",
                    "FECHA INICIAL": "2021-11-11",
                    "FECHA OPERACION": "2021-11-16",
                    "NOMBRES": "Yuly Stefany",
                    "APELLIDOS": "Cardozo Polania",
                    "NOMBRES COMPLETOS": "Yuly Stefany Cardozo Polania",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones ECU",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Angie Camila Diaz Escobar",
                    "CODIGO USUARIO": "card802239",
                    "USUARIOFIVE9": "card802239@siigo.com",
                    "USUARIO RED": "card802239",
                    "CORREOELECTRONICO": "card802239@siigo.com",
                    "STATION ID": "3690329",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Camila Diaz Escobar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143408555",
                    "FECHA INICIAL": "2021-12-22",
                    "FECHA OPERACION": "2021-12-30",
                    "NOMBRES": "Angie Carolina",
                    "APELLIDOS": "Tovio Machado",
                    "NOMBRES COMPLETOS": "Angie Carolina Tovio Machado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Nicoll Torres Celemin",
                    "CODIGO USUARIO": "tovi802438",
                    "USUARIOFIVE9": "tovi802438@siigo.com",
                    "USUARIO RED": "tovi802438",
                    "CORREOELECTRONICO": "tovi802438@siigo.com",
                    "STATION ID": "3720360",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Nicoll Torres Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000518042",
                    "FECHA INICIAL": "2021-12-22",
                    "FECHA OPERACION": "2021-12-30",
                    "NOMBRES": "David Steven",
                    "APELLIDOS": "Yusti Buitrago",
                    "NOMBRES COMPLETOS": "David Steven Yusti Buitrago",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Villavicencio",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Carolina Linares Gonzalez",
                    "CODIGO USUARIO": "yust802439",
                    "USUARIOFIVE9": "yust802439@siigo.com",
                    "USUARIO RED": "yust802439",
                    "CORREOELECTRONICO": "yust802439@siigo.com",
                    "STATION ID": "3720362",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Carolina Linares Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001053560",
                    "FECHA INICIAL": "2021-12-22",
                    "FECHA OPERACION": "2021-12-30",
                    "NOMBRES": "Gabrielle Alejandra",
                    "APELLIDOS": "Ramirez Celi",
                    "NOMBRES COMPLETOS": "Gabrielle Alejandra Ramirez Celi",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jefrey Julian Sanchez Celemin",
                    "CODIGO USUARIO": "rami802435",
                    "USUARIOFIVE9": "rami802435@siigo.com",
                    "USUARIO RED": "rami802435",
                    "CORREOELECTRONICO": "rami802435@siigo.com",
                    "STATION ID": "3720364",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jefrey Julian Sanchez Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1073517576",
                    "FECHA INICIAL": "2021-12-22",
                    "FECHA OPERACION": "2021-12-30",
                    "NOMBRES": "Heidy Viviana",
                    "APELLIDOS": "Vargas Vargas",
                    "NOMBRES COMPLETOS": "Heidy Viviana Vargas Vargas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Contabilidad",
                    "SUBAREA": "Contabilidad",
                    "CARGO": "Auxiliar Contable",
                    "JEFE INMEDIATO": "Diana Yaneth Espitia Acevedo",
                    "CODIGO USUARIO": "Varg802433",
                    "USUARIOFIVE9": "Varg802433@siigo.com",
                    "USUARIO RED": "Varg802433",
                    "CORREOELECTRONICO": "Varg802433@siigo.com",
                    "STATION ID": "3720365",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Contabilidad_Diana Yaneth Espitia Acevedo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1065653410",
                    "FECHA INICIAL": "2021-12-22",
                    "FECHA OPERACION": "2021-12-30",
                    "NOMBRES": "Jhonatan",
                    "APELLIDOS": "Araujo Guette",
                    "NOMBRES COMPLETOS": "Jhonatan Araujo Guette",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Nicoll Torres Celemin",
                    "CODIGO USUARIO": "arau802429",
                    "USUARIOFIVE9": "arau802429@siigo.com",
                    "USUARIO RED": "arau802429",
                    "CORREOELECTRONICO": "arau802429@siigo.com",
                    "STATION ID": "3720367",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Nicoll Torres Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1121967175",
                    "FECHA INICIAL": "2021-12-22",
                    "FECHA OPERACION": "2021-12-30",
                    "NOMBRES": "Karem Xiomara",
                    "APELLIDOS": "Holguin Aragon",
                    "NOMBRES COMPLETOS": "Karem Xiomara Holguin Aragon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Villavicencio",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Hancel Rafael Salcedo Mendoza",
                    "CODIGO USUARIO": "holg802422",
                    "USUARIOFIVE9": "holg802422@siigo.com",
                    "USUARIO RED": "holg802422",
                    "CORREOELECTRONICO": "holg802422@siigo.com",
                    "STATION ID": "3720369",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Hancel Rafael Salcedo Mendoza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52539417",
                    "FECHA INICIAL": "2021-12-30",
                    "FECHA OPERACION": "2021-12-30",
                    "NOMBRES": "Libia Amparo",
                    "APELLIDOS": "Osorio Rodriguez",
                    "NOMBRES COMPLETOS": "Libia Amparo Osorio Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Andrea Zuluaga Uribe",
                    "CODIGO USUARIO": "osor802360",
                    "USUARIOFIVE9": "osor802360@siigo.com",
                    "USUARIO RED": "osor802360",
                    "CORREOELECTRONICO": "osor802360@siigo.com",
                    "STATION ID": "3720371",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andrea Zuluaga Uribe",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "86074795",
                    "FECHA INICIAL": "2021-12-22",
                    "FECHA OPERACION": "2021-12-30",
                    "NOMBRES": "Roy",
                    "APELLIDOS": "Cruz Garcia",
                    "NOMBRES COMPLETOS": "Roy Cruz Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Villavicencio",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Sory Yaqueline Abello Polo",
                    "CODIGO USUARIO": "cruz802442",
                    "USUARIOFIVE9": "cruz802442@siigo.com",
                    "USUARIO RED": "cruz802442",
                    "CORREOELECTRONICO": "cruz802442@siigo.com",
                    "STATION ID": "3720372",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sory Yaqueline Abello Polo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1083026363",
                    "FECHA INICIAL": "2021-12-22",
                    "FECHA OPERACION": "2021-12-30",
                    "NOMBRES": "Valentina",
                    "APELLIDOS": "Herrera Sierra",
                    "NOMBRES COMPLETOS": "Valentina Herrera Sierra",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Santa Marta",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "herr802427",
                    "USUARIOFIVE9": "herr802427@siigo.com",
                    "USUARIO RED": "herr802427",
                    "CORREOELECTRONICO": "herr802427@siigo.com",
                    "STATION ID": "3720373",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1077087621",
                    "FECHA INICIAL": "2021-12-22",
                    "FECHA OPERACION": "2021-12-30",
                    "NOMBRES": "Yenifer Edilsa",
                    "APELLIDOS": "Esptia Acevedo",
                    "NOMBRES COMPLETOS": "Yenifer Edilsa Espitia Acevedo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Sory Yaqueline Abello Polo",
                    "CODIGO USUARIO": "Espi802421",
                    "USUARIOFIVE9": "Espi802421@siigo.com",
                    "USUARIO RED": "Espi802421",
                    "CORREOELECTRONICO": "Espi802421@siigo.com",
                    "STATION ID": "3720374",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sory Yaqueline Abello Polo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057591347",
                    "FECHA INICIAL": "2021-12-22",
                    "FECHA OPERACION": "2021-12-30",
                    "NOMBRES": "Yiselt Nataly",
                    "APELLIDOS": "Suarez Mesa",
                    "NOMBRES COMPLETOS": "Yiselt Nataly Suarez Mesa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Sogamoso",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luz Katerine Maya Mogollon",
                    "CODIGO USUARIO": "suar802423",
                    "USUARIOFIVE9": "suar802423@siigo.com",
                    "USUARIO RED": "suar802423",
                    "CORREOELECTRONICO": "suar802423@siigo.com",
                    "STATION ID": "3720376",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Katerine Maya Mogollon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1002134493",
                    "FECHA INICIAL": "2021-12-22",
                    "FECHA OPERACION": "2021-12-30",
                    "NOMBRES": "Yuneris",
                    "APELLIDOS": "Perez Rodriguez",
                    "NOMBRES COMPLETOS": "Yuneris Perez Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ecuador",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Edith Angelica Gil Umbarila",
                    "CODIGO USUARIO": "pere802426",
                    "USUARIOFIVE9": "pere802426@siigo.com",
                    "USUARIO RED": "pere802426",
                    "CORREOELECTRONICO": "pere802426@siigo.com",
                    "STATION ID": "3720378",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Edith Angelica Gil Umbarila",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024601084",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Diana Carolina",
                    "APELLIDOS": "Mestra Ruiz",
                    "NOMBRES COMPLETOS": "Diana Carolina Mestra Ruiz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ventas",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "mest802480",
                    "USUARIOFIVE9": "mest802480@siigo.com",
                    "USUARIO RED": "mest802480",
                    "CORREOELECTRONICO": "mest802480@siigo.com",
                    "STATION ID": "3720379",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "98557841",
                    "FECHA INICIAL": "2019-02-15",
                    "FECHA OPERACION": "2019-02-15",
                    "NOMBRES": "Diego Tamayo",
                    "APELLIDOS": "Munoz",
                    "NOMBRES COMPLETOS": "Diego Tamayo Munoz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnicos",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Anderson Cubillos Ayala",
                    "CODIGO USUARIO": "tama111914",
                    "USUARIOFIVE9": "tama111914@siigo.com",
                    "USUARIO RED": "tama111914",
                    "CORREOELECTRONICO": "tama111914@siigo.com",
                    "STATION ID": "2606793",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Anderson Cubillos Ayala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000894166",
                    "FECHA INICIAL": "2019-11-09",
                    "FECHA OPERACION": "2019-11-09",
                    "NOMBRES": "Dilan Vargas",
                    "APELLIDOS": "Suaza",
                    "NOMBRES COMPLETOS": "Dilan Vargas Suaza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnicos",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Anderson Cubillos Ayala",
                    "CODIGO USUARIO": "varg112050",
                    "USUARIOFIVE9": "varg112050@siigo.com",
                    "USUARIO RED": "varg112050",
                    "CORREOELECTRONICO": "varg112050@siigo.com",
                    "STATION ID": "2606801",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Anderson Cubillos Ayala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023906170",
                    "FECHA INICIAL": "2021-01-06",
                    "FECHA OPERACION": "2021-01-12",
                    "NOMBRES": "Nelson David",
                    "APELLIDOS": "Gutierrez Barbosa",
                    "NOMBRES COMPLETOS": "Nelson David Gutierrez Barbosa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Quality Control",
                    "JEFE INMEDIATO": "Sindy Paola Lasso Velasco",
                    "CODIGO USUARIO": "guti801019",
                    "USUARIOFIVE9": "guti801019@siigo.com",
                    "USUARIO RED": "guti801019",
                    "CORREOELECTRONICO": "guti801019@siigo.com",
                    "STATION ID": "2606388",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Sindy Paola Lasso Velasco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1036669427",
                    "FECHA INICIAL": "2021-08-12",
                    "FECHA OPERACION": "2021-08-13",
                    "NOMBRES": "Sebastian Andres",
                    "APELLIDOS": "Rios Guevara",
                    "NOMBRES COMPLETOS": "Sebastian Andres Rios Guevara",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnicos",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Anderson Cubillos Ayala",
                    "CODIGO USUARIO": "rios801665",
                    "USUARIOFIVE9": "rios801665@siigo.com",
                    "USUARIO RED": "rios801665",
                    "CORREOELECTRONICO": "rios801665@siigo.com",
                    "STATION ID": "2606776",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Anderson Cubillos Ayala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1097406302",
                    "FECHA INICIAL": "2021-09-06",
                    "FECHA OPERACION": "2021-09-06",
                    "NOMBRES": "Angie Julieth",
                    "APELLIDOS": "Toro Robayo",
                    "NOMBRES COMPLETOS": "Angie Julieth Toro Robayo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnicos",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Anderson Cubillos Ayala",
                    "CODIGO USUARIO": "toro801816",
                    "USUARIOFIVE9": "toro801816@siigo.com",
                    "USUARIO RED": "toro801816",
                    "CORREOELECTRONICO": "toro801816@siigo.com",
                    "STATION ID": "3267880",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Anderson Cubillos Ayala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1005977512",
                    "FECHA INICIAL": "2021-11-02",
                    "FECHA OPERACION": "2021-11-03",
                    "NOMBRES": "Andres Felipe",
                    "APELLIDOS": "Carvajal Vega",
                    "NOMBRES COMPLETOS": "Andres Felipe Carvajal Vega",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luz Adriana Hurtado Navarro",
                    "CODIGO USUARIO": "carv802188",
                    "USUARIOFIVE9": "carv802188@siigo.com",
                    "USUARIO RED": "carv802188",
                    "CORREOELECTRONICO": "carv802188@siigo.com",
                    "STATION ID": "2606019",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Adriana Hurtado Navarro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1113681582",
                    "FECHA INICIAL": "2021-10-08",
                    "FECHA OPERACION": "2021-11-03",
                    "NOMBRES": "Juan Sebastian",
                    "APELLIDOS": "Rivera Chavez",
                    "NOMBRES COMPLETOS": "Juan Sebastian Rivera Chavez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnicos",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Anderson Cubillos Ayala",
                    "CODIGO USUARIO": "rive802048",
                    "USUARIOFIVE9": "rive802048@siigo.com",
                    "USUARIO RED": "rive802048",
                    "CORREOELECTRONICO": "rive802048@siigo.com",
                    "STATION ID": "2606187",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Anderson Cubillos Ayala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1113695644",
                    "FECHA INICIAL": "2021-11-18",
                    "FECHA OPERACION": "2021-11-22",
                    "NOMBRES": "Harold",
                    "APELLIDOS": "Mondragon Jaramillo",
                    "NOMBRES COMPLETOS": "Harold Mondragon Jaramillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnicos",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Anderson Cubillos Ayala",
                    "CODIGO USUARIO": "mond802273",
                    "USUARIOFIVE9": "mond802273@siigo.com",
                    "USUARIO RED": "mond802273",
                    "CORREOELECTRONICO": "mond802273@siigo.com",
                    "STATION ID": "3690355",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Anderson Cubillos Ayala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1233696242",
                    "FECHA INICIAL": "2021-12-01",
                    "FECHA OPERACION": "2021-12-06",
                    "NOMBRES": "Andres Felipe",
                    "APELLIDOS": "Hincapie Navas",
                    "NOMBRES COMPLETOS": "Andres Felipe Hincapie Navas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnicos",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Anderson Cubillos Ayala",
                    "CODIGO USUARIO": "hinc802296",
                    "USUARIOFIVE9": "hinc802296@siigo.com",
                    "USUARIO RED": "hinc802296",
                    "CORREOELECTRONICO": "hinc802296@siigo.com",
                    "STATION ID": "2606186",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Anderson Cubillos Ayala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007648700",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Naiceth",
                    "APELLIDOS": "Dajana Naissir",
                    "NOMBRES COMPLETOS": "Naiceth Dajana Naissir Torres",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Mompox",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Tatiana Reyes Perez",
                    "CODIGO USUARIO": "nais802515",
                    "USUARIOFIVE9": "nais802515@siigo.com",
                    "USUARIO RED": "nais802515",
                    "CORREOELECTRONICO": "nais802515@siigo.com",
                    "STATION ID": "3720404",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Tatiana Reyes Perez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1045738299",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Luis",
                    "APELLIDOS": "Cesar Calderon",
                    "NOMBRES COMPLETOS": "Luis Cesar Calderon Lopez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Tatiana Reyes Perez",
                    "CODIGO USUARIO": "cald802514",
                    "USUARIOFIVE9": "cald802514@siigo.com",
                    "USUARIO RED": "cald802514",
                    "CORREOELECTRONICO": "cald802514@siigo.com",
                    "STATION ID": "3720405",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Tatiana Reyes Perez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1193231066",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Cristian",
                    "APELLIDOS": "David Valencia",
                    "NOMBRES COMPLETOS": "Cristian David Valencia Alvarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativos",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Maria Lucia Canales Parrales",
                    "CODIGO USUARIO": "vale802511",
                    "USUARIOFIVE9": "vale802511@siigo.com",
                    "USUARIO RED": "vale802511",
                    "CORREOELECTRONICO": "vale802511@siigo.com",
                    "STATION ID": "3720408",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Maria Lucia Canales Parrales",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143384265",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Ariel",
                    "APELLIDOS": "De Jesus Lambertino",
                    "NOMBRES COMPLETOS": "Ariel De Jesus Restrepo Lambertino",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Turbaco",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Tatiana Reyes Perez",
                    "CODIGO USUARIO": "rest802509",
                    "USUARIOFIVE9": "rest802509@siigo.com",
                    "USUARIO RED": "rest802509",
                    "CORREOELECTRONICO": "rest802509@siigo.com",
                    "STATION ID": "3720410",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Tatiana Reyes Perez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000273818",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Laura",
                    "APELLIDOS": "Jireth Avellaneda",
                    "NOMBRES COMPLETOS": "Laura Jireth Avellaneda Puentes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Tatiana Reyes Perez",
                    "CODIGO USUARIO": "avel802508",
                    "USUARIOFIVE9": "avel802508@siigo.com",
                    "USUARIO RED": "avel802508",
                    "CORREOELECTRONICO": "avel802508@siigo.com",
                    "STATION ID": "3720411",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Tatiana Reyes Perez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1071172197",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Laura",
                    "APELLIDOS": "Camila Hernandez",
                    "NOMBRES COMPLETOS": "Laura Camila Hernandez Dimate",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "La Calera",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "hern802507",
                    "USUARIOFIVE9": "hern802507@siigo.com",
                    "USUARIO RED": "hern802507",
                    "CORREOELECTRONICO": "hern802507@siigo.com",
                    "STATION ID": "3720412",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007395400",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Monica",
                    "APELLIDOS": "Judith Valderrama",
                    "NOMBRES COMPLETOS": "Monica Judith Valderrama Tibaduiza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Fernando Sotto Cardozo",
                    "CODIGO USUARIO": "vald802506",
                    "USUARIOFIVE9": "vald802506@siigo.com",
                    "USUARIO RED": "vald802506",
                    "CORREOELECTRONICO": "vald802506@siigo.com",
                    "STATION ID": "3720413",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Fernando Sotto Cardozo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1077966544",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Lady",
                    "APELLIDOS": "Yurany Murillo",
                    "NOMBRES COMPLETOS": "Lady Yurany Murillo Rubiano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Sasaima",
                    "AREA": "Administrativo",
                    "SUBAREA": "Contabilidad",
                    "CARGO": "Auxiliar Contable",
                    "JEFE INMEDIATO": "Luz Derly Bernal Malaver",
                    "CODIGO USUARIO": "muri802504",
                    "USUARIOFIVE9": "muri802504@siigo.com",
                    "USUARIO RED": "muri802504",
                    "CORREOELECTRONICO": "muri802504@siigo.com",
                    "STATION ID": "3720415",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Luz Derly Bernal Malaver",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1054567356",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Marinela",
                    "APELLIDOS": "Ramos Cruz",
                    "NOMBRES COMPLETOS": "Marinela Ramos Cruz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Yamid Ramiro Quintero Montano",
                    "CODIGO USUARIO": "ramo802501",
                    "USUARIOFIVE9": "ramo802501@siigo.com",
                    "USUARIO RED": "ramo802501",
                    "CORREOELECTRONICO": "ramo802501@siigo.com",
                    "STATION ID": "3720418",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yamid Ramiro Quintero Montano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007133977",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Harol Styd",
                    "APELLIDOS": "De Taibel",
                    "NOMBRES COMPLETOS": "Harol Styd De Alba Taibel",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Santo Tomas",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Tatiana Reyes Perez",
                    "CODIGO USUARIO": "deal802499",
                    "USUARIOFIVE9": "deal802499@siigo.com",
                    "USUARIO RED": "deal802499",
                    "CORREOELECTRONICO": "deal802499@siigo.com",
                    "STATION ID": "3720420",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Tatiana Reyes Perez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1116249046",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Suly",
                    "APELLIDOS": "Johanna Bejarano",
                    "NOMBRES COMPLETOS": "Suly Johanna Bejarano Loaiza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Tulua",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jazbleidy Andrea Nunez Montoya",
                    "CODIGO USUARIO": "beja802497",
                    "USUARIOFIVE9": "beja802497@siigo.com",
                    "USUARIO RED": "beja802497",
                    "CORREOELECTRONICO": "beja802497@siigo.com",
                    "STATION ID": "3720422",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jazbleidy Andrea Nunez Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1221981365",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Brandon",
                    "APELLIDOS": "Zarante Davila",
                    "NOMBRES COMPLETOS": "Brandon Zarante Davila",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cienaga",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Sory Yaqueline Abello Polo",
                    "CODIGO USUARIO": "zara802496",
                    "USUARIOFIVE9": "zara802496@siigo.com",
                    "USUARIO RED": "zara802496",
                    "CORREOELECTRONICO": "zara802496@siigo.com",
                    "STATION ID": "3720423",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sory Yaqueline Abello Polo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1140903389",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Jhon",
                    "APELLIDOS": "Sebastian Bolano",
                    "NOMBRES COMPLETOS": "Jhon Sebastian Bolano Rua",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Palmar de Varela",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Tatiana Reyes Perez",
                    "CODIGO USUARIO": "bola802495",
                    "USUARIOFIVE9": "bola802495@siigo.com",
                    "USUARIO RED": "bola802495",
                    "CORREOELECTRONICO": "bola802495@siigo.com",
                    "STATION ID": "3720424",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Tatiana Reyes Perez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1111775988",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Gisela",
                    "APELLIDOS": "Manyoma Angulo",
                    "NOMBRES COMPLETOS": "Gisela Manyoma Angulo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jenny Paola Mendez",
                    "CODIGO USUARIO": "many802494",
                    "USUARIOFIVE9": "many802494@siigo.com",
                    "USUARIO RED": "many802494",
                    "CORREOELECTRONICO": "many802494@siigo.com",
                    "STATION ID": "3720425",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jenny Paola Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1192816756",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Elias David",
                    "APELLIDOS": "Barrios Alvarez",
                    "NOMBRES COMPLETOS": "Elias David Barrios Alvarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jefrey Julian Sanchez Celemin",
                    "CODIGO USUARIO": "barr802493",
                    "USUARIOFIVE9": "barr802493@siigo.com",
                    "USUARIO RED": "barr802493",
                    "CORREOELECTRONICO": "barr802493@siigo.com",
                    "STATION ID": "3720426",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jefrey Julian Sanchez Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1193111231",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Miguel Angel",
                    "APELLIDOS": "Bedoya Munoz",
                    "NOMBRES COMPLETOS": "Miguel Angel Bedoya Munoz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Pereira",
                    "AREA": "Customer",
                    "SUBAREA": "UCI",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Nestor Oswaldo Becerra Prieto",
                    "CODIGO USUARIO": "bedo802492",
                    "USUARIOFIVE9": "bedo802492@siigo.com",
                    "USUARIO RED": "bedo802492",
                    "CORREOELECTRONICO": "bedo802492@siigo.com",
                    "STATION ID": "3720427",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Nestor Oswaldo Becerra Prieto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1067814388",
                    "FECHA INICIAL": "2021-12-22",
                    "FECHA OPERACION": "2022-01-06",
                    "NOMBRES": "Davith Jose",
                    "APELLIDOS": "Vega Borja",
                    "NOMBRES COMPLETOS": "Davith Jose Vega Borja",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Villavicencio",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Johanna Patricia Torres Quintero",
                    "CODIGO USUARIO": "vega802425",
                    "USUARIOFIVE9": "vega802425@siigo.com",
                    "USUARIO RED": "vega802425",
                    "CORREOELECTRONICO": "vega802425@siigo.com",
                    "STATION ID": "3720434",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Johanna Patricia Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032369989",
                    "FECHA INICIAL": "2021-12-21",
                    "FECHA OPERACION": "2022-01-06",
                    "NOMBRES": "Jenifer",
                    "APELLIDOS": "Noriega Cadavid",
                    "NOMBRES COMPLETOS": "Jenifer Noriega Cadavid",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angy Paola Umbacia Diaz",
                    "CODIGO USUARIO": "nori802449",
                    "USUARIOFIVE9": "nori802449@siigo.com",
                    "USUARIO RED": "nori802449",
                    "CORREOELECTRONICO": "nori802449@siigo.com",
                    "STATION ID": "3720435",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angy Paola Umbacia Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1073172771",
                    "FECHA INICIAL": "2021-12-21",
                    "FECHA OPERACION": "2022-01-06",
                    "NOMBRES": "Anggie Carolina",
                    "APELLIDOS": "Aguilar Delgado",
                    "NOMBRES COMPLETOS": "Anggie Carolina Aguilar Delgado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Eduardo Sanchez Castano",
                    "CODIGO USUARIO": "agui802450",
                    "USUARIOFIVE9": "agui802450@siigo.com",
                    "USUARIO RED": "agui802450",
                    "CORREOELECTRONICO": "agui802450@siigo.com",
                    "STATION ID": "3720436",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Eduardo Sanchez Castano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001933734",
                    "FECHA INICIAL": "2021-12-22",
                    "FECHA OPERACION": "2022-01-06",
                    "NOMBRES": "Kevin Andres",
                    "APELLIDOS": "Duran Bravo",
                    "NOMBRES COMPLETOS": "Kevin Andres Duran Bravo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Sabanalarga",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jefrey Julian Sanchez Celemin",
                    "CODIGO USUARIO": "dura802457",
                    "USUARIOFIVE9": "dura802457@siigo.com",
                    "USUARIO RED": "dura802457",
                    "CORREOELECTRONICO": "dura802457@siigo.com",
                    "STATION ID": "3720443",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jefrey Julian Sanchez Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1006820388",
                    "FECHA INICIAL": "2021-12-22",
                    "FECHA OPERACION": "2022-01-06",
                    "NOMBRES": "Daniela Johana",
                    "APELLIDOS": "Ruiz Torres",
                    "NOMBRES COMPLETOS": "Daniela Johana Ruiz Torres",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Villavicencio",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Colombia",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Olga Lucia Ospina Vidal",
                    "CODIGO USUARIO": "Ruiz802459",
                    "USUARIOFIVE9": "Ruiz802459@siigo.com",
                    "USUARIO RED": "Ruiz802459",
                    "CORREOELECTRONICO": "Ruiz802459@siigo.com",
                    "STATION ID": "3720445",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Olga Lucia Ospina Vidal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1094267702",
                    "FECHA INICIAL": "2021-12-22",
                    "FECHA OPERACION": "2022-01-06",
                    "NOMBRES": "Dayana Vanesa",
                    "APELLIDOS": "Diaz Mercado",
                    "NOMBRES COMPLETOS": "Dayana Vanesa Diaz Mercado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luz Adriana Hurtado Navarro",
                    "CODIGO USUARIO": "diaz802464",
                    "USUARIOFIVE9": "diaz802464@siigo.com",
                    "USUARIO RED": "diaz802464",
                    "CORREOELECTRONICO": "diaz802464@siigo.com",
                    "STATION ID": "3720450",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Adriana Hurtado Navarro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1003519488",
                    "FECHA INICIAL": "2021-12-22",
                    "FECHA OPERACION": "2022-01-06",
                    "NOMBRES": "Laura Lucia",
                    "APELLIDOS": "Garcia Acosta",
                    "NOMBRES COMPLETOS": "Laura Lucia Garcia Acosta",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Fusagasuga",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jefrey Julian Sanchez Celemin",
                    "CODIGO USUARIO": "garc802465",
                    "USUARIOFIVE9": "garc802465@siigo.com",
                    "USUARIO RED": "garc802465",
                    "CORREOELECTRONICO": "garc802465@siigo.com",
                    "STATION ID": "3720451",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jefrey Julian Sanchez Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1047451528",
                    "FECHA INICIAL": "2021-12-22",
                    "FECHA OPERACION": "2022-01-06",
                    "NOMBRES": "Andrea Paola",
                    "APELLIDOS": "Palencia Manjarres",
                    "NOMBRES COMPLETOS": "Andrea Paola Palencia Manjarres",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Diana Sofia Ramos Castillo",
                    "CODIGO USUARIO": "pale802467",
                    "USUARIOFIVE9": "pale802467@siigo.com",
                    "USUARIO RED": "pale802467",
                    "CORREOELECTRONICO": "pale802467@siigo.com",
                    "STATION ID": "3720453",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Sofia Ramos Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143163733",
                    "FECHA INICIAL": "2021-12-22",
                    "FECHA OPERACION": "2022-01-06",
                    "NOMBRES": "Winndy Johanis",
                    "APELLIDOS": "Agamez Mendoza",
                    "NOMBRES COMPLETOS": "Winndy Johanis Agamez Mendoza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "agam802470",
                    "USUARIOFIVE9": "agam802470@siigo.com",
                    "USUARIO RED": "agam802470",
                    "CORREOELECTRONICO": "agam802470@siigo.com",
                    "STATION ID": "3720456",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1086279769",
                    "FECHA INICIAL": "2021-12-22",
                    "FECHA OPERACION": "2022-01-06",
                    "NOMBRES": "Paula Andrea",
                    "APELLIDOS": "Cruz Marin",
                    "NOMBRES COMPLETOS": "Paula Andrea Cruz Marin",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Santa Rosa De Cabal",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones ECU",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Angie Camila Diaz Escobar",
                    "CODIGO USUARIO": "cruz802471",
                    "USUARIOFIVE9": "cruz802471@siigo.com",
                    "USUARIO RED": "cruz802471",
                    "CORREOELECTRONICO": "cruz802471@siigo.com",
                    "STATION ID": "3720457",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Camila Diaz Escobar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "30413632",
                    "FECHA INICIAL": "2021-12-22",
                    "FECHA OPERACION": "2022-01-06",
                    "NOMBRES": "Carolina",
                    "APELLIDOS": "Trejos Gonzalez",
                    "NOMBRES COMPLETOS": "Carolina Trejos Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Patricia Villarraga Forero",
                    "CODIGO USUARIO": "trej802476",
                    "USUARIOFIVE9": "trej802476@siigo.com",
                    "USUARIO RED": "trej802476",
                    "CORREOELECTRONICO": "trej802476@siigo.com",
                    "STATION ID": "3720460",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Patricia Villarraga Forero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007971745",
                    "FECHA INICIAL": "2022-01-06",
                    "FECHA OPERACION": "2022-01-11",
                    "NOMBRES": "Roymer Eduardo",
                    "APELLIDOS": "Berrio Garcia",
                    "NOMBRES COMPLETOS": "Roymer Eduardo Berrio Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Sory Yaqueline Abello Polo",
                    "CODIGO USUARIO": "berr802553",
                    "USUARIOFIVE9": "berr802553@siigo.com",
                    "USUARIO RED": "berr802553",
                    "CORREOELECTRONICO": "berr802553@siigo.com",
                    "STATION ID": "2606093",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sory Yaqueline Abello Polo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1047428642",
                    "FECHA INICIAL": "2022-01-06",
                    "FECHA OPERACION": "2022-01-11",
                    "NOMBRES": "Arnil Yesid",
                    "APELLIDOS": "Castellon Barrios",
                    "NOMBRES COMPLETOS": "Arnil Yesid Castellon Barrios",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Leidy Stefany Castillo",
                    "CODIGO USUARIO": "cast802563",
                    "USUARIOFIVE9": "cast802563@siigo.com",
                    "USUARIO RED": "cast802563",
                    "CORREOELECTRONICO": "cast802563@siigo.com",
                    "STATION ID": "3127506",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Leidy Stefany Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001881654",
                    "FECHA INICIAL": "2022-01-06",
                    "FECHA OPERACION": "2022-01-11",
                    "NOMBRES": "Mayerlis",
                    "APELLIDOS": "Torres Ramires",
                    "NOMBRES COMPLETOS": "Mayerlis Torres Ramires",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoraciones",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Sandra Catalina Suarez Castillo",
                    "CODIGO USUARIO": "torr802552",
                    "USUARIOFIVE9": "torr802552@siigo.com",
                    "USUARIO RED": "torr802552",
                    "CORREOELECTRONICO": "torr802552@siigo.com",
                    "STATION ID": "2606006",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Sandra Catalina Suarez Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1005160126",
                    "FECHA INICIAL": "2022-01-06",
                    "FECHA OPERACION": "2022-01-11",
                    "NOMBRES": "Michel Dayana",
                    "APELLIDOS": "Sosa Caceres",
                    "NOMBRES COMPLETOS": "Michel Dayana Sosa Caceres",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jenny Paola Mendez",
                    "CODIGO USUARIO": "sosa802562",
                    "USUARIOFIVE9": "sosa802562@siigo.com",
                    "USUARIO RED": "sosa802562",
                    "CORREOELECTRONICO": "sosa802562@siigo.com",
                    "STATION ID": "2606054",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jenny Paola Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015439277",
                    "FECHA INICIAL": "2020-12-07",
                    "FECHA OPERACION": "2020-12-07",
                    "NOMBRES": "Shirley Catalina",
                    "APELLIDOS": "Pena Neira",
                    "NOMBRES COMPLETOS": "Shirley Catalina Pena Neira",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "UCI",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Nestor Oswaldo Becerra Prieto",
                    "CODIGO USUARIO": "pena800920",
                    "USUARIOFIVE9": "pena800920@siigo.com",
                    "USUARIO RED": "pena800920",
                    "CORREOELECTRONICO": "pena800920@siigo.com",
                    "STATION ID": "2606316",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Nestor Oswaldo Becerra Prieto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143430808",
                    "FECHA INICIAL": "2022-01-06",
                    "FECHA OPERACION": "2022-01-11",
                    "NOMBRES": "Neiza Lizeth",
                    "APELLIDOS": "Gil Castrillo",
                    "NOMBRES COMPLETOS": "Neiza Lizeth Gil Castrillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jenny Paola Mendez",
                    "CODIGO USUARIO": "gilc802561",
                    "USUARIOFIVE9": "gilc802561@siigo.com",
                    "USUARIO RED": "gilc802561",
                    "CORREOELECTRONICO": "gilc802561@siigo.com",
                    "STATION ID": "2606077",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jenny Paola Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1140823567",
                    "FECHA INICIAL": "2021-11-05",
                    "FECHA OPERACION": "2021-11-08",
                    "NOMBRES": "Wilmer Andres",
                    "APELLIDOS": "Chavez Bermejo",
                    "NOMBRES COMPLETOS": "Wilmer Andres Chavez Bermejo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Mercedes Moncada Peraza",
                    "CODIGO USUARIO": "chav802204",
                    "USUARIOFIVE9": "chav802204@siigo.com",
                    "USUARIO RED": "chav802204",
                    "CORREOELECTRONICO": "chav802204@siigo.com",
                    "STATION ID": "3690304",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Mercedes Moncada Peraza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1051358772",
                    "FECHA INICIAL": "2022-01-06",
                    "FECHA OPERACION": "2022-01-11",
                    "NOMBRES": "Yessica Paola",
                    "APELLIDOS": "Narvaez Ospino",
                    "NOMBRES COMPLETOS": "Yessica Paola Narvaez Ospino",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Sory Yaqueline Abello Polo",
                    "CODIGO USUARIO": "narv802551",
                    "USUARIOFIVE9": "narv802551@siigo.com",
                    "USUARIO RED": "narv802551",
                    "CORREOELECTRONICO": "narv802551@siigo.com",
                    "STATION ID": "2606135",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sory Yaqueline Abello Polo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023903069",
                    "FECHA INICIAL": "2022-01-11",
                    "FECHA OPERACION": "2022-01-17",
                    "NOMBRES": "Camila Tatiana",
                    "APELLIDOS": "Bogota Sanchez",
                    "NOMBRES COMPLETOS": "Camila Tatiana Bogota Sanchez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Estrategia",
                    "SUBAREA": "Estrategia",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Katherina Ayala Garcia",
                    "CODIGO USUARIO": "bogo802568",
                    "USUARIOFIVE9": "bogo802568@siigo.com",
                    "USUARIO RED": "bogo802568",
                    "CORREOELECTRONICO": "bogo802568@siigo.com",
                    "STATION ID": "2606534",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Estrategia_Katherina Ayala Garcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1017182299",
                    "FECHA INICIAL": "2022-01-11",
                    "FECHA OPERACION": "2022-01-17",
                    "NOMBRES": "Cindy Viviana",
                    "APELLIDOS": "Gutierrez Gallego",
                    "NOMBRES COMPLETOS": "Cindy Viviana Gutierrez Gallego",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "guti802569",
                    "USUARIOFIVE9": "guti802569@siigo.com",
                    "USUARIO RED": "guti802569",
                    "CORREOELECTRONICO": "guti802569@siigo.com",
                    "STATION ID": "2606539",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "926585720",
                    "FECHA INICIAL": "2021-09-01",
                    "FECHA OPERACION": "2021-09-01",
                    "NOMBRES": "Jazmin Alexandra",
                    "APELLIDOS": "Anchundia Tenorio",
                    "NOMBRES COMPLETOS": "Jazmin Alexandra Anchundia Tenorio",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "anch801747",
                    "USUARIOFIVE9": "anch801747@siigo.com",
                    "USUARIO RED": "anch801747",
                    "CORREOELECTRONICO": "anch801747@siigo.com",
                    "STATION ID": "3267856",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "926243213",
                    "FECHA INICIAL": "2021-09-01",
                    "FECHA OPERACION": "2021-09-01",
                    "NOMBRES": "Cristian Armando",
                    "APELLIDOS": "Moreira Drouet",
                    "NOMBRES COMPLETOS": "Cristian Armando Moreira Drouet",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "more801746",
                    "USUARIOFIVE9": "more801746@siigo.com",
                    "USUARIO RED": "more801746",
                    "CORREOELECTRONICO": "more801746@siigo.com",
                    "STATION ID": "3267857",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1716262132",
                    "FECHA INICIAL": "2007-07-19",
                    "FECHA OPERACION": "2021-02-02",
                    "NOMBRES": "Mariela Edith",
                    "APELLIDOS": "Alvarado Onoa",
                    "NOMBRES COMPLETOS": "Mariela Edith Alvarado Onoa",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "alva222765",
                    "USUARIOFIVE9": "alva222765@siigo.com",
                    "USUARIO RED": "alva222765",
                    "CORREOELECTRONICO": "alva222765@siigo.com",
                    "STATION ID": "2606440",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "929695633",
                    "FECHA INICIAL": "2020-10-29",
                    "FECHA OPERACION": "2021-09-20",
                    "NOMBRES": "Manuel De Jesus",
                    "APELLIDOS": "Lopez Orellana",
                    "NOMBRES COMPLETOS": "Manuel De Jesus Lopez Orellana",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "lope800835",
                    "USUARIOFIVE9": "lope800835@siigo.com",
                    "USUARIO RED": "lope800835",
                    "CORREOELECTRONICO": "lope800835@siigo.com",
                    "STATION ID": "2605991",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016011497",
                    "FECHA INICIAL": "2021-12-29",
                    "FECHA OPERACION": "2022-01-04",
                    "NOMBRES": "Duvan Alejandro",
                    "APELLIDOS": "Rojas Lasso",
                    "NOMBRES COMPLETOS": "Duvan Alejandro Rojas Lasso",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Yeimy Milena Bustos Gutierrez",
                    "CODIGO USUARIO": "roja802533",
                    "USUARIOFIVE9": "roja802533@siigo.com",
                    "USUARIO RED": "roja802533",
                    "CORREOELECTRONICO": "roja802533@siigo.com",
                    "STATION ID": "3720430",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yeimy Milena Bustos Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "930972799",
                    "FECHA INICIAL": "2022-01-12",
                    "FECHA OPERACION": "2022-01-17",
                    "NOMBRES": "Andres Homero",
                    "APELLIDOS": "Medina Mite",
                    "NOMBRES COMPLETOS": "Andres Homero Medina Mite",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "medi802581",
                    "USUARIOFIVE9": "medi802581@siigo.com",
                    "USUARIO RED": "medi802581",
                    "CORREOELECTRONICO": "medi802581@siigo.com",
                    "STATION ID": "2606590",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "923943120",
                    "FECHA INICIAL": "2022-01-12",
                    "FECHA OPERACION": "2022-01-17",
                    "NOMBRES": "Anthony Fabrizzio",
                    "APELLIDOS": "Pinto Macias",
                    "NOMBRES COMPLETOS": "Anthony Fabrizzio Pinto Macias",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "pint802583",
                    "USUARIOFIVE9": "pint802583@siigo.com",
                    "USUARIO RED": "pint802583",
                    "CORREOELECTRONICO": "pint802583@siigo.com",
                    "STATION ID": "2606650",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80147654",
                    "FECHA INICIAL": "2022-12-16",
                    "FECHA OPERACION": "2022-01-17",
                    "NOMBRES": "Pablo Cesar",
                    "APELLIDOS": "Maldonado Betancourt",
                    "NOMBRES COMPLETOS": "Pablo Cesar Maldonado Betancourt",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Misael Martinez Montero",
                    "CODIGO USUARIO": "mald802446",
                    "USUARIOFIVE9": "mald802446@siigo.com",
                    "USUARIO RED": "mald802446",
                    "CORREOELECTRONICO": "mald802446@siigo.com",
                    "STATION ID": "3690368",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Misael Martinez Montero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1124091619",
                    "FECHA INICIAL": "2021-09-30",
                    "FECHA OPERACION": "2022-01-24",
                    "NOMBRES": "Alberto",
                    "APELLIDOS": "Guerrero Garcia",
                    "NOMBRES COMPLETOS": "Alberto Guerrero Garcia",
                    "PAIS ORIGEN": "Venezuela",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Venezuela",
                    "AREA": "IT",
                    "SUBAREA": "Tecnologia",
                    "CARGO": "IT Team Lead",
                    "JEFE INMEDIATO": "Juan Mauricio Jimenez Espinosa",
                    "CODIGO USUARIO": "guer801983",
                    "USUARIOFIVE9": "guer801983@siigo.com",
                    "USUARIO RED": "guer801983",
                    "CORREOELECTRONICO": "guer801983@siigo.com",
                    "STATION ID": "3127514",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "IT_Juan Mauricio Jimenez Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "23749010",
                    "FECHA INICIAL": "2021-11-30",
                    "FECHA OPERACION": "2022-01-24",
                    "NOMBRES": "Lian Alejandro",
                    "APELLIDOS": "Madriz Oliva",
                    "NOMBRES COMPLETOS": "Lian Alejandro Madriz Oliva",
                    "PAIS ORIGEN": "Venezuela",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Venezuela",
                    "AREA": "Tech",
                    "SUBAREA": "Infraestructura",
                    "CARGO": "Help Desk Analyst Ssr",
                    "JEFE INMEDIATO": "Alberto Guerrero Garcia",
                    "CODIGO USUARIO": "madr802282",
                    "USUARIOFIVE9": "madr802282@siigo.com",
                    "USUARIO RED": "madr802282",
                    "CORREOELECTRONICO": "madr802282@siigo.com",
                    "STATION ID": "3127528",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Alberto Guerrero Garcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "23457306",
                    "FECHA INICIAL": "2021-11-30",
                    "FECHA OPERACION": "2022-01-24",
                    "NOMBRES": "Cesar",
                    "APELLIDOS": "Araujo Basabe",
                    "NOMBRES COMPLETOS": "Cesar Araujo Basabe",
                    "PAIS ORIGEN": "Venezuela",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Venezuela",
                    "AREA": "Tech",
                    "SUBAREA": "Infraestructura",
                    "CARGO": "Help Desk Analyst Ssr",
                    "JEFE INMEDIATO": "Alberto Guerrero Garcia",
                    "CODIGO USUARIO": "arau802287",
                    "USUARIOFIVE9": "arau802287@siigo.com",
                    "USUARIO RED": "arau802287",
                    "CORREOELECTRONICO": "arau802287@siigo.com",
                    "STATION ID": "3127543",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Alberto Guerrero Garcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "16331596",
                    "FECHA INICIAL": "2021-11-30",
                    "FECHA OPERACION": "2022-01-24",
                    "NOMBRES": "Asdrubal Jose",
                    "APELLIDOS": "Polidor Marquez",
                    "NOMBRES COMPLETOS": "Asdrubal Jose Polidor Marquez",
                    "PAIS ORIGEN": "Venezuela",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Venezuela",
                    "AREA": "Tech",
                    "SUBAREA": "Soporte IT",
                    "CARGO": "IT Team Lead",
                    "JEFE INMEDIATO": "Alberto Guerrero Garcia",
                    "CODIGO USUARIO": "poli802286",
                    "USUARIOFIVE9": "poli802286@siigo.com",
                    "USUARIO RED": "poli802286",
                    "CORREOELECTRONICO": "poli802286@siigo.com",
                    "STATION ID": "3267835",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Alberto Guerrero Garcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1061747890",
                    "FECHA INICIAL": "2021-11-30",
                    "FECHA OPERACION": "2022-01-24",
                    "NOMBRES": "Carlos Eduardo",
                    "APELLIDOS": "Bedoya Valencia",
                    "NOMBRES COMPLETOS": "Carlos Eduardo Bedoya Valencia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Popayan",
                    "AREA": "Tech",
                    "SUBAREA": "Infraestructura",
                    "CARGO": "Help Desk Analyst Ssr",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "bedo802290",
                    "USUARIOFIVE9": "bedo802290@siigo.com",
                    "USUARIO RED": "bedo802290",
                    "CORREOELECTRONICO": "bedo802290@siigo.com",
                    "STATION ID": "3267837",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1020784186",
                    "FECHA INICIAL": "2022-01-26",
                    "FECHA OPERACION": "2022-02-01",
                    "NOMBRES": "Leidy Alexandra",
                    "APELLIDOS": "Fernandez Arevalo",
                    "NOMBRES COMPLETOS": "Leidy Alexandra Fernandez Arevalo",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Angel Alfonso Arciniegas Guerrero",
                    "CODIGO USUARIO": "fern802625",
                    "USUARIOFIVE9": "fern802625@siigo.com",
                    "USUARIO RED": "fern802625",
                    "CORREOELECTRONICO": "fern802625@siigo.com",
                    "STATION ID": "2606193",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Angel Alfonso Arciniegas Guerrero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012415324",
                    "FECHA INICIAL": "2022-01-26",
                    "FECHA OPERACION": "2022-02-01",
                    "NOMBRES": "Gissell Yudequy",
                    "APELLIDOS": "Coronado Medina",
                    "NOMBRES COMPLETOS": "Gissell Yudequy Coronado Medina",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Maria Lucia Canales Parrales",
                    "CODIGO USUARIO": "coro802622",
                    "USUARIOFIVE9": "coro802622@siigo.com",
                    "USUARIO RED": "coro802622",
                    "CORREOELECTRONICO": "coro802622@siigo.com",
                    "STATION ID": "2606771",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Maria Lucia Canales Parrales",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "63107465",
                    "FECHA INICIAL": "2022-01-26",
                    "FECHA OPERACION": "2022-02-01",
                    "NOMBRES": "Karla Patricia",
                    "APELLIDOS": "De La Rosa Gracia",
                    "NOMBRES COMPLETOS": "Karla Patricia De La Rosa Gracia",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Luis Ignacio Ferreira Duarte",
                    "CODIGO USUARIO": "dela802619",
                    "USUARIOFIVE9": "dela802619@siigo.com",
                    "USUARIO RED": "dela802619",
                    "CORREOELECTRONICO": "dela802619@siigo.com",
                    "STATION ID": "2606966",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Luis Ignacio Ferreira Duarte",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "39377058",
                    "FECHA INICIAL": "2022-01-26",
                    "FECHA OPERACION": "2022-02-01",
                    "NOMBRES": "Eduardo Adrian",
                    "APELLIDOS": "Espinosa Massa",
                    "NOMBRES COMPLETOS": "Eduardo Adrian Espinosa Massa",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Silvia Zilah Fernandez Almeida",
                    "CODIGO USUARIO": "espi802615",
                    "USUARIOFIVE9": "espi802615@siigo.com",
                    "USUARIO RED": "espi802615",
                    "CORREOELECTRONICO": "espi802615@siigo.com",
                    "STATION ID": "3127502",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Silvia Zilah Fernandez Almeida",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "51736763",
                    "FECHA INICIAL": "2022-01-26",
                    "FECHA OPERACION": "2022-02-01",
                    "NOMBRES": "Marcos Nicolas",
                    "APELLIDOS": "Nunez Clavijo",
                    "NOMBRES COMPLETOS": "Marcos Nicolas Nunez Clavijo",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Luis Ignacio Ferreira Duarte",
                    "CODIGO USUARIO": "nune802617",
                    "USUARIOFIVE9": "nune802617@siigo.com",
                    "USUARIO RED": "nune802617",
                    "CORREOELECTRONICO": "nune802617@siigo.com",
                    "STATION ID": "3127505",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Luis Ignacio Ferreira Duarte",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001185878",
                    "FECHA INICIAL": "2022-01-26",
                    "FECHA OPERACION": "2022-02-01",
                    "NOMBRES": "Kevin David",
                    "APELLIDOS": "Torres Cubillos",
                    "NOMBRES COMPLETOS": "Kevin David Torres Cubillos",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones ECU",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Angie Camila Diaz Escobar",
                    "CODIGO USUARIO": "torr802610",
                    "USUARIOFIVE9": "torr802610@siigo.com",
                    "USUARIO RED": "torr802610",
                    "CORREOELECTRONICO": "torr802610@siigo.com",
                    "STATION ID": "3471493",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Camila Diaz Escobar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007801665",
                    "FECHA INICIAL": "2022-01-26",
                    "FECHA OPERACION": "2022-02-01",
                    "NOMBRES": "Karen Yulieth",
                    "APELLIDOS": "Galindo Cuadrado",
                    "NOMBRES COMPLETOS": "Karen Yulieth Galindo Cuadrado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Villavicencio",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "gali802607",
                    "USUARIOFIVE9": "gali802607@siigo.com",
                    "USUARIO RED": "gali802607",
                    "CORREOELECTRONICO": "gali802607@siigo.com",
                    "STATION ID": "3483736",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1072963456",
                    "FECHA INICIAL": "2022-01-26",
                    "FECHA OPERACION": "2022-02-01",
                    "NOMBRES": "Neidy Vanesa",
                    "APELLIDOS": "Calderon Gomez",
                    "NOMBRES COMPLETOS": "Neidy Vanessa Calderon Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "cald802604",
                    "USUARIOFIVE9": "cald802604@siigo.com",
                    "USUARIO RED": "cald802604",
                    "CORREOELECTRONICO": "cald802604@siigo.com",
                    "STATION ID": "3690244",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52073679",
                    "FECHA INICIAL": "2022-01-25",
                    "FECHA OPERACION": "2022-02-01",
                    "NOMBRES": "Jimena",
                    "APELLIDOS": "Martiarena Schenck",
                    "NOMBRES COMPLETOS": "Jimena Martiarena Schenck",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "Firma Digital UY",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Elias Jusid Machado",
                    "CODIGO USUARIO": "mart802595",
                    "USUARIOFIVE9": "mart802595@siigo.com",
                    "USUARIO RED": "mart802595",
                    "CORREOELECTRONICO": "mart802595@siigo.com",
                    "STATION ID": "3690290",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Elias Jusid Machado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1054863307",
                    "FECHA INICIAL": "2022-02-01",
                    "FECHA OPERACION": "2022-02-03",
                    "NOMBRES": "Luisa Valentina",
                    "APELLIDOS": "Sanchez Quintero",
                    "NOMBRES COMPLETOS": "Luisa Valentina Sanchez Quintero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Manizales",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jessica Paola Paez Quintero",
                    "CODIGO USUARIO": "sanc802639",
                    "USUARIOFIVE9": "sanc802639@siigo.com",
                    "USUARIO RED": "sanc802639",
                    "CORREOELECTRONICO": "sanc802639@siigo.com",
                    "STATION ID": "2605982",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jessica Paola Paez Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015433006",
                    "FECHA INICIAL": "2022-02-01",
                    "FECHA OPERACION": "2022-02-03",
                    "NOMBRES": "Juan Guillermo",
                    "APELLIDOS": "Pineda Garcia",
                    "NOMBRES COMPLETOS": "Juan Guillermo Pineda Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jessica Paola Paez Quintero",
                    "CODIGO USUARIO": "pine802641",
                    "USUARIOFIVE9": "pine802641@siigo.com",
                    "USUARIO RED": "pine802641",
                    "CORREOELECTRONICO": "pine802641@siigo.com",
                    "STATION ID": "2606541",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jessica Paola Paez Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "917842916",
                    "FECHA INICIAL": "2022-02-01",
                    "FECHA OPERACION": "2022-02-03",
                    "NOMBRES": "Raul Alejandro",
                    "APELLIDOS": "Corona Amador",
                    "NOMBRES COMPLETOS": "Raul Alejandro Corona Amador",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Joan Sebastian Rodriguez",
                    "CODIGO USUARIO": "coro802644",
                    "USUARIOFIVE9": "coro802644@siigo.com",
                    "USUARIO RED": "coro802644",
                    "CORREOELECTRONICO": "coro802644@siigo.com",
                    "STATION ID": "2607012",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Joan Sebastian Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "47061813",
                    "FECHA INICIAL": "2022-02-08",
                    "FECHA OPERACION": "2022-02-10",
                    "NOMBRES": "Camila Claren",
                    "APELLIDOS": "Sanguinetti Casas",
                    "NOMBRES COMPLETOS": "Camila Claren Sanguinetti Casas",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "Firma Digital UY",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Elias Jusid Machado",
                    "CODIGO USUARIO": "sang802663",
                    "USUARIOFIVE9": "sang802663@siigo.com",
                    "USUARIO RED": "sang802663",
                    "CORREOELECTRONICO": "sang802663@siigo.com",
                    "STATION ID": "3690357",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Elias Jusid Machado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1726042201",
                    "FECHA INICIAL": "2022-01-12",
                    "FECHA OPERACION": "2022-01-17",
                    "NOMBRES": "Gisella Belen",
                    "APELLIDOS": "Montero Castillo",
                    "NOMBRES COMPLETOS": "Gisella Belen Montero Castillo",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "mont802582",
                    "USUARIOFIVE9": "mont802582@siigo.com",
                    "USUARIO RED": "mont802582",
                    "CORREOELECTRONICO": "mont802582@siigo.com",
                    "STATION ID": "2606968",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "4520197",
                    "FECHA INICIAL": "2022-02-01",
                    "FECHA OPERACION": "2022-02-15",
                    "NOMBRES": "Diego Andres",
                    "APELLIDOS": "Montoya Blandon",
                    "NOMBRES COMPLETOS": "Diego Andres Montoya Blandon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Pereira",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Tech Lead",
                    "JEFE INMEDIATO": "Marcos Damian Lizarraga Elizalde",
                    "CODIGO USUARIO": "mont801257",
                    "USUARIOFIVE9": "mont801257@siigo.com",
                    "USUARIO RED": "mont801257",
                    "CORREOELECTRONICO": "mont801257@siigo.com",
                    "STATION ID": "3720361",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Marcos Damian Lizarraga Elizalde",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018512797",
                    "FECHA INICIAL": "2022-02-09",
                    "FECHA OPERACION": "2022-02-21",
                    "NOMBRES": "Brigithe Vanessa",
                    "APELLIDOS": "Jimenez Roncancio",
                    "NOMBRES COMPLETOS": "Brigithe Vanessa Jimenez Roncancio",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Andrea Catalina Vega Gomez",
                    "CODIGO USUARIO": "jime802665",
                    "USUARIOFIVE9": "jime802665@siigo.com",
                    "USUARIO RED": "jime802665",
                    "CORREOELECTRONICO": "jime802665@siigo.com",
                    "STATION ID": "3690334",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andrea Catalina Vega Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033756888",
                    "FECHA INICIAL": "2022-02-08",
                    "FECHA OPERACION": "2022-02-21",
                    "NOMBRES": "Maria Teresa",
                    "APELLIDOS": "Penuela Roldan",
                    "NOMBRES COMPLETOS": "Maria Teresa Penuela Roldan",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Andrea Catalina Vega Gomez",
                    "CODIGO USUARIO": "penu802664",
                    "USUARIOFIVE9": "penu802664@siigo.com",
                    "USUARIO RED": "penu802664",
                    "CORREOELECTRONICO": "penu802664@siigo.com",
                    "STATION ID": "3690336",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andrea Catalina Vega Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1063960768",
                    "FECHA INICIAL": "2022-02-23",
                    "FECHA OPERACION": "2022-03-01",
                    "NOMBRES": "Zamira Lucia",
                    "APELLIDOS": "Reyes Carreno",
                    "NOMBRES COMPLETOS": "Zamira Lucia Reyes Carreno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bosconia",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "reye802694",
                    "USUARIOFIVE9": "reye802694@siigo.com",
                    "USUARIO RED": "reye802694",
                    "CORREOELECTRONICO": "reye802694@siigo.com",
                    "STATION ID": "2606149",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "29231944",
                    "FECHA INICIAL": "2022-02-23",
                    "FECHA OPERACION": "2022-03-01",
                    "NOMBRES": "Sandra Milena",
                    "APELLIDOS": "Arboleda Moreno",
                    "NOMBRES COMPLETOS": "Sandra Milena Arboleda Moreno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "arbo802695",
                    "USUARIOFIVE9": "arbo802695@siigo.com",
                    "USUARIO RED": "arbo802695",
                    "CORREOELECTRONICO": "arbo802695@siigo.com",
                    "STATION ID": "2606287",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1144197697",
                    "FECHA INICIAL": "2022-02-23",
                    "FECHA OPERACION": "2022-03-01",
                    "NOMBRES": "Estefanni",
                    "APELLIDOS": "Salazar Rey",
                    "NOMBRES COMPLETOS": "Estefanni Salazar Rey",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alix Dayana Archila Castillo",
                    "CODIGO USUARIO": "sala802693",
                    "USUARIOFIVE9": "sala802693@siigo.com",
                    "USUARIO RED": "sala802693",
                    "CORREOELECTRONICO": "sala802693@siigo.com",
                    "STATION ID": "2606370",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alix Dayana Archila Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001945193",
                    "FECHA INICIAL": "2022-02-23",
                    "FECHA OPERACION": "2022-03-01",
                    "NOMBRES": "Nayelis Del Carmen",
                    "APELLIDOS": "Padilla Peralta",
                    "NOMBRES COMPLETOS": "Nayelis Del Carmen Padilla Peralta",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "padi802692",
                    "USUARIOFIVE9": "padi802692@siigo.com",
                    "USUARIO RED": "padi802692",
                    "CORREOELECTRONICO": "padi802692@siigo.com",
                    "STATION ID": "2606544",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1051419061",
                    "FECHA INICIAL": "2022-02-23",
                    "FECHA OPERACION": "2022-03-01",
                    "NOMBRES": "Yenitir",
                    "APELLIDOS": "Guzman Bulfor",
                    "NOMBRES COMPLETOS": "Yenitir Guzman Bulfor",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Patricia Villarraga Forero",
                    "CODIGO USUARIO": "guzm802691",
                    "USUARIOFIVE9": "guzm802691@siigo.com",
                    "USUARIO RED": "guzm802691",
                    "CORREOELECTRONICO": "guzm802691@siigo.com",
                    "STATION ID": "2606643",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Patricia Villarraga Forero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1052395514",
                    "FECHA INICIAL": "2022-02-22",
                    "FECHA OPERACION": "2022-03-01",
                    "NOMBRES": "Jessica Marcela",
                    "APELLIDOS": "Zea Ballesteros",
                    "NOMBRES COMPLETOS": "Jessica Marcela Zea Ballesteros",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "zeab802684",
                    "USUARIOFIVE9": "zeab802684@siigo.com",
                    "USUARIO RED": "zeab802684",
                    "CORREOELECTRONICO": "zeab802684@siigo.com",
                    "STATION ID": "2606783",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1233191728",
                    "FECHA INICIAL": "2022-02-22",
                    "FECHA OPERACION": "2022-03-01",
                    "NOMBRES": "Yuliana Maritza",
                    "APELLIDOS": "Mera Jossa",
                    "NOMBRES COMPLETOS": "Yuliana Maritza Mera Jossa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Pasto",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alix Dayana Archila Castillo",
                    "CODIGO USUARIO": "meza802675",
                    "USUARIOFIVE9": "meza802675@siigo.com",
                    "USUARIO RED": "meza802675",
                    "CORREOELECTRONICO": "meza802675@siigo.com",
                    "STATION ID": "2606878",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alix Dayana Archila Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1085258673",
                    "FECHA INICIAL": "2022-02-22",
                    "FECHA OPERACION": "2022-03-01",
                    "NOMBRES": "Lady Katherine",
                    "APELLIDOS": "Eraso Josa",
                    "NOMBRES COMPLETOS": "Lady Katherine Eraso Josa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Pasto",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alix Dayana Archila Castillo",
                    "CODIGO USUARIO": "eras802676",
                    "USUARIOFIVE9": "eras802676@siigo.com",
                    "USUARIO RED": "eras802676",
                    "CORREOELECTRONICO": "eras802676@siigo.com",
                    "STATION ID": "2606912",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alix Dayana Archila Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1085322144",
                    "FECHA INICIAL": "2022-02-22",
                    "FECHA OPERACION": "2022-03-01",
                    "NOMBRES": "Jose Roberto",
                    "APELLIDOS": "Yepes Andrade",
                    "NOMBRES COMPLETOS": "Jose Roberto Yepes Andrade",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Pasto",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Patricia Villarraga Forero",
                    "CODIGO USUARIO": "yepe802677",
                    "USUARIOFIVE9": "yepe802677@siigo.com",
                    "USUARIO RED": "yepe802677",
                    "CORREOELECTRONICO": "yepe802677@siigo.com",
                    "STATION ID": "2606943",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Patricia Villarraga Forero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000328214",
                    "FECHA INICIAL": "2022-02-22",
                    "FECHA OPERACION": "2022-03-01",
                    "NOMBRES": "Ingry Vanessa",
                    "APELLIDOS": "Beltran Medina",
                    "NOMBRES COMPLETOS": "Ingry Vanessa Beltran Medina",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Claudia Marcela Leon Cerinza",
                    "CODIGO USUARIO": "belt802681",
                    "USUARIOFIVE9": "belt802681@siigo.com",
                    "USUARIO RED": "belt802681",
                    "CORREOELECTRONICO": "belt802681@siigo.com",
                    "STATION ID": "2606959",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Claudia Marcela Leon Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014274359",
                    "FECHA INICIAL": "2022-02-22",
                    "FECHA OPERACION": "2022-03-01",
                    "NOMBRES": "Carla Yojana",
                    "APELLIDOS": "Charry Rincon",
                    "NOMBRES COMPLETOS": "Carla Yojana Charry Rincon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alix Dayana Archila Castillo",
                    "CODIGO USUARIO": "char802682",
                    "USUARIOFIVE9": "char802682@siigo.com",
                    "USUARIO RED": "char802682",
                    "CORREOELECTRONICO": "char802682@siigo.com",
                    "STATION ID": "2607041",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alix Dayana Archila Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "63560984",
                    "FECHA INICIAL": "2022-02-22",
                    "FECHA OPERACION": "2022-03-01",
                    "NOMBRES": "Diana Mayerli",
                    "APELLIDOS": "Gomez Esparza",
                    "NOMBRES COMPLETOS": "Diana Mayerli Gomez Esparza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Giron",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Sory Yaqueline Abello Polo",
                    "CODIGO USUARIO": "gome802680",
                    "USUARIOFIVE9": "gome802680@siigo.com",
                    "USUARIO RED": "gome802680",
                    "CORREOELECTRONICO": "gome802680@siigo.com",
                    "STATION ID": "2607042",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sory Yaqueline Abello Polo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1078371731",
                    "FECHA INICIAL": "2022-02-28",
                    "FECHA OPERACION": "2022-03-04",
                    "NOMBRES": "Lina Maria",
                    "APELLIDOS": "Pena Canon",
                    "NOMBRES COMPLETOS": "Lina Maria Pena Canon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "pena802700",
                    "USUARIOFIVE9": "pena802700@siigo.com",
                    "USUARIO RED": "pena802700",
                    "CORREOELECTRONICO": "pena802700@siigo.com",
                    "STATION ID": "3127536",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016592615",
                    "FECHA INICIAL": "2022-02-28",
                    "FECHA OPERACION": "2022-03-04",
                    "NOMBRES": "Kimberly Yagara",
                    "APELLIDOS": "Zapata Ramirez",
                    "NOMBRES COMPLETOS": "Kimberly Yagara Zapata Ramirez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "zapa802701",
                    "USUARIOFIVE9": "zapa802701@siigo.com",
                    "USUARIO RED": "zapa802701",
                    "CORREOELECTRONICO": "zapa802701@siigo.com",
                    "STATION ID": "3127581",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1151940172",
                    "FECHA INICIAL": "2022-03-01",
                    "FECHA OPERACION": "2022-03-04",
                    "NOMBRES": "Ana Maria",
                    "APELLIDOS": "Quijano",
                    "NOMBRES COMPLETOS": "Ana Maria Quijano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Experience Manager",
                    "JEFE INMEDIATO": "Pedro Felipe Arana Lastra",
                    "CODIGO USUARIO": "quij802707",
                    "USUARIOFIVE9": "quij802707@siigo.com",
                    "USUARIO RED": "quij802707",
                    "CORREOELECTRONICO": "quij802707@siigo.com",
                    "STATION ID": "3267844",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Pedro Felipe Arana Lastra",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53070623",
                    "FECHA INICIAL": "2022-03-01",
                    "FECHA OPERACION": "2022-03-04",
                    "NOMBRES": "Catalina",
                    "APELLIDOS": "Rodriguez Convers",
                    "NOMBRES COMPLETOS": "Catalina Rodriguez Convers",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Ximena Manrique Isaziga",
                    "CODIGO USUARIO": "rodr802708",
                    "USUARIOFIVE9": "rodr802708@siigo.com",
                    "USUARIO RED": "rodr802708",
                    "CORREOELECTRONICO": "rodr802708@siigo.com",
                    "STATION ID": "3267859",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Ximena Manrique Isaziga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "JOSM930108U76",
                    "FECHA INICIAL": "2022-04-28",
                    "FECHA OPERACION": "2022-05-03",
                    "NOMBRES": "Mayte Guadalupe",
                    "APELLIDOS": "Joaquin Sanchez",
                    "NOMBRES COMPLETOS": "Mayte Guadalupe Joaquin Sanchez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "ASD MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Alejandro Salgado Vergara",
                    "CODIGO USUARIO": "mjoaquin",
                    "USUARIOFIVE9": "mjoaquin@aspel.com.mx",
                    "USUARIO RED": "mjoaquin",
                    "CORREOELECTRONICO": "mjoaquin@aspel.com.mx",
                    "STATION ID": "4036374",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Alejandro Salgado Vergara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014189772",
                    "FECHA INICIAL": "2022-03-01",
                    "FECHA OPERACION": "2022-03-04",
                    "NOMBRES": "Edwin Ricardo",
                    "APELLIDOS": "Rincon Ortega",
                    "NOMBRES COMPLETOS": "Edwin Ricardo Rincon Ortega",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Paula Alejandra Garcia Lujan",
                    "CODIGO USUARIO": "rinc802714",
                    "USUARIOFIVE9": "rinc802714@siigo.com",
                    "USUARIO RED": "rinc802714",
                    "CORREOELECTRONICO": "rinc802714@siigo.com",
                    "STATION ID": "3267871",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Paula Alejandra Garcia Lujan",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "953846029",
                    "FECHA INICIAL": "2022-01-12",
                    "FECHA OPERACION": "2022-01-17",
                    "NOMBRES": "Roxana De Jesus",
                    "APELLIDOS": "Franco Peralta",
                    "NOMBRES COMPLETOS": "Roxana De Jesus Franco Peralta",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "fran802580",
                    "USUARIOFIVE9": "fran802580@siigo.com",
                    "USUARIO RED": "fran802580",
                    "CORREOELECTRONICO": "fran802580@siigo.com",
                    "STATION ID": "2607039",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MAGB981031UR0",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-27",
                    "NOMBRES": "Brayan Enrique",
                    "APELLIDOS": "Manjarrez Gomez",
                    "NOMBRES COMPLETOS": "Brayan Enrique Manjarrez Gomez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Cristian Camilo Hernandez Gonzalez",
                    "CODIGO USUARIO": "bmanjarrez",
                    "USUARIOFIVE9": "bmanjarrez@aspel.com.mx",
                    "USUARIO RED": "bmanjarrez",
                    "CORREOELECTRONICO": "bmanjarrez@aspel.com.mx",
                    "STATION ID": "3763905",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Cristian Camilo Hernandez Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1721496147",
                    "FECHA INICIAL": "2022-03-03",
                    "FECHA OPERACION": "2022-03-04",
                    "NOMBRES": "Gina Gabriela",
                    "APELLIDOS": "Morales Montufar",
                    "NOMBRES COMPLETOS": "Gina Gabriela Morales Montufar",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "mora802728",
                    "USUARIOFIVE9": "mora802728@siigo.com",
                    "USUARIO RED": "mora802728",
                    "CORREOELECTRONICO": "mora802728@siigo.com",
                    "STATION ID": "3267891",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016011912",
                    "FECHA INICIAL": "2022-03-02",
                    "FECHA OPERACION": "2022-03-04",
                    "NOMBRES": "Leidy Marcela",
                    "APELLIDOS": "Rodriguez Rivera",
                    "NOMBRES COMPLETOS": "Leidy Marcela Rodriguez Rivera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Hollman Antonio Aponte Quiroga",
                    "CODIGO USUARIO": "rodr802722",
                    "USUARIOFIVE9": "rodr802722@siigo.com",
                    "USUARIO RED": "rodr802722",
                    "CORREOELECTRONICO": "rodr802722@siigo.com",
                    "STATION ID": "3690332",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Hollman Antonio Aponte Quiroga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "35893800",
                    "FECHA INICIAL": "2022-03-01",
                    "FECHA OPERACION": "2022-03-04",
                    "NOMBRES": "Luis Ignacio",
                    "APELLIDOS": "Rinon Martinez",
                    "NOMBRES COMPLETOS": "Luis Ignacio Rinon Martinez",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Luis Ignacio Ferreira Duarte",
                    "CODIGO USUARIO": "rino802704",
                    "USUARIOFIVE9": "rino802704@siigo.com",
                    "USUARIO RED": "rino802704",
                    "CORREOELECTRONICO": "rino802704@siigo.com",
                    "STATION ID": "3690371",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Luis Ignacio Ferreira Duarte",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022420657",
                    "FECHA INICIAL": "2022-03-01",
                    "FECHA OPERACION": "2022-03-04",
                    "NOMBRES": "Luisa Fernanda",
                    "APELLIDOS": "Cancelado Baron",
                    "NOMBRES COMPLETOS": "Luisa Fernanda Cancelado Baron",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Cristhian David Nino Barrera",
                    "CODIGO USUARIO": "canc802706",
                    "USUARIOFIVE9": "canc802706@siigo.com",
                    "USUARIO RED": "canc802706",
                    "CORREOELECTRONICO": "canc802706@siigo.com",
                    "STATION ID": "3690373",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Cristhian David Nino Barrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1095804904",
                    "FECHA INICIAL": "2022-03-02",
                    "FECHA OPERACION": "2022-03-04",
                    "NOMBRES": "Monica Rocio",
                    "APELLIDOS": "Parra Solano",
                    "NOMBRES COMPLETOS": "Monica Rocio Parra Solano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Paula Alejandra Garcia Lujan",
                    "CODIGO USUARIO": "parr802718",
                    "USUARIOFIVE9": "parr802718@siigo.com",
                    "USUARIO RED": "parr802718",
                    "CORREOELECTRONICO": "parr802718@siigo.com",
                    "STATION ID": "3763829",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Paula Alejandra Garcia Lujan",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022929477",
                    "FECHA INICIAL": "2022-03-02",
                    "FECHA OPERACION": "2022-03-08",
                    "NOMBRES": "Sofia",
                    "APELLIDOS": "Cruz Fernandez",
                    "NOMBRES COMPLETOS": "Sofia Cruz Fernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Yeira",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Aleida Bibiana Garcia Suarez",
                    "CODIGO USUARIO": "cruz802719",
                    "USUARIOFIVE9": "cruz802719@siigo.com",
                    "USUARIO RED": "cruz802719",
                    "CORREOELECTRONICO": "cruz802719@siigo.com",
                    "STATION ID": "3267893",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Aleida Bibiana Garcia Suarez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "GOGM960830A15",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-11",
                    "NOMBRES": "Martin",
                    "APELLIDOS": "Gonzalez Gonzalez",
                    "NOMBRES COMPLETOS": "Martin Gonzalez Gonzalez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "ASD MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Victor Hugo Huerta Lopez",
                    "CODIGO USUARIO": "mgonzalez",
                    "USUARIOFIVE9": "mgonzalez@aspel.com.mx",
                    "USUARIO RED": "mgonzalez",
                    "CORREOELECTRONICO": "mgonzalez@aspel.com.mx",
                    "STATION ID": "4421827",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Victor Hugo Huerta Lopez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "AULC951107F82",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-17",
                    "NOMBRES": "Carlos Rodrigo",
                    "APELLIDOS": "Aguilar Lopez",
                    "NOMBRES COMPLETOS": "Carlos Rodrigo Aguilar Lopez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Rene Gerardo Paredes Alcantara",
                    "CODIGO USUARIO": "raguilar",
                    "USUARIOFIVE9": "raguilar@aspel.com.mx",
                    "USUARIO RED": "raguilar",
                    "CORREOELECTRONICO": "raguilar@aspel.com.mx",
                    "STATION ID": "4421838",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Rene Gerardo Paredes Alcantara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1067939606",
                    "FECHA INICIAL": "2022-03-10",
                    "FECHA OPERACION": "2022-03-14",
                    "NOMBRES": "Nancy Luz",
                    "APELLIDOS": "Jarava Navarro",
                    "NOMBRES COMPLETOS": "Nancy Luz Jarava Navarro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Monteria",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Walter Antonio Munoz Antepara",
                    "CODIGO USUARIO": "jara802783",
                    "USUARIOFIVE9": "jara802783@siigo.com",
                    "USUARIO RED": "jara802783",
                    "CORREOELECTRONICO": "jara802783@siigo.com",
                    "STATION ID": "2606306",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Walter Antonio Munoz Antepara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "37550900",
                    "FECHA INICIAL": "2022-03-10",
                    "FECHA OPERACION": "2022-03-14",
                    "NOMBRES": "Deicy Dayana",
                    "APELLIDOS": "Rueda Gomez",
                    "NOMBRES COMPLETOS": "Deicy Dayana Rueda Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Giron",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnicos",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Anderson Cubillos Ayala",
                    "CODIGO USUARIO": "rued802780",
                    "USUARIOFIVE9": "rued802780@siigo.com",
                    "USUARIO RED": "rued802780",
                    "CORREOELECTRONICO": "rued802780@siigo.com",
                    "STATION ID": "2606423",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Anderson Cubillos Ayala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "COMO8712289N0",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jose Osvaldo",
                    "APELLIDOS": "Cortez Martinez",
                    "NOMBRES COMPLETOS": "Jose Osvaldo Cortez Martinez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Experto MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adair Blanco Landin",
                    "CODIGO USUARIO": "jcortez",
                    "USUARIOFIVE9": "jcortez@aspel.com.mx",
                    "USUARIO RED": "jcortez",
                    "CORREOELECTRONICO": "jcortez@aspel.com.mx",
                    "STATION ID": "3763897",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adair Blanco Landin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1107520695",
                    "FECHA INICIAL": "2022-03-18",
                    "FECHA OPERACION": "2022-03-24",
                    "NOMBRES": "Laura Vanessa",
                    "APELLIDOS": "Andrade Hurtado",
                    "NOMBRES COMPLETOS": "Laura Vanessa Andrade Hurtado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Pasto",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Colombia",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Olga Lucia Ospina Vidal",
                    "CODIGO USUARIO": "andr802812",
                    "USUARIOFIVE9": "andr802812@siigo.com",
                    "USUARIO RED": "andr802812",
                    "CORREOELECTRONICO": "andr802812@siigo.com",
                    "STATION ID": "3720473",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Olga Lucia Ospina Vidal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019042647",
                    "FECHA INICIAL": "2022-03-10",
                    "FECHA OPERACION": "2022-03-14",
                    "NOMBRES": "Susan Patricia",
                    "APELLIDOS": "Ontibon Pineda",
                    "NOMBRES COMPLETOS": "Susan Patricia Ontibon Pineda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alison Anaid Chica Ezeta",
                    "CODIGO USUARIO": "onti802769",
                    "USUARIOFIVE9": "onti802769@siigo.com",
                    "USUARIO RED": "onti802769",
                    "CORREOELECTRONICO": "onti802769@siigo.com",
                    "STATION ID": "2607091",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Alison Anaid Chica Ezeta",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023006018",
                    "FECHA INICIAL": "2022-03-09",
                    "FECHA OPERACION": "2022-03-14",
                    "NOMBRES": "Maria Alejandra",
                    "APELLIDOS": "Munoz Giraldo",
                    "NOMBRES COMPLETOS": "Maria Alejandra Munoz Giraldo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Andres Felipe Baron Ballen",
                    "CODIGO USUARIO": "muno802759",
                    "USUARIOFIVE9": "muno802759@siigo.com",
                    "USUARIO RED": "muno802759",
                    "CORREOELECTRONICO": "muno802759@siigo.com",
                    "STATION ID": "3483698",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andres Felipe Baron Ballen",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "BACA8712137C0",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Ayax",
                    "APELLIDOS": "Barrios Contreras",
                    "NOMBRES COMPLETOS": "Ayax Barrios Contreras",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Experto MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adair Blanco Landin",
                    "CODIGO USUARIO": "abarrios",
                    "USUARIOFIVE9": "abarrios@aspel.com.mx",
                    "USUARIO RED": "abarrios",
                    "CORREOELECTRONICO": "abarrios@aspel.com.mx",
                    "STATION ID": "3763923",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adair Blanco Landin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1044422128",
                    "FECHA INICIAL": "2022-03-10",
                    "FECHA OPERACION": "2022-03-14",
                    "NOMBRES": "Angie Paola",
                    "APELLIDOS": "Macias Corro",
                    "NOMBRES COMPLETOS": "Angie Paola Macias Corro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Puerto Colombia",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "maci802775",
                    "USUARIOFIVE9": "maci802775@siigo.com",
                    "USUARIO RED": "maci802775",
                    "CORREOELECTRONICO": "maci802775@siigo.com",
                    "STATION ID": "2606718",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80800244",
                    "FECHA INICIAL": "2022-03-09",
                    "FECHA OPERACION": "2022-03-14",
                    "NOMBRES": "Harold Arley",
                    "APELLIDOS": "Amaya Monroy",
                    "NOMBRES COMPLETOS": "Harold Arley Amaya Monroy",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Andres Felipe Baron Ballen",
                    "CODIGO USUARIO": "amay802758",
                    "USUARIOFIVE9": "amay802758@siigo.com",
                    "USUARIO RED": "amay802758",
                    "CORREOELECTRONICO": "amay802758@siigo.com",
                    "STATION ID": "3720417",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andres Felipe Baron Ballen",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1083553263",
                    "FECHA INICIAL": "2022-03-11",
                    "FECHA OPERACION": "2022-03-24",
                    "NOMBRES": "Eduardo Luis",
                    "APELLIDOS": "Gamero Villegas",
                    "NOMBRES COMPLETOS": "Eduardo Luis Gamero Villegas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Cienaga",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "game802791",
                    "USUARIOFIVE9": "game802791@siigo.com",
                    "USUARIO RED": "game802791",
                    "CORREOELECTRONICO": "game802791@siigo.com",
                    "STATION ID": "3720461",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018413653",
                    "FECHA INICIAL": "2022-03-09",
                    "FECHA OPERACION": "2022-03-14",
                    "NOMBRES": "Karol Viviana",
                    "APELLIDOS": "Quiroga Olarte",
                    "NOMBRES COMPLETOS": "Karol Viviana Quiroga Olarte",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "David Alfredo Sanchez Bastidas",
                    "CODIGO USUARIO": "quir802752",
                    "USUARIOFIVE9": "quir802752@siigo.com",
                    "USUARIO RED": "quir802752",
                    "CORREOELECTRONICO": "quir802752@siigo.com",
                    "STATION ID": "3720439",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_David Alfredo Sanchez Bastidas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52984021",
                    "FECHA INICIAL": "2022-03-09",
                    "FECHA OPERACION": "2022-03-14",
                    "NOMBRES": "Jeymi Astrid",
                    "APELLIDOS": "Rodriguez Bernal",
                    "NOMBRES COMPLETOS": "Jeymi Astrid Rodriguez Bernal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "David Alfredo Sanchez Bastidas",
                    "CODIGO USUARIO": "rodr802751",
                    "USUARIOFIVE9": "rodr802751@siigo.com",
                    "USUARIO RED": "rodr802751",
                    "CORREOELECTRONICO": "rodr802751@siigo.com",
                    "STATION ID": "3720441",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_David Alfredo Sanchez Bastidas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007380880",
                    "FECHA INICIAL": "2022-03-09",
                    "FECHA OPERACION": "2022-03-14",
                    "NOMBRES": "Jairo Eduardo",
                    "APELLIDOS": "Quitian Figueroa",
                    "NOMBRES COMPLETOS": "Jairo Eduardo Quitian Figueroa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "David Alfredo Sanchez Bastidas",
                    "CODIGO USUARIO": "quit802747",
                    "USUARIOFIVE9": "quit802747@siigo.com",
                    "USUARIO RED": "quit802747",
                    "CORREOELECTRONICO": "quit802747@siigo.com",
                    "STATION ID": "3720452",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_David Alfredo Sanchez Bastidas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1020809148",
                    "FECHA INICIAL": "2020-12-30",
                    "FECHA OPERACION": "2022-03-17",
                    "NOMBRES": "Andres Felipe",
                    "APELLIDOS": "Gonzalez Urrego",
                    "NOMBRES COMPLETOS": "Andres Felipe Gonzalez Urrego",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Estrategia",
                    "SUBAREA": "Estrategia",
                    "CARGO": "Partnerships Analyst",
                    "JEFE INMEDIATO": "Diana Carolina Perez Cerinza",
                    "CODIGO USUARIO": "gonz801015",
                    "USUARIOFIVE9": "gonz801015@siigo.com",
                    "USUARIO RED": "gonz801015",
                    "CORREOELECTRONICO": "gonz801015@siigo.com",
                    "STATION ID": "2606056",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Estrategia_Diana Carolina Perez Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019144621",
                    "FECHA INICIAL": "2020-11-05",
                    "FECHA OPERACION": "2022-03-17",
                    "NOMBRES": "Sebastian",
                    "APELLIDOS": "Cardona Perez",
                    "NOMBRES COMPLETOS": "Sebastian Cardona Perez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Estrategia",
                    "SUBAREA": "Estrategia",
                    "CARGO": "Partnerships Analyst",
                    "JEFE INMEDIATO": "Diana Carolina Perez Cerinza",
                    "CODIGO USUARIO": "card802197",
                    "USUARIOFIVE9": "card802197@siigo.com",
                    "USUARIO RED": "card802197",
                    "CORREOELECTRONICO": "card802197@siigo.com",
                    "STATION ID": "2606099",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Estrategia_Diana Carolina Perez Cerinza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007552616",
                    "FECHA INICIAL": "2022-03-10",
                    "FECHA OPERACION": "2022-03-14",
                    "NOMBRES": "Dionicio",
                    "APELLIDOS": "Pacheco Ibarra",
                    "NOMBRES COMPLETOS": "Dionicio Pacheco Ibarra",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cucuta",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adrian Alberto Salom Berrio",
                    "CODIGO USUARIO": "pach802774",
                    "USUARIOFIVE9": "pach802774@siigo.com",
                    "USUARIO RED": "pach802774",
                    "CORREOELECTRONICO": "pach802774@siigo.com",
                    "STATION ID": "2606736",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adrian Alberto Salom Berrio",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1085264472",
                    "FECHA INICIAL": "2022-03-18",
                    "FECHA OPERACION": "2022-03-24",
                    "NOMBRES": "Jaime Daniel",
                    "APELLIDOS": "Ceron Rosales",
                    "NOMBRES COMPLETOS": "Jaime Daniel Ceron Rosales",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Pasto",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnicos",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Anderson Cubillos Ayala",
                    "CODIGO USUARIO": "cero802809",
                    "USUARIOFIVE9": "cero802809@siigo.com",
                    "USUARIO RED": "cero802809",
                    "CORREOELECTRONICO": "cero802809@siigo.com",
                    "STATION ID": "3720470",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Anderson Cubillos Ayala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012392990",
                    "FECHA INICIAL": "2022-03-18",
                    "FECHA OPERACION": "2022-03-24",
                    "NOMBRES": "Luis Angel",
                    "APELLIDOS": "Bedoya Parra",
                    "NOMBRES COMPLETOS": "Luis Angel Bedoya Parra",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Proyecto FE",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alix Dayana Archila Castillo",
                    "CODIGO USUARIO": "bedo802816",
                    "USUARIOFIVE9": "bedo802816@siigo.com",
                    "USUARIO RED": "bedo802816",
                    "CORREOELECTRONICO": "bedo802816@siigo.com",
                    "STATION ID": "3720477",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alix Dayana Archila Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1113685444",
                    "FECHA INICIAL": "2022-03-09",
                    "FECHA OPERACION": "2022-03-14",
                    "NOMBRES": "Brayan David",
                    "APELLIDOS": "Molina Castaneda",
                    "NOMBRES COMPLETOS": "Brayan David Molina Castaneda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Palmira",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Nicoll Torres Celemin",
                    "CODIGO USUARIO": "moli802753",
                    "USUARIOFIVE9": "moli802753@siigo.com",
                    "USUARIO RED": "moli802753",
                    "CORREOELECTRONICO": "moli802753@siigo.com",
                    "STATION ID": "3720433",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Nicoll Torres Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1120505510",
                    "FECHA INICIAL": "2022-03-29",
                    "FECHA OPERACION": "2022-04-05",
                    "NOMBRES": "Leidi Jimena",
                    "APELLIDOS": "Rodriguez Gonzalez",
                    "NOMBRES COMPLETOS": "Leidi Jimena Rodriguez Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Andrea Catalina Vega Gomez",
                    "CODIGO USUARIO": "rodr802830",
                    "USUARIOFIVE9": "rodr802830@siigo.com",
                    "USUARIO RED": "rodr802830",
                    "CORREOELECTRONICO": "rodr802830@siigo.com",
                    "STATION ID": "2606142",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andrea Catalina Vega Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032937504",
                    "FECHA INICIAL": "2022-03-29",
                    "FECHA OPERACION": "2022-04-05",
                    "NOMBRES": "Ana Valentina",
                    "APELLIDOS": "Leon Salas",
                    "NOMBRES COMPLETOS": "Ana Valentina Leon Salas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones Uy",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "leon802833",
                    "USUARIOFIVE9": "leon802833@siigo.com",
                    "USUARIO RED": "leon802833",
                    "CORREOELECTRONICO": "leon802833@siigo.com",
                    "STATION ID": "2606163",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016105774",
                    "FECHA INICIAL": "2022-01-12",
                    "FECHA OPERACION": "2022-01-17",
                    "NOMBRES": "Hellen Gabriela",
                    "APELLIDOS": "Gallo Charry",
                    "NOMBRES COMPLETOS": "Hellen Gabriela Gallo Charry",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Yeimy Milena Bustos Gutierrez",
                    "CODIGO USUARIO": "gall802578",
                    "USUARIOFIVE9": "gall802578@siigo.com",
                    "USUARIO RED": "gall802578",
                    "CORREOELECTRONICO": "gall802578@siigo.com",
                    "STATION ID": "2607026",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yeimy Milena Bustos Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1003689867",
                    "FECHA INICIAL": "2022-04-12",
                    "FECHA OPERACION": "2022-04-21",
                    "NOMBRES": "Maria Camila",
                    "APELLIDOS": "Munoz Araujo",
                    "NOMBRES COMPLETOS": "Maria Camila Munoz Araujo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cultura",
                    "SUBAREA": "Cultura",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Ivon Andrea Rios Rodriguez",
                    "CODIGO USUARIO": "muno802574",
                    "USUARIOFIVE9": "muno802574@siigo.com",
                    "USUARIO RED": "muno802574",
                    "CORREOELECTRONICO": "muno802574@siigo.com",
                    "STATION ID": "2605985",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cultura_Ivon Andrea Rios Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032477577",
                    "FECHA INICIAL": "2022-04-12",
                    "FECHA OPERACION": "2022-04-21",
                    "NOMBRES": "Nicolas",
                    "APELLIDOS": "Avendano Romero",
                    "NOMBRES COMPLETOS": "Nicolas Avendano Romero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jesus Daniel Arzuza Roman",
                    "CODIGO USUARIO": "aven802861",
                    "USUARIOFIVE9": "aven802861@siigo.com",
                    "USUARIO RED": "aven802861",
                    "CORREOELECTRONICO": "aven802861@siigo.com",
                    "STATION ID": "2606790",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jesus Daniel Arzuza Roman",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1102576071",
                    "FECHA INICIAL": "2022-04-12",
                    "FECHA OPERACION": "2022-04-21",
                    "NOMBRES": "Anselmo Jose",
                    "APELLIDOS": "Yepes Jaraba",
                    "NOMBRES COMPLETOS": "Anselmo Jose Yepes Jaraba",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gustavo Adolfo Espinosa Toledo",
                    "CODIGO USUARIO": "yepe802892",
                    "USUARIOFIVE9": "yepe802892@siigo.com",
                    "USUARIO RED": "yepe802892",
                    "CORREOELECTRONICO": "yepe802892@siigo.com",
                    "STATION ID": "2605965",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gustavo Adolfo Espinosa Toledo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016015233",
                    "FECHA INICIAL": "2022-04-12",
                    "FECHA OPERACION": "2022-04-21",
                    "NOMBRES": "Luisa Fernanda",
                    "APELLIDOS": "Lopez Pinto",
                    "NOMBRES COMPLETOS": "Luisa Fernanda Lopez Pinto",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Vicky Alejandra Hernandez Mendez",
                    "CODIGO USUARIO": "lope802890",
                    "USUARIOFIVE9": "lope802890@siigo.com",
                    "USUARIO RED": "lope802890",
                    "CORREOELECTRONICO": "lope802890@siigo.com",
                    "STATION ID": "2606960",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Vicky Alejandra Hernandez Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1020795744",
                    "FECHA INICIAL": "2022-04-12",
                    "FECHA OPERACION": "2022-04-21",
                    "NOMBRES": "Angie Tatiana",
                    "APELLIDOS": "Esquinas Alfonso",
                    "NOMBRES COMPLETOS": "Angie Tatiana Esquinas Alfonso",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Mercadeo",
                    "CARGO": "Social Media Jr",
                    "JEFE INMEDIATO": "Giovanny Andres Perez Lopez",
                    "CODIGO USUARIO": "esqu802893",
                    "USUARIOFIVE9": "esqu802893@siigo.com",
                    "USUARIO RED": "esqu802893",
                    "CORREOELECTRONICO": "esqu802893@siigo.com",
                    "STATION ID": "3127471",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Giovanny Andres Perez Lopez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010242107",
                    "FECHA INICIAL": "2022-04-20",
                    "FECHA OPERACION": "2022-04-25",
                    "NOMBRES": "Nijhireth Dagnimanegzy",
                    "APELLIDOS": "Martinez Campos",
                    "NOMBRES COMPLETOS": "Nijhireth Dagnimanegzy Martinez Campos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Yeimy Milena Bustos Gutierrez",
                    "CODIGO USUARIO": "mart801732",
                    "USUARIOFIVE9": "mart801732@siigo.com",
                    "USUARIO RED": "mart801732",
                    "CORREOELECTRONICO": "mart801732@siigo.com",
                    "STATION ID": "3127567",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yeimy Milena Bustos Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032472219",
                    "FECHA INICIAL": "2022-04-12",
                    "FECHA OPERACION": "2022-04-25",
                    "NOMBRES": "Ivon Camila",
                    "APELLIDOS": "Marin Ramirez",
                    "NOMBRES COMPLETOS": "Ivon Camila Marin Ramirez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Buitrago Rios",
                    "CODIGO USUARIO": "mari802891",
                    "USUARIOFIVE9": "mari802891@siigo.com",
                    "USUARIO RED": "mari802891",
                    "CORREOELECTRONICO": "mari802891@siigo.com",
                    "STATION ID": "3127477",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Buitrago Rios",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1002255914",
                    "FECHA INICIAL": "2022-04-12",
                    "FECHA OPERACION": "2022-04-25",
                    "NOMBRES": "Wendy Johana",
                    "APELLIDOS": "Tafur Fonseca",
                    "NOMBRES COMPLETOS": "Wendy Johana Tafur Fonseca",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Silvia Zilah Fernandez Almeida",
                    "CODIGO USUARIO": "tafu802887",
                    "USUARIOFIVE9": "tafu802887@siigo.com",
                    "USUARIO RED": "tafu802887",
                    "CORREOELECTRONICO": "tafu802887@siigo.com",
                    "STATION ID": "3127551",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Silvia Zilah Fernandez Almeida",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1005289806",
                    "FECHA INICIAL": "2022-04-12",
                    "FECHA OPERACION": "2022-04-25",
                    "NOMBRES": "Jose Manuel",
                    "APELLIDOS": "Suarez Rodriguez",
                    "NOMBRES COMPLETOS": "Jose Manuel Suarez Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Pie de Cuesta",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Aleida Bibiana Garcia Suarez",
                    "CODIGO USUARIO": "suar802885",
                    "USUARIOFIVE9": "suar802885@siigo.com",
                    "USUARIO RED": "suar802885",
                    "CORREOELECTRONICO": "suar802885@siigo.com",
                    "STATION ID": "3127562",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Aleida Bibiana Garcia Suarez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1026282677",
                    "FECHA INICIAL": "2022-04-12",
                    "FECHA OPERACION": "2022-04-25",
                    "NOMBRES": "Younis Yanubis",
                    "APELLIDOS": "Salgado Guzman",
                    "NOMBRES COMPLETOS": "Younis Yanubis Salgado Guzman",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Aleida Bibiana Garcia Suarez",
                    "CODIGO USUARIO": "salg802882",
                    "USUARIOFIVE9": "salg802882@siigo.com",
                    "USUARIO RED": "salg802882",
                    "CORREOELECTRONICO": "salg802882@siigo.com",
                    "STATION ID": "3267840",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Aleida Bibiana Garcia Suarez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53089007",
                    "FECHA INICIAL": "2022-04-12",
                    "FECHA OPERACION": "2022-04-25",
                    "NOMBRES": "Sonia Viviana",
                    "APELLIDOS": "Reguillo Leon",
                    "NOMBRES COMPLETOS": "Sonia Viviana Reguillo Leon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Michelle Matamoros Del Rosario",
                    "CODIGO USUARIO": "regu802878",
                    "USUARIOFIVE9": "regu802878@siigo.com",
                    "USUARIO RED": "regu802878",
                    "CORREOELECTRONICO": "regu802878@siigo.com",
                    "STATION ID": "3267879",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Michelle Matamoros Del Rosario",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001897305",
                    "FECHA INICIAL": "2022-03-09",
                    "FECHA OPERACION": "2022-03-14",
                    "NOMBRES": "Richard Eduardo",
                    "APELLIDOS": "Carrillo Villa",
                    "NOMBRES COMPLETOS": "Richard Eduardo Carrillo Villa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jefrey Julian Sanchez Celemin",
                    "CODIGO USUARIO": "carr802754",
                    "USUARIOFIVE9": "carr802754@siigo.com",
                    "USUARIO RED": "carr802754",
                    "CORREOELECTRONICO": "carr802754@siigo.com",
                    "STATION ID": "3720432",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jefrey Julian Sanchez Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1004710578",
                    "FECHA INICIAL": "2022-03-18",
                    "FECHA OPERACION": "2022-03-24",
                    "NOMBRES": "Omar Santiago",
                    "APELLIDOS": "Cruz Munoz",
                    "NOMBRES COMPLETOS": "Omar Santiago Cruz Munoz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Pasto",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Nicoll Torres Celemin",
                    "CODIGO USUARIO": "cruz802814",
                    "USUARIOFIVE9": "cruz802814@siigo.com",
                    "USUARIO RED": "cruz802814",
                    "CORREOELECTRONICO": "cruz802814@siigo.com",
                    "STATION ID": "3720475",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Nicoll Torres Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80827588",
                    "FECHA INICIAL": "2022-03-18",
                    "FECHA OPERACION": "2022-03-24",
                    "NOMBRES": "Yesid",
                    "APELLIDOS": "Baquero Florez",
                    "NOMBRES COMPLETOS": "Yesid Baquero Florez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnicos",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Anderson Cubillos Ayala",
                    "CODIGO USUARIO": "baqu802819",
                    "USUARIOFIVE9": "baqu802819@siigo.com",
                    "USUARIO RED": "baqu802819",
                    "CORREOELECTRONICO": "baqu802819@siigo.com",
                    "STATION ID": "3763761",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Anderson Cubillos Ayala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1006098888",
                    "FECHA INICIAL": "2022-03-18",
                    "FECHA OPERACION": "2022-03-24",
                    "NOMBRES": "Valentina",
                    "APELLIDOS": "Correa Delgado",
                    "NOMBRES COMPLETOS": "Valentina Correa Delgado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Palmira",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnicos",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Anderson Cubillos Ayala",
                    "CODIGO USUARIO": "corr802821",
                    "USUARIOFIVE9": "corr802821@siigo.com",
                    "USUARIO RED": "corr802821",
                    "CORREOELECTRONICO": "corr802821@siigo.com",
                    "STATION ID": "3763763",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Anderson Cubillos Ayala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "EARN900717S49",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Nydia Michelle",
                    "APELLIDOS": "Estrada Romero",
                    "NOMBRES COMPLETOS": "Nydia Michelle Estrada Romero",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Experto MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adair Blanco Landin",
                    "CODIGO USUARIO": "nestrada",
                    "USUARIOFIVE9": "nestrada@aspel.com.mx",
                    "USUARIO RED": "nestrada",
                    "CORREOELECTRONICO": "nestrada@aspel.com.mx",
                    "STATION ID": "3857105",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adair Blanco Landin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "PACE891122KG4",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Enrique Fernando",
                    "APELLIDOS": "Paredes Cicero",
                    "NOMBRES COMPLETOS": "Enrique Fernando Paredes Cicero",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Experto MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adair Blanco Landin",
                    "CODIGO USUARIO": "eparedes",
                    "USUARIOFIVE9": "eparedes@aspel.com.mx",
                    "USUARIO RED": "eparedes",
                    "CORREOELECTRONICO": "eparedes@aspel.com.mx",
                    "STATION ID": "4421843",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adair Blanco Landin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "AOVL8512011Z8",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Luis Jesus",
                    "APELLIDOS": "Alonso Vratny",
                    "NOMBRES COMPLETOS": "Luis Jesus Alonso Vratny",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Experto MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adair Blanco Landin",
                    "CODIGO USUARIO": "lalonso",
                    "USUARIOFIVE9": "lalonso@aspel.com.mx",
                    "USUARIO RED": "lalonso",
                    "CORREOELECTRONICO": "lalonso@aspel.com.mx",
                    "STATION ID": "4421844",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adair Blanco Landin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "LOMD921107PU9",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "David",
                    "APELLIDOS": "Lopez Marquez",
                    "NOMBRES COMPLETOS": "David Lopez Marquez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Experto MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adair Blanco Landin",
                    "CODIGO USUARIO": "dlopez",
                    "USUARIOFIVE9": "dlopez@aspel.com.mx",
                    "USUARIO RED": "dlopez",
                    "CORREOELECTRONICO": "dlopez@aspel.com.mx",
                    "STATION ID": "4421845",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adair Blanco Landin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "EAPO9012294H4",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Omar Leonardo",
                    "APELLIDOS": "Estrada Pacheco",
                    "NOMBRES COMPLETOS": "Omar Leonardo Estrada Pacheco",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Experto MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adair Blanco Landin",
                    "CODIGO USUARIO": "oestrada",
                    "USUARIOFIVE9": "oestrada@aspel.com.mx",
                    "USUARIO RED": "oestrada",
                    "CORREOELECTRONICO": "oestrada@aspel.com.mx",
                    "STATION ID": "4421848",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adair Blanco Landin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "VEPO890207U6A",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Oscar",
                    "APELLIDOS": "Vera Perez",
                    "NOMBRES COMPLETOS": "Oscar Vera Perez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Experto MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adair Blanco Landin",
                    "CODIGO USUARIO": "overa",
                    "USUARIOFIVE9": "overa@aspel.com.mx",
                    "USUARIO RED": "overa",
                    "CORREOELECTRONICO": "overa@aspel.com.mx",
                    "STATION ID": "4421849",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adair Blanco Landin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "CAML8808219E9",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Lauriano",
                    "APELLIDOS": "Carmen Manuel",
                    "NOMBRES COMPLETOS": "Lauriano Carmen Manuel",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Experto MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adair Blanco Landin",
                    "CODIGO USUARIO": "lcarmen",
                    "USUARIOFIVE9": "lcarmen@aspel.com.mx",
                    "USUARIO RED": "lcarmen",
                    "CORREOELECTRONICO": "lcarmen@aspel.com.mx",
                    "STATION ID": "4421850",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adair Blanco Landin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "EIGM930622TK6",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Montserrat",
                    "APELLIDOS": "Espinosa Galindo",
                    "NOMBRES COMPLETOS": "Montserrat Espinosa Galindo",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Experto MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adair Blanco Landin",
                    "CODIGO USUARIO": "mespinosa",
                    "USUARIOFIVE9": "mespinosa@aspel.com.mx",
                    "USUARIO RED": "mespinosa",
                    "CORREOELECTRONICO": "mespinosa@aspel.com.mx",
                    "STATION ID": "4421851",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adair Blanco Landin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "VAMS870104577",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Sergio Aaron",
                    "APELLIDOS": "Valdez Marquez",
                    "NOMBRES COMPLETOS": "Sergio Aaron Valdez Marquez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Experto MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adair Blanco Landin",
                    "CODIGO USUARIO": "smarquez",
                    "USUARIOFIVE9": "smarquez@aspel.com.mx",
                    "USUARIO RED": "smarquez",
                    "CORREOELECTRONICO": "smarquez@aspel.com.mx",
                    "STATION ID": "2606456",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adair Blanco Landin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MOCE840407SN8",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Maria Esmeralda",
                    "APELLIDOS": "Moo Can",
                    "NOMBRES COMPLETOS": "Maria Esmeralda Moo Can",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Experto MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adair Blanco Landin",
                    "CODIGO USUARIO": "mmoo",
                    "USUARIOFIVE9": "mmoo@aspel.com.mx",
                    "USUARIO RED": "mmoo",
                    "CORREOELECTRONICO": "mmoo@aspel.com.mx",
                    "STATION ID": "2606523",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adair Blanco Landin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "FACC781012877",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Claudia Ivonne",
                    "APELLIDOS": "Fraga Chavez",
                    "NOMBRES COMPLETOS": "Claudia Ivonne Fraga Chavez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Experto MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adair Blanco Landin",
                    "CODIGO USUARIO": "ifraga",
                    "USUARIOFIVE9": "ifraga@aspel.com.mx",
                    "USUARIO RED": "ifraga",
                    "CORREOELECTRONICO": "ifraga@aspel.com.mx",
                    "STATION ID": "2606676",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adair Blanco Landin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "BAVM881013EX6",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Martha Neri",
                    "APELLIDOS": "Barrientos Velazquez",
                    "NOMBRES COMPLETOS": "Martha Neri Barrientos Velazquez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Experto MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adair Blanco Landin",
                    "CODIGO USUARIO": "mbarrientos",
                    "USUARIOFIVE9": "mbarrientos@aspel.com.mx",
                    "USUARIO RED": "mbarrientos",
                    "CORREOELECTRONICO": "mbarrientos@aspel.com.mx",
                    "STATION ID": "2606795",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adair Blanco Landin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "COCN880912K16",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Noe",
                    "APELLIDOS": "Couder Chinas",
                    "NOMBRES COMPLETOS": "Noe Couder Chinas",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Experto MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adair Blanco Landin",
                    "CODIGO USUARIO": "ncouder",
                    "USUARIOFIVE9": "ncouder@aspel.com.mx",
                    "USUARIO RED": "ncouder",
                    "CORREOELECTRONICO": "ncouder@aspel.com.mx",
                    "STATION ID": "2606992",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adair Blanco Landin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "FOFL840301Q95",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Leticia",
                    "APELLIDOS": "Flores Fragoso",
                    "NOMBRES COMPLETOS": "Leticia Flores Fragoso",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Oscar Rodrigo Navarro Hernandez",
                    "CODIGO USUARIO": "lfregoso",
                    "USUARIOFIVE9": "lfragoso@aspel.com.mx",
                    "USUARIO RED": "lfregoso",
                    "CORREOELECTRONICO": "lfragoso@aspel.com.mx",
                    "STATION ID": "3763860",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Oscar Rodrigo Navarro Hernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "GOVW9610289M2",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Willy",
                    "APELLIDOS": "Gonzalez Villeda",
                    "NOMBRES COMPLETOS": "Willy Gonzalez Villeda",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Alejandro Roa Franco",
                    "CODIGO USUARIO": "gvilleda",
                    "USUARIOFIVE9": "gvilleda@aspel.com.mx",
                    "USUARIO RED": "gvilleda",
                    "CORREOELECTRONICO": "gvilleda@aspel.com.mx",
                    "STATION ID": "3763835",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Alejandro Roa Franco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MAGJ9407207R9",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jocelyn Angelica",
                    "APELLIDOS": "Marin Garcia",
                    "NOMBRES COMPLETOS": "Jocelyn Angelica Marin Garcia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Alejandro Roa Franco",
                    "CODIGO USUARIO": "jmarin",
                    "USUARIOFIVE9": "jmarin@aspel.com.mx",
                    "USUARIO RED": "jmarin",
                    "CORREOELECTRONICO": "jmarin@aspel.com.mx",
                    "STATION ID": "3763853",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Alejandro Roa Franco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "FECJ910412SK8",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Janeth",
                    "APELLIDOS": "Fernandez Carrasco",
                    "NOMBRES COMPLETOS": "Janeth Fernandez Carrasco",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Alejandro Salgado Vergara",
                    "CODIGO USUARIO": "jcarrasco",
                    "USUARIOFIVE9": "jcarrasco@aspel.com.mx",
                    "USUARIO RED": "jcarrasco",
                    "CORREOELECTRONICO": "jcarrasco@aspel.com.mx",
                    "STATION ID": "3763857",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Alejandro Salgado Vergara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "GOCL940422UU6",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-27",
                    "NOMBRES": "Jose Luis",
                    "APELLIDOS": "Gomez Carrasco",
                    "NOMBRES COMPLETOS": "Jose Luis Gomez Carrasco",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jorge Alfredo Huesca Martinez",
                    "CODIGO USUARIO": "jgomez",
                    "USUARIOFIVE9": "jgomez@aspel.com.mx",
                    "USUARIO RED": "jgomez",
                    "CORREOELECTRONICO": "jgomez@aspel.com.mx",
                    "STATION ID": "3763909",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jorge Alfredo Huesca Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "RADJ931026J11",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Jesus Alejandro",
                    "APELLIDOS": "Ramirez Diaz",
                    "NOMBRES COMPLETOS": "Jesus Alejandro Ramirez Diaz",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Armando Uriel Lopez Vazquez",
                    "CODIGO USUARIO": "jaramirez",
                    "USUARIOFIVE9": "jaramirez@aspel.com.mx",
                    "USUARIO RED": "jaramirez",
                    "CORREOELECTRONICO": "jaramirez@aspel.com.mx",
                    "STATION ID": "3267878",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Armando Uriel Lopez Vazquez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MAMS9612262E1",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Santos Benjamin",
                    "APELLIDOS": "Martinez Martinez",
                    "NOMBRES COMPLETOS": "Santos Benjamin Martinez Martinez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Cartera",
                    "SUBAREA": "Aclaraciones MX",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Adriana Rodriguez Castro",
                    "CODIGO USUARIO": "smartinez",
                    "USUARIOFIVE9": "smartinez@aspel.com.mx",
                    "USUARIO RED": "smartinez",
                    "CORREOELECTRONICO": "smartinez@aspel.com.mx",
                    "STATION ID": "3267884",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Adriana Rodriguez Castro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "GORB951209IJ5",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Brandon Enrique",
                    "APELLIDOS": "Gomez Rosales",
                    "NOMBRES COMPLETOS": "Brandon Enrique Gomez Rosales",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Cartera",
                    "SUBAREA": "Aclaraciones MX",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Adriana Rodriguez Castro",
                    "CODIGO USUARIO": "brosales",
                    "USUARIOFIVE9": "brosales@aspel.com.mx",
                    "USUARIO RED": "brosales",
                    "CORREOELECTRONICO": "brosales@aspel.com.mx",
                    "STATION ID": "3471491",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Adriana Rodriguez Castro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "RAEV850824LF6",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Viviana",
                    "APELLIDOS": "Ramirez Elizalde",
                    "NOMBRES COMPLETOS": "Viviana Ramirez Elizalde",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Arturo Alvarez Martinez",
                    "CODIGO USUARIO": "velizalde",
                    "USUARIOFIVE9": "velizalde@aspel.com.mx",
                    "USUARIO RED": "velizalde",
                    "CORREOELECTRONICO": "velizalde@aspel.com.mx",
                    "STATION ID": "3483773",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Arturo Alvarez Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SAZM970827MG7",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Marisol",
                    "APELLIDOS": "Santos Zamora",
                    "NOMBRES COMPLETOS": "Marisol Santos Zamora",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Armando Uriel Lopez Vazquez",
                    "CODIGO USUARIO": "msantos",
                    "USUARIOFIVE9": "msantos@aspel.com.mx",
                    "USUARIO RED": "msantos",
                    "CORREOELECTRONICO": "msantos@aspel.com.mx",
                    "STATION ID": "3690369",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Armando Uriel Lopez Vazquez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "FOHG860713HC4",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-31",
                    "NOMBRES": "Gustavo",
                    "APELLIDOS": "Flores Hernandez",
                    "NOMBRES COMPLETOS": "Gustavo Flores Hernandez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Arturo Alvarez Martinez",
                    "CODIGO USUARIO": "gflores",
                    "USUARIOFIVE9": "gflores@aspel.com.mx",
                    "USUARIO RED": "gflores",
                    "CORREOELECTRONICO": "gflores@aspel.com.mx",
                    "STATION ID": "3763927",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Arturo Alvarez Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SUSA0006214J8",
                    "FECHA INICIAL": "2022-06-08",
                    "FECHA OPERACION": "2022-06-13",
                    "NOMBRES": "Aldo Ruben",
                    "APELLIDOS": "Suarez Segovia",
                    "NOMBRES COMPLETOS": "Aldo Ruben Suarez Segovia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Armando Uriel Lopez Vazquez",
                    "CODIGO USUARIO": "suar803294",
                    "USUARIOFIVE9": "suar803294@siigo.com",
                    "USUARIO RED": "suar803294",
                    "CORREOELECTRONICO": "suar803294@siigo.com",
                    "STATION ID": "3690235",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Armando Uriel Lopez Vazquez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SANL910710QZ5",
                    "FECHA INICIAL": "2022-06-28",
                    "FECHA OPERACION": "2022-06-29",
                    "NOMBRES": "Laura Estefania",
                    "APELLIDOS": "Sandoval Navarrete",
                    "NOMBRES COMPLETOS": "Laura Estefania Sandoval Navarrete",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Arturo Alvarez Martinez",
                    "CODIGO USUARIO": "lsandoval",
                    "USUARIOFIVE9": "lsandoval@aspel.com.mx",
                    "USUARIO RED": "lsandoval",
                    "CORREOELECTRONICO": "lsandoval@aspel.com.mx",
                    "STATION ID": "2606661",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Arturo Alvarez Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "JEAK930429E63",
                    "FECHA INICIAL": "2022-09-22",
                    "FECHA OPERACION": "2022-09-22",
                    "NOMBRES": "Karen Selene",
                    "APELLIDOS": "De Jesus Angeles",
                    "NOMBRES COMPLETOS": "Karen Selene De Jesus Angeles",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Armando Uriel Lopez Vazquez",
                    "CODIGO USUARIO": "kjesus",
                    "USUARIOFIVE9": "kjesus@aspel.com.mx",
                    "USUARIO RED": "kjesus",
                    "CORREOELECTRONICO": "kjesus@aspel.com.mx",
                    "STATION ID": "4421758",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Armando Uriel Lopez Vazquez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "AUEM940905EE0",
                    "FECHA INICIAL": "2022-09-22",
                    "FECHA OPERACION": "2022-09-22",
                    "NOMBRES": "Michelle",
                    "APELLIDOS": "Ahuacatitan Espindola",
                    "NOMBRES COMPLETOS": "Michelle Ahuacatitan Espindola",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Armando Uriel Lopez Vazquez",
                    "CODIGO USUARIO": "mahuacatitan",
                    "USUARIOFIVE9": "mahuacatitan@aspel.com.mx",
                    "USUARIO RED": "mahuacatitan",
                    "CORREOELECTRONICO": "mahuacatitan@aspel.com.mx",
                    "STATION ID": "4421761",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Armando Uriel Lopez Vazquez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SATJ850412HK8",
                    "FECHA INICIAL": "2022-10-06",
                    "FECHA OPERACION": "2022-10-11",
                    "NOMBRES": "Jessica",
                    "APELLIDOS": "Sanchez Torres",
                    "NOMBRES COMPLETOS": "Jessica Sanchez Torres",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Arturo Alvarez Martinez",
                    "CODIGO USUARIO": "sanc803900",
                    "USUARIOFIVE9": "sanc803900@siigo.com",
                    "USUARIO RED": "sanc803900",
                    "CORREOELECTRONICO": "sanc803900@siigo.com",
                    "STATION ID": "4421699",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Arturo Alvarez Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "VISA931119S60",
                    "FECHA INICIAL": "2022-09-28",
                    "FECHA OPERACION": "2022-10-17",
                    "NOMBRES": "Vidal Santiago",
                    "APELLIDOS": "Andrea Arumi",
                    "NOMBRES COMPLETOS": "Andrea Arumi Vidal Santiago",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Arturo Alvarez Martinez",
                    "CODIGO USUARIO": "avidal",
                    "USUARIOFIVE9": "avidal@aspel.com.mx",
                    "USUARIO RED": "avidal",
                    "CORREOELECTRONICO": "avidal@aspel.com.mx",
                    "STATION ID": "2605964",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Arturo Alvarez Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1070607545",
                    "FECHA INICIAL": "2023-01-25",
                    "FECHA OPERACION": "2023-01-26",
                    "NOMBRES": "Maria Camila",
                    "APELLIDOS": "Sanchez Sanchez",
                    "NOMBRES COMPLETOS": "Maria Camila Sanchez Sanchez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Elizabeth Giron Parra",
                    "CODIGO USUARIO": "mcsanchez",
                    "USUARIOFIVE9": "mcsanchez@aspel.com.mx",
                    "USUARIO RED": "mcsanchez",
                    "CORREOELECTRONICO": "mcsanchez@aspel.com.mx",
                    "STATION ID": "3483700",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Elizabeth Giron Parra",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SAMF920124R25",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Fanny",
                    "APELLIDOS": "Salmeron Munguia",
                    "NOMBRES COMPLETOS": "Fanny Salmeron Munguia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Alma Rosa Romero Garcia",
                    "CODIGO USUARIO": "fsalmeron",
                    "USUARIOFIVE9": "fsalmeron@aspel.com.mx",
                    "USUARIO RED": "fsalmeron",
                    "CORREOELECTRONICO": "fsalmeron@aspel.com.mx",
                    "STATION ID": "3763946",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Alma Rosa Romero Garcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MADV881231BM2",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Victor Alejandro",
                    "APELLIDOS": "Martinez Diaz",
                    "NOMBRES COMPLETOS": "Victor Alejandro Martinez Diaz",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Alma Rosa Romero Garcia",
                    "CODIGO USUARIO": "vdiaz",
                    "USUARIOFIVE9": "vdiaz@aspel.com.mx",
                    "USUARIO RED": "vdiaz",
                    "CORREOELECTRONICO": "vdiaz@aspel.com.mx",
                    "STATION ID": "3763947",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Alma Rosa Romero Garcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MAHJ920729D80",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Josefina Aidee",
                    "APELLIDOS": "Macias Hernandez",
                    "NOMBRES COMPLETOS": "Josefina Aidee Macias Hernandez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Alma Rosa Romero Garcia",
                    "CODIGO USUARIO": "jmacias",
                    "USUARIOFIVE9": "jmacias@aspel.com.mx",
                    "USUARIO RED": "jmacias",
                    "CORREOELECTRONICO": "jmacias@aspel.com.mx",
                    "STATION ID": "3763948",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Alma Rosa Romero Garcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SIBO910523KY8",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Olga Olivia",
                    "APELLIDOS": "Silva Bravo",
                    "NOMBRES COMPLETOS": "Olga Olivia Silva Bravo",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Alma Rosa Romero Garcia",
                    "CODIGO USUARIO": "osilva",
                    "USUARIOFIVE9": "osilva@aspel.com.mx",
                    "USUARIO RED": "osilva",
                    "CORREOELECTRONICO": "osilva@aspel.com.mx",
                    "STATION ID": "3763951",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Alma Rosa Romero Garcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "GUCA901107KS4",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Abigail",
                    "APELLIDOS": "Guevara Calderon",
                    "NOMBRES COMPLETOS": "Abigail Guevara Calderon",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Alma Rosa Romero Garcia",
                    "CODIGO USUARIO": "acalderon",
                    "USUARIOFIVE9": "acalderon@aspel.com.mx",
                    "USUARIO RED": "acalderon",
                    "CORREOELECTRONICO": "acalderon@aspel.com.mx",
                    "STATION ID": "3763952",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Alma Rosa Romero Garcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MAJV870429P18",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Veronica",
                    "APELLIDOS": "Mateo Jimenez",
                    "NOMBRES COMPLETOS": "Veronica Mateo Jimenez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoraciones",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Alma Rosa Romero Garcia",
                    "CODIGO USUARIO": "vmateo",
                    "USUARIOFIVE9": "vmateo@aspel.com.mx",
                    "USUARIO RED": "vmateo",
                    "CORREOELECTRONICO": "vmateo@aspel.com.mx",
                    "STATION ID": "3857150",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Alma Rosa Romero Garcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "VIPA900630PL6",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-10",
                    "NOMBRES": "Ana Gabriela",
                    "APELLIDOS": "Villalba Perez",
                    "NOMBRES COMPLETOS": "Ana Gabriela Villalba Perez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Valoraciones",
                    "CARGO": "Analista de Valoraciones",
                    "JEFE INMEDIATO": "Alma Rosa Romero Garcia",
                    "CODIGO USUARIO": "avillalba",
                    "USUARIOFIVE9": "avillalba@aspel.com.mx",
                    "USUARIO RED": "avillalba",
                    "CORREOELECTRONICO": "avillalba@aspel.com.mx",
                    "STATION ID": "4421839",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Alma Rosa Romero Garcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "RAML920722NTA",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Luis Alfredo",
                    "APELLIDOS": "Ramirez Martinez",
                    "NOMBRES COMPLETOS": "Luis Alfredo Ramirez Martinez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Victor Hugo Huerta Lopez",
                    "CODIGO USUARIO": "lamartinez",
                    "USUARIOFIVE9": "lamartinez@aspel.com.mx",
                    "USUARIO RED": "lamartinez",
                    "CORREOELECTRONICO": "lamartinez@aspel.com.mx",
                    "STATION ID": "3763880",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Victor Hugo Huerta Lopez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "VAJI961119F37",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Ignacio Jesus",
                    "APELLIDOS": "Vazquez Juarez",
                    "NOMBRES COMPLETOS": "Ignacio Jesus Vazquez Juarez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Maria Teresa Vazquez Garza",
                    "CODIGO USUARIO": "ijuarez",
                    "USUARIOFIVE9": "ijuarez@aspel.com.mx",
                    "USUARIO RED": "ijuarez",
                    "CORREOELECTRONICO": "ijuarez@aspel.com.mx",
                    "STATION ID": "3690372",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Maria Teresa Vazquez Garza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SANO9402116W3",
                    "FECHA INICIAL": "2022-04-28",
                    "FECHA OPERACION": "2022-04-28",
                    "NOMBRES": "Oscar Omar Adony",
                    "APELLIDOS": "Sanchez Nava",
                    "NOMBRES COMPLETOS": "Oscar Omar Adony Sanchez Nava",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Alejandro Roa Franco",
                    "CODIGO USUARIO": "osanchez",
                    "USUARIOFIVE9": "osanchez@aspel.com.mx",
                    "USUARIO RED": "osanchez",
                    "CORREOELECTRONICO": "osanchez@aspel.com.mx",
                    "STATION ID": "3857190",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Alejandro Roa Franco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "TARA980204S69",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jose Alfredo",
                    "APELLIDOS": "Tlahuechtl Rodriguez",
                    "NOMBRES COMPLETOS": "Jose Alfredo Tlahuechtl Rodriguez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Carranza Valdes",
                    "CODIGO USUARIO": "jrodriguez",
                    "USUARIOFIVE9": "jtrodriguez@aspel.com.mx",
                    "USUARIO RED": "jrodriguez",
                    "CORREOELECTRONICO": "jtrodriguez@aspel.com.mx",
                    "STATION ID": "3763836",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Carranza Valdes",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "AAMA900616U80",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Arturo",
                    "APELLIDOS": "Alvarez Martinez",
                    "NOMBRES COMPLETOS": "Arturo Alvarez Martinez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Gerardo Marquez Perez",
                    "CODIGO USUARIO": "aalvarez",
                    "USUARIOFIVE9": "aalvarez@aspel.com.mx",
                    "USUARIO RED": "aalvarez",
                    "CORREOELECTRONICO": "aalvarez@aspel.com.mx",
                    "STATION ID": "3857119",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gerardo Marquez Perez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "TEJP941212L32",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Pedro Guadalupe",
                    "APELLIDOS": "Tenorio Jimenez",
                    "NOMBRES COMPLETOS": "Pedro Guadalupe Tenorio Jimenez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Carranza Valdes",
                    "CODIGO USUARIO": "ptenorio",
                    "USUARIOFIVE9": "ptenorio@aspel.com.mx",
                    "USUARIO RED": "ptenorio",
                    "CORREOELECTRONICO": "ptenorio@aspel.com.mx",
                    "STATION ID": "3763944",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Carranza Valdes",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "FOMJ910115CZ0",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jonathan Uriel",
                    "APELLIDOS": "Flores Martinez",
                    "NOMBRES COMPLETOS": "Jonathan Uriel Flores Martinez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "ASD MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Alejandro Roa Franco",
                    "CODIGO USUARIO": "uflores",
                    "USUARIOFIVE9": "uflores@aspel.com.mx",
                    "USUARIO RED": "uflores",
                    "CORREOELECTRONICO": "uflores@aspel.com.mx",
                    "STATION ID": "3857168",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Alejandro Roa Franco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MOVJ9409044J3",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jazmin",
                    "APELLIDOS": "Mora Vazquez",
                    "NOMBRES COMPLETOS": "Jazmin Mora Vazquez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Victor Hugo Huerta Lopez",
                    "CODIGO USUARIO": "jmora",
                    "USUARIOFIVE9": "jmora@aspel.com.mx",
                    "USUARIO RED": "jmora",
                    "CORREOELECTRONICO": "jmora@aspel.com.mx",
                    "STATION ID": "3857171",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Victor Hugo Huerta Lopez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "HEAC930429RR1",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Ceina Catalina",
                    "APELLIDOS": "Hernandez Aguilar",
                    "NOMBRES COMPLETOS": "Ceina Catalina Hernandez Aguilar",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "ASD MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Rodriguez Castro",
                    "CODIGO USUARIO": "caguilar",
                    "USUARIOFIVE9": "caguilar@aspel.com.mx",
                    "USUARIO RED": "caguilar",
                    "CORREOELECTRONICO": "caguilar@aspel.com.mx",
                    "STATION ID": "3857172",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Rodriguez Castro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MALL921027LU8",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-10",
                    "NOMBRES": "Luis Alejandro",
                    "APELLIDOS": "Martinez Lujan",
                    "NOMBRES COMPLETOS": "Luis Alejandro Martinez Lujan",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jorge Alfredo Huesca Martinez",
                    "CODIGO USUARIO": "llujan",
                    "USUARIOFIVE9": "llujan@aspel.com.mx",
                    "USUARIO RED": "llujan",
                    "CORREOELECTRONICO": "llujan@aspel.com.mx",
                    "STATION ID": "4421840",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jorge Alfredo Huesca Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "VEGE870313764",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-17",
                    "NOMBRES": "Erik Antonio",
                    "APELLIDOS": "Vega Gomez",
                    "NOMBRES COMPLETOS": "Erik Antonio Vega Gomez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "ASD MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Maria Teresa Vazquez Garza",
                    "CODIGO USUARIO": "egomez",
                    "USUARIOFIVE9": "egomez@aspel.com.mx",
                    "USUARIO RED": "egomez",
                    "CORREOELECTRONICO": "egomez@aspel.com.mx",
                    "STATION ID": "4421833",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Maria Teresa Vazquez Garza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "CAAO901016K44",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-17",
                    "NOMBRES": "Osvaldo",
                    "APELLIDOS": "Castro Alva",
                    "NOMBRES COMPLETOS": "Osvaldo Castro Alva",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Maria Teresa Vazquez Garza",
                    "CODIGO USUARIO": "ocastro",
                    "USUARIOFIVE9": "ocastro@aspel.com.mx",
                    "USUARIO RED": "ocastro",
                    "CORREOELECTRONICO": "ocastro@aspel.com.mx",
                    "STATION ID": "4421834",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Maria Teresa Vazquez Garza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "JIBM930410KA5",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-17",
                    "NOMBRES": "Marco Antonio",
                    "APELLIDOS": "Jimenez Bernal",
                    "NOMBRES COMPLETOS": "Marco Antonio Jimenez Bernal",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Maria Teresa Vazquez Garza",
                    "CODIGO USUARIO": "ajimenez",
                    "USUARIOFIVE9": "ajimenez@aspel.com.mx",
                    "USUARIO RED": "ajimenez",
                    "CORREOELECTRONICO": "ajimenez@aspel.com.mx",
                    "STATION ID": "4421835",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Maria Teresa Vazquez Garza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "OINV891002CL2",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-17",
                    "NOMBRES": "Victor Eduardo",
                    "APELLIDOS": "Olivares Noria",
                    "NOMBRES COMPLETOS": "Victor Eduardo Olivares Noria",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "ASD MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Carranza Valdes",
                    "CODIGO USUARIO": "volivares",
                    "USUARIOFIVE9": "volivares@aspel.com.mx",
                    "USUARIO RED": "volivares",
                    "CORREOELECTRONICO": "volivares@aspel.com.mx",
                    "STATION ID": "4421836",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Carranza Valdes",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "TORA980622LQ5",
                    "FECHA INICIAL": "2022-08-19",
                    "FECHA OPERACION": "2022-08-23",
                    "NOMBRES": "Amaru",
                    "APELLIDOS": "Torres Reyes",
                    "NOMBRES COMPLETOS": "Amaru Torres Reyes",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Arturo Alvarez Martinez",
                    "CODIGO USUARIO": "atorres",
                    "USUARIOFIVE9": "atorres@aspel.com.mx",
                    "USUARIO RED": "atorres",
                    "CORREOELECTRONICO": "atorres@aspel.com.mx",
                    "STATION ID": "4421780",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Arturo Alvarez Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MARE890720AS6",
                    "FECHA INICIAL": "2022-08-19",
                    "FECHA OPERACION": "2022-08-23",
                    "NOMBRES": "Edith Patricia",
                    "APELLIDOS": "Magallanes Ramirez",
                    "NOMBRES COMPLETOS": "Edith Patricia Magallanes Ramirez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Armando Uriel Lopez Vazquez",
                    "CODIGO USUARIO": "emagallanes",
                    "USUARIOFIVE9": "emagallanes@aspel.com.mx",
                    "USUARIO RED": "emagallanes",
                    "CORREOELECTRONICO": "emagallanes@aspel.com.mx",
                    "STATION ID": "4421781",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Armando Uriel Lopez Vazquez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MOLP881129AI4",
                    "FECHA INICIAL": "2022-08-19",
                    "FECHA OPERACION": "2022-08-23",
                    "NOMBRES": "Paola Nayely",
                    "APELLIDOS": "Montano Lopez",
                    "NOMBRES COMPLETOS": "Paola Nayely Montano Lopez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Arturo Alvarez Martinez",
                    "CODIGO USUARIO": "pmontano",
                    "USUARIOFIVE9": "pmontano@aspel.com.mx",
                    "USUARIO RED": "pmontano",
                    "CORREOELECTRONICO": "pmontano@aspel.com.mx",
                    "STATION ID": "4421784",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Arturo Alvarez Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "RORL981027SK8",
                    "FECHA INICIAL": "2022-08-19",
                    "FECHA OPERACION": "2022-08-23",
                    "NOMBRES": "Luis",
                    "APELLIDOS": "Rodriguez Rodriguez",
                    "NOMBRES COMPLETOS": "Luis Rodriguez Rodriguez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Arturo Alvarez Martinez",
                    "CODIGO USUARIO": "lrrodriguez",
                    "USUARIOFIVE9": "lrrodriguez@aspel.com.mx",
                    "USUARIO RED": "lrrodriguez",
                    "CORREOELECTRONICO": "lrrodriguez@aspel.com.mx",
                    "STATION ID": "4421785",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Arturo Alvarez Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "RECA9106155T1",
                    "FECHA INICIAL": "2022-08-19",
                    "FECHA OPERACION": "2022-08-23",
                    "NOMBRES": "Alina Montserrat",
                    "APELLIDOS": "Rementeria Cruz",
                    "NOMBRES COMPLETOS": "Alina Montserrat Rementeria Cruz",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Armando Uriel Lopez Vazquez",
                    "CODIGO USUARIO": "arementeria",
                    "USUARIOFIVE9": "arementeria@aspel.com.mx",
                    "USUARIO RED": "arementeria",
                    "CORREOELECTRONICO": "arementeria@aspel.com.mx",
                    "STATION ID": "4421786",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Armando Uriel Lopez Vazquez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "RAGE860529FD0",
                    "FECHA INICIAL": "2022-08-19",
                    "FECHA OPERACION": "2022-08-23",
                    "NOMBRES": "Elizabeth",
                    "APELLIDOS": "Ramirez Garcia",
                    "NOMBRES COMPLETOS": "Elizabeth Ramirez Garcia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Arturo Alvarez Martinez",
                    "CODIGO USUARIO": "eramirez",
                    "USUARIOFIVE9": "eramirez@aspel.com.mx",
                    "USUARIO RED": "eramirez",
                    "CORREOELECTRONICO": "eramirez@aspel.com.mx",
                    "STATION ID": "4421787",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Arturo Alvarez Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SAHA890327J20",
                    "FECHA INICIAL": "2022-08-19",
                    "FECHA OPERACION": "2022-08-23",
                    "NOMBRES": "Alejandra",
                    "APELLIDOS": "Santiesteban Hernandez",
                    "NOMBRES COMPLETOS": "Alejandra Santiesteban Hernandez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Arturo Alvarez Martinez",
                    "CODIGO USUARIO": "ashernandez",
                    "USUARIOFIVE9": "ashernandez@aspel.com.mx",
                    "USUARIO RED": "ashernandez",
                    "CORREOELECTRONICO": "ashernandez@aspel.com.mx",
                    "STATION ID": "4421788",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Arturo Alvarez Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "RUVY780306FU2",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Yeni",
                    "APELLIDOS": "Rueda Villasenor",
                    "NOMBRES COMPLETOS": "Yeni Rueda Villasenor",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Arturo Alvarez Martinez",
                    "CODIGO USUARIO": "rued803671",
                    "USUARIOFIVE9": "rued803671@siigo.com",
                    "USUARIO RED": "rued803671",
                    "CORREOELECTRONICO": "rued803671@siigo.com",
                    "STATION ID": "3857219",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Arturo Alvarez Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "RAHL9410206T0",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-09",
                    "NOMBRES": "Luis Arturo",
                    "APELLIDOS": "Ramirez Hernandez",
                    "NOMBRES COMPLETOS": "Luis Arturo Ramirez Hernandez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Arturo Alvarez Martinez",
                    "CODIGO USUARIO": "rami803674",
                    "USUARIOFIVE9": "rami803674@siigo.com",
                    "USUARIO RED": "rami803674",
                    "CORREOELECTRONICO": "rami803674@siigo.com",
                    "STATION ID": "4421774",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Arturo Alvarez Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "AUCE961003EE9",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-09",
                    "NOMBRES": "Jose Eduardo",
                    "APELLIDOS": "Aguirre Colin",
                    "NOMBRES COMPLETOS": "Jose Eduardo Aguirre Colin",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Armando Uriel Lopez Vazquez",
                    "CODIGO USUARIO": "agui803673",
                    "USUARIOFIVE9": "agui803673@siigo.com",
                    "USUARIO RED": "agui803673",
                    "CORREOELECTRONICO": "agui803673@siigo.com",
                    "STATION ID": "4421777",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Armando Uriel Lopez Vazquez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MOGO911105LL4",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-09",
                    "NOMBRES": "Omar Isaias",
                    "APELLIDOS": "Montes De Oca Granados",
                    "NOMBRES COMPLETOS": "Omar Isaias Montes De Oca Granados",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Arturo Alvarez Martinez",
                    "CODIGO USUARIO": "mont803676",
                    "USUARIOFIVE9": "mont803676@siigo.com",
                    "USUARIO RED": "mont803676",
                    "CORREOELECTRONICO": "mont803676@siigo.com",
                    "STATION ID": "4421790",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Arturo Alvarez Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022411423",
                    "FECHA INICIAL": "2022-12-27",
                    "FECHA OPERACION": "2023-01-05",
                    "NOMBRES": "Maria Camila",
                    "APELLIDOS": "Rodriguez Guayacundo",
                    "NOMBRES COMPLETOS": "Maria Camila Rodriguez Guayacundo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Arturo Alvarez Martinez",
                    "CODIGO USUARIO": "rodr804551",
                    "USUARIOFIVE9": "rodr804551@siigo.com",
                    "USUARIO RED": "rodr804551",
                    "CORREOELECTRONICO": "rodr804551@siigo.com",
                    "STATION ID": "3690259",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Arturo Alvarez Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019110752",
                    "FECHA INICIAL": "2022-01-04",
                    "FECHA OPERACION": "2023-01-05",
                    "NOMBRES": "Angie Lorena",
                    "APELLIDOS": "Morales Cabulo",
                    "NOMBRES COMPLETOS": "Angie Lorena Morales Cabulo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Arturo Alvarez Martinez",
                    "CODIGO USUARIO": "mora804559",
                    "USUARIOFIVE9": "mora804559@siigo.com",
                    "USUARIO RED": "mora804559",
                    "CORREOELECTRONICO": "mora804559@siigo.com",
                    "STATION ID": "3690288",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Arturo Alvarez Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "AIZM870608H87",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Mariazell",
                    "APELLIDOS": "Arias Zepeda",
                    "NOMBRES COMPLETOS": "Mariazell Arias Zepeda",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Bismark Ivan Rios Campos",
                    "CODIGO USUARIO": "mariaz",
                    "USUARIOFIVE9": "mariaz@aspel.com.mx",
                    "USUARIO RED": "mariaz",
                    "CORREOELECTRONICO": "mariaz@aspel.com.mx",
                    "STATION ID": "2606799",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Bismark Ivan Rios Campos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "FEVI861120PN4",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Isaac",
                    "APELLIDOS": "Fernandez Velazquez",
                    "NOMBRES COMPLETOS": "Isaac Fernandez Velazquez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Arturo Alvarez Martinez",
                    "CODIGO USUARIO": "ifernandez",
                    "USUARIOFIVE9": "ifernandez@aspel.com.mx",
                    "USUARIO RED": "ifernandez",
                    "CORREOELECTRONICO": "ifernandez@aspel.com.mx",
                    "STATION ID": "2607048",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Arturo Alvarez Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "GABA8904183H6",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Ana Gabriela",
                    "APELLIDOS": "Garcia Baeza",
                    "NOMBRES COMPLETOS": "Ana Gabriela Garcia Baeza",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Bismark Ivan Rios Campos",
                    "CODIGO USUARIO": "abaeza",
                    "USUARIOFIVE9": "abaeza@aspel.com.mx",
                    "USUARIO RED": "abaeza",
                    "CORREOELECTRONICO": "abaeza@aspel.com.mx",
                    "STATION ID": "2607070",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Bismark Ivan Rios Campos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "GUHH901008DI1",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Heira",
                    "APELLIDOS": "Guzman Hernandez",
                    "NOMBRES COMPLETOS": "Heira Guzman Hernandez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Bismark Ivan Rios Campos",
                    "CODIGO USUARIO": "hguzman",
                    "USUARIOFIVE9": "hguzman@aspel.com.mx",
                    "USUARIO RED": "hguzman",
                    "CORREOELECTRONICO": "hguzman@aspel.com.mx",
                    "STATION ID": "2607118",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Bismark Ivan Rios Campos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MEVT7704115G4",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Teresa De Jesus",
                    "APELLIDOS": "Mendez Valencia",
                    "NOMBRES COMPLETOS": "Teresa De Jesus Mendez Valencia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Bismark Ivan Rios Campos",
                    "CODIGO USUARIO": "tmendez",
                    "USUARIOFIVE9": "tmendez@aspel.com.mx",
                    "USUARIO RED": "tmendez",
                    "CORREOELECTRONICO": "tmendez@aspel.com.mx",
                    "STATION ID": "3127498",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Bismark Ivan Rios Campos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "NOPK810809Q87",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Karla Lizet",
                    "APELLIDOS": "Noriega Perez",
                    "NOMBRES COMPLETOS": "Karla Lizet Noriega Perez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Bismark Ivan Rios Campos",
                    "CODIGO USUARIO": "knoriega",
                    "USUARIOFIVE9": "knoriega@aspel.com.mx",
                    "USUARIO RED": "knoriega",
                    "CORREOELECTRONICO": "knoriega@aspel.com.mx",
                    "STATION ID": "3127503",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Bismark Ivan Rios Campos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "RICL8408051N1",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Laura Esther",
                    "APELLIDOS": "Rivera De La Cruz",
                    "NOMBRES COMPLETOS": "Laura Esther Rivera De La Cruz",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Bismark Ivan Rios Campos",
                    "CODIGO USUARIO": "lrivera",
                    "USUARIOFIVE9": "lrivera@aspel.com.mx",
                    "USUARIO RED": "lrivera",
                    "CORREOELECTRONICO": "lrivera@aspel.com.mx",
                    "STATION ID": "3127566",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Bismark Ivan Rios Campos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SARL9307279Y1",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Leslie",
                    "APELLIDOS": "Sabino Rodriguez",
                    "NOMBRES COMPLETOS": "Leslie Sabino Rodriguez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Bismark Ivan Rios Campos",
                    "CODIGO USUARIO": "lsabino",
                    "USUARIOFIVE9": "lsabino@aspel.com.mx",
                    "USUARIO RED": "lsabino",
                    "CORREOELECTRONICO": "lsabino@aspel.com.mx",
                    "STATION ID": "3127590",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Bismark Ivan Rios Campos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SIGR841029A82",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Roberto Patricio",
                    "APELLIDOS": "Sil Guevara",
                    "NOMBRES COMPLETOS": "Roberto Patricio Sil Guevara",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Bismark Ivan Rios Campos",
                    "CODIGO USUARIO": "rsil",
                    "USUARIOFIVE9": "rsil@aspel.com.mx",
                    "USUARIO RED": "rsil",
                    "CORREOELECTRONICO": "rsil@aspel.com.mx",
                    "STATION ID": "3267861",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Bismark Ivan Rios Campos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "AAGD951128536",
                    "FECHA INICIAL": "2022-07-28",
                    "FECHA OPERACION": "2022-07-28",
                    "NOMBRES": "Diana Laura",
                    "APELLIDOS": "Andrade Garcia",
                    "NOMBRES COMPLETOS": "Diana Laura Andrade Garcia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Bismark Ivan Rios Campos",
                    "CODIGO USUARIO": "dlandrade",
                    "USUARIOFIVE9": "dlandrade@aspel.com.mx",
                    "USUARIO RED": "dlandrade",
                    "CORREOELECTRONICO": "dlandrade@aspel.com.mx",
                    "STATION ID": "3690374",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Bismark Ivan Rios Campos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "GUGL8805302SA",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Larissa Katary",
                    "APELLIDOS": "Guarneros Gutierrez",
                    "NOMBRES COMPLETOS": "Larissa Katary Guarneros Gutierrez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Armando Uriel Lopez Vazquez",
                    "CODIGO USUARIO": "lguarneros",
                    "USUARIOFIVE9": "lguarneros@aspel.com.mx",
                    "USUARIO RED": "lguarneros",
                    "CORREOELECTRONICO": "lguarneros@aspel.com.mx",
                    "STATION ID": "3763961",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Armando Uriel Lopez Vazquez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "BARD7604094C5",
                    "FECHA INICIAL": "2022-07-15",
                    "FECHA OPERACION": "2022-07-15",
                    "NOMBRES": "Maria Dolores",
                    "APELLIDOS": "Basurto Rodriguez",
                    "NOMBRES COMPLETOS": "Maria Dolores Basurto Rodriguez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Brian Yukio Nishizaki Simon",
                    "CODIGO USUARIO": "dbasurto",
                    "USUARIOFIVE9": "dbasurto@aspel.com.mx",
                    "USUARIO RED": "dbasurto",
                    "CORREOELECTRONICO": "dbasurto@aspel.com.mx",
                    "STATION ID": "2606854",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Brian Yukio Nishizaki Simon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "RIMP620630HM0",
                    "FECHA INICIAL": "2022-07-15",
                    "FECHA OPERACION": "2022-07-15",
                    "NOMBRES": "Pedro Arturo",
                    "APELLIDOS": "Rico Martinez",
                    "NOMBRES COMPLETOS": "Pedro Arturo Rico Martinez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Brian Yukio Nishizaki Simon",
                    "CODIGO USUARIO": "prico",
                    "USUARIOFIVE9": "prico@aspel.com.mx",
                    "USUARIO RED": "prico",
                    "CORREOELECTRONICO": "prico@aspel.com.mx",
                    "STATION ID": "2606857",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Brian Yukio Nishizaki Simon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1079096257",
                    "FECHA INICIAL": "2023-01-13",
                    "FECHA OPERACION": "2023-01-19",
                    "NOMBRES": "Yaneyd",
                    "APELLIDOS": "Waldo Quinto",
                    "NOMBRES COMPLETOS": "Yaneydi Waldo Quinto",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Arturo Alvarez Martinez",
                    "CODIGO USUARIO": "wald804587",
                    "USUARIOFIVE9": "wald804587@siigo.com",
                    "USUARIO RED": "wald804587",
                    "CORREOELECTRONICO": "wald804587@siigo.com",
                    "STATION ID": "3127596",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Arturo Alvarez Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "EASV880320DRA",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Vicente",
                    "APELLIDOS": "Estrada Sanchez",
                    "NOMBRES COMPLETOS": "Vicente Estrada Sanchez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Mexico",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Carlos Mario Espinosa Fernandez",
                    "CODIGO USUARIO": "vestrada",
                    "USUARIOFIVE9": "vestrada@aspel.com.mx",
                    "USUARIO RED": "vestrada",
                    "CORREOELECTRONICO": "vestrada@aspel.com.mx",
                    "STATION ID": "3483726",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Mario Espinosa Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "950906",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Enrique",
                    "APELLIDOS": "Vargas Dominguez",
                    "NOMBRES COMPLETOS": "Enrique Vargas Dominguez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Divulgacion",
                    "CARGO": "Divulgacion",
                    "JEFE INMEDIATO": "Carlos Maximo Garrido Mascott",
                    "CODIGO USUARIO": "evargas",
                    "USUARIOFIVE9": "evargas@aspel.com.mx",
                    "USUARIO RED": "evargas",
                    "CORREOELECTRONICO": "evargas@aspel.com.mx",
                    "STATION ID": "3857164",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Carlos Maximo Garrido Mascott",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "780427",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Maria Jose",
                    "APELLIDOS": "Palma Fernandez",
                    "NOMBRES COMPLETOS": "Maria Jose Palma Fernandez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Divulgacion",
                    "CARGO": "Divulgacion",
                    "JEFE INMEDIATO": "Carlos Maximo Garrido Mascott",
                    "CODIGO USUARIO": "mjfernandez",
                    "USUARIOFIVE9": "mjfernandez@aspel.com.mx",
                    "USUARIO RED": "mjfernandez",
                    "CORREOELECTRONICO": "mjfernandez@aspel.com.mx",
                    "STATION ID": "2606296",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Carlos Maximo Garrido Mascott",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "710315",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Juana Maria",
                    "APELLIDOS": "Alonso Maldonado",
                    "NOMBRES COMPLETOS": "Juana Maria Alonso Maldonado",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Divulgacion",
                    "CARGO": "Divulgacion",
                    "JEFE INMEDIATO": "Carlos Maximo Garrido Mascott",
                    "CODIGO USUARIO": "jmaldonado",
                    "USUARIOFIVE9": "jmaldonado@aspel.com.mx",
                    "USUARIO RED": "jmaldonado",
                    "CORREOELECTRONICO": "jmaldonado@aspel.com.mx",
                    "STATION ID": "2606457",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Carlos Maximo Garrido Mascott",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "870711",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Diego Alejandro",
                    "APELLIDOS": "Coronel Garcia",
                    "NOMBRES COMPLETOS": "Diego Alejandro Coronel Garcia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Divulgacion",
                    "CARGO": "Divulgacion",
                    "JEFE INMEDIATO": "Carlos Maximo Garrido Mascott",
                    "CODIGO USUARIO": "dcoronel",
                    "USUARIOFIVE9": "dcoronel@aspel.com.mx",
                    "USUARIO RED": "dcoronel",
                    "CORREOELECTRONICO": "dcoronel@aspel.com.mx",
                    "STATION ID": "2606549",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Carlos Maximo Garrido Mascott",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "910326",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Alejandra Zuleidha",
                    "APELLIDOS": "Campos Aguilar",
                    "NOMBRES COMPLETOS": "Alejandra Zuleidha Campos Aguilar",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Divulgacion",
                    "CARGO": "Divulgacion",
                    "JEFE INMEDIATO": "Carlos Maximo Garrido Mascott",
                    "CODIGO USUARIO": "acampos",
                    "USUARIOFIVE9": "acampos@aspel.com.mx",
                    "USUARIO RED": "acampos",
                    "CORREOELECTRONICO": "acampos@aspel.com.mx",
                    "STATION ID": "2606962",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Carlos Maximo Garrido Mascott",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MAPG830411JV9",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Gerardo",
                    "APELLIDOS": "Marquez Perez",
                    "NOMBRES COMPLETOS": "Gerardo Marquez Perez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Mexico",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Paul Rogelio Fuentes Tabarez",
                    "CODIGO USUARIO": "gmarquez",
                    "USUARIOFIVE9": "gmarquez@aspel.com.mx",
                    "USUARIO RED": "gmarquez",
                    "CORREOELECTRONICO": "gmarquez@aspel.com.mx",
                    "STATION ID": "3857146",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Paul Rogelio Fuentes Tabarez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "HEJE980730JH3",
                    "FECHA INICIAL": "2022-06-01",
                    "FECHA OPERACION": "2022-06-07",
                    "NOMBRES": "Emmanuel",
                    "APELLIDOS": "Hernandez Jimenez",
                    "NOMBRES COMPLETOS": "Emmanuel Hernandez Jimenez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call ADM",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Cristian Camilo Hernandez Gonzalez",
                    "CODIGO USUARIO": "ehernandez",
                    "USUARIOFIVE9": "ehernandez@aspel.com.mx",
                    "USUARIO RED": "ehernandez",
                    "CORREOELECTRONICO": "ehernandez@aspel.com.mx",
                    "STATION ID": "2606363",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Cristian Camilo Hernandez Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SOAY9208226SA",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Yessica Yadira",
                    "APELLIDOS": "Solis Aguirre",
                    "NOMBRES COMPLETOS": "Yessica Yadira Solis Aguirre",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional MX",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "ysolis",
                    "USUARIOFIVE9": "ysolis@aspel.com.mx",
                    "USUARIO RED": "ysolis",
                    "CORREOELECTRONICO": "ysolis@aspel.com.mx",
                    "STATION ID": "3857153",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "CABF860410318",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Francisco Damian",
                    "APELLIDOS": "Carmona Briones",
                    "NOMBRES COMPLETOS": "Francisco Damian Carmona Briones",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional MX",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "fcarmona",
                    "USUARIOFIVE9": "fcarmona@aspel.com.mx",
                    "USUARIO RED": "fcarmona",
                    "CORREOELECTRONICO": "fcarmona@aspel.com.mx",
                    "STATION ID": "3857156",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SAHO9106164J6",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Olidia",
                    "APELLIDOS": "Sanchez Hernandez",
                    "NOMBRES COMPLETOS": "Olidia Sanchez Hernandez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Candelaria Marquez Vazquez",
                    "CODIGO USUARIO": "ohernandez",
                    "USUARIOFIVE9": "ohernandez@aspel.com.mx",
                    "USUARIO RED": "ohernandez",
                    "CORREOELECTRONICO": "ohernandez@aspel.com.mx",
                    "STATION ID": "3690268",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Candelaria Marquez Vazquez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "VAHF871207JQ7",
                    "FECHA INICIAL": "2022-05-09",
                    "FECHA OPERACION": "2022-08-05",
                    "NOMBRES": "Francisco Javier",
                    "APELLIDOS": "Vazquez Hernandez",
                    "NOMBRES COMPLETOS": "Francisco Javier Vazquez Hernandez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional MX",
                    "CARGO": "Ops Tech",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "fjavier",
                    "USUARIOFIVE9": "fjavier@aspel.com.mx",
                    "USUARIO RED": "fjavier",
                    "CORREOELECTRONICO": "fjavier@aspel.com.mx",
                    "STATION ID": "4421819",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SASB790809Q9A",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Brenda",
                    "APELLIDOS": "Sanchez Sarmiento",
                    "NOMBRES COMPLETOS": "Brenda Sanchez Sarmiento",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Rene Gerardo Paredes Alcantara",
                    "CODIGO USUARIO": "bsanchez",
                    "USUARIOFIVE9": "bsanchez@aspel.com.mx",
                    "USUARIO RED": "bsanchez",
                    "CORREOELECTRONICO": "bsanchez@aspel.com.mx",
                    "STATION ID": "3763939",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Rene Gerardo Paredes Alcantara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MORI900706EJ8",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Isaias",
                    "APELLIDOS": "Morales Rubio",
                    "NOMBRES COMPLETOS": "Isaias Morales Rubio",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Rene Gerardo Paredes Alcantara",
                    "CODIGO USUARIO": "imorales",
                    "USUARIOFIVE9": "imorales@aspel.com.mx",
                    "USUARIO RED": "imorales",
                    "CORREOELECTRONICO": "imorales@aspel.com.mx",
                    "STATION ID": "3857149",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Rene Gerardo Paredes Alcantara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "ROGG860918PC7",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Gustavo",
                    "APELLIDOS": "Rodriguez Garcia",
                    "NOMBRES COMPLETOS": "Gustavo Rodriguez Garcia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Rodriguez Castro",
                    "CODIGO USUARIO": "grodriguez",
                    "USUARIOFIVE9": "grodriguez@aspel.com.mx",
                    "USUARIO RED": "grodriguez",
                    "CORREOELECTRONICO": "grodriguez@aspel.com.mx",
                    "STATION ID": "3857157",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Rodriguez Castro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MOCE9301243F0",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Erick Andres",
                    "APELLIDOS": "Morales Cuevas",
                    "NOMBRES COMPLETOS": "Erick Andres Morales Cuevas",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Rene Gerardo Paredes Alcantara",
                    "CODIGO USUARIO": "emorales",
                    "USUARIOFIVE9": "emorales@aspel.com.mx",
                    "USUARIO RED": "emorales",
                    "CORREOELECTRONICO": "emorales@aspel.com.mx",
                    "STATION ID": "3857159",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Rene Gerardo Paredes Alcantara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "BECB930724P42",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Berenice",
                    "APELLIDOS": "Bello Camacho",
                    "NOMBRES COMPLETOS": "Berenice Bello Camacho",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Rodriguez Castro",
                    "CODIGO USUARIO": "bbello",
                    "USUARIOFIVE9": "bbello@aspel.com.mx",
                    "USUARIO RED": "bbello",
                    "CORREOELECTRONICO": "bbello@aspel.com.mx",
                    "STATION ID": "3857160",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Rodriguez Castro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SILJ921015HQ6",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jesus Francisco",
                    "APELLIDOS": "Silva Leal",
                    "NOMBRES COMPLETOS": "Jesus Francisco Silva Leal",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Rene Gerardo Paredes Alcantara",
                    "CODIGO USUARIO": "jleal",
                    "USUARIOFIVE9": "jleal@aspel.com.mx",
                    "USUARIO RED": "jleal",
                    "CORREOELECTRONICO": "jleal@aspel.com.mx",
                    "STATION ID": "3857161",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Rene Gerardo Paredes Alcantara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "ROPS930604RR3",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Sergio",
                    "APELLIDOS": "Rodriguez Pineda",
                    "NOMBRES COMPLETOS": "Sergio Rodriguez Pineda",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Rene Gerardo Paredes Alcantara",
                    "CODIGO USUARIO": "spineda",
                    "USUARIOFIVE9": "spineda@aspel.com.mx",
                    "USUARIO RED": "spineda",
                    "CORREOELECTRONICO": "spineda@aspel.com.mx",
                    "STATION ID": "3857166",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Rene Gerardo Paredes Alcantara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "GUMC920321M90",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Cristian Jair",
                    "APELLIDOS": "Guzman Mancilla",
                    "NOMBRES COMPLETOS": "Cristian Jair Guzman Mancilla",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Rene Gerardo Paredes Alcantara",
                    "CODIGO USUARIO": "cguzman",
                    "USUARIOFIVE9": "cguzman@aspel.com.mx",
                    "USUARIO RED": "cguzman",
                    "CORREOELECTRONICO": "cguzman@aspel.com.mx",
                    "STATION ID": "3857170",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Rene Gerardo Paredes Alcantara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MEGA900919QG4",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Adriana Mireya",
                    "APELLIDOS": "Mesas Galvan",
                    "NOMBRES COMPLETOS": "Adriana Mireya Mesas Galvan",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Rodriguez Castro",
                    "CODIGO USUARIO": "amesas",
                    "USUARIOFIVE9": "amesas@aspel.com.mx",
                    "USUARIO RED": "amesas",
                    "CORREOELECTRONICO": "amesas@aspel.com.mx",
                    "STATION ID": "3857173",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Rodriguez Castro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "OIFI930505KI7",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Irving",
                    "APELLIDOS": "Olivares Felix",
                    "NOMBRES COMPLETOS": "Irving Olivares Felix",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Rene Gerardo Paredes Alcantara",
                    "CODIGO USUARIO": "iolivares",
                    "USUARIOFIVE9": "iolivares@aspel.com.mx",
                    "USUARIO RED": "iolivares",
                    "CORREOELECTRONICO": "iolivares@aspel.com.mx",
                    "STATION ID": "3857174",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Rene Gerardo Paredes Alcantara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "AUGJ911110IK9",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Jenifer",
                    "APELLIDOS": "Aquino Garcia",
                    "NOMBRES COMPLETOS": "Jenifer Aquino Garcia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Rodriguez Castro",
                    "CODIGO USUARIO": "jagarcia",
                    "USUARIOFIVE9": "jagarcia@aspel.com.mx",
                    "USUARIO RED": "jagarcia",
                    "CORREOELECTRONICO": "jagarcia@aspel.com.mx",
                    "STATION ID": "4421847",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Rodriguez Castro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "BOPA871222DD8",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-18",
                    "NOMBRES": "Adrian",
                    "APELLIDOS": "Borja Primitivo",
                    "NOMBRES COMPLETOS": "Adrian Borja Primitivo",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Rodriguez Castro",
                    "CODIGO USUARIO": "aborja",
                    "USUARIOFIVE9": "aborja@aspel.com.mx",
                    "USUARIO RED": "aborja",
                    "CORREOELECTRONICO": "aborja@aspel.com.mx",
                    "STATION ID": "4421830",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Rodriguez Castro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "HESF860102ME3",
                    "FECHA INICIAL": "2022-05-09",
                    "FECHA OPERACION": "2022-08-05",
                    "NOMBRES": "Fernando",
                    "APELLIDOS": "Hernandez Salas",
                    "NOMBRES COMPLETOS": "Fernando Hernandez Salas",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Rene Gerardo Paredes Alcantara",
                    "CODIGO USUARIO": "fhernandez",
                    "USUARIOFIVE9": "fhernandez@aspel.com.mx",
                    "USUARIO RED": "fhernandez",
                    "CORREOELECTRONICO": "fhernandez@aspel.com.mx",
                    "STATION ID": "4421821",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Rene Gerardo Paredes Alcantara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "PAAR800314D97",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Rene Gerardo",
                    "APELLIDOS": "Paredes Alcantara",
                    "NOMBRES COMPLETOS": "Rene Gerardo Paredes Alcantara",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Mexico",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Vicente Estrada Sanchez",
                    "CODIGO USUARIO": "rparedes",
                    "USUARIOFIVE9": "rparedes@aspel.com.mx",
                    "USUARIO RED": "rparedes",
                    "CORREOELECTRONICO": "rparedes@aspel.com.mx",
                    "STATION ID": "3857147",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Vicente Estrada Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "CACG870817MX2",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Gabriel",
                    "APELLIDOS": "Chavez Carrera",
                    "NOMBRES COMPLETOS": "Gabriel Chavez Carrera",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Ecuador",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "gchavez",
                    "USUARIOFIVE9": "gchavez@aspel.com.mx",
                    "USUARIO RED": "gchavez",
                    "CORREOELECTRONICO": "gchavez@aspel.com.mx",
                    "STATION ID": "3857155",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "LOVA911112GP3",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Armando Uriel",
                    "APELLIDOS": "Lopez Vazquez",
                    "NOMBRES COMPLETOS": "Armando Uriel Lopez Vazquez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Gerardo Marquez Perez",
                    "CODIGO USUARIO": "ulopez",
                    "USUARIOFIVE9": "ulopez@aspel.com.mx",
                    "USUARIO RED": "ulopez",
                    "CORREOELECTRONICO": "ulopez@aspel.com.mx",
                    "STATION ID": "3857163",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gerardo Marquez Perez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "BALA9005049I8",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Adair",
                    "APELLIDOS": "Blanco Landin",
                    "NOMBRES COMPLETOS": "Adair Blanco Landin",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Mexico",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Vicente Estrada Sanchez",
                    "CODIGO USUARIO": "ablanco",
                    "USUARIOFIVE9": "ablanco@aspel.com.mx",
                    "USUARIO RED": "ablanco",
                    "CORREOELECTRONICO": "ablanco@aspel.com.mx",
                    "STATION ID": "4421842",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Vicente Estrada Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "AUFA980525JK9",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Alejandro",
                    "APELLIDOS": "Aguilar Franco",
                    "NOMBRES COMPLETOS": "Alejandro Aguilar Franco",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Alejandro Roa Franco",
                    "CODIGO USUARIO": "faguilar",
                    "USUARIOFIVE9": "faguilar@aspel.com.mx",
                    "USUARIO RED": "faguilar",
                    "CORREOELECTRONICO": "faguilar@aspel.com.mx",
                    "STATION ID": "2606222",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Alejandro Roa Franco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MOPA980314JK1",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Aldair",
                    "APELLIDOS": "Moreno Perez",
                    "NOMBRES COMPLETOS": "Aldair Moreno Perez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Alejandro Roa Franco",
                    "CODIGO USUARIO": "amperez",
                    "USUARIOFIVE9": "amperez@aspel.com.mx",
                    "USUARIO RED": "amperez",
                    "CORREOELECTRONICO": "amperez@aspel.com.mx",
                    "STATION ID": "3763895",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Alejandro Roa Franco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "RICB8112278D3",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Bismark Ivan",
                    "APELLIDOS": "Rios Campos",
                    "NOMBRES COMPLETOS": "Bismark Ivan Rios Campos",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Lider Perfilamiento",
                    "JEFE INMEDIATO": "Harrison Martinez Callejas",
                    "CODIGO USUARIO": "brios",
                    "USUARIOFIVE9": "brios@aspel.com.mx",
                    "USUARIO RED": "brios",
                    "CORREOELECTRONICO": "brios@aspel.com.mx",
                    "STATION ID": "3127541",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Harrison Martinez Callejas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "CULC970808QJ6",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Cecilia",
                    "APELLIDOS": "Cruz Luna",
                    "NOMBRES COMPLETOS": "Cecilia Cruz Luna",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Alejandro Roa Franco",
                    "CODIGO USUARIO": "cluna",
                    "USUARIOFIVE9": "ccruz@aspel.com.mx",
                    "USUARIO RED": "cluna",
                    "CORREOELECTRONICO": "ccruz@aspel.com.mx",
                    "STATION ID": "3763913",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Alejandro Roa Franco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "CAFJ860530HF3",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jannet",
                    "APELLIDOS": "Casco Flores",
                    "NOMBRES COMPLETOS": "Jannet Casco Flores",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Alejandro Roa Franco",
                    "CODIGO USUARIO": "jcasco",
                    "USUARIOFIVE9": "jcasco@aspel.com.mx",
                    "USUARIO RED": "jcasco",
                    "CORREOELECTRONICO": "jcasco@aspel.com.mx",
                    "STATION ID": "3763918",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Alejandro Roa Franco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "AEJS920201GR9",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Sandra Azucena",
                    "APELLIDOS": "Arrellano Juarez",
                    "NOMBRES COMPLETOS": "Sandra Azucena Arellano Juarez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Alejandro Roa Franco",
                    "CODIGO USUARIO": "sarellano",
                    "USUARIOFIVE9": "sarellano@aspel.com.mx",
                    "USUARIO RED": "sarellano",
                    "CORREOELECTRONICO": "sarellano@aspel.com.mx",
                    "STATION ID": "3763920",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Alejandro Roa Franco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "CUAE8211046Y7",
                    "FECHA INICIAL": "2022-04-28",
                    "FECHA OPERACION": "2022-04-28",
                    "NOMBRES": "Emilio",
                    "APELLIDOS": "Cuellar Arroyo",
                    "NOMBRES COMPLETOS": "Emilio Cuellar Arroyo",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Alejandro Roa Franco",
                    "CODIGO USUARIO": "ecuellar",
                    "USUARIOFIVE9": "ecuellar@aspel.com.mx",
                    "USUARIO RED": "ecuellar",
                    "CORREOELECTRONICO": "ecuellar@aspel.com.mx",
                    "STATION ID": "3857184",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Alejandro Roa Franco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MAGD970807T59",
                    "FECHA INICIAL": "2022-04-28",
                    "FECHA OPERACION": "2022-04-28",
                    "NOMBRES": "Donay Arnaldo",
                    "APELLIDOS": "Martinez Garcia",
                    "NOMBRES COMPLETOS": "Donay Arnaldo Martinez Garcia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Alejandro Roa Franco",
                    "CODIGO USUARIO": "dmartinez",
                    "USUARIOFIVE9": "dmartinez@aspel.com.mx",
                    "USUARIO RED": "dmartinez",
                    "CORREOELECTRONICO": "dmartinez@aspel.com.mx",
                    "STATION ID": "3857186",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Alejandro Roa Franco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "CARA000818KY3",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-27",
                    "NOMBRES": "Alfredo",
                    "APELLIDOS": "Campos Rodriguez",
                    "NOMBRES COMPLETOS": "Alfredo Campos Rodriguez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Oscar Rodrigo Navarro Hernandez",
                    "CODIGO USUARIO": "acamposr",
                    "USUARIOFIVE9": "acamposr@aspel.com.mx",
                    "USUARIO RED": "acamposr",
                    "CORREOELECTRONICO": "acamposr@aspel.com.mx",
                    "STATION ID": "3720468",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Oscar Rodrigo Navarro Hernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MEHH991009TP6",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-27",
                    "NOMBRES": "Hector",
                    "APELLIDOS": "Meza Hernandez",
                    "NOMBRES COMPLETOS": "Hector Meza Hernandez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Alejandro Roa Franco",
                    "CODIGO USUARIO": "hmeza",
                    "USUARIOFIVE9": "hmeza@aspel.com.mx",
                    "USUARIO RED": "hmeza",
                    "CORREOELECTRONICO": "hmeza@aspel.com.mx",
                    "STATION ID": "3763907",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Alejandro Roa Franco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "CADA980428EP1",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-27",
                    "NOMBRES": "Ariday Danae",
                    "APELLIDOS": "Casarin Delgado",
                    "NOMBRES COMPLETOS": "Ariday Danae Casarin Delgado",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Alejandro Roa Franco",
                    "CODIGO USUARIO": "acasarin",
                    "USUARIOFIVE9": "acasarin@aspel.com.mx",
                    "USUARIO RED": "acasarin",
                    "CORREOELECTRONICO": "acasarin@aspel.com.mx",
                    "STATION ID": "3763911",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Alejandro Roa Franco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "HEEL910904JH9",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-27",
                    "NOMBRES": "Luis Alexander",
                    "APELLIDOS": "Hernandez Escorcia",
                    "NOMBRES COMPLETOS": "Luis Alexander Hernandez Escorcia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Alejandro Roa Franco",
                    "CODIGO USUARIO": "lhernandez",
                    "USUARIOFIVE9": "lhernandez@aspel.com.mx",
                    "USUARIO RED": "lhernandez",
                    "CORREOELECTRONICO": "lhernandez@aspel.com.mx",
                    "STATION ID": "3763925",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Alejandro Roa Franco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1025142104",
                    "FECHA INICIAL": "2022-04-05",
                    "FECHA OPERACION": "2022-05-04",
                    "NOMBRES": "Nicole Karina",
                    "APELLIDOS": "Arandia Castaneda",
                    "NOMBRES COMPLETOS": "Nicole Karina Arandia Castaneda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cultura",
                    "SUBAREA": "Cultura",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Karen Alejandra Casas Mesa",
                    "CODIGO USUARIO": "aran802854",
                    "USUARIOFIVE9": "aran802854@siigo.com",
                    "USUARIO RED": "aran802854",
                    "CORREOELECTRONICO": "aran802854@siigo.com",
                    "STATION ID": "3763899",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cultura_Karen Alejandra Casas Mesa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "35384453",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-04",
                    "NOMBRES": "Wilson Fernando",
                    "APELLIDOS": "Baez Bentos",
                    "NOMBRES COMPLETOS": "Wilson Fernando Baez Bentos",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "Call Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alcides Martin Salas Orozco",
                    "CODIGO USUARIO": "baez802899",
                    "USUARIOFIVE9": "baez802899@siigo.com",
                    "USUARIO RED": "baez802899",
                    "CORREOELECTRONICO": "baez802899@siigo.com",
                    "STATION ID": "3763900",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alcides Martin Salas Orozco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "48447086",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-04",
                    "NOMBRES": "Brahyan Matias",
                    "APELLIDOS": "Pacheco Abeldano",
                    "NOMBRES COMPLETOS": "Brahyan Matias Pacheco Abeldano",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Elias Jusid Machado",
                    "CODIGO USUARIO": "pach802898",
                    "USUARIOFIVE9": "pach802898@siigo.com",
                    "USUARIO RED": "pach802898",
                    "CORREOELECTRONICO": "pach802898@siigo.com",
                    "STATION ID": "3763901",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Elias Jusid Machado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52210926",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-04",
                    "NOMBRES": "Sandra Marithza",
                    "APELLIDOS": "Cisneros Lopez",
                    "NOMBRES COMPLETOS": "Sandra Marithza Cisneros Lopez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "cisn802909",
                    "USUARIOFIVE9": "cisn802909@siigo.com",
                    "USUARIO RED": "cisn802909",
                    "CORREOELECTRONICO": "cisn802909@siigo.com",
                    "STATION ID": "3763904",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "BARS9212039V9",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Scarlet Del Carmen",
                    "APELLIDOS": "Bravo Ramos",
                    "NOMBRES COMPLETOS": "Scarlet Del Carmen Bravo Ramos",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Oscar Rodrigo Navarro Hernandez",
                    "CODIGO USUARIO": "sbravo",
                    "USUARIOFIVE9": "sbravo@aspel.com.mx",
                    "USUARIO RED": "sbravo",
                    "CORREOELECTRONICO": "sbravo@aspel.com.mx",
                    "STATION ID": "3763915",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Oscar Rodrigo Navarro Hernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "VACM900407LZA",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Marisol",
                    "APELLIDOS": "Vargas Cortes",
                    "NOMBRES COMPLETOS": "Marisol Vargas Cortes",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Libia Amparo Osorio Rodriguez",
                    "CODIGO USUARIO": "mcortes",
                    "USUARIOFIVE9": "mcortes@aspel.com.mx",
                    "USUARIO RED": "mcortes",
                    "CORREOELECTRONICO": "mcortes@aspel.com.mx",
                    "STATION ID": "3857131",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Libia Amparo Osorio Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SAMR931209149",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Ricardo Johnatan",
                    "APELLIDOS": "San Nicolas Morales",
                    "NOMBRES COMPLETOS": "Ricardo Johnatan San Nicolas Morales",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jorge Alfredo Huesca Martinez",
                    "CODIGO USUARIO": "rsan",
                    "USUARIOFIVE9": "rsan@aspel.com.mx",
                    "USUARIO RED": "rsan",
                    "CORREOELECTRONICO": "rsan@aspel.com.mx",
                    "STATION ID": "3763943",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jorge Alfredo Huesca Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "ROGA900207NS0",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Alma Rosa",
                    "APELLIDOS": "Romero Garcia",
                    "NOMBRES COMPLETOS": "Alma Rosa Romero Garcia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Jarbin Dadey Rodriguez Pena",
                    "CODIGO USUARIO": "aromero",
                    "USUARIOFIVE9": "aromero@asple.com.mx",
                    "USUARIO RED": "aromero",
                    "CORREOELECTRONICO": "aromero@asple.com.mx",
                    "STATION ID": "3763945",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jarbin Dadey Rodriguez Pena",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "REGL880311G20",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Layla Edith",
                    "APELLIDOS": "Reyes Gutierrez",
                    "NOMBRES COMPLETOS": "Layla Edith Reyes Gutierrez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jorge Alfredo Huesca Martinez",
                    "CODIGO USUARIO": "lereyes",
                    "USUARIOFIVE9": "lereyes@aspel.com.mx",
                    "USUARIO RED": "lereyes",
                    "CORREOELECTRONICO": "lereyes@aspel.com.mx",
                    "STATION ID": "3483692",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jorge Alfredo Huesca Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "LORO900309344",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Oscar Ezequiel",
                    "APELLIDOS": "Lopez Rosas",
                    "NOMBRES COMPLETOS": "Oscar Ezequiel Lopez Rosas",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jorge Alfredo Huesca Martinez",
                    "CODIGO USUARIO": "oerosas",
                    "USUARIOFIVE9": "oerosas@aspel.com.mx",
                    "USUARIO RED": "oerosas",
                    "CORREOELECTRONICO": "oerosas@aspel.com.mx",
                    "STATION ID": "3763837",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jorge Alfredo Huesca Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "ZUAJ941009QM9",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Juan Octavio",
                    "APELLIDOS": "Zuniga Acosta",
                    "NOMBRES COMPLETOS": "Juan Octavio Zuniga Acosta",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Oscar Rodrigo Navarro Hernandez",
                    "CODIGO USUARIO": "jacosta",
                    "USUARIOFIVE9": "jacosta@aspel.com.mx",
                    "USUARIO RED": "jacosta",
                    "CORREOELECTRONICO": "jacosta@aspel.com.mx",
                    "STATION ID": "3763864",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Oscar Rodrigo Navarro Hernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "PEGL780428M63",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Leticia",
                    "APELLIDOS": "Perez Gomez",
                    "NOMBRES COMPLETOS": "Leticia Perez Gomez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Carranza Valdes",
                    "CODIGO USUARIO": "lgomez",
                    "USUARIOFIVE9": "lgomez@aspel.com.mx",
                    "USUARIO RED": "lgomez",
                    "CORREOELECTRONICO": "lgomez@aspel.com.mx",
                    "STATION ID": "3763916",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Carranza Valdes",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MAAY9902111IA",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Yuridia",
                    "APELLIDOS": "Martinez Aguilar",
                    "NOMBRES COMPLETOS": "Yuridia Martinez Aguilar",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Alejandro Salgado Vergara",
                    "CODIGO USUARIO": "yaguilar",
                    "USUARIOFIVE9": "yaguilar@aspel.com.mx",
                    "USUARIO RED": "yaguilar",
                    "CORREOELECTRONICO": "yaguilar@aspel.com.mx",
                    "STATION ID": "3763922",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Alejandro Salgado Vergara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030617566",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-04",
                    "NOMBRES": "Gloria Alejandra",
                    "APELLIDOS": "Nino Pineda",
                    "NOMBRES COMPLETOS": "Gloria Alejandra Nino Pineda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "nino802908",
                    "USUARIOFIVE9": "nino802908@siigo.com",
                    "USUARIO RED": "nino802908",
                    "CORREOELECTRONICO": "nino802908@siigo.com",
                    "STATION ID": "3763903",
                    "ANTIGUEDAD": ">= 60 Dias",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "LOPM9608222Y3",
                    "FECHA INICIAL": "2022-04-28",
                    "FECHA OPERACION": "2022-04-28",
                    "NOMBRES": "Marco Antonio",
                    "APELLIDOS": "Lopez Pena",
                    "NOMBRES COMPLETOS": "Marco Antonio Lopez Pena",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jorge Alfredo Huesca Martinez",
                    "CODIGO USUARIO": "mpena",
                    "USUARIOFIVE9": "mpena@aspel.com.mx",
                    "USUARIO RED": "mpena",
                    "CORREOELECTRONICO": "mpena@aspel.com.mx",
                    "STATION ID": "3857178",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jorge Alfredo Huesca Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "BOSP980829PX1",
                    "FECHA INICIAL": "2022-04-28",
                    "FECHA OPERACION": "2022-04-28",
                    "NOMBRES": "Pedro",
                    "APELLIDOS": "Bonilla Sosa",
                    "NOMBRES COMPLETOS": "Pedro Bonilla Sosa",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jorge Alfredo Huesca Martinez",
                    "CODIGO USUARIO": "pbonilla",
                    "USUARIOFIVE9": "pbonilla@aspel.com.mx",
                    "USUARIO RED": "pbonilla",
                    "CORREOELECTRONICO": "pbonilla@aspel.com.mx",
                    "STATION ID": "3857179",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jorge Alfredo Huesca Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MAGM821230965",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-27",
                    "NOMBRES": "Miguel",
                    "APELLIDOS": "Maldonado Garcia",
                    "NOMBRES COMPLETOS": "Miguel Maldonado Garcia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jorge Alfredo Huesca Martinez",
                    "CODIGO USUARIO": "mmaldonado",
                    "USUARIOFIVE9": "mmaldonado@aspel.com.mx",
                    "USUARIO RED": "mmaldonado",
                    "CORREOELECTRONICO": "mmaldonado@aspel.com.mx",
                    "STATION ID": "2606772",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jorge Alfredo Huesca Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "AUGA991108DT3",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-27",
                    "NOMBRES": "Altair Alfonso",
                    "APELLIDOS": "Aguilar Gonzalez",
                    "NOMBRES COMPLETOS": "Altair Alfonso Aguilar Gonzalez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jorge Alfredo Huesca Martinez",
                    "CODIGO USUARIO": "aaguilar",
                    "USUARIOFIVE9": "aaguilar@aspel.com.mx",
                    "USUARIO RED": "aaguilar",
                    "CORREOELECTRONICO": "aaguilar@aspel.com.mx",
                    "STATION ID": "3720442",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jorge Alfredo Huesca Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "VAER970501KN7",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-27",
                    "NOMBRES": "Jose Ruben",
                    "APELLIDOS": "Valdivia Espinosa",
                    "NOMBRES COMPLETOS": "Jose Ruben Valdivia Espinosa",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jorge Alfredo Huesca Martinez",
                    "CODIGO USUARIO": "jvaldivia",
                    "USUARIOFIVE9": "jvaldivia@aspel.com.mx",
                    "USUARIO RED": "jvaldivia",
                    "CORREOELECTRONICO": "jvaldivia@aspel.com.mx",
                    "STATION ID": "3720463",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jorge Alfredo Huesca Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MASL990212QI2",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-27",
                    "NOMBRES": "Luis Antonio",
                    "APELLIDOS": "Martinez Sosa",
                    "NOMBRES COMPLETOS": "Luis Antonio Martinez Sosa",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jorge Alfredo Huesca Martinez",
                    "CODIGO USUARIO": "lmartinez",
                    "USUARIOFIVE9": "lmartinez@aspel.com.mx",
                    "USUARIO RED": "lmartinez",
                    "CORREOELECTRONICO": "lmartinez@aspel.com.mx",
                    "STATION ID": "3763862",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jorge Alfredo Huesca Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "BAGL9907098Y5",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-27",
                    "NOMBRES": "Liz Fernanda",
                    "APELLIDOS": "Baltazar Garcia",
                    "NOMBRES COMPLETOS": "Liz Fernanda Baltazar Garcia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jorge Alfredo Huesca Martinez",
                    "CODIGO USUARIO": "lbaltazar",
                    "USUARIOFIVE9": "lbaltazar@aspel.com.mx",
                    "USUARIO RED": "lbaltazar",
                    "CORREOELECTRONICO": "lbaltazar@aspel.com.mx",
                    "STATION ID": "3763863",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jorge Alfredo Huesca Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "BAEA970822NR2",
                    "FECHA INICIAL": "2022-06-01",
                    "FECHA OPERACION": "2022-06-07",
                    "NOMBRES": "Aldhair Antonio",
                    "APELLIDOS": "Blanchot Escalona",
                    "NOMBRES COMPLETOS": "Aldhair Antonio Blanchot Escalona",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jorge Alfredo Huesca Martinez",
                    "CODIGO USUARIO": "ablanchot",
                    "USUARIOFIVE9": "ablanchot@aspel.com.mx",
                    "USUARIO RED": "ablanchot",
                    "CORREOELECTRONICO": "ablanchot@aspel.com.mx",
                    "STATION ID": "3267914",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jorge Alfredo Huesca Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "LODC910321KQ7",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jose Carlos",
                    "APELLIDOS": "Lopez Diaz",
                    "NOMBRES COMPLETOS": "Jose Carlos Lopez Diaz",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jorge Alfredo Huesca Martinez",
                    "CODIGO USUARIO": "jlopezd",
                    "USUARIOFIVE9": "jlopezd@aspel.com.mx",
                    "USUARIO RED": "jlopezd",
                    "CORREOELECTRONICO": "jlopezd@aspel.com.mx",
                    "STATION ID": "3763854",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jorge Alfredo Huesca Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "VEMK9510313B6",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Karla Angelica",
                    "APELLIDOS": "Velazquez Maqueda",
                    "NOMBRES COMPLETOS": "Karla Angelica Velazquez Maqueda",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Carranza Valdes",
                    "CODIGO USUARIO": "kavelazquez",
                    "USUARIOFIVE9": "kavelazquez@aspel.com.mx",
                    "USUARIO RED": "kavelazquez",
                    "CORREOELECTRONICO": "kavelazquez@aspel.com.mx",
                    "STATION ID": "3763852",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Carranza Valdes",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "CICE851227EX1",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-07-07",
                    "NOMBRES": "Emmanuel",
                    "APELLIDOS": "Chin Chan",
                    "NOMBRES COMPLETOS": "Emmanuel Chin Chan",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Tech",
                    "SUBAREA": "Soporte IT",
                    "CARGO": "Tech Lead",
                    "JEFE INMEDIATO": "Jonathan Oviedo Gonzalez",
                    "CODIGO USUARIO": "echin",
                    "USUARIOFIVE9": "echin@aspel.com.mx",
                    "USUARIO RED": "echin",
                    "CORREOELECTRONICO": "echin@aspel.com.mx",
                    "STATION ID": "2606115",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Jonathan Oviedo Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MABJ9312256J8",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jesus Enrique",
                    "APELLIDOS": "Martinez Baez",
                    "NOMBRES COMPLETOS": "Jesus Enrique Martinez Baez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Carranza Valdes",
                    "CODIGO USUARIO": "jbaez",
                    "USUARIOFIVE9": "jbaez@aspel.com.mx",
                    "USUARIO RED": "jbaez",
                    "CORREOELECTRONICO": "jbaez@aspel.com.mx",
                    "STATION ID": "3471497",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Carranza Valdes",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MOCA8012014R9",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Alexander",
                    "APELLIDOS": "Montes De Oca Campos",
                    "NOMBRES COMPLETOS": "Alexander Montes De Oca Campos",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Carranza Valdes",
                    "CODIGO USUARIO": "amcampos",
                    "USUARIOFIVE9": "amcampos@aspel.com.mx",
                    "USUARIO RED": "amcampos",
                    "CORREOELECTRONICO": "amcampos@aspel.com.mx",
                    "STATION ID": "3690250",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Carranza Valdes",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "CISM910222RR7",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jose Mario",
                    "APELLIDOS": "Cisneros Suastegui",
                    "NOMBRES COMPLETOS": "Jose Mario Cisneros Suastegui",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jorge Alfredo Huesca Martinez",
                    "CODIGO USUARIO": "mcisneros",
                    "USUARIOFIVE9": "mcisneros@aspel.com.mx",
                    "USUARIO RED": "mcisneros",
                    "CORREOELECTRONICO": "mcisneros@aspel.com.mx",
                    "STATION ID": "3690367",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jorge Alfredo Huesca Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "14253903",
                    "FECHA INICIAL": "2011-07-11",
                    "FECHA OPERACION": "2011-07-11",
                    "NOMBRES": "Jorge Alberto",
                    "APELLIDOS": "Parra Rocha",
                    "NOMBRES COMPLETOS": "Jorge Alberto Parra Rocha",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Angela Yolima Bohada Arias",
                    "CODIGO USUARIO": "parr1489",
                    "USUARIOFIVE9": "parr14989@siigo.com",
                    "USUARIO RED": "parr1489",
                    "CORREOELECTRONICO": "parr14989@siigo.com",
                    "STATION ID": "4421828",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Angela Yolima Bohada Arias",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1026255003",
                    "FECHA INICIAL": "2022-04-12",
                    "FECHA OPERACION": "2022-04-12",
                    "NOMBRES": "Hollman Antonio",
                    "APELLIDOS": "Aponte Quiroga",
                    "NOMBRES COMPLETOS": "Hollman Antonio Aponte Quiroga",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Director Mercadeo",
                    "JEFE INMEDIATO": "Claudio Federico Hornes Ismail",
                    "CODIGO USUARIO": "apon802858",
                    "USUARIOFIVE9": "apon802858@siigo.com",
                    "USUARIO RED": "apon802858",
                    "CORREOELECTRONICO": "apon802858@siigo.com",
                    "STATION ID": "2606102",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Claudio Federico Hornes Ismail",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010216526",
                    "FECHA INICIAL": "2022-03-29",
                    "FECHA OPERACION": "2022-03-29",
                    "NOMBRES": "Julieth Paola",
                    "APELLIDOS": "Vera Patino",
                    "NOMBRES COMPLETOS": "Julieth Paola Vera Patino",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Workfoce & Reporting",
                    "CARGO": "Gerente Workforce",
                    "JEFE INMEDIATO": "William Jesus Sotaquira Ayala",
                    "CODIGO USUARIO": "vera802839",
                    "USUARIOFIVE9": "vera802839@siigo.com",
                    "USUARIO RED": "vera802839",
                    "CORREOELECTRONICO": "vera802839@siigo.com",
                    "STATION ID": "3763872",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_William Jesus Sotaquira Ayala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MAMA951121588",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Alberto",
                    "APELLIDOS": "Martinez Martinez",
                    "NOMBRES COMPLETOS": "Alberto Martinez Martinez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Carranza Valdes",
                    "CODIGO USUARIO": "almartinez",
                    "USUARIOFIVE9": "almartinez@aspel.com.mx",
                    "USUARIO RED": "almartinez",
                    "CORREOELECTRONICO": "almartinez@aspel.com.mx",
                    "STATION ID": "3763887",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Carranza Valdes",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "CAFL950113R95",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jose Luis",
                    "APELLIDOS": "Chavez Flores",
                    "NOMBRES COMPLETOS": "Jose Luis Chavez Flores",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Carranza Valdes",
                    "CODIGO USUARIO": "jchavez",
                    "USUARIOFIVE9": "jchavez@aspel.com.mx",
                    "USUARIO RED": "jchavez",
                    "CORREOELECTRONICO": "jchavez@aspel.com.mx",
                    "STATION ID": "3763890",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Carranza Valdes",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "RAMI940302655",
                    "FECHA INICIAL": "2022-04-28",
                    "FECHA OPERACION": "2022-04-28",
                    "NOMBRES": "Isai",
                    "APELLIDOS": "Ramirez Mejia",
                    "NOMBRES COMPLETOS": "Isai Ramirez Mejia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Carranza Valdes",
                    "CODIGO USUARIO": "iramirez",
                    "USUARIOFIVE9": "iramirez@aspel.com.mx",
                    "USUARIO RED": "iramirez",
                    "CORREOELECTRONICO": "iramirez@aspel.com.mx",
                    "STATION ID": "3857181",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Carranza Valdes",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "RUHL990712F78",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-27",
                    "NOMBRES": "Leonardo",
                    "APELLIDOS": "Ruano Hernandez",
                    "NOMBRES COMPLETOS": "Leonardo Ruano Hernandez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Carranza Valdes",
                    "CODIGO USUARIO": "lruano",
                    "USUARIOFIVE9": "lruano@aspel.com.mx",
                    "USUARIO RED": "lruano",
                    "CORREOELECTRONICO": "lruano@aspel.com.mx",
                    "STATION ID": "3690291",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Carranza Valdes",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "RONE9404154A3",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Erick",
                    "APELLIDOS": "Rodriguez Nanco",
                    "NOMBRES COMPLETOS": "Erick Rodriguez Nanco",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Oscar Rodrigo Navarro Hernandez",
                    "CODIGO USUARIO": "enanco",
                    "USUARIOFIVE9": "enanco@aspel.com.mx",
                    "USUARIO RED": "enanco",
                    "CORREOELECTRONICO": "enanco@aspel.com.mx",
                    "STATION ID": "3690310",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Oscar Rodrigo Navarro Hernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "NALS970925LW8",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Sandra Isabel",
                    "APELLIDOS": "Najera Lopez",
                    "NOMBRES COMPLETOS": "Sandra Isabel Najera Lopez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Oscar Rodrigo Navarro Hernandez",
                    "CODIGO USUARIO": "inajera",
                    "USUARIOFIVE9": "inajera@aspel.com.mx",
                    "USUARIO RED": "inajera",
                    "CORREOELECTRONICO": "inajera@aspel.com.mx",
                    "STATION ID": "3763830",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Oscar Rodrigo Navarro Hernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "VAFE8902042V6",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Elios Daniel",
                    "APELLIDOS": "Vazquez Ferra",
                    "NOMBRES COMPLETOS": "Elios Daniel Vazquez Ferra",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Alejandro Salgado Vergara",
                    "CODIGO USUARIO": "eferra",
                    "USUARIOFIVE9": "evazquez@aspel.com.mx",
                    "USUARIO RED": "eferra",
                    "CORREOELECTRONICO": "evazquez@aspel.com.mx",
                    "STATION ID": "3763893",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Alejandro Salgado Vergara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "AOMD970227834",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-27",
                    "NOMBRES": "Daniela",
                    "APELLIDOS": "Aboytes Martinez",
                    "NOMBRES COMPLETOS": "Daniela Aboytes Martinez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Alejandro Salgado Vergara",
                    "CODIGO USUARIO": "daboytes",
                    "USUARIOFIVE9": "daboytes@aspel.com.mx",
                    "USUARIO RED": "daboytes",
                    "CORREOELECTRONICO": "daboytes@aspel.com.mx",
                    "STATION ID": "3763910",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Alejandro Salgado Vergara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "DIBE950318KX7",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jose Eduardo",
                    "APELLIDOS": "Diaz Brito",
                    "NOMBRES COMPLETOS": "Jose Eduardo Diaz Brito",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Oscar Rodrigo Navarro Hernandez",
                    "CODIGO USUARIO": "jbrito",
                    "USUARIOFIVE9": "jbrito@aspel.com.mx",
                    "USUARIO RED": "jbrito",
                    "CORREOELECTRONICO": "jbrito@aspel.com.mx",
                    "STATION ID": "3720391",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Oscar Rodrigo Navarro Hernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023879037",
                    "FECHA INICIAL": "2022-05-04",
                    "FECHA OPERACION": "2022-05-23",
                    "NOMBRES": "Ana Luidt",
                    "APELLIDOS": "Salamanca Wilches",
                    "NOMBRES COMPLETOS": "Ana Luidt Salamanca Wilches",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alison Anaid Chica Ezeta",
                    "CODIGO USUARIO": "sala802928",
                    "USUARIOFIVE9": "sala802928@siigo.com",
                    "USUARIO RED": "sala802928",
                    "CORREOELECTRONICO": "sala802928@siigo.com",
                    "STATION ID": "4421737",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Alison Anaid Chica Ezeta",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1052976367",
                    "FECHA INICIAL": "2022-05-04",
                    "FECHA OPERACION": "2022-05-23",
                    "NOMBRES": "Katerine Esther",
                    "APELLIDOS": "Aguirre Velasquez",
                    "NOMBRES COMPLETOS": "Katerine Esther Aguirre Velasquez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Cristhian David Nino Barrera",
                    "CODIGO USUARIO": "agui802932",
                    "USUARIOFIVE9": "agui802932@siigo.com",
                    "USUARIO RED": "agui802932",
                    "CORREOELECTRONICO": "agui802932@siigo.com",
                    "STATION ID": "4421741",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Cristhian David Nino Barrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52990575",
                    "FECHA INICIAL": "2022-05-04",
                    "FECHA OPERACION": "2022-05-23",
                    "NOMBRES": "Narda Viviana",
                    "APELLIDOS": "Jaramillo Pena",
                    "NOMBRES COMPLETOS": "Narda Viviana Jaramillo Pena",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Paula Alejandra Garcia Lujan",
                    "CODIGO USUARIO": "jara802925",
                    "USUARIOFIVE9": "jara802925@siigo.com",
                    "USUARIO RED": "jara802925",
                    "CORREOELECTRONICO": "jara802925@siigo.com",
                    "STATION ID": "4421747",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Paula Alejandra Garcia Lujan",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013599735",
                    "FECHA INICIAL": "2022-05-04",
                    "FECHA OPERACION": "2022-05-23",
                    "NOMBRES": "Jonathan Camilo",
                    "APELLIDOS": "Gonzalez Cortes",
                    "NOMBRES COMPLETOS": "Jonathan Camilo Gonzalez Cortes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Cristhian David Nino Barrera",
                    "CODIGO USUARIO": "gonz802923",
                    "USUARIOFIVE9": "gonz802923@siigo.com",
                    "USUARIO RED": "gonz802923",
                    "CORREOELECTRONICO": "gonz802923@siigo.com",
                    "STATION ID": "4421748",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Cristhian David Nino Barrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014273872",
                    "FECHA INICIAL": "2022-05-04",
                    "FECHA OPERACION": "2022-05-23",
                    "NOMBRES": "Wilmer Alejandro",
                    "APELLIDOS": "Correa Carmona",
                    "NOMBRES COMPLETOS": "Wilmer Alejandro Correa Carmona",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jessica Paola Paez Quintero",
                    "CODIGO USUARIO": "corr802921",
                    "USUARIOFIVE9": "corr802921@siigo.com",
                    "USUARIO RED": "corr802921",
                    "CORREOELECTRONICO": "corr802921@siigo.com",
                    "STATION ID": "4421750",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jessica Paola Paez Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016076458",
                    "FECHA INICIAL": "2022-05-04",
                    "FECHA OPERACION": "2022-05-23",
                    "NOMBRES": "Fredy Alejandro",
                    "APELLIDOS": "Urrego Perez",
                    "NOMBRES COMPLETOS": "Fredy Alejandro Urrego Perez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Girardot",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Aleida Bibiana Garcia Suarez",
                    "CODIGO USUARIO": "urre802919",
                    "USUARIOFIVE9": "urre802919@siigo.com",
                    "USUARIO RED": "urre802919",
                    "CORREOELECTRONICO": "urre802919@siigo.com",
                    "STATION ID": "4421752",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Aleida Bibiana Garcia Suarez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033694714",
                    "FECHA INICIAL": "2022-05-04",
                    "FECHA OPERACION": "2022-05-23",
                    "NOMBRES": "Sandra Liseth",
                    "APELLIDOS": "Bernal",
                    "NOMBRES COMPLETOS": "Sandra Liseth Bernal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Villavicencio",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Yuleidy Josefina Briceno Fernandez",
                    "CODIGO USUARIO": "bern802918",
                    "USUARIOFIVE9": "bern802918@siigo.com",
                    "USUARIO RED": "bern802918",
                    "CORREOELECTRONICO": "bern802918@siigo.com",
                    "STATION ID": "4421753",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Yuleidy Josefina Briceno Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1043006034",
                    "FECHA INICIAL": "2022-05-04",
                    "FECHA OPERACION": "2022-05-23",
                    "NOMBRES": "Laura Vanessa",
                    "APELLIDOS": "Anillo Beltran",
                    "NOMBRES COMPLETOS": "Laura Vanessa Anillo Beltran",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Aleida Bibiana Garcia Suarez",
                    "CODIGO USUARIO": "anil802917",
                    "USUARIOFIVE9": "anil802917@siigo.com",
                    "USUARIO RED": "anil802917",
                    "CORREOELECTRONICO": "anil802917@siigo.com",
                    "STATION ID": "4421754",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Aleida Bibiana Garcia Suarez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1504129",
                    "FECHA INICIAL": "2022-05-04",
                    "FECHA OPERACION": "2022-05-23",
                    "NOMBRES": "Yeisker Enrique",
                    "APELLIDOS": "Aranguren Fernandez",
                    "NOMBRES COMPLETOS": "Yeisker Enrique Aranguren Fernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Liliana Andrea Segura Martinez",
                    "CODIGO USUARIO": "aran802916",
                    "USUARIOFIVE9": "aran802916@siigo.com",
                    "USUARIO RED": "aran802916",
                    "CORREOELECTRONICO": "aran802916@siigo.com",
                    "STATION ID": "4421755",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Andrea Segura Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "9154888120219",
                    "FECHA INICIAL": "2022-03-02",
                    "FECHA OPERACION": "2022-05-23",
                    "NOMBRES": "Akira Oscar",
                    "APELLIDOS": "Mejia Molero",
                    "NOMBRES COMPLETOS": "Akira Oscar Mejia Molero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Cristhian David Nino Barrera",
                    "CODIGO USUARIO": "meji802723",
                    "USUARIOFIVE9": "meji802723@siigo.com",
                    "USUARIO RED": "meji802723",
                    "CORREOELECTRONICO": "meji802723@siigo.com",
                    "STATION ID": "4421757",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Cristhian David Nino Barrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015412570",
                    "FECHA INICIAL": "2022-03-02",
                    "FECHA OPERACION": "2022-05-23",
                    "NOMBRES": "Jennifer Alexandra",
                    "APELLIDOS": "Bernal Pineda",
                    "NOMBRES COMPLETOS": "Jennifer Alexandra Bernal Pineda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Planeacion Comercial",
                    "CARGO": "Analista de Planeacion Comercial",
                    "JEFE INMEDIATO": "Jaime Andres Aguilar Rivera",
                    "CODIGO USUARIO": "bern802720",
                    "USUARIOFIVE9": "bern802720@siigo.com",
                    "USUARIO RED": "bern802720",
                    "CORREOELECTRONICO": "bern802720@siigo.com",
                    "STATION ID": "4421735",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jaime Andres Aguilar Rivera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MUVR780112P98",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Ricardo Aurelio",
                    "APELLIDOS": "Munoz Valdes",
                    "NOMBRES COMPLETOS": "Ricardo Aurelio Munoz Valdes",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Alejandro Salgado Vergara",
                    "CODIGO USUARIO": "rmunoz",
                    "USUARIOFIVE9": "rmunoz@aspel.com.mx",
                    "USUARIO RED": "rmunoz",
                    "CORREOELECTRONICO": "rmunoz@aspel.com.mx",
                    "STATION ID": "3763842",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Alejandro Salgado Vergara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "AASI860418QXA",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Ingrid Eunice",
                    "APELLIDOS": "Alcantara Suarez",
                    "NOMBRES COMPLETOS": "Ingrid Eunice Alcantara Suarez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Alejandro Salgado Vergara",
                    "CODIGO USUARIO": "isuarez",
                    "USUARIOFIVE9": "isuarez@aspel.com.mx",
                    "USUARIO RED": "isuarez",
                    "CORREOELECTRONICO": "isuarez@aspel.com.mx",
                    "STATION ID": "3763844",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Alejandro Salgado Vergara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "ZEVO980429SRA",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Omar",
                    "APELLIDOS": "Zepeda Valencia",
                    "NOMBRES COMPLETOS": "Omar Zepeda Valencia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Alejandro Salgado Vergara",
                    "CODIGO USUARIO": "ozepeda",
                    "USUARIOFIVE9": "ozepeda@aspel.com.mx",
                    "USUARIO RED": "ozepeda",
                    "CORREOELECTRONICO": "ozepeda@aspel.com.mx",
                    "STATION ID": "3763847",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Alejandro Salgado Vergara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "AUSA911224PHA",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Alan Arturo",
                    "APELLIDOS": "Aguillon Salas",
                    "NOMBRES COMPLETOS": "Alan Arturo Aguillon Salas",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Alejandro Salgado Vergara",
                    "CODIGO USUARIO": "asalas",
                    "USUARIOFIVE9": "asalas@aspel.com.mx",
                    "USUARIO RED": "asalas",
                    "CORREOELECTRONICO": "asalas@aspel.com.mx",
                    "STATION ID": "3763873",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Alejandro Salgado Vergara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "GAVV8101159N4",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Vanessa",
                    "APELLIDOS": "Gamas Velasco",
                    "NOMBRES COMPLETOS": "Vanessa Gamas Velasco",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Alejandro Salgado Vergara",
                    "CODIGO USUARIO": "vgamas",
                    "USUARIOFIVE9": "vgamas@aspel.com.mx",
                    "USUARIO RED": "vgamas",
                    "CORREOELECTRONICO": "vgamas@aspel.com.mx",
                    "STATION ID": "3763874",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Alejandro Salgado Vergara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "NAAA950901Q7A",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Andres",
                    "APELLIDOS": "Najera Alarcon",
                    "NOMBRES COMPLETOS": "Andres Najera Alarcon",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Alejandro Salgado Vergara",
                    "CODIGO USUARIO": "anajera",
                    "USUARIOFIVE9": "anajera@aspel.com.mx",
                    "USUARIO RED": "anajera",
                    "CORREOELECTRONICO": "anajera@aspel.com.mx",
                    "STATION ID": "3763889",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Alejandro Salgado Vergara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "FACM741010QA5",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Marisela Yasmin",
                    "APELLIDOS": "Franco Cruz",
                    "NOMBRES COMPLETOS": "Marisela Yasmin Franco Cruz",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Juan Camilo Gonzalez Saldana",
                    "CODIGO USUARIO": "yfranco",
                    "USUARIOFIVE9": "yfranco@aspel.com.mx",
                    "USUARIO RED": "yfranco",
                    "CORREOELECTRONICO": "yfranco@aspel.com.mx",
                    "STATION ID": "3763964",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Camilo Gonzalez Saldana",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016015427",
                    "FECHA INICIAL": "2021-08-23",
                    "FECHA OPERACION": "2021-08-23",
                    "NOMBRES": "Andres",
                    "APELLIDOS": "Segura Martinez",
                    "NOMBRES COMPLETOS": "Andres Segura Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Nataly Carolina Gonzalez Sandoval",
                    "CODIGO USUARIO": "segu801723",
                    "USUARIOFIVE9": "segu801723@siigo.com",
                    "USUARIO RED": "segu801723",
                    "CORREOELECTRONICO": "segu801723@siigo.com",
                    "STATION ID": "3127577",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Nataly Carolina Gonzalez Sandoval",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MIMU791213DT7",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Ulises",
                    "APELLIDOS": "Miranda Molina",
                    "NOMBRES COMPLETOS": "Ulises Miranda Molina",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leidy Marcela Rodriguez Rivera",
                    "CODIGO USUARIO": "umolina",
                    "USUARIOFIVE9": "umolina@aspel.com.mx",
                    "USUARIO RED": "umolina",
                    "CORREOELECTRONICO": "umolina@aspel.com.mx",
                    "STATION ID": "3857101",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leidy Marcela Rodriguez Rivera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "AUVP870413GHA",
                    "FECHA INICIAL": "2022-10-24",
                    "FECHA OPERACION": "2022-11-25",
                    "NOMBRES": "Polet Magali",
                    "APELLIDOS": "Aguirre Valdez",
                    "NOMBRES COMPLETOS": "Polet Magali Aguirre Valdez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Nataly Carolina Gonzalez Sandoval",
                    "CODIGO USUARIO": "paguirre",
                    "USUARIOFIVE9": "paguirre@aspel.com.mx",
                    "USUARIO RED": "paguirre",
                    "CORREOELECTRONICO": "paguirre@aspel.com.mx",
                    "STATION ID": "3956753",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Nataly Carolina Gonzalez Sandoval",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "DETC800619DX2",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Cesar Jesus",
                    "APELLIDOS": "Delgado Tellez",
                    "NOMBRES COMPLETOS": "Cesar Jesus Delgado Tellez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Alejandro Salgado Vergara",
                    "CODIGO USUARIO": "cdelgado",
                    "USUARIOFIVE9": "cdelgado@aspel.com.mx",
                    "USUARIO RED": "cdelgado",
                    "CORREOELECTRONICO": "cdelgado@aspel.com.mx",
                    "STATION ID": "4421299",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Alejandro Salgado Vergara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MALM870118EH7",
                    "FECHA INICIAL": "2022-06-01",
                    "FECHA OPERACION": "2022-06-07",
                    "NOMBRES": "Moises",
                    "APELLIDOS": "Martinez Lopez",
                    "NOMBRES COMPLETOS": "Moises Martinez Lopez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Alejandro Salgado Vergara",
                    "CODIGO USUARIO": "mmartinez",
                    "USUARIOFIVE9": "mmartinez@aspel.com.mx",
                    "USUARIO RED": "mmartinez",
                    "CORREOELECTRONICO": "mmartinez@aspel.com.mx",
                    "STATION ID": "2606182",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Alejandro Salgado Vergara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "BALG971112HXA",
                    "FECHA INICIAL": "2022-06-01",
                    "FECHA OPERACION": "2022-06-07",
                    "NOMBRES": "Gladis",
                    "APELLIDOS": "Barrios Licona",
                    "NOMBRES COMPLETOS": "Gladis Barrios Licona",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Alejandro Salgado Vergara",
                    "CODIGO USUARIO": "gbarrios",
                    "USUARIOFIVE9": "gbarrios@aspel.com.mx",
                    "USUARIO RED": "gbarrios",
                    "CORREOELECTRONICO": "gbarrios@aspel.com.mx",
                    "STATION ID": "2606452",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Alejandro Salgado Vergara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SAML920202AL6",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Libia",
                    "APELLIDOS": "Sanchez Melendez",
                    "NOMBRES COMPLETOS": "Libia Sanchez Melendez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Contenidos",
                    "CARGO": "Analista Gestion del Conocimiento",
                    "JEFE INMEDIATO": "Luz Angelica Torres Quintero",
                    "CODIGO USUARIO": "lmelendez",
                    "USUARIOFIVE9": "lmelendez@aspel.com.mx",
                    "USUARIO RED": "lmelendez",
                    "CORREOELECTRONICO": "lmelendez@aspel.com.mx",
                    "STATION ID": "3471496",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Angelica Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SIHO970105BI3",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Oscar Ulises",
                    "APELLIDOS": "Sierra Hernandez",
                    "NOMBRES COMPLETOS": "Oscar Ulises Sierra Hernandez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Maria Teresa Vazquez Garza",
                    "CODIGO USUARIO": "osierra",
                    "USUARIOFIVE9": "osierra@aspel.com.mx",
                    "USUARIO RED": "osierra",
                    "CORREOELECTRONICO": "osierra@aspel.com.mx",
                    "STATION ID": "3763941",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Maria Teresa Vazquez Garza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "LOPV8407295V2",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Victor Alfonso",
                    "APELLIDOS": "Lopez Perez",
                    "NOMBRES COMPLETOS": "Victor Alfonso Lopez Perez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Maria Teresa Vazquez Garza",
                    "CODIGO USUARIO": "valopez",
                    "USUARIOFIVE9": "valopez@aspel.com.mx",
                    "USUARIO RED": "valopez",
                    "CORREOELECTRONICO": "valopez@aspel.com.mx",
                    "STATION ID": "3763866",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Maria Teresa Vazquez Garza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "HUGE860227FZ3",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Elsa Julia",
                    "APELLIDOS": "Huitron Guevara",
                    "NOMBRES COMPLETOS": "Elsa Julia Huitron Guevara",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Maria Teresa Vazquez Garza",
                    "CODIGO USUARIO": "eguevara",
                    "USUARIOFIVE9": "eguevara@aspel.com.mx",
                    "USUARIO RED": "eguevara",
                    "CORREOELECTRONICO": "eguevara@aspel.com.mx",
                    "STATION ID": "3763832",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Maria Teresa Vazquez Garza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "HEVI981109KU6",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Itzel",
                    "APELLIDOS": "Hernandez Valdivia",
                    "NOMBRES COMPLETOS": "Itzel Hernandez Valdivia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Oscar Rodrigo Navarro Hernandez",
                    "CODIGO USUARIO": "ivaldivia",
                    "USUARIOFIVE9": "ivaldivia@aspel.com.mx",
                    "USUARIO RED": "ivaldivia",
                    "CORREOELECTRONICO": "ivaldivia@aspel.com.mx",
                    "STATION ID": "3483709",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Oscar Rodrigo Navarro Hernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "AUCJ950305SE2",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jorge Luis",
                    "APELLIDOS": "Aguilar Camacho",
                    "NOMBRES COMPLETOS": "Jorge Luis Aguilar Camacho",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Maria Teresa Vazquez Garza",
                    "CODIGO USUARIO": "jcamacho",
                    "USUARIOFIVE9": "jcamacho@aspel.com.mx",
                    "USUARIO RED": "jcamacho",
                    "CORREOELECTRONICO": "jcamacho@aspel.com.mx",
                    "STATION ID": "3763841",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Maria Teresa Vazquez Garza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "AACD820715H24",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Daniel",
                    "APELLIDOS": "Andrade Contreras",
                    "NOMBRES COMPLETOS": "Daniel Andrade Contreras",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Maria Teresa Vazquez Garza",
                    "CODIGO USUARIO": "dandrade",
                    "USUARIOFIVE9": "dandrade@aspel.com.mx",
                    "USUARIO RED": "dandrade",
                    "CORREOELECTRONICO": "dandrade@aspel.com.mx",
                    "STATION ID": "3763846",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Maria Teresa Vazquez Garza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "DOGJ990727U72",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jair Abisain",
                    "APELLIDOS": "Dominguez Gonzalez",
                    "NOMBRES COMPLETOS": "Jair Abisain Dominguez Gonzalez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Maria Teresa Vazquez Garza",
                    "CODIGO USUARIO": "jdominguez",
                    "USUARIOFIVE9": "jdominguez@aspel.com.mx",
                    "USUARIO RED": "jdominguez",
                    "CORREOELECTRONICO": "jdominguez@aspel.com.mx",
                    "STATION ID": "3763855",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Maria Teresa Vazquez Garza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "LOMA9701043H5",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Abigail",
                    "APELLIDOS": "Lopez Marquez",
                    "NOMBRES COMPLETOS": "Abigail Lopez Marquez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Maria Teresa Vazquez Garza",
                    "CODIGO USUARIO": "amarquez",
                    "USUARIOFIVE9": "amarquez@aspel.com.mx",
                    "USUARIO RED": "amarquez",
                    "CORREOELECTRONICO": "amarquez@aspel.com.mx",
                    "STATION ID": "3763856",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Maria Teresa Vazquez Garza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "RUGA900426BTA",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Adalid",
                    "APELLIDOS": "Ruiz Guerrero",
                    "NOMBRES COMPLETOS": "Adalid Ruiz Guerrero",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Product Owner",
                    "JEFE INMEDIATO": "Luz Mery Patino Infante",
                    "CODIGO USUARIO": "aguerrero",
                    "USUARIOFIVE9": "aguerrero@aspel.com.mx",
                    "USUARIO RED": "aguerrero",
                    "CORREOELECTRONICO": "aguerrero@aspel.com.mx",
                    "STATION ID": "2605986",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Luz Mery Patino Infante",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SAPA9301269D8",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-24",
                    "NOMBRES": "Alexis Abraham",
                    "APELLIDOS": "Sanchez Portillo",
                    "NOMBRES COMPLETOS": "Alexis Abraham Sanchez Portillo",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Ana Maria Quijano",
                    "CODIGO USUARIO": "aportillo",
                    "USUARIOFIVE9": "aportillo@aspel.com.mx",
                    "USUARIO RED": "aportillo",
                    "CORREOELECTRONICO": "aportillo@aspel.com.mx",
                    "STATION ID": "3720346",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ana Maria Quijano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "8808030920",
                    "FECHA INICIAL": "2022-07-15",
                    "FECHA OPERACION": "2022-07-15",
                    "NOMBRES": "Maricruz",
                    "APELLIDOS": "Diaz Gonzalez",
                    "NOMBRES COMPLETOS": "Maricruz Diaz Gonzalez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "mdiaz",
                    "USUARIOFIVE9": "mdiaz@aspel.com.mx",
                    "USUARIO RED": "mdiaz",
                    "CORREOELECTRONICO": "mdiaz@aspel.com.mx",
                    "STATION ID": "2605960",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "8905110900",
                    "FECHA INICIAL": "2022-07-15",
                    "FECHA OPERACION": "2022-07-15",
                    "NOMBRES": "Oscar",
                    "APELLIDOS": "Alva Hernandez",
                    "NOMBRES COMPLETOS": "Oscar Alva Hernandez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "oalava",
                    "USUARIOFIVE9": "oalva@aspel.com.mx",
                    "USUARIO RED": "oalava",
                    "CORREOELECTRONICO": "oalva@aspel.com.mx",
                    "STATION ID": "2606012",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "9102242100",
                    "FECHA INICIAL": "2022-07-15",
                    "FECHA OPERACION": "2022-07-15",
                    "NOMBRES": "Christhian",
                    "APELLIDOS": "Marquez Marquez",
                    "NOMBRES COMPLETOS": "Christhian Marquez Marquez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "cmarquezm",
                    "USUARIOFIVE9": "cmarquezm@aspel.com.mx",
                    "USUARIO RED": "cmarquezm",
                    "CORREOELECTRONICO": "cmarquezm@aspel.com.mx",
                    "STATION ID": "2606407",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "9202072080",
                    "FECHA INICIAL": "2022-07-15",
                    "FECHA OPERACION": "2022-07-15",
                    "NOMBRES": "Nivardo Yonel",
                    "APELLIDOS": "Clemente Colon",
                    "NOMBRES COMPLETOS": "Nivardo Yonel Clemente Colon",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "nclemente",
                    "USUARIOFIVE9": "nclemente@aspel.com.mx",
                    "USUARIO RED": "nclemente",
                    "CORREOELECTRONICO": "nclemente@aspel.com.mx",
                    "STATION ID": "2606468",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "9410210950",
                    "FECHA INICIAL": "2022-07-15",
                    "FECHA OPERACION": "2022-07-15",
                    "NOMBRES": "Nataly",
                    "APELLIDOS": "Ferreira Carrera",
                    "NOMBRES COMPLETOS": "Nataly Ferreira Carrera",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "nferreira",
                    "USUARIOFIVE9": "nferreira@aspel.com.mx",
                    "USUARIO RED": "nferreira",
                    "CORREOELECTRONICO": "nferreira@aspel.com.mx",
                    "STATION ID": "2606484",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "9208102960",
                    "FECHA INICIAL": "2022-07-15",
                    "FECHA OPERACION": "2022-07-15",
                    "NOMBRES": "Yadira",
                    "APELLIDOS": "Larios Hernandez",
                    "NOMBRES COMPLETOS": "Yadira Larios Hernandez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "ylarios",
                    "USUARIOFIVE9": "ylarios@aspel.com.mx",
                    "USUARIO RED": "ylarios",
                    "CORREOELECTRONICO": "ylarios@aspel.com.mx",
                    "STATION ID": "2606530",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "8502180980",
                    "FECHA INICIAL": "2022-07-15",
                    "FECHA OPERACION": "2022-07-15",
                    "NOMBRES": "Claudia Sofia",
                    "APELLIDOS": "Santamaria Gonzalez",
                    "NOMBRES COMPLETOS": "Claudia Sofia Santamaria Gonzalez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "csantamaria",
                    "USUARIOFIVE9": "csantamaria@aspel.com.mx",
                    "USUARIO RED": "csantamaria",
                    "CORREOELECTRONICO": "csantamaria@aspel.com.mx",
                    "STATION ID": "2606568",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "8807090950",
                    "FECHA INICIAL": "2022-07-15",
                    "FECHA OPERACION": "2022-07-15",
                    "NOMBRES": "Blanca Guadalupe",
                    "APELLIDOS": "Sanchez Sanchez",
                    "NOMBRES COMPLETOS": "Blanca Guadalupe Sanchez Sanchez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "bgsanchez",
                    "USUARIOFIVE9": "bgsanchez@aspel.com.mx",
                    "USUARIO RED": "bgsanchez",
                    "CORREOELECTRONICO": "bgsanchez@aspel.com.mx",
                    "STATION ID": "2606587",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "9010200960",
                    "FECHA INICIAL": "2022-07-15",
                    "FECHA OPERACION": "2022-07-15",
                    "NOMBRES": "Maria De Jesus",
                    "APELLIDOS": "Hernandez Rodriguez",
                    "NOMBRES COMPLETOS": "Maria De Jesus Hernandez Rodriguez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "mjhernandez",
                    "USUARIOFIVE9": "mjhernandez@aspel.com.mx",
                    "USUARIO RED": "mjhernandez",
                    "CORREOELECTRONICO": "mjhernandez@aspel.com.mx",
                    "STATION ID": "2606622",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "85032821",
                    "FECHA INICIAL": "2022-10-06",
                    "FECHA OPERACION": "2022-09-26",
                    "NOMBRES": "Natividad",
                    "APELLIDOS": "Zarate Quileo",
                    "NOMBRES COMPLETOS": "Natividad Zarate Quileo",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "nzarate",
                    "USUARIOFIVE9": "nzarate@aspel.com.mx",
                    "USUARIO RED": "nzarate",
                    "CORREOELECTRONICO": "nzarate@aspel.com.mx",
                    "STATION ID": "2606347",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "92080509",
                    "FECHA INICIAL": "2022-10-06",
                    "FECHA OPERACION": "2022-09-26",
                    "NOMBRES": "Jhovanna Magdalena",
                    "APELLIDOS": "Hurtado Flores",
                    "NOMBRES COMPLETOS": "Jhovanna Magdalena Hurtado Flores",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "jhurtado",
                    "USUARIOFIVE9": "jhurtado@aspel.com.mx",
                    "USUARIO RED": "jhurtado",
                    "CORREOELECTRONICO": "jhurtado@aspel.com.mx",
                    "STATION ID": "2606517",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "91122409",
                    "FECHA INICIAL": "2022-10-06",
                    "FECHA OPERACION": "2022-09-26",
                    "NOMBRES": "Thalia",
                    "APELLIDOS": "Trejo Garcia",
                    "NOMBRES COMPLETOS": "Thalia Trejo Garcia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "ttrejo",
                    "USUARIOFIVE9": "ttrejo@aspel.com.mx",
                    "USUARIO RED": "ttrejo",
                    "CORREOELECTRONICO": "ttrejo@aspel.com.mx",
                    "STATION ID": "2606743",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "92061120",
                    "FECHA INICIAL": "2022-10-06",
                    "FECHA OPERACION": "2022-09-26",
                    "NOMBRES": "Gerardo",
                    "APELLIDOS": "Ramirez Gil",
                    "NOMBRES COMPLETOS": "Gerardo Ramirez Gil",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "gramirez",
                    "USUARIOFIVE9": "gramirez@aspel.com.mx",
                    "USUARIO RED": "gramirez",
                    "CORREOELECTRONICO": "gramirez@aspel.com.mx",
                    "STATION ID": "2606777",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80020809",
                    "FECHA INICIAL": "2022-10-06",
                    "FECHA OPERACION": "2022-09-26",
                    "NOMBRES": "Cinthya",
                    "APELLIDOS": "Uribe Rioverde",
                    "NOMBRES COMPLETOS": "Cinthya Uribe Rioverde",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "curibe",
                    "USUARIOFIVE9": "curibe@aspel.com.mx",
                    "USUARIO RED": "curibe",
                    "CORREOELECTRONICO": "curibe@aspel.com.mx",
                    "STATION ID": "2606778",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "88040614",
                    "FECHA INICIAL": "2022-10-06",
                    "FECHA OPERACION": "2022-09-26",
                    "NOMBRES": "Cesar Arturo",
                    "APELLIDOS": "Barcenas Hernandez",
                    "NOMBRES COMPLETOS": "Cesar Arturo Barcenas Hernandez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "cbarcenas",
                    "USUARIOFIVE9": "cbarcenas@aspel.com.mx",
                    "USUARIO RED": "cbarcenas",
                    "CORREOELECTRONICO": "cbarcenas@aspel.com.mx",
                    "STATION ID": "2607038",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "00012514",
                    "FECHA INICIAL": "2022-10-06",
                    "FECHA OPERACION": "2022-09-26",
                    "NOMBRES": "Elia Viridiana",
                    "APELLIDOS": "Carmona Gonzalez",
                    "NOMBRES COMPLETOS": "Elia Viridiana Carmona Gonzalez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "ecarmona",
                    "USUARIOFIVE9": "ecarmona@aspel.com.mx",
                    "USUARIO RED": "ecarmona",
                    "CORREOELECTRONICO": "ecarmona@aspel.com.mx",
                    "STATION ID": "3127467",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022925886",
                    "FECHA INICIAL": "2022-06-07",
                    "FECHA OPERACION": "2022-06-13",
                    "NOMBRES": "Melanny Mildred",
                    "APELLIDOS": "Moreno Osorio",
                    "NOMBRES COMPLETOS": "Melanny Mildred Moreno Osorio",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Uy",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Edith Angelica Gil Umbarila",
                    "CODIGO USUARIO": "more803262",
                    "USUARIOFIVE9": "more803262@siigo.com",
                    "USUARIO RED": "more803262",
                    "CORREOELECTRONICO": "more803262@siigo.com",
                    "STATION ID": "3127500",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Edith Angelica Gil Umbarila",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000786537",
                    "FECHA INICIAL": "2022-06-13",
                    "FECHA OPERACION": "2022-06-13",
                    "NOMBRES": "Ingrid Katherine",
                    "APELLIDOS": "Neva Fernandez",
                    "NOMBRES COMPLETOS": "Ingrid Katherine Neva Fernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones ECU",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Angie Camila Diaz Escobar",
                    "CODIGO USUARIO": "neva803274",
                    "USUARIOFIVE9": "neva803274@siigo.com",
                    "USUARIO RED": "neva803274",
                    "CORREOELECTRONICO": "neva803274@siigo.com",
                    "STATION ID": "2606263",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Camila Diaz Escobar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "919601997",
                    "FECHA INICIAL": "2022-03-03",
                    "FECHA OPERACION": "2022-03-04",
                    "NOMBRES": "Kelvin Eduardo",
                    "APELLIDOS": "Guaranda Choez",
                    "NOMBRES COMPLETOS": "Kelvin Eduardo Guaranda Choez",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "guar802729",
                    "USUARIOFIVE9": "guar802729@siigo.com",
                    "USUARIO RED": "guar802729",
                    "CORREOELECTRONICO": "guar802729@siigo.com",
                    "STATION ID": "3690303",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1073168548",
                    "FECHA INICIAL": "2022-05-23",
                    "FECHA OPERACION": "2022-06-15",
                    "NOMBRES": "Erick Saaid",
                    "APELLIDOS": "Castellanos Castro",
                    "NOMBRES COMPLETOS": "Erick Saaid Castellanos Castro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Diana Sofia Ramos Castillo",
                    "CODIGO USUARIO": "cast802935",
                    "USUARIOFIVE9": "cast802935@siigo.com",
                    "USUARIO RED": "cast802935",
                    "CORREOELECTRONICO": "cast802935@siigo.com",
                    "STATION ID": "2606304",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Sofia Ramos Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "60399582",
                    "FECHA INICIAL": "2020-11-09",
                    "FECHA OPERACION": "2020-11-09",
                    "NOMBRES": "Martha Jazmin",
                    "APELLIDOS": "Rivera Vega",
                    "NOMBRES COMPLETOS": "Martha Jazmin Rivera Vega",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Francy Julieth Viracacha Gutierrez",
                    "CODIGO USUARIO": "rive800840",
                    "USUARIOFIVE9": "rive800840@siigo.com",
                    "USUARIO RED": "rive800840",
                    "CORREOELECTRONICO": "rive800840@siigo.com",
                    "STATION ID": "2606204",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Francy Julieth Viracacha Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1054563674",
                    "FECHA INICIAL": "2022-06-14",
                    "FECHA OPERACION": "2022-06-20",
                    "NOMBRES": "Rances David",
                    "APELLIDOS": "Geraldino Triana",
                    "NOMBRES COMPLETOS": "Rances David Geraldino Triana",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "William Andres Reyes Silva",
                    "CODIGO USUARIO": "gera803302",
                    "USUARIOFIVE9": "gera803302@siigo.com",
                    "USUARIO RED": "gera803302",
                    "CORREOELECTRONICO": "gera803302@siigo.com",
                    "STATION ID": "2607017",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_William Andres Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024479067",
                    "FECHA INICIAL": "2022-06-14",
                    "FECHA OPERACION": "2022-06-20",
                    "NOMBRES": "Leidy Johana",
                    "APELLIDOS": "Gaviria Sanchez",
                    "NOMBRES COMPLETOS": "Leidy Johana Gaviria Sanchez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Andres Felipe Baron Ballen",
                    "CODIGO USUARIO": "gavi803303",
                    "USUARIOFIVE9": "gavi803303@siigo.com",
                    "USUARIO RED": "gavi803303",
                    "CORREOELECTRONICO": "gavi803303@siigo.com",
                    "STATION ID": "3127557",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andres Felipe Baron Ballen",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "916677859",
                    "FECHA INICIAL": "2021-09-07",
                    "FECHA OPERACION": "2022-06-21",
                    "NOMBRES": "Dorys Elisa",
                    "APELLIDOS": "Cruz Sandoya",
                    "NOMBRES COMPLETOS": "Dorys Elisa Cruz Sandoya",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Diana Elizabeth Davila Murillo",
                    "CODIGO USUARIO": "cruz801855",
                    "USUARIOFIVE9": "cruz801855@siigo.com",
                    "USUARIO RED": "cruz801855",
                    "CORREOELECTRONICO": "cruz801855@siigo.com",
                    "STATION ID": "4421823",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Diana Elizabeth Davila Murillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "931935076",
                    "FECHA INICIAL": "2022-03-02",
                    "FECHA OPERACION": "2022-06-21",
                    "NOMBRES": "Maria Pamela",
                    "APELLIDOS": "Macias Zurita",
                    "NOMBRES COMPLETOS": "Maria Pamela Macias Zurita",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Sanchez Velez",
                    "CODIGO USUARIO": "maci802716",
                    "USUARIOFIVE9": "maci802716@siigo.com",
                    "USUARIO RED": "maci802716",
                    "CORREOELECTRONICO": "maci802716@siigo.com",
                    "STATION ID": "4421824",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Sanchez Velez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024498097",
                    "FECHA INICIAL": "2022-06-14",
                    "FECHA OPERACION": "2022-06-21",
                    "NOMBRES": "Anyela Mercedes",
                    "APELLIDOS": "Rincon Ramos",
                    "NOMBRES COMPLETOS": "Anyela Mercedes Rincon Ramos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "William Andres Reyes Silva",
                    "CODIGO USUARIO": "rinc803308",
                    "USUARIOFIVE9": "rinc803308@siigo.com",
                    "USUARIO RED": "rinc803308",
                    "CORREOELECTRONICO": "rinc803308@siigo.com",
                    "STATION ID": "3127504",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_William Andres Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014182860",
                    "FECHA INICIAL": "2022-06-14",
                    "FECHA OPERACION": "2022-06-21",
                    "NOMBRES": "Claudia Marlen",
                    "APELLIDOS": "Velandia Latorre",
                    "NOMBRES COMPLETOS": "Claudia Marlen Velandia Latorre",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Andres Felipe Baron Ballen",
                    "CODIGO USUARIO": "vela803315",
                    "USUARIOFIVE9": "vela803315@siigo.com",
                    "USUARIO RED": "vela803315",
                    "CORREOELECTRONICO": "vela803315@siigo.com",
                    "STATION ID": "3763894",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andres Felipe Baron Ballen",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098765447",
                    "FECHA INICIAL": "2022-06-14",
                    "FECHA OPERACION": "2022-06-21",
                    "NOMBRES": "Maria Angelica",
                    "APELLIDOS": "Pinto Pinzon",
                    "NOMBRES COMPLETOS": "Maria Angelica Pinto Pinzon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juvenal Reyes Silva",
                    "CODIGO USUARIO": "pint803320",
                    "USUARIOFIVE9": "pint803320@siigo.com",
                    "USUARIO RED": "pint803320",
                    "CORREOELECTRONICO": "pint803320@siigo.com",
                    "STATION ID": "3763930",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juvenal Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000440881",
                    "FECHA INICIAL": "2022-06-16",
                    "FECHA OPERACION": "2022-06-22",
                    "NOMBRES": "Dayana",
                    "APELLIDOS": "Escobar Correa",
                    "NOMBRES COMPLETOS": "Dayana Escobar Correa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Fundacion",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "esco803329",
                    "USUARIOFIVE9": "esco803329@siigo.com",
                    "USUARIO RED": "esco803329",
                    "CORREOELECTRONICO": "esco803329@siigo.com",
                    "STATION ID": "3763955",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013673034",
                    "FECHA INICIAL": "2022-06-16",
                    "FECHA OPERACION": "2022-06-22",
                    "NOMBRES": "Katherine Milena",
                    "APELLIDOS": "Becerra Pacheco",
                    "NOMBRES COMPLETOS": "Katherine Milena Becerra Pacheco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Fundacion",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "bece803332",
                    "USUARIOFIVE9": "bece803332@siigo.com",
                    "USUARIO RED": "bece803332",
                    "CORREOELECTRONICO": "bece803332@siigo.com",
                    "STATION ID": "3857108",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "01030809",
                    "FECHA INICIAL": "2022-10-06",
                    "FECHA OPERACION": "2022-09-26",
                    "NOMBRES": "Galilea",
                    "APELLIDOS": "Osnaya Mendoza",
                    "NOMBRES COMPLETOS": "Galilea Osnaya Mendoza",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "gosnaya",
                    "USUARIOFIVE9": "gosnaya@aspel.com.mx",
                    "USUARIO RED": "gosnaya",
                    "CORREOELECTRONICO": "gosnaya@aspel.com.mx",
                    "STATION ID": "3127508",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "88050909",
                    "FECHA INICIAL": "2022-10-06",
                    "FECHA OPERACION": "2022-09-26",
                    "NOMBRES": "Victor Daniel",
                    "APELLIDOS": "Garcia Torres",
                    "NOMBRES COMPLETOS": "Victor Daniel Garcia Torres",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "dgarcia",
                    "USUARIOFIVE9": "dgarcia@aspel.com.mx",
                    "USUARIO RED": "dgarcia",
                    "CORREOELECTRONICO": "dgarcia@aspel.com.mx",
                    "STATION ID": "3267832",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1095815235",
                    "FECHA INICIAL": "2022-06-29",
                    "FECHA OPERACION": "2022-07-06",
                    "NOMBRES": "Laura Alejandra",
                    "APELLIDOS": "Chaves Uribe",
                    "NOMBRES COMPLETOS": "Laura Alejandra Chaves Uribe",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Aleida Bibiana Garcia Suarez",
                    "CODIGO USUARIO": "chav803345",
                    "USUARIOFIVE9": "chav803345@siigo.com",
                    "USUARIO RED": "chav803345",
                    "CORREOELECTRONICO": "chav803345@siigo.com",
                    "STATION ID": "3857114",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Aleida Bibiana Garcia Suarez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014230524",
                    "FECHA INICIAL": "2022-07-05",
                    "FECHA OPERACION": "2022-07-13",
                    "NOMBRES": "Andrea",
                    "APELLIDOS": "Gonzalez Ortega",
                    "NOMBRES COMPLETOS": "Andrea Gonzalez Ortega",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "gonz803361",
                    "USUARIOFIVE9": "gonz803361@siigo.com",
                    "USUARIO RED": "gonz803361",
                    "CORREOELECTRONICO": "gonz803361@siigo.com",
                    "STATION ID": "2606881",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "92090414",
                    "FECHA INICIAL": "2022-10-06",
                    "FECHA OPERACION": "2022-09-26",
                    "NOMBRES": "Yadira Guadalupe",
                    "APELLIDOS": "Lopez Moreno",
                    "NOMBRES COMPLETOS": "Yadira Guadalupe Lopez Moreno",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "ylopez",
                    "USUARIOFIVE9": "ylopez@aspel.com.mx",
                    "USUARIO RED": "ylopez",
                    "CORREOELECTRONICO": "ylopez@aspel.com.mx",
                    "STATION ID": "3267875",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "92120209",
                    "FECHA INICIAL": "2022-10-06",
                    "FECHA OPERACION": "2022-09-26",
                    "NOMBRES": "Fabiola",
                    "APELLIDOS": "Rojas Lindero",
                    "NOMBRES COMPLETOS": "Fabiola Rojas Lindero",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "frojas",
                    "USUARIOFIVE9": "frojas@aspel.com.mx",
                    "USUARIO RED": "frojas",
                    "CORREOELECTRONICO": "frojas@aspel.com.mx",
                    "STATION ID": "3267890",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "98080115",
                    "FECHA INICIAL": "2022-10-06",
                    "FECHA OPERACION": "2022-09-26",
                    "NOMBRES": "Daniela Jariffe",
                    "APELLIDOS": "Torres Gonzalez",
                    "NOMBRES COMPLETOS": "Daniela Jariffe Torres Gonzalez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "dtorres",
                    "USUARIOFIVE9": "dtorres@aspel.com.mx",
                    "USUARIO RED": "dtorres",
                    "CORREOELECTRONICO": "dtorres@aspel.com.mx",
                    "STATION ID": "3267903",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "97051414",
                    "FECHA INICIAL": "2022-10-06",
                    "FECHA OPERACION": "2022-09-26",
                    "NOMBRES": "Natally Joan",
                    "APELLIDOS": "Bernal Romero",
                    "NOMBRES COMPLETOS": "Natally Joan Bernal Romero",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "nbernal",
                    "USUARIOFIVE9": "nbernal@aspel.com.mx",
                    "USUARIO RED": "nbernal",
                    "CORREOELECTRONICO": "nbernal@aspel.com.mx",
                    "STATION ID": "3483710",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "95020709",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2022-12-12",
                    "NOMBRES": "Tania Martinez Tapia",
                    "APELLIDOS": "Martinez Tapia",
                    "NOMBRES COMPLETOS": "Tania Martinez Tapia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "tmartinez",
                    "USUARIOFIVE9": "tmartinez@aspel.com.mx",
                    "USUARIO RED": "tmartinez",
                    "CORREOELECTRONICO": "tmartinez@aspel.com.mx",
                    "STATION ID": "2606447",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "91071627",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2022-12-12",
                    "NOMBRES": "Gabriela Del Carmen",
                    "APELLIDOS": "Lujan Contreras",
                    "NOMBRES COMPLETOS": "Gabriela Del Carmen Lujan Contreras",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "glujan",
                    "USUARIOFIVE9": "glujan@aspel.com.mx",
                    "USUARIO RED": "glujan",
                    "CORREOELECTRONICO": "glujan@aspel.com.mx",
                    "STATION ID": "2606543",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "95032709",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2022-12-12",
                    "NOMBRES": "Karen Danae",
                    "APELLIDOS": "Tadeo Granados",
                    "NOMBRES COMPLETOS": "Karen Danae Tadeo Granados",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "ktadeo",
                    "USUARIOFIVE9": "ktadeo@aspel.com.mx",
                    "USUARIO RED": "ktadeo",
                    "CORREOELECTRONICO": "ktadeo@aspel.com.mx",
                    "STATION ID": "2606566",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033692876",
                    "FECHA INICIAL": "2023-01-17",
                    "FECHA OPERACION": "2023-01-17",
                    "NOMBRES": "Giovanny Andres",
                    "APELLIDOS": "Amaya",
                    "NOMBRES COMPLETOS": "Giovanny Andres Amaya",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Dolores Basurto Rodriguez",
                    "CODIGO USUARIO": "amay",
                    "USUARIOFIVE9": "amay804397@siigo.com",
                    "USUARIO RED": "amay",
                    "CORREOELECTRONICO": "amay804397@siigo.com",
                    "STATION ID": "3127592",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Dolores Basurto Rodriguez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "4139727",
                    "FECHA INICIAL": "2022-07-13",
                    "FECHA OPERACION": "2022-07-25",
                    "NOMBRES": "Daniela Del Carmen",
                    "APELLIDOS": "Velandia Granadillo",
                    "NOMBRES COMPLETOS": "Daniela Del Carmen Velandia Granadillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ecuador",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "vela803378",
                    "USUARIOFIVE9": "vela803378@siigo.com",
                    "USUARIO RED": "vela803378",
                    "CORREOELECTRONICO": "vela803378@siigo.com",
                    "STATION ID": "3127554",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "REEB970401864",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Braulio",
                    "APELLIDOS": "Resendiz Estrada",
                    "NOMBRES COMPLETOS": "Braulio Resendiz Estrada",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Maria Teresa Vazquez Garza",
                    "CODIGO USUARIO": "bestrada",
                    "USUARIOFIVE9": "bestrada@aspel.com.mx",
                    "USUARIO RED": "bestrada",
                    "CORREOELECTRONICO": "bestrada@aspel.com.mx",
                    "STATION ID": "3763885",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Maria Teresa Vazquez Garza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SOMD810609L52",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Daniel",
                    "APELLIDOS": "Soriano Mendoza",
                    "NOMBRES COMPLETOS": "Daniel Soriano Mendoza",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Maria Teresa Vazquez Garza",
                    "CODIGO USUARIO": "dsoriano",
                    "USUARIOFIVE9": "dsoriano@aspel.com.mx",
                    "USUARIO RED": "dsoriano",
                    "CORREOELECTRONICO": "dsoriano@aspel.com.mx",
                    "STATION ID": "3763942",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Maria Teresa Vazquez Garza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "AARB8804176R0",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-27",
                    "NOMBRES": "Brian Eduardo",
                    "APELLIDOS": "Anaya Ruiz",
                    "NOMBRES COMPLETOS": "Brian Eduardo Anaya Ruiz",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Maria Teresa Vazquez Garza",
                    "CODIGO USUARIO": "banaya",
                    "USUARIOFIVE9": "banaya@aspel.com.mx",
                    "USUARIO RED": "banaya",
                    "CORREOELECTRONICO": "banaya@aspel.com.mx",
                    "STATION ID": "3483690",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Maria Teresa Vazquez Garza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MOMC9507302E6",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Christian Pablo",
                    "APELLIDOS": "Moreno Moscosa",
                    "NOMBRES COMPLETOS": "Christian Pablo Moreno Moscosa",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Maria Teresa Vazquez Garza",
                    "CODIGO USUARIO": "cpmoreno",
                    "USUARIOFIVE9": "cpmoreno@aspel.com.mx",
                    "USUARIO RED": "cpmoreno",
                    "CORREOELECTRONICO": "cpmoreno@aspel.com.mx",
                    "STATION ID": "3690279",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Maria Teresa Vazquez Garza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "REPM8101083L3",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Marisol",
                    "APELLIDOS": "Reyes Perez",
                    "NOMBRES COMPLETOS": "Marisol Reyes Perez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "mperezp",
                    "USUARIOFIVE9": "mperezp@aspel.com.mx",
                    "USUARIO RED": "mperezp",
                    "CORREOELECTRONICO": "mperezp@aspel.com.mx",
                    "STATION ID": "3690281",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "GARA920317PW6",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Alejandra",
                    "APELLIDOS": "Galicia Ramirez",
                    "NOMBRES COMPLETOS": "Alejandra Galicia Ramirez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "agalicia",
                    "USUARIOFIVE9": "agalicia@aspel.com.mx",
                    "USUARIO RED": "agalicia",
                    "CORREOELECTRONICO": "agalicia@aspel.com.mx",
                    "STATION ID": "3857167",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000687850",
                    "FECHA INICIAL": "2022-06-29",
                    "FECHA OPERACION": "2022-07-25",
                    "NOMBRES": "Valerit Jinneth",
                    "APELLIDOS": "Forero Saldana",
                    "NOMBRES COMPLETOS": "Valerit Jinneth Forero Saldana",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ventas",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "fore803346",
                    "USUARIOFIVE9": "fore803346@siigo.com",
                    "USUARIO RED": "fore803346",
                    "CORREOELECTRONICO": "fore803346@siigo.com",
                    "STATION ID": "3857115",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014254302",
                    "FECHA INICIAL": "2022-07-05",
                    "FECHA OPERACION": "2022-07-26",
                    "NOMBRES": "Mary Anyel",
                    "APELLIDOS": "Rojano Salas",
                    "NOMBRES COMPLETOS": "Mary Anyel Rojano Salas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Andrea Catalina Vega Gomez",
                    "CODIGO USUARIO": "roja803364",
                    "USUARIOFIVE9": "roja803364@siigo.com",
                    "USUARIO RED": "roja803364",
                    "CORREOELECTRONICO": "roja803364@siigo.com",
                    "STATION ID": "2605938",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andrea Catalina Vega Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023000636",
                    "FECHA INICIAL": "2022-07-05",
                    "FECHA OPERACION": "2022-07-26",
                    "NOMBRES": "Leidy Tatiana",
                    "APELLIDOS": "Angulo Sanchez",
                    "NOMBRES COMPLETOS": "Leidy Tatiana Angulo Sanchez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Fundacion",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "angu803368",
                    "USUARIOFIVE9": "angu803368@siigo.com",
                    "USUARIO RED": "angu803368",
                    "CORREOELECTRONICO": "angu803368@siigo.com",
                    "STATION ID": "2606066",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1130585372",
                    "FECHA INICIAL": "2022-07-13",
                    "FECHA OPERACION": "2022-07-28",
                    "NOMBRES": "Guillermo Alberto",
                    "APELLIDOS": "Medina Garces",
                    "NOMBRES COMPLETOS": "Guillermo Alberto Medina Garces",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "medi803374",
                    "USUARIOFIVE9": "medi803374@siigo.com",
                    "USUARIO RED": "medi803374",
                    "CORREOELECTRONICO": "medi803374@siigo.com",
                    "STATION ID": "2606177",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1056554476",
                    "FECHA INICIAL": "2022-07-26",
                    "FECHA OPERACION": "2022-08-04",
                    "NOMBRES": "Erika Maritza",
                    "APELLIDOS": "Cely Amaya",
                    "NOMBRES COMPLETOS": "Erika Maritza Cely Amaya",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Tunja",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Michelle Matamoros Del Rosario",
                    "CODIGO USUARIO": "cely803449",
                    "USUARIOFIVE9": "cely803449@siigo.com",
                    "USUARIO RED": "cely803449",
                    "CORREOELECTRONICO": "cely803449@siigo.com",
                    "STATION ID": "2606358",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Michelle Matamoros Del Rosario",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80035636",
                    "FECHA INICIAL": "2022-07-26",
                    "FECHA OPERACION": "2022-08-04",
                    "NOMBRES": "Giovanni Alberto",
                    "APELLIDOS": "Moya Farfan",
                    "NOMBRES COMPLETOS": "Giovanni Alberto Moya Farfan",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jose Manuel Aguayo Escandon",
                    "CODIGO USUARIO": "moya803448",
                    "USUARIOFIVE9": "moya803448@siigo.com",
                    "USUARIO RED": "moya803448",
                    "CORREOELECTRONICO": "moya803448@siigo.com",
                    "STATION ID": "2606669",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jose Manuel Aguayo Escandon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022355495",
                    "FECHA INICIAL": "2022-07-26",
                    "FECHA OPERACION": "2022-08-04",
                    "NOMBRES": "Lady Marcela",
                    "APELLIDOS": "Mayorga Laverde",
                    "NOMBRES COMPLETOS": "Lady Marcela Mayorga Laverde",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Walter Antonio Munoz Antepara",
                    "CODIGO USUARIO": "mayo803446",
                    "USUARIOFIVE9": "mayo803446@siigo.com",
                    "USUARIO RED": "mayo803446",
                    "CORREOELECTRONICO": "mayo803446@siigo.com",
                    "STATION ID": "2606824",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Walter Antonio Munoz Antepara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007625801",
                    "FECHA INICIAL": "2022-07-29",
                    "FECHA OPERACION": "2022-08-04",
                    "NOMBRES": "Lizeth Natalia",
                    "APELLIDOS": "Henao Pinzon",
                    "NOMBRES COMPLETOS": "Lizeth Natalia Henao Pinzon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "hena803464",
                    "USUARIOFIVE9": "hena803464@siigo.com",
                    "USUARIO RED": "hena803464",
                    "CORREOELECTRONICO": "hena803464@siigo.com",
                    "STATION ID": "2606875",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "50970389",
                    "FECHA INICIAL": "2022-07-29",
                    "FECHA OPERACION": "2022-08-04",
                    "NOMBRES": "Yurledis",
                    "APELLIDOS": "Sanchez Meza",
                    "NOMBRES COMPLETOS": "Yurledis Sanchez Meza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "sanc803462",
                    "USUARIOFIVE9": "sanc803462@siigo.com",
                    "USUARIO RED": "sanc803462",
                    "CORREOELECTRONICO": "sanc803462@siigo.com",
                    "STATION ID": "3127600",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "930511",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jose Maria",
                    "APELLIDOS": "Rivera Rojas",
                    "NOMBRES COMPLETOS": "Jose Maria Rivera Rojas",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "jrojas",
                    "USUARIOFIVE9": "jrojas@aspel.com.mx",
                    "USUARIO RED": "jrojas",
                    "CORREOELECTRONICO": "jrojas@aspel.com.mx",
                    "STATION ID": "3763956",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014201546",
                    "FECHA INICIAL": "2022-08-03",
                    "FECHA OPERACION": "2022-08-09",
                    "NOMBRES": "Andres Felipe",
                    "APELLIDOS": "Archila Diaz",
                    "NOMBRES COMPLETOS": "Andres Felipe Archila Diaz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Sanchez Velez",
                    "CODIGO USUARIO": "arch803501",
                    "USUARIOFIVE9": "arch803501@siigo.com",
                    "USUARIO RED": "arch803501",
                    "CORREOELECTRONICO": "arch803501@siigo.com",
                    "STATION ID": "2605966",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Sanchez Velez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52394876",
                    "FECHA INICIAL": "2022-08-02",
                    "FECHA OPERACION": "2022-08-09",
                    "NOMBRES": "Claudia Patricia",
                    "APELLIDOS": "Galindo",
                    "NOMBRES COMPLETOS": "Claudia Patricia Galindo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Liliana Andrea Segura Martinez",
                    "CODIGO USUARIO": "gali803491",
                    "USUARIOFIVE9": "gali803491@siigo.com",
                    "USUARIO RED": "gali803491",
                    "CORREOELECTRONICO": "gali803491@siigo.com",
                    "STATION ID": "2606017",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Andrea Segura Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53089117",
                    "FECHA INICIAL": "2022-08-02",
                    "FECHA OPERACION": "2022-08-09",
                    "NOMBRES": "Jessica Del Pilar",
                    "APELLIDOS": "Guzman Rojas",
                    "NOMBRES COMPLETOS": "Jessica Del Pilar Guzman Rojas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Liliana Andrea Segura Martinez",
                    "CODIGO USUARIO": "guzm803481",
                    "USUARIOFIVE9": "guzm803481@siigo.com",
                    "USUARIO RED": "guzm803481",
                    "CORREOELECTRONICO": "guzm803481@siigo.com",
                    "STATION ID": "2606147",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Andrea Segura Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52836802",
                    "FECHA INICIAL": "2022-08-03",
                    "FECHA OPERACION": "2022-08-09",
                    "NOMBRES": "Katerine Andrea",
                    "APELLIDOS": "Palma Ripoll",
                    "NOMBRES COMPLETOS": "Katerine Andrea Palma Ripoll",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Liliana Andrea Segura Martinez",
                    "CODIGO USUARIO": "palm803504",
                    "USUARIOFIVE9": "palm803504@siigo.com",
                    "USUARIO RED": "palm803504",
                    "CORREOELECTRONICO": "palm803504@siigo.com",
                    "STATION ID": "2606275",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Andrea Segura Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016094405",
                    "FECHA INICIAL": "2022-08-03",
                    "FECHA OPERACION": "2022-08-09",
                    "NOMBRES": "Laura",
                    "APELLIDOS": "Carvajal Chaparro",
                    "NOMBRES COMPLETOS": "Laura Carvajal Chaparro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Liliana Andrea Segura Martinez",
                    "CODIGO USUARIO": "carv803500",
                    "USUARIOFIVE9": "carv803500@siigo.com",
                    "USUARIO RED": "carv803500",
                    "CORREOELECTRONICO": "carv803500@siigo.com",
                    "STATION ID": "2606307",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Andrea Segura Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016029984",
                    "FECHA INICIAL": "2022-08-03",
                    "FECHA OPERACION": "2022-08-09",
                    "NOMBRES": "Leidy",
                    "APELLIDOS": "De La Rosa Acero",
                    "NOMBRES COMPLETOS": "Leidy De La Rosa Acero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Mosquera",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Liliana Andrea Segura Martinez",
                    "CODIGO USUARIO": "dela803493",
                    "USUARIOFIVE9": "dela803493@siigo.com",
                    "USUARIO RED": "dela803493",
                    "CORREOELECTRONICO": "dela803493@siigo.com",
                    "STATION ID": "2606312",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Andrea Segura Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "28550804",
                    "FECHA INICIAL": "2022-08-03",
                    "FECHA OPERACION": "2022-08-09",
                    "NOMBRES": "Lyda Teresa",
                    "APELLIDOS": "Barahona Romero",
                    "NOMBRES COMPLETOS": "Lyda Teresa Barahona Romero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Sanchez Velez",
                    "CODIGO USUARIO": "bara803494",
                    "USUARIOFIVE9": "bara803494@siigo.com",
                    "USUARIO RED": "bara803494",
                    "CORREOELECTRONICO": "bara803494@siigo.com",
                    "STATION ID": "3127507",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Sanchez Velez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010242628",
                    "FECHA INICIAL": "2022-08-02",
                    "FECHA OPERACION": "2022-08-09",
                    "NOMBRES": "Maria Camila",
                    "APELLIDOS": "Rios Sanchez",
                    "NOMBRES COMPLETOS": "Maria Camila Rios Sanchez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jessica Paola Paez Quintero",
                    "CODIGO USUARIO": "rios803486",
                    "USUARIOFIVE9": "rios803486@siigo.com",
                    "USUARIO RED": "rios803486",
                    "CORREOELECTRONICO": "rios803486@siigo.com",
                    "STATION ID": "3267830",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jessica Paola Paez Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53017120",
                    "FECHA INICIAL": "2022-08-03",
                    "FECHA OPERACION": "2022-08-09",
                    "NOMBRES": "Nidia Marcela",
                    "APELLIDOS": "Bayona Sanchez",
                    "NOMBRES COMPLETOS": "Nidia Marcela Bayona Sanchez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Liliana Andrea Segura Martinez",
                    "CODIGO USUARIO": "bayo803496",
                    "USUARIOFIVE9": "bayo803496@siigo.com",
                    "USUARIO RED": "bayo803496",
                    "CORREOELECTRONICO": "bayo803496@siigo.com",
                    "STATION ID": "3267866",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Andrea Segura Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018484790",
                    "FECHA INICIAL": "2022-08-03",
                    "FECHA OPERACION": "2022-08-09",
                    "NOMBRES": "Paula Ximena",
                    "APELLIDOS": "Ramirez Rey",
                    "NOMBRES COMPLETOS": "Paula Ximena Ramirez Rey",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Liliana Andrea Segura Martinez",
                    "CODIGO USUARIO": "rami803488",
                    "USUARIOFIVE9": "rami803488@siigo.com",
                    "USUARIO RED": "rami803488",
                    "CORREOELECTRONICO": "rami803488@siigo.com",
                    "STATION ID": "3267886",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Andrea Segura Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1085331110",
                    "FECHA INICIAL": "2022-08-03",
                    "FECHA OPERACION": "2022-08-09",
                    "NOMBRES": "Paulo Alejandro",
                    "APELLIDOS": "Folleco Segovia",
                    "NOMBRES COMPLETOS": "Paulo Alejandro Folleco Segovia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Liliana Andrea Segura Martinez",
                    "CODIGO USUARIO": "foll803503",
                    "USUARIOFIVE9": "foll803503@siigo.com",
                    "USUARIO RED": "foll803503",
                    "CORREOELECTRONICO": "foll803503@siigo.com",
                    "STATION ID": "3267895",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Andrea Segura Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014198996",
                    "FECHA INICIAL": "2022-08-03",
                    "FECHA OPERACION": "2022-08-09",
                    "NOMBRES": "Yenny Andrea",
                    "APELLIDOS": "Calderon Rodriguez",
                    "NOMBRES COMPLETOS": "Yenny Andrea Calderon Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jessica Paola Paez Quintero",
                    "CODIGO USUARIO": "cald803502",
                    "USUARIOFIVE9": "cald803502@siigo.com",
                    "USUARIO RED": "cald803502",
                    "CORREOELECTRONICO": "cald803502@siigo.com",
                    "STATION ID": "3471511",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jessica Paola Paez Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1047486977",
                    "FECHA INICIAL": "2022-08-10",
                    "FECHA OPERACION": "2022-08-18",
                    "NOMBRES": "Jeferson Jose",
                    "APELLIDOS": "Palomino Guerrero",
                    "NOMBRES COMPLETOS": "Jeferson Jose Palomino Guerrero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Lida Katerine Puentes Navarro",
                    "CODIGO USUARIO": "palo803539",
                    "USUARIOFIVE9": "palo803539@siigo.com",
                    "USUARIO RED": "palo803539",
                    "CORREOELECTRONICO": "palo803539@siigo.com",
                    "STATION ID": "2606437",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Lida Katerine Puentes Navarro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1031152582",
                    "FECHA INICIAL": "2022-08-10",
                    "FECHA OPERACION": "2022-08-18",
                    "NOMBRES": "Junior Enrique",
                    "APELLIDOS": "Valderrama Bravo",
                    "NOMBRES COMPLETOS": "Junior Enrique Valderrama Bravo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Lida Katerine Puentes Navarro",
                    "CODIGO USUARIO": "vald803538",
                    "USUARIOFIVE9": "vald803538@siigo.com",
                    "USUARIO RED": "vald803538",
                    "CORREOELECTRONICO": "vald803538@siigo.com",
                    "STATION ID": "2606439",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Lida Katerine Puentes Navarro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1031133906",
                    "FECHA INICIAL": "2022-08-10",
                    "FECHA OPERACION": "2022-08-18",
                    "NOMBRES": "Geraldine",
                    "APELLIDOS": "Rodriguez Ospina",
                    "NOMBRES COMPLETOS": "Geraldine Rodriguez Ospina",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Yeimy Milena Bustos Gutierrez",
                    "CODIGO USUARIO": "rodr803532",
                    "USUARIOFIVE9": "rodr803532@siigo.com",
                    "USUARIO RED": "rodr803532",
                    "CORREOELECTRONICO": "rodr803532@siigo.com",
                    "STATION ID": "2606914",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yeimy Milena Bustos Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "38211962",
                    "FECHA INICIAL": "2022-08-10",
                    "FECHA OPERACION": "2022-08-18",
                    "NOMBRES": "Viviana Patricia",
                    "APELLIDOS": "Romero Osorio",
                    "NOMBRES COMPLETOS": "Viviana Patricia Romero Osorio",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Johanna Patricia Torres Quintero",
                    "CODIGO USUARIO": "rome803530",
                    "USUARIOFIVE9": "rome803530@siigo.com",
                    "USUARIO RED": "rome803530",
                    "CORREOELECTRONICO": "rome803530@siigo.com",
                    "STATION ID": "2607079",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Johanna Patricia Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1233501659",
                    "FECHA INICIAL": "2022-08-10",
                    "FECHA OPERACION": "2022-08-18",
                    "NOMBRES": "Anyi Alejandra",
                    "APELLIDOS": "Pabon Parrado",
                    "NOMBRES COMPLETOS": "Anyi Alejandra Pabon Parrado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Johanna Patricia Torres Quintero",
                    "CODIGO USUARIO": "pabo803529",
                    "USUARIOFIVE9": "pabo803529@siigo.com",
                    "USUARIO RED": "pabo803529",
                    "CORREOELECTRONICO": "pabo803529@siigo.com",
                    "STATION ID": "3127496",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Johanna Patricia Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098776191",
                    "FECHA INICIAL": "2022-08-10",
                    "FECHA OPERACION": "2022-08-18",
                    "NOMBRES": "Sebastian Andres",
                    "APELLIDOS": "Herrera Bautista",
                    "NOMBRES COMPLETOS": "Sebastian Andres Herrera Bautista",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Mercadeo",
                    "CARGO": "Segment Marketing Owner",
                    "JEFE INMEDIATO": "Estefania Sofia Barbosa Fajardo",
                    "CODIGO USUARIO": "herr803526",
                    "USUARIOFIVE9": "herr803526@siigo.com",
                    "USUARIO RED": "herr803526",
                    "CORREOELECTRONICO": "herr803526@siigo.com",
                    "STATION ID": "3127550",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Estefania Sofia Barbosa Fajardo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143460980",
                    "FECHA INICIAL": "2022-08-10",
                    "FECHA OPERACION": "2022-08-18",
                    "NOMBRES": "Jessica Patricia",
                    "APELLIDOS": "Acosta Charris",
                    "NOMBRES COMPLETOS": "Jessica Patricia Acosta Charris",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Yeimy Milena Bustos Gutierrez",
                    "CODIGO USUARIO": "acos803525",
                    "USUARIOFIVE9": "acos803525@siigo.com",
                    "USUARIO RED": "acos803525",
                    "CORREOELECTRONICO": "acos803525@siigo.com",
                    "STATION ID": "3127589",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yeimy Milena Bustos Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1149451583",
                    "FECHA INICIAL": "2022-08-10",
                    "FECHA OPERACION": "2022-08-18",
                    "NOMBRES": "Heivert Andres",
                    "APELLIDOS": "Cabrera Gamero",
                    "NOMBRES COMPLETOS": "Heivert Andres Cabrera Gamero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Eliana Maria Fajardo Prada",
                    "CODIGO USUARIO": "cabr803523",
                    "USUARIOFIVE9": "cabr803523@siigo.com",
                    "USUARIO RED": "cabr803523",
                    "CORREOELECTRONICO": "cabr803523@siigo.com",
                    "STATION ID": "3127602",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Eliana Maria Fajardo Prada",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1216713247",
                    "FECHA INICIAL": "2022-08-10",
                    "FECHA OPERACION": "2022-08-18",
                    "NOMBRES": "Ana Milena",
                    "APELLIDOS": "Garcia Urrego",
                    "NOMBRES COMPLETOS": "Ana Milena Garcia Urrego",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones ECU",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Angie Camila Diaz Escobar",
                    "CODIGO USUARIO": "garc803522",
                    "USUARIOFIVE9": "garc803522@siigo.com",
                    "USUARIO RED": "garc803522",
                    "CORREOELECTRONICO": "garc803522@siigo.com",
                    "STATION ID": "3267898",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Camila Diaz Escobar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1045733663",
                    "FECHA INICIAL": "2022-08-10",
                    "FECHA OPERACION": "2022-08-18",
                    "NOMBRES": "Wendy Sandry",
                    "APELLIDOS": "Barranza Correa",
                    "NOMBRES COMPLETOS": "Wendy Sandry Barranza Correa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Francy Julieth Viracacha Gutierrez",
                    "CODIGO USUARIO": "barr803521",
                    "USUARIOFIVE9": "barr803521@siigo.com",
                    "USUARIO RED": "barr803521",
                    "CORREOELECTRONICO": "barr803521@siigo.com",
                    "STATION ID": "3267916",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Francy Julieth Viracacha Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1098707904",
                    "FECHA INICIAL": "2022-08-10",
                    "FECHA OPERACION": "2022-08-18",
                    "NOMBRES": "Leidy Katherine",
                    "APELLIDOS": "Gomez Angarita",
                    "NOMBRES COMPLETOS": "Leidy Katherine Gomez Angarita",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Johanna Patricia Torres Quintero",
                    "CODIGO USUARIO": "gome803517",
                    "USUARIOFIVE9": "gome803517@siigo.com",
                    "USUARIO RED": "gome803517",
                    "CORREOELECTRONICO": "gome803517@siigo.com",
                    "STATION ID": "3483699",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Johanna Patricia Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030522033",
                    "FECHA INICIAL": "2022-08-10",
                    "FECHA OPERACION": "2022-08-18",
                    "NOMBRES": "Daniel Arturo",
                    "APELLIDOS": "Leyva Santana",
                    "NOMBRES COMPLETOS": "Daniel Arturo Leyva Santana",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Yeimy Milena Bustos Gutierrez",
                    "CODIGO USUARIO": "leyv803516",
                    "USUARIOFIVE9": "leyv803516@siigo.com",
                    "USUARIO RED": "leyv803516",
                    "CORREOELECTRONICO": "leyv803516@siigo.com",
                    "STATION ID": "3483701",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yeimy Milena Bustos Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "43571008",
                    "FECHA INICIAL": "2022-08-10",
                    "FECHA OPERACION": "2022-08-18",
                    "NOMBRES": "Sandra Isabel",
                    "APELLIDOS": "Gonzalez Morales",
                    "NOMBRES COMPLETOS": "Sandra Isabel Gonzalez Morales",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Johanna Patricia Torres Quintero",
                    "CODIGO USUARIO": "gonz803514",
                    "USUARIOFIVE9": "gonz803514@siigo.com",
                    "USUARIO RED": "gonz803514",
                    "CORREOELECTRONICO": "gonz803514@siigo.com",
                    "STATION ID": "3483719",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Johanna Patricia Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "880221",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Oscar Eduardo",
                    "APELLIDOS": "Gallardo Lopez",
                    "NOMBRES COMPLETOS": "Oscar Eduardo Gallardo Lopez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "ogallardo",
                    "USUARIOFIVE9": "ogallardo@aspel.com.mx",
                    "USUARIO RED": "ogallardo",
                    "CORREOELECTRONICO": "ogallardo@aspel.com.mx",
                    "STATION ID": "3763957",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "870707",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Pablo Cesar Arturo",
                    "APELLIDOS": "Villanueva Martinez",
                    "NOMBRES COMPLETOS": "Pablo Cesar Arturo Villanueva Martinez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "pvillanueva",
                    "USUARIOFIVE9": "pvillanueva@aspel.com.mx",
                    "USUARIO RED": "pvillanueva",
                    "CORREOELECTRONICO": "pvillanueva@aspel.com.mx",
                    "STATION ID": "3763959",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "940604",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Ricardo",
                    "APELLIDOS": "Sanchez Perez",
                    "NOMBRES COMPLETOS": "Ricardo Sanchez Perez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "ricsanchez",
                    "USUARIOFIVE9": "ricsanchez@aspel.com.mx",
                    "USUARIO RED": "ricsanchez",
                    "CORREOELECTRONICO": "ricsanchez@aspel.com.mx",
                    "STATION ID": "3763962",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "911",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Carlos Eduardo",
                    "APELLIDOS": "Hernandez Martinez",
                    "NOMBRES COMPLETOS": "Carlos Eduardo Hernandez Martinez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "chernandezm",
                    "USUARIOFIVE9": "chernandezm@aspel.com.mx",
                    "USUARIO RED": "chernandezm",
                    "CORREOELECTRONICO": "chernandezm@aspel.com.mx",
                    "STATION ID": "3763969",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "880104",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jose Luis",
                    "APELLIDOS": "Islas Jose",
                    "NOMBRES COMPLETOS": "Jose Luis Islas Jose",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "jislas",
                    "USUARIOFIVE9": "jislas@aspel.com.mx",
                    "USUARIO RED": "jislas",
                    "CORREOELECTRONICO": "jislas@aspel.com.mx",
                    "STATION ID": "3763970",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "850704",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Mauricio",
                    "APELLIDOS": "Molina Resendiz",
                    "NOMBRES COMPLETOS": "Mauricio Molina Resendiz",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "mmolina",
                    "USUARIOFIVE9": "mmolina@aspel.com.mx",
                    "USUARIO RED": "mmolina",
                    "CORREOELECTRONICO": "mmolina@aspel.com.mx",
                    "STATION ID": "3857094",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "9711218",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Juan Manuel",
                    "APELLIDOS": "Jerez Ramirez",
                    "NOMBRES COMPLETOS": "Juan Manuel Jerez Ramirez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "mjerez",
                    "USUARIOFIVE9": "mjerez@aspel.com.mx",
                    "USUARIO RED": "mjerez",
                    "CORREOELECTRONICO": "mjerez@aspel.com.mx",
                    "STATION ID": "3857096",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "830915",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Erick",
                    "APELLIDOS": "Galindo Rojas",
                    "NOMBRES COMPLETOS": "Erick Galindo Rojas",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "egalindo",
                    "USUARIOFIVE9": "egalindo@aspel.com.mx",
                    "USUARIO RED": "egalindo",
                    "CORREOELECTRONICO": "egalindo@aspel.com.mx",
                    "STATION ID": "3857097",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "9506019",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Aldo Uriel",
                    "APELLIDOS": "Calzada Vargas",
                    "NOMBRES COMPLETOS": "Aldo Uriel Calzada Vargas",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "acalzada",
                    "USUARIOFIVE9": "acalzada@aspel.com.mx",
                    "USUARIO RED": "acalzada",
                    "CORREOELECTRONICO": "acalzada@aspel.com.mx",
                    "STATION ID": "3857100",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "951211",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Eric Eduardo",
                    "APELLIDOS": "Figueroa Gonzalez",
                    "NOMBRES COMPLETOS": "Eric Eduardo Figueroa Gonzalez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "efigueroa",
                    "USUARIOFIVE9": "efigueroa@aspel.com.mx",
                    "USUARIO RED": "efigueroa",
                    "CORREOELECTRONICO": "efigueroa@aspel.com.mx",
                    "STATION ID": "3857103",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "COSE900909H90",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2022-09-28",
                    "NOMBRES": "Eline Michelle",
                    "APELLIDOS": "Corona Sanchez",
                    "NOMBRES COMPLETOS": "Eline Michelle Corona Sanchez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "emcorona",
                    "USUARIOFIVE9": "emcorona@aspel.com.mx",
                    "USUARIO RED": "emcorona",
                    "CORREOELECTRONICO": "emcorona@aspel.com.mx",
                    "STATION ID": "3127463",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "ZAEB920212B",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2022-09-28",
                    "NOMBRES": "Brenda Berenice",
                    "APELLIDOS": "Zamudio Estala",
                    "NOMBRES COMPLETOS": "Brenda Berenice Zamudio Estala",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "bzamudio",
                    "USUARIOFIVE9": "bzamudio@aspel.com.mx",
                    "USUARIO RED": "bzamudio",
                    "CORREOELECTRONICO": "bzamudio@aspel.com.mx",
                    "STATION ID": "3127484",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "OUVF780107UH9",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2022-09-28",
                    "NOMBRES": "Fernando Emmanuel",
                    "APELLIDOS": "Olguin Vallejo",
                    "NOMBRES COMPLETOS": "Fernando Emmanuel Olguin Vallejo",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "folguin",
                    "USUARIOFIVE9": "folguin@aspel.com.mx",
                    "USUARIO RED": "folguin",
                    "CORREOELECTRONICO": "folguin@aspel.com.mx",
                    "STATION ID": "3127486",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "GADY8712122U7",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-10",
                    "NOMBRES": "Yesica Guadalupe",
                    "APELLIDOS": "Garcia Damian",
                    "NOMBRES COMPLETOS": "Yesica Guadalupe Garcia Damian",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "jdamian",
                    "USUARIOFIVE9": "jdamian@aspel.com.mx",
                    "USUARIO RED": "jdamian",
                    "CORREOELECTRONICO": "jdamian@aspel.com.mx",
                    "STATION ID": "4421841",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MARL960620230",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Luis Antonio",
                    "APELLIDOS": "Matias Roman",
                    "NOMBRES COMPLETOS": "Luis Antonio Matias Roman",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Oscar Rodrigo Navarro Hernandez",
                    "CODIGO USUARIO": "lroman",
                    "USUARIOFIVE9": "lroman@aspel.com.mx",
                    "USUARIO RED": "lroman",
                    "CORREOELECTRONICO": "lroman@aspel.com.mx",
                    "STATION ID": "3690381",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Oscar Rodrigo Navarro Hernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "BAGV8610028Z0",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Victor Manuel",
                    "APELLIDOS": "Bastian Gomez",
                    "NOMBRES COMPLETOS": "Victor Manuel Bastian Gomez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Carranza Valdes",
                    "CODIGO USUARIO": "vbastian",
                    "USUARIOFIVE9": "vbastian@aspel.com.mx",
                    "USUARIO RED": "vbastian",
                    "CORREOELECTRONICO": "vbastian@aspel.com.mx",
                    "STATION ID": "4036375",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Carranza Valdes",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "TOHM860804SI4",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Marisol",
                    "APELLIDOS": "Torres Hernandez",
                    "NOMBRES COMPLETOS": "Marisol Torres Hernandez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Product Owner",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "mtorresh",
                    "USUARIOFIVE9": "mtorresh@aspel.com.mx",
                    "USUARIO RED": "mtorresh",
                    "CORREOELECTRONICO": "mtorresh@aspel.com.mx",
                    "STATION ID": "3763886",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "89080709",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Ulises",
                    "APELLIDOS": "Perez Velazquez",
                    "NOMBRES COMPLETOS": "Ulises Perez Velazquez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Desarrollo",
                    "JEFE INMEDIATO": "Oscar Francisco Plaza Giler",
                    "CODIGO USUARIO": "uperez",
                    "USUARIOFIVE9": "uperez@aspel.com.mx",
                    "USUARIO RED": "uperez",
                    "CORREOELECTRONICO": "uperez@aspel.com.mx",
                    "STATION ID": "3763912",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Oscar Francisco Plaza Giler",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "881202",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-13",
                    "NOMBRES": "Elizabeth",
                    "APELLIDOS": "Moreno Orozco",
                    "NOMBRES COMPLETOS": "Elizabeth Moreno Orozco",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Product Owner",
                    "JEFE INMEDIATO": "Oscar Francisco Plaza Giler",
                    "CODIGO USUARIO": "emoreno",
                    "USUARIOFIVE9": "emoreno@aspel.com.mx",
                    "USUARIO RED": "emoreno",
                    "CORREOELECTRONICO": "emoreno@aspel.com.mx",
                    "STATION ID": "3857239",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Oscar Francisco Plaza Giler",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "CARG961213MM4",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Geovanni Daniel",
                    "APELLIDOS": "Carreon Rico",
                    "NOMBRES COMPLETOS": "Geovanni Daniel Carreon Rico",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Oscar Rodrigo Navarro Hernandez",
                    "CODIGO USUARIO": "gcarreon",
                    "USUARIOFIVE9": "gcarreon@aspel.com.mx",
                    "USUARIO RED": "gcarreon",
                    "CORREOELECTRONICO": "gcarreon@aspel.com.mx",
                    "STATION ID": "3763838",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Oscar Rodrigo Navarro Hernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "ROLK940709GR7",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Karina",
                    "APELLIDOS": "Lerista De La Rosa",
                    "NOMBRES COMPLETOS": "Karina Lerista De La Rosa",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Alejandro Roa Franco",
                    "CODIGO USUARIO": "klerista",
                    "USUARIOFIVE9": "klerista@aspel.com.mx",
                    "USUARIO RED": "klerista",
                    "CORREOELECTRONICO": "klerista@aspel.com.mx",
                    "STATION ID": "3763858",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Alejandro Roa Franco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "DONV980728RW9",
                    "FECHA INICIAL": "2022-04-28",
                    "FECHA OPERACION": "2022-04-28",
                    "NOMBRES": "Victor Manuel",
                    "APELLIDOS": "Dominguez Nava",
                    "NOMBRES COMPLETOS": "Victor Manuel Dominguez Nava",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Oscar Rodrigo Navarro Hernandez",
                    "CODIGO USUARIO": "vdominguez",
                    "USUARIOFIVE9": "vdominguez@aspel.com.mx",
                    "USUARIO RED": "vdominguez",
                    "CORREOELECTRONICO": "vdominguez@aspel.com.mx",
                    "STATION ID": "3857182",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Oscar Rodrigo Navarro Hernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016049006",
                    "FECHA INICIAL": "2022-08-02",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Maria Paula",
                    "APELLIDOS": "Garzon Ortega",
                    "NOMBRES COMPLETOS": "Maria Paula Garzon Ortega",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Mercadeo",
                    "CARGO": "Strategic Marketing Segments",
                    "JEFE INMEDIATO": "Julian Bernardo Fernandez Garcia",
                    "CODIGO USUARIO": "garz803477",
                    "USUARIOFIVE9": "garz803477@siigo.com",
                    "USUARIO RED": "garz803477",
                    "CORREOELECTRONICO": "garz803477@siigo.com",
                    "STATION ID": "2606389",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Julian Bernardo Fernandez Garcia",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1027951151",
                    "FECHA INICIAL": "2022-08-10",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Eliana Marcela",
                    "APELLIDOS": "Patino Ramirez",
                    "NOMBRES COMPLETOS": "Eliana Marcela Patino Ramirez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Lida Katerine Puentes Navarro",
                    "CODIGO USUARIO": "pati803537",
                    "USUARIOFIVE9": "pati803537@siigo.com",
                    "USUARIO RED": "pati803537",
                    "CORREOELECTRONICO": "pati803537@siigo.com",
                    "STATION ID": "2606591",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Lida Katerine Puentes Navarro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1003465983",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Luz Mery",
                    "APELLIDOS": "Pelaez Arrieta",
                    "NOMBRES COMPLETOS": "Luz Mery Pelaez Arrieta",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Andrea Catalina Vega Gomez",
                    "CODIGO USUARIO": "pela803590",
                    "USUARIOFIVE9": "pela803590@siigo.com",
                    "USUARIO RED": "pela803590",
                    "CORREOELECTRONICO": "pela803590@siigo.com",
                    "STATION ID": "2606741",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andrea Catalina Vega Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52284977",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Veronica",
                    "APELLIDOS": "Albadan Castrillon",
                    "NOMBRES COMPLETOS": "Veronica Albadan Castrillon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "alba803592",
                    "USUARIOFIVE9": "alba803592@siigo.com",
                    "USUARIO RED": "alba803592",
                    "CORREOELECTRONICO": "alba803592@siigo.com",
                    "STATION ID": "2606852",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1051447664",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Venis",
                    "APELLIDOS": "Quintana Herrera",
                    "NOMBRES COMPLETOS": "Venis Quintana Herrera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soledad",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Carolina Linares Gonzalez",
                    "CODIGO USUARIO": "quin803605",
                    "USUARIOFIVE9": "quin803605@siigo.com",
                    "USUARIO RED": "quin803605",
                    "CORREOELECTRONICO": "quin803605@siigo.com",
                    "STATION ID": "2607002",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Carolina Linares Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007314177",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Sheyla Mercedes",
                    "APELLIDOS": "Utria Gomez",
                    "NOMBRES COMPLETOS": "Sheyla Mercedes Utria Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Carolina Linares Gonzalez",
                    "CODIGO USUARIO": "utri803604",
                    "USUARIOFIVE9": "utri803604@siigo.com",
                    "USUARIO RED": "utri803604",
                    "CORREOELECTRONICO": "utri803604@siigo.com",
                    "STATION ID": "3127521",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Carolina Linares Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010102324",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Keidy Talia",
                    "APELLIDOS": "Jimenez Suarez",
                    "NOMBRES COMPLETOS": "Keidy Talia Jimenez Suarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Yamid Ramiro Quintero Montano",
                    "CODIGO USUARIO": "jime803607",
                    "USUARIOFIVE9": "jime803607@siigo.com",
                    "USUARIO RED": "jime803607",
                    "CORREOELECTRONICO": "jime803607@siigo.com",
                    "STATION ID": "3127523",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yamid Ramiro Quintero Montano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1005718580",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Alejandra",
                    "APELLIDOS": "Reyes Nieto",
                    "NOMBRES COMPLETOS": "Alejandra Reyes Nieto",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luz Katerine Maya Mogollon",
                    "CODIGO USUARIO": "reye803606",
                    "USUARIOFIVE9": "reye803606@siigo.com",
                    "USUARIO RED": "reye803606",
                    "CORREOELECTRONICO": "reye803606@siigo.com",
                    "STATION ID": "3127527",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Katerine Maya Mogollon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "45564382",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Aurora Margarita",
                    "APELLIDOS": "Polo Alean",
                    "NOMBRES COMPLETOS": "Aurora Margarita Polo Alean",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Fernando Sotto Cardozo",
                    "CODIGO USUARIO": "polo803619",
                    "USUARIOFIVE9": "polo803619@siigo.com",
                    "USUARIO RED": "polo803619",
                    "CORREOELECTRONICO": "polo803619@siigo.com",
                    "STATION ID": "3267882",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Fernando Sotto Cardozo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "45120516",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Juan Martin",
                    "APELLIDOS": "Casavalle Bonilla",
                    "NOMBRES COMPLETOS": "Juan Martin Casavalle Bonilla",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Luis Ignacio Ferreira Duarte",
                    "CODIGO USUARIO": "casa803621",
                    "USUARIOFIVE9": "casa803621@siigo.com",
                    "USUARIO RED": "casa803621",
                    "CORREOELECTRONICO": "casa803621@siigo.com",
                    "STATION ID": "3483697",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Luis Ignacio Ferreira Duarte",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1221978212",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Ana Karina De Jesus",
                    "APELLIDOS": "Fernandez Glen",
                    "NOMBRES COMPLETOS": "Ana Karina De Jesus Fernandez Glen",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cienaga",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jefrey Julian Sanchez Celemin",
                    "CODIGO USUARIO": "fern803600",
                    "USUARIOFIVE9": "fern803600@siigo.com",
                    "USUARIO RED": "fern803600",
                    "CORREOELECTRONICO": "fern803600@siigo.com",
                    "STATION ID": "3483712",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jefrey Julian Sanchez Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1020799659",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Maritza Jeraldin",
                    "APELLIDOS": "Acevedo Nope",
                    "NOMBRES COMPLETOS": "Maritza Jeraldin Acevedo Nope",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Chinavita",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luz Katerine Maya Mogollon",
                    "CODIGO USUARIO": "acev803614",
                    "USUARIOFIVE9": "acev803614@siigo.com",
                    "USUARIO RED": "acev803614",
                    "CORREOELECTRONICO": "acev803614@siigo.com",
                    "STATION ID": "3483714",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Katerine Maya Mogollon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1082850928",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Yorleinis Melissa",
                    "APELLIDOS": "Gonzalez Rivas",
                    "NOMBRES COMPLETOS": "Yorleinis Melissa Gonzalez Rivas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Santa Marta",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Nicoll Torres Celemin",
                    "CODIGO USUARIO": "gonz803602",
                    "USUARIOFIVE9": "gonz803602@siigo.com",
                    "USUARIO RED": "gonz803602",
                    "CORREOELECTRONICO": "gonz803602@siigo.com",
                    "STATION ID": "3690260",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Nicoll Torres Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1110554630",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Jhon Weimar",
                    "APELLIDOS": "Prada Rojas",
                    "NOMBRES COMPLETOS": "Jhon Weimar Prada Rojas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luz Katerine Maya Mogollon",
                    "CODIGO USUARIO": "prad803613",
                    "USUARIOFIVE9": "prad803613@siigo.com",
                    "USUARIO RED": "prad803613",
                    "CORREOELECTRONICO": "prad803613@siigo.com",
                    "STATION ID": "3895085",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Katerine Maya Mogollon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1047491066",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Leyla Vanessa",
                    "APELLIDOS": "Florez Quinto",
                    "NOMBRES COMPLETOS": "Leyla Vanessa Florez Quinto",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "flor803589",
                    "USUARIOFIVE9": "flor803589@siigo.com",
                    "USUARIO RED": "flor803589",
                    "CORREOELECTRONICO": "flor803589@siigo.com",
                    "STATION ID": "3690305",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023036155",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Veronica Paola",
                    "APELLIDOS": "Ortiz Zapata",
                    "NOMBRES COMPLETOS": "Veronica Paola Ortiz Zapata",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Erika Mildred Avila Olaya",
                    "CODIGO USUARIO": "orti803616",
                    "USUARIOFIVE9": "orti803616@siigo.com",
                    "USUARIO RED": "orti803616",
                    "CORREOELECTRONICO": "orti803616@siigo.com",
                    "STATION ID": "3690328",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Erika Mildred Avila Olaya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "40888628",
                    "FECHA INICIAL": "2022-08-24",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Martin Esteban",
                    "APELLIDOS": "Echegorri Almiron",
                    "NOMBRES COMPLETOS": "Martin Esteban Echegorri Almiron",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Luis Ignacio Ferreira Duarte",
                    "CODIGO USUARIO": "eche803586",
                    "USUARIOFIVE9": "eche803586@siigo.com",
                    "USUARIO RED": "eche803586",
                    "CORREOELECTRONICO": "eche803586@siigo.com",
                    "STATION ID": "3690339",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Luis Ignacio Ferreira Duarte",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1045742631",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Daniela Patricia",
                    "APELLIDOS": "Ramos Acosta",
                    "NOMBRES COMPLETOS": "Daniela Patricia Ramos Acosta",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "ramo803618",
                    "USUARIOFIVE9": "ramo803618@siigo.com",
                    "USUARIO RED": "ramo803618",
                    "CORREOELECTRONICO": "ramo803618@siigo.com",
                    "STATION ID": "3690348",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143265076",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Nelson Jesus",
                    "APELLIDOS": "Castro Rodriguez",
                    "NOMBRES COMPLETOS": "Nelson Jesus Castro Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "cast803617",
                    "USUARIOFIVE9": "cast803617@siigo.com",
                    "USUARIO RED": "cast803617",
                    "CORREOELECTRONICO": "cast803617@siigo.com",
                    "STATION ID": "3690361",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1110478157",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Leidy Alejandra",
                    "APELLIDOS": "Barbosa Amorocho",
                    "NOMBRES COMPLETOS": "Leidy Alejandra Barbosa Amorocho",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "San Roque",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Tatiana Reyes Perez",
                    "CODIGO USUARIO": "barb803596",
                    "USUARIOFIVE9": "barb803596@siigo.com",
                    "USUARIO RED": "barb803596",
                    "CORREOELECTRONICO": "barb803596@siigo.com",
                    "STATION ID": "3720334",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Tatiana Reyes Perez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057604381",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Mariana Del Pilar",
                    "APELLIDOS": "Cubides Cely",
                    "NOMBRES COMPLETOS": "Mariana Del Pilar Cubides Cely",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Sogamoso",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adrian Alberto Salom Berrio",
                    "CODIGO USUARIO": "cubi803599",
                    "USUARIOFIVE9": "cubi803599@siigo.com",
                    "USUARIO RED": "cubi803599",
                    "CORREOELECTRONICO": "cubi803599@siigo.com",
                    "STATION ID": "3720338",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adrian Alberto Salom Berrio",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1049649123",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Angelica Juliana",
                    "APELLIDOS": "Vargas Espinosa",
                    "NOMBRES COMPLETOS": "Angelica Juliana Vargas Espinosa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adrian Alberto Salom Berrio",
                    "CODIGO USUARIO": "varg803598",
                    "USUARIOFIVE9": "varg803598@siigo.com",
                    "USUARIO RED": "varg803598",
                    "CORREOELECTRONICO": "varg803598@siigo.com",
                    "STATION ID": "3895065",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adrian Alberto Salom Berrio",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "65702125",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Dalia Rocio",
                    "APELLIDOS": "Murillo Paramo",
                    "NOMBRES COMPLETOS": "Dalia Rocio Murillo Paramo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Fernando Sotto Cardozo",
                    "CODIGO USUARIO": "muri803609",
                    "USUARIOFIVE9": "muri803609@siigo.com",
                    "USUARIO RED": "muri803609",
                    "CORREOELECTRONICO": "muri803609@siigo.com",
                    "STATION ID": "3720349",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Fernando Sotto Cardozo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1002370313",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Christian Camilo",
                    "APELLIDOS": "Borda Sanabria",
                    "NOMBRES COMPLETOS": "Christian Camilo Borda Sanabria",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Tunja",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Colombia",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Olga Lucia Ospina Vidal",
                    "CODIGO USUARIO": "bord803608",
                    "USUARIOFIVE9": "bord803608@siigo.com",
                    "USUARIO RED": "bord803608",
                    "CORREOELECTRONICO": "bord803608@siigo.com",
                    "STATION ID": "3720354",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Olga Lucia Ospina Vidal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1053855438",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Maira Alejandra",
                    "APELLIDOS": "Sanchez Villa",
                    "NOMBRES COMPLETOS": "Maira Alejandra Sanchez Villa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Manizales",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Hancel Rafael Salcedo Mendoza",
                    "CODIGO USUARIO": "sanc803610",
                    "USUARIOFIVE9": "sanc803610@siigo.com",
                    "USUARIO RED": "sanc803610",
                    "CORREOELECTRONICO": "sanc803610@siigo.com",
                    "STATION ID": "3720380",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Hancel Rafael Salcedo Mendoza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1051667894",
                    "FECHA INICIAL": "2022-08-25",
                    "FECHA OPERACION": "2022-09-01",
                    "NOMBRES": "Katerine Paola",
                    "APELLIDOS": "Benthan Gutierrez",
                    "NOMBRES COMPLETOS": "Katerine Paola Benthan Gutierrez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Mompos",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jazbleidy Andrea Nunez Montoya",
                    "CODIGO USUARIO": "bent803612",
                    "USUARIOFIVE9": "bent803612@siigo.com",
                    "USUARIO RED": "bent803612",
                    "CORREOELECTRONICO": "bent803612@siigo.com",
                    "STATION ID": "3720383",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jazbleidy Andrea Nunez Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143135795",
                    "FECHA INICIAL": "2022-08-30",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Ana Yuranis",
                    "APELLIDOS": "Galindo Martinez",
                    "NOMBRES COMPLETOS": "Ana Yuranis Galindo Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jesus Daniel Arzuza Roman",
                    "CODIGO USUARIO": "gali803631",
                    "USUARIOFIVE9": "gali803631@siigo.com",
                    "USUARIO RED": "gali803631",
                    "CORREOELECTRONICO": "gali803631@siigo.com",
                    "STATION ID": "3720399",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jesus Daniel Arzuza Roman",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1006121107",
                    "FECHA INICIAL": "2022-08-30",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Laura Natali",
                    "APELLIDOS": "Cepeda Vidal",
                    "NOMBRES COMPLETOS": "Laura Natali Cepeda Vidal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Francy Julieth Viracacha Gutierrez",
                    "CODIGO USUARIO": "cepe803653",
                    "USUARIOFIVE9": "cepe803653@siigo.com",
                    "USUARIO RED": "cepe803653",
                    "CORREOELECTRONICO": "cepe803653@siigo.com",
                    "STATION ID": "3720421",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Francy Julieth Viracacha Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1031131504",
                    "FECHA INICIAL": "2022-08-30",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Yeimy Daniela",
                    "APELLIDOS": "Paez Arenas",
                    "NOMBRES COMPLETOS": "Yeimy Daniela Paez Arenas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Mercedes Moncada Peraza",
                    "CODIGO USUARIO": "paez803632",
                    "USUARIOFIVE9": "paez803632@siigo.com",
                    "USUARIO RED": "paez803632",
                    "CORREOELECTRONICO": "paez803632@siigo.com",
                    "STATION ID": "3720447",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Mercedes Moncada Peraza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010042991",
                    "FECHA INICIAL": "2022-08-30",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Camila Alejandra",
                    "APELLIDOS": "Leon Acosta",
                    "NOMBRES COMPLETOS": "Camila Alejandra Leon Acosta",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Villavicencio",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Lida Katerine Puentes Navarro",
                    "CODIGO USUARIO": "leon803645",
                    "USUARIOFIVE9": "leon803645@siigo.com",
                    "USUARIO RED": "leon803645",
                    "CORREOELECTRONICO": "leon803645@siigo.com",
                    "STATION ID": "3720478",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Lida Katerine Puentes Navarro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1095829990",
                    "FECHA INICIAL": "2022-08-30",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Juan Sebastian",
                    "APELLIDOS": "Calero Gil",
                    "NOMBRES COMPLETOS": "Juan Sebastian Calero Gil",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Francy Julieth Viracacha Gutierrez",
                    "CODIGO USUARIO": "cale803648",
                    "USUARIOFIVE9": "cale803648@siigo.com",
                    "USUARIO RED": "cale803648",
                    "CORREOELECTRONICO": "cale803648@siigo.com",
                    "STATION ID": "3763762",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Francy Julieth Viracacha Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019052655",
                    "FECHA INICIAL": "2022-08-30",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Erika Regina",
                    "APELLIDOS": "Vanegas Pedraza",
                    "NOMBRES COMPLETOS": "Erika Regina Vanegas Pedraza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Francy Julieth Viracacha Gutierrez",
                    "CODIGO USUARIO": "vane803646",
                    "USUARIOFIVE9": "vane803646@siigo.com",
                    "USUARIO RED": "vane803646",
                    "CORREOELECTRONICO": "vane803646@siigo.com",
                    "STATION ID": "3763764",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Francy Julieth Viracacha Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032453093",
                    "FECHA INICIAL": "2022-08-30",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Valentina",
                    "APELLIDOS": "Acosta Gonzalez",
                    "NOMBRES COMPLETOS": "Valentina Acosta Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gustavo Adolfo Espinosa Toledo",
                    "CODIGO USUARIO": "acos803628",
                    "USUARIOFIVE9": "acos803628@siigo.com",
                    "USUARIO RED": "acos803628",
                    "CORREOELECTRONICO": "acos803628@siigo.com",
                    "STATION ID": "3763831",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gustavo Adolfo Espinosa Toledo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1020740919",
                    "FECHA INICIAL": "2022-08-30",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Gladys Mariela",
                    "APELLIDOS": "Arango Suarez",
                    "NOMBRES COMPLETOS": "Gladys Mariela Arango Suarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Yeimy Milena Bustos Gutierrez",
                    "CODIGO USUARIO": "aran803643",
                    "USUARIOFIVE9": "aran803643@siigo.com",
                    "USUARIO RED": "aran803643",
                    "CORREOELECTRONICO": "aran803643@siigo.com",
                    "STATION ID": "3763834",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yeimy Milena Bustos Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1044425945",
                    "FECHA INICIAL": "2022-08-30",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Merelyn Johanna",
                    "APELLIDOS": "Ardila Perez",
                    "NOMBRES COMPLETOS": "Merelyn Johanna Ardila Perez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Eliana Maria Fajardo Prada",
                    "CODIGO USUARIO": "ardi803642",
                    "USUARIOFIVE9": "ardi803642@siigo.com",
                    "USUARIO RED": "ardi803642",
                    "CORREOELECTRONICO": "ardi803642@siigo.com",
                    "STATION ID": "3763840",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Eliana Maria Fajardo Prada",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1053858603",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Andrea",
                    "APELLIDOS": "Sanchez Hernandez",
                    "NOMBRES COMPLETOS": "Andrea Sanchez Hernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Johanna Patricia Torres Quintero",
                    "CODIGO USUARIO": "sanc803693",
                    "USUARIOFIVE9": "sanc803693@siigo.com",
                    "USUARIO RED": "sanc803693",
                    "CORREOELECTRONICO": "sanc803693@siigo.com",
                    "STATION ID": "3763843",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Johanna Patricia Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "43253797",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Victoria Eugenia",
                    "APELLIDOS": "Quintero Lopez",
                    "NOMBRES COMPLETOS": "Victoria Eugenia Quintero Lopez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Maria Naranjo Mattheus",
                    "CODIGO USUARIO": "quin803622",
                    "USUARIOFIVE9": "quin803622@siigo.com",
                    "USUARIO RED": "quin803622",
                    "CORREOELECTRONICO": "quin803622@siigo.com",
                    "STATION ID": "3763848",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Maria Naranjo Mattheus",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022983219",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Leidy Johana",
                    "APELLIDOS": "Quiroga Rios",
                    "NOMBRES COMPLETOS": "Leidy Johana Quiroga Rios",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Francy Julieth Viracacha Gutierrez",
                    "CODIGO USUARIO": "quir803691",
                    "USUARIOFIVE9": "quir803691@siigo.com",
                    "USUARIO RED": "quir803691",
                    "CORREOELECTRONICO": "quir803691@siigo.com",
                    "STATION ID": "3763850",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Francy Julieth Viracacha Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1151943791",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Jessica Maria",
                    "APELLIDOS": "Hidalgo Bonilla",
                    "NOMBRES COMPLETOS": "Jessica Maria Hidalgo Bonilla",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Francy Julieth Viracacha Gutierrez",
                    "CODIGO USUARIO": "hida803694",
                    "USUARIOFIVE9": "hida803694@siigo.com",
                    "USUARIO RED": "hida803694",
                    "CORREOELECTRONICO": "hida803694@siigo.com",
                    "STATION ID": "3763861",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Francy Julieth Viracacha Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023966466",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Yuliana Marcela",
                    "APELLIDOS": "Ocampo Escobar",
                    "NOMBRES COMPLETOS": "Yuliana Marcela Ocampo Escobar",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jefferson Arvey Ramirez Ibanez",
                    "CODIGO USUARIO": "ocam803692",
                    "USUARIOFIVE9": "ocam803692@siigo.com",
                    "USUARIO RED": "ocam803692",
                    "CORREOELECTRONICO": "ocam803692@siigo.com",
                    "STATION ID": "3763871",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jefferson Arvey Ramirez Ibanez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024567109",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Gisela",
                    "APELLIDOS": "Palacios Palacios",
                    "NOMBRES COMPLETOS": "Gisela Palacios Palacios",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Aleida Bibiana Garcia Suarez",
                    "CODIGO USUARIO": "pala803696",
                    "USUARIOFIVE9": "pala803696@siigo.com",
                    "USUARIO RED": "pala803696",
                    "CORREOELECTRONICO": "pala803696@siigo.com",
                    "STATION ID": "3763879",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Aleida Bibiana Garcia Suarez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013644541",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Paola Andrea",
                    "APELLIDOS": "Cortes Vega",
                    "NOMBRES COMPLETOS": "Paola Andrea Cortes Vega",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juliana Castro Diaz",
                    "CODIGO USUARIO": "cort803687",
                    "USUARIOFIVE9": "cort803687@siigo.com",
                    "USUARIO RED": "cort803687",
                    "CORREOELECTRONICO": "cort803687@siigo.com",
                    "STATION ID": "3763888",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juliana Castro Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010066265",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Karen Margarita",
                    "APELLIDOS": "Vergara Mora",
                    "NOMBRES COMPLETOS": "Karen Margarita Vergara Mora",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Neiva",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Daniel Eduardo Pinto Cepeda",
                    "CODIGO USUARIO": "verg803682",
                    "USUARIOFIVE9": "verg803682@siigo.com",
                    "USUARIO RED": "verg803682",
                    "CORREOELECTRONICO": "verg803682@siigo.com",
                    "STATION ID": "3763928",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Daniel Eduardo Pinto Cepeda",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030581505",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Karen Alejandra",
                    "APELLIDOS": "Penagos Zamudio",
                    "NOMBRES COMPLETOS": "Karen Alejandra Penagos Zamudio",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Mercedes Moncada Peraza",
                    "CODIGO USUARIO": "pena803664",
                    "USUARIOFIVE9": "pena803664@siigo.com",
                    "USUARIO RED": "pena803664",
                    "CORREOELECTRONICO": "pena803664@siigo.com",
                    "STATION ID": "3857109",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Mercedes Moncada Peraza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015405522",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Monica Alejandra",
                    "APELLIDOS": "Gordillo Patino",
                    "NOMBRES COMPLETOS": "Monica Alejandra Gordillo Patino",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leida Adriana Rico Herrera",
                    "CODIGO USUARIO": "gord803672",
                    "USUARIOFIVE9": "gord803672@siigo.com",
                    "USUARIO RED": "gord803672",
                    "CORREOELECTRONICO": "gord803672@siigo.com",
                    "STATION ID": "3857187",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leida Adriana Rico Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52879962",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Sila Milena",
                    "APELLIDOS": "Lara Sanchez",
                    "NOMBRES COMPLETOS": "Sila Milena Lara Sanchez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Buitrago Rios",
                    "CODIGO USUARIO": "lara803680",
                    "USUARIOFIVE9": "lara803680@siigo.com",
                    "USUARIO RED": "lara803680",
                    "CORREOELECTRONICO": "lara803680@siigo.com",
                    "STATION ID": "3857192",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Buitrago Rios",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "57467271",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Angingler Johana",
                    "APELLIDOS": "Eguis Garcia",
                    "NOMBRES COMPLETOS": "Angingler Johana Eguis Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Mercedes Moncada Peraza",
                    "CODIGO USUARIO": "egui803698",
                    "USUARIOFIVE9": "egui803698@siigo.com",
                    "USUARIO RED": "egui803698",
                    "CORREOELECTRONICO": "egui803698@siigo.com",
                    "STATION ID": "3857193",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Mercedes Moncada Peraza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1090391727",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Jesus David",
                    "APELLIDOS": "Perez Mendoza",
                    "NOMBRES COMPLETOS": "Jesus David Perez Mendoza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Cucuta",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Michelle Matamoros Del Rosario",
                    "CODIGO USUARIO": "pere803720",
                    "USUARIOFIVE9": "pere803720@siigo.com",
                    "USUARIO RED": "pere803720",
                    "CORREOELECTRONICO": "pere803720@siigo.com",
                    "STATION ID": "3857197",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Michelle Matamoros Del Rosario",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024584246",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Anny Yulieth",
                    "APELLIDOS": "Mosquera Ibarguen",
                    "NOMBRES COMPLETOS": "Anny Yulieth Mosquera Ibarguen",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Sanchez Velez",
                    "CODIGO USUARIO": "mosq803716",
                    "USUARIOFIVE9": "mosq803716@siigo.com",
                    "USUARIO RED": "mosq803716",
                    "CORREOELECTRONICO": "mosq803716@siigo.com",
                    "STATION ID": "3857198",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Sanchez Velez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024510645",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Yeraldine Lizeth",
                    "APELLIDOS": "Moreno Alba",
                    "NOMBRES COMPLETOS": "Yeraldine Lizeth Moreno Alba",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Walter Antonio Munoz Antepara",
                    "CODIGO USUARIO": "more803708",
                    "USUARIOFIVE9": "more803708@siigo.com",
                    "USUARIO RED": "more803708",
                    "CORREOELECTRONICO": "more803708@siigo.com",
                    "STATION ID": "3857199",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Walter Antonio Munoz Antepara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022418231",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Sandy Dayana",
                    "APELLIDOS": "Timote Rodriguez",
                    "NOMBRES COMPLETOS": "Sandy Dayana Timote Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Liliana Andrea Segura Martinez",
                    "CODIGO USUARIO": "timo803715",
                    "USUARIOFIVE9": "timo803715@siigo.com",
                    "USUARIO RED": "timo803715",
                    "CORREOELECTRONICO": "timo803715@siigo.com",
                    "STATION ID": "3857202",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Andrea Segura Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019106899",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Ofir",
                    "APELLIDOS": "Moreno Suarez",
                    "NOMBRES COMPLETOS": "Ofir Moreno Suarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Lida Katerine Puentes Navarro",
                    "CODIGO USUARIO": "more803639",
                    "USUARIOFIVE9": "more803639@siigo.com",
                    "USUARIO RED": "more803639",
                    "CORREOELECTRONICO": "more803639@siigo.com",
                    "STATION ID": "3857204",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Lida Katerine Puentes Navarro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "43114472",
                    "FECHA INICIAL": "2022-08-30",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Yenny Andrea",
                    "APELLIDOS": "Gutierrez Acevedo",
                    "NOMBRES COMPLETOS": "Yenny Andrea Gutierrez Acevedo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Francy Julieth Viracacha Gutierrez",
                    "CODIGO USUARIO": "guti803640",
                    "USUARIOFIVE9": "guti803640@siigo.com",
                    "USUARIO RED": "guti803640",
                    "CORREOELECTRONICO": "guti803640@siigo.com",
                    "STATION ID": "3857206",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Francy Julieth Viracacha Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1102360109",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Yudi Veronica",
                    "APELLIDOS": "Montenegro Jere",
                    "NOMBRES COMPLETOS": "Yudi Veronica Montenegro Jere",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leidy Marcela Rodriguez Rivera",
                    "CODIGO USUARIO": "mont803710",
                    "USUARIOFIVE9": "mont803710@siigo.com",
                    "USUARIO RED": "mont803710",
                    "CORREOELECTRONICO": "mont803710@siigo.com",
                    "STATION ID": "3857207",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leidy Marcela Rodriguez Rivera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030621328",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Paola Andrea",
                    "APELLIDOS": "Bohorquez Mejia",
                    "NOMBRES COMPLETOS": "Paola Andrea Bohorquez Mejia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jessica Paola Paez Quintero",
                    "CODIGO USUARIO": "boho803713",
                    "USUARIOFIVE9": "boho803713@siigo.com",
                    "USUARIO RED": "boho803713",
                    "CORREOELECTRONICO": "boho803713@siigo.com",
                    "STATION ID": "3857210",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jessica Paola Paez Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016011983",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Jhonathan Camilo",
                    "APELLIDOS": "Carrillo Romero",
                    "NOMBRES COMPLETOS": "Jhonathan Camilo Carrillo Romero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Cristhian David Nino Barrera",
                    "CODIGO USUARIO": "carr803718",
                    "USUARIOFIVE9": "carr803718@siigo.com",
                    "USUARIO RED": "carr803718",
                    "CORREOELECTRONICO": "carr803718@siigo.com",
                    "STATION ID": "3857212",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Cristhian David Nino Barrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010200832",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Dario",
                    "APELLIDOS": "Ordonez Cerquera",
                    "NOMBRES COMPLETOS": "Dario Ordonez Cerquera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Paula Alejandra Garcia Lujan",
                    "CODIGO USUARIO": "ordo803717",
                    "USUARIOFIVE9": "ordo803717@siigo.com",
                    "USUARIO RED": "ordo803717",
                    "CORREOELECTRONICO": "ordo803717@siigo.com",
                    "STATION ID": "3857213",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Paula Alejandra Garcia Lujan",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016028411",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Liliana",
                    "APELLIDOS": "Gualteros Alfonso",
                    "NOMBRES COMPLETOS": "Liliana Gualteros Alfonso",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Michelle Matamoros Del Rosario",
                    "CODIGO USUARIO": "gual803719",
                    "USUARIOFIVE9": "gual803719@siigo.com",
                    "USUARIO RED": "gual803719",
                    "CORREOELECTRONICO": "gual803719@siigo.com",
                    "STATION ID": "3857214",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Michelle Matamoros Del Rosario",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033700404",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Andrea Gissel",
                    "APELLIDOS": "De La Rans Ipuz",
                    "NOMBRES COMPLETOS": "Andrea Gissel De La Rans Ipuz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Johanna Patricia Torres Quintero",
                    "CODIGO USUARIO": "dela803670",
                    "USUARIOFIVE9": "dela803670@siigo.com",
                    "USUARIO RED": "dela803670",
                    "CORREOELECTRONICO": "dela803670@siigo.com",
                    "STATION ID": "3857217",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Johanna Patricia Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023955579",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Maria Paz",
                    "APELLIDOS": "Guzman Parrado",
                    "NOMBRES COMPLETOS": "Maria Paz Guzman Parrado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leidy Marcela Rodriguez Rivera",
                    "CODIGO USUARIO": "guzm803704",
                    "USUARIOFIVE9": "guzm803704@siigo.com",
                    "USUARIO RED": "guzm803704",
                    "CORREOELECTRONICO": "guzm803704@siigo.com",
                    "STATION ID": "3857218",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leidy Marcela Rodriguez Rivera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "HEMM000120MSA",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-27",
                    "NOMBRES": "Marcos Daniel",
                    "APELLIDOS": "Ernandez Martinez",
                    "NOMBRES COMPLETOS": "Marcos Daniel Hernandez Martinez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Oscar Rodrigo Navarro Hernandez",
                    "CODIGO USUARIO": "mhernandez",
                    "USUARIOFIVE9": "mhernandez@aspel.com.mx",
                    "USUARIO RED": "mhernandez",
                    "CORREOELECTRONICO": "mhernandez@aspel.com.mx",
                    "STATION ID": "3763849",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Oscar Rodrigo Navarro Hernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "57466886",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Angelith Del Carmen",
                    "APELLIDOS": "De Armas Lopez",
                    "NOMBRES COMPLETOS": "Angelith Del Carmen De Armas Lopez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Santa Marta",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Daniel Eduardo Pinto Cepeda",
                    "CODIGO USUARIO": "dear803701",
                    "USUARIOFIVE9": "dear803701@siigo.com",
                    "USUARIO RED": "dear803701",
                    "CORREOELECTRONICO": "dear803701@siigo.com",
                    "STATION ID": "3857222",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Daniel Eduardo Pinto Cepeda",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1045752655",
                    "FECHA INICIAL": "2022-08-30",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Dayana Andrea",
                    "APELLIDOS": "De La Rosa Arriguez",
                    "NOMBRES COMPLETOS": "Dayana Andrea De La Rosa Arriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Francy Julieth Viracacha Gutierrez",
                    "CODIGO USUARIO": "dela803656",
                    "USUARIOFIVE9": "dela803656@siigo.com",
                    "USUARIO RED": "dela803656",
                    "CORREOELECTRONICO": "dela803656@siigo.com",
                    "STATION ID": "3857223",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Francy Julieth Viracacha Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033687917",
                    "FECHA INICIAL": "2022-08-30",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Viviana",
                    "APELLIDOS": "Valencia Martinez",
                    "NOMBRES COMPLETOS": "Viviana Valencia Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Yeimy Milena Bustos Gutierrez",
                    "CODIGO USUARIO": "vale803655",
                    "USUARIOFIVE9": "vale803655@siigo.com",
                    "USUARIO RED": "vale803655",
                    "CORREOELECTRONICO": "vale803655@siigo.com",
                    "STATION ID": "3857224",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yeimy Milena Bustos Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012336975",
                    "FECHA INICIAL": "2022-08-31",
                    "FECHA OPERACION": "2022-09-05",
                    "NOMBRES": "Diego Alejandro",
                    "APELLIDOS": "Romero Villamizar",
                    "NOMBRES COMPLETOS": "Diego Alejandro Romero Villamizar",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Hollman Antonio Aponte Quiroga",
                    "CODIGO USUARIO": "rome803663",
                    "USUARIOFIVE9": "rome803663@siigo.com",
                    "USUARIO RED": "rome803663",
                    "CORREOELECTRONICO": "rome803663@siigo.com",
                    "STATION ID": "3857226",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Hollman Antonio Aponte Quiroga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032388669",
                    "FECHA INICIAL": "2021-06-15",
                    "FECHA OPERACION": "2021-07-09",
                    "NOMBRES": "Rafael Enrique",
                    "APELLIDOS": "Tascon Chavez",
                    "NOMBRES COMPLETOS": "Rafael Enrique Tascon Chavez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento MX",
                    "CARGO": "Experience Manager",
                    "JEFE INMEDIATO": "Paul Rogelio Fuentes Tabarez",
                    "CODIGO USUARIO": "tasc801516",
                    "USUARIOFIVE9": "tasc801516@siigo.com",
                    "USUARIO RED": "tasc801516",
                    "CORREOELECTRONICO": "tasc801516@siigo.com",
                    "STATION ID": "2607018",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Paul Rogelio Fuentes Tabarez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "GAGJ920131GI2",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jair Josue",
                    "APELLIDOS": "Gaytan Gonzalez",
                    "NOMBRES COMPLETOS": "Jair Josue Gaytan Gonzalez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Rafael Enrique Tascon Chavez",
                    "CODIGO USUARIO": "jgaytan",
                    "USUARIOFIVE9": "jgaytan@aspel.com.mx",
                    "USUARIO RED": "jgaytan",
                    "CORREOELECTRONICO": "jgaytan@aspel.com.mx",
                    "STATION ID": "3763937",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Rafael Enrique Tascon Chavez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "LOVF920519G23",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Francisco Javier",
                    "APELLIDOS": "Lorenzo Vargas",
                    "NOMBRES COMPLETOS": "Francisco Javier Lorenzo Vargas",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Rafael Enrique Tascon Chavez",
                    "CODIGO USUARIO": "florenzo",
                    "USUARIOFIVE9": "florenzo@aspel.com.mx",
                    "USUARIO RED": "florenzo",
                    "CORREOELECTRONICO": "florenzo@aspel.com.mx",
                    "STATION ID": "3857158",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Rafael Enrique Tascon Chavez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MACA781105NA4",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-18",
                    "NOMBRES": "Armando",
                    "APELLIDOS": "Mariano Cirilo",
                    "NOMBRES COMPLETOS": "Armando Mariano Cirilo",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Ana Maria Quijano",
                    "CODIGO USUARIO": "amariano",
                    "USUARIOFIVE9": "amariano@aspel.com.mx",
                    "USUARIO RED": "amariano",
                    "CORREOELECTRONICO": "amariano@aspel.com.mx",
                    "STATION ID": "4421832",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ana Maria Quijano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "950277129",
                    "FECHA INICIAL": "2022-08-30",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Joan Gabriel",
                    "APELLIDOS": "Lozano Guano",
                    "NOMBRES COMPLETOS": "Joan Gabriel Lozano Guano",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Operativo Customer",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "loza803647",
                    "USUARIOFIVE9": "loza803647@siigo.com",
                    "USUARIO RED": "loza803647",
                    "CORREOELECTRONICO": "loza803647@siigo.com",
                    "STATION ID": "2605968",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1047407537",
                    "FECHA INICIAL": "2022-09-07",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Mariluz",
                    "APELLIDOS": "Nunez Martinez",
                    "NOMBRES COMPLETOS": "Mariluz Nunez Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Hancel Rafael Salcedo Mendoza",
                    "CODIGO USUARIO": "nune803724",
                    "USUARIOFIVE9": "nune803724@siigo.com",
                    "USUARIO RED": "nune803724",
                    "CORREOELECTRONICO": "nune803724@siigo.com",
                    "STATION ID": "2606565",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Hancel Rafael Salcedo Mendoza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032360860",
                    "FECHA INICIAL": "2022-09-07",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Sonia Rosa",
                    "APELLIDOS": "Rueda Gomez",
                    "NOMBRES COMPLETOS": "Sonia Rosa Rueda Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Carolina Linares Gonzalez",
                    "CODIGO USUARIO": "rued803726",
                    "USUARIOFIVE9": "rued803726@siigo.com",
                    "USUARIO RED": "rued803726",
                    "CORREOELECTRONICO": "rued803726@siigo.com",
                    "STATION ID": "2606913",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Carolina Linares Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1144166594",
                    "FECHA INICIAL": "2022-09-07",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Luisa Fernanda",
                    "APELLIDOS": "Mora Hurtado",
                    "NOMBRES COMPLETOS": "Luisa Fernanda Mora Hurtado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Hancel Rafael Salcedo Mendoza",
                    "CODIGO USUARIO": "mora803729",
                    "USUARIOFIVE9": "mora803729@siigo.com",
                    "USUARIO RED": "mora803729",
                    "CORREOELECTRONICO": "mora803729@siigo.com",
                    "STATION ID": "2607080",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Hancel Rafael Salcedo Mendoza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1072430137",
                    "FECHA INICIAL": "2022-09-07",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Laura Juliana",
                    "APELLIDOS": "Cardenas Montero",
                    "NOMBRES COMPLETOS": "Laura Juliana Cardenas Montero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Tocancipa",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Colombia",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Olga Lucia Ospina Vidal",
                    "CODIGO USUARIO": "card803730",
                    "USUARIOFIVE9": "card803730@siigo.com",
                    "USUARIO RED": "card803730",
                    "CORREOELECTRONICO": "card803730@siigo.com",
                    "STATION ID": "2607113",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Olga Lucia Ospina Vidal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1140889018",
                    "FECHA INICIAL": "2022-09-07",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Lisleidy",
                    "APELLIDOS": "Angarita Prada",
                    "NOMBRES COMPLETOS": "Lisleidy Angarita Prada",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Colombia",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Olga Lucia Ospina Vidal",
                    "CODIGO USUARIO": "anga803731",
                    "USUARIOFIVE9": "anga803731@siigo.com",
                    "USUARIO RED": "anga803731",
                    "CORREOELECTRONICO": "anga803731@siigo.com",
                    "STATION ID": "2607125",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Olga Lucia Ospina Vidal",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001995941",
                    "FECHA INICIAL": "2022-09-07",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Schalia",
                    "APELLIDOS": "Munoz Lerma",
                    "NOMBRES COMPLETOS": "Schalia Munoz Lerma",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jefrey Julian Sanchez Celemin",
                    "CODIGO USUARIO": "muno803733",
                    "USUARIOFIVE9": "muno803733@siigo.com",
                    "USUARIO RED": "muno803733",
                    "CORREOELECTRONICO": "muno803733@siigo.com",
                    "STATION ID": "3127501",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jefrey Julian Sanchez Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143439034",
                    "FECHA INICIAL": "2022-09-07",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Cesar Wilfrido",
                    "APELLIDOS": "Vargas Romero",
                    "NOMBRES COMPLETOS": "Cesar Wilfrido Vargas Romero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adrian Alberto Salom Berrio",
                    "CODIGO USUARIO": "varg803734",
                    "USUARIOFIVE9": "varg803734@siigo.com",
                    "USUARIO RED": "varg803734",
                    "CORREOELECTRONICO": "varg803734@siigo.com",
                    "STATION ID": "3127531",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adrian Alberto Salom Berrio",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1074486488",
                    "FECHA INICIAL": "2022-09-07",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Francy Ney",
                    "APELLIDOS": "Ramirez Bustamante",
                    "NOMBRES COMPLETOS": "Francy Ney Ramirez Bustamante",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Tatiana Reyes Perez",
                    "CODIGO USUARIO": "rami803737",
                    "USUARIOFIVE9": "rami803737@siigo.com",
                    "USUARIO RED": "rami803737",
                    "CORREOELECTRONICO": "rami803737@siigo.com",
                    "STATION ID": "3127560",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Tatiana Reyes Perez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1050956592",
                    "FECHA INICIAL": "2022-09-07",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Liliana Margarita",
                    "APELLIDOS": "Rocha Perez",
                    "NOMBRES COMPLETOS": "Liliana Margarita Rocha Perez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Fernando Sotto Cardozo",
                    "CODIGO USUARIO": "roch803738",
                    "USUARIOFIVE9": "roch803738@siigo.com",
                    "USUARIO RED": "roch803738",
                    "CORREOELECTRONICO": "roch803738@siigo.com",
                    "STATION ID": "3127594",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Fernando Sotto Cardozo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1045741957",
                    "FECHA INICIAL": "2022-09-07",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "David Santiago",
                    "APELLIDOS": "Perea Torres",
                    "NOMBRES COMPLETOS": "David Santiago Perea Torres",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Nicoll Torres Celemin",
                    "CODIGO USUARIO": "pere803739",
                    "USUARIOFIVE9": "pere803739@siigo.com",
                    "USUARIO RED": "pere803739",
                    "CORREOELECTRONICO": "pere803739@siigo.com",
                    "STATION ID": "3267905",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Nicoll Torres Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1048266579",
                    "FECHA INICIAL": "2022-09-07",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Yulys Del Carmen",
                    "APELLIDOS": "Calderon Perez",
                    "NOMBRES COMPLETOS": "Yulys Del Carmen Calderon Perez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Malambo",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Tatiana Reyes Perez",
                    "CODIGO USUARIO": "cald803740",
                    "USUARIOFIVE9": "cald803740@siigo.com",
                    "USUARIO RED": "cald803740",
                    "CORREOELECTRONICO": "cald803740@siigo.com",
                    "STATION ID": "3471506",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Tatiana Reyes Perez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1042457263",
                    "FECHA INICIAL": "2022-09-08",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Mayra Alejandra",
                    "APELLIDOS": "Martinez Del Guercio",
                    "NOMBRES COMPLETOS": "Mayra Alejandra Martinez Del Guercio",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soledad",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Hancel Rafael Salcedo Mendoza",
                    "CODIGO USUARIO": "mart803744",
                    "USUARIOFIVE9": "mart803744@siigo.com",
                    "USUARIO RED": "mart803744",
                    "CORREOELECTRONICO": "mart803744@siigo.com",
                    "STATION ID": "3483695",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Hancel Rafael Salcedo Mendoza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1129581345",
                    "FECHA INICIAL": "2022-09-08",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Cindy Margarita",
                    "APELLIDOS": "Ahumada Bustamant",
                    "NOMBRES COMPLETOS": "Cindy Margarita Ahumada Bustamant",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Nicoll Torres Celemin",
                    "CODIGO USUARIO": "ahum803747",
                    "USUARIOFIVE9": "ahum803747@siigo.com",
                    "USUARIO RED": "ahum803747",
                    "CORREOELECTRONICO": "ahum803747@siigo.com",
                    "STATION ID": "3690232",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Nicoll Torres Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001829762",
                    "FECHA INICIAL": "2022-09-08",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Ismael De Jesus",
                    "APELLIDOS": "Marquez Valeazquez",
                    "NOMBRES COMPLETOS": "Ismael De Jesus Marquez Valeazquez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Sabanagrande",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Carolina Linares Gonzalez",
                    "CODIGO USUARIO": "marq803748",
                    "USUARIOFIVE9": "marq803748@siigo.com",
                    "USUARIO RED": "marq803748",
                    "CORREOELECTRONICO": "marq803748@siigo.com",
                    "STATION ID": "3690261",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Carolina Linares Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1050040490",
                    "FECHA INICIAL": "2022-09-08",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Carlos Andres",
                    "APELLIDOS": "Lora Deavila",
                    "NOMBRES COMPLETOS": "Carlos Andres Lora Deavila",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soledad",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adrian Alberto Salom Berrio",
                    "CODIGO USUARIO": "lora803749",
                    "USUARIOFIVE9": "lora803749@siigo.com",
                    "USUARIO RED": "lora803749",
                    "CORREOELECTRONICO": "lora803749@siigo.com",
                    "STATION ID": "3690301",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adrian Alberto Salom Berrio",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1193276556",
                    "FECHA INICIAL": "2022-09-08",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Karen Lizeth",
                    "APELLIDOS": "Estrada Pacheco",
                    "NOMBRES COMPLETOS": "Karen Lizeth Estrada Pacheco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Malambo",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Diana Carolina Linares Gonzalez",
                    "CODIGO USUARIO": "estr803754",
                    "USUARIOFIVE9": "estr803754@siigo.com",
                    "USUARIO RED": "estr803754",
                    "CORREOELECTRONICO": "estr803754@siigo.com",
                    "STATION ID": "3720370",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Diana Carolina Linares Gonzalez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143437949",
                    "FECHA INICIAL": "2022-09-08",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Susana Del Carmen",
                    "APELLIDOS": "Guerra Lujan",
                    "NOMBRES COMPLETOS": "Susana Del Carmen Guerra Lujan",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luz Katerine Maya Mogollon",
                    "CODIGO USUARIO": "guer803755",
                    "USUARIOFIVE9": "guer803755@siigo.com",
                    "USUARIO RED": "guer803755",
                    "CORREOELECTRONICO": "guer803755@siigo.com",
                    "STATION ID": "3720389",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Katerine Maya Mogollon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1117539044",
                    "FECHA INICIAL": "2022-09-08",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Karen Tatiana",
                    "APELLIDOS": "Lopez Ruiz",
                    "NOMBRES COMPLETOS": "Karen Tatiana Lopez Ruiz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Florencia",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "lope803760",
                    "USUARIOFIVE9": "lope803760@siigo.com",
                    "USUARIO RED": "lope803760",
                    "CORREOELECTRONICO": "lope803760@siigo.com",
                    "STATION ID": "3720409",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1072495881",
                    "FECHA INICIAL": "2022-09-08",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Jeyson Alejandro",
                    "APELLIDOS": "Martinez Mendoza",
                    "NOMBRES COMPLETOS": "Jeyson Alejandro Martinez Mendoza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Silvania",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Sory Yaqueline Abello Polo",
                    "CODIGO USUARIO": "mart803761",
                    "USUARIOFIVE9": "mart803761@siigo.com",
                    "USUARIO RED": "mart803761",
                    "CORREOELECTRONICO": "mart803761@siigo.com",
                    "STATION ID": "3720416",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sory Yaqueline Abello Polo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1003652580",
                    "FECHA INICIAL": "2022-09-08",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Alexandra",
                    "APELLIDOS": "Urrego Montejo",
                    "NOMBRES COMPLETOS": "Alexandra Urrego Montejo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Silvania",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jenny Paola Mendez",
                    "CODIGO USUARIO": "urre803763",
                    "USUARIOFIVE9": "urre803763@siigo.com",
                    "USUARIO RED": "urre803763",
                    "CORREOELECTRONICO": "urre803763@siigo.com",
                    "STATION ID": "3763859",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jenny Paola Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "962094371",
                    "FECHA INICIAL": "2022-09-08",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Marie Cristhiam",
                    "APELLIDOS": "Murillo Lino",
                    "NOMBRES COMPLETOS": "Marie Cristhiam Murillo Lino",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "muri803764",
                    "USUARIOFIVE9": "muri803764@siigo.com",
                    "USUARIO RED": "muri803764",
                    "CORREOELECTRONICO": "muri803764@siigo.com",
                    "STATION ID": "3763867",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "930882055",
                    "FECHA INICIAL": "2022-09-08",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Lissette Adaleyn",
                    "APELLIDOS": "Tomala Cabezas",
                    "NOMBRES COMPLETOS": "Lissette Adaleyn Tomala Cabezas",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "toma803765",
                    "USUARIOFIVE9": "toma803765@siigo.com",
                    "USUARIO RED": "toma803765",
                    "CORREOELECTRONICO": "toma803765@siigo.com",
                    "STATION ID": "3763868",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143402051",
                    "FECHA INICIAL": "2022-09-08",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Luis Manuel",
                    "APELLIDOS": "Pacheco Buelvas",
                    "NOMBRES COMPLETOS": "Luis Manuel Pacheco Buelvas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "pach803767",
                    "USUARIOFIVE9": "pach803767@siigo.com",
                    "USUARIO RED": "pach803767",
                    "CORREOELECTRONICO": "pach803767@siigo.com",
                    "STATION ID": "3763870",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1052358900",
                    "FECHA INICIAL": "2022-09-08",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Maria Carolina",
                    "APELLIDOS": "Segura Sanabria",
                    "NOMBRES COMPLETOS": "Maria Carolina Segura Sanabria",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Tunja",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "segu803768",
                    "USUARIOFIVE9": "segu803768@siigo.com",
                    "USUARIO RED": "segu803768",
                    "CORREOELECTRONICO": "segu803768@siigo.com",
                    "STATION ID": "3763960",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019078650",
                    "FECHA INICIAL": "2022-09-08",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Javier Alonzo",
                    "APELLIDOS": "Hernandez Ramirez",
                    "NOMBRES COMPLETOS": "Javier Alonzo Hernandez Ramirez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Eduardo Sanchez Castano",
                    "CODIGO USUARIO": "hern803770",
                    "USUARIOFIVE9": "hern803770@siigo.com",
                    "USUARIO RED": "hern803770",
                    "CORREOELECTRONICO": "hern803770@siigo.com",
                    "STATION ID": "2605983",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Eduardo Sanchez Castano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1122679592",
                    "FECHA INICIAL": "2022-08-30",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Simon Alberto",
                    "APELLIDOS": "Bolivar Gordillo",
                    "NOMBRES COMPLETOS": "Simon Alberto Bolivar Gordillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Estrategia",
                    "SUBAREA": "Business Intelligence",
                    "CARGO": "Bussiness Inteligence",
                    "JEFE INMEDIATO": "Jairo Andres Mendieta Reyes",
                    "CODIGO USUARIO": "boli803644",
                    "USUARIOFIVE9": "boli803644@siigo.com",
                    "USUARIO RED": "boli803644",
                    "CORREOELECTRONICO": "boli803644@siigo.com",
                    "STATION ID": "2605976",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Estrategia_Jairo Andres Mendieta Reyes",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "LOGC911230QY1",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-18",
                    "NOMBRES": "Cuauhtemoc",
                    "APELLIDOS": "Lopez Gaspar",
                    "NOMBRES COMPLETOS": "Cuauhtemoc Lopez Gaspar",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Rafael Enrique Tascon Chavez",
                    "CODIGO USUARIO": "cgaspar",
                    "USUARIOFIVE9": "cgaspar@aspel.com.mx",
                    "USUARIO RED": "cgaspar",
                    "CORREOELECTRONICO": "cgaspar@aspel.com.mx",
                    "STATION ID": "4421829",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Rafael Enrique Tascon Chavez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "OADI940221FP8",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-06-01",
                    "NOMBRES": "Isaac",
                    "APELLIDOS": "Ocampo Delgado",
                    "NOMBRES COMPLETOS": "Isaac Ocampo Delgado",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Rodriguez Castro",
                    "CODIGO USUARIO": "iocampo",
                    "USUARIOFIVE9": "iocampo@aspel.com.mx",
                    "USUARIO RED": "iocampo",
                    "CORREOELECTRONICO": "iocampo@aspel.com.mx",
                    "STATION ID": "3763926",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Rodriguez Castro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "GAPH940724RW7",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Heriberto",
                    "APELLIDOS": "Granados Perez",
                    "NOMBRES COMPLETOS": "Heriberto Granados Perez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Luz Angelica Torres Quintero",
                    "CODIGO USUARIO": "hgranados",
                    "USUARIOFIVE9": "hgranados@aspel.com.mx",
                    "USUARIO RED": "hgranados",
                    "CORREOELECTRONICO": "hgranados@aspel.com.mx",
                    "STATION ID": "3857151",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Angelica Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1059909710",
                    "FECHA INICIAL": "2022-09-14",
                    "FECHA OPERACION": "2022-09-22",
                    "NOMBRES": "Leidy Jhohana",
                    "APELLIDOS": "Melendez Torres",
                    "NOMBRES COMPLETOS": "Leidy Jhohana Melendez Torres",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Sory Yaqueline Abello Polo",
                    "CODIGO USUARIO": "mele803781",
                    "USUARIOFIVE9": "mele803781@siigo.com",
                    "USUARIO RED": "mele803781",
                    "CORREOELECTRONICO": "mele803781@siigo.com",
                    "STATION ID": "4421727",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sory Yaqueline Abello Polo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143457291",
                    "FECHA INICIAL": "2022-09-14",
                    "FECHA OPERACION": "2022-09-22",
                    "NOMBRES": "Jesus Alfonso",
                    "APELLIDOS": "Correa Camargo",
                    "NOMBRES COMPLETOS": "Jesus Alfonso Correa Camargo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luisa Fernanda Rodriguez Larrota",
                    "CODIGO USUARIO": "corr803778",
                    "USUARIOFIVE9": "corr803778@siigo.com",
                    "USUARIO RED": "corr803778",
                    "CORREOELECTRONICO": "corr803778@siigo.com",
                    "STATION ID": "4421730",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luisa Fernanda Rodriguez Larrota",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1049645353",
                    "FECHA INICIAL": "2022-09-14",
                    "FECHA OPERACION": "2022-09-22",
                    "NOMBRES": "Mery Jicela",
                    "APELLIDOS": "Velandia Torres",
                    "NOMBRES COMPLETOS": "Mery Jicela Velandia Torres",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Tunja",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Enrique Varela Lasso",
                    "CODIGO USUARIO": "vela803780",
                    "USUARIOFIVE9": "vela803780@siigo.com",
                    "USUARIO RED": "vela803780",
                    "CORREOELECTRONICO": "vela803780@siigo.com",
                    "STATION ID": "4421731",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Enrique Varela Lasso",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1042066914",
                    "FECHA INICIAL": "2022-09-14",
                    "FECHA OPERACION": "2022-09-22",
                    "NOMBRES": "Ana Virgelina",
                    "APELLIDOS": "Montoya Castaneda",
                    "NOMBRES COMPLETOS": "Ana Virgelina Montoya Castaneda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jenny Paola Mendez",
                    "CODIGO USUARIO": "mont803779",
                    "USUARIOFIVE9": "mont803779@siigo.com",
                    "USUARIO RED": "mont803779",
                    "CORREOELECTRONICO": "mont803779@siigo.com",
                    "STATION ID": "4421732",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jenny Paola Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "928830736",
                    "FECHA INICIAL": "2022-09-14",
                    "FECHA OPERACION": "2022-09-22",
                    "NOMBRES": "David Antonio",
                    "APELLIDOS": "Bajana Troya",
                    "NOMBRES COMPLETOS": "David Antonio Bajana Troya",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luis Angel Barragan Alvarado",
                    "CODIGO USUARIO": "baja803800",
                    "USUARIOFIVE9": "baja803800@siigo.com",
                    "USUARIO RED": "baja803800",
                    "CORREOELECTRONICO": "baja803800@siigo.com",
                    "STATION ID": "4421733",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luis Angel Barragan Alvarado",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "924938863",
                    "FECHA INICIAL": "2022-09-14",
                    "FECHA OPERACION": "2022-09-22",
                    "NOMBRES": "Marielena Beatriz",
                    "APELLIDOS": "Tagle Arce",
                    "NOMBRES COMPLETOS": "Marielena Beatriz Tagle Arce",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Firma Electronica",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "tagl803801",
                    "USUARIOFIVE9": "tagl803801@siigo.com",
                    "USUARIO RED": "tagl803801",
                    "CORREOELECTRONICO": "tagl803801@siigo.com",
                    "STATION ID": "4421734",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "952756708",
                    "FECHA INICIAL": "2022-09-14",
                    "FECHA OPERACION": "2022-09-22",
                    "NOMBRES": "George Javier",
                    "APELLIDOS": "Lopez Sosa",
                    "NOMBRES COMPLETOS": "George Javier Lopez Sosa",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Firma Electronica",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "lope803802",
                    "USUARIOFIVE9": "lope803802@siigo.com",
                    "USUARIO RED": "lope803802",
                    "CORREOELECTRONICO": "lope803802@siigo.com",
                    "STATION ID": "4421736",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1100974728",
                    "FECHA INICIAL": "2022-09-14",
                    "FECHA OPERACION": "2022-09-22",
                    "NOMBRES": "Sebastian Camilo",
                    "APELLIDOS": "Pinzon Davila",
                    "NOMBRES COMPLETOS": "Sebastian Camilo Pinzon Davila",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "San Gil",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jenny Paola Mendez",
                    "CODIGO USUARIO": "pinz803797",
                    "USUARIOFIVE9": "pinz803797@siigo.com",
                    "USUARIO RED": "pinz803797",
                    "CORREOELECTRONICO": "pinz803797@siigo.com",
                    "STATION ID": "4421764",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jenny Paola Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1075303515",
                    "FECHA INICIAL": "2022-09-14",
                    "FECHA OPERACION": "2022-09-22",
                    "NOMBRES": "Jennifer Tatiana",
                    "APELLIDOS": "Perdomo Ordonez",
                    "NOMBRES COMPLETOS": "Jennifer Tatiana Perdomo Ordonez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Neiva",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Sory Yaqueline Abello Polo",
                    "CODIGO USUARIO": "perd803798",
                    "USUARIOFIVE9": "perd803798@siigo.com",
                    "USUARIO RED": "perd803798",
                    "CORREOELECTRONICO": "perd803798@siigo.com",
                    "STATION ID": "4421765",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sory Yaqueline Abello Polo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1118845423",
                    "FECHA INICIAL": "2022-09-14",
                    "FECHA OPERACION": "2022-09-22",
                    "NOMBRES": "Miriana",
                    "APELLIDOS": "Perez Castro",
                    "NOMBRES COMPLETOS": "Miriana Perez Castro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Andrea Catalina Vega Gomez",
                    "CODIGO USUARIO": "pere803799",
                    "USUARIOFIVE9": "pere803799@siigo.com",
                    "USUARIO RED": "pere803799",
                    "CORREOELECTRONICO": "pere803799@siigo.com",
                    "STATION ID": "4421766",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andrea Catalina Vega Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1085287185",
                    "FECHA INICIAL": "2022-09-14",
                    "FECHA OPERACION": "2022-09-22",
                    "NOMBRES": "Angela Marcela",
                    "APELLIDOS": "Ortega Realpe",
                    "NOMBRES COMPLETOS": "Angela Marcela Ortega Realpe",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Tunja",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Sory Yaqueline Abello Polo",
                    "CODIGO USUARIO": "orte803792",
                    "USUARIOFIVE9": "orte803792@siigo.com",
                    "USUARIO RED": "orte803792",
                    "CORREOELECTRONICO": "orte803792@siigo.com",
                    "STATION ID": "4421767",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sory Yaqueline Abello Polo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1049652353",
                    "FECHA INICIAL": "2022-09-14",
                    "FECHA OPERACION": "2022-09-22",
                    "NOMBRES": "Karol Vanessa",
                    "APELLIDOS": "Rodriguez Castillo",
                    "NOMBRES COMPLETOS": "Karol Vanessa Rodriguez Castillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Tunja",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Elizabeth Giron Parra",
                    "CODIGO USUARIO": "rodr803793",
                    "USUARIOFIVE9": "rodr803793@siigo.com",
                    "USUARIO RED": "rodr803793",
                    "CORREOELECTRONICO": "rodr803793@siigo.com",
                    "STATION ID": "4421768",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Elizabeth Giron Parra",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057598657",
                    "FECHA INICIAL": "2022-09-14",
                    "FECHA OPERACION": "2022-09-22",
                    "NOMBRES": "Juan David",
                    "APELLIDOS": "Rodriguez Trujillo",
                    "NOMBRES COMPLETOS": "Juan David Rodriguez Trujillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Sogamoso",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Enrique Varela Lasso",
                    "CODIGO USUARIO": "rodr803772",
                    "USUARIOFIVE9": "rodr803772@siigo.com",
                    "USUARIO RED": "rodr803772",
                    "CORREOELECTRONICO": "rodr803772@siigo.com",
                    "STATION ID": "4421770",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Enrique Varela Lasso",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1144106433",
                    "FECHA INICIAL": "2022-09-14",
                    "FECHA OPERACION": "2022-09-22",
                    "NOMBRES": "Jhon Alexander",
                    "APELLIDOS": "Gomez Erazo",
                    "NOMBRES COMPLETOS": "Jhon Alexander Gomez Erazo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Pasto",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jenny Paola Mendez",
                    "CODIGO USUARIO": "gome803795",
                    "USUARIOFIVE9": "gome803795@siigo.com",
                    "USUARIO RED": "gome803795",
                    "CORREOELECTRONICO": "gome803795@siigo.com",
                    "STATION ID": "4421771",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jenny Paola Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1075273200",
                    "FECHA INICIAL": "2022-09-14",
                    "FECHA OPERACION": "2022-09-22",
                    "NOMBRES": "Diego Alejandro",
                    "APELLIDOS": "Benavides Gonzalez",
                    "NOMBRES COMPLETOS": "Diego Alejandro Benavides Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Neiva",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alison Anaid Chica Ezeta",
                    "CODIGO USUARIO": "bena803790",
                    "USUARIOFIVE9": "bena803790@siigo.com",
                    "USUARIO RED": "bena803790",
                    "CORREOELECTRONICO": "bena803790@siigo.com",
                    "STATION ID": "4421794",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Alison Anaid Chica Ezeta",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1049618411",
                    "FECHA INICIAL": "2022-09-14",
                    "FECHA OPERACION": "2022-09-22",
                    "NOMBRES": "Wendy Yolany",
                    "APELLIDOS": "Avila Avila",
                    "NOMBRES COMPLETOS": "Wendy Yolany Avila Avila",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Togui",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "avil803791",
                    "USUARIOFIVE9": "avil803791@siigo.com",
                    "USUARIO RED": "avil803791",
                    "CORREOELECTRONICO": "avil803791@siigo.com",
                    "STATION ID": "4421796",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001994591",
                    "FECHA INICIAL": "2022-09-14",
                    "FECHA OPERACION": "2022-09-22",
                    "NOMBRES": "Joselyn Maileth",
                    "APELLIDOS": "Nevado Lopez",
                    "NOMBRES COMPLETOS": "Joselyn Maileth Nevado Lopez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Pyme",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Ivan Enrique Varela Lasso",
                    "CODIGO USUARIO": "neva803774",
                    "USUARIOFIVE9": "neva803774@siigo.com",
                    "USUARIO RED": "neva803774",
                    "CORREOELECTRONICO": "neva803774@siigo.com",
                    "STATION ID": "4421800",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ivan Enrique Varela Lasso",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057598995",
                    "FECHA INICIAL": "2022-09-14",
                    "FECHA OPERACION": "2022-09-22",
                    "NOMBRES": "Leidy Johana",
                    "APELLIDOS": "Pacheco Salcedo",
                    "NOMBRES COMPLETOS": "Leidy Johana Pacheco Salcedo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Sogamoso",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jefrey Julian Sanchez Celemin",
                    "CODIGO USUARIO": "pach803776",
                    "USUARIOFIVE9": "pach803776@siigo.com",
                    "USUARIO RED": "pach803776",
                    "CORREOELECTRONICO": "pach803776@siigo.com",
                    "STATION ID": "4421810",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jefrey Julian Sanchez Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "AEMR8901238N5",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Raul",
                    "APELLIDOS": "Avendano Macias",
                    "NOMBRES COMPLETOS": "Raul Avendano Macias",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Experto MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Rene Gerardo Paredes Alcantara",
                    "CODIGO USUARIO": "rmacias",
                    "USUARIOFIVE9": "rmacias@aspel.com.mx",
                    "USUARIO RED": "rmacias",
                    "CORREOELECTRONICO": "rmacias@aspel.com.mx",
                    "STATION ID": "3763875",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Rene Gerardo Paredes Alcantara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "VAVA930504DI7",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Adrian",
                    "APELLIDOS": "Vargas Vega",
                    "NOMBRES COMPLETOS": "Adrian Vargas Vega",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Capacitadores",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Luz Angelica Torres Quintero",
                    "CODIGO USUARIO": "avega",
                    "USUARIOFIVE9": "avega@aspel.com.mx",
                    "USUARIO RED": "avega",
                    "CORREOELECTRONICO": "avega@aspel.com.mx",
                    "STATION ID": "3857152",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Angelica Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MESA821019MB3",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Adriana",
                    "APELLIDOS": "Mendoza Santillan",
                    "NOMBRES COMPLETOS": "Adriana Mendoza Santillan",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Rodriguez Castro",
                    "CODIGO USUARIO": "asantillan",
                    "USUARIOFIVE9": "asantillan@aspel.com.mx",
                    "USUARIO RED": "asantillan",
                    "CORREOELECTRONICO": "asantillan@aspel.com.mx",
                    "STATION ID": "3857154",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Rodriguez Castro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "VECE900219NJ3",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Eduardo",
                    "APELLIDOS": "Velazquez Cruz",
                    "NOMBRES COMPLETOS": "Eduardo Velazquez Cruz",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Experto MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Rene Gerardo Paredes Alcantara",
                    "CODIGO USUARIO": "evelazquez",
                    "USUARIOFIVE9": "evelazquez@aspel.com.mx",
                    "USUARIO RED": "evelazquez",
                    "CORREOELECTRONICO": "evelazquez@aspel.com.mx",
                    "STATION ID": "4421846",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Rene Gerardo Paredes Alcantara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MOCC9505159D0",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Carlos",
                    "APELLIDOS": "Monterrey Camacho",
                    "NOMBRES COMPLETOS": "Carlos Monterrey Camacho",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Rene Gerardo Paredes Alcantara",
                    "CODIGO USUARIO": "cmonterrey",
                    "USUARIOFIVE9": "cmonterrey@aspel.com.mx",
                    "USUARIO RED": "cmonterrey",
                    "CORREOELECTRONICO": "cmonterrey@aspel.com.mx",
                    "STATION ID": "4421852",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Rene Gerardo Paredes Alcantara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "LOLL860422CT4",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Maria De La Luz",
                    "APELLIDOS": "Lopez Lopez",
                    "NOMBRES COMPLETOS": "Maria De La Luz Lopez Lopez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Rodriguez Castro",
                    "CODIGO USUARIO": "mlopez",
                    "USUARIOFIVE9": "mlopezl@aspel.com.mx",
                    "USUARIO RED": "mlopez",
                    "CORREOELECTRONICO": "mlopezl@aspel.com.mx",
                    "STATION ID": "2606001",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Rodriguez Castro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "JAFF870704TU3",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Francisco",
                    "APELLIDOS": "Javier Flores",
                    "NOMBRES COMPLETOS": "Francisco Javier Flores",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Experto MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Rene Gerardo Paredes Alcantara",
                    "CODIGO USUARIO": "fflores",
                    "USUARIOFIVE9": "fflores@aspel.com.mx",
                    "USUARIO RED": "fflores",
                    "CORREOELECTRONICO": "fflores@aspel.com.mx",
                    "STATION ID": "2606874",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Rene Gerardo Paredes Alcantara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "DILJ841120EB4",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Julian",
                    "APELLIDOS": "Dib Lopez",
                    "NOMBRES COMPLETOS": "Julian Dib Lopez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Experto MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Rene Gerardo Paredes Alcantara",
                    "CODIGO USUARIO": "jdib",
                    "USUARIOFIVE9": "jdib@aspel.com.mx",
                    "USUARIO RED": "jdib",
                    "CORREOELECTRONICO": "jdib@aspel.com.mx",
                    "STATION ID": "3471489",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Rene Gerardo Paredes Alcantara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "GAAI861019LGA",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Isaac Obed",
                    "APELLIDOS": "Garcia Aranda",
                    "NOMBRES COMPLETOS": "Isaac Obed Garcia Aranda",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Rodriguez Castro",
                    "CODIGO USUARIO": "iaranda",
                    "USUARIOFIVE9": "iaranda@aspel.com.mx",
                    "USUARIO RED": "iaranda",
                    "CORREOELECTRONICO": "iaranda@aspel.com.mx",
                    "STATION ID": "3483768",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Rodriguez Castro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "DAMV8608081V4",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Victor Manuel",
                    "APELLIDOS": "Damian Meneses",
                    "NOMBRES COMPLETOS": "Victor Manuel Damian Meneses",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Rodriguez Castro",
                    "CODIGO USUARIO": "vdamian",
                    "USUARIOFIVE9": "vdamian@aspel.com.mx",
                    "USUARIO RED": "vdamian",
                    "CORREOELECTRONICO": "vdamian@aspel.com.mx",
                    "STATION ID": "3483777",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Rodriguez Castro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "BARP770517883",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Paul",
                    "APELLIDOS": "Baleon Rico",
                    "NOMBRES COMPLETOS": "Paul Baleon Rico",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Rodriguez Castro",
                    "CODIGO USUARIO": "pbaleon",
                    "USUARIOFIVE9": "pbaleon@aspel.com.mx",
                    "USUARIO RED": "pbaleon",
                    "CORREOELECTRONICO": "pbaleon@aspel.com.mx",
                    "STATION ID": "4421826",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Rodriguez Castro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MOYO9101038XA",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-18",
                    "NOMBRES": "Ollinyoliliztli",
                    "APELLIDOS": "Morales Yanez",
                    "NOMBRES COMPLETOS": "Ollinyoliliztli Morales Yanez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Rodriguez Castro",
                    "CODIGO USUARIO": "ymorales",
                    "USUARIOFIVE9": "ymorales@aspel.com.mx",
                    "USUARIO RED": "ymorales",
                    "CORREOELECTRONICO": "ymorales@aspel.com.mx",
                    "STATION ID": "4421831",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Rodriguez Castro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "NIOI940702F18",
                    "FECHA INICIAL": "2022-05-09",
                    "FECHA OPERACION": "2022-08-05",
                    "NOMBRES": "Ivan",
                    "APELLIDOS": "Nicolas Osorio",
                    "NOMBRES COMPLETOS": "Ivan Nicolas Osorio",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Especialista MX",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Rodriguez Castro",
                    "CODIGO USUARIO": "inicolas",
                    "USUARIOFIVE9": "inicolas@aspel.com.mx",
                    "USUARIO RED": "inicolas",
                    "CORREOELECTRONICO": "inicolas@aspel.com.mx",
                    "STATION ID": "4421820",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Rodriguez Castro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "HUMJ8901101A7",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jorge Alfredo",
                    "APELLIDOS": "Huesca Martinez",
                    "NOMBRES COMPLETOS": "Jorge Alfredo Huesca Martinez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Mexico",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Vicente Estrada Sanchez",
                    "CODIGO USUARIO": "jhuesca",
                    "USUARIOFIVE9": "jhuesca@aspel.com.mx",
                    "USUARIO RED": "jhuesca",
                    "CORREOELECTRONICO": "jhuesca@aspel.com.mx",
                    "STATION ID": "2606109",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Vicente Estrada Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "PEDL850223D79",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jose Luis",
                    "APELLIDOS": "Perales Duenas",
                    "NOMBRES COMPLETOS": "Jose Luis Perales Duenas",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Ecuador",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "jperales",
                    "USUARIOFIVE9": "jperales@aspel.com.mx",
                    "USUARIO RED": "jperales",
                    "CORREOELECTRONICO": "jperales@aspel.com.mx",
                    "STATION ID": "4023876",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "880302",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-10",
                    "NOMBRES": "Rodrigo",
                    "APELLIDOS": "Garzon Dominguez",
                    "NOMBRES COMPLETOS": "Rodrigo Garzon Dominguez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Product Owner",
                    "JEFE INMEDIATO": "Reyna Lopez Perez",
                    "CODIGO USUARIO": "rdominguez",
                    "USUARIOFIVE9": "rdominguez@aspel.com.mx",
                    "USUARIO RED": "rdominguez",
                    "CORREOELECTRONICO": "rdominguez@aspel.com.mx",
                    "STATION ID": "4421837",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Reyna Lopez Perez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "ROFI900123R75",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Ivan Alejandro",
                    "APELLIDOS": "Roa Franco",
                    "NOMBRES COMPLETOS": "Ivan Alejandro Roa Franco",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Mexico",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Vicente Estrada Sanchez",
                    "CODIGO USUARIO": "iroa",
                    "USUARIOFIVE9": "iroa@aspel.com.mx",
                    "USUARIO RED": "iroa",
                    "CORREOELECTRONICO": "iroa@aspel.com.mx",
                    "STATION ID": "3720466",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Vicente Estrada Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SAVL8809238G4",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Luis Alejandro",
                    "APELLIDOS": "Salgado Vergara",
                    "NOMBRES COMPLETOS": "Luis Alejandro Salgado Vergara",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Mexico",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Vicente Estrada Sanchez",
                    "CODIGO USUARIO": "lsalgado",
                    "USUARIOFIVE9": "lsalgado@aspel.com.mx",
                    "USUARIO RED": "lsalgado",
                    "CORREOELECTRONICO": "lsalgado@aspel.com.mx",
                    "STATION ID": "3763839",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Vicente Estrada Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1005911589",
                    "FECHA INICIAL": "2022-09-28",
                    "FECHA OPERACION": "2022-09-28",
                    "NOMBRES": "Maria Fernanda",
                    "APELLIDOS": "Miranda Perez",
                    "NOMBRES COMPLETOS": "Maria Fernanda Miranda Perez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Johanna Patricia Torres Quintero",
                    "CODIGO USUARIO": "mira803824",
                    "USUARIOFIVE9": "mira803824@siigo.com",
                    "USUARIO RED": "mira803824",
                    "CORREOELECTRONICO": "mira803824@siigo.com",
                    "STATION ID": "3483743",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Johanna Patricia Torres Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000990130",
                    "FECHA INICIAL": "2022-09-28",
                    "FECHA OPERACION": "2022-10-10",
                    "NOMBRES": "Yesennia",
                    "APELLIDOS": "Bolivar Sanchez",
                    "NOMBRES COMPLETOS": "Yesennia Bolivar Sanchez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Lida Katerine Puentes Navarro",
                    "CODIGO USUARIO": "boli803825",
                    "USUARIOFIVE9": "boli803825@siigo.com",
                    "USUARIO RED": "boli803825",
                    "CORREOELECTRONICO": "boli803825@siigo.com",
                    "STATION ID": "4421722",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Lida Katerine Puentes Navarro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1006505348",
                    "FECHA INICIAL": "2022-09-28",
                    "FECHA OPERACION": "2022-10-10",
                    "NOMBRES": "Angie Millie",
                    "APELLIDOS": "Escandon Martinez",
                    "NOMBRES COMPLETOS": "Angie Millie Escandon Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Florencia",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ecuador",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Edith Angelica Gil Umbarila",
                    "CODIGO USUARIO": "esca803829",
                    "USUARIOFIVE9": "esca803829@siigo.com",
                    "USUARIO RED": "esca803829",
                    "CORREOELECTRONICO": "esca803829@siigo.com",
                    "STATION ID": "4421720",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Edith Angelica Gil Umbarila",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "ROCA810227GTA",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Adriana",
                    "APELLIDOS": "Rodriguez Castro",
                    "NOMBRES COMPLETOS": "Adriana Rodriguez Castro",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Mexico",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Vicente Estrada Sanchez",
                    "CODIGO USUARIO": "arodriguez",
                    "USUARIOFIVE9": "arodriguez@aspel.com.mx",
                    "USUARIO RED": "arodriguez",
                    "CORREOELECTRONICO": "arodriguez@aspel.com.mx",
                    "STATION ID": "3763851",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Vicente Estrada Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033679017",
                    "FECHA INICIAL": "2022-10-06",
                    "FECHA OPERACION": "2022-10-11",
                    "NOMBRES": "Shanon Nicol",
                    "APELLIDOS": "Melo Gomez",
                    "NOMBRES COMPLETOS": "Shanon Nicol Melo Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Sonia Mabel Fernandez Calderon",
                    "CODIGO USUARIO": "melo803893",
                    "USUARIOFIVE9": "melo803893@siigo.com",
                    "USUARIO RED": "melo803893",
                    "CORREOELECTRONICO": "melo803893@siigo.com",
                    "STATION ID": "3483770",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sonia Mabel Fernandez Calderon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016105539",
                    "FECHA INICIAL": "2022-10-06",
                    "FECHA OPERACION": "2022-10-11",
                    "NOMBRES": "Angelica Rossana",
                    "APELLIDOS": "Tole Tique",
                    "NOMBRES COMPLETOS": "Angelica Rossana Tole Tique",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones ECU",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Angie Camila Diaz Escobar",
                    "CODIGO USUARIO": "tole803905",
                    "USUARIOFIVE9": "tole803905@siigo.com",
                    "USUARIO RED": "tole803905",
                    "CORREOELECTRONICO": "tole803905@siigo.com",
                    "STATION ID": "3690375",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Camila Diaz Escobar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1216720202",
                    "FECHA INICIAL": "2022-10-06",
                    "FECHA OPERACION": "2022-10-11",
                    "NOMBRES": "Juan Andres",
                    "APELLIDOS": "Jaramillo Muriel",
                    "NOMBRES COMPLETOS": "Juan Andres Jaramillo Muriel",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "jara803904",
                    "USUARIOFIVE9": "jara803904@siigo.com",
                    "USUARIO RED": "jara803904",
                    "CORREOELECTRONICO": "jara803904@siigo.com",
                    "STATION ID": "3720375",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1095941863",
                    "FECHA INICIAL": "2022-10-05",
                    "FECHA OPERACION": "2022-10-11",
                    "NOMBRES": "Maria Jose",
                    "APELLIDOS": "Perez Manjarres",
                    "NOMBRES COMPLETOS": "Maria Jose Perez Manjarres",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Giron",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leida Adriana Rico Herrera",
                    "CODIGO USUARIO": "pere803884",
                    "USUARIOFIVE9": "pere803884@siigo.com",
                    "USUARIO RED": "pere803884",
                    "CORREOELECTRONICO": "pere803884@siigo.com",
                    "STATION ID": "3720464",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leida Adriana Rico Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019093191",
                    "FECHA INICIAL": "2022-10-05",
                    "FECHA OPERACION": "2022-10-11",
                    "NOMBRES": "Angy Carolina",
                    "APELLIDOS": "Beto Marroquin",
                    "NOMBRES COMPLETOS": "Angy Carolina Beto Marroquin",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Daniel Eduardo Pinto Cepeda",
                    "CODIGO USUARIO": "beto803888",
                    "USUARIOFIVE9": "beto803888@siigo.com",
                    "USUARIO RED": "beto803888",
                    "CORREOELECTRONICO": "beto803888@siigo.com",
                    "STATION ID": "3720469",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Daniel Eduardo Pinto Cepeda",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "5081500",
                    "FECHA INICIAL": "2022-10-05",
                    "FECHA OPERACION": "2022-10-11",
                    "NOMBRES": "Angel Eduardo",
                    "APELLIDOS": "Hernandez Munoz",
                    "NOMBRES COMPLETOS": "Angel Eduardo Hernandez Munoz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Misael Martinez Montero",
                    "CODIGO USUARIO": "hern803879",
                    "USUARIOFIVE9": "hern803879@siigo.com",
                    "USUARIO RED": "hern803879",
                    "CORREOELECTRONICO": "hern803879@siigo.com",
                    "STATION ID": "3763891",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Misael Martinez Montero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "5973966",
                    "FECHA INICIAL": "2022-10-05",
                    "FECHA OPERACION": "2022-10-11",
                    "NOMBRES": "Yholmery Carolina",
                    "APELLIDOS": "Dominguez Quintero",
                    "NOMBRES COMPLETOS": "Yholmery Carolina Dominguez Quintero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Andres Felipe Baron Ballen",
                    "CODIGO USUARIO": "domi803872",
                    "USUARIOFIVE9": "domi803872@siigo.com",
                    "USUARIO RED": "domi803872",
                    "CORREOELECTRONICO": "domi803872@siigo.com",
                    "STATION ID": "3763892",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andres Felipe Baron Ballen",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1037613615",
                    "FECHA INICIAL": "2022-10-05",
                    "FECHA OPERACION": "2022-10-11",
                    "NOMBRES": "Vanessa Maria",
                    "APELLIDOS": "Gomez Mazo",
                    "NOMBRES COMPLETOS": "Vanessa Maria Gomez Mazo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juliana Castro Diaz",
                    "CODIGO USUARIO": "gome803876",
                    "USUARIOFIVE9": "gome803876@siigo.com",
                    "USUARIO RED": "gome803876",
                    "CORREOELECTRONICO": "gome803876@siigo.com",
                    "STATION ID": "3763906",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juliana Castro Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000696780",
                    "FECHA INICIAL": "2022-10-05",
                    "FECHA OPERACION": "2022-10-11",
                    "NOMBRES": "Laura Valentina",
                    "APELLIDOS": "Manrique Gonzalez",
                    "NOMBRES COMPLETOS": "Laura Valentina Manrique Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Girardot",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Andres Felipe Baron Ballen",
                    "CODIGO USUARIO": "manr803875",
                    "USUARIOFIVE9": "manr803875@siigo.com",
                    "USUARIO RED": "manr803875",
                    "CORREOELECTRONICO": "manr803875@siigo.com",
                    "STATION ID": "3763968",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andres Felipe Baron Ballen",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "925507501",
                    "FECHA INICIAL": "2022-09-28",
                    "FECHA OPERACION": "2022-10-11",
                    "NOMBRES": "Karol Daniela",
                    "APELLIDOS": "Nieves Henriquez",
                    "NOMBRES COMPLETOS": "Karol Daniela Nieves Henriquez",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "niev803844",
                    "USUARIOFIVE9": "niev803844@siigo.com",
                    "USUARIO RED": "niev803844",
                    "CORREOELECTRONICO": "niev803844@siigo.com",
                    "STATION ID": "4421712",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "930549316",
                    "FECHA INICIAL": "2022-09-28",
                    "FECHA OPERACION": "2022-10-11",
                    "NOMBRES": "Jackson Richard",
                    "APELLIDOS": "Arroba Pluas",
                    "NOMBRES COMPLETOS": "Jackson Richard Arroba Pluas",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "arro8036848",
                    "USUARIOFIVE9": "arro8036848@siigo.com",
                    "USUARIO RED": "arro8036848",
                    "CORREOELECTRONICO": "arro8036848@siigo.com",
                    "STATION ID": "4421715",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030627970",
                    "FECHA INICIAL": "2022-10-05",
                    "FECHA OPERACION": "2022-10-11",
                    "NOMBRES": "Johana Stefany",
                    "APELLIDOS": "Rubio Rivera",
                    "NOMBRES COMPLETOS": "Johana Stefany Rubio Rivera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Andres Felipe Baron Ballen",
                    "CODIGO USUARIO": "rubi803871",
                    "USUARIOFIVE9": "rubi803871@siigo.com",
                    "USUARIO RED": "rubi803871",
                    "CORREOELECTRONICO": "rubi803871@siigo.com",
                    "STATION ID": "4421716",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andres Felipe Baron Ballen",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "957270440",
                    "FECHA INICIAL": "2022-09-28",
                    "FECHA OPERACION": "2022-10-11",
                    "NOMBRES": "Ricardo Saul",
                    "APELLIDOS": "Gurumendi Carreno",
                    "NOMBRES COMPLETOS": "Ricardo Saul Gurumendi Carreno",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "guru803845",
                    "USUARIOFIVE9": "guru803845@siigo.com",
                    "USUARIO RED": "guru803845",
                    "CORREOELECTRONICO": "guru803845@siigo.com",
                    "STATION ID": "4421717",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "928815109",
                    "FECHA INICIAL": "2022-09-28",
                    "FECHA OPERACION": "2022-10-11",
                    "NOMBRES": "Bianca Analia",
                    "APELLIDOS": "Pena Arias",
                    "NOMBRES COMPLETOS": "Bianca Analia Pena Arias",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "pena803846",
                    "USUARIOFIVE9": "pena803846@siigo.com",
                    "USUARIO RED": "pena803846",
                    "CORREOELECTRONICO": "pena803846@siigo.com",
                    "STATION ID": "4421719",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "VAGT701007389",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Maria Teresa",
                    "APELLIDOS": "Vasquez Garza",
                    "NOMBRES COMPLETOS": "Maria Teresa Vasquez Garza",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Mexico",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Vicente Estrada Sanchez",
                    "CODIGO USUARIO": "tvazquez",
                    "USUARIOFIVE9": "tvazquez@aspel.com.mx",
                    "USUARIO RED": "tvazquez",
                    "CORREOELECTRONICO": "tvazquez@aspel.com.mx",
                    "STATION ID": "4063370",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Vicente Estrada Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1075276349",
                    "FECHA INICIAL": "2022-09-28",
                    "FECHA OPERACION": "2022-10-11",
                    "NOMBRES": "Karla",
                    "APELLIDOS": "Caviedes Muneton",
                    "NOMBRES COMPLETOS": "Karla Caviedes Muneton",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jenny Paola Mendez",
                    "CODIGO USUARIO": "cavi8032835",
                    "USUARIOFIVE9": "cavi8032835@siigo.com",
                    "USUARIO RED": "cavi8032835",
                    "CORREOELECTRONICO": "cavi8032835@siigo.com",
                    "STATION ID": "4421703",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jenny Paola Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1113666698",
                    "FECHA INICIAL": "2022-09-28",
                    "FECHA OPERACION": "2022-10-11",
                    "NOMBRES": "Milton Fabian",
                    "APELLIDOS": "Holguin Mejia",
                    "NOMBRES COMPLETOS": "Milton Fabian Holguin Mejia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Palmira",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "holg803834",
                    "USUARIOFIVE9": "holg803834@siigo.com",
                    "USUARIO RED": "holg803834",
                    "CORREOELECTRONICO": "holg803834@siigo.com",
                    "STATION ID": "4421704",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1233694996",
                    "FECHA INICIAL": "2022-10-06",
                    "FECHA OPERACION": "2022-10-14",
                    "NOMBRES": "Lina Maria",
                    "APELLIDOS": "Rincon Ramos",
                    "NOMBRES COMPLETOS": "Lina Maria Rincon Ramos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "rinc803892",
                    "USUARIOFIVE9": "rinc803892@siigo.com",
                    "USUARIO RED": "rinc803892",
                    "CORREOELECTRONICO": "rinc803892@siigo.com",
                    "STATION ID": "4421707",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "NAHO9208273T3",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Oscar Rodrigo",
                    "APELLIDOS": "Navarro Hernandez",
                    "NOMBRES COMPLETOS": "Oscar Rodrigo Navarro Hernandez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Mexico",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Maria Magdalena Mendez Romero",
                    "CODIGO USUARIO": "onavarro",
                    "USUARIOFIVE9": "onavarro@aspel.com.mx",
                    "USUARIO RED": "onavarro",
                    "CORREOELECTRONICO": "onavarro@aspel.com.mx",
                    "STATION ID": "3763936",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Maria Magdalena Mendez Romero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1071166433",
                    "FECHA INICIAL": "2022-01-06",
                    "FECHA OPERACION": "2022-10-19",
                    "NOMBRES": "Jonathan",
                    "APELLIDOS": "Rodriguez Sanabria",
                    "NOMBRES COMPLETOS": "Jonathan Rodriguez Sanabria",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Edith Angelica Gil Umbarila",
                    "CODIGO USUARIO": "rodr802549",
                    "USUARIOFIVE9": "rodr802549@siigo.com",
                    "USUARIO RED": "rodr802549",
                    "CORREOELECTRONICO": "rodr802549@siigo.com",
                    "STATION ID": "2606078",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Edith Angelica Gil Umbarila",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1233904117",
                    "FECHA INICIAL": "2017-11-17",
                    "FECHA OPERACION": "2022-10-19",
                    "NOMBRES": "Stefania",
                    "APELLIDOS": "Trivino Penagos",
                    "NOMBRES COMPLETOS": "Stefania Trivino Penagos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Liliana Ramirez Vanegas",
                    "CODIGO USUARIO": "triv111508",
                    "USUARIOFIVE9": "triv111508@siigo.com",
                    "USUARIO RED": "triv111508",
                    "CORREOELECTRONICO": "triv111508@siigo.com",
                    "STATION ID": "2606089",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Liliana Ramirez Vanegas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1118569249",
                    "FECHA INICIAL": "2022-10-12",
                    "FECHA OPERACION": "2022-10-24",
                    "NOMBRES": "Claudia Yamile",
                    "APELLIDOS": "Gil Estupinan",
                    "NOMBRES COMPLETOS": "Claudia Yamile Gil Estupinan",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Yopal",
                    "AREA": "Customer",
                    "SUBAREA": "Call Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alcides Martin Salas Orozco",
                    "CODIGO USUARIO": "gile803907",
                    "USUARIOFIVE9": "gile803907@siigo.com",
                    "USUARIO RED": "gile803907",
                    "CORREOELECTRONICO": "gile803907@siigo.com",
                    "STATION ID": "2606350",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alcides Martin Salas Orozco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "46386018",
                    "FECHA INICIAL": "2022-10-12",
                    "FECHA OPERACION": "2022-10-24",
                    "NOMBRES": "Alexandra",
                    "APELLIDOS": "Hurtado Uribe",
                    "NOMBRES COMPLETOS": "Alexandra Hurtado Uribe",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Sogamoso",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "hurt803911",
                    "USUARIOFIVE9": "hurt803911@siigo.com",
                    "USUARIO RED": "hurt803911",
                    "CORREOELECTRONICO": "hurt803911@siigo.com",
                    "STATION ID": "2606746",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1056572871",
                    "FECHA INICIAL": "2022-10-12",
                    "FECHA OPERACION": "2022-10-24",
                    "NOMBRES": "Andrea Paola",
                    "APELLIDOS": "Mateus Rodriguez",
                    "NOMBRES COMPLETOS": "Andrea Paola Mateus Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Tuta",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "mate803912",
                    "USUARIOFIVE9": "mate803912@siigo.com",
                    "USUARIO RED": "mate803912",
                    "CORREOELECTRONICO": "mate803912@siigo.com",
                    "STATION ID": "2606794",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1049642941",
                    "FECHA INICIAL": "2022-10-12",
                    "FECHA OPERACION": "2022-10-24",
                    "NOMBRES": "Tania Milena",
                    "APELLIDOS": "Zea Morales",
                    "NOMBRES COMPLETOS": "Tania Milena Zea Morales",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Tunja",
                    "AREA": "Customer",
                    "SUBAREA": "Call Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alcides Martin Salas Orozco",
                    "CODIGO USUARIO": "zeam803915",
                    "USUARIOFIVE9": "zeam803915@siigo.com",
                    "USUARIO RED": "zeam803915",
                    "CORREOELECTRONICO": "zeam803915@siigo.com",
                    "STATION ID": "2606957",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alcides Martin Salas Orozco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001339906",
                    "FECHA INICIAL": "2022-10-12",
                    "FECHA OPERACION": "2022-10-24",
                    "NOMBRES": "Karen Valentina",
                    "APELLIDOS": "Castellanos Munoz",
                    "NOMBRES COMPLETOS": "Karen Valentina Castellanos Munoz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alcides Martin Salas Orozco",
                    "CODIGO USUARIO": "cast803916",
                    "USUARIOFIVE9": "cast803916@siigo.com",
                    "USUARIO RED": "cast803916",
                    "CORREOELECTRONICO": "cast803916@siigo.com",
                    "STATION ID": "2606985",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alcides Martin Salas Orozco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1049645504",
                    "FECHA INICIAL": "2022-10-12",
                    "FECHA OPERACION": "2022-10-24",
                    "NOMBRES": "Erika Paola",
                    "APELLIDOS": "Lopez Cative",
                    "NOMBRES COMPLETOS": "Erika Paola Lopez Cative",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Tunja",
                    "AREA": "Customer",
                    "SUBAREA": "Call Uruguay",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alcides Martin Salas Orozco",
                    "CODIGO USUARIO": "lope803917",
                    "USUARIOFIVE9": "lope803917@siigo.com",
                    "USUARIO RED": "lope803917",
                    "CORREOELECTRONICO": "lope803917@siigo.com",
                    "STATION ID": "2606990",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alcides Martin Salas Orozco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022438205",
                    "FECHA INICIAL": "2022-10-13",
                    "FECHA OPERACION": "2022-10-24",
                    "NOMBRES": "Paola Alejandra",
                    "APELLIDOS": "Jerez Pedraza",
                    "NOMBRES COMPLETOS": "Paola Alejandra Jerez Pedraza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Tunja",
                    "AREA": "Customer",
                    "SUBAREA": "Retencion y Enamoramiento",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luz Adriana Hurtado Navarro",
                    "CODIGO USUARIO": "jere803919",
                    "USUARIOFIVE9": "jere803919@siigo.com",
                    "USUARIO RED": "jere803919",
                    "CORREOELECTRONICO": "jere803919@siigo.com",
                    "STATION ID": "2607049",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Adriana Hurtado Navarro",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1140884329",
                    "FECHA INICIAL": "2022-11-03",
                    "FECHA OPERACION": "2022-11-03",
                    "NOMBRES": "Redy Barney",
                    "APELLIDOS": "Asis Torrecilla",
                    "NOMBRES COMPLETOS": "Redy Barney Asis Torrecilla",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soledad",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Andrea Catalina Vega Gomez",
                    "CODIGO USUARIO": "asis804006",
                    "USUARIOFIVE9": "asis804006@siigo.com",
                    "USUARIO RED": "asis804006",
                    "CORREOELECTRONICO": "asis804006@siigo.com",
                    "STATION ID": "2606003",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andrea Catalina Vega Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001882094",
                    "FECHA INICIAL": "2022-10-27",
                    "FECHA OPERACION": "2022-11-03",
                    "NOMBRES": "Yudis Maria",
                    "APELLIDOS": "Baldovino Hoyos",
                    "NOMBRES COMPLETOS": "Yudis Maria Baldovino Hoyos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soledad",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "bald804020",
                    "USUARIOFIVE9": "bald804020@siigo.com",
                    "USUARIO RED": "bald804020",
                    "CORREOELECTRONICO": "bald804020@siigo.com",
                    "STATION ID": "2606616",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1061820333",
                    "FECHA INICIAL": "2022-10-27",
                    "FECHA OPERACION": "2022-11-03",
                    "NOMBRES": "Juan Andres",
                    "APELLIDOS": "Chate Capote",
                    "NOMBRES COMPLETOS": "Juan Andres Chate Capote",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Popayan",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jazbleidy Andrea Nunez Montoya",
                    "CODIGO USUARIO": "chat804019",
                    "USUARIOFIVE9": "chat804019@siigo.com",
                    "USUARIO RED": "chat804019",
                    "CORREOELECTRONICO": "chat804019@siigo.com",
                    "STATION ID": "2606633",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jazbleidy Andrea Nunez Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1140891373",
                    "FECHA INICIAL": "2022-10-27",
                    "FECHA OPERACION": "2022-11-03",
                    "NOMBRES": "Carolay Patricia",
                    "APELLIDOS": "Leiva Buelvas",
                    "NOMBRES COMPLETOS": "Carolay Patricia Leiva Buelvas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soledad",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jefrey Julian Sanchez Celemin",
                    "CODIGO USUARIO": "leiv804017",
                    "USUARIOFIVE9": "leiv804017@siigo.com",
                    "USUARIO RED": "leiv804017",
                    "CORREOELECTRONICO": "leiv804017@siigo.com",
                    "STATION ID": "2607040",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jefrey Julian Sanchez Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "40343317",
                    "FECHA INICIAL": "2022-10-27",
                    "FECHA OPERACION": "2022-11-03",
                    "NOMBRES": "Tatiana Veronica",
                    "APELLIDOS": "Prieto Prieto",
                    "NOMBRES COMPLETOS": "Tatiana Veronica Prieto Prieto",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Villavicencio",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Luz Katerine Maya Mogollon",
                    "CODIGO USUARIO": "prie804016",
                    "USUARIOFIVE9": "prie804016@siigo.com",
                    "USUARIO RED": "prie804016",
                    "CORREOELECTRONICO": "prie804016@siigo.com",
                    "STATION ID": "3127513",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Katerine Maya Mogollon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1003913422",
                    "FECHA INICIAL": "2022-10-27",
                    "FECHA OPERACION": "2022-11-03",
                    "NOMBRES": "Laura Yulieth",
                    "APELLIDOS": "Ruiz Rodriguez",
                    "NOMBRES COMPLETOS": "Laura Yulieth Ruiz Rodriguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Villavicencio",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Hancel Rafael Salcedo Mendoza",
                    "CODIGO USUARIO": "ruiz804015",
                    "USUARIOFIVE9": "ruiz804015@siigo.com",
                    "USUARIO RED": "ruiz804015",
                    "CORREOELECTRONICO": "ruiz804015@siigo.com",
                    "STATION ID": "3127532",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Hancel Rafael Salcedo Mendoza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "903472",
                    "FECHA INICIAL": "2022-10-27",
                    "FECHA OPERACION": "2022-11-03",
                    "NOMBRES": "Jesus Javier",
                    "APELLIDOS": "Rivero Escalona",
                    "NOMBRES COMPLETOS": "Jesus Javier Rivero Escalona",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Villavicencio",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nomina",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jenny Paola Mendez",
                    "CODIGO USUARIO": "rive804014",
                    "USUARIOFIVE9": "rive804014@siigo.com",
                    "USUARIO RED": "rive804014",
                    "CORREOELECTRONICO": "rive804014@siigo.com",
                    "STATION ID": "3127534",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jenny Paola Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143169160",
                    "FECHA INICIAL": "2022-10-27",
                    "FECHA OPERACION": "2022-11-03",
                    "NOMBRES": "Karina Pilar",
                    "APELLIDOS": "Escobar Pichon",
                    "NOMBRES COMPLETOS": "Karina Pilar Escobar Pichon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Hancel Rafael Salcedo Mendoza",
                    "CODIGO USUARIO": "esco804013",
                    "USUARIOFIVE9": "esco804013@siigo.com",
                    "USUARIO RED": "esco804013",
                    "CORREOELECTRONICO": "esco804013@siigo.com",
                    "STATION ID": "3127583",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Hancel Rafael Salcedo Mendoza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000439869",
                    "FECHA INICIAL": "2022-10-27",
                    "FECHA OPERACION": "2022-11-03",
                    "NOMBRES": "Luisa Fernanda",
                    "APELLIDOS": "Suazo Tamayo",
                    "NOMBRES COMPLETOS": "Luisa Fernanda Suazo Tamayo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bello",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Tatiana Reyes Perez",
                    "CODIGO USUARIO": "suaz804012",
                    "USUARIOFIVE9": "suaz804012@siigo.com",
                    "USUARIO RED": "suaz804012",
                    "CORREOELECTRONICO": "suaz804012@siigo.com",
                    "STATION ID": "3267834",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Tatiana Reyes Perez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "46511920",
                    "FECHA INICIAL": "2022-11-01",
                    "FECHA OPERACION": "2022-11-03",
                    "NOMBRES": "Natalia",
                    "APELLIDOS": "Cancela Rodriguez",
                    "NOMBRES COMPLETOS": "Natalia Cancela Rodriguez",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Luis Ignacio Ferreira Duarte",
                    "CODIGO USUARIO": "canc804034",
                    "USUARIOFIVE9": "canc804034@siigo.com",
                    "USUARIO RED": "canc804034",
                    "CORREOELECTRONICO": "canc804034@siigo.com",
                    "STATION ID": "3483718",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Luis Ignacio Ferreira Duarte",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "71711929",
                    "FECHA INICIAL": "2022-11-16",
                    "FECHA OPERACION": "2022-11-16",
                    "NOMBRES": "Sergio Alberto",
                    "APELLIDOS": "Raigosa Hernandez",
                    "NOMBRES COMPLETOS": "Sergio Alberto Raigosa Hernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Rocxy Daniela Fernandez Fernandez",
                    "CODIGO USUARIO": "raig112010",
                    "USUARIOFIVE9": "raig112010@siigo.com",
                    "USUARIO RED": "raig112010",
                    "CORREOELECTRONICO": "raig112010@siigo.com",
                    "STATION ID": "4023736",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Rocxy Daniela Fernandez Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1004509764",
                    "FECHA INICIAL": "2022-11-16",
                    "FECHA OPERACION": "2022-11-16",
                    "NOMBRES": "Paula Alexandra",
                    "APELLIDOS": "Munoz Narvaez",
                    "NOMBRES COMPLETOS": "Paula Alexandra Munoz Narvaez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Pasto",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Eduardo Sanchez Castano",
                    "CODIGO USUARIO": "muno804076",
                    "USUARIOFIVE9": "muno804076@siigo.com",
                    "USUARIO RED": "muno804076",
                    "CORREOELECTRONICO": "muno804076@siigo.com",
                    "STATION ID": "3956813",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Eduardo Sanchez Castano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1085291425",
                    "FECHA INICIAL": "2022-11-16",
                    "FECHA OPERACION": "2022-11-16",
                    "NOMBRES": "Vanessa Estefania",
                    "APELLIDOS": "Yacelga Leiton",
                    "NOMBRES COMPLETOS": "Vanessa Estefania Yacelga Leiton",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Pasto",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Yamid Ramiro Quintero Montano",
                    "CODIGO USUARIO": "yace804071",
                    "USUARIOFIVE9": "yace804071@siigo.com",
                    "USUARIO RED": "yace804071",
                    "CORREOELECTRONICO": "yace804071@siigo.com",
                    "STATION ID": "3956814",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yamid Ramiro Quintero Montano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1107975210",
                    "FECHA INICIAL": "2022-11-16",
                    "FECHA OPERACION": "2022-11-16",
                    "NOMBRES": "Edgar David",
                    "APELLIDOS": "Andrade Varon",
                    "NOMBRES COMPLETOS": "Edgar David Andrade Varon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Leidy Stefany Castillo",
                    "CODIGO USUARIO": "andr804072",
                    "USUARIOFIVE9": "andr804072@siigo.com",
                    "USUARIO RED": "andr804072",
                    "CORREOELECTRONICO": "andr804072@siigo.com",
                    "STATION ID": "3956815",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Leidy Stefany Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "27177643",
                    "FECHA INICIAL": "2022-11-16",
                    "FECHA OPERACION": "2022-11-16",
                    "NOMBRES": "Carmen Elizabeth",
                    "APELLIDOS": "Bolanos",
                    "NOMBRES COMPLETOS": "Carmen Elizabeth Bolanos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ipiales",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Yamid Ramiro Quintero Montano",
                    "CODIGO USUARIO": "bola804073",
                    "USUARIOFIVE9": "bola804073@siigo.com",
                    "USUARIO RED": "bola804073",
                    "CORREOELECTRONICO": "bola804073@siigo.com",
                    "STATION ID": "3956816",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yamid Ramiro Quintero Montano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1087423989",
                    "FECHA INICIAL": "2022-11-16",
                    "FECHA OPERACION": "2022-11-16",
                    "NOMBRES": "Anderson Yesid",
                    "APELLIDOS": "Mora Munoz",
                    "NOMBRES COMPLETOS": "Anderson Yesid Mora Munoz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Tuquerres",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Leidy Stefany Castillo",
                    "CODIGO USUARIO": "mora804074",
                    "USUARIOFIVE9": "mora804074@siigo.com",
                    "USUARIO RED": "mora804074",
                    "CORREOELECTRONICO": "mora804074@siigo.com",
                    "STATION ID": "3956817",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Leidy Stefany Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "HULV9007281N6",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Victor Hugo",
                    "APELLIDOS": "Huerta Lopez",
                    "NOMBRES COMPLETOS": "Victor Hugo Huerta Lopez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Mexico",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Vicente Estrada Sanchez",
                    "CODIGO USUARIO": "vhuerta",
                    "USUARIOFIVE9": "vhuerta@aspel.com.mx",
                    "USUARIO RED": "vhuerta",
                    "CORREOELECTRONICO": "vhuerta@aspel.com.mx",
                    "STATION ID": "3763954",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Vicente Estrada Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001216363",
                    "FECHA INICIAL": "2022-11-21",
                    "FECHA OPERACION": "2022-11-21",
                    "NOMBRES": "Maria Alejandra",
                    "APELLIDOS": "Riano Rubiano",
                    "NOMBRES COMPLETOS": "Maria Alejandra Riano Rubiano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Jazbleidy Andrea Nunez Montoya",
                    "CODIGO USUARIO": "rian804134",
                    "USUARIOFIVE9": "rian804134@siigo.com",
                    "USUARIO RED": "rian804134",
                    "CORREOELECTRONICO": "rian804134@siigo.com",
                    "STATION ID": "3956768",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jazbleidy Andrea Nunez Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "CAVL870726R82",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-09",
                    "NOMBRES": "Jose Luis",
                    "APELLIDOS": "Carranza Valdes",
                    "NOMBRES COMPLETOS": "Jose Luis Carranza Valdes",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Mexico",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Vicente Estrada Sanchez",
                    "CODIGO USUARIO": "jcarranza",
                    "USUARIOFIVE9": "jcarranza@aspel.com.mx",
                    "USUARIO RED": "jcarranza",
                    "CORREOELECTRONICO": "jcarranza@aspel.com.mx",
                    "STATION ID": "3127454",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Vicente Estrada Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "VENM8911019U2",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Miguel Angel",
                    "APELLIDOS": "Velasco Nambo",
                    "NOMBRES COMPLETOS": "Miguel Angel Velasco Nambo",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Victor Hugo Huerta Lopez",
                    "CODIGO USUARIO": "mvelazco",
                    "USUARIOFIVE9": "mvelasco@aspel.com.mx",
                    "USUARIO RED": "mvelazco",
                    "CORREOELECTRONICO": "mvelasco@aspel.com.mx",
                    "STATION ID": "3857169",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Victor Hugo Huerta Lopez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53097313",
                    "FECHA INICIAL": "2022-11-02",
                    "FECHA OPERACION": "2022-12-07",
                    "NOMBRES": "Natalia",
                    "APELLIDOS": "Rivas Lizcano",
                    "NOMBRES COMPLETOS": "Natalia Rivas Lizcano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Jefferson Arvey Ramirez Ibanez",
                    "CODIGO USUARIO": "riva804059",
                    "USUARIOFIVE9": "riva804059@siigo.com",
                    "USUARIO RED": "riva804059",
                    "CORREOELECTRONICO": "riva804059@siigo.com",
                    "STATION ID": "3956756",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jefferson Arvey Ramirez Ibanez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1036950381",
                    "FECHA INICIAL": "2022-11-02",
                    "FECHA OPERACION": "2022-12-07",
                    "NOMBRES": "Luisa Fernanda",
                    "APELLIDOS": "Rendon Sepulveda",
                    "NOMBRES COMPLETOS": "Luisa Fernanda Rendon Sepulveda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Neiva",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "William Andres Reyes Silva",
                    "CODIGO USUARIO": "rend804060",
                    "USUARIOFIVE9": "rend804060@siigo.com",
                    "USUARIO RED": "rend804060",
                    "CORREOELECTRONICO": "rend804060@siigo.com",
                    "STATION ID": "3956754",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_William Andres Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1149062",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2022-12-09",
                    "NOMBRES": "Reiser Ayurami",
                    "APELLIDOS": "Valdivieso Pina",
                    "NOMBRES COMPLETOS": "Reiser Ayurami Valdivieso Pina",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ecuador",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Edith Angelica Gil Umbarila",
                    "CODIGO USUARIO": "vald804153",
                    "USUARIOFIVE9": "vald804153@siigo.com",
                    "USUARIO RED": "vald804153",
                    "CORREOELECTRONICO": "vald804153@siigo.com",
                    "STATION ID": "2606015",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Edith Angelica Gil Umbarila",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "CAML970523T64",
                    "FECHA INICIAL": "2022-04-26",
                    "FECHA OPERACION": "2022-05-27",
                    "NOMBRES": "Luis Angel",
                    "APELLIDOS": "Castillo Morales",
                    "NOMBRES COMPLETOS": "Luis Angel Castillo Morales",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Victor Hugo Huerta Lopez",
                    "CODIGO USUARIO": "acastillo",
                    "USUARIOFIVE9": "acastillo@aspel.com.mx",
                    "USUARIO RED": "acastillo",
                    "CORREOELECTRONICO": "acastillo@aspel.com.mx",
                    "STATION ID": "3763924",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Victor Hugo Huerta Lopez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1140850712",
                    "FECHA INICIAL": "2022-11-02",
                    "FECHA OPERACION": "2022-12-12",
                    "NOMBRES": "Sara Lucia",
                    "APELLIDOS": "Alvarez Charrys",
                    "NOMBRES COMPLETOS": "Sara Lucia Alvarez Charrys",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soledad",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Carlos Andres Valencia Bravo",
                    "CODIGO USUARIO": "alva804056",
                    "USUARIOFIVE9": "alva804056@siigo.com",
                    "USUARIO RED": "alva804056",
                    "CORREOELECTRONICO": "alva804056@siigo.com",
                    "STATION ID": "2606180",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Andres Valencia Bravo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1004346066",
                    "FECHA INICIAL": "2022-11-02",
                    "FECHA OPERACION": "2022-12-12",
                    "NOMBRES": "Giselle Karina",
                    "APELLIDOS": "Estrada Banderas",
                    "NOMBRES COMPLETOS": "Giselle Karina Estrada Banderas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Santa Marta",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Luz Katerine Maya Mogollon",
                    "CODIGO USUARIO": "estr804058",
                    "USUARIOFIVE9": "estr804058@siigo.com",
                    "USUARIO RED": "estr804058",
                    "CORREOELECTRONICO": "estr804058@siigo.com",
                    "STATION ID": "2606185",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Luz Katerine Maya Mogollon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1086302692",
                    "FECHA INICIAL": "2022-11-02",
                    "FECHA OPERACION": "2022-12-12",
                    "NOMBRES": "Yuliza Katherine",
                    "APELLIDOS": "Diaz Cuaran",
                    "NOMBRES COMPLETOS": "Yuliza Katherine Diaz Cuaran",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Pasto",
                    "AREA": "Customer",
                    "SUBAREA": "Call I",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Pierre Fardey Velasquez Sanchez",
                    "CODIGO USUARIO": "diaz804062",
                    "USUARIOFIVE9": "diaz804062@siigo.com",
                    "USUARIO RED": "diaz804062",
                    "CORREOELECTRONICO": "diaz804062@siigo.com",
                    "STATION ID": "2606202",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Pierre Fardey Velasquez Sanchez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "RAEA930528NG7",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2022-12-12",
                    "NOMBRES": "Araceli",
                    "APELLIDOS": "Ramirez Estrada",
                    "NOMBRES COMPLETOS": "Araceli Ramirez Estrada",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube MX",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Oscar Rodrigo Navarro Hernandez",
                    "CODIGO USUARIO": "aestrada",
                    "USUARIOFIVE9": "aestrada@aspel.com.mx",
                    "USUARIO RED": "aestrada",
                    "CORREOELECTRONICO": "aestrada@aspel.com.mx",
                    "STATION ID": "2606393",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Oscar Rodrigo Navarro Hernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "SABJ9302212A3",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jorge Luis",
                    "APELLIDOS": "Sartillo Boyzo",
                    "NOMBRES COMPLETOS": "Jorge Luis Sartillo Boyzo",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Victor Hugo Huerta Lopez",
                    "CODIGO USUARIO": "jboyzo",
                    "USUARIOFIVE9": "jboyzo@aspel.com.mx",
                    "USUARIO RED": "jboyzo",
                    "CORREOELECTRONICO": "jboyzo@aspel.com.mx",
                    "STATION ID": "3690307",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Victor Hugo Huerta Lopez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "AASJ910227DD5",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jessica Yadira",
                    "APELLIDOS": "Albarran Sanchez",
                    "NOMBRES COMPLETOS": "Jessica Yadira Albarran Sanchez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Victor Hugo Huerta Lopez",
                    "CODIGO USUARIO": "jalbarran",
                    "USUARIOFIVE9": "jalbarran@aspel.com.mx",
                    "USUARIO RED": "jalbarran",
                    "CORREOELECTRONICO": "jalbarran@aspel.com.mx",
                    "STATION ID": "3127518",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Victor Hugo Huerta Lopez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "GACC891106CL8",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Carlos Alberto",
                    "APELLIDOS": "Garcia Castillo",
                    "NOMBRES COMPLETOS": "Carlos Alberto Garcia Castillo",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Victor Hugo Huerta Lopez",
                    "CODIGO USUARIO": "cagarcia",
                    "USUARIOFIVE9": "cagarcia@aspel.com.mx",
                    "USUARIO RED": "cagarcia",
                    "CORREOELECTRONICO": "cagarcia@aspel.com.mx",
                    "STATION ID": "3471499",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Victor Hugo Huerta Lopez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1036398289",
                    "FECHA INICIAL": "2021-04-05",
                    "FECHA OPERACION": "2022-12-21",
                    "NOMBRES": "Jhony Alexander",
                    "APELLIDOS": "Alzate Soto",
                    "NOMBRES COMPLETOS": "Jhony Alexander Alzate Soto",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Johanna Sanchez Moncada",
                    "CODIGO USUARIO": "alza801298",
                    "USUARIOFIVE9": "alza801298@siigo.com",
                    "USUARIO RED": "alza801298",
                    "CORREOELECTRONICO": "alza801298@siigo.com",
                    "STATION ID": "2606908",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Johanna Sanchez Moncada",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010236958",
                    "FECHA INICIAL": "2022-03-12",
                    "FECHA OPERACION": "2022-12-26",
                    "NOMBRES": "Xiomara Natali",
                    "APELLIDOS": "Camelo Fonseca",
                    "NOMBRES COMPLETOS": "Xiomara Natali Camelo Fonseca",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Leidy Paola Rubiano Vargas",
                    "CODIGO USUARIO": "came801217",
                    "USUARIOFIVE9": "came801217@siigo.com",
                    "USUARIO RED": "came801217",
                    "CORREOELECTRONICO": "came801217@siigo.com",
                    "STATION ID": "2606364",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Leidy Paola Rubiano Vargas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010060768",
                    "FECHA INICIAL": "2020-10-29",
                    "FECHA OPERACION": "2022-12-26",
                    "NOMBRES": "Angie Vanessa",
                    "APELLIDOS": "Galeano Lara",
                    "NOMBRES COMPLETOS": "Angie Vanessa Galeano Lara",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Leidy Paola Rubiano Vargas",
                    "CODIGO USUARIO": "gale800827",
                    "USUARIOFIVE9": "gale800827@siigo.com",
                    "USUARIO RED": "gale800827",
                    "CORREOELECTRONICO": "gale800827@siigo.com",
                    "STATION ID": "2606378",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Leidy Paola Rubiano Vargas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014230421",
                    "FECHA INICIAL": "2019-11-12",
                    "FECHA OPERACION": "2022-12-26",
                    "NOMBRES": "Mahycol Estivens",
                    "APELLIDOS": "Salazar Turmeque",
                    "NOMBRES COMPLETOS": "Mahycol Estivens Salazar Turmeque",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Diana Mercedes Moncada Peraza",
                    "CODIGO USUARIO": "sala112531",
                    "USUARIOFIVE9": "sala112531@siigo.com",
                    "USUARIO RED": "sala112531",
                    "CORREOELECTRONICO": "sala112531@siigo.com",
                    "STATION ID": "2606561",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Mercedes Moncada Peraza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "LOLJ9205241J0",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Juan Carlos",
                    "APELLIDOS": "Lopez Lopez",
                    "NOMBRES COMPLETOS": "Juan Carlos Lopez Lopez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Victor Hugo Huerta Lopez",
                    "CODIGO USUARIO": "jclopez",
                    "USUARIOFIVE9": "jclopez@aspel.com.mx",
                    "USUARIO RED": "jclopez",
                    "CORREOELECTRONICO": "jclopez@aspel.com.mx",
                    "STATION ID": "3690302",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Victor Hugo Huerta Lopez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "JALM9011293Z6",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Marco Antonio",
                    "APELLIDOS": "Jacobo Lopez",
                    "NOMBRES COMPLETOS": "Marco Antonio Jacobo Lopez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Victor Hugo Huerta Lopez",
                    "CODIGO USUARIO": "mjacobo",
                    "USUARIOFIVE9": "mjacobo@aspel.com.mx",
                    "USUARIO RED": "mjacobo",
                    "CORREOELECTRONICO": "mjacobo@aspel.com.mx",
                    "STATION ID": "3720414",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Victor Hugo Huerta Lopez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "VEVC960314M16",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Carlos Alberto",
                    "APELLIDOS": "Velazquez Valencia",
                    "NOMBRES COMPLETOS": "Carlos Alberto Velazquez Valencia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Victor Hugo Huerta Lopez",
                    "CODIGO USUARIO": "cvalencia",
                    "USUARIOFIVE9": "cvalencia@aspel.com.mx",
                    "USUARIO RED": "cvalencia",
                    "CORREOELECTRONICO": "cvalencia@aspel.com.mx",
                    "STATION ID": "3763876",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Victor Hugo Huerta Lopez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1233902923",
                    "FECHA INICIAL": "2022-12-06",
                    "FECHA OPERACION": "2022-09-28",
                    "NOMBRES": "Brigett Natalia",
                    "APELLIDOS": "Garcia Arevalo",
                    "NOMBRES COMPLETOS": "Brigett Natalia Garcia Arevalo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Bismark Ivan Rios Campos",
                    "CODIGO USUARIO": "garc804408",
                    "USUARIOFIVE9": "garc804408@siigo.com",
                    "USUARIO RED": "garc804408",
                    "CORREOELECTRONICO": "garc804408@siigo.com",
                    "STATION ID": "3127495",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Bismark Ivan Rios Campos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52759566",
                    "FECHA INICIAL": "2022-12-21",
                    "FECHA OPERACION": "2023-01-03",
                    "NOMBRES": "Luz Arelix",
                    "APELLIDOS": "Franco Palma",
                    "NOMBRES COMPLETOS": "Luz Arelix Franco Palma",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Diana Maria Naranjo Mattheus",
                    "CODIGO USUARIO": "fran804157",
                    "USUARIOFIVE9": "fran804157@siigo.com",
                    "USUARIO RED": "fran804157",
                    "CORREOELECTRONICO": "fran804157@siigo.com",
                    "STATION ID": "3267848",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Maria Naranjo Mattheus",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030564230",
                    "FECHA INICIAL": "2022-12-21",
                    "FECHA OPERACION": "2023-01-03",
                    "NOMBRES": "Carlos Andres",
                    "APELLIDOS": "Gomez Cubillos",
                    "NOMBRES COMPLETOS": "Carlos Andres Gomez Cubillos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Diana Maria Naranjo Mattheus",
                    "CODIGO USUARIO": "gome804158",
                    "USUARIOFIVE9": "gome804158@siigo.com",
                    "USUARIO RED": "gome804158",
                    "CORREOELECTRONICO": "gome804158@siigo.com",
                    "STATION ID": "3267855",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Maria Naranjo Mattheus",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1006595466",
                    "FECHA INICIAL": "2022-12-21",
                    "FECHA OPERACION": "2023-01-03",
                    "NOMBRES": "Damaris Cilena",
                    "APELLIDOS": "Franco Sanabria",
                    "NOMBRES COMPLETOS": "Damaris Cilena Franco Sanabria",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bucaramanga",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Juvenal Reyes Silva",
                    "CODIGO USUARIO": "fran804162",
                    "USUARIOFIVE9": "fran804162@siigo.com",
                    "USUARIO RED": "fran804162",
                    "CORREOELECTRONICO": "fran804162@siigo.com",
                    "STATION ID": "3267904",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juvenal Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "HESA960331UX9",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Jose Alberto",
                    "APELLIDOS": "Hernandez Santillan",
                    "NOMBRES COMPLETOS": "Jose Alberto Hernandez Santillan",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Victor Hugo Huerta Lopez",
                    "CODIGO USUARIO": "jsantillan",
                    "USUARIOFIVE9": "jsantillan@aspel.com.mx",
                    "USUARIO RED": "jsantillan",
                    "CORREOELECTRONICO": "jsantillan@aspel.com.mx",
                    "STATION ID": "3763878",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Victor Hugo Huerta Lopez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032500369",
                    "FECHA INICIAL": "2022-12-27",
                    "FECHA OPERACION": "2023-01-05",
                    "NOMBRES": "Sharick Tatiana",
                    "APELLIDOS": "Pulecio Sanchez",
                    "NOMBRES COMPLETOS": "Sharick Tatiana Pulecio Sanchez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Funza",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "pule804517",
                    "USUARIOFIVE9": "pule804517@siigo.com",
                    "USUARIO RED": "pule804517",
                    "CORREOELECTRONICO": "pule804517@siigo.com",
                    "STATION ID": "3690236",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012425199",
                    "FECHA INICIAL": "2022-12-27",
                    "FECHA OPERACION": "2023-01-05",
                    "NOMBRES": "Angie Paola",
                    "APELLIDOS": "Garcia Hurtado",
                    "NOMBRES COMPLETOS": "Angie Paola Garcia Hurtado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "garc804536",
                    "USUARIOFIVE9": "garc804536@siigo.com",
                    "USUARIO RED": "garc804536",
                    "CORREOELECTRONICO": "garc804536@siigo.com",
                    "STATION ID": "3690238",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "LORH990726SQ9",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Hugo",
                    "APELLIDOS": "Lozano Rivera",
                    "NOMBRES COMPLETOS": "Hugo Lozano Rivera",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube MX",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Oscar Rodrigo Navarro Hernandez",
                    "CODIGO USUARIO": "hlozano",
                    "USUARIOFIVE9": "hlozano@aspel.com.mx",
                    "USUARIO RED": "hlozano",
                    "CORREOELECTRONICO": "hlozano@aspel.com.mx",
                    "STATION ID": "3763883",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Oscar Rodrigo Navarro Hernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "LOMA911102PE2",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Andrea Yaquelin",
                    "APELLIDOS": "Lopez Manjarrez",
                    "NOMBRES COMPLETOS": "Andrea Yaquelin Lopez Manjarrez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Victor Hugo Huerta Lopez",
                    "CODIGO USUARIO": "amanjarrez",
                    "USUARIOFIVE9": "amanjarrez@aspel.com.mx",
                    "USUARIO RED": "amanjarrez",
                    "CORREOELECTRONICO": "amanjarrez@aspel.com.mx",
                    "STATION ID": "3763949",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Victor Hugo Huerta Lopez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "EILM8201179J4",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Maribel",
                    "APELLIDOS": "Espinosa Luna",
                    "NOMBRES COMPLETOS": "Maribel Espinosa Luna",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Victor Hugo Huerta Lopez",
                    "CODIGO USUARIO": "mluna",
                    "USUARIOFIVE9": "mluna@aspel.com.mx",
                    "USUARIO RED": "mluna",
                    "CORREOELECTRONICO": "mluna@aspel.com.mx",
                    "STATION ID": "3763950",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Victor Hugo Huerta Lopez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143984512",
                    "FECHA INICIAL": "2022-01-04",
                    "FECHA OPERACION": "2023-01-05",
                    "NOMBRES": "Jessica Carolina",
                    "APELLIDOS": "Meneses Arango",
                    "NOMBRES COMPLETOS": "Jessica Carolina Meneses Arango",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "mene804563",
                    "USUARIOFIVE9": "mene804563@siigo.com",
                    "USUARIO RED": "mene804563",
                    "CORREOELECTRONICO": "mene804563@siigo.com",
                    "STATION ID": "3690298",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032481325",
                    "FECHA INICIAL": "2022-12-27",
                    "FECHA OPERACION": "2023-01-05",
                    "NOMBRES": "Hammer Jair",
                    "APELLIDOS": "Cruz Guaje",
                    "NOMBRES COMPLETOS": "Hammer Jair Cruz Guaje",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnicos",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Anderson Cubillos Ayala",
                    "CODIGO USUARIO": "cruz804533",
                    "USUARIOFIVE9": "cruz804533@siigo.com",
                    "USUARIO RED": "cruz804533",
                    "CORREOELECTRONICO": "cruz804533@siigo.com",
                    "STATION ID": "3690306",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Anderson Cubillos Ayala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1005876345",
                    "FECHA INICIAL": "2022-12-27",
                    "FECHA OPERACION": "2023-01-05",
                    "NOMBRES": "Daniel Alexander",
                    "APELLIDOS": "Munoz Triana",
                    "NOMBRES COMPLETOS": "Daniel Alexander Munoz Triana",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Palmira",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnicos",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Anderson Cubillos Ayala",
                    "CODIGO USUARIO": "muno804534",
                    "USUARIOFIVE9": "muno804534@siigo.com",
                    "USUARIO RED": "muno804534",
                    "CORREOELECTRONICO": "muno804534@siigo.com",
                    "STATION ID": "3690312",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Anderson Cubillos Ayala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010213493",
                    "FECHA INICIAL": "2023-01-04",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Jonathan Alfonso",
                    "APELLIDOS": "Estrada Vega",
                    "NOMBRES COMPLETOS": "Jonathan Alfonso Estrada Vega",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Angel Alfonso Arciniegas Guerrero",
                    "CODIGO USUARIO": "estr804569",
                    "USUARIOFIVE9": "estr804569@siigo.com",
                    "USUARIO RED": "estr804569",
                    "CORREOELECTRONICO": "estr804569@siigo.com",
                    "STATION ID": "2606487",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Angel Alfonso Arciniegas Guerrero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000186068",
                    "FECHA INICIAL": "2023-01-04",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Katerin Alejandra",
                    "APELLIDOS": "Gomez Valero",
                    "NOMBRES COMPLETOS": "Katerin Alejandra Gomez Valero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Jazbleidy Andrea Nunez Montoya",
                    "CODIGO USUARIO": "gome804570",
                    "USUARIOFIVE9": "gome804570@siigo.com",
                    "USUARIO RED": "gome804570",
                    "CORREOELECTRONICO": "gome804570@siigo.com",
                    "STATION ID": "2606444",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jazbleidy Andrea Nunez Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010230185",
                    "FECHA INICIAL": "2023-01-04",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Angy Marcela",
                    "APELLIDOS": "Celi Garcia",
                    "NOMBRES COMPLETOS": "Angy Marcela Celi Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Walter Antonio Munoz Antepara",
                    "CODIGO USUARIO": "celi804572",
                    "USUARIOFIVE9": "celi804572@siigo.com",
                    "USUARIO RED": "celi804572",
                    "CORREOELECTRONICO": "celi804572@siigo.com",
                    "STATION ID": "2606563",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Walter Antonio Munoz Antepara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024514451",
                    "FECHA INICIAL": "2023-01-04",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Mairon Fabian",
                    "APELLIDOS": "Quitian Figueroa",
                    "NOMBRES COMPLETOS": "Mairon Fabian Quitian Figueroa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juliana Castro Diaz",
                    "CODIGO USUARIO": "quit804575",
                    "USUARIOFIVE9": "quit804575@siigo.com",
                    "USUARIO RED": "quit804575",
                    "CORREOELECTRONICO": "quit804575@siigo.com",
                    "STATION ID": "2606578",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juliana Castro Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "57467331",
                    "FECHA INICIAL": "2023-01-04",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Maritza",
                    "APELLIDOS": "Vargas Pabon",
                    "NOMBRES COMPLETOS": "Maritza Vargas Pabon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Pie de Cuesta",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Angel Alfonso Arciniegas Guerrero",
                    "CODIGO USUARIO": "varg804577",
                    "USUARIOFIVE9": "varg804577@siigo.com",
                    "USUARIO RED": "varg804577",
                    "CORREOELECTRONICO": "varg804577@siigo.com",
                    "STATION ID": "2606698",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Angel Alfonso Arciniegas Guerrero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52915203",
                    "FECHA INICIAL": "2023-01-04",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Derly Dayan",
                    "APELLIDOS": "Alvarez Castaneda",
                    "NOMBRES COMPLETOS": "Derly Dayan Alvarez Castaneda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "William Andres Reyes Silva",
                    "CODIGO USUARIO": "alva804580",
                    "USUARIOFIVE9": "alva804580@siigo.com",
                    "USUARIO RED": "alva804580",
                    "CORREOELECTRONICO": "alva804580@siigo.com",
                    "STATION ID": "2606735",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_William Andres Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1104704137",
                    "FECHA INICIAL": "2022-10-27",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Alexander",
                    "APELLIDOS": "Duque Jimenez",
                    "NOMBRES COMPLETOS": "Alexander Duque Jimenez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Lina Maria Buitrago Herrera",
                    "CODIGO USUARIO": "duqu804029",
                    "USUARIOFIVE9": "duqu804029@siigo.com",
                    "USUARIO RED": "duqu804029",
                    "CORREOELECTRONICO": "duqu804029@siigo.com",
                    "STATION ID": "2606745",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Lina Maria Buitrago Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022415870",
                    "FECHA INICIAL": "2022-10-27",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Wendy Yurany",
                    "APELLIDOS": "Reina Ricardo",
                    "NOMBRES COMPLETOS": "Wendy Yurany Reina Ricardo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Lina Maria Buitrago Herrera",
                    "CODIGO USUARIO": "rein804030",
                    "USUARIOFIVE9": "rein804030@siigo.com",
                    "USUARIO RED": "rein804030",
                    "CORREOELECTRONICO": "rein804030@siigo.com",
                    "STATION ID": "2606754",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Lina Maria Buitrago Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52394382",
                    "FECHA INICIAL": "2022-10-29",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Adelia Maria",
                    "APELLIDOS": "Malagon Moreno",
                    "NOMBRES COMPLETOS": "Adelia Maria Malagon Moreno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Ximena Manrique Isaziga",
                    "CODIGO USUARIO": "mala804183",
                    "USUARIOFIVE9": "mala804183@siigo.com",
                    "USUARIO RED": "mala804183",
                    "CORREOELECTRONICO": "mala804183@siigo.com",
                    "STATION ID": "2606782",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Ximena Manrique Isaziga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1232888049",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Angie Vanessa",
                    "APELLIDOS": "Ortiz Contreras",
                    "NOMBRES COMPLETOS": "Angie Vanessa Ortiz Contreras",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Yuleidy Josefina Briceno Fernandez",
                    "CODIGO USUARIO": "orti804185",
                    "USUARIOFIVE9": "orti804185@siigo.com",
                    "USUARIO RED": "orti804185",
                    "CORREOELECTRONICO": "orti804185@siigo.com",
                    "STATION ID": "2606789",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Yuleidy Josefina Briceno Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010133632",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Danna Valentina",
                    "APELLIDOS": "Vergara Marsiglia",
                    "NOMBRES COMPLETOS": "Danna Valentina Vergara Marsiglia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Paula Alejandra Garcia Lujan",
                    "CODIGO USUARIO": "Verg804177",
                    "USUARIOFIVE9": "Verg804177@siigo.com",
                    "USUARIO RED": "Verg804177",
                    "CORREOELECTRONICO": "Verg804177@siigo.com",
                    "STATION ID": "2606792",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Paula Alejandra Garcia Lujan",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80187543",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Eduardo Andres",
                    "APELLIDOS": "Bonilla Tabare",
                    "NOMBRES COMPLETOS": "Eduardo Andres Bonilla Tabare",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Yuleidy Josefina Briceno Fernandez",
                    "CODIGO USUARIO": "boni804179",
                    "USUARIOFIVE9": "boni804179@siigo.com",
                    "USUARIO RED": "boni804179",
                    "CORREOELECTRONICO": "boni804179@siigo.com",
                    "STATION ID": "2606796",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Yuleidy Josefina Briceno Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80218233",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Edwin",
                    "APELLIDOS": "Rene Montana",
                    "NOMBRES COMPLETOS": "Edwin Rene Montana",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cajica",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Paula Alejandra Garcia Lujan",
                    "CODIGO USUARIO": "rene804192",
                    "USUARIOFIVE9": "rene804192@siigo.com",
                    "USUARIO RED": "rene804192",
                    "CORREOELECTRONICO": "rene804192@siigo.com",
                    "STATION ID": "2606837",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Paula Alejandra Garcia Lujan",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015435022",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "James Smit Lewis",
                    "APELLIDOS": "Yepez Martinez",
                    "NOMBRES COMPLETOS": "James Smit Lewis Yepez Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Silvia Zilah Fernandez Almeida",
                    "CODIGO USUARIO": "yepe804184",
                    "USUARIOFIVE9": "yepe804184@siigo.com",
                    "USUARIO RED": "yepe804184",
                    "CORREOELECTRONICO": "yepe804184@siigo.com",
                    "STATION ID": "2606855",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Silvia Zilah Fernandez Almeida",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "3667457",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Juana Yris",
                    "APELLIDOS": "Bonifertty Amaya",
                    "NOMBRES COMPLETOS": "Juana Yris Bonifertty Amaya",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Yuleidy Josefina Briceno Fernandez",
                    "CODIGO USUARIO": "boni804180",
                    "USUARIOFIVE9": "boni804180@siigo.com",
                    "USUARIO RED": "boni804180",
                    "CORREOELECTRONICO": "boni804180@siigo.com",
                    "STATION ID": "2606870",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Yuleidy Josefina Briceno Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030621562",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Karol Dallana",
                    "APELLIDOS": "Perez Charry",
                    "NOMBRES COMPLETOS": "Karol Dallana Perez Charry",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Ximena Manrique Isaziga",
                    "CODIGO USUARIO": "pere804191",
                    "USUARIOFIVE9": "pere804191@siigo.com",
                    "USUARIO RED": "pere804191",
                    "CORREOELECTRONICO": "pere804191@siigo.com",
                    "STATION ID": "2606891",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Ximena Manrique Isaziga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014270186",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Katherinne Vanessa",
                    "APELLIDOS": "Cantor Ceballos",
                    "NOMBRES COMPLETOS": "Katherinne Vanessa Cantor Ceballos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Yuleidy Josefina Briceno Fernandez",
                    "CODIGO USUARIO": "cant804195",
                    "USUARIOFIVE9": "cant804195@siigo.com",
                    "USUARIO RED": "cant804195",
                    "CORREOELECTRONICO": "cant804195@siigo.com",
                    "STATION ID": "2606897",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Yuleidy Josefina Briceno Fernandez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019055476",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Laura Nataly",
                    "APELLIDOS": "Montano Suarez",
                    "NOMBRES COMPLETOS": "Laura Nataly Montano Suarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Ximena Manrique Isaziga",
                    "CODIGO USUARIO": "mont804175",
                    "USUARIOFIVE9": "mont804175@siigo.com",
                    "USUARIO RED": "mont804175",
                    "CORREOELECTRONICO": "mont804175@siigo.com",
                    "STATION ID": "2606909",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Ximena Manrique Isaziga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "55249585",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Lineys Patricia",
                    "APELLIDOS": "Esquea De Lavalle",
                    "NOMBRES COMPLETOS": "Lineys Patricia Esquea De Lavalle",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Sabanagrande",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Aleida Bibiana Garcia Suarez",
                    "CODIGO USUARIO": "esqu804186",
                    "USUARIOFIVE9": "esqu804186@siigo.com",
                    "USUARIO RED": "esqu804186",
                    "CORREOELECTRONICO": "esqu804186@siigo.com",
                    "STATION ID": "2606953",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Aleida Bibiana Garcia Suarez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1069466609",
                    "FECHA INICIAL": "2023-03-01",
                    "FECHA OPERACION": "2023-01-12",
                    "NOMBRES": "Lilia Lucia",
                    "APELLIDOS": "Prado Martinez",
                    "NOMBRES COMPLETOS": "Lilia Lucia Prado Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Divulgacion",
                    "CARGO": "Divulgacion",
                    "JEFE INMEDIATO": "Jose Daniel Huerfano Martinez",
                    "CODIGO USUARIO": "prad900546",
                    "USUARIOFIVE9": "prad900546@siigo.com",
                    "USUARIO RED": "prad900546",
                    "CORREOELECTRONICO": "prad900546@siigo.com",
                    "STATION ID": "2606995",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jose Daniel Huerfano Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "38288529",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Maria Cecilia",
                    "APELLIDOS": "Ovalle Mejia",
                    "NOMBRES COMPLETOS": "Maria Cecilia Ovalle Mejia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Ximena Manrique Isaziga",
                    "CODIGO USUARIO": "oval804176",
                    "USUARIOFIVE9": "oval804176@siigo.com",
                    "USUARIO RED": "oval804176",
                    "CORREOELECTRONICO": "oval804176@siigo.com",
                    "STATION ID": "2606965",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Ximena Manrique Isaziga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1079410260",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Oscar Julian",
                    "APELLIDOS": "Medina Perez",
                    "NOMBRES COMPLETOS": "Oscar Julian Medina Perez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Liliana Andrea Segura Martinez",
                    "CODIGO USUARIO": "medi804194",
                    "USUARIOFIVE9": "medi804194@siigo.com",
                    "USUARIO RED": "medi804194",
                    "CORREOELECTRONICO": "medi804194@siigo.com",
                    "STATION ID": "2607010",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Andrea Segura Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "KUYC850818NY1",
                    "FECHA INICIAL": "2023-03-01",
                    "FECHA OPERACION": "2023-01-12",
                    "NOMBRES": "Carmen del Sagrario",
                    "APELLIDOS": "Ku Yah",
                    "NOMBRES COMPLETOS": "Carmen del Sagrario Ku Yah",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Analista Funcional",
                    "JEFE INMEDIATO": "Maria Jose Vargas Braga",
                    "CODIGO USUARIO": "cku",
                    "USUARIOFIVE9": "cku@aspel.com.mx",
                    "USUARIO RED": "cku",
                    "CORREOELECTRONICO": "cku@aspel.com.mx",
                    "STATION ID": "2606997",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Maria Jose Vargas Braga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019056187",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Suleith Yulieth",
                    "APELLIDOS": "Piragauta Amado",
                    "NOMBRES COMPLETOS": "Suleith Yulieth Piragauta Amado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Aleida Bibiana Garcia Suarez",
                    "CODIGO USUARIO": "pira804181",
                    "USUARIOFIVE9": "pira804181@siigo.com",
                    "USUARIO RED": "pira804181",
                    "CORREOELECTRONICO": "pira804181@siigo.com",
                    "STATION ID": "2607029",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Aleida Bibiana Garcia Suarez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007631349",
                    "FECHA INICIAL": "2022-12-27",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Andres Estiven",
                    "APELLIDOS": "Gonzalez Moreno",
                    "NOMBRES COMPLETOS": "Andres Estiven Gonzalez Moreno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guateque",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "gonz804543",
                    "USUARIOFIVE9": "gonz804543@siigo.com",
                    "USUARIO RED": "gonz804543",
                    "CORREOELECTRONICO": "gonz804543@siigo.com",
                    "STATION ID": "2606046",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1152215726",
                    "FECHA INICIAL": "2022-12-27",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Anyela Patricia",
                    "APELLIDOS": "Basto Zambrano",
                    "NOMBRES COMPLETOS": "Anyela Patricia Basto Zambrano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "bast804519",
                    "USUARIOFIVE9": "bast804519@siigo.com",
                    "USUARIO RED": "bast804519",
                    "CORREOELECTRONICO": "bast804519@siigo.com",
                    "STATION ID": "2606051",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1234890541",
                    "FECHA INICIAL": "2022-12-27",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Estefany Carolina",
                    "APELLIDOS": "Sanchez Vesga",
                    "NOMBRES COMPLETOS": "Estefany Carolina Sanchez Vesga",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Soledad",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "sanc804547",
                    "USUARIOFIVE9": "sanc804547@siigo.com",
                    "USUARIO RED": "sanc804547",
                    "CORREOELECTRONICO": "sanc804547@siigo.com",
                    "STATION ID": "2606961",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1110541265",
                    "FECHA INICIAL": "2022-12-27",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Jhonny Estefan",
                    "APELLIDOS": "Moreno Alvarez",
                    "NOMBRES COMPLETOS": "Jhonny Estefan Moreno Alvarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "more804549",
                    "USUARIOFIVE9": "more804549@siigo.com",
                    "USUARIO RED": "more804549",
                    "CORREOELECTRONICO": "more804549@siigo.com",
                    "STATION ID": "2606880",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1004382575",
                    "FECHA INICIAL": "2022-12-27",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Johan Esteban",
                    "APELLIDOS": "Arevalo Carrascal",
                    "NOMBRES COMPLETOS": "Johan Esteban Arevalo Carrascal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Aguachica",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "arev804522",
                    "USUARIOFIVE9": "arev804522@siigo.com",
                    "USUARIO RED": "arev804522",
                    "CORREOELECTRONICO": "arev804522@siigo.com",
                    "STATION ID": "2606308",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1214743360",
                    "FECHA INICIAL": "2022-12-27",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Johanna Carolina",
                    "APELLIDOS": "Moreno Romero",
                    "NOMBRES COMPLETOS": "Johanna Carolina Moreno Romero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Sincelejo",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jose Luis Perales Duenas",
                    "CODIGO USUARIO": "more804545",
                    "USUARIOFIVE9": "more804545@siigo.com",
                    "USUARIO RED": "more804545",
                    "CORREOELECTRONICO": "more804545@siigo.com",
                    "STATION ID": "2606310",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jose Luis Perales Duenas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007379522",
                    "FECHA INICIAL": "2022-12-27",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Laura Sofia",
                    "APELLIDOS": "Sanabria Rojas",
                    "NOMBRES COMPLETOS": "Laura Sofia Sanabria Rojas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Duitama",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "sana804548",
                    "USUARIOFIVE9": "sana804548@siigo.com",
                    "USUARIO RED": "sana804548",
                    "CORREOELECTRONICO": "sana804548@siigo.com",
                    "STATION ID": "2607100",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001995398",
                    "FECHA INICIAL": "2022-12-27",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Oscar David",
                    "APELLIDOS": "Alvarez Niebles",
                    "NOMBRES COMPLETOS": "Oscar David Alvarez Niebles",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Soledad",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "alva804531",
                    "USUARIOFIVE9": "alva804531@siigo.com",
                    "USUARIO RED": "alva804531",
                    "CORREOELECTRONICO": "alva804531@siigo.com",
                    "STATION ID": "3127488",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001852075",
                    "FECHA INICIAL": "2022-12-27",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "William Gabriel",
                    "APELLIDOS": "Zambrano Briceno",
                    "NOMBRES COMPLETOS": "William Gabriel Zambrano Briceno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Soledad",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "zamb804532",
                    "USUARIOFIVE9": "zamb804532@siigo.com",
                    "USUARIO RED": "zamb804532",
                    "CORREOELECTRONICO": "zamb804532@siigo.com",
                    "STATION ID": "3127509",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024547074",
                    "FECHA INICIAL": "2022-11-29",
                    "FECHA OPERACION": "2023-01-13",
                    "NOMBRES": "Eider Steven",
                    "APELLIDOS": "Rodriguez Barragan",
                    "NOMBRES COMPLETOS": "Eider Steven Rodriguez Barragan",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Liliana Andrea Segura Martinez",
                    "CODIGO USUARIO": "rodr804187",
                    "USUARIOFIVE9": "rodr804187@siigo.com",
                    "USUARIO RED": "rodr804187",
                    "CORREOELECTRONICO": "rodr804187@siigo.com",
                    "STATION ID": "3471494",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Andrea Segura Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "55575313",
                    "FECHA INICIAL": "2023-01-04",
                    "FECHA OPERACION": "2023-01-16",
                    "NOMBRES": "Micaela Lourdes",
                    "APELLIDOS": "Modernell Moliterno",
                    "NOMBRES COMPLETOS": "Micaela Lourdes Modernell Moliterno",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Recepcionista",
                    "JEFE INMEDIATO": "Giselda Keymetlian",
                    "CODIGO USUARIO": "mode804574",
                    "USUARIOFIVE9": "mode804574@siigo.com",
                    "USUARIO RED": "mode804574",
                    "CORREOELECTRONICO": "mode804574@siigo.com",
                    "STATION ID": "3471510",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Giselda Keymetlian",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000035627",
                    "FECHA INICIAL": "2023-01-04",
                    "FECHA OPERACION": "2023-01-16",
                    "NOMBRES": "Darlin Dayana",
                    "APELLIDOS": "Urrea Ceballos",
                    "NOMBRES COMPLETOS": "Darlin Dayana Urrea Ceballos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Fundacion",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "urre804573",
                    "USUARIOFIVE9": "urre804573@siigo.com",
                    "USUARIO RED": "urre804573",
                    "CORREOELECTRONICO": "urre804573@siigo.com",
                    "STATION ID": "3483689",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "LOPI880818AB8",
                    "FECHA INICIAL": "2022-05-10",
                    "FECHA OPERACION": "2022-10-29",
                    "NOMBRES": "Irma Beatriz",
                    "APELLIDOS": "Lopez Pena",
                    "NOMBRES COMPLETOS": "Irma Beatriz Lopez Pena",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Call MX",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Maria Magadalena Mendez Romero",
                    "CODIGO USUARIO": "ilopezp",
                    "USUARIOFIVE9": "ilopezp@aspel.com.mx",
                    "USUARIO RED": "ilopezp",
                    "CORREOELECTRONICO": "ilopezp@aspel.com.mx",
                    "STATION ID": "3956771",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Maria Magadalena Mendez Romero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "HEMM7503213UA",
                    "FECHA INICIAL": "2022-04-28",
                    "FECHA OPERACION": "2022-04-28",
                    "NOMBRES": "Marcela Vanessa",
                    "APELLIDOS": "Hernandez Moreno",
                    "NOMBRES COMPLETOS": "Marcela Vanessa Hernandez Moreno",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Mexico",
                    "CARGO": "Gerente de Operaciones",
                    "JEFE INMEDIATO": "Carlos Huertas",
                    "CODIGO USUARIO": "vhernandez",
                    "USUARIOFIVE9": "vhernandez@aspel.com.mx",
                    "USUARIO RED": "vhernandez",
                    "CORREOELECTRONICO": "vhernandez@aspel.com.mx",
                    "STATION ID": "3857177",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Huertas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032071178",
                    "FECHA INICIAL": "2023-10-27",
                    "FECHA OPERACION": "2023-01-19",
                    "NOMBRES": "Gil Agudelo",
                    "APELLIDOS": "Valery",
                    "NOMBRES COMPLETOS": "Valery Gil Agudelo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Divulgacion",
                    "CARGO": "Divulgacion",
                    "JEFE INMEDIATO": "Jose Daniel Huerfano Martinez",
                    "CODIGO USUARIO": "gila804026",
                    "USUARIOFIVE9": "gila804026@siigo.com",
                    "USUARIO RED": "gila804026",
                    "CORREOELECTRONICO": "gila804026@siigo.com",
                    "STATION ID": "3127597",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jose Daniel Huerfano Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022349631",
                    "FECHA INICIAL": "2023-01-04",
                    "FECHA OPERACION": "2023-01-25",
                    "NOMBRES": "Andres Fabian",
                    "APELLIDOS": "Gutierrez Gutierrez",
                    "NOMBRES COMPLETOS": "Andres Fabian Gutierrez Gutierrez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Experience Manager",
                    "JEFE INMEDIATO": "John Alexander Benavides Cobos",
                    "CODIGO USUARIO": "guti804564",
                    "USUARIOFIVE9": "guti804564@siigo.com",
                    "USUARIO RED": "guti804564",
                    "CORREOELECTRONICO": "guti804564@siigo.com",
                    "STATION ID": "3471490",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_John Alexander Benavides Cobos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MERM800722HY8",
                    "FECHA INICIAL": "2022-04-27",
                    "FECHA OPERACION": "2022-04-27",
                    "NOMBRES": "Maria Magdalena",
                    "APELLIDOS": "Mendez Romero",
                    "NOMBRES COMPLETOS": "Maria Magdalena Mendez Romero",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Mexico",
                    "CARGO": "Director Customer",
                    "JEFE INMEDIATO": "William Jesus Sotaquira Ayala",
                    "CODIGO USUARIO": "mmendez",
                    "USUARIOFIVE9": "mmendez@aspel.com.mx",
                    "USUARIO RED": "mmendez",
                    "CORREOELECTRONICO": "mmendez@aspel.com.mx",
                    "STATION ID": "3857145",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_William Jesus Sotaquira Ayala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000992904",
                    "FECHA INICIAL": "2022-10-27",
                    "FECHA OPERACION": "2022-12-20",
                    "NOMBRES": "Yiseth Nayibe",
                    "APELLIDOS": "Melo Forero",
                    "NOMBRES COMPLETOS": "Yiseth Nayibe Melo Forero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ecuador",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Edith Angelica Gil Umbarila",
                    "CODIGO USUARIO": "melo804010",
                    "USUARIOFIVE9": "melo804010@siigo.com",
                    "USUARIO RED": "melo804010",
                    "CORREOELECTRONICO": "melo804010@siigo.com",
                    "STATION ID": "4421711",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Edith Angelica Gil Umbarila",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1027948290",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-02-10",
                    "NOMBRES": "Manuela",
                    "APELLIDOS": "Mendoza Sanchez",
                    "NOMBRES COMPLETOS": "Manuela Mendoza Sanchez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Apartado",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones ECU",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Angie Camila Diaz Escobar",
                    "CODIGO USUARIO": "mend804597",
                    "USUARIOFIVE9": "mend804597@siigo.com",
                    "USUARIO RED": "mend804597",
                    "CORREOELECTRONICO": "mend804597@siigo.com",
                    "STATION ID": "2606092",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Camila Diaz Escobar",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1020771075",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-02-16",
                    "NOMBRES": "Laura",
                    "APELLIDOS": "Caro Olarte",
                    "NOMBRES COMPLETOS": "Laura Caro Olarte",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Misael Martinez Montero",
                    "CODIGO USUARIO": "olart804592",
                    "USUARIOFIVE9": "olart804592@siigo.com",
                    "USUARIO RED": "olart804592",
                    "CORREOELECTRONICO": "olart804592@siigo.com",
                    "STATION ID": "2606402",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Misael Martinez Montero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1083876689",
                    "FECHA INICIAL": "2023-02-08",
                    "FECHA OPERACION": "2023-02-16",
                    "NOMBRES": "Lida Marcela",
                    "APELLIDOS": "Quintero Buitrago",
                    "NOMBRES COMPLETOS": "Lida Marcela Quintero Buitrago",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Funza",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Sonia Mabel Fernandez Calderon",
                    "CODIGO USUARIO": "quin804640",
                    "USUARIOFIVE9": "quin804640@siigo.com",
                    "USUARIO RED": "quin804640",
                    "CORREOELECTRONICO": "quin804640@siigo.com",
                    "STATION ID": "2606504",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sonia Mabel Fernandez Calderon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1002998175",
                    "FECHA INICIAL": "2023-02-08",
                    "FECHA OPERACION": "2023-02-16",
                    "NOMBRES": "Liseth Paola",
                    "APELLIDOS": "Rosso Arrieta",
                    "NOMBRES COMPLETOS": "Liseth Paola Rosso Arrieta",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "ross804636",
                    "USUARIOFIVE9": "ross804636@siigo.com",
                    "USUARIO RED": "ross804636",
                    "CORREOELECTRONICO": "ross804636@siigo.com",
                    "STATION ID": "2606510",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1003066332",
                    "FECHA INICIAL": "2023-02-08",
                    "FECHA OPERACION": "2023-02-16",
                    "NOMBRES": "Erika Paola",
                    "APELLIDOS": "Garces Gonzalez",
                    "NOMBRES COMPLETOS": "Erika Paola Garces Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Andrea Catalina Vega Gomez",
                    "CODIGO USUARIO": "garc804635",
                    "USUARIOFIVE9": "garc804635@siigo.com",
                    "USUARIO RED": "garc804635",
                    "CORREOELECTRONICO": "garc804635@siigo.com",
                    "STATION ID": "2606533",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andrea Catalina Vega Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1234889850",
                    "FECHA INICIAL": "2022-12-27",
                    "FECHA OPERACION": "2023-01-10",
                    "NOMBRES": "Jorsany Patricia",
                    "APELLIDOS": "Vanegas Fontalvo",
                    "NOMBRES COMPLETOS": "Jorsany Patricia Vanegas Fontalvo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "Chat Ecuador",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Alexandra Geraldine Sanchez Paiva",
                    "CODIGO USUARIO": "vane804539",
                    "USUARIOFIVE9": "vane804539@siigo.com",
                    "USUARIO RED": "vane804539",
                    "CORREOELECTRONICO": "vane804539@siigo.com",
                    "STATION ID": "2606372",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Alexandra Geraldine Sanchez Paiva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1006149668",
                    "FECHA INICIAL": "2023-08-01",
                    "FECHA OPERACION": "2023-02-22",
                    "NOMBRES": "Faiber",
                    "APELLIDOS": "Leyton Ducuara",
                    "NOMBRES COMPLETOS": "Faiber Leyton Ducuara",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Elizabeth Giron Parra",
                    "CODIGO USUARIO": "leyt804593",
                    "USUARIOFIVE9": "leyt804593@siigo.com",
                    "USUARIO RED": "leyt804593",
                    "CORREOELECTRONICO": "leyt804593@siigo.com",
                    "STATION ID": "2606613",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Elizabeth Giron Parra",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033706501",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-02-22",
                    "NOMBRES": "Jessika Katherine",
                    "APELLIDOS": "Roa",
                    "NOMBRES COMPLETOS": "Jessika Katherine Roa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Leidy Stefany Castillo",
                    "CODIGO USUARIO": "roaj804614",
                    "USUARIOFIVE9": "roaj804614@siigo.com",
                    "USUARIO RED": "roaj804614",
                    "CORREOELECTRONICO": "roaj804614@siigo.com",
                    "STATION ID": "2606651",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Leidy Stefany Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1144073517",
                    "FECHA INICIAL": "2023-02-08",
                    "FECHA OPERACION": "2023-02-22",
                    "NOMBRES": "Erika Alejandra",
                    "APELLIDOS": "Arteaga Cabrera",
                    "NOMBRES COMPLETOS": "Erika Alejandra Arteaga Cabrera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Putumayo",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Mercadeo",
                    "CARGO": "Analista Mercadeo",
                    "JEFE INMEDIATO": "Maria Paula Garzon Ortega",
                    "CODIGO USUARIO": "arte804634",
                    "USUARIOFIVE9": "arte804634@siigo.com",
                    "USUARIO RED": "arte804634",
                    "CORREOELECTRONICO": "arte804634@siigo.com",
                    "STATION ID": "2606708",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Maria Paula Garzon Ortega",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1205550997",
                    "FECHA INICIAL": "2020-02-27",
                    "FECHA OPERACION": "2023-02-22",
                    "NOMBRES": "Dariany Yelena",
                    "APELLIDOS": "Pincay Coronel",
                    "NOMBRES COMPLETOS": "Dariany Yelena Pincay Coronel",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Guayaquil",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "pinc800569",
                    "USUARIOFIVE9": "pinc800569@siigo.com",
                    "USUARIO RED": "pinc800569",
                    "CORREOELECTRONICO": "pinc800569@siigo.com",
                    "STATION ID": "4421822",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001362110",
                    "FECHA INICIAL": "2023-02-15",
                    "FECHA OPERACION": "2023-02-23",
                    "NOMBRES": "Yesdy Valentina",
                    "APELLIDOS": "Chica Agudelo",
                    "NOMBRES COMPLETOS": "Yesdy Valentina Chica Agudelo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "chic804646",
                    "USUARIOFIVE9": "chic804646@siigo.com",
                    "USUARIO RED": "chic804646",
                    "CORREOELECTRONICO": "chic804646@siigo.com",
                    "STATION ID": "4421724",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010247007",
                    "FECHA INICIAL": "2023-02-16",
                    "FECHA OPERACION": "2023-02-23",
                    "NOMBRES": "Michelle Alexandra",
                    "APELLIDOS": "Garcia Morato",
                    "NOMBRES COMPLETOS": "Michelle Alexandra Garcia Morato",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "garc804647",
                    "USUARIOFIVE9": "garc804647@siigo.com",
                    "USUARIO RED": "garc804647",
                    "CORREOELECTRONICO": "garc804647@siigo.com",
                    "STATION ID": "4421751",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007688728",
                    "FECHA INICIAL": "2023-02-16",
                    "FECHA OPERACION": "2023-02-23",
                    "NOMBRES": "Mary",
                    "APELLIDOS": "Luna Farfan",
                    "NOMBRES COMPLETOS": "Mary Luna Farfan",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "luna804648",
                    "USUARIOFIVE9": "luna804648@siigo.com",
                    "USUARIO RED": "luna804648",
                    "CORREOELECTRONICO": "luna804648@siigo.com",
                    "STATION ID": "4421762",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1031152071",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Ana Maria",
                    "APELLIDOS": "Tamayo Villarroel",
                    "NOMBRES COMPLETOS": "Ana Maria Tamayo Villarroel",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Nataly Carolina Gonzalez Sandoval",
                    "CODIGO USUARIO": "tama900264",
                    "USUARIOFIVE9": "tama900264@siigo.com",
                    "USUARIO RED": "tama900264",
                    "CORREOELECTRONICO": "tama900264@siigo.com",
                    "STATION ID": "4421775",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Nataly Carolina Gonzalez Sandoval",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1067892031",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Andry Patricia",
                    "APELLIDOS": "Hoyos Perez",
                    "NOMBRES COMPLETOS": "Andry Patricia Hoyos Perez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Michelle Matamoros Del Rosario",
                    "CODIGO USUARIO": "hoyo900482",
                    "USUARIOFIVE9": "hoyo900482@siigo.com",
                    "USUARIO RED": "hoyo900482",
                    "CORREOELECTRONICO": "hoyo900482@siigo.com",
                    "STATION ID": "4421782",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Michelle Matamoros Del Rosario",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022328677",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Diva Fabiola",
                    "APELLIDOS": "Cortes Velasco",
                    "NOMBRES COMPLETOS": "Diva Fabiola Cortes Velasco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Nataly Carolina Gonzalez Sandoval",
                    "CODIGO USUARIO": "cort900396",
                    "USUARIOFIVE9": "cort900396@siigo.com",
                    "USUARIO RED": "cort900396",
                    "CORREOELECTRONICO": "cort900396@siigo.com",
                    "STATION ID": "4421783",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Nataly Carolina Gonzalez Sandoval",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1077974434",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Angie Daniela",
                    "APELLIDOS": "Garzon Bustos",
                    "NOMBRES COMPLETOS": "Angie Daniela Garzon Bustos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Facatativa",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jose Manuel Aguayo Escandon",
                    "CODIGO USUARIO": "garz900473",
                    "USUARIOFIVE9": "garz900473@siigo.com",
                    "USUARIO RED": "garz900473",
                    "CORREOELECTRONICO": "garz900473@siigo.com",
                    "STATION ID": "4421789",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jose Manuel Aguayo Escandon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024567661",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Brayan Camilo",
                    "APELLIDOS": "Lara Pena",
                    "NOMBRES COMPLETOS": "Brayan Camilo Lara Pena",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jose Manuel Aguayo Escandon",
                    "CODIGO USUARIO": "lara900479",
                    "USUARIOFIVE9": "lara900479@siigo.com",
                    "USUARIO RED": "lara900479",
                    "CORREOELECTRONICO": "lara900479@siigo.com",
                    "STATION ID": "4421793",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jose Manuel Aguayo Escandon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019057276",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Cristian Camilo",
                    "APELLIDOS": "Lasso Gamboa",
                    "NOMBRES COMPLETOS": "Cristian Camilo Lasso Gamboa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Chia",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juliana Castro Diaz",
                    "CODIGO USUARIO": "lass900459",
                    "USUARIOFIVE9": "lass900459@siigo.com",
                    "USUARIO RED": "lass900459",
                    "CORREOELECTRONICO": "lass900459@siigo.com",
                    "STATION ID": "4421799",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juliana Castro Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52975958",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Diana Carolina",
                    "APELLIDOS": "Rodriguez Sarmiento",
                    "NOMBRES COMPLETOS": "Diana Carolina Rodriguez Sarmiento",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jose Manuel Aguayo Escandon",
                    "CODIGO USUARIO": "rodr900469",
                    "USUARIOFIVE9": "rodr900469@siigo.com",
                    "USUARIO RED": "rodr900469",
                    "CORREOELECTRONICO": "rodr900469@siigo.com",
                    "STATION ID": "4421803",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jose Manuel Aguayo Escandon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014176946",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Henry Alberto",
                    "APELLIDOS": "Martinez Ladino",
                    "NOMBRES COMPLETOS": "Henry Alberto Martinez Ladino",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juliana Castro Diaz",
                    "CODIGO USUARIO": "mart900463",
                    "USUARIOFIVE9": "mart900463@siigo.com",
                    "USUARIO RED": "mart900463",
                    "CORREOELECTRONICO": "mart900463@siigo.com",
                    "STATION ID": "4421809",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "comercial_Juliana Castro Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "22443476",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Gloria Sofia",
                    "APELLIDOS": "Silva Lizarazo",
                    "NOMBRES COMPLETOS": "Gloria Sofia Silva Lizarazo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Andrea Catalina Vega Gomez",
                    "CODIGO USUARIO": "silv900465",
                    "USUARIOFIVE9": "silv900465@siigo.com",
                    "USUARIO RED": "silv900465",
                    "CORREOELECTRONICO": "silv900465@siigo.com",
                    "STATION ID": "4421811",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andrea Catalina Vega Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "954949772",
                    "FECHA INICIAL": "2023-02-23",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Melissa Elizabeth",
                    "APELLIDOS": "Ortiz Caiza",
                    "NOMBRES COMPLETOS": "Melissa Elizabeth Ortiz Caiza",
                    "PAIS ORIGEN": "Ecuador",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Quito",
                    "AREA": "Customer",
                    "SUBAREA": "Firma Electronica",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Estefany Rocio Munoz Rojas",
                    "CODIGO USUARIO": "orti900493",
                    "USUARIOFIVE9": "orti900493@siigo.com",
                    "USUARIO RED": "orti900493",
                    "CORREOELECTRONICO": "orti900493@siigo.com",
                    "STATION ID": "3895100",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Estefany Rocio Munoz Rojas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1049606251",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Merley Liliana",
                    "APELLIDOS": "Saavedra Mancipe",
                    "NOMBRES COMPLETOS": "Merley Liliana Saavedra Mancipe",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leidy Marcela Rodriguez Rivera",
                    "CODIGO USUARIO": "saav900467",
                    "USUARIOFIVE9": "saav900467@siigo.com",
                    "USUARIO RED": "saav900467",
                    "CORREOELECTRONICO": "saav900467@siigo.com",
                    "STATION ID": "3895101",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leidy Marcela Rodriguez Rivera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "87064467",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Pablo Orlando",
                    "APELLIDOS": "Eraso Arteaga",
                    "NOMBRES COMPLETOS": "Pablo Orlando Eraso Arteaga",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Nataly Carolina Gonzalez Sandoval",
                    "CODIGO USUARIO": "eras900348",
                    "USUARIOFIVE9": "eras900348@siigo.com",
                    "USUARIO RED": "eras900348",
                    "CORREOELECTRONICO": "eras900348@siigo.com",
                    "STATION ID": "3895102",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Nataly Carolina Gonzalez Sandoval",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80075688",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Richard Eduardo",
                    "APELLIDOS": "Ferrer Muller",
                    "NOMBRES COMPLETOS": "Richard Eduardo Ferrer Muller",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jefferson Arvey Ramirez Ibanez",
                    "CODIGO USUARIO": "ferr900461",
                    "USUARIOFIVE9": "ferr900461@siigo.com",
                    "USUARIO RED": "ferr900461",
                    "CORREOELECTRONICO": "ferr900461@siigo.com",
                    "STATION ID": "3895103",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jefferson Arvey Ramirez Ibanez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030524629",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Sara Yalile",
                    "APELLIDOS": "Pineda Albarracin",
                    "NOMBRES COMPLETOS": "Sara Yalile Pineda Albarracin",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alison Anaid Chica Ezeta",
                    "CODIGO USUARIO": "pine900336",
                    "USUARIOFIVE9": "pine900336@siigo.com",
                    "USUARIO RED": "pine900336",
                    "CORREOELECTRONICO": "pine900336@siigo.com",
                    "STATION ID": "3895104",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Alison Anaid Chica Ezeta",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030679232",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Sergio Sneyder",
                    "APELLIDOS": "Cortes Gil",
                    "NOMBRES COMPLETOS": "Sergio Sneyder Cortes Gil",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Nataly Carolina Gonzalez Sandoval",
                    "CODIGO USUARIO": "cort900354",
                    "USUARIOFIVE9": "cort900354@siigo.com",
                    "USUARIO RED": "cort900354",
                    "CORREOELECTRONICO": "cort900354@siigo.com",
                    "STATION ID": "3895105",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Nataly Carolina Gonzalez Sandoval",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015435598",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Shanon Paola",
                    "APELLIDOS": "Cepeda Chaparro",
                    "NOMBRES COMPLETOS": "Shanon Paola Cepeda Chaparro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Villavicencio",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "David Alfredo Sanchez Bastidas",
                    "CODIGO USUARIO": "cepe900483",
                    "USUARIOFIVE9": "cepe900483@siigo.com",
                    "USUARIO RED": "cepe900483",
                    "CORREOELECTRONICO": "cepe900483@siigo.com",
                    "STATION ID": "3895106",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_David Alfredo Sanchez Bastidas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53072301",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Viviana Andrea",
                    "APELLIDOS": "Pinto Caicedo",
                    "NOMBRES COMPLETOS": "Viviana Andrea Pinto Caicedo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leidy Marcela Rodriguez Rivera",
                    "CODIGO USUARIO": "pint900475",
                    "USUARIOFIVE9": "pint900475@siigo.com",
                    "USUARIO RED": "pint900475",
                    "CORREOELECTRONICO": "pint900475@siigo.com",
                    "STATION ID": "3956653",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leidy Marcela Rodriguez Rivera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "65632738",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Yudy Milena",
                    "APELLIDOS": "Aya Ortiz",
                    "NOMBRES COMPLETOS": "Yudy Milena Aya Ortiz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jefferson Arvey Ramirez Ibanez",
                    "CODIGO USUARIO": "ayao900342",
                    "USUARIOFIVE9": "ayao900342@siigo.com",
                    "USUARIO RED": "ayao900342",
                    "CORREOELECTRONICO": "ayao900342@siigo.com",
                    "STATION ID": "3956654",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jefferson Arvey Ramirez Ibanez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012362471",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Gabriela",
                    "APELLIDOS": "Rico Cardona",
                    "NOMBRES COMPLETOS": "Gabriela Rico Cardona",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Angel Alfonso Arciniegas Guerrero",
                    "CODIGO USUARIO": "rico900389",
                    "USUARIOFIVE9": "rico900389@siigo.com",
                    "USUARIO RED": "rico900389",
                    "CORREOELECTRONICO": "rico900389@siigo.com",
                    "STATION ID": "3956715",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Angel Alfonso Arciniegas Guerrero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032372942",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Ingrid Yorcidia",
                    "APELLIDOS": "Ruiz Cavanzo",
                    "NOMBRES COMPLETOS": "Ingrid Yorcidia Ruiz Cavanzo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Nataly Carolina Gonzalez Sandoval",
                    "CODIGO USUARIO": "ruiz900340",
                    "USUARIOFIVE9": "ruiz900340@siigo.com",
                    "USUARIO RED": "ruiz900340",
                    "CORREOELECTRONICO": "ruiz900340@siigo.com",
                    "STATION ID": "2605993",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Nataly Carolina Gonzalez Sandoval",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014205548",
                    "FECHA INICIAL": "2023-02-27",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Jenifer Constanza",
                    "APELLIDOS": "Gil Gutierrez",
                    "NOMBRES COMPLETOS": "Jenifer Constanza Gil Gutierrez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leidy Marcela Rodriguez Rivera",
                    "CODIGO USUARIO": "gilg900483",
                    "USUARIOFIVE9": "gilg900483@siigo.com",
                    "USUARIO RED": "gilg900483",
                    "CORREOELECTRONICO": "gilg900483@siigo.com",
                    "STATION ID": "2605997",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leidy Marcela Rodriguez Rivera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52890933",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Jennifer",
                    "APELLIDOS": "Restrepo Ortiz",
                    "NOMBRES COMPLETOS": "Jennifer Restrepo Ortiz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leidy Marcela Rodriguez Rivera",
                    "CODIGO USUARIO": "rest900338",
                    "USUARIOFIVE9": "rest900338@siigo.com",
                    "USUARIO RED": "rest900338",
                    "CORREOELECTRONICO": "rest900338@siigo.com",
                    "STATION ID": "2606039",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leidy Marcela Rodriguez Rivera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52243028",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Julieth",
                    "APELLIDOS": "Gonzalez Cruz",
                    "NOMBRES COMPLETOS": "Julieth Gonzalez Cruz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Nataly Carolina Gonzalez Sandoval",
                    "CODIGO USUARIO": "gonz900352",
                    "USUARIOFIVE9": "gonz900352@siigo.com",
                    "USUARIO RED": "gonz900352",
                    "CORREOELECTRONICO": "gonz900352@siigo.com",
                    "STATION ID": "2606065",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Nataly Carolina Gonzalez Sandoval",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1140860868",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Leider Andres",
                    "APELLIDOS": "Agudelo Jimenez",
                    "NOMBRES COMPLETOS": "Leider Andres Agudelo Jimenez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jose Manuel Aguayo Escandon",
                    "CODIGO USUARIO": "agud900350",
                    "USUARIOFIVE9": "agud900350@siigo.com",
                    "USUARIO RED": "agud900350",
                    "CORREOELECTRONICO": "agud900350@siigo.com",
                    "STATION ID": "2606068",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jose Manuel Aguayo Escandon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014253315",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Leidy Marcela",
                    "APELLIDOS": "Penuela Ruiz",
                    "NOMBRES COMPLETOS": "Leidy Marcela Penuela Ruiz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Nataly Carolina Gonzalez Sandoval",
                    "CODIGO USUARIO": "penu900346",
                    "USUARIOFIVE9": "penu900346@siigo.com",
                    "USUARIO RED": "penu900346",
                    "CORREOELECTRONICO": "penu900346@siigo.com",
                    "STATION ID": "2606095",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Nataly Carolina Gonzalez Sandoval",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "53083205",
                    "FECHA INICIAL": "2023-02-23",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Lida Marcela",
                    "APELLIDOS": "Pedraza Aleman",
                    "NOMBRES COMPLETOS": "Lida Marcela Pedraza Aleman",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leidy Marcela Rodriguez Rivera",
                    "CODIGO USUARIO": "pedr900500",
                    "USUARIOFIVE9": "pedr900500@siigo.com",
                    "USUARIO RED": "pedr900500",
                    "CORREOELECTRONICO": "pedr900500@siigo.com",
                    "STATION ID": "2606103",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leidy Marcela Rodriguez Rivera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1075870649",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Luz Angelica",
                    "APELLIDOS": "Galvis Camargo",
                    "NOMBRES COMPLETOS": "Luz Angelica Galvis Camargo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "William Andres Reyes Silva",
                    "CODIGO USUARIO": "galv900477",
                    "USUARIOFIVE9": "galv900477@siigo.com",
                    "USUARIO RED": "galv900477",
                    "CORREOELECTRONICO": "galv900477@siigo.com",
                    "STATION ID": "2606119",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_William Andres Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "28169265",
                    "FECHA INICIAL": "2023-02-22",
                    "FECHA OPERACION": "2023-02-27",
                    "NOMBRES": "Martha Humbelina",
                    "APELLIDOS": "Fajardo Marin",
                    "NOMBRES COMPLETOS": "Martha Humbelina Fajardo Marin",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Andrea Catalina Vega Gomez",
                    "CODIGO USUARIO": "faja900481",
                    "USUARIOFIVE9": "faja900481@siigo.com",
                    "USUARIO RED": "faja900481",
                    "CORREOELECTRONICO": "faja900481@siigo.com",
                    "STATION ID": "2606121",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andrea Catalina Vega Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "42527672",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-02",
                    "NOMBRES": "Leandro Brian",
                    "APELLIDOS": "Barcos Bobadilla",
                    "NOMBRES COMPLETOS": "Leandro Brian Barcos Bobadilla",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Silvia Zilah Fernandez Almeida",
                    "CODIGO USUARIO": "barc804624",
                    "USUARIOFIVE9": "barc804624@siigo.com",
                    "USUARIO RED": "barc804624",
                    "CORREOELECTRONICO": "barc804624@siigo.com",
                    "STATION ID": "2605929",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Silvia Zilah Fernandez Almeida",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "46554247",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-02",
                    "NOMBRES": "Luis Joel",
                    "APELLIDOS": "Pereyra Martinez",
                    "NOMBRES COMPLETOS": "Luis Joel Pereyra Martinez",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Luis Ignacio Ferreira Duarte",
                    "CODIGO USUARIO": "pere804621",
                    "USUARIOFIVE9": "pere804621@siigo.com",
                    "USUARIO RED": "pere804621",
                    "CORREOELECTRONICO": "pere804621@siigo.com",
                    "STATION ID": "2605947",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Luis Ignacio Ferreira Duarte",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "31196973",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-02",
                    "NOMBRES": "Patricia Janneth",
                    "APELLIDOS": "Veloso Blanco",
                    "NOMBRES COMPLETOS": "Patricia Janneth Veloso Blanco",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Luis Ignacio Ferreira Duarte",
                    "CODIGO USUARIO": "velo804623",
                    "USUARIOFIVE9": "velo804623@siigo.com",
                    "USUARIO RED": "velo804623",
                    "CORREOELECTRONICO": "velo804623@siigo.com",
                    "STATION ID": "2605970",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Luis Ignacio Ferreira Duarte",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "41211258",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-02",
                    "NOMBRES": "Analia",
                    "APELLIDOS": "Lopez Pintos",
                    "NOMBRES COMPLETOS": "Analia Lopez Pintos",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Luis Ignacio Ferreira Duarte",
                    "CODIGO USUARIO": "lope804620",
                    "USUARIOFIVE9": "lope804620@siigo.com",
                    "USUARIO RED": "lope804620",
                    "CORREOELECTRONICO": "lope804620@siigo.com",
                    "STATION ID": "2606075",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Luis Ignacio Ferreira Duarte",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "40082333",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-02",
                    "NOMBRES": "Ricardo Martin",
                    "APELLIDOS": "Morales Carreras",
                    "NOMBRES COMPLETOS": "Ricardo Martin Morales Carreras",
                    "PAIS ORIGEN": "Uruguay",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Montevideo",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial Uruguay",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Luis Ignacio Ferreira Duarte",
                    "CODIGO USUARIO": "mora804622",
                    "USUARIOFIVE9": "mora804622@siigo.com",
                    "USUARIO RED": "mora804622",
                    "CORREOELECTRONICO": "mora804622@siigo.com",
                    "STATION ID": "2606234",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Luis Ignacio Ferreira Duarte",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "OELA8108067TA",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-02",
                    "NOMBRES": "Alma Cinthya",
                    "APELLIDOS": "Ortega Lomeli",
                    "NOMBRES COMPLETOS": "Alma Cinthya Ortega Lomeli",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Plan Contador MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alejandro Lopez Manzo",
                    "CODIGO USUARIO": "aortega",
                    "USUARIOFIVE9": "aortega@aspel.com.mx",
                    "USUARIO RED": "aortega",
                    "CORREOELECTRONICO": "aortega@aspel.com.mx",
                    "STATION ID": "2606256",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Alejandro Lopez Manzo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "FOMA810712TQ6",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-02",
                    "NOMBRES": "Alma Karina",
                    "APELLIDOS": "Flores Muniz",
                    "NOMBRES COMPLETOS": "Alma Karina Flores Muniz",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Plan Contador MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alejandro Lopez Manzo",
                    "CODIGO USUARIO": "aflores",
                    "USUARIOFIVE9": "aflores@aspel.com.mx",
                    "USUARIO RED": "aflores",
                    "CORREOELECTRONICO": "aflores@aspel.com.mx",
                    "STATION ID": "2606262",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Alejandro Lopez Manzo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "DOCA951205GA6",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-02",
                    "NOMBRES": "Aranza Ivonne",
                    "APELLIDOS": "Dominguez Carrillo",
                    "NOMBRES COMPLETOS": "Aranza Ivonne Dominguez Carrillo",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Plan Contador MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alejandro Lopez Manzo",
                    "CODIGO USUARIO": "aidominguez",
                    "USUARIOFIVE9": "aidominguez@aspel.com",
                    "USUARIO RED": "aidominguez",
                    "CORREOELECTRONICO": "aidominguez@aspel.com",
                    "STATION ID": "2606266",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Alejandro Lopez Manzo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "EIJD8803051I7",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-02",
                    "NOMBRES": "Daniel",
                    "APELLIDOS": "Espinoza Juarez",
                    "NOMBRES COMPLETOS": "Daniel Espinoza Juarez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Plan Contador MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alejandro Lopez Manzo",
                    "CODIGO USUARIO": "despinoza",
                    "USUARIOFIVE9": "despinoza@aspel.com.mx",
                    "USUARIO RED": "despinoza",
                    "CORREOELECTRONICO": "despinoza@aspel.com.mx",
                    "STATION ID": "2606267",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Alejandro Lopez Manzo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "PACE9201222NA",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-02",
                    "NOMBRES": "Erick",
                    "APELLIDOS": "Pascual Carrasco",
                    "NOMBRES COMPLETOS": "Erick Pascual Carrasco",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Plan Contador MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alejandro Lopez Manzo",
                    "CODIGO USUARIO": "epascual",
                    "USUARIOFIVE9": "epascual@aspel.com.mx",
                    "USUARIO RED": "epascual",
                    "CORREOELECTRONICO": "epascual@aspel.com.mx",
                    "STATION ID": "2606270",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Alejandro Lopez Manzo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "COPG850825BJ3",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-02",
                    "NOMBRES": "Grisell",
                    "APELLIDOS": "Contreras Pavon",
                    "NOMBRES COMPLETOS": "Grisell Contreras Pavon",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Plan Contador MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alejandro Lopez Manzo",
                    "CODIGO USUARIO": "gcontreras",
                    "USUARIOFIVE9": "gcontreras@aspel.com.mx",
                    "USUARIO RED": "gcontreras",
                    "CORREOELECTRONICO": "gcontreras@aspel.com.mx",
                    "STATION ID": "2606278",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Alejandro Lopez Manzo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "DOGJ920923C28",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-02",
                    "NOMBRES": "Jonathan",
                    "APELLIDOS": "Dominguez Galavis",
                    "NOMBRES COMPLETOS": "Jonathan Dominguez Galavis",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Saul Jonathan De la Rosa Blanco",
                    "CODIGO USUARIO": "jgalavis",
                    "USUARIOFIVE9": "jgalavis@aspel.com.mx",
                    "USUARIO RED": "jgalavis",
                    "CORREOELECTRONICO": "jgalavis@aspel.com.mx",
                    "STATION ID": "2606326",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Saul Jonathan De la Rosa Blanco",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "FIHJ800416SU5",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-02",
                    "NOMBRES": "Juan Pablo",
                    "APELLIDOS": "Frias Hernandez",
                    "NOMBRES COMPLETOS": "Juan Pablo Frias Hernandez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Plan Contador MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alejandro Lopez Manzo",
                    "CODIGO USUARIO": "jhernandez",
                    "USUARIOFIVE9": "jhernandez@aspel.com.mx",
                    "USUARIO RED": "jhernandez",
                    "CORREOELECTRONICO": "jhernandez@aspel.com.mx",
                    "STATION ID": "2606328",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Alejandro Lopez Manzo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "JERM830608RE2",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-02",
                    "NOMBRES": "Maribel De Jesus",
                    "APELLIDOS": "Rojas",
                    "NOMBRES COMPLETOS": "Maribel De Jesus Rojas",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Plan Contador MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alejandro Lopez Manzo",
                    "CODIGO USUARIO": "mrojas",
                    "USUARIOFIVE9": "mrojas@aspel.com.mx",
                    "USUARIO RED": "mrojas",
                    "CORREOELECTRONICO": "mrojas@aspel.com.mx",
                    "STATION ID": "2606339",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Alejandro Lopez Manzo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "CAGR900126E82",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-02",
                    "NOMBRES": "Ricardo",
                    "APELLIDOS": "Chavez Garcia",
                    "NOMBRES COMPLETOS": "Ricardo Chavez Garcia",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Plan Contador MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alejandro Lopez Manzo",
                    "CODIGO USUARIO": "rchavez",
                    "USUARIOFIVE9": "rchavez@aspel.com.mx",
                    "USUARIO RED": "rchavez",
                    "CORREOELECTRONICO": "rchavez@aspel.com.mx",
                    "STATION ID": "2606349",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Alejandro Lopez Manzo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "LOGR950304SG6",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-02",
                    "NOMBRES": "Ricardo",
                    "APELLIDOS": "Lopez Gomez",
                    "NOMBRES COMPLETOS": "Ricardo Lopez Gomez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Plan Contador MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alejandro Lopez Manzo",
                    "CODIGO USUARIO": "rgomez",
                    "USUARIOFIVE9": "rgomez@aspel.com.mx",
                    "USUARIO RED": "rgomez",
                    "CORREOELECTRONICO": "rgomez@aspel.com.mx",
                    "STATION ID": "2606454",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Alejandro Lopez Manzo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "LOMA921001LYA",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-02",
                    "NOMBRES": "Alejandro",
                    "APELLIDOS": "Lopez Manzo",
                    "NOMBRES COMPLETOS": "Alejandro Lopez Manzo",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Plan Contador MX",
                    "CARGO": "Lider Comercial",
                    "JEFE INMEDIATO": "Juan Camilo Gonzalez Saldana",
                    "CODIGO USUARIO": "amanzo",
                    "USUARIOFIVE9": "amanzo@aspel.com.mx",
                    "USUARIO RED": "amanzo",
                    "CORREOELECTRONICO": "amanzo@aspel.com.mx",
                    "STATION ID": "2606521",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Juan Camilo Gonzalez Saldana",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1026263411",
                    "FECHA INICIAL": "2023-03-01",
                    "FECHA OPERACION": "2023-03-06",
                    "NOMBRES": "Nikole Andrea",
                    "APELLIDOS": "Aldana Rey",
                    "NOMBRES COMPLETOS": "Nikole Andrea Aldana Rey",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Fundacion",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "alda900554",
                    "USUARIOFIVE9": "alda900554@siigo.com",
                    "USUARIO RED": "alda900554",
                    "CORREOELECTRONICO": "alda900554@siigo.com",
                    "STATION ID": "2609258",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018491615",
                    "FECHA INICIAL": "2023-03-01",
                    "FECHA OPERACION": "2023-03-06",
                    "NOMBRES": "Alison Dajanna",
                    "APELLIDOS": "Mendieta Velasco",
                    "NOMBRES COMPLETOS": "Alison Dajanna Mendieta Velasco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Liliana Ramirez Vanegas",
                    "CODIGO USUARIO": "mend900553",
                    "USUARIOFIVE9": "mend900553@siigo.com",
                    "USUARIO RED": "mend900553",
                    "CORREOELECTRONICO": "mend900553@siigo.com",
                    "STATION ID": "2609259",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Liliana Ramirez Vanegas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "55118254",
                    "FECHA INICIAL": "2023-03-03",
                    "FECHA OPERACION": "2023-03-06",
                    "NOMBRES": "Diana Mabel",
                    "APELLIDOS": "Rodriguez Oviedo",
                    "NOMBRES COMPLETOS": "Diana Mabel Rodriguez Oviedo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Huila",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Bismark Ivan Rios Campos",
                    "CODIGO USUARIO": "rodr900556",
                    "USUARIOFIVE9": "rodr900556@siigo.com",
                    "USUARIO RED": "rodr900556",
                    "CORREOELECTRONICO": "rodr900556@siigo.com",
                    "STATION ID": "2605980",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Bismark Ivan Rios Campos",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1026565110",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-08",
                    "NOMBRES": "Maira Alejandra",
                    "APELLIDOS": "Ruiz Espitia",
                    "NOMBRES COMPLETOS": "Maira Alejandra Ruiz Espitia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Madrid",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jesus Daniel Arzuza Roman",
                    "CODIGO USUARIO": "ruiz804594",
                    "USUARIOFIVE9": "ruiz804594@siigo.com",
                    "USUARIO RED": "ruiz804594",
                    "CORREOELECTRONICO": "ruiz804594@siigo.com",
                    "STATION ID": "2606037",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jesus Daniel Arzuza Roman",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52858927",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-08",
                    "NOMBRES": "Rocio Del Pilar",
                    "APELLIDOS": "Ordonez Bermudez",
                    "NOMBRES COMPLETOS": "Rocio Del Pilar Ordonez Bermudez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Palmira",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Daniel Eduardo Pinto Cepeda",
                    "CODIGO USUARIO": "ordo804605",
                    "USUARIOFIVE9": "ordo804605@siigo.com",
                    "USUARIO RED": "ordo804605",
                    "CORREOELECTRONICO": "ordo804605@siigo.com",
                    "STATION ID": "2606258",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Daniel Eduardo Pinto Cepeda",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016014362",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-08",
                    "NOMBRES": "Lady Alexandra",
                    "APELLIDOS": "Rodriguez Bernal",
                    "NOMBRES COMPLETOS": "Lady Alexandra Rodriguez Bernal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Mosquera",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Misael Martinez Montero",
                    "CODIGO USUARIO": "rodr804613",
                    "USUARIOFIVE9": "rodr804613@siigo.com",
                    "USUARIO RED": "rodr804613",
                    "CORREOELECTRONICO": "rodr804613@siigo.com",
                    "STATION ID": "2606449",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Misael Martinez Montero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030526164",
                    "FECHA INICIAL": "2023-02-01",
                    "FECHA OPERACION": "2023-03-08",
                    "NOMBRES": "Erika Del Mar",
                    "APELLIDOS": "Ahumada Leon",
                    "NOMBRES COMPLETOS": "Erika Del Mar Ahumada Leon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Vicky Alejandra Hernandez Mendez",
                    "CODIGO USUARIO": "ahum804616",
                    "USUARIOFIVE9": "ahum804616@siigo.com",
                    "USUARIO RED": "ahum804616",
                    "CORREOELECTRONICO": "ahum804616@siigo.com",
                    "STATION ID": "2606526",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Vicky Alejandra Hernandez Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52938326",
                    "FECHA INICIAL": "2023-03-08",
                    "FECHA OPERACION": "2023-03-16",
                    "NOMBRES": "Alicia",
                    "APELLIDOS": "Sarria Ceballos",
                    "NOMBRES COMPLETOS": "Alicia Sarria Ceballos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Fundacion",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "sarr900626",
                    "USUARIOFIVE9": "sarr900626@siigo.com",
                    "USUARIO RED": "sarr900626",
                    "CORREOELECTRONICO": "sarr900626@siigo.com",
                    "STATION ID": "2606117",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52752552",
                    "FECHA INICIAL": "2023-03-08",
                    "FECHA OPERACION": "2023-03-16",
                    "NOMBRES": "Paola Andrea",
                    "APELLIDOS": "Vargas Gonzalez",
                    "NOMBRES COMPLETOS": "Paola Andrea Vargas Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Arturo Alvarez Martinez",
                    "CODIGO USUARIO": "varg900624",
                    "USUARIOFIVE9": "varg900624@siigo.com",
                    "USUARIO RED": "varg900624",
                    "CORREOELECTRONICO": "varg900624@siigo.com",
                    "STATION ID": "3763898",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Arturo Alvarez Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1004268743",
                    "FECHA INICIAL": "2023-03-08",
                    "FECHA OPERACION": "2023-03-16",
                    "NOMBRES": "Valentina",
                    "APELLIDOS": "Parra Rojas",
                    "NOMBRES COMPLETOS": "Valentina Parra Rojas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Uruguay",
                    "CIUDAD OFICINA": "Pitalito",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Uy",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Edith Angelica Gil Umbarila",
                    "CODIGO USUARIO": "parr900622",
                    "USUARIOFIVE9": "parr900622@siigo.com",
                    "USUARIO RED": "parr900622",
                    "CORREOELECTRONICO": "parr900622@siigo.com",
                    "STATION ID": "2606637",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Edith Angelica Gil Umbarila",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032481966",
                    "FECHA INICIAL": "2023-03-08",
                    "FECHA OPERACION": "2023-03-16",
                    "NOMBRES": "Carol Tatiana",
                    "APELLIDOS": "Mejia Arevalo",
                    "NOMBRES COMPLETOS": "Carol Tatiana Mejia Arevalo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Madrid",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Arturo Alvarez Martinez",
                    "CODIGO USUARIO": "meji900610",
                    "USUARIOFIVE9": "meji900610@siigo.com",
                    "USUARIO RED": "meji900610",
                    "CORREOELECTRONICO": "meji900610@siigo.com",
                    "STATION ID": "2606140",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Arturo Alvarez Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1069741687",
                    "FECHA INICIAL": "2022-12-31",
                    "FECHA OPERACION": "2023-03-17",
                    "NOMBRES": "Cristian Armando",
                    "APELLIDOS": "Rozo Bermudez",
                    "NOMBRES COMPLETOS": "Cristian Armando Rozo Bermudez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Desarrollo",
                    "CARGO": "Product Owner",
                    "JEFE INMEDIATO": "Ana Rita Ortiz Herrera",
                    "CODIGO USUARIO": "rozo800141",
                    "USUARIOFIVE9": "rozo800141@siigo.com",
                    "USUARIO RED": "rozo800141",
                    "CORREOELECTRONICO": "rozo800141@siigo.com",
                    "STATION ID": "2605998",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Ana Rita Ortiz Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030641736",
                    "FECHA INICIAL": "2022-12-31",
                    "FECHA OPERACION": "2023-03-17",
                    "NOMBRES": "Ginna Patricia",
                    "APELLIDOS": "Barajas Diaz",
                    "NOMBRES COMPLETOS": "Ginna Patricia Barajas Diaz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Tech",
                    "SUBAREA": "Investigador",
                    "CARGO": "Product Owner",
                    "JEFE INMEDIATO": "Luz Mery Patino Infante",
                    "CODIGO USUARIO": "bara801265",
                    "USUARIOFIVE9": "bara801265@siigo.com",
                    "USUARIO RED": "bara801265",
                    "CORREOELECTRONICO": "bara801265@siigo.com",
                    "STATION ID": "2606840",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Tech_Luz Mery Patino Infante",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032417194",
                    "FECHA INICIAL": "2022-12-31",
                    "FECHA OPERACION": "2023-03-17",
                    "NOMBRES": "Angie Lorena",
                    "APELLIDOS": "Perez Espitia",
                    "NOMBRES COMPLETOS": "Angie Lorena Perez Espitia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Mercadeo",
                    "CARGO": "Cross Sell Planning Team Leader",
                    "JEFE INMEDIATO": "Camilo Andres Munoz Guerra",
                    "CODIGO USUARIO": "pere803479",
                    "USUARIOFIVE9": "pere803479@siigo.com",
                    "USUARIO RED": "pere803479",
                    "CORREOELECTRONICO": "pere803479@siigo.com",
                    "STATION ID": "3763919",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Camilo Andres Munoz Guerra",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "5236985",
                    "FECHA INICIAL": "2023-03-17",
                    "FECHA OPERACION": "2023-03-23",
                    "NOMBRES": "Gabriela Valentina",
                    "APELLIDOS": "Avilez Oviedo",
                    "NOMBRES COMPLETOS": "Gabriela Valentina Avilez Oviedo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "avil900640",
                    "USUARIOFIVE9": "avil900640@siigo.com",
                    "USUARIO RED": "avil900640",
                    "CORREOELECTRONICO": "avil900640@siigo.com",
                    "STATION ID": "2606028",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1144135327",
                    "FECHA INICIAL": "2023-03-17",
                    "FECHA OPERACION": "2023-03-23",
                    "NOMBRES": "Linda Lucia",
                    "APELLIDOS": "Osorio Cardenas",
                    "NOMBRES COMPLETOS": "Linda Lucia Osorio Cardenas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "osor900641",
                    "USUARIOFIVE9": "osor900641@siigo.com",
                    "USUARIO RED": "osor900641",
                    "CORREOELECTRONICO": "osor900641@siigo.com",
                    "STATION ID": "2606014",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1091674168",
                    "FECHA INICIAL": "2023-03-29",
                    "FECHA OPERACION": "2023-03-31",
                    "NOMBRES": "Maria Alejandra",
                    "APELLIDOS": "Jacome Casadiegos",
                    "NOMBRES COMPLETOS": "Maria Alejandra Jacome Casadiegos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ocana",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Sonia Mabel Fernandez Calderon",
                    "CODIGO USUARIO": "jaco900724",
                    "USUARIOFIVE9": "jaco900724@siigo.com",
                    "USUARIO RED": "jaco900724",
                    "CORREOELECTRONICO": "jaco900724@siigo.com",
                    "STATION ID": "2606860",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sonia Mabel Fernandez Calderon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1118564694",
                    "FECHA INICIAL": "2023-03-29",
                    "FECHA OPERACION": "2023-03-31",
                    "NOMBRES": "Karen Melissa",
                    "APELLIDOS": "Gomez Avendano",
                    "NOMBRES COMPLETOS": "Karen Melissa Gomez Avendano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Yopal",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Sonia Mabel Fernandez Calderon",
                    "CODIGO USUARIO": "gome900722",
                    "USUARIOFIVE9": "gome900722@siigo.com",
                    "USUARIO RED": "gome900722",
                    "CORREOELECTRONICO": "gome900722@siigo.com",
                    "STATION ID": "2606866",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sonia Mabel Fernandez Calderon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1061814942",
                    "FECHA INICIAL": "2023-03-29",
                    "FECHA OPERACION": "2023-03-31",
                    "NOMBRES": "Andres Felipe",
                    "APELLIDOS": "Moncada Collazos",
                    "NOMBRES COMPLETOS": "Andres Felipe Moncada Collazos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jazbleidy Andrea Nunez Montoya",
                    "CODIGO USUARIO": "monc900706",
                    "USUARIOFIVE9": "monc900706@siigo.com",
                    "USUARIO RED": "monc900706",
                    "CORREOELECTRONICO": "monc900706@siigo.com",
                    "STATION ID": "2606886",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jazbleidy Andrea Nunez Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1052734522",
                    "FECHA INICIAL": "2023-03-29",
                    "FECHA OPERACION": "2023-03-31",
                    "NOMBRES": "Jessica Paola",
                    "APELLIDOS": "Yanez Cabarcas",
                    "NOMBRES COMPLETOS": "Jessica Paola Yanez Cabarcas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "yane900708",
                    "USUARIOFIVE9": "yane900708@siigo.com",
                    "USUARIO RED": "yane900708",
                    "CORREOELECTRONICO": "yane900708@siigo.com",
                    "STATION ID": "2607063",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1140839717",
                    "FECHA INICIAL": "2023-03-29",
                    "FECHA OPERACION": "2023-03-31",
                    "NOMBRES": "Juleys",
                    "APELLIDOS": "Agamez Bermudez",
                    "NOMBRES COMPLETOS": "Juleys Agamez Bermudez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Malambo",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Divulgacion",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Jose Daniel Huerfano Martinez",
                    "CODIGO USUARIO": "agam900712",
                    "USUARIOFIVE9": "agam900712@siigo.com",
                    "USUARIO RED": "agam900712",
                    "CORREOELECTRONICO": "agam900712@siigo.com",
                    "STATION ID": "2606973",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jose Daniel Huerfano Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1091655782",
                    "FECHA INICIAL": "2023-03-29",
                    "FECHA OPERACION": "2023-03-31",
                    "NOMBRES": "Monica Liliana",
                    "APELLIDOS": "Arevalo Perez",
                    "NOMBRES COMPLETOS": "Monica Liliana Arevalo Perez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ocana",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Sonia Mabel Fernandez Calderon",
                    "CODIGO USUARIO": "arev900720",
                    "USUARIOFIVE9": "arev900720@siigo.com",
                    "USUARIO RED": "arev900720",
                    "CORREOELECTRONICO": "arev900720@siigo.com",
                    "STATION ID": "2606977",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sonia Mabel Fernandez Calderon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "55177797",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-14",
                    "NOMBRES": "Adriana",
                    "APELLIDOS": "Gomez Calderon",
                    "NOMBRES COMPLETOS": "Adriana Gomez Calderon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Neiva",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jenny Paola Mendez",
                    "CODIGO USUARIO": "gome901187",
                    "USUARIOFIVE9": "gome901187@siigo.com",
                    "USUARIO RED": "gome901187",
                    "CORREOELECTRONICO": "gome901187@siigo.com",
                    "STATION ID": "3127604",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jenny Paola Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1057596225",
                    "FECHA INICIAL": "2023-04-12",
                    "FECHA OPERACION": "2023-04-14",
                    "NOMBRES": "Alejandro",
                    "APELLIDOS": "Rosas Chaparro",
                    "NOMBRES COMPLETOS": "Alejandro Rosas Chaparro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Sogamoso",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "rosa901245",
                    "USUARIOFIVE9": "rosa901245@siigo.com",
                    "USUARIO RED": "rosa901245",
                    "CORREOELECTRONICO": "rosa901245@siigo.com",
                    "STATION ID": "2607073",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1110470390",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-14",
                    "NOMBRES": "Angelica Judith",
                    "APELLIDOS": "Osorio Carvajal",
                    "NOMBRES COMPLETOS": "Angelica Judith Osorio Carvajal",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gustavo Adolfo Espinosa Toledo",
                    "CODIGO USUARIO": "osor901000",
                    "USUARIOFIVE9": "osor901000@siigo.com",
                    "USUARIO RED": "osor901000",
                    "CORREOELECTRONICO": "osor901000@siigo.com",
                    "STATION ID": "3127605",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gustavo Adolfo Espinosa Toledo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1044604461",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-14",
                    "NOMBRES": "Angie Paola",
                    "APELLIDOS": "Montano Guillen",
                    "NOMBRES COMPLETOS": "Angie Paola Montano Guillen",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "UCI",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Nestor Oswaldo Becerra Prieto",
                    "CODIGO USUARIO": "mont901201",
                    "USUARIOFIVE9": "mont901201@siigo.com",
                    "USUARIO RED": "mont901201",
                    "CORREOELECTRONICO": "mont901201@siigo.com",
                    "STATION ID": "3127608",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Nestor Oswaldo Becerra Prieto",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016071984",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-14",
                    "NOMBRES": "Astrid Karime",
                    "APELLIDOS": "Ramirez Rocha",
                    "NOMBRES COMPLETOS": "Astrid Karime Ramirez Rocha",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Liliana Andrea Segura Martinez",
                    "CODIGO USUARIO": "rami901038",
                    "USUARIOFIVE9": "rami901038@siigo.com",
                    "USUARIO RED": "rami901038",
                    "CORREOELECTRONICO": "rami901038@siigo.com",
                    "STATION ID": "3127612",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Andrea Segura Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143266971",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-14",
                    "NOMBRES": "Breiner",
                    "APELLIDOS": "Vides Lara",
                    "NOMBRES COMPLETOS": "Breiner Vides Lara",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jenny Paola Mendez",
                    "CODIGO USUARIO": "vide901004",
                    "USUARIOFIVE9": "vide901004@siigo.com",
                    "USUARIO RED": "vide901004",
                    "CORREOELECTRONICO": "vide901004@siigo.com",
                    "STATION ID": "3127474",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jenny Paola Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1043434515",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-14",
                    "NOMBRES": "Britney Isabel",
                    "APELLIDOS": "Monterrosa Gomez",
                    "NOMBRES COMPLETOS": "Britney Isabel Monterrosa Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Eduardo Sanchez Castano",
                    "CODIGO USUARIO": "mont901183",
                    "USUARIOFIVE9": "mont901183@siigo.com",
                    "USUARIO RED": "mont901183",
                    "CORREOELECTRONICO": "mont901183@siigo.com",
                    "STATION ID": "3127475",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Eduardo Sanchez Castano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1002132093",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-14",
                    "NOMBRES": "Camilo Andres",
                    "APELLIDOS": "De La Rosa Vaca",
                    "NOMBRES COMPLETOS": "Camilo Andres De La Rosa Vaca",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Elizabeth Giron Parra",
                    "CODIGO USUARIO": "dela901189",
                    "USUARIOFIVE9": "dela901189@siigo.com",
                    "USUARIO RED": "dela901189",
                    "CORREOELECTRONICO": "dela901189@siigo.com",
                    "STATION ID": "2607072",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Elizabeth Giron Parra",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80108605",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-14",
                    "NOMBRES": "Carlos Andres",
                    "APELLIDOS": "Porras Cubillos",
                    "NOMBRES COMPLETOS": "Carlos Andres Porras Cubillos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leida Adriana Rico Herrera",
                    "CODIGO USUARIO": "porr901195",
                    "USUARIOFIVE9": "porr901195@siigo.com",
                    "USUARIO RED": "porr901195",
                    "CORREOELECTRONICO": "porr901195@siigo.com",
                    "STATION ID": "3127555",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leida Adriana Rico Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018410289",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-14",
                    "NOMBRES": "Carols Irene",
                    "APELLIDOS": "Penagos",
                    "NOMBRES COMPLETOS": "Carols Irene Penagos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Ximena Manrique Isaziga",
                    "CODIGO USUARIO": "pena901096",
                    "USUARIOFIVE9": "pena901096@siigo.com",
                    "USUARIO RED": "pena901096",
                    "CORREOELECTRONICO": "pena901096@siigo.com",
                    "STATION ID": "3127559",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Ximena Manrique Isaziga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1129490020",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-14",
                    "NOMBRES": "Daymer David",
                    "APELLIDOS": "Lopez Ortega",
                    "NOMBRES COMPLETOS": "Daymer David Lopez Ortega",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Yamid Ramiro Quintero Montano",
                    "CODIGO USUARIO": "lope900996",
                    "USUARIOFIVE9": "lope900996@siigo.com",
                    "USUARIO RED": "lope900996",
                    "CORREOELECTRONICO": "lope900996@siigo.com",
                    "STATION ID": "3267833",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yamid Ramiro Quintero Montano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52801063",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-14",
                    "NOMBRES": "Diana Deyanira",
                    "APELLIDOS": "Mora Portuguez",
                    "NOMBRES COMPLETOS": "Diana Deyanira Mora Portuguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Aleida Bibiana Garcia Suarez",
                    "CODIGO USUARIO": "mora901101",
                    "USUARIOFIVE9": "mora901101@siigo.com",
                    "USUARIO RED": "mora901101",
                    "CORREOELECTRONICO": "mora901101@siigo.com",
                    "STATION ID": "3267838",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Aleida Bibiana Garcia Suarez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1118309322",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-14",
                    "NOMBRES": "Dilia Aurora",
                    "APELLIDOS": "Zuleta Molina",
                    "NOMBRES COMPLETOS": "Dilia Aurora Zuleta Molina",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Yumbo",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leida Adriana Rico Herrera",
                    "CODIGO USUARIO": "zule901193",
                    "USUARIOFIVE9": "zule901193@siigo.com",
                    "USUARIO RED": "zule901193",
                    "CORREOELECTRONICO": "zule901193@siigo.com",
                    "STATION ID": "3267849",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leida Adriana Rico Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1235044595",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-14",
                    "NOMBRES": "Edwin Camilo",
                    "APELLIDOS": "Garcia Ortega",
                    "NOMBRES COMPLETOS": "Edwin Camilo Garcia Ortega",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Leidy Stefany Castillo",
                    "CODIGO USUARIO": "garc900970",
                    "USUARIOFIVE9": "garc900970@siigo.com",
                    "USUARIO RED": "garc900970",
                    "CORREOELECTRONICO": "garc900970@siigo.com",
                    "STATION ID": "3267853",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Leidy Stefany Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007288313",
                    "FECHA INICIAL": "2023-04-12",
                    "FECHA OPERACION": "2023-04-14",
                    "NOMBRES": "Eliana Yulitza",
                    "APELLIDOS": "Moreno Mendez",
                    "NOMBRES COMPLETOS": "Eliana Yulitza Moreno Mendez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jefrey Julian Sanchez Celemin",
                    "CODIGO USUARIO": "more901296",
                    "USUARIOFIVE9": "more901296@siigo.com",
                    "USUARIO RED": "more901296",
                    "CORREOELECTRONICO": "more901296@siigo.com",
                    "STATION ID": "3267862",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jefrey Julian Sanchez Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1049627596",
                    "FECHA INICIAL": "2023-04-12",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Luis Felipe",
                    "APELLIDOS": "Diaz Gonzalez",
                    "NOMBRES COMPLETOS": "Luis Felipe Diaz Gonzalez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Tunja",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Angie Tatiana Reyes Perez",
                    "CODIGO USUARIO": "diaz901235",
                    "USUARIOFIVE9": "diaz901235@siigo.com",
                    "USUARIO RED": "diaz901235",
                    "CORREOELECTRONICO": "diaz901235@siigo.com",
                    "STATION ID": "2606105",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Angie Tatiana Reyes Perez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1002027421",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Maria Elena",
                    "APELLIDOS": "Rangel Julio",
                    "NOMBRES COMPLETOS": "Maria Elena Rangel Julio",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soledad",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Sory Yaqueline Abello Polo",
                    "CODIGO USUARIO": "rang901094",
                    "USUARIOFIVE9": "rang901094@siigo.com",
                    "USUARIO RED": "rang901094",
                    "CORREOELECTRONICO": "rang901094@siigo.com",
                    "STATION ID": "2607074",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sory Yaqueline Abello Polo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1031136419",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Maritza Julieth",
                    "APELLIDOS": "Cruz Jimenez",
                    "NOMBRES COMPLETOS": "Maritza Julieth Cruz Jimenez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juvenal Reyes Silva",
                    "CODIGO USUARIO": "cruz900990",
                    "USUARIOFIVE9": "cruz900990@siigo.com",
                    "USUARIO RED": "cruz900990",
                    "CORREOELECTRONICO": "cruz900990@siigo.com",
                    "STATION ID": "2606555",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juvenal Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1102800158",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Miguel Alberto",
                    "APELLIDOS": "Morales Beltran",
                    "NOMBRES COMPLETOS": "Miguel Alberto Morales Beltran",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Yamid Ramiro Quintero Montano",
                    "CODIGO USUARIO": "ora901032",
                    "USUARIOFIVE9": "ora901032@siigo.com",
                    "USUARIO RED": "ora901032",
                    "CORREOELECTRONICO": "ora901032@siigo.com",
                    "STATION ID": "3690429",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yamid Ramiro Quintero Montano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1192763248",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Natali Vanessa",
                    "APELLIDOS": "Arteaga Canas",
                    "NOMBRES COMPLETOS": "Natali Vanessa Arteaga Canas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Yamid Ramiro Quintero Montano",
                    "CODIGO USUARIO": "arte901008",
                    "USUARIOFIVE9": "arte901008@siigo.com",
                    "USUARIO RED": "arte901008",
                    "CORREOELECTRONICO": "arte901008@siigo.com",
                    "STATION ID": "2607108",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yamid Ramiro Quintero Montano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1078369745",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Natalia",
                    "APELLIDOS": "Forero Suarez",
                    "NOMBRES COMPLETOS": "Natalia Forero Suarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Misael Martinez Montero",
                    "CODIGO USUARIO": "fore901020",
                    "USUARIOFIVE9": "fore901020@siigo.com",
                    "USUARIO RED": "fore901020",
                    "CORREOELECTRONICO": "fore901020@siigo.com",
                    "STATION ID": "2605957",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Misael Martinez Montero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143345221",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Rafael Antonio",
                    "APELLIDOS": "Zapata Sarkar",
                    "NOMBRES COMPLETOS": "Rafael Antonio Zapata Sarkar",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Leidy Stefany Castillo",
                    "CODIGO USUARIO": "zapa901042",
                    "USUARIOFIVE9": "zapa901042@siigo.com",
                    "USUARIO RED": "zapa901042",
                    "CORREOELECTRONICO": "zapa901042@siigo.com",
                    "STATION ID": "3127549",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Leidy Stefany Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1126256992",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Ricardo Jose",
                    "APELLIDOS": "Valle Sermeno",
                    "NOMBRES COMPLETOS": "Ricardo Jose Valle Sermeno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gustavo Adolfo Espinosa Toledo",
                    "CODIGO USUARIO": "vall901010",
                    "USUARIOFIVE9": "vall901010@siigo.com",
                    "USUARIO RED": "vall901010",
                    "CORREOELECTRONICO": "vall901010@siigo.com",
                    "STATION ID": "2606025",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gustavo Adolfo Espinosa Toledo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1049648819",
                    "FECHA INICIAL": "2023-04-12",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Tatiana Marisol",
                    "APELLIDOS": "Faura Pizon",
                    "NOMBRES COMPLETOS": "Tatiana Marisol Faura Pizon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Tunja",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Hancel Rafael Salcedo Mendoza",
                    "CODIGO USUARIO": "faur901241",
                    "USUARIOFIVE9": "faur901241@siigo.com",
                    "USUARIO RED": "faur901241",
                    "CORREOELECTRONICO": "faur901241@siigo.com",
                    "STATION ID": "2606713",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Hancel Rafael Salcedo Mendoza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000125254",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Wilman Herley",
                    "APELLIDOS": "Arenas Sanchez",
                    "NOMBRES COMPLETOS": "Wilman Herley Arenas Sanchez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Espinal",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Yamid Ramiro Quintero Montano",
                    "CODIGO USUARIO": "aren901018",
                    "USUARIOFIVE9": "aren901018@siigo.com",
                    "USUARIO RED": "aren901018",
                    "CORREOELECTRONICO": "aren901018@siigo.com",
                    "STATION ID": "2607105",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yamid Ramiro Quintero Montano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010194125",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Yudi Marcela",
                    "APELLIDOS": "Romero Ramirez",
                    "NOMBRES COMPLETOS": "Yudi Marcela Romero Ramirez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Liliana Andrea Segura Martinez",
                    "CODIGO USUARIO": "rome901063",
                    "USUARIOFIVE9": "rome901063@siigo.com",
                    "USUARIO RED": "rome901063",
                    "CORREOELECTRONICO": "rome901063@siigo.com",
                    "STATION ID": "3857242",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Liliana Andrea Segura Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1003520110",
                    "FECHA INICIAL": "2023-04-12",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Yully Stephania",
                    "APELLIDOS": "Pena Guzman",
                    "NOMBRES COMPLETOS": "Yully Stephania Pena Guzman",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Fusagasuga",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Fernando Sotto Cardozo",
                    "CODIGO USUARIO": "pena901294",
                    "USUARIOFIVE9": "pena901294@siigo.com",
                    "USUARIO RED": "pena901294",
                    "CORREOELECTRONICO": "pena901294@siigo.com",
                    "STATION ID": "3127603",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Fernando Sotto Cardozo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1005851611",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Zaira Alejandra",
                    "APELLIDOS": "Morales",
                    "NOMBRES COMPLETOS": "Zaira Alejandra Morales",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Elizabeth Giron Parra",
                    "CODIGO USUARIO": "mora900982",
                    "USUARIOFIVE9": "mora900982@siigo.com",
                    "USUARIO RED": "mora900982",
                    "CORREOELECTRONICO": "mora900982@siigo.com",
                    "STATION ID": "2606002",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Elizabeth Giron Parra",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001437975",
                    "FECHA INICIAL": "2023-04-12",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Elizabeth Vanesa",
                    "APELLIDOS": "Garcia Palacio",
                    "NOMBRES COMPLETOS": "Elizabeth Vanesa Garcia Palacio",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Puerto Nare",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Divulgacion",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Jose Daniel Huerfano Martinez",
                    "CODIGO USUARIO": "garc901287",
                    "USUARIOFIVE9": "garc901287@siigo.com",
                    "USUARIO RED": "garc901287",
                    "CORREOELECTRONICO": "garc901287@siigo.com",
                    "STATION ID": "2605934",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jose Daniel Huerfano Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1083033968",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Eva De Jesus",
                    "APELLIDOS": "Serje Arvilla",
                    "NOMBRES COMPLETOS": "Eva De Jesus Serje Arvilla",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Santa Marta",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Leidy Stefany Castillo",
                    "CODIGO USUARIO": "serj901036",
                    "USUARIOFIVE9": "serj901036@siigo.com",
                    "USUARIO RED": "serj901036",
                    "CORREOELECTRONICO": "serj901036@siigo.com",
                    "STATION ID": "2605981",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Leidy Stefany Castillo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52809162",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Francy Yohana",
                    "APELLIDOS": "Bernal Garcia",
                    "NOMBRES COMPLETOS": "Francy Yohana Bernal Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Zipaquira",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Paula Alejandra Garcia Lujan",
                    "CODIGO USUARIO": "bern901022",
                    "USUARIOFIVE9": "bern901022@siigo.com",
                    "USUARIO RED": "bern901022",
                    "CORREOELECTRONICO": "bern901022@siigo.com",
                    "STATION ID": "2606010",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Paula Alejandra Garcia Lujan",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1898964",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Gelary Gabriela",
                    "APELLIDOS": "Manosalva Quinones",
                    "NOMBRES COMPLETOS": "Gelary Gabriela Manosalva Quinones",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Misael Martinez Montero",
                    "CODIGO USUARIO": "mano900978",
                    "USUARIOFIVE9": "mano900978@siigo.com",
                    "USUARIO RED": "mano900978",
                    "CORREOELECTRONICO": "mano900978@siigo.com",
                    "STATION ID": "2606084",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Misael Martinez Montero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1070008343",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Gloria Alejandra",
                    "APELLIDOS": "Nunez Virguez",
                    "NOMBRES COMPLETOS": "Gloria Alejandra Nunez Virguez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Zipaquira",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Vicky Alejandra Hernandez Mendez",
                    "CODIGO USUARIO": "nune901028",
                    "USUARIOFIVE9": "nune901028@siigo.com",
                    "USUARIO RED": "nune901028",
                    "CORREOELECTRONICO": "nune901028@siigo.com",
                    "STATION ID": "2606114",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Vicky Alejandra Hernandez Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1140899943",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Gloria Daney",
                    "APELLIDOS": "Torres Montes",
                    "NOMBRES COMPLETOS": "Gloria Daney Torres Montes",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Sory Yaqueline Abello Polo",
                    "CODIGO USUARIO": "torr900992",
                    "USUARIOFIVE9": "torr900992@siigo.com",
                    "USUARIO RED": "torr900992",
                    "CORREOELECTRONICO": "torr900992@siigo.com",
                    "STATION ID": "2606132",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sory Yaqueline Abello Polo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1110588526",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Hernan Dario",
                    "APELLIDOS": "Gutierrez Sanabria",
                    "NOMBRES COMPLETOS": "Hernan Dario Gutierrez Sanabria",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Venadillo",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jenny Paola Mendez",
                    "CODIGO USUARIO": "guti901026",
                    "USUARIOFIVE9": "guti901026@siigo.com",
                    "USUARIO RED": "guti901026",
                    "CORREOELECTRONICO": "guti901026@siigo.com",
                    "STATION ID": "2606168",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jenny Paola Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1107046214",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Jenny Johana",
                    "APELLIDOS": "Rojas Uscategui",
                    "NOMBRES COMPLETOS": "Jenny Johana Rojas Uscategui",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Mercedes Moncada Peraza",
                    "CODIGO USUARIO": "roja900998",
                    "USUARIOFIVE9": "roja900998@siigo.com",
                    "USUARIO RED": "roja900998",
                    "CORREOELECTRONICO": "roja900998@siigo.com",
                    "STATION ID": "2606340",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Mercedes Moncada Peraza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1121864298",
                    "FECHA INICIAL": "2023-04-12",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Jennyffer Adriana",
                    "APELLIDOS": "Murillo Betancourt",
                    "NOMBRES COMPLETOS": "Jennyffer Adriana Murillo Betancourt",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Call Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Jefrey Julian Sanchez Celemin",
                    "CODIGO USUARIO": "muri901243",
                    "USUARIOFIVE9": "muri901243@siigo.com",
                    "USUARIO RED": "muri901243",
                    "CORREOELECTRONICO": "muri901243@siigo.com",
                    "STATION ID": "2606360",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Jefrey Julian Sanchez Celemin",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1121874685",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Jessica Lorena",
                    "APELLIDOS": "Garavito Leon",
                    "NOMBRES COMPLETOS": "Jessica Lorena Garavito Leon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Zipaquira",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Mercedes Moncada Peraza",
                    "CODIGO USUARIO": "gara901044",
                    "USUARIOFIVE9": "gara901044@siigo.com",
                    "USUARIO RED": "gara901044",
                    "CORREOELECTRONICO": "gara901044@siigo.com",
                    "STATION ID": "2606377",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Mercedes Moncada Peraza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030695709",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Jessica Selena",
                    "APELLIDOS": "Vargas Suesca",
                    "NOMBRES COMPLETOS": "Jessica Selena Vargas Suesca",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jessica Paola Paez Quintero",
                    "CODIGO USUARIO": "varg900976",
                    "USUARIOFIVE9": "varg900976@siigo.com",
                    "USUARIO RED": "varg900976",
                    "CORREOELECTRONICO": "varg900976@siigo.com",
                    "STATION ID": "2606412",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jessica Paola Paez Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015397948",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Johana Paola",
                    "APELLIDOS": "Morales Suarez",
                    "NOMBRES COMPLETOS": "Johana Paola Morales Suarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Paula Alejandra Garcia Lujan",
                    "CODIGO USUARIO": "mora901177",
                    "USUARIOFIVE9": "mora901177@siigo.com",
                    "USUARIO RED": "mora901177",
                    "CORREOELECTRONICO": "mora901177@siigo.com",
                    "STATION ID": "3127569",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Paula Alejandra Garcia Lujan",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1102042658",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Jose Alfredo",
                    "APELLIDOS": "Maldonado Soto",
                    "NOMBRES COMPLETOS": "Jose Alfredo Maldonado Soto",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Aleida Bibiana Garcia Suarez",
                    "CODIGO USUARIO": "mald901040",
                    "USUARIOFIVE9": "mald901040@siigo.com",
                    "USUARIO RED": "mald901040",
                    "CORREOELECTRONICO": "mald901040@siigo.com",
                    "STATION ID": "2606418",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Aleida Bibiana Garcia Suarez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1065836786",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Juan David",
                    "APELLIDOS": "Bermudez Acosta",
                    "NOMBRES COMPLETOS": "Juan David Bermudez Acosta",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Elizabeth Giron Parra",
                    "CODIGO USUARIO": "berm901002",
                    "USUARIOFIVE9": "berm901002@siigo.com",
                    "USUARIO RED": "berm901002",
                    "CORREOELECTRONICO": "berm901002@siigo.com",
                    "STATION ID": "2606441",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Elizabeth Giron Parra",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "8101775",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Klever Danhur",
                    "APELLIDOS": "Alvarez Giraldo",
                    "NOMBRES COMPLETOS": "Klever Danhur Alvarez Giraldo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Mosquera",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "John Misael Martinez Montero",
                    "CODIGO USUARIO": "alva901014",
                    "USUARIOFIVE9": "alva901014@siigo.com",
                    "USUARIO RED": "alva901014",
                    "CORREOELECTRONICO": "alva901014@siigo.com",
                    "STATION ID": "2606553",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_John Misael Martinez Montero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010023544",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Laura Cristina",
                    "APELLIDOS": "Franco Granja",
                    "NOMBRES COMPLETOS": "Laura Cristina Franco Granja",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Yamid Ramiro Quintero Montano",
                    "CODIGO USUARIO": "fran901199",
                    "USUARIOFIVE9": "fran901199@siigo.com",
                    "USUARIO RED": "fran901199",
                    "CORREOELECTRONICO": "fran901199@siigo.com",
                    "STATION ID": "2606739",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yamid Ramiro Quintero Montano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1006553844",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Laura Vanessa",
                    "APELLIDOS": "Barrera Cardenas",
                    "NOMBRES COMPLETOS": "Laura Vanessa Barrera Cardenas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Majagual",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Adriana Elizabeth Giron Parra",
                    "CODIGO USUARIO": "barr901056",
                    "USUARIOFIVE9": "barr901056@siigo.com",
                    "USUARIO RED": "barr901056",
                    "CORREOELECTRONICO": "barr901056@siigo.com",
                    "STATION ID": "2606809",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Adriana Elizabeth Giron Parra",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016021347",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Leidy Johana",
                    "APELLIDOS": "Castelblanco Pinzon",
                    "NOMBRES COMPLETOS": "Leidy Johana Castelblanco Pinzon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jessica Paola Paez Quintero",
                    "CODIGO USUARIO": "cast901034",
                    "USUARIOFIVE9": "cast901034@siigo.com",
                    "USUARIO RED": "cast901034",
                    "CORREOELECTRONICO": "cast901034@siigo.com",
                    "STATION ID": "2606865",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jessica Paola Paez Quintero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1143427833",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-15",
                    "NOMBRES": "Linda Alejandra",
                    "APELLIDOS": "Mendoza Fontalvo",
                    "NOMBRES COMPLETOS": "Linda Alejandra Mendoza Fontalvo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Aleida Bibiana Garcia Suarez",
                    "CODIGO USUARIO": "mend901197",
                    "USUARIOFIVE9": "mend901197@siigo.com",
                    "USUARIO RED": "mend901197",
                    "CORREOELECTRONICO": "mend901197@siigo.com",
                    "STATION ID": "2606999",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Aleida Bibiana Garcia Suarez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1043129114",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-04-18",
                    "NOMBRES": "Julitza Paola",
                    "APELLIDOS": "Marriaga Castillo",
                    "NOMBRES COMPLETOS": "Julitza Paola Marriaga Castillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Malambo",
                    "AREA": "Customer",
                    "SUBAREA": "TCN Nube",
                    "CARGO": "Asesor de Customer",
                    "JEFE INMEDIATO": "Carlos Eduardo Sanchez Castano",
                    "CODIGO USUARIO": "marr9011796",
                    "USUARIOFIVE9": "marr9011796@siigo.com",
                    "USUARIO RED": "marr9011796",
                    "CORREOELECTRONICO": "marr9011796@siigo.com",
                    "STATION ID": "2607043",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Carlos Eduardo Sanchez Castano",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1039679333",
                    "FECHA INICIAL": "2023-03-08",
                    "FECHA OPERACION": "2023-04-18",
                    "NOMBRES": "Maria Cristina",
                    "APELLIDOS": "Torres Gutierrez",
                    "NOMBRES COMPLETOS": "Maria Cristina Torres Gutierrez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Divulgacion",
                    "CARGO": "Divulgacion",
                    "JEFE INMEDIATO": "Jose Daniel Huerfano Martinez",
                    "CODIGO USUARIO": "torr900630",
                    "USUARIOFIVE9": "torr900630@siigo.com",
                    "USUARIO RED": "torr900630",
                    "CORREOELECTRONICO": "torr900630@siigo.com",
                    "STATION ID": "2607060",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Jose Daniel Huerfano Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "FUTP760116NZ8",
                    "FECHA INICIAL": "2022-08-17",
                    "FECHA OPERACION": "2022-09-12",
                    "NOMBRES": "Paul",
                    "APELLIDOS": "Fuentes",
                    "NOMBRES COMPLETOS": "Paul Fuentes",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Customer",
                    "SUBAREA": "Customer Mexico",
                    "CARGO": "Director Customer",
                    "JEFE INMEDIATO": "Andrea Zuluaga Uribe",
                    "CODIGO USUARIO": "paul.fuentes",
                    "USUARIOFIVE9": "paul.fuentes@aspel.com.mx",
                    "USUARIO RED": "paul.fuentes",
                    "CORREOELECTRONICO": "paul.fuentes@aspel.com.mx",
                    "STATION ID": "3857240",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andrea Zuluaga Uribe",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52725954",
                    "FECHA INICIAL": "2023-04-04",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Rocio Del Pilar",
                    "APELLIDOS": "Gaitan Diaz",
                    "NOMBRES COMPLETOS": "Rocio Del Pilar Gaitan Diaz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Daniel Eduardo Pinto Cepeda",
                    "CODIGO USUARIO": "gait901172",
                    "USUARIOFIVE9": "gait901172@siigo.com",
                    "USUARIO RED": "gait901172",
                    "CORREOELECTRONICO": "gait901172@siigo.com",
                    "STATION ID": "2606707",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Daniel Eduardo Pinto Cepeda",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1100966190",
                    "FECHA INICIAL": "2023-05-03",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Atalia Cecilia",
                    "APELLIDOS": "De Alba Arrieta",
                    "NOMBRES COMPLETOS": "Atalia Cecilia De Alba Arrieta",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones MX",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Arturo Alvarez Martinez",
                    "CODIGO USUARIO": "deal901415",
                    "USUARIOFIVE9": "deal901415@siigo.com",
                    "USUARIO RED": "deal901415",
                    "CORREOELECTRONICO": "deal901415@siigo.com",
                    "STATION ID": "2606723",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Arturo Alvarez Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1000272529",
                    "FECHA INICIAL": "2023-05-03",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Maria Paula",
                    "APELLIDOS": "Buitrago Wilches",
                    "NOMBRES COMPLETOS": "Maria Paula Buitrago Wilches",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Andrea Catalina Vega Gomez",
                    "CODIGO USUARIO": "buit901419",
                    "USUARIOFIVE9": "buit901419@siigo.com",
                    "USUARIO RED": "buit901419",
                    "CORREOELECTRONICO": "buit901419@siigo.com",
                    "STATION ID": "2606166",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andrea Catalina Vega Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "79761669",
                    "FECHA INICIAL": "2023-05-03",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Dago Harvey",
                    "APELLIDOS": "Lozada Marin",
                    "NOMBRES COMPLETOS": "Dago Harvey Lozada Marin",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alison Anaid Chica Ezeta",
                    "CODIGO USUARIO": "loza901413",
                    "USUARIOFIVE9": "loza901413@siigo.com",
                    "USUARIO RED": "loza901413",
                    "CORREOELECTRONICO": "loza901413@siigo.com",
                    "STATION ID": "4421723",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Alison Anaid Chica Ezeta",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "LOPD780815EK5",
                    "FECHA INICIAL": "2023-04-27",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Daniel",
                    "APELLIDOS": "Lopez Perez",
                    "NOMBRES COMPLETOS": "Daniel Lopez Perez",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jose De Jesus De Aquino Curiel",
                    "CODIGO USUARIO": "lope901326",
                    "USUARIOFIVE9": "lope901326@siigo.com",
                    "USUARIO RED": "lope901326",
                    "CORREOELECTRONICO": "lope901326@siigo.com",
                    "STATION ID": "2606387",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jose De Jesus De Aquino Curiel",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80164030",
                    "FECHA INICIAL": "2023-05-03",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Diego Armando",
                    "APELLIDOS": "Quiroga Alfonso",
                    "NOMBRES COMPLETOS": "Diego Armando Quiroga Alfonso",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Angel Alfonso Arciniegas Guerrero",
                    "CODIGO USUARIO": "quir901446",
                    "USUARIOFIVE9": "quir901446@siigo.com",
                    "USUARIO RED": "quir901446",
                    "CORREOELECTRONICO": "quir901446@siigo.com",
                    "STATION ID": "2606751",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Angel Alfonso Arciniegas Guerrero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014226384",
                    "FECHA INICIAL": "2023-05-03",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Edna Yamile",
                    "APELLIDOS": "Soto Gil",
                    "NOMBRES COMPLETOS": "Edna Yamile Soto Gil",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Walter Antonio Munoz Antepara",
                    "CODIGO USUARIO": "soto901421",
                    "USUARIOFIVE9": "soto901421@siigo.com",
                    "USUARIO RED": "soto901421",
                    "CORREOELECTRONICO": "soto901421@siigo.com",
                    "STATION ID": "2606888",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Walter Antonio Munoz Antepara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016040889",
                    "FECHA INICIAL": "2023-05-03",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Isabel Cristina",
                    "APELLIDOS": "Duarte Medina",
                    "NOMBRES COMPLETOS": "Isabel Cristina Duarte Medina",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alison Anaid Chica Ezeta",
                    "CODIGO USUARIO": "duar901400",
                    "USUARIOFIVE9": "duar901400@siigo.com",
                    "USUARIO RED": "duar901400",
                    "CORREOELECTRONICO": "duar901400@siigo.com",
                    "STATION ID": "2606900",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Alison Anaid Chica Ezeta",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022940036",
                    "FECHA INICIAL": "2023-05-03",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Jhon Alexander",
                    "APELLIDOS": "Porras Aricapa",
                    "NOMBRES COMPLETOS": "Jhon Alexander Porras Aricapa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alison Anaid Chica Ezeta",
                    "CODIGO USUARIO": "porr901425",
                    "USUARIOFIVE9": "porr901425@siigo.com",
                    "USUARIO RED": "porr901425",
                    "CORREOELECTRONICO": "porr901425@siigo.com",
                    "STATION ID": "2606925",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Alison Anaid Chica Ezeta",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022325005",
                    "FECHA INICIAL": "2023-05-10",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Johan Estevan",
                    "APELLIDOS": "Rodriguez Moyano",
                    "NOMBRES COMPLETOS": "Johan Estevan Rodriguez Moyano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Mosquera",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "William Andres Reyes Silva",
                    "CODIGO USUARIO": "rodr901519",
                    "USUARIOFIVE9": "rodr901519@siigo.com",
                    "USUARIO RED": "rodr901519",
                    "CORREOELECTRONICO": "rodr901519@siigo.com",
                    "STATION ID": "2606970",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_William Andres Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1048325101",
                    "FECHA INICIAL": "2023-05-03",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Karem Paola",
                    "APELLIDOS": "Gomez Tete",
                    "NOMBRES COMPLETOS": "Karem Paola Gomez Tete",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Malambo",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Walter Antonio Munoz Antepara",
                    "CODIGO USUARIO": "gome901438",
                    "USUARIOFIVE9": "gome901438@siigo.com",
                    "USUARIO RED": "gome901438",
                    "CORREOELECTRONICO": "gome901438@siigo.com",
                    "STATION ID": "2607003",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Walter Antonio Munoz Antepara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1104706783",
                    "FECHA INICIAL": "2023-05-10",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Leidy Katherine",
                    "APELLIDOS": "Martinez Bautista",
                    "NOMBRES COMPLETOS": "Leidy Katherine Martinez Bautista",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "David Alfredo Sanchez Bastidas",
                    "CODIGO USUARIO": "mart901513",
                    "USUARIOFIVE9": "mart901513@siigo.com",
                    "USUARIO RED": "mart901513",
                    "CORREOELECTRONICO": "mart901513@siigo.com",
                    "STATION ID": "2607055",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_David Alfredo Sanchez Bastidas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010213424",
                    "FECHA INICIAL": "2023-05-03",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Lilibeth Yourley",
                    "APELLIDOS": "Castellanos Rojas",
                    "NOMBRES COMPLETOS": "Lilibeth Yourley Castellanos Rojas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Angel Alfonso Arciniegas Guerrero",
                    "CODIGO USUARIO": "cast901417",
                    "USUARIOFIVE9": "cast901417@siigo.com",
                    "USUARIO RED": "cast901417",
                    "CORREOELECTRONICO": "cast901417@siigo.com",
                    "STATION ID": "2607059",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Angel Alfonso Arciniegas Guerrero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1003527163",
                    "FECHA INICIAL": "2023-05-10",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Luisa Fernanda",
                    "APELLIDOS": "Ortiz Ruiz",
                    "NOMBRES COMPLETOS": "Luisa Fernanda Ortiz Ruiz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cajica",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "David Alfredo Sanchez Bastidas",
                    "CODIGO USUARIO": "orti901527",
                    "USUARIOFIVE9": "orti901527@siigo.com",
                    "USUARIO RED": "orti901527",
                    "CORREOELECTRONICO": "orti901527@siigo.com",
                    "STATION ID": "2607071",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_David Alfredo Sanchez Bastidas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1233505481",
                    "FECHA INICIAL": "2023-05-03",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Maria Camila",
                    "APELLIDOS": "Cancelado Baron",
                    "NOMBRES COMPLETOS": "Maria Camila Cancelado Baron",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Angel Alfonso Arciniegas Guerrero",
                    "CODIGO USUARIO": "canc901453",
                    "USUARIOFIVE9": "canc901453@siigo.com",
                    "USUARIO RED": "canc901453",
                    "CORREOELECTRONICO": "canc901453@siigo.com",
                    "STATION ID": "4421714",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Angel Alfonso Arciniegas Guerrero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1144126335",
                    "FECHA INICIAL": "2023-05-03",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Maria Jimena",
                    "APELLIDOS": "Cardozo Santibanez",
                    "NOMBRES COMPLETOS": "Maria Jimena Cardozo Santibanez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Cali",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Michelle Matamoros Del Rosario",
                    "CODIGO USUARIO": "card901408",
                    "USUARIOFIVE9": "card901408@siigo.com",
                    "USUARIO RED": "card901408",
                    "CORREOELECTRONICO": "card901408@siigo.com",
                    "STATION ID": "3127611",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Michelle Matamoros Del Rosario",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024535146",
                    "FECHA INICIAL": "2023-05-03",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Oscar Andres",
                    "APELLIDOS": "Vasquez Leon",
                    "NOMBRES COMPLETOS": "Oscar Andres Vasquez Leon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alison Anaid Chica Ezeta",
                    "CODIGO USUARIO": "vasq901411",
                    "USUARIOFIVE9": "vasq901411@siigo.com",
                    "USUARIO RED": "vasq901411",
                    "CORREOELECTRONICO": "vasq901411@siigo.com",
                    "STATION ID": "2607120",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Alison Anaid Chica Ezeta",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001834144",
                    "FECHA INICIAL": "2023-05-10",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Samir Estiben",
                    "APELLIDOS": "Valiente Vivanco",
                    "NOMBRES COMPLETOS": "Samir Estiben Valiente Vivanco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ventas",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "vali901496",
                    "USUARIOFIVE9": "vali901496@siigo.com",
                    "USUARIO RED": "vali901496",
                    "CORREOELECTRONICO": "vali901496@siigo.com",
                    "STATION ID": "2607096",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007449711",
                    "FECHA INICIAL": "2023-05-10",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Sebastian",
                    "APELLIDOS": "Serrato Moreno",
                    "NOMBRES COMPLETOS": "Sebastian Serrato Moreno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alison Anaid Chica Ezeta",
                    "CODIGO USUARIO": "serr901429",
                    "USUARIOFIVE9": "serr901429@siigo.com",
                    "USUARIO RED": "serr901429",
                    "CORREOELECTRONICO": "serr901429@siigo.com",
                    "STATION ID": "2606045",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Alison Anaid Chica Ezeta",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1002732380",
                    "FECHA INICIAL": "2023-05-10",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Yenny Tatiana",
                    "APELLIDOS": "Garcia Vargas",
                    "NOMBRES COMPLETOS": "Yenny Tatiana Garcia Vargas",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "David Alfredo Sanchez Bastidas",
                    "CODIGO USUARIO": "garc901517",
                    "USUARIOFIVE9": "garc901517@siigo.com",
                    "USUARIO RED": "garc901517",
                    "CORREOELECTRONICO": "garc901517@siigo.com",
                    "STATION ID": "2606545",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_David Alfredo Sanchez Bastidas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1102363909",
                    "FECHA INICIAL": "2023-05-03",
                    "FECHA OPERACION": "2023-05-16",
                    "NOMBRES": "Yessica Juliana",
                    "APELLIDOS": "Jerez Diaz",
                    "NOMBRES COMPLETOS": "Yessica Juliana Jerez Diaz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Piedecuesta",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Michelle Matamoros Del Rosario",
                    "CODIGO USUARIO": "jere901423",
                    "USUARIOFIVE9": "jere901423@siigo.com",
                    "USUARIO RED": "jere901423",
                    "CORREOELECTRONICO": "jere901423@siigo.com",
                    "STATION ID": "2606036",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Michelle Matamoros Del Rosario",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1233696969",
                    "FECHA INICIAL": "2023-06-02",
                    "FECHA OPERACION": "2023-05-31",
                    "NOMBRES": "Karen Viviana",
                    "APELLIDOS": "Abril Cabrejo",
                    "NOMBRES COMPLETOS": "Karen Viviana Abril Cabrejo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Nataly Carolina Gonzalez Sandoval",
                    "CODIGO USUARIO": "abril901775",
                    "USUARIOFIVE9": "abril901775@siigo.com",
                    "USUARIO RED": "abril901775",
                    "CORREOELECTRONICO": "abril901775@siigo.com",
                    "STATION ID": "2605969",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Nataly Carolina Gonzalez Sandoval",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1065900859",
                    "FECHA INICIAL": "2023-06-02",
                    "FECHA OPERACION": "2023-05-31",
                    "NOMBRES": "Katalina Stephany",
                    "APELLIDOS": "Arrazola Sepulveda",
                    "NOMBRES COMPLETOS": "Katalina Stephany Arrazola Sepulveda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Mercedes Moncada Peraza",
                    "CODIGO USUARIO": "arra901763",
                    "USUARIOFIVE9": "arra901763@siigo.com",
                    "USUARIO RED": "arra901763",
                    "CORREOELECTRONICO": "arra901763@siigo.com",
                    "STATION ID": "2606055",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Mercedes Moncada Peraza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007969621",
                    "FECHA INICIAL": "2023-06-02",
                    "FECHA OPERACION": "2023-05-31",
                    "NOMBRES": "Laura Camila",
                    "APELLIDOS": "Ahumada Luna",
                    "NOMBRES COMPLETOS": "Laura Camila Ahumada Luna",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Sanabalarga",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "ahum901654",
                    "USUARIOFIVE9": "ahum901654@siigo.com",
                    "USUARIO RED": "ahum901654",
                    "CORREOELECTRONICO": "ahum901654@siigo.com",
                    "STATION ID": "2606162",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014298888",
                    "FECHA INICIAL": "2023-06-02",
                    "FECHA OPERACION": "2023-05-31",
                    "NOMBRES": "Laura Natalia",
                    "APELLIDOS": "Lozano Benavides",
                    "NOMBRES COMPLETOS": "Laura Natalia Lozano Benavides",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Cristhian David Nino Barrera",
                    "CODIGO USUARIO": "loza901621",
                    "USUARIOFIVE9": "loza901621@siigo.com",
                    "USUARIO RED": "loza901621",
                    "CORREOELECTRONICO": "loza901621@siigo.com",
                    "STATION ID": "2606191",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Cristhian David Nino Barrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014241564",
                    "FECHA INICIAL": "2023-06-02",
                    "FECHA OPERACION": "2023-05-31",
                    "NOMBRES": "Leidy Daniela",
                    "APELLIDOS": "Solano Nino",
                    "NOMBRES COMPLETOS": "Leidy Daniela Solano Nino",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Zipaquira",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leida Adriana Rico Herrera",
                    "CODIGO USUARIO": "sola901961",
                    "USUARIOFIVE9": "sola901961@siigo.com",
                    "USUARIO RED": "sola901961",
                    "CORREOELECTRONICO": "sola901961@siigo.com",
                    "STATION ID": "2606207",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leida Adriana Rico Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033735866",
                    "FECHA INICIAL": "2023-06-02",
                    "FECHA OPERACION": "2023-05-31",
                    "NOMBRES": "Leidy Jhoanna",
                    "APELLIDOS": "Tibasosa Romero",
                    "NOMBRES COMPLETOS": "Leidy Jhoanna Tibasosa Romero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Nataly Carolina Gonzalez Sandoval",
                    "CODIGO USUARIO": "tiba901752",
                    "USUARIOFIVE9": "tiba901752@siigo.com",
                    "USUARIO RED": "tiba901752",
                    "CORREOELECTRONICO": "tiba901752@siigo.com",
                    "STATION ID": "2606214",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Nataly Carolina Gonzalez Sandoval",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030559973",
                    "FECHA INICIAL": "2023-06-02",
                    "FECHA OPERACION": "2023-05-31",
                    "NOMBRES": "Leidy Johana",
                    "APELLIDOS": "Zambrano Cardozo",
                    "NOMBRES COMPLETOS": "Leidy Johana Zambrano Cardozo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Vicky Alejandra Hernandez Mendez",
                    "CODIGO USUARIO": "zamb901884",
                    "USUARIOFIVE9": "zamb901884@siigo.com",
                    "USUARIO RED": "zamb901884",
                    "CORREOELECTRONICO": "zamb901884@siigo.com",
                    "STATION ID": "2606239",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Vicky Alejandra Hernandez Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1052947984",
                    "FECHA INICIAL": "2023-06-02",
                    "FECHA OPERACION": "2023-05-31",
                    "NOMBRES": "Lina Yohana",
                    "APELLIDOS": "Silva Gaviria",
                    "NOMBRES COMPLETOS": "Lina Yohana Silva Gaviria",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Jamundi",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Plan Contador MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alejandro Lopez Manzo",
                    "CODIGO USUARIO": "silv901627",
                    "USUARIOFIVE9": "silv901627@siigo.com",
                    "USUARIO RED": "silv901627",
                    "CORREOELECTRONICO": "silv901627@siigo.com",
                    "STATION ID": "2606294",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Alejandro Lopez Manzo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1088284372",
                    "FECHA INICIAL": "2023-06-02",
                    "FECHA OPERACION": "2023-05-31",
                    "NOMBRES": "Luz Karime",
                    "APELLIDOS": "Guerra Gongora",
                    "NOMBRES COMPLETOS": "Luz Karime Guerra Gongora",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juvenal Reyes Silva",
                    "CODIGO USUARIO": "guer901879",
                    "USUARIOFIVE9": "guer901879@siigo.com",
                    "USUARIO RED": "guer901879",
                    "CORREOELECTRONICO": "guer901879@siigo.com",
                    "STATION ID": "2606321",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juvenal Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007491922",
                    "FECHA INICIAL": "2023-06-02",
                    "FECHA OPERACION": "2023-05-31",
                    "NOMBRES": "Luz Neida",
                    "APELLIDOS": "Cristiano Montoya",
                    "NOMBRES COMPLETOS": "Luz Neida Cristiano Montoya",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Paz de Ariporo",
                    "AREA": "Customer",
                    "SUBAREA": "Customer",
                    "CARGO": "Lider Customer",
                    "JEFE INMEDIATO": "Yessica Yulieth Quintero Martinez",
                    "CODIGO USUARIO": "cris901631",
                    "USUARIOFIVE9": "cris901631@siigo.com",
                    "USUARIO RED": "cris901631",
                    "CORREOELECTRONICO": "cris901631@siigo.com",
                    "STATION ID": "2606336",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Yessica Yulieth Quintero Martinez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1015404047",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-02",
                    "NOMBRES": "Adriana Maria",
                    "APELLIDOS": "Ruiz Nino",
                    "NOMBRES COMPLETOS": "Adriana Maria Ruiz Nino",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alejandro Lopez Manzo",
                    "CODIGO USUARIO": "ruiz901748",
                    "USUARIOFIVE9": "ruiz901748@siigo.com",
                    "USUARIO RED": "ruiz901748",
                    "CORREOELECTRONICO": "ruiz901748@siigo.com",
                    "STATION ID": "4421802",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Alejandro Lopez Manzo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1129523206",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-02",
                    "NOMBRES": "Ana Carolina",
                    "APELLIDOS": "San Juan Lora",
                    "NOMBRES COMPLETOS": "Ana Carolina San Juan Lora",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alejandro Lopez Manzo",
                    "CODIGO USUARIO": "sanj901746",
                    "USUARIOFIVE9": "sanj901746@siigo.com",
                    "USUARIO RED": "sanj901746",
                    "CORREOELECTRONICO": "sanj901746@siigo.com",
                    "STATION ID": "4421804",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Alejandro Lopez Manzo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016025606",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-02",
                    "NOMBRES": "Andrea Milena",
                    "APELLIDOS": "Montana Celis",
                    "NOMBRES COMPLETOS": "Andrea Milena Montana Celis",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jesus Daniel Arzuza Roman",
                    "CODIGO USUARIO": "mont901665",
                    "USUARIOFIVE9": "mont901665@siigo.com",
                    "USUARIO RED": "mont901665",
                    "CORREOELECTRONICO": "mont901665@siigo.com",
                    "STATION ID": "4421805",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jesus Daniel Arzuza Roman",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1053848962",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-02",
                    "NOMBRES": "Blanca Angelica",
                    "APELLIDOS": "Duque Mezu",
                    "NOMBRES COMPLETOS": "Blanca Angelica Duque Mezu",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Manizales",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leidy Marcela Rodriguez Rivera",
                    "CODIGO USUARIO": "duqu901754",
                    "USUARIOFIVE9": "duqu901754@siigo.com",
                    "USUARIO RED": "duqu901754",
                    "CORREOELECTRONICO": "duqu901754@siigo.com",
                    "STATION ID": "4421806",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leidy Marcela Rodriguez Rivera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1060653993",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-02",
                    "NOMBRES": "Cristian Camilo",
                    "APELLIDOS": "Soto Diaz",
                    "NOMBRES COMPLETOS": "Cristian Camilo Soto Diaz",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Tamesis",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leidy Marcela Rodriguez Rivera",
                    "CODIGO USUARIO": "soto901604",
                    "USUARIOFIVE9": "soto901604@siigo.com",
                    "USUARIO RED": "soto901604",
                    "CORREOELECTRONICO": "soto901604@siigo.com",
                    "STATION ID": "4421808",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leidy Marcela Rodriguez Rivera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1034310268",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-02",
                    "NOMBRES": "Daniel Adolfo",
                    "APELLIDOS": "Racine Valera",
                    "NOMBRES COMPLETOS": "Daniel Adolfo Racine Valera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leida Adriana Rico Herrera",
                    "CODIGO USUARIO": "raci901931",
                    "USUARIOFIVE9": "raci901931@siigo.com",
                    "USUARIO RED": "raci901931",
                    "CORREOELECTRONICO": "raci901931@siigo.com",
                    "STATION ID": "4421812",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leida Adriana Rico Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030555305",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-02",
                    "NOMBRES": "Daniel Gonzalo Eduardo",
                    "APELLIDOS": "Fajardo Alvarez",
                    "NOMBRES COMPLETOS": "Daniel Gonzalo Eduardo Fajardo Alvarez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Mercedes Moncada Peraza",
                    "CODIGO USUARIO": "faja901948",
                    "USUARIOFIVE9": "faja901948@siigo.com",
                    "USUARIO RED": "faja901948",
                    "CORREOELECTRONICO": "faja901948@siigo.com",
                    "STATION ID": "4421813",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Mercedes Moncada Peraza",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019032852",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-02",
                    "NOMBRES": "Diana Marcela",
                    "APELLIDOS": "Romero Lopez",
                    "NOMBRES COMPLETOS": "Diana Marcela Romero Lopez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Buitrago Rios",
                    "CODIGO USUARIO": "rome901946",
                    "USUARIOFIVE9": "rome901946@siigo.com",
                    "USUARIO RED": "rome901946",
                    "CORREOELECTRONICO": "rome901946@siigo.com",
                    "STATION ID": "4421815",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Buitrago Rios",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1026576566",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-02",
                    "NOMBRES": "Fernando",
                    "APELLIDOS": "Sanchez Cadena",
                    "NOMBRES COMPLETOS": "Fernando Sanchez Cadena",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "sanc901767",
                    "USUARIOFIVE9": "sanc901767@siigo.com",
                    "USUARIO RED": "sanc901767",
                    "CORREOELECTRONICO": "sanc901767@siigo.com",
                    "STATION ID": "4421816",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007586959",
                    "FECHA INICIAL": "2023-06-01",
                    "FECHA OPERACION": "2023-06-02",
                    "NOMBRES": "Juan Diego",
                    "APELLIDOS": "Ruano Carrillo",
                    "NOMBRES COMPLETOS": "Juan Diego Ruano Carrillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Huila",
                    "AREA": "Customer",
                    "SUBAREA": "Tecnologia Operacional",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Ecna Lisette Garcia Espinosa",
                    "CODIGO USUARIO": "ruan901567",
                    "USUARIOFIVE9": "ruan901567@siigo.com",
                    "USUARIO RED": "ruan901567",
                    "CORREOELECTRONICO": "ruan901567@siigo.com",
                    "STATION ID": "4421817",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Ecna Lisette Garcia Espinosa",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "55307569",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Maria Alejandra",
                    "APELLIDOS": "Barreto Ferreira",
                    "NOMBRES COMPLETOS": "Maria Alejandra Barreto Ferreira",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Cristhian David Nino Barrera",
                    "CODIGO USUARIO": "barr901658",
                    "USUARIOFIVE9": "barr901658@siigo.com",
                    "USUARIO RED": "barr901658",
                    "CORREOELECTRONICO": "barr901658@siigo.com",
                    "STATION ID": "4421740",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Cristhian David Nino Barrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001822506",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Maria Gabriela",
                    "APELLIDOS": "Orozco Reales",
                    "NOMBRES COMPLETOS": "Maria Gabriela Orozco Reales",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Fundacion",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "oroz901952",
                    "USUARIOFIVE9": "oroz901952@siigo.com",
                    "USUARIO RED": "oroz901952",
                    "CORREOELECTRONICO": "oroz901952@siigo.com",
                    "STATION ID": "4421743",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1012364838",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Marly Liliana",
                    "APELLIDOS": "Yara Cubides",
                    "NOMBRES COMPLETOS": "Marly Liliana Yara Cubides",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "yara901625",
                    "USUARIOFIVE9": "yara901625@siigo.com",
                    "USUARIO RED": "yara901625",
                    "CORREOELECTRONICO": "yara901625@siigo.com",
                    "STATION ID": "4421744",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1193472753",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Mileidis Maria",
                    "APELLIDOS": "Vidal Oviedo",
                    "NOMBRES COMPLETOS": "Mileidis Maria Vidal Oviedo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jesus Daniel Arzuza Roman",
                    "CODIGO USUARIO": "vida901629",
                    "USUARIOFIVE9": "vida901629@siigo.com",
                    "USUARIO RED": "vida901629",
                    "CORREOELECTRONICO": "vida901629@siigo.com",
                    "STATION ID": "4421745",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jesus Daniel Arzuza Roman",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1020816449",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Monica Marcela",
                    "APELLIDOS": "Moreno Gomez",
                    "NOMBRES COMPLETOS": "Monica Marcela Moreno Gomez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leida Adriana Rico Herrera",
                    "CODIGO USUARIO": "more901942",
                    "USUARIOFIVE9": "more901942@siigo.com",
                    "USUARIO RED": "more901942",
                    "CORREOELECTRONICO": "more901942@siigo.com",
                    "STATION ID": "4421746",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leida Adriana Rico Herrera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1045733511",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Natalia Marcela",
                    "APELLIDOS": "Carrasquilla De La Rosa",
                    "NOMBRES COMPLETOS": "Natalia Marcela Carrasquilla De La Rosa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Vicky Alejandra Hernandez Mendez",
                    "CODIGO USUARIO": "carr901685",
                    "USUARIOFIVE9": "carr901685@siigo.com",
                    "USUARIO RED": "carr901685",
                    "CORREOELECTRONICO": "carr901685@siigo.com",
                    "STATION ID": "4421773",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Vicky Alejandra Hernandez Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1033711819",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Nathaly",
                    "APELLIDOS": "Bedoya Osorio",
                    "NOMBRES COMPLETOS": "Nathaly Bedoya Osorio",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Aleida Bibiana Garcia Suarez",
                    "CODIGO USUARIO": "bedo901950",
                    "USUARIOFIVE9": "bedo901950@siigo.com",
                    "USUARIO RED": "bedo901950",
                    "CORREOELECTRONICO": "bedo901950@siigo.com",
                    "STATION ID": "4421776",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Aleida Bibiana Garcia Suarez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014184241",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Sonia Vivian",
                    "APELLIDOS": "Melo Lopez",
                    "NOMBRES COMPLETOS": "Sonia Vivian Melo Lopez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leidy Marcela Rodriguez Rivera",
                    "CODIGO USUARIO": "melo901674",
                    "USUARIOFIVE9": "melo901674@siigo.com",
                    "USUARIO RED": "melo901674",
                    "CORREOELECTRONICO": "melo901674@siigo.com",
                    "STATION ID": "4421778",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leidy Marcela Rodriguez Rivera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018460630",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Wendy Tatiana",
                    "APELLIDOS": "Castellanos Hospital",
                    "NOMBRES COMPLETOS": "Wendy Tatiana Castellanos Hospital",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Andrea Catalina Vega Gomez",
                    "CODIGO USUARIO": "cast901864",
                    "USUARIOFIVE9": "cast901864@siigo.com",
                    "USUARIO RED": "cast901864",
                    "CORREOELECTRONICO": "cast901864@siigo.com",
                    "STATION ID": "4421779",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andrea Catalina Vega Gomez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1053775615",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Yesica Paola",
                    "APELLIDOS": "Aguilar Mira",
                    "NOMBRES COMPLETOS": "Yesica Paola Aguilar Mira",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Manizales",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leidy Marcela Rodriguez Rivera",
                    "CODIGO USUARIO": "agui901623",
                    "USUARIOFIVE9": "agui901623@siigo.com",
                    "USUARIO RED": "agui901623",
                    "CORREOELECTRONICO": "agui901623@siigo.com",
                    "STATION ID": "4421791",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leidy Marcela Rodriguez Rivera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014280989",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Geraldinne",
                    "APELLIDOS": "Borras Torres",
                    "NOMBRES COMPLETOS": "Geraldinne Borras Torres",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Perfilamiento",
                    "CARGO": "Asesor Operativo",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "borr901875",
                    "USUARIOFIVE9": "borr901875@siigo.com",
                    "USUARIO RED": "borr901875",
                    "CORREOELECTRONICO": "borr901875@siigo.com",
                    "STATION ID": "4421697",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018461371",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Hollman Steiguer",
                    "APELLIDOS": "Pinzon Barragan",
                    "NOMBRES COMPLETOS": "Hollman Steiguer Pinzon Barragan",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Manizales",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Leidy Marcela Rodriguez Rivera",
                    "CODIGO USUARIO": "pinz901773",
                    "USUARIOFIVE9": "pinz901773@siigo.com",
                    "USUARIO RED": "pinz901773",
                    "CORREOELECTRONICO": "pinz901773@siigo.com",
                    "STATION ID": "4421698",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Leidy Marcela Rodriguez Rivera",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013599018",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Inerida Esmeralda",
                    "APELLIDOS": "Martinez Romero",
                    "NOMBRES COMPLETOS": "Inerida Esmeralda Martinez Romero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soacha",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Nataly Carolina Gonzalez Sandoval",
                    "CODIGO USUARIO": "mart901656",
                    "USUARIOFIVE9": "mart901656@siigo.com",
                    "USUARIO RED": "mart901656",
                    "CORREOELECTRONICO": "mart901656@siigo.com",
                    "STATION ID": "4421700",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Nataly Carolina Gonzalez Sandoval",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1032414413",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Jairo Hernan",
                    "APELLIDOS": "Franco Castillo",
                    "NOMBRES COMPLETOS": "Jairo Hernan Franco Castillo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Maria Ximena Manrique Isaziga",
                    "CODIGO USUARIO": "fran901765",
                    "USUARIOFIVE9": "fran901765@siigo.com",
                    "USUARIO RED": "fran901765",
                    "CORREOELECTRONICO": "fran901765@siigo.com",
                    "STATION ID": "4421701",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Maria Ximena Manrique Isaziga",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016107394",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Jeffry Stiven",
                    "APELLIDOS": "Gomez Fajardo",
                    "NOMBRES COMPLETOS": "Jeffry Stiven Gomez Fajardo",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Nataly Carolina Gonzalez Sandoval",
                    "CODIGO USUARIO": "gome901761",
                    "USUARIOFIVE9": "gome901761@siigo.com",
                    "USUARIO RED": "gome901761",
                    "CORREOELECTRONICO": "gome901761@siigo.com",
                    "STATION ID": "4421702",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Nataly Carolina Gonzalez Sandoval",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022390807",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Jessica Andrea",
                    "APELLIDOS": "Moreno Sierra",
                    "NOMBRES COMPLETOS": "Jessica Andrea Moreno Sierra",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Comercial MX",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "more901750",
                    "USUARIOFIVE9": "more901750@siigo.com",
                    "USUARIO RED": "more901750",
                    "CORREOELECTRONICO": "more901750@siigo.com",
                    "STATION ID": "4421705",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1144147446",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Jessica Leandra",
                    "APELLIDOS": "Velasco Perez",
                    "NOMBRES COMPLETOS": "Jessica Leandra Velasco Perez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juvenal Reyes Silva",
                    "CODIGO USUARIO": "vela901926",
                    "USUARIOFIVE9": "vela901926@siigo.com",
                    "USUARIO RED": "vela901926",
                    "CORREOELECTRONICO": "vela901926@siigo.com",
                    "STATION ID": "4421706",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juvenal Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1075265399",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Jessica Lorena",
                    "APELLIDOS": "Patino Valencia",
                    "NOMBRES COMPLETOS": "Jessica Lorena Patino Valencia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Maria Naranjo Mattheus",
                    "CODIGO USUARIO": "pati901938",
                    "USUARIOFIVE9": "pati901938@siigo.com",
                    "USUARIO RED": "pati901938",
                    "CORREOELECTRONICO": "pati901938@siigo.com",
                    "STATION ID": "4421708",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Maria Naranjo Mattheus",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1019023999",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Jhon Alexander",
                    "APELLIDOS": "Zamora Carranza",
                    "NOMBRES COMPLETOS": "Jhon Alexander Zamora Carranza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Gustavo Adolfo Espinosa Toledo",
                    "CODIGO USUARIO": "zamo901886",
                    "USUARIOFIVE9": "zamo901886@siigo.com",
                    "USUARIO RED": "zamo901886",
                    "CORREOELECTRONICO": "zamo901886@siigo.com",
                    "STATION ID": "4421709",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Gustavo Adolfo Espinosa Toledo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030587973",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Julieth Catherin",
                    "APELLIDOS": "Londono Morales",
                    "NOMBRES COMPLETOS": "Julieth Catherin Londono Morales",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Vicky Alejandra Hernandez Mendez",
                    "CODIGO USUARIO": "lond901918",
                    "USUARIOFIVE9": "lond901918@siigo.com",
                    "USUARIO RED": "lond901918",
                    "CORREOELECTRONICO": "lond901918@siigo.com",
                    "STATION ID": "4421710",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Vicky Alejandra Hernandez Mendez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016069046",
                    "FECHA INICIAL": "2023-05-31",
                    "FECHA OPERACION": "2023-06-05",
                    "NOMBRES": "Natalia Andrea",
                    "APELLIDOS": "Avila Guauque",
                    "NOMBRES COMPLETOS": "Natalia Andrea Avila Guauque",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Maria Naranjo Mattheus",
                    "CODIGO USUARIO": "avil901940",
                    "USUARIOFIVE9": "avil901940@siigo.com",
                    "USUARIO RED": "avil901940",
                    "CORREOELECTRONICO": "avil901940@siigo.com",
                    "STATION ID": "4421749",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Maria Naranjo Mattheus",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1016058057",
                    "FECHA INICIAL": "2023-06-01",
                    "FECHA OPERACION": "2023-06-07",
                    "NOMBRES": "Leidy Yecenia",
                    "APELLIDOS": "Garzon Urrego",
                    "NOMBRES COMPLETOS": "Leidy Yecenia Garzon Urrego",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Fundacion",
                    "SUBAREA": "Fundacion",
                    "CARGO": "Fundacion",
                    "JEFE INMEDIATO": "Nelsy Adriana Sanchez Ramirez",
                    "CODIGO USUARIO": "garz901573",
                    "USUARIOFIVE9": "garz901573@siigo.com",
                    "USUARIO RED": "garz901573",
                    "CORREOELECTRONICO": "garz901573@siigo.com",
                    "STATION ID": "4421713",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Fundacion_Nelsy Adriana Sanchez Ramirez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007855457",
                    "FECHA INICIAL": "2023-06-02",
                    "FECHA OPERACION": "2023-06-13",
                    "NOMBRES": "Maria Ines",
                    "APELLIDOS": "Merchan Payares",
                    "NOMBRES COMPLETOS": "Maria Ines Merchan Payares",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ventas",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Gloria Estela Osorio Montoya",
                    "CODIGO USUARIO": "merc901571",
                    "USUARIOFIVE9": "merc901571@siigo.com",
                    "USUARIO RED": "merc901571",
                    "CORREOELECTRONICO": "merc901571@siigo.com",
                    "STATION ID": "2605928",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Gloria Estela Osorio Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1002148432",
                    "FECHA INICIAL": "2023-06-07",
                    "FECHA OPERACION": "2023-06-13",
                    "NOMBRES": "Aura Maria",
                    "APELLIDOS": "David Espinosa",
                    "NOMBRES COMPLETOS": "Aura Maria David Espinosa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Sonia Mabel Fernandez Calderon",
                    "CODIGO USUARIO": "davi902048",
                    "USUARIOFIVE9": "davi902048@siigo.com",
                    "USUARIO RED": "davi902048",
                    "CORREOELECTRONICO": "davi902048@siigo.com",
                    "STATION ID": "2606104",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Sonia Mabel Fernandez Calderon",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "43812961",
                    "FECHA INICIAL": "2003-12-01",
                    "FECHA OPERACION": "2019-12-17",
                    "NOMBRES": "Marcela Alexandra",
                    "APELLIDOS": "Arias Echavarria",
                    "NOMBRES COMPLETOS": "Marcela Alexandra Arias Echavarria",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Medellin",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Sanchez Velez",
                    "CODIGO USUARIO": "Aria30654",
                    "USUARIOFIVE9": "Aria30654@siigo.com",
                    "USUARIO RED": "Aria30654",
                    "CORREOELECTRONICO": "Aria30654@siigo.com",
                    "STATION ID": "2606131",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Sanchez Velez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "3363795",
                    "FECHA INICIAL": "2022-10-06",
                    "FECHA OPERACION": "2022-10-11",
                    "NOMBRES": "Charly Jose",
                    "APELLIDOS": "Colmenares Hernandez",
                    "NOMBRES COMPLETOS": "Charly Jose Colmenares Hernandez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Tunja",
                    "AREA": "Comercial",
                    "SUBAREA": "Venta Cruzada Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juan Carlos Sanchez Velez",
                    "CODIGO USUARIO": "colm803902",
                    "USUARIOFIVE9": "colm803902@siigo.com",
                    "USUARIO RED": "colm803902",
                    "CORREOELECTRONICO": "colm803902@siigo.com",
                    "STATION ID": "4421718",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juan Carlos Sanchez Velez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "MOPC870614HYNGTR05",
                    "FECHA INICIAL": "2023-06-20",
                    "FECHA OPERACION": "2023-06-20",
                    "NOMBRES": "Carlos David",
                    "APELLIDOS": "Moguel Pat",
                    "NOMBRES COMPLETOS": "Carlos David Moguel Pat",
                    "PAIS ORIGEN": "Mexico",
                    "PAIS AL QUE LABORA": "Mexico",
                    "CIUDAD OFICINA": "Ciudad de Mexico",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Developer Ssr",
                    "JEFE INMEDIATO": "Carmen del Sagrario Ku Yah",
                    "CODIGO USUARIO": "cmoguel",
                    "USUARIOFIVE9": "cmoguel@aspel.com.mx",
                    "USUARIO RED": "cmoguel",
                    "CORREOELECTRONICO": "cmoguel@aspel.com.mx",
                    "STATION ID": "2606535",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Carmen del Sagrario Ku Yah",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023026569",
                    "FECHA INICIAL": "2020-12-04",
                    "FECHA OPERACION": "2023-06-28",
                    "NOMBRES": "Lilia Johana",
                    "APELLIDOS": "Gonzalez Parrado",
                    "NOMBRES COMPLETOS": "Lilia Johana Gonzalez Parrado",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Administrativo",
                    "SUBAREA": "Administrativo",
                    "CARGO": "Administrativo",
                    "JEFE INMEDIATO": "Marilyn Vanessa Malambo Montoya",
                    "CODIGO USUARIO": "gonz800964",
                    "USUARIOFIVE9": "gonz800964@siigo.com",
                    "USUARIO RED": "gonz800964",
                    "CORREOELECTRONICO": "gonz800964@siigo.com",
                    "STATION ID": "3471486",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Administrativo_Marilyn Vanessa Malambo Montoya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "91508129",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Alvaro Miguel",
                    "APELLIDOS": "Duran Colmenares",
                    "NOMBRES COMPLETOS": "Alvaro Miguel Duran Colmenares",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Cordoba",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Angel Alfonso Arciniegas Guerrero",
                    "CODIGO USUARIO": "dura902360",
                    "USUARIOFIVE9": "dura902360@siigo.com",
                    "USUARIO RED": "dura902360",
                    "CORREOELECTRONICO": "dura902360@siigo.com",
                    "STATION ID": "3483750",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Angel Alfonso Arciniegas Guerrero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1001823893",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Amanda Sofia",
                    "APELLIDOS": "Recuero Garcia",
                    "NOMBRES COMPLETOS": "Amanda Sofia Recuero Garcia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Entrenamiento",
                    "CARGO": "Entrenador",
                    "JEFE INMEDIATO": "Erika Mildred Avila Olaya",
                    "CODIGO USUARIO": "recu902358",
                    "USUARIOFIVE9": "recu902358@siigo.com",
                    "USUARIO RED": "recu902358",
                    "CORREOELECTRONICO": "recu902358@siigo.com",
                    "STATION ID": "3483753",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Erika Mildred Avila Olaya",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023873274",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Andres Ricardo",
                    "APELLIDOS": "Caicedo Moreno",
                    "NOMBRES COMPLETOS": "Andres Ricardo Caicedo Moreno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alison Anaid Chica Ezeta",
                    "CODIGO USUARIO": "caic902368",
                    "USUARIOFIVE9": "caic902368@siigo.com",
                    "USUARIO RED": "caic902368",
                    "CORREOELECTRONICO": "caic902368@siigo.com",
                    "STATION ID": "3483756",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Alison Anaid Chica Ezeta",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1023918342",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Angie Katherine",
                    "APELLIDOS": "Tique Castro",
                    "NOMBRES COMPLETOS": "Angie Katherine Tique Castro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Angel Alfonso Arciniegas Guerrero",
                    "CODIGO USUARIO": "tiqu902396",
                    "USUARIOFIVE9": "tiqu902396@siigo.com",
                    "USUARIO RED": "tiqu902396",
                    "CORREOELECTRONICO": "tiqu902396@siigo.com",
                    "STATION ID": "3483767",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Angel Alfonso Arciniegas Guerrero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1014186423",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Briyith Jazmin",
                    "APELLIDOS": "Garcia Buitrago",
                    "NOMBRES COMPLETOS": "Briyith Jazmin Garcia Buitrago",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Michelle Matamoros Del Rosario",
                    "CODIGO USUARIO": "garc902366",
                    "USUARIOFIVE9": "garc902366@siigo.com",
                    "USUARIO RED": "garc902366",
                    "CORREOELECTRONICO": "garc902366@siigo.com",
                    "STATION ID": "3483769",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Michelle Matamoros Del Rosario",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1024562505",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Bryanth Andres",
                    "APELLIDOS": "Diaz Pinto",
                    "NOMBRES COMPLETOS": "Bryanth Andres Diaz Pinto",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Cartera",
                    "SUBAREA": "Cartera Ventas",
                    "CARGO": "Lider Cartera",
                    "JEFE INMEDIATO": "Edith Angelica Gil Umbarila",
                    "CODIGO USUARIO": "diaz902374",
                    "USUARIOFIVE9": "diaz902374@siigo.com",
                    "USUARIO RED": "diaz902374",
                    "CORREOELECTRONICO": "diaz902374@siigo.com",
                    "STATION ID": "3483772",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Cartera_Edith Angelica Gil Umbarila",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52427689",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Carolina",
                    "APELLIDOS": "Rodriguez Barrera",
                    "NOMBRES COMPLETOS": "Carolina Rodriguez Barrera",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "rodr902321",
                    "USUARIOFIVE9": "rodr902321@siigo.com",
                    "USUARIO RED": "rodr902321",
                    "CORREOELECTRONICO": "rodr902321@siigo.com",
                    "STATION ID": "3483775",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1026257852",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Christian Jeferson",
                    "APELLIDOS": "Romero Moreno",
                    "NOMBRES COMPLETOS": "Christian Jeferson Romero Moreno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jefferson Arvey Ramirez Ibanez",
                    "CODIGO USUARIO": "rome902354",
                    "USUARIOFIVE9": "rome902354@siigo.com",
                    "USUARIO RED": "rome902354",
                    "CORREOELECTRONICO": "rome902354@siigo.com",
                    "STATION ID": "3483776",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jefferson Arvey Ramirez Ibanez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030694055",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Deily Geraldine",
                    "APELLIDOS": "Norena Baez",
                    "NOMBRES COMPLETOS": "Deily Geraldine Norena Baez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "nore902384",
                    "USUARIOFIVE9": "nore902384@siigo.com",
                    "USUARIO RED": "nore902384",
                    "CORREOELECTRONICO": "nore902384@siigo.com",
                    "STATION ID": "3690237",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52753927",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Diana Marcela",
                    "APELLIDOS": "Zaraza Silva",
                    "NOMBRES COMPLETOS": "Diana Marcela Zaraza Silva",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Juliana Castro Diaz",
                    "CODIGO USUARIO": "zara902416",
                    "USUARIOFIVE9": "zara902416@siigo.com",
                    "USUARIO RED": "zara902416",
                    "CORREOELECTRONICO": "zara902416@siigo.com",
                    "STATION ID": "3690242",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Juliana Castro Diaz",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1020765963",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Erika Alejandra",
                    "APELLIDOS": "Valderrama Gacha",
                    "NOMBRES COMPLETOS": "Erika Alejandra Valderrama Gacha",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jefferson Arvey Ramirez Ibanez",
                    "CODIGO USUARIO": "vald90241",
                    "USUARIOFIVE9": "vald902414@siigo.com",
                    "USUARIO RED": "vald90241",
                    "CORREOELECTRONICO": "vald902414@siigo.com",
                    "STATION ID": "3690243",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jefferson Arvey Ramirez Ibanez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007029828",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Evelyn Johanna",
                    "APELLIDOS": "Diaz Penaranda",
                    "NOMBRES COMPLETOS": "Evelyn Johanna Diaz Penaranda",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Alison Anaid Chica Ezeta",
                    "CODIGO USUARIO": "diaz902398",
                    "USUARIOFIVE9": "diaz902398@siigo.com",
                    "USUARIO RED": "diaz902398",
                    "CORREOELECTRONICO": "diaz902398@siigo.com",
                    "STATION ID": "3690264",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Alison Anaid Chica Ezeta",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1026284322",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Heather Eyuceve",
                    "APELLIDOS": "Urrea Bautista",
                    "NOMBRES COMPLETOS": "Heather Eyuceve Urrea Bautista",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "urre902388",
                    "USUARIOFIVE9": "urre902388@siigo.com",
                    "USUARIO RED": "urre902388",
                    "CORREOELECTRONICO": "urre902388@siigo.com",
                    "STATION ID": "3690272",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1040045113",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Heiner Alberto",
                    "APELLIDOS": "Chica Botero",
                    "NOMBRES COMPLETOS": "Heiner Alberto Chica Botero",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "chic902410",
                    "USUARIOFIVE9": "chic902410@siigo.com",
                    "USUARIO RED": "chic902410",
                    "CORREOELECTRONICO": "chic902410@siigo.com",
                    "STATION ID": "3690282",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007389490",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Jessica Paola",
                    "APELLIDOS": "Serrano Serrano",
                    "NOMBRES COMPLETOS": "Jessica Paola Serrano Serrano",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "serr902378",
                    "USUARIOFIVE9": "serr902378@siigo.com",
                    "USUARIO RED": "serr902378",
                    "CORREOELECTRONICO": "serr902378@siigo.com",
                    "STATION ID": "3690284",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1130598753",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Jeyson Andres",
                    "APELLIDOS": "Cordoba Palacios",
                    "NOMBRES COMPLETOS": "Jeyson Andres Cordoba Palacios",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "cord902440",
                    "USUARIOFIVE9": "cord902440@siigo.com",
                    "USUARIO RED": "cord902440",
                    "CORREOELECTRONICO": "cord902440@siigo.com",
                    "STATION ID": "3690286",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1038807923",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Jhonatan",
                    "APELLIDOS": "Ramos Cavadia",
                    "NOMBRES COMPLETOS": "Jhonatan Ramos Cavadia",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Chigordo",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Carol Andrea Cifuentes Alcala",
                    "CODIGO USUARIO": "ramo902317",
                    "USUARIOFIVE9": "ramo902317@siigo.com",
                    "USUARIO RED": "ramo902317",
                    "CORREOELECTRONICO": "ramo902317@siigo.com",
                    "STATION ID": "3690295",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Carol Andrea Cifuentes Alcala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013641705",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Johanna Elizabeth",
                    "APELLIDOS": "Herrera Bonilla",
                    "NOMBRES COMPLETOS": "Johanna Elizabeth Herrera Bonilla",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Mercadeo",
                    "SUBAREA": "Mercadeo",
                    "CARGO": "Mercadeo",
                    "JEFE INMEDIATO": "Estefania Sofia Barbosa Fajardo",
                    "CODIGO USUARIO": "herr902364",
                    "USUARIOFIVE9": "herr902364@siigo.com",
                    "USUARIO RED": "herr902364",
                    "CORREOELECTRONICO": "herr902364@siigo.com",
                    "STATION ID": "3690296",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Mercadeo_Estefania Sofia Barbosa Fajardo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1022437470",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Kamila Alejandra",
                    "APELLIDOS": "Perez Campos",
                    "NOMBRES COMPLETOS": "Kamila Alejandra Perez Campos",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "pere902340",
                    "USUARIOFIVE9": "pere902340@siigo.com",
                    "USUARIO RED": "pere902340",
                    "CORREOELECTRONICO": "pere902340@siigo.com",
                    "STATION ID": "3690308",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1051678854",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Karen Anis",
                    "APELLIDOS": "Rocha Ospino",
                    "NOMBRES COMPLETOS": "Karen Anis Rocha Ospino",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Barranquilla",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Andres Felipe Baron Ballen",
                    "CODIGO USUARIO": "roch902408",
                    "USUARIOFIVE9": "roch902408@siigo.com",
                    "USUARIO RED": "roch902408",
                    "CORREOELECTRONICO": "roch902408@siigo.com",
                    "STATION ID": "3690314",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Andres Felipe Baron Ballen",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1065821471",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Katya Vanessa",
                    "APELLIDOS": "Cera Meza",
                    "NOMBRES COMPLETOS": "Katya Vanessa Cera Meza",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Soledad",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "cera902392",
                    "USUARIOFIVE9": "cera902392@siigo.com",
                    "USUARIO RED": "cera902392",
                    "CORREOELECTRONICO": "cera902392@siigo.com",
                    "STATION ID": "3690330",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52911697",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Kelly Johanna",
                    "APELLIDOS": "Reyes Villalba",
                    "NOMBRES COMPLETOS": "Kelly Johanna Reyes Villalba",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Neiva",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Carol Andrea Cifuentes Alcala",
                    "CODIGO USUARIO": "reye902315",
                    "USUARIOFIVE9": "reye902315@siigo.com",
                    "USUARIO RED": "reye902315",
                    "CORREOELECTRONICO": "reye902315@siigo.com",
                    "STATION ID": "3690331",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Carol Andrea Cifuentes Alcala",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1010229251",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Lady Guilliana",
                    "APELLIDOS": "Chaparro Barbosa",
                    "NOMBRES COMPLETOS": "Lady Guilliana Chaparro Barbosa",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "chap902153",
                    "USUARIOFIVE9": "chap902153@siigo.com",
                    "USUARIO RED": "chap902153",
                    "CORREOELECTRONICO": "chap902153@siigo.com",
                    "STATION ID": "3690335",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1070329444",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Laura Michelle",
                    "APELLIDOS": "Cardona Castro",
                    "NOMBRES COMPLETOS": "Laura Michelle Cardona Castro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "card902313",
                    "USUARIOFIVE9": "card902313@siigo.com",
                    "USUARIO RED": "card902313",
                    "CORREOELECTRONICO": "card902313@siigo.com",
                    "STATION ID": "3690343",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007227944",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Laura Valentina",
                    "APELLIDOS": "Amaya Linares",
                    "NOMBRES COMPLETOS": "Laura Valentina Amaya Linares",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "amay902394",
                    "USUARIOFIVE9": "amay902394@siigo.com",
                    "USUARIO RED": "amay902394",
                    "CORREOELECTRONICO": "amay902394@siigo.com",
                    "STATION ID": "3690352",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1003803810",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Lida Yiced",
                    "APELLIDOS": "Ardila Martinez",
                    "NOMBRES COMPLETOS": "Lida Yiced Ardila Martinez",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "ardi902390",
                    "USUARIOFIVE9": "ardi902390@siigo.com",
                    "USUARIO RED": "ardi902390",
                    "CORREOELECTRONICO": "ardi902390@siigo.com",
                    "STATION ID": "3690358",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "39758144",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Liliana",
                    "APELLIDOS": "Callejas Morales",
                    "NOMBRES COMPLETOS": "Liliana Callejas Morales",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Jefferson Arvey Ramirez Ibanez",
                    "CODIGO USUARIO": "call902400",
                    "USUARIOFIVE9": "call902400@siigo.com",
                    "USUARIO RED": "call902400",
                    "CORREOELECTRONICO": "call902400@siigo.com",
                    "STATION ID": "3690362",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Jefferson Arvey Ramirez Ibanez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013650353",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Lizeth Tatiana",
                    "APELLIDOS": "Pena Linares",
                    "NOMBRES COMPLETOS": "Lizeth Tatiana Pena Linares",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "David Alfredo Sanchez Bastidas",
                    "CODIGO USUARIO": "pena902412",
                    "USUARIOFIVE9": "pena902412@siigo.com",
                    "USUARIO RED": "pena902412",
                    "CORREOELECTRONICO": "pena902412@siigo.com",
                    "STATION ID": "3483715",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_David Alfredo Sanchez Bastidas",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007260269",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Luis Miguel",
                    "APELLIDOS": "Urriola Franco",
                    "NOMBRES COMPLETOS": "Luis Miguel Urriola Franco",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Cartagena",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Aprendiz Sena",
                    "JEFE INMEDIATO": "Magda Lilian Leal Rozo",
                    "CODIGO USUARIO": "urri902336",
                    "USUARIOFIVE9": "urri902336@siigo.com",
                    "USUARIO RED": "urri902336",
                    "CORREOELECTRONICO": "urri902336@siigo.com",
                    "STATION ID": "3690364",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Magda Lilian Leal Rozo",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018413527",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Marilym Tatiana",
                    "APELLIDOS": "Pinzon Castro",
                    "NOMBRES COMPLETOS": "Marilym Tatiana Pinzon Castro",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Villavicencio",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "pinz902338",
                    "USUARIOFIVE9": "pinz902338@siigo.com",
                    "USUARIO RED": "pinz902338",
                    "CORREOELECTRONICO": "pinz902338@siigo.com",
                    "STATION ID": "3690365",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1070963300",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Maritza Yaneth",
                    "APELLIDOS": "Camelo Duarte",
                    "NOMBRES COMPLETOS": "Maritza Yaneth Camelo Duarte",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Facatativa",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "William Andres Reyes Silva",
                    "CODIGO USUARIO": "came902402",
                    "USUARIOFIVE9": "came902402@siigo.com",
                    "USUARIO RED": "came902402",
                    "CORREOELECTRONICO": "came902402@siigo.com",
                    "STATION ID": "3483716",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_William Andres Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1007263669",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Meizy Liliana",
                    "APELLIDOS": "Venner Camacho",
                    "NOMBRES COMPLETOS": "Meizy Liliana Venner Camacho",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "venn902382",
                    "USUARIOFIVE9": "venn902382@siigo.com",
                    "USUARIO RED": "venn902382",
                    "CORREOELECTRONICO": "venn902382@siigo.com",
                    "STATION ID": "3690370",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1069742326",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Natalia Alexandra",
                    "APELLIDOS": "Gonzalez Moreno",
                    "NOMBRES COMPLETOS": "Natalia Alexandra Gonzalez Moreno",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "William Andres Reyes Silva",
                    "CODIGO USUARIO": "gonz902370",
                    "USUARIOFIVE9": "gonz902370@siigo.com",
                    "USUARIO RED": "gonz902370",
                    "CORREOELECTRONICO": "gonz902370@siigo.com",
                    "STATION ID": "3690376",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_William Andres Reyes Silva",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1018494250",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Natalia",
                    "APELLIDOS": "Vides Castrillon",
                    "NOMBRES COMPLETOS": "Natalia Vides Castrillon",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Empresarios Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Diana Michelle Matamoros Del Rosario",
                    "CODIGO USUARIO": "vide902362",
                    "USUARIOFIVE9": "vide902362@siigo.com",
                    "USUARIO RED": "vide902362",
                    "CORREOELECTRONICO": "vide902362@siigo.com",
                    "STATION ID": "3690378",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Diana Michelle Matamoros Del Rosario",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1235040095",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Rosa Maria",
                    "APELLIDOS": "Ahumada Ahumada",
                    "NOMBRES COMPLETOS": "Rosa Maria Ahumada Ahumada",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Angel Alfonso Arciniegas Guerrero",
                    "CODIGO USUARIO": "ahum902350",
                    "USUARIOFIVE9": "ahum902350@siigo.com",
                    "USUARIO RED": "ahum902350",
                    "CORREOELECTRONICO": "ahum902350@siigo.com",
                    "STATION ID": "3690380",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Angel Alfonso Arciniegas Guerrero",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1017147416",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Sara Alejandra",
                    "APELLIDOS": "Jimenez Amaya",
                    "NOMBRES COMPLETOS": "Sara Alejandra Jimenez Amaya",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Sabaneta",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "jime902323",
                    "USUARIOFIVE9": "jime902323@siigo.com",
                    "USUARIO RED": "jime902323",
                    "CORREOELECTRONICO": "jime902323@siigo.com",
                    "STATION ID": "3720332",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1110586151",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Vivian Andrea",
                    "APELLIDOS": "Leal Guevara",
                    "NOMBRES COMPLETOS": "Vivian Andrea Leal Guevara",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Ibague",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "leal902151",
                    "USUARIOFIVE9": "leal902151@siigo.com",
                    "USUARIO RED": "leal902151",
                    "CORREOELECTRONICO": "leal902151@siigo.com",
                    "STATION ID": "3483717",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "52428189",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Yaneth Ximena",
                    "APELLIDOS": "Riano Sarmiento",
                    "NOMBRES COMPLETOS": "Yaneth Ximena Riano Sarmiento",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Ecuador",
                    "CIUDAD OFICINA": "Madrid",
                    "AREA": "Comercial",
                    "SUBAREA": "Aliados Ecuador",
                    "CARGO": "Asesor Comercial",
                    "JEFE INMEDIATO": "Walter Antonio Munoz Antepara",
                    "CODIGO USUARIO": "rian902356",
                    "USUARIOFIVE9": "rian902356@siigo.com",
                    "USUARIO RED": "rian902356",
                    "CORREOELECTRONICO": "rian902356@siigo.com",
                    "STATION ID": "3483721",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Comercial_Walter Antonio Munoz Antepara",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1013659734",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Yessica Mildrey",
                    "APELLIDOS": "Duque Cardona",
                    "NOMBRES COMPLETOS": "Yessica Mildrey Duque Cardona",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "duqu902380",
                    "USUARIOFIVE9": "duqu902380@siigo.com",
                    "USUARIO RED": "duqu902380",
                    "CORREOELECTRONICO": "duqu902380@siigo.com",
                    "STATION ID": "3483722",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "1030561492",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Yuli Alejandra",
                    "APELLIDOS": "Pulido",
                    "NOMBRES COMPLETOS": "Yuli Alejandra Pulido",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "puli902376",
                    "USUARIOFIVE9": "puli902376@siigo.com",
                    "USUARIO RED": "puli902376",
                    "CORREOELECTRONICO": "puli902376@siigo.com",
                    "STATION ID": "3483723",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                },
                {
                    "IDENTIFICACION": "80122753",
                    "FECHA INICIAL": "2023-06-28",
                    "FECHA OPERACION": "2023-06-30",
                    "NOMBRES": "Juan Mauricio",
                    "APELLIDOS": "Parra Parra",
                    "NOMBRES COMPLETOS": "Juan Mauricio Parra Parra",
                    "PAIS ORIGEN": "Colombia",
                    "PAIS AL QUE LABORA": "Colombia",
                    "CIUDAD OFICINA": "Bogota",
                    "AREA": "Customer",
                    "SUBAREA": "Renovaciones",
                    "CARGO": "Asesor Cartera",
                    "JEFE INMEDIATO": "Andres Fabian Gutierrez Gutierrez",
                    "CODIGO USUARIO": "parr902319",
                    "USUARIOFIVE9": "parr902319@siigo.com",
                    "USUARIO RED": "parr902319",
                    "CORREOELECTRONICO": "parr902319@siigo.com",
                    "STATION ID": "3483734",
                    "ANTIGUEDAD": "",
                    "AGENT GROUP": "Customer_Andres Fabian Gutierrez Gutierrez",
                    "LICENCIAS": "",
                    "NOTICIAS": ""
                }
            ]
        }
    ],
    "freeSlots": {},
    "novelties": [
        {
            "_id": {
                "$oid": "64a5bb8c1ec1bc165289c74a"
            },
            "date": "2023-07-19",
            "data": [
                {
                    "ID_AUSENCIA": "1300000016450621",
                    "WORKER_NUMBER": "3316",
                    "IDENTIFICACION_NACIONAL": "LOMA9701043H5",
                    "NOMBRE": "Abigail Lopez Marquez",
                    "DEPARTAMENTO": "Soporte",
                    "TIPO_DE_AUSENCIA": "Vacaciones",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "3",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-19",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016304063",
                    "WORKER_NUMBER": "2685",
                    "IDENTIFICACION_NACIONAL": "FEHA930917RL0",
                    "NOMBRE": "Adolfo Israel Fernandez Hernandez",
                    "DEPARTAMENTO": "Indirect Channel",
                    "TIPO_DE_AUSENCIA": "Vacaciones",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "3",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-19",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "En espera de aprobacion"
                },
                {
                    "ID_AUSENCIA": "1300000015777407",
                    "WORKER_NUMBER": "804054",
                    "IDENTIFICACION_NACIONAL": "25287899",
                    "NOMBRE": "Adriana Marcela Duque",
                    "DEPARTAMENTO": "Quality",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "4",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015665008",
                    "WORKER_NUMBER": "2168",
                    "IDENTIFICACION_NACIONAL": "MESA821019MB3",
                    "NOMBRE": "Adriana Mendoza Santillan",
                    "DEPARTAMENTO": "Soporte",
                    "TIPO_DE_AUSENCIA": "Vacaciones",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "5",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016214098",
                    "WORKER_NUMBER": "801382",
                    "IDENTIFICACION_NACIONAL": "1022408281",
                    "NOMBRE": "Alejandra Guzman Sanchez",
                    "DEPARTAMENTO": "Call Nube",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "1",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-19",
                    "FECHA_FIN_AUSENCIA": "2023-07-19",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000014982211",
                    "WORKER_NUMBER": "223915",
                    "IDENTIFICACION_NACIONAL": "66933415",
                    "NOMBRE": "Ana Bolena Perez Martinez",
                    "DEPARTAMENTO": "Soporte",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "9",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-28",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000012959298",
                    "WORKER_NUMBER": "802309",
                    "IDENTIFICACION_NACIONAL": "1044432800",
                    "NOMBRE": "Ana Maria Corro Altahona",
                    "DEPARTAMENTO": "Soporte",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "4",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016258383",
                    "WORKER_NUMBER": "800007",
                    "IDENTIFICACION_NACIONAL": "1015477076",
                    "NOMBRE": "Ana Maria Moreno Mora",
                    "DEPARTAMENTO": "Client Communication Marketing",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "9",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-28",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016479957",
                    "WORKER_NUMBER": "801505",
                    "IDENTIFICACION_NACIONAL": "1067941458",
                    "NOMBRE": "Andrea Carolina Mercado Vasquez",
                    "DEPARTAMENTO": "Onboarding & Training",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "3",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-18",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Guardado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": ""
                },
                {
                    "ID_AUSENCIA": "1300000016465304",
                    "WORKER_NUMBER": "2419",
                    "IDENTIFICACION_NACIONAL": "LOMA911102PE2",
                    "NOMBRE": "Andrea Yaquelin Lopez Manjarrez",
                    "DEPARTAMENTO": "Soporte",
                    "TIPO_DE_AUSENCIA": "Vacaciones",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "3",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-19",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016413655",
                    "WORKER_NUMBER": "801180",
                    "IDENTIFICACION_NACIONAL": "1234338309",
                    "NOMBRE": "Andres Mauricio Meza Mendez",
                    "DEPARTAMENTO": "E-Commerce",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "13",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-04",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015888413",
                    "WORKER_NUMBER": "802072",
                    "IDENTIFICACION_NACIONAL": "79781919",
                    "NOMBRE": "Angel Alfonso Arciniegas Guerrero",
                    "DEPARTAMENTO": "Aliados",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "4",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Retirado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015888510",
                    "WORKER_NUMBER": "802072",
                    "IDENTIFICACION_NACIONAL": "79781919",
                    "NOMBRE": "Angel Alfonso Arciniegas Guerrero",
                    "DEPARTAMENTO": "Aliados",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "3",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-18",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000014861287",
                    "WORKER_NUMBER": "800236",
                    "IDENTIFICACION_NACIONAL": "1061750280",
                    "NOMBRE": "Angela Maria Guacheta Ibarra",
                    "DEPARTAMENTO": "SIIGO Nube Add-Ons - Latam",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "4",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016172683",
                    "WORKER_NUMBER": "33806",
                    "IDENTIFICACION_NACIONAL": "43987660",
                    "NOMBRE": "Angelica Maria Otalvaro Rios",
                    "DEPARTAMENTO": "Venta Cruzada",
                    "TIPO_DE_AUSENCIA": "Vacaciones en dinero",
                    "MOTIVO_DE_AUSENCIA": "Vacaciones en dinero",
                    "DURACION": "6",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-25",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015480120",
                    "WORKER_NUMBER": "109172",
                    "IDENTIFICACION_NACIONAL": "1030634094",
                    "NOMBRE": "Angy Paola Umbacia Diaz",
                    "DEPARTAMENTO": "Engagement & Retention Empresario",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "9",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-10",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016171825",
                    "WORKER_NUMBER": "801868",
                    "IDENTIFICACION_NACIONAL": "1007273704",
                    "NOMBRE": "Bibiana Yaneth Guevara Cubillos",
                    "DEPARTAMENTO": "Call Nube",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "15",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-08-08",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015997183",
                    "WORKER_NUMBER": "803667",
                    "IDENTIFICACION_NACIONAL": "1152452036",
                    "NOMBRE": "Carolina Jordan Naranjo",
                    "DEPARTAMENTO": "Internal Communications",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "4",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000013668871",
                    "WORKER_NUMBER": "800857",
                    "IDENTIFICACION_NACIONAL": "32369307",
                    "NOMBRE": "Claudio Federico Hornes Ismail",
                    "DEPARTAMENTO": "Venta Cruzada",
                    "TIPO_DE_AUSENCIA": "Vacaciones",
                    "MOTIVO_DE_AUSENCIA": "Vacaciones",
                    "DURACION": "9",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-10",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015685370",
                    "WORKER_NUMBER": "112524",
                    "IDENTIFICACION_NACIONAL": "1002528381",
                    "NOMBRE": "Danna Fernanda Beltran Guevara",
                    "DEPARTAMENTO": "Entrenamiento",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "4",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000014214957",
                    "WORKER_NUMBER": "110109",
                    "IDENTIFICACION_NACIONAL": "1061713459",
                    "NOMBRE": "David Felipe Penagos Mosquera",
                    "DEPARTAMENTO": "Engineering",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "4",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-18",
                    "FECHA_FIN_AUSENCIA": "2023-07-24",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015982328",
                    "WORKER_NUMBER": "800399",
                    "IDENTIFICACION_NACIONAL": "1037655138",
                    "NOMBRE": "David Rosero Calle",
                    "DEPARTAMENTO": "Infraestructura",
                    "TIPO_DE_AUSENCIA": "Vacaciones en dinero",
                    "MOTIVO_DE_AUSENCIA": "Vacaciones en dinero",
                    "DURACION": "7",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-19",
                    "FECHA_FIN_AUSENCIA": "2023-07-28",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015976377",
                    "WORKER_NUMBER": "801886",
                    "IDENTIFICACION_NACIONAL": "1000362943",
                    "NOMBRE": "David Steban Buitrago Duran",
                    "DEPARTAMENTO": "Soporte",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "3",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-19",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000013628130",
                    "WORKER_NUMBER": "802425",
                    "IDENTIFICACION_NACIONAL": "1067814388",
                    "NOMBRE": "Davith Jose Vega Borja",
                    "DEPARTAMENTO": "Call Nube",
                    "TIPO_DE_AUSENCIA": "Dia de cumpleanos",
                    "MOTIVO_DE_AUSENCIA": "Dia de cumpleanos",
                    "DURACION": "1",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-19",
                    "FECHA_FIN_AUSENCIA": "2023-07-19",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015428989",
                    "WORKER_NUMBER": "801141",
                    "IDENTIFICACION_NACIONAL": "1061692838",
                    "NOMBRE": "Deiber Gaviria Alvarado",
                    "DEPARTAMENTO": "Engineering",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "9",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-28",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015429135",
                    "WORKER_NUMBER": "801141",
                    "IDENTIFICACION_NACIONAL": "1061692838",
                    "NOMBRE": "Deiber Gaviria Alvarado",
                    "DEPARTAMENTO": "Engineering",
                    "TIPO_DE_AUSENCIA": "Vacaciones en dinero",
                    "MOTIVO_DE_AUSENCIA": "Vacaciones en dinero",
                    "DURACION": "9",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-28",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015862951",
                    "WORKER_NUMBER": "15948",
                    "IDENTIFICACION_NACIONAL": "1070329084",
                    "NOMBRE": "Deimer Esteban Pena Gonzalez",
                    "DEPARTAMENTO": "SIIGO Nube Core - Latam",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "5",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-14",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000014777641",
                    "WORKER_NUMBER": "800694",
                    "IDENTIFICACION_NACIONAL": "1014306688",
                    "NOMBRE": "Derly Paola Laverde Castiblanco",
                    "DEPARTAMENTO": "Contabilidad",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "9",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-10",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Retirado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000014581970",
                    "WORKER_NUMBER": "110038",
                    "IDENTIFICACION_NACIONAL": "53036681",
                    "NOMBRE": "Diana Sofia Ramos Castillo",
                    "DEPARTAMENTO": "Onboarding & Training",
                    "TIPO_DE_AUSENCIA": "Vacaciones en dinero",
                    "MOTIVO_DE_AUSENCIA": "Vacaciones en dinero",
                    "DURACION": "15",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-10",
                    "FECHA_FIN_AUSENCIA": "2023-07-31",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015028073",
                    "WORKER_NUMBER": "801360",
                    "IDENTIFICACION_NACIONAL": "1033777208",
                    "NOMBRE": "Duwan Estiven Sierra Guerrero",
                    "DEPARTAMENTO": "Engineering",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "9",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-28",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000014882925",
                    "WORKER_NUMBER": "10786",
                    "IDENTIFICACION_NACIONAL": "1014184371",
                    "NOMBRE": "Edwin Alfredo Guayacan Cardenal",
                    "DEPARTAMENTO": "SIIGO Nube Core - Latam",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "4",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015890210",
                    "WORKER_NUMBER": "108114",
                    "IDENTIFICACION_NACIONAL": "53038566",
                    "NOMBRE": "Eliana Heidi Barreto Tovar",
                    "DEPARTAMENTO": "Aliados",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "15",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-10",
                    "FECHA_FIN_AUSENCIA": "2023-07-31",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015544526",
                    "WORKER_NUMBER": "800902",
                    "IDENTIFICACION_NACIONAL": "1088320593",
                    "NOMBRE": "Enith Yohana Loaiza Gonzalez",
                    "DEPARTAMENTO": "Venta Cruzada",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "15",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-04",
                    "FECHA_FIN_AUSENCIA": "2023-07-25",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015107266",
                    "WORKER_NUMBER": "111796",
                    "IDENTIFICACION_NACIONAL": "80087814",
                    "NOMBRE": "Esteban Tellez Garcia",
                    "DEPARTAMENTO": "Sales",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "2",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-19",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015871034",
                    "WORKER_NUMBER": "800499",
                    "IDENTIFICACION_NACIONAL": "80794857",
                    "NOMBRE": "Fabian David Carreno Leon",
                    "DEPARTAMENTO": "Data Analytics",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "7",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-18",
                    "FECHA_FIN_AUSENCIA": "2023-07-27",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "En espera de aprobacion"
                },
                {
                    "ID_AUSENCIA": "1300000016426063",
                    "WORKER_NUMBER": "800861",
                    "IDENTIFICACION_NACIONAL": "28078706",
                    "NOMBRE": "Giselda Keymetlian",
                    "DEPARTAMENTO": "Contabilidad",
                    "TIPO_DE_AUSENCIA": "Vacaciones",
                    "MOTIVO_DE_AUSENCIA": "Vacaciones",
                    "DURACION": "4",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-13",
                    "FECHA_FIN_AUSENCIA": "2023-07-19",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "En espera de aprobacion"
                },
                {
                    "ID_AUSENCIA": "1300000015921208",
                    "WORKER_NUMBER": "800276",
                    "IDENTIFICACION_NACIONAL": "1030581231",
                    "NOMBRE": "Harold Mauricio Toro Vargas",
                    "DEPARTAMENTO": "Call Nube",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "15",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-04",
                    "FECHA_FIN_AUSENCIA": "2023-07-25",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015696612",
                    "WORKER_NUMBER": "16",
                    "IDENTIFICACION_NACIONAL": "DUVH700804KZ9",
                    "NOMBRE": "Hector Duque Vergara",
                    "DEPARTAMENTO": "Indirect Channel",
                    "TIPO_DE_AUSENCIA": "Vacaciones",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "10",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-28",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000011925388",
                    "WORKER_NUMBER": "802105",
                    "IDENTIFICACION_NACIONAL": "1022442245",
                    "NOMBRE": "Ingrid Paola Wilchez Leal",
                    "DEPARTAMENTO": "Call Nube",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "13",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-08-03",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016477707",
                    "WORKER_NUMBER": "800609",
                    "IDENTIFICACION_NACIONAL": "0922330816",
                    "NOMBRE": "Jesus Amado Gaibor Yanez",
                    "DEPARTAMENTO": "Infraestructura",
                    "TIPO_DE_AUSENCIA": "Vacaciones",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "19",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias naturales",
                    "FECHA_INICIO_AUSENCIA": "2023-07-10",
                    "FECHA_FIN_AUSENCIA": "2023-07-28",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000014411194",
                    "WORKER_NUMBER": "112244",
                    "IDENTIFICACION_NACIONAL": "72278102",
                    "NOMBRE": "Jesus Daniel Arzuza Roman",
                    "DEPARTAMENTO": "Aliados",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "6",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-13",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016207834",
                    "WORKER_NUMBER": "801100",
                    "IDENTIFICACION_NACIONAL": "45721829",
                    "NOMBRE": "Johana Micaela Ruiz Diaz Santuro",
                    "DEPARTAMENTO": "Soporte",
                    "TIPO_DE_AUSENCIA": "Vacaciones",
                    "MOTIVO_DE_AUSENCIA": "Vacaciones",
                    "DURACION": "4",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Retirado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015930786",
                    "WORKER_NUMBER": "112126",
                    "IDENTIFICACION_NACIONAL": "1082908974",
                    "NOMBRE": "Jordan Hernandez Villegas",
                    "DEPARTAMENTO": "Aliados",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "7",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-19",
                    "FECHA_FIN_AUSENCIA": "2023-07-28",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000012786812",
                    "WORKER_NUMBER": "801674",
                    "IDENTIFICACION_NACIONAL": "1033695430",
                    "NOMBRE": "Jose Armel Zambrano Trujillo",
                    "DEPARTAMENTO": "Soporte",
                    "TIPO_DE_AUSENCIA": "Dias Casuales Admin",
                    "MOTIVO_DE_AUSENCIA": "Dia de la familia",
                    "DURACION": "1",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-19",
                    "FECHA_FIN_AUSENCIA": "2023-07-19",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016510917",
                    "WORKER_NUMBER": "112170",
                    "IDENTIFICACION_NACIONAL": "1061780881",
                    "NOMBRE": "Jose Julio Tobar Cifuentes",
                    "DEPARTAMENTO": "Engineering",
                    "TIPO_DE_AUSENCIA": "Vacaciones en dinero",
                    "MOTIVO_DE_AUSENCIA": "Vacaciones en dinero",
                    "DURACION": "15",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-04",
                    "FECHA_FIN_AUSENCIA": "2023-07-25",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "En espera de aprobacion"
                },
                {
                    "ID_AUSENCIA": "1300000014868143",
                    "WORKER_NUMBER": "801147",
                    "IDENTIFICACION_NACIONAL": "79596420",
                    "NOMBRE": "Juan Carlos Valdes Martin",
                    "DEPARTAMENTO": "Communications & Media",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "11",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-06",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016505212",
                    "WORKER_NUMBER": "801488",
                    "IDENTIFICACION_NACIONAL": "8027027",
                    "NOMBRE": "Juan Esteban Restrepo Cardona",
                    "DEPARTAMENTO": "Product Design",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "4",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "En espera de aprobacion"
                },
                {
                    "ID_AUSENCIA": "1300000015695579",
                    "WORKER_NUMBER": "802685",
                    "IDENTIFICACION_NACIONAL": "1020726107",
                    "NOMBRE": "Juan Jose Correa Acosta",
                    "DEPARTAMENTO": "Engagement & Retention Core",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "12",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-04",
                    "FECHA_FIN_AUSENCIA": "2023-07-19",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015970456",
                    "WORKER_NUMBER": "804195",
                    "IDENTIFICACION_NACIONAL": "1014270186",
                    "NOMBRE": "Katherinne Vanessa Cantor Ceballos",
                    "DEPARTAMENTO": "Venta Cruzada",
                    "TIPO_DE_AUSENCIA": "Dias de matrimonio",
                    "MOTIVO_DE_AUSENCIA": "Dias de matrimonio",
                    "DURACION": "3",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-19",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016200592",
                    "WORKER_NUMBER": "803345",
                    "IDENTIFICACION_NACIONAL": "1095815235",
                    "NOMBRE": "Laura Alejandra Chaves Uribe",
                    "DEPARTAMENTO": "Venta Cruzada",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "8",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-14",
                    "FECHA_FIN_AUSENCIA": "2023-07-26",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000012972362",
                    "WORKER_NUMBER": "802220",
                    "IDENTIFICACION_NACIONAL": "1020790033",
                    "NOMBRE": "Laura Camila Romero Rubio",
                    "DEPARTAMENTO": "HSE & Workplace",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "25",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-13",
                    "FECHA_FIN_AUSENCIA": "2023-08-18",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015377367",
                    "WORKER_NUMBER": "801135",
                    "IDENTIFICACION_NACIONAL": "1019132393",
                    "NOMBRE": "Laura Catalina Chica Lopez",
                    "DEPARTAMENTO": "Client Communication Marketing",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "8",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-11",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016199919",
                    "WORKER_NUMBER": "3006",
                    "IDENTIFICACION_NACIONAL": "GURL900303RS2",
                    "NOMBRE": "Leidy Guadarrama Reyna",
                    "DEPARTAMENTO": "Indirect Channel",
                    "TIPO_DE_AUSENCIA": "Vacaciones",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "3",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-19",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Denegado"
                },
                {
                    "ID_AUSENCIA": "1300000016279964",
                    "WORKER_NUMBER": "3006",
                    "IDENTIFICACION_NACIONAL": "GURL900303RS2",
                    "NOMBRE": "Leidy Guadarrama Reyna",
                    "DEPARTAMENTO": "Indirect Channel",
                    "TIPO_DE_AUSENCIA": "Vacaciones",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "3",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-19",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015078248",
                    "WORKER_NUMBER": "802023",
                    "IDENTIFICACION_NACIONAL": "1014186545",
                    "NOMBRE": "Libardo Andres Heredia Posada",
                    "DEPARTAMENTO": "Onboarding & Training",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "9",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-28",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016414165",
                    "WORKER_NUMBER": "111872",
                    "IDENTIFICACION_NACIONAL": "43827259",
                    "NOMBRE": "Liliana Patricia Giraldo Gomez",
                    "DEPARTAMENTO": "Aliados",
                    "TIPO_DE_AUSENCIA": "Vacaciones en dinero",
                    "MOTIVO_DE_AUSENCIA": "Vacaciones en dinero",
                    "DURACION": "5",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-24",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "En espera de aprobacion"
                },
                {
                    "ID_AUSENCIA": "1300000016414050",
                    "WORKER_NUMBER": "111872",
                    "IDENTIFICACION_NACIONAL": "43827259",
                    "NOMBRE": "Liliana Patricia Giraldo Gomez",
                    "DEPARTAMENTO": "Aliados",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "5",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-24",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "En espera de aprobacion"
                },
                {
                    "ID_AUSENCIA": "1300000014394530",
                    "WORKER_NUMBER": "803344",
                    "IDENTIFICACION_NACIONAL": "1061692009",
                    "NOMBRE": "Lina Marisol Molina Insuasty",
                    "DEPARTAMENTO": "Quality",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "4",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016238266",
                    "WORKER_NUMBER": "801452",
                    "IDENTIFICACION_NACIONAL": "1019109634",
                    "NOMBRE": "Lina Vanessa Espinosa Poveda",
                    "DEPARTAMENTO": "Venta Cruzada",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "7",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-12",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015845045",
                    "WORKER_NUMBER": "802153",
                    "IDENTIFICACION_NACIONAL": "1020737665",
                    "NOMBRE": "Luis Felipe Zea Obando",
                    "DEPARTAMENTO": "SIIGO Nube Core - Latam",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "3",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-19",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015998922",
                    "WORKER_NUMBER": "802706",
                    "IDENTIFICACION_NACIONAL": "1022420657",
                    "NOMBRE": "Luisa Fernanda Cancelado Baron",
                    "DEPARTAMENTO": "Venta Cruzada",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "12",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-13",
                    "FECHA_FIN_AUSENCIA": "2023-07-31",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015977593",
                    "WORKER_NUMBER": "801913",
                    "IDENTIFICACION_NACIONAL": "1001938467",
                    "NOMBRE": "Luz Daniela Estrada Pino",
                    "DEPARTAMENTO": "Call Nube",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "2",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-19",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015887816",
                    "WORKER_NUMBER": "801837",
                    "IDENTIFICACION_NACIONAL": "1083026746",
                    "NOMBRE": "Luz Elena Torres Baslanoa",
                    "DEPARTAMENTO": "Call Nube",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "12",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-04",
                    "FECHA_FIN_AUSENCIA": "2023-07-19",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016456527",
                    "WORKER_NUMBER": "29615",
                    "IDENTIFICACION_NACIONAL": "43544312",
                    "NOMBRE": "Luz Katerine Maya Mogollon",
                    "DEPARTAMENTO": "Call Nube",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "9",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-28",
                    "ESTADO_DE_LA_AUSENCIA": "Retirado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016252370",
                    "WORKER_NUMBER": "802167",
                    "IDENTIFICACION_NACIONAL": "0909138976",
                    "NOMBRE": "Magdalena Cinzia Alvarado Vergara",
                    "DEPARTAMENTO": "Contabilidad",
                    "TIPO_DE_AUSENCIA": "Vacaciones",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "2",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias naturales",
                    "FECHA_INICIO_AUSENCIA": "2023-07-19",
                    "FECHA_FIN_AUSENCIA": "2023-07-20",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000014849044",
                    "WORKER_NUMBER": "800239",
                    "IDENTIFICACION_NACIONAL": "1061782799",
                    "NOMBRE": "Mallory Julieth Munoz Gutierrez",
                    "DEPARTAMENTO": "Quality",
                    "TIPO_DE_AUSENCIA": "Vacaciones en dinero",
                    "MOTIVO_DE_AUSENCIA": "Vacaciones en dinero",
                    "DURACION": "9",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-28",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000014849130",
                    "WORKER_NUMBER": "800239",
                    "IDENTIFICACION_NACIONAL": "1061782799",
                    "NOMBRE": "Mallory Julieth Munoz Gutierrez",
                    "DEPARTAMENTO": "Quality",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "9",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-28",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000012443098",
                    "WORKER_NUMBER": "803768",
                    "IDENTIFICACION_NACIONAL": "1052358900",
                    "NOMBRE": "Maria Carolina Segura Sanabria",
                    "DEPARTAMENTO": "Soporte",
                    "TIPO_DE_AUSENCIA": "Softlanding",
                    "MOTIVO_DE_AUSENCIA": "Medio tiempo 2 semanas",
                    "DURACION": "10",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-06",
                    "FECHA_FIN_AUSENCIA": "2023-07-19",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015821071",
                    "WORKER_NUMBER": "800800",
                    "IDENTIFICACION_NACIONAL": "1031143842",
                    "NOMBRE": "Maria Fernanda Vera Soriano",
                    "DEPARTAMENTO": "SIIGO Nube Add-Ons - Latam",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "5",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-14",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016208155",
                    "WORKER_NUMBER": "803287",
                    "IDENTIFICACION_NACIONAL": "37341570",
                    "NOMBRE": "Martin Alejandro Marcher Bertotto",
                    "DEPARTAMENTO": "Strategic Marketing Segments",
                    "TIPO_DE_AUSENCIA": "Vacaciones",
                    "MOTIVO_DE_AUSENCIA": "Vacaciones",
                    "DURACION": "4",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Denegado"
                },
                {
                    "ID_AUSENCIA": "1300000016412308",
                    "WORKER_NUMBER": "112130",
                    "IDENTIFICACION_NACIONAL": "1075272716",
                    "NOMBRE": "Mary Shiseth Riveros Palma",
                    "DEPARTAMENTO": "Aliados",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "8",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-19",
                    "FECHA_FIN_AUSENCIA": "2023-07-31",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015805329",
                    "WORKER_NUMBER": "801110",
                    "IDENTIFICACION_NACIONAL": "45804037",
                    "NOMBRE": "Matias Alejandro Menendez Sanguinedo",
                    "DEPARTAMENTO": "Legacy & Localizadas",
                    "TIPO_DE_AUSENCIA": "Vacaciones",
                    "MOTIVO_DE_AUSENCIA": "Vacaciones",
                    "DURACION": "3",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-19",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "En espera de aprobacion"
                },
                {
                    "ID_AUSENCIA": "1300000016004923",
                    "WORKER_NUMBER": "801127",
                    "IDENTIFICACION_NACIONAL": "42955502",
                    "NOMBRE": "Mauricio Ramirez Minetti",
                    "DEPARTAMENTO": "Legacy & Localizadas",
                    "TIPO_DE_AUSENCIA": "Vacaciones",
                    "MOTIVO_DE_AUSENCIA": "Vacaciones",
                    "DURACION": "4",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "En espera de aprobacion"
                },
                {
                    "ID_AUSENCIA": "1300000015083760",
                    "WORKER_NUMBER": "111760",
                    "IDENTIFICACION_NACIONAL": "1014300834",
                    "NOMBRE": "Miguel Angel Gomez Espinosa",
                    "DEPARTAMENTO": "Engineering",
                    "TIPO_DE_AUSENCIA": "Vacaciones en dinero",
                    "MOTIVO_DE_AUSENCIA": "Vacaciones en dinero",
                    "DURACION": "15",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-04",
                    "FECHA_FIN_AUSENCIA": "2023-07-25",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015083378",
                    "WORKER_NUMBER": "111760",
                    "IDENTIFICACION_NACIONAL": "1014300834",
                    "NOMBRE": "Miguel Angel Gomez Espinosa",
                    "DEPARTAMENTO": "Engineering",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "15",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-04",
                    "FECHA_FIN_AUSENCIA": "2023-07-25",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000013131900",
                    "WORKER_NUMBER": "801589",
                    "IDENTIFICACION_NACIONAL": "1026594500",
                    "NOMBRE": "Miguel Angel Parada Canon",
                    "DEPARTAMENTO": "Engineering",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "19",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-04",
                    "FECHA_FIN_AUSENCIA": "2023-07-31",
                    "ESTADO_DE_LA_AUSENCIA": "Retirado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015952625",
                    "WORKER_NUMBER": "801832",
                    "IDENTIFICACION_NACIONAL": "1057590136",
                    "NOMBRE": "Monica Lizeth Sanabria Vargas",
                    "DEPARTAMENTO": "Call Nube",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "2",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-18",
                    "FECHA_FIN_AUSENCIA": "2023-07-19",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015922390",
                    "WORKER_NUMBER": "112141",
                    "IDENTIFICACION_NACIONAL": "1026293879",
                    "NOMBRE": "Nicolas Gualtero Tovar",
                    "DEPARTAMENTO": "Quality",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "9",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-10",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015474268",
                    "WORKER_NUMBER": "800378",
                    "IDENTIFICACION_NACIONAL": "1069305921",
                    "NOMBRE": "Norma Constanza Cajica Rodriguez",
                    "DEPARTAMENTO": "Onboarding & Training",
                    "TIPO_DE_AUSENCIA": "Dia de cumpleanos",
                    "MOTIVO_DE_AUSENCIA": "Dia de cumpleanos",
                    "DURACION": "1",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-19",
                    "FECHA_FIN_AUSENCIA": "2023-07-19",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016231628",
                    "WORKER_NUMBER": "2118",
                    "IDENTIFICACION_NACIONAL": "MOYO9101038XA",
                    "NOMBRE": "Ollinyoliliztli Morales Yanez",
                    "DEPARTAMENTO": "Soporte",
                    "TIPO_DE_AUSENCIA": "Vacaciones",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "1",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-19",
                    "FECHA_FIN_AUSENCIA": "2023-07-19",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015020436",
                    "WORKER_NUMBER": "800906",
                    "IDENTIFICACION_NACIONAL": "52860300",
                    "NOMBRE": "Paola Rico Escobar",
                    "DEPARTAMENTO": "Small and Medium Business",
                    "TIPO_DE_AUSENCIA": "Vacaciones en dinero",
                    "MOTIVO_DE_AUSENCIA": "Vacaciones en dinero",
                    "DURACION": "6",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-12",
                    "FECHA_FIN_AUSENCIA": "2023-07-19",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015845654",
                    "WORKER_NUMBER": "801224",
                    "IDENTIFICACION_NACIONAL": "1113640439",
                    "NOMBRE": "Pedro Felipe Arana Lastra",
                    "DEPARTAMENTO": "Engagement & Retention Empresario",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "1",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-19",
                    "FECHA_FIN_AUSENCIA": "2023-07-19",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016220069",
                    "WORKER_NUMBER": "884",
                    "IDENTIFICACION_NACIONAL": "RUMR820106190",
                    "NOMBRE": "Reyna Gricelda Ruiz Medina",
                    "DEPARTAMENTO": "Engineering",
                    "TIPO_DE_AUSENCIA": "Vacaciones",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "10",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-28",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016226653",
                    "WORKER_NUMBER": "3273",
                    "IDENTIFICACION_NACIONAL": "MUVR780112P98",
                    "NOMBRE": "Ricardo Aurelio Munoz Valdes",
                    "DEPARTAMENTO": "Soporte",
                    "TIPO_DE_AUSENCIA": "Vacaciones",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "5",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015759805",
                    "WORKER_NUMBER": "3252",
                    "IDENTIFICACION_NACIONAL": "CUGS860609CZ4",
                    "NOMBRE": "Salvador Cruz Garcia",
                    "DEPARTAMENTO": "Quality",
                    "TIPO_DE_AUSENCIA": "Vacaciones",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "3",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-19",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "En espera de aprobacion"
                },
                {
                    "ID_AUSENCIA": "1300000014787599",
                    "WORKER_NUMBER": "800685",
                    "IDENTIFICACION_NACIONAL": "1014271756",
                    "NOMBRE": "Samuel Antonio Sanchez Jimenez",
                    "DEPARTAMENTO": "Entrenamiento",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "9",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-10",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Retirado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015980947",
                    "WORKER_NUMBER": "801722",
                    "IDENTIFICACION_NACIONAL": "52075893",
                    "NOMBRE": "Sandra Patricia Caro Aponte",
                    "DEPARTAMENTO": "Venta Cruzada",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "15",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-10",
                    "FECHA_FIN_AUSENCIA": "2023-07-31",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016407916",
                    "WORKER_NUMBER": "800517",
                    "IDENTIFICACION_NACIONAL": "1013657349",
                    "NOMBRE": "Sara Natalia Rondon Martinez",
                    "DEPARTAMENTO": "Engagement & Retention Empresario",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "14",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-04",
                    "FECHA_FIN_AUSENCIA": "2023-07-24",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015938412",
                    "WORKER_NUMBER": "112010",
                    "IDENTIFICACION_NACIONAL": "71711929",
                    "NOMBRE": "Sergio Alberto Raigosa Hernandez",
                    "DEPARTAMENTO": "Compras y Administracion",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "13",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-04",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015058852",
                    "WORKER_NUMBER": "801774",
                    "IDENTIFICACION_NACIONAL": "1090522256",
                    "NOMBRE": "Silvana Camila Jaimes Gafaro",
                    "DEPARTAMENTO": "Planeacion Comercial",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "13",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-04",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016283263",
                    "WORKER_NUMBER": "800635",
                    "IDENTIFICACION_NACIONAL": "0922886726",
                    "NOMBRE": "Steffany Mabell Vallejo Castro",
                    "DEPARTAMENTO": "Engineering",
                    "TIPO_DE_AUSENCIA": "Vacaciones",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "9",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias naturales",
                    "FECHA_INICIO_AUSENCIA": "2023-07-18",
                    "FECHA_FIN_AUSENCIA": "2023-07-26",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000015704735",
                    "WORKER_NUMBER": "801049",
                    "IDENTIFICACION_NACIONAL": "1053853992",
                    "NOMBRE": "Valentina Loaiza Gonzalez",
                    "DEPARTAMENTO": "Proyeccion Negocio/Perfilamiento",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "4",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-21",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                },
                {
                    "ID_AUSENCIA": "1300000016499234",
                    "WORKER_NUMBER": "800346",
                    "IDENTIFICACION_NACIONAL": "1010237720",
                    "NOMBRE": "Yessica Paola Uribe Castano",
                    "DEPARTAMENTO": "Onboarding & Training",
                    "TIPO_DE_AUSENCIA": "Vacaciones en tiempo",
                    "MOTIVO_DE_AUSENCIA": "Dias de vacaciones",
                    "DURACION": "5",
                    "UNIDAD_DE_MEDIDA_DURACION": "Dias",
                    "FECHA_INICIO_AUSENCIA": "2023-07-17",
                    "FECHA_FIN_AUSENCIA": "2023-07-24",
                    "ESTADO_DE_LA_AUSENCIA": "Enviado",
                    "ESTADO_DE_APROBACION_DE_LA_AUSENCIA": "Aprobado"
                }
            ]
        }
    ],
    "schedules": [
        {
            "_id": "2023-07-19beni801366@siigo.com",
            "Area": "Call Uruguay",
            "Cedula": "43159434",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:30 - 16:45",
            "Email": "beni801366@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "9:00:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:30",
            "Nombre": "Andres Jorge Benitez Caffaro",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19arob801393@siigo.com",
            "Area": "Call Uruguay",
            "Cedula": "47457185",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:30 - 16:45",
            "Email": "arob801393@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "9:00:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:30",
            "Nombre": "Diego Arobba Corbo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rodr801114@siigo.com",
            "Area": "Call Uruguay",
            "Cedula": "53029679",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:45 - 17:00",
            "Email": "rodr801114@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "17:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "9:00:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:45",
            "Nombre": "Maximiliano Ariel Rodriguez Deangelis",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19gile803907@siigo.com",
            "Area": "Call Uruguay",
            "Cedula": "1118569249",
            "Desc 1": "11:00 - 11:15",
            "Desc 2": "16:45 - 17:00",
            "Email": "gile803907@siigo.com",
            "FIN Break 1": "11:15",
            "FIN Break 2": "17:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:00:00",
            "H Ini Alm": "14:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "9:00:00",
            "IN Break 1": "11:00",
            "IN Break 2": "16:45",
            "Nombre": "Claudia Yamile Gil Estupinan",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19lope803917@siigo.com",
            "Area": "Call Uruguay",
            "Cedula": "1049645504",
            "Desc 1": "11:15 - 11:30",
            "Desc 2": "17:00 - 17:15",
            "Email": "lope803917@siigo.com",
            "FIN Break 1": "11:30",
            "FIN Break 2": "17:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:30:00",
            "H Ini Alm": "14:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "9:00:00",
            "IN Break 1": "11:15",
            "IN Break 2": "17:00",
            "Nombre": "Erika Paola Lopez Cative",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cast803916@siigo.com",
            "Area": "Call Uruguay",
            "Cedula": "1001339906",
            "Desc 1": "11:30 - 11:45",
            "Desc 2": "17:15 - 17:30",
            "Email": "cast803916@siigo.com",
            "FIN Break 1": "11:45",
            "FIN Break 2": "17:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:30:00",
            "H Ini Alm": "14:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "9:00:00",
            "IN Break 1": "11:30",
            "IN Break 2": "17:15",
            "Nombre": "Karen Valentina Castellanos Munoz",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19zeam803915@siigo.com",
            "Area": "Call Uruguay",
            "Cedula": "1049642941",
            "Desc 1": "11:45 - 12:00",
            "Desc 2": "17:30 - 17:45",
            "Email": "zeam803915@siigo.com",
            "FIN Break 1": "12:00",
            "FIN Break 2": "17:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "16:30:00",
            "H Ini Alm": "15:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "9:00:00",
            "IN Break 1": "11:45",
            "IN Break 2": "17:30",
            "Nombre": "Tania Milena Zea Morales",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19varg803598@siigo.com",
            "Area": "Soporte Texto",
            "Cedula": "1049649123",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "15:00 - 15:15",
            "Email": "varg803598@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "16:30:00",
            "Hr Inicio": "7:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "15:00",
            "Nombre": "Angelica Juliana Vargas Espinosa",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19meza801847@siigo.com",
            "Area": "Soporte Texto",
            "Cedula": "1234095390",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:15 - 15:30",
            "Email": "meza801847@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "16:30:00",
            "Hr Inicio": "7:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:15",
            "Nombre": "Maryuris Sarife Meza Fernandez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19suar802423@siigo.com",
            "Area": "Soporte Texto",
            "Cedula": "1057591347",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "15:00 - 15:15",
            "Email": "suar802423@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "16:30:00",
            "Hr Inicio": "7:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "15:00",
            "Nombre": "Yiselt Nataly Suarez Mesa",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19corr801643@siigo.com",
            "Area": "Soporte Texto",
            "Cedula": "1000502517",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:15 - 15:30",
            "Email": "corr801643@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "16:30:00",
            "Hr Inicio": "7:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:15",
            "Nombre": "Nicole Stefani Corredor Herreno",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19espi802122@siigo.com",
            "Area": "Soporte Texto",
            "Cedula": "1001043634",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:00 - 15:15",
            "Email": "espi802122@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:00",
            "Nombre": "Angie Gabriela Espinosa Molina",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19guti801030@siigo.com",
            "Area": "Soporte Texto",
            "Cedula": "1024560724",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:15 - 15:30",
            "Email": "guti801030@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:15",
            "Nombre": "Angie Marcela Gutierrez Moreno",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19vale802126@siigo.com",
            "Area": "Soporte Texto",
            "Cedula": "1098787159",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:30 - 15:45",
            "Email": "vale802126@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:30",
            "Nombre": "Azly Valencia Gonzalez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19bord803608@siigo.com",
            "Area": "Soporte Texto",
            "Cedula": "1002370313",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:45 - 16:00",
            "Email": "bord803608@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:45",
            "Nombre": "Christian Camilo Borda Sanabria",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19Ruiz802459@siigo.com",
            "Area": "Soporte Texto",
            "Cedula": "1006820388",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:30 - 15:45",
            "Email": "Ruiz802459@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:30",
            "Nombre": "Daniela Johana Ruiz Torres",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19reyv801289@siigo.com",
            "Area": "Soporte Texto",
            "Cedula": "53930236",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:45 - 16:00",
            "Email": "reyv801289@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:45",
            "Nombre": "Luz Adriana Rey Villarraga",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19torr801837@siigo.com",
            "Area": "Soporte Texto",
            "Cedula": "1083026746",
            "Desc 1": "0:00:00",
            "Desc 2": "0:00:00",
            "Email": "torr801837@siigo.com",
            "FIN Break 1": "0",
            "FIN Break 2": "0",
            "Fecha": "2023-07-19",
            "H Fin Alm": "0:00:00",
            "H Ini Alm": "0:00:00",
            "Hr Fin": "Vacaciones",
            "Hr Inicio": "Vacaciones",
            "IN Break 1": "0",
            "IN Break 2": "0",
            "Nombre": "Luz Elena Torres Baslanoa",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19jime801280@siigo.com",
            "Area": "Soporte Texto",
            "Cedula": "1143269894",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:15 - 16:30",
            "Email": "jime801280@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:00:00",
            "H Ini Alm": "14:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:15",
            "Nombre": "Angellys Cecilia Jimenez Aguas",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19bayo112484@siigo.com",
            "Area": "Soporte Texto",
            "Cedula": "1035440213",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "bayo112484@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:00:00",
            "H Ini Alm": "14:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Daniela Bayona Gaviria",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rodr800869@siigo.com",
            "Area": "Soporte Texto",
            "Cedula": "1024494631",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:15 - 16:30",
            "Email": "rodr800869@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:15",
            "Nombre": "Fanny Elvira Rodriguez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19card803730@siigo.com",
            "Area": "Soporte Texto",
            "Cedula": "1072430137",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:00 - 16:15",
            "Email": "card803730@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:00:00",
            "H Ini Alm": "14:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:00",
            "Nombre": "Laura Juliana Cardenas Montero",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19vald802506@siigo.com",
            "Area": "Nube",
            "Cedula": "1007395400",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "16:15 - 16:30",
            "Email": "vald802506@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13.00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "16:15",
            "Nombre": "Monica Judith Valderrama Tibaduiza",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19anga803731@siigo.com",
            "Area": "Soporte Texto",
            "Cedula": "1140889018",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:00 - 16:15",
            "Email": "anga803731@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:00:00",
            "H Ini Alm": "14:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:00",
            "Nombre": "Lisleidy Angarita Prada",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19nava801775@siigo.com",
            "Area": "Soporte Texto",
            "Cedula": "1057594815",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:15 - 16:30",
            "Email": "nava801775@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:15",
            "Nombre": "William Andres Navas Cardenas",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19caba111749@siigo.com",
            "Area": "Renovaciones Normal",
            "Cedula": "1012439608",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "14:30 - 14:45",
            "Email": "caba111749@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "14:30",
            "Nombre": "Yulie Maricsa Caballero Mateus",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19boli803825@siigo.com",
            "Area": "Renovaciones Normal",
            "Cedula": "1000990130",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "14:45 - 15:00",
            "Email": "boli803825@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "14:45",
            "Nombre": "Yesennia Bolivar Sanchez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19more803639@siigo.com",
            "Area": "Renovaciones Normal",
            "Cedula": "1019106899",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "16:00 - 16:15",
            "Email": "more803639@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "16:00",
            "Nombre": "Ofir Moreno Suarez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19garc33953@siigo.com",
            "Area": "Renovaciones Normal",
            "Cedula": "55148706",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "16:15 - 16:30",
            "Email": "garc33953@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "16:15",
            "Nombre": "Martha Isabel Garcia Claros",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19vald803538@siigo.com",
            "Area": "Renovaciones Normal",
            "Cedula": "1031152582",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "16:30 - 16:45",
            "Email": "vald803538@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:00:00",
            "H Ini Alm": "14:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "16:30",
            "Nombre": "Junior Enrique Valderrama Bravo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19boli800512@siigo.com",
            "Area": "Renovaciones Normal",
            "Cedula": "1030678025",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "14:30 - 14:45",
            "Email": "boli800512@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "14:30",
            "Nombre": "Julieth Daniela Bolivar Guzman",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19palo803539@siigo.com",
            "Area": "Renovaciones Normal",
            "Cedula": "1047486977",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "14:45 - 15:00",
            "Email": "palo803539@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "14:45",
            "Nombre": "Jeferson Jose Palomino Guerrero",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19pati803537@siigo.com",
            "Area": "Renovaciones Normal",
            "Cedula": "1027951151",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "pati803537@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Eliana Marcela Patino Ramirez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cast800677@siigo.com",
            "Area": "Renovaciones Normal",
            "Cedula": "1030624946",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:15 - 16:30",
            "Email": "cast800677@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:15",
            "Nombre": "Cindy Carolina Castrillon Castillo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19leon803645@siigo.com",
            "Area": "Renovaciones Normal",
            "Cedula": "1010042991",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:30 - 16:45",
            "Email": "leon803645@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:00:00",
            "H Ini Alm": "14:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:30",
            "Nombre": "Camila Alejandra Leon Acosta",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19wilc112439@siigo.com",
            "Area": "Renovaciones Normal",
            "Cedula": "1032476641",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "14:30 - 14:45",
            "Email": "wilc112439@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:30",
            "IN Break 2": "14:30",
            "Nombre": "Angie Daniela Wilches Penaloza",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19guzm801382@siigo.com",
            "Area": "Renovaciones Normal",
            "Cedula": "1022408281",
            "Desc 1": "0:00:00",
            "Desc 2": "0:00:00",
            "Email": "guzm801382@siigo.com",
            "FIN Break 1": "0",
            "FIN Break 2": "0",
            "Fecha": "2023-07-19",
            "H Fin Alm": "0:00:00",
            "H Ini Alm": "0:00:00",
            "Hr Fin": "0:00:00",
            "Hr Inicio": "0:00:00",
            "IN Break 1": "0",
            "IN Break 2": "0",
            "Nombre": "Alejandra Guzman Sanchez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19bece800816@siigo.com",
            "Area": "Renovaciones Normal",
            "Cedula": "1030546364",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:30 - 16:45",
            "Email": "bece800816@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:30",
            "Nombre": "Adriana Patricia Becerra Bermejo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19huer802212@siigo.com",
            "Area": "Renovaciones Texto",
            "Cedula": "1010247270",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:00 - 16:15",
            "Email": "huer802212@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:00",
            "Nombre": "Alisson Daniela Huertas Roldan",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19carr802251@siigo.com",
            "Area": "Renovaciones Texto",
            "Cedula": "1140866663",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "14:30 - 14:45",
            "Email": "carr802251@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "12:30:00",
            "H Ini Alm": "11:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "14:30",
            "Nombre": "Eilen Tatiana Carrillo Rada",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19eraz185336@siigo.com",
            "Area": "Renovaciones Texto",
            "Cedula": "66858284",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:15 - 16:30",
            "Email": "eraz185336@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:15",
            "Nombre": "Yamileth Erazo Agreda",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19ahum800815@siigo.com",
            "Area": "Renovaciones Tardia",
            "Cedula": "1007645559",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "14:45 - 15:00",
            "Email": "ahum800815@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "14:45",
            "Nombre": "Estefany Ahumada Torres",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rodr801381@siigo.com",
            "Area": "Renovaciones Tardia",
            "Cedula": "1012364037",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:15 - 15:30",
            "Email": "rodr801381@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:15",
            "Nombre": "Jennifer Eliana Navarro Olaya",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19nava111799@siigo.com",
            "Area": "Renovaciones Tardia",
            "Cedula": "1012414508",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "16:15 - 16:30",
            "Email": "nava111799@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "16:15",
            "Nombre": "Sindy Alejandra Rodriguez Giraldo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19pere800812@siigo.com",
            "Area": "Renovaciones Tardia",
            "Cedula": "1018414980",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "16:30 - 16:45",
            "Email": "pere800812@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:00:00",
            "H Ini Alm": "14:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "16:30",
            "Nombre": "Karen Perez Daza",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19villa112269@siigo.com",
            "Area": "Renovaciones Tardia",
            "Cedula": "1022947797",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "14:45 - 15:00",
            "Email": "villa112269@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "14:45",
            "Nombre": "Ana Milena Villarraga Guasca",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19gome801727@siigo.com",
            "Area": "Renovaciones Tardia",
            "Cedula": "1023026552",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:00 - 15:15",
            "Email": "gome801727@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:00",
            "Nombre": "Laura Camila Gomez Cuellar",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19toro800276@siigo.com",
            "Area": "Renovaciones Tardia",
            "Cedula": "1030581231",
            "Desc 1": "0:00:00",
            "Desc 2": "0:00:00",
            "Email": "toro800276@siigo.com",
            "FIN Break 1": "0",
            "FIN Break 2": "0",
            "Fecha": "2023-07-19",
            "H Fin Alm": "0:00:00",
            "H Ini Alm": "0:00:00",
            "Hr Fin": "0:00:00",
            "Hr Inicio": "0:00:00",
            "IN Break 1": "0",
            "IN Break 2": "0",
            "Nombre": "Harold Mauricio Toro Vargas",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19ardi803642@siigo.com",
            "Area": "Renovaciones Tardia",
            "Cedula": "1044425945",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "15:45 - 16:00",
            "Email": "ardi803642@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "15:45",
            "Nombre": "Merelyn Johanna Ardila Perez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19alac801763@siigo.com",
            "Area": "Renovaciones Tardia",
            "Cedula": "1057608088",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "15:30 - 15:45",
            "Email": "alac801763@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:15",
            "IN Break 2": "15:30",
            "Nombre": "Adriana Rocio Alarcon Cardenas",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cabr803523@siigo.com",
            "Area": "Renovaciones Tardia",
            "Cedula": "1149451583",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "15:15 - 15:30",
            "Email": "cabr803523@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:15",
            "IN Break 2": "15:15",
            "Nombre": "Heivert Andres Cabrera Gamero",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19sanc802214@siigo.com",
            "Area": "Renovaciones Tardia",
            "Cedula": "1070597183",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:00 - 16:15",
            "Email": "sanc802214@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:00:00",
            "H Ini Alm": "14:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:00",
            "Nombre": "Meribeth Sanchez Sanchez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19guti803640@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "43114472",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:45 - 15:00",
            "Email": "guti803640@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:45",
            "Nombre": "Yenny Andrea Gutierrez Acevedo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rive800840@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "60399582",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "15:00 - 15:15",
            "Email": "rive800840@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "15:00",
            "Nombre": "Martha Yazmin Rivera Vega",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19matt800847@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1005825216",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:15 - 15:30",
            "Email": "matt800847@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:15",
            "Nombre": "Laura Alejandra Matta Villareal",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cepe803653@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1006121107",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:30 - 15:45",
            "Email": "cepe803653@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:30",
            "Nombre": "Laura Natali Cepeda Vidal",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19vane803646@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1019052655",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:45 - 16:00",
            "Email": "vane803646@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:45",
            "Nombre": "Erika Regina Vanegas Pedraza",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19quir803691@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1022983219",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "16:00 - 16:15",
            "Email": "quir803691@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "16:00",
            "Nombre": "Leidy Johana Quiroga Rios",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19barr803521@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1045733663",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "16:15 - 16:30",
            "Email": "barr803521@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "16:15",
            "Nombre": "Wendy Sandry Barranza Correa",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19dela803656@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1045752655",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:30 - 16:45",
            "Email": "dela803656@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:00:00",
            "H Ini Alm": "14:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:30",
            "Nombre": "Dayana Andrea De La Rosa Arriguez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cale803648@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1095829990",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "15:00 - 15:15",
            "Email": "cale803648@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "15:00",
            "Nombre": "Juan Sebastian Calero Gil",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19diaz802180@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1143442787",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "15:45 - 16:00",
            "Email": "diaz802180@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:00:00",
            "H Ini Alm": "14:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:15",
            "IN Break 2": "15:45",
            "Nombre": "Gisella Del Carmen Diazgranados Vergara",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19hida803694@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1151943791",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "14:30 - 14:45",
            "Email": "hida803694@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:30",
            "IN Break 2": "14:30",
            "Nombre": "Jessica Maria Hidalgo Bonilla",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19sanc801728@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1233509565",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "14:45 - 15:00",
            "Email": "sanc801728@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:30",
            "IN Break 2": "14:45",
            "Nombre": "Daniela Sanchez Rojas",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19herr111416@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "28822069",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:30 - 14:45",
            "Email": "herr111416@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:30",
            "Nombre": "Nancy Johanna Herrera Vargas",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19gome802102@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1000347588",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "14:45 - 15:00",
            "Email": "gome802102@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "14:45",
            "Nombre": "Valentina Gomez Vanegas",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19vela800817@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1000578760",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "16:30 - 16:45",
            "Email": "vela800817@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:00:00",
            "H Ini Alm": "14:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "16:30",
            "Nombre": "Valerye Alejandra Velandia Reyes",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19zaba801490@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1007311689",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "14:45 - 15:00",
            "Email": "zaba801490@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "14:45",
            "Nombre": "Yesica Alejandra Zabala Culma",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19lope111559@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1010186332",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "14:30 - 14:45",
            "Email": "lope111559@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "14:30",
            "Nombre": "Leidy Johanna Lopez Rubio",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19mart801732@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1010242107",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "14:45 - 15:00",
            "Email": "mart801732@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:15",
            "IN Break 2": "14:45",
            "Nombre": "Nijhireth Dagnimanegzy Martinez Campos",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19casa801045@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1014246788",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:00 - 16:15",
            "Email": "casa801045@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:00",
            "Nombre": "Cristian Fabian Casas Rodriguez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19roja802533@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1016011497",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "16:15 - 16:30",
            "Email": "roja802533@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:00:00",
            "H Ini Alm": "14:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "16:15",
            "Nombre": "Duvan Alejandro Rojas Lasso",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19gall802578@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1016105774",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "14:30 - 14:45",
            "Email": "gall802578@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "14:30",
            "Nombre": "Hellen Gabriela Gallo Charry",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19aran803643@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1020740919",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "14:30 - 14:45",
            "Email": "aran803643@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "14:30",
            "Nombre": "Gladys Mariela Arango Suarez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19leyv803516@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1030522033",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "14:45 - 15:00",
            "Email": "leyv803516@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "14:45",
            "Nombre": "Daniel Arturo Leyva Santana",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rodr803532@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1031133906",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:30 - 16:45",
            "Email": "rodr803532@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:30",
            "Nombre": "Geraldine Rodriguez Ospina",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19vale803655@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1033687917",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:15 - 16:30",
            "Email": "vale803655@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:00:00",
            "H Ini Alm": "14:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:15",
            "Nombre": "Viviana Valencia Martinez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19olar801962@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1033782240",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "14:45 - 15:00",
            "Email": "olar801962@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:30",
            "IN Break 2": "14:45",
            "Nombre": "Karol Stepfania Olarte Roa",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19muno801777@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1040740638",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:30 - 14:45",
            "Email": "muno801777@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:30",
            "Nombre": "Ana Gabriela Munoz Rodriguez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19acos803525@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1143460980",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "14:45 - 15:00",
            "Email": "acos803525@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "14:45",
            "Nombre": "Jessica Patricia Acosta Charris",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rome803530@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "38211962",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:45 - 16:00",
            "Email": "rome803530@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:45",
            "Nombre": "Viviana Patricia Romero Osorio",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19gonz803514@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "43571008",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:30 - 15:45",
            "Email": "gonz803514@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:00:00",
            "H Ini Alm": "14:00:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:30",
            "Nombre": "Sandra Isabel Gonzalez Morales",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cabu800298@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "53045437",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "14:45 - 15:00",
            "Email": "cabu800298@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "10:00",
            "IN Break 2": "14:45",
            "Nombre": "Doli Alexandra Cabuya",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19mira803824@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1005911589",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "14:30 - 14:45",
            "Email": "mira803824@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "10:15",
            "IN Break 2": "14:30",
            "Nombre": "Maria Fernanda Miranda Perez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19olav111848@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1010020678",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "15:00 - 15:15",
            "Email": "olav111848@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "10:30",
            "IN Break 2": "15:00",
            "Nombre": "Lady Johanna Olave Vargas",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19altu801964@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1018491887",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "15:15 - 15:30",
            "Email": "altu801964@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "09:00",
            "IN Break 2": "15:15",
            "Nombre": "Maria Paula Alturo Ramirez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19dela803670@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1033700404",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:45 - 16:00",
            "Email": "dela803670@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:00:00",
            "H Ini Alm": "14:00:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:45",
            "Nombre": "Andrea Gissel De La Rans Ipuz",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19sanc803693@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1053858603",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "14:45 - 15:00",
            "Email": "sanc803693@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "09:30",
            "IN Break 2": "14:45",
            "Nombre": "Andrea Sanchez Hernandez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19vega802425@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1067814388",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:30 - 15:45",
            "Email": "vega802425@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:30",
            "Nombre": "Davith Jose Vega Borja",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19gome803517@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1098707904",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "14:30 - 14:45",
            "Email": "gome803517@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "10:00",
            "IN Break 2": "14:30",
            "Nombre": "Leidy Katherine Gomez Angarita",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19duar801830@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1234094766",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "15:45 - 16:00",
            "Email": "duar801830@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "10:15",
            "IN Break 2": "15:45",
            "Nombre": "Wendy Vanessa Duarte Pinzon",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19torr801483@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1136885122",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:00 - 15:15",
            "Email": "torr801483@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:00",
            "Nombre": "Rosa Camila Torres Galvis",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19pabo803529@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1233501659",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "14:45 - 15:00",
            "Email": "pabo803529@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "10:00",
            "IN Break 2": "14:45",
            "Nombre": "Anyi Alejandra Pabon Parrado",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19wilc802105@siigo.com",
            "Area": "Renovaciones Temprana",
            "Cedula": "1022442245",
            "Desc 1": "0:00:00",
            "Desc 2": "0:00:00",
            "Email": "wilc802105@siigo.com",
            "FIN Break 1": "0",
            "FIN Break 2": "0",
            "Fecha": "2023-07-19",
            "H Fin Alm": "0:00:00",
            "H Ini Alm": "0:00:00",
            "Hr Fin": "0:00:00",
            "Hr Inicio": "0:00:00",
            "IN Break 1": "0",
            "IN Break 2": "0",
            "Nombre": "Ingrid Paola Wilchez Leal",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19torr802610@siigo.com",
            "Area": "Ecu Renovaciones",
            "Cedula": "1001185878",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "15:00 - 15:15",
            "Email": "torr802610@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "9:00:00",
            "IN Break 1": "10:15",
            "IN Break 2": "15:00",
            "Nombre": "Kevin David Torres",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19gali112096@siigo.com",
            "Area": "Ecu Renovaciones",
            "Cedula": "1020827383",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "15:45 - 16:00",
            "Email": "gali112096@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "9:00:00",
            "IN Break 1": "10:45",
            "IN Break 2": "15:45",
            "Nombre": "Jeimy Tatiana Galindo Mor",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19mend804597@siigo.com",
            "Area": "Ecu Renovaciones",
            "Cedula": "1027948290",
            "Desc 1": "11:45 - 12:00",
            "Desc 2": "16:45 - 17:00",
            "Email": "mend804597@siigo.com",
            "FIN Break 1": "12:00",
            "FIN Break 2": "17:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:00:00",
            "H Ini Alm": "14:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "9:00:00",
            "IN Break 1": "11:45",
            "IN Break 2": "16:45",
            "Nombre": "Manuela Mendoza",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19muri800527@siigo.com",
            "Area": "Especilaistas",
            "Cedula": "1023972304",
            "Desc 1": "0:00:00",
            "Desc 2": "0:00:00",
            "Email": "muri800527@siigo.com",
            "FIN Break 1": "0",
            "FIN Break 2": "0",
            "Fecha": "2023-07-19",
            "H Fin Alm": "0:00:00",
            "H Ini Alm": "0:00:00",
            "Hr Fin": "0:00:00",
            "Hr Inicio": "0:00:00",
            "IN Break 1": "0",
            "IN Break 2": "0",
            "Nombre": "Jenny Julieth Murillo Palacios",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19pena800998@siigo.com",
            "Area": "Especilaistas",
            "Cedula": "1000615794",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "14:45 - 15:00",
            "Email": "pena800998@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "14:45",
            "Nombre": "Leidy Natalia Pena Ayala",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19pala801284@siigo.com",
            "Area": "Especilaistas",
            "Cedula": "1113680646",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:15 - 15:30",
            "Email": "pala801284@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:15",
            "Nombre": "Hernan David Palacio Mejia",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cort801768@siigo.com",
            "Area": "Especilaistas",
            "Cedula": "1143467570",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:30 - 15:45",
            "Email": "cort801768@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:30",
            "Nombre": "Juliet Tatiana Cortes Acevedor",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rosa802246@siigo.com",
            "Area": "Especilaistas",
            "Cedula": "1045671192",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:45 - 16:00",
            "Email": "rosa802246@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:45",
            "Nombre": "Yulianys Paola Rosado Ospino",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19more800874@siigo.com",
            "Area": "Expertos",
            "Cedula": "1095832659",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "15:00 - 15:15",
            "Email": "more800874@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "16:30:00",
            "Hr Inicio": "7:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "15:00",
            "Nombre": "Stefania Moreno Soler",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19mart800244@siigo.com",
            "Area": "Expertos",
            "Cedula": "1013659327",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:30 - 14:45",
            "Email": "mart800244@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "16:30:00",
            "Hr Inicio": "7:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:30",
            "Nombre": "Yinary Lorena Martinez Aguirre",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19varg112522@siigo.com",
            "Area": "Expertos",
            "Cedula": "52845843",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:15 - 15:30",
            "Email": "varg112522@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:15",
            "Nombre": "Adriana Mercedes Vargas Gonzalez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19vall801050@siigo.com",
            "Area": "Expertos",
            "Cedula": "80259815",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:45 - 16:00",
            "Email": "vall801050@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:45",
            "Nombre": "Albert Andrey Vallejo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19ibar800325@siigo.com",
            "Area": "Expertos",
            "Cedula": "1030632901",
            "Desc 1": "11:45 - 12:00",
            "Desc 2": "15:15 - 15:30",
            "Email": "ibar800325@siigo.com",
            "FIN Break 1": "12:00",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "11:45",
            "IN Break 2": "15:15",
            "Nombre": "Carolina Andrea Ibarguen Arcos",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19muno800991@siigo.com",
            "Area": "Expertos",
            "Cedula": "1071165167",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:30 - 15:45",
            "Email": "muno800991@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:30",
            "Nombre": "Fabian Alfonso Munoz Moreno",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cace800999@siigo.com",
            "Area": "Expertos",
            "Cedula": "1098809774",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "14:45 - 15:00",
            "Email": "cace800999@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "14:45",
            "Nombre": "German Camilo Caceres Castillo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19gilg801285@siigo.com",
            "Area": "Expertos",
            "Cedula": "1069403860",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "15:00 - 15:15",
            "Email": "gilg801285@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "15:00",
            "Nombre": "Leidy Johana Gil Garavito",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19monc800491@siigo.com",
            "Area": "Expertos",
            "Cedula": "1193063936",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "14:30 - 14:45",
            "Email": "monc800491@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "14:30",
            "Nombre": "Evelyn Dayana Moncada Diaz",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19medi800793@siigo.com",
            "Area": "Expertos",
            "Cedula": "1016113990",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:45 - 17:00",
            "Email": "medi800793@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "17:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:45",
            "Nombre": "Andres Felipe Medina Rincon",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19gome801639@siigo.com",
            "Area": "Expertos",
            "Cedula": "1098818175",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:30 - 16:45",
            "Email": "gome801639@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:30",
            "Nombre": "Luis Jose Gomez Villarreal",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19pere800332@siigo.com",
            "Area": "Expertos",
            "Cedula": "37949431",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:45 - 17:00",
            "Email": "pere800332@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "17:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:45",
            "Nombre": "Yadira Perez Ballesteros",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19mera800521@siigo.com",
            "Area": "Expertos",
            "Cedula": "1143933814",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:30 - 16:45",
            "Email": "mera800521@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:15:00",
            "Hr Inicio": "8:45:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:30",
            "Nombre": "Katherine Lisseth Mera Ramos",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19baqu802819@siigo.com",
            "Area": "Tecnicos",
            "Cedula": "80827588",
            "Desc 1": "00:00",
            "Desc 2": "00:00",
            "Email": "baqu802819@siigo.com",
            "FIN Break 1": "0",
            "FIN Break 2": "0",
            "Fecha": "2023-07-19",
            "H Fin Alm": "00:00",
            "H Ini Alm": "00:00",
            "Hr Fin": "00:00",
            "Hr Inicio": "00:00",
            "IN Break 1": "0",
            "IN Break 2": "0",
            "Nombre": "Yesid Baquero Florez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19corr802821@siigo.com",
            "Area": "Tecnicos",
            "Cedula": "1006098888",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "15:30 - 15:45",
            "Email": "corr802821@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00",
            "H Ini Alm": "12:00",
            "Hr Fin": "17:30",
            "Hr Inicio": "08:00",
            "IN Break 1": "09:00",
            "IN Break 2": "15:30",
            "Nombre": "Valentina Correa Delgado",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rios801665@siigo.com",
            "Area": "Tecnicos",
            "Cedula": "1036669427",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:30 - 15:45",
            "Email": "rios801665@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00",
            "H Ini Alm": "12:00",
            "Hr Fin": "17:30",
            "Hr Inicio": "08:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:30",
            "Nombre": "Sebastian Andres Rios Guevara",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rive802048@siigo.com",
            "Area": "Tecnicos",
            "Cedula": "1113681582",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:45 - 16:00",
            "Email": "rive802048@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00",
            "H Ini Alm": "12:00",
            "Hr Fin": "17:30",
            "Hr Inicio": "08:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:45",
            "Nombre": "Juan Sebastian Rivera Chavez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cero802809@siigo.com",
            "Area": "Tecnicos",
            "Cedula": "1085264472",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:45 - 16:00",
            "Email": "cero802809@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00",
            "H Ini Alm": "12:00",
            "Hr Fin": "17:30",
            "Hr Inicio": "08:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:45",
            "Nombre": "Jaime Daniel Ceron Rosales",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19mond802273@siigo.com",
            "Area": "Tecnicos",
            "Cedula": "1113695644",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:45 - 16:00",
            "Email": "mond802273@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00",
            "H Ini Alm": "13:00",
            "Hr Fin": "17:30",
            "Hr Inicio": "08:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:45",
            "Nombre": "Harold Mondragon Jaramillo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cruz804533@siigo.com",
            "Area": "Tecnicos",
            "Cedula": "1032481325",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:30 - 16:45",
            "Email": "cruz804533@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00",
            "H Ini Alm": "13:00",
            "Hr Fin": "17:30",
            "Hr Inicio": "08:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:30",
            "Nombre": "Hammer Jair Cruz Guaje",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rued802780@siigo.com",
            "Area": "Tecnicos",
            "Cedula": "37550900",
            "Desc 1": "00:00",
            "Desc 2": "00:00",
            "Email": "rued802780@siigo.com",
            "FIN Break 1": "0",
            "FIN Break 2": "0",
            "Fecha": "2023-07-19",
            "H Fin Alm": "00:00",
            "H Ini Alm": "00:00",
            "Hr Fin": "Vacaciones",
            "Hr Inicio": "Vacaciones",
            "IN Break 1": "0",
            "IN Break 2": "0",
            "Nombre": "Deicy Dayana Rueda Gomez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19muno804534@siigo.com",
            "Area": "Tecnicos",
            "Cedula": "1005876345",
            "Desc 1": "00:00",
            "Desc 2": "00:00",
            "Email": "muno804534@siigo.com",
            "FIN Break 1": "0",
            "FIN Break 2": "0",
            "Fecha": "2023-07-19",
            "H Fin Alm": "00:00",
            "H Ini Alm": "00:00",
            "Hr Fin": "00:00",
            "Hr Inicio": "00:00",
            "IN Break 1": "0",
            "IN Break 2": "0",
            "Nombre": "Daniel Alexander Munoz Triana",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19toro801816@siigo.com",
            "Area": "Tecnicos",
            "Cedula": "1097406302",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:45 - 17:00",
            "Email": "toro801816@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "17:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00",
            "H Ini Alm": "13:00",
            "Hr Fin": "17:30",
            "Hr Inicio": "08:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:45",
            "Nombre": "Angie Julieth Toro Robayo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19hinc802296@siigo.com",
            "Area": "Tecnicos",
            "Cedula": "1233696242",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:45 - 17:00",
            "Email": "hinc802296@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "17:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00",
            "H Ini Alm": "13:00",
            "Hr Fin": "17:30",
            "Hr Inicio": "08:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:45",
            "Nombre": "Andres Felipe Hincapie Navas",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19lope111909@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "98701553",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "15:00 - 15:15",
            "Email": "lope111909@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "15:00",
            "Nombre": "Carlos Andres Lopera",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rome800285@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "1214732469",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "15:00 - 15:15",
            "Email": "rome800285@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "15:00",
            "Nombre": "Cristian Camilo Romero Acevedo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19diaz801410@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "1095824513",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "15:00 - 15:15",
            "Email": "diaz801410@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "15:00",
            "Nombre": "Gina Brigette Diaz Gomez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19pere800870@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "1024567924",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "15:15 - 15:30",
            "Email": "pere800870@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "15:15",
            "Nombre": "Jessica Lorena Perez Barrera",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19roja800881@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "1045755978",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:15 - 15:30",
            "Email": "roja800881@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:15",
            "Nombre": "Marianella Rojas Caicedo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19meza801621@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "1002242786",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:30 - 15:45",
            "Email": "meza801621@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:30",
            "Nombre": "Ivana Teresa Meza De La Rosa",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rest111930@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "21527717",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:30 - 15:45",
            "Email": "rest111930@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:30",
            "Nombre": "Natalia Restrepo Garcia",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19ozae802143@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "1110569471",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:30 - 15:45",
            "Email": "ozae802143@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:30",
            "Nombre": "Luisa Fernanda Ozaeta Rojas",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19ball802145@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "1047482161",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:45 - 16:00",
            "Email": "ball802145@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:45",
            "Nombre": "Yesmy Alejandra Ballesta Tous",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19mira802183@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "1002233134",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:45 - 16:00",
            "Email": "mira802183@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:45",
            "Nombre": "Milagro De Jesus Miranda Suarez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19corr802149@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "1006026464",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "15:45 - 16:00",
            "Email": "corr802149@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "15:45",
            "Nombre": "Leidy Daniela Corral Aranzalez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19robl111907@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "84074002",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "robl111907@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Arlen Rafael Robles Mejia",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19tama111936@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "43276584",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "tama111936@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Tatiana Marcela Tamayo Serna",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19bece111929@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "43870207",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "bece111929@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Nancy Olivia Becerra Calderon",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19more111916@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "42773481",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:15 - 16:30",
            "Email": "more111916@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:15",
            "Nombre": "Elizabeth Moreno Vargas",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19gonz800151@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "1039453778",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:15 - 16:30",
            "Email": "gonz800151@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:15",
            "Nombre": "Monica Johana Gonzalez Espinosa",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19arci111918@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "15531454",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:30 - 16:45",
            "Email": "arci111918@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:30",
            "Nombre": "Francisco Luis Arcila Luna",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19esco801628@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "1083027495",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:30 - 16:45",
            "Email": "esco801628@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:30",
            "Nombre": "Marilin Julieth Escorcia Torres",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19mart800877@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "1020842214",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:30 - 16:45",
            "Email": "mart800877@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:30",
            "Nombre": "Gabriella Caroline Martinez Bolivar",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19narv801408@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "1085299564",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:30 - 16:45",
            "Email": "narv801408@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:30",
            "Nombre": "Erika Marcela Narvaez Barco",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19gila801625@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "1233894407",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:30 - 16:45",
            "Email": "gila801625@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:30",
            "Nombre": "Valentina Gil Agudelo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cald802166@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "1014263052",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:45 - 17:00",
            "Email": "cald802166@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "17:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:45",
            "Nombre": "Luisa Fernanda Calderon Torres",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19palm802528@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "1083021763",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "0:00:00",
            "Email": "palm802528@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "0",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "15:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:45",
            "IN Break 2": "0",
            "Nombre": "Ana Marcela Palma Arvilla",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19gome800136@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "1017140019",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:45 - 17:00",
            "Email": "gome800136@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "17:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:45",
            "Nombre": "Hillary Cristina Gomez Pineda",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19diaz804062@siigo.com",
            "Area": "Ilimitada",
            "Cedula": "1086302692",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:45 - 17:00",
            "Email": "diaz804062@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "17:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:45",
            "Nombre": "Yuliza Katherine Diaz Cuaran",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19liza802523@siigo.com",
            "Area": "Pyme",
            "Cedula": "1143464050",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "15:00 - 15:15",
            "Email": "liza802523@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "15:00",
            "Nombre": "Yuliana Paola Lizarazo Munoz",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19torr20098@siigo.com",
            "Area": "Pyme",
            "Cedula": "57430122",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "15:00 - 15:15",
            "Email": "torr20098@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "15:00",
            "Nombre": "Yamile Patricia Torres Palacio",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rodr19229@siigo.com",
            "Area": "Pyme",
            "Cedula": "80249132",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "15:00 - 15:15",
            "Email": "rodr19229@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "15:00",
            "Nombre": "Wilmar Andres Rodriguez Hernandez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19clar802537@siigo.com",
            "Area": "Pyme",
            "Cedula": "1054993459",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "15:00 - 15:15",
            "Email": "clar802537@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "15:00",
            "Nombre": "Veronica Claros Marin",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19arri802519@siigo.com",
            "Area": "Pyme",
            "Cedula": "1002212542",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "15:00 - 15:15",
            "Email": "arri802519@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "15:00",
            "Nombre": "Valentina Arrieta Jimenez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19ruiz802135@siigo.com",
            "Area": "Pyme",
            "Cedula": "1016102019",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "15:00 - 15:15",
            "Email": "ruiz802135@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "15:00",
            "Nombre": "Tiffany Michelle Ruiz Velasquez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19muno801860@siigo.com",
            "Area": "Pyme",
            "Cedula": "1006505793",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "15:15 - 15:30",
            "Email": "muno801860@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "15:15",
            "Nombre": "Stefanny Munoz Gasca",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19angu802137@siigo.com",
            "Area": "Pyme",
            "Cedula": "1111810353",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "15:15 - 15:30",
            "Email": "angu802137@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "15:15",
            "Nombre": "Rosemary Angulo Borja",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19comb239051@siigo.com",
            "Area": "Pyme",
            "Cedula": "65630848",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "15:15 - 15:30",
            "Email": "comb239051@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "15:15",
            "Nombre": "Ninza Aidee Combita Nunez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19diaz800478@siigo.com",
            "Area": "Pyme",
            "Cedula": "1007290150",
            "Desc 1": "0:00:00",
            "Desc 2": "0:00:00",
            "Email": "diaz800478@siigo.com",
            "FIN Break 1": "0",
            "FIN Break 2": "0",
            "Fecha": "2023-07-19",
            "H Fin Alm": "0:00:00",
            "H Ini Alm": "0:00:00",
            "Hr Fin": "Vacaciones",
            "Hr Inicio": "Vacaciones",
            "IN Break 1": "0",
            "IN Break 2": "0",
            "Nombre": "Nazly Yulieth Diaz Benitez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19vela803780@siigo.com",
            "Area": "Pyme",
            "Cedula": "1049645353",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:15 - 15:30",
            "Email": "vela803780@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:15",
            "Nombre": "Mery Jicela Velandia Torres",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19gonz802525@siigo.com",
            "Area": "Pyme",
            "Cedula": "1001919200",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:15 - 15:30",
            "Email": "gonz802525@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:15",
            "Nombre": "Melanie Paola Gonzalez Rodriguez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19buit243825@siigo.com",
            "Area": "Pyme",
            "Cedula": "37272087",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:30 - 15:45",
            "Email": "buit243825@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:30",
            "Nombre": "Maria Zenaida Buitrago Carrillo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19oroz802150@siigo.com",
            "Area": "Pyme",
            "Cedula": "55313011",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:30 - 15:45",
            "Email": "oroz802150@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:30",
            "Nombre": "Maria Luisa Orozco Suarez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19ruam802541@siigo.com",
            "Area": "Pyme",
            "Cedula": "1001999817",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:30 - 15:45",
            "Email": "ruam802541@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:30",
            "Nombre": "Maria Fernanda Rua Mendoza",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19baut800995@siigo.com",
            "Area": "Pyme",
            "Cedula": "1233495696",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:30 - 15:45",
            "Email": "baut800995@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:30",
            "Nombre": "Maria Fernanda Bautista Sanchez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cald801378@siigo.com",
            "Area": "Pyme",
            "Cedula": "1033798686",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:45 - 16:00",
            "Email": "cald801378@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:45",
            "Nombre": "Maria Alejandra Caldas Garcia",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19pata802325@siigo.com",
            "Area": "Pyme",
            "Cedula": "1121923094",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:45 - 16:00",
            "Email": "pata802325@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:45",
            "Nombre": "Manuel Elkin Patarroyo Herrera",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19torr802075@siigo.com",
            "Area": "Pyme",
            "Cedula": "1073382799",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:45 - 16:00",
            "Email": "torr802075@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:45",
            "Nombre": "Lilia Stella Torres Urrego",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19arci801390@siigo.com",
            "Area": "Pyme",
            "Cedula": "1098779597",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:45 - 16:00",
            "Email": "arci801390@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:45",
            "Nombre": "Leydi Vanessa Arciniegas Castaneda",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19vela802532@siigo.com",
            "Area": "Pyme",
            "Cedula": "1144148835",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "vela802532@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Leidy Tatiana Vela Molina",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19muno802314@siigo.com",
            "Area": "Pyme",
            "Cedula": "1049644047",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "muno802314@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Leidy Johana Munoz Suarez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19puli20592@siigo.com",
            "Area": "Pyme",
            "Cedula": "39750071",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "puli20592@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Katalina Pulido Quimbayo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19lobo802146@siigo.com",
            "Area": "Pyme",
            "Cedula": "1140892356",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "lobo802146@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Karen Melissa Lobo Gonzalez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19torre800265@siigo.com",
            "Area": "Pyme",
            "Cedula": "1015994852",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "torre800265@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Juan Jose Torres Mora",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rodr803772@siigo.com",
            "Area": "Pyme",
            "Cedula": "1057598657",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "rodr803772@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Juan David Rodriguez Trujillo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19varg800308@siigo.com",
            "Area": "Pyme",
            "Cedula": "1018491156",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "varg800308@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Juan Camilo Vargas Pelayo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19neva803774@siigo.com",
            "Area": "Pyme",
            "Cedula": "1001994591",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "neva803774@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Joselyn Maileth Nevado Lopez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19ospi801853@siigo.com",
            "Area": "Pyme",
            "Cedula": "1143163436",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "ospi801853@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Jose Gregorio Ospino Sandoval",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19zamb801674@siigo.com",
            "Area": "Pyme",
            "Cedula": "1033695430",
            "Desc 1": "0:00:00",
            "Desc 2": "0:00:00",
            "Email": "zamb801674@siigo.com",
            "FIN Break 1": "0",
            "FIN Break 2": "0",
            "Fecha": "2023-07-19",
            "H Fin Alm": "0:00:00",
            "H Ini Alm": "0:00:00",
            "Hr Fin": "Dia Familia",
            "Hr Inicio": "Dia Familia",
            "IN Break 1": "0",
            "IN Break 2": "0",
            "Nombre": "Jose Armel Zambrano Trujillo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19acos802303@siigo.com",
            "Area": "Pyme",
            "Cedula": "1006120931",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:15 - 16:30",
            "Email": "acos802303@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:15",
            "Nombre": "Jonathan Estiven Acosta Payan",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19diaz800156@siigo.com",
            "Area": "Pyme",
            "Cedula": "1110572750",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:15 - 16:30",
            "Email": "diaz800156@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:15",
            "Nombre": "John Sebastian Diaz Mosquera",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19corr803778@siigo.com",
            "Area": "Pyme",
            "Cedula": "1143457291",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:15 - 16:30",
            "Email": "corr803778@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:15",
            "Nombre": "Jesus Alfonso Correa Camargo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19suar800784@siigo.com",
            "Area": "Pyme",
            "Cedula": "53030421",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:15 - 16:30",
            "Email": "suar800784@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:15",
            "Nombre": "Jenny Johanna Suarez Gomez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19gome802313@siigo.com",
            "Area": "Pyme",
            "Cedula": "1043021782",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:15 - 16:30",
            "Email": "gome802313@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:15",
            "Nombre": "Ivan Fernando Gomez Mercado",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19pera204477@siigo.com",
            "Area": "Pyme",
            "Cedula": "72008479",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:15 - 16:30",
            "Email": "pera204477@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:15",
            "Nombre": "Elvis Manuel Peralta Quintero",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cama802520@siigo.com",
            "Area": "Pyme",
            "Cedula": "1090503390",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:15 - 16:30",
            "Email": "cama802520@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:15",
            "Nombre": "Edwin Alexis Camargo Contreras",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19buit801886@siigo.com",
            "Area": "Pyme",
            "Cedula": "1000362943",
            "Desc 1": "0:00:00",
            "Desc 2": "0:00:00",
            "Email": "buit801886@siigo.com",
            "FIN Break 1": "0",
            "FIN Break 2": "0",
            "Fecha": "2023-07-19",
            "H Fin Alm": "0:00:00",
            "H Ini Alm": "0:00:00",
            "Hr Fin": "Vacaciones",
            "Hr Inicio": "Vacaciones",
            "IN Break 1": "0",
            "IN Break 2": "0",
            "Nombre": "David Steban Buitrago Duran",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cast802344@siigo.com",
            "Area": "Pyme",
            "Cedula": "1000077679",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:15 - 16:30",
            "Email": "cast802344@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:15",
            "Nombre": "David Felipe Castro Leon",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rico802179@siigo.com",
            "Area": "Pyme",
            "Cedula": "1007787788",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:15 - 16:30",
            "Email": "rico802179@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:15",
            "Nombre": "Daniela Adriana Rico Rubiano",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19truy802317@siigo.com",
            "Area": "Pyme",
            "Cedula": "1047362685",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:30 - 16:45",
            "Email": "truy802317@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:30",
            "Nombre": "Carlos Andres Truyol Gutierrez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19silv220528@siigo.com",
            "Area": "Pyme",
            "Cedula": "63490108",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:30 - 16:45",
            "Email": "silv220528@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:30",
            "Nombre": "Belsy Silva Mendez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19asca14300@siigo.com",
            "Area": "Pyme",
            "Cedula": "28151938",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:30 - 16:45",
            "Email": "asca14300@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:30",
            "Nombre": "Angelica Maria Ascanio Rueda",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19mora802534@siigo.com",
            "Area": "Pyme",
            "Cedula": "1001887471",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:30 - 16:45",
            "Email": "mora802534@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:30",
            "Nombre": "Andrea Liliana Morales Martinez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19corr802309@siigo.com",
            "Area": "Pyme",
            "Cedula": "1044432800",
            "Desc 1": "0:00:00",
            "Desc 2": "0:00:00",
            "Email": "corr802309@siigo.com",
            "FIN Break 1": "0",
            "FIN Break 2": "0",
            "Fecha": "2023-07-19",
            "H Fin Alm": "0:00:00",
            "H Ini Alm": "0:00:00",
            "Hr Fin": "Vacaciones",
            "Hr Inicio": "Vacaciones",
            "IN Break 1": "0",
            "IN Break 2": "0",
            "Nombre": "Ana Maria Corro Altahona",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cast223509@siigo.com",
            "Area": "Pyme",
            "Cedula": "52444190",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:30 - 16:45",
            "Email": "cast223509@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:30",
            "Nombre": "Ana Consuelo Castro Mendoza",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rodr802524@siigo.com",
            "Area": "Pyme",
            "Cedula": "1143168645",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:30 - 16:45",
            "Email": "rodr802524@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:30",
            "Nombre": "Alonso Andres Rodriguez Acevedo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19oval802316@siigo.com",
            "Area": "Pyme",
            "Cedula": "1065611568",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:30 - 16:45",
            "Email": "oval802316@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:30",
            "Nombre": "Alejandro David Ovalle Escobar",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19tovi802438@siigo.com",
            "Area": "Nube",
            "Cedula": "1143408555",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:30 - 14:45",
            "Email": "tovi802438@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "16:30:00",
            "Hr Inicio": "7:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:30",
            "Nombre": "Angie Carolina Tovio Machado",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19moli802753@siigo.com",
            "Area": "Nube",
            "Cedula": "1113685444",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:30 - 14:45",
            "Email": "moli802753@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "16:30:00",
            "Hr Inicio": "7:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:30",
            "Nombre": "Brayan David Molina Castaneda",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19pere803739@siigo.com",
            "Area": "Nube",
            "Cedula": "1045741957",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:30 - 14:45",
            "Email": "pere803739@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "16:30:00",
            "Hr Inicio": "7:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:30",
            "Nombre": "David Santiago Perea Torres",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19fern112512@siigo.com",
            "Area": "Nube",
            "Cedula": "10545489",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:30 - 14:45",
            "Email": "fern112512@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "16:30:00",
            "Hr Inicio": "7:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:30",
            "Nombre": "Efrain Fernandez Trujillo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19lope802260@siigo.com",
            "Area": "Nube",
            "Cedula": "1049646905",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:30 - 14:45",
            "Email": "lope802260@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "16:30:00",
            "Hr Inicio": "7:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:30",
            "Nombre": "Eliana Marcela Lopez Sierra",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19arau802429@siigo.com",
            "Area": "Nube",
            "Cedula": "1065653410",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:30 - 14:45",
            "Email": "arau802429@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "16:30:00",
            "Hr Inicio": "7:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:30",
            "Nombre": "Jhonatan Araujo Guette",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19vele801829@siigo.com",
            "Area": "Nube",
            "Cedula": "1113676129",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:30 - 14:45",
            "Email": "vele801829@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "16:30:00",
            "Hr Inicio": "7:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:30",
            "Nombre": "Luis Edgar Velez Guerrero",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19nava801279@siigo.com",
            "Area": "Nube",
            "Cedula": "1143369164",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:30 - 14:45",
            "Email": "nava801279@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "14:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "16:30:00",
            "Hr Inicio": "7:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:30",
            "Nombre": "Luz Neyla Navas Paredes",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cruz802814@siigo.com",
            "Area": "Nube",
            "Cedula": "1004710578",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:45 - 15:00",
            "Email": "cruz802814@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "16:30:00",
            "Hr Inicio": "7:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:45",
            "Nombre": "Omar Santiago Cruz Munoz",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19guti801676@siigo.com",
            "Area": "Nube",
            "Cedula": "1002032821",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:45 - 15:00",
            "Email": "guti801676@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "16:30:00",
            "Hr Inicio": "7:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:45",
            "Nombre": "Shayla Alejandra Gutierrez Ledesma",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19gonz803602@siigo.com",
            "Area": "Nube",
            "Cedula": "1082850928",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:45 - 15:00",
            "Email": "gonz803602@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "16:30:00",
            "Hr Inicio": "7:00:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:45",
            "Nombre": "Yorleinis Melissa Gonzalez Rivas",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19ardi802057@siigo.com",
            "Area": "Nube",
            "Cedula": "1007744681",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:00 - 16:15",
            "Email": "ardi802057@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:00",
            "Nombre": "Maria Jose Ardila Ojeda",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19bric800921@siigo.com",
            "Area": "Nube",
            "Cedula": "1235038508",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:45 - 15:00",
            "Email": "bric800921@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:45",
            "Nombre": "Adrian Esteban Briceno Bautista",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19reye803606@siigo.com",
            "Area": "Nube",
            "Cedula": "1005718580",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:45 - 15:00",
            "Email": "reye803606@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:45",
            "Nombre": "Alejandra Reyes Nieto",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19lope801791@siigo.com",
            "Area": "Nube",
            "Cedula": "1012358258",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:45 - 15:00",
            "Email": "lope801791@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:45",
            "Nombre": "Ana Constanza Lopez Reina",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19estr804058@siigo.com",
            "Area": "Nube",
            "Cedula": "1004346066",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:45 - 15:00",
            "Email": "estr804058@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:45",
            "Nombre": "Giselle Karina Estrada Banderas",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19prad803613@siigo.com",
            "Area": "Nube",
            "Cedula": "1110554630",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:45 - 15:00",
            "Email": "prad803613@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:45",
            "Nombre": "Jhon Weimar Prada Rojas",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19gome801502@siigo.com",
            "Area": "Nube",
            "Cedula": "55307136",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:45 - 15:00",
            "Email": "gome801502@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:45",
            "Nombre": "Karina Maria Gomez Alvarez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19bece801800@siigo.com",
            "Area": "Nube",
            "Cedula": "1143251202",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "14:45 - 15:00",
            "Email": "bece801800@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "09:00",
            "IN Break 2": "14:45",
            "Nombre": "Katherine Becerra Garcia",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cerr801797@siigo.com",
            "Area": "Nube",
            "Cedula": "1042439205",
            "Desc 1": "09:00 - 09:15",
            "Desc 2": "15:00 - 15:15",
            "Email": "cerr801797@siigo.com",
            "FIN Break 1": "09:15",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "09:00",
            "IN Break 2": "15:00",
            "Nombre": "Keyla Maritza Cerra Pacheco",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19acev803614@siigo.com",
            "Area": "Nube",
            "Cedula": "1020799659",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:00 - 15:15",
            "Email": "acev803614@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:00",
            "Nombre": "Maritza Jeraldin Acevedo Nope",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19aran802261@siigo.com",
            "Area": "Nube",
            "Cedula": "1106789499",
            "Desc 1": "0:00:00",
            "Desc 2": "0:00:00",
            "Email": "aran802261@siigo.com",
            "FIN Break 1": "0",
            "FIN Break 2": "0",
            "Fecha": "2023-07-19",
            "H Fin Alm": "0:00:00",
            "H Ini Alm": "0:00:00",
            "Hr Fin": "0:00:00",
            "Hr Inicio": "0:00:00",
            "IN Break 1": "0",
            "IN Break 2": "0",
            "Nombre": "Silvia Dahyana Arango Cardona",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19prie804016@siigo.com",
            "Area": "Nube",
            "Cedula": "40343317",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:00 - 15:15",
            "Email": "prie804016@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:00",
            "Nombre": "Tatiana Veronica Prieto Prieto",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19medi801917@siigo.com",
            "Area": "Nube",
            "Cedula": "1018477685",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:00 - 15:15",
            "Email": "medi801917@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "7:30:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:00",
            "Nombre": "William Fernando Medina Camargo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19varg803734@siigo.com",
            "Area": "Nube",
            "Cedula": "1143439034",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:00 - 15:15",
            "Email": "varg803734@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:00",
            "Nombre": "Cesar Wilfrido Vargas Romero",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rest802509@siigo.com",
            "Area": "Nube",
            "Cedula": "1143384265",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:00 - 15:15",
            "Email": "rest802509@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:00",
            "Nombre": "Ariel De Jesus Restrepo Lambertino",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19roja801662@siigo.com",
            "Area": "Nube",
            "Cedula": "1002682679",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:00 - 15:15",
            "Email": "roja801662@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:00",
            "Nombre": "Daisy Dayana Rojas Bernal",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rami803737@siigo.com",
            "Area": "Nube",
            "Cedula": "1074486488",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:00 - 15:15",
            "Email": "rami803737@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:00",
            "Nombre": "Francy Ney Ramirez Bustamante",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19deal802499@siigo.com",
            "Area": "Nube",
            "Cedula": "1007133977",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:00 - 15:15",
            "Email": "deal802499@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:00",
            "Nombre": "Harol Styd De Alba Taibel",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19bola802495@siigo.com",
            "Area": "Nube",
            "Cedula": "1140903389",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:00 - 15:15",
            "Email": "bola802495@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:00",
            "Nombre": "Jhon Sebastian Bolano Rua",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19avel802508@siigo.com",
            "Area": "Nube",
            "Cedula": "1000273818",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:00 - 15:15",
            "Email": "avel802508@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:00",
            "Nombre": "Laura Jireth Avellaneda Puentes",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19barb803596@siigo.com",
            "Area": "Nube",
            "Cedula": "1110478157",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:00 - 15:15",
            "Email": "barb803596@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:00",
            "Nombre": "Leidy Alejandra Barbosa Amorocho",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cald802514@siigo.com",
            "Area": "Nube",
            "Cedula": "1045738299",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:00 - 15:15",
            "Email": "cald802514@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:00",
            "Nombre": "Luis Cesar Calderon Lopez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19diaz901235@siigo.com",
            "Area": "Nube",
            "Cedula": "1049627596",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:00 - 15:15",
            "Email": "diaz901235@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:00:00",
            "H Ini Alm": "12:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:00",
            "Nombre": "Luis Felipe Diaz Gonzalez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19suaz804012@siigo.com",
            "Area": "Nube",
            "Cedula": "1000439869",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:00 - 15:15",
            "Email": "suaz804012@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:00",
            "Nombre": "Luisa Fernanda Suazo Tamayo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19nais802515@siigo.com",
            "Area": "Nube",
            "Cedula": "1007648700",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:00 - 15:15",
            "Email": "nais802515@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:00",
            "Nombre": "Naiceth Dajana Naissir Torres",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19monr801184@siigo.com",
            "Area": "Nube",
            "Cedula": "37579480",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:00 - 15:15",
            "Email": "monr801184@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:00",
            "Nombre": "Nidian Carolina Monroy Serrano",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19ramo802518@siigo.com",
            "Area": "Nube",
            "Cedula": "1018467331",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:00 - 15:15",
            "Email": "ramo802518@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:00",
            "Nombre": "Orleidys Paola Ramos Salgado",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19puel801622@siigo.com",
            "Area": "Nube",
            "Cedula": "1047472692",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:00 - 15:15",
            "Email": "puel801622@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:00",
            "Nombre": "Raquel Puello Pajaro",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cald803740@siigo.com",
            "Area": "Nube",
            "Cedula": "1048266579",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:15 - 15:30",
            "Email": "cald803740@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:15",
            "Nombre": "Yulys Del Carmen Calderon Perez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19yust802439@siigo.com",
            "Area": "Nube",
            "Cedula": "1000518042",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:15 - 15:30",
            "Email": "yust802439@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:15",
            "Nombre": "David Steven Yusti Buitrago",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19segu801006@siigo.com",
            "Area": "Nube",
            "Cedula": "1024524725",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:15 - 15:30",
            "Email": "segu801006@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:15",
            "Nombre": "Diana Katherin Segura Leguizamon",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19mend800692@siigo.com",
            "Area": "Nube",
            "Cedula": "1052996202",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "15:15 - 15:30",
            "Email": "mend800692@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "15:15",
            "Nombre": "Elisa Maria Mendez Manjarres",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19blan800690@siigo.com",
            "Area": "Nube",
            "Cedula": "1143155469",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:15 - 15:30",
            "Email": "blan800690@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:15",
            "Nombre": "Gabriel Angel Blanco Rojas",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19marq803748@siigo.com",
            "Area": "Nube",
            "Cedula": "1001829762",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:15 - 15:30",
            "Email": "marq803748@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:15",
            "Nombre": "Ismael De Jesus Marquez Velasquez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19estr803754@siigo.com",
            "Area": "Nube",
            "Cedula": "1193276556",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:15 - 15:30",
            "Email": "estr803754@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:15",
            "Nombre": "Karen Lizeth Estrada Pacheco",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19saav802124@siigo.com",
            "Area": "Nube",
            "Cedula": "1030677333",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:15 - 15:30",
            "Email": "saav802124@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:15",
            "Nombre": "Leidy Paola Saavedra Parada",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19pach802123@siigo.com",
            "Area": "Nube",
            "Cedula": "1143266330",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:15 - 15:30",
            "Email": "pach802123@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:15",
            "Nombre": "Luis Enrique Pacheco Romero",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19sant800878@siigo.com",
            "Area": "Nube",
            "Cedula": "1070624656",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:15 - 15:30",
            "Email": "sant800878@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:15",
            "Nombre": "Maria Camila Santos Nunez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19delv801407@siigo.com",
            "Area": "Nube",
            "Cedula": "1045759235",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:15 - 15:30",
            "Email": "delv801407@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:15",
            "Nombre": "Maria Paula Del Villar De La Hoz",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19nava112489@siigo.com",
            "Area": "Nube",
            "Cedula": "1045697690",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:15 - 15:30",
            "Email": "nava112489@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:15",
            "Nombre": "Orlando Elias Navarro Vasquez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rued803726@siigo.com",
            "Area": "Nube",
            "Cedula": "1032360860",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:15 - 15:30",
            "Email": "rued803726@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:15",
            "Nombre": "Sonia Rosa Rueda Gomez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19moli801401@siigo.com",
            "Area": "Nube",
            "Cedula": "1100971009",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:15 - 15:30",
            "Email": "moli801401@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:15",
            "Nombre": "Yuri Andrea Molina Castillo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19ball802118@siigo.com",
            "Area": "Nube",
            "Cedula": "1033790465",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:15 - 15:30",
            "Email": "ball802118@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:15",
            "Nombre": "Aaron Andres Ballen Perez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19card802058@siigo.com",
            "Area": "Nube",
            "Cedula": "53089344",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "0:00:00",
            "Email": "card802058@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "0",
            "Fecha": "2023-07-19",
            "H Fin Alm": "0:00:00",
            "H Ini Alm": "0:00:00",
            "Hr Fin": "12:00:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "0",
            "Nombre": "Angelica Cardenas Rodriguez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19hern801642@siigo.com",
            "Area": "Nube",
            "Cedula": "1053614127",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:15 - 15:30",
            "Email": "hern801642@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:15",
            "Nombre": "July Andrea Hernandez Chivata",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19holg802422@siigo.com",
            "Area": "Nube",
            "Cedula": "1121967175",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:15 - 15:30",
            "Email": "holg802422@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:15",
            "Nombre": "Karem Xiomara Holguin Aragon",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19esco804013@siigo.com",
            "Area": "Nube",
            "Cedula": "1143169160",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:15 - 15:30",
            "Email": "esco804013@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:00:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:15",
            "Nombre": "Karina Pilar Escobar Pichon",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19ruiz804015@siigo.com",
            "Area": "Nube",
            "Cedula": "1003913422",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:15 - 15:30",
            "Email": "ruiz804015@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:15",
            "Nombre": "Laura Yulieth Ruiz Rodriguez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19mora803729@siigo.com",
            "Area": "Nube",
            "Cedula": "1144166594",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:30 - 15:45",
            "Email": "mora803729@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:30",
            "Nombre": "Luisa Fernanda Mora Hurtado",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19sanc803610@siigo.com",
            "Area": "Nube",
            "Cedula": "1053855438",
            "Desc 1": "09:30 - 09:45",
            "Desc 2": "15:30 - 15:45",
            "Email": "sanc803610@siigo.com",
            "FIN Break 1": "09:45",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:30",
            "IN Break 2": "15:30",
            "Nombre": "Maira Alejandra Sanchez Villa",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19nune803724@siigo.com",
            "Area": "Nube",
            "Cedula": "1047407537",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:30 - 15:45",
            "Email": "nune803724@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:30",
            "Nombre": "Mariluz Nunez Martinez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19mart803744@siigo.com",
            "Area": "Nube",
            "Cedula": "1042457263",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:30 - 15:45",
            "Email": "mart803744@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:30",
            "Nombre": "Mayra Alejandra Martinez Del Guercio",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19sabo801681@siigo.com",
            "Area": "Nube",
            "Cedula": "1033807543",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:30 - 15:45",
            "Email": "sabo801681@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:30",
            "Nombre": "Chirlly Esneidy Sabogal Castro",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19chat804019@siigo.com",
            "Area": "Nube",
            "Cedula": "1061820333",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:30 - 15:45",
            "Email": "chat804019@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:30",
            "Nombre": "Juan Andres Chate Capote",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19bent803612@siigo.com",
            "Area": "Nube",
            "Cedula": "1051667894",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:30 - 15:45",
            "Email": "bent803612@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "13:30:00",
            "H Ini Alm": "12:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:30",
            "Nombre": "Katerine Paola Benthan Gutierrez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cerq801914@siigo.com",
            "Area": "Nube",
            "Cedula": "1106779797",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:30 - 15:45",
            "Email": "cerq801914@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:30",
            "Nombre": "Liset Viviana Cerquera Cuellar",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rosa901245@siigo.com",
            "Area": "Nube",
            "Cedula": "1057596225",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:30 - 15:45",
            "Email": "rosa901245@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:30",
            "Nombre": "Alejandro Rosas Chaparro",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19maci802775@siigo.com",
            "Area": "Nube",
            "Cedula": "1044422128",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:30 - 15:45",
            "Email": "maci802775@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "15:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:30",
            "Nombre": "Angie Paola Macias Corro",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19vele112392@siigo.com",
            "Area": "Nube",
            "Cedula": "43828216",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:45 - 16:00",
            "Email": "vele112392@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:45",
            "Nombre": "Clara Elena Velez Perez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19ramo803618@siigo.com",
            "Area": "Nube",
            "Cedula": "1045742631",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:45 - 16:00",
            "Email": "ramo803618@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:45",
            "Nombre": "Daniela Patricia Ramos Acosta",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19garc112008@siigo.com",
            "Area": "Nube",
            "Cedula": "53075007",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:45 - 16:00",
            "Email": "garc112008@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:45",
            "Nombre": "Diana Paola Garcia Arias",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19zapa802255@siigo.com",
            "Area": "Nube",
            "Cedula": "1075291230",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:45 - 16:00",
            "Email": "zapa802255@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:45",
            "Nombre": "Karen Vanessa Zapata Candela",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19pere802125@siigo.com",
            "Area": "Nube",
            "Cedula": "1057603754",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:45 - 16:00",
            "Email": "pere802125@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:45",
            "Nombre": "Manuel Antonio Perez Rodriguez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cast803617@siigo.com",
            "Area": "Nube",
            "Cedula": "1143265076",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:45 - 16:00",
            "Email": "cast803617@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:45",
            "Nombre": "Nelson Jesus Castro Rodriguez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19carr801804@siigo.com",
            "Area": "Nube",
            "Cedula": "1143162879",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:45 - 16:00",
            "Email": "carr801804@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:45",
            "Nombre": "Regina Sofia Carroll Arango",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19acos802244@siigo.com",
            "Area": "Nube",
            "Cedula": "1007157285",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:45 - 16:00",
            "Email": "acos802244@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:45",
            "Nombre": "Sara Lucia Acosta Valle",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19land801909@siigo.com",
            "Area": "Nube",
            "Cedula": "1057601587",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:45 - 16:00",
            "Email": "land801909@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:45",
            "Nombre": "Sulma Carolina Landinez Alvarado",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19avil803791@siigo.com",
            "Area": "Nube",
            "Cedula": "1049618411",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:45 - 16:00",
            "Email": "avil803791@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:45",
            "Nombre": "Wendy Yolany Avila Avila",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19agam802470@siigo.com",
            "Area": "Nube",
            "Cedula": "1143163733",
            "Desc 1": "09:45 - 10:00",
            "Desc 2": "15:45 - 16:00",
            "Email": "agam802470@siigo.com",
            "FIN Break 1": "10:00",
            "FIN Break 2": "16:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:45",
            "IN Break 2": "15:45",
            "Nombre": "Winndy Johanis Agamez Mendoza",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19bald804020@siigo.com",
            "Area": "Nube",
            "Cedula": "1001882094",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "bald804020@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Yudis Maria Baldovino Hoyos",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19faur901241@siigo.com",
            "Area": "Nube",
            "Cedula": "1049648819",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "faur901241@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Tatiana Marisol Faura Pizon",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19silv801636@siigo.com",
            "Area": "Nube",
            "Cedula": "1001876558",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "silv801636@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Yerlys Silva Polanco",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19beja802497@siigo.com",
            "Area": "Nube",
            "Cedula": "1116249046",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "beja802497@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Suly Johanna Bejarano Loaiza",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19diaz801406@siigo.com",
            "Area": "Nube",
            "Cedula": "79886467",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "diaz801406@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "William Yamid Diaz Buitrago",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rami801824@siigo.com",
            "Area": "Nube",
            "Cedula": "1056804373",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "rami801824@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Yenny Veronica Ramirez Gil",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19blan802056@siigo.com",
            "Area": "Nube",
            "Cedula": "1098749965",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "blan802056@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Yesica Patricia Blanco Alvarado",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19pozo801778@siigo.com",
            "Area": "Nube",
            "Cedula": "1143464229",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "pozo801778@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Alexandra Vanessa Pozo Padilla",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19lora803749@siigo.com",
            "Area": "Nube",
            "Cedula": "1050040490",
            "Desc 1": "10:00 - 10:15",
            "Desc 2": "16:00 - 16:15",
            "Email": "lora803749@siigo.com",
            "FIN Break 1": "10:15",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:00",
            "IN Break 2": "16:00",
            "Nombre": "Carlos Andres Lora Deavila",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cast801508@siigo.com",
            "Area": "Nube",
            "Cedula": "52838173",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "15:00 - 15:15",
            "Email": "cast801508@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "15:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:00:00",
            "H Ini Alm": "14:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:15",
            "IN Break 2": "15:00",
            "Nombre": "Carolina Castro Castano",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19diaz801504@siigo.com",
            "Area": "Nube",
            "Cedula": "1111202735",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:00 - 16:15",
            "Email": "diaz801504@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:00",
            "Nombre": "Cristian David Diaz Giraldo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19pach802774@siigo.com",
            "Area": "Nube",
            "Cedula": "1007552616",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:00 - 16:15",
            "Email": "pach802774@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:00",
            "Nombre": "Dionicio Pacheco Ibarra",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19dela801786@siigo.com",
            "Area": "Nube",
            "Cedula": "1143268053",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:00 - 16:15",
            "Email": "dela801786@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:00",
            "Nombre": "Georgelly Johanna De La Hoz Fontalvo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19garz800933@siigo.com",
            "Area": "Nube",
            "Cedula": "1018417353",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:00 - 16:15",
            "Email": "garz800933@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:00",
            "Nombre": "Jhon Jairo Garzon Ramirez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19guat801921@siigo.com",
            "Area": "Nube",
            "Cedula": "1057600396",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:00 - 16:15",
            "Email": "guat801921@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:00",
            "Nombre": "Maria Jose Guatibonza Florez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19mart801512@siigo.com",
            "Area": "Nube",
            "Cedula": "1052021077",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:00 - 16:15",
            "Email": "mart801512@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:00",
            "Nombre": "Maria Paula Martinez Perez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cubi803599@siigo.com",
            "Area": "Nube",
            "Cedula": "1057604381",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:00 - 16:15",
            "Email": "cubi803599@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:00",
            "Nombre": "Mariana Del Pilar Cubides Cely",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19arci801980@siigo.com",
            "Area": "Nube",
            "Cedula": "1005417933",
            "Desc 1": "10:15 - 10:30",
            "Desc 2": "16:00 - 16:15",
            "Email": "arci801980@siigo.com",
            "FIN Break 1": "10:30",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "10:15",
            "IN Break 2": "16:00",
            "Nombre": "Miguel Alberto Arciria Guevara",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19card800515@siigo.com",
            "Area": "Nube",
            "Cedula": "1010213611",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:00 - 16:15",
            "Email": "card800515@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:00",
            "Nombre": "Natalia Cardona Gutierrez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19suar801675@siigo.com",
            "Area": "Nube",
            "Cedula": "1006513195",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:00 - 16:15",
            "Email": "suar801675@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:15",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:00",
            "Nombre": "Paula Valentina Suarez Ramirez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cero800297@siigo.com",
            "Area": "Nube",
            "Cedula": "80183109",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:15 - 16:30",
            "Email": "cero800297@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:15",
            "Nombre": "Luis Alberto Ceron Velasco",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19polo803619@siigo.com",
            "Area": "Nube",
            "Cedula": "45564382",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:15 - 16:30",
            "Email": "polo803619@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:15",
            "Nombre": "Aurora Margarita Polo Alean",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19muri803609@siigo.com",
            "Area": "Nube",
            "Cedula": "65702125",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:15 - 16:30",
            "Email": "muri803609@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:15",
            "Nombre": "Dalia Rocio Murillo Paramo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19acos801880@siigo.com",
            "Area": "Nube",
            "Cedula": "1140900349",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:15 - 16:30",
            "Email": "acos801880@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:15",
            "Nombre": "Idalia Marcela Acosta Rodriguez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19pala801287@siigo.com",
            "Area": "Nube",
            "Cedula": "1234092366",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:15 - 16:30",
            "Email": "pala801287@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:15",
            "Nombre": "Jose Fernando Palacio Manotas",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19gall801835@siigo.com",
            "Area": "Nube",
            "Cedula": "1023979425",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:15 - 16:30",
            "Email": "gall801835@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:15",
            "Nombre": "Karoll Dayanna Gallego Preciado",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19guet801843@siigo.com",
            "Area": "Nube",
            "Cedula": "1083570775",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:15 - 16:30",
            "Email": "guet801843@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:15",
            "Nombre": "Kiara Yarnelyne Guette Ramirez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19cifu800927@siigo.com",
            "Area": "Nube",
            "Cedula": "1016074644",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:15 - 16:30",
            "Email": "cifu800927@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:15",
            "Nombre": "Lady Tatiana Cifuentes Avila",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19roch803738@siigo.com",
            "Area": "Nube",
            "Cedula": "1050956592",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:15 - 16:30",
            "Email": "roch803738@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:15",
            "Nombre": "Liliana Margarita Rocha Perez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19usec801951@siigo.com",
            "Area": "Nube",
            "Cedula": "1006032469",
            "Desc 1": "09:15 - 09:30",
            "Desc 2": "16:15 - 16:30",
            "Email": "usec801951@siigo.com",
            "FIN Break 1": "09:30",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:00:00",
            "H Ini Alm": "13:00:00",
            "Hr Fin": "17:30:00",
            "Hr Inicio": "8:00:00",
            "IN Break 1": "09:15",
            "IN Break 2": "16:15",
            "Nombre": "Maria Angelica Useche Cardenas",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19bece801292@siigo.com",
            "Area": "Nube",
            "Cedula": "1143263414",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:15 - 16:30",
            "Email": "bece801292@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:15",
            "Nombre": "Stefanny Becerra Garcia",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19pena901294@siigo.com",
            "Area": "Nube",
            "Cedula": "1003520110",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:15 - 16:30",
            "Email": "pena901294@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:15",
            "Nombre": "Yully Stephania Pena Guzman",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19vill802174@siigo.com",
            "Area": "Nube",
            "Cedula": "1094249274",
            "Desc 1": "10:30 - 10:45",
            "Desc 2": "16:15 - 16:30",
            "Email": "vill802174@siigo.com",
            "FIN Break 1": "10:45",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:30",
            "IN Break 2": "16:15",
            "Nombre": "Adriana Villamizar Jaimes",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19fern803600@siigo.com",
            "Area": "Nube",
            "Cedula": "1221978212",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:15 - 16:30",
            "Email": "fern803600@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:15",
            "Nombre": "Ana Karina De Jesus Fernandez Glen",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19leiv804017@siigo.com",
            "Area": "Nube",
            "Cedula": "1140891373",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:15 - 16:30",
            "Email": "leiv804017@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:15",
            "Nombre": "Carolay Patricia Leiva Buelvas",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19more901296@siigo.com",
            "Area": "Nube",
            "Cedula": "1007288313",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:15 - 16:30",
            "Email": "more901296@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:15",
            "Nombre": "Eliana Yulitza Moreno Mendez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19barr802493@siigo.com",
            "Area": "Nube",
            "Cedula": "1192816756",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:15 - 16:30",
            "Email": "barr802493@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:15",
            "Nombre": "Elias David Barrios Alvarez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rami802435@siigo.com",
            "Area": "Nube",
            "Cedula": "1001053560",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:15 - 16:30",
            "Email": "rami802435@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:15",
            "Nombre": "Gabrielle Alejandra Ramirez Celi",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19mart800916@siigo.com",
            "Area": "Nube",
            "Cedula": "1007845528",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:15 - 16:30",
            "Email": "mart800916@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "16:30",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:15",
            "Nombre": "Janeth Paola Martinez Cuartas",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19muri901243@siigo.com",
            "Area": "Nube",
            "Cedula": "1121864298",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:30 - 16:45",
            "Email": "muri901243@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:30",
            "Nombre": "Jennyffer Adriana Murillo Betancourt",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19sana801981@siigo.com",
            "Area": "Nube",
            "Cedula": "1099215393",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:30 - 16:45",
            "Email": "sana801981@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:30",
            "Nombre": "Jessica Andrea Sanabria Gerena",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19dura802457@siigo.com",
            "Area": "Nube",
            "Cedula": "1001933734",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:30 - 16:45",
            "Email": "dura802457@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:30",
            "Nombre": "Kevin Andres Duran Bravo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19garc802465@siigo.com",
            "Area": "Nube",
            "Cedula": "1003519488",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:30 - 16:45",
            "Email": "garc802465@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:30",
            "Nombre": "Laura Lucia Garcia Acosta",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19pach803776@siigo.com",
            "Area": "Nube",
            "Cedula": "1057598995",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:30 - 16:45",
            "Email": "pach803776@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:30",
            "Nombre": "Leidy Johana Pacheco Salcedo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19rodr801288@siigo.com",
            "Area": "Nube",
            "Cedula": "1023905191",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:30 - 16:45",
            "Email": "rodr801288@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "16:45",
            "Fecha": "2023-07-19",
            "H Fin Alm": "15:30:00",
            "H Ini Alm": "14:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:30",
            "Nombre": "Leslie Geraldine Rodriguez",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19torr801844@siigo.com",
            "Area": "Nube",
            "Cedula": "1056076052",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:45 - 17:00",
            "Email": "torr801844@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "17:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:45",
            "Nombre": "Paula Andrea Torres Fajardo",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19carr802754@siigo.com",
            "Area": "Nube",
            "Cedula": "1001897305",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:45 - 17:00",
            "Email": "carr802754@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "17:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:45",
            "Nombre": "Richard Eduardo Carrillo Villa",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19muno803733@siigo.com",
            "Area": "Nube",
            "Cedula": "1001995941",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:45 - 17:00",
            "Email": "muno803733@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "17:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:45",
            "Nombre": "Schalia Munoz Lerma",
            "Pais": "Todo"
        },
        {
            "_id": "2023-07-19bala801283@siigo.com",
            "Area": "Nube",
            "Cedula": "1014211561",
            "Desc 1": "10:45 - 11:00",
            "Desc 2": "16:45 - 17:00",
            "Email": "bala801283@siigo.com",
            "FIN Break 1": "11:00",
            "FIN Break 2": "17:00",
            "Fecha": "2023-07-19",
            "H Fin Alm": "14:30:00",
            "H Ini Alm": "13:30:00",
            "Hr Fin": "18:00:00",
            "Hr Inicio": "8:30:00",
            "IN Break 1": "10:45",
            "IN Break 2": "16:45",
            "Nombre": "Yuly Alexandra Balaguera Rodriguez",
            "Pais": "Todo"
        }
    ]
}

export const cuposDisponibles = [
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-01",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-02",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-03",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-04",
        "NECESARIOS": 127
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-05",
        "NECESARIOS": 125
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-06",
        "NECESARIOS": 129
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-07",
        "NECESARIOS": 120
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-08",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-09",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-10",
        "NECESARIOS": 160
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-11",
        "NECESARIOS": 130
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-12",
        "NECESARIOS": 131
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-13",
        "NECESARIOS": 132
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-14",
        "NECESARIOS": 160
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-15",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-16",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-17",
        "NECESARIOS": 160
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-18",
        "NECESARIOS": 114
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-19",
        "NECESARIOS": 108
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-20",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-21",
        "NECESARIOS": 106
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-22",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-23",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-24",
        "NECESARIOS": 116
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-25",
        "NECESARIOS": 109
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-26",
        "NECESARIOS": 106
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-27",
        "NECESARIOS": 103
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-28",
        "NECESARIOS": 160
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-29",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-30",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-07-31",
        "NECESARIOS": 160
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-01",
        "NECESARIOS": 160
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-02",
        "NECESARIOS": 140
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-03",
        "NECESARIOS": 122
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-04",
        "NECESARIOS": 122
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-05",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-06",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-07",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-08",
        "NECESARIOS": 150
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-09",
        "NECESARIOS": 151
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-10",
        "NECESARIOS": 152
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-11",
        "NECESARIOS": 119
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-12",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-13",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-14",
        "NECESARIOS": 160
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-15",
        "NECESARIOS": 145
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-16",
        "NECESARIOS": 124
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-17",
        "NECESARIOS": 112
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-18",
        "NECESARIOS": 120
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-19",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-20",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-21",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-22",
        "NECESARIOS": 125
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-23",
        "NECESARIOS": 129
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-24",
        "NECESARIOS": 121
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-25",
        "NECESARIOS": 123
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-26",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-27",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-28",
        "NECESARIOS": 128
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-29",
        "NECESARIOS": 122
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-30",
        "NECESARIOS": 150
    },
    {
        "CELULA": "Call Nube",
        "FECHA": "2023-08-31",
        "NECESARIOS": 155
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-01",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-02",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-03",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-04",
        "NECESARIOS": 16
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-05",
        "NECESARIOS": 16
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-06",
        "NECESARIOS": 15
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-07",
        "NECESARIOS": 14
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-08",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-09",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-10",
        "NECESARIOS": 21
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-11",
        "NECESARIOS": 18
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-12",
        "NECESARIOS": 16
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-13",
        "NECESARIOS": 16
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-14",
        "NECESARIOS": 22
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-15",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-16",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-17",
        "NECESARIOS": 24
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-18",
        "NECESARIOS": 18
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-19",
        "NECESARIOS": 16
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-20",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-21",
        "NECESARIOS": 15
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-22",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-23",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-24",
        "NECESARIOS": 17
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-25",
        "NECESARIOS": 16
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-26",
        "NECESARIOS": 15
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-27",
        "NECESARIOS": 15
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-28",
        "NECESARIOS": 19
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-29",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-30",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-07-31",
        "NECESARIOS": 20
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-01",
        "NECESARIOS": 20
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-02",
        "NECESARIOS": 16
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-03",
        "NECESARIOS": 16
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-04",
        "NECESARIOS": 16
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-05",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-06",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-07",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-08",
        "NECESARIOS": 20
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-09",
        "NECESARIOS": 21
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-10",
        "NECESARIOS": 22
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-11",
        "NECESARIOS": 18
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-12",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-13",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-14",
        "NECESARIOS": 22
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-15",
        "NECESARIOS": 21
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-16",
        "NECESARIOS": 17
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-17",
        "NECESARIOS": 15
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-18",
        "NECESARIOS": 15
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-19",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-20",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-21",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-22",
        "NECESARIOS": 18
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-23",
        "NECESARIOS": 16
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-24",
        "NECESARIOS": 15
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-25",
        "NECESARIOS": 14
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-26",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-27",
        "NECESARIOS": 0
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-28",
        "NECESARIOS": 15
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-29",
        "NECESARIOS": 16
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-30",
        "NECESARIOS": 18
    },
    {
        "CELULA": "Chat Colombia",
        "FECHA": "2023-08-31",
        "NECESARIOS": 19
    }
]