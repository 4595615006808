import React, { useState } from 'react';
import './CardExpandable.css'; // Asegúrate de importar el archivo CSS

const CardComponent = ({ tableName, tableId, jobName, avgTime, maxTime, minTime, state }) => {
  const [isStatsVisible, setIsStatsVisible] = useState(false);

  const toggleStatsVisibility = () => {
    setIsStatsVisible(!isStatsVisible);
  };

  return (
    <div className="Databrickscard">
      <div className="Databrickscard-header" title='Más Información' onClick={toggleStatsVisibility} style={{ cursor: 'pointer' }}>
        <h3>{tableName}</h3>
        <span>{isStatsVisible ? '▲' : '▼'}</span> 
      </div>
      <div className="Databrickscard-body">
        <p><strong>Table ID:</strong> {tableId}</p>
        <p><strong>Job Name:</strong> {jobName}</p>
        {avgTime && maxTime && minTime && state && (
          <div className={`Databrickscard-stats ${isStatsVisible ? 'visible' : 'hidden'}`}>
            <p><strong>Avg time update Job:</strong> {avgTime}</p>
            <p><strong>Max time update Job:</strong> {maxTime}</p>
            <p><strong>Min time update Job:</strong> {minTime}</p>
            <p><strong>State Job:</strong> <span className={state.toLowerCase()}>{state}</span></p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardComponent;
