import axios from 'axios';

export const controller = async (url, method, data) => {
  
  try {
    const response = await axios({ url, method, data });
    return response.data;
  } catch (error) {
    let errorMsg;
    if (error.response) {
      // Si error.response.data es un objeto, conviértelo en una cadena.
      errorMsg = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
    } else if (error.request) {
      errorMsg = 'No se recibió respuesta del servidor';
    } else {
      errorMsg = error.message;
    }
    throw new Error(errorMsg);
  }
};
