export const countries = [
	'Albania',
	'Andorra',
	'Armenia',
	'Austria',
	'Azerbaijan',
	'Belarus',
	'Belgium',
	'Bosnia & Herzegovina',
	'Bulgaria',
	'Croatia',
	'Cyprus',
	'Czech Republic',
	'Denmark',
	'Estonia',
	'Finland',
	'France',
	'Georgia',
	'Germany',
	'Greece',
	'Hungary',
	'Iceland',
	'Ireland',
	'Italy',
	'Kosovo',
	'Latvia',
	'Liechtenstein',
	'Lithuania',
	'Luxembourg',
	'Macedonia',
	'Malta',
	'Moldova',
	'Monaco',
	'Montenegro',
	'Netherlands',
	'Norway',
	'Poland',
	'Portugal',
	'Romania',
	'Russia',
	'San Marino',
	'Serbia',
	'Slovakia',
	'Slovenia',
	'Spain',
	'Sweden',
	'Switzerland',
	'Turkey',
	'Ukraine',
	'United Kingdom',
	'Vatican City',
];

export const incidenceTypes = [
	{
		label: 'Modelado de datos',
		value: 'Modelado de datos',
	},
	{
		label: 'Análisis',
		value: 'Análisis',
	},
	{
		label: 'Visualización',
		value: 'Visualización',
	},
];
export const ImpactTypes = [
	{
		label: 'Bajo',
		value: 'Bajo',
	},
	{
		label: 'Medio',
		value: 'Medio',
	},
	{
		label: 'Alto',
		value: 'Alto',
	},
];

export const areas = [
	'Sales and Marketing',
	'Customer and Success',
	'Operations',
	'Strategy',
	'Tech',
	'Finanzas',
	'People and Culture',
	'Educacion y Fundacion Siigo',
];
export const yes_no = [
	{
		label: 'Si',
		value: true,
	},
	{
		label: 'No',
		value: false,
	},
];
export const TypeofRequest = [
	{
		label: 'Incidencia',
		value: 'Incidencia',
	},
	{
		label: 'Requerimiento',
		value: 'Requerimiento',
	},
	{
		label: 'solicitud de Reporte',
		value: 'solicitud de Reporte',
	}
];

export const typeOfReport = [
	'Report',
	'Paginated Report',
	'Report (Related Datasets)',
	'Dashboard RSL'
];

export const frecuency = [
	{label: 'Diario', value: 'Diario'},
	{label: 'Día de por medio', value: 'Día de por medio'},
	{label: 'Cada tres días', value: 'Cada tres días'},
	{label: 'Semanal', value: 'Semanal'},
	{label: 'Mensual', value: 'Mensual'},
];

export const dataTree = [
	{
		text: 'Furniture',
		id: 1,
		items: [
			{
				text: 'Tables & Chairs',
				id: 2,
			},
			{
				text: 'Sofas',
				id: 3,
			},
			{
				text: 'Occasional Furniture',
				id: 4,
			},
		],
	},
	{
		text: 'Decor',
		id: 5,
		items: [
			{
				text: 'Bed Linen',
				id: 6,
			},
			{
				text: 'Curtains & Blinds',
				id: 7,
			},
			{
				text: 'Carpets',
				id: 8,
			},
		],
	},
];
export const keywords = [
	'# Churn',
	'# Synergy',
	'# Five9',
	'# CEO',
	'# CEO',
	'# Azure',
	'# Synergy',
	'# Google Analytics',
	'# Campañas',
	'# Synergy',
	'# TNB',
	'# Aspel',
	'# People App',
	'# Cartera',
	'# DataSmart',
];


export const dataTreeMulti = [
  {
    text: "Furniture",
    id: 1,
    items: [
      {
        text: "Tables & Chairs",
        id: 2,
      },
      {
        text: "Sofas",
        id: 3,
      },
      {
        text: "Occasional Furniture",
        id: 4,
      },
    ],
  },
  {
    text: "Decor",
    id: 5,
    items: [
      {
        text: "Bed Linen",
        id: 6,
      },
      {
        text: "Curtains & Blinds",
        id: 7,
      },
      {
        text: "Carpets",
        id: 8,
      },
    ],
  },
];