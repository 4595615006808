import React, {useState, useEffect} from 'react';
import * as FaIcons from 'react-icons/fa';
import './Navbar.css'
import ReactTooltip from 'react-tooltip'
import NavbarItems from './NavbarItems.js'
import {Roles} from '../usersManagement/roles'
import ChatBox from '../../Chatbot/components/ChatBox.jsx';


const menuItems=[
  {name: 'Home', exact: true, to: "/", iconName: 'FaHome'},
  {name: 'Reportes', exact: true, to: '/Reports', iconName: 'FaChartPie', subMenus: [{name: 'Sales & Marketing', to:'/Reports/ReportsSales'},{name: 'Customer Success', to:'/Reports/ReportsCustomer'}, {name: 'Finance', to:'/Reports/ReportsFinance'}, {name: 'Human Resources', to: '/Reports/ReportsHR'}, {name: 'Tech', to: '/Reports/ReportsTech'}]},
  {name: 'Aprendizaje', to: "/Training", iconName: 'FaGraduationCap', subMenus: [{name: 'Business Intelligence', to:'/Learning/LearningBI'},{name: 'Data Science', to:'/Learning/LearningDS'}, {name: 'Data Engineering' , to:'/Learning/LearningDE'}, {name: 'Soft Skills', to:'/Learning/SoftSkills'}]},
  {name: 'Gobierno de Datos', to: "/DataGovernance", iconName: 'FaLandmark',  subMenus: [{name: 'Diccionario de Datos', to:'/DataGovernance/Dictionary'},{name: 'Documentacion', to:'/DataGovernance/Documentation'}, {name: 'Organigrama', to:'/DataGovernance/OrgChart'}, {name: 'Procesos', to:'/DataGovernance/Processes'}]},
  {name: 'Requerimientos', to: "/HelpDesk", iconName: 'FaTasks'},
  {name: 'Herramientas', to: "/Tools", iconName: 'FaTools',  subMenus: [{name: 'Data Mart', to: '/Tools/DataMart'},{name: 'Segmentacion Siigo', to: '/Tools/Segmentacion'}, {name: 'Recomendaciones Comerciales', to: '/Tools/Wayfinder'}, {name: 'Status del Producto', to: '/Tools/ProductStatus'}, {name: 'Presupuesto', to: '/Tools/Budget'}]},
  {name: 'Compass', to: "/Compass", iconName: 'FaCompass',  subMenus: [{name: 'Ware', to: '/Compass/Ware'}, {name: 'Sales Forecast', to: '/Compass/SalesForecast'}, {name: 'Lending', to: '/Compass/Lending'}, {name: 'Financial Advisor', to: '/Compass  /FinancialAnalysis'}]}
];

function Navbar(props) {
  const [inactive,setInactive]= useState(true);
  useEffect(()=>{
    if(inactive)
      {document.querySelectorAll('.sub-menu').forEach(el=>{
        el.classList.remove('active');
      });
    }
    props.onCollapse(inactive)
  },[inactive]);

  return (
        <div className={`Navbar ${inactive ? "inactive":""}`}>
          <div className='top-section'>
              <FaIcons.FaBars onClick={()=> setInactive(!inactive)} className='logo'/>
          </div>
          
          
          <div className='search-section'>
            <button className='search-btn'>
              <FaIcons.FaSearch />
            </button>
            <input type='text' placeholder='Search...'/>
          </div>

          <div className='divider'></div>

          <div className='main-menu-ds'>
            <ul>
              {menuItems.map((menuItem, index) =>(
                // <div data-tip={menuItem.name} data-for='iconTooltip' >
                  <NavbarItems
                    key={index}
                    name={menuItem.name}
                    to={menuItem.to}
                    // subMenus={menuItem.subMenus || []}
                    iconName={menuItem.iconName}
                  />
                //   <ReactTooltip id='iconTooltip' place='left' type='light'/>
                // </div>
              ))}
            </ul>
          </div>
        </div>
  )
}

export default Navbar