
import { Dialog } from '@progress/kendo-react-dialogs';
import { useState } from 'react';
export const DialogComponent = ({children,visibility,setVisivility,title='ventana nueva'}) => {
  const [visible, setVisible] = useState(visibility);
  const toggleDialog = () => {
    setVisible(!visible);
    setVisivility(!visible)
  };
  return <div>
        
        {visible && <Dialog title={title} onClose={toggleDialog} height={'90%'}>
          {children}
        </Dialog>}
      </div>;
};
