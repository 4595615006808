import React from 'react';
import { Typography } from '@mui/material';

const Breadcrumb = ({ selectionHistory, navigateTo, randomImage }) => {
  return (
    <Typography variant="h6" component="div">
      {selectionHistory.map((item, index) => {
        const isLastItem = index === selectionHistory.length - 1;
        return (
          <React.Fragment key={item}>
            {isLastItem ? (
              <span className={`renewals-font-${randomImage.color} renewals-breadcrumb-item renewals-active `}>{item}</span>
            ) : (
              <React.Fragment>
                <span
                  className={`renewals-font-${randomImage.color} renewals-breadcrumb-item `}
                  onClick={() => navigateTo(index)}
                >
                  {item}
                </span>
                <span className={`renewals-font-${randomImage.color} renewals-breadcrumb-separator `}></span>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      })}
    </Typography>
  );
};

export default Breadcrumb;
