import React from "react";

export const ProfileData = (props) => {
    return (
        <div id="profile-div">
            <p><strong>First Name: </strong> {props.graphData.givenName}</p>
            <p><strong>Last Name: </strong> {props.graphData.surname}</p>
            <p><strong>Email: </strong> {props.graphData.userPrincipalName}</p>
            <p><strong>Cargo: </strong> {props.graphData.jobTitle}</p>
            <p><strong>Area: </strong> {props.graphData.department}</p>
            <p><strong>Pais: </strong> {props.graphData.country}</p>
            <p><strong>Id: </strong> {props.graphData.id}</p>
        </div>
    );
};


export const calendarData = (props) => {
    return (
        <div id="profile-div">
            <p><strong>First Name: </strong> {props.graphData.givenName}</p>
            <p><strong>Last Name: </strong> {props.graphData.surname}</p>
            <p><strong>Email: </strong> {props.graphData.userPrincipalName}</p>
            <p><strong>Cargo: </strong> {props.graphData.jobTitle}</p>
            <p><strong>Area: </strong> {props.graphData.department}</p>
            <p><strong>Pais: </strong> {props.graphData.country}</p>
            <p><strong>Id: </strong> {props.graphData.id}</p>
        </div>
    );
};