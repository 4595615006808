import './MassiveIncidents.css';
import React, { useState, useEffect } from 'react';
import {
    GridLayout, GridLayoutItem,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardActions,
    CardSubtitle,
    Avatar
} from '@progress/kendo-react-layout';
import { getIncidents, saveNewIncident, saveEditIncident, addClientToIncident, getAdmins } from './requestService';
import { useUserService } from './../../services/userService'
const itemsPerPage = 12; // Number of items per page

const MassiveIncidents = () => {

    const [isLoading, setIsLoading] = useState(true);
    const { userProfile } = useUserService();
    const [isAddingClient, setIsAddingClient] = useState(false);
    const [incidentes, setIncidentes] = useState([]);

    const [adminsList, setAdminsList] = useState([])

    const initialIncidentValue = {
        codigo: '',
        inicio: new Date().toISOString().split('T')[0],
        fin: '',
        estado: 'Abierto',
        descripcion: '',
        archivado: false,
        clientes: []
    };

    const [editingIncident, setEditingIncident] = useState(null);
    const [editIncidentValue, setEditIncidentValue] = useState(initialIncidentValue);
    const [addingClient, setAddingClient] = useState({
        nombre: '',
        telefono: '',
        ticket: '',
    });

    const formFields = [
        { name: 'codigo', type: 'text', placeholder: 'Codigo del incidente', colSpan: 1, rowSpan: 1, col: 1, row: 1 },
        { name: 'inicio', type: 'date', placeholder: 'Inicio', colSpan: 1, rowSpan: 1, col: 1, row: 2 },
        { name: 'fin', type: 'date', placeholder: 'Fin', colSpan: 1, rowSpan: 1, col: 1, row: 3 },
        { name: 'estado', type: 'select', options: ['Abierto', 'Cerrado'], colSpan: 1, rowSpan: 1, col: 1, row: 4 },
        { name: 'descripcion', type: 'textarea', placeholder: 'Descripcion', colSpan: 3, rowSpan: 4, col: 2, row: 1 },
        { name: 'solucion', type: 'textarea', placeholder: 'Solución', colSpan: 3, rowSpan: 4, col: 5, row: 1 },
    ];

    const handleChange = (ev) => {
        const { name, value } = ev.target;
        setEditIncidentValue({
            ...editIncidentValue,
            [name]: value,
        });
    };

    const toggleArchived = (incidente) => {
        let incidentToUpdate = { ...incidente }
        incidentToUpdate.archivado = !incidentToUpdate.archivado
        setIsLoading(true)
        saveEditIncident(incidentToUpdate, handleGetIncidents);
    }

    const generateCSV = (data) => {
        const header = "tiempo_registro,cliente_nombre,cliente_telefono,serial,creado_por\n";
        const csv = data.map((cliente) => {
            return `${cliente.reportTime},${cliente.nombre},${cliente.telefono},${cliente.ticket},${cliente.addedBy}`;
        });
        return header + csv.join("\n");
    }

    const downloadCSV = (incidenId, incidenteClients) => {
        console.log(incidenId, incidenteClients)
        const csvContent = generateCSV(incidenteClients);
        const blob = new Blob([csvContent], { type: "text/csv" });

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `MassiveIncident_${incidenId}.csv`;

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    const validateAndSaveIncident = () => {
        if (
            editIncidentValue.codigo != '' &&
            editIncidentValue.inicio != '' &&
            editIncidentValue.descripcion != '' &&
            editIncidentValue.estado != ''
        ) {
            setIncidentes([...incidentes, editIncidentValue]);
            saveNewIncident({
                ...editIncidentValue,
                addedBy: userProfile.email
            });
        } else {
            alert("Revisa la informacion del formulario");
        }
    }

    const validateAndSaveEditIncident = () => {
        if (
            editIncidentValue.codigo != '' &&
            editIncidentValue.inicio != '' &&
            editIncidentValue.descripcion != '' &&
            editIncidentValue.estado != ''
        ) {
            let newData = [...incidentes];
            newData[editingIncident] = editIncidentValue;
            setIncidentes(newData);
            saveEditIncident(editIncidentValue, handleGetIncidents);
            resetIncidentReportform()

            // remove clientes from editIncidentValue
            let editIncidentValueCopy = { ...editIncidentValue };
            delete editIncidentValueCopy.clientes;
        } else {
            alert("Revisa la informacion del formulario");
        }
    }

    const resetIncidentReportform = () => {
        setEditIncidentValue({
            codigo: '',
            inicio: '',
            fin: '',
            estado: 'Abierto',
            descripcion: '',
            archivado: false,
            clientes: [],
        });
        setEditingIncident(null);
    }

    const validateAndSaveClient = () => {
        let newData = [...paginatedIncidents];
        newData[editingIncident]['clientes'].push(
            addingClient
        );
        let newDataFull = [...incidentes];
        newDataFull[editingIncident]['clientes'].push(
            addingClient
        );
        setIncidentes(newDataFull);
        setAddingClient({
            nombre: '',
            telefono: '',
            correo: '',
            ticket: '',
        });
        addClientToIncident(newData[editingIncident], {
            ...addingClient,
            addedBy: userProfile.email
        })
    }

    const handleGetIncidents = (incidentsResponse) => {
        setIncidentes(incidentsResponse);
        setIsLoading(false);
    }

    const setAdmins = (data) => {
        console.log(data[0].admins)
        setAdminsList(data[0].admins)
    }

    const isUserAdmin = () => {
        const userEmail = sessionStorage.getItem('usr');
        return adminsList.includes(userEmail)

    }

    useEffect(() => {
        getAdmins(setAdmins)
        getIncidents(handleGetIncidents)
    }, [])
    // ... Existing state variables

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);

    // Sorting state
    const [sortOrder, setSortOrder] = useState('desc'); // 'asc' for ascending, 'desc' for descending

    // Function to toggle sorting order
    const toggleSortOrder = () => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        sortIncidents(newSortOrder);
    };

    // Function to sort incidents by creation date
    const sortIncidents = (order) => {
        const sorted = [...incidentes].sort((a, b) => {
            const dateA = new Date(a.inicio).getTime();
            const dateB = new Date(b.inicio).getTime();

            if (order === 'asc') {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });
        setIncidentes(sorted);
    };

    // Calculate the start and end indices for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Paginate the incidents based on the current page
    const paginatedIncidents = incidentes.slice(startIndex, endIndex);

    // Rest of your component code...

    return (
        isLoading ? <div className="loading">Loading&#8230;</div> :
            <>
                {
                    isUserAdmin() ? <><GridLayout className="massiveIncidents">
                        <GridLayoutItem colSpan={4}>
                            <Card>
                                <CardHeader
                                    className="k-hbox"
                                >
                                    <div>
                                        <CardTitle>
                                            <strong>Reporte de Incidentes</strong>
                                            {
                                                editingIncident != null ? <span> - Editando {editIncidentValue.codigo}</span> : <span>
                                                    Creando nuevo incidente
                                                </span>
                                            }
                                        </CardTitle>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <GridLayout
                                        gap={{ rows: 8, cols: 8 }}>
                                        {formFields.map((field) => (
                                            <GridLayoutItem
                                                key={field.name}
                                                colSpan={field.colSpan}
                                                rowSpan={field.rowSpan}
                                                col={field.col}
                                                row={field.row}
                                            >
                                                {field.type === 'select' ? (
                                                    <select name={field.name} value={editIncidentValue[field.name]} onChange={handleChange} disabled={editIncidentValue["estado"] == "Cerrado"}>
                                                        {field.options.map((option) => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                ) : field.type === 'textarea' ? (
                                                    <textarea
                                                        name={field.name}
                                                        placeholder={field.placeholder}
                                                        value={editIncidentValue[field.name]}
                                                        enabled={(field.name == 'solucion' && editIncidentValue["estado"] == "Abierto") || editIncidentValue["estado"] == "Cerrado"}
                                                        className='massiveIncidentsTextArea'
                                                        onChange={handleChange}
                                                    />
                                                ) : (
                                                    (!(field.name == 'fin' && editIncidentValue["estado"] == "Abierto")) ? <>
                                                        <input
                                                            type={field.type}
                                                            name={field.name}
                                                            enabled={(field.name == 'codigo' && editingIncident != null) || editIncidentValue["estado"] == "Cerrado"}
                                                            placeholder={field.placeholder}
                                                            value={editIncidentValue[field.name]}
                                                            onChange={handleChange}
                                                        />
                                                    </> : null
                                                )}
                                            </GridLayoutItem>
                                        ))}
                                    </GridLayout>
                                </CardBody>
                                <CardActions
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: "13px",
                                            alignSelf: "center",
                                            color: "#656565",
                                        }}
                                    >
                                        Registraremos tu correo electronico con cada registro
                                    </span>

                                    <div>
                                        {
                                            (editingIncident != null) ? <>
                                                <button
                                                    className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                                                    onClick={() => {
                                                        resetIncidentReportform()
                                                    }}>
                                                    <span className="k-icon k-i-undo" /> Cancelar
                                                </button>
                                            </> : null
                                        }
                                        <button
                                            className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                                            onClick={() => {
                                                if (editingIncident == null) {
                                                    validateAndSaveIncident();
                                                } else {
                                                    validateAndSaveEditIncident();
                                                }
                                            }}
                                        >
                                            <span className="k-icon k-i-save" /> Guardar {
                                                (editingIncident != null) ? 'Cambios' : 'Incidente'
                                            }
                                        </button>

                                    </div>
                                </CardActions>
                            </Card>
                        </GridLayoutItem>
                    </GridLayout>
                    </> : null
                }

                <GridLayout className="listIncidents ">
                    <GridLayoutItem colSpan={3}>
                        <button onClick={toggleSortOrder}>
                            Fecha de Creacion {sortOrder === 'asc' ? '▲' : '▼'}
                        </button>
                    </GridLayoutItem>
                    {paginatedIncidents.map((incidente, index) => {
                        if (isUserAdmin()) {
                            return (
                                <div
                                    key={`incidente${index}`}
                                    className={incidente.estado == 'Cerrado' ? "massiveIncidentsCardClosed" : "massiveIncidentsCardActive"} onClick={() => {
                                        setEditingIncident(index);
                                        setEditIncidentValue(paginatedIncidents[index]);
                                        if (index !== editingIncident) {
                                            setAddingClient({
                                                nombre: '',
                                                telefono: '',
                                                correo: '',
                                            });
                                        }
                                    }}>
                                    <Card key={index}>
                                        <CardHeader className="k-hbox" style={{ justifyContent: 'space-between' }}>
                                            <div>
                                                <Avatar type="image" size="medium" shape="circle" style={{
                                                    background: (incidente.estado == 'Cerrado') ? 'var(--siigo-green)' : 'var(--siigo-red)',
                                                }}></Avatar>
                                                <div>
                                                    <CardSubtitle>
                                                        <strong>{incidente.codigo}</strong>
                                                        <p>
                                                            <strong>{incidente.estado}</strong>
                                                            <br />
                                                            {incidente.inicio}{(incidente.estado == 'Abierto') ? ' - Activo' : ` - ${incidente.fin}`}
                                                        </p>
                                                    </CardSubtitle>
                                                </div>
                                            </div>
                                            {
                                                (isUserAdmin() && incidente.estado == "Cerrado") ? <>
                                                    <div >
                                                        <button onClick={() => toggleArchived(incidente)}>
                                                            {incidente.archivado ? "Desarchivar" : "Archivar"}
                                                        </button>
                                                    </div>
                                                </> : null
                                            }
                                        </CardHeader>
                                        <CardBody>
                                            <h5>Descripcion:</h5>
                                            <p>
                                                {incidente.descripcion}
                                            </p>
                                        </CardBody>
                                        {
                                            (incidente.estado == 'Cerrado') ? <>
                                                <hr />
                                                <CardBody>
                                                    <h5>Solucion:</h5>
                                                    <p>
                                                        {incidente.solucion}
                                                    </p>
                                                </CardBody>
                                            </> : null
                                        }
                                        <hr />
                                        {editingIncident == index && incidente.estado == "Abierto" && isAddingClient ? (
                                            <CardBody>
                                                <hr />
                                                <GridLayout
                                                    className="formAddUSerToList"
                                                    cols={[{ width: '100%' }]}
                                                >
                                                    <GridLayoutItem>
                                                        <label>Nombre del cliente</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Nombre del cliente"
                                                            className='massiveIncidentsUserForm'
                                                            value={addingClient.nombre}
                                                            onChange={(ev) => {
                                                                setAddingClient({
                                                                    ...addingClient,
                                                                    nombre: ev.target.value,
                                                                });
                                                            }}
                                                        />
                                                    </GridLayoutItem>
                                                    <GridLayoutItem>
                                                        <label>Telefono del cliente</label>
                                                        <input
                                                            type="number"
                                                            placeholder="Telefono del cliente"
                                                            className='massiveIncidentsUserForm'
                                                            value={addingClient.telefono}
                                                            onChange={(ev) => {
                                                                setAddingClient({
                                                                    ...addingClient,
                                                                    telefono: ev.target.value,
                                                                });
                                                            }}
                                                        />
                                                    </GridLayoutItem>
                                                    <GridLayoutItem>
                                                        <label>Serial</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Serial"
                                                            className='massiveIncidentsUserForm'
                                                            value={addingClient.ticket}
                                                            onChange={(ev) => {
                                                                setAddingClient({
                                                                    ...addingClient,
                                                                    ticket: ev.target.value,
                                                                });
                                                            }}
                                                        />
                                                    </GridLayoutItem>
                                                    <GridLayoutItem>
                                                        <button
                                                            onClick={() => {
                                                                validateAndSaveClient()
                                                            }}
                                                        >
                                                            Añadir Cliente
                                                        </button>
                                                    </GridLayoutItem>
                                                </GridLayout>
                                            </CardBody>
                                        ) : null}
                                        <CardActions
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: "13px",
                                                    alignSelf: "center",
                                                    color: "#656565",
                                                }}
                                            >
                                                {incidente.clientes.length} Cliente{incidente.clientes.length != 1 ? "s" : null} reportados
                                            </span>

                                            <div>
                                                {
                                                    isUserAdmin() ? <>
                                                        <button
                                                            className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                                                            onClick={() => {
                                                                console.log("download")
                                                                downloadCSV(incidente._id, incidente.clientes)
                                                            }}
                                                        >
                                                            <span className="k-icon k-i-download" /> Descargar
                                                        </button>
                                                    </> : null
                                                }

                                                {
                                                    (incidente.estado == "Abierto") ? <>
                                                        <button
                                                            className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                                                            onClick={() => {
                                                                setIsAddingClient(true);
                                                            }}>
                                                            <span className="k-icon k-i-plus" /> Registrar Cliente
                                                        </button>
                                                    </> : null
                                                }

                                            </div>
                                        </CardActions>
                                    </Card>
                                </div>
                            )
                        } else if (incidente.archivado) {
                            return null
                        } else {
                            return (
                                <div
                                    key={`incidente${index}`}
                                    className={incidente.estado == 'Cerrado' ? "massiveIncidentsCardClosed" : "massiveIncidentsCardActive"} onClick={() => {
                                        setEditingIncident(index);
                                        setEditIncidentValue(incidentes[index]);
                                        if (index !== editingIncident) {
                                            setAddingClient({
                                                nombre: '',
                                                telefono: '',
                                                correo: '',
                                            });
                                        }
                                    }}>
                                    <Card key={index}>
                                        <CardHeader className="k-hbox" style={{ justifyContent: 'space-between' }}>
                                            <div>
                                                <Avatar type="image" size="medium" shape="circle" style={{
                                                    background: (incidente.estado == 'Cerrado') ? 'var(--siigo-green)' : 'var(--siigo-red)',
                                                }}></Avatar>
                                                <div>
                                                    <CardSubtitle>
                                                        <strong>{incidente.codigo}</strong>
                                                        <p>
                                                            <strong>{incidente.estado}</strong>
                                                            <br />
                                                            {incidente.inicio}{(incidente.estado == 'Abierto') ? ' - Activo' : ` - ${incidente.fin}`}
                                                        </p>
                                                    </CardSubtitle>
                                                </div>
                                            </div>
                                            {
                                                (isUserAdmin() && incidente.estado == "Cerrado") ? <>
                                                    <div >
                                                        <button onClick={() => toggleArchived(incidente)}>
                                                            {incidente.archivado ? "Desarchivar" : "Archivar"} - {JSON.stringify({ "aRCHIVADO": incidente.archivado })}
                                                        </button>
                                                    </div>
                                                </> : null
                                            }
                                        </CardHeader>
                                        <CardBody>
                                            <h5>Descripcion:</h5>
                                            <p>
                                                {incidente.descripcion}
                                            </p>
                                        </CardBody>
                                        {
                                            (incidente.estado == 'Cerrado') ? <>
                                                <hr />
                                                <CardBody>
                                                    <h5>Solucion:</h5>
                                                    <p>
                                                        {incidente.solucion}
                                                    </p>
                                                </CardBody>
                                            </> : null
                                        }
                                        <hr />
                                        {editingIncident == index && incidente.estado == "Abierto" && isAddingClient ? (
                                            <CardBody>
                                                <hr />
                                                <GridLayout
                                                    className="formAddUSerToList"
                                                    cols={[{ width: '100%' }]}
                                                >
                                                    <GridLayoutItem>
                                                        <label>Nombre del cliente</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Nombre del cliente"
                                                            className='massiveIncidentsUserForm'
                                                            value={addingClient.nombre}
                                                            onChange={(ev) => {
                                                                setAddingClient({
                                                                    ...addingClient,
                                                                    nombre: ev.target.value,
                                                                });
                                                            }}
                                                        />
                                                    </GridLayoutItem>
                                                    <GridLayoutItem>
                                                        <label>Telefono del cliente</label>
                                                        <input
                                                            type="number"
                                                            placeholder="Telefono del cliente"
                                                            className='massiveIncidentsUserForm'
                                                            value={addingClient.telefono}
                                                            onChange={(ev) => {
                                                                setAddingClient({
                                                                    ...addingClient,
                                                                    telefono: ev.target.value,
                                                                });
                                                            }}
                                                        />
                                                    </GridLayoutItem>
                                                    <GridLayoutItem>
                                                        <label>Serial</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Serial"
                                                            className='massiveIncidentsUserForm'
                                                            value={addingClient.ticket}
                                                            onChange={(ev) => {
                                                                setAddingClient({
                                                                    ...addingClient,
                                                                    ticket: ev.target.value,
                                                                });
                                                            }}
                                                        />
                                                    </GridLayoutItem>
                                                    <GridLayoutItem>
                                                        <button
                                                            onClick={() => {
                                                                validateAndSaveClient()
                                                            }}
                                                        >
                                                            Añadir Cliente
                                                        </button>
                                                    </GridLayoutItem>
                                                </GridLayout>
                                            </CardBody>
                                        ) : null}
                                        <CardActions
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: "13px",
                                                    alignSelf: "center",
                                                    color: "#656565",
                                                }}
                                            >
                                                {incidente.clientes.length} Cliente{incidente.clientes.length != 1 ? "s" : null} reportados
                                            </span>

                                            <div>
                                                {
                                                    isUserAdmin() ? <>
                                                        <button
                                                            className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                                                            onClick={() => {
                                                                console.log("download")
                                                                downloadCSV(incidente._id, incidente.clientes)
                                                            }}
                                                        >
                                                            <span className="k-icon k-i-download" /> Descargar
                                                        </button>
                                                    </> : null
                                                }

                                                {
                                                    (incidente.estado == "Abierto") ? <>
                                                        <button
                                                            className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                                                            onClick={() => {
                                                                setIsAddingClient(true);
                                                            }}>
                                                            <span className="k-icon k-i-plus" /> Registrar Cliente
                                                        </button>
                                                    </> : null
                                                }

                                            </div>
                                        </CardActions>
                                    </Card>
                                </div>
                            )
                        }
                    })}
                </GridLayout>
                <GridLayout>
                    <GridLayoutItem />
                    <GridLayoutItem>
                        <div className="pagination">
                            <button
                                onClick={() => setCurrentPage(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="paginationButton"
                            >
                                Anterior
                            </button>
                            <div className='paginationDetails'>
                                Pagina {currentPage} de {Math.ceil(incidentes.length / itemsPerPage)}
                                <br />
                                Elementos {startIndex + 1} al {endIndex} de {incidentes.length}
                            </div>
                            <button
                                onClick={() => setCurrentPage(currentPage + 1)}
                                disabled={endIndex >= incidentes.length}
                                className="paginationButton"
                            >
                                Siguiente
                            </button>
                        </div>
                    </GridLayoutItem>
                    <GridLayoutItem />
                </GridLayout>
            </>
    );
};

export { MassiveIncidents };
