import {useEffect, useState} from 'react';
import '../../components/helpDesk/contentTarjet.css';
import {Badge, BadgeContainer} from '@progress/kendo-react-indicators';
import {Button, ButtonGroup} from '@progress/kendo-react-buttons';
import {GridTickets} from '../components/MyJob/GridTickets';
import useAxiosTicket from '../hooks/UseAxiosTickets';
import { SvgIcon } from '@progress/kendo-react-common';
import { chevronLeftIcon } from '@progress/kendo-svg-icons';
import { useNavigate } from 'react-router';
import { IsAuthorized } from '../../components/auth/IsAuthorized';
import { useSelector } from 'react-redux';
import { selectCredencialesArea, selectCredencialesRole } from '../../redux/credencialesReducer';

export const MyJob = () => {
	const tabNames = ['Nuevo', 'Asignado', 'Proceso', 'Revisión','Aprobación', 'Cerrado','Espera'];
	const navigate = useNavigate();
	const area = useSelector(selectCredencialesArea);
    const rol=useSelector(selectCredencialesRole);
	const [toSend, setToSend] = useState({
		name: sessionStorage.getItem('name'),
		rol: IsAuthorized('HelpDesk-dataPartner',area,rol )?"Data Partner":IsAuthorized('HelpDesk-resolver',area,rol )?"Resolver":"user",
		status:
		IsAuthorized('HelpDesk-dataPartner',area,rol )
				? 'Nuevo'
				: 'Asignado',
	});
	const [toSendNumToTickets, setToSendNumToTickets] = useState({
		name: sessionStorage.getItem('name'),
		rol: IsAuthorized('HelpDesk-dataPartner',area,rol )?"Data Partner":IsAuthorized('HelpDesk-resolver',area,rol )?"Resolver":"user",
	});

	const handleSelect = (selected) => {
		setToSend({
			...toSend,
			status: selected,
		});
	};
	const TabTitle = ({text, count}) => (
		<div style={{display: 'flex', alignItems: 'center'}}>
			{text}
			<Badge themeColor="tertiary" style={{marginLeft: 5}}>
				{count}
			</Badge>
		</div>
	);

	const {data: counter, isLoading: isLoadingCounter} = useAxiosTicket(
		'/api/totalTicketsByStatus',
		toSendNumToTickets
	); //acuerdate de quitar el POST
	useEffect(() => {}, []);

	function counterNum(item = 'Nuevo') {
		let matchingItem = false;
		if (!isLoadingCounter) {
			matchingItem = counter?.find((c) => c._id === item);
		}
		return matchingItem ? matchingItem.count : 0;
	}

	return (
		<>
			<br />
			<div style={{display:'flex',gap:'20px'}}>
				<div>
					<SvgIcon className='ButtonBack' icon={chevronLeftIcon} size="xxxlarge" onClick={()=>{navigate('/HelpDesk')}}/>
				</div>
				<h1 style={{fontWeight:'bolder',color:'#00244C',padding:'-2rem'}}>Mis asignaciones</h1>
			</div>
			<div className="contenedorTarjet">
				<br />
				<ButtonGroup>
					{tabNames.map((sectionItem, index) => (
						<Button
							key={index}
							themeColor={sectionItem === toSend.status ? 'primary' : 'light'}
							size={'large'}
							onClick={() => {
								handleSelect(sectionItem);
							}}>
							<TabTitle text={sectionItem} count={counterNum(sectionItem)} />
						</Button>
					))}
				</ButtonGroup>

				<div>
					<GridTickets toSend={toSend} />
				</div>
			</div>
		</>
	);
};
