import {Card, CardBody} from '@progress/kendo-react-layout';
import React from 'react';
import {useNavigate} from 'react-router-dom';

export const HeaderHome = ({name}) => {
	const doLink = (cadenaOriginal) => {
		const partes = cadenaOriginal.split('/');
		const nameReport = partes.splice(-2, 1)[0];
		let encodeNameReport = encodeURIComponent(nameReport);
		partes.pop();
		const cadenaReconstruida = encodeURIComponent(partes.join('/'));
		const cadenaFinal = `${cadenaReconstruida}?report=${encodeNameReport}`;
		return cadenaFinal;
	};
	const doTitle = (cadenaOriginal) => {
		let partes = '';
		try {
			partes = cadenaOriginal.split('-');
		} catch (error) {
			return cadenaOriginal;
		}
		let postGuion = partes[1].trim();
		let preGuion = partes[0].trim();

		if (postGuion.length < 22) {
			let charsToAdd = 22 - postGuion.length;
			let addition = preGuion.substring(0, charsToAdd);
			postGuion += ' ' + addition;
		}

		return postGuion;
	};
	let CustomReport = sessionStorage.getItem('customReports');

	let Topreports = [];

	if (CustomReport) {
		CustomReport = JSON.parse(CustomReport);
		Topreports = CustomReport.mostUsedReports;
		Topreports = Topreports.map((report) => {
			let link = doLink(report.route);
			let title = doTitle(report.name);
			return {title, link};
		});
	}

	let navigate = useNavigate();
	return (
		<Card
			style={{
				minHeight: 120,
				minWidth: 800,
				width: '100%',
			}}>
			<CardBody>
				<h2 className="titulo-home">
					Hola, <span>{name}!</span>{' '}
				</h2>
				<div style={{display: 'flex', gap: '15px'}}>
					{Topreports.length > 0 ? (
						<>
							<h5>{Topreports.length > 0 ? 'Top Reportes' : ''}</h5>
							<ul className="tres-columnas-home">
								{Topreports.map((card, index) => (
									<li
										className="li-reports-home"
										onClick={() => {
											navigate(`/dashboardpage/${card.link}`);
										}}
										key={index}>{`${card.title}`}</li>
								))}
							</ul>
						</>
					) : (
						<div style={{display:'flex',flexDirection:'column'}}><h4>Correo: {CustomReport?.email}</h4><div><span style={{fontWeight:'bold'}}>Área: </span>{CustomReport?.area}</div> </div>
					)}
				</div>
			</CardBody>
		</Card>
	);
};
