import React, {Component} from 'react'
import '../../components/status-card/statuscard.css'
import { SLAgreements } from '../../components/documentation/SLAs';


export const DGSLA=() => {

  return (
    <><h1 className='page-header-reports'>Acuerdos de Linea De Servicio</h1><div>
        <SLAgreements></SLAgreements>
    </div></>
      )
  }
