import { createSlice } from '@reduxjs/toolkit'

export const siignalReducer = createSlice({
    name: 'siignal',
    initialState: {
        modulesConfig: null,
        headCount: null,
        personalStatsTimes: null,
        personalStatsStates: null,
        selectedComponent: 'SiignalLandingcomponent',
        userData: null,
    },
    reducers: {
        setModulesConfig: (state, action) => {
            state.modulesConfig = action.payload
        },
        setHeadCount: (state, action) => {
            state.headCount = action.payload
        },
        setPersonalStatsTimes: (state, action) => {
            state.personalStatsTimes = action.payload
        },
        setPersonalStatsStates: (state, action) => {
            state.personalStatsStates = action.payload
        },
        setSelectedComponent: (state, action) => {
            state.selectedComponent = action.payload
        },
        setUserData: (state, action) => {
            state.userData = action.payload
        },
    },
});

export const {
    setModulesConfig,
    setHeadCount,
    setPersonalStatsStates,
    setPersonalStatsTimes,
    setSelectedComponent,
    setUserData
} = siignalReducer.actions;

export const selectModulesConfig = state => state.siignal.modulesConfig;
export const selectHeadCount = state => state.siignal.headCount;
export const selectPersonalStatsTimes = state => state.siignal.personalStatsTimes;
export const selectPersonalStatsStates = state => state.siignal.personalStatsStates;
export const selectSelectedComponent = state => state.siignal.selectedComponent;
export const selectUserData = state => state.siignal.userData;

export default siignalReducer.reducer;