import React, { useState } from 'react'
import { Card } from './card'

const handleCreateActivities = (setToast,handleSelect) => {
  setToast({snackbarOpen: true, message: `Crea una nueva actividad`, type: 'info'});
  let e={selected:3}
  handleSelect?.(e)
}

export const TablaActividades = ({ticketActivities,setItem,handleSelect,id,isPartner,setToast,timeElapsedStatus}) => {



  if(!ticketActivities||ticketActivities.length === 0){
    return(
      <div style={{
        paddingLeft: '200px',
        paddingTop: '100px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        <h1>Actividades</h1>
        <h2>No hay actividades</h2>
        <button className='Button-circle' onClick={()=>handleCreateActivities(setToast,handleSelect)}>
            <svg width="214" height="214" viewBox="0 0 24 24">
                <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
            </svg>
        </button>
      </div>
    );
  }
   
    
  return (
    <div className='container-activities'>
        {ticketActivities&&ticketActivities.map((activity,index)=><Card key={index} id={id} isPartner={isPartner} activity={activity} setItem={setItem}handleSelect={handleSelect} setToast={setToast} timeElapsedStatus={timeElapsedStatus} />)}
    </div>
  )
}
