import React, {useEffect, useState} from 'react';
import useAxiosEmails from '../../../components/ViewCustomer/Hook/UseAxios';
import {AutoComplete, DropDownList} from '@progress/kendo-react-dropdowns';
import {Loader} from '@progress/kendo-react-indicators';

export const ExtractEmails = ({setEmailAsesor}) => {
	const [value, setValue] = useState(null);
	const {
		data: emails,
		loading,
		error,
	} = useAxiosEmails('/api/extractAgentsMarketing');
	const [List, setList] = useState(['hola', 'mundo']);
	useEffect(() => {
		if (!error) setList(emails);
	}, [emails]);

	const handleChange = (event) => {
		setValue(event.target.value);

		const inputValue = event.target.value;
    	const selectedItem = List.find(item => item === inputValue);
		if (selectedItem) {
			// Si el usuario selecciona un nombre de la lista
			setEmailAsesor(event.target.value);

		} else {
			// Si el usuario está escribiendo o borrando texto, y no es una selección de la lista
		}
	};

	if (loading) {
		return <Loader size="small" type={'converging-spinner'} />;
	}
	return (
		<>
			<div>Correos Asesores:</div>
			<AutoComplete
				style={{
					width: '300px',
				}}
				data={List}
				value={value}
				onChange={handleChange}
			/>
		</>
	);
};
