import { DropDownList } from "@progress/kendo-react-dropdowns";
import { FieldWrapper } from "@progress/kendo-react-form";
import { useEffect, useRef, useState } from "react";
import {Label, Error, Hint, FloatingLabel} from '@progress/kendo-react-labels';
import { TimePicker } from "@progress/kendo-react-dateinputs";
import './scheduleStyle.css'
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { FormDatePicker } from "../../RequestForm/form-components";
import { arrowRotateCcwIcon } from "@progress/kendo-svg-icons";

export const ScheduleField = fieldRenderProps => {
    const {
      validationMessage,
      touched,
      label,
      id,
      valid,
      disabled,
      hint,
      value,
      wrapperStyle,
      ...others
    } = fieldRenderProps;
    const editorRef = useRef(null);
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';
    const [selectFrame, setSelectFrame] = useState('daily')
    const [messageTraslate, setMessageTraslate] = useState(null)
    const cronstrue = require('cronstrue');
    require('cronstrue/locales/es');
    const [GlobalValues, setGlobalValues] = useState(null)
    const [currentExpression, setCurrentExpression] = useState(null)
    const thereIsValue = value && value !== '';
    const FREQ=[
      {text: "Hours",value:'hour'},
      {text: "Days", value: "daily"},
      {text: "Weeks", value: "weekly"},
      {text: "Months", value: "monthly"},
    ];

    useEffect(() => {
      if(value){
        try {
          let expression = value;
          let description = cronstrue.toString(expression, { locale: "es" });
          setMessageTraslate(description)
        } catch (err) {
          console.error("Error al interpretar la expresión cron:", err);
        }
      }
    }, [])
    
  
    const Frame=({selectedFrame})=>{
      switch(selectedFrame){
        case 'hour':
          return <HourFrame />
        case 'daily':
          return <DailyFrame />
        case 'weekly':
          return <WeeklyFrame />
        case 'monthly':
          return <MonthyFrame />
        default:
          return <DailyFrame />
      }
    }

    const chooseStructure=(e)=>{
      setSelectFrame(e.target.value.value);
    }
    const setValidVField=(value) => {

      
      fieldRenderProps.onChange({
        value: value,
      });
      
      try {
        let expression = value;
        let description = cronstrue.toString(expression, { locale: "es" });
        setMessageTraslate(description)
      } catch (err) {
        console.error("Error al interpretar la expresión cron:", err);
      }
    }
    const HourFrame=()=>{

      const [maxDate, setMaxDate] = useState(0)
      const [validValue,setValidValue]= useState((GlobalValues&&GlobalValues.name==='hour')?GlobalValues:{
        name:"hour",
        every:{
          value:0,
          valid:true
        },
        hour:{
          value:null,
          valid:false,
          date:null,
        },
      });
      const [max, setMax] = useState(new Date(2010, 2, 10, maxDate, 59));

      useEffect(() => {
        setMax(new Date(2010, 2, 10, maxDate, 59));
      }, [maxDate]);
      

      const min = new Date(2000, 2, 10, 0,0 );

      let range=[
        {text:'Every 1', value:1},
        {text:'Every 2', value:2},
        {text:'Every 3', value:3},
        {text:'Every 4', value:4},
        {text:'Every 6', value:6},
        {text:'Every 8', value:8},
        {text:'Every 12', value:12},
      ];
      const onChangeTimer=(e)=>{
        let fecha = e.target.value;
        let hora = fecha.getHours();
        let minuto = fecha.getUTCMinutes();

      let hours={hora,minuto}
      setValidValue({
        ...validValue,
        hour:{
          value:hours,
          valid:true,
          date:fecha
        }
      })
      }
      const onChangeEvery=(e)=>{
        let limit={1:{max:0},2:{max:1},3:{max:2},4:{max:3},6:{max:5},8:{max:7},12:{max:11}}
        setMaxDate(limit[e.target.value.value].max)
        setValidValue({
          ...validValue,
          every:{
            value:e.target.value.value,
            valid:true
          }
        })
      }
      useEffect(() => {

        if(Object.entries(validValue).filter(([key]) => key !== 'name').every(([key, item]) => item.valid)){  
          const newExpression = expressionGenerator();
          if (newExpression !== currentExpression) {
            setGlobalValues(validValue);
            setValidVField(newExpression);
            setCurrentExpression(newExpression); // Suponiendo que mantienes el estado de la última expresión generada
          }
        }
      }, [validValue])
      const expressionGenerator=()=>{
        let expression=`${validValue.hour.value.minuto} ${validValue.hour.value.hora}/${validValue.every.value} * * ?`
        return expression;
      }
      
      return(
        <div>
          <div className="containerSchedule">
            <div className="row">
              <div className="col-4">
                <DropDownList ariaLabelledBy={labelId} data={range} style={{height:'30px'}} value={range.find((item)=>{return item.value===validValue.every.value})} onChange={onChangeEvery} textField="text" defaultValue={{text:'Every 1', value:1}} dataItemKey="value" ariaDescribedBy={`${hintId} ${errorId}`} ref={editorRef} valid={valid} id={id} disabled={disabled} />
              </div>
              <div className="col-3">
                <DropDownList ariaLabelledBy={labelId} data={FREQ} style={{height:'30px'}} onChange={chooseStructure} defaultValue={validValue.every.value===0?{text: "Hour",value:'hour'}:{text: "Hours",value:'hour'}} textField="text" dataItemKey="value" ariaDescribedBy={`${hintId} ${errorId}`} ref={editorRef} valid={valid} id={id} disabled={disabled} />
              </div>
              <div className="col-1">
                <Label className='k-form-label' style={{textAlign:'center',justifyContent: 'center',marginTop:'5px'}}>
                  <p>at</p>
                </Label>
              </div>
              <div className="col-4">
                <TimePicker format={{
                  hour: "2-digit",
                  minute: "2-digit"
                }} ariaDescribedBy={`${hintId} ${errorId}`} value={validValue.hour.date} onChange={onChangeTimer} min={min} max={max} ref={editorRef} valid={valid} id={id} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
      );
    }

    const WeeklyFrame=()=>{
      const [activeDays, setActiveDays] = useState((GlobalValues&&GlobalValues.name==='weekly')?GlobalValues.days.value:{
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false
    });
    const [validValue, setValidValue] = useState((GlobalValues&&GlobalValues.name==='weekly')?GlobalValues:{
      name:'weekly',
      hour:{
        value:null,
        valid:false,
        date:null
      },
      days:{
        value:activeDays,
        valid:false
      }
    })
      const onChangeTimer=(e)=>{
        let fecha = e.target.value;
        let hora = fecha.getHours();
        let minuto = fecha.getUTCMinutes();
        let hours={hora,minuto}
        setValidValue({
          ...validValue,
          hour:{
            value:hours,
            valid:true,
            date:fecha
          }
        })
      }

    // Función para manejar el clic en cada botón
    const handleButtonClick = (e, day) => {
      e.preventDefault();
      setActiveDays(prevState => {
        const updatedDays = {
          ...prevState,
          [day]: !prevState[day]
        };
    
        setValidValue(validValue => ({
          ...validValue,
          days: {
            value: updatedDays,
            valid: true
          }
        }));
    
        return updatedDays;
      });
    };

      useEffect(() => {
        let response=null
        let todosFalsos = Object.values(activeDays).every(valor => valor === false);
        if(Object.entries(validValue).filter(([key]) => key !== 'name').every(([key, item]) => item.valid)&&!todosFalsos){
          const newExpression = expressionGenerator();
          if (newExpression !== currentExpression) {
            setGlobalValues(validValue);
            setValidVField(newExpression);
            setCurrentExpression(newExpression); // Suponiendo que mantienes el estado de la última expresión generada
          }
        }
      }, [validValue])
      const expressionGenerator=()=>{
        let days = Object.entries(activeDays).map(([day, isActive], index) => isActive ? index : '').filter((item) => item !== '');
        let expression = `${validValue.hour.value.minuto} ${validValue.hour.value.hora} ? * ${days.join(',')}`
        return expression;
      }

      return(
        <div>
          <div className="containerSchedule">
            <div className="row">
            <div className="col-1">
                <Label className='k-form-label' style={{textAlign:'center',justifyContent: 'center',marginTop:'5px'}}>
                  <p>Every</p>
                </Label>
              </div>
              <div className="col-2">
                <DropDownList ariaLabelledBy={labelId} data={FREQ} style={{height:'30px'}} defaultValue={{text: "Weeks", value: "weekly"}} onChange={chooseStructure} textField="text" dataItemKey="value" ariaDescribedBy={`${hintId} ${errorId}`} ref={editorRef} valid={valid} id={id} disabled={disabled} />
              </div>
              <div className="col-7">
              <ButtonGroup>
                  {Object.entries(activeDays).map(([day, isActive]) => (
                      <Button
                          key={day}
                          togglable={true}
                          onClick={(e) => handleButtonClick(e,day)}
                          selected={isActive} 
                          ariaDescribedBy={`${hintId} ${errorId}`} ref={editorRef} valid={valid} id={id} disabled={disabled}
                      >
                          {day.charAt(0).toUpperCase() + day.slice(1)}
                      </Button>
                  ))}
              </ButtonGroup>
              </div>
              <div className="col-2" >
                <Label className='k-form-label' style={{marginTop:'-18px',marginBottom:'-18px'}}>
                  <p>At</p>
                </Label>
                <TimePicker format={{
                  hour: "2-digit",
                  minute: "2-digit"
                }} value={validValue.hour.date} ariaDescribedBy={`${hintId} ${errorId}`} onChange={onChangeTimer} ref={editorRef} valid={valid} id={id} disabled={disabled} />
              </div>
              
              
            </div>
          </div>
        </div>
      );
    }
    
    const DailyFrame=()=>{
      const [validValue, setValidValue] = useState((GlobalValues&&GlobalValues.name==='daily')?GlobalValues:{
        name:'daily',
        hour:{
          value:null,
          valid:false,
          date:null
        }
      });
      const onChangeTimer=(e)=>{
        let fecha = e.target.value;
        let hora = fecha.getHours();
        let minuto = fecha.getUTCMinutes();
        let hours={hora,minuto}
        setValidValue({
          ...validValue,
          hour:{
            value:hours,
            valid:true,
            date:fecha
          }
        })
      }
      useEffect(() => {
        let response=null
        if(Object.entries(validValue).filter(([key]) => key !== 'name').every(([key, item]) => item.valid)){
          const newExpression = expressionGenerator();
          if (newExpression !== currentExpression) {
            setGlobalValues(validValue);
            setValidVField(newExpression);
            setCurrentExpression(newExpression); // Suponiendo que mantienes el estado de la última expresión generada
          }
        }
      }, [validValue])
      const expressionGenerator=()=>{
        let expression=`${validValue.hour.value.minuto} ${validValue.hour.value.hora} * * ?`
        return expression;
      }
      return(
        <div>
          <div className="containerSchedule">
            <div className="row">
            <div className="col-1">
                <Label className='k-form-label' style={{textAlign:'center',justifyContent: 'center',marginTop:'5px'}}>
                  <p>Every</p>
                </Label>
              </div>
              <div className="col-4">
                <DropDownList ariaLabelledBy={labelId} data={FREQ} defaultValue={{text: "Days", value: "daily"}} style={{height:'30px'}} onChange={chooseStructure} textField="text" dataItemKey="value" ariaDescribedBy={`${hintId} ${errorId}`} ref={editorRef} valid={valid} id={id} disabled={disabled} />
              </div>
              <div className="col-1">
                <Label className='k-form-label' style={{textAlign:'center',justifyContent: 'center',marginTop:'5px'}}>
                  <p>at</p>
                </Label>
              </div>
              <div className="col-4">
                <TimePicker format={{
                  hour: "2-digit",
                  minute: "2-digit"
                }} value={validValue.hour.date} ariaDescribedBy={`${hintId} ${errorId}`} onChange={onChangeTimer} ref={editorRef} valid={valid} id={id} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
      );
    }
    const MonthyFrame=()=>{
      const [validValue, setValidValue] = useState((GlobalValues&&GlobalValues.name==='monthly')?GlobalValues:
        {
          name:'monthly',
          day:{
            value:new Date().getDate()>29?29:new Date().getDate(),
            valid:true
          },
          hour:{
            value:null,
            valid:false
          }
        }
      )

      const dayChoosed=(e)=>{

        setValidValue({
          ...validValue,
          day:{
            value:e.target.value,
            valid:true
          }
        })
      }
      const onChangeTimer=(e)=>{

        let fecha = e.target.value;
        let hora = fecha.getHours();
        let minuto = fecha.getUTCMinutes();
        let hours={hora,minuto}
        setValidValue({
          ...validValue,
          hour:{
            value:hours,
            valid:true
          }
        })
      }
      const daysArray = [];
      for (let i = 1; i <= 29; i++) {
        daysArray.push(i);
      }
      const date=new Date();
      let day=date.getDate();
      if(day>29){
        day=29;
      }
      useEffect(() => {
        let response=null
        if(Object.entries(validValue).filter(([key]) => key !== 'name').every(([key, item]) => item.valid)){
          const newExpression = expressionGenerator();
          if (newExpression !== currentExpression) {
            setGlobalValues(validValue);
            setValidVField(newExpression);
            setCurrentExpression(newExpression); // Suponiendo que mantienes el estado de la última expresión generada
          }
        }
      }, [validValue])
      const expressionGenerator=()=>{
        let expression=`${validValue.hour.value.minuto} ${validValue.hour.value.hora} ${validValue.day.value} * ?`
        return expression;
      }

      return(
        <div>
          <div className="containerSchedule">
            <div className="row">
            <div className="col-1">
                <Label className='k-form-label' style={{textAlign:'center',justifyContent: 'center',marginTop:'5px'}}>
                  <p>Every</p>
                </Label>
              </div>
              <div className="col-4">
                <DropDownList ariaLabelledBy={labelId} data={FREQ}  style={{height:'30px'}} defaultValue={{text: "Months", value: "monthly"}}  onChange={chooseStructure} textField="text" dataItemKey="value" ariaDescribedBy={`${hintId} ${errorId}`} ref={editorRef} valid={valid} id={id} disabled={disabled} />
              </div>
              <div className="col-1">
                <Label className='k-form-label' style={{textAlign:'center',justifyContent: 'center',marginTop:'5px'}}>
                  <p>on the</p>
                </Label>
              </div>
              <div className="col-2">
              <DropDownList ariaLabelledBy={labelId} value={validValue.day.value} data={daysArray} defaultValue={day} style={{height:'30px'}} onChange={dayChoosed} ariaDescribedBy={`${hintId} ${errorId}`} ref={editorRef} valid={valid} id={id} disabled={disabled} />
              </div>
              <div className="col-1">
                <Label className='k-form-label' style={{textAlign:'center',justifyContent: 'center',marginTop:'5px'}}>
                  <p>at</p>
                </Label>
              </div>
              <div className="col-3">
                <TimePicker format={{
                  hour: "2-digit",
                  minute: "2-digit"
                }} value={validValue.hour.date} ariaDescribedBy={`${hintId} ${errorId}`} onChange={onChangeTimer} ref={editorRef} valid={valid} id={id} disabled={disabled}/>
              </div>
            </div>
          </div>
        </div>
      );
    }
    const handleReset=(e)=>{
      e.preventDefault();
      fieldRenderProps.onChange({
        value: null,
      });
      setMessageTraslate(null)
      setGlobalValues(null)
      setSelectFrame('daily')
    }

    return <FieldWrapper style={wrapperStyle}>
          <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled} className='k-form-label'>
            {label}
          </Label>
          <br />
          <div className={'containerSchedule'}>
            
            {
            messageTraslate?(
              <div className="message-expressionCron">
                <h5>{messageTraslate}</h5>
                <Button svgIcon={arrowRotateCcwIcon} onClick={handleReset} fillMode={'flat'} size={'large'} className="button-expressionCron"></Button>
              </div>
            ):(null)
          }
          {thereIsValue?null:<Frame selectedFrame={selectFrame} />}
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
            
          </div>
          
        </FieldWrapper>;
  };