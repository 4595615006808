import { createSlice } from '@reduxjs/toolkit';

export const credencialesSlice = createSlice({
    name: 'credenciales',
    initialState: {
        credenciales:{}, 
        checking: false,
    },
    reducers: {
        setCredenciales: (state, action) => {
            state.credenciales = action.payload;
        },
        setChecking: (state, action) => {
            state.checking = action.payload;
        },

    }
});

// export const thunkGetMonthOperation=()=>{
//     return async (dispatch,getState)=>{
//         dispatch(changeIsLoadingDayOperation(true));

//             const response = await axios.get(`/Api/DayOperation`);
//             let test=response.data          
//         dispatch(allActividiesDayOperationPerMonth(test[0].days))
//         dispatch(changeIsLoadingDayOperation(false));
//     }
// }


export const { setCredenciales,setChecking } = credencialesSlice.actions;
export const selectCredenciales = state => state.credenciales.credenciales;
export const selectCredencialesArea = state => state.credenciales.credenciales.Area;
export const selectCredencialesRole = state => state.credenciales.credenciales.Role;
export const selectCredencialesCountry = state => state.credenciales.credenciales.Pais_de_Contratacion;
export const selectCredencialesName = state => state.credenciales.credenciales.Nombre;
export const selectCredencialesJob = state => state.credenciales.credenciales.Cargo;
export const selectCredencialesUsr = state => state.credenciales.credenciales.E_mail_Corporativo_1;
export const selectCredencialesSubArea = state => state.credenciales.credenciales.SubArea;

export const selectChecking = state => state.credenciales.checking;
export default credencialesSlice.reducer;
