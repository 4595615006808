import React, {useState, useEffect} from 'react';
import { FormElement, Field } from '@progress/kendo-react-form';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Label, Error } from '@progress/kendo-react-labels';
import { TextArea, Input } from '@progress/kendo-react-inputs';
import { DatePicker, DateTimePicker } from '@progress/kendo-react-dateinputs';
import { CountryEditor, AreaEditor, RoomEditor, TherapistEditor } from './editors.tsx';
import { stakeholderValidator } from '../../HelpDesk/components/RequestForm/Validators.js';
import { DropdownWorkers } from '../../HelpDesk/components/RequestForm/DropdownWorkers.js';
export const CustomFormEditor = props => {
  return (
    <FormElement horizontal={true}>
    <div className="k-form-field">
      <Label>
        Nombre
      </Label>
      <div className="k-form-field-wrap">
        <Field name={'Nombre'} component={Input} disabled={true}/>
      </div>
    </div>
    <div className="k-form-field">
      <Label>
        Usuario
      </Label>
      <div className="k-form-field-wrap">
        <Field name={'Usuario'} component={Input} disabled={true}/>
      </div>
    </div>
    <div className="k-form-field">
      <Label>
        Pais
      </Label>
      <div className="k-form-field-wrap">
        <Field name={'country'} component={CountryEditor}/>
        {props.errors.country && <Error>{props.errors.country}</Error>}
      </div>
    </div>
    <div className="k-form-field">
      <Label>
        Usuario Final
      </Label>
      <div className="k-form-field-wrap" style={{marginTop:'-15px'}}>
        {/* <Field name={'area'} component={AreaEditor}/> */}
        <Field
          key={'stakeholder'}
          id={'stakeholder'}
          name={'stakeholder'}
          component={DropdownWorkers}
          value={props}
          validator={stakeholderValidator}
          width={'16.6rem'}
        />
        {/* {props.errors.area && <Error>{props.errors.area}</Error>} */}
      </div>
    </div>
    <div className="k-form-field">
      <Label>
        Tipo de Actividad
      </Label>
      <div className="k-form-field-wrap">
        <Field name={'activityType'} component={RoomEditor}/>
        {props.errors.activityType && <Error>{props.errors.activityType}</Error>}
      </div>
    </div>
    <div className="k-form-field">
      <Label>
        Actividad Mesa de Ayuda
      </Label>
      <div className="k-form-field-wrap">
        <Field name={'ticketActivity'} component={TherapistEditor}/>
      </div>
    </div>
    <div className="k-form-field">
      <Label>
        Nombre Actividad
      </Label>
      <div className="k-form-field-wrap">
        <Field name={'TituloActividad'} component={Input} />
        {props.errors.TituloActividad && <Error>{props.errors.TituloActividad}</Error>}
      </div>
    </div>
    <div className="k-form-field">
    <Label>Start</Label>
    <div className="k-form-field-wrap">
      <div
        style={{
          width: "100%",
          // display: "flex",
          alignItems: "center",
        }}
      >
        <Field
          name={"Start"}
          component={props.startEditor || DatePicker}
          as={DateTimePicker}
          rows={1}
          width={"140px"} 
          format="t"
        />
        &nbsp;
        <Label>End</Label>
        &nbsp;
        <Field
          name={"End"}
          component={props.endEditor || DatePicker}
          as={DateTimePicker}
          rows={1}
          width={"140px"}
          format="t"
        />
      </div>
    </div>
  </div>
    <div className="k-form-field">
      <Label>
        Descripcion
      </Label>
      <div className="k-form-field-wrap">
        <Field name={'Descripcion'} component={TextArea} rows={1}  />
        {props.errors.Descripcion && <Error>{props.errors.Descripcion}</Error>}
      </div>
    </div>
  </FormElement>
  )
};