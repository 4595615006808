import { Label } from '@mui/icons-material';
import { Container, TextField, Box } from '@mui/material';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';
import axios from 'axios';
 
import { formatMessage, loadMessages, locale } from 'devextreme/localization';

import DataGrid, {
    Column,
    Editing,
    Popup,
    Paging,
    Lookup,
    Form,
} from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { Item } from 'devextreme-react/form';

const notesEditorOptions = { height: 100 };

const IActivity = {
    idActivity: "",
    DescriptionActivity: "",
    objectives: "",
    assignedTo: "",  
    resolverEmail:"",      
    statusTask: "",        
    dateStart: "",
    dateEnd: "",                        
}

const ActivityCard = ({handlerActivity, Activitys}) => {
    // JS
    locale(navigator.language);
    const arrActivity = [];
    const [currentActivity, setCurrentActivity] = useState(IActivity);
    const [selectResolutor, setSelectResolutor] = useState([]);
    const [resolutorName, setResolutorName] = useState([]);


    const status = [{
        ID: 'Asignado',
        Name: 'Asignado'
    }, {
        ID: 'Proceso',
        Name: 'Proceso'
    },{
        ID: 'Revisión',
        Name: 'Revisión'
    },{
        ID: 'Cerrado',
        Name: 'Cerrado'
    }];    

    const onRowInserted = React.useCallback((e) => {
        e.cancel = true;

        if(e.component.totalCount() === 10){         
            e.element.childNodes[0].childNodes[3].childNodes[0].childNodes[0].childNodes[2].childNodes[0].hidden = true;
        }        
    }, []);

    
    const onRowRemoved = React.useCallback((e) => { 
        
        if(e.component.totalCount() < 10){
            e.element.childNodes[0].childNodes[3].childNodes[0].childNodes[0].childNodes[2].childNodes[0].hidden = false;            
        }


        if(e.component.totalCount() === 0){
            console.log('todos removidos');            
        }

    }, []);

    const onSaving = React.useCallback( (e) => {                
        //console.log(e.component.getVisibleRows());
        //let newActivity = e.changes[0].data;
        //console.log(e.changes[0].key);
        //newActivity['idActivity'] = newActivity['__KEY__'];
        //delete newActivity['__KEY__'];
        //setCurrentActivity(newActivity);
        //console.log(currentActivity);
        //newActivity['dateStart'] = dayjs(newActivity['dateStart']).format('DD/MM/YYYY');
        //newActivity['dateEnd'] = dayjs(newActivity['dateEnd']).format('DD/MM/YYYY');
        //newActivity.idActivity = 
        //arrActivity.push(newActivity);
        //setCurrentActivity(arrActivity);
        //handlerActivity(newActivity);
       // Activitys = Activity;
        //sessionStorage.setItem('newActivity', JSON.stringify(arrActivity));
    }, []);

    const onSaved = React.useCallback( (e) => {     
        //console.log('saved',e.component.getVisibleRows()); 

        let arreglo = [];
        let newActivity = {};
        //sessionStorage.setItem.apply("editForm","1");
        e.component.getVisibleRows().forEach(element => {
            newActivity = {};
            newActivity = element.data;
            newActivity['idActivity'] = newActivity['__KEY__'];
            //delete newActivity['__KEY__'];
    
            //newActivity['dateStart'] = dayjs(newActivity['dateStart']).format('DD/MM/YYYY');
            //newActivity['dateEnd'] = dayjs(newActivity['dateEnd']).format('DD/MM/YYYY');

            arreglo.push(newActivity);
            
        });

        //console.log('saved', arreglo);

    }, []);
    
    const onValueChanged=React.useCallback((e) =>{
        //console.log('llama evento:',e.name);

        // setSelectedValue(e.component);
        //setResolutorName(value);
    });


    const onContentReady = React.useCallback( (e) => {
        //console.log('entro');
        let api_url = '/api/listRolesDataAnalitycs';
        let data = {};
        axios.post(api_url, { data }, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Origin': '*'
            }
        })
            .then(response => {
                // console.log('select1: ',response)
                setSelectResolutor(response);
                
            })
            .catch(err => {
                console.log(err);
                alert("El requerimiento no se guardo, favor de revisar la información");
            });     
    });
    

    // HTML
    return (
        <Container>
            
                <DataGrid
                    dataSource={ Activitys ? Activitys : currentActivity}
                    //keyExpr="idActivity"
                    key="idActivity"
                    showBorders={true}
                    onRowInserted={onRowInserted}
                    onRowRemoved={onRowRemoved}
                    onSaving={onSaving}
                    onSaved={handlerActivity}
                    onInitialized={onContentReady}
                    noDataText = "No hay actividades registradas"
                >
                    <Paging enabled={false} />
                    <Editing
                        mode="popup"                                
                        allowUpdating={true}
                        allowAdding={true}                        
                        allowDeleting={true}>
                        <Popup title="Información de la actividad" showTitle={true} width={700} height={450} />
                        <Form>
                            <Item itemType="group" colCount={2} colSpan={2}>
                                <Item disabled={true} dataField="idActivity" visible={false}/>
                                <Item dataField="resolverEmail" colSpan={2} />
                                <Item dataField="assignedTo" disabled={true} colSpan={2} visible={false} />
                                <Item dataField="objectives" />
                                <Item dataField="statusTask" />
                                <Item dataField="dateStart" />
                                <Item dataField="dateEnd" />                                    
                                <Item
                                    dataField="DescriptionActivity"
                                    editorType="dxTextArea"
                                    colSpan={2}
                                    editorOptions={notesEditorOptions} />
                            </Item>                            
                        </Form>
                    </Editing>                    
                    <Column dataField="idActivity" caption="Id Actividad" visible={true}/>
                    <Column dataField="resolverEmail" caption="Asignado a">
                        {/* <Lookup dataSource={selectResolutor.data} valueExpr="username" displayExpr="username" onValueChanged={this.onValueChanged}/> */}
                        <Lookup dataSource={selectResolutor.data} valueExpr="username" displayExpr="username"  onSelectionChanged={onValueChanged}/>
                    </Column>
                    <Column dataField="assignedTo" caption="Nombre"/>   
                    <Column dataField="objectives" caption="Objetivos"/>                    
                    <Column dataField="statusTask" caption="Estado" width={125}>
                        <Lookup dataSource={status} valueExpr="ID" displayExpr="Name" />
                    </Column>
                    <Column dataField="dateStart" dataType="date" caption="Fecha inicio" />                    
                    <Column dataField="dateEnd" dataType="date" caption="Fecha fin"/>                    
                    <Column dataField="DescriptionActivity" caption="Descripción" />
                </DataGrid>            
        </Container>
    )
}
export default ActivityCard;