import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid } from '@mui/material';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const CommentCard = ({jComments} ) => {
  function dateFormat(date){
    let f1 = date.substring(0, 10);
    let f2 = date.substring(11, 19);
    return f1 + ', ' + f2;
  }
  function getcommentPersonName(commentPerson){
    console.log(commentPerson)
    let name=commentPerson['name'];
    return name
  }
  return (
    jComments.map((item) => (
    <Grid key={item.commentsId}>
      <Card bg="Light" style={{ width: '30rem' }} >
      <CardActionArea>
      <CardContent>
      <Row>
      <Col>        
        {dateFormat(item.dateComment['$date'])}        
      </Col>
      </Row>
      
        <Typography gutterBottom variant="subtitle1" component="div">
          {getcommentPersonName(item.commentPerson)}
        </Typography>
        <Typography gutterBottom variant="alignJustify" component="div">
          {item.comments}
        </Typography>
       
      </CardContent>
      </CardActionArea>
      </Card>
    </Grid>
    
    ))

  );
};

export default CommentCard;