import {useSelector} from 'react-redux';
import CardComponent from './CardExpandable';
import {selectData} from '../../../../../redux/viewBISlice';

export const FourthSlice = () => {
	const mainData = useSelector(selectData);
	const {dataLineage} = mainData || {};

	return (
		<div>
			{dataLineage?.map((item, index) => {
				return (
					<CardComponent
						tableName={item.tableName}
						tableId={item.tableId}
						jobName={item.jobName}
						avgTime={item.details.AvgTime}
						maxTime={item.details.MaxTime}
						minTime={item.details.MinTime}
						state={item.details.state}
						key={index}
					/>
				);
			})}
		</div>
	);
};

export default FourthSlice;
