import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import './uploadReportPage.css';
import {
	Form,
	Field,
	FormElement,
	FieldWrapper,
} from '@progress/kendo-react-form';
import {Error} from '@progress/kendo-react-labels';
import {Input, Checkbox} from '@progress/kendo-react-inputs';
import {DropDownList, MultiSelect} from '@progress/kendo-react-dropdowns';
import axios from 'axios';
import {CircularProgress} from '@mui/material';

export const UpdatableReportsDropdown = (fieldRenderProps) => {
	const actualRute = useParams().actualRute;
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';
	const [resultsUpdatableReports, setResultsUpdatableReports] = useState([]);

	React.useEffect(() => {
		const getReports = async () => {
			const responseReports = await axios.get(
				`/api/DataSmart/Route/Reports?route=${actualRute}`
			);
			setResultsUpdatableReports(responseReports.data);
		};
		getReports();
	}, []);
	return (
		<FieldWrapper style={wrapperStyle}>
			<DropDownList
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				data={resultsUpdatableReports}
				{...others}
			/>
		</FieldWrapper>
	);
};

const reportIDInput = (fieldRenderProps) => {
	const {validationMessage, visited, ...others} = fieldRenderProps;
	return (
		<div>
			<Input {...others} />
			{visited && validationMessage && <Error>{validationMessage}</Error>}
		</div>
	);
};

const reportNameInput = (fieldRenderProps) => {
	const {validationMessage, visited, ...others} = fieldRenderProps;
	return (
		<div>
			<Input {...others} />
			{visited && validationMessage && <Error>{validationMessage}</Error>}
		</div>
	);
};

const reportIdLengthValidator = (value) => {
	return !value || value.length < 36
		? 'El ReportID tiene que ser de 36 Caracteres'
		: undefined;
};

const reportNameLengthValidator = (value) => {
  if (!value || value.length > 22) {
      return 'El Titulo del Reporte tiene que ser de menos de 22 Caracteres';
  }
  
  if (value.includes('/')) {
      return 'El Titulo del Reporte no puede contener el carácter " / "';
  }

  return undefined;
};


export default function UploadReport({setVisibleUploadReport}) {
	const user = sessionStorage.getItem('usr');
	const actualRute = useParams().actualRute;
	const [isloading, setIsloading] = useState(false);
	const userRoles = [
		'AKKRdirector',
		'Analyst',
		'Data Analyst',
		'Data Business Partner',
		'Manager',
		'businessAnalyst',
		'cLevel',
		'dataAnalyst',
		'director',
		'experienceManager',
		'financeManager',
		'learningManager',
		'liderCelula',
		'manager',
		'pgAnalyst',
		'portalAdmin',
		'procurementManager',
		'productOwner',
		'salesAnalyst',
		'teamLeader',
		'user',
		'wfManager',
	];
	const reportType = [
		'Report',
		'Report (Related Datasets)',
		'Paginated Report',
	];
	const areas = [
		'Country Management',
		'Customer Success',
		'Finance & Administration',
		'Investigacion y Desarrollo',
		'Marketing',
		'Mercadeo',
		'People & Culture',
		'Product',
		'Producto',
		'Sales',
		'Sistemas Mexico',
		'Soporte Tecnico',
		'Strategy',
		'Data Analytics',
		'TI',
	];
	const [value, setValue] = React.useState([]);
	const onChange = (event) => {
		setValue([...event.value]);
	};
	const [valueUpdate, setValueUpdate] = React.useState(false);
	const onChangeUpdate = () => {
		setValueUpdate(!valueUpdate);
	};
	const [valueRestricted, setValueRestricted] = React.useState(false);
	const onChangeRestricted = () => {
		setValueRestricted(!valueRestricted);
	};
	const [valueUser, setValueUser] = React.useState([]);
	const onChangeUser = (event) => {
		let newValue = [...event.value];
		// Asegurar que 'portalAdmin' esté en la lista
		if (!newValue.includes('portalAdmin')) {
			newValue.push('portalAdmin');
		}
		setValueUser(newValue);
	};

	const [valueReportType, setValueReportType] = React.useState([]);
	const onChangeReportType = (event) => {
		setValueReportType(event.target.value);
	};
	const handleSubmit = async (dataItem) => {
		setIsloading(true);
		const customConfig = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		try {
			let data;
			if (valueUpdate) {
				data = JSON.stringify({
					reportId: dataItem.reportId,
					reportTitle: dataItem.reportTitle,
					masterRoute: actualRute,
					updateFlag: valueUpdate,
					modifiedBy: user,
				});
				const response = await axios.post(
					'/api/DataSmart/Reports/Register',
					data,
					customConfig
				);
				alert(response.data);
			} else if (!valueUpdate) {
				data = JSON.stringify({
					reportTitle: dataItem.reportTitle,
					reportId: dataItem.reportId,
					updateFlag: valueUpdate,
					itemType: valueReportType,
					restricted: valueRestricted,
					Area: value,
					user: valueUser,
					masterRoute: actualRute,
					createdBy: user,
				});
				const response = await axios.post(
					'/api/DataSmart/Reports/Register',
					data,
					customConfig
				);
				alert(response.data);
			}
		} catch (error) {
			// Puedes manejar y mostrar el error aquí si lo deseas
			console.error('Error al enviar la petición:', error);
			alert('Ocurrió un error al enviar los datos.');
		} finally {
			setVisibleUploadReport(false);
			setIsloading(false);
		}
	};

	return (
		<>
			{isloading ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						margin: 20,
					}}>
					<h2>Procesando tu petición...</h2>
					<CircularProgress />
				</div>
			) : (
				<Form
					onSubmit={handleSubmit}
					render={(formRenderProps) => (
						<FormElement
							style={{
								maxWidth: 650,
							}}>
							<fieldset className={'k-form-fieldset'}>
								<legend className={'k-form-legend'}>
									Para publicar un reporte diligencia el siguiente formulario:
								</legend>
								<Checkbox
									label={'Quiere reemplazar un reporte?'}
									value={valueUpdate}
									onClick={onChangeUpdate}
								/>
								{valueUpdate && (
									<>
										<div className="mb-3">
											<Field
												name={'reportTitle'}
												component={UpdatableReportsDropdown}
												label={'Nombre del Reporte'}
											/>
											<Field
												name={'reportId'}
												component={reportIDInput}
												label={'Codigo del Reporte'}
												validator={reportIdLengthValidator}
											/>
										</div>
									</>
								)}
								{!valueUpdate && (
									<>
										<div className="mb-3">
											<Field
												name={'reportTitle'}
												component={reportNameInput}
												label={'Titulo del Reporte'}
												validator={reportNameLengthValidator}
											/>
										</div>
										<div className="mb-3">
											<Field
												name={'reportId'}
												component={reportIDInput}
												label={'Codigo del Reporte'}
												validator={reportIdLengthValidator}
											/>
										</div>
										<div className="mb-3">
											<DropDownList
												label="Tipo de Reporte"
												data={reportType}
												onChange={onChangeReportType}
												value={valueReportType}
												required={true}
												placeholder="Please select ..."
											/>
										</div>
										<div className="mb-3">
											<Checkbox
												label={'Quiere restringir el reporte?'}
												value={valueRestricted}
												onClick={onChangeRestricted}
											/>
											{valueRestricted && (
												<>
													<MultiSelect
														label="Seleccionar Areas"
														data={areas}
														onChange={onChange}
														value={value}
														required={true}
														placeholder="Please select ..."
													/>
													<MultiSelect
														label="Seleccionar tipo de Usuario"
														data={userRoles}
														onChange={onChangeUser}
														value={valueUser}
														required={true}
														placeholder="Please select ..."
													/>
												</>
											)}
										</div>
									</>
								)}
							</fieldset>
							<div className="k-form-buttons">
								<button
									type={'submit'}
									className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
									disabled={!formRenderProps.allowSubmit}>
									Submit
								</button>
							</div>
						</FormElement>
					)}
				/>
			)}
		</>
	);
}
