import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, CardBody } from '@progress/kendo-react-layout';
import { useSelector, useDispatch } from 'react-redux'
import { selectMessageSelected, selectMessageParametersConfig, selectCampaignDetails } from '../../../../../../redux/whatsAppConnect'
import { setMessageParametersConfig, setCampaignDetails } from '../../../../../../redux/whatsAppConnect'

const StepThird = ({ messageFilter, prevStep, nextStep }) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);
    }, [])

    return (
        loading ? <>Cargando</> : <>
            <Card>
                <CardHeader>
                    <CardTitle>
                        <b>Paso 3 - Confirmacion</b>
                        <button className='WhatsAppConnect-cardHeaderAction WhatsAppConnect-success' onClick={() => {
                            nextStep();
                        }}>Aceptar</button>
                    </CardTitle>
                </CardHeader>
                <CardBody className=''>
                    <b>Gracias! Tu campaña sera revisada y pronto podras cargar tus bases.</b>
                </CardBody>
            </Card >
        </>
    )
}

export { StepThird }