

function processDataForChart(siignalForecast, siignalExecution, siignalStaff, siignalSkills) {
    let siigoCells = {}
    let siigoCountries = {}
    let siignalForecastTemp = []
    let initialSeries = []
    let chartSeries = []
    let chartCategories = []
    let skillsCreated = {}
    let selectorOptions = []

    // initialSeries.map(el => {
    //     chartSeries.push({ name: el, type: 'line', data: Array.apply(null, Array(25)).map(a => null) })
    //     skillsCreated[el] = chartSeries.length;
    // })

    siignalForecastTemp = siignalForecast.map(el => {
        let selectOption = `${el.Pais}#${el.Skill}`

        //check if the option is already in the selector
        if (selectorOptions.indexOf(selectOption) == -1) {
            selectorOptions.push(selectOption)
        }

        let elDate = new Date(`${el.Fecha} ${el.Intervalo}`).toISOString()
        // let elDate = new Date(`2023-02-06 ${el.Intervalo}`).toISOString()

        //Adds intervals
        if (chartCategories.indexOf(elDate) == -1) {
            chartCategories.push(elDate)
        }

        el.date = elDate;

        return el;
    });

    chartCategories.sort((dateA, dateB) => {
        dateA = new Date(dateA)
        dateB = new Date(dateB)
        return dateA - dateB
    });

    siignalForecastTemp.map(el => {
        if (siigoCountries[el.Pais] == undefined) {
            siigoCountries[el.Pais] = 1
        }
        if (skillsCreated[el.Pais + "Volumen" + el.Skill + "_forecast"] == undefined) {
            let type = ["Staff", "Volumen"]
            let typeData = ["_forecast", "_real"]

            type.map(eltype => {
                typeData.map(eltypeData => {
                    chartSeries.push({ name: el.Pais + eltype + el.Skill + eltypeData, type: 'line', data: Array.apply(0, Array(25)).map(a => 0) })
                    skillsCreated[el.Pais + eltype + el.Skill + eltypeData] = chartSeries.length;
                })
            })
        }
        siigoCells[el.Skill] = {}
        chartSeries[skillsCreated[el.Pais + "Staff" + el.Skill + "_forecast"] - 1].data[chartCategories.indexOf(el.date)] = el['Agentes programados']
        chartSeries[skillsCreated[el.Pais + "Volumen" + el.Skill + "_forecast"] - 1].data[chartCategories.indexOf(el.date)] = el['Total de llamadas']
    })


    //Process Real Execution

    let skillsNotInForecast = {}
    let testSuma = {}
    siignalExecution.map(el => {
        if (el.Pais != "Other") {
            let serieName = `${el.Pais}Volumen${el.Celula}_real`

            try {

                let category = (new Date((new Date()).toISOString().split("T")[0] + " " + el.HALF_HOUR))
                // let category = (new Date("2023-02-06 " + el.HALF_HOUR))

                category = category.toISOString()




                let currentValue = chartSeries[skillsCreated[serieName] - 1].data[chartCategories.indexOf(category)];
                let volumenReal = parseInt(el.CALL_ID_distinct_count)
                currentValue = (currentValue == null) ? 0 : parseInt(currentValue)
                let newValue = (currentValue == null) ? volumenReal : volumenReal + currentValue;
                chartSeries[skillsCreated[serieName] - 1].data[chartCategories.indexOf(category)] = newValue



            } catch (e) {
                if (skillsNotInForecast[serieName] == undefined) {
                    skillsNotInForecast[serieName] = 1
                } else {
                    skillsNotInForecast[serieName]++
                }
            }
        }
    })


    //REAL STAFF
    let intervalosStaff = {}
    let testDataCounter = {}
    siignalStaff.map(el => {
        let agentsSkills = siignalSkills.find(skill => skill.AGENT_EMAIL == el.AGENT_EMAIL);

        if (agentsSkills != undefined) {
            //"ColombiaVolumenWhatsApp Operativo_real"
            if (agentsSkills.Celula.other == undefined) {


                el.Celula = Object.keys(agentsSkills.Celula)[0]
                el.Pais = Object.keys(agentsSkills.Pais)[0]



                intervalosStaff[el._id.substring(0, 5)] = true;

                let category = (new Date((new Date()).toISOString().split("T")[0] + " " + (el._id.substring(0, 5))))
                category = category.toISOString()

                let serieName = el.Pais + "Staff" + el.Celula + "_real"

                if (skillsCreated[serieName] == undefined) {
                    chartSeries.push({ name: serieName, type: 'line', data: Array.apply(0, Array(25)).map(a => 0) })
                    skillsCreated[serieName] = chartSeries.length;
                }
                let staffThisRow = (el.AGENT_STATE_TIME > 1800) ? 1 : el.AGENT_STATE_TIME / 1800;
                chartSeries[skillsCreated[serieName] - 1].data[chartCategories.indexOf(category)] += parseFloatNumber(staffThisRow);
            }
        } else {
        }
    })
    let returnData = {
        series: chartSeries,
        categories: chartCategories,
        activeSeries: [chartSeries[2], chartSeries[3]],
        siigoCells: Object.keys(siigoCells),
        skillsCreated: skillsCreated,
        countries: Object.keys(siigoCountries),
        selectorOptions: selectorOptions,
    }
    return returnData
}

function getDateKey() {
    let date = new Date()
    // let date = new Date("2023-02-06")
    date.setHours(date.getHours() - 5)
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add 1 to the month since it is 0-indexed
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}${month}${day}`;
}

function dataForTable(siignalForecast, siignalExecution, chartData) {
    let tableData = {}

    chartData.categories.map(el => {
        let date = new Date(el)
        date.setHours(date.getHours() - 5)

    })

    //Add forecast
    siignalForecast.map(el => {

        el.Intervalo = (el.Intervalo.length < 5) ? '0' + el.Intervalo : el.Intervalo

        //Check if cell/interval is created
        if (tableData[el.Intervalo] == undefined) {
            tableData[el.Intervalo] = {}
        }
        //Check if country is created
        if (tableData[el.Intervalo][el.Pais] == undefined) {
            tableData[el.Intervalo][el.Pais] = {}
        }

        //Check if skill is created
        if (tableData[el.Intervalo][el.Pais][el.Skill] == undefined) {
            tableData[el.Intervalo][el.Pais][el.Skill] = {
                TMO: 0,
                ACW: 0,
                SL: 0,
                SLA: 0,
                Abandoned: 0,
                "% Abandoned": 0,
                VolumenReal: 0,
                VolumenForecast: 0,
                StaffReal: 0,
                StaffForecast: 0,
                totalHandleTime: 0,
                totalAcwTime: 0,
                "% Forecast": 0,
                "% Staffing": 0
            }
        }
        try {
            tableData[el.Intervalo][el.Pais][el.Skill].StaffForecast += parseInt(el['Agentes programados']);
            tableData[el.Intervalo][el.Pais][el.Skill].VolumenForecast += parseInt(el['Total de llamadas']);
        } catch (error) {

        }
    })

    //Add Real
    let unmatched = {}
    let wrongMathces = {};
    siignalExecution.map(el => {

        if (el.Celula != "Other") {
            try {
                tableData[el.HALF_HOUR][el.Pais][el.Celula].totalHandleTime += parseInt(el.HANDLE_TIME);
                tableData[el.HALF_HOUR][el.Pais][el.Celula].totalAcwTime += parseInt(el.AFTER_CALL_WORK_TIME);
                tableData[el.HALF_HOUR][el.Pais][el.Celula].VolumenReal += parseInt(el.CALL_ID_distinct_count);
                tableData[el.HALF_HOUR][el.Pais][el.Celula].SL += parseInt(el.SERVICE_LEVEL_count);
                tableData[el.HALF_HOUR][el.Pais][el.Celula].Abandoned += parseInt(el.ABANDONED_count);
            } catch (error) {
                let celula = `${el.Pais} - ${el.Celula}`
                if (wrongMathces[celula] == undefined) {
                    wrongMathces[celula] = { count: 1, data: el }
                } else {
                    wrongMathces[celula].count += 1;
                }
            }
        } else {
            let skill = el.SKILL

            // check if skill is in unmatched
            if (unmatched[skill] == undefined) {
                unmatched[skill] = { count: 1, data: el }
            } else {
                unmatched[skill].count += 1
            }
        }
    })
    //Add Staf Real

    //TODO: In case that an agent has 2 cell coutn to both
    Object.keys(tableData).map(el => {
        if (el != "cardsData") {
            let category = (new Date((new Date()).toISOString().split("T")[0] + " " + (el)))
            // let category = (new Date((new Date("2023-02-06")).toISOString().split("T")[0] + " " + (el)))
            category = category.toISOString()

            let countryData = tableData[el];

            Object.keys(countryData).map(country => {
                let skillData = countryData[country]

                Object.keys(skillData).map(skill => {

                    let cellData = skillData[skill]
                    let value = chartData.series[chartData.skillsCreated[country + "Staff" + skill + "_real"] - 1].data[chartData.categories.indexOf(category)]
                    value = (value == null) ? 0 : value;
                    tableData[el][country][skill].StaffReal = parseFloatNumber(value).toFixed(2);
                })
            })
        }
    })


    //Calculate TMO, ACW, SL and abandoned

    Object.keys(tableData).map(el => {
        let intervalData = tableData[el]

        Object.keys(intervalData).map(country => {
            let countryData = intervalData[country]

            Object.keys(countryData).map(skill => {
                let cellData = countryData[skill]

                let totalCalls = cellData.VolumenReal
                let totalHT = cellData.totalHandleTime
                let totalACW = cellData.totalAcwTime
                let totalSL = cellData.SL
                let totalAbandoned = cellData.Abandoned

                tableData[el][country][skill].TMO = (totalCalls == 0) ? 0 : secondsToTime(parseFloatNumber(totalHT) / (parseFloatNumber(totalCalls) - totalAbandoned))
                tableData[el][country][skill].ACW = (totalCalls == 0) ? 0 : secondsToTime(parseFloatNumber(totalACW) / (parseFloatNumber(totalCalls) - totalAbandoned))
                tableData[el][country][skill].SLA = (totalCalls == 0) ? 0 : parseFloatNumber((totalSL / totalCalls) * 100).toFixed(2) + "%"
                tableData[el][country][skill]["% Abandoned"] = (totalCalls == 0) ? 0 : parseFloatNumber((totalAbandoned / totalCalls) * 100).toFixed(2) + "%"

                tableData[el][country][skill]["% Forecast"] = parseFloatNumber((totalCalls / cellData.VolumenForecast) * 100).toFixed(2) + "%"
                tableData[el][country][skill]["% Staffing"] = parseFloatNumber((cellData.StaffReal / cellData.StaffForecast) * 100).toFixed(2) + "%"
            })

        })
    })

    //Calculate data forcards and summary row
    let cardsData = {};
    let test = {}
    Object.keys(tableData).map(interval => {
        let intervalData = tableData[interval]
        if (interval != "cardsData") {
            Object.keys(tableData[interval]).map(country => {
                let countryData = intervalData[country]
                Object.keys(countryData).map(skill => {
                    let cellData = countryData[skill]
                    if (cardsData[country + "#" + skill] == undefined) {
                        cardsData[country + "#" + skill] = {
                            totalHandleTime: 0,
                            totalAcwTime: 0,
                            volumenForecast: 0,
                            volumenReal: 0,
                            staffForecast: 0,
                            staffReal: 0,
                            SL: 0,
                            Abandoned: 0,
                            VolForecast: 0,
                            VolReal: 0,
                            StaffForecast: 0,
                            StaffReal: 0,
                        }
                    }
                    let thisData = tableData[interval][country][skill]
                    if (isTimeBeforeNow(interval)) {
                        test[interval] = isTimeBeforeNow(interval)
                        cardsData[country + "#" + skill].totalHandleTime += parseFloatNumber(thisData.totalHandleTime);
                        cardsData[country + "#" + skill].totalAcwTime += parseFloatNumber(thisData.totalAcwTime);
                        cardsData[country + "#" + skill].volumenForecast += parseFloatNumber(thisData.VolumenForecast);
                        cardsData[country + "#" + skill].volumenReal += parseFloatNumber(thisData.VolumenReal);
                        cardsData[country + "#" + skill].staffForecast += parseFloatNumber(thisData.StaffForecast);
                        cardsData[country + "#" + skill].staffReal += parseFloatNumber(thisData.StaffReal);
                        cardsData[country + "#" + skill].SL += parseFloatNumber(thisData.SL);
                        cardsData[country + "#" + skill].Abandoned += parseFloatNumber(thisData.Abandoned);
                        cardsData[country + "#" + skill].VolForecast += parseFloatNumber(thisData.VolumenForecast);
                        cardsData[country + "#" + skill].VolReal += parseFloatNumber(thisData.VolumenReal);
                        cardsData[country + "#" + skill].StaffForecast += parseFloatNumber(thisData.StaffForecast);
                        cardsData[country + "#" + skill].StaffReal += parseFloatNumber(thisData.StaffReal);
                    }
                })
            })
        }
    })

    Object.keys(cardsData).map(cell => {
        cardsData[cell] = {
            TMO: secondsToTime(cardsData[cell].totalHandleTime / (cardsData[cell].volumenReal - cardsData[cell].Abandoned)),
            ACW: secondsToTime(cardsData[cell].totalAcwTime / (cardsData[cell].volumenReal - cardsData[cell].Abandoned)),
            SLA: parseFloatNumber((cardsData[cell].SL / cardsData[cell].volumenReal) * 100).toFixed(2) + "%",
            "% Abandoned": parseFloatNumber((cardsData[cell].Abandoned / cardsData[cell].volumenReal) * 100).toFixed(2) + "%",
            VolForecast: cardsData[cell].VolForecast.toFixed(2),
            VolReal: cardsData[cell].VolReal.toFixed(2),
            "% Forecast": parseFloatNumber((cardsData[cell].volumenReal / cardsData[cell].volumenForecast) * 100).toFixed(2) + "%",
            StaffForecast: cardsData[cell].StaffForecast.toFixed(2),
            StaffReal: cardsData[cell].StaffReal.toFixed(2),
            "% Staffing": parseFloatNumber((cardsData[cell].staffReal / cardsData[cell].staffForecast) * 100).toFixed(2) + "%",
        }
    })

    tableData["cardsData"] = cardsData
    tableData["Resumen"] = cardsData
    return tableData;
}

function secondsToTime(seconds) {
    seconds = parseIntNumber(seconds)
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
}

function parseFloatNumber(num) {
    return isNaN(num) ? 0 : parseFloat(num);
}

function parseIntNumber(num) {
    return isNaN(num) ? 0 : parseInt(num);
}

function isTimeBeforeNow(timeString) {
    const now = new Date();
    const [hours, minutes] = timeString.split(":").map(el => { return parseInt(el) });
    let currentHours = now.getHours();
    let currentMinutes = now.getMinutes();

    currentHours = (currentMinutes == 30) ? ++currentHours : currentHours
    currentMinutes = (currentMinutes == 30) ? 0 : 30;

    // Compare the time from the string to the current time
    if (hours < currentHours || (hours === currentHours && minutes < currentMinutes)) {
        return true;
    }
    return false;
}

export { processDataForChart, getDateKey, dataForTable, secondsToTime, parseFloatNumber, parseIntNumber, isTimeBeforeNow }