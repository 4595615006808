
const readingTextEditor=(tablaHTML)=>{
    // Convie el string en un elemento DOM
var parser = new DOMParser();
var doc = parser.parseFromString(tablaHTML, 'text/html');

// Busca todas las filas de la tabla
var trs = doc.querySelectorAll('table tbody tr');
var listas = [];

trs.forEach(function(tr) {
    var tds = tr.querySelectorAll('td p');
    var listaFila = [];
    tds.forEach(function(td) {
        listaFila.push(td.textContent);
    });
    listas.push(listaFila);
});

return listas


}

export default readingTextEditor;