import React, { useState, useEffect } from 'react';
import "./ProcessTreeRenewals.css"
import axios from 'axios';
import { writeData, readData, deleteData } from './services/db';
import { useSelector, useDispatch } from 'react-redux';
import { setShapesData, setConectionsData, setConfig } from '../../../../redux/renewalsTreeReducer';
import { selectDynamicInputsData, selectConfig } from '../../../../redux/renewalsTreeReducer';
import { useUserService } from '../services/userService';

import Admin from './Modules/Admin';
import Agent from './Modules/Agent';
import { AdminOptions, AgentPanel } from './Components';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import ProcessTreeConfig from './Components/ProcessTreeConfig';
import AgentPanelMX from './Components/AgentPanelMX';

function ProcessTreeRenewalsDashboard() {
    const [selectedCountryValue, setSelectedCounrtyValue] = useState(null)
    const [selected, setSelected] = useState(0)
    const renewalsConfig = useSelector(selectConfig)
    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    const { userProfile } = useUserService();
    const dispatch = useDispatch();
    const [showAgent, setShowAgent] = useState(true);
    const [lastUpdate, setLastUpdate] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [adminPanel, setAdminPanel] = useState(false);
    const [agentPanel, setAgentPanel] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)

    const [loading, setLoading] = useState({
        state: true,
        messageQueue: []
    });

    const updateLoading = (action, message) => {
        if (action == "reset") {
            setLoading({
                state: false,
                messageQueue: []
            });
        } else {
            setLoading((prevState) => ({
                ...prevState,
                state: true,
                messageQueue: [...prevState.messageQueue, message]
            }));
        }
    }

    useEffect(() => {
        if (userProfile != null && renewalsConfig != null) {
            let adminEmails = []
            Object.keys(renewalsConfig).map(country => {
                if (country != "_id") {
                    adminEmails = [...adminEmails, ...renewalsConfig[country].treeAdmin]
                }
            })
            if (adminEmails.includes(userEmail)) {
                setIsAdmin(true)
            }
            setSelectedCounrtyValue()
        }
        let selectedCountry = localStorage.getItem("selectedCountryName")
        setSelectedCounrtyValue(selectedCountry)
    }, [userProfile])

    useEffect(() => {
        setUserEmail(userProfile.email)
        updateLoading("add", "Validando información local");
        readData('SiignalRenewals', "renewalsNitInfo").then((localData) => {
            if (localData.code === 'NOT_FOUND') {
                updateLoading("add", "No existe información local, conectando a la base de datos");
                getDataFromDatabase();
            } else {
                axios.get("/api/siignalGetPaymentPromiseConfig").then(res => {
                    dispatch(setConfig(res.data))

                    updateLoading("add", "Existe información local, validando fecha de actualización");
                    axios.get('/api/siignalGetBaseRenovacionesLastUpdate').then((response) => {
                        const lastUpdateServer = new Date(response.data[0].lastUpdate);
                        const lastUpdateLocal = new Date(localData.data[0].lastUpdate);

                        if (lastUpdateServer.getTime() > lastUpdateLocal.getTime()) {
                            updateLoading("add", "La información local está desactualizada, eliminando información local");
                            deleteData('SiignalRenewals', "renewalsNitInfo").then(() => {
                                updateLoading("add", "Información local eliminada, conectando a la base de datos");
                                getDataFromDatabase();
                            }).catch(() => {
                                updateLoading("add", "Error eliminando información local, finalizando proceso");
                                updateLoading("add", "CONTACTA TU LIDER");
                            })
                        } else {
                            updateLoading("add", "La información local está actualizada, finalizando proceso");
                            getTreeConfig();
                        }
                    })
                })
            }
        }).catch((error) => {
            console.warn(error);
        })
    }, []);

    const getTreeConfig = () => {
        updateLoading("add", "Obteniendo configuración del árbol");
        axios.get(`/api/siignalGetProcessTreeRenewals`).then(res => {
            updateLoading("add", "Configuración obtenida, guardando en local");
            let requestData = res.data[0];
            const storedData = requestData.shapes;
            const storedLines = requestData.lines;
            if (storedData) {
                const shapes = storedData;
                dispatch(setShapesData(shapes));

                if (storedLines) {
                    dispatch(setConectionsData(storedLines));
                }
                updateLoading("reset")
            }
        }).catch(err => {
            updateLoading("add", "Error obteniendo configuración del árbol, Contacta tu lider");
            console.warn(err)
        })
    }

    const getDataFromDatabase = () => {
        axios.get('/api/siignalGetBaseRenovaciones').then((response) => {
            updateLoading("add", "Información obtenida, guardando en local");
            let renewalsTreeData = response.data;
            writeData('SiignalRenewals', renewalsTreeData, "renewalsNitInfo").then(() => {
                updateLoading("add", "Información guardada localmente, finalizando proceso");
                getTreeConfig();
            }).catch(() => {
                updateLoading("add", "Error guardando información localmente, finalizando proceso");
                updateLoading("add", "CONTACTA TU LIDER");
            })
            console.log(renewalsTreeData);
        })
    }

    return (
        (loading.state) ? <>
            <div className="loading-center">
                <div className="loading-container">
                    {
                        loading.messageQueue.map((message, index) => {
                            return <p key={index}>{`${message}`}</p>
                        })
                    }
                </div>
            </div>
        </> : <>
            <div className='tabContainer'>
                <TabStrip selected={selected} onSelect={handleSelect}>
                    <TabStripTab title="Tablero">
                        <Agent updateLoading={updateLoading} />
                    </TabStripTab>
                    {
                        (isAdmin) ? <TabStripTab title="Administrar Arbol">
                            <Admin />
                        </TabStripTab> : null
                    }
                    <TabStripTab title="Configuracion">
                        <ProcessTreeConfig renewalsConfig={renewalsConfig} />
                    </TabStripTab>
                    <TabStripTab title="Como Vamos">
                        <AgentPanel />
                    </TabStripTab>
                    <TabStripTab title="Como Vamos MX">
                        <AgentPanelMX />
                    </TabStripTab>
                </TabStrip>
            </div>
        </>
    );
}

export { ProcessTreeRenewalsDashboard };