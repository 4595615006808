import React, { useState } from 'react';
import './Tooltip.css';
import {formatDate} from '@telerik/kendo-intl';
const CustomTooltip = ({ data }) => {

  const [visible, setVisible] = useState(false);

  const calculateTime = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      console.error('Invalid date provided for calculation.');
      return "0 seconds";
    }
    const durationInSeconds = (endDate - startDate) / 1000;
    if (durationInSeconds > 60) {
      const minutes = Math.floor(durationInSeconds / 60);
      const seconds = Math.round(durationInSeconds % 60);
      return ` ${minutes}:${seconds.toString().padStart(2, '0')} minutes`;
    } else {
      return ` ${Math.round(durationInSeconds)} seconds`;
    }
  }
  const adjustTimeZone = (dateString) => {
		const date = new Date(dateString);
		// Restar 5 horas UTC
		date.setHours(date.getHours());
		return date;
	};

  const CustomCell = ({value}) => {

			value = adjustTimeZone(value);
		return <>{formatDate(value, 'HH:mm:ss')}</>;
	};

  return (
    <div 
      className="tooltip-container" 
      onMouseEnter={() => setVisible(true)} 
      onMouseLeave={() => setVisible(false)}
    >
      <div className={`ChunkBargrid-item ${data.status === 'Completed' ? 'CHGreen' : 'CHRed'}`}></div>
      {visible && (
        <div className="tooltip-box">
          <div className="tooltip-content">
            <div className="tooltip-header">{data.status} Update</div>
            <div><strong>refresh ID:</strong> {data.id}</div>
            <div><strong>Start Time:</strong> <CustomCell value={data.startTime}/></div>
            <div><strong>End Time:</strong><CustomCell value={data.endTime}/></div>
            <div><strong>Extended Status:</strong> {data.extendedStatus}</div>
            <div><strong>Execution Time:</strong>{calculateTime(data.startTime,data.endTime)}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
