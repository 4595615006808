import React from 'react'
import ProfileContent from '../components/ProfileContent'
import '../components/status-card/statuscard.css'
import * as FaIcons from 'react-icons/fa'
import * as BiIcons from 'react-icons/bi'
import * as MdIcons from 'react-icons/md'
import { Link } from 'react-router-dom'
import {CircleFlag} from 'react-circle-flags'
import { IoCalendarOutline } from 'react-icons/io5'

const DynamicFaIcon = ({ name }) => {
    const IconComponent = FaIcons[name];
  
    if (!IconComponent) { // Return a default one
      return <FaIcons.FaBeer />;
    }
  
    return <IconComponent />;
  };



export const DataGovernance=() => {

  return (
    <div>
      <h2 className='page-header'>Data Governance</h2>
      <h3 className='page-subheader'>Documentación</h3>
      <section className='status-cards-container'>
        <div className="col-6-SDR">
            <div className='status-card'>
              <div className="status-card__icon">
                  <BiIcons.BiNetworkChart/>
              </div>
              <div className="status-card__info">
                <Link to='/DataGovernance/DataModel'>Modelo de Datos</Link>
              </div>
            </div>
        </div>
        <div className='col-6-SDR'>
              <div className='status-card'>
                <div className="status-card__icon">
                    <MdIcons.MdOutlinePlaylistPlay/>
                </div>
                <div className="status-card__info">
                  <Link to='/DataGovernance/Projects'>Proyectos</Link>
                </div>
              </div>
        </div>
        <div className='col-6-SDR'>
              <div className='status-card'>
                <div className="status-card__icon">
                    <FaIcons.FaRegFileCode/>
                </div>
                <div className="status-card__info">
                  <Link to='/DataGovernance/QueryList'>Lista de Queries</Link>
                </div>
              </div>
        </div>
          <div className="col-6-SDR">
              <div className='status-card'>
                <div className="status-card__icon">
                    <FaIcons.FaRegHandshake/>
                </div>
                <div className="status-card__info">
                  <Link to='/DataGovernance/SLAs'>Acuerdos de Linea de Servicio</Link>
                </div>
              </div>
          </div>
      </section>
      <h3 className='page-subheader'>Lógica de Negocio</h3>
      <section className='status-cards-container'>
        <div className="col-6-SDR">
            <div className='status-card'>
              <div className="status-card__icon">
                  <MdIcons.MdOutlineMenuBook/>
              </div>
              <div className="status-card__info">
                <Link to='/DataGovernance/Definitions'>Definiciones</Link>
              </div>
            </div>
        </div>
        <div className='col-6-SDR'>
              <div className='status-card'>
                <div className="status-card__icon">
                    <MdIcons.MdManageSearch/>
                </div>
                <div className="status-card__info">
                  <Link to='/DataGovernance/Dictorionary'>Diccionario de Datos</Link>
                </div>
              </div>
        </div>
      </section>
      <h3 className='page-subheader'>Gobierno de Datos</h3>
      <section className='status-cards-container'>
        <div className="col-6-SDR">
            <div className='status-card'>
              <div className="status-card__icon">
                  <FaIcons.FaRegAddressCard/>
              </div>
              <div className="status-card__info">
                <Link to='/DataGovernance/OrgChart'>Equipo</Link>
              </div>
            </div>
        </div>
        <div className='col-6-SDR'>
              <div className='status-card'>
                <div className="status-card__icon">
                    <MdIcons.MdAltRoute/>
                </div>
                <div className="status-card__info">
                  <Link to='/DataGovernance/Processes'>Procesos</Link>
                </div>
              </div>
        </div>
        <div className="col-6-SDR">
            <div className='status-card'>
              <div className="status-card__icon">
                <IoCalendarOutline />
              </div>
              <div className="status-card__info">
                <Link to='/Reports/ReportsData/DayOperation'>Mes de Operación</Link>
              </div>
            </div>
        </div>
      </section>
    </div>
      )
  }
