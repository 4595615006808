import React from 'react'
import { Link } from 'react-router-dom'
import * as FaIcons from 'react-icons/fa'


export const Tools = () => {
  return (
    <div>
      <h2 className='page-header'>Tools</h2>
      {/* <p>Este Modulo está en construcción</p> */}
      <section className='status-cards-container'>
        <div className="col-6-SDR">
          <div className='status-card'>
            <div className="status-card__icon">
              <FaIcons.FaHeadset />
            </div>
            <div className="status-card__info">
              <Link to='/Tools/InvoicesCertification'>Certificacion de facturas</Link>
            </div>
          </div>
        </div>
        <div className="col-6-SDR">
          <div className='status-card'>
            <div className="status-card__icon">
              <FaIcons.FaExchangeAlt />
            </div>
            <div className="status-card__info">
              <Link to='/Reports/ReportsCustomer/TranferencesHelper'>Asistente De Transferencias Colombia</Link>
            </div>
          </div>
        </div>
        <div className="col-6-SDR">
          <div className='status-card'>
            <div className="status-card__icon">
              <FaIcons.FaSitemap />
            </div>
            <div className="status-card__info">
              <Link to='/Reports/ReportsCustomer/ProcessTreeRenewals'>Arbol de Procesos Renovaciones</Link>
            </div>
          </div>
        </div>
        <div className="col-6-SDR">
          <div className='status-card'>
            <div className="status-card__icon">
              <FaIcons.FaCalendar />
            </div>
            <div className="status-card__info">
              <Link to='/Tools/ShiftNovelties'>Novedades de Turno</Link>
            </div>
          </div>
        </div>
        <div className="col-6-SDR">
          <div className='status-card'>
            <div className="status-card__icon">
              <FaIcons.FaDesktop/>
            </div>
            <div className="status-card__info">
              <Link to='/Tools/UniqueDesk'>Unique Desk</Link>
            </div>
          </div>
        </div>
        <div className="col-6-SDR">
          <div className='status-card'>
            <div className="status-card__icon">
              <FaIcons.FaExchangeAlt />
            </div>
            <div className="status-card__info">
              <Link to='/Reports/ReportsCustomer/TranferencesHelperMX'>Asistente De Transferencias Mexico</Link>
            </div>
          </div>
        </div>
        <div className="col-6-SDR">
          <div className='status-card'>
            <div className="status-card__icon">
              <FaIcons.FaExchangeAlt />
            </div>
            <div className="status-card__info">
              <Link to='/Reports/ReportsCustomer/TranferencesHelperECU'>Asistente De Transferencias Ecuador</Link>
            </div>
          </div>
        </div>
        <div className="col-6-SDR">
          <div className='status-card'>
            <div className="status-card__icon">
              <FaIcons.FaExchangeAlt />
            </div>
            <div className="status-card__info">
              <Link to='/Reports/ReportsCustomer/TranferencesHelperURU'>Asistente De Transferencias Uruguay</Link>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="col-6">
          <div className="row">
            <div className='status-card'>
              <div className="status-card__icon">
                  <FaIcons.FaHeadset/>
              </div>
              <div className="status-card__info">
                <Link to='/Tools/DataMart'>Data Mart</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="row">
              <div className='status-card'>
                <div className="status-card__icon">
                    <FaIcons.FaMoneyBillWave/>
                </div>
                <div className="status-card__info">
                  <Link to='/Tools/Segmentacion'>Segmentacion Siigo</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className='row'>
              <div className='status-card'>
                <div className="status-card__icon">
                    <FaIcons.FaPeopleArrows/>
                </div>
                <div className="status-card__info">
                  <Link to='/Tools/Wayfinder'>Wayfinder</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className='row'>
              <div className='status-card'>
                <div className="status-card__icon">
                    <FaIcons.FaPeopleArrows/>
                </div>
                <div className="status-card__info">
                  <Link to='/Tools/ProductStatus'>Status de los Productos</Link>
                </div>
              </div>
            </div>
          </div>
    */}
    </div>
  )
}