import React, { useState, useEffect } from 'react';
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import {
    GridLayout, GridLayoutItem,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardActions,
    CardSubtitle,
    Avatar
} from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { useSelector, useDispatch } from 'react-redux'
import { useUserService } from './../../../services/userService'
import { setMessageFilter, setMessageSelected, setCampaingMetaData } from './../../../../../../redux/whatsAppConnect'
import { selectMessageFilter, selectCampaingMetaData } from './../../../../../../redux/whatsAppConnect'

const StepOne = ({ messageTemplates, nextStep, prevStep }) => {
    const dispatch = useDispatch();
    const { userProfile } = useUserService();
    const messageFilter = useSelector(selectMessageFilter)

    const applyFilters = (messages) => {
        if (messageFilter) {
            const lowercasedFilter = messageFilter.toLowerCase();
            return messages.filter((message) => {
                const concatenatedFields = (message["Template Name"] + message.Content).toLowerCase();
                return concatenatedFields.includes(lowercasedFilter);
            });
        } else {
            return messages;
        }
    }

    const setSelectedMessageForCampaign = (selectedMessage) => {
        let date = new Date();
        date = date.toString()
        date = date.replace("GMT-0500", "GMT-0000")
        let f_date = new Date(date)
        dispatch(setMessageSelected(selectedMessage))
        dispatch(setCampaingMetaData({
            status: "Pendiente",
            priority: null,
            creationDate: f_date.toISOString().slice(0, 19).replace("T", " "),
            reviewDate: null,
            email: userProfile.email,
            url : selectedMessage['URL IMAGEN']
        }))
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle>
                    <b>Paso 1 - Selecciona el mensaje</b>
                    <input placeholder='Buscar plantilla' value={messageFilter} className='WhatsAppConnect-cardHeaderAction' onChange={(ev) => {
                        dispatch(setMessageFilter(ev.target.value))
                    }} />
                </CardTitle>
            </CardHeader>
            <CardBody className='WhatsAppConnect-messagesList'>
                {
                    applyFilters(messageTemplates).map((template, index) => {
                        return (
                            <Card key={index}>
                                <CardHeader>
                                    <CardTitle>
                                        {template["Template Name"]}
                                        <br />
                                        <span className='WhatsAppConnect-cardDetails'>{template["División"]} - {template["Solicitado por:"]}</span>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <pre>
                                        {/* {JSON.stringify(template, null, 4)} */}
                                        <textarea className='WhatsAppConnect-textarea' value={template.Content} disabled onChange={() => { }} />
                                    </pre>
                                </CardBody>
                                <CardActions>
                                    <Button className='WhatsAppConnect-cardButton' onClick={() => {
                                        setSelectedMessageForCampaign(template)
                                        nextStep()
                                    }}>
                                        Selecionar
                                    </Button>
                                </CardActions>
                            </Card>
                        )
                    })
                }
            </CardBody>
        </Card>
    )
}

export { StepOne }