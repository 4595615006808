import { useState, useEffect } from 'react';
import axios from 'axios';

const useAxiosTicket = (url,datatoSend) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    axios.post(url,datatoSend,{
        headers: {
           'Content-Type': 'application/json; charset=UTF-8',
           'Access-Control-Allow-Origin': '*'
    }})
      .then(response => {
        setData(response.data);
        setError(null);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [url,datatoSend]);

  return { data, isLoading, error };
};

export default useAxiosTicket;
