import React from 'react'
import { GridKPI } from './GridKpi'
import { useSelector } from 'react-redux';
import { selectCredencialesUsr } from '../../../../../redux/credencialesReducer';
import { selectData } from '../../../../../redux/viewBISlice';

export const KPIs = () => {
    const mainData = useSelector(selectData);
    const { reportConfig, reportOwner, schedule } = mainData || {};
    const belonging = reportConfig?.reportName || '';
    let email=useSelector(selectCredencialesUsr);
    let params = {    "gridSchemas" : "KPIsReports",
		"dateCreatedAt" : "2024-02-01T00:00:00.000+0000",
		"userCreated" : "Huguito Fabian Manuel Carreño",
		"columns" : [
			{
				"field" : "KEY",
				"title" : "ID",
				"editable" : false,
				"width" : "80px",
				"editor" : "text"
			},
			{
				"field" : "Indicator",
				"title" : "Indicator",
				"width" : "150px",
				"editor" : "text",
				"columnMenu" : "ColumnMenu"
			},
			{
				"field" : "Description",
				"title" : "Description",
				"width" : "220px",
				"editor" : "text",
				"columnMenu" : "ColumnMenu"
			},
			{
				"field" : "Formula",
				"title" : "Formula",
				"width" : "150px",
				"editor" : "text",
				"columnMenu" : "ColumnMenu"
			}
		],
		"disabledDate" : null,
		"disabledDeleteButton" : false,
		"requiredFields" : [
			{
				"field" : "Indicator",
				"textMessage" : "Indicator es requerido",
				"minLength" : 2
			}
		]
	}
  return (
    <GridKPI
								columns={params?.columns || []}
								belonging={belonging}
								params={params}
								IsThereSearchBar={(params?.searchFields?.length || 0) > 0}
                                email={email}
							/>
  )
}
