const ResumeTableMx = ({ data }) => {
    const tableHeaders = [
        "Total",
        "Recaudado",
        "Vencido",
        "Promesas",
    ]

    if (data != undefined) {
        return (<table className="renewals-tableResume">
            <thead>
                <tr>
                    <th className="renewalsAgentPanel-th" rowSpan={2}>Fecha</th>
                    <th className="renewalsAgentPanel-th" colSpan={3}>
                        Valor Promesas
                    </th>
                    <th className="renewalsAgentPanel-th">
                        Conteo
                    </th>
                </tr>
                <tr>
                    {tableHeaders.map(name => (
                        <th className="renewalsAgentPanel-th" key={name}>{name}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {
                    data.map((el, index) => {
                        return (
                            <tr key={index}>
                                <td className="renewalsAgentPanel-td">{el.Fecha}</td>
                                <td className="renewalsAgentPanel-td">$ {(parseFloat(el.Total)).toFixed(2)}</td>
                                <td className="renewalsAgentPanel-td">$ {(parseFloat(el.Recaudado)).toFixed(2)}</td>
                                <td className="renewalsAgentPanel-td">$ {(parseFloat(el.Vencido)).toFixed(2)}</td>
                                <td className="renewalsAgentPanel-td">{el.Promesas}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
        )
    } else {
        return "-----";
    }
}

export { ResumeTableMx }