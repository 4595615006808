import * as React from 'react';
import { TileLayout } from '@progress/kendo-react-layout';


import { TnbGruposTable } from '../../components/TNB/TrainingTable/table';
import { TnbFormularioCapacitacion } from '../../components/TNB/TrainingForm/tnbFormularioCapacitacion';



export const LearningDevelopmentManagement=(props) => {
  let tiles2
    console.log(props.roleValue)
     tiles2 = [
      {
        defaultPosition: {
          col: 1,
          colSpan: 4,
          rowSpan: 4,
        },
        header: "Crea una capacitación",
        body: <TnbFormularioCapacitacion/>,
        resizable: false,
        reorderable: false
      },
      {
        defaultPosition: {
          col: 5,
          colSpan: 8,
          rowSpan: 4,
        },
        header: "Detalle Grupos",
        body: <TnbGruposTable/>,
        resizable: false,
        reorderable: false
      },
    ];


    return (
      <>
            <div className="example-wrapper">
            <TileLayout
                    columns={12}
                    rowHeight={200}
                    gap={{
                        rows: 10,
                        columns: 10,
                    }}
                    items={tiles2}
                />
            </div>

      </>
        )
    }
