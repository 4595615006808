import "./MenuNavigation.css";
import { React, Component } from 'react'
import { Grid } from '@mui/material';


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

class MenuNavigation extends Component {
    selected = 0;
    handleChange = (selectedValue) => {
        this.props.optionSelect(selectedValue)
    }
    render() {
        return (
            <Grid item xs>
                <br />
                <FormControl xs={12} className="select">
                    <InputLabel id={"labelFor" + this.props.name}>{this.props.name}</InputLabel>
                    <Select
                        labelId={"labelFor" + this.props.name}
                        id={"labelFor" + this.props.name}
                        value={this.selected}
                        label={this.props.name}
                        onChange={(event, selectedIndex) => {
                            this.selected = event.target.value
                            let selectedValue = this.props.options[event.target.value]
                            this.handleChange(selectedValue);
                        }}
                    >
                        {
                            this.props.options.map((option, index) => {
                                return <MenuItem key={this.props.name + option} value={index}>{option.replace("#", " - ")}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
        )
    }
}

export { MenuNavigation };