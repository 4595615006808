import React from 'react'
import '../../components/status-card/statuscard.css'
import Tabs from "../../components/multitab/Tabs";
import Panel from "../../components/multitab/Panel";
import { CustomerValoracionesCall } from '../../components/pbi/Customer/Customer_Valoraciones_Call';
import { CustomerValoracionesChat } from '../../components/pbi/Customer/Customer_Valoraciones_Chat';
import { CustomerValoracionesMexico } from '../../components/pbi/Customer/Customer_Valoraciones_Mexico';
import { CustomerValoracionesTCN } from '../../components/pbi/Customer/Customer_Valoraciones_TCN';
import { CustomerValoracionesUCI } from '../../components/pbi/Customer/Customer_Valoraciones_UCI';
import { CustomerValoracionesSoporteEcuador } from '../../components/pbi/Customer/Customer_Valoraciones_SoporteEcuador';
import { CustomerValoracionesUruguay } from '../../components/pbi/Customer/Customer_Valoraciones_Uruguay';
import { CustomerValoracionesExpertos } from '../../components/pbi/Customer/Customer_Valoraciones_Expertos';
import { CustomerValoracionesNewRandom } from '../../components/pbi/Customer/Customer_Valoraciones_NewRandom';
import { CustomerValoracionesHistoricos } from '../../components/pbi/Customer/Customer_Valoraciones_Historicos';
import { CustomerSupportNubeLatam } from '../../components/pbi/Customer/Customer_SupportNubeLATAM';
import { CustomerValoracionesExcelencia } from '../../components/pbi/Customer/Customer_Valoraciones_Excelencia';



export const CustomerReportsValoracionesTopPerformance = () => {

    let renderView
    if (sessionStorage.getItem('role') === 'portalAdmin' || sessionStorage.getItem('role') === 'cLevel' || sessionStorage.getItem('role') === 'productOwner') {
        renderView = <Tabs>
            <Panel title="Valoraciones">
                <CustomerSupportNubeLatam />
            </Panel>
            <Panel title="">
                
            </Panel>
        </Tabs>
    } else if (sessionStorage.getItem('area') === 'Sales' || sessionStorage.getItem('area') === 'Strategy' || sessionStorage.getItem('area') === 'Marketing' || sessionStorage.getItem('area') === 'Sistemas Mexico' || sessionStorage.getItem('area') === 'Soporte Tecnico' || sessionStorage.getItem('area') === 'Customer Success' || sessionStorage.getItem('area') === 'Finance & Administration') {
        renderView = <Tabs>
            <Panel title="Valoraciones">
                <CustomerSupportNubeLatam />
            </Panel>   
            <Panel title="">
                
            </Panel>         
        </Tabs>
    } else {
        renderView = <div className='desktop-view-SDR'>
            <div id='reportContainerSDR'>
                <div className='centerDS'>No eres un usuario registrado, lo sentimos</div>
            </div>
        </div>
    }


    return (
        <>
            <><h1 className='page-header-reports'>Reportes Valoraciones y Top Performance</h1><div>{renderView}
            </div></>
        </>
    )
}


