import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/css/grid.css'
import './assets/css/index.css'
import App from './App';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { config } from "./Config";
import { Provider } from 'react-redux';
import store from './redux/store';


const msalInstance= new PublicClientApplication(config)

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <App />
    </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);