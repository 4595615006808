export const exportNestedObject= async (diagram,rute)=>{

    //!--- el trae el diagrama entero de como estan distribuidos los botones, de todos los reportes
    //!--- rute es la ruta actual donde vamos para que? para mostrar a partir de ese nivel extraer todas las claves desde ese nivel
    //por ejemplo si la ruta fuera reportes generales/Customer/Inbound/llamadas       (****es ejemplo*****)
    // lo que hace nestedObject es practicamente hacer esto diagram[reportes generales][Customer][Inbound][llamadas] dara todo el diagrama pero desde ese nivel


    let nestedObject =rute?.reduce(                       
    (obj, key) => (obj && obj[key] ? obj[key] : null),diagram);
    //nestedObject es el arbol desde la ruta que se le pide buscar
    //puedes salir nulo si la ruta llega vacia
    
    if (nestedObject) {
        return inLabel(nestedObject);//inLabel es cuando crea a partir de un nivel de profundidad diccionario de JavaScript clave valor, 
      } else {//como clave son todos los titulos del componente de reportes y el valor son los hijos y/o nombres de los botones
        return inLabelZeroLevel(diagram);//Zero es cuando hace el diagrama, saca las llaves(claves) y extrae todos los hijos(valores)  desde el nivel cero pero solo un nivel
    }

}

const inLabel = async (nestedObject) => { //esta funcion solo saca las llaves(claves) y extrae todos los hijos(valores)
  const data = Object.keys(nestedObject).reduce((acc, key) => {
    if (nestedObject[key] && typeof nestedObject[key] === "object") {
      if (nestedObject[key]["*"]) {
        return true; //este true sirve pcomo bandera de que encontro en los hijo o uno de los hijos que es un"*" lo que significa es que es el ultimo nivel de la ruta y debera desplegar los dashboards 
      }
      acc[key] = Object.keys(nestedObject[key]);
    }
    return acc;
  }, {});

  if (typeof data === "boolean" && data === true) {
    //console.log("True encontrado");
    return true;
  }

  //console.log(JSON.stringify(data, null, 2));
  //console.log("////////////////data//////",data)
  return data;
};


  const inLabelZeroLevel = async (diagram) => {
    const data = Object.keys(diagram).reduce((acc, key) => {
      if (diagram[key] && typeof diagram[key] === "object") {
        if (diagram[key]["*"]) {
          //console.log(`El padre de "*" es ${key}`);
        }
        acc[key] = Object.keys(diagram[key]);
      }
      return acc;
    }, {});
    //console.log(JSON.stringify(data, null, 2));
    //console.log("//////////////////////",data)
    return(data);
  };


  // const inLabel = async (nestedObject) => {
//     const data = Object.keys(nestedObject).reduce((acc, key) => {
//       if (nestedObject[key] && typeof nestedObject[key] === "object") {
//         if (nestedObject[key]["*"]) {


//         }
//         acc[key] = Object.keys(nestedObject[key]);
//       }
//       return acc;
//     }, {});
//     console.log(JSON.stringify(data, null, 2));
//     console.log("//////////////////////",data)
//     return(data);
// };