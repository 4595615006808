import { useSelector } from "react-redux"
import { selectAccessPermissions } from "../../redux/AccessPermissions";

export function IsAuthorized(nModule,area, requiredRole) {
    const permissions=useSelector(selectAccessPermissions);
    const accessKey = `${area}.${requiredRole}`;
    if (permissions[nModule]) {
        return permissions[nModule][accessKey]||false;
    }
    return false;

}