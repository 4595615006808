import React, {useState, useEffect} from 'react'
import ProfileContent from '../components/ProfileContent.js'
import {Login, Person, PersonCard, Agenda} from '@microsoft/mgt-react'
import { Providers, ProviderState } from '@microsoft/mgt-element';
import {MgtPersonCard} from '@microsoft/mgt'
import { useMsal } from '@azure/msal-react';
import {ListUsers} from '../components/usersManagement/listusers'




export const Users=()=> {

  const { instance, accounts } = useMsal();
  const name = accounts[0] && accounts[0].name;

let renderView
if (sessionStorage.getItem('role') === 'portalAdmin'){
  renderView=<ListUsers/>
} else  {
  renderView = <div className='desktop-view-SDR'>
      <div id='reportContainerSDR'>
          <div className='centerDS'>No eres un usuario registrado, lo sentimos</div>
      </div>
  </div>
}


  return (
        <>
          {renderView}
        </>
        

  )
}
