import React from 'react';

const ErrorMessage = ({ message }) => {
    return (
        <div style={{ color: 'red', padding: '10px', border: '1px solid red', borderRadius: '5px' }}>
            <h2>Error</h2>
            <p>{message}</p>
        </div>
    );
};

export default ErrorMessage;
