import React from 'react'
import {Card, ListGroup, ListGroupItem, Tab, Tabs} from 'react-bootstrap';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { xt256 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Link } from 'react-router-dom';



const CODE=`IF OBJECT_ID(N'stg.ContratosRenovaciones') IS NOT NULL BEGIN DROP TABLE stg.ContratosRenovaciones END 

CREATE TABLE stg.ContratosRenovaciones with(DISTRIBUTION = ROUND_ROBIN,CLUSTERED COLUMNSTORE INDEX)AS

WITH Contratos AS 
(
  SELECT --TOP 100
    CF.serial,
    CF.ID_CUENTA,
    CF.ID_FACTURACION,
    -- CC.COD_PRODUCTO,
    -- CC.PRODUCTO,
    CONVERT(DATE, CF.Fec_creacion) Fec_creacion,
    rn = ROW_NUMBER() OVER (PARTITION BY CF.serial ORDER BY CONVERT(DATE, CF.Fec_creacion)),
    anterior = LAG(CONVERT(DATE, CF.FEC_creacion), 1) OVER(PARTITION BY CF.serial ORDER BY CONVERT(DATE, CF.FEC_creacion))
    FROM (
            SELECT DISTINCT 
                CF.Serial
                ,CF.ID_CUENTA
                ,CF.ID_FACTURACION
                ,CF.Fec_Creacion
            FROM Comercial.Facturacion CF 
            LEFT JOIN Comercial.Cotizaciones CC
                ON CF.COTIZACION_NO COLLATE SQL_LATIN1_GENERAL_CP1_CI_AI = CC.COTIZACION_NO
            WHERE COD_PRODUCTO NOT LIKE '9380002000011'
        ) CF
    LEFT JOIN Finanzas.MAESTRA_NC_ANULACIONES FMA 
        ON CF.ID_FACTURACION = FMA.ID_FACTURACION
    INNER JOIN (SELECT DISTINCT SERIAL FROM stg.EmpresasInicial) EI
        ON EI.Serial = CF.SERIAL COLLATE SQL_Latin1_General_CP1_CI_AI
    WHERE 1 = 1
        AND (FMA.ID_FACTURACION IS NULL OR FMA.TIPO_NC = 4029)
        -- 4014 y 4020 nc completas
        -- 4029 nc parcial
-- Insu: Se cruza con Maestra_NC_Anulaciones para saber que factura fue un genero nota crédito
        -- Por revisar: El serial '01020020824507' tiene la nube con nota crédito 
            -- pero en synergy tiene el producto *activo*
        -- ORDER BY serial, Fec_creacion
), Renovaciones AS 
(
    SELECT serial, Fec_creacion, 
        DATEADD(year, 1, Fec_creacion) as siguiente, 
        1 as rn, 
        CASE WHEN anterior IS NULL THEN DATEADD(year, 1, Fec_creacion) END anterior
    FROM Contratos 
        WHERE rn = 1 
    UNION ALL
    SELECT 
        serial, 
        Fec_creacion,
        DATEADD(
            year, 
            1, 
            MIN(FEC_creacion) 
            OVER(
                PARTITION BY SERIAL ORDER BY  rn DESC
                ROWS BETWEEN 1 PRECEDING AND CURRENT ROW 
            )     
        ) as siguiente,
        rn,
        DATEADD(
            year,
            1,
            ISNULL(
                CASE WHEN anterior = Fec_creacion 
                    THEN LAG(anterior, 1) OVER (PARTITION BY serial ORDER BY rn) 
                    ELSE anterior 
                END,
                Fec_creacion
            )
        ) anterior
    FROM Contratos 
    WHERE rn > 1 
) 
SELECT DISTINCT
    Renovaciones.Serial,
    Renovaciones.Fec_creacion,
    Contratos.ID_FACTURACION,
    Contratos.ID_CUENTA,
    -- Contratos.COD_PRODUCTO, 
    -- Contratos.PRODUCTO, 
    DATEDIFF(day, Renovaciones.anterior, Renovaciones.Fec_creacion) DiasRenovacion 
--INTO #ContratosRenovaciones 
FROM Renovaciones 
LEFT JOIN Contratos 
    ON Contratos.SERIAL = Renovaciones.SERIAL
    AND Contratos.rn = Renovaciones.rn`

export const DGDefinitions=()=> {
  return (
    <>
    {/* <h2 className='page-header-reports'>Documentación Data Governance</h2> */}
    <h5>Queries</h5>
    <Card>
      <Card.Header>Información</Card.Header>
      <ListGroup variant="flush">
        <ListGroup.Item>Nombre Query: Contratos Renovaciones</ListGroup.Item>
        <ListGroup.Item>Descripción breve: Tabla de información por serial de cliente, id de facturación, fecha de creación y días de renovación teniendo en cuenta el producto usado anteriormente.</ListGroup.Item>
        <ListGroup.Item>Alcance: Base inicial de empresas activas, 100% Siigo Nube (Colombia). Sin certificados digitales, sin notas créditos completas. Adicionalmente, este query sólo aplica para la base training, la cual reconstruye dias de renovación al momento de realizar la compra en el pasado.</ListGroup.Item>
        <ListGroup.Item>Autor: <Link to='/'>Insu</Link></ListGroup.Item>
        <ListGroup.Item>Base de datos: analyticsDWH</ListGroup.Item>
        <ListGroup.Item>Esquema: Comercial y Finanzas</ListGroup.Item>
        <ListGroup.Item>Tabla: Facturación, cotizaciones y MAESTRA_NC_ANULACIONES</ListGroup.Item>
                <ListGroup.Item>Tags: Renovaciones, Ventas, Customer, Contratos</ListGroup.Item>
      </ListGroup>
    </Card>
    <p></p>
    <p></p>
    <p></p>

    <Card>
      <Tabs
        defaultActiveKey="training">
        <Tab eventKey="training" title='Training'>
          <SyntaxHighlighter language="sql" style={xt256} wrapLongLines={true} showLineNumbers={true}>
            {CODE}
          </SyntaxHighlighter>
        </Tab>
        <Tab eventKey="testing" title='Testing'>
          <SyntaxHighlighter language="sql" style={xt256} wrapLongLines={true} showLineNumbers={true}>
            {CODE}
          </SyntaxHighlighter>
        </Tab>
      </Tabs>

    </Card>
</>
  )
}