import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Lobby } from '../pages/Lobby'
import { MyRequest } from '../../pages/helpDesk/MyRequest'
import FormEditTicket from '../../components/helpDesk/FormEditTicket'
import { MyJob } from '../pages/MyJob'
import DescriptionJob from '../../components/helpDesk/DescriptionJob'
import { InfoTicket } from '../pages/InfoTicket'
import { RequestForm } from '../pages/RequestForm'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'devextreme/dist/css/dx.light.css';
export const HelpRoutes = () => {
  return (
    <Routes>
        <Route path='/'element={<Lobby/>}/>
        <Route path='/RegisterRequest/:type'element={<RequestForm/>}/>
        <Route path='/MyRequest' element={<MyRequest />} />
        <Route path='/MyJob' element={<MyJob/>} />
        <Route path='/MyJob/EditTicket1/:id' element={<FormEditTicket/>} />
        <Route path='/MyJob/EditTicket/:id' element={<InfoTicket/>} />
        <Route path='/MyJob/DescriptionTicket1/:id' element={<DescriptionJob/>}/>
        <Route path='/MyJob/DescriptionTicket/:id' element={<InfoTicket/>}/>
    </Routes>
  )
}
