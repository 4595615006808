import * as ReactDOM from "react-dom";
import { ProgressBar } from "@progress/kendo-react-progressbars";
import { useCallback, useRef, useState } from "react";
export const ChargingBar = ({mensaje,time}) => {
    let timer=(time*0.5)/10000
  const { value, start} = useInterval(0, timer);
  return (
    <div>
      <div className="text-center">
        <h2>{mensaje}</h2>
      </div>
      <br />
      <div className="text-center">
        <ProgressBar value={value} />
      </div>
    </div>
  );
};
function useInterval(initialValue, ms) {
  const [value, setValue] = useState(initialValue);
  const intervalRef = useRef(null);
  let val = 0;
  const start = useCallback(() => {
    if (intervalRef.current !== null) {
      return;
    }
    intervalRef.current = setInterval(() => {
      if (val <= 99) {
        setValue((v) => v + 0.1);
        val = val + 0.1;
      }
    }, ms);
  }, []);
  start()
  
  return {
    value,
    start,

  };
}