import { Switch } from "@progress/kendo-react-inputs";
import { useCallback } from "react";
import {Label, Error, Hint, FloatingLabel} from '@progress/kendo-react-labels';

export const MyCustomSwitch = (fieldRenderProps) => {
    const {
      // The meta props of the Field.
      validationMessage,
      id,
      valid,
      // The input props of the Field.
      value,
      visited,
      onChange,
      label,
      ...others
      // The custom props that you passed to the Field.
    } = fieldRenderProps;
    const showValidationMessage =  validationMessage;
    const errorId = showValidationMessage ? `${id}_error` : '';
    const onValueChange = useCallback(() => {
      // onChange callback expects argument with 'value' property
      onChange({
        value: !value,
      });
    }, [onChange, value]);
    return (
      <div>
        <Label
				editorId={id}
				editorValid={valid}
				className="k-form-label">
				{label}
			</Label>
        <Switch onChange={onValueChange} checked={value} id={id} onLabel={"Si"} offLabel={"No"} {...others}/>
        {showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
      </div>
    );
  };