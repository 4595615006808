import axios from 'axios';
// Initialize variables to find the closest date

const getIncidentByRFC = async (rfc, callback) => {
    try {
        // Fetch data from the API
        const response = await axios.get(`/api/siignalGetBaseRenovacionesMX/${rfc}`);
        const data = response.data;
        const today = new Date();
        console.log(data)
        // Helper function to parse a date string
        const parseDate = (dateString) => {
            const date = new Date(dateString);
            return isNaN(date.getTime()) ? null : date; // Return null if the date is invalid
        };
        // Helper function to calculate days difference
        const calculateDaysDifference = (date) => (date - today) / (1000 * 60 * 60 * 24);
        let closestDate = null;
        let minDaysDifference = Infinity;
        // Process the records
        if (data.base.length > 1) {
            data.base.forEach(record => {
                const renovacionDate = parseDate(record.FECHA_RENOVACION);
                const daysDifference = calculateDaysDifference(renovacionDate);
                if (daysDifference < 0) {
                    // If the renovation date is in the past, return the data immediately
                    callback(data);
                    return; // Exit loop
                }
                // Find the closest future date
                if (daysDifference >= 0 && daysDifference < minDaysDifference) {
                    closestDate = renovacionDate;
                    minDaysDifference = daysDifference;
                }
            });
            // Filter records based on the closest date
            data.base = data.base.filter(record => {
                const renovacionDate = parseDate(record.FECHA_RENOVACION);
                return renovacionDate <= today || renovacionDate <= closestDate;
            });
        }
        // Return filtered data or original data if no filtering is applied
        callback(data);
    } catch (err) {
        // Improved error handling
        console.error('Error fetching data:', err);
        alert('Se ha presentado un error al obtener los datos.');
    }
};

const getIncidents = async(callback) => {
    await axios.get('/api/mxPaymentPromises').then(res => {
        let resultData = res.data;
        callback(resultData);
    }).catch(err => {
        alert("Se ha presentado un error");
    });
}
const get_Left_Days = async(callback) => {
    const response = await axios.get('/api/mxPayment_left_days').then(res => {
        let resultData = res.data[0].Mexico;
        callback(resultData);
    }).catch(err => {
        alert("Se ha presentado un error");
    });
}

const saveNewIncident = (incident) => {
    axios.post('/api/mxPaymentPromises', incident).then(res => {
        alert("Incidente Guardado")
    }).catch(err => {
        console.log(err)
        alert(err.response.data.message)
    });
}

const saveEditIncident = (incident) => {
    axios.patch(`/api/mxPaymentPromises/${incident.codigo}`, incident).then(res => {
        console.log("Incident saved")
    }).catch(err => {
        alert(err.response.data.message)
    });
}

const addClientToIncident = (incident, client) => {
    axios.put(`/api/mxPaymentPromises/${incident.codigo}`, client).then(res => {
        console.log("Incident saved")
    }).catch(err => {
        alert(err.response.data.message)
    });
}

export { getIncidentByRFC, getIncidents, saveNewIncident, saveEditIncident, addClientToIncident, get_Left_Days }