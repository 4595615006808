
import { FeedbackForm } from './feeedbackForm';
import { TileLayout } from '@progress/kendo-react-layout';

export const TnbFeedback = () => {

    const tiles2 = [
        {
          defaultPosition: {
            col: 1,
            colSpan: 12,
            rowSpan: 4,
          },
          header: "Feedback",
          body: <FeedbackForm/>,
          resizable: false,
          reorderable: false
        },
      ];
    return (
      <>
      {/* <h1 className="demo-heading">GitHub Issues Demo</h1> */}
        <div className="example-wrapper">
          <TileLayout
            columns={12}
            rowHeight={'auto'}
            gap={{
                rows: 10,
                columns: 10,
            }}
            items={tiles2}
          />
        </div>

      </>
    )
}