import React, { Component } from 'react'
import '../../components/status-card/statuscard.css'


import Tabs from "../../components/multitab/Tabs";
import Panel from "../../components/multitab/Panel";
import { ReportsTNBEvaluacionLatam } from '../../components/pbi/Sales/TNB_EvaluacionLatam';
import { ReportsTNBETopPerformanceAsistencia } from '../../components/pbi/Sales/TNB_TopPerformanceAsistencia';
import { ReportsTNBTopPerfomance } from '../../components/pbi/Sales/TNB_TopPerformance';
import { ReportsTNBRutaAprendizaje } from '../../components/pbi/Sales/TNB_RutaDeAprendizaje';
import { ReportsTNBReporteGeneral } from '../../components/pbi/Sales/TNB_ReporteGeneral';

export const ReportsTNB = () => {

  return (
    <><h1 className='page-header-reports'>Reportes TNB</h1>
      <div id='reportContainer'>
        <Tabs>
          <Panel title="Evaluacion Latam">
            <ReportsTNBEvaluacionLatam />
          </Panel>
          <Panel title="Top Performance Asistencia">
            <ReportsTNBETopPerformanceAsistencia />
          </Panel>
          <Panel title="Ruta de Aprendizaje">
            <ReportsTNBRutaAprendizaje />
          </Panel>
        </Tabs>
      </div></>
  )
}


