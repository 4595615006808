import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { Button } from '@progress/kendo-react-buttons';
import { useUserService } from '../../services/userService';

const ProcessTreeConfig = ({ renewalsConfig }) => {
  const { userProfile } = useUserService();
  const [configFormData, setConfigFormData] = useState({ ...renewalsConfig });
  const [addingAdminEmail, setAddingAdminEmail] = useState(null)
  const [loading, setLoading] = useState(true);
  const inputsMap = {
    "daysToExtendedPromise": { "id": "daysToExtendedPromise", "name": "Dias Fecha Extendida", "type": "number" },
    "daysToPromise": { "id": "daysToPromise", "name": "Dias Promesa", "type": "number" },
    "leftDaysToShow_year": {"id": "leftDaysToShow_year", "name": "Dias Faltantes anuales", "type": "number" },
    "leftDaysToShow_month": {"id": "leftDaysToShow_month", "name": "Dias Faltantes mensuales", "type": "number" },
    "iva": { "id": "iva", "name": "IVA", "type": "number" },
    "ncAvailable": { "id": "ncAvailable", "name": "NC Disponible", "type": "bool" },
    "treeAdmin": { "id": "treeAdmin", "name": "Administrador de Arbol", "type": "list" },
    "configPanel": { "id": "configPanel", "name": "Administrador General", "type": "list" },
  }

  useEffect(() => {
    let tempAddingAdminEmail = { ...addingAdminEmail };
    let newFormData = {}
    Object.keys(configFormData).map(country => {
      if (country != "_id") {
        newFormData[country] = configFormData[country]
        Object.keys(configFormData[country]).forEach(input => {
          if (input != "$oid" && inputsMap[input].type == "list") {
            const inputCountry = country;
            if (!tempAddingAdminEmail[inputCountry]) {
              tempAddingAdminEmail[inputCountry] = {};
            }
            tempAddingAdminEmail[inputCountry][input] = "";
          }
        })
      }
    })
    setConfigFormData(newFormData)
    setAddingAdminEmail({ ...tempAddingAdminEmail });
  }, [])

  useEffect(() => {
    if (addingAdminEmail != null) {
      setLoading(false)
    }
  }, [addingAdminEmail])

  const handleDeleteEmail = (country, inputId, emailToDelete) => {
    // Filtrar los correos electrónicos para excluir el que se va a eliminar
    const updatedEmails = configFormData[country][inputId].filter(email => email !== emailToDelete);
    // Actualizar el estado con los correos electrónicos filtrados
    setConfigFormData(prevConfigFormData => ({
      ...prevConfigFormData,
      [country]: {
        ...prevConfigFormData[country],
        [inputId]: updatedEmails,
      },
    }));
  };

  const saveRenewalsTreeConfig = () => {
    axios.post("/api/siignalGetPaymentPromiseConfig", configFormData).then(res => {
      console.log(res)
      alert("Configuracion Guardada")
    }).catch(err => {
      console.log(err)
      alert("Error al guardar configuración")
    })
  }

  const generateInput = (config, data, country) => {
    let result = null;
    switch (config.type) {
      case "number":
        result = <>
          <div><b className='siigoRenewalsTitle'>{config.name}</b></div>
          <Input
            value={configFormData[country][config.id]}
            onChange={(e) => {
              setConfigFormData({
                ...configFormData,
                [country]: {
                  ...configFormData[country], [config.id]: e.target.value
                }
              })
            }}
          />
        </>
        break;
      case "bool":
        result = <Checkbox
          value={configFormData[country][config.id]}
          onChange={(e) => {
            setConfigFormData({
              ...configFormData,
              [country]: {
                ...configFormData[country],
                [config.id]: e.target.value
              }
            })
          }}
          label={config.name} />
        break;
      case "list":
        result = <>
          <div><b className='siigoRenewalsTitle'>{config.name}</b></div>
          <table className='siignalRenewalsTable'>
            <thead>
              <tr>
                <th>Email</th>
                <th>Accion</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Input
                    placeholder='Correo alfanumerico'
                    value={addingAdminEmail[country][config.id]}
                    onChange={(e) => {
                      setAddingAdminEmail({
                        ...addingAdminEmail,
                        [country]: {
                          ...addingAdminEmail[country],
                          [config.id]: e.target.value
                        }
                      })
                    }}
                  />
                </td>
                <td>
                  <Button themeColor={"default"} onClick={() => {
                    let currentInputId = config.id;
                    let currentInputCountry = country;

                    if (addingAdminEmail[currentInputCountry][currentInputId].trim() != 0) {
                      setAddingAdminEmail({
                        ...addingAdminEmail,
                        [currentInputCountry]: {
                          ...addingAdminEmail[currentInputCountry],
                          [currentInputId]: ""
                        }
                      })

                      setConfigFormData(prevConfigFormData => ({
                        ...prevConfigFormData,
                        [currentInputCountry]: {
                          ...prevConfigFormData[currentInputCountry],
                          [currentInputId]: [
                            ...(prevConfigFormData[currentInputCountry][currentInputId] || []),
                            addingAdminEmail[currentInputCountry][currentInputId] || []
                          ]
                        }
                      }));
                    }
                  }}>Añadir</Button>
                </td>
              </tr>
              {
                data.map(email => {
                  return (
                    <tr>
                      <td>{email}</td>
                      <td className='k-button-solid-primary siigoRenewalsButton siignalPointer' onClick={() => {
                        handleDeleteEmail(country, config.id, email)
                      }}>
                        Eliminar
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </>
        break;
      default:
        break;
    }
    return result;
  }

  return (
    loading ? <>Cargando</> : <>
      <GridLayout
        gap={{
          rows: 6,
          cols: 10,
        }}
        cols={[
          {
            width: "32%",
          },
          {
            width: "33%",
          },
          {
            width: "32%",
          },
        ]}>
        <GridLayoutItem>
        </GridLayoutItem>
        <div onClick={() => {
          saveRenewalsTreeConfig()
        }}>
          <GridLayoutItem className="siignalRenewalSaveAdminButton siignalPointer">
            Guardar Configuracion
          </GridLayoutItem>
        </div>
      </GridLayout>
      <GridLayout
        gap={{
          rows: 6,
          cols: 10,
        }}
        cols={[
          {
            width: "24%",
          },
          {
            width: "24%",
          },
          {
            width: "25%",
          },
          {
            width: "24%",
          },
        ]}>
        {
          Object.keys(configFormData).map(country => {
            if (country != "_id" && configFormData[country].configPanel.includes(userProfile.email)) {
              return (
                <GridLayoutItem
                  key={country}
                  className='siignal-box-shadow'>
                  <h2>{country}</h2>
                  <hr />
                  {
                    Object.keys(configFormData[country]).map(input => {
                      return (
                        <div className='siignal-box-shadow siignalRenewalsMarginTop'>
                          {
                            generateInput(inputsMap[input], configFormData[country][input], country)
                          }
                        </div>
                      )
                    })
                  }
                </GridLayoutItem>
              )
            } else {

            }
          })
        }
      </GridLayout>
      <GridLayout
        gap={{
          rows: 6,
          cols: 10,
        }}
        cols={[
          {
            width: "32%",
          },
          {
            width: "33%",
          },
          {
            width: "32%",
          },
        ]}>
        <GridLayoutItem>
        </GridLayoutItem>
        <div onClick={() => {
          saveRenewalsTreeConfig()
        }}>
          <GridLayoutItem className="siignalRenewalSaveAdminButton siignalPointer">
            Guardar Configuracion
          </GridLayoutItem>
        </div>
      </GridLayout>
    </>
  )

};

export default ProcessTreeConfig;
