import React from 'react';
import './ThirdSlice.css';
import {useSelector} from 'react-redux';
import {selectData} from '../../../../../redux/viewBISlice';

const ThirdSlice = ({successPercentage = 0}) => {
	const cronstrue = require('cronstrue');
	require('cronstrue/locales/es');

	let cronExpression = (schedule) => {
		try {
			let expression = schedule;
			let description = cronstrue.toString(expression, {locale: 'es'});
			return description;
		} catch (error) {
			return '';
		}
	};

	const mainData = useSelector(selectData);

	const {reportConfig, reportOwner, schedule} = mainData || {};
	const result = [
		reportConfig?.reportName,
		reportConfig?.datasetId,
		reportOwner?.email ?? 'User not found',
		cronExpression(schedule),
		`${successPercentage} %`,
	];

	return (
		<div className="third-slice-grid-container">
			<div className="third-slice-grid-item left">
				<p className="third-slice-bold">Nombre</p>
				<p className="third-slice-bold">DataSetId</p>
				<p> </p>
				<p className="third-slice-bold">DataOwner</p>
				<p className="third-slice-bold">Horarios de ejecución</p>
				<p className="third-slice-bold">Porcentaje de Ejecuciones satisfactorias</p>
			</div>
			<div className="third-slice-grid-item right third-slice-right">
				{result.map((item, index) => (
					<p key={index}>{item}</p>
				))}
			</div>
		</div>
	);
};

export default ThirdSlice;
