import {Form, Field, FormElement} from '@progress/kendo-react-form';
import {Button} from '@progress/kendo-react-buttons';
import {
	FormDatePicker,
	FormDropDownList,
	FormInput,
	FormTextArea,
} from '../../RequestForm/form-components';
import {
	arrivalDateValidator,
	requiredValidator,
} from '../../RequestForm/Validators';
import useAxios from '../../../hooks/UseAxios';
import {Loader} from '@progress/kendo-react-indicators';
import useAxiosPut from '../../../hooks/UseAxiosPut';
import { thunkChargeInfoTicket } from '../../../../redux/helpDeskTicketSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { selectCredencialesArea, selectCredencialesRole } from '../../../../redux/credencialesReducer';
import { IsAuthorized } from '../../../../components/auth/IsAuthorized';

export const ActivityForm = ({status, id, item = null,handleSelect,setItem,isPartner,setToast,timeElapsedStatus}) => {
	const area = useSelector(selectCredencialesArea);
    const rol=useSelector(selectCredencialesRole);
	const roleLogged=IsAuthorized('HelpDesk-dataPartner',area,rol )?"Data Partner":IsAuthorized('HelpDesk-resolver',area,rol )?"Resolver":"user";
	let disabled=!isPartner;
	const statusList = [];
	const dispatch = useDispatch();
	let initialValues = {};
	if (item) {
		initialValues = {
			idActivity: item.idActivity,
			dateStart: new Date(item.dateStart.$date),
			dateEnd: new Date(item.dateEnd.$date),
			assignedTo: {name: item.assignedTo, username: item.resolverEmail},
			statusTask: item.statusTask,
			objectives: item.objectives,
			DescriptionActivity: item.DescriptionActivity,
		};
	}
	const {responseData,isLoading: Loadingput,error,executePut,statusResponse} = useAxiosPut('/api/helpDesk/addActivityV2');
	for (let key in status) {
		if (status.hasOwnProperty(key)) {
			let item = status[key];
			statusList.push(item.name);
		}
	}
	const {data: Resolvers,isLoading: LoadingResol,error: errorResol} = useAxios('/api/listRolesDataAnalitycs');
	const HandleSubmit = async(dataItem) => {
		dataItem._id = id;
		dataItem.timeElapsedStatus = timeElapsedStatus;
		dataItem.userLoggedin= sessionStorage.getItem('usr');
		dataItem.roleLoggedin= roleLogged;
		dataItem = {...dataItem, commentOwner:{name: sessionStorage.getItem('name'), mail: sessionStorage.getItem('usr')}}
		await executePut(dataItem);
		if(!errorResol){
			setItem(null);
			dispatch(thunkChargeInfoTicket({numTicket: id}));
			let e={selected:2}
			handleSelect(e);
		}
		
	};
	useEffect(() => {
		if (statusResponse === 200&&item) {
			setToast({snackbarOpen: true, message: 'Actividad editada con éxito', type: 'success'});
		} else if (statusResponse === 200) {
			setToast({snackbarOpen: true, message: 'Actividad guardada con éxito', type: 'success'});
		} else if(statusResponse === 400) {
			setToast({snackbarOpen: true, message: responseData.error, type: 'error'});
		}

	}, [error,responseData,statusResponse])
	
	useEffect(() => {

	  return () => {
		setItem(null);
	  }
	}, [])
	
	if (Loadingput) {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}>
				<Loader size="large" />
			</div>
		);
	}
	return (
		<Form
			initialValues={initialValues}
			onSubmit={HandleSubmit}
			style={{display: 'flex'}}
			render={(formRenderProps) => (
				<FormElement
					style={{
						flex: '1',
						padding: '10px',
					}}
					className="container-activities">
					<fieldset className={'k-form-fieldset'}>
						<br />
						<legend className={'k-form-legend'}>
							INFORMACIÓN DE LA ACTIVIDAD
						</legend>
						<Field
							key={'assignedTo'}
							id={'assignedTo'}
							name={'assignedTo'}
							label={'Asignado a:'}
							component={FormDropDownList}
							data={Resolvers}
							textField={'name'}
							validator={requiredValidator}
							disabled={disabled}
						/>
						<Field
							id={'objectives'}
							name={'objectives'}
							label={'Objetivo:'}
							component={FormInput}
							validator={requiredValidator}
							disabled={disabled}
						/>
						<Field
							key={'statusTask'}
							id={'statusTask'}
							name={'statusTask'}
							label={'Estado:'}
							component={FormDropDownList}
							data={statusList}
							validator={requiredValidator}
						/>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
							}}>
							<Field
								id={'dateStart'}
								name={'dateStart'}
								label={'Fecha Inicio'}
								component={FormDatePicker}
								validator={arrivalDateValidator}
								wrapperStyle={{
									width: '90%',
									marginRight: '18px',
								}}
								disabled={disabled}
							/>
							<Field
								id={'dateEnd'}
								name={'dateEnd'}
								label={'Fecha fin:'}
								hint={'Posible fecha de fin'}
								component={FormDatePicker}
								validator={arrivalDateValidator}
								wrapperStyle={{
									width: '90%',
									marginRight: '18px',
								}}
							/>
						</div>
						<Field
							id={'DescriptionActivity'}
							name={'DescriptionActivity'}
							label={'Descriptición:'}
							optional={true}
							component={FormTextArea}
							disabled={disabled}
						/>
						<span className={'k-form-separator'} />
						<div className="k-form-buttons">
							<Button
								themeColor={'primary'}
								type={'submit'}
								disabled={!formRenderProps.allowSubmit}>
								Guardar
							</Button>
							<Button onClick={formRenderProps.onFormReset}>Clear</Button>
						</div>
						{LoadingResol && <Loader size="medium" />}
					</fieldset>
				</FormElement>
			)}
		/>
	);
};
