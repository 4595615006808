import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { CardContentNormal, GridTransfer, Wrapper } from '../Extras';


export const SkillTransfer = ({ currentStep, formData, handleOptionChange, reset, randomImage }) => {
    return (<>
        <GridLayout
            className='renewals-tree-card-container generalCase'
            style={{ margin: '10px' }}
            align={{ horizontal: 'center', }}
            gap={{ rows: 5, cols: 0, }}>


            {/* TITULO */}
            <GridLayoutItem className={`siignal-w100`} colSpan={10}>
                <GridLayout
                    style={{ margin: '10px' }}
                    cols={[{ width: "100px" }, { width: "auto" }, { width: "100px" }]}
                    rows={[{ height: "100px" }]}
                    gap={{ rows: 5, cols: 5, }}>

                    <GridLayoutItem>
                        <img className={`renewals-tree-title-img`} src={randomImage.image} />
                    </GridLayoutItem>
                    <GridLayoutItem className={`renewals-tree-title`} >
                        Transfiere al siguiente skil, esperamos haberte ayudado ! 😁
                    </GridLayoutItem>
                    <GridLayoutItem>
                        <img className={`renewals-tree-title-img`} src={randomImage.image} />
                    </GridLayoutItem>
                </GridLayout>
            </GridLayoutItem>
            {(formData[currentStep].data.notasGenerales != undefined && formData[currentStep].data.notasGenerales.length != 0) ? <>
                <GridLayoutItem col={2} colSpan={8} className='siignal-box-shadow'>
                    <div className='neutralize-css'
                        style={{ padding: '20px' }}
                        dangerouslySetInnerHTML={{
                            __html: formData[currentStep].data.notasGenerales
                        }}>
                    </div>
                </GridLayoutItem>
            </> : null}

            <GridLayoutItem col={2} colSpan={3} className='siignal-box-shadow'>
                <h4>{formData[currentStep].schedule}</h4>
                <p>Horario de atencion</p>
            </GridLayoutItem>

            <GridLayoutItem col={5} colSpan={5} className='siignal-box-shadow'>
                <h3>
                    {formData[currentStep].transfer}
                </h3>
                <p>Skill de destino</p>
            </GridLayoutItem>

            <GridLayoutItem col={4} colSpan={3}>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2, mb: 2 }}
                    onClick={() => {
                        reset();
                    }}
                >
                    Reiniciar
                </Button>
            </GridLayoutItem>
        </GridLayout>
    </>
    )
}