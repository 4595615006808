import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    formStep: 1,
    messageFilter: "",
    messageSelected: null,
    messageParametersConfig: null,
    emailsWithAccess: [],
    campaignDetails: {
        area: "",
        fecha_de_inicio: "",
        fecha_de_fin: "",
        responsable: ""
    },
    campaignMetaData: {
        status: "pending",
        priority: null,
        creationDate: null,
        reviewDate: null,
        email: null
    }
};

const whatsAppConnectReducer = createSlice({
    name: 'whatsAppConnect',
    initialState,
    reducers: {
        setFormStep: (state, action) => {
            state.formStep = action.payload;
        },
        setMessageFilter: (state, action) => {
            state.messageFilter = action.payload;
        },
        setMessageSelected: (state, action) => {
            state.messageSelected = action.payload;
        },
        setMessageParametersConfig: (state, action) => {
            state.messageParametersConfig = action.payload;
        },
        setCampaignDetails: (state, action) => {
            state.campaignDetails = action.payload;
        },
        setCampaingMetaData: (state, action) => {
            state.campaignMetaData = action.payload
        },
        setEmailsWithAccess: (state, action) => {
            state.emailsWithAccess = action.payload
        }
    }
});

export const { setFormStep, setMessageFilter, setMessageSelected, setMessageParametersConfig, setCampaignDetails, setCampaingMetaData, setEmailsWithAccess } = whatsAppConnectReducer.actions;

export const selectFormStep = state => state.whatsAppConnect.formStep;
export const selectMessageFilter = state => state.whatsAppConnect.messageFilter;
export const selectMessageSelected = state => state.whatsAppConnect.messageSelected;
export const selectMessageParametersConfig = state => state.whatsAppConnect.messageParametersConfig;
export const selectCampaignDetails = state => state.whatsAppConnect.campaignDetails;
export const selectWhatsAppConnect = state => state.whatsAppConnect;
export const selectCampaingMetaData = state => state.whatsAppConnect.campaignMetaData;
export const selectEmailsWithAccess = state => state.whatsAppConnect.emailsWithAccess

export default whatsAppConnectReducer.reducer;
