import {TileLayout} from '@progress/kendo-react-layout';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {CrossSellTiles} from './CrossSellTiles';

export const CrossSellView = () => {
	const dispatch = useDispatch();
	const [rowHeight, setRowHeight] = useState(100);
	let dataComercial = sessionStorage.getItem('dataComercial');
	dataComercial = JSON.parse(dataComercial);
	let date = dataComercial['fechaActualizacion'];

	const CustomDate = ({ date: fecha }) => {
        let fechaUTC = new Date(fecha);
        fechaUTC.setHours(fechaUTC.getHours() );
    
        let dia = fechaUTC.getDate().toString().padStart(2, '0');
        let mes = (fechaUTC.getMonth() + 1).toString().padStart(2, '0'); 
        let año = fechaUTC.getFullYear();
    
        let hora = fechaUTC.getHours().toString().padStart(2, '0');
        let minutos = fechaUTC.getMinutes().toString().padStart(2, '0');
    
        let fechaFormateada = `${dia}/${mes}/${año}`;
        let horaFormateada = `${hora}:${minutos}`;
    
        return (
            <span>
                <span style={{color: '#818181', fontWeight: 'bold', fontSize: '0.9rem'}}>
                    {`Última actualización: ${fechaFormateada} ${horaFormateada}`}
                </span>
            </span>
        );
    };
    
	useEffect(() => {
		const handleResize = () => {
			setRowHeight(window.innerHeight / 7);
		};
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);
	return (
		<div style={{}}>
			<div style={{display: 'flex', flexDirection: 'column'}}>
				<h4
					style={{
						position: 'absolute',
						right: '40px',
						top: '85px',
						fontWeight: 'bold',
						color: '#818181',
						zIndex: 1,
					}}>
					<CustomDate date={date} />
				</h4>
				<h6
					style={{
						position: 'absolute',
						right: '40px',
						top: '115px',
						fontWeight: 'bold',
						color: '#818181',
						zIndex: 1,
					}}>
					<span
						style={{
							color: '#818181',
							fontWeight: 'bold',
							fontSize: '0.9rem',
						}}>
						Venta Cruzada
					</span>
				</h6>
			</div>
			<TileLayout
				columns={8}
				columnWidth={'25%'}
				rowHeight={rowHeight}
				autoFlow={'column dense'}
				gap={{
					rows: '1rem',
					columns: '1rem',
				}}
				items={CrossSellTiles}
			/>
		</div>
	);
};
