import { createSlice } from '@reduxjs/toolkit'

export const viewBISlice = createSlice({
  name: 'viewBI',
  initialState: {
    data: null,
    url:null
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setUrl: (state, action) => {
      state.url = action.payload;
    }
  },
})

export const { setData,setUrl } = viewBISlice.actions

export const selectData = state => state.viewBI.data
export const selectUrl = state => state.viewBI.url

export default viewBISlice.reducer
