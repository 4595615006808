import React from 'react'
import { TileLayoutHome } from '../utils/TileLayout/TileLayout'
import './customLayout.css';
import { MarketingView } from '../Marketing/MarketingView';
import { ViewDefault } from '../home/ViewDefault';
import { CrossSellView } from '../CrossSell/CrossSellView';


export const CustomLayout = ({name, view}) => {
  const renderView = () => {

    switch (view) {
        case 'comercial':
            return <MarketingView />;
        case 'CrossSell'://Sales
            return <CrossSellView/>;
        case 'Strategy':
            return <ViewDefault/>;
        default:
            return <ViewDefault/>;
    }
  }
return renderView();
}
//return <TileLayoutHome/>;