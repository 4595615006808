import { getter } from '@progress/kendo-react-common';
const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const numberRegex = new RegExp(/^\d{1,4}$/);
const ccardRegex = new RegExp(/^[0-9-]+$/);
const cvcRegex = new RegExp(/^[0-9]+$/);
const ReportRegex = new RegExp(/^[^_&\/]+$/);
export const termsValidator = value => value ? "" : "It's required to agree with Terms and Conditions.";
export const emailValidator = value => !value ? "Email field is required." : emailRegex.test(value) ? "" : "Email is not in a valid format.";
export const nameValidator = value => !value ? "Full Name is required" : value.length < 7 ? "Full Name should be at least 7 characters long." : "";
export const userNameValidator = value => !value ? "Este campo es requerido" : value.length < 5 ? "Debe tener al menos 5 letras" : "";
export const AreaNameValidator = value => !value ? "Este campo es requerido" : value.length < 3 ? "Debe tener al menos 3 letras" : "";
export const titleValidator = value => !value ? "Título Requerido" : value.length<100 ? "" : "El título debe tener menos de 100 caracteres";
export const phoneValidator = value => !value ? "Phone number is required." : phoneRegex.test(value) ? "" : "Not a valid phone number.";
export const numTicketsValidator = value => !value ? "Número de ticket es requerido." : numberRegex.test(value) ? "" : "No es un número de ticket válido";
export const cardValidator = value => !value ? "Credit card number is required. " : ccardRegex.test(value) ? "" : "Not a valid credit card number format.";
export const cvcValidator = value => !value ? "CVC code is required," : cvcRegex.test(value) || value.length !== 3 ? "" : "Not a valid CVC code format.";
export const guestsValidator = value => !value ? "Number of guests is required" : value < 5 ? "" : "Maximum 5 guests";
export const nightsValidator = value => value ? "" : "Number of Nights is required.";
export const arrivalDateValidator = value => value ? "" : "Date is required.";
export const colorValidator = value => value ? "" : "Color is required.";
export const requiredValidator = value => value ? "" : "Este campo es requerido";
export const RadioButtonValidator = value => value != null ? "" : "Este campo es requerido";
export const RouteValidator = (value) => (value && value.length>=2 && value.length%2===0 ? '' : 'Aún no terminas la Ruta del Reporte');
export const reportValidator = value => !value||(value&& value.length > 25)  ?  "Este campo es requerido y debe tener menos de 25 caracteres" : ReportRegex.test(value) ? "" : "No puede tener los caracteres '_', '&' o '/'";
export const WhoWillSeeValidator = value => value && value.length > 0 ? "" : "Ingresa el cargo de al menos una persona";
export const passwordValidator = value => value && value.length > 8 ? '' : 'Password must be at least 8 symbols.';
export const addressValidator = value => value ? "" : "Address is required.";
export const stakeholderValidator = value => !value ? "Stakeholder es requerido." : emailRegex.test(value) ? "" : "Stakeholder no es un correo válido.";
const userNameGetter = getter('username');
const emailGetter = getter('email');
export const formValidator = values => {
  const userName = userNameGetter(values);
  const emailValue = emailGetter(values);
  if (userName && emailValue && emailRegex.test(emailValue)) {
    return {};
  }
  return {
    VALIDATION_SUMMARY: 'Please fill in the following fields.',
    ['username']: !userName ? 'User Name is required.' : '',
    ['email']: emailValue && emailRegex.test(emailValue) ? '' : 'Email is required and should be in a valid format.'
  };
};