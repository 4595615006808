import Button from '@mui/material/Button';
import {FieldWrapper} from '@progress/kendo-react-form';
import {Input} from '@progress/kendo-react-inputs';
import {Label, Error, Hint, FloatingLabel} from '@progress/kendo-react-labels';
import {xIcon} from '@progress/kendo-svg-icons';
import {SvgIcon} from '@progress/kendo-react-common';
import {useState} from 'react';
export const FormInputKeywords = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		type,
		optional,
		value,
		placeholder,
		...others
	} = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const [listKey, setListKey] = useState(value || []);
	const [valor, setValor] = useState('');
	const onchanger = (e) => {
		setValor(e.target.value);
	};
	const onSave = () => {
		if (valor.length > 0 && listKey.length < 6) {
			setListKey([...listKey, valor]);
			let result = [...listKey, valor];
			fieldRenderProps.onChange({
				value: result,
			});
			setValor('');
		}
	};
	const [hover, setHover] = useState(false);

	const style = {
		paddingLeft: '5px',
		cursor: 'pointer',
		color: hover ? 'red' : 'initial',
	};
  function deletee(e) {
    let result = listKey.filter((value) => value !== e);
    setListKey(result);
    fieldRenderProps.onChange({
      value: result,
    });
  }

	return (
		<FieldWrapper>
			<Label
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
				optional={optional}
				className="k-form-label">
				{label}
			</Label>
			<div className={'k-form-field-wrap'} style={{display: 'flex'}}>
				<Input
					valid={valid}
					type={type}
					id={id}
					disabled={disabled}
					placeholder={placeholder}
					ariaDescribedBy={`${hintId} ${errorId}`}
					onChange={onchanger}
					value={valor}
					style={{height: '30px'}}
				/>
				{showHint && <Hint id={hintId}>{hint}</Hint>}
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
				<Button variant="contained" onClick={() => onSave()}>
					Agregar
				</Button>
			</div>
			<div style={{display: 'flex'}} {...others}>
				{listKey.map((valueKey, key) => (
					<p
						key={key}
						style={{
							border: '1px solid #D1D1D1',
							borderRadius: '7px',
							padding: '0 5px 2px 5px',
							background: '#D1D1D1',
							margin: '10px',
							color: 'black',
						}}>
						{valueKey}
						<span
							style={style}
							onMouseEnter={() => setHover(true)}
							onMouseLeave={() => setHover(false)}
              onClick={()=>deletee(valueKey)}>
                
							<SvgIcon icon={xIcon} size="xsmall" />
						</span>
					</p>
				))}
			</div>
		</FieldWrapper>
	);
};
