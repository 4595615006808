import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Message from './Message';
import ChatInput from './ChatInput';
import './Components.css';
import siigoLogo from '../assets/LOGO AZUL CLARO.png';
import { useSelector } from 'react-redux';
import { selectCredencialesName } from '../../redux/credencialesReducer';

const ChatBox = () => {
    const name=useSelector(selectCredencialesName);
    const [messages, setMessages] = useState([]);
    const [references, setReferences] = useState([]);
    const [input, setInput] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showReferences, setShowReferences] = useState(false);
    const [expandedQuestions, setExpandedQuestions] = useState({});
    const [expandedReferences, setExpandedReferences] = useState({});
    const [selectedService, setSelectedService] = useState('');
    const messagesEndRef = useRef(null);
    const baseURL =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;

    const databricksBaseUrl = "https://adb-4661861446694957.17.azuredatabricks.net/explore/data/analyticdl/db_customer/";

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        // Enviar mensaje inicial para solicitar la selección de servicio al cargar el componente
        initializeChat();
    }, []);

    const initializeChat = () => {
        setMessages([
            { text: "Seleccione el servicio que desea utilizar", sender: 'bot', options: [
                { label: "Alice Databricks", value: "Alice databricks" },
                { label: "CLAUDI", value: "CLAUDI" },
                // { label: "Indicadores y KPI's", value: "Indicadores - Kpi´s" }
            ]}
        ]);
        setReferences([]);
        setShowReferences(false);
        setSelectedService('');
    };

    const sendMessage = async (text) => {
        if (text.trim() === "") return;

        const userMessage = {
            messages: [
                {
                    role: 'user',
                    content: text
                }
            ]
        };

        setMessages([...messages, { text, sender: 'user' }]);
        setInput('');
        setLoading(true);

        try {                                  
            const response = await axios.post(`https://datasmartreportingapi.azurewebsites.net/chatbot/api/v1/chat/?service_name=${selectedService}`, userMessage);
            if (response.data.options) {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: response.data.response, sender: 'bot', options: response.data.options }
                ]);
            } else {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: response.data.response, sender: 'bot', references: response.data.references }
                ]);
                setReferences(prevReferences => [...prevReferences, response.data.references || []]);
                setShowReferences(true);
            }
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setLoading(false);
        }
    };

    const clearChat = async () => {
        const conversationId = "your_conversation_id"; // Este ID debería generarse o manejarse correctamente
        try {
            await axios.delete(`https://datasmartreportingapi.azurewebsites.net/chatbot/api/v1/conversation/${conversationId}`);
            initializeChat();
        } catch (error) {
            console.error('Error clearing chat:', error);
        }
    };

    const toggleReferences = () => {
        setShowReferences(!showReferences);
    };

    const toggleQuestion = (index) => {
        setExpandedQuestions(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const toggleReference = (questionIndex, refIndex) => {
        const key = `${questionIndex}-${refIndex}`;
        setExpandedReferences(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    const generateDatabricksLink = (tableName) => {
        return `${databricksBaseUrl}${tableName}`;
    };

    const handleServiceSelection = (service) => {
        setSelectedService(service);
        sendMessage(`Servicio seleccionado: ${service}`);
    };

    return (
        <div className={`chat-container-cb ${isOpen&&showReferences && references.length > 0 ? 'open-cb' : 'closed-cb'}`}>
            <div className={`chat-box-container-cb ${references.length > 0 ? 'with-references-cb' : ''}`}>
                <div className="chat-header-cb" onClick={() => setIsOpen(!isOpen)}>
                    <img src={siigoLogo} alt="Siigo Logo" className="chat-logo-cb" />
                    <span style={{fontFamily:'nunito'}}>Chatbot</span>
                    <span className="toggle-icon-cb">{isOpen ?  '▶':'◀' }</span>
                </div>
                <div className="chat-content-cb">
                    <div className="chat-box-cb">
                        <div className="messages-cb">
                            {messages.map((msg, index) => (
                                <div key={index} className="message-container-cb">
                                    <Message 
                                        text={msg.text} 
                                        sender={msg.sender} 
                                        references={msg.references} 
                                        toggleReferences={toggleReferences}
                                        name={name.charAt(0)} 
                                    />
                                    {msg.options && (
                                        <div className="service-options-cb">
                                            {msg.options.map((option, idx) => (
                                                <button
                                                    key={idx}
                                                    onClick={() => handleServiceSelection(option.value)}
                                                    className="service-option-button-cb"
                                                >
                                                    {option.label}
                                                </button>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                            {loading && (
                                <div className="message-cb bot-cb">
                                    <div className="message-content-cb loading-cb">
                                        Escribiendo...
                                    </div>
                                </div>
                            )}
                            <div ref={messagesEndRef} />
                        </div>
                        <ChatInput input={input} setInput={setInput} sendMessage={() => sendMessage(input)} clearChat={clearChat} />
                    </div>
                    {showReferences && references.length > 0 && (
                        <div className="references-cb">
                            <div className="references-header-cb">
                                <button className="close-references-button-cb" onClick={() => setShowReferences(false)}>
                                    ✖
                                </button>
                            </div>
                            {references.map((refGroup, questionIndex) => (
                                refGroup.length > 0 && (
                                    <div key={questionIndex} className="reference-group-wrapper-cb">
                                        <button 
                                            className={`question-toggle-button-cb ${expandedQuestions[questionIndex] ? 'expanded-cb' : ''}`}
                                            onClick={() => toggleQuestion(questionIndex)}>
                                            Pregunta {questionIndex + 1} {expandedQuestions[questionIndex] ? '▲' : '▼'}
                                        </button>
                                        {expandedQuestions[questionIndex] && (
                                            <div className="reference-group-cb">
                                                {refGroup.map((ref, refIndex) => (
                                                    <div key={refIndex} className="reference-cb">
                                                        <button 
                                                            className={`reference-title-button-cb ${expandedReferences[`${questionIndex}-${refIndex}`] ? 'expanded-cb' : ''}`}
                                                            onClick={() => toggleReference(questionIndex, refIndex)}>
                                                            {ref.title}
                                                        </button>
                                                        {expandedReferences[`${questionIndex}-${refIndex}`] && (
                                                            <div className="reference-content-cb expanded-cb">
                                                                <strong>{ref.title}</strong>
                                                                <p>{ref.content}</p>
                                                                <a href={generateDatabricksLink(ref.title)} target="_blank" rel="noopener noreferrer" className="databricks-link-cb">
                                                                    Abrir en Databricks
                                                                </a>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                )
                            ))}
                        </div>
                    )}
                </div>
                
            </div>
        </div>
    );
};

export default ChatBox;