import {useEffect, useReducer} from 'react';
import {todoReducer} from '../TodoReducer';
import {controller} from '../db/controller';

function areEqualObjects(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
}

function compareObjectLists(list1, list2) {
    if (list1.length !== list2.length) {
        return false;
    }

    for (let i = 0; i < list1.length; i++) {
        if (!areEqualObjects(list1[i], list2[i])) {
            return false;
        }
    }

    return true;
}

function init(valueInit) {
	let dataComercial = JSON.parse(sessionStorage.getItem('dataComercial')); // Convertir la cadena JSON en un objeto
	let dataTareas = '';
	try {
		dataTareas = dataComercial[valueInit];
	} catch (error) {
		dataTareas = [];
	}
	return dataTareas;
}

export const useTodo = (valueInit) => {
	const [todos, dispatch] = useReducer(todoReducer, [], () => init(valueInit));

	useEffect(() => {
		const saveData = async () => {
			let dataToSend = JSON.parse(sessionStorage.getItem('dataComercial')); // Convertir la cadena JSON en un objeto
			try {
				if(!compareObjectLists(dataToSend[valueInit], todos)){
					dataToSend[valueInit] = todos; // Agregar una nueva propiedad al objeto
					let dataBuilt = {...dataToSend}; // Hacer una copia del objeto
					sessionStorage.setItem('dataComercial', JSON.stringify(dataBuilt)); // Convertir el objeto en una cadena JSON
					await controller('api/Viewcomercial', 'PUT', {data: dataBuilt});
				}
			} catch (error) {}
		};

		saveData();
	}, [todos, valueInit]);

	const handleSubmit = (newTodo) => {
		const action = {
			type: '[TODO] Add Todo',
			payload: newTodo,
		};
		dispatch(action);
	};
	const handleDeleteTodo = (id) => {
		dispatch({
			type: '[TODO] Remove Todo',
			payload: id,
		});
	};
	const handleTodoMade = ({checked}, id) => {
		const todomade = {
			atencion: checked,
			idSeguimientoAliado: id,
		};
		const action = {
			type: '[TODO] doneCheck Todo',
			payload: todomade,
		};
		dispatch(action);
	};

	return {
		todos,
		handleSubmit,
		handleDeleteTodo,
		handleTodoMade,
		todosCount: todos ? todos.length : 0,
		pendingTodosCount: todos
			? todos.filter((todo) => !todo.atencion).length
			: 0,
	};
};


