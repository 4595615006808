
import {Form, FormElement} from '@progress/kendo-react-form';
import {Button} from '@progress/kendo-react-buttons';
import {Stepper} from '@progress/kendo-react-layout';
import {AccountDetails} from '../account-details';
import {PersonalDetails} from '../personal-details';
import {PaymentDetails} from '../payment-details';

import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {
	getListaDeCapacitaciones,
	selectListadeCapacitaciones,
} from '../../../redux/TNBSlice';
import { useCallback, useState } from 'react';
const stepPages = [AccountDetails, PersonalDetails, PaymentDetails];

export const TnbFormularioCapacitacion = (props) => {
	const defaultTrainerEmail = sessionStorage.getItem('usr');
	const defaultTrainerName = sessionStorage.getItem('name');
	const ListaDeCapacitaciones = useSelector(selectListadeCapacitaciones);
	const dispatch = useDispatch();
	const startingValues = {
		nombreCapacitacion: '',
		tipoCapacitacion: '',
		subTipoCapacitacion: '',
		fechaIncioCapacitacion: new Date(),
		fechaFinCapacitacion: new Date(),
		pais: '',
		area: '',
		entrenadorAdicional: '',
		nombreEntrenador: defaultTrainerName,
		emailEntrenador: defaultTrainerEmail,
		topic1: '',
		trainer1: '',
		type1: '',
		topic2: '',
		trainer2: '',
		type2: '',
		topic3: '',
		trainer3: '',
		type3: '',
		topic4: '',
		trainer4: '',
		type4: '',
		topic5: '',
		trainer5: '',
		type5: '',
		topic6: '',
		trainer6: '',
		type6: '',
		topic7: '',
		trainer7: '',
		type7: '',
		topic8: '',
		trainer8: '',
		type8: '',
	};
	const [defaultValues, setDefaultValues] = useState(startingValues);
	const [step, setStep] = useState(0);
	const [formState, setFormState] = useState({});
	const [tableUpdate, setTableUpdate] = useState(false);
  const [formKey, setFormKey] = useState(0);
	let validatenombreCapacitacion = '';
	const [steps, setSteps] = useState([
		{
			label: 'Capacitación',
			isValid: undefined,
		},
		{
			label: 'Capacitadores',
			isValid: undefined,
		},
		{
			label: 'Temas',
			isValid: undefined,
		},
	]);

	const lastStepIndex = steps.length - 1;
	const isLastStep = lastStepIndex === step;
  
	const isPreviousStepsValid =
		steps
			.slice(0, step)
			.findIndex((currentStep) => currentStep.isValid === false) === -1;
	const onStepSubmit = useCallback(
		(event) => {
			const {isValid, values} = event;
			let validatenombreCapacitacion = '';
			const currentSteps = steps.map((currentStep, index) => ({
				...currentStep,
				isValid: index === step ? isValid : currentStep.isValid,
			}));
			setSteps(currentSteps);
			validatenombreCapacitacion = comparenombreCapacitación(
				values.nombreCapacitacion,
				ListaDeCapacitaciones
			);
			if (!validatenombreCapacitacion) {
				setStep(() => Math.min(step + 1, lastStepIndex));
			}
			setFormState(values);
			if (isLastStep && isPreviousStepsValid && isValid) {
				sendNewCapacitacion(
					values,
					setTableUpdate,
					tableUpdate,
					setStep,
					setDefaultValues,
					startingValues,
					setSteps,
					setFormState,
					dispatch,
					getListaDeCapacitaciones,
          setFormKey
				);
			}
		},
		[
			steps,
			isLastStep,
			isPreviousStepsValid,
			step,
			lastStepIndex,
			defaultValues,
			formState,
		]
	);

	const onPrevClick = useCallback(
		(event) => {
			event.preventDefault();
			setStep(() => Math.max(step - 1, 0));
		},
		[step, setStep]
	);
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
			}}>
			<Stepper value={step} items={steps} />
			<Form
				initialValues={defaultValues}
				onSubmitClick={onStepSubmit}
				ignoreModified={true}
        key={formKey}
				render={(formRenderProps) => (
					<div>
						<FormElement
							style={{
								width: '100%',
								size: 'small',
							}}>
							{stepPages[step]}
							<span
								style={{
									marginTop: '40px',
								}}
								className={'k-form-separator'}
							/>
							<div
								style={{
									justifyContent: 'space-between',
									alignContent: 'center',
								}}
								className={
									'k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end'
								}>
								<span
									style={{
										alignSelf: 'center',
									}}>
									Paso {step + 1} de 3
								</span>
								<div>
									{step !== 0 ? (
										<Button
											style={{
												marginRight: '16px',
											}}
											onClick={onPrevClick}>
											Anterior
										</Button>
									) : undefined}
									<Button
										themeColor={'primary'}
										disabled={isLastStep ? !isPreviousStepsValid : false}
										onClick={formRenderProps.onSubmit}>
										{isLastStep ? 'Registrar' : 'Siguiente'}
									</Button>
								</div>
							</div>
						</FormElement>
					</div>
				)}
			/>
		</div>
	);
};

function comparenombreCapacitación(nombreCapacitacion, ListaDeCapacitaciones) {
	const existe = ListaDeCapacitaciones.some(
		(elem) => elem.nombreCapacitacion === nombreCapacitacion
	);
	if (existe) {
		alert(`El nombre de la capacitación ya existe: ${nombreCapacitacion}`);
	}
	return existe;
}

async function sendNewCapacitacion(
	values,
	setTableUpdate,
	tableUpdate,
	setStep,
	setDefaultValues,
	startingValues,
	setSteps,
	setFormState,
	dispatch,
	getListaDeCapacitaciones,
  setFormKey
) {
	try {

		const response = await axios.post('/api/TNB/RegisterTraining', values);
		alert(response.data['message']);

		// Actualizaciones del estado y otros procesos
    setFormKey(prevKey => prevKey + 1);
		setTableUpdate(!tableUpdate);
    setStep(0);
    setDefaultValues({...startingValues}); // Es una buena práctica clonar el objeto
    setFormState({...startingValues}); // Resetear también formState
    setSteps([
      { label: 'Capacitación', isValid: undefined },
      { label: 'Capacitadores', isValid: undefined },
      { label: 'Temas', isValid: undefined }
    ]);
    dispatch(getListaDeCapacitaciones());
	} catch (error) {
		// Manejo de error en caso de que la solicitud falle
		console.error('Error al enviar capacitación:', error);
	}
}
