import '../../components/multitab/tabs.css';
import { useNavigate } from 'react-router-dom';


export default function Newtabs({
	actualRute,
	nombreDelBoton,
	setSelectedButton,
	isSelected,
}) {
	const navigate = useNavigate();
	const handleClick = async () => {
		if (isSelected) {
			// Deselecciona si ya estaba seleccionado
		} else {
			setSelectedButton(nombreDelBoton); // Selecciona si no estaba seleccionado
		}

		let encodeRute = encodeURIComponent(actualRute);
		let encodeNameReport = encodeURIComponent(nombreDelBoton);
		navigate(
			`/dashboardpage/${encodeRute}?report=${encodeNameReport}`
		);
		
	};




	return (
		<li
			onClick={handleClick}
			className={`tabs ${isSelected ? 'selected' : ''}`}>
			{nombreDelBoton}
		</li>
	);
}
