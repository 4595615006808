import React, { useState, useEffect } from 'react';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { commentIcon, envelopIcon, volumeDownIcon, minusSmIcon } from "@progress/kendo-svg-icons";
import { ColumnNotaCredito } from "./TableColumns/columnNotaCredito";
import { useUserService } from '../../services/userService';
import axios from 'axios';
import { ColumnActionsMx } from './TableColumns/columnActionsMx';
import { RowDetails } from './TableColumns/rowDetails';
import { ColumnTipoDeGestion } from './TableColumns/columnTipoDeGestion';
import { Chip, ChipList } from "@progress/kendo-react-buttons";
import { ResumeTableMx } from './CustomComponents/resumeTableMx';
import { ColumnValorMx } from './TableColumns/columnValorMx';
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Popup } from "@progress/kendo-react-popup";

const AgentPanelMX = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [savedPromises, setSavedPromises] = useState([]);
  const [userEmail, setUserEmail] = useState(sessionStorage.getItem("usr"));
  const [editMode, setEditMode] = useState({});
  const [editingData, setEditingData] = useState({});
  const [showDetails, setShowDetails] = useState({});
  const [isAdminMx, setIsAdminMx] = useState(false)
  const { userProfile } = useUserService();
  const [paginationConfig, setPaginationConfig] = useState([]);
  const [compiledData, setCompiledData] = useState([])
  const [searchText, setSearchText] = useState(null)
  const [activeView, setActiveView] = useState("general")
  const [value, setValue] = React.useState(new Date());
  const [promisesResume, setPromisesResume] = useState({
    daily: {},
    monthly: {},
  });
  const [chartsData, setChartsData] = useState([])
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    setShow(false);
  }, []);
  const onClick = () => {
    setShow(!show);
  };
  const getBoardConfig = (email) => {
    axios.get("/api/siignalGetPaymentPromiseConfig").then(res => {
      let adminEmails = []
      Object.keys(res.data).map(country => {
        if (country != "_id") {
          adminEmails = [...adminEmails, ...res.data[country].configPanel]
          console.log(adminEmails)
        }
      })
      if (adminEmails.includes(userEmail)) {
        console.log("Es admin")
        setIsAdminMx(true)
      } 
    })
  }

  useEffect(() => {
    if (isAdminMx != undefined) {
      loadSavedPromises();
    }
  }, [isAdminMx])

  const getPromisedByUser = (page = 1, elPerPage = 50) => {
    let email = (isAdminMx) ? "admin" : sessionStorage.getItem("usr");
    setIsLoading(true);
    axios.get(`/api/siignalGetComoVamosMx/${email}/${page}/${elPerPage}/${searchText}`).then(res => {
      let savedPromises = res.data;
      setPaginationConfig({
        elementsPerPage: savedPromises.elementsPerPage,
        page: savedPromises.page,
        totalElements: savedPromises.totalElements
      })
      setCompiledData(savedPromises.compiled[0])
      savedPromises = JSON.parse(savedPromises.data)
      setSavedPromises(savedPromises);
    }).catch(err => {
      console.log(err);
      alert("Error al cargar configuración")
    });
  }

  const getPromisedByLeader = (page = 1, elPerPage = 50) => {
    axios.get(`/api/siignalGetPaymentPromisesByLeaderMx/${sessionStorage.getItem("usr")}/${page}/${elPerPage}/${searchText}`).then(res => {
      let savedPromises = res.data;
      if (savedPromises.message != undefined) {
        setPaginationConfig({
          elementsPerPage: savedPromises.elementsPerPage,
          page: 1,
          totalElements: 0
        })
        savedPromises = []
        setSavedPromises(savedPromises);
      } else {
        setPaginationConfig({
          elementsPerPage: savedPromises.elementsPerPage,
          page: savedPromises.page,
          totalElements: savedPromises.totalElements
        })
        savedPromises = JSON.parse(savedPromises.data)
        setSavedPromises(savedPromises);
      }
    }).catch(err => {
      console.log(err);
      alert("Error al cargar configuración")
    });
  }

  const loadSavedPromises = (page = 1, elementsPerPage = paginationConfig.elementsPerPage) => {
    getPromisedByUser(page, elementsPerPage);
  }

  const generateCustomClass = (item) => {
    let classesToAdd = [];

    const promiseDate = new Date(item.fechaExtendida)
    const currentDate = new Date();
    const nextTwoDays = new Date()
    nextTwoDays.setDate(nextTwoDays.getDate() + 2)

    if (item.status == "Pagado") {
      classesToAdd.push("renewalsTree-promiseIsPaid")
    } else if (item.status == "Vencido") {
      classesToAdd.push("renewalsTree-promiseIsExpired")
    } else if (promiseDate >= currentDate && promiseDate <= nextTwoDays) {
      classesToAdd.push("renewalsTree-promiseIsAlmostExpired")
    }
    return classesToAdd.join(" ");
  }

  const viewDetails = (index, data) => {
    setShowDetails((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  }

  const toggleEditMode = (index, data) => {
    setEditingData((prevState) => ({
      ...prevState,
      [index]: data,
    }));

    setEditMode((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const updatePromise = (index) => {
    let updatedRow = editingData[index]
    axios
      .put(`/api/siignalUpdatePaymentPromiseMx/${JSON.stringify(updatedRow._id)}`, updatedRow)
      .then((res) => {
        loadSavedPromises(paginationConfig.page, paginationConfig.elementsPerPage);
        toggleEditMode(index);
      })
      .catch((err) => {
        console.log(err);
        alert("Error al guardar cambios");
      });
  };

  const deletePromise = (index, item) => {
    const elementId = item._id["$oid"]

    const deleteData = {
      _id: elementId,
      deletedBy: userEmail,
      deletedDate: new Date(),
      deletedRecord: item
    }

    axios.put(`/api/siignalDeletePaymentPromiseMx/${elementId}`, deleteData).then(res => {
      alert(res.data.message)
      loadSavedPromises(paginationConfig.page, paginationConfig.elementsPerPage);
    }).catch(err => {
      alert("Error al eliminar")
    })
  };

  useEffect(() => {
    let email = sessionStorage.getItem("usr");
    setUserEmail(email);
    getBoardConfig(email);
  }, []);

  useEffect(() => {
    if (savedPromises != null) {
      setIsLoading(false);
      if (savedPromises.length != 0) {
        calculateResume()
      }
    }
  }, [savedPromises]);

  const generateDataForCharts = (data) => {
    const categoriesNameMap = {
      daily: "Diario",
      monthly: "Mensual"
    };
    const seriesNameMap = {
      totalPromised: "Total",
      totalPayed: "Recaudado",
      totalOutdated: "Vencido",
      totalPromises: "Promesas",
      totalNC: "NC Total",
      totalNCDone: "NC Creadas"
    }
    let tempChartsData = Object.keys(data).map(group => {
      const categories = Object.keys(data[group]);
      const seriesData = categories.reduce((result, dataPoint) => {
        const dataPoints = Object.keys(data[group][dataPoint]);
        dataPoints.forEach(dataPointName => {
          if (!result[dataPointName]) {
            result[dataPointName] = [];
          }
          result[dataPointName].push(data[group][dataPoint][dataPointName]);
        });
        return result;
      }, {});

      let result = {
        chartName: '',
        categories,
        series: [{
          name: categoriesNameMap[group],
          data: seriesData
        }]
      };

      let newSeriesData = Object.entries(result.series[0].data).map(([serie, data]) => ({
        name: seriesNameMap[serie],
        data: data
      }));

      result.series = newSeriesData;

      return result
    });
    setChartsData(tempChartsData)
  }

  const calculateResume = () => {
    const monthNames = [
      "Enero", "Febrero", "Marzo", "Abril",
      "Mayo", "Junio", "Julio", "Agosto",
      "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    let tempResumeData = {
      daily: {},
      monthly: {},
    }

    savedPromises.forEach((item, index) => {
      const tableroData = null;
      let promiseDate = new Date(item.fechaCompromiso)

      const currentDay = item.fechaCompromiso
      let currentDayData = tempResumeData.daily[currentDay]
      if (currentDayData == undefined) {
        tempResumeData.daily[item.fechaCompromiso] = {
          totalPromised: item.valor,
          totalPayed: (item.status == 'Pagado') ? item.valor : 0,
          totalOutdated: (item.status == "Vencido") ? item.valor : 0,
          totalPromises: 1,
        }
      } else {
        currentDayData.totalPromised += item.valor
        currentDayData.totalPayed += (item.status == 'Pagado') ? item.valor : 0
        currentDayData.totalOutdated += (item.status == "Vencido") ? item.valor : 0
        currentDayData.totalPromises += 1
      }
      const currentMonth = monthNames[promiseDate.getMonth()]
      let currentMonthData = tempResumeData.monthly[currentMonth]
      if (currentMonthData == undefined) {
        tempResumeData.monthly[currentMonth] = {
          totalPromised: item.valor,
          totalPayed: (item.status == 'Pagado') ? item.valor : 0,
          totalOutdated: (item.status == "Vencido") ? item.valor : 0,
          totalPromises: 1,
        }
      } else {
        currentMonthData.totalPromised += item.valor
        currentMonthData.totalPayed += (item.status == 'Pagado') ? item.valor : 0
        currentMonthData.totalOutdated += (item.status == "Vencido") ? item.valor : 0
        currentMonthData.totalPromises += 1
      }
    })
    setPromisesResume(tempResumeData)
    generateDataForCharts(tempResumeData)
  }

  const formatDataMx = (data) => {
    const {
      rfc,
      serial,
      producto,
      fechaExtendida,
      fechaCompromiso,
      valor,
      notas,
    } = data;
    return `RFC: ${rfc}\nSerial ${serial} del producto ${producto}\nFue programado el ${fechaCompromiso} para pagar máximo hasta el ${fechaExtendida} por un valor de ${valor}\nSe presentan las siguientes notas:\n\n${notas}`;
  };

  const generatePageNumbers = (maxPagesToShow = 11) => {
    let currentPage = paginationConfig.page;
    let totalElements = paginationConfig.totalElements;
    let elementsPerPage = paginationConfig.elementsPerPage;
    const totalPages = Math.ceil(totalElements / elementsPerPage);

    currentPage = Math.min(Math.max(currentPage, 1), totalPages);

    let startPage, endPage;

    if (totalPages <= maxPagesToShow) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
      if (currentPage <= halfMaxPagesToShow) {
        startPage = 1;
        endPage = maxPagesToShow;
      } else if (currentPage + halfMaxPagesToShow >= totalPages) {
        startPage = totalPages - maxPagesToShow + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - halfMaxPagesToShow;
        endPage = currentPage + halfMaxPagesToShow;
      }
    }
    let tempResult = (currentPage < 6) ? [] : ['Primera']

    tempResult = [
      ...tempResult,
      ...Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)
    ];
    if (currentPage < totalPages - 6) {
      tempResult.push('Ultima')
    }

    return tempResult.map(el => {
      return {
        text: (el == 'Ultima') ? `${el} (${totalPages})` : el,
        themeColor: (el == currentPage) ? 'info' : 'base',
        onClick: () => {
          handlePaginationButtonClick(el)
        }
      }
    })
  };

  const handlePaginationButtonClick = (target) => {
    let newPage;
    switch (target) {
      case 'Primera':
        newPage = 1;
        break;
      case 'Ultima':
        newPage = Math.ceil(paginationConfig.totalElements / paginationConfig.elementsPerPage);
        break;
      default:
        newPage = target;
    }

    if (activeView == "general") {
      getPromisedByUser(newPage, paginationConfig.elementsPerPage);
    } else {
      getPromisedByLeader(newPage, paginationConfig.elementsPerPage)
    }
  };
  const [anchorAlign, setAnchorAlign] = React.useState({
    horizontal: "left",
    vertical: "top",
  });
  const [popupAlign, setPopupAlign] = React.useState({
    horizontal: "left",
    vertical: "top",
  });
  const [anchorAlign2, setAnchorAlign2] = React.useState({
    horizontal: "right",
    vertical: "top",
  });
  const [popupAlign2, setPopupAlign2] = React.useState({
    horizontal: "right",
    vertical: "top",
  });
  const handleChange = (event, page = 1, elementsPerPage = paginationConfig.elementsPerPage2) => {
    let promise_date = event.value
    promise_date = promise_date.toString()
    promise_date = promise_date.replace("GMT-0500", "GMT-0000")
    promise_date = new Date(promise_date)
    promise_date = promise_date.toISOString()
    promise_date = promise_date.split('T')[0]
    setValue(event.value);
    getPromisedByDateMX(page, elementsPerPage, promise_date)
  };
  const getPromisedByDateMX = (page = 1, elPerPage = 50, promise_date) => {
    let email = (isAdminMx) ? "admin" : sessionStorage.getItem("usr");
    setIsLoading(true);
    axios.get(`/api/siignalGetComoVamosDateMX/${email}/${page}/${elPerPage}/${promise_date}`).then(res => {
      let savedPromises = res.data;
      setPaginationConfig({
        elementsPerPage: savedPromises.elementsPerPage,
        page: savedPromises.page,
        totalElements: savedPromises.totalElements
      })
      setCompiledData(savedPromises.compiled[0])
      savedPromises = JSON.parse(savedPromises.data)
      setSavedPromises(savedPromises);
    }).catch(err => {
      console.log(err);
      alert("Error al cargar configuración")
    });
  }
  return (
    <GridLayout cols={[{ width: "auto", width: "auto", width: "auto", width: "auto" }]}
      gap={{ rows: 10, cols: 10, }}>
      <GridLayoutItem colSpan={1}>
        <h6>
          <button onClick={() => loadSavedPromises()}>🔄Actualizar</button> <b>Configuración del Tablero</b>
        </h6>
      </GridLayoutItem>

      {isLoading ? (
        <>Cargando...</>
      ) : (
        <>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={onClick}
              ref={anchor}
            >
              {show ? "Esconder resumen diario y mensual" : "Mostrar resumen diario y mensual"}
            </button>
          <GridLayoutItem>
            <GridLayout>
              <GridLayoutItem>
                <Popup anchorAlign={anchorAlign} popupAlign={popupAlign} anchor={anchor.current} show={show} popupClass={"popup-content"}>
                  <ResumeTableMx data={compiledData.daily}/>
                </Popup>
              </GridLayoutItem>
              <GridLayoutItem>
                <Popup anchorAlign={anchorAlign2} popupAlign={popupAlign2} anchor={anchor.current} show={show} popupClass={"popup-content"}>
                  <ResumeTableMx data={compiledData.monthly}/>
                </Popup>
              </GridLayoutItem>
            </GridLayout>
          </GridLayoutItem>

          <GridLayoutItem>
            <GridLayout
              cols={[{ width: "35%" }, { width: "65%" }]}>
              <GridLayoutItem>
                <GridLayout
                  cols={[{ width: "50%" }, { width: "50%" }]}>
                  <GridLayoutItem>
                    <div className='mxRenewalsInputCont'>
                      <input
                        className='mxRenewalsInput'
                        type="text"
                        placeholder="Buscar..."
                        value={searchText}
                        onChange={(event) => { setSearchText((event.target.value == "") ? null : event.target.value) }} />
                      <Button
                        onClick={() => {
                          if (activeView == "general") {
                            getPromisedByUser(paginationConfig.page, paginationConfig.elementsPerPage);
                          } else {
                            getPromisedByLeader(paginationConfig.page, paginationConfig.elementsPerPage)
                          }
                        }}>🔍</Button>
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <ButtonGroup>
                      <Button
                        className='siignalBtn info'
                        onClick={(page = 1, elPerPage = 50) => {
                          setActiveView("team")
                          setIsLoading(true);
                          getPromisedByLeader()
                        }}>
                        Ver Mi Equipo
                      </Button>
                      <Button
                        className='siignalBtn info'
                        onClick={() => {
                          setActiveView("general")
                          loadSavedPromises()
                        }}>Ver General</Button>
                    </ButtonGroup>
                  </GridLayoutItem>
                  <GridLayoutItem>
                      <DatePicker 
                        placeholder="Choose a date..."
                        format={"yyyy-MM-dd"}
                        value={value} onChange={handleChange}
                      />
                  </GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
              <GridLayoutItem>
                <div className='tipoGestionLegend'>
                  <ChipList
                    data={generatePageNumbers()}
                    chip={(props) => (
                      <Chip
                        {...props}
                        text={(props.dataItem.text).toString()}
                        themeColor={props.dataItem.themeColor}
                        onClick={props.dataItem.onClick}
                      />
                    )}
                  />
                </div>
              </GridLayoutItem>

            </GridLayout>
          </GridLayoutItem>

          <GridLayoutItem>
            <table className="renewalsAgentPanel-table">
              <thead>
                <tr>
                  <th className="renewalsAgentPanel-th">Correo</th>
                  <th className="renewalsAgentPanel-th">RFC</th>
                  <th className="renewalsAgentPanel-th">Serial</th>
                  <th className="renewalsAgentPanel-th">Estado</th>
                  <th className="renewalsAgentPanel-th">Fecha Gestion</th>
                  <th className="renewalsAgentPanel-th">Fecha Promesa</th>
                  <th className="renewalsAgentPanel-th">Valor</th>
                  <th className="renewalsAgentPanel-th">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {savedPromises.map((item, index) => (
                  <>
                    <tr key={index} className={`renewalsAgentPanel-tr ${generateCustomClass(item)}`}>
                      <td className="renewalsAgentPanel-td">{item.user}</td>
                      <td className="renewalsAgentPanel-td">{item.rfc}</td>
                      <td className="renewalsAgentPanel-td">{item.serial}</td>
                      <td className="renewalsAgentPanel-td">
                        {
                          editMode[index] ? <>
                            <select value={editingData[index].status} onChange={(event) => {
                              setEditingData((prevState) => ({
                                ...prevState,
                                [index]: {
                                  ...prevState[index],
                                  status: event.target.value,
                                }
                              }));
                            }}>
                              <option value="Pendiente">Pendiente</option>
                              <option value="Pagado">Pagado</option>
                              <option value="Vencido">Vencido</option>
                            </select>
                          </> : item.status
                        }
                      </td>
                      <td className="renewalsAgentPanel-td">{item.fechaCompromiso}</td>
                      <td className="renewalsAgentPanel-td">{item.fechaExtendida}</td>
                      <ColumnValorMx item={item} index={index} editMode={editMode} editingData={editingData} setEditingData={setEditingData} />
                      <ColumnActionsMx item={item} index={index} editMode={editMode} toggleEditMode={toggleEditMode} updatePromise={updatePromise} deletePromise={deletePromise} formatData={formatDataMx} viewDetails={viewDetails} isAdminMx={isAdminMx} />
                    </tr>
                    {
                      showDetails[index] ? <>
                        <RowDetails item={item} customClass={generateCustomClass(item)} />
                      </> : null
                    }
                  </>
                ))}
              </tbody>
            </table>
          </GridLayoutItem>
        </>
      )}
    </GridLayout >
  );
};

export default AgentPanelMX;
