import React from "react";

const ColumnValorMx = ({ item, index, editMode, editingData, setEditingData }) => {
    return (
        <td className="renewalsAgentPanel-td">
            {
                editMode[index] ? <>
                    <input className='renewalsAgentPanelInput' value={editingData[index].valor} onChange={(event) => {
                        setEditingData((prevState) => ({
                            ...prevState,
                            [index]: {
                                ...prevState[index],
                                valor: event.target.value,
                            }
                        }));
                    }} />
                </> : item.valor
            }
        </td>
    )
}

export { ColumnValorMx }