import {useState, useEffect} from 'react';
import ProfileContent from '../components/ProfileContent.js';
import {Login, Person, PersonCard, Agenda} from '@microsoft/mgt-react';
import {Providers, ProviderState} from '@microsoft/mgt-element';
import {MgtPersonCard} from '@microsoft/mgt';
import {useMsal} from '@azure/msal-react';
import {Roles} from '../components/usersManagement/roles';
import '@progress/kendo-theme-default/dist/all.css';
import {DropDownList} from '@progress/kendo-react-dropdowns';
import {Loader} from '@progress/kendo-react-indicators';
// import Card from 'react-bootstrap/Card';

import {Link, useNavigate} from 'react-router-dom';
import {
	Card,
	CardTitle,
	CardHeader,
	CardBody,
	CardActions,
	Avatar,
} from '@progress/kendo-react-layout';
import './home.css';

import {useSelector} from 'react-redux';
import {selectArea, selectChecking, selectCredencialesArea, selectCredencialesName, selectCredencialesRole} from '../redux/credencialesReducer.js';
import {MarketingView} from './viewsHome/Marketing/MarketingView.jsx';
import {ExtractEmails} from './viewsHome/Marketing/ExtractEmails.jsx';
import {UseChargeData} from './viewsHome/Marketing/UseChargeData.jsx';
import {HomeDefault} from './viewsHome/home/HomeDefault.jsx';
import {Wrap} from './viewsHome/wrap/Wrap.jsx';
export const Home = () => {
	const {instance, accounts} = useMsal();
	const name = accounts[0]?.name;
	const email = accounts[0]?.username;
	const [emailAsesor, setEmailAsesor] = useState(email);
	const isloading = useSelector(selectChecking);

	const {view, params} = UseChargeData(emailAsesor);

	if (isloading) {
		return (
			<div className="loader-container">
				<Loader size="large" type={'converging-spinner'} />
			</div>
		);
	}
	if (
//Este será un home especifico para los lideres de Sales que puedan ver todos los dashboards de Ventas
		(email === 'pere803479@siigo.com' && emailAsesor === 'pere803479@siigo.com') ||
        (email==='muno800314@siigo.com' && emailAsesor==='muno800314@siigo.com')||
        (email==='gome804598@siigo.com'&& emailAsesor==='gome804598@siigo.com')
	) {
		return (
			<HomeDefault name={name} setEmailAsesor={setEmailAsesor} flagLider={true}/>
		);
	}

	//<HomeDefault email={email} name={name} setEmailAsesor={setEmailAsesor} />;

	return (
		<div>
			{view === 'default' ? (
				<HomeDefault name={name} flagLider={false} />
			) : (
				<Wrap name={name} view={view} nameAsesor={params} />
			)}
		</div>
	);
};
