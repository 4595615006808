import {Link, Outlet} from 'react-router-dom';
import './adminpage.css';
import * as FaIcons from 'react-icons/fa';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {useState} from 'react';

const Reportes = () => {
	return <div>Este es el subcomponente de Reportes</div>;
};

const Botones = () => {
	return <div>Este componente es para Configurar los accesos a los reportes</div>;
};
const AdminPage = () => {
	const [value, setValue] = useState(0);

	return (
    <div className="AdminPage-container">
      <h1>Access Configuration Modules</h1>
    <ul className="tabs">
      <li className="tab">
      <Link to={`AccessModule`} icon={<FaIcons.FaLayerGroup />}>
						{'Buscar usuario'}
					</Link>
      </li>
      <li className="tab">
      <Link to={`botones`} icon={<FaIcons.FaLayerGroup />}>
						{'Buscar Reporte'}
					</Link>
      </li>
    </ul>
    <Outlet></Outlet>
  </div>
	);
};

export default AdminPage;
