// import css
import './style.css';
import axios from 'axios'
import { Fade } from "@progress/kendo-react-animation";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Grid, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

// import react
import React, { useEffect } from 'react';

function UploadCenter({ fileStructures }) {
    const [csvFile, setCsvFile] = React.useState(null);
    const [jsonData, setJsonData] = React.useState(null);
    const [fileStructureMsg, setFileStructureMsg] = React.useState(null);
    const [validFileStructure, setValidFileStructure] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [notification, setNotification] = React.useState({
        notification: false,
        notificationType: 'success',
        notificationMessage: '',
    });

    // const fileStructures = {
    //     "Horarios": {
    //         "cols": ['Pais', 'Fecha', 'Cedula', 'Nombre', 'Hr Inicio', 'Hr Fin', 'Desc 1', 'Desc 2', 'H Ini Alm', 'H Fin Alm', 'Area', 'Email', 'IN Break 1', 'FIN Break 1', 'IN Break 2', 'FIN Break 2'],
    //         "endPoint": "/api/siignalUpdateSchedule",
    //         "handleUpload": (data) => {
    //             return data
    //         }
    //     },
    // }

    const findKeyByValue = (fileStructures, inputArray) => {
        for (const key in fileStructures) {
            console.log(key)
            if (fileStructures[key].cols.every(value => inputArray.includes(value))) {
                return key;
            }
        }
        return null; // return null if no matching key is found
    }


    const parseCsv = (csvString) => {

        const rows = csvString.split('\n');
        const headers = rows[0].split(',');

        let data = [];
        for (let i = 1; i < rows.length; i++) {
            const row = rows[i];
            if (row.trim() === '') continue; // Skip empty rows

            let rowData = [];
            let insideQuotes = false;
            let currentCell = '';

            for (let j = 0; j < row.length; j++) {
                const char = row[j];

                if (char === '"') {
                    insideQuotes = !insideQuotes;
                } else if (char === ',' && !insideQuotes) {
                    rowData.push(currentCell.trim());
                    currentCell = '';
                } else {
                    currentCell += char;
                }
            }

            rowData.push(currentCell.trim());
            data.push(rowData);
        }

        console.log("###########")
        // Replace \r or \t from each header and handle accent vowels
        headers.forEach((el, i) => {
            headers[i] = el
                .replace(/\r/g, '')
                .replace(/\t/g, '')
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .trim();
        });

        data = data.map((row) => {
            return row.reduce((acc, curr, i) => {
                // Handle accent vowels in row values
                const normalizedValue = curr
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '');
                acc[headers[i]] = normalizedValue;
                return acc;
            }, {});
        });

        return data;
    };

    const handleUpload = () => {
        // setLoading(true);
        const fileConfig = fileStructures[validFileStructure]
        console.log("%%%%%%%%%%")
        console.log(fileConfig.handleUpload)
        fileConfig.handleUpload(jsonData, fileConfig.campaign)
        // console.log("***********")

        // const fileUpload = async () => {
        //     const response = await axios.patch(fileConfig.endPoint, processedData);
        setNotification({
            notification: true,
            notificationType: "success",
            notificationMessage: "File Uploaded",
        })
        //     setLoading(false);
        // }
        // fileUpload();
    };

    useEffect(() => {
        if (csvFile) {
            const reader = new FileReader();
            reader.onload = async (event) => {
                const csv = event.target.result;
                let jsonRawData = parseCsv(csv);
                let jsonFixedData = []
                jsonRawData.map(el => {
                    if (el.Pais != "") {
                        jsonFixedData.push(el)
                    }
                })
                setJsonData(jsonFixedData);
                console.log(Object.keys(jsonFixedData[0]))
                const fileStructure = findKeyByValue(fileStructures, Object.keys(jsonFixedData[0]));
                setValidFileStructure(fileStructure);
                if (fileStructure != null) {
                    setFileStructureMsg(`Cargaste un archivo que corresponde a ${fileStructure}:`);
                } else {
                    setFileStructureMsg(`El archivo cargado no tiene una estructura válida`);
                }
            };
            reader.readAsText(csvFile, 'ISO-8859-1');
        }
    }, [csvFile]);

    const handleFileSelect = (event) => {
        setCsvFile(event.target.files[0]);
    };

    return (

        loading ? <>Cargando</> : <>
            <>
                <h4>Carga un archivo para continuar</h4>
                <p>
                    El archivo debe tener la estructura correcta, de lo contrario no se podrá cargar.
                    <br />
                    La estructura la consigues usando el boton <b>Plantilla</b>.
                </p>
            </>
            <Grid container spacing={3}>
                <Grid xs={1}>
                </Grid>
                <Grid xs={10}>
                    <form noValidate autoComplete="off">
                        <label id="csvFileUpload" className='siignalFileUploadCenterLabel'>
                            <UploadFileIcon sx={{ fontSize: 60 }} />
                            <br />
                            Cargar archivo CSV:
                            <input accept='.csv' id='fileUpload' type='file' onChange={handleFileSelect} />
                        </label>
                    </form>
                </Grid>
            </Grid>

            <Grid container spacing={3} alignItems="center">
                <Grid item xs={12}>
                    {
                        (jsonData != null) ? <>
                            <h4>
                                {fileStructureMsg}
                            </h4>

                            <TableContainer>
                                <Table stickyHeader sx={{ minWidth: 700 }} size="small" >
                                    <TableHead>
                                        <TableRow>
                                            {
                                                Object.keys(jsonData[0]).map((el, i) => {
                                                    return <TableCell key={i} className='uploadCenterCell'>{el}</TableCell>
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            jsonData.slice(0, 10).map((el, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        {
                                                            Object.keys(el).map((el2, i2) => {
                                                                return <TableCell key={i2} className='uploadCenterCell'>{el[el2]}</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br />
                            {
                                (validFileStructure != null) ? <h4>
                                    <Button onClick={handleUpload} variant="contained" color="primary">Confirmar y cargar</Button>
                                </h4> : <h4>
                                    <Button disabled variant="contained" color="primary">Confirmar y cargar</Button>
                                </h4>
                            }
                            
                        </> : null
                    }
                </Grid>
            </Grid>
            <NotificationGroup style={{
                style: "success", 
                icon: true,
                right: 0,
                bottom: 0,
                alignItems: 'flex-start',
                flexWrap: 'wrap-reverse'
            }}>
                <Fade>
                    {notification.notification && <Notification type={{
                        style: notification.notificationType,
                        icon: true
                    }} closable={false}>
                        <span>{notification.notificationMessage}</span>
                    </Notification>}
                </Fade>
            </NotificationGroup>
        </>
    );
}
export default UploadCenter;