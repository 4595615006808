import * as React from 'react';

import {
    Sparkline,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartArea
} from '@progress/kendo-react-charts';
import { Badge } from '@progress/kendo-react-indicators';
import {
    useInternationalization
} from '@progress/kendo-react-intl';

import { images } from './images';

export const FullNameCell = (props) => {

    const customerName = {
        display: 'inline-block',
        verticalAlign: 'middle',
        lineHeight: '25px',
        paddingLeft: '5px',
        fontSize:'12px'
    };

    if (props.rowType === 'groupHeader') {
        return null;
    }

    return (
        <td>
            <div style={customerName}>{ props.dataItem.nombreCapacitacion }</div>
        </td>
    );
};

export const TrainerNameCell = (props) => {

    const customerName = {
        display: 'inline-block',
        verticalAlign: 'middle',
        lineHeight: '25px',
        paddingLeft: '5px',
        fontSize:'12px'
    };

    if (props.rowType === 'groupHeader') {
        return null;
    }

    return (
        <td>
            <div style={customerName}>{ props.dataItem.nombreEntrenador }</div>
        </td>
    );
};

export const StartDateCell = (props) => {

    const customerName = {
        display: 'inline-block',
        verticalAlign: 'middle',
        lineHeight: '25px',
        paddingLeft: '5px',
        fontSize:'12px'
    };

    const dateStartCell=new Date(props.dataItem.fechaIncioCapacitacion)
    const dateStartFormatted=Intl.DateTimeFormat("es", {dateStyle:"short"}).format(dateStartCell)

    if (props.rowType === 'groupHeader') {
        return null;
    }

    return (
        <td>
            <div style={customerName}>{dateStartFormatted}</div>
        </td>
    );
};

export const TopicsDateCell = (props) => {

    const customerName = {
        display: 'inline-block',
        verticalAlign: 'middle',
        lineHeight: '25px',
        paddingLeft: '5px',
    };

    const dateTopicCell=new Date(props)
    const dateTopicFormatted=Intl.DateTimeFormat("en", {dateStyle:"short"}).format(dateTopicCell)

    if (props.rowType === 'groupHeader') {
        return null;
    }

    return (
        <td>
            <div style={customerName}>{dateTopicFormatted}</div>
        </td>
    );
};

export const EndDateCell = (props) => {

    const customerName = {
        display: 'inline-block',
        verticalAlign: 'middle',
        lineHeight: '25px',
        paddingLeft: '5px',
        fontSize:'12px'
    };
    const dateEndCell=new Date(props.dataItem.fechaFinCapacitacion)
    const dateEndFormatted=Intl.DateTimeFormat("es", {dateStyle:"short"}).format(dateEndCell)

    if (props.rowType === 'groupHeader') {
        return null;
    }

    return (
        <td>
            <div style={customerName}>{dateEndFormatted}</div>
        </td>
    );
};

export const TypeCell = (props) => {

    const customerName = {
        display: 'inline-block',
        verticalAlign: 'middle',
        lineHeight: '25px',
        paddingLeft: '5px',
        fontSize:'12px'
    };

    if (props.rowType === 'groupHeader') {
        return null;
    }

    return (
        <td>
            <div style={customerName}>{ props.dataItem.tipoCapacitacion }</div>
        </td>
    );
};

export const AreaCell = (props) => {

    const customerName = {
        display: 'inline-block',
        verticalAlign: 'middle',
        lineHeight: '25px',
        paddingLeft: '5px',
        fontSize:'12px'
    };

    if (props.rowType === 'groupHeader') {
        return null;
    }

    return (
        <td>
            <div style={customerName}>{ props.dataItem.area }</div>
        </td>
    );
};


export const FlagCell = (props) => {
    if (props.rowType === 'groupHeader') {
        return null;
    }

    return (
        <td style={{textAlign: 'center'}}>
            <img
                src={images[props.dataItem.pais]}
                style={{width: 30, verticalAlign: 'middle', borderStyle: 'none'}}
                alt={props.dataItem.pais}
            />
        </td>
    );
};

export const RatingCell = (props) => {
    const MAX_STARS = 5;
    const rating = props.dataItem.rating;

    if (props.rowType === 'groupHeader') {
        return null;
    }

    return (
        <td>
            {
                [...new Array(MAX_STARS)].map((_, idx) => {
                    const isActive = rating <= idx;
                    return (
                        <span
                            key={idx}
                            className={!isActive ? 'k-icon k-i-star' : 'k-icon k-i-star-outline'}
                            style={!isActive ? {color: '#ffa600'} : undefined}
                        />
                    );
                })
            }
        </td>
    );
};

export const OnlineCell = (props) => {
    const badgeStyle = {
        position: 'relative',
        display: 'inline'
    };

    if (props.rowType === 'groupHeader') {
        return null;
    }

    return (
        <td style={{textAlign: 'center'}}>
            
            {
                props.dataItem.estado === true ?
                    <Badge themeColor={'success'} shape={'rounded'} fillMode={'outline'} style={badgeStyle}>Activo</Badge> :
                    <Badge themeColor={'light'} shape={'rounded'} style={badgeStyle}>Inactivo</Badge>
            }
        </td>
    );
};

export const ActiveCell = (props) => {
    const badgeStyle = {
        position: 'relative',
        display: 'inline',
        fontSize: '14px'
    };

    if (props.rowType === 'groupHeader') {
        return null;
    }

    return (
        <td style={{textAlign: 'center'}}>
            
            {
                props.dataItem.estado === true ?
                    <Badge themeColor={'success'} shape={'rounded'} style={badgeStyle} fill={'outline'}>Activo</Badge> :
                    <Badge themeColor={'light'} shape={'rounded'} style={badgeStyle}>Inactivo</Badge>
            }
        </td>
    );
};

export const Participo = (props) => {
    const {
        dataItem
      } = props;
    let checkView
    const badgeStyle = {
        position: 'relative',
        display: 'inline',
        fontSize: '10px'
    };

    if (props.rowType === 'groupHeader') {
        return null;
    }

    if (props.dataItem.participo === true) {
        checkView=<Badge themeColor={'success'} shape={'rounded'} style={badgeStyle} cutoutBorder={true}><span className="k-icon k-i-check" /></Badge>}
    else if (props.dataItem.participo === false) {
        checkView=<Badge themeColor={'light'} shape={'rounded'} style={badgeStyle}>Inactivo</Badge>}
    else if (props.dataItem.participo === null) {}

    const {viewParticipo}= checkView
    return (
        <td style={{textAlign: 'center'}}>
            
            {dataItem.inEdit ? ""
                : viewParticipo
            }
        </td>
    );
};

export const Desertor = (props) => {
    let checkView
    const badgeStyle = {
        position: 'relative',
        display: 'inline',
        fontSize: '10px'
    };

    if (props.rowType === 'groupHeader') {
        return null;
    }

    if (props.dataItem.deserto === true) {
        checkView=<Badge themeColor={'error'} shape={'rounded'} style={badgeStyle} cutoutBorder={true}><span className="k-icon k-i-minus" /></Badge>}
    else if (props.dataItem.deserto === false) {checkView=""}
    else if (props.dataItem.desero === null) {}

    return (
        <td style={{textAlign: 'center'}}>
            
            {
                checkView
            }
        </td>
    );
};

export const EngagementCell = (props) => {
    if (props.rowType === 'groupHeader') {
        return null;
    }

    return (
        <td>
            <Sparkline
                type={'bar'}
                data={props.dataItem.target}
            >
                <ChartArea opacity={0} width={200} />
                <ChartValueAxis visible={false} >
                    <ChartValueAxisItem min={0} max={130} />
                </ChartValueAxis>
            </Sparkline>
        </td>
    );
};

export const CurrencyCell = (props) => {
    const redBoldStyle = {
        color: '#d9534f',
        fontWeight: 600
    };

    const intlService = useInternationalization();

    if (props.rowType === 'groupHeader') {
        return null;
    }

    return (
        <td>
             <span style={props.dataItem.budget < 0 ? redBoldStyle : undefined}>{ intlService.formatNumber(props.dataItem.budget, 'c') }</span>
        </td>
    );
};