import React, { useEffect, useState } from 'react';
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import axios from 'axios';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import './ShiftNovelties.css';
import { responseData, cuposDisponibles } from './fakeHttp';
import { Loading } from '../Siignal/Loading/Loading';

const ShiftNovelties = () => {
    useEffect(() => {
        getData()
    }, [])

    const [shiftNoveltiesTableTemp, setShiftNoveltiesTableTemp] = useState({})
    const [shiftNoveltiesTable, setShiftNoveltiesTable] = useState({})
    const [registeredNovelties, setRegisteredNovelties] = useState({})
    const [basePersonal, setBasePersonal] = useState({})

    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));

    const [availablePais, setAvailablePais] = useState([])
    const [selectedPais, setSelectedPais] = useState(0)
    const [availableAreas, setAvailableAreas] = useState([])
    const [selectedArea, setSelectedArea] = useState(0)
    const [availableLiders, setAvailableLiders] = useState([])
    const [selectedLider, setSelectedLider] = useState(0)


    const [availableFilter, setAvailableFilter] = useState([])
    const [selectedFilter, setSelectedFilter] = useState(0)

    const [totalNovedades, setTotalNovedades] = useState(0)

    const [availableCupos, setAvailableCupos] = useState(0)

    const timeToDate = (time) => {
        let result = 0

        if (time.length <= 5) {
            time = time + ":00"
        }

        let fixedTime = ("00" + time).slice(-8)
        let date = new Date(`${selectedDate}T${fixedTime}-05:00`)
        // check if date is valid
        if (date instanceof Date && !isNaN(date)) {
            return date
        } else {
            return null
        }
    }


    useEffect(() => {
        let totalData = {
            data: shiftNoveltiesTableTemp.data,
            minTime: shiftNoveltiesTableTemp.minTime,
            maxTime: shiftNoveltiesTableTemp.maxTime
        }

        if (totalData.data == undefined) {
            return;
        }

        if (totalData.data == undefined) {
            return;
        }

        if (availableFilter[selectedFilter] == 'Todo') {
            setShiftNoveltiesTable(totalData)
            setTotalNovedades("estos son los horarios de todos los agentes")
        } else {
            let pais = availableFilter[selectedFilter].split(" - ")[0]
            let area = availableFilter[selectedFilter].split(" - ")[1]
            let lider = availableFilter[selectedFilter].split(" - ")[2]

            totalData.data = Object.values(totalData.data).filter((item) => {
                return item.pais == pais && item.area == area && item.lider == lider
            })

            let totalinCelula = Object.values(shiftNoveltiesTableTemp.data).filter((item) => {
                return item.area == area
            })

            setAvailableCupos(Object.values(shiftNoveltiesTable.data))

            let tempCuposDisponibles = cuposDisponibles.filter(el => {
                return el.CELULA == area && el.FECHA == selectedDate
            })

            console.log(tempCuposDisponibles)

            if (tempCuposDisponibles.length > 0) {
                console.log(totalinCelula.length)
                setAvailableCupos(tempCuposDisponibles[0].CUPOS_DISPONIBLES)
            } else {
                setAvailableCupos(0)
            }

            const requiredAgentsForCellAndDate = cuposDisponibles.filter(el => {
                return el.CELULA == area && el.FECHA == selectedDate
            })

            let agentRequired = 0;
            if (requiredAgentsForCellAndDate.length > 0) {
                console.log(requiredAgentsForCellAndDate)
                agentRequired = requiredAgentsForCellAndDate[0].NECESARIOS;
            }

            console.log(agentRequired)
            console.log(basePersonal)

            let novedadesRegistradas = registeredNovelties.filter(el => {
                return el.area == area
            }).length

            console.log("Novedades Registradas: " + novedadesRegistradas)
            console.log("Agentes Requeridos: " + agentRequired)
            console.log("Agentes Disponibles: " + totalinCelula.length)

            setShiftNoveltiesTable(totalData)
            if (agentRequired == 0) {
                setTotalNovedades(`no hay cupos disponibles en ${area}`)
            } else {
                setTotalNovedades(` hay ${((totalinCelula.length - agentRequired) < 0) ? 0 : totalinCelula.length - agentRequired - novedadesRegistradas} cupos disponibles en ${area}`)
            }
        }
        // setShiftNoveltiesTable(totalData)

        setLoading(false)

    }, [selectedFilter])

    useEffect(() => {
        if (selectedDate == null) {
            return;
        }
        getData()
    }, [selectedDate])

    const getData = () => {
        console.clear()
        setLoading(true)
        axios
            .get(`/api/siignalGetShiftNoveltiesData/${selectedDate}`, {})
            .then((response) => {
                const responseData = response.data;

                const schedules = responseData.schedules;
                const headcount = responseData.headcount;
                const hcDimensionamiento = responseData.hcDimensionamiento
                const noveltiesReal = responseData.novelties[0].data;
                const basePersonal = responseData.basePersonal[0].data;
                const noveltiesSimulated = []


                setBasePersonal(basePersonal)

                let tempTest = noveltiesReal.map(el => {
                    let tempPersonal = basePersonal.find((agent) => agent.IDENTIFICACION === el.IDENTIFICACION_NACIONAL)
                    if (tempPersonal != null && tempPersonal != undefined) {
                        el.area = tempPersonal.SUBAREA
                        el.lider = tempPersonal["JEFE INMEDIATO"]
                    }
                    return el;
                })

                setRegisteredNovelties(tempTest)


                let paisAreaLider = []

                let tempTotalNovedades = 0;

                let tempShiftNoveltiesTable = {}
                let minTime = new Date(`${selectedDate}T23:59:59`)
                let maxTime = new Date(`${selectedDate}T00:00:00`)

                // schedules.slice(0, 2).forEach((schedule) => {
                schedules.forEach((schedule) => {
                    let startTime = timeToDate(schedule['Hr Inicio'])
                    let endTime = timeToDate(schedule['Hr Fin'])


                    if (startTime != null && endTime != null) {

                        // check if start and end time is not 00:00:00
                        if (startTime.getHours() !== 0 && endTime.getHours() !== 0) {

                            let agentHc = basePersonal.find((agent) => agent.CORREOELECTRONICO === schedule.Email)

                            // console.log(schedule)
                            if (startTime < minTime) {
                                minTime = startTime
                            }
                            if (endTime > maxTime) {
                                maxTime = endTime
                            }

                            let thisSchedule = {
                                startTime: startTime,
                                endTime: endTime,
                                break1StartTime: timeToDate(schedule['IN Break 1']),
                                break1EndTime: timeToDate(schedule['FIN Break 1']),
                                break2StartTime: timeToDate(schedule['IN Break 2']),
                                break2EndTime: timeToDate(schedule['FIN Break 2']),
                                lunchStartTime: timeToDate(schedule['H Ini Alm']),
                                lunchEndTime: timeToDate(schedule['H Fin Alm']),
                                name: schedule.Nombre,
                                email: schedule.Email,
                                id: schedule.Cedula,
                                pais: agentHc['PAIS AL QUE LABORA'],
                                area: agentHc.SUBAREA,
                                lider: agentHc["JEFE INMEDIATO"],
                                type: "schedule"
                            }

                            thisSchedule.key = `${thisSchedule.pais}${thisSchedule.area}${thisSchedule.lider}${thisSchedule.name}${thisSchedule.email}`

                            paisAreaLider.push(`${thisSchedule.pais} - ${thisSchedule.area} - ${thisSchedule.lider}`)

                            tempShiftNoveltiesTable[thisSchedule.email] = thisSchedule
                        }
                    }
                })

                noveltiesReal.forEach((novelty) => {
                    if (novelty.UNIDAD_DE_MEDIDA_DURACION == "Dias" && novelty.ESTADO_DE_APROBACION_DE_LA_AUSENCIA == "Aprobado") {
                        let agentHc = basePersonal.find((agent) => agent.IDENTIFICACION === novelty.IDENTIFICACION_NACIONAL)

                        if (agentHc != null && agentHc != undefined) {


                            let thisNovelty = {
                                startTime: minTime,
                                endTime: maxTime,
                                break1StartTime: null,
                                break1EndTime: null,
                                break2StartTime: null,
                                break2EndTime: null,
                                lunchStartTime: null,
                                lunchEndTime: null,
                                name: novelty.NOMBRE,
                                email: agentHc.CORREOELECTRONICO,
                                id: agentHc.IDENTIFICACION,
                                pais: agentHc['PAIS AL QUE LABORA'],
                                area: agentHc.SUBAREA,
                                lider: agentHc["JEFE INMEDIATO"],
                                type: "novelty",
                                label: novelty.TIPO_DE_AUSENCIA
                            }

                            thisNovelty.key = `${thisNovelty.pais}${thisNovelty.area}${thisNovelty.lider}${thisNovelty.name}${thisNovelty.email}`

                            paisAreaLider.push(`${thisNovelty.pais} - ${thisNovelty.area} - ${thisNovelty.lider}`)

                            tempShiftNoveltiesTable[agentHc.CORREOELECTRONICO] = thisNovelty

                            tempTotalNovedades++

                        } else {
                            agentHc = headcount.find((agent) => agent.Agente === novelty.WORKER_NUMBER)
                            if (agentHc != null && agentHc != undefined) {

                            } else {
                                console.log(novelty.NOMBRE + " - Not Found")
                                return;
                            }
                        }
                    }
                })

                console.log(basePersonal.slice(0, 3))

                // Add one hour to maxTime
                maxTime.setHours(maxTime.getHours() + 1)

                // reduce one hour to minTime
                minTime.setHours(minTime.getHours() - 1)

                console.log({
                    data: tempShiftNoveltiesTable,
                    minTime: minTime,
                    maxTime: maxTime
                })

                setShiftNoveltiesTable({
                    data: tempShiftNoveltiesTable,
                    minTime: minTime,
                    maxTime: maxTime
                })

                setShiftNoveltiesTableTemp({
                    data: tempShiftNoveltiesTable,
                    minTime: minTime,
                    maxTime: maxTime
                })

                let orderedList = [...new Set(paisAreaLider)].sort()
                setAvailableFilter(["Todo", ...orderedList])

                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        // console.log(shiftNoveltiesTable)
    }, [shiftNoveltiesTable])

    const getNearestHour = (date, next = false) => {
        const nearestHour = new Date(date);
        nearestHour.setMinutes(0, 0, 0);
        nearestHour.setHours(nearestHour.getHours() + (next ? 1 : 0));
        return nearestHour;
    }

    const generateLines = () => {
        const lines = [];
        const start = getNearestHour(shiftNoveltiesTable.minTime);
        const end = getNearestHour(shiftNoveltiesTable.maxTime, true);
        const hourDiff = (end.getTime() - start.getTime()) / 1000 / 60 / 60;
        const quarterDiff = hourDiff * 4;
        const hourMillis = 60 * 60 * 1000;
        const quarterHourMillis = 15 * 60 * 1000;

        for (let i = 0; i <= quarterDiff; i++) {
            const time = new Date(start.getTime() + i * quarterHourMillis);
            const linePercentaje = calculateScheduleBlockPercentage(shiftNoveltiesTable.minTime, shiftNoveltiesTable.maxTime, time, time)
            lines.push(
                <line
                    key={`quarterIntervalLine${i}`}
                    x1={numberToSvgPercentage(linePercentaje.start)}
                    y1="0%"
                    x2={numberToSvgPercentage(linePercentaje.start)}
                    y2="100%"
                    stroke="#858585"
                    strokeWidth="1"
                // strokeDasharray="4"
                />
            );
        }

        for (let i = 0; i <= hourDiff; i++) {
            const time = new Date(start.getTime() + i * hourMillis);
            const linePercentaje = calculateScheduleBlockPercentage(shiftNoveltiesTable.minTime, shiftNoveltiesTable.maxTime, time, time)
            lines.push(
                <line
                    key={`hourIntervalLine${i}`}
                    x1={numberToSvgPercentage(linePercentaje.start)}
                    y1="0%"
                    x2={numberToSvgPercentage(linePercentaje.start)}
                    y2="100%"
                    stroke="#000000"
                    strokeWidth="1"
                // strokeDasharray="5,5"
                />
            );
        }
        return lines;
    }

    const generateHourTexts = () => {
        const hourLabels = [];
        const start = getNearestHour(shiftNoveltiesTable.minTime);
        const end = getNearestHour(shiftNoveltiesTable.maxTime, true);
        const hourDiff = (end.getTime() - start.getTime()) / 1000 / 60 / 60;
        const hourMillis = 60 * 60 * 1000;

        for (let i = 0; i <= hourDiff; i++) {
            const time = new Date(start.getTime() + i * hourMillis);
            const linePercentaje = calculateScheduleBlockPercentage(shiftNoveltiesTable.minTime, shiftNoveltiesTable.maxTime, time, time)
            hourLabels.push(
                <text x={numberToSvgPercentage(linePercentaje.start)} y={numberToSvgPercentage(50)} fontSize={12} fill="#808080" fontWeight={800}>
                    {" "}{time.toLocaleTimeString([], { hour: '2-digit' }).replace(" ", "")}
                </text>
            );
        }
        return hourLabels;
    }


    const numberToSvgPercentage = (number) => `${number}%`;

    function calculateScheduleBlockPercentage(schedStart, schedEnd, activityStart, activityEnd) {
        const shiftDuration = schedEnd.getTime() - schedStart.getTime();
        const activityDuration = activityEnd.getTime() - activityStart.getTime();

        const startDiff = Math.max(activityStart.getTime(), schedStart.getTime()) - schedStart.getTime();
        const endDiff = schedEnd.getTime() - Math.min(activityEnd.getTime(), schedEnd.getTime());

        const startPercentage = (startDiff / shiftDuration) * 100;
        const endPercentage = ((shiftDuration - endDiff) / shiftDuration) * 100;

        return {
            start: startPercentage.toFixed(2),
            end: (endPercentage - startPercentage).toFixed(2)
        };
    }

    const renderScheduleSVG = (startTime, endTime, label, color, scheduleBlocks) => {
        let chartStartTimestamp = shiftNoveltiesTable.minTime
        let chartEndTimestamp = shiftNoveltiesTable.maxTime
        let totalChartLength = chartEndTimestamp - chartStartTimestamp

        let barStartTime = startTime.getTime()
        let barEndTime = endTime.getTime()
        let totalBarLength = barEndTime - barStartTime
        let barStartAndEnd = calculateScheduleBlockPercentage(chartStartTimestamp, chartEndTimestamp, startTime, endTime)

        let startPercentage = ((barStartTime - chartStartTimestamp) / totalChartLength) * 100
        let endPercentage = ((barEndTime - chartStartTimestamp) / totalChartLength) * 100

        // Calculate SVG dimensions
        const svgHeight = 100;
        const barHeight = 100;
        const scheduleHeight = 100;

        return (
            <svg width="100%" height={numberToSvgPercentage(svgHeight)}>
                {/* Shcedule full bar */}
                <rect
                    x={numberToSvgPercentage(barStartAndEnd.start)}
                    y={numberToSvgPercentage(svgHeight / 2 - barHeight / 2)}
                    width={numberToSvgPercentage(barStartAndEnd.end)}
                    height={numberToSvgPercentage(barHeight)}
                    fill={color}>
                    <title>
                        Start: {startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                        {" -"}
                        End: {endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    </title>
                </rect>
                {
                    scheduleBlocks.map((block, index) => {
                        if (block.start != null && block.end != null) {
                            const blockStartAndEnd = calculateScheduleBlockPercentage(chartStartTimestamp, chartEndTimestamp, block.start, block.end)
                            return (
                                <>
                                    <rect x={numberToSvgPercentage(blockStartAndEnd.start)}
                                        y={numberToSvgPercentage(svgHeight / 2 - scheduleHeight / 2)}
                                        width={numberToSvgPercentage(blockStartAndEnd.end)}
                                        height={numberToSvgPercentage(scheduleHeight)} fill={block.color}>
                                        <title>
                                            {block.label}
                                            {" -"}
                                            {block.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                            {" - "}
                                            {block.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                        </title>
                                    </rect>
                                </>
                            )
                        } else {
                            return null
                        }
                    })
                }
                {generateLines()}

                <text x={numberToSvgPercentage(50)} y={numberToSvgPercentage(60)} textAnchor="middle" stroke="red">
                    {label}
                </text>

                {generateHourTexts()}

            </svg>
        );
    };

    const getColorForScheduleChart = (item) => {
        let colorMatrix = {
            "schedule": "#130be8",
            "novelty": "#f39a9a"
        }
        return colorMatrix[item.type]
    }
    return (

        (loading) ? <Loading /> : <>
            <GridLayout
                px={2}
                pt={3}
                mx={10}
                gap={{
                    cols: 10,
                    rows: 10
                }}
                cols={[
                    {
                        width: "24%",
                    }, {
                        width: "24%",
                    }, {
                        width: "24%",
                    }, {
                        width: "24%",
                    }
                ]}>

                <GridLayoutItem row={1} col={1} colSpan={2}>
                    <div
                        style={{ padding: '16px' }}>
                        <FormControl xs={12} className="select">
                            <TextField
                                label="Fecha"
                                value={selectedDate}
                                onChange={(ev) => {
                                    setLoading(true)
                                    setSelectedDate(ev.target.value)
                                }}
                                type={"date"}
                            />
                        </FormControl>
                    </div>
                </GridLayoutItem>

                <GridLayoutItem row={1} col={3} colSpan={2} >
                    <div
                        style={{ padding: '16px' }}>
                        <FormControl xs={12}
                            className="select">
                            <InputLabel>Pais:</InputLabel>
                            <Select
                                labelId={"labelForArea"}
                                id={"labelForArea"}
                                value={selectedFilter}
                                label="Area"
                                onChange={(event, selectedFilter) => {
                                    setLoading(true)
                                    setSelectedFilter(event.target.value)
                                }}
                            >
                                {
                                    availableFilter.map((option, index) => {
                                        return <MenuItem key={"pais" + index} value={index}>{option}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                </GridLayoutItem>

                <GridLayoutItem row={2} col={1} colSpan={4} style={{ textAlign: 'center' }}>
                    Para el dia {selectedDate} {totalNovedades}.
                </GridLayoutItem>

                <GridLayoutItem row={3} col={1} colSpan={4}>

                    <table className='shiftNovelties '>
                        <colgroup>
                            <col style={{ width: "6%" }} />
                            <col style={{ width: "8%" }} />
                            <col style={{ width: "14%" }} />
                            <col style={{ width: "14%" }} />
                            <col style={{ width: "62%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>Pais</th>
                                <th>Area</th>
                                <th>Lider</th>
                                <th>Agente</th>
                                <th>Horarios</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(shiftNoveltiesTable.data).map((key, index) => {
                                    const item = shiftNoveltiesTable.data[key]
                                    return (
                                        <tr key={index}>
                                            <td>{item.pais}</td>
                                            <td>{item.area}</td>
                                            <td>{item.lider}</td>
                                            <td>{item.name}</td>
                                            <td>
                                                <div className='svgCont'>
                                                    {

                                                        renderScheduleSVG(
                                                            item.startTime,
                                                            item.endTime,
                                                            item.label,
                                                            getColorForScheduleChart(item),
                                                            [
                                                                { start: item.break1StartTime, end: item.break1EndTime, color: '#958d8d', label: 'Break 1' },
                                                                { start: item.break2StartTime, end: item.break2EndTime, color: '#958d8d', label: 'Break 2' },
                                                                { start: item.lunchStartTime, end: item.lunchEndTime, color: '#22c3f2', label: 'Almuerzo' },
                                                            ],
                                                            item
                                                        )
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </GridLayoutItem>
            </GridLayout >
        </>

    );
};

export { ShiftNovelties };
