import React, {useState, useEffect, useRef} from 'react';
import Navbar from '../Navbar/Navbar';
import './Topsection.css';
import * as FaIcons from 'react-icons/fa';
import {useMsal} from '@azure/msal-react';
import logo from '../../assets/logo/logo_white.png';
import {CSSTransition} from 'react-transition-group';
import {Login} from '@microsoft/mgt-react';
import {Providers, ProviderState} from '@microsoft/mgt-element';
import {NavLink, Link, useNavigate} from 'react-router-dom';

function handleLogout(instance) {
	instance.logoutRedirect().catch((e) => {
		console.error(e);
	});
}

function TopNavbar(props) {
	return (
		<nav className="container-top-navbar">
			<div className="logo">
				<img href="www.siigo.com" src={logo} />
			</div>
			<div className="title">
				<h4>| DataSmart</h4>
			</div>
			<ul className="container-top-navbar-right">{props.children}</ul>
		</nav>
	);
}

function TopNavbarItems(props) {
	const [open, setOpen] = useState(false);

	return (
		<li className="top-navbar-item">
			<a href="#" className="icon-item" onClick={() => setOpen(!open)}>
				{props.icon}
			</a>

			{open && props.children}
		</li>
	);
}

function DropdownMenuUser() {
	const {instance, accounts} = useMsal();
	const [activeMenu, setActiveMenu] = useState('main');
	const [menuHeight, setMenuHeight] = useState(null);
	const dropdownRef = useRef(null);

	const name = accounts[0] && accounts[0].name;
	const email = accounts[0] && accounts[0].username;

	useEffect(() => {
		setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
	}, []);

	function calcHeight(el) {
		const height = el.offsetHeight;
		setMenuHeight(height);
	}

	function DropdownItem(props) {
		return (
			<a
				href="#"
				className="dropdown-menu-item"
				onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}>
				<span className="icon-button">{props.leftIcon}</span>
				<span className="text-button">{props.children}</span>
			</a>
		);
	}
	function HItem(props) {
    let navigate=useNavigate();
		function handleClick() {
    navigate(`adminPage/${props.nombre}`)


		}
		return (
			<a
				href="#"
				className="dropdown-menu-item"
				onClick={() => {
					handleClick();
				}}>
				<span className="icon-button">{props.leftIcon}</span>
				<span className="text-button">{props.children}</span>
			</a>
		);
	}

	return (
		<div className="dropdown" style={{height: menuHeight}} ref={dropdownRef}>
			<CSSTransition
				in={activeMenu === 'main'}
				timeout={500}
				classNames="menu-primary"
				unmountOnExit
				onEnter={calcHeight}>
				<div className="menu">
					<div className="dropdown-namecontainer">
						<div className="dropdown-header">{name}</div>
						<div className="dropdown-subheader">{email}</div>
					</div>
					<DropdownItem leftIcon={<FaIcons.FaUser />} goToMenu="settings">
						Administrar Cuenta
					</DropdownItem>
					<DropdownItem leftIcon={<FaIcons.FaFolderOpen />} goToMenu="AccessModule">
						Accesos a Reportes
					</DropdownItem>
					<a
						href="#"
						className="dropdown-menu-item"
						onClick={() => handleLogout(instance)}>
						<span className="icon-button">
							<FaIcons.FaSignOutAlt />
						</span>
						<span className="text-button">Sign Out</span>
					</a>
				</div>
			</CSSTransition>

			<CSSTransition
				in={activeMenu === 'settings'}
				timeout={500}
				classNames="menu-secondary"
				unmountOnExit
				onEnter={calcHeight}>
				<div className="menu">
					<DropdownItem goToMenu="main" leftIcon={<FaIcons.FaUser />}>
						<h2>My Tutorial</h2>
					</DropdownItem>
					<DropdownItem leftIcon={<FaIcons.FaUser />}>HTML</DropdownItem>
					<DropdownItem leftIcon={<FaIcons.FaUser />}>CSS</DropdownItem>
					<DropdownItem leftIcon={<FaIcons.FaUser />}>JavaScript</DropdownItem>
					<DropdownItem leftIcon={<FaIcons.FaUser />}>Awesome!</DropdownItem>
				</div>
			</CSSTransition>

			<CSSTransition
				in={activeMenu === 'AccessModule'}
				timeout={500}
				classNames="menu-secondary"
				unmountOnExit
				onEnter={calcHeight}>
				<div className="menu">
					<h4>Administrar Reportes</h4>
					{sessionStorage.getItem('role') === 'portalAdmin' ? (
						<>
							<HItem leftIcon={<FaIcons.FaLayerGroup />} nombre={'AccessModule'}>
								Reportes
							</HItem>
							<HItem leftIcon={<FaIcons.FaAlignCenter />} nombre={'AccessModule'}>
								Editar Botones
							</HItem>
						</>
					) : (
						<p>No tienes permisos</p>
					)}
				</div>
			</CSSTransition>
		</div>
	);
}



function DropdownMenuConfig() {
	const {instance, accounts} = useMsal();
	const [activeMenu, setActiveMenu] = useState('main');
	const [menuHeight, setMenuHeight] = useState(null);
	const dropdownRef = useRef(null);

	const name = accounts[0] && accounts[0].name;
	const email = accounts[0] && accounts[0].username;

	useEffect(() => {
		setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
	}, []);

	function calcHeight(el) {
		const height = el.offsetHeight;
		setMenuHeight(height);
	}

	function DropdownItem(props) {
		return (
			<a
				href="#"
				className="dropdown-menu-item"
				onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}>
				<span className="icon-button">{props.leftIcon}</span>
				<span className="text-button">{props.children}</span>
			</a>
		);
	}

	return (
		<div className="dropdown" style={{height: menuHeight}} ref={dropdownRef}>
			<CSSTransition
				in={activeMenu === 'main'}
				timeout={500}
				classNames="menu-primary"
				unmountOnExit
				onEnter={calcHeight}>
				<div className="menu">
					<div className="dropdown-namecontainer">
						<div className="dropdown-header-DS">Configuración</div>
					</div>
					<DropdownItem leftIcon={<FaIcons.FaUserPlus />} goToMenu="settings">
						Agregar Usuario
					</DropdownItem>

					<Link to="Users" className="dropdown-menu-item">
						<span className="icon-button">
							<FaIcons.FaUsers />
						</span>
						<span className="text-button"> Usuarios</span>
					</Link>
				</div>
			</CSSTransition>

			<CSSTransition
				in={activeMenu === 'settings'}
				timeout={500}
				classNames="menu-secondary"
				unmountOnExit
				onEnter={calcHeight}>
				<div className="menu">
					<DropdownItem goToMenu="main" leftIcon={<FaIcons.FaUser />}>
						<h2>My Tutorial</h2>
					</DropdownItem>
					<DropdownItem leftIcon={<FaIcons.FaUser />}>HTML</DropdownItem>
					<DropdownItem leftIcon={<FaIcons.FaUser />}>CSS</DropdownItem>
					<DropdownItem leftIcon={<FaIcons.FaUser />}>JavaScript</DropdownItem>
					<DropdownItem leftIcon={<FaIcons.FaUser />}>Awesome!</DropdownItem>
				</div>
			</CSSTransition>

			<CSSTransition
				in={activeMenu === 'animals'}
				timeout={500}
				classNames="menu-secondary"
				unmountOnExit
				onEnter={calcHeight}>
				<div className="menu">
					<DropdownItem goToMenu="main" leftIcon={<FaIcons.FaUser />}>
						<h2>Animals</h2>
					</DropdownItem>
					<DropdownItem leftIcon="🦘">Kangaroo</DropdownItem>
					<DropdownItem leftIcon="🐸">Frog</DropdownItem>
					<DropdownItem leftIcon="🦋">Horse?</DropdownItem>
					<DropdownItem leftIcon="🦔">Hedgehog</DropdownItem>
				</div>
			</CSSTransition>
		</div>
	);
}

export const Topsection = (inactive) => {
	const {instance} = useMsal();
	const [open, setOpen] = useState(false);
	return (
		<TopNavbar>
			<TopNavbarItems icon={<FaIcons.FaCogs />} onClick={() => setOpen(!open)}>
				<DropdownMenuConfig onClick={() => setOpen()}></DropdownMenuConfig>
			</TopNavbarItems>
			<TopNavbarItems icon={<FaIcons.FaBell />} />
			<TopNavbarItems icon={<FaIcons.FaUser />} onClick={() => setOpen(!open)}>
				<DropdownMenuUser></DropdownMenuUser>
			</TopNavbarItems>
		</TopNavbar>
	);
};
