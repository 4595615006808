// Open or create a database
function openDatabase(databaseName, version) {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(databaseName, version);

        request.onerror = event => {
            reject("Error opening database");
        };

        request.onsuccess = event => {
            const db = event.target.result;
            resolve(db);
        };

        request.onupgradeneeded = event => {
            const db = event.target.result;
            // Create an object store if it doesn't exist
            if (!db.objectStoreNames.contains('data')) {
                db.createObjectStore('data', { keyPath: 'id', autoIncrement: true });
            }
        };
    });
}

async function writeData(databaseName, data, customId = null) {
    const db = await openDatabase(databaseName, 1);
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(['data'], 'readwrite');
        const objectStore = transaction.objectStore('data');

        if (customId !== null) {
            const getRequest = objectStore.get(customId);
            getRequest.onsuccess = event => {
                const existingData = event.target.result;
                if (existingData) {
                    const putRequest = objectStore.put(data);
                    putRequest.onsuccess = () => {
                        resolve("Data updated successfully");
                    };
                    putRequest.onerror = () => {
                        reject("Error updating data");
                    };
                } else {
                    const addRequest = objectStore.add({ ...data, id: customId });
                    addRequest.onsuccess = () => {
                        resolve("Data added successfully");
                    };
                    addRequest.onerror = () => {
                        reject("Error adding data");
                    };
                }
            };
        } else {
            const addRequest = objectStore.add(data);
            addRequest.onsuccess = () => {
                resolve("Data added successfully");
            };
            addRequest.onerror = () => {
                reject("Error adding data");
            };
        }
    });
}

async function readData(databaseName, id) {
    const db = await openDatabase(databaseName, 1);
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(['data'], 'readonly');
        const objectStore = transaction.objectStore('data');

        const request = objectStore.get(id);

        request.onsuccess = event => {
            const result = event.target.result;
            if (result) {
                resolve({
                    message: "Data read successfully",
                    data: result,
                    code: "OK"
                });
            } else {
                resolve({
                    message: "No data found",
                    data: null,
                    code: "NOT_FOUND"
                })
            }
        };

        request.onerror = event => {
            reject("Error reading data");
        };
    });
}

async function deleteData(databaseName, id) {
    const db = await openDatabase(databaseName, 1);
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(['data'], 'readwrite');
        const objectStore = transaction.objectStore('data');

        const request = objectStore.delete(id);

        request.onsuccess = event => {
            resolve("Data deleted successfully");
        };

        request.onerror = event => {
            reject("Error deleting data");
        };
    });
}



export { writeData, readData, deleteData };