import "./Profile.css"
import axios from 'axios';
import './Profile.css';
import { React, Component } from 'react'
import { Grid, Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, CircularProgress, CardContent } from '@mui/material';
import { Chart, ChartSeries, ChartSeriesItem, ChartSeriesLabels } from '@progress/kendo-react-charts';
import { secondsToTime } from '../helperScripts'
import { Button } from "@progress/kendo-react-buttons";
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';

class Profile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            notProductiveStates: ['Not Ready', 'Break', 'Lunch', "Almuerzo", "Baño", "Break", "Pausas Activas", "System", "End Shift", "Logout"],
            loading: true,
            userEmail: sessionStorage.getItem('usr'),
            userName: sessionStorage.getItem('name'),
            scorecardsData: {},
            funnelData: null,
            totalTime: 0,
            chartHeight: 200,
            lastUpdated: new Date(),
            shouldDisableUpdateBtn: false,
            colors: [
                "#00244c", // dark
                "#5093c1", // clear
                "#004c7f", // dark
                "#72b7e8", // clear
                "#0072b1", // dark
                "#a7d7f1", // clear
                "#0095d9", // dark
                "#d5edfc", // clear
                "#00b4ff", // dark
                "#bfeaff", // clear
                "#4a90e2", // dark
                "#cfe2f3", // clear
                "#0077c3", // dark
                "#e6f3ff", // clear
                "#00619d"  // dark
            ]
        }

        this.interval = null;
    }

    componentDidMount() {
        this.generateComponentData();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    generateComponentData = () => {
        this.setState({
            ...this.state,
            shouldDisableUpdateBtn: true
        });
        Promise.all([
            axios.get(`/api/siignalGetAgentProfileStates/${this.state.userEmail}`, {}),
            axios.get(`/api/siignalGetAgentProfileTimes/${this.state.userEmail}`, {}),
        ]).then(([statesResponse, times]) => {
            let states = statesResponse.data[0];
            let statesData = states.documents;
            let timesData = times.data[0];
            let userProfile = true

            if (statesData.length == 0 || timesData.documents.length == 0) {
                userProfile = false
                statesData = [{ "AGENT_STATE_TIME": "00:25:59", "STATE": "After Call Work", "REASON_CODE": { "$numberDouble": "NaN" }, "AGENT_EMAIL": "dummyUser@siigo.com", "_id": "dummyUser@siigo.com_After Call Work" }, { "AGENT_STATE_TIME": "09:32:50", "STATE": "Login", "REASON_CODE": { "$numberDouble": "NaN" }, "AGENT_EMAIL": "dummyUser@siigo.com", "_id": "dummyUser@siigo.com_Login" }, { "AGENT_STATE_TIME": "00:00:06", "STATE": "Not Ready", "REASON_CODE": "After Call Work", "AGENT_EMAIL": "dummyUser@siigo.com", "_id": "dummyUser@siigo.com_Not Ready_After Call Work" }, { "AGENT_STATE_TIME": "01:04:19", "STATE": "Not Ready", "REASON_CODE": "Almuerzo", "AGENT_EMAIL": "dummyUser@siigo.com", "_id": "dummyUser@siigo.com_Not Ready_Almuerzo" }, { "AGENT_STATE_TIME": "00:09:35", "STATE": "Not Ready", "REASON_CODE": "Baño", "AGENT_EMAIL": "dummyUser@siigo.com", "_id": "dummyUser@siigo.com_Not Ready_Baño" }, { "AGENT_STATE_TIME": "00:32:12", "STATE": "Not Ready", "REASON_CODE": "Break", "AGENT_EMAIL": "dummyUser@siigo.com", "_id": "dummyUser@siigo.com_Not Ready_Break" }, { "AGENT_STATE_TIME": "00:17:16", "STATE": "Not Ready", "REASON_CODE": "Llamadas manuales", "AGENT_EMAIL": "dummyUser@siigo.com", "_id": "dummyUser@siigo.com_Not Ready_Llamadas manuales" }, { "AGENT_STATE_TIME": "00:00:45", "STATE": "Not Ready", "REASON_CODE": "Not Ready", "AGENT_EMAIL": "dummyUser@siigo.com", "_id": "dummyUser@siigo.com_Not Ready_Not Ready" }, { "AGENT_STATE_TIME": "05:57:30", "STATE": "On Call", "REASON_CODE": { "$numberDouble": "NaN" }, "AGENT_EMAIL": "dummyUser@siigo.com", "_id": "dummyUser@siigo.com_On Call" }, { "AGENT_STATE_TIME": "00:11:45", "STATE": "On Hold", "REASON_CODE": { "$numberDouble": "NaN" }, "AGENT_EMAIL": "dummyUser@siigo.com", "_id": "dummyUser@siigo.com_On Hold" }, { "AGENT_STATE_TIME": "01:05:09", "STATE": "Ready", "REASON_CODE": { "$numberDouble": "NaN" }, "AGENT_EMAIL": "dummyUser@siigo.com", "_id": "dummyUser@siigo.com_Ready" }]
                timesData = { "_id": "ProfileTimes", "documents": [{ "email": "rami802435@siigo.com", "interactions": [{ "ACW": 1958, "HANDLE_TIME": 21814, "INTERACTIONS": 28 }] }] }
            }

            let processedData = this.calculateFunnelData(statesData);

            this.setState({
                scorecardsData: this.calculateScorecardsData(timesData, statesData),
                tableData: processedData[0],
                chartData: processedData[1],
                totalTime: processedData[2],
                loading: false,
                userHasProfile: userProfile,
                lastUpdated: states.lastUpdated,
            })

            setTimeout(() => {
                this.setState({
                    ...this.state,
                    shouldDisableUpdateBtn: false
                });
            }, 30000);
        }).catch(err => {
            console.log(err);
        });
    }

    calculateScorecardsData = (times, states) => {
        let productiveTime = 0;
        let totalTime = 0;
        states.forEach(element => {
            if (element.STATE == "Login") {
                totalTime = this.timeToSeconds(element.AGENT_STATE_TIME);
            } else {
                let stateExists = this.state.notProductiveStates.includes(element.STATE);
                if (!stateExists) {
                    productiveTime += this.timeToSeconds(element.AGENT_STATE_TIME);
                }
            }
        })

        let scorecardsData = {
            interactionsCount: times.documents[0].interactions[0].INTERACTIONS,
            totalHT: times.documents[0].interactions[0].HANDLE_TIME,
            totalACW: times.documents[0].interactions[0].ACW
        }

        let cardsData = {
            Interacciones: scorecardsData.interactionsCount,
            TMO: secondsToTime(scorecardsData.totalHT / scorecardsData.interactionsCount),
            ACW: secondsToTime(scorecardsData.totalACW / scorecardsData.interactionsCount),
            Ocupacion: `${((productiveTime / totalTime) * 100).toFixed(2)}%`,
        };
        return cardsData;
    }

    calculateFunnelData = (agentStates) => {
        console.log(agentStates)
        let funnelData = [];
        let totalTime = 0;
        let index = 0;
        agentStates.forEach(element => {
            if (element.STATE == "Login") {
                totalTime = this.timeToSeconds(element.AGENT_STATE_TIME);
            }
        });
        agentStates.forEach(element => {
            if (element.STATE != "Login") {
                let percentage = (this.timeToSeconds(element.AGENT_STATE_TIME) / totalTime) * 100;
                let color = this.state.colors[index++];
                let state = element.STATE + ((typeof element.REASON_CODE != 'object') ? ` - ${element.REASON_CODE}` : "");
                let time = element.AGENT_STATE_TIME;
                funnelData.push({ state, time, color, percentage });
            }
        });
        funnelData.sort((a, b) => b.time - a.time);

        const categories = ['States'];
        const series = funnelData.map(item => ({
            name: item.state,
            data: [item.time],
            color: item.color,
            label: item.percentage.toFixed(2) + "%",
        }));
        return [funnelData, { categories: categories, series: series }, totalTime];
    }

    tooltipRender(props) {
        if (props.point) {
            console.log(props.point.dataItem);
            return props.point.dataItem.stat;
        }
    };

    timeToSeconds(time) {
        const [hours, minutes, seconds] = time.split(':').map(Number);
        return (hours * 60 * 60) + (minutes * 60) + seconds;
    }

    getCodeFromEmail() {
        return "800514"
    }

    render() {
        return (
            (this.state.loading) ? <CircularProgress /> :
                // (this.state.userHasProfile) ? 
                <>
                    <GridLayout
                        className="siignal-grid-layout"
                        align={{ horizontal: 'center', }}
                        cols={[{ width: '26%' }, { width: '14%' }, { width: '14%' }, { width: '11%' }, { width: '11%' }, { width: '11%' }, { width: '11%' }]}
                        rows={[{ height: "auto" }, { height: "auto" }, { height: "auto" }]}
                        gap={{ rows: 3, cols: 3, }}
                    >

                        {
                            (!this.state.userHasProfile) ? <>
                                <GridLayoutItem className={`personal-box-shadow`} colSpan={7}>
                                    <div className="k-hbox">
                                        <p className="nouserDataFound" xs={{ paddingTop: 3, margin: 3 }} align="center">
                                            No se han encontrado datos para este usuario, sin embargo este es un ejemplo de esta visual.
                                        </p>
                                    </div>
                                </GridLayoutItem>
                            </> : null
                        }

                        <GridLayoutItem className={`personal-box-shadow`}>
                            <div className="k-hbox">
                                <div>
                                    <p className="personal-card-title">
                                        {this.state.userName}
                                    </p>
                                    <p className="personal-card-value">
                                        {this.state.userEmail}
                                    </p>
                                </div>
                            </div>
                        </GridLayoutItem>

                        <GridLayoutItem className={`personal-box-shadow`} colSpan={2}>
                            <div className="k-vbox">
                                <p className="lastUpdaced-centered">Ultima actualización: {this.state.lastUpdated}</p>
                                <Button
                                    className="nextUpdate-centered"
                                    disabled={this.state.shouldDisableUpdateBtn}
                                    fillMode={"outline"}
                                    size={'large'}
                                    style={{ padding: '6px', margin: '16px', color: '#00244c', borderColor: '#00244c', boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.2)' }}
                                    onClick={() => {
                                        this.generateComponentData()
                                    }}>
                                    🚀 Actualizar la información
                                </Button>
                            </div>
                        </GridLayoutItem>

                        {
                            Object.keys(this.state.scorecardsData).slice(0, 5).map(el => {
                                return (

                                    <GridLayoutItem className={`personal-box-shadow`}>
                                        <div className="k-hbox">
                                            <div>
                                                <p className="personal-card-title">
                                                    {el}
                                                </p>
                                                <p className="personal-card-value">
                                                    {this.state.scorecardsData[el]}
                                                </p>
                                            </div>
                                        </div>
                                    </GridLayoutItem>
                                )
                            })
                        }

                        <GridLayoutItem className={`personal-box-shadow`} colSpan={2}>
                            <div className="centerH">
                                <TableContainer className="centerH">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Leyenda</TableCell>
                                                <TableCell>Estado</TableCell>
                                                <TableCell>Tiempo</TableCell>
                                                <TableCell>%</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.tableData.map((item, index) => {
                                                return (
                                                    <TableRow key={`TableRow${index}`}>
                                                        <TableCell style={{ backgroundColor: item.color, width: "20px", height: "20px" }}></TableCell>
                                                        <TableCell>{item.state}</TableCell>
                                                        <TableCell>{item.time}</TableCell>
                                                        <TableCell>{item.percentage.toFixed(2)}%</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </GridLayoutItem>

                        <GridLayoutItem className={`personal-box-shadow`} colSpan={5}>
                            <div className="centerH chartCont">
                                <Chart style={{ margin: '0 auto', width: 'auto' }} className="chartCont">
                                    <ChartSeries>
                                        <ChartSeriesItem
                                            type="funnel"
                                            data={this.state.tableData}
                                            categoryField="stat"
                                            field="percentage"
                                            colorField="color"
                                            dynamicSlope={"true"}
                                            dynamicHeight={"true"}
                                            gap={10}
                                        >
                                            <ChartSeriesLabels
                                                position="center"
                                                background="white"
                                                color="black"
                                                height={30}
                                                format="{0}"
                                                content={(e) => {
                                                    let label = e.value.toFixed(2) + "%"
                                                    return label;
                                                }}
                                            />
                                        </ChartSeriesItem>
                                    </ChartSeries>
                                </Chart>
                            </div>
                        </GridLayoutItem>

                    </GridLayout>
                </>


        )

    }
}

export { Profile };