import React, {useState} from 'react';
import {Input} from '@progress/kendo-react-inputs';
import {Button} from '@progress/kendo-react-buttons';

const SearchBar = ({data = [], searchFields = [], onSearch}) => {
	const [query, setQuery] = useState('');

	const handleInputChange = (e) => {
		setQuery(e.target.value);
	};

	const handleSearch = () => {
		if (!Array.isArray(data)) {
			console.error('Data should be an array');
			return;
		}

		const filteredData = data.filter((item) =>
			searchFields.some((field) =>
				item[field]?.toString().toLowerCase().includes(query.toLowerCase())
			)
		);
		onSearch(filteredData);
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			handleSearch();
		}
	};

	return (
		<div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
			<Input
				placeholder="Search..."
				value={query}
				onChange={handleInputChange}
				onKeyDown={handleKeyDown}
				style={{flex: 1}}
			/>
			<Button onClick={handleSearch}>Search</Button>
		</div>
	);
};

export default SearchBar;
