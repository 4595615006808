import {MyCustomSwitch} from './CustomSwitch';
import {Form, Field} from '@progress/kendo-react-form';
import {Input} from '@progress/kendo-react-inputs';
import {Button} from '@progress/kendo-react-buttons';
import {DropdownWorkers} from '../../RequestForm/DropdownWorkers';
import {RouteValidator, reportValidator, userNameValidator} from '../../RequestForm/Validators';
import {FormDatePicker, FormInput} from '../../RequestForm/form-components';
import {FormInputReport} from '../../PublicacionReportes/InputRouteReport/InputRouteReport';
import useAxios from '../../../hooks/UseAxios';
import {Loader} from '@progress/kendo-react-indicators';
import DropDownTreeCustom from '../../PublicacionReportes/dropDownThree/DropDownTreeCustom';
import {useEffect, useState} from 'react';
import axios from 'axios';
import DropGenerated from '../../PublicacionReportes/DropGenerated/DropGenerated';
import {downloadLightIcon} from '@progress/kendo-svg-icons';
import {handleDownload} from './handleDownload';
import '../../../style/MissingbodyTicket.css';
import { FormInputReportV2 } from '../../PublicacionReportes/InputRouteReport/InputRouteReportV2';

const MissingbodyofTicket = ({type, ticket, isPartner, setToast,timeElapsedStatus}) => {
	const renderContent = () => {
		switch (type) {
			case 'solicitud de Reporte':
				if (
					ticket.approval.dataPartner.state === true &&
					ticket.approval.performancePartner.state === true &&
					ticket.status.currently === 'Cerrado'
				) {
					return <ReporteriaCompleted ticket={ticket} isPartner={isPartner} />;
				} else {
					return (
						<ReporteriaComponent
							ticket={ticket}
							isPartner={isPartner}
							setToast={setToast}
							timeElapsedStatus={timeElapsedStatus}
						/>
					);
				}
			// Agrega más casos aquí si es necesario
			default:
				return (
					<SolicitudesComponent
						ticket={ticket}
						isPartner={isPartner}
						setToast={setToast}
					/>
				);
		}
	};

	return <div>{renderContent()}</div>;
};

// Asegúrate de tener componentes definidos para Reportería y Solicitudes
const ReporteriaComponent = ({ticket, isPartner, setToast,timeElapsedStatus}) => {
	const [isReadytoUpload, setIsReadytoUpload] = useState(
		ticket.approval.dataPartner.state === true &&
			ticket.approval.performancePartner.state === true
	);
	const [existAlredyUploaded, setExistAlredyUploaded] = useState(ticket.hasOwnProperty('alreadyUploaded'));
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([
		{
			baseDatos: 'db_nubemultitenantchile',
			tabla: null,
		},
		{
			baseDatos: 'db_nubemultitenantmexico',
			tabla: null,
		},
	]);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				const response = await axios.get('/api/MesadeAyuda/reading_databricks');
				const data = response.data;
				setData(data);
				// Process the data here
			} catch (error) {
				// Handle the error here
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();
	}, []);
	
	const uploadReportPUT = async (url, MsgSuccess, MsgError, open) => {
		try {
			// Usar axios.put para enviar un request PUT
			const response = await axios.put(url);
			console.log(response.data);
			setToast({
				snackbarOpen: open,
				message: MsgSuccess,
				type: 'success',
			});
		} catch (error) {
			console.error('Error al actualizar el reporte:', error.response);
			setToast({
				snackbarOpen: open,
				message: MsgError,
				type: 'error',
			});
		}
	};
	

	const uploadReport = async (url, userData, MsgSuccess, MsgError, open) => {
		try {
			const response = await axios.post(url, userData);
			console.log(response.data);
			setToast({
				snackbarOpen: open,
				message: MsgSuccess,
				type: 'success',
			});
		} catch (error) {
			console.error('Error al publicar reporte:', error.response);
			setToast({
				snackbarOpen: open,
				message: MsgError,
				type: 'error',
			});

		}
	};
	const handleSubmit = async (fieldsForm) => {
		if (isReadytoUpload) {
			setIsDownload(true);
			setExistAlredyUploaded(true);
			let dataItem = JSON.parse(JSON.stringify(fieldsForm));
			dataItem['possibleRoute'] = dataItem['possibleRoute'].join('/');
			dataItem['ticketId'] = ticket.ticketId;
			dataItem['commentOwner']= {name: sessionStorage.getItem('name'), mail: sessionStorage.getItem('usr')}
			dataItem['timeElapsedStatus']=timeElapsedStatus;
			let dataTosend = dataItem;
			await uploadReport(
				`/api/helpDesk/updateTicketBeforeToUploadReport`,
				dataTosend,
				'Reporte Publicado con Éxito',
				'Error al publicar el reporte',
				false
			);
			if(ticket.isNewReport){
					
				dataTosend = {
					email: sessionStorage.getItem('usr'),
					ticketId: ticket.ticketId,
				};
				await uploadReport(
					`https://datasmartreportingapi.azurewebsites.net/pbi/api/v1/Reports/publishReport`,
					dataTosend,
					'Reporte Publicado con Éxito',
					'Error al publicar el reporte',
					true
				);
			}else{
				console.log(ticket.reportFile[0].uid,ticket.reportName);
				await uploadReportPUT(
					`https://datasmartreportingapi.azurewebsites.net/pbi/api/v1/Reports/publishReport?datasetDisplayName=${ticket.reportName}&id=${ticket.reportFile[0].uid}`,
					'Reporte Publicado con Éxito',
					'Error al publicar el reporte',
					true
				);
				}
		} else {
			setToast({
				snackbarOpen: true,
				message:
					'Aún no se ha aprobado el reporte por parte del DataPartner o PerformancePartner',
				type: 'error',
			});
		}		
		setIsDownload(false);
	};

	const requiredValidator = (value) => (value ? '' : 'This field is required.');
	let initialValues = {};
	if (ticket.validatedwithBIteam) {
		initialValues = {
			withwhomBIteam: ticket.withwhomBIteam,
			whenCheckBIteam: new Date(ticket.whenCheckBIteam),
			validatedwithBIteam: !ticket.validatedwithBIteam,
			reportName: ticket.reportName,
			lineage: ticket.lineage,
			possibleRoute: ticket.possibleRoute.split('/'),
		};
	} else {
		initialValues = {
			validatedwithBIteam: ticket.validatedwithBIteam,
			reportName: ticket.reportName,
			lineage: ticket.lineage,
			possibleRoute: ticket.possibleRoute.split('/'),
		};
	}
	const [isDownload, setIsDownload] = useState(false);
	return (
		<div style={{display: 'flex', gap: '20px'}}>
			<div
				style={{
					width: '300px',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}>
				<h4>Adjuntos</h4>
				{/* {reportName,reportFile} */}
				<div className="contenedor-dowload-ticket">
					<Button
						onClick={() => {
							handleDownload(
								ticket.reportFile[0].uid,
								setToast,
								'solicitud de Reporte',
								ticket.reportFile[0].name,
								ticket.reportFile[0].extension,
								setIsDownload
							);
						}}
						style={{width: '180px', height: '60px'}}
						size={'large'}
						disabled={!(isPartner || ticket.approval.performancePartner.Name === sessionStorage.getItem('name'))}
						svgIcon={downloadLightIcon}
						className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">
						Descargar
					</Button>
				</div>
				<div className={`${isDownload ? '' : 'dissapear-text'}`}>
					<Loader size="medium" type="converging-spinner" />
					<span className={`messageWaitingDownload `}>Espere un momento</span>
				</div>
				{/* <div>
					{ticket.lineage && ticket.lineage
						.filter((BDatos) => {
							return !BDatos.baseDatos.startsWith('tbl') || !BDatos.baseDatos.startsWith('vw');
						})
						.map((BDatos, index) => {
							return (
								<div key={index}>
									<p>{BDatos.baseDatos}</p>
									<p style={{fontSize: 12}}>
										{BDatos.tabla && BDatos.tabla
											.filter((BDatos) => {
												return BDatos.baseDatos.startsWith('tbl') || BDatos.baseDatos.startsWith('vw');;
											})
											.map((BDatos) => {
												return BDatos.baseDatos;
											})
											.join(', ')}
									</p>
								</div>
							);
						})}
				</div> */}
			</div>
			<div>
				<Form
					onSubmit={handleSubmit}
					initialValues={initialValues}
					render={(formRenderProps) => (
						<form onSubmit={formRenderProps.onSubmit} className={'k-form'}>
							<fieldset>
								<legend>Información del Reporte:</legend>
								<div className="mb-3">
									<Field
										key={'validatedwithBIteam'}
										id={'validatedwithBIteam'}
										name={'validatedwithBIteam'}
										disabled={!(isPartner)}
										label={'Aprobado el reporte'}
										component={MyCustomSwitch}
										validator={requiredValidator}
									/>
								</div>
								<div className="mb-3">
									<Field
										key={'withwhomBIteam'}
										id={'withwhomBIteam'}
										name={'withwhomBIteam'}
										disabled={!isPartner}
										label={'Aprobado el reporte por equipo de BI'}
										component={DropdownWorkers}
										validator={userNameValidator}
									/>
								</div>
								<div className="mb-3">
									<Field
										key={'whenCheckBIteam'}
										id={'whenCheckBIteam'}
										disabled={!isPartner}
										name={'whenCheckBIteam'}
										label={'¿Cuándo revisaron el reporte?'}
										component={FormDatePicker}
										validator={userNameValidator}
									/>
								</div>
								<div className="mb-3">
									<Field
										key={'reportName'}
										id={'reportName'}
										disabled={!isPartner}
										name={'reportName'}
										label={ticket.isNewReport?'Nombre del Reporte':'Nombre del Reporte a actualizar debe coincidir con el nombre del Control Center'}
										placeholder={'Escriba el nombre'}
										hint={
											ticket.isNewReport?'Nombre del reporte que se publicará':'Coloque el nombre del reporte que aparece en el Control Center'
										}
										component={FormInput}
										validator={!ticket.isNewReport?requiredValidator:reportValidator}
									/>
								</div>

								{isLoading ? (
									<Loader size="large" type="converging-spinner" />
								) : (
									<Field
										key={'lineage'}
										id={'lineage'}
										disabled={!isPartner}
										name={'lineage'}
										label={'Linaje de la información: ¿De dónde viene?'}
										placeholder={'Base de datos, Array tablas'}
										hint={'Este es el origen de los datos'}
										component={DropDownTreeCustom}
										validator={requiredValidator}
										data={data}
									/>
								)}
								<div className="mb-3">
									<Field
										key={'possibleRoute'}
										id={'possibleRoute'}
										disabled={!isPartner}
										name={'possibleRoute'}
										label={'Ruta del Reporte'}
										placeholder={'Parte por parte de la ruta'}
										component={FormInputReportV2}
										validator={RouteValidator}
									/>
								</div>
							</fieldset>
							<br />
							<Button
								type={'submit'}
								themeColor={ticket.isNewReport ? 'success' : 'info'}
								className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
								disabled={!formRenderProps.allowSubmit||existAlredyUploaded}>
								{ticket.isNewReport ? 'Publicar Reporte' : 'Actualizar Reporte'}
							</Button>
							<span
								style={{
									fontSize: 12,
									marginLeft: 10,
								}}>
								Aprobar el reporte
							</span>
						</form>
					)}
				/>
				<br />
			</div>
		</div>
	);
};

const SolicitudesComponent = ({ticket}) => {
	let type =
		ticket.requestType === 'Requerimiento' ? 'Requerimiento' : 'Incidente';
	// Contenido del componente Solicitudes
	//!Uso que le dará usePerson - Personas que veran la información: authorizedPersons
	return type === 'Requerimiento' ? (
		<>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: 'repeat(2, 1fr)', // Crea dos columnas de igual tamaño
					gridGap: '20px', // Espacio entre columnas y filas
					padding: '10px',
				}}>
				<div className='panelsDescription'>
					<h5>Uso que le dará:</h5>
					<p style={{maxWidth: '250px', wordWrap: 'break-word'}}>
						{ticket.requestUse}
					</p>
				</div>
				<div className='panelsDescription'>
					<h5>Personas que verán la información:</h5>
					<p>
						{ticket.requestViewInf?.map((person, index) => person).join(', ')}
					</p>
				</div>
				<div className='panelsDescription'> 
					<h5>Tipo de requerimiento:</h5>
					<p style={{maxWidth: '250px', wordWrap: 'break-word'}}>
						{ticket.requestNeed}
					</p>
				</div>
				<div className='panelsDescription'>
					<h5>¿Qué necesita?</h5>
					<p>{ticket.requestProduct}</p>
				</div>
				<div className='panelsDescription'>
					<h5>¿En qué herramienta necesita la entrega?</h5>
					<p style={{maxWidth: '250px', wordWrap: 'break-word'}}>
						{ticket.requestTool}
					</p>
				</div>
				<div className='panelsDescription'>
					<h5>¿Qué espera de la entrega?</h5>
					<p>{ticket.descriptionTask}</p>
				</div>
			</div>
		</>
	) : (
		<>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: 'repeat(3, 1fr)', // Crea tres columnas de igual tamaño
					gridGap: '20px', // Espacio entre columnas y filas
					padding: '10px',
				}}>
				<div className='panelsDescription'>
					<h5>Tipo de incidencia:</h5>
					<p style={{maxWidth: '250px', wordWrap: 'break-word'}}>
						{ticket.requestNeed}
					</p>
				</div>
				<div className='panelsDescription'>
					<h5>¿Qué problema tiene?</h5>
					<p>{ticket.requestProduct}</p>
				</div>
				<div className='panelsDescription'>
					<h5>¿Cuál es la herramienta?</h5>
					<p style={{maxWidth: '250px', wordWrap: 'break-word'}}>					
						{ticket.requestTool}
					</p>
					<p style={{maxWidth: '250px', wordWrap: 'break-word'}}>
						{ticket.requestItem}
					</p>
				</div>
				<div style={{gridColumn: '1 / -1'}} className='panelsDescription'>
					{' '}
					{/* Hace que el elemento abarque todas las columnas */}
					<h5>¿Qué sucede con el item?</h5>
					<p style={{maxWidth: '650px'}}>{ticket.descriptionTask}</p>
				</div>
			</div>
		</>
	);
};

const ReporteriaCompleted = ({ticket, isPartner}) => {
	return (
		<>
			<h2>El Reporte {ticket.reportName} ya fue publicado</h2>
			<div style={{display: 'flex'}}>
				<p
					style={{
						padding: '3px',
						margin: '10px',
						color: 'black',
						fontSize: '20px',
						fontWeight: 'italic',
					}}>
					{ticket.possibleRoute}
					{' /'}
				</p>

				<p
					style={{
						padding: '3px 3px 3px 0px',
						margin: '10px 10px 10px 0px',
						color: 'black',
						fontSize: '20px',
						fontWeight: 'italic',
					}}>
					{ticket.reportName}
				</p>
			</div>
			<div style={{minHeight: '90px'}}></div>
		</>
	);
};

export default MissingbodyofTicket;
