import React from "react";
import { SvgIcon } from "@progress/kendo-react-common";
import { commentIcon, envelopIcon, volumeDownIcon, minusSmIcon } from "@progress/kendo-svg-icons";

const ColumnTipoDeGestion = ({ tipoGestion }) => {

    let icon = '';

    switch (tipoGestion) {
        case '1':
            icon = commentIcon;
            break;
        case '2':
            icon = envelopIcon;
            break;
        case 'Renov. Telefónica':
            icon = volumeDownIcon;
            break;
        default:
            icon = minusSmIcon
            break;
    }

    return (
        <td className="renewalsAgentPanel-td">
            <SvgIcon icon={icon} />
        </td>
    )
}

export { ColumnTipoDeGestion }