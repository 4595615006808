import React, {useEffect, useState} from 'react'
import { models } from "powerbi-client";
import { PowerBIEmbed } from 'powerbi-client-react'
import axios from 'axios'
import '../../../App.css'
import { CircularProgress } from '@mui/material';


export const ReportsTNBReporteGeneral = () => {
    // console.log(EmbedData.embedUrl)

    const [isloading, setLoading] = useState(true);
    const [results, setResults] = useState(0);

    useEffect(() => {
        const loadPost = async () => {
  
            // Till the data is fetch using API 
            // the Loading page will show.

            // Await make wait until that 
            // promise settles and return its result
            const response = await axios.get("/ei_TNB/ReporteGeneral");
            // After fetching data stored it in posts state.
            setResults(response.data);
  
            // Closed the loading page
            setLoading(false);
        }
  
        // Call the function
        loadPost();
    }, []);
    // debugger

    
   

    // console.log(access)
    // // debugger;

    if (isloading) {
        return <div className='desktop-view-SDR'>
                    <div id='reportContainerSDR'>
                    <div className='centerDS'><CircularProgress/></div>
                    </div>
                </div>
    }

    return (
        <>
                    <div className='desktop-view-SDR'>
                        <div id='reportContainerSDR'>
                            <PowerBIEmbed cssClassName='iframe' embedConfig={{
                                type: "report",
                                tokenType: models.TokenType.Embed,
                                accessToken: results.accessToken,
                                embedUrl:results.reportConfig.map(reportConfig=>reportConfig.embedUrl)[0],
                                id:results.reportConfig.map(reportConfig=>reportConfig.reportId)[0],
                                pageView: 'actualSize',
                                
                                
                                
                                // Enable this setting to remove gray shoulders from embedded report
                                settings: {
                                    background: models.BackgroundType.Transparent,
                                    filterPaneEnabled: false,
                                    navContentPaneEnabled: false,
                                    }
                            }} />
                        </div>
              </div>
        </>
      )


};

