import {React, useState} from 'react'
import * as FaIcons from 'react-icons/fa'
import {NavLink, Link} from 'react-router-dom'

const DynamicFaIcon = ({ name }) => {
    const IconComponent = FaIcons[name];
    if (!IconComponent) { // Return a default one
      return <FaIcons.FaBeer />;
    }
    return <IconComponent />;
  };

function NavbarItems(props) {

    const {name, subMenus, iconName, onClick, to, exact}= props;
    const [expand, setExpand]= useState(false)


  return (
    <li onClick={props.onClick}>
        <Link to={to} className='menu-item'>
            <div className='item-icon'>
                <DynamicFaIcon name={iconName}/>
            </div>
            <span onClick={()=>setExpand(!expand)}>
                {name}
            </span>
        </Link>
        {subMenus && subMenus.length>0 ? (
                <ul className={`sub-menu-ds ${expand ? "" : "active"}`}>
                    {subMenus.map((menu,index)=> (
                            <li key={index}>
                                <NavLink to={menu.to}>{menu.name}</NavLink>
                            </li>
                        ))}
                </ul>
            ):null}
    </li>
  );
};

export default NavbarItems
