import React, { useState } from 'react';
import {searchPeople} from '../Graph'
import axios from 'axios'
import { DropDownList, DropDownListChangeEvent, AutoComplete } from '@progress/kendo-react-dropdowns';
import { TnbFormularioUpload } from '../components/TNB/tnbFormularioUpload';
import { TopicsTable } from '../components/TNB/topicsTable';


export const Compass=()=> {

  return (
    <><h2 className='page-header'>Siigo Compass</h2>
      {/* <TopicsTable/> */}
    </>
  );
}