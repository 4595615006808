import {AutoComplete, DropDownList} from '@progress/kendo-react-dropdowns';
import {FieldWrapper} from '@progress/kendo-react-form';
import {
	Input,
	InputClearValue,
	InputSeparator,
	TextBox,
} from '@progress/kendo-react-inputs';
import {Label, Error, Hint, FloatingLabel} from '@progress/kendo-react-labels';

import {useEffect, useRef, useState} from 'react';
import {exportNestedObject} from '../../../../reports/utils/nestedObjectDiagram';

import {SvgIcon} from '@progress/kendo-react-common';
import {cancelIcon, checkIcon, plusIcon,trashIcon,xCircleIcon} from '@progress/kendo-svg-icons';
import {Button} from '@progress/kendo-react-buttons';

export const FormInputReportV2 = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		type,
		optional,
		value,
		placeholder,
		...others
	} = fieldRenderProps;

	const editorRef = useRef(null);
	const labelId = label ? `${id}_label` : '';
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const [data, setData] = useState(
		value ? [] : ['Reportes por Área', 'Reportes por País ']
	); //
	const [listKey, setListKey] = useState(value || []);

	const [messageC, setMessageC] = useState(null);
	const diagram = JSON.parse(sessionStorage.getItem('diagram')); //este es diagrama entero de lso botones
	const [customRouteFlag, setCustomRouteFlag] = useState(false);
	const [valueCustom, setValueCustom] = useState('');
    const [disappear, setDisappear] = useState(false);
    const [messageError, setMessageError] = useState(null);
    const [countCustom, setCountCustom] = useState(0);
    const [disappearButtónClear, setDisappearButtónClear] = useState(value?false:true)
    const [dissapearButtonAdd, setDissapearButtonAdd] = useState(true)

    const plusAction = (e) => {
        e.preventDefault();
        setCustomRouteFlag(true);
        setDissapearButtonAdd(true);
    }


	const onSave = async (e) => {
        let valor = e.target.value;
		if (e.target.value !== '...Agrega un nuevo apartado'&&e.target.value !== '...Agrega un nuevo Botón') {
			setListKey([...listKey, valor]);
			let result = [...listKey, valor];
			let aux = await exportNestedObject(diagram, result);
			if (aux === true) {
				setMessageC('Correcto!! Acá termina la Ruta');
				fieldRenderProps.onChange({
					value: result,
				});
				setData(Object.keys(aux));
                setDisappear(true);
			} else {
				if(listKey.length %2===0){
					setData(['...Agrega un nuevo Botón', ...Object.keys(aux)])
				}else{
					setData(['...Agrega un nuevo apartado', ...Object.keys(aux)]);
				}
			}

		} else {
			if (valor === '...Agrega un nuevo apartado'||valor === '...Agrega un nuevo Botón') {
				setCustomRouteFlag(true);
                setDisappear(true);
			}
		}
		if(countCustom > 0){
			fieldRenderProps.onChange({
				value: [...listKey, valor]
			});
		}
        if(listKey.length > 0){
            setDisappearButtónClear(false);
        }else{  
            setDisappearButtónClear(true);
        }
        setValueCustom('');
	};

	const onDelete = async (e) => {
		e.preventDefault();
            listKey.pop();
            if(listKey.length %2===0){
				setData([
					'...Agrega un nuevo apartado',
					...Object.keys(await exportNestedObject(diagram, listKey)),
				]);
			}else{
				setData([
					'...Agrega un nuevo Botón',
					...Object.keys(await exportNestedObject(diagram, listKey)),
				]);
			}
            setListKey([...listKey]);
            fieldRenderProps.onChange({
                value: null,
            });
            // setValor('');
            setMessageC(null);
		if(listKey.length > 0&&listKey.length%2===0){
			fieldRenderProps.onChange({
                value: listKey,
            });
			setMessageError(null);
		}

        if(countCustom > 0){
            setCustomRouteFlag(false);
            if(countCustom === 1){
                setDissapearButtonAdd(true);
            }else{setDissapearButtonAdd(false);}
            setCountCustom(countCustom - 1);
        }else{
            setCustomRouteFlag(false);
            setDissapearButtonAdd(true);
            setDisappear(false);
        }
	};

	const handleCustomReAdd = () => {
        setCountCustom(countCustom + 1);
        setDissapearButtonAdd(false);
		setListKey([...listKey, valueCustom]);
        let result = [...listKey, valueCustom];
		if (result.length % 2 === 0) {
			//debe ser par
            setMessageError(null);

            fieldRenderProps.onChange({
                value: [...listKey, valueCustom]
            });
			
		}else{
            setMessageError('Debe ser PAR los segmentos de la Ruta, ya que el orden es: Apartado - Nombre de Botón, por favor agrega un nuevo segmento para completar la ruta');
			fieldRenderProps.onChange({
				value:null
			})
        }
        setCustomRouteFlag(false);
	};
	const handleChangeInput = (e) => {
		setValueCustom(e.target.value);
	};
    const handleCancelCustom = () => {
        setCustomRouteFlag(false);
        if(countCustom === 0){
            setDisappear(false);
        }
    }

    useEffect(() => {
        if(countCustom > 0){
            setDisappearButtónClear(false);
        }else{
            setDissapearButtonAdd(true);
            setDisappear(false);
            setValueCustom('');
        }
		if(listKey%2===0&&listKey.length>0){
			setMessageError(null);
			setMessageC('Correcto!! Acá termina la Ruta');
		}

    }, [countCustom,listKey])

	return (
		<FieldWrapper>
			<Label
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
				optional={optional}
				className="k-form-label">
				{label}
			</Label>
			
			<div style={{display: 'flex'}} {...others}>
				{listKey?.map((valueKey, key) => (
					<p
						key={key}
						style={{
							padding: '3px',
							margin: '10px',
							color: 'black',
							fontSize: '15px',
							fontWeight: 'bold',
						}}>
						{valueKey}
						{'  '} /
					</p>
				))}
                {!disappearButtónClear&&(
                    <Button variant="contained" fillMode={'flat'} onClick={onDelete} disabled={disabled} style={{maxWidth:'10px',maxHeight:'10px',paddingBottom:'8px',marginLeft:'-15px'}}><SvgIcon icon={xCircleIcon}/></Button>
                )}
                {
                    !dissapearButtonAdd &&(
                        <Button variant="contained" fillMode={'flat'} onClick={plusAction} disabled={disabled}><SvgIcon icon={plusIcon}/></Button>
                    )
                }
                {
                    !disappear && (
                        <DropDownList
						ariaLabelledBy={labelId}
						style={{width: '150px'}}
						data={data}
						placeholder={placeholder}
						onChange={onSave}
						ariaDescribedBy={`${hintId} ${errorId}`}
						ref={editorRef}
						valid={valid}
						id={id}
						disabled={disabled}
						value={value}
						fillMode={'flat'}
						suffix={() => (
							<>
								<InputClearValue onClick={handleCustomReAdd}>
										<SvgIcon icon={checkIcon} />
								</InputClearValue>
                                
							</>
						)}
					/>
                    )
                }
				{customRouteFlag && (
					<TextBox
						value={valueCustom}
                        style={{width: '150px'}}
						onChange={handleChangeInput}
						fillMode={'flat'}
						placeholder="Escribe aquí el apartado nuevo"
						suffix={() => (
							<>

									<InputClearValue onClick={handleCustomReAdd}>
										<SvgIcon icon={checkIcon} />
									</InputClearValue>
                                    <InputSeparator />
                                <InputClearValue onClick={handleCancelCustom}>
										<SvgIcon icon={trashIcon} />
								</InputClearValue>
							</>
						)}
					/>
				)}
					
				
			</div>
            <div
				className={'k-form-field-wrap'}
				style={{display: 'flex', gap: '10px'}}>
				{/* <Input valid={valid} type={type} id={id} disabled={disabled} placeholder={placeholder} ariaDescribedBy={`${hintId} ${errorId}`} onChange={onchanger} value={valor} /> */}

				{showHint && <Hint id={hintId}>{hint}</Hint>}
				{messageC && <Hint id={hintId}>{messageC}</Hint>}
                {messageError && <Hint className='red' id={hintId}>{messageError}</Hint>}
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
			</div>
		</FieldWrapper>
	);
};
