import axios from 'axios';
import React, { useState } from 'react'
import { searchByEmail, searchPeople } from '../../Graph';

export const UseSearchdirectory = () => {
    const [loading, setLoading] = useState(false)
    const [ listNames, setListNames ] = useState([]);

    async function getToken() {
        let api_url = '/tokenGraph';
        return axios.get(api_url,{
          headers: { 
            'Content-Type': 'application/json; charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
            'ConsistencyLevel':'eventual'
          }});
      }

    async function search(name) {
        if(name!==''){
            setLoading(true);
            await getToken().then((response)=>{
                searchPeople(response.data.access_token,name).then(response=>{
                    setListNames(response.value?.map((objeto) => {
                        return {
                            email: objeto.userPrincipalName,
                            name: objeto.displayName
                        };                      
                    }));
                    setLoading(false);
                    if(listNames?.length > 0){
                        // setListShow(true);
                        // setLoading(false);
                    }
                    else{
                        if (!response){
                            console.error("no se puede conectar con Graph")
                        }
                        // setLoading(false);
                        // setListShow(false);
                    }
                    // setInitialSearch(false);
                })
            });
        }
    }
    async function searchByemail(email) {
        if(email !== ''){
            setLoading(true);
            await getToken().then((response) => {
                searchByEmail(response.data.access_token, email).then(response => {
                    setListNames(response.value?.map((objeto) => {
                        return {
                            email: objeto.userPrincipalName,
                            name: objeto.displayName
                        };                      
                    }));
                    setLoading(false);
                    if(listNames?.length > 0){
                        // Additional logic for successful search
                    }
                    else{
                        if (!response){
                            console.error("No se puede conectar con Graph");
                        }
                        // Additional logic for unsuccessful search
                    }
                    // Additional logic for after search
                })
            });
        }
    }
    

  return {
    search,
    loading,
    searchByemail,
    listNames
  }
}
