import * as React from 'react';
import { ExternalDropZone, Upload } from '@progress/kendo-react-upload';
import axios from 'axios'
const uploadRef = React.createRef();
const fileStatuses = [
  "UploadFailed",
  "Initial",
  "Selected",
  "Uploading",
  "Uploaded",
  "RemoveFailed",
  "Removing",
];

export const TnbFormularioUpload = () => {
  const [files, setFiles] = React.useState([]);
  const [events, setEvents] = React.useState([]);
  const [filePreviews, setFilePreviews] = React.useState({});
  const [affectedFiles, setAffectedFiles] = React.useState([]);
  const [resultsActivities, setResultsActivities] = React.useState([]);
  const userID=sessionStorage.getItem('usr')

  React.useEffect(() => {
    affectedFiles
      .filter((file) => !file.validationErrors)
      .forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = (ev) => {
          setFilePreviews({
            ...filePreviews,
            [file.uid]: ev.target.result,
          });
        };
        if (file && file.getRawFile) {
          reader.readAsDataURL(file.getRawFile());
        }
      });
  }, [affectedFiles, filePreviews]);
  const onAdd = (event) => {
    setFiles(event.newState);
    setEvents([...events, `File selected: ${event.affectedFiles[0].name}`]);
    setAffectedFiles(event.affectedFiles);
  };
  const onRemove = (event) => {
    let newFilePreviews = {
      ...filePreviews,
    };
    event.affectedFiles.forEach((file) => {
      delete newFilePreviews[file.uid];
    });
    setFiles(event.newState);
    setEvents([...events, `File removed: ${event.affectedFiles[0].name}`]);
    setFilePreviews(newFilePreviews);
  };
  const onProgress = (event) => {
    setFiles(event.newState);
    setEvents([...events, `On Progress: ${event.affectedFiles[0].progress} %`]);
  };
  const onStatusChange = (event) => {
    const file = event.affectedFiles[0];
    setFiles(event.newState);
    setEvents([
      ...events,
      `File '${file.name}' with extension:'${file.extension}' status changed to: ${file.status} and its progress is: '${file.progress}'`,
    ])
    const name=`${file.name}`
    const extension=`${file.extension}`
    const progress=`${file.progress}`
    const status=`${file.status}`
    const user ={name, extension, progress, status}}
    // if(fileStatuses[file.status]!=='Uploading'){axios.post(`/api/TNB/UploadedFilesid=${sessionStorage.getItem('usr')}`, user)}} 
    ;
  ;
  return <div>
  <ExternalDropZone uploadRef={uploadRef} />
  <div style={{
height: '50px'
}} />
  <Upload         
        onAdd={onAdd}
        onRemove={onRemove}
        onProgress={onProgress}
        onStatusChange={onStatusChange}
        autoUpload={false} 
        ref={uploadRef} 
        batch={false} 
        saveMethod={'POST'} 
        multiple={true} 
        defaultFiles={[]} 
        withCredentials={false} 
        saveUrl={`/api/TNB/UploadFile?id=${userID}`} 
    />
</div>;

};


