import { useState } from 'react';
import axios from 'axios';

const useAxiosPut = (url) => {
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [statusResponse, setStatusResponse] = useState(null);

  const executePut = async (data) => {
    setIsLoading(true);
    setError(null);
    setResponseData(null);

    try {
      const response = await axios.put(url, data);
        setResponseData(response.data);
        setStatusResponse(response.status);
    } catch (err) {
      setResponseData(err.response.data);
      setStatusResponse(err.response.status);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return { responseData, statusResponse, isLoading, error, executePut };
};

export default useAxiosPut;
