import React from 'react'
import "./AccessModule.css"

export default function AccessModule() {
  return (
    <div className='AccessModule-Container'>
      <h1>Aca es para buscar los Usuarios</h1>
    </div>
  )
}

