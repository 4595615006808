import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomSnackbar({ open, handleClose, message, severity }) {
  return (
    <Snackbar open={open} autoHideDuration={7000} onClose={handleClose}anchorOrigin={{ vertical:'top', horizontal:'right' }}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
//success
//error
//warning
//info