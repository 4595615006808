import {Form, FormElement} from '@progress/kendo-react-form';
import {Button} from '@progress/kendo-react-buttons';
import {Stepper} from '@progress/kendo-react-layout';
import {useCallback, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import CustomSnackbar from '../../components/Notification/SnackBar';
import {CircularProgress} from '@mui/material';
import {SvgIcon} from '@progress/kendo-react-common';
import {chevronLeftIcon} from '@progress/kendo-svg-icons';
import { BeforeSendInfo } from '../components/RequestForm/BeforeSendInfo';
import { Step1RequestForm, Step2RequestForm, Step3RequestForm } from '../layout/RequestForm';


export const RequestForm = () => {

	const TypeRequest = useParams().type;
	const [formState, setFormState] = useState(initialValues);
	let stepPages = [<Step1RequestForm/>,<Step2RequestForm/>,<Step3RequestForm/>];
	const [Loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [messageSnackBar, setMessageSnackBar] = useState(null);
	const [typeSnackBar, setTypeSnackBar] = useState('success');
	const [step, setStep] = useState(0);
	const [steps, setSteps] = useState([
		{
			label: 'Datos Solicitante',
			isValid: undefined,
		},
		{
			label: 'Datos Solicitud',
			isValid: undefined,
		},
		{
			label: 'Antecedentes\nsolicitud',
			isValid: undefined,
		},
	]);
	const lastStepIndex = steps.length - 1;
	const isLastStep = lastStepIndex === step;
	const onStepSubmit = useCallback(
		(event) => {
			const {isValid, values} = event;
			const currentSteps = steps.map((currentStep, index) => ({
				...currentStep,
				isValid: index === step ? isValid : currentStep.isValid,
			}));
			setSteps(currentSteps);
			if (!isValid) {
				return;
			}
			setStep(() => Math.min(step + 1, lastStepIndex));
			setFormState(values);
			if (isLastStep) {
				InsertTickets(values, TypeRequest);
			}
		},
		[steps, isLastStep, step, lastStepIndex]
	);
	const onPrevClick = useCallback(
		(event) => {
			event.preventDefault();
			setStep(() => Math.max(step - 1, 0));
		},
		[step, setStep]
	);
	const handleOpenSnackbar = () => {
		setSnackbarOpen(true);
	};

	const handleCloseSnackbar = () => {
		setSnackbarOpen(false);
	};

	async function InsertTickets(toDo, Type) {
		setLoading(true);
		let dataToSend=await BeforeSendInfo(toDo,Type);

		let api_url = {
			'Incidencia':'https://datasmartreportingapi.azurewebsites.net/helpDesk/Incident',
			'Requerimiento':'https://datasmartreportingapi.azurewebsites.net/helpDesk/Request',
			'solicitud de Reporte':'https://datasmartreportingapi.azurewebsites.net/helpDesk/Report'
		}
		const data = JSON.stringify(dataToSend);
		axios
			.post(api_url[Type], data, {
				headers: {
					'Content-Type': 'application/json; charset=UTF-8',
					'Access-Control-Allow-Origin': '*',
				},
			})
			.then((response) => {
				setLoading(false);
				console.log('Success: ', response);
				if (response.status === 200) {
					setMessageSnackBar('El requerimiento se guardo exitosamente.');
					handleOpenSnackbar();
					setTimeout(() => {
						navigate('/HelpDesk');
					}, 6000);
				} else if (response.status === 500) {
					setMessageSnackBar('Problema del servidor.');
					setTypeSnackBar('warning');
					handleOpenSnackbar();
					setTimeout(() => {
						navigate('/HelpDesk');
					}, 6000);
					console.log(response.data);
				} else {
					setMessageSnackBar('El requerimiento no se guardo.');
					setTypeSnackBar('error');
					handleOpenSnackbar();
					console.log('Error: ', response.data['message']);
				}
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				setTypeSnackBar('error');
				setMessageSnackBar('El requerimiento no se guardo.');
				handleOpenSnackbar();
			});
	}

	if (Loading) {
		return (
			<div className="desktop-view-SDR">
				<div id="reportContainerSDR">
					<div className="centerDS">
						<CircularProgress />
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			<div style={{display:'flex',gap:'20px'}}>
				<div>
					<SvgIcon className='ButtonBack' icon={chevronLeftIcon} size="xxxlarge" onClick={()=>{navigate('/HelpDesk')}}/>
				</div>
				<h1>{`Registrar ${TypeRequest}`}</h1>
			</div>
			<CustomSnackbar
				open={snackbarOpen}
				handleClose={handleCloseSnackbar}
				message={messageSnackBar}
				severity={typeSnackBar}
			/>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					marginTop: 25,
				}}>
				<Stepper value={step} items={steps} />

				<Form
					initialValues={formState}
					onSubmitClick={onStepSubmit}
					render={(formRenderProps) => (
						<div
							style={{
								alignSelf: 'center',
							}}>
							<FormElement
								style={{
									width: 880,
								}}>
								{stepPages[step]}
								<span
									style={{
										marginTop: '40px',
									}}
									className={'k-form-separator'}
								/>
								<div
									style={{
										justifyContent: 'space-between',
										alignContent: 'center',
									}}
									className={
										'k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end'
									}>
									<span
										style={{
											alignSelf: 'center',
										}}>
										Step {step + 1} of 3
									</span>
									<div>
										{step !== 0 ? (
											<Button
												style={{
													marginRight: '16px',
												}}
												onClick={onPrevClick}>
												Previous
											</Button>
										) : undefined}
										<Button
											themeColor={'primary'}
											disabled={!formRenderProps.allowSubmit}
											onClick={formRenderProps.onSubmit}>
											{isLastStep ? 'Submit' : 'Next'}
										</Button>
									</div>
								</div>
							</FormElement>
						</div>
					)}
				/>
			</div>
		</>
	);
};

const initialValues = () => {
	const name = sessionStorage.getItem('name');
	const email = sessionStorage.getItem('usr');
	const job = sessionStorage.getItem('role');
	const area = sessionStorage.getItem('area');
	const region = sessionStorage.getItem('country');
	const date = new Date();
	date.setHours(date.getHours() - 5);
	const isoDate = date.toISOString();
	return {
		requestorName: name,
		requestorEmail: email,
		requestorJobTitle: job,
		requestorArea: area,
		requestDate: isoDate,
		requestorOther: false,
		requestorNameOther: '',
		validatedwithBIteam: false,
		withwhomBIteam: '',
		whenCheckBIteam: '',
		associatedTicketNumber: '', //Incidencia - Requerimiento
		priority: 'Media',
		region: region,
	};
};

// return {
// 	requestorName: name,
// 	requestorEmail: email,
// 	requestorJobTitle: job,
// 	requestorArea: area,
// 	requestorOther: false,
// 	requestorNameOther: '',
// 	requestDate: date,
// 	requestType: '',//Incidencia - Requerimiento - Solicitud de Reporte
// 	requestNeed: '',//Incidencia - Requerimiento
// 	requestProduct: '',//Incidencia - Requerimiento
// 	title: '',//Incidencia - Requerimiento - Solicitud de Reporte
// 	descriptionTask: '',//Incidencia - Requerimiento
// 	requestItem: '',//Incidencia
// 	requestTool: '',//Incidencia - Requerimiento - Solicitud de Reporte
// 	requestImpact: '',//Incidencia - Solicitud de Reporte
// 	previousTickets: '',//Incidencia - Requerimiento
// 	associatedTicketNumber: '',//Incidencia - Requerimiento
// 	requestUse: '',//Requerimiento
// 	requestViewInf: [],//Requerimiento - Solicitud de Reporte
// 	requestAutomatizade: '',//Requerimiento
// 	status: 'Nuevo',
// 	dateLastModified: date,
// 	numberPriority: 3,
// 	priority: 'Media',
// 	region: region,
// 	destinationArea: '',
// };
