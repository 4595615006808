
import DayOperation from './DayOperation';

const MonthOperation = () => {

    return (
        <div>
            <h1 style={{'fontWeight':'bold'}}>Mes de Operación</h1>
            <br/>
            <DayOperation/>
        </div>
    );
};

export default MonthOperation;
