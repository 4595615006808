
import { Form, FormElement } from '@progress/kendo-react-form';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { Stepper } from '@progress/kendo-react-layout';
import { AccountDetails } from './account-details';
import { PersonalDetails } from './personal-details';
import { PaymentDetails } from './payment-details';
import { TileLayout } from '@progress/kendo-react-layout';


import { TopicsTable } from './topicsTable';
import { useState } from 'react';
import { ProjectsTable } from './projectsTable';


export const TnbTemas = () => {
const [chooseView, setChooseView] = useState('Temas')
const onClicks = (e) => {
  setChooseView(e.target.innerText)
}
    const tiles2 = [
        {
          defaultPosition: {
            col: 1,
            colSpan: 12,
            rowSpan: 4,
          },

          body: <div>
            <div style={{display:'flex', alignContent:'center',justifyContent:'center'}}><ButtonGroup>
          <Button togglable={true} selected={chooseView==='Temas'?true:false} onClick={onClicks}>Temas</Button>
          <Button togglable={true} selected={chooseView==='Proyectos'?true:false} onClick={onClicks}>Proyectos</Button>
        </ButtonGroup></div>
        <br/>
            {
              chooseView==='Temas'?<><h1>Temas</h1><TopicsTable/></>:<><h1>Proyectos</h1><ProjectsTable/></>
            }

          </div>,
          resizable: false,
          reorderable: false
        },
      ];
    return (
      <>
      {/* <h1 className="demo-heading">GitHub Issues Demo</h1> */}
            <div className="example-wrapper">
            <TileLayout
                    columns={12}
                    rowHeight={200}
                    gap={{
                        rows: 10,
                        columns: 10,
                    }}
                    items={tiles2}
                />
            </div>

      </>
    )
}