import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { callMsGraph } from '../../../../Graph';

function useUserService() {
    const { instance, accounts } = useMsal();
    const userProfile = {
        name: accounts[0].name,
        email: accounts[0].username,
    }
    return {
        userProfile,
    };
}

export { useUserService };