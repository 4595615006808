import React from 'react'
import '../../components/status-card/statuscard.css'
import Tabs from "../../components/multitab/Tabs";
import Panel from "../../components/multitab/Panel";
import { CustomerComunicaciones } from '../../components/pbi/Customer/Customer_Comunicaciones';



export const CustomerReportsComunicaciones = () => {

  let renderView
  if (sessionStorage.getItem('role') === 'portalAdmin' || sessionStorage.getItem('role') === 'cLevel'|| sessionStorage.getItem('role') === 'productOwner'){
    renderView=<Tabs>
     <Panel title="Comunicaciones">
         <CustomerComunicaciones/>
     </Panel>
     <Panel title="">
     </Panel>
     </Tabs>
  } else if(sessionStorage.getItem('area') === 'Sales' || sessionStorage.getItem('area') === 'Strategy' || sessionStorage.getItem('area') === 'Marketing' || sessionStorage.getItem('area') === 'Sistemas Mexico' || sessionStorage.getItem('area') === 'Soporte Tecnico' || sessionStorage.getItem('area') === 'Customer Success' ){
    renderView=<Tabs>
    <Panel title="Comunicaciones">
        <CustomerComunicaciones/>
    </Panel>
    <Panel title="">
    </Panel>
    </Tabs>
  } else  {
    renderView = <div className='desktop-view-SDR'>
        <div id='reportContainerSDR'>
            <div className='centerDS'>No eres un usuario registrado, lo sentimos</div>
        </div>
    </div>
  }


    return (
        <>
      <><h1 className='page-header-reports'>Reportes Comunicaciones</h1><div>{renderView}
      </div></>
        </>
    )
}


