// import css
import './SiignalChart.css';

// import react
import React, { useEffect } from 'react';
import Chart from 'react-apexcharts'
import { Grid, Box, Card, CardContent, Typography } from '@mui/material';


function SiignalChart({ chartData, selectedView }) {
    const [chartRequiredData, setChartRequiredData] = React.useState({ categories: [], activeSeries: [] });

    useEffect(() => {
        updateChart()
    }, [])

    useEffect(() => {
        updateChart()
    }, [selectedView, chartData])

    const updateChart = () => {
        let categories = [];

        let section = (selectedView.view == "Volumen") ? "Volume" : "Staff".toUpperCase()

        let forecast = {
            name: `${section.toUpperCase()}_FORECAST`,
            data: []
        }

        let real = {
            name: `${section.toUpperCase()}_REAL`,
            data: []
        }

        categories = Object.keys(chartData).sort()

        for (const interval of categories) {
            const _forecast = chartData[interval][section.toUpperCase() + "_FORECAST"]
            const _real = chartData[interval][section.toUpperCase() + "_REAL"]

            forecast.data.push((_forecast != null && _forecast != undefined) ? parseFloat(_forecast).toFixed(2) : 0)
            real.data.push((_real != null && _real != undefined) ? parseFloat(_real).toFixed(2) : 0)
        }

        setChartRequiredData({
            categories: categories,
            activeSeries: [
                forecast,
                real
            ]
        })
    }


    return (

        <Grid item xs={12}>
            <Box >
                <Card variant="outlined" className='boxShadow'>
                    <Typography component="div" sx={{ fontWeight: 800, fontSize: 19, color: '#00244c', fontFamily: 'inherit', paddingLeft: '5px' }}>
                        Proyectado vs  Real - {selectedView.view} {selectedView.cell} ({(selectedView.view === 'STAFF') ? 'FTEs' : 'Interacciones Entrantes'})
                    </Typography>
                    <CardContent >
                        <Chart
                            options={{
                                colors: ['#00AAcF', '#00244c', '#00AAFF', '#00AAFF', '#00244c', '#00AAFF', '#CCEEFF'],
                                stroke: {
                                    curve: 'smooth',
                                    width: Array.apply(3, Array(chartRequiredData.categories.length)).map(a => 3),
                                },
                                xaxis: {
                                    type: 'time',
                                    categories: chartRequiredData.categories
                                },
                                legend: {
                                    position: 'bottom',
                                    fontSize: '12px',
                                    fontFamily: 'Nunito Sans',
                                },
                                grid: {
                                    show: true
                                },
                                markers: {
                                    size: 5,

                                }
                            }}
                            series={chartRequiredData.activeSeries}
                            height='500rem' />
                    </CardContent>
                </Card>
            </Box>
        </Grid>
    );
}

export { SiignalChart };
