import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, CardBody } from '@progress/kendo-react-layout';

const Details = ({ messageData }) => {
    const [loading, setLoading] = useState(true);
    const [messageParametersConfig, setMessageParametersConfig] = useState({})
    // const campaignDetails = useSelector(selectCampaignDetails)
    // const whatsAppConnect = useSelector(selectWhatsAppConnect)
    const templateDetailsToShow = [
        {
            name: "Template Name",
            label: "Nombre de plantilla"
        }, {
            name: "Category",
            label: "Categoria"
        }, {
            name: "División",
            label: "División"
        }, {
            name: "Channel",
            label: "Canal"
        }, {
            name: "Solicitado por:",
            label: "Solicitado Por"
        }
    ]
    const campaignCreationForm = [
        {
            label: "Area",
            type: "text"
        }, {
            label: "Fecha de inicio",
            type: "date"
        }, {
            label: "Fecha de fin",
            type: "date"
        }, {
            label: "Responsable",
            type: "text"
        }
    ]

    const extractNumbers = (template) => {
        const regex = /{{(\d+)}}/g;
        const numbers = [];
        let match;

        while ((match = regex.exec(template))) {
            const number = parseInt(match[1], 10);
            numbers.push(number);
        }
        return numbers;
    };

    const applyParametersToTemplate = (message) => {
        messageParametersConfig.forEach(messageConfig => {
            if (messageConfig.parameterExample.trim().length != 0) {
                message = message.replace(`{{${messageConfig.parameterNumber}}}`, `<span class="whatsappConnectParameterRendered">${messageConfig.parameterExample}</span>`)
            }
        })
        return message.split(/\r\n|\r|\n/).map(el => {
            return `<p>${el}</p>`
        }).join("")
    }

    useEffect(() => {
        console.log("##############################")
        console.log(messageData)

        let messageParametersConfig = messageData.messageParametersConfig

        setMessageParametersConfig(messageParametersConfig);

        setLoading(false);
    }, [])


    return (
        loading ? <>Cargando</> : <>
            <Card>
                <CardBody className='WhatsAppConnect-templateDetails'>
                    {
                        templateDetailsToShow.map(field => {
                            return (
                                <div className='gridCell'>
                                    <b>{field.label}</b>
                                    <div>
                                        {messageData.messageSelected[field.name]}
                                    </div>
                                </div>
                            )
                        })
                    }

                    {
                        campaignCreationForm.map(field => {
                            return (
                                <div className='gridCell'>
                                    <b>{field.label}</b>
                                    <div>
                                        <input
                                            disabled
                                            type={field.type}
                                            value={messageData.campaignDetails[(field.label).toLowerCase().split(" ").join("_")]}
                                            min={(field.type == "date") ? new Date().toISOString().split("T")[0] : ""}

                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className='gridCell'>
                        <b>Correos con acceso:</b>
                        <textarea
                            disabled
                            value={(messageData.emailsWithAccess).join("\n")} />
                    </div>
                    <div className='gridCell'>
                        <b>Plantilla:</b>
                        <div className='whatsapp-message-white' dangerouslySetInnerHTML={{
                            __html: messageData.messageSelected.Content.split(/\r\n|\r|\n/).map(el => {
                                return `<p>${el}</p>`
                            }).join("")
                        }}>
                        </div>
                    </div>
                    <div className='gridCell'>
                        <div>
                            <table className='whatsAppConnect-templateInputs'>
                                <thead>
                                    <tr>
                                        <th>
                                            ID
                                        </th>
                                        <th>
                                            Nombre
                                        </th>
                                        <th>
                                            Ejemplo
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        messageParametersConfig.map((parameter, index) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        {parameter.parameterNumber}
                                                    </td>
                                                    <td>
                                                        <input
                                                            disabled
                                                            value={parameter.parameterName} />
                                                    </td>
                                                    <td>
                                                        <input
                                                            disabled
                                                            value={parameter.parameterExample} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='gridCell'>
                        <b>Previsualizacion:</b>
                        <div className="whatsapp-message-green">
                            <div className='' dangerouslySetInnerHTML={{ __html: applyParametersToTemplate(messageData.messageSelected.Content) }}></div>
                        </div>
                    </div>
                </CardBody>
            </Card >
        </>
    )
}

export { Details }