import {DropDownList} from '@progress/kendo-react-dropdowns';

import {useRef, useState} from 'react';
import {getDaigramStorage} from '../../../../reports/utils/localStorageDiagram';
import {exportNestedObject} from '../../../../reports/utils/nestedObjectDiagram';
import {Label, Error, Hint, FloatingLabel} from '@progress/kendo-react-labels';
import {Button} from '@mui/material';
const diagram = getDaigramStorage();

const DropGenerated = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		type,
		optional,
		value,
		placeholder,
		...others
	} = fieldRenderProps;
	const editorRef = useRef(null);
	const labelId = label ? `${id}_label` : '';
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const [data, setData] = useState([['Reportes por Área', 'Reportes por País ']]);
	const [listKey, setListKey] = useState(value || []);
	const [valor, setValor] = useState([]);

	const handleChange = async (e,index) => {
        if(e.target.value.length>0){
			const newValues = [...valor];
			newValues[index] = e.target.value;
			setValor(newValues);
			setListKey([...listKey, value? newValues[index]:newValues[0]]);
			let result = ([...listKey, value? newValues[index]:newValues[0]]);
			const newData =  Object.keys(await exportNestedObject(diagram, result));
			if(newData.length>0){
				console.log("🚀 ~ handleChange ~ newData:", newData)
				setData(prevData => [...prevData, newData]);

			}
			fieldRenderProps.onChange({
				value: result,
			});
        setValor('');
		}
	};
	const onSave = async () => {
            setListKey([...listKey, value? valor[value.length]:valor[0]]);
			let result = ([...listKey, value? valor[value.length]:valor[0]]);
            const newData = Object.keys(await exportNestedObject(diagram, result));
            setData(prevData => [...prevData, newData]);
			fieldRenderProps.onChange({
				value: result,
			});
			setValor('');

		
	};
	const onDelete = async () => {
		listKey.pop();
        data.pop();
        setData([...data]);
		setListKey([...listKey]);
		fieldRenderProps.onChange({
			value: listKey,
		});
		setValor('');
	};
	return (
		<div
			style={{
				display: 'flex',
				gap: '30px',
				flexWrap: 'wrap',
			}}>
			<div>
				monda
				<br />
				{data.map((item, index) => {
					return (
						<DropDownList
                            key={index}
                            ref={editorRef} valid={valid} id={id} disabled={disabled}
							style={{
								width: '300px',marginLeft:'20px'
							}}
							data={data[index]}
							onChange={(e)=>handleChange(e,index)}
						/>
					);
				})}
			</div>
			{/* <Button variant="contained" onClick={onSave} disabled={disabled}>
				Agregar
			</Button> */}
            <Button
					variant="text"
					disabled={disabled}
					color="error"
					onClick={onDelete}>
					Eliminar
				</Button>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
			<div style={{display: 'flex'}} {...others}>
				{listKey.map((valueKey, key) => (
					<p
						key={key}
						style={{
							padding: '3px',
							margin: '10px',
							color: 'black',
							fontSize: '15px',
							fontWeight: 'bold',
						}}>
						{valueKey}
						{'  '} /
					</p>
				))}
			</div>
		</div>
	);
};
export default DropGenerated;

//defaultItem={defaultItemCategory}
