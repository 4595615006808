import {Editor, EditorTools, EditorUtils} from '@progress/kendo-react-editor';
import React, { useState } from 'react'
import readingTextEditor from './readingTextEditor';

const {
	AddRowBefore,
	AddRowAfter,
	DeleteRow,
	DeleteTable,
	Undo,
	Redo,
} = EditorTools;

export const EditorText = ({setFlagAfterCharge,onClickUpload,tablaHTMLResult="",status}) => {
    const editor = React.createRef();
	let mensajeEditorBox=""
	let tablaHTML = ''
	if(!tablaHTMLResult){
		mensajeEditorBox="Bienvenido al modulo que le permitirá cargar a los participantes de la capacitación más rápido y sencillo"
		tablaHTML = `
		<table>
			<tbody>
				<tr>
					<td><p>Externo/Interno</p></td>
					<td><p>Documento</p></td>
					<td><p>Nombre Completo</p></td>
					<td><p>Correo</p></td>
					<td><p>Tema</p></td>
					<td><p>Nota</p></td>
					<td><p>Estado</p></td>				
				</tr>
				<tr>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
				</tr>
			</tbody>
		</table>
		`;}else{
			mensajeEditorBox="Estos fueron los registros que no se pudieron Cargar"
			tablaHTML=tablaHTMLResult
		}

    const getHtml = () => {
        let r=""
		setFlagAfterCharge(true)
		if (editor.current) {
			const view = editor.current.view;
			if (view) {
				r = EditorUtils.getHtml(view.state);
			}
            
		}
        let AsistansList=readingTextEditor(r)
		onClickUpload(AsistansList)
	};
  return (
    <div>
			<h2 style={{ textAlign: 'center', color: '#00244c'}}>{mensajeEditorBox}</h2>
			<button
				style={{ marginBottom: '20px'}}
				className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-button k-button-md k-rounded-md k-button-solid k-button-solid-base-icontext"
				onClick={getHtml}
				disabled={!status}
				>
				<span className="k-icon k-i-arrow-60-down" />
				Cargar participantes
			</button>
			<Editor
				tools={[
					[AddRowBefore, AddRowAfter],
					[DeleteRow, DeleteTable],
					[Undo, Redo],
				]}
				contentStyle={{
					height: 260
				}}
				defaultContent={tablaHTML}
				ref={editor}
			/>
			<br />
			
			

            &nbsp;
      
		</div>
  )
}
