import * as React from 'react';
import {MyCommandCellParticipantes} from './topicsCommandCell';
import {
	Grid,
	GridColumn as Column,
	GridColumnMenuFilter,
	GridColumnMenuSort,
	GridToolbar,
} from '@progress/kendo-react-grid';
import axios from 'axios';
import {CircularProgress} from '@mui/material';
import {process} from '@progress/kendo-data-query';
import {ExcelExport} from '@progress/kendo-react-excel-export';
import {Button} from '@progress/kendo-react-buttons';
import {
	TopicsParticipantsDrowpdown,
	TopicsParticipantsStatusDrowpdown,
} from './participantsFields';
import { useDispatch, useSelector } from 'react-redux';
import { selectDetalleCapacitacion, setDetalleCapacitacion } from '../../redux/TNBSlice';
import { fileExcelIcon } from '@progress/kendo-svg-icons';
const editField = 'inEdit';

export const myContext = React.createContext(null);

export const ColumnMenu = (props) => {
	return (
		<div>
			<GridColumnMenuSort {...props} />
			<GridColumnMenuFilter {...props} />
		</div>
	);
};

export const TrainingParticipantsExternalsTable = (props) => {


	const [dataDetailsParticipants, setDataDetailsParticipants] = React.useState([]);
	const [isloading, setLoading] = React.useState(true);
	const [take, setTake] = React.useState();
	const [skip, setSkip] = React.useState(0);
	const [sort, setSort] = React.useState([]);
	const [group, setGroup] = React.useState([]);
	const [filter, setFilter] = React.useState(null);
	
	const excelExport = () => {
		if (_export.current !== null) {
			_export.current.save();
		}
	};
	const dispatch=useDispatch()
	// const myValue={trainingName:props.value, updateTrigger:trigger}
	const [myValue, setMyValue] = React.useState(props.value);
	const capacitacion=useSelector(selectDetalleCapacitacion);
	const inactiveParticipantsTraining = capacitacion.estado; 
	const _export = React.useRef(null);


	React.useEffect(() => {
		const loadGet = async () => {
			try {
				setDataDetailsParticipants(capacitacion.participantesExternos||[]);
				setLoading(false);
			} catch (error) {
				setDataDetailsParticipants(undefined)
			}
		};
		loadGet();
	}, [capacitacion]);

	const dataState = {
		take,
		skip,
		sort,
		group,
		filter,
	};

	const onDataStateChange = React.useCallback(
		(event) => {
			setTake(event.dataState.take);
			setSkip(event.dataState.skip);
			setSort(event.dataState.sort);
			setGroup(event.dataState.group);
			setFilter(event.dataState.filter);
		},
		[setTake, setSkip, setSort, setGroup]
	);

	// modify the data in the store, db etc
	const remove = async(dataItem) => {
		await axios
			.post(
				`/api/TNB/TrainingDetails/ExternalsParticipants/DeleteOne?id=${props.value}`,
				dataItem
			)
			.then((response) => {
				const resultRemove = response.data;
				setDataDetailsParticipants(resultRemove.participantesExternos||[]);
				dispatch(setDetalleCapacitacion(resultRemove))
			});
			
	};
	const add = async(dataItem) => {
		dataItem.inEdit = false;
		
		await axios
			.post(
				`/api/TNB/TrainingDetails/ExternalsParticipants/InsertOne?id=${props.value}`,
				dataItem
			)
			.then((response) => {
				const resultAdd = response.data;
				setDataDetailsParticipants(resultAdd.participantesExternos||[]);
				dispatch(setDetalleCapacitacion(resultAdd))
			});
			
	};
	const update = async(dataItem) => {
		dataItem.inEdit = false;
		await axios
			.post(
				`/api/TNB/TrainingDetails/ExternalsParticipants/UpdateOne?id=${props.value}`,
				dataItem
			)
			.then((response) => {
				const resultAdd = response.data;
				setDataDetailsParticipants(resultAdd.participantesExternos||[]);
				dispatch(setDetalleCapacitacion(resultAdd))
			});
			
	};

	// Local state operations
	const discard = () => {
		const newData = [...dataDetailsParticipants];
		newData.splice(0, 1);
		setDataDetailsParticipants(newData);
	};
	const cancel = (dataItem) => {
		setDataDetailsParticipants(
			dataDetailsParticipants.map((item) =>
				item.idParticipante === dataItem.idParticipante
					? {
							...item,
							inEdit: false,
					  }
					: item
			)
		);
	};
	const enterEdit = (dataItem) => {
		setDataDetailsParticipants(
			dataDetailsParticipants.map((item) =>
				item.idParticipante === dataItem.idParticipante
					? {
							...item,
							inEdit: true,
					  }
					: item
			)
		);
	};
	const itemChange = (event) => {
		const newData = dataDetailsParticipants.map((item) =>
			item.idParticipante === event.dataItem.idParticipante
				? {
						...item,
						[event.field || '']: event.value,
				  }
				: item
		);
		setDataDetailsParticipants(newData);
	};
	const addNew = () => {
		const newDataItemParticipants = {
			inEdit: true,
			Discontinued: false,
		};
		setDataDetailsParticipants([
			newDataItemParticipants,
			...dataDetailsParticipants,
		]);
	};

	const CommandCell = (props) => (
		<MyCommandCellParticipantes
			{...props}
			edit={enterEdit}
			remove={remove}
			add={add}
			discard={discard}
			update={update}
			cancel={cancel}
			editField={editField}
		/>
	);

	const processedData = process(dataDetailsParticipants, dataState);

	if (isloading) {
		return (
			<div className="desktop-view-SDR">
				<div id="reportContainerSDR">
					<div className="centerDS">
						<CircularProgress />
					</div>
				</div>
			</div>
		);
	} else if (dataDetailsParticipants === undefined) {
		return '';
	} else
		return (
			<>
				<myContext.Provider value={myValue}>
					<ExcelExport data={dataDetailsParticipants} ref={_export}>
						<Grid
							style={{
								height: '100%',
							}}
							data={processedData}
							onItemChange={itemChange}
							onDataStateChange={onDataStateChange}
							editField={editField}>
							<GridToolbar>
								<Button	title="Add new"size={'small'}rounded={'medium'}themeColor={'base'}onClick={addNew}disabled={!inactiveParticipantsTraining}>
									Agregar
								</Button>
								<Button	svgIcon={fileExcelIcon}onClick={excelExport}size={'small'}rounded={'medium'}themeColor={'success'}fillMode={'outline'}></Button>
							</GridToolbar>
							<Column	field={'idParticipante'}title={'ID'}width="60%"editable={false}columnMenu={ColumnMenu}style={{fontSize: '10px'}}/>
							<Column	field={'documentoParticipante'}title={'Document'}columnMenu={ColumnMenu}editor="text"width="120%"style={{fontSize: '10px'}}/>
							<Column field={'nombreParticipante'}title={'Nombre'}editor="text"columnMenu={ColumnMenu}/>
							<Column field={'email'}title={'Email'}editor="text"columnMenu={ColumnMenu}/>
							<Column field={'nombreActividad'}title={'Tema'}columnMenu={ColumnMenu}cell={TopicsParticipantsDrowpdown}/>
							<Column field={'nota'}title={'Nota'}editor="numeric"columnMenu={ColumnMenu}width="80%"/>
							<Column field={'estado'}title={'Estado'}columnMenu={ColumnMenu}cell={TopicsParticipantsStatusDrowpdown}width="110%"/>
							{inactiveParticipantsTraining === true ? (
								<Column cell={CommandCell} title={'Acciones'} width="150%" />
							) : null}
						</Grid>
					</ExcelExport>
				</myContext.Provider>
			</>
		);
};
