
import { useParams } from 'react-router-dom';
import { Step3IncidenciaYrequerimiento } from '../Incidencia/Step3IncidenciaYRequerimiento';
import { Step3Reporteria } from '../Reporteria/Step3Reporteria';

export const Step3RequestForm = () => {
    const TypeRequest = useParams().type;
    switch (TypeRequest) {
        case 'Incidencia':
            return (
                <Step3IncidenciaYrequerimiento />
            )
        case 'Requerimiento':
            return (
                <Step3IncidenciaYrequerimiento />
            )
        case 'solicitud de Reporte':
            return (
                <Step3Reporteria />
            )
        default:
            return (
                <div>Algo Salió Mal</div>
            )
    }
}