import { ItemTodo } from "./components/ItemTodo"

export const TodoList = ({todos, handleTodoMade}) => {
  const todosFalse = todos?.filter(item => item.atencion === false);
  const todosTrue = todos?.filter(item => item.atencion === true);

  return (
    <ul className="list-group">
        {todosFalse?.map((item)=>(
            <ItemTodo item={item} key={item.idSeguimientoAliado} todoMade={handleTodoMade}color={'red'}/>            
        ))}
        {todosTrue?.map((item)=>(
            <ItemTodo item={item} key={item.idSeguimientoAliado} todoMade={handleTodoMade}color={'#007bff'}/>            
        ))}
    </ul>
  )
}