import { useTodo } from "../Hook/useTodo";
import { TodoList } from "../TodoList";




export const MiddleList = ({title,valueInit}) => {
   const {todos, handleDeleteTodo, handleTodoMade,todosCount,pendingTodosCount}=useTodo(valueInit)


  return (
    <>
      <h4>{title}</h4>
      <hr />
      <div className="row">
        <div className="col-12 todoList-Style">
          <TodoList todos={todos} onDeleteTodo={handleDeleteTodo} handleTodoMade={handleTodoMade} />
        </div>
        <small style={{textAlign:'end',fontSize:'1.1rem'}}>pendientes: <span style={{fontWeight:'bold'}}>{pendingTodosCount}</span></small>
      </div>
    </>
  );
};
