import {DropDownList} from '@progress/kendo-react-dropdowns';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import useAxios from '../../hooks/UseAxios';
import {Loader} from '@progress/kendo-react-indicators';
import useAxiosPut from '../../hooks/UseAxiosPut';
import {useDispatch} from 'react-redux';
import {thunkChargeInfoTicket} from '../../../redux/helpDeskTicketSlice';
import {FormMultiSelect} from '../RequestForm/form-components';

export const TableEditing = ({
	data,
	valuesToEdit,
	setToast,
	timeElapsedStatus,
}) => {
	const [selectedArea, setSelectedArea] = useState(
		data.destinationArea.areaName?.map((item) => {
			return item;
		})
	);
	const {
		data: listDataParners,
		isLoadingPartners,
		error: ErrorDataPartners,
	} = useAxios('/api/listDataParner');
	const {
		data: listOperation,
		isLoadingOperation,
		error: ErrorOperation,
	} = useAxios('/api/collaboratordayofOperation');
	const listPerformancePartners = useMemo(
		() => listDataParners.filter((item) => item.area === 'Performance'),
		[listDataParners]
	);
	const {
		data: Areas,
		isLoading,
		error,
	} = useAxios('/API/HelpDesk/AreaAndSubAreas');
	const [chossedSubArea, setChossedSubArea] = useState(
		Areas[data.destinationArea[0]?.areaName]
	);
	const {
		responseData,
		isLoading: isLoadingEditing,
		error: errorEditing,
		executePut,
		statusResponse,
	} = useAxiosPut('/api/helpDesk/DtaEditing');
	const dispatch = useDispatch();
	useEffect(() => {
		if (Areas) {
			setChossedSubArea(filterCategories(selectedArea));
		}
	}, [Areas]);
	const filterCategories = (categories) => {
		return categories.map((category) => Areas[category] || []).flat();
	};
	const handleSubArea = (event) => {
		setChossedSubArea(filterCategories(event.value));
	};

	const handleSubmit = async (e, Modificación) => {
		if (Modificación === 'area') {
			setSelectedArea(e.target.value);
			handleSubArea(e.target);
		}
		let dataToSend = {
			[Modificación]: e.target.value,
			ticketId: data.ticketId,
			timeElapsedStatus: timeElapsedStatus,
		};
		if (Modificación === 'assignedTo') {
			dataToSend = {
				[Modificación]: {
					name: e.target.value,
					email: listDataParners.find((item) => item.name === e.target.value)
						.username,
					area: listDataParners.find((item) => item.name === e.target.value)
						.area,
				},
				ticketId: data.ticketId,
				timeElapsedStatus: timeElapsedStatus,
			};
		}
		if (Modificación === 'partnerApproved') {
			dataToSend = {
				[Modificación]: {
					Name: data.assignedTo.name,
					state: e.target.value === 'Si' ? true : false,
					domain: data.assignedTo.area,
				},
				ticketId: data.ticketId,
				timeElapsedStatus: timeElapsedStatus,
			};
		}
		if (Modificación === 'performancePartner') {
			dataToSend = {
				[Modificación]: {
					Name: e.target.value,
					state: false,
					domain: 'Data Engineering',
				},
				ticketId: data.ticketId,
				timeElapsedStatus: timeElapsedStatus,
			};
		}
		if (Modificación === 'validatedwithPerformance') {
			dataToSend = {
				[Modificación]: {
					Name: data.approval.performancePartner.Name,
					state: e.target.value === 'Si' ? true : false,
					domain: 'Data Engineering',
				},
				ticketId: data.ticketId,
				timeElapsedStatus: timeElapsedStatus,
			};
		}
		if (Modificación === 'validatedwithDataOwner') {
			dataToSend = {
				[Modificación]: {
					Name: data.approval.dataOwner.Name,
					email: data.approval.dataOwner.email,
					state: e.target.value === 'Si' ? true : false,
				},
				ticketId: data.ticketId,
				timeElapsedStatus: timeElapsedStatus,
			};
		}
        
        dataToSend = {...dataToSend, commentOwner:{name: sessionStorage.getItem('name'), mail: sessionStorage.getItem('usr')}}
		await executePut(dataToSend);
	};
	useEffect(() => {
		if (responseData && statusResponse === 200) {
			setToast({
				snackbarOpen: true,
				message: responseData.message,
				type: 'success',
			});
			dispatch(thunkChargeInfoTicket({numTicket: data.ticketId}));
		} else if (errorEditing) {
			setToast({
				snackbarOpen: true,
				message: responseData.error,
				type: 'error',
			});
			//responseData.error
		}
		// Asegúrate de incluir responseData y errorEditing en el array de dependencias
	}, [responseData, errorEditing, statusResponse]);

	const DroptdownEdit = ({status, modificación, defaultValue}) => {
		let categories = [];
		switch (modificación) {
			case 'status':
				for (let key in status) {
					if (status.hasOwnProperty(key)) {
						let item = status[key];
						categories.push(item.name);
					}
				}
				break;
			case 'priority':
				categories.push('Baja', 'Media', 'Alta');
				break;
			case 'assignedTo':
				for (let partner in listDataParners && listDataParners) {
					if (listDataParners.hasOwnProperty(partner)) {
						let item = listDataParners[partner];
						categories.push(item.name);
					}
				}
				break;
			case 'partnerApproved':
				categories.push('Si', 'No');
				break;
			case 'performancePartner':
				categories.push(listOperation.title?listOperation.title:'Fabian David Carreño Leon');
				break;
			case 'validatedwithPerformance':
				categories.push('Si', 'No');
				break;
			case 'validatedwithDataOwner':
				categories.push('Si', 'No');
				break;
            case 'requestType':
                categories.push('solicitud de Reporte', 'Incidencia','Requerimiento');
                break;
			default:
				break;
		}

		return (
			<DropDownList
				style={{
					width: '300px',
				}}
				data={categories}
				onChange={(e) => handleSubmit(e, modificación)}
				defaultValue={defaultValue}
			/>
		);
	};
	if (isLoadingEditing || isLoadingPartners || isLoading) {
		return <Loader size="large" />;
	}

	const FormMultiSelectEdited = ({modificación, defaultValue}) => {
		if (defaultValue.length === 1 && defaultValue[0] === null) {
			defaultValue = [];
		}
		

		let categories = [];
		if (modificación === 'area') {
			categories = Object.keys(Areas);
		} else {
			categories = chossedSubArea;
		}

		return (
			<FormMultiSelect
				style={{
					width: '300px',
				}}
				onChange={(e) => handleSubmit(e, modificación)}
				data={categories}
				defaultValue={defaultValue}
			/>
		);
	};

	return (
		<>
			{valuesToEdit === 'values' ? (
				<table style={{width: '100%'}} className="table-Ticket">
					<tbody>
						<tr>
							<td>Estatus</td>
							<DroptdownEdit
								status={data.status.history}
								modificación={'status'}
								defaultValue={data.status.currently}
							/>
						</tr>
						<tr>
							<td>Prioridad</td>
							<DroptdownEdit
								modificación={'priority'}
								defaultValue={data.priority}
							/>
						</tr>
						<tr>
							<td>Área</td>
							<FormMultiSelectEdited
								modificación={'area'}
								defaultValue={selectedArea}
							/>
						</tr>
						<tr>
							<td>Subárea</td>
							<FormMultiSelectEdited
								modificación={'subArea'}
								defaultValue={data.destinationArea.subArea?.map((item) => {
									return item;
								})}
							/>
						</tr>
					</tbody>
				</table>
			) : valuesToEdit === 'Partner' ? (
				<table style={{width: '100%'}} className="table-Ticket">
					<tbody>
						<tr>
							<td>Assigned To</td>
							<DroptdownEdit
								modificación={'assignedTo'}
								defaultValue={data.assignedTo.name}
							/>
						</tr>
						<tr>
							<td>¿Aprobación del Partner?</td>
							<DroptdownEdit
								modificación={'partnerApproved'}
								defaultValue={data.approval.dataPartner.state ? 'Si' : 'No'}
							/>
						</tr>
						<tr>
							<td>Aprobación del Data Owner?</td>
							<DroptdownEdit
								modificación={'validatedwithDataOwner'}
								defaultValue={data.approval.dataOwner.state ? 'Si' : 'No'}
							/>
						</tr>
						{data.requestType === 'solicitud de Reporte' && (
							<>
								<tr>
									<td>Performance Review</td>
									<DroptdownEdit
										modificación={'performancePartner'}
										defaultValue={data.approval.performancePartner.Name}
									/>
								</tr>
								<tr>
									<td>¿Aprobación del Performance Review?</td>
									<DroptdownEdit
										modificación={'validatedwithPerformance'}
										defaultValue={
											data.approval.performancePartner.state ? 'Si' : 'No'
										}
									/>
								</tr>
							</>
						)}
					</tbody>
				</table>
			) : (
				<p>
					<span style={{paddingRight: '30px'}}>Tipo:</span>{' '}
					<DroptdownEdit
						modificación={'requestType'}
						defaultValue={data.requestType}
					/>
				</p>
			)}
			{isLoading && <Loader size="medium" />}
		</>
	);
};
