const baseData = [{
  "TaskID": 4,
  "OwnerID": 2,
  "ActivityTitle": "Bowling tournament",
  "Description": "",
  "StartTimezone": null,
  "Start": "2023-11-14T13:00:00.000Z",
  "End": "2023-11-14T14:00:00.000Z",
  "EndTimezone": null,
  "RecurrenceRule": null,
  "RecurrenceID": null,
  "RecurrenceException": null,
  "isAllDay": false
},{
  "ActivityID": 1,
  "Area": 11,
  "Country": 1,
  "ActivityType": 1,
  "ActivityTitle": "Reunión de dataSmart Time Reporting",
  "idActivity": "Non help desk related",
  "Description": "Reunión de seguimiento de avances Time Reporting, se detallo la propuesta de un cambio en Time reporting, sin embargo por el momento solamnete se enfocará en arreglar detalles de time reporting nada más",
  "Name": "Hugo Fernando Castiblanco Acosta",
  "User": "cast902386@siigo.com",
  "Start": "2023-11-14T13:00:00.000Z",
  "End": "2023-11-14T14:00:00.000Z"
},
{
  "ActivityID": 2,
  "Area": 11,
  "Country": 1,
  "ActivityType": 3,
  "ActivityTitle": "Daily Meeting con el Equipo",
  "idActivity": "Non help desk related",
  "Description": "Daily compañeros expusieron sus alcances para el día",
  "Name": "Hugo Fernando Castiblanco Acosta",
  "User": "cast902386@siigo.com",
  "Start": "2023-11-14T14:30:00.000Z",
  "End": "2023-11-14T15:00:00.000Z"
}];
export const customModelFields = {
id: 'ActivityID',
description: 'Description',
start: 'Start',
end: 'End',
title: 'ActivityTitle',
};

const currentYear = new Date().getFullYear();
export const parseAdjust = eventDate => {
  const date = new Date(eventDate);
  date.setFullYear(currentYear);
  return date;
};
const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
const date = Date.now();
export const displayDate = new Date(date);
export const sampleData = baseData.map(dataItem => ({
  id: dataItem.TaskID,
  start: parseAdjust(dataItem.Start),
  startTimezone: dataItem.startTimezone,
  end: parseAdjust(dataItem.End),
  endTimezone: dataItem.endTimezone,
  isAllDay: dataItem.isAllDay,
  title: dataItem.ActivityTitle,
  description: dataItem.Description,
  recurrenceRule: dataItem.RecurrenceRule,
  recurrenceId: dataItem.RecurrenceID,
  recurrenceExceptions: dataItem.RecurrenceException,
  roomId: dataItem.RoomID,
  ownerID: dataItem.OwnerID,
  personId: dataItem.OwnerID
}));
export const sampleDataWithResources = baseData.map(dataItem => ({
  id: dataItem.TaskID,
  start: parseAdjust(dataItem.Start),
  startTimezone: dataItem.startTimezone,
  end: parseAdjust(dataItem.End),
  endTimezone: dataItem.endTimezone,
  isAllDay: dataItem.isAllDay,
  title: dataItem.ActivityTitle,
  description: dataItem.Description,
  recurrenceRule: dataItem.RecurrenceRule,
  recurrenceId: dataItem.RecurrenceID,
  recurrenceExceptions: dataItem.RecurrenceException,
  roomId: randomInt(1, 2),
  personId: randomInt(1, 2)
}));
export const sampleDataWithCustomSchema = baseData.map(dataItem => ({
  ...dataItem,
  Start: parseAdjust(dataItem.Start),
  End: parseAdjust(dataItem.End),
  PersonIDs: randomInt(1, 2),
  RoomID: randomInt(1, 2)
}));
