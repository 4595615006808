import React from 'react'
import { SiignalDashboard } from '../../components/Dashboards/Customer/Siignal/Siignal'
//import { SiignalDashboard } from '../../components/Siignal/index'

export const Siignal = () => {
    return (
        <SiignalDashboard />
    )
}


