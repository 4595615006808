import React, {useEffect} from 'react';
import './Wrap.css';

import {HeaderHome} from '../layout/header';
import {CustomLayout} from '../layout/CustomLayout';
import {OrgTree} from '../utils/OrgChart/OrgTree';

export const Wrap = ({name, view, nameAsesor}) => {
//  console.log('%c%s', 'color: #997326', nameAsesor);
// 	console.log('🚀 ~ Wrap ~ view:', view);

	return (
		<div className="container-home-wrap">
			<div className="div1Wrap">
				<HeaderHome name={nameAsesor ? nameAsesor : name} />
			</div>
			<div className="div2Wrap">
				<CustomLayout name={nameAsesor ? nameAsesor : name} view={view} />
			</div>
			<div className="div3Wrap">
				<OrgTree />
			</div>
		</div>
	);
};
