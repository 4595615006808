import React from 'react'

export const WrongMessage = ({data}) => {
    let messages = data?.messages;
    return (
        <div className="error-details">
          {messages?.map((msg, index) => (
            <div key={index} className="message">
              <h2 className='h-errorRowRefresh'>Código de Error: {msg.code}</h2>
              <p className='p-errorRowRefresh'>{msg.message}</p>

              {msg.location && msg.location.SourceObject && (
                <div className="source-object">
                    <h3 className='h-errorRowRefresh'>Location</h3>
                <p className='p-errorRowRefresh'>StartLine: {msg.location.StartLine}</p>
                <p className='p-errorRowRefresh'>EndLine: {msg.location.EndLine}</p>
                <p className='p-errorRowRefresh'>StartColumn: {msg.location.StartColumn}</p>
                <p className='p-errorRowRefresh'>EndColumn: {msg.location.EndColumn}</p>
                  <h3 className='h-errorRowRefresh'>Source Object</h3>
                  <p className='p-errorRowRefresh'>Table: {msg.location.SourceObject.Table}</p>
                  <p className='p-errorRowRefresh'>Partition: {msg.location.SourceObject.Partition}</p>
                  <p className='p-errorRowRefresh'>Column: {msg.location.SourceObject.Column || 'N/A'}</p>
                  <p className='p-errorRowRefresh'>Measure: {msg.location.SourceObject.Measure || 'N/A'}</p>
                </div>
              )}
                {data.objects?.map((obj, index) => (
                    <div key={index} className={obj.status==='Completed'?"success-message":"wrong-message"}>
                    <h4 className={obj.status==='Completed'?'h-successRowRefresh':'h-errorRowRefresh'}>Tabla: {obj.table}</h4>
                    <p className='p-successRowRefresh'>{obj.status}</p>
                    </div>
                ))}
            </div>
          ))}
        </div>
      );
}

