import axios from 'axios';

const getIncidents = (callback) => {
    axios.get('/api/massiveIncidents').then(res => {
        let resultData = res.data;
        console.log(resultData)
        callback(resultData);
    }).catch(err => {
        alert("Se ha presentado un error");
    });
}

const getAdmins = (callback) => {
    axios.get('/api/massiveIncidentsAdmins').then(res => {
        let resultData = res.data;
        callback(resultData);
    }).catch(err => {
        alert("Se ha presentado un error");
    });
}

const saveNewIncident = (incident) => {
    axios.post('/api/massiveIncidents', incident).then(res => {
        console.log("Incident saved")
    }).catch(err => {
        console.log(err)
        alert(err.response.data.message)
    });
}

const saveEditIncident = (incident, callback) => {
    axios.patch(`/api/massiveIncidents/${incident.codigo}`, incident).then(res => {
        console.log("Incident saved")
        getIncidents(callback)
        console.log("Incidente guardado")
    }).catch(err => {
        alert(err.response.data.message)
    });
}

const addClientToIncident = (incident, client) => {
    axios.put(`/api/massiveIncidents/${incident.codigo}`, client).then(res => {
        console.log("Incident saved")
        alert("Cliente añadido")
    }).catch(err => {
        alert(err.response.data.message)
    });
}

export { getIncidents, saveNewIncident, saveEditIncident, addClientToIncident, getAdmins }